import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import {
	map,
	transform,
	filter,
	join,
	split,
	range,
	concat,
	difference,
	get,
	cloneDeep,
	forEach,
	set,
	every,
	findKey,
} from 'lodash';

import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { withBlock } from 'common/components/block';
import { Notification } from 'common/components/notifications';
import { withLoader } from 'common/components/loader';
import settingService from 'common/services/settingsService';
import { principalService } from 'common/services';

class WebhookSettings extends Component {
	constructor(props) {
		super(props);

		this.transactionKeys = [
			'xCommand',
			'xMaskedAccountNum',
			'xAccountType',
			'xCardLastFour',
			'xExp',
			'xCardType',
			'xAuthCode',
			'xEntryMethod',
			'xCurrency',
			'xDigitalWalletType',
			'xCashbackAmount',
			'xStatus',
			'xResponseError',
			'xResponseRefnum',
			'xResponseAuthCode',
			'xResponseAVSCode',
			'xResponseEmailVerificationCode',
			'xSoftwareName',
			'xUsername',
			'xComputerName',
			'xClientIP',
			'xIP',
			'xKey',
			'xSourceKey',
			'xDescription',
			'xInvoice',
			'xPONum',
			'xComments',
		];

		const principal = principalService.get();
		const isGoPlus = get(principal, 'idInfo.xIsGoPlusAccount', false);
		this.transactionKeys = isGoPlus ? [...this.transactionKeys, 'xProcessing'] : this.transactionKeys;

		this.billingKeys = [
			'xName',
			'xBillFirstName',
			'xBillMiddleName',
			'xBillLastName',
			'xBillCompany',
			'xEmail',
			'xBillPhone',
			'xBillMobile',
			'xBillStreet',
			'xBillStreet2',
			'xBillCity',
			'xBillState',
			'xBillZip',
			'xBillCountry',
			'xStreet',
			'xZip',
			'xWebsite',
		];

		this.shippingKeys = [
			'xShipFirstName',
			'xShipMiddleName',
			'xShipLastName',
			'xShipCompany',
			'xShipEmail',
			'xShipPhone',
			'xShipMobile',
			'xShipStreet',
			'xShipStreet2',
			'xShipCity',
			'xShipState',
			'xShipZip',
			'xShipCountry',
		];

		this.orderCustomkeys = [
			'xOrderID',
			'xOrderType',
			'xOrderDate',
			'xSubtotal',
			'xTip',
			'xTax',
			'xCoupon',
			'xShipMethod',
			'xShipAmount',
			'xScheduleID',
			'xCustNumber',
			'xCustID',
			'xCustomerID',
			'xExistingCustomer',
		];

		this.customKeys = range(1, 21).map(i => `xCustom${i.toString().padStart(2, '0')}`);

		this.otherKeys = [
			'xCVMResult',
			'xGatewayAVS',
			'xGatewayError',
			'xGatewayRefNum',
			'xGatewayResult',
			'xMerchantID',
			'xStatusReason',
		];

		this.fieldKeysMapping = {
			allBilling: this.billingKeys,
			allShipping: this.shippingKeys,
			allTransaction: this.transactionKeys,
			allOrderCustomer: this.orderCustomkeys,
			allCustom: this.customKeys,
			allOther: this.otherKeys,
		};

		this.requiredFields = [
			'xRefnum',
			'xAmount',
			'xEnteredDate',
			'xMaskedCardNumber',
			'xRequestAmount',
			'xResponseResult',
			'xReviewed',
			'xToken',
		];
		const allFields = concat(
			this.billingKeys,
			this.shippingKeys,
			this.transactionKeys,
			this.orderCustomkeys,
			this.customKeys,
			this.otherKeys
		);
		this.optionalFields = difference(allFields, this.requiredFields);

		this.state = {
			webhookSettings: {
				webhookUrl: 'https://',
				pin: '',
				optionalFields: transform(
					this.optionalFields,
					(result, field) => {
						result[field] = false;
					},
					{}
				),
			},
			disableSave: true,
		};

		this.notificationRef = createRef();
	}

	async componentDidMount() {
		this.props.showLoader(true);
		try {
			const result = await this.props.makePendingRequest(settingService.loadSettings('IPN'));
			const optionalFields = transform(
				filter(split(result.cardknoxSettings.IPN_CustomTransactionFields, ','), x => !this.requiredFields.includes(x)),
				(result, field) => {
					result[field] = true;
				},
				{ ...this.state.optionalFields }
			);

			const allOther = this.checkAllFieldsToggled(this.otherKeys, optionalFields);
			const allCustom = this.checkAllFieldsToggled(this.customKeys, optionalFields);
			const allOrderCustomer = this.checkAllFieldsToggled(this.orderCustomkeys, optionalFields);
			const allShipping = this.checkAllFieldsToggled(this.shippingKeys, optionalFields);
			const allBilling = this.checkAllFieldsToggled(this.billingKeys, optionalFields);
			const allTransaction = this.checkAllFieldsToggled(this.transactionKeys, optionalFields);

			this.setState({
				webhookSettings: {
					webhookUrl: result.cardknoxSettings.IPN_URL,
					pin: result.cardknoxSettings.IPN_Pin,
					optionalFields,
				},
				disableSave: false,
				allOther,
				allCustom,
				allOrderCustomer,
				allShipping,
				allBilling,
				allTransaction,
			});
		} catch (e) {
			this.props.handleError(e);
		}
		this.props.showLoader(false);
	}
	checkAllFieldsToggled = (keys, optionalFields) => {
		return every(keys, key => optionalFields[key] === true);
	};
	handleChange = ({ target: { name, value } }) => {
		this.props.handleBlockChange(true);
		this.setState({ webhookSettings: { ...this.state.webhookSettings, [name]: value } });
	};
	handleSelectOptionalField = field => {
		this.props.handleBlockChange(true);

		const updatedOptionalFields = {
			...this.state.webhookSettings.optionalFields,
			[field]: !this.state.webhookSettings.optionalFields[field],
		};

		this.setState(
			{
				webhookSettings: {
					...this.state.webhookSettings,
					optionalFields: updatedOptionalFields,
				},
			},
			() => {
				// ensure that the "all" fields checkbox is checked if all fields are selected
				const fieldCategory = findKey(this.fieldKeysMapping, fields => fields.includes(field));

				if (fieldCategory) {
					const allFieldsChecked = this.checkAllFieldsToggled(
						this.fieldKeysMapping[fieldCategory],
						this.state.webhookSettings.optionalFields
					);
					this.setState({ [fieldCategory]: allFieldsChecked });
				}
			}
		);
	};

	toggleAllFields = ({ target: { name, checked } }) => {
		const webhookSettings = cloneDeep(this.state.webhookSettings);
		const fields = this.fieldKeysMapping[name];

		if (fields) {
			forEach(fields, field => {
				set(webhookSettings, `optionalFields.${field}`, checked);
			});

			this.setState({
				[name]: checked,
				webhookSettings,
			});
		}

		this.props.handleBlockChange(true);
	};
	updateData = async setToDefault => {
		this.props.showLoader(true);
		try {
			let transactionFields = setToDefault
				? this.requiredFields
				: [
						...this.requiredFields,
						...filter(this.optionalFields, field => this.state.webhookSettings.optionalFields[field]),
				  ];
			if (this.state.webhookSettings.pin !== '') transactionFields = [...transactionFields, 'xSignature'];
			const joinedFields = join(transactionFields, ',');
			const result = await this.props.makePendingRequest(
				settingService.updateSettings('IPN', {
					IPN_URL: setToDefault ? '' : this.state.webhookSettings.webhookUrl,
					IPN_Pin: setToDefault ? '' : this.state.webhookSettings.pin,
					IPN_CustomTransactionFields: joinedFields,
					IPN_CustomAdjustFields: joinedFields,
				})
			);
			this.props.handleBlockChange(false);
			this.notificationRef.current.addNotification({
				message: setToDefault ? 'Settings reset successfully' : 'Settings saved successfully',
				success: true,
				ref: result.refNum,
			});
		} catch (e) {
			this.props.handleError(e);
		}
		this.props.showLoader(false);
	};

	save = async () => {
		await this.updateData(false);
	};

	renderSaveButton() {
		return (
			<Fragment>
				<span className="settings__header__action__text">
					Settings are saved only after clicking on <strong>Save</strong> button
				</span>
				<button
					className="btn btn--med btn--primary spc--bottom--sml"
					disabled={this.props.isLoading || this.state.disableSave}
					onClick={this.save}
				>
					Save
				</button>
			</Fragment>
		);
	}

	renderWebhookSettingsTable = () => {
		const maxLength = Math.max(
			this.billingKeys.length,
			this.shippingKeys.length,
			this.transactionKeys.length,
			this.orderCustomkeys.length,
			this.customKeys.length,
			this.otherKeys.length
		);
		const keys = [
			this.billingKeys,
			this.shippingKeys,
			this.transactionKeys,
			this.orderCustomkeys,
			this.customKeys,
			this.otherKeys,
		];

		const rows = range(maxLength).map(i => (
			<tr key={i}>
				{map(keys, (keyArray, j) => {
					const field = get(keyArray, i, '');
					return (
						<td key={j}>
							{field && (
								<div key={field} className="display--ib">
									<input
										className="input--check"
										type="checkbox"
										name={field}
										id={`webhookSettings.optionalFields.${field}`}
										checked={this.state.webhookSettings.optionalFields[field] || false}
										onChange={() => this.handleSelectOptionalField(field)}
										disabled={this.props.isLoading}
									/>
									<label htmlFor={`webhookSettings.optionalFields.${field}`}>{field}</label>
								</div>
							)}
						</td>
					);
				})}
			</tr>
		));

		return (
			<table className="table table--secondary table--secondary--alt">
				<thead>
					<tr>
						<th className="sticky">
							<div className="flex--primary flex--nowrap">
								<div className="display--ib spc--right--sml">
									<input
										className="input--check input--check--no-label"
										type="checkbox"
										name="allBilling"
										id="allBilling"
										checked={this.state.allBilling}
										onChange={this.toggleAllFields}
										disabled={this.props.isLoading}
									/>
									<label htmlFor="allBilling"></label>
								</div>
								<label>Billing Fields</label>
							</div>
						</th>
						<th className="sticky">
							<div className="flex--primary flex--nowrap">
								<div className="display--ib spc--right--sml">
									<input
										className="input--check input--check--no-label"
										type="checkbox"
										name="allShipping"
										id="allShipping"
										checked={this.state.allShipping}
										onChange={this.toggleAllFields}
										disabled={this.props.isLoading}
									/>
									<label htmlFor="allShipping"></label>
								</div>
								<label>Shipping Fields</label>
							</div>
						</th>
						<th className="sticky">
							<div className="flex--primary flex--nowrap">
								<div className="display--ib spc--right--sml">
									<input
										className="input--check input--check--no-label"
										type="checkbox"
										name="allTransaction"
										id="allTransaction"
										checked={this.state.allTransaction}
										onChange={this.toggleAllFields}
										disabled={this.props.isLoading}
									/>
									<label htmlFor="allTransaction"></label>
								</div>
								<label>Transaction Fields</label>
							</div>
						</th>
						<th className="sticky">
							<div className="flex--primary flex--nowrap">
								<div className="display--ib spc--right--sml">
									<input
										className="input--check input--check--no-label"
										type="checkbox"
										name="allOrderCustomer"
										id="allOrderCustomer"
										checked={this.state.allOrderCustomer}
										onChange={this.toggleAllFields}
										disabled={this.props.isLoading}
									/>
									<label htmlFor="allOrderCustomer"></label>
								</div>
								<label>Order/Customer Details</label>
							</div>
						</th>
						<th className="sticky">
							<div className="flex--primary flex--nowrap">
								<div className="display--ib spc--right--sml">
									<input
										className="input--check input--check--no-label"
										type="checkbox"
										name="allCustom"
										id="allCustom"
										checked={this.state.allCustom}
										onChange={this.toggleAllFields}
										disabled={this.props.isLoading}
									/>
									<label htmlFor="allCustom"></label>
								</div>
								<label>Custom Fields</label>
							</div>
						</th>
						<th className="sticky">
							<div className="flex--primary flex--nowrap">
								<div className="display--ib spc--right--sml">
									<input
										className="input--check input--check--no-label"
										type="checkbox"
										name="allOther"
										id="allOther"
										checked={this.state.allOther}
										onChange={this.toggleAllFields}
										disabled={this.props.isLoading}
									/>
									<label htmlFor="allOther"></label>
								</div>
								<label>Other Fields</label>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</table>
		);
	};
	render = () => {
		return (
			<div className="settings--main settings--main--alt">
				<Notification ref={this.notificationRef} />
				<div className="settings__header">
					<div className="settings__header__title">Webhook Settings</div>
					<div className="settings__header__action">{this.renderSaveButton()}</div>
				</div>

				<div className="spr__card clearfix">
					<div className="spr__heading is-preview">
						<div className="flex--primary datatooltip--w--140">
							<h4 className="spr__heading__title">Configure Webhook</h4>
							<div data-tooltip="Configure webhook for your Cardknox account" className="display--f spc--left--tny">
								<i className="icon icon--tiny icon--info cursor--pointer"></i>
							</div>
						</div>
					</div>
					<div className="spr__content">
						<div className="spc--bottom--med">
							<label htmlFor="webhookSettings.webhookUrl" className="label">
								Postback URL
							</label>
							<p className="type--color--text">Enter the URL to receive webhook notifications</p>
							<input
								type="text"
								id="webhookSettings.webhookUrl"
								name="webhookUrl"
								onChange={this.handleChange}
								value={this.state.webhookSettings.webhookUrl}
								disabled={this.props.isLoading}
								className="input input--med"
							/>
						</div>
						<div className="spc--bottom--med">
							<label htmlFor="webhookSettings.pin" className="label">
								PIN
							</label>
							<p className="type--color--text">
								Adding a PIN allows for verification that the webhook originated from Cardknox. For more info, refer to
								our docs{' '}
								<a
									className="anchor anchor--primary type--underline"
									href="https://docs.cardknox.com/cardknox-products/webhooks#webhook-pin"
								>
									here
								</a>
								.
							</p>
							<input
								onChange={this.handleChange}
								value={this.state.webhookSettings.pin}
								type="text"
								id="webhookSettings.pin"
								name="pin"
								disabled={this.props.isLoading}
								className="input input--med"
							/>
						</div>
						<div className="spc--bottom--sml--alt">
							<div className="spc--bottom--sml--alt">
								<label className="label">Optional Fields</label>
								<p className="type--color--text">
									For a list of fields that are returned by default, see{' '}
									<a
										className="anchor anchor--primary type--underline"
										href="https://docs.cardknox.com/cardknox-products/webhooks#webhook-pin"
									>
										here
									</a>
									. Select additional fields to be included.
								</p>
							</div>
							<div>
								<div className="table--secondary__wrapper table--secondary__wrapper--webhook">
									{this.renderWebhookSettingsTable()}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
}

WebhookSettings.propTypes = {
	handleError: PropTypes.func.isRequired,
	handleBlockChange: PropTypes.func.isRequired,
	makePendingRequest: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	showLoader: PropTypes.func.isRequired,
};

export default withLoader(withError(withCancelable(withBlock(WebhookSettings))));
