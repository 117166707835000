import React, { Fragment } from 'react';
import { invokeIfFunction } from 'common/utilities';
import { map } from 'lodash';

const AddNewSectionActions = ({ actions, toggledSections, label }) => {
	return map(actions, ({ key: actionKey, onChange, disabled }) => {
		const isChecked = toggledSections[actionKey];
		return (
			<Fragment key={actionKey}>
				<input
					checked={isChecked}
					id={actionKey}
					className="input--check input--check--sml"
					name={actionKey}
					type="checkbox"
					onChange={onChange}
					disabled={invokeIfFunction(disabled)}
				/>
				<label htmlFor={actionKey}>{label}</label>
			</Fragment>
		);
	});
};

export default AddNewSectionActions;
