import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { authenticationService } from 'common/services';

class LogoutComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			resolved: false,
		};
	}

	async componentDidMount() {
		const { resolved } = this.state;
		const { history } = this.props;

		let isInactive = false;
		if (this.props && this.props.location && this.props.location.state && this.props.location.state.inactivity) {
			isInactive = this.props.location.state.inactivity;
		}

		await authenticationService.logout();
		if (!resolved) {
			this.setState(
				{
					resolved: true,
				},
				() => {
					history.push({
						pathname: '/login',
						state: { inactivity: isInactive },
					});
				}
			);
		}
	}

	render() {
		return <React.Fragment></React.Fragment>;
	}
}

LogoutComponent.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
};

export default LogoutComponent;
