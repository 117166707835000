import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { findIndex, cloneDeep, every, map, some, get, find, includes, filter, toLower, isEmpty, replace } from 'lodash';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';

import { isComponent, uniqueId } from '../../utilities';
import { Tour } from '../tour';

const tourConfig = {
	version: 1, // increase this every time you make changes to the tourConfig,
	key: 'avsField',
	steps: [
		{
			selector: '.avsField',
			content: 'AVS Street and AVS Zip can now be set to visible on Transaction Reports.',
		},
	],
};

class ColumnFilterComponent extends Component {
	state = {
		hideTooltip: false,
		searchTerm: '',
	};
	idPrefix = uniqueId();
	get columns() {
		const { searchTerm } = this.state;
		const cols = this.props.state.columns.filter(c => c.hideable === true);
		if (searchTerm) {
			return this.getFilteredColumns(searchTerm, cols);
		}
		return cols;
	}

	componentDidUpdate = prevProps => {
		if (
			some(prevProps.columns, ({ key, visible, hideable }) => {
				const col = find(this.props.columns, { key });
				return get(col, 'visible') !== visible || get(col, 'hideable') !== hideable;
			})
		) {
			this.props.updateState({
				columns: cloneDeep(this.props.columns),
			});
		}
	};

	getFilteredColumns = (searchTerm, cols) => {
		return filter(cols, col => {
			return includes(toLower(replace(col.name, /\u00AD/g, '')), toLower(searchTerm));
		});
	};

	showHideColumns = (columns, value) => {
		return map(columns, col => {
			if (col.hideable) {
				col.visible = value;
			}
			return col;
		});
	};

	handleAllColumnsChange = (key, columns, value) => {
		const { searchTerm } = this.state;

		if (key === 'allColumns' && searchTerm) {
			const filteredColumns = this.getFilteredColumns(searchTerm, columns);
			return this.showHideColumns(filteredColumns, value);
		} else {
			return this.showHideColumns(columns, value);
		}
	};

	onChange = e => {
		// filter entire list, not just the columns that are hideable
		const columns = [...this.props.state.columns];
		const key = e.target.name;
		const value = e.target.checked;
		if (key === 'allColumns') {
			this.handleAllColumnsChange(key, columns, value);
		} else {
			const idx = findIndex(columns, { key: key });
			columns[idx] = {
				...columns[idx],
				visible: value,
			};
		}

		this.props.updateState({ columns });
	};

	closeDropdown = () => {
		this.props.updateState({ activeKeys: [] });
	};

	onApply = (...props) => {
		this.props.filteredColumns(this.props.state.columns, ...props);

		this.closeDropdown();
	};

	onReset = () => {
		this.setState({ searchTerm: '' });
		this.props.updateState({ columns: cloneDeep(this.props.defaultColumns), activeKeys: [] });
		this.props.filteredColumns(cloneDeep(this.props.defaultColumns));
	};

	onColumnSearch = ({ target: { value } }) => {
		this.setState({ searchTerm: value });
	};

	renderColumnSelector = () => {
		const availableCols = this.columns;

		const selectAllValue = every(map(availableCols, i => i.visible), i => i === true);
		const selectAllLabel = selectAllValue ? 'Unselect all' : 'Select all';
		return (
			<React.Fragment>
				<div className="rc-menu-search">
					<input
						type="text"
						name="columnFilter"
						className="input input--sml input--search"
						placeholder="Search columns"
						id={`${this.idPrefix}search`}
						value={this.state.searchTerm || ''}
						onChange={this.onColumnSearch}
					/>
				</div>
				{isEmpty(availableCols) && <p className="spc--top--tny type--wgt--bold type--color--text--light">No Results</p>}

				<div className="rc-menu-list">
					{this.renderColumns(availableCols, selectAllLabel, selectAllValue)}
					<Tour tourConfig={tourConfig} />
				</div>
			</React.Fragment>
		);
	};

	onOpenChange = activeKeys => {
		this.props.updateState({ activeKeys: activeKeys });
	};
	renderColumns = (availableCols, selectAllLabel, selectAllValue) => {
		if (isEmpty(availableCols)) return;
		return (
			<Fragment>
				<div className="rc-menu-list-item">
					<input
						type="checkbox"
						name="allColumns"
						className="input input--check"
						checked={selectAllValue}
						id={`${this.idPrefix}allColumns`}
						onChange={this.onChange}
					/>
					<label htmlFor={`${this.idPrefix}allColumns`}>{selectAllLabel}</label>
				</div>
				{availableCols.map((col, i) => {
					return (
						<div key={i} className={'rc-menu-list-item'} id={col.key}>
							<div className={`${col.key === 'xStreet' || col.key === 'xZip' ? ' avsField' : ''}`}>
								<input
									type="checkbox"
									name={col.key}
									className="input input--check"
									checked={col.visible}
									id={`column-${col.key}${this.idPrefix}`}
									onChange={this.onChange}
								/>
								<label htmlFor={`column-${col.key}${this.idPrefix}`}>{col.name}</label>
							</div>
						</div>
					);
				})}
			</Fragment>
		);
	};

	render() {
		const { activeKeys, columns } = this.props.state;
		const {
			header: HeaderComponent,
			footer: FooterComponent,
			isDisabled,
			type,
			hideSaveSelection,
			hideSettings,
			kvaasResourceType,
		} = this.props;
		const tooltipInfo = this.state.hideTooltip ? null : 'Settings';
		return (
			<div className="filter__settings">
				<Menu
					mode={'horizontal'}
					openAnimation={'slide-up'}
					triggerSubMenuAction={'click'}
					onOpenChange={this.onOpenChange}
					openKeys={activeKeys}
				>
					<SubMenu disabled={isDisabled} key="columns">
						<MenuItem disabled>
							<HeaderComponent type={type} hideSettings={hideSettings} />
							{this.renderColumnSelector()}
						</MenuItem>
						<MenuItem disabled className="rc-menu-abs-footer">
							<div className="rc-menu-footer">
								<FooterComponent
									onReset={this.onReset}
									onApply={this.onApply}
									type={type}
									kvaasResourceType={kvaasResourceType}
									columns={columns}
									hasNoColumns={isEmpty(this.columns)}
									hideSaveSelection={hideSaveSelection}
								/>
							</div>
						</MenuItem>
					</SubMenu>
				</Menu>
				<div data-tooltip={tooltipInfo}></div>
			</div>
		);
	}
}

ColumnFilterComponent.propTypes = {
	defaultColumns: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			visible: PropTypes.bool.isRequired,
			hideable: PropTypes.bool.isRequired,
		})
	),
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			visible: PropTypes.bool.isRequired,
			hideable: PropTypes.bool.isRequired,
		})
	),
	filteredColumns: PropTypes.func.isRequired,
	header: isComponent,
	footer: isComponent,
	isDisabled: PropTypes.bool,
	type: PropTypes.string.isRequired,
	kvaasResourceType: PropTypes.string.isRequired,
	state: PropTypes.shape({
		columns: PropTypes.array,
		activeKeys: PropTypes.array,
	}).isRequired,
	updateState: PropTypes.func.isRequired,
	hideSettings: PropTypes.any,
	hideSaveSelection: PropTypes.any,
};

export default ColumnFilterComponent;
