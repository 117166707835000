import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { invokeIfFunction } from 'common/utilities';

class AddOn extends Component {
	constructor(props) {
		super(props);
		this.state = { activating: false };
	}

	render() {
		const { onBeforeActivate } = this.props;
		const {
			title,
			details,
			templateId,
			path,
			successMessage,
			question,
			activateLabel,
			additionalContent,
		} = this.props.data;
		return (
			<div className="add-ons__card">
				<div className="add-ons__card__body">
					<a href="/" className="add-ons__card__logo" />
					<div>
						<h2 className="add-ons__card__title">{title}</h2>
						<p className="add-ons__card__paragraph">{invokeIfFunction(details)}</p>
					</div>
				</div>
				<div className="add-ons__card__footer">
					<button onClick={() => this.props.history.push(`features/${path}`)} className="btn btn--sml btn--anchor">
						Show Details
					</button>
					<button
						onClick={() => onBeforeActivate(templateId, successMessage, path, question, additionalContent)}
						className="btn btn--sml btn--ghost"
					>
						{activateLabel || 'Activate'}
					</button>
				</div>
			</div>
		);
	}
}

AddOn.propTypes = {
	data: PropTypes.object.isRequired,
	makePendingRequest: PropTypes.func,
	history: PropTypes.object,
	onBeforeActivate: PropTypes.func,
};

export default AddOn;
