import React from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';

const menuList = ({ options, children, maxHeight, getValue, itemSize = 35 }) => {
	const [value] = getValue();
	const itemCount = children.length || 0;
	const height = Math.min(maxHeight, (itemSize + 1) * (itemCount || 1) + 1);
	const initialScrollOffset = height < maxHeight ? 0 : options.indexOf(value) * itemSize;

	return (
		<List height={height} itemCount={itemCount} itemSize={itemSize} initialScrollOffset={initialScrollOffset}>
			{({ index, style }) => <div style={style}>{children[index]}</div>}
		</List>
	);
};

menuList.propTypes = {
	options: PropTypes.any,
	children: PropTypes.any,
	maxHeight: PropTypes.number,
	getValue: PropTypes.func,
	itemSize: PropTypes.number,
};

export default menuList;
