import React, { Fragment } from 'react';
import { split, toLower, includes, get } from 'lodash';
import { object, func, bool } from 'prop-types';

import TooltipFormatterComponent from '../columns/formatters/TooltipFormatter';
import { featurePackageTooltips, hasFeaturePackage, featurePackages } from 'common/utilities/has-feature-package';
import { modalNames } from './modal-names';
import { withError } from '../error';
import { withCancelable } from '../cancelable';
import { principalService, transactionService } from 'common/services';
import { withForwardRef } from '../with-forward-ref';
import { withRouter } from 'react-router-dom';
import LinkTransaction from './LinkTransaction';
import sectionKeys from 'routing/sections';
import RenderLinkPaymentMethodOrCustomer from 'components/transaction-grid/components/RenderLinkPaymentCustomer';

const requestKeys = {
	DATA: 'data',
};

class TransactionRowActions extends TooltipFormatterComponent {
	constructor(props) {
		super(props, TransactionRowActions.instances);
		this.principal = principalService.get();
	}

	static instances = [];

	get hasTerminalOnly() {
		return hasFeaturePackage(featurePackages.terminalOnly);
	}

	get permissions() {
		return get(this.principal, 'idInfo.permissions', {});
	}

	get tooltip() {
		return this.props.action.tooltip;
	}

	get isRefund() {
		return this.props.row.xCommand.toLowerCase().split(':')[1] === 'refund';
	}

	get isApproved() {
		return toLower(this.props.row.xResponseResult) === 'approved';
	}

	get isVoid() {
		return this.isApproved && this.props.row.xVoid === '1';
	}

	get isGrant() {
		return includes(toLower(this.props.row.xCommand), 'grant');
	}

	get canAdjust() {
		const { row } = this.props;
		const [cardType] = split(toLower(row.xCommand), ':');
		return (
			!this.isGrant && this.isApproved && !this.isVoid && cardType !== 'gift' && toLower(row.xCommand) !== 'cash:sale'
		);
	}

	get isAuthOnly() {
		return toLower(this.props.row.xCommand).indexOf('authonly') !== -1;
	}

	redirectToUpgradePlan = () => {
		this.props.history.push({ pathname: '/terminal-only' });
	};

	getTransactionExtended = async () => {
		const {
			row: { xRefNum, xMerchantId },
			handleError,
		} = this.props;
		try {
			return await transactionService.getTransactionExtended(xRefNum, xMerchantId);
		} catch (e) {
			handleError(e);
		}
	};

	closeSplitCapturedAuthOnly = async () => {
		const {
			row: { xRefNum, xMerchantId },
			handleError,
			makePendingRequest,
		} = this.props;
		try {
			return await makePendingRequest(
				transactionService.closeSplitCapturedAuthOnly(xRefNum, xMerchantId),
				requestKeys.DATA
			);
		} catch (e) {
			handleError(e);
		}
	};

	openCaptureModal = async () => {
		try {
			const { autoPartialAuthReversal, row, splitCaptureEnabled, showLoader } = this.props;
			let transactionStatus = {};

			if (splitCaptureEnabled) {
				showLoader(true);
				transactionStatus = await this.getTransactionExtended();
				showLoader(false);
			}

			this.openModal(modalNames.capture, {
				autoPartialAuthReversal,
				row,
				refreshGridData: this.props.refreshGridData,
				transactionStatus,
				splitCaptureEnabled,
			});
		} catch (e) {
			this.props.showLoader(false);
			this.props.handleError(e);
		}
	};

	openModal = (modalName, data = {}) => {
		this.props.openModal({
			name: modalName,
			data: data,
		});
	};

	openNewTransaction = async () => {
		const { showLoader, refreshGridData, row } = this.props;

		showLoader(true);
		const { data: existingTransaction } = await this.getTransactionExtended();
		this.openModal(modalNames.newTransaction, {
			existingTransaction,
			refreshGridData: refreshGridData,
			xMerchantId: row.xMerchantId,
		});
		showLoader(false);
	};

	openLinkTransactionPopup = async linkPaymentMethod => {
		const { refreshGridData, openModal, showLoader, makePendingRequest, handleError } = this.props;
		this.props.showLoader(true);
		const { data: row } = await this.getTransactionExtended();
		this.openModal(
			{
				component: LinkTransaction,
				props: {
					shouldCloseOnOverlayClick: false,
				},
			},
			{
				row,
				refreshGridData,
				openModal,
				showLoader,
				makePendingRequest,
				handleError,
				xMerchantId: this.props.row.xMerchantId,
				linkPaymentMethod: linkPaymentMethod,
				newCustomer: !linkPaymentMethod, // for better readability
			}
		);
		this.props.showLoader(false);
	};

	renderCaptureButton = () => {
		const { allowCcCapture } = this.permissions;
		const { row, splitCaptureEnabled } = this.props;

		const canSplitCapture = get(row, 'xIsSplitCapturable', '0') === '1';
		const isSplitCaptured = get(row, 'xClearedCount') > 0;
		const splitCapturedAndSettingDisabled = canSplitCapture && isSplitCaptured && !splitCaptureEnabled;
		const disabled = this.isVoid || !allowCcCapture || this.hasTerminalOnly || splitCapturedAndSettingDisabled;
		let component = null;
		let tooltip = 'Capture';
		if (this.hasTerminalOnly) {
			tooltip = featurePackageTooltips.hasTerminalOnly;
		} else if (!allowCcCapture) {
			tooltip = 'Permission required';
		} else if (this.isVoid) {
			tooltip = "The transaction can't be captured";
		} else if (splitCapturedAndSettingDisabled) {
			tooltip = 'Transaction was split captured and can no longer be captured. Enable split capture to continue';
		}

		if (this.isAuthOnly && this.isApproved) {
			component = (
				<td
					className={splitCapturedAndSettingDisabled ? 'datatooltip--w--200 datatooltip--right' : ''}
					data-tooltip={tooltip}
				>
					<button
						type="button"
						className="btn btn--anchor btn--sml"
						disabled={disabled}
						onClick={this.openCaptureModal}
					>
						<i className="icon icon--xsml icon--capture"></i>
					</button>
				</td>
			);
		}

		return component;
	};

	renderCloseSplitCapturedAuthOnlyButton = () => {
		const { row } = this.props;
		const isSplitCaptured = get(row, 'xClearedCount') > 0;

		return (
			isSplitCaptured && (
				<td className="datatooltip--right">
					<button
						type="button"
						data-tooltip="Close Split Capture"
						className="btn btn--clear btn--sml grid__expansion__btn"
						onClick={() =>
							this.openModal(modalNames.confirmAction, {
								loadingMessage: 'Closing Split Capture',
								question: 'This action cannot be undone. Are you sure you want to close Split Capture?',
								onConfirm: () => this.closeSplitCapturedAuthOnly(row),
								notificationHandler: rsp => ({
									message: 'Closed Split Capture successfully',
									success: toLower(get(rsp, 'xStatus')) === 'approved',
									ref: get(rsp, 'xRefNum'),
									onClose: this.props.refreshGridData,
								}),
							})
						}
					>
						<i className="icon icon--sml icon--void icon--middle"></i>
					</button>
				</td>
			)
		);
	};

	renderAdjustButton = () => {
		const {
			currency,
			row: {
				xRefNum: refNum,
				xCommand: type,
				xAmount: amount,
				xTip: tip,
				xDescription: description,
				xOrderID: orderId,
				xCustom01: custom1,
				xCustom02: custom2,
				xCustom03: custom3,
				xRefnumCurrent,
				xResponseResult,
				xMerchantId,
			},
			refreshGridData,
			handleError,
			customDisplayLabels,
		} = this.props;
		const { allowCcAdjust, allowCheckAdjust } = this.permissions;

		let component = null;
		const paymentType = split(toLower(type), ':')[0];
		const allowAdjust = paymentType === 'cc' ? allowCcAdjust : allowCheckAdjust;

		if (this.isApproved || toLower(xResponseResult) === 'declined') {
			if (this.canAdjust) {
				component = (
					<td
						data-tooltip={
							this.hasTerminalOnly
								? featurePackageTooltips.hasTerminalOnly
								: !allowAdjust
								? 'Permission required'
								: 'Adjust'
						}
					>
						<button
							type="button"
							className="btn btn--anchor btn--sml"
							disabled={this.hasTerminalOnly || !allowAdjust}
							onClick={() =>
								this.openModal(modalNames.adjust, {
									refNum,
									type,
									amount,
									tip,
									description,
									orderId,
									custom1,
									custom2,
									custom3,
									currency,
									refreshGridData,
									handleError,
									xRefnumCurrent,
									xMerchantId,
									customDisplayLabels,
								})
							}
						>
							<i className="icon icon--xsml icon--edit"></i>
						</button>
					</td>
				);
			} else {
				component = (
					<td className="datatooltip--right">
						<div data-tooltip="The transaction can't be adjusted">
							<button type="button" disabled={true} className="btn btn--anchor btn--sml">
								<i className="icon icon--xsml icon--edit"></i>
							</button>
						</div>
					</td>
				);
			}
		}

		return <Fragment>{component}</Fragment>;
	};

	renderNewTransactionButton = () => {
		const {
			row: { xResponseResult, xCommand, xToken },
		} = this.props;
		const isErroredToken = xResponseResult === 'Error' && xToken;
		const principal = principalService.get();
		const canProcessNewTransaction = principal.hasAccess[sectionKeys.newTransaction];
		const commandType = toLower(xCommand);
		const isSplitCapture = commandType === 'cc:splitcapture';
		const isCashSale = commandType === 'cash:sale';
		const isEbt = includes(toLower(xCommand), 'ebt');
		const isCrypto = includes(toLower(xCommand), 'crypto');
		const tooltip = 'Add new transaction';
		const disabled =
			this.isRefund ||
			!canProcessNewTransaction ||
			isEbt ||
			isSplitCapture ||
			isCrypto ||
			isCashSale ||
			!canProcessNewTransaction;
		if (disabled) return null;
		if (this.isApproved || toLower(xResponseResult) === 'declined' || isErroredToken) {
			return (
				<td data-tooltip={tooltip}>
					<button
						type="button"
						className="btn btn--anchor btn--sml"
						disabled={disabled}
						onClick={() => {
							if (this.hasTerminalOnly) {
								this.redirectToUpgradePlan();
							} else {
								this.openNewTransaction();
							}
						}}
					>
						<i className="icon icon--xsml icon--newtransaction"></i>
					</button>
				</td>
			);
		}
	};

	render() {
		return (
			<tr>
				{this.renderNewTransactionButton()}
				{
					<RenderLinkPaymentMethodOrCustomer
						row={this.props.row}
						openLinkTransactionPopup={this.openLinkTransactionPopup}
						principal={this.principal}
					/>
				}
				{
					<RenderLinkPaymentMethodOrCustomer
						row={this.props.row}
						openLinkTransactionPopup={this.openLinkTransactionPopup}
						isLinkPaymentMethod={true}
						principal={this.principal}
					/>
				}
				{this.renderCaptureButton()}
				{this.renderAdjustButton()}
				{this.renderCloseSplitCapturedAuthOnlyButton()}
			</tr>
		);
	}
}

TransactionRowActions.defaultProps = {
	autoPartialAuthReversal: false,
	splitCaptureEnabled: false,
};

TransactionRowActions.propTypes = {
	row: object.isRequired,
	openModal: func.isRequired,
	refreshGridData: func.isRequired,
	showLoader: func.isRequired,
	history: object.isRequired,
	makePendingRequest: func.isRequired,
	handleError: func.isRequired,
	autoPartialAuthReversal: bool,
	splitCaptureEnabled: bool,
	customDisplayLabels: object,
};

export default withError(withCancelable(withForwardRef(TransactionRowActions, withRouter)));
