import React, { Component, Fragment } from 'react';
import { node, PropTypes } from 'prop-types';

import principalService from '../services/principalService';

class Authorize extends Component {
	constructor(props) {
		super(props);

		const principal = principalService.get();
		this.state = {
			isAuthorized: this.authorize(principal),
		};
	}

	componentDidMount() {
		this.subscription = principalService.subscribe(principal => {
			this.setState({
				isAuthorized: this.authorize(principal),
			});
		});
	}

	componentWillUnmount() {
		this.subscription.unsubscribe();
	}

	authorize = principal => {
		const { section } = this.props;
		return !section || (principal && principal.hasAccess && !!principal.hasAccess[section]);
	};

	render() {
		const { isAuthorized } = this.state;
		const { children } = this.props;
		return isAuthorized ? <Fragment>{children}</Fragment> : null;
	}
}

Authorize.propTypes = {
	children: node.isRequired,
	section: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

export default Authorize;
