import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'common/components/modal';
import { invokeIfFunction } from 'common/utilities';

class AdvancedAddOn extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { feature, onBeforeActivate, isLoading } = this.props;
		const {
			templateId,
			successMessage,
			path,
			question,
			activateLabel,
			additionalContent,
			advancedDetails: { title, subtitle, details, learnMoreLink },
		} = feature;
		return (
			<Fragment>
				<Modal
					isOpen={true}
					onClose={() => this.props.history.push('/features')}
					shouldCloseOnOverlayClick={false}
					className="popup__content"
				>
					{isLoading ? null : (
						<div className="popup add-ons__popup">
							<div className="add-ons__popup__header">
								<a href="/" className="add-ons__popup__header__logo" />
								<label className="add-ons__popup__header__title">{title}</label>
							</div>

							<div className="add-ons__popup__body">
								<div className="add-ons__popup__body__title">{subtitle}</div>
								<p className="add-ons__popup__body__paragraph">{invokeIfFunction(details)}</p>
							</div>

							<div className="add-ons__popup__footer">
								<button
									onClick={() =>
										onBeforeActivate(templateId, successMessage, path, question, additionalContent)
									}
									className="btn btn--med btn--primary"
								>
									{activateLabel || 'Activate'}
								</button>
								<button
									className="btn btn--med btn--ghost"
									onClick={() => {
										window.open(learnMoreLink, '_blank');
									}}
								>
									Learn More
								</button>
							</div>
						</div>
					)}
				</Modal>
			</Fragment>
		);
	}
}

AdvancedAddOn.propTypes = {
	feature: PropTypes.object.isRequired,
	onBeforeActivate: PropTypes.func.isRequired,
	history: PropTypes.object,
	isLoading: PropTypes.bool,
};

export default AdvancedAddOn;
