import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const { cardExpiryDateFormat } = ApplicationSettings;
const currentDate = moment();
const aboutToExpireUpperLimit = moment(currentDate).add(1, 'M');

const PaymentMethodExpiry = props => {
	const {
		value,
		row: { paymentMethodDetails, paymentMethod },
	} = props;
	const rawExpiry = !paymentMethodDetails ? '' : paymentMethodDetails.exp;
	const maskedCard = !paymentMethodDetails
		? ''
		: Array.from(paymentMethodDetails.maskedNumber)
				.splice(-4, 4)
				.join('');

	const exp = moment(rawExpiry, cardExpiryDateFormat, true);
	const isExpired = !rawExpiry ? false : exp.isValid() && exp.endOf('month').isBefore(currentDate);

	const isAboutToExpire = !rawExpiry
		? false
		: exp.isValid() &&
		  exp.endOf('month').isBetween(currentDate, aboutToExpireUpperLimit.endOf('month'), undefined, '[]');

	return (
		<div className="display--f f--a--c">
			<div className="spc--right--tny">
				{paymentMethod} {maskedCard}
			</div>
			<div className="spc--right--tny padd--left--tny separator--grey1--left">{value}</div>
			{isExpired ? (
				<div className="type--status type--status--error grid__holder__payment-method__tag">Expired</div>
			) : isAboutToExpire ? (
				<div className="type--status type--status--pending grid__holder__payment-method__tag">Expires Soon</div>
			) : (
				''
			)}
		</div>
	);
};

PaymentMethodExpiry.propTypes = {
	value: PropTypes.string.isRequired,
	row: PropTypes.object,
};

export default PaymentMethodExpiry;
