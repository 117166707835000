import React from 'react';

const RoutingNumberTooltipComponent = props => {
	return (
		<i {...props} className="icon icon--tiny icon--info newtransaction__tooltip__trigger">
			<span className="newtransaction__tooltip newtransaction__tooltip--routing">
				<div className="check">
					<div className="row">
						<div className="col col-sml-7">
							<div className="check__line check__line--primary"></div>
						</div>
						<div className="col col-sml-5">
							<div className="group">
								<div className="check__label">&#36;</div>
								<div className="check__square"></div>
							</div>
						</div>
					</div>
					<div className="check__line check__line--secondary"></div>
					<div className="row">
						<div className="col col-sml-7">
							<div className="check__line check__line--tertiary"></div>
						</div>
						<div className="col col-sml-5">
							<div className="check__line check__line--tertiary"></div>
						</div>
					</div>
					<div className="row--alt">
						<div className="col-sml-6">
							<div className="check__number is-focused">0123451234</div>
						</div>
						<div className="col-sml-6">
							<div className="check__number">52184720082</div>
						</div>
					</div>
				</div>
				<p>
					Your routing number or ABA number is the nine-digit code number of your bank, located at the bottom left on
					your bank checks. The ABA number is EXACTLY 9 digits long.
				</p>
			</span>
		</i>
	);
};

export default RoutingNumberTooltipComponent;
