import { trim } from 'lodash';

import { createFilters, getFilterValues } from 'common/utilities';
import { SelectFilter, StringFilter, SingleDateFilter, NumberFilter, FrequencyFilter } from 'common/components/filters';
import {
	SelectSelection,
	SingleSelection,
	SingleDateSelection,
	FrequencySelection,
} from 'common/components/selections';

export const recurringSchedulesFilter = createFilters([
	{
		key: 'scheduleName',
		name: 'Schedule Name',
		values: {
			scheduleName: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'company',
		name: 'Company',
		values: {
			company: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'startDay',
		name: 'Start Date',
		values: {
			startDay: null,
		},
		component: SingleDateFilter,
		selectionComponent: SingleDateSelection,
	},
	{
		key: 'frequency',
		name: 'Frequency',
		values: {
			count: null,
			type: null,
		},
		optionalValues: ['count'],
		component: FrequencyFilter,
		selectionComponent: FrequencySelection,
	},
	{
		key: 'remainingCharges',
		name: 'Remaining Charges',
		values: {
			remainingCharges: null,
		},
		component: NumberFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'amount',
		name: 'Amount',
		values: {
			amount: null,
		},
		props: {
			prefix: '$',
		},
		component: NumberFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'cardholderName',
		name: 'Customer',
		values: {
			cardholderName: null,
		},
		props: {
			placeholder: 'Customer Name',
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'email',
		name: 'Email',
		values: {
			email: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'description',
		name: 'Description',
		values: {
			description: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'active',
		name: 'Status',
		values: {
			active: false,
			inactive: false,
		},
		options: {
			active: 'Active',
			inactive: 'Inactive',
		},
		component: SelectFilter,
		selectionComponent: SelectSelection,
	},
]);

export const compileFilter = async filters => {
	const {
		scheduleName,
		startDay,
		frequency,
		remainingCharges,
		amount,
		cardholderName,
		description,
		active,
		company,
		email,
	} = getFilterValues(filters);

	const filter = { SortOrder: 'Descending', Filters: {} };
	const { Filters } = filter;

	if (trim(scheduleName.scheduleName)) {
		Filters.ScheduleName = scheduleName.scheduleName;
	}

	if (trim(company.company)) {
		Filters.BillCompany = company.company;
	}

	if (trim(startDay.startDay)) {
		Filters.StartDate = startDay.startDay;
	}

	if (trim(frequency.type)) {
		Filters.IntervalType = frequency.type;
		if (trim(frequency.count)) {
			Filters.IntervalCount = frequency.count;
		}
	}

	if (trim(remainingCharges.remainingCharges)) {
		Filters.PaymentsRemaining = remainingCharges.remainingCharges;
	}

	if (trim(amount.amount)) {
		Filters.Amount = amount.amount;
	}

	if (trim(cardholderName.cardholderName)) {
		Filters.BillName = cardholderName.cardholderName;
	}

	if (trim(description.description)) {
		Filters.Description = description.description;
	}

	if (trim(email.email)) {
		Filters.Email = email.email;
	}

	if (active.active) {
		Filters.Active = false;
	} else if (active.inactive) {
		Filters.Active = true;
	}

	return filter;
};
