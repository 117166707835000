import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { transform } from 'lodash';

const arrowClassNames = {
	NONE: 'sort',
	ASC: 'dropup',
	DESC: 'dropdown',
};

class BatchGridHeaderItem extends Component {
	handleGridSort = () => {
		const { column } = this.props;
		const sortDirection =
			column.isDefaultSorter && !column.sortDirection ? column.defaultSortDirection : column.sortDirection || 'NONE';

		if (column.sortable) {
			let direction = 'ASC';

			if (sortDirection === 'ASC') {
				direction = 'DESC';
			} else if (sortDirection === 'DESC') {
				direction = 'NONE';
			}

			this.props.onGridSort(column.key, direction);
		}
	};

	render() {
		const { column, children, displaySorting, ...rest } = this.props;
		const sortDirection =
			column.isDefaultSorter && !column.sortDirection ? column.defaultSortDirection : column.sortDirection || 'NONE';

		const props = transform(rest, (acc, value, key) => {
			if (key !== 'onGridSort') {
				acc[key] = value;
			}
		});

		return (
			<th onClick={this.handleGridSort} {...props}>
				{column.name}
				{displaySorting && column.sortable && (
					<i className={`icon icon--tiny icon--arrow--${arrowClassNames[sortDirection]}--grey spc--left--tny`}></i>
				)}
			</th>
		);
	}
}

BatchGridHeaderItem.propTypes = {
	column: PropTypes.object.isRequired,
	onGridSort: PropTypes.func.isRequired,
	displaySorting: PropTypes.bool.isRequired,
	children: PropTypes.any,
};

export default BatchGridHeaderItem;
