import {
	toLower,
	toUpper,
	each,
	filter,
	find,
	size,
	isEmpty,
	head,
	includes,
	slice,
	split,
	replace,
	round,
	get,
} from 'lodash-es';
import CurrencyMap from './currencyMap';

const getInlineFilterValues = inlineFilters => {
	let result = [];
	if (isEmpty(get(inlineFilters, 'xResponseResult.filterTerm'))) return ['approved'];
	if (inlineFilters.xResponseResult) {
		const {
			xResponseResult: { filterTerm },
		} = inlineFilters;
		each(filterTerm, ({ value }) => result.push(toLower(value)));
	} else {
		return result;
	}

	return result;
};

const getInlineFilters = (inlineFilterValues, inlineFilters, reportItem, statusLabel) => {
	const statusToParse = statusLabel && slice(split(replace(statusLabel, /[^a-zA-Z ]/g, ' '), ' '));
	const isApproved = includes(statusToParse, 'Approved') && size(statusToParse) === 3;
	if (isApproved && inlineFilters && head(inlineFilterValues) === 'approved' && size(inlineFilterValues) === 1) {
		return reportItem.xRefNum && includes('approved', toLower(reportItem.xResponseResult));
	} else return true;
};

const getMappedCurrency = currency => {
	return CurrencyMap.isoCodesMap[currency] || toUpper(currency);
};

const calculateTransactionAmounts = (
	data,
	processingFeeVisible,
	netSaleVisible,
	sCurrency,
	filters = null,
	inlineFilters = null,
	statusLabel = null
) => {
	let inlineFilterValues = !isEmpty(filters) ? filters : getInlineFilterValues(inlineFilters);
	const currOptions = [];
	if (data && data.length > 0) {
		each(data, reportItem => {
			if (getInlineFilters(inlineFilterValues, inlineFilters, reportItem, statusLabel)) {
				const { currency, xAmount, xProcessingFee, netSale, xCommand } = reportItem;
				const subtract = toLower(xCommand) === 'gift:issue';
				const isGiftRedeem = toLower(xCommand) === 'gift:redeem';

				let amount = xAmount;
				if (subtract) {
					amount = -Math.abs(xAmount);
				}
				if (isGiftRedeem) {
					amount = Math.abs(amount);
				}
				const mappedCurrency = getMappedCurrency(currency);
				const item = find(currOptions, { label: mappedCurrency });
				if (item) {
					if (processingFeeVisible) {
						item.processingFee += xProcessingFee;
					}
					if (netSaleVisible) {
						item.netSale += netSale;
					}
					item.amount = round(item.amount + amount, 2);
					item.count++;
				} else {
					const currencyOption = {
						label: mappedCurrency,
						symbol: CurrencyMap.resolveCurrency(mappedCurrency),
						amount: amount,
						count: 1,
					};
					if (processingFeeVisible) {
						currencyOption.processingFee = xProcessingFee;
					}
					if (netSaleVisible) {
						currencyOption.netSale = netSale;
					}
					currOptions.push(currencyOption);
				}
			}
		});
	}
	return currOptions;
};

const getTransactionAmounts = (
	data,
	processingFeeVisible,
	netSaleVisible,
	sCurrency,
	filters = null,
	inlineFilters = null,
	statusLabel = null
) => {
	const defaultInlineFilteredRows = isEmpty(inlineFilters) && filter(data, d => d.xResponseResult === 'Approved');

	const currencyOptions = calculateTransactionAmounts(
		!isEmpty(defaultInlineFilteredRows) ? defaultInlineFilteredRows : data,
		processingFeeVisible,
		netSaleVisible,
		sCurrency,
		filters,
		inlineFilters,
		statusLabel
	);

	const selectedCurrency = find(currencyOptions, ({ label }) => label === sCurrency.label) || currencyOptions[0] || {};
	return {
		currencyOptions,
		selectedCurrency,
	};
};

export default getTransactionAmounts;
