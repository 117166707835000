import { trim } from 'lodash';

import { createFilters, getFilterValues } from 'common/utilities';
import { StringFilter, NumberFilter, PhoneFilter } from 'common/components/filters';
import { SingleSelection } from 'common/components/selections';

export const customersFilter = createFilters([
	{
		key: 'customerId',
		name: 'Customer ID',
		values: {
			customerId: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'customerNumber',
		name: 'Customer #',
		values: {
			customerNumber: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'company',
		name: 'Company',
		type: 'text',
		values: {
			company: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'firstName',
		name: 'First Name',
		values: {
			firstName: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'lastName',
		name: 'Last Name',
		values: {
			lastName: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
	},
	{
		name: 'Street',
		key: 'street',
		values: {
			street: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
	},
	{
		name: 'City',
		key: 'city',
		values: {
			city: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
	},
	{
		name: 'State',
		key: 'state',
		values: {
			state: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
	},
	{
		name: 'Zip',
		key: 'zip',
		values: {
			zip: null,
		},
		component: NumberFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'phoneNumber',
		name: 'Phone Number',
		values: {
			phoneNumber: null,
		},
		component: PhoneFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'mobilePhoneNumber',
		name: 'Mobile Phone Number',
		values: {
			mobilePhoneNumber: null,
		},
		component: PhoneFilter,
		selectionComponent: SingleSelection,
	},
	{
		name: 'Email',
		key: 'email',
		values: {
			email: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
	},
]);

export const compileFilter = async filters => {
	const {
		customerId,
		customerNumber,
		company,
		lastName,
		firstName,
		street,
		city,
		state,
		zip,
		phoneNumber,
		mobilePhoneNumber,
		email,
	} = getFilterValues(filters);

	const filter = { SortOrder: 'Descending', Filters: {} };
	const { Filters } = filter;

	if (trim(customerId.customerId)) {
		Filters.CustomerId = customerId.customerId;
	}

	if (trim(customerNumber.customerNumber)) {
		Filters.CustomerNumber = customerNumber.customerNumber;
	}

	if (trim(company.company)) {
		Filters.BillCompany = company.company;
	}

	if (trim(lastName.lastName)) {
		Filters.BillLastName = lastName.lastName;
	}

	if (trim(firstName.firstName)) {
		Filters.BillFirstName = firstName.firstName;
	}

	if (trim(street.street)) {
		Filters.BillStreet = street.street;
	}

	if (trim(city.city)) {
		Filters.BillCity = city.city;
	}

	if (trim(state.state)) {
		Filters.BillState = state.state;
	}

	if (trim(zip.zip)) {
		Filters.BillZip = zip.zip;
	}

	if (trim(phoneNumber.phoneNumber)) {
		Filters.BillPhone = phoneNumber.phoneNumber;
	}

	if (trim(mobilePhoneNumber.mobilePhoneNumber)) {
		Filters.BillMobile = mobilePhoneNumber.mobilePhoneNumber;
	}

	if (trim(email.email)) {
		Filters.Email = email.email;
	}

	return filter;
};
