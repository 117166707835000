import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop, map, find } from 'lodash';

class CardTypeFilterComponent extends Component {
	filterChanged = event => {
		const { value: name, checked } = event.target;

		const values = map(this.props.filter.values, (value, key) => {
			return { key, value };
		});

		const changedValue = find(values, value => value.key === name);
		changedValue.value = checked;

		this.props.onFilterChanged({
			id: 'cardType',
			values: values,
			emptyValue: false,
		});
	};

	render() {
		const { filter, goButtonHandler } = this.props;
		const { visa, mastercard, amex, discover, jcb, diners, other } = filter.values;
		const isAnyChecked = visa || mastercard || amex || discover || jcb || diners;

		return (
			<div className="spc--top--sml">
				<div className="filter--cardtype">
					<input
						disabled={other}
						type="checkbox"
						name="ctype-visa"
						className="input input--check"
						checked={visa}
						value="visa"
						id="ctype-visa"
						onChange={noop}
						onClick={this.filterChanged}
					/>
					<label htmlFor="ctype-visa">Visa</label>
					<img src="/static/media/credit-cards/visa.svg" className="filter--cardtype__image" />
				</div>
				<div className="filter--cardtype">
					<input
						disabled={other}
						type="checkbox"
						name="ctype-mastercard"
						className="input input--check"
						checked={mastercard}
						value="mastercard"
						id="ctype-mastercard"
						onChange={noop}
						onClick={this.filterChanged}
					/>
					<label htmlFor="ctype-mastercard">Mastercard</label>
					<img src="/static/media/credit-cards/mastercard.svg" className="filter--cardtype__image" />
				</div>
				<div className="filter--cardtype">
					<input
						disabled={other}
						type="checkbox"
						name="ctype-amex"
						className="input input--check"
						checked={amex}
						value="amex"
						id="ctype-amex"
						onChange={noop}
						onClick={this.filterChanged}
					/>
					<label htmlFor="ctype-amex">American Express</label>
					<img src="/static/media/credit-cards/amex.svg" className="filter--cardtype__image" />
				</div>
				<div className="filter--cardtype">
					<input
						disabled={other}
						type="checkbox"
						name="ctype-discover"
						className="input input--check"
						checked={discover}
						value="discover"
						id="ctype-discover"
						onChange={noop}
						onClick={this.filterChanged}
					/>
					<label htmlFor="ctype-discover">Discover</label>
					<img src="/static/media/credit-cards/discover.svg" className="filter--cardtype__image" />
				</div>
				<div className="filter--cardtype">
					<input
						disabled={other}
						type="checkbox"
						name="ctype-jcb"
						className="input input--check"
						checked={jcb}
						value="jcb"
						id="ctype-jcb"
						onChange={noop}
						onClick={this.filterChanged}
					/>
					<label htmlFor="ctype-jcb">JCB</label>
					<img src="/static/media/credit-cards/jcb.svg" className="filter--cardtype__image" />
				</div>
				<div className="filter--cardtype">
					<input
						disabled={other}
						type="checkbox"
						name="ctype-diners"
						className="input input--check"
						checked={diners}
						value="diners"
						id="ctype-diners"
						onChange={noop}
						onClick={this.filterChanged}
					/>
					<label htmlFor="ctype-diners">Diners</label>
					<img src="/static/media/credit-cards/diners.svg" className="filter--cardtype__image" />
				</div>
				<hr className="separator separator--grey1" />
				<div className="filter--cardtype">
					<input
						disabled={isAnyChecked}
						type="checkbox"
						name="ctype-other"
						className="input input--check"
						checked={other}
						value="other"
						id="ctype-other"
						onChange={noop}
						onClick={this.filterChanged}
					/>
					<label htmlFor="ctype-other">Other</label>
					<img src="/static/media/credit-cards/unknown.svg" className="filter--cardtype__image" />
				</div>
				{goButtonHandler && (
					<button
						className="btn btn--sml btn--primary spc--top--sml spc--bottom--xsml fullwidth"
						onClick={goButtonHandler}
						data-tooltip="Apply"
					>
						<i className="icon icon--nano icon--arrow--right--positive"></i>
					</button>
				)}
			</div>
		);
	}
}

CardTypeFilterComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
	goButtonHandler: PropTypes.func,
};

export default CardTypeFilterComponent;
