import { map, cloneDeep, each } from 'lodash';
import moment from 'moment';

const commonProperties = {
	sortDirection: 'asc',
	actions: {
		view: true,
		download: true,
		edit: false,
		delete: false,
	},
};
const otherReportsConstants = [
	{ name: null, reportType: null },
	{ name: 'Expired Payment Methods', reportType: 'expiredPayments' },
	{
		name: 'Expired Payment Methods (Active Recurring)',
		reportType: 'expiredPaymentsActiveRecurring',
	},
	{ name: 'Customers and Recurring Records', reportType: 'customersAndRecurringRecords', csRepOnly: true },
];

const previousYear = moment().subtract(1, 'year');

const mapReport = report => {
	const mappedReport = {
		...report,
	};
	mappedReport.fetchReport = () => Promise.resolve(mappedReport);
	return mappedReport;
};
export const OtherReports = () => {
	const reports = [];
	each(otherReportsConstants, ({ name, reportType, csRepOnly }) => {
		if (!name) {
			return reports.push({
				name: `${cloneDeep(previousYear).format('YYYY')} Year-End Summary`,
				date: 'custom',
				sortBy: 'date',
				showDownloadDisclaimer: true,
				filters: {
					date: {
						key: 'custom',
						startDate: cloneDeep(previousYear).startOf('year'),
						endDate: cloneDeep(previousYear).endOf('year'),
					},
				},
				sortDirection: 'asc',
				...commonProperties,
			});
		}
		reports.push({
			name: name,
			date: 'custom',
			sortBy: 'date',
			showDownloadDisclaimer: true,
			filters: {
				date: {
					key: 'custom',
					startDate: cloneDeep(previousYear).startOf('year'),
					endDate: cloneDeep(previousYear).endOf('year'),
				},
			},
			reportType: reportType,
			sortDirection: 'asc',
			actions: {
				view: false,
				download: true,
				edit: false,
				delete: false,
			},
			csRepOnly: csRepOnly,
		});
	});
	return map(reports, mapReport);
};
