import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

const images = {
	Pending: '/static/media/tags/pending.svg',
	Guaranteed: '/static/media/tags/guaranteed.svg',
	'Not Guaranteed - Probable Fraud': '/static/media/tags/probable-fraud.svg',
	'Not Guaranteed - High Risk': '/static/media/tags/high-risk.svg',
	'Not Guaranteed - Low Risk': '/static/media/tags/low-risk.svg',
	Error: '/static/media/tags/error.svg',
	Review: '/static/media/tags/review.svg',
	Chargeback: '/static/media/tags/chargeback.svg',
	Settled: '/static/media/tags/settled.svg',
	Safe: '/static/media/tags/safe.svg',
	Unknown: '/static/media/tags/unknown.svg',
	Cancelled: '/static/media/tags/cancelled.svg',
	'Awaiting Response': '/static/media/tags/awaiting-response.svg',
	Funded: '/static/media/tags/funded.svg',
	Returned: '/static/media/tags/returned.svg',
	Hold: '/static/media/tags/hold.svg',
	Rejected: '/static/media/tags/rejected.svg',
	'Unauthorized Return': '/static/media/tags/unauthorized-return.svg',
};

class StatusFraudComponent extends Component {
	render = () => {
		const { value, showBadge } = this.props;
		const showUnknownStatusCode = !images[value];
		let img = images[value];
		let statusCode = value;
		if (showUnknownStatusCode) {
			[, statusCode] = value.split(' ');
			img = images.Unknown;
		}
		return (
			<span className="fraud-status">
				{showBadge ? (
					<Fragment>
						<img src={img} alt={statusCode} />
						{showUnknownStatusCode && ` ${statusCode || ''}`}
					</Fragment>
				) : (
					value
				)}
			</span>
		);
	};
}
StatusFraudComponent.defaultProps = {
	showBadge: true,
};
StatusFraudComponent.propTypes = {
	value: PropTypes.any,
	showBadge: PropTypes.bool,
};

export default StatusFraudComponent;
