import { get } from 'lodash';
import moment from 'moment';

import { principalService } from 'common/services';

const { displayShortDateTimeFormat, apiDateFormat } = ApplicationSettings;

let principal = principalService.get();
let xAccountCreationDate = get(principal, 'idInfo.xAccountCreationDate');
export let isOlderThanSevenMonths = moment(xAccountCreationDate, displayShortDateTimeFormat).isBefore(
	moment.max(moment().add(-7, 'months'), moment('2020-12-01', apiDateFormat, true))
);

principalService.subscribe(data => {
	principal = data;
	xAccountCreationDate = get(principal, 'idInfo.xAccountCreationDate');
	isOlderThanSevenMonths = moment(xAccountCreationDate, displayShortDateTimeFormat).isBefore(
		moment.max(moment().add(-7, 'months'), moment('2020-12-01', apiDateFormat, true))
	);
});
