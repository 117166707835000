import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { findIndex, each, startsWith } from 'lodash';

import { AddEditTemplate, DeleteTemplate } from '../../recurring-templates/popup';
import AddEditCustomer from '../../customers/popup/add-edit-customer';
import { OutsideClick } from '../../../utilities';
import LinkExistingCustomerForm from 'common/components/customers/popup/LinkExistingCustomerForm';
import { Modal } from 'common/components/modal';

class TemplateActionsComponent extends Component {
	constructor(props) {
		super(props);

		TemplateActionsComponent.instances.push(this);
		this.popupRef = React.createRef();
		this.state = this.initialState;
	}
	get initialState() {
		return {
			template: {},
			isDisplayMenuOpen: false,
			isEditOpen: false,
			isDeleteOpen: false,
			isNewCustomerOpen: false,
			isOpenLinkSchedule: false,
			isOpenLinkExistingCustomer: false,
			ishiddenLinkSchedule: false,
			data: null,
			selectedCustomer: null,
			isLoading: false,
		};
	}

	static instances = [];

	componentDidMount = () => {
		this.mapTemplateToState();
	};

	componentDidUpdate = prevProps => {
		if (prevProps.dependentValues !== this.props.dependentValues) {
			this.mapTemplateToState();
		}
		if (prevProps.isScrolling !== this.props.isScrolling) {
			this.closeDisplayMenu();
		}
	};

	componentWillUnmount() {
		const index = findIndex(TemplateActionsComponent.instances, this);
		if (index > -1) {
			TemplateActionsComponent.instances.splice(index, 1);
		}
	}
	closeLinkSchedulePopup = () => {
		this.setState({
			isOpenLinkSchedule: false,
			isOpenLinkExistingCustomer: false,
			isNewCustomerOpen: false,
			selectedCustomer: null,
		});
	};
	openLinkSchedulePopup = () => {
		this.setState({ isOpenLinkSchedule: true });
	};

	openLinkExistingCustomer = () => {
		this.setState({ isOpenLinkExistingCustomer: true });
	};

	selectCustomer = customer => {
		this.setState({ selectedCustomer: customer });
	};

	closeRenderLinkSchedulePopup = () => {
		this.setState({ ...this.initialState, template: this.state.template });
	};
	mapTemplateToState = () => {
		const template = {};
		each(this.props.dependentValues, (value, key) => {
			if (startsWith(key, 'x') || key === 'amount') {
				template[key] = value;
			}
		});
		this.setState({ template });
	};

	displayMenu = () => {
		const { openActions } = this.props.dependentValues;
		const { isDisplayMenuOpen } = this.state;

		const actions = isDisplayMenuOpen ? (
			<OutsideClick action={this.closeDisplayMenu} className="buttondropdown buttondropdown--reset">
				<ul className="buttondropdown__list">
					<li className="buttondropdown__item buttondropdown__item--withlink">
						<button className="buttondropdown__button" onClick={this.openLinkSchedulePopup}>
							Apply schedule template
						</button>
					</li>
					<li className="buttondropdown__item buttondropdown__item--withlink">
						<button className="buttondropdown__button" onClick={this.openEditModal}>
							Edit
						</button>
					</li>
					<li className="buttondropdown__item buttondropdown__item--withlink">
						<button className="buttondropdown__button" onClick={this.openDeleteModal}>
							Delete template
						</button>
					</li>
				</ul>
			</OutsideClick>
		) : null;
		const rect = this.info.getBoundingClientRect();
		openActions(
			{
				width: (rect.left || 0) - 30,
				height: (rect.top || 0) - 50,
			},
			actions
		);
		if (isDisplayMenuOpen) {
			each(TemplateActionsComponent.instances, instance => {
				if (instance.state.isDisplayMenuOpen && instance !== this) {
					instance.setState({ isDisplayMenuOpen: false });
				}
			});
		}
	};

	openDisplayMenu = () => {
		this.setState({ isDisplayMenuOpen: true }, this.displayMenu);
	};

	closeDisplayMenu = () => {
		this.setState({ isDisplayMenuOpen: false }, this.displayMenu);
	};

	openNewCustomer = () => {
		this.setState({ isNewCustomerOpen: true, isDisplayMenuOpen: false }, this.displayMenu);
	};

	openEditModal = () => {
		this.setState({ isEditOpen: true, isDisplayMenuOpen: false }, this.displayMenu);
	};

	openDeleteModal = () => {
		this.setState({ isDeleteOpen: true, isDisplayMenuOpen: false }, this.displayMenu);
	};

	closeNewCustomerModal = () => {
		this.setState({ isNewCustomerOpen: false });
	};

	closeEditModal = () => {
		this.setState({ isEditOpen: false });
	};

	closeDeleteModal = () => {
		this.setState({ isDeleteOpen: false });
	};

	closeMenu = () => {
		this.props.dependentValues.openActions({}, null);
	};
	handleLinkExistingCustomer = async (selectedCustomer, template) => {
		this.setState({ isLoading: true });
		const { linkScheduleToExistingCustomer } = this.props.dependentValues;
		await linkScheduleToExistingCustomer(selectedCustomer, template);
		this.closeLinkSchedulePopup();
		this.closeRenderLinkSchedulePopup();
		this.setState({ isLoading: false });
	};
	renderLinkSchedulePopup = () => {
		const {
			isOpenLinkSchedule,
			isOpenLinkExistingCustomer,
			ishiddenLinkSchedule,
			selectedCustomer,
			isNewCustomerOpen,
			template,
			isLoading,
		} = this.state;
		const { refreshGridData, notificationRef } = this.props.dependentValues;

		return (
			<Modal
				isOpen={isOpenLinkSchedule}
				onClose={this.closeLinkSchedulePopup}
				shouldCloseOnOverlayClick={false}
				overlayClassName={ishiddenLinkSchedule ? '' : 'popup__overlay'}
				className={`popup__content${ishiddenLinkSchedule ? ' display--n' : ''} ${
					isOpenLinkExistingCustomer ? 'popup--lrg' : ''
				}`}
			>
				<div ref={this.popupRef} className="">
					<div className={`popup__header ${isOpenLinkExistingCustomer ? 'popup__header--existing-customer' : ''}`}>
						<div className="popup__header__title">
							{`Link Schedule Template to${isOpenLinkExistingCustomer ? ' an existing customer' : ':'}`}
						</div>
						{!isOpenLinkExistingCustomer ? (
							<div>
								<AddEditCustomer
									advancedView={true}
									advancedViewByDefault={true}
									closeModal={this.closeNewCustomerModal}
									template={template}
									isOpen={isNewCustomerOpen}
									refreshGrid={refreshGridData}
									type="schedules"
									notificationRef={notificationRef()}
									closeLinkTransactionPopup={this.closeLinkSchedulePopup}
								/>
								<button className="btn btn--primary btn--sml spc--bottom--sml" onClick={this.openNewCustomer}>
									New Customer
								</button>
								<button
									className="btn btn--primary btn--sml spc--left--tny spc--bottom--sml"
									onClick={this.openLinkExistingCustomer}
								>
									Existing Customer
								</button>
							</div>
						) : (
							<p className="message message--primary">
								Please select the customer you'd like to link this Schedule Template to.
							</p>
						)}
					</div>
					{isOpenLinkExistingCustomer && (
						<div className="popup__body">
							<div className="grid__holder--link-trans">
								<LinkExistingCustomerForm popupRef={this.popupRef} selectCustomer={this.selectCustomer} />
							</div>
						</div>
					)}
					{isOpenLinkExistingCustomer && (
						<div className="popup__footer popup__footer--styled">
							<button
								type="button"
								className="btn btn--sml btn--ghost"
								onClick={this.closeRenderLinkSchedulePopup}
								disabled={isLoading}
							>
								Cancel
							</button>
							<button
								type="button"
								tabIndex="-1"
								className="btn btn--sml btn--primary"
								onClick={() => this.handleLinkExistingCustomer(selectedCustomer, template)}
								disabled={!selectedCustomer || isLoading}
							>
								Save
							</button>
						</div>
					)}
				</div>
			</Modal>
		);
	};

	render() {
		const { isDisplayMenuOpen, template, isEditOpen, isDeleteOpen } = this.state;
		const { saveTemplate } = this.props.dependentValues;
		return (
			<Fragment>
				<div
					className={`anchor type--wgt--bold recurring__tooltip__trigger ${isDisplayMenuOpen ? 'anchor--primary' : ''}`}
					ref={el => (this.info = el)}
					onClick={this.openDisplayMenu}
				>
					...
				</div>
				<AddEditTemplate
					openEditModal={this.openEditModal}
					closeEditModal={this.closeEditModal}
					saveTemplate={saveTemplate}
					template={template}
					type="edit"
					isEditOpen={isEditOpen}
					className="popup__content popup__med"
				/>
				<DeleteTemplate
					closeModal={this.closeDeleteModal}
					saveTemplate={saveTemplate}
					template={template}
					isModalOpen={isDeleteOpen}
				/>
				{this.renderLinkSchedulePopup()}
			</Fragment>
		);
	}
}

TemplateActionsComponent.propTypes = {
	dependentValues: PropTypes.object,
	isScrolling: PropTypes.bool,
};

export default TemplateActionsComponent;
