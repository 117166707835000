import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toLower, includes, replace, endsWith } from 'lodash';
import NumberFormat from 'react-number-format';

import { transactionService, authenticationService } from '../../services';
import { withStepup } from './../stepup-mfa';
import { CurrencyMap } from '../../../Common/utilities';
import { Spinner, withLoader } from '../loader';
import { UNEXPECTED_ERROR_MSG } from '../../../components/error';
import { validators } from '../../fields';

class TransactionRefundComponent extends Component {
	get currencyCode() {
		return CurrencyMap.resolveCurrency(this.props.currency);
	}

	constructor(props) {
		super(props);
		this.state = {
			refNum: props.refNum,
			isFullRefund: true,
			fullAmount: props.amount,
			partialAmount: '',
			note: null,
			disableSubmit: false,
			isLoading: false,
			sendCopy: false,
			sendReceipt: false,
			email: props.email || '',
			emailModified: false,
		};
	}

	componentDidMount(){
		if (this.transactionType === 'check')
			this.props.setBypass(false);
	}

	get transactionType() {
		const { type } = this.props;
		const lower = toLower(type);

		if (includes(lower, 'cc:')) {
			return 'cc';
		} else if (includes(lower, 'check:')) {
			return 'check';
		} else if (includes(lower, 'ebtonline:')) {
			return 'ebtonline';
		} else {
			throw new Error('Unknown type');
		}
	}

	get getTransactionAmount() {
		const { isFullRefund, partialAmount, fullAmount } = this.state;
		return isFullRefund ? fullAmount : partialAmount;
	}

	blurAmountInput = () => {
		if (this.partialAmountRef && !endsWith(this.state.partialAmount, '.')) {
			this.partialAmountRef.blur();
			this.partialAmountRef.focus();
		}
	};

	handleOptionChange = ({ target: { value } }) => {
		const { partialAmount } = this.state;
		let disableSubmit = false;
		const isFullRefund = value === 'full';
		if (!isFullRefund && !parseFloat(partialAmount)) {
			disableSubmit = true;
		}

		this.setState({
			isFullRefund,
			disableSubmit,
		});
	};

	handlePartialAmountChange = ({ floatValue = 0, value }) => {
		const { fullAmount } = this.state;
		if (floatValue <= fullAmount) {
			this.setState(
				{
					partialAmount: value,
					disableSubmit: !floatValue,
				},
				this.blurAmountInput
			);
		} else {
			this.setState(
				{
					partialAmount: fullAmount.toString(),
					disableSubmit: false,
				},
				this.blurAmountInput
			);
		}
	};

	handleChange = e => {
		const { value, name, checked, type } = e.target;
		const val = type === 'checkbox' ? checked : value;
		this.setState({ [name]: val });
	};

	refreshGridData = () => {
		this.props.refreshGridData();
	};

	closeModal = () => {
		this.props.closeModal();
	};

	showLoader = isLoading => {
		this.setState({
			isLoading,
		});
	};

	setStateAsync = async newState => {
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	refundTransaction = async () => {
		const { isLoading, refNum, note, email, sendCopy, sendReceipt } = this.state;
		const { currency, notificationRef, xMerchantId } = this.props;
		let mappedEmail = '';
		let emailInvalid = false;

		if (sendReceipt) {
			await this.validateEmail();
			const { emailModified, sendReceipt: sendReceiptEmail } = this.state;
			const emailValid = validators.email(replace(email, /\s/g, ''));
			emailInvalid = emailModified && !emailValid && sendReceiptEmail;
		}

		if (isLoading || emailInvalid) {
			return;
		}

		this.showLoader(true);
		try {
			const rsp = await transactionService.transactionRefund(
				refNum,
				this.transactionType,
				this.getTransactionAmount,
				note,
				currency,
				xMerchantId
			);

			if (sendReceipt || sendCopy) {
				if (sendReceipt) {
					mappedEmail = replace(email, /\s/g, '');
				}

				if (sendCopy) {
					const user = await authenticationService.getUser();
					const username = user && user.attributes && user.attributes.email;
					mappedEmail = sendReceipt ? `${email},${username}` : username;
				}

				await transactionService.sendReceipt(mappedEmail, rsp.xRefNum, xMerchantId);
			}

			notificationRef.addNotification({
				ref: rsp.xRefNum,
				showViewTransaction: true,
				message: 'Transaction refunded',
				success: true,
				onClose: this.refreshGridData,
			});
		} catch (e) {
			this.handleRefundError(e);
		}
		this.closeModal();
		this.showLoader(false);
	};

	handleRefundError = e => {
		if (!e || !e.isCanceled) {
			this.props.notificationRef.addNotification({
				ref: e && e.ref,
				message: (e && e.message) || UNEXPECTED_ERROR_MSG,
				success: false,
				onClose: this.refreshGridData,
			});
		}
	};

	renderRefundBody = () => {
		const {
			isFullRefund,
			fullAmount,
			partialAmount,
			sendCopy,
			sendReceipt,
			email,
			emailModified,
			isLoading,
		} = this.state;
		const emailValid = validators.email(email);
		if (isLoading) {
			return <Spinner />;
		}
		return (
			<form onSubmit={this.refundTransaction} className="w--334p w--max--100">
				{emailModified && !emailValid && sendReceipt ? (
					<div className="validation spc--bottom--med">
						<p className="type--error">Please enter a valid email address</p>
					</div>
				) : null}
				<div className="row">
					<div className="col col-sml-6 col-med-5 padd--top--tny">
						<input
							type="radio"
							id="fullRefund"
							className="input--radio"
							value="full"
							checked={isFullRefund}
							onChange={this.handleOptionChange}
						/>
						<label htmlFor="fullRefund" className="type--color--black">
							Full refund
						</label>
					</div>
					<div className="col col-sml-6 col-med-7 spc--bottom--med">
						<div className="inputgroup">
							<div className="inputgroup--aside inputgroup--aside--bordered inputgroup--aside--iconed">
								<span>{this.currencyCode}</span>
							</div>
							<div className="inputgroup--main inputgroup--main--bordered">
								<NumberFormat
									disabled
									value={fullAmount}
									className="input input--sml inputgroup__input"
									readOnly
									thousandSeparator=","
									decimalSeparator="."
									decimalScale={2}
								/>
							</div>
						</div>
					</div>
				</div>
				{this.transactionType === 'cc' && (
					<div className="row">
						<div className="col col-sml-6 col-med-5 padd--top--tny">
							<input
								type="radio"
								id="partialRefund"
								className="input--radio"
								value="partial"
								checked={!isFullRefund}
								onChange={this.handleOptionChange}
							/>
							<label htmlFor="partialRefund" className="type--color--black">
								Partial refund
							</label>
						</div>
						<div className="col col-sml-6 col-med-7 spc--bottom--med">
							<div className="inputgroup">
								<div className="inputgroup--aside inputgroup--aside--bordered inputgroup--aside--iconed">
									<span>{this.currencyCode}</span>
								</div>
								<div className="inputgroup--main inputgroup--main--bordered">
									<NumberFormat
										className="input input--sml inputgroup__input"
										getInputRef={el => {
											this.partialAmountRef = el;
										}}
										placeholder="0"
										thousandSeparator=","
										decimalSeparator="."
										allowNegative={false}
										decimalScale={2}
										onValueChange={this.handlePartialAmountChange}
										value={partialAmount}
										disabled={isFullRefund}
										inputMode="numeric"
									/>
								</div>
							</div>
						</div>
					</div>
				)}
				<div>
					<label htmlFor="note" className="type--color--black spc--bottom--tny">
						Description
					</label>
					<div className="spc--top--tny">
						<input type="text" id="note" name="note" className="input input--sml" onChange={this.handleChange} />
					</div>
				</div>
				<div className="row spc--top--med">
					<div className="col col-sml-12 col-med-5 spc--bottom--med padd--top--tny">
						<input
							type="checkbox"
							name="sendReceipt"
							className="input input--check"
							checked={sendReceipt}
							onChange={this.handleChange}
							id="sendReceipt"
						/>
						<label htmlFor="sendReceipt" className="type--none">
							Send receipt
						</label>
					</div>
					<div className="col col-sml-12 col-med-7 spc--bottom--med">
						<input
							type="text"
							name="email"
							className={`input input--sml${emailModified && !emailValid && sendReceipt ? ' is-invalid' : ''}`}
							placeholder="user@mail.com"
							onChange={this.handleChange}
							autoComplete="new-password"
							value={email}
							onBlur={this.validateEmail}
							inputMode="email"
						/>
					</div>
					<div className="col col-sml-12">
						<input
							type="checkbox"
							name="sendCopy"
							className="input input--check"
							checked={sendCopy}
							onChange={this.handleChange}
							id="sendCopy"
						/>
						<label htmlFor="sendCopy" className="type--none">
							Send me a copy
						</label>
					</div>
				</div>
			</form>
		);
	};

	renderRefundHeader = () => {
		return (
			<div className="popup__header">
				<div className="popup__header__title">{'Refund #' + this.props.refNum}</div>
			</div>
		);
	};

	renderRefundFooter = () => {
		const { disableSubmit, email, sendReceipt, emailModified } = this.state;
		const emailValid = validators.email(email);

		return (
			<div className="popup__footer ">
				<button type="button" className="btn btn--sml btn--ghost spc--right--xsml" onClick={this.closeModal}>
					Cancel
				</button>
				<button
					type="button"
					className="btn btn--sml btn--primary"
					onClick={this.refundTransaction}
					disabled={disableSubmit || (emailModified && !emailValid && sendReceipt)}
				>
					Refund
				</button>
			</div>
		);
	};

	validateEmail = async () => this.setStateAsync({ emailModified: true });

	render = () => (
		<div>
			{this.renderRefundHeader()}
			<div className="popup__body">{this.renderRefundBody()}</div>
			{this.renderRefundFooter()}
		</div>
	);
}

TransactionRefundComponent.propTypes = {
	refNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	type: PropTypes.string.isRequired,
	amount: PropTypes.number.isRequired,
	refreshGridData: PropTypes.func,
	closeModal: PropTypes.func,
	notificationRef: PropTypes.any.isRequired,
	email: PropTypes.string,
	currency: PropTypes.string,
	xMerchantId: PropTypes.any,
	setBypass: PropTypes.func.isRequired,
};

export default withLoader(withStepup(TransactionRefundComponent, 'transaction-refund', true));
