import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { split } from 'lodash-es';

class DisplayDateComponent extends React.Component {
	render() {
		const inputFormat =
			(this.props.dependentValues && this.props.dependentValues.inputFormat) ||
			ApplicationSettings.apiResponseDateTimeFormat;
		const outputFormat =
			(this.props.dependentValues && this.props.dependentValues.outputFormat) ||
			ApplicationSettings.displayDateTimeFormat;
		if (this.props.value) {
			const date = moment(this.props.value, inputFormat);
			const formatedDate = split(date.format(outputFormat), ' ');
			const printClass = this.props.isPrint ? 'display--b' : 'flex flex--primary flex--nowrap';
			return (
				<div className={printClass}>
					<div className="type--nowrap spc--right--tny">{formatedDate[0]}</div>
					<div className="type--nowrap">
						{formatedDate[1]} {formatedDate[2]}
					</div>
				</div>
			);
		}
		return null;
	}
}

DisplayDateComponent.propTypes = {
	value: PropTypes.string.isRequired,
	dependentValues: PropTypes.object,
	isPrint: PropTypes.any,
};

export default DisplayDateComponent;
