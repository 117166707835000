import React from 'react';
import { times, noop } from 'lodash';
import moment from 'moment';

import { GridComponent } from 'common/components/grid';
import { terminalOnlyUpgradePlanFakeGridColumns } from './columns/terminalOnlyUpgradePlanFakeGridColumns';

const generateRandomName = () => {
	const names = ['Cardknox', 'Platform', 'Admiral Club', 'Daily A', 'Daughter', 'Daily B', 'Daily C'];
	return names[Math.round(Math.random() * 6)];
};

const getMockData = () => {
	const { random, round } = Math;
	const data = { xReportData: [] };

	times(20, i => {
		data.xReportData.push({
			ref: `ref_000${round(random() * 9)}`,
			name: generateRandomName(),
			dateTime: moment().format(ApplicationSettings.displayDateTimeFormat),
			date: moment().format(ApplicationSettings.displayDateFormat),
			frequency: `Every ${round(random() * 10)} Days`,
			amount: (random() * 200).toFixed(round(random())),
			result: 'Approved',
			isActive: round(random()) > 0,
			isExpandable: true,
			gridRowNumber: i,
			index: i + 1,
		});
	});

	return data;
};

const data = getMockData();

export const TerminalOnlyUpgradePlanFakeGrid = () => {
	return (
		<div className="terminal-only__grid">
			<GridComponent
				filteredRows={data.xReportData}
				columns={terminalOnlyUpgradePlanFakeGridColumns}
				defaultColumns={terminalOnlyUpgradePlanFakeGridColumns}
				type="schedules"
				onChange={noop}
				showGridHeader={false}
				showPanel={false}
				showHeader={false}
			/>
		</div>
	);
};
