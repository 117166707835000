import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { toLower } from 'lodash';

import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { CurrencyMap } from 'common/utilities';
import { giftService } from 'common/services';
import GiftCard from 'common/components/gift-report/gift';
import Balance from 'common/components/gift-report/dollar';
import { withLoader } from 'common/components/loader';

const invalidCardNumberMessage = 'Please enter a valid card number';

const requestKeys = {
	FETCH: 'fetch',
};

class GiftActivityBreakdownGridHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			giftCardNumber: '',
			maskedCardNumber: '',
		};
	}
	isValidGiftCardNumber = (maskedCardNumber = this.state.maskedCardNumber) => {
		if (maskedCardNumber.length < 12 || maskedCardNumber.length > 19) {
			return false;
		}
		return true;
	};

	async componentDidMount() {
		try {
			const { giftCardNumber } = this.state;
			if (giftCardNumber.length > 15) {
				await this.getRemainingBalance(giftCardNumber);
			}
		} catch (e) {
			this.props.handleError(e);
		}
	}
	handleGridHeaderChange = async state => {
		this.setState(state);
	};

	handleGiftCardNumberChange = ({ value }) => {
		const newState = { maskedCardNumber: value };
		newState.error = this.isValidGiftCardNumber(value) ? '' : invalidCardNumberMessage;
		this.handleGridHeaderChange(newState);
	};

	handleApply = async e => {
		e.preventDefault();
		if (!this.props.isLoading) {
			const isValid = this.isValidGiftCardNumber();
			this.handleGridHeaderChange({
				error: isValid ? '' : invalidCardNumberMessage,
			});
			if (isValid) {
				await this.getRemainingBalance();
			}
			this.props.showLoader(false);
		}
	};

	getRemainingBalance = async (giftCardNumber = this.state.maskedCardNumber) => {
		try {
			this.props.showLoader(true);
			const { xStatus, xRemainingBalance } = await this.props.makePendingRequest(
				giftService.getRemainingBalance(giftCardNumber),
				requestKeys.FETCH
			);
			if (toLower(xStatus) === 'approved') {
				this.handleGridHeaderChange({
					remainingBalance: xRemainingBalance,
				});
			}
		} catch (e) {
			const { message } = e;
			if (message === 'Invalid GiftCard Number') {
				this.setState({
					error: message,
				});
			}
		}
		this.props.showLoader(false);
	};

	render() {
		const { maskedCardNumber, remainingBalance, error } = this.state;
		const { isLoading } = this.props;

		return (
			<Fragment>
				<div className="gift-balance__popup__grid spc--bottom--sml">
					<div className="giftreport__aside__card-number__icon">
						<div className="display--f">
							<GiftCard />
						</div>
					</div>
					<div>
						{error && <p className="type--error spc--bottom--tny spc--bottom--sml">{error}</p>}
						<form onSubmit={this.handleApply}>
							<div className="flex--primary flex--nowrap">
								<NumberFormat
									name="giftCardNumber"
									className="input input--sml"
									placeholder="Enter gift card number"
									value={maskedCardNumber}
									inputMode="numeric"
									onValueChange={this.handleGiftCardNumberChange}
								/>
								<button type="submit" className="btn btn--primary btn--sml spc--left--tny">
									Apply
								</button>
							</div>
						</form>
					</div>
				</div>
				<div>
					{isLoading ? (
						<div className="loader__holder">
							<div className="loader__spinner"></div>
						</div>
					) : (
						<div className="gift-balance__popup__grid">
							<div className="giftreport__aside__card-number__icon">
								<Balance />
							</div>
							<div className="flex--primary flex--nowrap">
								<div className="spc--right--xsml">
									{remainingBalance ? (
										<NumberFormat
											prefix={CurrencyMap.resolveCurrency()}
											value={remainingBalance}
											displayType="text"
											thousandSeparator={true}
											decimalScale={2}
											fixedDecimalScale={true}
											className="giftreport__aside__value__number"
										/>
									) : (
										`${CurrencyMap.resolveCurrency()} -`
									)}
									<span className="giftreport__aside__label">Remaining balance</span>
								</div>
								<div className="datatooltip--w--200 datatooltip--top-left">
									<div data-tooltip="Please enter gift card number to see remaining balance">
										<i className="icon icon--tiny icon--info--note"/>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</Fragment>
		);
	}
}

GiftActivityBreakdownGridHeader.propTypes = {
	data: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleError: PropTypes.func.isRequired,
	makePendingRequest: PropTypes.func.isRequired,
	showLoader: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	renderTitle: PropTypes.func.isRequired,
};

export default withError(withLoader(withCancelable(GiftActivityBreakdownGridHeader)));
