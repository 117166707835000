import httpService from './httpService';
import { take, map } from 'lodash';
import moment from 'moment';
import 'rss-parser/dist/rss-parser.min.js'; // https://github.com/bobby-brennan/rss-parser/issues/53

const endpoint = ApplicationSettings.rssEndpoint;

class RssService {
	constructor(httpService) {
		this.httpService = httpService;
	}

	async getFeed(itemsNum = 4) {
		try {
			let parser = new window.RSSParser();
			const feed = await parser.parseURL(endpoint);
			return this.formatResponse(feed, itemsNum);
		} catch (ex) {
			//eslint-disable-next-line
			console.log('Error', ex);
			return null;
		}
	}

	formatResponse = (feed, itemsNum) => {
		let obj = {
			title: feed.title || null,
			description: feed.description || null,
			lastUpdate: moment(feed.lastBuildDate) || null,
			link: feed.link || null,
			items: [],
		};

		if (feed.items) {
			obj.items = map(take(feed.items, itemsNum), e => {
				return {
					title: e.title || null,
					link: e.link || null,
					categories: e.categories || null,
					published: moment(e.isoDate) || null,
					publishedWords: moment(e.isoDate).fromNow() || null,
				};
			});
		}

		return obj;
	};
}

const rssService = new RssService(httpService);
export default rssService;
