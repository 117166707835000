import { padStart, times } from 'lodash-es';

function isTransactionCustomLabel(customKey, originalCustomKey, convenienceCustomKey) {
	return customKey === originalCustomKey || customKey === convenienceCustomKey;
}
function printUtils(data, customDisplayLabels, renderReceiptItem, zeroBased) {
	const originalCustomKey = data.originalCustomKey;
	const convenienceCustomKey = data.convenienceCustomKey;
	const customData = [];
	if (!customDisplayLabels) {
		customDisplayLabels = data.customDisplayLabels;
	}
	if (originalCustomKey) {
		customData.push(
			renderReceiptItem('Original amount', data[originalCustomKey]),
			renderReceiptItem('Electronic Transfer Fee', data[convenienceCustomKey])
		);
	}
	times(19, i => {
		const startWithZeroIndex = padStart(i + 1, 2, 0);
		const oneBasedIndex = padStart(i + 1, 1);
		const index = zeroBased ? startWithZeroIndex : oneBasedIndex;
		if (
			data[`xCustom${index}`] &&
			!isTransactionCustomLabel(`xCustom${index}`, originalCustomKey, convenienceCustomKey)
		) {
			customData.push(
				renderReceiptItem(
					(customDisplayLabels && customDisplayLabels[`custom${oneBasedIndex}`]) || `Custom ${oneBasedIndex}`,
					data[`xCustom${index}`],
					true
				)
			);
		}
	});

	return customData;
}

export default printUtils;
