import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { map, some } from 'lodash';

import AddEditCustomerGeneralAddEdit from './add-edit';

class AddEditCustomerGeneral extends Component {
	onChange = change => {
		this.props.onChange(change);
	};

	onCustomBlur = fieldName => {
		this.props.onCustomBlur(fieldName);
	};

	render() {
		const {
			data,
			refNum,
			errorMessages,
			advancedView,
			isCanadian,
			isExpanded,
			isLoading,
			requiredFields,
			blurredField,
			savedGeneral,
			renderFailedToFetch,
			customerRequiredFields,
			customDisplayLabels,
			customerHiddenFields,
			isViewOnly,
		} = this.props;

		return (
			<Fragment>
				{(data.customerId || refNum) && (
					<div className="spc--bottom--med">
						{data.customerId && (
							<span className="spc--top--sml type--wgt--medium type--color--text spc--right--med">
								Customer ID: {data.customerId}
							</span>
						)}
						{refNum ? (
							<span className="spc--bottom--sml type--wgt--medium type--color--text spc--bottom--med">(#{refNum})</span>
						) : null}
					</div>
				)}
				{!advancedView && (
					<Fragment>
						{renderFailedToFetch('spc--bottom--sml', 'message message--warning fullwidth')}
						{!some(['billFirstName', 'billLastName', 'billCompany'], field => data[field]) && (
							<div className="message message--primary fullwidth type--center spc--bottom--med">
								{`${customDisplayLabels['firstName'] || 'First Name'}, ${customDisplayLabels['lastName'] ||
									'Last Name'} or ${customDisplayLabels['company'] || 'Company'} is required.`}
							</div>
						)}
					</Fragment>
				)}
				{map(errorMessages, (errorMessage, index) => (
					<div key={index} className="validation spc--bottom--med">
						<p className="type--error">{errorMessage}</p>
					</div>
				))}
				<AddEditCustomerGeneralAddEdit
					data={data}
					onChange={this.onChange}
					onCustomBlur={this.onCustomBlur}
					blurredField={blurredField}
					savedGeneral={savedGeneral}
					advancedView={advancedView}
					isCanadian={isCanadian}
					isExpanded={isExpanded}
					isLoading={isLoading}
					requiredFields={requiredFields}
					customDisplayLabels={customDisplayLabels}
					customerRequiredFields={customerRequiredFields}
					customerHiddenFields={customerHiddenFields}
					isViewOnly={isViewOnly}
				/>
			</Fragment>
		);
	}
}

AddEditCustomerGeneral.propTypes = {
	data: PropTypes.object,
	errorMessages: PropTypes.arrayOf(PropTypes.string),
	refNum: PropTypes.string,
	onChange: PropTypes.func,
	onCustomBlur: PropTypes.func,
	advancedView: PropTypes.bool.isRequired,
	isCanadian: PropTypes.bool,
	isExpanded: PropTypes.bool,
	isViewOnly: PropTypes.bool,
	isLoading: PropTypes.bool.isRequired,
	requiredFields: PropTypes.object,
	customDisplayLabels: PropTypes.object,
	blurredField: PropTypes.any,
	savedGeneral: PropTypes.any,
	renderFailedToFetch: PropTypes.func.isRequired,
	customerRequiredFields: PropTypes.object,
	customerDisplayLabels: PropTypes.object,
	customerHiddenFields: PropTypes.object,
};

export default AddEditCustomerGeneral;
