import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { CurrencyMap } from './../../../utilities';

class CurrencyCodesComponent extends Component {
	render() {
		const { value } = this.props;
		return <div>{CurrencyMap.isoCodesMap[value] || value}</div>;
	}
}

CurrencyCodesComponent.propTypes = {
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	dependentValues: PropTypes.object,
};

export default CurrencyCodesComponent;
