import React, { Component } from 'react';
import propTypes from 'prop-types';
import { map, toLower } from 'lodash';
import { OutsideClick, history } from 'common/utilities';
import { principalService } from 'common/services';

class BaseTitle extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showLinksDropDown: false,
		};
		this.principal = principalService.get() || { hasAccess: () => false };
	}

	get linksActiveClass() {
		return this.state.showLinksDropDown ? 'is-active' : 'is-closed';
	}

	onCloseLinksDropDown = () => {
		if (!this.state.showLinksDropDown) return;
		this.setState({
			showLinksDropDown: false,
		});
	};
	toggleLinksDropDown = () => {
		this.setState({
			showLinksDropDown: !this.state.showLinksDropDown,
		});
	};

	handleRouteChange = pathname => {
		history.push({ pathname });
	};
	renderBadge = title => {
		if (!this.props.withBadge) return null;
		return (
			toLower(title) === 'templates' && (
				<img className="header__title__badge" src="/static/media/new-badge.png" alt="Red badge for new feature"></img>
			)
		);
	};

	render = () => {
		const {
			location: { pathname },
		} = history;
		const { titles } = this.props;

		return (
			<OutsideClick action={this.onCloseLinksDropDown} className="header__title__holder">
				<React.Fragment>
					<div className="pos--rel hide--from--med">
						<button
							onClick={this.toggleLinksDropDown}
							className={`header__title datatooltip--gift type--wgt--medium ${this.linksActiveClass}`}
						>
							<span className="hide--to--med--inline">
								{titles[pathname].title}
								{this.renderBadge(titles[pathname].title)}
							</span>
							<span className="hide--from--med--inline">
								{titles[pathname].title}
								{this.renderBadge(titles[pathname].title)}
							</span>
							<i className="icon icon--tiny icon--arrow--dropdown is-active align--v--neg--2 spc--left--xsml"></i>
						</button>
						{this.state.showLinksDropDown ? (
							<div className={`header__title__menu ${this.linksActiveClass}`}>
								<ul className="header__title__menu__list">
									{map(titles, ({ title, sectionKey }, key) =>
										key !== pathname && (!sectionKey || this.principal.hasAccess[sectionKey]) ? (
											<li key={key} className="header__title__menu__item">
												<button onClick={() => this.handleRouteChange(key)} className="header__title__menu__link">
													{title}
													{this.renderBadge(title)}
												</button>
											</li>
										) : null
									)}
								</ul>
							</div>
						) : null}
					</div>
					<div className="hide--to--med">
						{map(titles, ({ title, sectionKey }, key) =>
							!sectionKey || this.principal.hasAccess[sectionKey] ? (
								<button
									key={key}
									href="#"
									onClick={e => {
										e.preventDefault();
										this.handleRouteChange(key);
									}}
									className={`header__title__link ${key === pathname ? 'is-active' : ''}`}
								>
									{title}
								</button>
							) : null
						)}
					</div>
				</React.Fragment>
			</OutsideClick>
		);
	};
}
BaseTitle.propTypes = {
	titles: propTypes.object.isRequired,
	withBadge: propTypes.bool,
};
export default BaseTitle;
