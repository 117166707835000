import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

const ImageWithFallback = ({ children, imgAttributes, className }) => {
	const [hasError, setHasError] = useState(false);

	const handleError = useCallback(() => {
		window.domainLogoUrl = null;
		setHasError(true);
	}, []);

	if (hasError || !window.domainLogoUrl) {
		return children(imgAttributes);
	}

	return (
		<img src={window.domainLogoUrl} onError={handleError} className={className} alt="Company Logo" {...imgAttributes} />
	);
};

ImageWithFallback.propTypes = {
	children: PropTypes.func.isRequired,
	imgAttributes: PropTypes.object,
	className: PropTypes.string,
};

export default ImageWithFallback;
