import React, { Component } from 'react';

import { withLoader } from '../../../Common/components/loader';

class FraudComponent extends Component {
	constructor(props) {
		super(props);
	}

	renderSaveButton = () => (
		<React.Fragment>
			<span className="settings__header__action__text">
				Settings are saved only after clicking on <strong>Save</strong> button
			</span>{' '}
			<button className="btn btn--primary btn--med settings__header__action__btn" onClick={this.save}>
				Save
			</button>
		</React.Fragment>
	);

	render = () => (
		<div className="settings--main settings--main--alt">
			<div className="settings__header">
				<div className="settings__header__title">Fraud Settings</div>
				<div className="settings__header__action">{this.renderSaveButton()}</div>
			</div>
			<div className="fraud__heading">Allow Country</div>
			<div className="spc--bottom--med">
				<label className="field__label--form" htmlFor="errorMessage">
					Error Message
				</label>
				<input
					name="errorMessage"
					type="text"
					className="input input--med"
					placeholder="Declined - Contact Support (CI)"
				/>
			</div>
			<div className="listbox listbox--double">
				<div className="listbox--double__item">
					<label className="field__label--form">List of Countries to Allow</label>
					<select name="deny" multiple={true} className="listbox__select">
						<option className="listbox__select__option">Australia</option>
						<option className="listbox__select__option">Austria</option>
						<option className="listbox__select__option">Belgium</option>
						<option className="listbox__select__option">Brazil</option>
					</select>
				</div>
				<div className="listbox--double__action">
					<button type="button" className="btn btn--primary btn--med fullwidth spc--bottom--med">
						{'Add to Deny >'}
					</button>
					<button type="button" className="btn btn--default btn--med fullwidth spc--bottom--med">
						{'< Add to Allow'}
					</button>
				</div>
				<div className="listbox--double__item">
					<div>List of Countries to Deny</div>
					<select name="deny" multiple={true} className="listbox__select">
						<option className="listbox__select__option">Venezuela</option>
					</select>
				</div>
			</div>
			<div className="fraud__heading">Block Credit Card (CC)</div>
			<div className="spc--bottom--med">
				<label className="field__label--form" htmlFor="errorCC">
					Error CC
				</label>
				<input name="errorCC" type="text" className="input input--med" placeholder="Declined - Contact Support (CI)" />
			</div>
			<div>
				<label className="field__label--form" htmlFor="addNewCard">
					Add New Card
				</label>
				<div className="row">
					<div className="col col-sml-12 col-med-6 spc--bottom--med">
						<input name="addNewCard" type="text" className="input input--med" placeholder="Card Number" />
					</div>
					<div className="col col-sml-12 col-med-6 spc--bottom--med">
						<div className="inputgroup">
							<div className="inputgroup--main">
								<input name="note" type="text" className="input input--med" placeholder="Note" />
							</div>
							<div className="inputgroup--aside">
								<button type="button" className="btn btn--primary btn--med spc--left--xsml">
									Add
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="dtable__scrollholder spc--bottom--lrg">
				<table className="dtable">
					<tbody className="dtable__body">
						<tr className="dtable__row">
							<th className="dtable__header__cell">Added Date</th>
							<th className="dtable__header__cell">Card Number</th>
							<th className="dtable__header__cell">Note</th>
						</tr>
						<tr className="dtable__row">
							<td className="dtable__cell">placeholder</td>
							<td className="dtable__cell">placeholder</td>
							<td className="dtable__cell">placeholder</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="fraud__heading">Block IP</div>
			<div className="spc--bottom--med">
				<label className="field__label--form" htmlFor="ipCustom">
					Error IP Custom
				</label>
				<input name="ipCustom" type="text" className="input input--med" placeholder="Declined - Contact Support (CI)" />
			</div>
			<div className="row">
				<div className="col col-sml-12 col-med-6 spc--bottom--med">
					<div className="group">
						<div className="fraud__customlabel">
							<label className="field__label--form" htmlFor="ip3">
								Block IP 3
							</label>
						</div>
						<div className="fraud__customfield">
							<input name="ip3" type="text" className="input input--med" placeholder="1 . 2 . 3 . 4" />
						</div>
					</div>
				</div>
				<div className="col col-sml-12 col-med-6 spc--bottom--med">
					<div className="group">
						<div className="fraud__customlabel">
							<label className="field__label--form" htmlFor="ip4">
								Block IP 4
							</label>
						</div>
						<div className="fraud__customfield">
							<input name="ip4" type="text" className="input input--med" placeholder="1 . 2 . 3 . 4" />
						</div>
					</div>
				</div>
			</div>
			<div className="listbox row">
				<div className="col col-sml-12 col-lrg-6">
					<label className="field__label--form">List of blocked IPs</label>
					<select name="deny" multiple={true} className="listbox__select">
						<option className="listbox__select__option">88.22.11.88</option>
						<option className="listbox__select__option">88.22.11.88</option>
						<option className="listbox__select__option">88.22.11.88</option>
						<option className="listbox__select__option">88.22.11.88</option>
					</select>
					<div className="spc--top--sml type--right">
						<button type="button" className="btn btn--primary btn--med">
							Unblock
						</button>
					</div>
				</div>
				<div className="col col-sml-12 col-lrg-6">
					<label className="field__label--form">List of blocked IPs</label>
					<select name="deny" multiple={true} className="listbox__select">
						<option className="listbox__select__option">88.22.11.88</option>
						<option className="listbox__select__option">88.22.11.88</option>
						<option className="listbox__select__option">88.22.11.88</option>
						<option className="listbox__select__option">88.22.11.88</option>
					</select>
					<div className="spc--top--sml type--right">
						<button type="button" className="btn btn--primary btn--med">
							Unblock
						</button>
					</div>
				</div>
			</div>
			<div className="fraud__heading">Block Velocity</div>
			<div className="spc--bottom--med">
				<label className="field__label--form" htmlFor="velocityCustom">
					Block Velocity Custom Error
				</label>
				<input
					name="velocityCustom"
					type="text"
					className="input input--med"
					placeholder="Declined - Contact Support (I)"
				/>
			</div>
			<div className="row">
				<div className="col col-sml-12 col-lrg-6 spc--bottom--med">
					<label className="field__label--form" htmlFor="velocityCustom">
						Block Velocity - Number of Cards
					</label>
					<input name="velocityCustom" type="text" className="input input--med" placeholder="-1" />
				</div>
				<div className="col col-sml-12 col-lrg-6 spc--bottom--med">
					<label className="field__label--form" htmlFor="velocityCustom">
						Block Velocity - Number of Declines per Card
					</label>
					<input name="velocityCustom" type="text" className="input input--med" placeholder="-1" />
				</div>
			</div>
			<div className="fraud__heading">Block the Following:</div>
			<div className="row type--wgt--medium">
				<div className="col col-sml-12 col-med-6 col-lrg-9">
					<div className="spc--bottom--med">
						<input type="checkbox" value="Keyed" name="keyed" id="keyed" className="input input--check" />
						<label htmlFor="keyed">Keyed</label>
					</div>
					<div className="spc--bottom--med">
						<input
							type="checkbox"
							value="Keyed Custom Error"
							name="keyedCustomError"
							id="keyedCustomError"
							className="input input--check"
						/>
						<label htmlFor="keyedCustomError">Keyed Custom Error</label>
					</div>
					<div className="spc--bottom--med">
						<input type="checkbox" value="Debit" name="debit" id="debit" className="input input--check" />
						<label htmlFor="debit">Debit</label>
					</div>
					<div className="spc--bottom--med">
						<input type="checkbox" value="Credit" name="credit" id="credit" className="input input--check" />
						<label htmlFor="credit">Credit</label>
					</div>
					<div className="spc--bottom--med">
						<input
							type="checkbox"
							value="Prepaid Card"
							name="prepaidCard"
							id="prepaidCard"
							className="input input--check"
						/>
						<label htmlFor="prepaidCard">Prepaid Card</label>
					</div>
				</div>
				<div className="col col-sml-12 col-med-6 col-lrg-3">
					<div className="spc--bottom--med">
						<input type="checkbox" value="Visa" name="visa" id="visa" className="input input--check" />
						<label htmlFor="visa">Visa</label>
					</div>
					<div className="spc--bottom--med">
						<input
							type="checkbox"
							value="Mastercard"
							name="mastercard"
							id="mastercard"
							className="input input--check"
						/>
						<label htmlFor="mastercard">Mastercard</label>
					</div>
					<div className="spc--bottom--med">
						<input type="checkbox" value="Discover" name="discover" id="discover" className="input input--check" />
						<label htmlFor="discover">Discover</label>
					</div>
					<div className="spc--bottom--med">
						<input type="checkbox" value="Amex" name="amex" id="amex" className="input input--check" />
						<label htmlFor="amex">Amex</label>
					</div>
				</div>
			</div>
			<hr className="separator separator--grey1 spc--bottom--med" />
			<div className="spc--bottom--sml">
				<input
					type="checkbox"
					value="alertIf"
					name="alertIf"
					id="alertIf"
					className="input input--check input--check--withinput"
				/>
				<label htmlFor="alertIf" className="field__label--form">
					Email an alert if
					<input
						type="number"
						className="input input--med w--max--54 type--center spc--left--xsml spc--right--xsml"
						value=""
						placeholder="12"
						name="number"
					/>
					declined transactions to
				</label>
			</div>
			<div className="spc--bottom--med">
				<input type="text" className="input input--med" />
			</div>
			<hr className="separator separator--grey1 spc--bottom--med" />
			<footer className="group spc--bottom--med">
				<div className="settings__header__action">{this.renderSaveButton()}</div>
			</footer>
		</div>
	);
}

export default withLoader(FraudComponent);
