import { transform, identity, map, replace } from 'lodash';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';

export const exportOptions = {
	quoteStrings: '"',
	showLabels: true,
	useKeysAsHeaders: true,
};

const { apiResponseDateTimeFormat, displayDateFormat } = ApplicationSettings;

const removeSpecialCharacters = string => replace(string, /[^\w\d\s]/gi, '');

export const exportTypes = [
	{
		name: 'CSV',
		key: 'csv',
		export: (filename, data) => {
			const exporter = new ExportToCsv({ filename, ...exportOptions });
			return exporter.generateCsv(data);
		},
		getColumns: ({ columns }) => columns,
		cleanData: identity,
	},
	{
		name: 'Quickbooks',
		key: 'quickbooks',
		export: (filename, data) => {
			const exporter = new ExportToCsv({ filename, quoteStrings: '', showLabels: true, useKeysAsHeaders: true });
			return exporter.generateCsv(data);
		},
		getColumns: () => [
			{
				key: 'date',
				name: 'Date',
			},
			{
				key: 'description',
				name: 'Description',
			},
			{
				key: 'amount',
				name: 'Amount',
			},
		],
		cleanData: data =>
			map(data, ({ xEnteredDate, xDescription, xAmount }) => ({
				date: moment(xEnteredDate, apiResponseDateTimeFormat).format(displayDateFormat),
				description: removeSpecialCharacters(xDescription),
				amount: parseFloat(xAmount) || '',
			})),
	},
];

export const exportTypesByKey = transform(
	exportTypes,
	(acc, type) => {
		acc[type.key] = type;
	},
	{}
);
