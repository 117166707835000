import React from 'react';
import TooltipFormatter from './TooltipFormatter';

class CellTooltipFormatter extends TooltipFormatter {
	static instances = [];
	constructor(props) {
		super(props, CellTooltipFormatter.instances);
		this.tooltipClass = 'recurring__tooltip recurring__tooltip--alt';
	}

	get tooltip() {
		const { value } = this.props;
		return <div className="type--wgt--bold">{value}</div>;
	}

	render() {
		return (
			<span ref={this.elementRef}>
				<span onMouseEnter={this.displayPopup} onMouseLeave={this.closePopup}>
					{this.props.value}
				</span>
			</span>
		);
	}
}

export default CellTooltipFormatter;
