import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isArray, map, toLower, cloneDeep, filter, size, startsWith, some, isEmpty } from 'lodash';

class PaymentSiteAvailableFields extends Component {
	constructor(props) {
		super(props);
	}
	static propTypes = {
		availableFields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
		isToggle: PropTypes.bool,
		isAdmin: PropTypes.bool,
		isGoPlus: PropTypes.bool,
		isKioskTheme: PropTypes.bool,
		entireBilling: PropTypes.bool,
		entireShipping: PropTypes.bool,
		toggleSelect: PropTypes.func,
		shouldValidateAmount: PropTypes.func,
	};

	static defaultProps = {
		isToggle: false,
		isAdmin: false,
		isKioskTheme: false,
		entireShipping: false,
		entireBilling: false,
		isGoPlus: false,
	};

	renderAvailableFields = () => {
		const { availableFields, isToggle, isKioskTheme, isAdmin, entireBilling, entireShipping, isGoPlus } = this.props;

		let fieldsArray = cloneDeep(availableFields);
		if (!isArray(availableFields)) {
			fieldsArray = [availableFields];
		}

		if (size(fieldsArray) > 1) {
			if (entireShipping) {
				fieldsArray = filter(fieldsArray, ({ key }) => key !== 'shipping');
			}
			if (entireBilling) {
				fieldsArray = filter(fieldsArray, ({ key }) => key !== 'billing');
			}
		} else {
			const shippingKey = entireShipping ? 'xShip' : '';
			const billingKey = entireBilling ? 'xBill' : '';
			fieldsArray = filter(fieldsArray, ({ fields }) =>
				filter(fields, ({ key }) => !startsWith(key, shippingKey) && !startsWith(key, billingKey))
			);
		}

		const filteredFieldsArray = filter(fieldsArray, ({ fields, internalSection, label }) => {
			const isShippingInfo = toLower(label) === 'shipping information';
			if ((internalSection && !isAdmin) || (isKioskTheme && isShippingInfo)) {
				return false;
			}
			return some(fields, ({ isSelected }) => !isSelected);
		});

		if (isEmpty(filteredFieldsArray)) {
			return (
				<div className="paymentsite__popover paymentsite__popover--card">
					<ul>
						<li className="all-selected">
							<i className="icon icon--sml icon--check--success"></i>
							<span>All Fields Selected</span>
						</li>
					</ul>
				</div>
			);
		}

		return (
			<div className="paymentsite__popover paymentsite__popover--card">
				<ul>
					{map(filteredFieldsArray, ({ fields }) => {
						return map(
							fields,
							({
								key,
								isSelected,
								name,
								toggleSelect,
								customLabel,
								isConvFeeCustomField,
								shouldValidateAmount,
								disable: fieldDisabled,
								internalSetting,
								originalCustom,
								convFeeCustom,
								isProPay,
							}) => {
								if (
									(isKioskTheme && key === 'xAmount') ||
									(isProPay && !isGoPlus && !isAdmin && (key === 'xRecurring' || key === 'customers_schedules'))
								) {
									return null;
								}
								const disabled = key === 'xAmount' && isSelected && shouldValidateAmount && shouldValidateAmount();
								const customTooltip = isConvFeeCustomField || fieldDisabled || originalCustom || convFeeCustom;
								if ((internalSetting && !isAdmin) || (isSelected && !isToggle)) {
									return null;
								}
								return (
									<li className={` ${isSelected ? 'cursor--default' : ''}`} key={key}>
										<input
											id={key}
											name={key}
											className="input--check input--check--sml"
											type="checkbox"
											checked={isSelected}
											disabled={isConvFeeCustomField || disabled || fieldDisabled || originalCustom || convFeeCustom}
											onChange={toggleSelect}
										/>
										<label
											htmlFor={key}
											className={customTooltip ? 'datatooltip--left datatooltip--w--200' : 'datatooltip--no-wrap'}
										>
											{name}
										</label>
										{customLabel && <div data-tooltip={customLabel}><i className="icon icon--tiny icon--info"></i></div>}
									</li>
								);
							}
						);
					})}
				</ul>
			</div>
		);
	};
	render() {
		return <Fragment>{this.renderAvailableFields()}</Fragment>;
	}
}

export default PaymentSiteAvailableFields;
