import { replace } from 'lodash';

import { checkIfCanadian } from './check-if-canadian';

export const setToCanadianVerbiage = label => {
	if (checkIfCanadian()) {
		label = replace(label, new RegExp(/zip\b/g, 'i'), 'Postal Code');
		label = replace(label, new RegExp(/state\b/g, 'i'), 'Province');
	}
	return label;
};
