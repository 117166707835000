import { withCancelable } from 'common/components/cancelable';
import { withError } from 'common/components/error';
import { withLoader } from 'common/components/loader';
import { Notification } from 'common/components/notifications';
import { ActionsModal, modalNames } from 'common/components/transaction-actions';
import UserAccountPanel from 'common/components/user-account-panel/user-account-panel';
import { validators } from 'common/fields';
import { customerService, principalService } from 'common/services';
import Title from 'components/customer-grid/title';
import { get, isEmpty, split, trim } from 'lodash';
import React from 'react';
import { propTypes } from 'react-keyboard-event-handler';

const MIGRATION_OPTIONS = {
	MIGRATE_ALL: 'migrateAll',
	MIGRATE_SPECIFIC: 'migrateSpecific',
};

class CustomersMigrationTool extends React.Component {
	constructor(props) {
		super(props);
		this.principal = principalService.get();
		this.notificationRef = React.createRef();
		this.state = this.initialState;
	}

	get initialState() {
		return {
			selectedOption: MIGRATION_OPTIONS.MIGRATE_ALL,
			migrateTo: '',
			ticketNumber: '',
			customerIds: [],
			merchantEmail: '',
			cardknoxMid: get(this.principal, 'id', ''),
		};
	}
	get modalData() {
		return {
			name: modalNames.confirmAction,
			data: {
				question: `${this.state.selectedOption === MIGRATION_OPTIONS.MIGRATE_SPECIFIC? 'The specified' : 'All'} Customers and Payment Methods will be copied from Cardknox Mid ${this.state.cardknoxMid}, to Cardknox Mid ${this.state.migrateTo}.`,
				onConfirm: () => this.handleMigrateCustomers(),
			},
		};
	}
	get isFormValid() {
		const { selectedOption, migrateTo, customerIds, merchantEmail } = this.state;
		const hasTicketOrMid = migrateTo === '' || this.state.ticketNumber === '';
		const merchantEmailInvalid = !validators.email(merchantEmail);
		return (
			(merchantEmailInvalid && (selectedOption === MIGRATION_OPTIONS.MIGRATE_ALL && hasTicketOrMid)) ||
			(selectedOption === MIGRATION_OPTIONS.MIGRATE_SPECIFIC && (hasTicketOrMid || customerIds.length === 0))
		);
	}
	handleShowNotification = (notification, error = {}) => {
		if (!isEmpty(error)) {
			notification.message = error.message;
		}
		notification.show();
	};

	handleMigrateCustomers = async () => {
		const { cardknoxMid, migrateTo, ticketNumber, merchantEmail, customerIds, selectedOption } = this.state;
		try {
			const res = await this.props.makePendingRequest(
				customerService.transferCustomerDatabase(
					cardknoxMid,
					migrateTo,
					ticketNumber,
					merchantEmail,
					selectedOption === MIGRATION_OPTIONS.MIGRATE_SPECIFIC ? customerIds : []
				),
				'fetch'
			);
			const ref = get(res, 'refNum', '');
			if (this.notificationRef.current) {
				this.notificationRef.current.addNotification({
					message: 'Customer migration was initiated successfully. You will receive an email with the detailed results and the information will be added to the ticket .',
					success: true,
					ref,
				});
			}
			this.setState(this.initialState);
		} catch (e) {
			const notification = this.props.handleError(e, { delayMessage: true });
			this.handleShowNotification(notification, e.ex);
		}
	};

	handleOptionChange = event => {
		this.setState({
			selectedOption: event.target.value,
		});
	};

	handleInputChange = event => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	onCustomerIdsChange = e => {
		const input = e.target.value;
		const customerIds = split(input, ',').map(id => trim(id));
		this.setState({ customerIds });
	};
	togglePopupActive = () =>
		this.setState(prevState => {
			return { popupActive: !prevState.popupActive };
		});

	renderHeader = () => (
		<header className="header">
			<div className="header__title">
				<Title />
			</div>
			<div className="header__menu">
				<UserAccountPanel />
			</div>
		</header>
	);

	render() {
		return (
			<React.Fragment>
				{this.state.popupActive && <ActionsModal modal={this.modalData} onModalClose={this.togglePopupActive} />}
				<Notification ref={this.notificationRef} />
				{this.renderHeader()}
				<div className="l--content--centered__wrapper">
					<div className="l--content--centered">
						<div className="spr__card">
							<div className="f-row f-row--bottom">
								<div className="f-col f-col-sml-12 f-col-lrg-6 spc--bottom--med">
									<label className="label" htmlFor="migrateTo">
									Destination MID<span className="label--required">*</span>
									</label>
									<input
										id="migrateTo"
										className="input input--med"
										name="migrateTo"
										value={this.state.migrateTo}
										onChange={this.handleInputChange}
									/>
								</div>
								<div className="f-col f-col-sml-12 f-col-lrg-6 spc--bottom--med">
									<label className="label" htmlFor="ticketNumber">
										Ticket Number<span className="label--required">*</span>
									</label>
									<input
										id="ticketNumber"
										className="input input--med"
										name="ticketNumber"
										value={this.state.ticketNumber}
										onChange={this.handleInputChange}
									/>
								</div>
								<div className="f-col f-col-sml-12 f-col-lrg-6 spc--bottom--med">
									<label className="label" htmlFor="ticketNumber">
										Email<span className="label--required">*</span>
									</label>
									<input
										id="merchantEmail"
										className="input input--med"
										name="merchantEmail"
										value={this.state.merchantEmail}
										onChange={this.handleInputChange}
										type="email"
									/>
								</div>
								<div className="f-col f-col-sml-12 spc--bottom--sml">
									<div className="flex--primary flex--gap--30 spc--bottom--xsml">
										<div>
											<input
												type="radio"
												id="migrateAll"
												value={MIGRATION_OPTIONS.MIGRATE_ALL}
												className="input--radio"
												checked={this.state.selectedOption === MIGRATION_OPTIONS.MIGRATE_ALL}
												onChange={this.handleOptionChange}
											/>
											<label htmlFor="migrateAll">Migrate All Customers</label>
										</div>
										<div>
											<input
												type="radio"
												id="migrateSpecific"
												value={MIGRATION_OPTIONS.MIGRATE_SPECIFIC}
												className="input--radio"
												checked={this.state.selectedOption === MIGRATION_OPTIONS.MIGRATE_SPECIFIC}
												onChange={this.handleOptionChange}
											/>
											<label htmlFor="migrateSpecific">Migrate Specific Customer Ids</label>
										</div>
									</div>
								</div>
								{this.state.selectedOption === 'migrateSpecific' && (
									<div className="f-col f-col-sml-12 spc--bottom--med">
										<label className="label" htmlFor="customerIds">
											Add Customer Ids<span className="label--required">*</span>
										</label>
										<input
											id="customerIds"
											className="input input--med"
											name="customerIds"
											placeholder=""
											onChange={this.onCustomerIdsChange}
											value={this.state.customerIds}
										/>
									</div>
								)}
							</div>
							<button
								className="btn btn--med btn--primary spc--bottom--med"
								disabled={this.isFormValid}
								onClick={this.togglePopupActive}
							>
								Submit
							</button>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

CustomersMigrationTool.propTypes = {
	makePendingRequest: propTypes.func,
	handleError: propTypes.func,
};

export default withError(withLoader(withCancelable(CustomersMigrationTool)));
