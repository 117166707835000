const awsUserStatuses = {
	ARCHIVED: { label: 'Inactive User', color: 'grey' },
	COMPROMISED: { label: 'User Disabled', color: 'warning' },
	CONFIRMED: { label: 'Confirmed User', color: 'success' },
	UNCONFIRMED: { label: 'Unconfirmed User', color: 'note' },
	UNKNOWN: { label: 'Unknown', color: 'grey' },
	NONEXISTENT: { label: 'User Not Found', color: 'dark' },
	FORCE_CHANGE_PASSWORD: { label: 'Reset Password Required Upon Login', color: 'dark' },
	RESET_REQUIRED: { label: 'Request Code Required', color: 'dark' },
};

export default awsUserStatuses;
