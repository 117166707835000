import { toLower } from 'lodash';

const secureResult = ({ value }) => {
	const key = toLower(value);
	if (key === 'n') {
		return 'Not Approved';
	} else if (key === 'y' || key === 'p') {
		return 'Approved';
	} else {
		return '';
	}
};

export default secureResult;
