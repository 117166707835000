import { map, find, clone } from 'lodash';

import { predefinedDates } from 'common/components/date-picker';

const commonProperties = {
	sortDirection: 'asc',
	actions: {
		view: true,
		download: true,
		edit: false,
		delete: false,
	},
};

const mapReport = report => {
	const mappedReport = {
		...commonProperties,
		...report,
	};
	mappedReport.dateFilter = clone(find(predefinedDates, { key: report.date }));
	delete mappedReport.dateFilter.description;
	mappedReport.fetchReport = () => Promise.resolve(mappedReport);
	return mappedReport;
};

export const QuickReports = map(
	[
		{
			name: 'Approved, by source key',
			date: '30',
			sortBy: 'sourceKey',
			filters: {
				status: {
					approved: true,
				},
			},
		},
		{
			name: 'Auths, by source key',
			date: '30',
			sortBy: 'sourceKey',
			filters: {
				transactionType: {
					auth: true,
				},
			},
		},
		{
			name: 'Errors by date',
			date: '30',
			sortBy: 'date',
			filters: {
				status: {
					declined: true,
				},
			},
		},
		{
			name: 'Sales by date',
			date: '30',
			sortBy: 'date',
			filters: {
				transactionType: {
					sale: true,
				},
			},
		},
		{
			name: 'Sales by Custom01',
			date: '30',
			sortBy: 'custom01',
			filters: {
				transactionType: {
					sale: true,
				},
			},
		},
		{
			name: 'Sales by batch number',
			date: '30',
			sortBy: 'batchNumber',
			filters: {
				transactionType: {
					sale: true,
				},
			},
		},
		{
			name: 'Refunds by amount',
			date: '30',
			sortBy: 'amount',
			filters: {
				transactionType: {
					refund: true,
				},
			},
		},
		{
			name: 'Sales by amount',
			date: '30',
			sortBy: 'amount',
			filters: {
				transactionType: {
					sale: true,
				},
			},
		},
	],
	mapReport
);
