import moment from 'moment';
import { find } from 'lodash';

import { localToApiMoment, getDate, getFilter } from '../../../Common/utilities';

const { checkDateFormat } = ApplicationSettings;

export const Filters = [
	{
		key: 'date',
		hasSelection: false,
		defaultHasSelection: false,
		getSelectionText: ({ key, startDate, endDate }, predefinedDates) => {
			const predefinedDate = find(predefinedDates, { key });
			if (predefinedDate) {
				return predefinedDate.displayValue;
			}
			try {
				return `${startDate.format(checkDateFormat)} - ${endDate.format(checkDateFormat)}`;
			} catch (e) {
				return 'Custom';
			}
		},
		type: null,
		values: {
			key: 'yesterday',
			startDate: moment()
				.startOf('day')
				.add(-1, 'day'),
			endDate: moment()
				.endOf('day')
				.add(-1, 'day'),
		},
		defaultValues: {
			key: 'yesterday',
			startDate: moment()
				.startOf('day')
				.add(-1, 'day'),
			endDate: moment()
				.endOf('day')
				.add(-1, 'day'),
		},
		props: {
			maxDaysRange: 7,
		},
	},
];

export const compileFilters = async (filters, filterDateFormat) => {
	const dateFilter = getFilter(filters, 'date');
	const beginDate = await localToApiMoment(dateFilter.values.startDate);
	const endDate = await localToApiMoment(dateFilter.values.endDate);
	const filter = {
		xBeginDate: getDate(beginDate, filterDateFormat),
		xEndDate: getDate(endDate, filterDateFormat),
	};

	return filter;
};
