import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { identity } from 'lodash';

function withBlock(WrappedComponent, shouldBlockHistory = identity) {
	class HistoryBlockComponent extends Component {
		constructor(props) {
			super(props);

			this.state = {
				shouldBlock: false,
			};
		}

		componentDidMount = () => {
			const { history } = this.props;

			this.unblock = history.block((location, action) => {
				const { shouldBlock } = this.state;
				if (shouldBlockHistory(shouldBlock, location, action)) {
					return 'Are you sure you want to leave before saving?';
				}
			});
		};

		componentWillUnmount = () => {
			if (this.unblock) {
				this.unblock();
			}
		};

		handleBlockChange = shouldBlock => {
			this.setState({ shouldBlock });
		};

		render = () => {
			const { forwardedRef, ...rest } = this.props;
			return <WrappedComponent handleBlockChange={this.handleBlockChange} ref={forwardedRef} {...rest} />;
		};
	}

	HistoryBlockComponent.propTypes = {
		history: PropTypes.object,
		forwardedRef: PropTypes.any,
	};

	const HistoryWithRouter = withRouter(HistoryBlockComponent);

	return forwardRef((props, ref) => <HistoryWithRouter {...props} forwardedRef={ref} />);
}

export default withBlock;
