import React from 'react';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';

function giftCardNotification(props) {
	return (
		<div className="">
			<p className="spc--bottom--sml">
				Get started with our Cardknox Gift Card Program to issue, track, and redeem gift cards.
			</p>
			<div className="spc--bottom--sml--alt">
				<button className="btn btn--sml btn--primary" onClick={() => props.history.push('features/gift-card')}>
					Activate
				</button>
				<button
					className="btn btn--sml btn--ghost spc--left--tny"
					onClick={() =>
						window.open(
							'https://www.cardknox.com/gift?utm_source=portal&utm_medium=banner&utm_campaign=cs',
							'_blank',
							'noopener'
						)
					}
				>
					Learn More
				</button>
			</div>
		</div>
	);
}
giftCardNotification.propTypes = {
	history: object.isRequired,
};
export default withRouter(giftCardNotification);
