import { first, last, replace } from 'lodash';

export function fixJsonFormat(data) {
	if (first(data) !== '[' && last(data) !== ']') {
		const fixedJson = replace(data, /\}\{/gi, '},{');
		return JSON.parse(`[${fixedJson}]`);
	}

	return JSON.parse(data);
}
