import React, { Component, Fragment } from 'react';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';
import PropTypes from 'prop-types';
import moment from 'moment';
import { cloneDeep, find, split, map } from 'lodash-es';
import DateFilter from './DateFilter';
import { invokeIfFunction } from 'common/utilities';
import { Tour } from 'common/components/tour';

const tourConfig = {
	version: 1, // increase this every time you make changes to the tourConfig,
	key: 'dateRange',
	steps: [
		{
			selector: '.rc-menu-submenu [title="Custom"]',
			content:
				'Now you can locate transactions for a timeframe that exceeds 90 days. Click here to select your desired dates.',
		},
	],
};

class DatePickerPredefinedComponent extends Component {
	state = {
		showTour: false,
	};

	setDaySelection = key => {
		const { startValue, endValue } = find(this.props.predefinedDates, { key });
		const startDate = moment()
			.startOf('day')
			.add(invokeIfFunction(startValue), 'days');
		const endDate = moment()
			.endOf('day')
			.add(invokeIfFunction(endValue), 'days');
		this.props.onActiveFilterChanged({
			id: 'date',
			values: [
				{ key: 'key', value: key },
				{ key: 'startDate', value: startDate },
				{ key: 'endDate', value: endDate },
				{ key: 'disabled', value: false },
			],
		});
	};

	setCustomDaySelection = range => {
		let filterRange = cloneDeep(range);
		filterRange.from = moment(filterRange.from).startOf('day');
		filterRange.to = moment(filterRange.to).endOf('day');
		if (this.props.displayTime) {
			const [[fromHours, fromMinutes], [toHours, toMinutes]] = map([filterRange.fromTime, filterRange.toTime], time =>
				split(time, ':')
			);
			if (filterRange.fromTime) {
				filterRange.from.hours(fromHours).minutes(fromMinutes);
			}
			if (filterRange.toTime) {
				filterRange.to.hours(toHours).minutes(toMinutes);
			}
		}

		this.props.onActiveFilterChanged({
			id: 'date',
			values: [
				{ key: 'key', value: 'custom' },
				{ key: 'startDate', value: filterRange.from },
				{ key: 'endDate', value: filterRange.to },
				{ key: 'disabled', value: false },
			],
		});
	};

	onOpenChange = activeKeys => {
		const { onOpenChange, showTour } = this.props;
		onOpenChange(activeKeys);
		if (showTour) {
			this.setState({
				showTour: !this.state.showTour,
			});
		}
	};

	renderMenu() {
		const { filter, maxDaysRange, predefinedDates, displayTime, subMenuTitle, activeKeys, onApplyFilter } = this.props;

		return (
			<Menu
				mode={'horizontal'}
				openAnimation={'slide-up'}
				triggerSubMenuAction={'click'}
				openKeys={activeKeys}
				onOpenChange={this.onOpenChange}
				selectedKeys={[filter.values.key.toString()]}
				onClick={({ key }) => this.setDaySelection(key)}
			>
				<SubMenu
					popupClassName="rc-menu-datepicker-tooltip"
					disabled={filter.values.disabled}
					title={subMenuTitle}
					key="date"
				>
					{map(predefinedDates, ({ key, displayValue }) => (
						<MenuItem key={key}>{displayValue}</MenuItem>
					))}
					<SubMenu title="Custom" mode={'vertical-right'} key={'customDate'}>
						<MenuItem disabled>
							<DateFilter
								displayTime={displayTime}
								maxDaysRange={maxDaysRange}
								from={filter.values.startDate.toDate()}
								to={filter.values.endDate.toDate()}
								onApplyFilter={onApplyFilter}
								onChange={this.setCustomDaySelection}
							/>
						</MenuItem>
					</SubMenu>
				</SubMenu>
			</Menu>
		);
	}

	render() {
		const { showTour } = this.state;
		return (
			<Fragment>
				{this.renderMenu()}
				{showTour && <Tour tourConfig={tourConfig} />}
			</Fragment>
		);
	}
}

DatePickerPredefinedComponent.propTypes = {
	filter: PropTypes.any,
	maxDaysRange: PropTypes.number.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
	onActiveFilterChanged: PropTypes.func,
	subMenuTitle: PropTypes.node.isRequired,
	onOpenChange: PropTypes.func.isRequired,
	onApplyFilter: PropTypes.func,
	predefinedDates: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			startValue: PropTypes.oneOfType([PropTypes.number, PropTypes.func]).isRequired,
			endValue: PropTypes.oneOfType([PropTypes.number, PropTypes.func]).isRequired,
			displayValue: PropTypes.string.isRequired,
		})
	).isRequired,
	displayTime: PropTypes.bool,
	activeKeys: PropTypes.any,
	showTour: PropTypes.bool,
};

export default DatePickerPredefinedComponent;
