import React, { Component } from 'react';
import { string } from 'prop-types';
import { toLower } from 'lodash';

class CommandComponent extends Component {
	constructor(props) {
		super(props);
	}

	get commandClasses() {
		return {
			'cc:sale': '',
			'cc:authonly': '',
			'cc:capture': '',
			'cc:save': '',
			'cc:postauth': '',
			'cc:credit': '',
			'cc:refund': '',
			'cc:voidrefund': '',
			'cc:voidrelease': '',
			'cc:void': '',
			'check:sale': '',
			'check:credit': '',
			'check:save': '',
			'check:void': '',
			'check:refund': '',
			'ebtfs:sale': '',
			'ebtfs:credit': '',
			'ebtfs:balance': '',
			'ebtfs:voucher': '',
			'ebtfs:return': '',
			'ebtcb:sale': '',
			'ebtcb:cash': '',
			'ebtcb:balance': '',
			'ebtw:sale': '',
			'ebtw:balance': '',
			'ebtw:void': '',
			'gift:issue': '',
			'gift:redeem': '',
			'gift:balance': '',
			'fraud:submit': '',
		};
	}

	render() {
		const { value } = this.props;
		return <div className={this.commandClasses[toLower(value)]}>{value}</div>;
	}
}

CommandComponent.propTypes = {
	value: string,
};

export default CommandComponent;
