import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { map, toLower, isEmpty } from 'lodash';

import { principalService } from '../../../../services';
import { CardTypeImagePath } from 'common/utilities';
import { modalNames } from 'common/components/transaction-actions/modal-names';
import { Tour } from 'common/components/tour';
const { ifieldsSource } = ApplicationSettings;
const commonStyle = {
	width: 'calc(100% - 28px)',
	height: '38px',
	outline: 'none',
	'border-radius': '4px',
};

const inputStyle = {
	...commonStyle,
	padding: '0 12px',
	border: '1px solid #dfe3e8',
	'box-shadow': '',
};

const tourConfig = {
	version: 1, // increase this every time you make changes to the tourConfig,
	key: 'newFields',
	steps: [
		{
			selector: '#newFields',
			content: 'The AVS Street and AVS Zip values can now be entered on the customer screen.',
		},
	],
};

/* global setAccount, setIfieldStyle, enableAutoFormatting, ifieldEventCallbacks, addIfieldKeyPressCallback, getTokens */

class AddEditCustomerPaymentsAddEdit extends Component {
	constructor(props) {
		super(props);

		this.paymentNameInput = createRef();
		this.cardNumRef = createRef();
		this.hidCardNumRef = createRef();
		this.accountNumRef = createRef();
		this.hidAccountNumRef = createRef();
	}

	get zipLabel() {
		return this.props.isCanadian ? 'AVS Postal Code' : 'AVS Zip';
	}

	componentDidMount = async () => {
		const { requiredFields, isExpanded, advancedView, advancedViewByDefault } = this.props;
		if (
			this.cardNumRef.current &&
			this.hidCardNumRef.current &&
			this.accountNumRef.current &&
			this.hidAccountNumRef.current &&
			(isExpanded ||
				!advancedView ||
				advancedViewByDefault ||
				(requiredFields && (requiredFields.address || requiredFields[this.zipLabel])))
		) {
			window.ifieldDataCache = {
				cardNumberIsValid: false,
				cardNumberLength: 0,
				cardNumberFormattedLength: 0,
				cardNumberIsEmpty: true,
				issuer: 'unknown',
				cvvIsValid: false,
				cvvLength: 0,
				cvvIsEmpty: true,
				achLength: 0,
				achIsEmpty: true,
				achIsValid: false,
				lastIfieldChanged: '',
			};
			const ifields = principalService.get().ifields;
			setAccount(ifields, SoftwareSettings.name, SoftwareSettings.version);
			setIfieldStyle('card-number', inputStyle);
			setIfieldStyle('ach', inputStyle);
			enableAutoFormatting(' ');
			if (ifieldEventCallbacks) {
				//eslint-disable-next-line
				window.ifieldEventCallbacks = {};
			}
			addIfieldKeyPressCallback(
				function(value) {
					const { onChange, id } = this.props;
					const { cardNumberIsValid, cardNumberIsEmpty, achIsValid, achIsEmpty } = value;
					onChange(id, '_meta.fetchToken', this.getIfieldTokens);
					onChange(id, '_meta.cc', {
						cardNumberIsValid,
						cardNumberIsEmpty,
					});
					onChange(id, '_meta.check', {
						achIsEmpty,
						achIsValid,
					});
				}.bind(this)
			);
		}
	};

	componentDidUpdate = prevProps => {
		const { isExpanded, focusOnMount } = this.props;
		if (prevProps.isExpanded !== isExpanded) {
			if (focusOnMount && this.paymentNameInput.current && isExpanded) {
				this.paymentNameInput.current.focus();
			}
		}
	};

	toggleExpandCollapsePayment = () => {
		const {
			payment: {
				_meta: { id, paymentExpanded },
			},
		} = this.props;
		this.props.onChange(id, '_meta.paymentExpanded', !paymentExpanded);
	};
	onAccountTypeChange = ({ target: { value } }) => {
		const { id } = this.props;
		this.props.onPaymentAccountTypeChange(id, value);
	};
	onChange = ({ target: { name, value } }) => {
		const { id } = this.props;
		this.props.onChange(id, name, value);
	};

	getIfieldTokens = () => {
		const {
			payment: { tokenType },
		} = this.props;
		return new Promise(resolve => {
			if (tokenType === 'cc' && this.hidCardNumRef.current) {
				getTokens(
					() => {
						resolve({
							cc: this.hidCardNumRef.current.value,
						});
					},
					resolve,
					30000
				);
			} else if (tokenType === 'check' && this.hidAccountNumRef.current) {
				getTokens(
					() => {
						resolve({
							check: this.hidAccountNumRef.current.value,
						});
					},
					resolve,
					30000
				);
			} else {
				resolve();
			}
		});
	};

	onNumberChange = ({ value }, { target: { name } }) => {
		const { id } = this.props;
		this.props.onChange(id, name, value);
	};

	isAllowedRoutingNumber = values => {
		const { value } = values;

		return value.length < 10;
	};

	handleDelete = () => {
		const { id, payment, defaultPaymentMethodExists, hasMultiplePayments } = this.props;
		const isOnlyDefaultPaymentMethod = !hasMultiplePayments && payment.isDefaultPaymentMethod;

		if (payment.isDefaultPaymentMethod && !payment._meta.deleted && defaultPaymentMethodExists && hasMultiplePayments) {
			this.props.onChange(id, '_meta.tentativeDelete', true);
		} else {
			if (isOnlyDefaultPaymentMethod && payment.paymentMethodId) {
				this.openDeleteOnlyAvailablePaymentMethod();
			} else {
				this.props.handleDelete(id);
			}
		}
	};

	openDeleteOnlyAvailablePaymentMethod = () => {
		const { handleOpenCloseModal, deactivateActiveSchedulesAndRemovePaymentMethod } = this.props;

		handleOpenCloseModal({
			name: modalNames.confirmAction,
			data: {
				loadingMessage: 'Deactivating schedules and removing payment method',
				question: (
					<div>
						<p>
							By removing the only available payment method, all recurring schedules will be automatically deactivated.
						</p>
						<br />
						<p>Are you sure you want to remove the only available payment method?</p>
					</div>
				),
				onConfirm: deactivateActiveSchedulesAndRemovePaymentMethod,
			},
		});
	};

	onSetAsDefaultChange = () => {
		const { id } = this.props;
		this.props.onChange(id, 'isDefaultPaymentMethod', true);
	};
	getPaymentTitle = payment => {
		return payment.token
			? payment.tokenAlias || (
					<div className="display--f">
						<img src={CardTypeImagePath.getPath(payment.cardType)} className="grid__creditcard" />
						<span>{`****${payment.maskedNumber.slice(-4)}`}</span>
					</div>
			  )
			: 'New payment';
	};

	renderAdvancedViewHeader = (payment, paymentTitle, isViewOnly) => {
		return (
			<div className="grid-sidebar__body__header">
				<div className="flex--primary cursor--pointer spc--bottom--sml--alt" onClick={this.toggleExpandCollapsePayment}>
					<i
						className={`icon icon--nano icon--arrow--${
							payment._meta.paymentExpanded ? 'down' : 'right'
						}--primary icon--middle spc--right--tny flex--no-shrink`}
					></i>
					<label className="grid-sidebar__body__title cursor--pointer">{paymentTitle}</label>
				</div>
				<div className="grid-sidebar__body__header__actions spc--bottom--sml--alt">
					<div className="display--ib type--left spc--right--sml">
						<input
							type="checkbox"
							id={`${payment._meta.id}-isDefaultPaymentMethod`}
							name="isDefaultPaymentMethod"
							className="input input--check"
							value={payment.isDefaultPaymentMethod || false}
							checked={payment.isDefaultPaymentMethod || false}
							onChange={this.onSetAsDefaultChange}
						/>
						<label htmlFor={`${payment._meta.id}-isDefaultPaymentMethod`}>Set to Default</label>
					</div>

					{payment.paymentMethodId ? (
						<div className="display--ib type--left spc--right--med">
							<input
								type="checkbox"
								id={`${payment._meta.id}-_meta.deleted`}
								name="_meta.deleted"
								className="input input--check"
								value={payment._meta.deleted || false}
								checked={payment._meta.deleted || false}
								onChange={this.handleDelete}
								disabled={isViewOnly}
							/>
							<label htmlFor={`${payment._meta.id}-_meta.deleted`}>
								Remove {toLower(payment.tokenType) === 'cc' ? 'Card' : 'Check'}
							</label>
						</div>
					) : (
						<span className="display--ib datatooltip--removeschedule">
							<button
								className="btn btn--sml btn--ghost"
								data-tooltip={`Remove ${toLower(payment.tokenType) === 'cc' ? 'Card' : 'Check'}`}
								onClick={this.handleDelete}
								disabled={isViewOnly}
							>
								<i className="icon icon--xsml icon--wastebasket icon--middle"></i>
							</button>
						</span>
					)}
				</div>
			</div>
		);
	};
	renderAdvancedViewRow = (payment, isViewOnly, required) => {
		return (
			<Fragment>
				{payment._meta.tentativeDelete && payment.isDefaultPaymentMethod && (
					<div className="col col-sml-12">
						<div className="validation spc--bottom--med">
							<p className="type--error">Please set default payment method before removing</p>
						</div>
					</div>
				)}

				{payment._meta.paymentExpanded && (
					<Fragment>
						<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--sml--alt">
							<label htmlFor={`${payment._meta.id}-tokenAlias`} className="grid__recurring__main__label">
								Payment name
							</label>
							<div className="customer__item__value">
								<input
									ref={this.paymentNameInput}
									type="text"
									id={`${payment._meta.id}-tokenAlias`}
									name="tokenAlias"
									className="input input--med"
									placeholder="Payment method name"
									value={payment.tokenAlias || ''}
									onChange={this.onChange}
									disabled={isViewOnly}
								/>
							</div>
						</div>
						<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--sml--alt">
							<label htmlFor={`${payment._meta.id}-name`} className="grid__recurring__main__label">
								{toLower(payment.tokenType) === 'cc' ? 'Cardholder name' : <span>Account name {required}</span>}
							</label>
							<div className="customer__item__value">
								{payment.paymentMethodId && toLower(payment.tokenType) === 'check' ? (
									<input type="text" className="input input--med" value={payment.name || ''} disabled />
								) : (
									<input
										type="text"
										id={`${payment._meta.id}-name`}
										name="name"
										className="input input--med"
										value={payment.name || ''}
										onChange={this.onChange}
										disabled={isViewOnly}
										placeholder={toLower(payment.tokenType) === 'cc' ? 'Cardholder name' : 'Account name'}
									/>
								)}
							</div>
						</div>
					</Fragment>
				)}
			</Fragment>
		);
	};
	renderRow = (paymentTitle, payment, isViewOnly) => {
		return (
			<Fragment>
				<div className="f-col f-col-sml-12">
					<div className="display--f pull cursor--pointer" onClick={this.toggleExpandCollapsePayment}>
						<i
							className={`icon icon--nano icon--arrow--${
								payment._meta.paymentExpanded ? 'down' : 'right'
							}--primary icon--middle spc--right--tny`}
						></i>
						<label className="grid__recurring__main__title cursor--pointer">{paymentTitle}</label>
					</div>
					<div className="type--right spc--bottom--sml--alt">
						<div className="display--ib type--left spc--right--sml">
							<input
								type="checkbox"
								id={`${payment._meta.id}-isDefaultPaymentMethod`}
								name="isDefaultPaymentMethod"
								className="input input--check"
								value={payment.isDefaultPaymentMethod || ''}
								checked={payment.isDefaultPaymentMethod || false}
								onChange={this.onSetAsDefaultChange}
								disabled={isViewOnly}
							/>
							<label htmlFor={`${payment._meta.id}-isDefaultPaymentMethod`}>Set to Default</label>
						</div>

						{payment.paymentMethodId ? (
							<div className="display--ib type--left spc--right--med">
								<input
									type="checkbox"
									id={`${payment._meta.id}-_meta.deleted`}
									name="_meta.deleted"
									className="input input--check"
									value={payment._meta.deleted || ''}
									checked={payment._meta.deleted || false}
									disabled={isViewOnly}
									onChange={this.handleDelete}
								/>
								<label htmlFor={`${payment._meta.id}-_meta.deleted`}>
									Remove {toLower(payment.tokenType) === 'cc' ? 'Card' : 'Check'}
								</label>
							</div>
						) : null}
					</div>
				</div>
			</Fragment>
		);
	};

	renderCreditFields = (payment, required, dontDisplay, advancedView, maskedCardNumber, isViewOnly) => {
		return (
			<div className="f-row" style={toLower(payment.tokenType) !== 'cc' ? dontDisplay : null}>
				<div className={`spc--bottom--sml--alt f-col f-col-sml-12 f-col-med-6 ${advancedView ? ' f-col-xxlrg-6' : ''}`}>
					<label htmlFor={`${payment._meta.id}-_meta.cardNumber`} className="grid__recurring__main__label">
						Card number {required}
					</label>
					<div className="customer__item__value">
						{payment.maskedNumber ? (
							<div className="input--cardnumber">
								<div className="input input--med input--disabled datatooltip--w--100" disabled>
									<img src={CardTypeImagePath.getPath(payment.cardType)} className="grid__creditcard" />
									<a
										href={`/transactions?cardNumber=${payment.maskedNumber.slice(-4)}&disabled=true`}
										target="_blank"
										data-tooltip="Filter by last 4 digits"
										className="anchor anchor--primary type--wgt--bold padd--left--med--alt"
									>
										{maskedCardNumber || ''}
									</a>
								</div>
							</div>
						) : (
							<Fragment>
								<iframe
									ref={this.cardNumRef}
									data-ifields-id="card-number"
									className="newtransaction__iframe"
									data-ifields-placeholder="XXXX XXXX XXXX XXXX"
									src={ifieldsSource}
								/>
								<input
									type="hidden"
									data-ifields-id="card-number-token"
									name="xCardNum"
									ref={this.hidCardNumRef}
									required
								/>
							</Fragment>
						)}
					</div>
				</div>

				<div className={`spc--bottom--sml--alt f-col f-col-sml-12 f-col-med-6 ${advancedView ? ' f-col-xxlrg-6' : ''}`}>
					<label htmlFor={`${payment._meta.id}-exp`} className="grid__recurring__main__label">
						Exp Date {required}
					</label>
					<div className="customer__item__value">
						<NumberFormat
							format="##/##"
							id={`${payment._meta.id}-exp`}
							name="exp"
							className="input input--med"
							value={payment.exp || ''}
							placeholder="MM/YY"
							mask={['M', 'M', 'Y', 'Y']}
							onValueChange={this.onNumberChange}
							disabled={isViewOnly}
							inputMode="decimal"
						/>
					</div>
				</div>
			</div>
		);
	};
	renderCheckFields = (payment, required, dontDisplay, advancedView, isViewOnly) => {
		return (
			<div className="f-row" style={toLower(payment.tokenType) !== 'check' ? dontDisplay : null}>
				<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--sml--alt">
					<label data-ifields-id="card-data-error">Account Type {required}</label>
					<div className="customer__item__value">
						<select
							name="accountType"
							className="input input--med input--select"
							value={payment.accountType}
							onChange={this.onAccountTypeChange}
						>
							<option value="" selected hidden={payment.accountType}>
								Select an account type
							</option>
							<option value={this.props.accountTypeConstants.CHECKING}>Checking</option>
							<option value={this.props.accountTypeConstants.SAVING}>Saving</option>
						</select>
					</div>
				</div>

				<div className={`spc--bottom--sml--alt f-col f-col-sml-12 f-col-med-6 ${advancedView ? 'f-col-xxlrg-6' : ''}`}>
					<label htmlFor={`${payment._meta.id}-_meta.routingNumber`} className="grid__recurring__main__label">
						Routing number {required}
					</label>
					<div className="customer__item__value">
						{payment.maskedNumber ? (
							<input type="text" inputMode="numeric" className="input input--med" value="*********" disabled />
						) : (
							<NumberFormat
								value={payment._meta.routingNumber || ''}
								format="##########"
								placeholder="Routing number"
								className="input input--med"
								inputMode="numeric"
								name="_meta.routingNumber"
								id={`${payment._meta.id}-_meta.routingNumber`}
								onValueChange={this.onNumberChange}
								isAllowed={this.isAllowedRoutingNumber}
								disabled={isViewOnly}
							/>
						)}
					</div>
				</div>

				{!advancedView && toLower(payment.tokenType) === 'check' && (
					<div className="spc--bottom--sml--alt f-col f-col-sml-12 f-col-med-6">
						<label htmlFor={`${payment._meta.id}-name`} className="grid__recurring__main__label">
							Account name {required}
						</label>
						<div className="customer__item__value">
							{payment.paymentMethodId ? (
								<input type="text" className="input input--med" value={payment.name || ''} disabled />
							) : (
								<input
									type="text"
									id={`${payment._meta.id}-name`}
									name="name"
									className="input input--med"
									value={payment.name || ''}
									onChange={this.onChange}
									disabled={isViewOnly}
									placeholder="Account name"
								/>
							)}
						</div>
					</div>
				)}

				<div className={`f-col f-col-sml-12 f-col-med-6 ${advancedView ? 'f-col-xxlrg-6' : ''} spc--bottom--sml--alt`}>
					<label htmlFor={`${payment._meta.id}-_meta.accountNumber`} className="grid__recurring__main__label">
						Account number {required}
					</label>
					<div className="customer__item__value">
						{payment.maskedNumber ? (
							<input
								type="text"
								inputMode="numeric"
								className="input input--med"
								value={payment.maskedNumber || ''}
								disabled
							/>
						) : (
							<Fragment>
								<iframe
									className="newtransaction__iframe"
									data-ifields-id="ach"
									data-ifields-placeholder="Account number"
									src={ifieldsSource}
									ref={this.accountNumRef}
								/>
								<input type="hidden" data-ifields-id="ach-token" name="xAccount" ref={this.hidAccountNumRef} required />
							</Fragment>
						)}
					</div>
				</div>
			</div>
		);
	};

	renderFields = ({ payment, dontDisplay, advancedView, required, maskedCardNumber, isViewOnly }) => {
		return (
			<div className={payment._meta.paymentExpanded ? 'f-col f-col-sml-12' : 'display--n'}>
				{this.renderCreditFields(payment, required, dontDisplay, advancedView, maskedCardNumber, isViewOnly)}
				{this.renderCheckFields(payment, required, dontDisplay, advancedView, isViewOnly)}
			</div>
		);
	};
	render = () => {
		const { payment, requiredFields, advancedView, isExpanded, isViewOnly } = this.props;
		const maskedCardNumber = payment && !isEmpty(payment.maskedNumber) && `****${payment.maskedNumber.slice(-4)}`;
		const required = (
			<span data-tooltip="Required" className="label--required">
				*
			</span>
		);
		const dontDisplay = { display: 'none' };
		const paymentTitle = this.getPaymentTitle(payment);
		if (payment._meta.isLoading) {
			return (
				<div className="loader__holder">
					<div className="loader__spinner"></div>
				</div>
			);
		} else {
			return (
				<div className="border--bottom">
					{map(payment._meta.errorMessages, (errorMessage, index) => (
						<div key={index} className="validation spc--bottom--med">
							<p className="type--error">{errorMessage}</p>
						</div>
					))}
					{advancedView && this.renderAdvancedViewHeader(payment, paymentTitle, isViewOnly)}
					<div className="f-row">
						<Fragment>
							{advancedView && this.renderAdvancedViewRow(payment, isViewOnly, required)}
							{!advancedView && this.renderRow(paymentTitle, payment, isViewOnly)}
							{this.renderFields({
								payment,
								dontDisplay,
								advancedView,
								required,
								maskedCardNumber,
								isViewOnly,
							})}
						</Fragment>
					</div>
					{advancedView && payment._meta.paymentExpanded && (
						<Fragment>
							<div id="newFields" className="f-row">
								<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--sml--alt">
									<label htmlFor={`${payment._meta.id}-street`} className="grid__recurring__main__label">
										AVS Street {requiredFields.address && required}
									</label>
									<div className="customer__item__value">
										<input
											type="text"
											id={`${payment._meta.id}-street`}
											name="street"
											className="input input--med"
											placeholder="AVS Street"
											value={payment.street || ''}
											disabled={isViewOnly}
											onChange={this.onChange}
										/>
									</div>
								</div>
								<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--sml--alt">
									<label htmlFor={`${payment._meta.id}-zip`} className="grid__recurring__main__label">
										{this.zipLabel} {requiredFields.zip && required}
									</label>
									<div className="customer__item__value">
										<input
											type="text"
											inputMode="numeric"
											id={`${payment._meta.id}-zip`}
											name="zip"
											className="input input--med"
											placeholder={this.zipLabel}
											value={payment.zip || ''}
											disabled={isViewOnly}
											onChange={this.onChange}
										/>
									</div>
								</div>
							</div>
							{isExpanded && <Tour tourConfig={tourConfig} />}
						</Fragment>
					)}
				</div>
			);
		}
	};
}

AddEditCustomerPaymentsAddEdit.propTypes = {
	type: PropTypes.string,
	id: PropTypes.string.isRequired,
	payment: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	handleDelete: PropTypes.func.isRequired,
	savePayment: PropTypes.func.isRequired,
	focusOnMount: PropTypes.bool.isRequired,
	requiredFields: PropTypes.object,
	defaultPaymentMethodExists: PropTypes.bool,
	advancedView: PropTypes.bool.isRequired,
	isCanadian: PropTypes.bool,
	isExpanded: PropTypes.bool,
	isViewOnly: PropTypes.bool,
	hasMultiplePayments: PropTypes.bool,
	handleOpenCloseModal: PropTypes.func.isRequired,
	deactivateActiveSchedulesAndRemovePaymentMethod: PropTypes.func.isRequired,
	accountTypeConstants: PropTypes.object.isRequired,
	onPaymentAccountTypeChange: PropTypes.func.isRequired,
};

export default AddEditCustomerPaymentsAddEdit;
