import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { toNumber, split, toLower, get, toUpper } from 'lodash';

import NumberFormat from 'react-number-format';

import { CurrencyMap } from './../../../utilities';
import { Modal } from 'common/components/modal';
import TooltipFormatterComponent from './TooltipFormatter';

class CurrencyComponent extends TooltipFormatterComponent {
	static instances = [];

	constructor(props) {
		super(props, CurrencyComponent.instances);

		this.tooltipClass = 'datatooltip--fake';

		this.state = {
			isOriginalRefPopupOpen: false,
		};
	}

	get tooltip() {
		return this.isSplitCaptured ? 'This is the amount that remains after split captures.' : null;
	}

	get currency() {
		return this.props.dependentValues && this.props.dependentValues.currency;
	}

	get isVoided() {
		return this.props.dependentValues && this.props.dependentValues.xVoid == 1;
	}

	get isSplitCaptured() {
		return parseInt(get(this.props, 'dependentValues.xClearedCount', 0)) > 0;
	}

	get isAuthOnly() {
		return (
			this.props.dependentValues &&
			this.props.dependentValues.xCommand &&
			this.props.dependentValues.xCommand.toLowerCase() === 'cc:authonly'
		);
	}

	get isSplitPay() {
		return this.props.dependentValues.xCommand && toLower(this.props.dependentValues.xCommand) === 'split pay';
	}

	get currencyCode() {
		return CurrencyMap.resolveCurrency(toUpper(this.currency));
	}

	handleClosePopup = () => {
		this.setState({ isOriginalRefPopupOpen: false });
	};

	handleOpenPopup = () => {
		this.setState({ isOriginalRefPopupOpen: true });
	};

	renderOriginalRefPopup = () => {
		const {
			row: { xCustom01 },
		} = this.props;
		const { isOriginalRefPopupOpen } = this.state;

		return (
			<Modal
				isOpen={isOriginalRefPopupOpen}
				onClose={this.handleClosePopup}
				shouldCloseOnOverlayClick={false}
				className="popup__content"
			>
				<div className="popup">
					<div className="popup__header">
						<div className="popup__header__title">Original transaction's Ref #</div>
					</div>
					<div className="popup__body">
						<p className="type--wgt--bold">Ref #: {xCustom01}</p>
					</div>
				</div>
			</Modal>
		);
	};

	render() {
		let { value, dependentValues, row } = this.props;
		const isNumber = !isNaN(toNumber(value));
		const isBreakdown = dependentValues && dependentValues.seperateCountColumn;
		const displayBadge = dependentValues && dependentValues.displayBadge;
		const [payment, transaction] = split(toLower(row.xCommand), ':');
		const issueGift = payment === 'gift' && transaction === 'issue';
		const redeemGift = payment === 'gift' && transaction === 'redeem';
		if (redeemGift) {
			value = Math.abs(value);
		}
		return (
			<div
				className={
					isBreakdown
						? 'type--left'
						: `type--wgt--medium grid__holder--tooltip type--${
								dependentValues.alignLeftOnPrint || dependentValues.alignLeftOnGrid ? 'left' : 'right'
						  }`
				}
			>
				{!isNumber && <span>{value}</span>}
				{isNumber && !this.isVoided && (
					<Fragment>
						<NumberFormat
							value={value}
							displayType="text"
							thousandSeparator={true}
							prefix={`${issueGift ? '- ' : ''}${this.currencyCode}`}
							decimalScale={2}
							fixedDecimalScale={true}
						/>
						{dependentValues && dependentValues.seperateCountColumn && row && row.currency ? ` ${row.currency}` : null}
					</Fragment>
				)}
				{isNumber && !this.isVoided && this.isAuthOnly && displayBadge && (
					<span>
						{this.isSplitCaptured ? (
							<Fragment>
								<i
									ref={this.elementRef}
									onMouseEnter={this.displayPopup}
									onMouseLeave={this.closePopup}
									className="icon icon--tiny icon--info align--v--middle spc--left--tny align--v--top"
								></i>
								<img src="/static/media/tags/split-captured.svg" className="spc--left--tny " />
							</Fragment>
						) : (
							<img src="/static/media/tags/auth.svg" className="spc--left--tny " />
						)}
					</span>
				)}
				{isNumber && this.isSplitPay && displayBadge && (
					<Fragment>
						{this.renderOriginalRefPopup()}
						<span
							className="type--status type--status--sml type--status--declined spc--left--tny align--v--middle"
							data-tooltip="Click to retrieve original transaction's Ref #"
							onClick={this.handleOpenPopup}
						>
							<span className="type--uppercase">Split Pay</span>
						</span>
					</Fragment>
				)}
				{isNumber && this.isVoided && (
					<Fragment>
						<NumberFormat
							value={value}
							displayType="text"
							thousandSeparator={true}
							className={displayBadge ? 'type--linethrough type--wgt--light type--color--primary' : ''}
							prefix={this.currencyCode}
							decimalScale={2}
							fixedDecimalScale={true}
						/>
						{displayBadge && (
							<span>
								<img src="/static/media/tags/void.svg" className="spc--left--tny " />
							</span>
						)}
					</Fragment>
				)}
			</div>
		);
	}
}

CurrencyComponent.propTypes = {
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	dependentValues: PropTypes.object,
	row: PropTypes.object,
};

export default CurrencyComponent;
