import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';

import { ReactToPrint } from './../react-to-print';
import { renderIf, OutsideClick } from '../../utilities';
import { withError } from '../error';
import { withCancelable } from '../cancelable';
import PrintGridData from './print-grid-data';

class PrintGridButton extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expand: false,
			allData: null,
			isPrinting: false,
		};

		this.currentRef = createRef();
		this.allRef = createRef();

		this.setState = this.setState.bind(this);
	}

	onOpen = () => {
		this.props.togglePrintExportTooltip(true, 'exportGridButtonRef');

		this.setState({
			expand: true,
		});
	};

	onClose = () => {
		if (!this.state.expand) {
			return;
		}
		this.props.togglePrintExportTooltip(false, 'exportGridButtonRef');

		this.setState({
			expand: false,
		});
	};

	showLoader = show => {
		this.props.showLoaderMethod(show);
	};

	triggerAll = async () => {
		this.showLoader(true);
		this.onClose();
		try {
			this.setState({ isPrinting: true });
			const allData = await this.props.makePendingRequest(this.props.getAll());
			this.setState(
				{
					allData,
					expand: false,
				},
				() => {
					if (this.allRef.current) {
						this.allRef.current.handlePrint();
					}
					this.showLoader(false);
				}
			);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.showLoader(false);
			}
		}
	};

	triggerCurrent = () => {
		this.showLoader(true);
		this.onClose();
		try {
			this.setState(
				{
					expand: false,
					isPrinting: true,
				},
				() => {
					if (this.currentRef.current) {
						this.currentRef.current.handlePrint();
					}
					this.showLoader(false);
				}
			);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.showLoader(false);
			}
		}
	};

	handlePrintError = (method, error) => {
		const { handleError } = this.props;
		if (handleError(error, { additionalInfo: { method } })) {
			this.setState({ isPrinting: false });
		}
	};

	handleAfterPrint = () => {
		this.setState({ isPrinting: false });
	};

	render() {
		const {
			data,
			className,
			columns,
			type,
			showDropdown,
			hasMoreData,
			title,
			components,
			printProcessingFee,
			printNetSale,
			printInPortrait,
			settingsOpen,
			printOnOnePage,
			hideApprovedAmount,
			hideTotalByCard,
		} = this.props;
		const { expand, allData, hideTooltip, isPrinting } = this.state;
		let hasData = false;
		if (data !== null && data.length > 0) {
			hasData = true;
		}
		const enabled = hasData && !isPrinting;
		let allTitle = 'All transactions';
		if (type === 'giftCardLiability') {
			allTitle = 'All gift cards';
		} else if (type === 'giftCardSummary') {
			allTitle = 'All summaries';
		} else if (type === 'disputes') {
			allTitle = 'All disputes';
		}
		const tooltipText = settingsOpen ? null : 'Print';
		const tooltipInfo = !tooltipText ? null : hasMoreData && (hideTooltip || expand) ? null : tooltipText;
		return (
			<OutsideClick action={this.onClose} className="pos--rel datatooltip--v--bottom">
				<React.Fragment>
					{renderIf(showDropdown && hasMoreData)(() => (
						<React.Fragment>
							<button
								className="btn btn--ghost btn--med btn--med--wicon"
								onClick={this.onOpen}
								disabled={!enabled}
								data-tooltip={tooltipInfo}
							>
								<i className="icon icon--xsml icon--print align--v--middle" />
							</button>
							<div className="buttondropdown">
								<ReactToPrint
									splitColumns={true}
									ref={this.currentRef}
									trigger={() => <div style={{ display: 'none' }}></div>}
									content={() => this.print}
									isPortrait={printInPortrait}
									printOnOnePage={printOnOnePage}
									onPrintError={this.handlePrintError}
									onAfterPrint={this.handleAfterPrint}
								/>
								<ReactToPrint
									ref={this.allRef}
									splitColumns={true}
									trigger={() => <div style={{ display: 'none' }}></div>}
									content={() => this.printAll}
									isPortrait={printInPortrait}
									printOnOnePage={printOnOnePage}
									onPrintError={this.handlePrintError}
									onAfterPrint={this.handleAfterPrint}
								/>
								{renderIf(expand)(
									<Fragment>
										<ul className="buttondropdown__list">
											<li className="buttondropdown__item" onClick={this.triggerCurrent}>
												Current view (PDF)
											</li>
											{hasMoreData && (
												<li className="buttondropdown__item" onClick={this.triggerAll}>
													{allTitle} (PDF)
												</li>
											)}
										</ul>
									</Fragment>
								)}
							</div>
						</React.Fragment>
					))}
					{renderIf(!showDropdown || !hasMoreData)(() => (
						<Fragment>
							<ReactToPrint
								splitColumns={true}
								ref={this.currentRef}
								trigger={() => <div style={{ display: 'none' }}></div>}
								content={() => this.print}
								isPortrait={printInPortrait}
								printOnOnePage={printOnOnePage}
								onPrintError={this.handlePrintError}
								onAfterPrint={this.handleAfterPrint}
							/>
							<div>
								<button
									className="btn btn--ghost btn--med btn--med--wicon"
									disabled={!enabled}
									onClick={this.triggerCurrent}
									data-tooltip={tooltipText}
								>
									<i className="icon icon--xsml icon--print align--v--middle" />
								</button>
							</div>
						</Fragment>
					))}
					{renderIf(hasData && isPrinting)(() => (
						<div style={{ display: 'none' }}>
							<PrintGridData
								ref={el => (this.print = el)}
								data={data}
								columns={columns}
								className={className}
								title={title}
								type={type}
								components={components}
								printProcessingFee={printProcessingFee}
								printNetSale={printNetSale}
								isPortrait={printInPortrait}
								printOnOnePage={printOnOnePage}
								hideApprovedAmount={hideApprovedAmount}
								hideTotalByCard={hideTotalByCard}
							/>
							{allData ? (
								<PrintGridData
									ref={el => (this.printAll = el)}
									data={allData}
									columns={columns}
									className={className}
									title={title}
									type={type}
									components={components}
									printProcessingFee={printProcessingFee}
									printNetSale={printNetSale}
									isPortrait={printInPortrait}
									printOnOnePage={printOnOnePage}
									hideApprovedAmount={hideApprovedAmount}
									hideTotalByCard={hideTotalByCard}
								/>
							) : null}
						</div>
					))}
				</React.Fragment>
			</OutsideClick>
		);
	}
}

PrintGridButton.propTypes = {
	data: PropTypes.array,
	columns: PropTypes.array.isRequired,
	allColumns: PropTypes.array,
	showLoaderMethod: PropTypes.func,
	type: PropTypes.string,
	showDropdown: PropTypes.bool,
	getAll: PropTypes.func,
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func,
	hasMoreData: PropTypes.bool,
	title: PropTypes.string,
	className: PropTypes.string,
	components: PropTypes.object,
	printProcessingFee: PropTypes.bool,
	printNetSale: PropTypes.bool,
	printInPortrait: PropTypes.bool,
	settingsOpen: PropTypes.bool,
	togglePrintExportTooltip: PropTypes.any,
	printOnOnePage: PropTypes.bool,
	hideApprovedAmount: PropTypes.bool,
	hideTotalByCard: PropTypes.bool,
};

export default withError(withCancelable(PrintGridButton));
