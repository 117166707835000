import { chunk, join, split } from 'lodash';

import httpService from './httpService';
import principalService from './principalService';

const { ifieldEndpoint, ifieldVersion } = ApplicationSettings;
const { version, name } = SoftwareSettings;

const chunkSize = 100;

class IfieldService {
	constructor(httpService, principalService) {
		this.httpService = httpService;
		this.principalService = principalService;
	}

	transformCardNumbers = (cardNumbers, tokenType) => {
		return new Promise((resolve, reject) => {
			let fullResponse;
			let chunkIndex = 0;
			const chunkedCardNumbers = chunk(cardNumbers, chunkSize);
			const socket = new WebSocket(ifieldEndpoint);
			const principal = this.principalService.get();
			function sendChunk() {
				socket.send(
					JSON.stringify({
						action: 'filedrop',
						xVersion: ifieldVersion,
						xKey: principal.ifields,
						xReferrer: window.location.href,
						xSoftwareVersion: version,
						xSoftwareName: name,
						xTokenType: tokenType,
						xData: join(chunkedCardNumbers[chunkIndex], '\n'),
					})
				);
			}
			socket.onopen = () => {
				sendChunk();
			};
			socket.onmessage = ({ data }) => {
				const response = JSON.parse(data);
				if (response.Status !== 'Success') {
					reject(response);
					socket.close();
					return;
				}
				if (!fullResponse) {
					fullResponse = response;
					fullResponse.Data = split(fullResponse.Data, '\n');
				} else {
					fullResponse.Data = [...fullResponse.Data, ...split(response.Data, '\n')];
				}
				chunkIndex++;
				if (chunkIndex < chunkedCardNumbers.length) {
					sendChunk();
				} else {
					resolve(fullResponse);
					socket.close();
				}
			};
		});
	};
}

const ifieldService = new IfieldService(httpService, principalService);

export default ifieldService;
