import React from 'react';
import { map } from 'lodash';

import { NumericFilter, MultiselectCardknoxFilter, StringFilter } from '../../columns/filters';

import {
	DisplayDateComponent,
	CreditCardComponent,
	CurrencyComponent,
	StatusComponent,
	CurrencyCodesComponent,
	CardTypeComponent,
} from '../../columns/formatters';
import { ColumnHeader } from 'common/components/columns/headers';

const columns = [
	{
		key: 'xRefNum',
		name: 'Ref #',
		sortable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		showOnMobile: true,
		resizable: true,
		order: 1,
		fieldKey: 'xRefNum,xEnteredDate,xResponseResult,xAmount,xCurrency,xCardType,xCommand',
	},
	{
		key: 'xEnteredDate',
		name: 'Date',
		sortable: true,
		filterable: true,
		visible: true,
		filterRenderer: StringFilter,
		formatter: DisplayDateComponent,
		initWidth: 200,
		showOnMobile: true,
		isDefaultSorter: true,
		defaultSortDirection: 'DESC',
		customSettingsKey: 'date',
		resizable: true,
	},
	{
		key: 'currency',
		exportKey: 'xCurrency',
		name: 'Currency',
		sortable: true,
		hideable: false,
		visible: false,
		hideOnPrint: true,
		filterRenderer: MultiselectCardknoxFilter,
		formatter: CurrencyCodesComponent,
		initWidth: 130,
		showOnMobile: true,
		customSettingsKey: 'currency',
		resizable: true,
	},
	{
		key: 'amountToUse',
		exportKey: 'xAmount',
		name: 'Amount',
		sortable: true,
		visible: true,
		filterable: true,
		filterRenderer: NumericFilter,
		formatter: CurrencyComponent,
		getRowMetaData: row => {
			return { ...row, displayBadge: true };
		},
		initWidth: 133,
		showOnMobile: true,
		dependentExportKey: 'currency',
		customSettingsKey: 'amount',
		resizable: true,
		alignHeaderRight: true,
	},
	{
		key: 'xName',
		name: 'C­ar­dholder N­ame', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		sortable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 160,
		customSettingsKey: 'cardholderName',
		resizable: true,
	},
	{
		key: 'xCardType',
		name: 'Card type',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: MultiselectCardknoxFilter,
		formatter: CardTypeComponent,
		initWidth: 150,
		customSettingsKey: 'cardType',
		resizable: true,
	},
	{
		key: 'xMaskedCardNumber',
		name: 'Account #',
		sortable: true,
		visible: true,
		filterable: true,
		formatter: CreditCardComponent,
		filterRenderer: StringFilter,
		getRowMetaData: row => row,
		initWidth: 170,
		showOnMobile: true,
		dependentExportKey: 'xCardType',
		customSettingsKey: 'accountNumber',
		resizable: true,
	},
	{
		key: 'xResponseResult',
		name: 'Result',
		sortable: true,
		formatter: StatusComponent,
		visible: true,
		filterable: true,
		filterRenderer: MultiselectCardknoxFilter,
		initWidth: 120,
		showOnMobile: true,
		getRowMetaData: row => row,
		customSettingsKey: 'result',
		resizable: true,
	},
	{
		key: 'xCommand',
		name: 'Command',
		sortable: true,
		visible: true,
		filterable: true,
		filterRenderer: MultiselectCardknoxFilter,
		initWidth: 120,
		customSettingsKey: 'command',
		resizable: true,
	},
	{
		key: 'xDescription',
		name: 'Description',
		sortable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 300,
		customSettingsKey: 'description',
		resizable: true,
	},
];

export const ViewPaymentsColumns = map(columns, column => {
	column = {
		headerRenderer: <ColumnHeader />,
		...column,
	};
	return column;
});
