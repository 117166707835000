import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { map, cloneDeep, find, toLower, memoize, filter, get, sortBy, replace, includes, remove } from 'lodash';
import moment from 'moment';
import 'moment-timezone';

import { withLoader } from '../../../Common/components/loader';
import { withCancelable } from '../../../Common/components/cancelable';
import { withError } from '../../../Common/components/error';
import { withBlock } from '../../../Common/components/block';
import { kvaasService } from '../../../Common/services';
import { Notification } from '../../../Common/components/notifications';
import { kvaasResources, initialPageOptions } from '../../../Common/utilities';
import { Select } from '../../../Common/components/select';
import { portalTimezones } from 'components/settings/constants';
import handleInvalidRevision from '../utils/invalidRevision';
import renderSaveButton from '../components/SaveButton';
import checkIfError from '../utils/checkIfError';

const requestKeys = {
	FETCH: 'fetch',
	SAVE: 'save',
};

const filteredTimeZones = filter(
	moment.tz.names(),
	timezone =>
		!includes(portalTimezones, timezone) &&
		/\//.test(timezone) &&
		!timezone.includes('GMT') &&
		!timezone.includes('ETC')
);
const timezones = sortBy(
	sortBy(map(filteredTimeZones, timezone => ({ name: timezone, label: replace(timezone, '_', ' ') })), item =>
		toLower(item.name)
	),
	item => toLower(item.name).includes('us/'),
	['desc', 'asc']
);
const usTimezones = remove(timezones, timezone => toLower(timezone.name).includes('us/'));
timezones.unshift(...usTimezones);

const routeOptions = map(initialPageOptions, ({ key: name, title: label }) => ({ name, label }));

class UserSettingsGeneral extends Component {
	constructor(props) {
		super(props);
		this.top = createRef();
		this.notification = createRef();

		this.state = {
			isSaving: false,
			expanded: false,
			collapsedSettingsMessages: { data: {} },
			errorMessages: [],
			oldData: {
				userSettings: null,
			},
			users: [],
			userSettings: {
				timezone: '',
				initialPage: '',
			},
		};
	}

	mapStateToRequest = (data, oldData) => {
		return {
			newData: {
				revision: 0,
				data,
			},
			oldData,
			...kvaasResources.collapsedSettingsMessages,
		};
	};

	componentDidMount = async () => {
		this.props.showLoader(true);
		try {
			const [[userSettings, collapsedSettingsMessages]] = await this.props.makePendingRequest(
				Promise.all([
					kvaasService.get(
						{ ...kvaasResources.userSettings, throwError: true },
						{ ...kvaasResources.collapsedSettingsMessages, throwError: true }
					),
				]),
				requestKeys.FETCH
			);
			const collapsed = get(collapsedSettingsMessages, 'data.displaySettingsMessageCollapsed', false);
			const newState = {
				...this.mapResponseToState(userSettings),
				collapsedSettingsMessages,
				expanded: !collapsed,
			};
			this.setState(newState);
			this.props.showLoader(false);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.handleKvaasLoadError();
				this.props.showLoader(false);
			}
		}
	};

	setStateAsync = newState => new Promise(resolve => this.setState(newState, resolve));

	getValue = container =>
		memoize(
			name => find(container, { name }) || find(container, ({ label }) => toLower(label).indexOf('new york') > -1)
		);

	getTimezone = this.getValue(timezones);

	getRoute = this.getValue(routeOptions);

	mapResponseToState = (oldUserSettings = this.state.oldData.userSettings) => {
		const { oldData, userSettings } = cloneDeep(this.state);
		const newState = {
			errorMessages: [],
			oldData,
			userSettings,
		};
		this.mapFieldsToState(newState, oldUserSettings, 'userSettings');
		return newState;
	};

	mapFieldsToState = (newState, oldData, type) => {
		checkIfError(newState, oldData, type, (value, key) => {
			newState[type][key] = value;
		});
	};

	updateUserSettings = async () => {
		const [userSettings] = await this.props.makePendingRequest(
			kvaasService.save({
				newData: { revision: 0, data: this.state.userSettings },
				oldData: this.state.oldData.userSettings,
				...kvaasResources.userSettings,
			}),
			requestKeys.SAVE
		);
		const newState = this.mapResponseToState(userSettings);

		await this.setStateAsync(newState);
		this.props.handleBlockChange(false);
		return userSettings.refNum;
	};
	save = async () => {
		if (this.props.isLoading) {
			return;
		}
		this.props.showLoader(true);
		let refNum;
		let error;

		try {
			refNum = await this.updateUserSettings();
		} catch (e) {
			error = this.props.handleError(e, { delayMessage: true });
			if (error) {
				try {
					const [userSettings] = await this.props.makePendingRequest(
						kvaasService.get(kvaasResources.userSettings),
						requestKeys.SAVE
					);
					const newState = this.mapResponseToState(userSettings);
					await this.setStateAsync(newState);
				} catch (err) {
					error = this.props.handleError(err, { delayMessage: true });
					if (!error) {
						return;
					}
				}
			} else {
				return;
			}
		}
		this.handleInfoChanged(refNum, error);
	};

	handleInfoChanged = (refNum, error) => {
		this.props.showLoader(false);
		if (!error) {
			this.showNotification(refNum);
		} else {
			error.show();
		}
	};
	showNotification = refNum => {
		this.notification.current.addNotification({
			message: 'User General settings updated',
			ref: refNum,
			success: true,
		});
	};

	handleSelectChange = ({ name: value }, { name }) => {
		this.handleUserSettingsChange({ target: { value, name } });
	};

	handleUserSettingsChange = ({ target: { value, name, type, checked } }) => {
		this.setState({
			userSettings: {
				...this.state.userSettings,
				[name]: type === 'checkbox' ? checked : value,
			},
		});
		this.props.handleBlockChange(true);
	};

	render() {
		const {
			errorMessages,
			userSettings: { timezone, initialPage },
		} = this.state;

		return (
			<div className="settings--main settings--main--alt">
				<div className="settings__header">
					<div className="settings__header__title">General</div>
					<div className="settings__header__action">{renderSaveButton.bind(this)()}</div>
				</div>
				<div ref={this.top}></div>
				{errorMessages.length > 0
					? map(errorMessages, (error, index) => (
							<div key={index} className="spc--top--sml spc--bottom--med type--color--warning">
								{error}
							</div>
					  ))
					: null}

				<div>
					<div>
						<div className="spr__card clearfix">
							<div className="spr__content">
								<div className="f-row f-row--center">
									<div className="f-col f-col-sml-12 f-col-med-5 f-col-xxxlrg-4 spc--bottom--sml">
										<div className="type--base type--wgt--medium">Timezone</div>
									</div>
									<div className="f-col f-col-sml-12 f-col-med-7 f-col-xxxlrg-6 spc--bottom--sml">
										<Select
											name="timezone"
											id="timezone"
											value={this.getTimezone(timezone)}
											options={timezones}
											onChange={this.handleSelectChange}
											menuPlacement="auto"
										/>
									</div>
								</div>
								<div className="f-row f-row--center">
									<div className="f-col f-col-sml-12 f-col-med-5 f-col-xxxlrg-4 spc--bottom--sml datatooltip--newtransaction">
										<div className="flex--primary">
											<div className="label type--base">
												First Screen After Login
											</div>
											<div className="display--f spc--left--tny" data-tooltip="If you select a tab for which you do not have access, the system displays the Dashboard by default.">
												<i className="icon icon--tiny icon--info align--v--middle"></i>
											</div>
										</div>
									</div>
									<div className="f-col f-col-sml-12 f-col-med-7 f-col-xxxlrg-6 spc--bottom--sml">
										<Select
											name="initialPage"
											id="initialPage"
											value={this.getRoute(initialPage)}
											options={routeOptions}
											onChange={this.handleSelectChange}
											menuPlacement="auto"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Notification ref={this.notification} />
				</div>
			</div>
		);
	}
}

UserSettingsGeneral.propTypes = {
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func,
	showLoader: PropTypes.func,
	isLoading: PropTypes.bool,
	handleBlockChange: PropTypes.func,
	handleKvaasLoadError: PropTypes.func,
};

export default withError(withLoader(withCancelable(withBlock(UserSettingsGeneral))), handleInvalidRevision);
