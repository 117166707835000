import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const CardType = ({ value }) => {
	return <Fragment>{value ? value : 'Unknown'}</Fragment>;
};

CardType.propTypes = {
	value: PropTypes.string.isRequired,
};

export default CardType;
