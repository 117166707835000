import { StatusComponent, CurrencyComponent, ActiveComponent } from 'common/components/columns/formatters';
import FakeRefNumComponent from '../formatters/refNum';

export const terminalOnlyUpgradePlanFakeGridColumns = [
	{
		key: 'ref',
		visible: true,
		formatter: FakeRefNumComponent,
		initWidth: 150,
		order: 1,
	},
	{
		key: 'name',
		visible: true,
		initWidth: 100,
	},
	{
		key: 'dateTime',
		visible: true,
		initWidth: 110,
	},
	{
		key: 'date',
		visible: true,
		initWidth: 100,
	},
	{
		key: 'frequency',
		visible: true,
		initWidth: 100,
	},
	{
		key: 'amount',
		visible: true,
		initWidth: 100,
		getRowMetaData: row => row,
		formatter: CurrencyComponent,
	},
	{
		key: 'result',
		visible: true,
		initWidth: 100,
		formatter: StatusComponent,
	},
	{
		key: 'isActive',
		visible: true,
		initWidth: 50,
		formatter: ActiveComponent,
	},
];
