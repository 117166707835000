import { times, padStart, transform } from 'lodash';

import { validators as v } from 'common/fields';

const dateTooltip = 'Supported Format: MM/DD/YYYY';
const expDateTooltip = 'Supported Format: MMYY';
const routingTooltip = 'Supports 9 Digits';
const accountNumberTooltip = 'Supports 4 - 17 Digits';
const amountTooltip = 'Amount rounded to 2 decimals';
const paymentTypeTooltip = 'Accepts "CC" or "Check"';
const intervalTooltip = 'Supports “Day,” “Week,” “Month,” or “Year”';
const untilTooltip = 'Supports a number of payments, an end date formatted as MM/DD/YYYY, or blank to run indefinitely';
const frequencyTooltip = 'Supports a number indicating the frequency at which the schedule will run';

const groups = {
	customer: { className: 'customer', title: 'Customer Information' },
	payment1: { className: 'payment1', title: 'Payment Method 1' },
	payment2: { className: 'payment2', title: 'Payment Method 2' },
	payment3: { className: 'payment3', title: 'Payment Method 3' },
	schedule1: { className: 'schedule1', title: 'Schedule 1' },
	schedule2: { className: 'schedule2', title: 'Schedule 2' },
	schedule3: { className: 'schedule3', title: 'Schedule 3' },
};

function createField({ validators = [], ...rest }) {
	return {
		...rest,
		validators,
	};
}

function createScheduleFields(index) {
	function createScheduleField(title, rest) {
		return createField({ ...rest, title, group: groups[`schedule${index}`] });
	}
	return {
		[`scheduleName${index}`]: createScheduleField('Schedule Name'),
		[`description${index}`]: createScheduleField('Description'),
		[`intervalType${index}`]: createScheduleField('Every', {
			validators: [v.intervalType],
			tooltip: intervalTooltip,
		}),
		[`frequency${index}`]: createScheduleField('Frequency', {
			validators: [v.frequency],
			tooltip: frequencyTooltip,
		}),
		[`amount${index}`]: createScheduleField('Amount', {
			validators: [v.currency, v.required],
			tooltip: amountTooltip,
		}),
		[`startDate${index}`]: createScheduleField('Start Date', {
			validators: [v.date],
			tooltip: dateTooltip,
		}),
		[`remainingPayments${index}`]: createScheduleField('Remaining Payments', {
			validators: [v.remainingPayments],
		}),
		[`until${index}`]: createScheduleField('Until', {
			validators: [v.untilType],
			tooltip: untilTooltip,
		}),
	};
}

function createPaymentMethodFields(index) {
	function createPaymentField(title, rest) {
		return createField({ ...rest, title, group: groups[`payment${index}`] });
	}
	return {
		[`paymentType${index}`]: createPaymentField('Payment Type', {
			validators: [v.paymentType, v.required],
			tooltip: paymentTypeTooltip,
		}),
		[`paymentName${index}`]: createPaymentField('Payment Name'),
		[`cardholderName${index}`]: createPaymentField('Cardholder Name'),
		[`cardNumber${index}`]: createPaymentField('Card Number', {
			validators: [v.cardNumber],
		}),
		[`expDate${index}`]: createPaymentField('Exp Date', {
			validators: [v.any, v.required],
			tooltip: expDateTooltip,
		}),
		[`token${index}`]: createPaymentField('Token', {
			validators: [v.token],
		}),
		[`accountName${index}`]: createPaymentField('Account Name', { validators: [v.required] }),
		[`routingNumber${index}`]: createPaymentField('Routing Number', {
			validators: [v.routing, v.required],
			tooltip: routingTooltip,
		}),
		[`accountNumber${index}`]: createPaymentField('Account Number', {
			validators: [v.accNumber, v.required],
			tooltip: accountNumberTooltip,
		}),
	};
}

function createAddressFields(isBilling) {
	const titlePrefix = isBilling ? 'Billing' : 'Shipping';
	const keyPrefix = isBilling ? 'billing' : 'shipping';
	function createAddressField(title, rest) {
		return createField({ ...rest, title: `${titlePrefix} ${title}`, group: groups.customer });
	}
	return {
		[`${keyPrefix}FirstName`]: createAddressField('First Name', {
			validators: isBilling ? [v.required] : [],
		}),
		[`${keyPrefix}LastName`]: createAddressField('Last Name', {
			validators: isBilling ? [v.required] : [],
		}),
		[`${keyPrefix}Company`]: createAddressField('Company', {
			validators: isBilling ? [v.required] : [],
		}),
		[`${keyPrefix}Address`]: createAddressField('Address'),
		[`${keyPrefix}Address2`]: createAddressField('Address 2'),
		[`${keyPrefix}City`]: createAddressField('City'),
		[`${keyPrefix}State`]: createAddressField('State'),
		[`${keyPrefix}Zip`]: createAddressField('Zip'),
		[`${keyPrefix}Phone`]: createAddressField('Phone'),
	};
}

function createCustomFields() {
	function createCustomField(index) {
		// Adding 2 to skip Custom01 since it will be overriden
		const fullIndex = padStart(index + 2, 2, 0);
		return {
			key: `custom${fullIndex}`,
			title: `Custom${fullIndex}`,
			group: groups.customer,
		};
	}

	return transform(
		times(19, createCustomField),
		(acc, { key, title, ...rest }) => {
			acc[key] = createField({ title, ...rest });
		},
		{}
	);
}

function createCustomerFields() {
	function createCustomerField(title, rest) {
		return createField({ ...rest, title, group: groups.customer });
	}
	return {
		customerLine: createCustomerField(''),
		customerNumber: createCustomerField('Customer Number'),
		...createAddressFields(true),
		email: createCustomerField('Email', {
			validators: [v.email],
		}),
		customerNotes: createCustomerField('Customer Notes'),
		...createAddressFields(false),
		...createCustomFields(),
	};
}

const headers = {
	status: createField({ title: 'Status', group: groups.customer }),
	...createCustomerFields(),
	...createPaymentMethodFields(1),
	...createPaymentMethodFields(2),
	...createPaymentMethodFields(3),
	...createScheduleFields(1),
	...createScheduleFields(2),
	...createScheduleFields(3),
};

export const columns = headers;
