import React from 'react';
import { map } from 'lodash';
import { ColumnHeader } from 'common/components/columns/headers';
import { StringFilter } from 'common/components/columns/filters';
const columns = [
	{
		key: 'logDate',
		name: 'Date',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 160,
		resizable: true,
		isDefaultSorter: true,
		defaultSortDirection: 'DESC',
	},
	{
		key: 'message',
		name: 'Message',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 130,
		resizable: true,
	},
	{
		key: 'url',
		name: 'URL',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		generateInlineFilterOptions: true,
		initWidth: 140,
		resizable: true,
	},
];
export const logViewerColumns = map(columns, column => {
	column = {
		headerRenderer: <ColumnHeader />,
		...column,
	};
	return column;
});
