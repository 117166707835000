import React from 'react';
import { object } from 'prop-types';

import TooltipFormatter from './TooltipFormatter';

class InfoIconFormatterComponent extends TooltipFormatter {
	constructor(props) {
		super(props, InfoIconFormatterComponent.instances);
	}

	static instances = [];

	get tooltip() {
		return this.props.tooltip;
	}

	tooltipClass = 'datatooltip--fake';

	render() {
		const { tooltip, handleClick } = this.props;

		const iconProps = tooltip
			? {
					onMouseEnter: this.displayPopup,
					onMouseLeave: this.closePopup,
			  }
			: {};

		if (handleClick) {
			iconProps.onClick = handleClick;
		}

		return (
			<span onClick={e => e.stopPropagation()}>
				<i className="icon icon--xsml icon--info" ref={this.elementRef} {...iconProps} />
			</span>
		);
	}
}

InfoIconFormatterComponent.propTypes = {
	dependentValues: object,
	row: object.isRequired,
};

export default InfoIconFormatterComponent;
