import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBoolean, toLower } from 'lodash';

class ActiveComponent extends Component {
	constructor(props) {
		super(props);
	}

	render = () => {
		const { value } = this.props;
		const isActive = isBoolean(value) ? value : toLower(value) === 'true';

		const icon = isActive
			? 'icon icon--xsml icon--check--success icon--middle'
			: 'icon icon--xsml icon--clear--warning icon--middle';
		return <i className={icon}></i>;
	};
}

ActiveComponent.propTypes = {
	value: PropTypes.bool.isRequired,
};

export default ActiveComponent;
