import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { CurrencyMap } from '../../../utilities';

class CardAmountCountComponent extends React.Component {
	render() {
		const { value, dependentValues } = this.props;
		const className = `type--right ${this.props.boldify ? 'type--wgt--bold' : ''}`;
		return (
			<div
				className={className}
				title={
					this.props.row && this.props.row.currency
						? `(${this.props.row.currency}: Total ${dependentValues})`
						: `${CurrencyMap.resolveCurrency()}${value ? value.toFixed(2) : 0} (Count: ${dependentValues || 0})`
				}
			>
				<NumberFormat
					prefix={CurrencyMap.resolveCurrency(this.props.row && this.props.row.currency)}
					value={value}
					displayType="text"
					thousandSeparator={true}
					decimalScale={2}
					fixedDecimalScale={true}
				/>
				<span>
					{' '}
					{this.props.row && this.props.row.currency
						? `(${this.props.row.currency}: Total ${dependentValues})`
						: `(${dependentValues})`}
				</span>
			</div>
		);
	}
}

CardAmountCountComponent.propTypes = {
	value: PropTypes.number.isRequired,
	dependentValues: PropTypes.number.isRequired,
	row: PropTypes.object,
	boldify: PropTypes.any,
};

export default CardAmountCountComponent;
