import moment from 'moment';
import { find, findIndex } from 'lodash';

import { transactionsFilter, compileFilter } from '../../../Common/components/transactions/filter/transactionsFilter';
import { DatePickerSelection } from 'common/components/selections';
import { maxApiDaysRange } from 'common/utilities';

const { checkDateFormat } = ApplicationSettings;
const newFilters = [...transactionsFilter];
const dateFilterIndex = findIndex(transactionsFilter, { key: 'date' });
const dateFilter = newFilters[dateFilterIndex];

newFilters[dateFilterIndex] = {
	key: 'date',
	hasSelection: true,
	getSelectionText: ({ key, startDate, endDate }, predefinedDates, isReportFilterOverride = false) => {
		if (!isReportFilterOverride) {
			return dateFilter.getSelectionText({ key, startDate, endDate }, predefinedDates);
		}
		const predefinedDate = find(predefinedDates, { key });
		if (predefinedDate) {
			return predefinedDate.displayValue;
		}
		try {
			return `${startDate.format(checkDateFormat)} - ${endDate.format(checkDateFormat)}`;
		} catch (e) {
			return 'Custom';
		}
	},
	type: null,
	values: {
		key: 'today',
		startDate: moment().startOf('day'),
		endDate: moment().endOf('day'),
		disabled: false,
	},
	defaultValues: {
		key: 'today',
		startDate: moment().startOf('day'),
		endDate: moment().endOf('day'),
		disabled: false,
	},
	props: {
		displayTime: true,
		maxDaysRange: Infinity,
		maxApiDaysRange: maxApiDaysRange,
	},
	selectionComponent: DatePickerSelection,
};

export const Filters = newFilters;

export { compileFilter };
