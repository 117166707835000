export const portalTimezones = [
	'America/Juneau',
	'America/Boise',
	'America/Anchorage',
	'America/Nome',
	'America/Sitka',
	'America/Yakutat',
	'America/North_Dakota/Beulah',
	'America/North_Dakota/Center',
	'America/North_Dakota/New_Salem',
	'America/Los_Angeles',
	'America/Phoenix',
	'America/Detroit',
	'America/Denver',
	'America/Chicago',
	'America/New_York',
	'America/Indiana/Indianapolis',
	'America/Indiana/Marengo',
	'America/Indiana/Winamac',
	'America/Indiana/Vincennes',
	'America/Indiana/Vevay',
	'America/Indiana/Tell_City',
	'America/Indiana/Petersburg',
	'America/Winnipeg',
	'America/Halifax',
	'America/Toronto',
	'America/Edmonton',
	'America/St_Johns',
	'America/Vancouver',
	'America/Regina',
	'America/Whitehorse',
	'America/Indiana/Knox',
	'America/Kentucky/Louisville',
	'America/Kentucky/Monticello',
	'America/Blanc-Sablon',
	'America/Cambridge_Bay',
	'America/Creston',
	'America/Dawson',
	'America/Dawson_Creek',
	'America/Menominee',
	'America/Atikokan',
	'America/Glace_Bay',
	'America/Goose_Bay',
	'America/Inuvik',
	'America/Iqaluit',
	'America/Moncton',
	'America/Nipigon',
	'America/Pangnirtung',
	'America/Rainy_River',
	'America/Rankin_Inlet',
	'America/Resolute',
	'America/Swift_Current',
	'America/Thunder_Bay',
	'America/Yellowknife',
	'Pacific/Honolulu',
	'America/Adak',
	'US/Pacific-New',
	'Africa/Asmera',
	'Africa/Timbuktu',
	'America/Argentina/ComodRivadavia',
	'America/Atka',
	'America/Buenos_Aires',
	'America/Catamarca',
	'America/Coral_Harbour',
	'America/Cordoba',
	'America/Ensenada',
	'America/Fort_Wayne',
	'America/Godthab',
	'America/Indianapolis',
	'America/Jujuy',
	'America/Knox_IN',
	'America/Louisville',
	'America/Mendoza',
	'America/Montreal',
	'America/Porto_Acre',
	'America/Rosario',
	'America/Santa_Isabel',
	'America/Shiprock',
	'America/Virgin',
	'Anctarctica/South_Pole',
	'Asia/Ashkhabad',
	'Asia/Calcutta',
	'Asia/Chonggqing',
	'Asia/Chungking',
	'Asia/Dacca',
	'Asia/Harbin',
	'Asia/Kashgar',
	'Asia/Katmandu',
	'Asia/Macao',
	'Asia/Rangoon',
	'Asia/Saigon',
	'Asia/Tel_Aviv',
	'Asia/Thumbu',
	'Asia/Ujung_Pandang',
	'Asia/Ulan_Bator',
	'Atlantic/Faeroe',
	'Atlantic/Jan_Mayen',
	'Australia/ACT',
	'Australia/Canberra',
	'Australia/Currie',
	'Australia/LHI',
	'Australia/North',
	'Australia/NSW',
	'Australia/Queensland',
	'Australia/South',
	'Australia/Tasmania',
	'Australia/Victoria',
	'Australia/West',
	'Australia/Yancowinna',
	'Brazil/Acre',
	'Brazil/DeNoronha',
	'Brazil/East',
	'Brazil/West',
	'Chile/Continental',
	'Chile/EasterIsland',
	'Cuba',
	'CET',
	'Egypt',
	'Eire',
	'WET',
	'Zulu',
	'US/East-Indiana',
	'US/Indiana-Starke',
	'US/Michigan',
	'US/Aleutian',
	'US/Samoa',
];
