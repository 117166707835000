export const roles = [
	{
		key: 'admin',
		title: 'Admin',
		desc: 'Has access to all permissions including users management.',
	},
	{
		key: 'advanced',
		title: 'Advanced user',
		desc: 'Has access to all permissions except for users management.',
	},
	{
		key: 'standard',
		title: 'Standard user',
		desc: "Has access to process new transaction but can't adjust transaction (Void, Refund, etc.).",
	},
	{
		key: 'additionalRoles',
		title: 'Additional roles',
		desc: '',
		roles: [
			{
				key: 'viewonly',
				title: 'View only',
				desc: 'Can only access reports.',
			},
			{
				key: 'saveonly',
				title: 'Save only',
				desc: 'Can only run save commands.',
			},
			{
				key: 'authonly',
				title: 'Auth only',
				desc: 'Can only run auth commands.',
			},
			{
				key: 'saleonly',
				title: 'Sale only',
				desc: 'Can only run sales.',
			},
		],
	},
];

export const permissionsPerRole = {
	admin: {
		allowCcSale: true,
		allowCcAuthOnly: true,
		allowCcPostAuth: true,
		allowCcAdjust: true,
		allowAvsOnly: true,
		allowCcCapture: true,
		allowCcUncapture: true,
		allowCcBalance: true,
		allowCcVoid: true,
		allowCcVoidRelease: true,
		allowCcVoidRefund: true,
		allowCcRefund: true,
		allowCcCredit: true,
		allowCcCreditVoid: true,
		allowCcAuthenticate: true,
		allowCcLookup: true,
		allowCcAuthenticate3D: true,
		allowCheckSale: true,
		allowCheckCredit: true,
		allowCheckVoid: true,
		allowCheckVoidRefund: true,
		allowCheckRefund: true,
		allowCheckAdjust: true,
		allowCheckSave: true,
		allowCcSave: true,
		allowCashSale: true,
		allowFraudSave: true,
		allowGiftIssue: true,
		allowGiftRedeem: true,
		allowGiftBalance: true,
		allowGiftAdjust: true,
		allowGiftAddCard: true,
		allowGiftEnroll: true,
		allowGiftExpire: true,
		allowGiftActivate: true,
		allowGiftDeactivate: true,
		allowEbtfsSale: true,
		allowEbtfsReturn: true,
		allowEbtfsVoucher: true,
		allowEbtfsAuthOnly: true,
		allowEbtfsVoid: true,
		allowEbtfsBalance: true,
		allowEbtcbCash: true,
		allowEbtcbSale: true,
		allowEbtcbVoid: true,
		allowEbtcbAuthOnly: true,
		allowEbtcbBalance: true,
		allowEbtwAuthOnly: true,
		allowEbtwBalance: true,
		allowEbtwVoucher: true,
		allowEbtwSale: true,
		allowEbtwCapture: true,
		allowEbtwCredit: true,
		allowEbtwVoid: true,
		allowCcSettle: true,
		allowReportApproved: true,
		allowReportOther: true,
		allowReportAuth: true,
		allowReportDeclined: true,
		allowReportBatch: true,
		allowReportTransaction: true,
		allowReportTransactions: true,
		allowReportEbtwApl: true,
		allowReportAll: true,
		allowReportGiftApproved: true,
		allowReportGiftSummary: true,
		allowReportGiftDetailed: true,
		allowReportLiability: true,
		allowReportNet: true,
		allowReportRelated: true,
		allowSummary: true,
		allowEmvaid: true,
		allowOmxAdjust: true,
		allowOmxUnhold: true,
		allowOmxCancel: true,
		allowOmxOrderInfo: true,
		allowL3Add: true,
		allowL3Del: true,
		allowFraudReSubmit: true,
		allowFraudSubmit: true,
		allowFraudAdjust: true,
		allowTrnSignature: true,
	},
	advanced: {
		allowCcSale: true,
		allowCcAuthOnly: true,
		allowCcPostAuth: true,
		allowCcAdjust: true,
		allowAvsOnly: true,
		allowCcCapture: true,
		allowCcUncapture: true,
		allowCcBalance: true,
		allowCcVoid: true,
		allowCcVoidRelease: true,
		allowCcVoidRefund: true,
		allowCcRefund: true,
		allowCcCredit: true,
		allowCcCreditVoid: true,
		allowCcAuthenticate: true,
		allowCcLookup: true,
		allowCcAuthenticate3D: true,
		allowCheckSale: true,
		allowCheckCredit: true,
		allowCheckVoid: true,
		allowCheckVoidRefund: true,
		allowCheckRefund: true,
		allowCheckAdjust: true,
		allowCheckSave: true,
		allowCcSave: true,
		allowCashSale: true,
		allowFraudSave: true,
		allowGiftIssue: true,
		allowGiftRedeem: true,
		allowGiftBalance: true,
		allowGiftAdjust: true,
		allowGiftAddCard: true,
		allowGiftEnroll: true,
		allowGiftExpire: true,
		allowGiftActivate: true,
		allowGiftDeactivate: true,
		allowEbtfsSale: true,
		allowEbtfsReturn: true,
		allowEbtfsVoucher: true,
		allowEbtfsAuthOnly: true,
		allowEbtfsVoid: true,
		allowEbtfsBalance: true,
		allowEbtcbCash: true,
		allowEbtcbSale: true,
		allowEbtcbVoid: true,
		allowEbtcbAuthOnly: true,
		allowEbtcbBalance: true,
		allowEbtwAuthOnly: true,
		allowEbtwBalance: true,
		allowEbtwVoucher: true,
		allowEbtwSale: true,
		allowEbtwCapture: true,
		allowEbtwCredit: true,
		allowEbtwVoid: true,
		allowCcSettle: true,
		allowReportApproved: true,
		allowReportOther: true,
		allowReportAuth: true,
		allowReportDeclined: true,
		allowReportBatch: true,
		allowReportTransaction: true,
		allowReportTransactions: true,
		allowReportEbtwApl: true,
		allowReportAll: true,
		allowReportGiftApproved: true,
		allowReportGiftSummary: true,
		allowReportGiftDetailed: true,
		allowReportLiability: true,
		allowReportNet: true,
		allowReportRelated: true,
		allowSummary: true,
		allowEmvaid: true,
		allowOmxAdjust: true,
		allowOmxUnhold: true,
		allowOmxCancel: true,
		allowOmxOrderInfo: true,
		allowL3Add: true,
		allowL3Del: true,
		allowFraudReSubmit: true,
		allowFraudSubmit: true,
		allowFraudAdjust: true,
		allowTrnSignature: true,
	},
	standard: {
		allowCcSale: true,
		allowCcAuthOnly: true,
		allowCcPostAuth: true,
		allowAvsOnly: true,
		allowCcCapture: true,
		allowCcBalance: true,
		allowCheckSale: true,
		allowCheckSave: true,
		allowCcSave: true,
		allowCashSale: true,
		allowFraudSave: true,
		allowGiftRedeem: true,
		allowGiftBalance: true,
		allowEbtfsSale: true,
		allowEbtfsVoucher: true,
		allowEbtfsAuthOnly: true,
		allowEbtfsBalance: true,
		allowEbtcbCash: true,
		allowEbtcbSale: true,
		allowEbtcbAuthOnly: true,
		allowEbtcbBalance: true,
		allowEbtwAuthOnly: true,
		allowEbtwBalance: true,
		allowEbtwVoucher: true,
		allowEbtwSale: true,
		allowEbtwCapture: true,
		allowReportApproved: true,
		allowReportOther: true,
		allowReportAuth: true,
		allowReportDeclined: true,
		allowReportBatch: true,
		allowReportTransaction: true,
		allowReportTransactions: true,
		allowReportEbtwApl: true,
		allowReportAll: true,
		allowReportGiftApproved: true,
		allowReportGiftSummary: true,
		allowReportGiftDetailed: true,
		allowReportLiability: true,
		allowReportNet: true,
		allowReportRelated: true,
		allowSummary: true,
		allowEmvaid: true,
		allowOmxAdjust: true,
		allowOmxOrderInfo: true,
		allowL3Add: true,
		allowL3Del: true,
		allowFraudReSubmit: true,
		allowFraudSubmit: true,
		allowTrnSignature: true,
	},
	viewonly: {
		allowReportApproved: true,
		allowReportOther: true,
		allowReportAuth: true,
		allowReportDeclined: true,
		allowReportBatch: true,
		allowReportTransaction: true,
		allowReportTransactions: true,
		allowReportEbtwApl: true,
		allowReportAll: true,
		allowReportGiftApproved: true,
		allowReportGiftSummary: true,
		allowReportGiftDetailed: true,
		allowReportLiability: true,
		allowReportNet: true,
		allowReportRelated: true,
		allowSummary: true,
	},
	saveonly: {
		allowCcSave: true,
	},
	authonly: {
		allowCcAuthOnly: true,
		allowEbtfsAuthOnly: true,
		allowEbtcbAuthOnly: true,
		allowEbtwAuthOnly: true,
	},
	custom: {},
};

export const permissions = [
	{ key: 'allowCcSale', label: 'Allow credit card sale', snakeKey: 'Allow_CC_Sale' },
	{ key: 'allowCcAuthOnly', label: 'Allow credit card auth only', snakeKey: 'Allow_CC_AuthOnly' },
	{ key: 'allowCcPostAuth', label: 'Allow credit card post auth', snakeKey: 'Allow_CC_PostAuth' },
	{ key: 'allowCcAdjust', label: 'Allow credit card adjust', snakeKey: 'Allow_CC_Adjust' },
	{ key: 'allowAvsOnly', label: 'Allow avs only', snakeKey: 'Allow_AvsOnly' },
	{ key: 'allowCcCapture', label: 'Allow credit card capture', snakeKey: 'Allow_CC_Capture' },
	{ key: 'allowCcUncapture', label: 'Allow credit card uncapture', snakeKey: 'Allow_CC_Uncapture' },
	{ key: 'allowCcBalance', label: 'Allow credit card balance', snakeKey: 'Allow_CC_Balance' },
	{ key: 'allowCcVoid', label: 'Allow credit card void', snakeKey: 'Allow_CC_Void' },
	{ key: 'allowCcVoidRelease', label: 'Allow credit card void release', snakeKey: 'Allow_CC_VoidRelease' },
	{ key: 'allowCcVoidRefund', label: 'Allow credit card void refund', snakeKey: 'Allow_CC_VoidRefund' },
	{ key: 'allowCcRefund', label: 'Allow credit card refund', snakeKey: 'Allow_CC_Refund' },
	{ key: 'allowCcCredit', label: 'Allow credit card credit', snakeKey: 'Allow_CC_Credit' },
	{ key: 'allowCcCreditVoid', label: 'Allow credit card credit void', snakeKey: 'Allow_CC_CreditVoid' },
	{ key: 'allowCcAuthenticate', label: 'Allow credit card authenticate', snakeKey: 'Allow_CC_Authenticate' },
	{ key: 'allowCcLookup', label: 'Allow credit card lookup', snakeKey: 'Allow_CC_Lookup' },
	{ key: 'allowCcAuthenticate3D', label: 'Allow credit card authenticate3D', snakeKey: 'Allow_CC_Authenticate3D' },
	{ key: 'allowCheckSale', label: 'Allow check sale', snakeKey: 'Allow_Check_Sale' },
	{ key: 'allowCheckCredit', label: 'Allow check credit', snakeKey: 'Allow_Check_Credit' },
	{ key: 'allowCheckVoid', label: 'Allow check void', snakeKey: 'Allow_Check_Void' },
	{ key: 'allowCheckVoidRefund', label: 'Allow check void refund', snakeKey: 'Allow_Check_VoidRefund' },
	{ key: 'allowCheckRefund', label: 'Allow check refund', snakeKey: 'Allow_Check_Refund' },
	{ key: 'allowCheckAdjust', label: 'Allow check adjust', snakeKey: 'Allow_Check_Adjust' },
	{ key: 'allowCheckSave', label: 'Allow check save', snakeKey: 'Allow_Check_Save' },
	{ key: 'allowCcSave', label: 'Allow credit card save', snakeKey: 'Allow_CC_Save' },
	{ key: 'allowCashSale', label: 'Allow cash sale', snakeKey: 'Allow_Cash_Sale' },
	{ key: 'allowFraudSave', label: 'Allow fraud save', snakeKey: 'Allow_Fraud_Save' },
	{ key: 'allowGiftIssue', label: 'Allow gift issue', snakeKey: 'Allow_Gift_Issue' },
	{ key: 'allowGiftRedeem', label: 'Allow gift redeem', snakeKey: 'Allow_Gift_Redeem' },
	{ key: 'allowGiftBalance', label: 'Allow gift balance', snakeKey: 'Allow_Gift_Balance' },
	{ key: 'allowGiftAdjust', label: 'Allow gift adjust', snakeKey: 'Allow_Gift_Adjust' },
	{ key: 'allowGiftAddCard', label: 'Allow gift add card', snakeKey: 'Allow_Gift_AddCard' },
	{ key: 'allowGiftEnroll', label: 'Allow gift enroll', snakeKey: 'Allow_Gift_Enroll' },
	{ key: 'allowGiftExpire', label: 'Allow gift expire', snakeKey: 'Allow_Gift_Expire' },
	{ key: 'allowGiftActivate', label: 'Allow gift activate', snakeKey: 'Allow_Gift_Activate' },
	{ key: 'allowGiftDeactivate', label: 'Allow gift deactivate', snakeKey: 'Allow_Gift_Deactivate' },
	{ key: 'allowEbtfsSale', label: 'Allow ebtfs sale', snakeKey: 'Allow_Ebtfs_Sale' },
	{ key: 'allowEbtfsReturn', label: 'Allow ebtfs return', snakeKey: 'Allow_Ebtfs_Return' },
	{ key: 'allowEbtfsVoucher', label: 'Allow ebtfs voucher', snakeKey: 'Allow_Ebtfs_Voucher' },
	{ key: 'allowEbtfsAuthOnly', label: 'Allow ebtfs auth only', snakeKey: 'Allow_Ebtfs_AuthOnly' },
	{ key: 'allowEbtfsVoid', label: 'Allow ebtfs void', snakeKey: 'Allow_Ebtfs_Void' },
	{ key: 'allowEbtfsBalance', label: 'Allow ebtfs balance', snakeKey: 'Allow_Ebtfs_Balance' },
	{ key: 'allowEbtcbCash', label: 'Allow ebtcb cash', snakeKey: 'Allow_Ebtcb_Cash' },
	{ key: 'allowEbtcbSale', label: 'Allow ebtcb sale', snakeKey: 'Allow_Ebtcb_Sale' },
	{ key: 'allowEbtcbVoid', label: 'Allow ebtcb void', snakeKey: 'Allow_Ebtcb_Void' },
	{ key: 'allowEbtcbAuthOnly', label: 'Allow ebtcb auth only', snakeKey: 'Allow_Ebtcb_AuthOnly' },
	{ key: 'allowEbtcbBalance', label: 'Allow ebtcb balance', snakeKey: 'Allow_Ebtcb_Balance' },
	{ key: 'allowEbtwAuthOnly', label: 'Allow ebtw authOnly', snakeKey: 'Allow_Ebtw_AuthOnly' },
	{ key: 'allowEbtwBalance', label: 'Allow ebtw balance', snakeKey: 'Allow_Ebtw_Balance' },
	{ key: 'allowEbtwVoucher', label: 'Allow ebtw voucher', snakeKey: 'Allow_Ebtw_Voucher' },
	{ key: 'allowEbtwSale', label: 'Allow ebtw sale', snakeKey: 'Allow_Ebtw_Sale' },
	{ key: 'allowEbtwCapture', label: 'Allow ebtw capture', snakeKey: 'Allow_Ebtw_Capture' },
	{ key: 'allowEbtwCredit', label: 'Allow ebtw credit', snakeKey: 'Allow_Ebtw_Credit' },
	{ key: 'allowEbtwVoid', label: 'Allow ebtw void', snakeKey: 'Allow_Ebtw_Void' },
	{ key: 'allowCcSettle', label: 'Allow credit card settle', snakeKey: 'Allow_CC_Settle' },
	{ key: 'allowReportApproved', label: 'Allow report approved', snakeKey: 'Allow_Report_Approved' },
	{ key: 'allowReportOther', label: 'Allow report other', snakeKey: 'Allow_Report_Other' },
	{ key: 'allowReportAuth', label: 'Allow report auth', snakeKey: 'Allow_Report_Auth' },
	{ key: 'allowReportDeclined', label: 'Allow report declined', snakeKey: 'Allow_Report_Declined' },
	{ key: 'allowReportBatch', label: 'Allow report batch', snakeKey: 'Allow_Report_Batch' },
	{ key: 'allowReportTransaction', label: 'Allow report transaction', snakeKey: 'Allow_Report_Transaction' },
	{ key: 'allowReportTransactions', label: 'Allow report transactions', snakeKey: 'Allow_Report_Transactions' },
	{ key: 'allowReportEbtwApl', label: 'Allow report ebtw apl', snakeKey: 'Allow_Report_EbtwAPL' },
	{ key: 'allowReportAll', label: 'Allow report all', snakeKey: 'Allow_Report_All' },
	{ key: 'allowReportGiftApproved', label: 'Allow report gift approved', snakeKey: 'Allow_Report_GiftApproved' },
	{ key: 'allowReportGiftSummary', label: 'Allow report gift summary', snakeKey: 'Allow_Report_GiftSummary' },
	{ key: 'allowReportGiftDetailed', label: 'Allow report gift detailed', snakeKey: 'Allow_Report_GiftDetailed' },
	{ key: 'allowReportLiability', label: 'Allow report liability', snakeKey: 'Allow_Report_Liability' },
	{ key: 'allowReportNet', label: 'Allow report net', snakeKey: 'Allow_Report_Net' },
	{ key: 'allowReportRelated', label: 'Allow report related', snakeKey: 'Allow_Report_Related' },
	{ key: 'allowSummary', label: 'Allow summary', snakeKey: 'Allow_Summary' },
	{ key: 'allowEmvaid', label: 'Allow emvaid', snakeKey: 'Allow_EMV_AID' },
	{ key: 'allowOmxAdjust', label: 'Allow omx adjust', snakeKey: 'Allow_OMX_Adjust' },
	{ key: 'allowOmxUnhold', label: 'Allow omx unhold', snakeKey: 'Allow_OMX_Unhold' },
	{ key: 'allowOmxCancel', label: 'Allow omx cancel', snakeKey: 'Allow_OMX_Cancel' },
	{ key: 'allowOmxOrderInfo', label: 'Allow omx order info', snakeKey: 'Allow_OMX_OrderInfo' },
	{ key: 'allowL3Add', label: 'Allow L3 add', snakeKey: 'Allow_L3Add' },
	{ key: 'allowL3Del', label: 'Allow L3 del', snakeKey: 'Allow_L3Del' },
	{ key: 'allowFraudReSubmit', label: 'Allow fraud resubmit', snakeKey: 'Allow_Fraud_ReSubmit' },
	{ key: 'allowFraudSubmit', label: 'Allow fraud submit', snakeKey: 'Allow_Fraud_Submit' },
	{ key: 'allowFraudAdjust', label: 'Allow fraud adjust', snakeKey: 'Allow_Fraud_Adjust' },
	{ key: 'allowTrnSignature', label: 'Allow trn signature', snakeKey: 'Allow_Trn_Signature' },
];
