import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, clone } from 'lodash';

import { OutsideClick } from '../../utilities';
import { titles, mobileTitles, linkTitles } from './constants';
import { principalService } from '../../services';

class Title extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showLinksDropDown: false,
			permissions: {},
			titles,
			mobileTitles,
			linkTitles,
		};
	}

	componentDidMount() {
		const principal = principalService.get();
		const permissions = (principal && principal.idInfo && principal.idInfo.permissions) || {};
		const titles = clone(this.state.titles);
		const mobileTitles = clone(this.state.mobileTitles);
		const linkTitles = clone(this.state.linkTitles);
		if (!permissions.allowReportGiftApproved) {
			delete titles.giftReport;
			delete mobileTitles.giftReport;
			delete linkTitles.giftReport;
		}
		if (!permissions.allowReportLiability) {
			delete titles.giftCardLiability;
			delete mobileTitles.giftCardLiability;
			delete linkTitles.giftCardLiability;
		}
		if (!permissions.allowReportGiftSummary) {
			delete titles.giftCardSummary;
			delete mobileTitles.giftCardSummary;
			delete linkTitles.giftCardSummary;
		}
		const newState = {
			titles,
			mobileTitles,
			linkTitles,
		};
		this.setState(newState);
	}

	get linksActiveClass() {
		return this.state.showLinksDropDown ? 'open' : 'closed';
	}

	toggleLinksDropDown = () => {
		this.setState({
			showLinksDropDown: !this.state.showLinksDropDown,
		});
	};

	onCloseLinksDropDown = () => {
		if (!this.state.showLinksDropDown) return;
		this.setState({
			showLinksDropDown: false,
		});
	};

	handleLinkDropDownClick = key => {
		this.props.onTitleClick(key);
		this.onCloseLinksDropDown();
	};

	render = () => {
		const { showLinksDropDown, titles, mobileTitles, linkTitles } = this.state;
		return (
			<OutsideClick action={this.onCloseLinksDropDown} className="header__title__holder">
				<React.Fragment>
					<div className="hide--from--med">
						<div onClick={this.toggleLinksDropDown} className={this.linksActiveClass}>
							<div className="header__title datatooltip--gift">
								<span className="hide--to--med--inline">{titles[this.props.type]}</span>
								<span className="hide--from--med--inline">{mobileTitles[this.props.type]}</span>
								<i
									className="icon icon--tiny icon--arrow--dropdown is-active align--v--neg--2 spc--left--xsml"
									data-tooltip="Additional gift reports"
								></i>
							</div>
						</div>
						{showLinksDropDown ? (
							<div className={`header__title__menu ${this.linksActiveClass}`}>
								<ul className="header__title__menu__list">
									{map(titles, (title, key) =>
										key !== this.props.type ? (
											<li key={key} className="header__title__menu__item">
												<span onClick={() => this.handleLinkDropDownClick(key)} className="header__title__menu__link">
													{title}
												</span>
											</li>
										) : null
									)}
								</ul>
							</div>
						) : null}
					</div>
					<div className="header__title__tabs">
						{map(linkTitles, (title, key) => (
							<a
								key={key}
								href="javascript:void(0)"
								onClick={() => this.handleLinkDropDownClick(key)}
								className={`header__title__link ${key === this.props.type ? 'is-active' : ''}`}
							>
								{title}
							</a>
						))}
					</div>
				</React.Fragment>
			</OutsideClick>
		);
	};
}

Title.propTypes = {
	onTitleClick: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
};

export default Title;
