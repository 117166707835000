import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Tooltip, PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { includes, filter, sumBy, toLower, map, some, each, has, keys, split, isEmpty, round } from 'lodash';

import { CurrencyMap, CardTypeImagePath } from '../../Common/utilities';

const RADIAN = Math.PI / 180;
const chartColors = {
	visa: {
		sale: '#794EBF',
		credit: '#D21745',
	},
	mastercard: {
		sale: '#6E48AC',
		credit: '#BD1740',
	},
	discover: {
		sale: '#634299',
		credit: '#A8173B',
	},
	amex: {
		sale: '#583B87',
		credit: '#931735',
	},
	ebt: {
		sale: '#4D3574',
		credit: '#7E1730',
	},
	ebtw: {
		sale: '#422F61',
		credit: '#6A162B',
	},
	grant: {
		sale: '#362939',
		credit: '#4F292F',
	},
	gift: {
		sale: '#211C29',
		credit: '#2B161B',
	},
	other: {
		sale: '#141414',
		credit: '#161616',
	},
};

const cardTypes = {
	visa: 'Visa',
	mastercard: 'Master Card',
	discover: 'Discover',
	amex: 'Amex',
	ebt: 'EBT',
	ebtw: 'EBTW',
	grant: 'Grant',
	gift: 'Gift',
	other: 'Other',
};

class DashboardPieChart extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currency: CurrencyMap.resolveCurrency(),
		};
	}

	getCardType = cardType => {
		includes(['pledger', 'donorsfund', 'ojc'], toLower(cardType));
		const isGrant = includes(['pledger', 'donorsfund', 'ojc'], toLower(cardType));
		const cType = isGrant ? 'Grant' : cardType;
		return cType;
	};

	getCardData = (transactions, cardType) => {
		const { type } = this.props;
		const cType = this.getCardType(cardType);
		const filteredTransactions = filter(transactions, ({ card }) => card === cardType);
		return {
			name: cardTypes[cType],
			icon: cardType,
			isNa: false,
			color: chartColors[toLower(cType)][type],
			amount: round(sumBy(filteredTransactions, t => round(t.amount, 2)), 2),
			count: filteredTransactions.length,
		};
	};

	getData = () => {
		const { data, type, shouldDisplayChart } = this.props;
		if (!data || isEmpty(data.xReportData) || !shouldDisplayChart) {
			return this.getEmptyData();
		}

		const transactions = [];
		const validCommands =
			type === 'sale'
				? ['sale', 'capture', 'postauth', 'authonly', 'redeem', 'recommendation']
				: ['credit', 'refund', 'issue'];
		each(data.xReportData, ({ xCommand, xAmount, xCardType }) => {
			const [, command] = split(toLower(xCommand), ':');
			if (includes(validCommands, command)) {
				const cardType = toLower(this.getCardType(xCardType));
				transactions.push({
					amount: Math.abs(xAmount),
					card: has(cardTypes, cardType) ? cardType : 'other',
				});
			}
		});

		const mappedData = map(keys(cardTypes), cardType => this.getCardData(transactions, cardType));
		if (!some(mappedData, ({ amount }) => amount > 0)) {
			return this.getEmptyData();
		}
		return mappedData;
	};

	getEmptyData = () => {
		const { type } = this.props;
		const color = type === 'sale' ? 'var(--color-primary)' : '#fcdfe5';
		return [
			{
				name: 'N/A',
				icon: 'N/A',
				isNa: true,
				color,
				amount: 1,
				count: 'N/A',
			},
		];
	};

	getDashboardCardName = name => {
		if (toLower(name) === 'ebtw') {
			name = 'eWIC';
		}
		return name;
	};

	renderCustomTooltip = data => {
		let payload = {};
		let payloadTooltipName = this.getDashboardCardName(payload.name);
		if (this.props.shouldDisplayChart && data && data.payload && data.payload[0] && data.payload[0].payload) {
			payload = data.payload[0].payload;
		} else {
			payload = {
				color: '#9AA2B0',
				stroke: '#9AA2B0',
				fill: '#9AA2B0',
				icon: 'N/A',
				name: 'N/A',
				amount: 0,
				count: 'N/A',
			};
		}

		if (payload.isNa) {
			payload.amount = 0;
		}

		return (
			<div className="batches__chart__tooltip">
				<div className="spc--bottom--sml">
					{this.props.shouldDisplayChart ? (
						<React.Fragment>
							<img src={CardTypeImagePath.getPath(payload.icon)} className="grid__creditcard" />
							<span className="type--color--text--regular">{payloadTooltipName}</span>
						</React.Fragment>
					) : (
						<React.Fragment>
							<div className="type--center">
								<img src="/static/media/warning.jpg" className="spc--bottom--nano" />
								<div className="type--color--warning type--wgt--medium type--lineheight--22">
									There are no values
									<br />
									to show in this view
								</div>
								<hr className="separator separator--grey1" />
							</div>
						</React.Fragment>
					)}
				</div>
				<div className="group type--sml">
					<div className="batches__chart__tooltip__label"></div>
					<div className="batches__chart__tooltip__value">
						<NumberFormat
							prefix={this.state.currency}
							value={payload.amount}
							displayType="text"
							thousandSeparator={true}
							decimalScale={2}
							fixedDecimalScale={true}
						/>
						<span> {`(Count: ${payload.count})`}</span>
					</div>
				</div>
			</div>
		);
	};

	renderCustomChartLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		if (this.props.shouldDisplayChart) {
			if (percent > 0.04) {
				return (
					<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
						{' '}
						{`${(percent * 100).toFixed(0)}%`}
					</text>
				);
			}
		} else {
			return (
				<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
					0%
				</text>
			);
		}
	};

	renderLegend = data => {
		const total = round(sumBy(data, dataItem => round(dataItem.amount, 2)), 2) || 0;
		return map(data, ({ amount, isNa, name, color }) => {
			const legendName = this.getDashboardCardName(name);
			if (isNa) {
				return (
					<div key={legendName}>
						<span style={{ color: color, fontSize: '36px', verticalAlign: 'text-bottom' }}>&#9679;</span> {legendName}{' '}
						(100%)
					</div>
				);
			}
			const percent = (amount / total) * 100;
			if (percent > 0) {
				const percentString = percent < 1 ? '<1' : percent.toFixed(0);

				return (
					<div key={legendName} className="piechart-legend-item">
						<span style={{ color: color, fontSize: '36px', verticalAlign: 'text-bottom' }}>&#9679;</span> {legendName} (
						{percentString}%)
					</div>
				);
			}
		});
	};

	render() {
		const chartData = this.getData();
		const chartTitle = this.props.type === 'sale' ? 'Sales Summary' : 'Credit Summary';

		return (
			<React.Fragment>
				<h3 className="title title--secondary">{chartTitle}</h3>
				<div className="batches__card">
					<div className="f-row">
						<div className="f-col f-col-sml-12 f-col-med-6">
							<ResponsiveContainer height="100%" aspect={1} debounce={1}>
								<PieChart width={110} height={110}>
									<Tooltip content={this.renderCustomTooltip} />
									<Pie
										data={chartData}
										paddingAngle={0}
										innerRadius="56%"
										outerRadius="100%"
										fill="#82ca9d"
										dataKey="amount"
										legendType="circle"
										label={this.renderCustomChartLabel}
										labelLine={false}
									>
										{chartData.map((entry, index) => {
											return <Cell key={`cell-${index}`} stroke={entry.color} fill={entry.color} />;
										})}
									</Pie>
								</PieChart>
							</ResponsiveContainer>
						</div>
						<div className="f-col f-col-sml-12 f-col-med-6 pos--rel">
							<div className="batches__chart__legend">{this.renderLegend(chartData)}</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

DashboardPieChart.propTypes = {
	data: PropTypes.any,
	type: PropTypes.oneOf(['sale', 'credit']).isRequired,
	shouldDisplayChart: PropTypes.bool,
};

export default DashboardPieChart;
