import { split, includes, toLower, map, isObject } from 'lodash';

import principalService from '../services/principalService';

export const featurePackageTooltips = {
	noCustomerBilling:
		'Your account does not support Customers & Recurring Billing. Please reach out to cs@cardknox.com to upgrade your pricing plan.',
	hasTerminalOnly:
		'Your account is Terminal Only and does not support this action. Please reach out to your agent to upgrade your pricing plan.',
};

export const featurePackages = {
	terminalOnly: {
		featurePackage: 'EMV',
		exactMatch: true,
	},
	nonTerminal: 'NonTerminal',
	customerBilling: 'CustomerBilling',
	intelligentRouting: 'IntelligentRouting',
	emv: 'EMV',
};

export const hasFeaturePackage = featurePackage => {
	const principal = principalService.get();
	const principalFeaturePackages =
		principal && principal.idInfo && map(split(principal.idInfo.xFeaturePackage, ','), item => toLower(item));

	if (isObject(featurePackage) && featurePackage.exactMatch && principalFeaturePackages.length === 1) {
		return principalFeaturePackages[0] === toLower(featurePackage.featurePackage);
	} else {
		return includes(principalFeaturePackages, toLower(featurePackage));
	}
};
