import React from 'react';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';

function MobileAppNotification(props) {
	return (
		<div className="">
			<p className="spc--bottom--sml">Accept and manage payments on the go with our free mobile app!</p>
			<div className="spc--bottom--sml--alt">
				<button
					className="btn btn--sml btn--primary spc--left--tny"
					onClick={() =>
						props.history.replace({
							pathname: '/',
							state: {
								displaySendLink: true,
							},
						})
					}
				>
					Send Link
				</button>
				<button
					className="btn btn--sml btn--ghost spc--left--tny"
					onClick={() => window.open('https://www.cardknox.com/mobile-app/', '_blank', 'noopener')}
				>
					Learn More
				</button>
			</div>
		</div>
	);
}
MobileAppNotification.propTypes = {
	history: object.isRequired,
};
export default withRouter(MobileAppNotification);
