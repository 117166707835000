import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { each, clone, isEmpty, map, take, takeRight, findIndex, times, size } from 'lodash';

import { CurrencyMap } from 'common/utilities';
import { withError } from 'common/components/error';
import { BatchGrid, BatchGridHeader, BatchGridHeaderItem, BatchGridBody, BatchGridRow } from './grid';

const tabs = {
	closed: 'Closed Batches',
	open: 'Open Batches',
};

const defaultColumns = [
	{
		key: 'xBatch',
		name: 'ID #',
		sortable: true,
	},
	{
		key: 'xBatchDate',
		name: 'Date and Time',
		isDefaultSorter: true,
		defaultSortDirection: 'DESC',
		sortable: true,
	},
	{
		key: 'xTotalAmount',
		name: 'Total',
		className: 'type--right',
		sortable: true,
	},
	{
		key: 'xSaleAmount',
		name: 'Sale',
		className: 'type--right',
		sortable: true,
	},
	{
		key: 'xCreditAmount',
		name: 'Credit',
		className: 'type--right',
		sortable: true,
	},
	{
		key: 'actions',
		name: '',
		className: 'type--right',
	},
];

const salesIndex = findIndex(defaultColumns, { key: 'xSaleAmount' }) + 1;

const voidColumns = [
	...take(defaultColumns, salesIndex),
	{
		key: 'xVoidAmount',
		name: 'Void',
		className: 'type--right',
	},
	...takeRight(defaultColumns, defaultColumns.length - salesIndex),
];

const initExpanded = input => {
	const expanded = {};
	if (!isEmpty(input)) {
		each(input, ({ xBatch, index }) => {
			expanded[xBatch || index] = false;
		});
	}
	return expanded;
};

class BatchDataGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currency: CurrencyMap.resolveCurrency(),
			expanded: {},
			columns: this.columns,
		};
		this.rowPortals = [];
	}

	static getDerivedStateFromProps = (nextProps, prevState) => {
		if (nextProps.rows !== prevState.rows) {
			const state = {
				rows: nextProps.rows,
				expanded: initExpanded(nextProps.rows),
			};

			nextProps.updateExpandedRows(state.expanded);
			return state;
		}
		return null;
	};

	componentDidUpdate(prevProps) {
		if (prevProps.rows !== this.props.rows) {
			this.rowPortals = times(size(prevProps.rows), () => null);
		}
		if (prevProps.activeTab !== this.props.activeTab) {
			this.setState({ columns: this.columns });
		}
	}

	get columns() {
		let columns = [...(this.props.showVoid ? voidColumns : defaultColumns)];
		if (this.props.showProcessingFee) {
			columns = [
				...take(columns, salesIndex - 1),
				{
					key: 'xTotalProcessingFeeAmount',
					name: 'Processing Fee',
					className: 'type--right',
					sortable: true,
				},
				{
					key: 'xNetTotalAmount',
					name: 'Net Total',
					className: 'type--right',
					sortable: true,
				},
				...takeRight(columns, columns.length - salesIndex + 1),
			];
		}
		if (this.props.isFundedReport || this.props.isFundedReportOpenBatches) {
			columns.shift();
		}
		return columns;
	}

	toggleExpand = (index, allExpanded) => {
		const expanded = clone(this.state.expanded);

		each(expanded, (_, i) => {
			if (index === 'all') {
				expanded[i] = !allExpanded;
			} else if (index == i) {
				expanded[i] = !expanded[i];
			}
		});

		this.setState(
			{
				expanded: expanded,
			},
			() => {
				this.props.updateExpandedRows(this.state.expanded);
			}
		);
	};

	renderEmptyGrid = () => {
		const { showVoid, showProcessingFee } = this.props;
		const colSpan = showVoid && showProcessingFee ? 7 : showProcessingFee ? 6 : 5;

		return (
			<tbody className="dtable__body">
				<tr className="dtable__row">
					<td colSpan={colSpan} className="dtable__cell dtable__cell--emptystate">
						<div className="loader__holder">
							{showVoid ? (
								<div className="emptystate">
									<div className="emptystate__title">There are currently no open batches</div>
								</div>
							) : (
								<div className="emptystate">
									<div className="emptystate__title">0 Results</div>
									<p className="emptystate__message">You should change your filter options</p>
								</div>
							)}
						</div>
					</td>
				</tr>
			</tbody>
		);
	};

	onGridSort = (key, sortDirection) => {
		const columns = clone(this.state.columns);
		each(columns, column => {
			if (key !== column.key) {
				column.sortDirection = 'NONE';
			} else {
				column.sortDirection = sortDirection;
			}
		});
		this.setState({ columns }, () => this.props.onGridSort(key, sortDirection));
	};

	render() {
		const { rows, expanded, columns } = this.state;
		const {
			showVoid,
			showProcessingFee,
			batches,
			isFundedReport,
			isFundedReportOpenBatches,
			handleBreakdownOpen,
			activeTab,
			exportButtons,
			handleError,
		} = this.props;

		const openBatches = isFundedReportOpenBatches ? batches : [];

		return (
			<div className="spc--bottom--xxlrg">
				{map(rows, (_, index) => (
					<div
						key={index}
						className="display--n"
						ref={el => {
							this.rowPortals[index] = el;
						}}
					></div>
				))}
				<BatchGrid
					expanded={expanded}
					handleBreakdownOpen={handleBreakdownOpen}
					toggleExpandAll={this.toggleExpand}
					caption="Batches Details"
					className="dtable type--wgt--medium"
					exportButtons={exportButtons}
				>
					<BatchGridHeader className="dtable__header">
						{map(columns, (column, i) => {
							const lastClass = columns.length === i + 1 ? 'dtable__header__cell--right' : '';
							return (
								<BatchGridHeaderItem
									key={i}
									column={column}
									className={`dtable__header__cell ${lastClass} ${column.className}`}
									onGridSort={this.onGridSort}
									tabs={tabs}
									displaySorting={activeTab === tabs.closed}
								/>
							);
						})}
					</BatchGridHeader>
					{!isEmpty(rows) ? (
						<BatchGridBody className="dtable__body">
							{map(rows, (row, index) => (
								<BatchGridRow
									key={row.xBatch || row.index}
									row={row}
									columns={columns}
									expanded={expanded}
									toggleExpand={this.toggleExpand}
									showVoid={showVoid}
									showProcessingFee={showProcessingFee}
									batches={isFundedReport ? batches[row.xBatchDate] : openBatches}
									displayEmptyIdColumn={activeTab == tabs.closed ? !isFundedReport : !isFundedReportOpenBatches}
									handleError={handleError}
									portal={this.rowPortals[index]}
								/>
							))}
						</BatchGridBody>
					) : (
						this.renderEmptyGrid()
					)}
				</BatchGrid>
			</div>
		);
	}
}

BatchDataGrid.propTypes = {
	rows: PropTypes.array,
	showVoid: PropTypes.bool,
	showProcessingFee: PropTypes.bool,
	isFundedReport: PropTypes.bool,
	isFundedReportOpenBatches: PropTypes.bool,
	batches: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
	handleBreakdownOpen: PropTypes.func,
	activeTab: PropTypes.string.isRequired,
	exportButtons: PropTypes.any,
	handleError: PropTypes.func,
	onGridSort: PropTypes.func.isRequired,
	updateExpandedRows: PropTypes.func,
};

export default withError(BatchDataGrid);
