import React from 'react';
import PropTypes from 'prop-types';
import { includes, toLower } from 'lodash';

const trueValues = ['1', 'true'];

const BooleanMethod = ({ value }) => {
	if (value == null) return null;
	const booleanValue = includes(trueValues, toLower(value)) ? 'True' : 'False';
	return <div>{booleanValue}</div>;
};

BooleanMethod.propTypes = {
	value: PropTypes.string,
};

export default BooleanMethod;
