import { get, includes, size, split, toLower } from 'lodash';
const digitalWalletsFeatureConstant = principal => {
	const featurePackage = get(principal, 'idInfo.xFeaturePackage', '');
	const featureList = split(featurePackage, ',');
	const isPremium = size(featureList) === 4;
	const isStarter = toLower(featurePackage) === 'nonterminal';
	let plan = '';
	if (size(featureList) === 2 && includes(featureList, 'CustomerBilling') && includes(featureList, 'NonTerminal')) {
		plan = 'Standard';
	} else if (isPremium) {
		plan = 'Premium';
	} else if (isStarter) {
		plan = 'Starter';
	}
	const digitalWalletsFeature = {
		templateId: 'digitalwallet',
		path: 'digital-wallet',
		title: 'Streamline Checkout With Digital Wallets',
		details: 'Enable Google Pay, and Apple Pay on PaymentSITE hosted checkout pages to make checkout easier than ever.',
		advancedDetails: {
			title: 'Streamline Checkout With Digital Wallets',
			subtitle: 'Streamline Checkout by Enabling Google Pay and Apple Pay',
			learnMoreLink:
				'https://www.cardknox.com/paymentsite/?utm_source=portal&utm_medium=banner&utm_campaign=cs#wallets',
			details:
				'By enabling Google Pay and Apple Pay on PaymentSITE hosted checkout pages, you’ll make it easier than ever for your customers to complete transactions. With no need to enter credit card numbers manually, payments can be\n made with just a few clicks. Your customers will thank you!\n\n And the best news? You can enable Digital Wallets at no additional cost.',
		},
		successMessage:
			'Your request has been submitted. After we review your account, you will receive an email with instructions on setting up Digital Wallets on your PaymentSITE.',
		question: 'Please confirm you would like to enable Digital Wallets on your PaymentSITE at no additional cost.',
		activateLabel: 'Activate',
		additionalContent: {
			plan,
		},
	};
	if (!isPremium) {
		digitalWalletsFeature.successMessage =
			'Your request has been submitted. After we upgrade your account, you will receive an email with instructions on setting up Digital Wallets on your PaymentSITE.';
		digitalWalletsFeature.question =
			"We'll need to upgrade your account to the Premium Plan to enable Digital Wallets.\n Please confirm you would like to upgrade your account for $29.99 per month plus $.10 per transaction.";
		digitalWalletsFeature.advancedDetails.details =
			'By enabling Google Pay and Apple Pay on PaymentSITE hosted checkout pages, you’ll make it easier than ever for your customers to complete transactions. With no need to enter credit card numbers manually, payments can be made with just a few clicks.\n\nClick “Activate” to upgrade your account to Premium to enable Apple Pay and Google Pay for $29.99 per month plus $.10 per transaction. Your customers will thank you!';
	}
	return digitalWalletsFeature;
};

export default digitalWalletsFeatureConstant;
