import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactModal from 'react-modal';
import { Auth } from 'aws-amplify';
import { includes, get, toLower, find, each } from 'lodash';
import { parse } from 'query-string';

import App from './App';
import '../public/modernizr.custom.min';
import './styles/css/app.css';
import { principalService, lastAccessService, authenticationService } from 'common/services';
import setDelightedStyles from 'common/utilities/setDelightedStyles';

ReactModal.setAppElement('#app');
window.addEventListener('storage', async function(event) {
	if (event.key == 'signIn/out') {
		location.reload();
	}
});

(async () => {
	let currentAuthUser;

	const delightedSelector = '.delighted-web-survey';

	const mo = new MutationObserver(onMutation);
	onMutation([{ addedNodes: [document.documentElement] }]);
	observe();

	function onMutation(mutations) {
		let stopped;
		// loop through all added nodes until the one with the given selector is found
		if (mutations)
			each(mutations, ({ addedNodes }) => {
				each(addedNodes, n => {
					if (n.tagName) {
						if (n.matches(delightedSelector) || (n.firstElementChild && n.querySelector(delightedSelector))) {
							stopped = true;
							// disconnect in order to prevent unnecessary repetitive calls
							mo.disconnect();
							setDelightedStyles();
						}
					}
				});
			});

		if (stopped) observe();
	}

	function observe() {
		mo.observe(document, {
			subtree: true,
			childList: true,
		});
	}

	try {
		currentAuthUser = await Auth.currentAuthenticatedUser();
		const { username } = currentAuthUser;
		window.onload = () => {
			window.dataLayer.push({ event: 'userIdAvailable', user_id: username });
			document.onreadystatechange = setDelightedStyles();
			// second call is made to ensure the inline styles are set
		};
	} catch (err) {
		principalService.clear();
	} finally {
		const dropinDBA = get(parse(window.location.search), 'dropinDBA');

		if (!lastAccessService.isValid) {
			lastAccessService.clear();
			if (currentAuthUser) {
				currentAuthUser = null;
				await Auth.signOut();
			}
		}

		const cookie = includes(document.cookie, `${AppBuildEnvironment}RedirectToNewPortal=true`);
		if (cookie) {
			document.cookie = `${AppBuildEnvironment}RedirectToNewPortal=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${
				AppBuildEnvironment !== 'local' ? 'cardknox.com' : LocalHost
			}`;
		}
		if (!principalService.get() && currentAuthUser) {
			try {
				const {
					attributes: { email },
					signInUserSession: {
						idToken: { jwtToken },
					},
				} = currentAuthUser;
				await authenticationService.login(jwtToken, email);
			} catch (err) {
				//intentionally empty catch block
			}
		} else if (currentAuthUser && dropinDBA) {
			const principal = principalService.get();
			const company = find(principal.list, ({ dba }) => toLower(dba) === toLower(dropinDBA));

			if (company && toLower(company.dba) !== toLower(principal.companyName)) {
				const idInfo = await authenticationService.validateKey(company.key, company.mid);
				principalService.set({
					id: company.mid,
					companyName: company.dba_alias || company.dba,
					list: principal.list,
					idInfo: idInfo,
				});
				window.location = '/';
			}
		}
		lastAccessService.refresh();
		const rootElement = document.getElementById('app');
		const root = createRoot(rootElement);
		root.render(<App />);
	}
})();
