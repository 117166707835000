import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { trim, toLower, isEmpty, map, startsWith, includes } from 'lodash';
import { Link } from 'react-router-dom';

import { principalService } from 'common/services';
import { PasswordInput } from 'common/components/password-input';
import { logger } from 'common/utilities';
import PrivacyPolicyFooter from 'common/components/footer/PrivacyPolicyFooter';
import { mapPasswordRequirements } from './utils/loginUtils';

class RegisterComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			username: '',
			password: '',
			password2: '',
			errorMessage: null,
			isFocusedPassword: false,
			showOldPortalMessage: startsWith(props.history.location.pathname, '/register2'),
		};
	}

	componentWillMount() {
		if (principalService.get()) {
			this.redirect();
		}
	}

	setErrorMessage = errorMessage => {
		this.setState({ errorMessage });
	};

	handleChange = ({ target: { name, value } }) => {
		this.setState({
			[name]: value,
		});
	};

	handleFocusChange = isFocusedPassword => this.setState({ isFocusedPassword });

	handleSubmit = async event => {
		event.preventDefault();
		const { pendingRequest } = this.state;
		if (!this.formValidation() || pendingRequest) {
			return;
		}

		const { history } = this.props;
		const { password } = this.state;
		let { username } = this.state;
		username = trim(toLower(username));
		this.setState({ pendingRequest: true });
		try {
			const data = await Auth.signUp({
				username,
				password,
				attributes: {
					email: username,
				},
			});
			if (data.userConfirmed) {
				history.push({
					pathname: '/login',
					state: { username: username },
				});
			} else {
				history.push({
					pathname: '/confirm-registration',
					state: { username: username },
				});
			}
			return data;
		} catch (err) {
			logger.logError({
				message: 'Register error.',
				errorDetails: err,
				username,
			});
			let message;
			switch (err && err.code) {
				case 'InvalidParameterException': {
					message = err.message;
					break;
				}
				case 'UsernameExistsException': {
					message = (
						<span>
							{err.message} Click <Link to={{ pathname: '/login', state: { username: username } }}>here</Link> to log
							in.
						</span>
					);
					break;
				}
				case 'InvalidPasswordException': {
					if (includes(toLower(err.message), 'security')) {
						message = 'There may be a key word in your password that is not allowed. Please try another password';
					} else {
						message =
							'Your password needs to be at least 8 characters long and contain at least one uppercase character, one lowercase character, one special character and one number. The keyword "password" is not allowed.';
					}
					break;
				}
				default: {
					message = 'Something went wrong. Please try again.';
					break;
				}
			}
			this.setState({
				errorMessage: message,
				pendingRequest: false,
			});
		}
	};

	formValidation = () => {
		const { username, password, password2 } = this.state;

		if (username.length <= 0) {
			this.setState({
				errorMessage: 'Please enter your email',
			});
			return false;
		}

		if (password.length <= 0 || password2.length <= 0) {
			this.setState({
				errorMessage: 'Please enter your password',
			});
			return false;
		}

		if (password !== password2) {
			this.setState({
				errorMessage: 'Passwords do not match',
			});
			return false;
		}

		return true;
	};

	renderPasswordRequirements = () => {
		const requirements = mapPasswordRequirements(this.state.password);
		if (!isEmpty(requirements) && this.state.isFocusedPassword) {
			return (
				<div className="validation validation--requirements spc--bottom--sml">
					<p>Your password needs to contain at least:</p>
					<ul className="validation__list">
						{map(requirements, (item, index) => (
							<li key={index}>{item}</li>
						))}
					</ul>
				</div>
			);
		}
	};

	redirectToLogin = () => {
		const { history } = this.props;
		history.push('/login');
	};

	redirect() {
		const { history, location } = this.props;
		let redirectUrl = '/';

		if (location.state && location.state.returnUrl) {
			redirectUrl = location.state.returnUrl;
		}

		history.push(redirectUrl);
	}

	render() {
		const { username, password, password2, errorMessage, showOldPortalMessage } = this.state;

		return (
			<div>
				<form className="membership__form form" onSubmit={this.handleSubmit}>
					<div className="membership__section">
						{showOldPortalMessage && (
							<div className="message message--default spc--bottom--lrg">
								<p className="spc--bottom--sml">
									As of August 1, 2021 the merchant portal, secure.cardknox.com, has been deactivated and is no longer
									accessible.
								</p>
								<p className="spc--bottom--sml">
									Merchant account management is now available through our next-generation Cardknox merchant portal.
								</p>
								<p className="spc--bottom--sml">
									Please register now to enjoy the new portal's sleek interface, robust reporting features, improved
									virtual terminal functions, customizable recurring payments, and much more!
								</p>
								<p className="spc--bottom--sml">
									Please note, your transaction history will be available to you here. However, if you have stored
									customers in the customer database, you will need to reach out to our Support Team to migrate your
									data.
								</p>
								<p className="spc--bottom--sml">
									Please contact us at{' '}
									<a className="anchor anchor--primary" href="mailto:support@cardknox.com">
										support@cardknox.com
									</a>{' '}
									for questions.
								</p>
							</div>
						)}
						<h2 className="membership__title spc--bottom--med">Register with Your Account Email</h2>
						<div className="membership--main__action spc--bottom--med">
							<p>Already have a Merchant Portal account?</p>
							<div className="flex--primary">
								<p>Use your existing credentials to</p>
								<button
									type="button"
									onClick={this.redirectToLogin}
									className="btn membership--main__btn type--wgt--medium"
								>
									sign in.
								</button>
							</div>
						</div>
						<div className="membership__spacer">
							<label className="membership__label">Email</label>
							<input
								name="username"
								type="email"
								className="input input--med"
								placeholder="user@gmail.com"
								value={username}
								onChange={this.handleChange}
								tabIndex="1"
								inputMode="email"
							/>
						</div>
						<div className="membership__spacer">
							<label className="membership__label type--none">Create new Password</label>
							{this.renderPasswordRequirements()}
							<PasswordInput
								value={password}
								onChange={this.handleChange}
								tabIndex="2"
								setErrorMessage={this.setErrorMessage}
								onFocus={this.handleFocusChange}
							/>
						</div>
						<div className="membership__spacer">
							<label className="membership__label">Confirm Password</label>
							<PasswordInput
								placeholder="Confirm your password"
								name="password2"
								value={password2}
								onChange={this.handleChange}
								tabIndex="3"
								setErrorMessage={this.setErrorMessage}
							/>
							{errorMessage ? (
								<div className="spc--top--sml spc--bottom--med type--color--warning">{errorMessage}</div>
							) : null}
						</div>
						<button type="submit" className="btn btn--primary btn--med membership__btn">
							Register
						</button>
					</div>
				</form>
				<div className="membership__companies">
					<p className="membership__companies__title">Trusted by companies</p>
					<div className="membership__companies__wrapper group">
						<div className="membership__companies__item">
							<img src="/static/media/eaglerider-member.png" />
						</div>
						<div className="membership__companies__item">
							<img src="/static/media/good-year-member.png" />
						</div>
						<div className="membership__companies__item">
							<img src="/static/media/jesel-member.png" />
						</div>
						<div className="membership__companies__item">
							<img src="/static/media/maglite-member.png" />
						</div>
					</div>
				</div>
				<PrivacyPolicyFooter />
			</div>
		);
	}
}

RegisterComponent.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
};

export default RegisterComponent;
