import React from 'react';
import { string, object } from 'prop-types';

import { CardTypeImagePath } from 'common/utilities';
import TooltipFormatter from './TooltipFormatter';

class GiftCardComponent extends TooltipFormatter {
	static instances = [];
	constructor(props) {
		super(props, GiftCardComponent.instances);
		this.tooltipClass = 'recurring__tooltip';
	}

	get tooltip() {
		return 'Click to see Gift Card activity breakdown';
	}

	render() {
		const {
			value,
			dependentValues: { onClick },
		} = this.props;
		return (
			<div
				onClick={onClick}
				onMouseEnter={this.displayPopup}
				onMouseLeave={this.closePopupLater}
				className="display--ib align--v--middle cursor--pointer"
			>
				<img src={CardTypeImagePath.getPath('gift')} className="grid__creditcard" />
				<a className="anchor anchor--primary anchor--underline recurring__tooltip__trigger" ref={this.elementRef}>
					{value || 'Unknown'}
				</a>
			</div>
		);
	}
}

GiftCardComponent.propTypes = {
	value: string.isRequired,
	dependentValues: object,
};

export default GiftCardComponent;
