import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Toolbar extends Component {
	componentDidMount = () => {
		this.props.onToggleFilter();
	};

	render = () => {
		return <div>{this.props.children}</div>;
	};
}

Toolbar.propTypes = {
	onToggleFilter: PropTypes.func,
	enableFilter: PropTypes.bool,
	numberOfRows: PropTypes.number,
	children: PropTypes.any,
};

export default Toolbar;
