import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { join, filter } from 'lodash';

class SelectSelectionComponent extends Component {
	filterRemoved = () => {
		const {
			filter: { key },
			onFilterRemoved,
		} = this.props;
		onFilterRemoved({
			id: key,
			emptyValue: null,
		});
	};

	render() {
		const {
			filter: { name, selectionName, values, options },
		} = this.props;
		return (
			<div className="filter__tag">
				<label className="filter__tag__label">{selectionName || name}: </label>
				{join(filter(options, (_, key) => values[key]), ', ')}
				<button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
			</div>
		);
	}
}

SelectSelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired,
};

export default SelectSelectionComponent;
