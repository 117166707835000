import moment from 'moment';

export function generateFileName(all = false, refNum = '') {
	let format = moment.utc().format('YYYY_MM_DD_HH:mm:ss');

	if (refNum !== '') {
		format += `_Cardknox_${refNum}`;
		return format;
	}

	if (all) {
		format += '_Cardknox_all';
	} else {
		format += '_Cardknox_current';
	}

	return format;
}
