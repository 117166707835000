import React from 'react';
import BaseTitle from 'common/components/baseTitle/BaseTitle';
import sectionKeys from 'routing/sections';

const titles = {
	'/customers': { title: 'Customers' },
	'/upload-customer-preview': { title: 'Upload Customers and Schedules' },
	'/migrate-customers': { title: 'Migrate Customers and Payment Methods', sectionKey: sectionKeys.dropIn },
};

class Title extends React.Component {
	render() {
		return <BaseTitle titles={titles} />;
	}
}

export default Title;
