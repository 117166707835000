import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isObject, map } from 'lodash';

class FormErrorsComponent extends Component {
	renderError = (error, key) => {
		if (isObject(error)) {
			return map(error, (subError, subKey) => this.renderError(subError, key ? `${key}.${subKey}` : subKey));
		}
		if (error) {
			return (
				<p className="type--error" key={key}>
					{error}
				</p>
			);
		}
		return null;
	};

	render = () => {
		const { errors } = this.props;
		return <div className="validation validation--newtransaction ignoreHeight">{this.renderError(errors)}</div>;
	};
}

FormErrorsComponent.propTypes = {
	errors: PropTypes.object,
};

export default FormErrorsComponent;
