import React from 'react';
import PropTypes from 'prop-types';

const PaymentMethod = ({ value }) => {
	return <div>{value}</div>;
};

PaymentMethod.propTypes = {
	value: PropTypes.string.isRequired,
};

export default PaymentMethod;
