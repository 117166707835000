import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Logo from '../../img/cardholder-footer.png';
class GiftBalance extends Component {
	constructor(props) {
		super(props);
	}
	render = () => (
		<Fragment>
			<div className="gift-balance">
				<div className="gift-balance__logo"></div>
				<h1 className="gift-balance__title">
					Check your gift
					<br />
					card balance
				</h1>
				<div className="gift-balance__card">
					<p className="spc--bottom--sml type--base--alt">
						You can use these fields to check your
						<br />
						<strong>gift card balance</strong>
					</p>
					<p className=" spc--bottom--med">
						To do this, type in the gift card number and PIN. Check the reCAPTCHA box, and click the Check Balance
						button.
					</p>
					<div className="gift-balance__iframe__wrapper">
						<iframe ref={this.cardNumRef} src="https://secure.cardknox.com/balance" className="gift-balance__iframe" />
					</div>
					<div className="gift-balance__card__footer">
						<div className="gift-balance__card__footer__img"></div>
						<p>
							Cardknox offers so many hassle-free payment features for merchants - including gift card management - that
							you'll wonder how you ever did business without it. To enroll with Cardknox or learn more,{' '}
							<a
								href="https://www.cardknox.com/contact/"
								target="_blank"
								rel="noreferrer noopener"
								className="anchor anchor--primary"
							>
								contact us{' '}
							</a>
							today.
						</p>
					</div>
				</div>
				<div className="gift-balance__footer">
					<img src={Logo} className="gift-balance__footer__img"></img>
				</div>
			</div>
		</Fragment>
	);
}

GiftBalance.propTypes = {
	renderTitle: PropTypes.func,
};

export default GiftBalance;
