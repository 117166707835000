import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { AddEditCustomerForm } from '../../../Common/components/customers/popup';
import { Notification } from '../../../Common/components/notifications';
import CustomerTransactionsHistory from '../../../Common/components/customers/popup/transactions/main';
import moment from 'moment';

const customerTransactionHistoryWrapper = ({ data, loadmore, loadMoreFlag, dataRange }) => (
	<CustomerTransactionsHistory
		customer={data.general}
		transactions={data.transactions}
		refNum={data.references.transactions}
		customerId={data.general.customerId}
		errorMessages={data.errorMessages.transactions}
		loadmore={loadmore}
		loadMoreFlag={loadMoreFlag}
		dataRange={dataRange}
	/>
);

customerTransactionHistoryWrapper.propTypes = {
	data: PropTypes.object.isRequired,
	loadmore: PropTypes.func,
	loadMoreFlag: PropTypes.bool,
	dataRange: PropTypes.shape({
		start: PropTypes.instanceOf(moment),
		end: PropTypes.instanceOf(moment),
	}),
};

const additionalSections = [
	{
		key: 'transactions',
		name: 'Transaction History',
		permissions: ['allowReportAll'],
		Component: customerTransactionHistoryWrapper,
	},
];

class RowDetailsComponent extends Component {
	constructor(props) {
		super(props);
		this.notificationRef = React.createRef();
	}

	shouldComponentUpdate = nextProps => {
		if (nextProps.row != this.props.row) {
			return true;
		}
		return false;
	};

	addNotification = async notification => {
		this.notificationRef.current.addNotification({
			...notification,
		});
	};

	openModal = (modalName, data = {}) => {
		this.props.openModal({
			name: modalName,
			data: data,
		});
	};

	handleTab = e => {
		if (e.keyCode === 9 || e.keyCode === 37 || e.keyCode === 39) {
			// tab
			e.stopPropagation();
		}
	};

	handleDataChange = newData => {
		if (this.props.row.updateRows) {
			this.props.row.updateRows(newData);
		}
	};

	resizeGrid = () => {
		this.props.resizeGrid();
	};

	render = () => {
		const {
			type,
			setDetailsRef,
			row,
			gridHolder,
			focusSchedule,
			focusPayments,
			focusScheduleIndex,
			refreshGridData,
			closeRow,
			isDuplicate,
		} = this.props;

		return (
			<React.Fragment>
				<Notification ref={this.notificationRef} />
				<div ref={setDetailsRef} onKeyDown={this.handleTab}>
					<AddEditCustomerForm
						customerId={row.customerId}
						customer={type === 'customers' ? row : null}
						schedule={type === 'schedules' ? row : null}
						addNotification={this.addNotification}
						gridHolder={gridHolder}
						resizeGrid={this.resizeGrid}
						type={type}
						focusSchedule={focusSchedule}
						focusPayments={focusPayments}
						focusScheduleIndex={focusScheduleIndex}
						refreshGridData={refreshGridData}
						onDataChange={this.handleDataChange}
						additionalSections={additionalSections}
						advancedView={true}
						advancedViewByDefault={true}
						closeRow={closeRow}
						isDuplicate={isDuplicate}
					/>
				</div>
			</React.Fragment>
		);
	};
}

RowDetailsComponent.propTypes = {
	row: PropTypes.shape({
		customerId: PropTypes.string.isRequired,
		scheduleId: PropTypes.string,
		updateRows: PropTypes.func,
	}),
	refreshGridData: PropTypes.func,
	openModal: PropTypes.func,
	type: PropTypes.oneOf(['customers', 'schedules']).isRequired,
	setDetailsRef: PropTypes.func.isRequired,
	gridHolder: PropTypes.object,
	resizeGrid: PropTypes.func.isRequired,
	updateRows: PropTypes.func,
	focusSchedule: PropTypes.bool,
	focusPayments: PropTypes.bool,
	focusScheduleIndex: PropTypes.number,
	closeRow: PropTypes.func,
	isDuplicate: PropTypes.func,
};

export default RowDetailsComponent;
