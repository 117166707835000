import { split, trim } from 'lodash';

const hebrewMonths = {
	Tishri: 1,
	Heshvan: 2,
	Kislev: 3,
	Tevet: 4,
	Shevat: 5,
	Adar: 6,
	Nisan: 7,
	Iyar: 8,
	Sivan: 9,
	Tamuz: 10,
	Av: 11,
	Elul: 12,
};

const leapYearHebrewMonths = {
	Tishri: 1,
	Heshvan: 2,
	Kislev: 3,
	Tevet: 4,
	Shevat: 5,
	'Adar I': 6,
	'Adar II': 7,
	Nisan: 8,
	Iyar: 9,
	Sivan: 10,
	Tamuz: 11,
	Av: 12,
	Elul: 13,
};

const isLeapYear = year => {
	return (7 * year + 1) % 19 < 7;
};

export const getHebrewDateValues = date => {
	if (!date) {
		return;
	}
	const hebrewFormat = 'en-us-u-ca-hebrew';
	const day = date.toLocaleDateString(hebrewFormat, { weekday: 'long' });
	const dayOfMonth = date.toLocaleDateString(hebrewFormat, { day: 'numeric' });
	const month = date.toLocaleDateString(hebrewFormat, { month: 'long' });
	const year = date.toLocaleDateString(hebrewFormat, { year: 'numeric' });
	const months = isLeapYear(year) ? leapYearHebrewMonths : hebrewMonths;

	return {
		day,
		dayOfMonth,
		monthOfYear: months[month],
		year,
	};
};

export const toHebrew = date => {
	if (!date) {
		return;
	}
	const localeDate = date.toLocaleDateString('en-us-u-ca-hebrew');
	const months = isLeapYear(localeDate.slice(-4)) ? leapYearHebrewMonths : hebrewMonths;
	const [day, splitMonth, year] = split(localeDate, /(\s\w{2,}\s\w{1,2}\s|\s\w{2,}\s)/);
	const month = trim(splitMonth);
	if (months[month]) {
		return `${months[month]}/${day}/${year}`;
	} else {
		return localeDate;
	}
};
