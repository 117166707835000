import httpService from './httpService';
import principalService from './principalService';

const { messagingApiEndpoint } = ApplicationSettings;

class MessagingService {
	constructor(_httpService, _principalService) {
		this.httpService = _httpService;
		this.principalService = _principalService;
	}

	get headers() {
		let headers = new Headers();
		return headers;
	}

	get options() {
		return {
			isJson: true,
			headers: this.headers,
		};
	}

	send = async (templateName, to, template) => {
		const body = {
			templateName,
			to,
			template,
		};
		return await this.httpService.post(`${messagingApiEndpoint}SendSms`, body, this.options);
	};

	sendReceipt = (
		phoneNumber,
		dba,
		paymentMethod,
		paymentAction,
		paymentDirection,
		paymentType,
		paymentTypeLastFour,
		referenceNumber,
		amount
	) => {
		return this.send('a04_multipaymentmerchantreceipt_917f5966f58ff216', phoneNumber, {
			dba,
			paymentMethod,
			paymentAction,
			paymentDirection,
			paymentType,
			paymentTypeLastFour,
			referenceNumber,
			amount,
		});
	};

	sendPaymentRequest = (phoneNumber, dba, paymentSiteName) => {
		return this.send('a03_sendpaymentrequest_92a24e383d9f10fb', phoneNumber, { dba, paymentSiteName });
	};
}

const messagingService = new MessagingService(httpService, principalService);

export default messagingService;
