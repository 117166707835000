import emailjs from 'emailjs-com';
import httpService from './httpService';
import principalService from './principalService';
const { messagingApiEndpoint } = ApplicationSettings;

const serviceId = 'sendgrid';
const userId = 'user_pAV41tk5V6obsTYT5ELwD';

class EmailService {
	constructor(_httpService, _principalService) {
		this.httpService = _httpService;
		this.principalService = _principalService;
	}

	get headers() {
		let headers = new Headers();
		return headers;
	}

	get options() {
		return {
			isJson: true,
			headers: this.headers,
		};
	}

	sendFraudEmail = async template => {
		const body = template;
		return await this.httpService.post(`${messagingApiEndpoint}sendFraudEmail`, body, {
			allowPublic: true,
			isJson: true,
		});
	};
	sendEmail = async template => {
		const body = template;
		return await this.httpService.post(`${messagingApiEndpoint}sendEmail`, body, this.options);
	};
	send = async (templateId, templateParams) => {
		try {
			return await emailjs.send(serviceId, templateId, templateParams, userId);
		} catch (e) {
			throw { message: e.text, displayMessage: e.text };
		}
	};
}

const emailService = new EmailService(httpService, principalService);

export default emailService;
