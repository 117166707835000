import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { every, filter, head, includes, upperFirst } from 'lodash';

import { DraggableColumn } from './../../../Common/components/settings';
import { kvaasResources, mapConvenienceToCustom } from 'common/utilities';
import { kvaasService } from 'common/services';
import { withError } from 'common/components/error';
import { withLoader } from 'common/components/loader';
import { withCancelable } from 'common/components/cancelable';
import { withBlock } from 'common/components/block';
import handleInvalidRevision from '../utils/invalidRevision';
import BaseSettings from 'components/settings/pages/BaseSettings';
import { Notification } from 'common/components/notifications';

class UserSettingsFraudwatch extends BaseSettings {
	constructor(props) {
		super(props);
		this.state = {
			reportType: 'fraudwatchReport',
			fraudwatchReport: {
				oldData: {
					isDefault: null,
					order: null,
				},
				fields: [...this.fraudWatchReportInitialFields],
			},
		};
	}
	componentDidMount = async () => {
		this.props.showLoader(true);
		await this.fetchData();
		this.props.showLoader(false);
	};
	requiredKvaasResources() {
		return kvaasService.get(
			...[
				{ ...kvaasResources.convenienceFees, throwError: true },
				{ ...kvaasResources.portalFlags, throwError: true },
				{ ...kvaasResources.transactionDisplayLabels, throwError: true },
				{ ...kvaasResources.fraudwatchReportDefaultColumns, throwError: true },
				{ ...kvaasResources.fraudwatchReportDefaulOrder, throwError: true },
			]
		);
	}
	getData = async () => {
		const getKvaas = this.requiredKvaasResources();
		const [
			convenienceFees,
			portalFlags,
			transactionDisplayLabels,
			fraudwatchReportDefaultColumns,
			fraudwatchReportDefaulOrder,
		] = await this.props.makePendingRequest(getKvaas, 'kvaas');

		return {
			convenienceFees,
			portalFlags,
			transactionDisplayLabels,
			fraudwatchReportDefaultColumns,
			fraudwatchReportDefaulOrder,
		};
	};

	fetchData = async () => {
		this.props.showLoader(true);
		try {
			const {
				convenienceFees,
				portalFlags,
				transactionDisplayLabels,
				fraudwatchReportDefaultColumns,
				fraudwatchReportDefaulOrder,
			} = await this.getData();
			const { parsedConvenienceKey, parsedOriginalKey } = mapConvenienceToCustom(
				convenienceFees,
				null,
				transactionDisplayLabels
			);

			const newState = this.mapResponseToState(fraudwatchReportDefaultColumns, fraudwatchReportDefaulOrder);
			const portalFlagsData = this.getPortalFlagsData(portalFlags);
			newState.parsedConvenienceKey = parsedConvenienceKey;
			newState.parsedOriginalKey = parsedOriginalKey;

			if (!this.hasDataPortalFlags('displaySplitPayColumns', portalFlagsData)) {
				const fields = filter(newState.fields, ({ key }) => !includes(['netSale', 'processingFee'], key));
				newState.fields = fields;
			}

			if (!this.hasDataPortalFlags('multipleCapture', portalFlagsData)) {
				const fields = filter(newState.fields, ({ key }) => !includes(['clearedAmount', 'clearedCount'], key));
				newState.fields = fields;
			}

			this.setState({
				fraudwatchReport: newState,
			});
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.handleKvaasLoadError();
			}
		}
		this.props.showLoader(false);
	};
	setDefault = async () => {
		return [
			this.mapStateToFields('fraudwatchReport', 'isDefault', kvaasResources.fraudwatchReportDefaultColumns, true),
			this.mapStateToFields('fraudwatchReport', 'order', kvaasResources.fraudwatchReportDefaulOrder, true),
		];
	};
	mapReportToState = () => {
		return [
			this.mapStateToFields('fraudwatchReport', 'order', kvaasResources.fraudwatchReportDefaulOrder),
			this.mapStateToFields('fraudwatchReport', 'isDefault', kvaasResources.fraudwatchReportDefaultColumns),
		];
	};

	save = async toDefault => {
		const { showLoader, makePendingRequest, handleError, handleBlockChange } = this.props;
		showLoader(true);
		let error;
		let refNum;
		try {
			const mappedState = toDefault ? await this.setDefault() : this.mapReportToState();
			const response = await makePendingRequest(kvaasService.save(...mappedState), 'save');
			refNum = head(response).refNum;

			handleBlockChange(false);
			await this.fetchData();
		} catch (e) {
			showLoader(false);

			error = handleError(e, { delayMessage: true });
			if (error) {
				await this.fetchData();
			}
		}
		this.notifySuccess(error, toDefault, 'Fraudwatch Settings', refNum);
		showLoader(false);
	};

	toggleExpandCollapseSection = section => {
		this.setState({ [`is${upperFirst(section)}Expanded`]: !this.state[`is${upperFirst(section)}Expanded`] });
	};

	render = () => {
		const { fields } = this.state.fraudwatchReport;
		const { parsedConvenienceKey, parsedOriginalKey } = this.state;

		const allSelectValue = every(fields, i => i.hide || i.isDefault);
		const allSelectLabel = allSelectValue ? 'Unselect all' : 'Select all';
		return (
			<Fragment>
				<div className="settings--main settings--main--alt">
					<div className="settings__header">
						<div className="settings__header__title">Fraudwatch Settings</div>
						<div className="settings__header__action">{this.renderSaveButton(false)}</div>
					</div>
					<div>
						<div className="message message--default spc--bottom--sml">
							The column name reflects the name set on the New Transaction Settings page.
						</div>
						<div className="settings--report__header spc--bottom--sml">
							<div className="settings__transaction__heading">Manage Columns</div>

							<div className="type--sml settings__transaction__manual">
								<i className="icon icon--xsml icon--drag icon--middle spc--right--tny"></i>
								Drag to edit order
							</div>
						</div>

						<div className="w--max--570">
							<div className="display--ib spc--bottom--sml">
								<input
									type="checkbox"
									name="selectAll"
									id="allSelect"
									checked={allSelectValue}
									className="input--check"
									onChange={this.handleChange}
								/>
								<label htmlFor="allSelect" className="type--color--text--medium type--wgt--medium type--base">
									{allSelectLabel}
								</label>
							</div>
							<hr className="separator separator--grey1" />
							<DraggableColumn
								onChange={this.handleChange}
								parsedOriginalKey={parsedOriginalKey}
								onHover={this.moveColumn}
								disable={false}
								parsedConvenienceKey={parsedConvenienceKey}
								items={fields}
							/>
						</div>
					</div>
				</div>
				<Notification ref={this.notification} />
			</Fragment>
		);
	};
}
UserSettingsFraudwatch.propTypes = {
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	showLoader: PropTypes.func,
	history: PropTypes.object,
	location: PropTypes.object,
	handleBlockChange: PropTypes.func,
};

export default withError(withLoader(withCancelable(withBlock(UserSettingsFraudwatch))), handleInvalidRevision);
