import { toLower, each, findKey, isObject, isArray, cloneDeep } from 'lodash';

import { log4javascript } from 'common/components/log4javascript';
import loggerSettings from './loggerSettings';
import principalService from 'common/services/principalService';

const { getLogger, AjaxAppender, JsonLayout, Level } = log4javascript;
const sensitiveFields = ['xtoken', 'token', 'key', 'xKey', 'password', 'cvv', 'xCvv', 'cardNumber', 'adminKey'];

function createLogger({ name, url }) {
	const logger = getLogger(name);
	const ajaxAppender = new AjaxAppender(url);
	const layout = new JsonLayout();
	ajaxAppender.addHeader('Accept', 'application/json');
	ajaxAppender.addHeader('Content-Type', 'application/json');
	ajaxAppender.setThreshold(Level.INFO);
	ajaxAppender.setBatchSize(10);
	ajaxAppender.setSendAllOnUnload(true);
	ajaxAppender.setLayout(layout);
	logger.addAppender(ajaxAppender);
	let oldPage = '';

	function setUserInfo(username) {
		const principal = principalService.get();
		// There is a potential edge-case here, but we don't want to add async here if not needed.
		// At worst, we'll have a slightly different username here for SAML users which is not a big deal anyway.
		const user = principal ? principal.username : username;
		layout.setCustomField('username', user);
		if (principal) {
			layout.setCustomField('dba', principal.companyName);
			layout.setCustomField('mid', principal.idInfo.xMerchantID);
		}
	}

	function removeSensitiveInformation(object) {
		each(sensitiveFields, sensitiveKey => {
			const bodyKey = findKey(object, (_, key) => toLower(key) === toLower(sensitiveKey));
			if (!bodyKey || !object[bodyKey]) return;
			object[bodyKey] = '[REDACTED]';
		});
		each(object, (val, key) => {
			if (isObject(val)) removeSensitiveInformation(object[key]);
			if (isArray(val)) each(val, (_, item) => removeSensitiveInformation(item));
		});
		return object;
	}

	function logResponse(response) {
		if (!response) return;
		const { data, status } = response;
		layout.setCustomField('status', status);
		if (!data) return;
		const refnum = data.xGatewayRefNum || data.xRefNum || data.xRecurringRefNum || data.refnum || data.RefNum;
		if (!refnum) return;
		layout.setCustomField('refnum', refnum);
	}

	return {
		logError: ({ username = '', message = '', errorDetails = '' }) => {
			layout.customFields = [];
			setUserInfo(username);
			layout.setCustomField('errorDetails', errorDetails);
			logger.error(message);
		},
		logApiCall: ({ url, request, response }) => {
			layout.customFields = [];
			setUserInfo();
			layout.setCustomField('request', removeSensitiveInformation(cloneDeep(request)));
			logResponse(response);
			logger.info(url);
		},
		logPageView: () => {
			const page = window.location.pathname;
			if (oldPage === page) return;
			oldPage = page;
			layout.customFields = [];
			setUserInfo();
			logger.info(page);
		},
	};
}

const logger = createLogger(loggerSettings);

export default logger;
