import moment from 'moment';
import { find, trim } from 'lodash';

import { localToApiMoment, getDate, createFilters, getFilterValues } from 'common/utilities';

const { checkDateFormat } = ApplicationSettings;

export const Filters = createFilters([
	{
		key: 'date',
		getSelectionText: ({ key, startDate, endDate }, predefinedDates) => {
			const predefinedDate = find(predefinedDates, { key });
			if (predefinedDate) {
				return predefinedDate.displayValue;
			}
			try {
				return `${startDate.format(checkDateFormat)} - ${endDate.format(checkDateFormat)}`;
			} catch (e) {
				return 'Custom';
			}
		},
		values: {
			key: '30',
			startDate: moment()
				.startOf('day')
				.add(-30, 'day'),
			endDate: moment().endOf('day'),
		},
	},
	{
		key: 'batch',
		name: 'Search by Batch number',
		values: {
			batch: null,
		},
		standalone: true,
	},
]);

export const compileClosedBatchFilters = async (filters, filterDateFormat) => {
	const { date, batch } = getFilterValues(filters);
	const beginDate = moment(date.startDate);
	const endDate = moment(date.endDate);
	const filter = {
		xBeginDate: getDate(beginDate, filterDateFormat),
		xEndDate: getDate(endDate, filterDateFormat),
	};

	if (batch.batch != null) {
		filter.xBatch = batch.batch.toString();
	}
	return filter;
};

export const compileOpenBatchFilters = async (filters, filterDateFormat) => {
	const { date, batch } = getFilterValues(filters);
	const beginDate = await localToApiMoment(date.startDate);
	const endDate = await localToApiMoment(date.endDate);
	const filter = {
		xBeginDate: getDate(beginDate, filterDateFormat),
		xEndDate: getDate(endDate, filterDateFormat),
		xCommand: 'Report:Approved',
		xFilterCommand:
			'cc_credit,check_credit,cc_sale,check_sale,ebtfs_sale,ebtcb_sale,ebtw_sale,cc_refund,check_refund,cc_capture,cc_postauth',
	};

	if (trim(batch.batch)) {
		filter.xResponseBatch = 'ai' + batch.batch.toString();
	}
	return filter;
};
