import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImageWithFallback from 'common/components/ImageFallback';

class PublicLayoutComponent extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<React.Fragment>
				<div className="banner banner--sola banner--sola--login">
					<p className="banner--sola__text">
						<span className="type--wgt--bold">The Dawn of a New Era:</span> Cardknox is becoming Sola
					</p>
					<a
						className="banner--sola__button"
						rel="noopener noreferrer"
						target="_blank"
						href="https://solapayments.com/launch-page/"
					>
						Learn more →
					</a>
				</div>
				<div className="membership">
					<header className="membership__header">
						<div className="membership__logo__wrapper">
							<ImageWithFallback imgAttributes={{ alt: 'logo' }}>
								{props => (
									<img
										src={'static/media/cardknox-logo.svg'}
										className="membership__logo membership__logo--primary"
										{...props}
									/>
								)}
							</ImageWithFallback>
						</div>
					</header>

					<div className="membership--main">{this.props.children}</div>
				</div>
			</React.Fragment>
		);
	}
}

PublicLayoutComponent.propTypes = {
	children: PropTypes.any,
};

export default PublicLayoutComponent;
