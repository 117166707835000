import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PublicLayoutComponent extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<React.Fragment>
				<div className="upgrade-plan">{this.props.children}</div>
			</React.Fragment>
		);
	}
}

PublicLayoutComponent.propTypes = {
	children: PropTypes.any,
};

export default PublicLayoutComponent;
