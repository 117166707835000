import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, get, find, camelCase } from 'lodash';

import { CurrencyMap } from '../../Common/utilities';
import NumberFormat from 'react-number-format';
import BatchPieChart from './batch-chart';
import BatchDataGrid from './batch-data-grid';

const cardTrendArrows = {
	positive: {
		arrow: 'icon--ascending--success',
		cssClass: 'type--color--success',
	},
	negative: {
		arrow: 'icon--descending--warning',
		cssClass: 'type--color--primary',
	},
	neutral: {
		arrow: '',
		cssClass: null,
	},
};

class BatchCards extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currency: CurrencyMap.resolveCurrency(),
		};

		this.gridRef = createRef();
	}

	componentDidMount() {
		this.handleInitialSort();
	}

	handleInitialSort = () => {
		const { onGridSort } = this.props;
		const columns = get(this.gridRef, 'current.columns');
		let selectedColumn = find(columns, column => column.sortDirection);
		if (!selectedColumn) {
			selectedColumn = find(columns, column => column.isDefaultSorter);
		}
		if (columns && selectedColumn) {
			if (selectedColumn.isDefaultSorter) {
				onGridSort(selectedColumn.key, selectedColumn.sortDirection || selectedColumn.defaultSortDirection);
			} else {
				onGridSort(selectedColumn.key, selectedColumn.sortDirection || 'ASC');
			}
		}
	};

	renderBatchCard = (title, amount, count, numberOfCards) => {
		const { currency } = this.state;

		let trend;
		if (title === 'Sale' || title === 'Void') {
			trend = cardTrendArrows.neutral;
		} else if (title === 'Credit' || title === 'Total Processing Fee') {
			trend = cardTrendArrows.negative;
		} else {
			trend = cardTrendArrows.positive;
		}

		return (
			<div
				key={title}
				className={`batches__card__wrap f-col f-col-sml-12 f-col-med-${Math.max(parseInt(12 / numberOfCards), 3)}`}
			>
				<div className="batches__card">
					<div className="clearfix">
						<div className="pull batches__section__title">{title}</div>
						<div className="push">
							<i className={`icon icon--nano ${trend.arrow}`}></i>
						</div>
					</div>
					<div className="">
						<span className="batches__value">
							<span className={trend.cssClass}>
								<NumberFormat
									prefix={currency}
									value={amount}
									displayType="text"
									thousandSeparator={true}
									decimalScale={2}
									fixedDecimalScale={true}
								/>
							</span>
						</span>
						<span className="batches__value--count" data-tooltip="Count">
							(<NumberFormat value={count} displayType="text" thousandSeparator={true} />)
						</span>
					</div>
				</div>
			</div>
		);
	};

	render() {
		const {
			data,
			selectedStartDate,
			selectedEndDate,
			handleBreakdownOpen,
			transactionTypes,
			showVoid,
			showProcessingFee,
			isFundedReport,
			isFundedReportOpenBatches,
			activeTab,
			exportButtons,
			onGridSort,
			updateExpandedRows,
		} = this.props;

		let batches = [];
		if (data && data.xAdditionalData) {
			batches = data.xAdditionalData;
		} else if (data && data.openBatches) {
			batches = data.openBatches;
		}

		return (
			<React.Fragment>
				{isEmpty(data) ? (
					<div>No data</div>
				) : (
					<React.Fragment>
						<BatchDataGrid
							ref={this.gridRef}
							rows={data && data.xReportData}
							onGridSort={onGridSort}
							showVoid={showVoid}
							showProcessingFee={showProcessingFee}
							isFundedReport={isFundedReport}
							isFundedReportOpenBatches={isFundedReportOpenBatches}
							batches={batches}
							handleBreakdownOpen={handleBreakdownOpen}
							activeTab={activeTab}
							exportButtons={exportButtons}
							updateExpandedRows={updateExpandedRows}
						/>
						<div className="row">
							<div className="col col-sml-12 col-lrg-8">
								<h3 className="title title--secondary">
									{`Batch Summary for ${selectedStartDate}`}{' '}
									{selectedStartDate !== selectedEndDate ? `- ${selectedEndDate}` : null}
								</h3>
							</div>
						</div>
						<div className="f-row">
							{map(transactionTypes, type =>
								this.renderBatchCard(
									type,
									data.totals[`${camelCase(type.replace(/ /g, ''))}Amount`],
									data.totals[`${camelCase(type.replace(/ /g, ''))}Count`],
									transactionTypes.length
								)
							)}
						</div>
						<div className="f-row">
							<div className="f-col f-col-sml-12 f-col-xlrg-6 spc--bottom--sml">
								<BatchPieChart data={data} type="sale" />
							</div>
							<div className="f-col f-col-sml-12 f-col-xlrg-6 spc--bottom--sml">
								<BatchPieChart data={data} type="credit" />
							</div>
						</div>
					</React.Fragment>
				)}
			</React.Fragment>
		);
	}
}

BatchCards.propTypes = {
	data: PropTypes.object,
	onGridSort: PropTypes.func.isRequired,
	selectedStartDate: PropTypes.string,
	selectedEndDate: PropTypes.string,
	handleBreakdownOpen: PropTypes.func,
	transactionTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
	showVoid: PropTypes.bool,
	showProcessingFee: PropTypes.bool,
	activeTab: PropTypes.string.isRequired,
	isFundedReport: PropTypes.bool,
	isFundedReportOpenBatches: PropTypes.bool,
	exportButtons: PropTypes.any,
	updateExpandedRows: PropTypes.func,
};

export default BatchCards;
