import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import FraudSettingsActionGrid from './components/FraudSettingsActionGrid';
import { trim, pullAt } from 'lodash';

const ipPattern = /^(\d{1,3}\.){3}\d{1,3}$/;
class AdditionalFraudSettings extends Component {
	constructor(props) {
		super(props);
		this.state = { newValue: '' };
	}
	renderErrors = () => {
		const { isInvalid } = this.state;
		if (!isInvalid) return null;
		return (
			<div className="spc--bottom--sml message message--warning">
				<p>Invalid Whitelist IP</p>
			</div>
		);
	};
	onInputButton = () => {
		const { newValue } = this.state;
		if (!newValue) return;

		if (!ipPattern.test(newValue)) {
			return this.setState({ isInvalid: true });
		}

		const newIpList = [...this.props.affSettings.AFF_WhiteList_IPs, this.state.newValue];
		this.props.handleSettingsUpdate('affSettings', {
			...this.props.affSettings,
			AFF_WhiteList_IPs: newIpList,
		});
		this.setState({ newValue: '', isInvalid: false });
	};

	onAction = (_, index) => {
		let newSettings = { ...this.props.affSettings };
		pullAt(newSettings.AFF_WhiteList_IPs, index);
		this.props.handleSettingsUpdate('affSettings', newSettings);
	};
	onGridInputChange = value => {
		this.setState({ newValue: value });
	};
	setVelocitySetting = velocity => {
		this.props.handleSettingsUpdate('affSettings', {
			...this.props.affSettings,
			AFF_Velocity_NumberOfCards: velocity,
		});
	};
	render() {
		const { affSettings, isLoading, isExpanded, toggleExpand } = this.props;
		const { newValue } = this.state;
		return (
			<div className="spr__card clearfix">
				<button className={trim(`spr__heading ${isExpanded ? 'is-expanded' : ''}`)} onClick={toggleExpand}>
					<h4 className="spr__heading__title">Additional Fraud Settings</h4>
					<i
						className={`icon icon--tiny icon--arrow--right--grey spr__heading__expand ${
							isExpanded ? 'is-expanded' : ''
						}`}
					></i>
				</button>
				{isExpanded && (
					<Fragment>
						<div>
							<h4 className="type--lrg type--wgt--semibold spc--bottom--med">Whitelist IP Addresses</h4>
							{this.renderErrors()}
							<div className="message message--default type--sml--plus spc--bottom--med--alt fullwidth">
								When IP addresses are entered, transactions will only be permitted from those IP addresses.
							</div>
						</div>
						<FraudSettingsActionGrid
							data={affSettings.AFF_WhiteList_IPs}
							columns={[{ label: '', field: 'ipAddress' }]}
							onAction={this.onAction}
							onInputButton={this.onInputButton.bind(this)}
							gridTitle="List of whitelist IP addresses"
							inputPlaceholder={'000000 000000 000000 000000'}
							addButtonLabel={'Add to whitelist'}
							actionLabel={'Remove'}
							newGridItem={newValue}
							onGridInputChange={this.onGridInputChange}
							isLoading={isLoading}
						/>
						<h4 className="type--lrg type--wgt--semibold spc--bottom--sml">Velocity settings</h4>
						<div className="flex--primary spc--bottom--med">
							<p className="flex--primary type--wgt--medium">
								Block an IP address that processed{' '}
								<input
									className="input input--med w--52p spc--right--xsml spc--left--xsml"
									type="number"
									value={affSettings.AFF_Velocity_NumberOfCards}
									onChange={e => this.setVelocitySetting(e.target.value)}
									disabled={isLoading}
								/>{' '}
								declined cards in the last 10 minutes.
							</p>
						</div>
					</Fragment>
				)}
			</div>
		);
	}
}
AdditionalFraudSettings.propTypes = {
	isExpanded: PropTypes.bool.isRequired,
	toggleExpand: PropTypes.func.isRequired,
	affSettings: PropTypes.object.isRequired,
	handleSettingsUpdate: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
};
export default AdditionalFraudSettings;
