import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, each, get, clone, concat, isEmpty } from 'lodash';
import { Data } from 'react-data-grid-addons';

import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { portalManagementFilter } from 'common/components/portal-management/filter';
import { portalManagementUserEventsColumns } from 'common/components/portal-management/column-filter/portalManagementUserEventsColumns';
import { ZebraRenderer } from 'common/components/row';
import { portalManagementService } from 'common/services';
import { PopoverGridTooltip } from 'common/components/tooltips';
import PopupGrid from 'common/components/grid/PopupGrid';

const requestKeys = {
	FETCH: 'fetch',
};

const loadMoreOptions = [60, 0];

class PortalManagementUserEventsGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filters: cloneDeep(portalManagementFilter),
			activeFilters: cloneDeep(portalManagementFilter),
			columns: cloneDeep(portalManagementUserEventsColumns),
			inlineFilters: {},
			data: null,
			originalData: null,
			filteredRows: [],
			fetchData: false,
			fetchingAdditionalData: false,
			lastApiRefNum: null,
			loadMoreLimit: 60,
		};

		this.components = {
			rowRenderer: ZebraRenderer,
			tooltip: PopoverGridTooltip,
		};
		this.classes = {
			gridHeader: 'col col-sml-2 col-lrg-6 col-xxlrg-8',
			filter: 'col col-sml-10 col-lrg-6 col-xxlrg-4',
		};

		this.classes = {
			filterContainer: '',
			filter: '',
			gridHeader: 'col col-sml-12 col-med-6 spc--bottom--sml',
			gridHolder: 'grid__holder grid__holder--no-shadow',
			gridWrapper: 'grid__holder--override',
		};

		this.gridRef = createRef();
	}

	fetchData = async () => {
		const { handleError, makePendingRequest, username } = this.props;
		this.setState({
			fetchingData: true,
			data: null,
			filteredRows: [],
			lastApiRefNum: null,
		});

		try {
			const data = await makePendingRequest(portalManagementService.listAuthEvents({ username }), requestKeys.FETCH);

			this.mapData(data);
			const filteredRows =
				data && data.xReportData
					? Data.Selectors.getRows({
							rows: data.xReportData,
							filters: this.state.inlineFilters,
					  })
					: [];
			this.setState(
				{
					data,
					originalData: cloneDeep(data),
					filteredRows,
					fetchingData: false,
					lastApiRefNum: data.refNum,
				},
				() => {
					const grid = this.gridRef.current;
					if (grid) {
						grid.handleInitialSort();
						grid.calculateColumnWidths();
					}
				}
			);
		} catch (e) {
			if (handleError(e)) {
				this.setState({
					fetchingData: false,
				});
			}
		}
	};

	mapData = data => {
		if (isEmpty(data.xReportData)) {
			data.xReportData = [];
		}
		each(
			data.events,
			(
				{ creationDate, creationDateMoment, eventType, eventContextData: { city, country, deviceName, ipAddress } },
				i
			) => {
				data.xReportData.push({
					eventType: eventType.value,
					creationDate,
					creationDateMoment,
					city,
					country,
					deviceName,
					ipAddress,
					gridRowNumber: i,
					index: i + 1,
				});
			}
		);
		delete data.events;
	};

	openActions = (infoDimensions, tooltip) => {
		this.setState({ tooltipProps: { infoDimensions, tooltip } }, () => {
			if (this.gridRef.current) {
				this.gridRef.current.forceUpdate();
			}
		});
	};

	refetchData = () => {
		const { activeFilters } = this.state;
		this.fetchData(activeFilters);
	};

	resolveColumnName = column => {
		switch (column) {
			case 'creationDate':
				return 'creationDateMoment';
			default:
				return column;
		}
	};

	hasMoreData = data => {
		return data && data.xReportData && data.xReportData.length > 0 && !!data.nextToken;
	};

	handleChange = changes => {
		const newState = {};
		each(changes, ({ key, value }) => {
			if (key === 'data' || key === 'inlineFilters') {
				let filters, data;
				if (key === 'data') {
					filters = this.state.inlineFilters;
					data = value;
				} else {
					filters = value;
					data = this.state.data;
				}
				newState.filteredRows =
					data && data.xReportData
						? Data.Selectors.getRows({
								rows: data.xReportData,
								filters,
						  })
						: [];
			}
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	loadMore = async () => {
		const { loadMoreLimit, data, originalData } = this.state;
		const { username } = this.props;
		const hasData = originalData && originalData.xReportData && originalData.xReportData.length > 0;

		if (hasData) {
			if (originalData.nextToken) {
				this.setState({
					fetchingAdditionalData: true,
					lastApiRefNum: null,
				});

				try {
					const nextToken = get(data, 'nextToken', '');
					let pendingData = await this.props.makePendingRequest(
						loadMoreLimit
							? portalManagementService.listAuthEvents({ username, nextToken })
							: portalManagementService.listAuthEventsAll(username, nextToken),
						requestKeys.LOAD_MORE
					);
					this.mapData(pendingData);

					if (!isEmpty(pendingData.xReportData)) {
						const updatedData = clone(data);
						updatedData.nextToken = pendingData.nextToken;
						updatedData.xReportData = concat(updatedData.xReportData, pendingData.xReportData);
						this.mapData(updatedData);
						const filteredRows =
							updatedData && updatedData.xReportData
								? Data.Selectors.getRows({
										rows: updatedData.xReportData,
										filters: this.state.inlineFilters,
								  })
								: [];
						this.setState(
							{
								data: updatedData,
								originalData: cloneDeep(updatedData),
								filteredRows,
								fetchingAdditionalData: false,
								lastApiRefNum: updatedData.refNum,
							},
							() => {
								if (this.gridRef.current) {
									this.gridRef.current.handleInitialSort();
									this.gridRef.current.calculateColumnWidths();
								}
							}
						);
					} else {
						this.setState({
							fetchingAdditionalData: false,
							lastApiRefNum: pendingData.xRefNum,
						});
					}
				} catch (e) {
					if (this.props.handleError(e)) {
						this.setState({
							fetchingAdditionalData: false,
						});
					}
				}
				if (this.gridRef.current) {
					this.gridRef.current.oldVisibleColumns = [];
				}
			}
		}
	};

	onLoadMoreLimitChange = value => {
		this.setState(
			{
				loadMoreLimit: value,
			},
			() => {
				this.loadMore();
			}
		);
	};

	render() {
		const {
			fetchingData,
			fetchingAdditionalData,
			filteredRows,
			columns,
			data,
			inlineFilters,
			filters,
			activeFilters,
			lastApiRefNum,
			defaultColumns,
			expanded,
			loadMoreLimit,
		} = this.state;
		const { popupRef } = this.props;

		return (
			<PopupGrid
				title="User Events"
				emptyMessage="You should change your filter options"
				fetchingData={fetchingData}
				fetchingAdditionalData={fetchingAdditionalData}
				filteredRows={filteredRows}
				columns={columns}
				data={data}
				inlineFilters={inlineFilters}
				components={this.components}
				classes={this.classes}
				onChange={this.handleChange}
				expanded={expanded}
				type="user"
				filters={filters}
				activeFilters={activeFilters}
				fetchData={this.refetchData}
				lastApiRefNum={lastApiRefNum}
				showPrintDropdown={false}
				filterColumns={true}
				defaultColumns={defaultColumns}
				ref={this.gridRef}
				initialFetch={true}
				columnFilterType="userEvents"
				kvaasResourceType="userEvents"
				useInlineFilters={true}
				showGridHeader={true}
				showHeader={true}
				showPanel={false}
				displayHeaderMenu={false}
				displayShowHideSelectedFilters={false}
				showResults={true}
				showGridFooter={true}
				enableFilters={true}
				enablePrint={false}
				enableExport={false}
				loadMoreOptions={loadMoreOptions}
				onLoadMoreLimitChange={this.onLoadMoreLimitChange}
				loadMoreLimit={loadMoreLimit}
				hasPaging={true}
				hasMoreData={this.hasMoreData}
				popupRef={popupRef}
				resolveColumnName={this.resolveColumnName}
			/>
		);
	}
}

PortalManagementUserEventsGrid.propTypes = {
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	popupRef: PropTypes.any.isRequired,
	username: PropTypes.string.isRequired,
};

export default withError(withCancelable(PortalManagementUserEventsGrid));
