import { keys, each, toLower, map, snakeCase, camelCase, split, join, noop, get } from 'lodash';
import { Auth, Hub } from 'aws-amplify';

import httpService from './httpService';
import principalService from './principalService';
import inMemoryCacheService from './inMemoryCacheService';
import { makeCancelable } from '../utilities/makeCancelable';
import { parseError } from '../utilities/parse-error';
import { uniqueId } from 'common/utilities/uniqueId';

const { kvaasEndpoint, kvaasMinorVersion } = ApplicationSettings;
const DEFAULT_KVAAS_ERROR = 'Failed fetching from KVAAS';

class KvaasService {
	constructor(httpService, principalService, dataCacheService, requestCacheService) {
		this.httpService = httpService;
		this.principalService = principalService;
		this.dataCacheService = dataCacheService;
		this.requestCacheService = requestCacheService;

		this.getEndpoint = `${kvaasEndpoint}LoadRecord`;
		this.createEndpoint = `${kvaasEndpoint}SaveRecord`;
		this.updateEndpoint = `${kvaasEndpoint}UpdateRecord`;
		this.deleteEndpoint = `${kvaasEndpoint}DeleteRecord`;
		this.tableName = `${AppBuildEnvironment}CardknoxPortalSettings`;

		this._cancelablePromises = [];
		this.principal = this.principalService.get();
		this.subscription = this.principalService.subscribe(newPrincipal => {
			this.principal = newPrincipal;
			this.clearCache();
		});
		Hub.listen('auth', ({ payload: { event } }) => {
			if (event === 'signOut' || event === 'signIn' || event === 'oAuthSignOut') {
				localStorage.setItem('signIn/out', 'reload' + uniqueId());

				this.clearCache();
			}
		});
	}

	get options() {
		const headers = new Headers();
		headers.set('X-Kvaas-Api-Version', kvaasMinorVersion);
		headers.set('X-Kvaas-Authorization-Method', 'cognito');
		headers.set('X-Kvaas-Account-Name', 'caas_portal');
		const options = {
			headers,
			isJson: true,
			allowPublic: true,
		};
		return options;
	}

	clearCache = () => {
		each(this._cancelablePromises, ({ cancel }) => {
			cancel();
		});
		this._cancelablePromises = [];
		this.dataCacheService.clear();
		this.requestCacheService.clear();
	};

	handleError = error => {
		if (!error || !error.isCanceled) {
			throw error;
		}
	};

	makeCancelable = promise => {
		const cancelablePromise = makeCancelable(promise);
		this._cancelablePromises.push(cancelablePromise);
		return cancelablePromise.promise;
	};

	exists = value => !!value && value !== '0' && value !== 'false';

	generatePrimaryKey = async ({ primaryKey, userSetting }) => {
		let prefix = '';
		if (userSetting) {
			await this.principalService.emailPromise;
			prefix = this.principal && this.principal.username;
			if (!prefix) {
				try {
					let user = await Auth.currentAuthenticatedUser();
					prefix = get(user, 'attributes.email');
					// eslint-disable-next-line no-empty
				} catch (err) {}
			}
		} else {
			prefix = this.principal && this.principal.idInfo && this.principal.idInfo.xMerchantID;
		}
		if (!prefix) {
			throw new Error(`Unable to load user credentials ${primaryKey}`);
		}
		return `${prefix}_${primaryKey}`;
	};

	getRequest = async ({
		primaryKey,
		tableName = this.tableName,
		mapper,
		defaultData,
		userSetting,
		throwError = false,
	}) => {
		try {
			const result = await this.makeCancelable(
				this.dataCacheService.loadOrFetch(tableName, primaryKey, async () => {
					let request;
					try {
						request = await this.makeCancelable(
							this.requestCacheService.loadOrFetch(tableName, primaryKey, () => {
								return this.makeCancelable(
									(async () => {
										try {
											const generatedKey = await this.generatePrimaryKey({ primaryKey, userSetting });
											return await this.httpService.post(
												this.getEndpoint,
												{
													tableName,
													primaryKey: generatedKey,
												},
												this.options
											);
										} catch (e) {
											let { message } = parseError(e);
											if (!message) {
												message = DEFAULT_KVAAS_ERROR;
											}
											return {
												error: message,
											};
										}
									})()
								);
							})
						);
					} catch (e) {
						let { message } = parseError(e);
						if (!message) {
							message = DEFAULT_KVAAS_ERROR;
						}
						request = Promise.resolve({
							error: message,
						});
					}
					try {
						let response;
						try {
							response = await request;
						} catch (e) {
							if (e && e.isCanceled) {
								return;
							}
							let { message } = parseError(e);
							if (!message) {
								message = DEFAULT_KVAAS_ERROR;
							}
							response = {
								error: message,
							};
						}
						this.requestCacheService.clear(tableName, primaryKey);
						this.mapResponseData(response, defaultData, mapper);
						return response;
					} catch (e) {
						this.handleError(e);
					}
				})
			);
			if (throwError && result && result.error && toLower(result.error) !== 'item does not exist') throw result.error;
			return result;
		} catch (e) {
			this.handleError(e);
		}
	};

	mapResponseData = (response, defaultData, mapper) => {
		if (response && response.error) {
			response.data = { ...defaultData };
		}

		if (response && response.data) {
			const mappedData = {};
			each(response.data, (value, key) => {
				const mappedKey = join(map(split(key, '_dot_'), camelCase), '.');
				mappedData[mappedKey] = mapper(value);
			});
			response.data = mappedData;
			each(defaultData, (defaultValue, key) => {
				if (defaultValue === false && response.data[key] === undefined) {
					response.data[key] = false;
				}
			});
		}
	};

	getRequestWithNoCache = async ({ primaryKey, tableName = this.tableName, mapper, defaultData, userSetting }) => {
		try {
			const generatedKey = await this.generatePrimaryKey({ primaryKey, userSetting });
			const response = await this.httpService.post(
				this.getEndpoint,
				{
					tableName,
					primaryKey: generatedKey,
				},
				this.options
			);

			this.mapResponseData(response, defaultData, mapper);
			return response;
		} catch (e) {
			let { message } = parseError(e);
			if (!message) {
				message = DEFAULT_KVAAS_ERROR;
			}
			return {
				error: message,
			};
		}
	};

	get = (...resources) => Promise.all(map(resources, this.getRequest));

	getIgnoreCache = (...resources) => Promise.all(map(resources, resource => this.getRequestWithNoCache(resource)));

	saveRequest = async ({
		newData,
		oldData,
		primaryKey,
		tableName = this.tableName,
		userSetting,
		secondRequest = false,
	}) => {
		const data = {};
		const attributesToRemove = [];
		const resultData = {};
		let updateRequest = false;

		if (oldData) {
			each(keys(newData.data), key => {
				const snakeKey = join(map(split(key, '.'), snakeCase), '_dot_');
				if (this.exists(newData.data[key]) && !this.exists(oldData.data[key])) {
					resultData[key] = newData.data[key];
					data[snakeKey] = newData.data[key];
				}
				if (!this.exists(newData.data[key]) && this.exists(oldData.data[key])) {
					attributesToRemove.push(snakeKey);
				}
				if (this.exists(newData.data[key]) && this.exists(oldData.data[key])) {
					// KVAAS doesn't allow updating existing keys so we have to make 2 requests
					if (newData.data[key] !== oldData.data[key]) {
						attributesToRemove.push(snakeKey);
						updateRequest = true;
					} else {
						resultData[key] = newData.data[key];
					}
				}
			});
		} else {
			each(keys(newData.data), key => {
				const snakeKey = join(map(split(key, '.'), snakeCase), '_dot_');
				if (this.exists(newData.data[key])) {
					resultData[key] = newData.data[key];
					data[snakeKey] = newData.data[key];
				}
			});
		}

		let result = null;
		try {
			const generatedKey = await this.makeCancelable(this.generatePrimaryKey({ primaryKey, userSetting }));
			const body = {
				tableName,
				primaryKey: generatedKey,
				data,
				attributesToRemove,
				revision: oldData ? oldData.revision : 0,
			};
			result = await this.makeCancelable(
				this.httpService.post(oldData ? this.updateEndpoint : this.createEndpoint, body, this.options)
			);
		} catch (e) {
			if (e && e.isCanceled) {
				return;
			}
			let { message } = parseError(e);
			if (!message) {
				message = DEFAULT_KVAAS_ERROR;
			}
			result = {
				error: message,
			};
		}

		if (result) {
			result.revision = result.newRevision;
			result.data = resultData;
			if (toLower(result.result) === 's') {
				if (updateRequest && !secondRequest) {
					// just in case something goes wrong we should prevent infinite loops
					return await this.saveRequest({
						newData,
						oldData: result,
						primaryKey,
						secondRequest: true,
						userSetting,
						tableName,
					});
				} else {
					this.dataCacheService.save(tableName, primaryKey, result);
				}
			} else {
				this.dataCacheService.clear(tableName, primaryKey);
				this.requestCacheService.clear(tableName, primaryKey);
			}
		}
		return result;
	};

	save = (...resources) => Promise.all(map(resources, this.saveRequest));

	deleteRequest = async ({ revision, primaryKey, tableName = this.tableName, userSetting }) => {
		try {
			const generatedKey = await this.makeCancelable(this.generatePrimaryKey({ primaryKey, userSetting }));
			const body = {
				tableName,
				primaryKey: generatedKey,
				revision,
			};
			this.requestCacheService.clear(tableName);
			this.dataCacheService.clear(tableName);
			return await this.httpService.post(this.deleteEndpoint, body, this.options);
		} catch (e) {
			this.handleError(e);
		}
	};

	delete = (...resources) => Promise.all(map(resources, this.deleteRequest));

	mapFieldsToState = (newState, oldData, type, callback = noop) => {
		const { data, result, error, refNum } = oldData;
		if (data && (toLower(result) === 's' || error === 'Item does not exist')) {
			if (!error) {
				newState.oldData[type] = {
					...oldData,
				};
			}
			each(data, callback);
		} else if (toLower(error) === 'invalid: revision' || toLower(error) === 'item exists. revision cannot be 0') {
			throw {
				isApiError: true,
				ref: refNum,
				message: 'The data you were trying to update is not up to date. Fetching latest data...',
				success: false,
			};
		} else {
			newState.errorMessages.push(error + (refNum ? ' ' + '(Ref# ' + refNum + ')' : ''));
		}
	};
}

const kvaasService = new KvaasService(
	httpService,
	principalService,
	new inMemoryCacheService('data'),
	new inMemoryCacheService('requests')
);

export default kvaasService;
