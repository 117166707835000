import httpService from './httpService';

const { cardholderUpdaterEndpoint } = ApplicationSettings;

class CardholderUpdaterService {
	constructor(httpService) {
		this.httpService = httpService;
	}

	updateCard = async cardData => {
		return await this.httpService.post(cardholderUpdaterEndpoint, cardData, { isJson: true, allowPublic: true });
	};
}

const cardholderUpdaterService = new CardholderUpdaterService(httpService);

export default cardholderUpdaterService;
