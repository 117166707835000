import React, { Component } from 'react';
import moment from 'moment';

import { Modal } from '../modal';
import PrivacyPolicy from '../../../assets/privacy_policy.html';

class PrivacyPolicyFooter extends Component {
	constructor() {
		super();

		this.state = {
			privacyPolicyVisible: false,
		};
	}

	togglePrivacyPolicy = () => {
		const { privacyPolicyVisible } = this.state;
		this.setState({
			privacyPolicyVisible: !privacyPolicyVisible,
		});
	};

	render = () => (
		<div className="membership__privacy">
			<Modal isOpen={this.state.privacyPolicyVisible} onClose={this.togglePrivacyPolicy}>
				<PrivacyPolicy />
			</Modal>
			&copy; Copyright {moment().format('YYYY')} Cardknox -{' '}
			<a href="javascript:void(0)" className="membership__privacy__link" onClick={this.togglePrivacyPolicy}>
				Privacy Policy
			</a>
		</div>
	);
}

export default PrivacyPolicyFooter;
