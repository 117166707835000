export const modalNames = {
	none: null,
	newTransaction: 'nt',
	capture: 'c',
	print: 'p',
	refund: 'r',
	void: 'v',
	confirmAction: 'ca',
	breakdown: 'br',
	adjust: 'a',
	previewLogo: 'pl',
	whitelistIPs: 'wl',
	bulkCharge: 'bc',
	sendEmail: 'se',
	sendSms: 'ss',
	totalCharges: 'tc',
	registerApplePay: 'rap',
	generateApplePay: 'gap',
	configureClickToPay: 'ccp',
};
