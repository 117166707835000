import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { identity } from 'lodash';

import { itemTypes } from './item-types';
import { invokeIfFunction } from 'common/utilities';

const dragSpec = {
	beginDrag: identity,
	canDrag: ({ disable }) => !disable,
};

const dragCollect = (connect, monitor) => ({
	connectDragSource: connect.dragSource(),
	connectDragPreview: connect.dragPreview(),
	isDragging: monitor.isDragging(),
});

const dropSpec = {
	hover: ({ id: targetId, onHover, sectionKey, disableFieldOutsideDrag }, monitor) => {
		if (!onHover) {
			return;
		}
		const { id: sourceId } = monitor.getItem();
		if (invokeIfFunction(disableFieldOutsideDrag, { sectionKey, sourceId })) {
			return;
		}
		if (targetId !== sourceId) {
			onHover(sourceId, targetId);
		}
	},
	drop: ({ id: targetId, onDrop }, monitor) => {
		if (!onDrop) {
			return;
		}
		const { id: sourceId } = monitor.getItem();
		if (sourceId !== targetId) {
			onDrop(sourceId, targetId);
		}
	},
	canDrop: props => {
		return !props.disableDrag;
	},
};

const dropCollect = (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
	canDrop: monitor.canDrop(),
	draggedItemType: monitor.getItemType(),
	draggedItem: monitor.getItem(),
});

const DraggableItem = (
	WrappedComponent,
	{ dragStyle, useDefaultDrop = true, useDefaultDrag = true, hideWhileDragging = true, dragOverride } = {}
) => {
	class WrappedDraggableItem extends PureComponent {
		componentDidMount() {
			const { connectDragPreview } = this.props;
			if (connectDragPreview) {
				connectDragPreview(getEmptyImage(), {
					captureDraggingState: true,
				});
			}
		}

		render() {
			const { isDragging, connectDragSource, connectDropTarget, disable, children, className, ...rest } = this.props;
			const isDraggingOverride = isDragging || (dragOverride && dragOverride(this.props));

			return (useDefaultDrag ? connectDragSource : identity)(
				(useDefaultDrop ? connectDropTarget : identity)(
					<div
						className={`${className || ''} `}
						style={
							isDraggingOverride
								? {
										...dragStyle,
										transition: 'all 0.01s',
								  }
								: { height: '100%', transition: 'all 0.01s' }
						}
					>
						<div style={{ opacity: isDraggingOverride && hideWhileDragging ? 0 : 1 }}>
							<WrappedComponent
								isDragging={isDraggingOverride}
								isDisabled={disable}
								connectDragSource={useDefaultDrag ? undefined : connectDragSource}
								connectDropTarget={useDefaultDrop ? undefined : connectDropTarget}
								{...rest}
							/>
						</div>
					</div>
				)
			);
		}
	}

	WrappedDraggableItem.propTypes = {
		connectDragPreview: PropTypes.func,
		isDragging: PropTypes.bool,
		connectDragSource: PropTypes.func,
		connectDropTarget: PropTypes.func,
		disable: PropTypes.bool,
		children: PropTypes.any,
		className: PropTypes.string,
	};

	return WrappedDraggableItem;
};

const withListItem = (WrappedComponent, opts = {}) =>
	DragSource(opts.dragType || itemTypes.LIST_ITEM, dragSpec, dragCollect)(
		DropTarget(opts.dropType || itemTypes.LIST_ITEM, dropSpec, dropCollect)(DraggableItem(WrappedComponent, opts))
	);

export default withListItem;
