import httpService from './httpService';
import principalService from './principalService';

const { settingsEndpoint } = ApplicationSettings;

class SettingService {
	constructor(httpService, principalService) {
		this.httpService = httpService;
		this.principalService = principalService;
	}

	getOptions = () => {
		return {
			isJson: true,
		};
	};

	loadSettings = async settingsGroup => {
		const result = await this.httpService.post(
			`${settingsEndpoint}GetCardknoxSettings`,
			{ settingsGroup },
			{
				...this.getOptions(),
			}
		);

		return result;
	};

	updateSettings = async (settingsGroup, cardknoxSettings) => {
		const result = await this.httpService.post(
			`${settingsEndpoint}UpdateCardknoxSettings`,
			{ settingsGroup, cardknoxSettings },
			{
				...this.getOptions(),
			}
		);

		return result;
	};
}

const settingService = new SettingService(httpService, principalService);

export default settingService;
