import React from 'react';
import { map } from 'lodash';

import { NumericFilter, StringFilter, MultiselectCardknoxFilter } from '../../columns/filters';

import {
	DisplayDateComponent,
	CreditCardComponent,
	CurrencyComponent,
	CurrencyCodesComponent,
	CardTypeComponent,
} from '../../columns/formatters';
import { ColumnHeader } from 'common/components/columns/headers';

const columns = [
	{
		key: 'xRefNum',
		name: 'Reference #',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		showOnMobile: true,
		resizable: true,
		order: 1,
	},
	{
		key: 'xEnteredDate',
		name: 'Transaction date',
		sortable: true,
		hideable: true,
		filterable: true,
		visible: false,
		filterRenderer: StringFilter,
		formatter: DisplayDateComponent,
		initWidth: 200,
		showOnMobile: true,
		isDefaultSorter: true,
		defaultSortDirection: 'DESC',
		customSettingsKey: 'transactionDate',
		resizable: true,
	},
	{
		key: 'amountToUse',
		exportKey: 'xAmount',
		name: 'Transaction amount',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: NumericFilter,
		formatter: CurrencyComponent,
		getRowMetaData: row => {
			return { ...row, displayBadge: true };
		},
		initWidth: 126,
		showOnMobile: true,
		dependentExportKey: 'currency',
		customSettingsKey: 'transactionAmount',
		resizable: true,
	},
	{
		key: 'currency',
		exportKey: 'xCurrency',
		name: 'Currency',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		hideOnPrint: true,
		filterRenderer: MultiselectCardknoxFilter,
		formatter: CurrencyCodesComponent,
		initWidth: 130,
		showOnMobile: true,
		customSettingsKey: 'currency',
		resizable: true,
	},
	{
		key: 'xName',
		name: 'C­ar­dholder N­ame', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		customSettingsKey: 'cardholderName',
		resizable: true,
	},
	{
		key: 'xCardType',
		name: 'Card type',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: MultiselectCardknoxFilter,
		formatter: CardTypeComponent,
		initWidth: 150,
		customSettingsKey: 'cardType',
		resizable: true,
	},
	{
		key: 'xMaskedCardNumber',
		name: 'Account #',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		formatter: CreditCardComponent,
		filterRenderer: StringFilter,
		getRowMetaData: row => row,
		initWidth: 115,
		showOnMobile: true,
		dependentExportKey: 'xCardType',
		customSettingsKey: 'accountNumber',
		resizable: true,
	},
	{
		key: 'xStatus',
		name: 'Dispute Status',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		customSettingsKey: 'disputeStatus',
		resizable: true,
	},
	{
		key: 'xDisputeAmount',
		name: 'Dispute Amount',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: NumericFilter,
		formatter: CurrencyComponent,
		getRowMetaData: row => {
			return { ...row, displayBadge: true };
		},
		initWidth: 126,
		dependentExportKey: 'currency',
		customSettingsKey: 'disputeAmount',
		resizable: true,
		showGoPlus: true,
	},
	{
		key: 'xDisputeReason',
		name: 'Dispute Reason',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		customSettingsKey: 'disputeReason',
		resizable: true,
		showGoPlus: true,
	},
];

export const DisputeColumns = map(columns, column => {
	column = {
		headerRenderer: <ColumnHeader />,
		...column,
	};
	return column;
});
