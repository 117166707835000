import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { startsWith, find, some, get } from 'lodash';
import moment from 'moment';

import sectionKeys from '../../routing/sections';
import { principalService, authenticationService, kvaasService, logoManagementService } from '../../Common/services';
import { kvaasResources } from '../../Common/utilities';
import { withCancelable } from '../../Common/components/cancelable';
import { withError } from '../../Common/components/error';
import { hasFeaturePackage, featurePackages } from 'common/utilities/has-feature-package';
import { withForwardRef } from 'common/components/with-forward-ref';
import { Tour } from 'common/components/tour';
import { hasPaymentSites } from 'common/services/helper-service';
import ImageWithFallback from 'common/components/ImageFallback';

const requestKeys = {
	FETCH: 'fetch',
	KVAAS: 'kvaas',
	LOGO: 'logo',
};

const tourConfig = {
	version: 1, // increase this every time you make changes to the tourConfig,
	key: 'sidebarVideoTutorial',
	steps: [
		{
			selector: '#sidebarVideoTutorial',
			content: 'Make the most of your Portal experience. Check out our video demos to learn how to use key features!',
		},
	],
};

class SidebarComponent extends Component {
	constructor(props) {
		super(props);

		const principal = principalService.get();

		this.state = {
			principal,
			dba: this.getDba(principal),
			email: '',
			isHelpOpen: false,
			showDisputesTab: false,
			coBrandPortal: false,
			hasIfields: false,
			logoUrl: false,
		};
	}

	get hasTerminalOnly() {
		return hasFeaturePackage(featurePackages.terminalOnly);
	}

	componentDidMount = async () => {
		const { principal } = this.state;
		try {
			this.subscription = principalService.subscribe(principal => {
				this.setState({ principal, dba: this.getDba(principal) });
			});
			const [user] = await this.props.makePendingRequest(
				Promise.all([authenticationService.getUser(), this.fetchKvaas()]),
				requestKeys.FETCH
			);

			principal.hasAccess[sectionKeys.sendPaymentRequest] = await hasPaymentSites(principal);
			this.setState({
				email: get(user, 'attributes.email', ''),
				principal,
			});
		} catch (e) {
			this.props.handleError(e);
		}
	};

	componentWillUnmount() {
		this.subscription.unsubscribe();
	}
	hasPageAccess(sectionKey) {
		if (this.state.principal && this.state.principal.hasAccess) {
			return this.state.principal.hasAccess[sectionKeys[sectionKey]];
		}
	}
	fetchLogo = async (fetchNew = false) => {
		if (this.state.logoUrl && !fetchNew) {
			return;
		}
		try {
			const { LogoUrl } = await this.props.makePendingRequest(logoManagementService.getLogoUrl(), requestKeys.LOGO);
			let logoUrl = LogoUrl && `${LogoUrl}?${Date.now()}`;
			this.setState({ logoUrl });
		} catch (e) {
			this.props.handleError(e);
		}
	};

	hasAccessGoPlus = () => {
		const { principal } = this.state;
		if (principal && principal.hasAccess) {
			return principal.hasAccess[sectionKeys.goPlus];
		}
		return false;
	};
	fetchKvaas = async () => {
		const { history, makePendingRequest, handleError } = this.props;
		try {
			const [portalFlags, walkthroughSettings, logoManagement] = await makePendingRequest(
				kvaasService.get(kvaasResources.portalFlags, kvaasResources.walkthroughSettings, kvaasResources.logoManagement),
				requestKeys.KVAAS
			);
			const showDisputesTab = get(portalFlags, 'data.showDisputesTab', false) || this.hasAccessGoPlus();
			const sidebarVideoTutorial = get(walkthroughSettings, 'data.sidebarVideoTutorial');
			const isHelpOpen = !sidebarVideoTutorial && !startsWith(history.location.pathname, '/transactions');
			const coBrandPortal = get(logoManagement, 'data.coBrandPortal', false);

			const newState = {
				showDisputesTab,
				isHelpOpen,
				coBrandPortal,
			};

			if (coBrandPortal) {
				await this.fetchLogo();
			}

			if (isHelpOpen) {
				this.props.openSidebar();
				setTimeout(
					() => {
						this.setState(newState);
					},
					isHelpOpen ? 250 : 0
				);
			} else {
				this.setState(newState);
			}
		} catch (e) {
			handleError(e);
		}
	};

	getDba = principal => {
		if (!principal) {
			return;
		}
		const activeKey = find(principal.list, ({ mid }) => mid === principal.id);
		return activeKey && activeKey.dba;
	};

	expandedClass = className =>
		!this.props.isExpanded && window.innerWidth > 1199 ? `${className} is-collapsed` : className;

	redirectToUpgradePlan = (hasCustomerBilling = false) => {
		this.props.history.push({ pathname: '/terminal-only', state: { hasCustomerBilling } });
	};

	onNewTransactionClick = () => {
		const { history } = this.props;
		history.push({
			pathname: '/transactions',
			openNewTransaction: true,
		});
	};

	onNewCustomerClick = async () => {
		try {
			const { history } = this.props;
			const hasIfields =
				!!document.querySelector('.newtransaction__iframe') && history.location.pathname !== '/new-transaction';
			const [userSettings] = await this.props.makePendingRequest(
				kvaasService.get(kvaasResources.userSettings),
				requestKeys.KVAAS
			);
			const expandNewCustomerPopup = get(userSettings, 'data.expandNewCustomerPopup', false);
			history.push({
				pathname: '/customers',
				openNewCustomer: !hasIfields,
				advancedView: !!expandNewCustomerPopup,
			});
		} catch (e) {
			this.props.handleError(e);
		}
	};

	handleOpenCloseHelp = () => {
		this.setState({ isHelpOpen: !this.state.isHelpOpen }, this.props.scrollHelpIntoView);
	};

	onRecurringSchedulePaths = (_, { pathname }) => {
		return some(['/recurring-schedules', '/recurring-templates'], item => item === pathname);
	};

	onCustomerPaths = (_, { pathname }) => {
		return some(['/customers', '/upload-customer-preview', '/migrate-customers'], item => item === pathname);
	};
	render = () => {
		const {
			email,
			isHelpOpen,
			dba,
			showDisputesTab,
			coBrandPortal,
			hasIfields,
			hasSelectedRows,
			isFraud,
			logoUrl,
		} = this.state;
		const { history } = this.props;
		const principal = get(this.state, 'principal', { hasAccess: {} });
		const isViewOnly = principal.isViewOnly;
		const version =
			SoftwareSettings.version +
			'-' +
			(AppBuildVersion ? AppBuildVersion : '') +
			'-' +
			(AppBuildEnvironment ? AppBuildEnvironment : '');

		return (
			<Fragment>
				<div className="sidebar">
					<div className={this.expandedClass('sidebar__header')}>
						<div className="sidebar__header__logo">
							<ImageWithFallback>
								{props => (
									<a href="/" {...props}>
										<div className="sidebar__header__image"></div>
									</a>
								)}
							</ImageWithFallback>
						</div>
						<span className="sidebar__header__title">Merchant Portal</span>
					</div>

					<div className="nav sidebar__nav__wrapper">
						<ul className="nav sidebar__nav padd--top--sml">
							{this.hasPageAccess(sectionKeys.fraud) ? (
								<li className="nav__item">
									<NavLink className={this.expandedClass('nav__link')} activeClassName="is-active" to="/fraudwatch">
										<i className="icon icon--xsml nav__icon nav__icon--fraudwatch" />
										<span className="nav__link__text">FraudWatch Reports</span>
									</NavLink>
								</li>
							) : null}
							{this.hasPageAccess(sectionKeys.dashboard) ? (
								<Fragment>
									<li className="nav__item">
										<NavLink className={this.expandedClass('nav__link')} activeClassName="is-active" exact to="/">
											<i className="icon icon--xsml nav__icon nav__icon nav__icon--dashboard" />
											<span className="nav__link__text">Dashboard</span>
										</NavLink>
									</li>
									{this.hasPageAccess(sectionKeys.transactions) ? (
										<Fragment>
											<li className="nav__item">
												<div
													className={`${this.expandedClass('nav__link')} ${
														startsWith(history.location.pathname, '/transactions') ? 'is-active' : ''
													}`}
												>
													<NavLink className="nav__link__padding nav__link__padding--transactions" to="/transactions">
														<i className="icon icon--xsml nav__icon nav__icon--transactions" />
														<span className="nav__link__text">Transactions</span>
													</NavLink>
													{this.hasPageAccess(sectionKeys.newTransaction) ? (
														<button
															className="btn nav__link__button"
															onClick={() => {
																if (this.hasTerminalOnly) {
																	this.redirectToUpgradePlan();
																} else {
																	this.onNewTransactionClick();
																}
															}}
														>
															New{' '}
															<i className="nav__icon--newtransaction" />
														</button>
													) : null}
												</div>
											</li>
											{this.hasPageAccess(sectionKeys.transfers) ? (
												<li className="nav__item">
													<NavLink
														className={this.expandedClass('nav__link')}
														activeClassName="is-active"
														to="/transfers"
													>
														<i className="icon icon--xsml nav__icon nav__icon--reports" />
														<span className="nav__link__text">Transfers</span>
													</NavLink>
												</li>
											) : null}
											<li className="nav__item">
												<NavLink className={this.expandedClass('nav__link')} activeClassName="is-active" to="/reports">
													<i className="icon icon--xsml nav__icon nav__icon--reports" />
													<span className="nav__link__text">Reports</span>
												</NavLink>
											</li>
										</Fragment>
									) : null}
									{this.hasPageAccess(sectionKeys.gift) ? (
										<li className="nav__item">
											<NavLink className={this.expandedClass('nav__link')} activeClassName="is-active" to="/giftreport">
												<i className="icon icon--xsml nav__icon nav__icon--gift-report" />
												<span className="nav__link__text">Gift Report</span>
											</NavLink>
										</li>
									) : null}
								</Fragment>
							) : this.hasPageAccess(sectionKeys.virtualTerminal) ? (
								<Fragment>
									<li className="nav__item">
										<div
											className={`${this.expandedClass('nav__link')} ${
												startsWith(history.location.pathname, '/new-transaction') ? 'is-active' : ''
											}`}
										>
											<NavLink className="nav__link__padding nav__link__padding--transactions" to="/new-transaction">
												<i className="icon icon--xsml nav__icon nav__icon--transactions" />
												<span className="nav__link__text">New Transaction</span>
											</NavLink>
										</div>
									</li>
								</Fragment>
							) : null}
							{this.hasPageAccess(sectionKeys.batches) ? (
								<li className="nav__item">
									<NavLink className={this.expandedClass('nav__link')} activeClassName="is-active" to="/batches">
										<i className="icon icon--xsml nav__icon nav__icon--batches" />
										<span className="nav__link__text">Batches</span>
									</NavLink>
								</li>
							) : null}
							{showDisputesTab &&
								this.hasPageAccess(sectionKeys.dashboard) &&
								this.hasPageAccess(sectionKeys.disputes) && (
									<li className="nav__item">
										<div
											className={`${this.expandedClass('nav__link')} ${
												startsWith(history.location.pathname, '/disputes') ? 'is-active' : ''
											}`}
										>
											<NavLink className="display--b" to="/disputes">
												<i className="icon icon--xsml nav__icon nav__icon--exclamation-mark" />
												<span className="nav__link__text">Disputes</span>
											</NavLink>
										</div>
									</li>
								)}
							{this.hasPageAccess(sectionKeys.customers) && (
								<li className="nav__item">
									<div
										className={`${this.expandedClass('nav__link')} ${
											startsWith(history.location.pathname, '/customers') ||
											startsWith(history.location.pathname, '/migrate-customers') ||
											startsWith(history.location.pathname, '/upload-customer-preview')
												? 'is-active'
												: ''
										}`}
									>
										<NavLink
											className="nav__link__padding nav__link__padding--customers"
											to={`/customers${startsWith(history.location.pathname, '/customers') ? '?refresh=true' : ''}`}
											isActive={this.onCustomerPaths}
										>
											<i className="icon icon--xsml nav__icon nav__icon--customers" />
											<span className="nav__link__text">Customers</span>
										</NavLink>
										{!isViewOnly && (
											<button
												disabled={hasIfields || hasSelectedRows}
												className="btn nav__link__button"
												onClick={() => {
													if (!hasFeaturePackage(featurePackages.customerBilling)) {
														this.redirectToUpgradePlan(true);
													} else {
														this.onNewCustomerClick();
													}
												}}
											>
												New <i className="nav__icon--newtransaction" />
											</button>
										)}
									</div>
								</li>
							)}
							{this.hasPageAccess(sectionKeys.dashboard) ? (
								<li className="nav__item">
									<NavLink
										className={this.expandedClass('nav__link')}
										isActive={this.onRecurringSchedulePaths}
										activeClassName="is-active"
										to={`/recurring-schedules${
											startsWith(history.location.pathname, '/recurring-schedules') ? '?refresh=true' : ''
										}`}
									>
										<i className="icon icon--xsml nav__icon nav__icon--recurring-payments" />
										<span className="nav__link__text">Recurring Schedules</span>
									</NavLink>
								</li>
							) : null}
							{this.hasPageAccess(sectionKeys.newTransaction) && this.hasPageAccess(sectionKeys.sendPaymentRequest) && (
								<li className="nav__item">
									<NavLink
										className={this.expandedClass('nav__link')}
										activeClassName="is-active"
										to="/send-payment-request"
									>
										<i className="icon icon--xsml nav__icon nav__icon--payment" />
										<span className="nav__link__text">Send Payment Request</span>
									</NavLink>
								</li>
							)}
							{this.hasPageAccess(sectionKeys.portalManagement) && (
								<li className="nav__item">
									<NavLink
										className={this.expandedClass('nav__link')}
										activeClassName="is-active"
										to="/portal-management"
									>
										<i className="icon icon--xsml nav__icon nav__icon--portal-management" />
										<span className="nav__link__text">Portal Management</span>
									</NavLink>
								</li>
							)}
							{this.hasPageAccess(sectionKeys.statements) ? (
								<li className="nav__item">
									<NavLink className={this.expandedClass('nav__link')} activeClassName="is-active" to={'/statements'}>
										<i className="icon icon--xsml nav__icon nav__icon--statements" />
										<span className="nav__link__text">Statements</span>
									</NavLink>
								</li>
							) : null}

							<div className="nav__item__border" />
							<li className="nav__item">
								<NavLink
									className={this.expandedClass('nav__link')}
									activeClassName="is-active"
									to={this.hasTerminalOnly ? '/user-settings/new-transaction' : '/user-settings'}
								>
									<i className="icon icon--xsml nav__icon nav__icon--user-settings" />
									<span className="nav__link__text">User Settings</span>
								</NavLink>
							</li>
							{this.hasPageAccess(sectionKeys.settings) && (
								<Fragment>
									{(isFraud || !isViewOnly) && (
										<li className="nav__item">
											<NavLink
												className={this.expandedClass('nav__link')}
												activeClassName="is-active"
												to={this.hasTerminalOnly ? '/account-settings/new-transaction' : '/account-settings'}
											>
												<i className="icon icon--xsml nav__icon nav__icon--settings-alt" />
												<span className="nav__link__text">Portal Account Settings</span>
											</NavLink>
										</li>
									)}
								</Fragment>
							)}
							{this.hasPageAccess(sectionKeys.cardknoxAccountSettings) && (
								<Fragment>
									{!isFraud && (
										<li className="nav__item">
											<NavLink
												className={this.expandedClass('nav__link')}
												activeClassName="is-active"
												to="/cardknox-account-settings"
											>
												<i className="icon icon--xsml nav__icon nav__icon--settings-alt" />
												<span className="nav__link__text">Cardknox Account Settings</span>
											</NavLink>
										</li>
									)}
								</Fragment>
							)}

							{this.hasPageAccess(sectionKeys.featureAddons) && (
								<li className="nav__item">
									<NavLink className={this.expandedClass('nav__link')} activeClassName="is-active" to="/features">
										<i className="icon icon--xsml nav__icon nav__icon--puzzle" />
										<span className="nav__link__text">Feature Add-Ons</span>
									</NavLink>
								</li>
							)}
							<li className="nav__item">
								<Fragment>
									<div
										className={this.expandedClass(isHelpOpen ? 'nav__link is-active' : 'nav__link')}
										onClick={this.handleOpenCloseHelp}
									>
										<i className="icon icon--xsml nav__icon nav__icon--support" />
										<span className="nav__link__text">
											Help
										</span>
										<i className="icon nav__icon nav__icon--more" />
									</div>
									{isHelpOpen && (
										<div className={`spc--top--tny ${!this.props.isExpanded ? '' : 'spc--left--med'}`}>
											<a
												className={this.expandedClass('nav__link')}
												href={`https://cardknox.wufoo.com/forms/z30vu2k0i7r23e/?Field118=${encodeURIComponent(
													dba
												)}&Field119=${encodeURIComponent(email)}`}
												target="_blank"
											>
												<i className="icon icon--xsml nav__icon nav__icon--contact-us-alt" />
												<span className="nav__link__text">Contact Us</span>
											</a>
											<a
												className={this.expandedClass('nav__link')}
												href="https://kb.cardknox.com/portal-demo"
												rel="noopener noreferrer"
												target="_blank"
												id="sidebarVideoTutorial"
											>
												<i className="icon icon--xsml nav__icon nav__icon--video-tutorial" />
												<span className="nav__link__text">Video Tutorial</span>
											</a>
											<a
												className={this.expandedClass('nav__link')}
												href="https://kb.cardknox.com/portal-faq"
												rel="noopener noreferrer"
												target="_blank"
											>
												<i className="icon icon--xsml nav__icon nav__icon--faq" />
												<span className="nav__link__text">FAQ</span>
											</a>
											<a
												className={this.expandedClass('nav__link')}
												href="https://kb.cardknox.com/portal"
												rel="noopener noreferrer"
												target="_blank"
											>
												<i className="icon icon--xsml nav__icon nav__icon--user-guide" />
												<span className="nav__link__text">User Guide</span>
											</a>
											<a
												className={this.expandedClass('nav__link')}
												href="https://www.cardknox.com/remote"
												rel="noopener noreferrer"
												target="_blank"
												id="lastElementInHelp"
											>
												<i className="icon icon--xsml nav__icon nav__icon--remote-support" />
												<span className="nav__link__text">Remote Support</span>
											</a>
										</div>
									)}
								</Fragment>
							</li>
						</ul>
					</div>
				</div>
				<div className="sidebar__footer">
					{coBrandPortal && logoUrl && (
						<div className="sidebar__footer__logo">
							<img src={logoUrl} alt="Logo Image" />
						</div>
					)}
					<div className="sidebar__footer__text">
						&#169; Copyright {moment().format('YYYY')}
						<div className="type--break-word">({version})</div>
					</div>
				</div>
				{isHelpOpen && !startsWith(history.location.pathname, '/transactions') && <Tour tourConfig={tourConfig} />}
			</Fragment>
		);
	};
}

SidebarComponent.propTypes = {
	isExpanded: PropTypes.bool,
	location: PropTypes.object,
	history: PropTypes.object,
	handleError: PropTypes.func.isRequired,
	makePendingRequest: PropTypes.func.isRequired,
	scrollHelpIntoView: PropTypes.func.isRequired,
	openSidebar: PropTypes.func,
};

export default withError(withCancelable(withForwardRef(SidebarComponent, withRouter)));
