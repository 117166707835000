import React, { Component, createRef } from 'react';
import { object, number, bool, array, func, string, any, shape } from 'prop-types';
import { Row } from 'react-data-grid';
import { some, get, toLower } from 'lodash';

import { isComponent, ResizeSensor } from '../../utilities';
import { TransactionRowGridActionsTooltip } from '../tooltips';
import { propTypes } from 'react-keyboard-event-handler';

class ZebraRendererComponent extends Component {
	propsUpdateKeys = ['row', 'columns', 'isScrolling'];
	stateUpdateKeys = ['tooltipProps'];
	state = {
		tooltipProps: null,
	};
	rowRef = createRef();

	get zebraClass() {
		const {
			row: { gridRowNumber },
		} = this.props;
		return gridRowNumber % 2 ? 'grid-style-odd' : 'grid-style-even';
	}

	get expandedClass() {
		const {
			row: { isDetails },
			rowDetailsRenderer,
		} = this.props;
		return !rowDetailsRenderer && isDetails ? 'is-expanded' : '';
	}

	componentDidMount() {
		const { gridHolder } = this.props;
		if (!gridHolder) {
			return;
		}
		ResizeSensor(gridHolder, () => {
			if (!this.state.tooltipProps) {
				return;
			}
			this.showActions();
		});
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.hideActions && !this.props.hideActions) {
			this.hideActions();
		} else if (nextProps.hideActions === false && this.props.hideActions) {
			this.showActions();
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		const propsUpdated = this.isAnyUpdated(this.propsUpdateKeys, this.props, nextProps);
		const stateUpdated = this.isAnyUpdated(this.stateUpdateKeys, this.state, nextState);
		return propsUpdated || stateUpdated;
	}

	isAnyUpdated = (keys, current, next) => some(keys, key => current[key] !== next[key]);

	refreshGridData = () => {
		this.props.refreshGridData();
	};

	showActions = () => {
		const {
			row,
			gridRef,
			openModal,
			showLoader,
			dependentProps,
			actionsRenderer,
			showActionsOnError,
			hideActions,
		} = this.props;
		if (!actionsRenderer || hideActions) {
			return;
		}
		const rect = this.rowRef.current && this.rowRef.current.getBoundingClientRect();
		const canvas = get(gridRef, 'base.viewport.canvas.canvas');
		if (!canvas || !rect) {
			return;
		}
		const showTooltip = !row.isDetails && (showActionsOnError || toLower(row.xResponseResult) !== 'error');
		const gridRect = canvas.getBoundingClientRect();
		this.setState({
			tooltipProps: {
				infoDimensions: {
					width: gridRect.right + (parseInt(row.xClearedCount) ? -48 : 0) || 0,
					height: rect.bottom || 0,
				},
				tooltip: showTooltip && (
					<this.props.actionsRenderer
						row={row}
						openModal={openModal}
						refreshGridData={this.refreshGridData}
						showLoader={showLoader}
						{...dependentProps}
					/>
				),
			},
		});
	};

	hideActions = () => {
		const { actionsRenderer } = this.props;
		if (!actionsRenderer) {
			return;
		}
		this.setState({
			tooltipProps: null,
		});
	};
	render() {
		const {
			row,
			columns,
			openModal,
			rowDetailsProps,
			gridHolder,
			setDetailsRef,
			resizeGrid,
			type,
			closeRow,
		} = this.props;
		const { tooltipProps } = this.state;
		return (
			<div
				onMouseEnter={this.showActions}
				onMouseLeave={this.hideActions}
				className={`${this.zebraClass} ${this.expandedClass}`}
				ref={this.rowRef}
			>
				<Row {...this.props} />
				{row.isDetails && this.props.rowDetailsRenderer && (
					<this.props.rowDetailsRenderer
						row={row}
						visibleColumns={columns}
						openModal={openModal}
						refreshGridData={this.refreshGridData}
						gridHolder={gridHolder}
						setDetailsRef={setDetailsRef}
						resizeGrid={resizeGrid}
						type={type}
						{...(row.expandedRowProps || {})}
						{...rowDetailsProps}
						closeRow={closeRow}
					/>
				)}
				{tooltipProps && <TransactionRowGridActionsTooltip {...tooltipProps} />}
			</div>
		);
	}
}

ZebraRendererComponent.propTypes = {
	row: shape({
		isDetails: bool,
		gridRowNumber: number,
	}),
	columns: array,
	isScrolling: bool,
	refreshGridData: func,
	openModal: func,
	setDetailsRef: func,
	resizeGrid: func,
	gridHolder: object,
	type: string,
	rowDetailsRenderer: isComponent,
	actionsRenderer: isComponent,
	rowDetailsProps: object,
	closeRow: func,
	gridRef: any,
	showLoader: func.isRequired,
	dependentProps: object,
	showActionsOnError: bool,
	hideActions: propTypes.bool,
};

export default ZebraRendererComponent;
