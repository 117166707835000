import React from 'react';
import PropTypes from 'prop-types';

class OutsideClick extends React.Component {
	constructor(props) {
		super(props);

		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.props.action(event);
		}
	}

	render() {
		return (
			<div className={this.props.className} ref={this.setWrapperRef}>
				{this.props.children}
			</div>
		);
	}
}

OutsideClick.propTypes = {
	children: PropTypes.any.isRequired,
	action: PropTypes.func,
	className: PropTypes.string,
};

export default OutsideClick;
