import React from 'react';
import PropTypes from 'prop-types';
import ModalComponent from 'common/components/modal/modal';
import { withRouter } from 'react-router-dom';

const SuccessModal = ({ isOpen, onClose, history }) => {
	const redirectToCustomers = () => {
		history.push({ pathname: '/customers' });
	};
	return (
		<ModalComponent isOpen={isOpen} onClose={onClose}>
			<div className="popup__body popup--process__wrapper">
				<div className="popup--process is-success">
					<div className="icon"></div>
				</div>
				<p className="type--base type--color--text--regular spc--bottom--sml--alt">
					All Customers have been uploaded successfully!
				</p>
				<button className="btn btn--med btn--primary fullwidth" onClick={redirectToCustomers}>
					OK
				</button>
			</div>
		</ModalComponent>
	);
};

SuccessModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
};

export default withRouter(SuccessModal);
