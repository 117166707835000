import React, { Component, Fragment } from 'react';
import { object, func, bool } from 'prop-types';
import PaymentSiteAdditionalSettings from './PaymentSiteAdditionalSettings';
import PaymentSiteFormSetting from '../PaymentSiteFormSetting';
import { get, map } from 'lodash';

class PaymentSiteGroupedSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
		};
	}
	get expanded() {
		return {
			theme: true,
			backgroundColor: true,
		};
	}

	get settings() {
		const { hasThemeAccess } = this.props;
		const availableSettings = [];

		if (hasThemeAccess) {
			availableSettings.push({
				key: 'theme',
				label: 'PaymentSITE Theme',
				type: 'select',
				options: [{ label: 'Green', value: 'green' }, { label: 'Grey', value: 'grey' }],
				className: 'react-select-container reactselect is-open-top',
				classNamePrefix: 'react-select',
			});
		}
		return availableSettings;
	}

	handleExpand = () => {
		this.setState({ expanded: !this.state.expanded });
	};
	renderFormSettings = data => {
		if (get(this.props.state.data, 'theme', false) === 'green') return null;
		return map(
			data,
			({ key, label, tooltip, fields, expanded, component, groupedSetting }, sectionIndex) =>
				groupedSetting && (
					<PaymentSiteFormSetting
						key={key}
						label={label}
						tooltip={tooltip}
						fields={fields}
						expanded={expanded}
						formComponent={component}
						sectionIndex={sectionIndex}
						section={'generalSettings'}
						formKey={key}
						hideHeader={true}
						groupedSetting={true}
						{...this.props}
					/>
				)
		);
	};
	render() {
		const {
			handleAdditionalSettingsClear,
			handleAdditionalSettingsDataChange,
			state: { data, formSettings },
		} = this.props;
		const { expanded } = this.state;
		return (
			<div className="paymentsite__card--secondary spc--bottom--sml--alt">
				<div className="paymentsite__card--secondary__header">
					<label className="paymentsite__card__title">Themes</label>
					<button
						type="button"
						className="btn btn--sml btn--clear"
						onClick={() => this.handleExpand()}
						data-tooltip={expanded ? 'Collapse' : 'Expand'}
					>
						<i className={`icon icon--tiny icon--arrow icon--arrow--right--grey ${expanded ? 'is-expanded' : ''}`}></i>
					</button>
				</div>
				{expanded && (
					<Fragment>
						<div className="separator separator--grey1 spc--bottom--med"></div>
						<PaymentSiteAdditionalSettings
							settings={this.settings}
							data={data}
							expanded={this.expanded}
							handleExpand={this.handleExpand}
							handleAdditionalSettingsDataChange={handleAdditionalSettingsDataChange}
							handleAdditionalSettingsClear={handleAdditionalSettingsClear}
							hideHeader={true}
							groupedSetting={true}
						/>
						{this.renderFormSettings(formSettings)}
					</Fragment>
				)}
			</div>
		);
	}
}

PaymentSiteGroupedSettings.propTypes = {
	handleAdditionalSettingsDataChange: func.isRequired,
	handleAdditionalSettingsClear: func.isRequired,
	state: object.isRequired,
	hasThemeAccess: bool.isRequired,
	internalSetting: bool,
	hideHeader: bool,
};

export default PaymentSiteGroupedSettings;
