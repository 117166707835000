import React from 'react';
import PropTypes from 'prop-types';
import { SidebarComponent } from 'components/sidebar';
import { get, map, split } from 'lodash';
import { withRouter } from 'react-router-dom';
import { withCancelable } from '../cancelable';
import { OutsideClick, hasFeaturePackage } from 'common/utilities';
import { SidebarContext } from 'common/contexts';
import routes from 'routing/routes';
import TerminalOnlyUpgradePlan from 'components/upgrade-plan/TerminalOnlyUpgradePlan';
import { featurePackages } from 'common/utilities/has-feature-package';

const VIRTUAL_PATHS = ['transactions', 'account-settings/new-transaction', 'upgrade-plan'];
const BILLING_PATHS = ['customers', 'upload-customer-preview', 'recurring-schedules', 'migrate-customers'];
import ImageWithFallback from '../ImageFallback';

export function withSidebar(WrappedComponent) {
	WrappedComponent = withRouter(withCancelable(WrappedComponent));
	class WithSidebar extends React.Component {
		constructor(props) {
			super(props);
			this.routesWithoutSidebar = map(routes, route => {
				if (route.layout || route.isPublic) {
					return route.path.slice(1);
				}
			});
			this.sidebarRef = React.createRef();
			this.state = {
				sidebarVisible: false,
				isExpanded: true,
			};
			this.contextValue = {
				sidebarRef: this.sidebarRef,
				toggleSidebar: this.toggleSidebar,
				openSidebar: this.openSidebar,
				closeSidebar: this.closeSidebar,
				toggleExpand: this.toggleExpand,
				enableDisableNewTransactionSidebar: this.enableDisableNewTransactionSidebar,
				enableDisableNewCustomerSidebar: this.enableDisableNewCustomerSidebar,
				updateSidebarLogo: this.updateSidebarLogo,
				showDisputesTab: this.showDisputesTab,
			};
		}
		componentDidUpdate(prevProps) {
			if (this.props.location.pathname !== prevProps.location.pathname) {
				this.handleResize();
			}
		}

		componentDidMount() {
			window.addEventListener('resize', this.handleResize);
			this.handleResize();
		}
		get currentPath() {
			const {
				location: { pathname },
			} = this.props;
			return pathname.slice(1);
		}

		get isVirtualOnly() {
			return hasFeaturePackage(featurePackages.terminalOnly);
		}

		get isCustomerBilling() {
			return BILLING_PATHS.includes(this.currentPath);
		}
		componentWillUnmount() {
			window.removeEventListener('resize', this.handleResize);
		}

		enableDisableNewCustomerSidebar = enable => {
			this.sidebarRef.current.setState({ hasIfields: enable });
		};
		enableDisableNewTransactionSidebar = enable => {
			this.sidebarRef.current.setState({ hasSelectedRows: enable });
		};

		handleResize = () => {
			const sidebarVisible = window.innerWidth > 1200;
			if (this.state.sidebarVisible === sidebarVisible) return;
			this.setState({ sidebarVisible });
		};

		toggleSidebar = () => {
			this.setState(prevState => ({
				sidebarVisible: !prevState.sidebarVisible,
				isExpanded: window.innerWidth > 1200 ? true : !prevState.isExpanded,
			}));
		};
		openSidebar = () => {
			if (this.state.sidebarVisible) return;
			this.setState({ sidebarVisible: true });
		};

		closeSidebar = () => {
			if (!this.state.sidebarVisible) return;
			this.setState({ sidebarVisible: false });
		};

		showDisputesTab = showDisputesTab => {
			this.sidebarRef.current.setState({ showDisputesTab });
		};

		shouldShowTerminalOnly() {
			const isVirtual = VIRTUAL_PATHS.includes(this.currentPath);
			const isCustomerBilling = BILLING_PATHS.includes(this.currentPath);
			const hasCustomerBillingFeature = hasFeaturePackage(featurePackages.customerBilling);

			return (this.isVirtualOnly && !isVirtual) || (isCustomerBilling && !hasCustomerBillingFeature);
		}
		toggleExpand = expand => {
			const newExpandedState = expand !== undefined && expand !== null ? expand : !this.state.isExpanded;
			this.setState({ isExpanded: newExpandedState });
		};
		scrollHelpIntoView = () => {
			const elem = document.getElementsByClassName('nav sidebar__nav__wrapper')[0];
			const lastItem = document.querySelector('#lastElementInHelp');
			if (elem && lastItem && !this.state.isExpanded) {
				const itemHeight = lastItem.offsetHeight - 12;
				const rect = lastItem.getBoundingClientRect();
				const previousScroll = parseInt(split(split(elem.style.transform, 'translateY(')[1], 'px')[0]);
				const newScroll = Math.ceil((rect.bottom - window.innerHeight + 72) / itemHeight) * itemHeight;
				if (previousScroll) {
					elem.style.transform = `translateY(${previousScroll - newScroll}px)`;
				}
			}
		};
		updateSidebarLogo = logoData => {
			const { logoUrl, coBrandPortal } = logoData;
			this.sidebarRef.current.logoUrl = logoUrl;
			this.sidebarRef.current.setState({ logoUrl, coBrandPortal });
		};
		render() {
			const { sidebarVisible, isExpanded } = this.state;
			const sidebarCssClass = sidebarVisible ? 'is-revealed' : '';
			const collapsedClass = isExpanded ? '' : 'is-collapsed';
			const isMfaRequired = get(this.props.history, 'location.state.isMfaRequired');

			const isWithoutSidebar = this.routesWithoutSidebar.includes(this.currentPath);
			if (isWithoutSidebar) {
				return <WrappedComponent {...this.props} />;
			}
			return (
				<SidebarContext.Provider value={this.contextValue}>
					<div>
						{isMfaRequired ? null : (
							<OutsideClick action={this.closeSidebar}>
								<header className="header--mobile datatooltip--h--right datatooltip--no-wrap datatooltip--expand-menu">
									<button
										onClick={this.toggleSidebar}
										type="button"
										className="btn--reset header--mobile__btn header--mobile__item"
										data-tooltip="Expand Menu"
									>
										<i className="icon icon--xsml icon--menu"></i>
									</button>
									<ImageWithFallback>
										{() => (
											<a
												href="#"
												onClick={e => e.preventDefault()}
												className="header--mobile__logo header--mobile__item"
											></a>
										)}
									</ImageWithFallback>

									<div className="header--mobile__membership header--mobile__item">
										<div className="header--mobile__actions" id="mobileHeaderActionsPortal"></div>
										<div className="header--mobile__notifications" id="mobileHeaderNotificationPortal"></div>
									</div>
								</header>
								<div className={`l--aside ${collapsedClass} ${sidebarCssClass}`}>
									<div className="l--aside__header datatooltip--h--right datatooltip--no-wrap datatooltip--close-mobile-nav">
										<div className="datatooltip--h--right datatooltip--no-wrap datatooltip--close-mobile-nav">
											<button
												onClick={this.toggleSidebar}
												type="button"
												className="btn--reset header--mobile__btn"
												data-tooltip="Collapse Menu"
											>
												<i className="icon icon--tiny icon--close-big--navigation"></i>
											</button>
										</div>
									</div>
									<SidebarComponent
										ref={this.sidebarRef}
										scrollHelpIntoView={this.scrollHelpIntoView}
										isExpanded={isExpanded}
										openSidebar={this.openSidebar}
									/>
									<button
										type="button"
										className="btn btn--med btn--collapse datatooltip--collapse"
										onClick={() => this.toggleExpand()}
									>
										<i
											className={`icon nav__icon nav__icon--${
												isExpanded ? 'menu-collapse' : 'menu-expand'
											} align--v--middle`}
											data-tooltip={isExpanded ? 'Collapse Menu' : 'Expand Menu'}
										/>
									</button>
								</div>
								<div className={`l--main ${collapsedClass}`}>
									<div className="l--content__wrapper">
										{this.shouldShowTerminalOnly() ? (
											<TerminalOnlyUpgradePlan isCustomerBilling={this.isCustomerBilling} />
										) : (
											<WrappedComponent {...this.props} />
										)}
									</div>
								</div>
							</OutsideClick>
						)}
					</div>
				</SidebarContext.Provider>
			);
		}
	}
	WithSidebar.propTypes = {
		history: PropTypes.object,
		location: PropTypes.object,
	};

	return WithSidebar;
}
