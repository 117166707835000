import React from 'react';

const Developer = () => (
	<div>
		<p className="type--wgt--bold spc--bottom--med">MUTUAL CONFIDENTIALITY AND NON-DISCLOSURE AGREEMENT</p>
		<p className="type--wgt--bold spc--bottom--sml">TERMS AND CONDITIONS</p>
		<p className="type--wgt--bold spc--bottom--med">Use of Cardknox Software Development Kit (SDK)</p>
		<p className="type--wgt--bold spc--bottom--med">
			PLEASE READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY BEFORE APPLYING OR OTHERWISE USING THE SOFTWARE
			DEVELOPMENT KIT ("SDK") OF CARDKNOX DEVELOPMENT INC. ("CARDKNOX") THESE TERMS AND CONDITIONS (THIS "AGREEMENT") IS
			A LEGALLY BINDING AGREEMENT BETWEEN YOU ("YOU" OR "YOUR") AND CARDKNOX DEVELOPMENT, INC. ("CARDKNOX") AND
			REPRESENTS THE ENTIRE AGREEMENT CONCERNING THE APPLICATION AND USE OF THE SDK BETWEEN YOU AND CARDKNOX.
		</p>
		<p className="spc--bottom--med">
			By using or applying the SDK, or accessing the SDK through any means permissible, you acknowledge and agree that:
			(i) you have reviewed and understand this Agreement; (ii) you agree to be legally bound by the terms and
			conditions of this Agreement; and (iii) your use of the SDK will be governed by this Agreement. If you do not
			agree or are not willing to be bound by the terms and conditions of this Agreement, you should not use the SDK.
		</p>
		<ol className="list--decimal spc--left--sml">
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">License.</p>
				Subject to the terms and conditions of this Agreement, Cardknox hereby grants to you, and you hereby accept, a
				limited, non-exclusive, non-transferable, non-sublicensable right and license during the term of this Agreement
				to use and integrate the SDK into your products for purposes relating solely to your internal operations.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">Reservation.</p>
				The SDK is licensed, not sold, to you. This Agreement does not convey title or ownership to you, but instead
				gives you only the limited right to use the SDK strictly in accordance with this Agreement. Cardknox reserves
				all rights not expressly granted by this Agreement.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">Ownership of Intellectual Property Rights.</p>
				You hereby acknowledge that all intellectual property rights protecting or pertaining to any aspect of the SDK
				are and shall remain the sole and exclusive property of Cardknox. Except as expressly set forth in this
				Agreement, you have no right to use, make, sublicense, modify, transfer, rent, lease, sell, display, distribute
				or copy originals or copies of the SDK, or to permit anyone else to do so. In addition, you will not reverse
				engineer, unencrypt, disassemble, decompile or otherwise translate the SDK or allow anyone else to do it.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">Proprietary Notices.</p>
				You shall not remove any patent, copyright or trademark or other intellectual property notices that may appear
				on any part of the SDK.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">Audit.</p>
				Cardknox may, upon written notice to you, audit your compliance with this Agreement, as Cardknox deems
				reasonable.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">Irreparable Harm.</p>
				You acknowledge that money damages may not be an adequate remedy for any breach or violation of any requirement
				set forth in this Agreement and that any such breach or violation may leave Cardknox without an adequate remedy
				at law. You therefore agree and acknowledge that any such breach or violation or threatened breach or violation
				will cause irreparable harm to Cardknox and that, in addition to any other remedies that may be available, in
				law, in equity or otherwise, Cardknox shall be entitled to obtain both temporary and permanent injunctive relief
				against the threatened breach or violation of this Agreement or the continuation of any such breach or
				violation, without the necessity of proving actual damages or posting bond or other security (to the extent that
				Cardknox is required to post bond or other security, the parties agree and stipulate that $1,000 is sufficient
				for such bond or other security).
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">Term; Termination.</p>
				This Agreement will commence on the Effective Date (as set forth below) and will continue until terminated by
				either party as provided herein. Either party may terminate this Agreement at any time in its discretion upon 15
				days prior written notice. Cardknox may terminate this Agreement immediately upon notice thereof due to any
				breach or suspected breach of this Agreement by you. Upon any termination or expiration of this Agreement, you
				shall immediately cease the use of the SDK, and will immediately return to Cardknox or destroy all copies of the
				SDK, and remove the SDK from all your products and other media in your custody, possession or control. If you
				otherwise retain or continue using any portion of the SDK after termination of this Agreement, Cardknox shall be
				entitled to obtain both temporary and permanent injunctive relief, without any bond, and irreparable harm shall
				be presumed. Termination shall not limit Cardknox from pursuing any other available remedies.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">Compliance with Laws.</p>
				You will, at all times, use the SDK in compliance with all applicable laws, rules and regulations and the terms
				of this Agreement.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">Disclaimer of Warranties.</p>
				YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE SDK IS AT YOUR SOLE RISK AND THAT THE ENTIRE RISK AS TO
				SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT IS WITH YOU. THE SDK IS PROVIDED "AS IS," WITH ALL FAULTS
				AND WITHOUT WARRANTY OF ANY KIND, AND CARDKNOX HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT TO
				THE SDK, EITHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR
				CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, OF
				QUIET ENJOYMENT, AND NON-INFRINGEMENT OF THIRD PARTY RIGHTS. ANY USE OF THE SDK IS AT THE USERS RISK. CARDKNOX
				DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE SDK WILL MEET YOUR REQUIREMENTS, THAT THE OPERATION OF THE
				SDK WILL BE ERROR-FREE, OR THAT DEFECTS IN THE SDK WILL BE CORRECTED. NO ORAL OR WRITTEN INFORMATION OR ADVICE
				GIVEN BY CARDKNOX OR ITS AUTHORIZED REPRESENTATIVE SHALL CREATE A WARRANTY.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">Limitation of Liability.</p>
				IN NO EVENT SHALL CARDKNOX BE LIABLE FOR ANY INCIDENTAL, SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES WHATSOEVER,
				INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION OR ANY OTHER
				COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO USE OF THE SDK, HOWEVER CAUSED, REGARDLESS OF THE
				THEORY OF LIABILITY (CONTRACT, TORT OR OTHERWISE) AND EVEN IF CARDKNOX HAS BEEN ADVISED OF THE POSSIBILITY OF
				SUCH DAMAGES. In no event shall Cardknox's total liability to you for all damages exceed the amount of fifty
				dollars ($50.00). The foregoing limitations will apply even if the above stated remedy fails of its essential
				purpose.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">Notices.</p>
				All notices and other communications required or permitted under this Agreement shall be in writing and given by
				personal delivery, telecopy (confirmed by a mailed copy), or first class mail, postage prepaid, sent to the
				respective contact at the other party. Notices to you shall be sent to the address set forth on your applicable
				account. Notices to Cardknox shall be sent to Cardknox Development Inc., Attn: President, 895 Towbin Avenue,
				Lakewood, NJ 08701. The effective dates of such notice shall be (1) upon evidence of a successful facsimile
				transmission; (2) five days following the date of mailing for certified or registered letters; or (3) two days
				following the date sent for overnight letters. The above addresses may be changed at any time by giving prior
				written notice as above provided.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">Assignment.</p>
				You may not assign this Agreement and the rights and obligations hereunder without the prior written consent of
				Cardknox.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">Independent Contractors.</p>
				The parties are not, and nothing in this Agreement shall be interpreted that the parties are, partners, joint
				venturers, co-owners or otherwise participants in a joint or common undertaking.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">Governing Law; Waiver of Jury Trial.</p>
				THIS AGREEMENT WILL BE GOVERNED BY AND INTERPRETED IN ACCORDANCE WITH THE LAWS OF THE STATE OF DELAWARE, WITHOUT
				REGARD TO CONFLICT OF LAWS PRINCIPLES. YOU HEREBY CONSENT AND SUBMIT TO THE EXCLUSIVE JURISDICTION OF COMPETENT
				STATE AND FEDERAL COURTS IN NEW YORK, NEW YORK FOR ANY LITIGATION OR PROCEEDING ARISING UNDER THIS AGREEMENT.
				EACH PARTY EXPRESSLY WAIVES ITS RIGHT TO A TRIAL BY JURY.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">Amendments.</p>
				MODIFICATIONS TO THIS AGREEMENT MAY BE MADE BY CARDKNOX AT ANY TIME. THE MODIFIED AGREEMENT WILL BE EFFECTIVE
				IMMEDIATELY UPON POSTING ON THE CARDKNOX WEBSITE AND YOU AGREE TO THE NEW POSTED AGREEMENT BY CONTINUING THE USE
				OF THE SDK. CARDKNOX WILL USE REASONABLE EFFORTS TO NOTIFY YOU OF MODIFICATIONS THAT ARE MATERIAL TO YOUR USE OF
				THE SDK, WHICH MODIFICATION MAY BE PROVIDED BY EMAIL OR A NOTIFICATION POSTED TO YOUR ACCOUNT. IF YOU DO NOT
				AGREE WITH THE MODIFIED AGREEMENT, YOUR ONLY REMEDY IS TO (A) DISCONTINUE USING THE SDK, AND (B) TERMINATE THIS
				AGREEMENT IN ACCORDANCE WITH ITS TERMS.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">Survival.</p>
				All agreements that by their context are intended to survive the termination of this Agreement will survive
				termination of this Agreement.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">E-Sign Consent Agreement.</p>
				By accessing and using the SDK, you hereby consent and agree that: (a) Cardknox can provide disclosures required
				by applicable law to you electronically; (b) where required or requested, your electronic signature (via
				"click-through" or other method) on agreements and documents relating to use of the SDK has the same effect as
				if you signed them in ink; (c) Notice by Cardknox to you (including notice of changes to this Agreement) shall
				be deemed given when: (1) transmitted to your primary email address on file with Cardknox; or (2) mailed via the
				U.S. mail or hand-delivered to your address on file with Cardknox; or (3) when posted to the merchant portal.
				Written notices will be deemed given upon personal delivery, upon confirmation of receipt if sent by fax, or
				three (3) days after the date of mailing if sent by certified or registered mail, postage prepaid. Email notices
				shall be deemed given the next business day following the date delivered; (d) if you want a paper copy, you can
				print a copy of the Disclosure or download the information for your records; and (e) This consent applies to all
				future Disclosures sent to you in connection with the use of the SDK or this Agreement.
			</li>
		</ol>
	</div>
);
export default Developer;
