import moment from 'moment';
import { find, trim } from 'lodash';

import { createFilters, getDate, localToApiMoment, getFilterValues } from 'common/utilities';
import { DatePickerSelection, SingleSelection } from 'common/components/selections';
import { NumberFilter, StringFilter, LastDigitsFilter } from 'common/components/filters';

export const disputesFilter = createFilters([
	{
		key: 'date',
		customSettingsKey: 'date',
		hasSelection: true,
		defaultHasSelection: true,
		getSelectionText: ({ key, startDate, endDate }, predefinedDates) => {
			const predefinedDate = find(predefinedDates, { key });
			if (predefinedDate) {
				return predefinedDate.displayValue;
			}
			const diff = endDate.diff(startDate, 'days');
			return diff ? `Custom (${diff + 1} days)` : diff === 0 ? 'Custom (1 day)' : 'Custom';
		},
		values: {
			key: '7',
			startDate: moment()
				.startOf('day')
				.add(-7, 'day'),
			endDate: moment().endOf('day'),
			disabled: false,
		},
		props: {
			displayTime: true,
			maxDaysRange: 100,
			milliseconds: {
				startDate: '000',
				endDate: '999',
			},
		},
		selectionComponent: DatePickerSelection,
	},
	{
		key: 'cardNumber',
		name: 'Card Number',
		customSettingsKey: 'accountNumber',
		values: {
			cardNumber: null,
		},
		component: LastDigitsFilter,
		selectionComponent: SingleSelection,
		props: {
			numberOfDigits: 4,
		},
		onTop: true,
		allowsDateDisable: true,
	},
	{
		key: 'amount',
		name: 'Transaction Amount',
		customSettingsKey: 'transactionAmount',
		values: {
			amount: null,
		},
		props: {
			prefix: '$',
		},
		component: NumberFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'cardholderName',
		name: 'Cardholder Name',
		customSettingsKey: 'cardholderName',
		values: {
			cardholderName: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
		allowsDateDisable: true,
		onTop: true,
	},
	{
		key: 'referenceNumber',
		name: 'Reference Number',
		values: {
			referenceNumber: '',
		},
		component: NumberFilter,
		selectionComponent: SingleSelection,
		allowsDateDisable: true,
		onTop: true,
	},
]);

export const compileFilter = async (filters, filterDateFormat) => {
	const { date, cardNumber, amount, cardholderName, referenceNumber } = getFilterValues(filters);

	const filter = {
		xCommand: date.disabled ? 'Report:Transactions' : 'Report:All',
	};
	filter.xStatus = 'ai13,14,17';

	if (!date.disabled) {
		const beginDate = await localToApiMoment(date.startDate);
		const endDate = await localToApiMoment(date.endDate);
		filter.xBeginDate = getDate(beginDate, filterDateFormat);
		filter.xEndDate = getDate(endDate, filterDateFormat);
		if (trim(amount.amount)) {
			filter.xAmount = 'ai' + amount.amount.toString();
		}
	}

	if (trim(cardholderName.cardholderName)) {
		filter.xName = (date.disabled ? '' : 'a=') + cardholderName.cardholderName;
	}

	if (trim(cardNumber.cardNumber) && cardNumber.cardNumber.indexOf('_') === -1) {
		filter.xCardLastFour = (date.disabled ? '' : 'ai') + cardNumber.cardNumber.toString();
	}

	if (trim(referenceNumber.referenceNumber)) {
		filter.xRefNum = (date.disabled ? '' : 'a=') + referenceNumber.referenceNumber.toString();
	}

	return filter;
};
