import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { trim, toLower } from 'lodash';

import { principalService } from 'common/services';
import { logger } from 'common/utilities';
import PrivacyPolicyFooter from 'common/components/footer/PrivacyPolicyFooter';

class ForgotPasswordComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			username: '',
			errorMessage: null,
		};
	}

	componentWillMount() {
		if (principalService.get()) {
			this.redirect();
		}
	}

	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleSubmit = async event => {
		const { history } = this.props;
		let { username } = this.state;
		username = trim(toLower(username));

		event.preventDefault();

		try {
			await Auth.forgotPassword(username);
			history.push({
				pathname: '/confirm-new-password',
				state: { username: username },
			});
		} catch (err) {
			logger.logError({
				message: 'Forgot password error.',
				errorDetails: err,
				username,
			});
			let message;
			if (
				err &&
				toLower(err.message) ===
					'cannot reset password for the user as there is no registered/verified email or phone_number'
			) {
				history.push({
					pathname: '/confirm-registration',
					state: { username: username },
				});
			}
			switch (err && err.code) {
				case 'UserNotConfirmedException': {
					history.push({
						pathname: '/confirm-registration',
						state: { username: username },
					});
					break;
				}
				case 'UserNotFoundException': {
					message = 'User with provided email does not exist.';
					break;
				}
				case 'LimitExceededException': {
					message = 'Login attempts exceeded the limit. Please wait and try again later.';
					break;
				}
				default: {
					if (!this.state.username) {
						message = 'Please enter your email.';
					} else {
						message = 'Something went wrong. Please try again.';
					}
					break;
				}
			}
			this.setState({
				errorMessage: message,
			});
		}
	};

	redirectToRegister = () => {
		const { history } = this.props;
		history.push('/register');
	};

	redirectToLogin = () => {
		const { history } = this.props;
		history.push('/login');
	};

	redirect() {
		const { history, location } = this.props;
		let redirectUrl = '/';

		if (location.state && location.state.returnUrl) {
			redirectUrl = location.state.returnUrl;
		}

		history.push(redirectUrl);
	}

	render() {
		const { username, errorMessage } = this.state;

		return (
			<div>
				<form className="membership__form form" onSubmit={this.handleSubmit}>
					<div className="membership__section">
						<h2 className="membership__title membership__title--alt">Forgot your password?</h2>
						<p className="membership__description">
							Enter your email and we'll email you instructions on how to reset your password
						</p>
						<div className="membership__spacer">
							<label className="membership__label">Email</label>
							<input
								name="username"
								type="email"
								className="input input--med"
								placeholder="user@gmail.com"
								value={username}
								onChange={this.handleChange}
								inputMode="email"
							/>
							{errorMessage ? (
								<div className="spc--top--sml membership__spacer type--color--warning">{errorMessage}</div>
							) : null}
						</div>
						<button type="submit" className="btn btn--primary btn--med membership__btn">
							Reset password
						</button>
						<div className="membership--main__action">
							<span className="membership--main__label">Do you have an account?</span>{' '}
							<button
								type="button"
								onClick={this.redirectToLogin}
								className="btn membership--main__btn type--wgt--medium"
							>
								Log in
							</button>
						</div>
					</div>
				</form>
				<PrivacyPolicyFooter />
			</div>
		);
	}
}

ForgotPasswordComponent.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
};

export default ForgotPasswordComponent;
