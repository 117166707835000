import React, { Component, createRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { get, isFunction } from 'lodash';

const appRoot = document.getElementById('app');

const arrowClassNames = {
	NONE: 'sort',
	ASC: 'dropup',
	DESC: 'dropdown',
};

class Tooltip extends Component {
	constructor(props) {
		super(props);
		this.el = document.createElement('div');
		this.activator = createRef();
		this.activator2 = createRef();
		this.nameRef = createRef();

		this.state = {
			popupVisible: false,
			left: '',
			top: '',
		};
	}

	componentDidMount = () => {
		appRoot.appendChild(this.el);
	};

	componentWillUnmount = () => {
		appRoot.removeChild(this.el);
	};

	showPopup = (useCheckboxTooltip = false) => () => {
		const ref = useCheckboxTooltip ? this.activator2 : this.activator;
		const { left, top } = ref.current.getBoundingClientRect();
		this.setState({
			useCheckboxTooltip,
			popupVisible: true,
			left: `${left - 20}px`,
			top: `${top + 26}px`,
		});
	};

	hidePopup = () => {
		this.setState({
			useCheckboxTooltip: false,
			popupVisible: false,
		});
	};

	renderPopup = () => {
		const {
			tooltip,
			column: { getColumnHeaderTooltip },
		} = this.props;
		const { left, top, useCheckboxTooltip } = this.state;

		return (
			<div
				style={{
					position: 'absolute',
					zIndex: 999999999,
					left,
					top,
				}}
				className="grid__header__tooltip"
			>
				{useCheckboxTooltip && isFunction(getColumnHeaderTooltip) ? getColumnHeaderTooltip() : tooltip}
			</div>
		);
	};

	get style() {
		const ref = get(this.nameRef, 'current', false);
		let style = {};

		if (ref) {
			const iconsWidth = 70;
			const width = this.props.column.width - iconsWidth;
			const isOverflowing = ref.scrollWidth > width;

			if (isOverflowing) {
				style = {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					width,
				};
			}
		}

		return style;
	}

	forceUpdateOnClick = e => {
		e.stopPropagation();
		this.forceUpdate();
	};

	render = () => {
		const {
			column: { sortable, name, alignHeaderRight, renderCheckbox },
			sortDirection,
			tooltip,
		} = this.props;
		const { popupVisible } = this.state;
		const shouldRenderCheckbox = isFunction(renderCheckbox);

		return (
			<div className={alignHeaderRight ? 'type--right' : ''}>
				{shouldRenderCheckbox && (
					<span
						ref={this.activator2}
						onClick={this.forceUpdateOnClick}
						onMouseEnter={this.showPopup(true)}
						onMouseLeave={this.hidePopup}
					>
						{renderCheckbox()}
					</span>
				)}
				<p className="display--ib" style={this.style} ref={this.nameRef}>
					{name}
				</p>
				<div className="display--ib align--v--top">
					{tooltip && (
						<span ref={this.activator} onMouseEnter={this.showPopup()} onMouseLeave={this.hidePopup}>
							<i className="icon icon--tiny icon--info align--v--middle spc--left--tny align--v--top"></i>
						</span>
					)}
					{popupVisible && createPortal(this.renderPopup(), this.el)}
					{sortable && (
						<i
							className={`icon icon--tiny icon--arrow--${arrowClassNames[sortDirection]}--grey spc--left--tny align--v--top`}
						></i>
					)}
				</div>
			</div>
		);
	};
}

Tooltip.propTypes = {
	tooltip: PropTypes.string,
	column: PropTypes.object,
	sortDirection: PropTypes.string,
};

export default Tooltip;
