import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardTypeImagePath } from '../../Common/utilities';

class CheckDisplayComponent extends Component {
	render() {
		const { accountNumberOnly } = this.props;
		return (
			<div className="newtransaction--expanded--inner spc--top--med">
				{accountNumberOnly ? null : (
					<div className="newtransaction__item newtransaction__item--expandable newtransaction__item--expandable--alt">
						<label className="newtransaction__item__label newtransaction__item__label--expandable">Account name</label>
						<div className="newtransaction__item__value newtransaction__item__value--expandable">
							<div className="fakeinput fakeinput--med">
								<span>{this.props.accountName}</span>
							</div>
						</div>
					</div>
				)}
				<div className="newtransaction__item newtransaction__item--expandable newtransaction__item--expandable--alt">
					<label className="newtransaction__item__label newtransaction__item__label--expandable">Account number</label>
					<div className="newtransaction__item__value newtransaction__item__value--expandable">
						<div className="fakeinput fakeinput--med">
							<img src={CardTypeImagePath.getPath(this.props.type)} className="grid__creditcard" />
							<span>{this.props.mask}</span>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

CheckDisplayComponent.propTypes = {
	mask: PropTypes.string,
	accountName: PropTypes.string,
	accountNumberOnly: PropTypes.bool,
	type: PropTypes.string,
};

export default CheckDisplayComponent;
