import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { noop } from 'lodash';

class NumberFilterComponent extends Component {
	get value() {
		const {
			filter: { key, values },
		} = this.props;
		return values[key] === null ? '' : values[key];
	}

	componentDidUpdate() {
		const { noFocus } = this.props;
		if (this.inputRef && !noFocus) {
			this.inputRef.focus();
		}
	}

	handleChange = values => {
		const { formattedValue } = values;
		const {
			onFilterChanged,
			filter: { key },
		} = this.props;

		onFilterChanged({
			id: key,
			values: [{ key, value: formattedValue === '' ? null : formattedValue }],
			emptyValue: null,
		});
	};

	render() {
		const {
			filter: { key, name, standalone, values },
			noFocus,
			onBlur,
			goButtonHandler,
			inputMode,
			thousandSeparator,
			decimalSeparator,
		} = this.props;
		const userInputMode = inputMode || 'numeric';
		return (
			<div className="pos--rel inputgroup">
				<div className="inputgroup--main">
					<NumberFormat
						decimalSeparator={decimalSeparator}
						thousandSeparator={thousandSeparator}
						value={values[key] === null ? '' : values[key]}
						placeholder={name}
						id={key}
						onValueChange={this.handleChange}
						onBlur={onBlur ? onBlur : noop}
						autoFocus={!noFocus}
						getInputRef={el => (this.inputRef = el)}
						inputMode={userInputMode}
						className={`input input--sml input--search ${
							standalone
								? 'input--med input--search inputgroup--main--bordered--left'
								: goButtonHandler
								? 'inputgroup--main--bordered--left'
								: ''
						}`}
					/>
				</div>
				{goButtonHandler && (
					<div className="inputgroup--aside">
						<button
							className={`btn btn--${standalone ? 'med' : 'sml'} btn--primary inputgroup--aside--bordered--right`}
							data-tooltip="Apply"
							onClick={goButtonHandler}
						>
							<i className="icon icon--nano icon--arrow--right--positive"></i>
						</button>
					</div>
				)}
			</div>
		);
	}
}

NumberFilterComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
	noFocus: PropTypes.bool,
	onBlur: PropTypes.func,
	goButtonHandler: PropTypes.func,
	inputMode: PropTypes.any,
	thousandSeparator: PropTypes.any,
	decimalSeparator: PropTypes.any,
};

export default NumberFilterComponent;
