import React, { Fragment } from 'react';
import { func, object, bool, string, array } from 'prop-types';
import { identity, isEmpty as checkIsEmpty, some } from 'lodash';

import { withContainer, itemTypes } from './../drag-and-drop';
import SectionActions from './section-actions';
import DraggableSectionInput from './draggable-section-input';

const dragStyle = {
	height: '66px',
	marginBottom: '24px',
	border: '1px dashed #c7ced5',
	backgroundColor: '#ffffff',
	borderRadius: '6px',
	overflow: 'hidden',
};

const draggableSections = ({
	item,
	isCanadian,
	items,
	onChange,
	connectDropTarget,
	connectDragSource,
	canDrop,
	draggedItemType,
	draggedItem,
	isPreview,
	onHoverField,
	isDisabled,
	sectionReferrer,
	isAdmin,
	disableAmountDropdown,
	renderAvailableFields,
	availableFields,
	hideDropdownOption,
	disableFieldOutsideDrag,
	createActionRef,
	renderOptions,
	isOpenOptionsModal,
	expandOptions,
	isGoPlus,
}) => {
	const {
		key: sectionKey,
		label: sectionLabel,
		isEdit,
		toggleEdit,
		inputRef,
		actions,
		isEmpty,
		setRef,
		expanded,
		disableRemove,
		internalSection,
		addFieldExpanded,
		hideAdd,
		hideDelete,
		disableDrag,
		hideEdit,
		hideRename,
		hideActions,
		isHidden,
		hideFieldLabel,
		disableLabelSave,
		hideOptionsPreview,
	} = item;
	if (internalSection && !isAdmin) return null;
	let fields = item.fields;

	if (isEmpty) {
		if (!checkIsEmpty(items) && draggedItemType !== itemTypes.CREATABLE_SECTION_ITEM) {
			return null;
		}
		const isDraggingNew = !some(items, item => item.key === (draggedItem && draggedItem.id));
		return connectDropTarget(
			<div className="cursor--default" style={isDraggingNew && canDrop ? dragStyle : { height: '66px' }}>
				&nbsp;
			</div>
		);
	}
	if (isHidden) return;
	return (
		<div ref={elem => sectionReferrer(item, elem)}>
			{connectDragSource(
				<div ref={!isPreview ? setRef : null}>
					<form className="paymentsite__card draggable" onSubmit={e => e.preventDefault()}>
						{connectDropTarget(
							<div className="paymentsite__card__header">
								{isEdit ? (
									<div className="inputgroup w--334p">
										<div className="inputgroup--main padd--right--sml">
											<input
												ref={inputRef}
												name={`${sectionKey}.label`}
												type="text"
												value={sectionLabel}
												onChange={onChange}
												disabled={isDisabled}
												className="input input--sml"
												tabIndex="-1"
											/>
										</div>
										<div className="inputgroup--aside">
											<button
												onClick={toggleEdit}
												className="btn btn--sml btn--primary"
												disabled={disableLabelSave(sectionLabel)}
											>
												Save
											</button>
										</div>
									</div>
								) : (
									<div className="flex--primary flex--nowrap">
										<h2 className="paymentsite__card__title" htmlFor={sectionKey}>
											{sectionLabel}
										</h2>
										{!hideRename && (
											<button
												className="btn btn--sml btn--clear"
												data-tooltip="Rename"
												type="button"
												onClick={toggleEdit}
											>
												<i className="icon icon--nano icon--edit-alt--grey cursor--pointer align--v--middle"></i>
											</button>
										)}
									</div>
								)}

								<SectionActions
									disableAll={isDisabled}
									actions={actions}
									addFieldExpanded={addFieldExpanded}
									availableFields={availableFields}
									renderAvailableFields={renderAvailableFields}
									label={sectionLabel}
									hideAdd={hideAdd}
									hideDelete={hideDelete}
									hideEdit={hideEdit}
									createActionRef={createActionRef}
								/>
							</div>
						)}
						{expanded && !isPreview && (
							<Fragment>
								<DraggableSectionInput
									isCanadian={isCanadian}
									items={fields}
									disable={isDisabled}
									sectionKey={sectionKey}
									onChange={onChange}
									isNested={true}
									onHover={onHoverField}
									disableRemove={disableRemove}
									expandOptions={expandOptions}
									isAdmin={isAdmin}
									isGoPlus={isGoPlus}
									disableAmountDropdown={disableAmountDropdown}
									disableDrag={disableDrag}
									hideEdit={hideEdit}
									hideDropdownOption={hideDropdownOption}
									hideActions={hideActions}
									hideFieldLabel={hideFieldLabel}
									hideOptionsPreview={hideOptionsPreview}
									sections={items}
									disableFieldOutsideDrag={disableFieldOutsideDrag}
									renderOptions={renderOptions}
									isOpenOptionsModal={isOpenOptionsModal}
								/>
							</Fragment>
						)}
					</form>
				</div>
			)}
		</div>
	);
};

draggableSections.defaultProps = {
	onChange: () => {},
	connectDropTarget: identity,
	connectDragSource: identity,
};

draggableSections.propTypes = {
	onChange: func,
	item: object,
	connectDropTarget: func,
	connectDragSource: func,
	canDrop: bool,
	draggedItemType: string,
	draggedItem: object,
	isDragging: bool,
	isPreview: bool,
	onHoverField: func,
	items: array,
	isDisabled: bool,
	sectionReferrer: func,
	expandOptions: func,
	createActionRef: func,
	disableFieldOutsideDrag: func,
	isCanadian: bool,
	disableAmountDropdown: bool.isRequired,
	isAdmin: bool.isRequired,
	isGoPlus: bool.isRequired,
	renderAvailableFields: func.isRequired,
	availableFields: array.isRequired,
	hideDropdownOption: bool.isRequired,
	renderOptions: func,
	isOpenOptionsModal: bool,
};

const mapItemToId = ({ key }) => key;

export default withContainer(draggableSections, mapItemToId, {
	dragStyle,
	useDefaultDrag: false,
	useDefaultDrop: false,
	dragType: itemTypes.SECTION_ITEM,
	dropType: [
		itemTypes.SECTION_ITEM,
		itemTypes.CREATABLE_SECTION_ITEM,
		itemTypes.CREATABLE_INPUT_ITEM,
		itemTypes.INPUT_ITEM,
	],
	dragOverride: ({ item, draggedItem, draggedItemType }) => {
		return draggedItem && draggedItemType === itemTypes.CREATABLE_SECTION_ITEM && item.key === draggedItem.id;
	},
});
