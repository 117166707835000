import React from 'react';
import { Auth, Hub } from 'aws-amplify';
import { find } from 'lodash';

import principalService from '../services/principalService';
import sendError from 'common/components/error/sendError';

let dba = '';
let email = '';

principalService.subscribe(updateDba);
Hub.listen('auth', updateEmail);

updateDba(principalService.get());
Auth.currentAuthenticatedUser()
	.then(data => {
		if (data) {
			updateEmail({ payload: { event: 'signIn', data } });
		}
	})
	.catch(() => {});

function updateDba(principal) {
	if (principal) {
		const activeKey = find(principal.list, ({ key }) => key === principal.id);
		dba = activeKey && activeKey.dba;
	} else {
		dba = '';
	}
}

function updateEmail({ payload: { event, data } }) {
	if (event === 'signIn') {
		email = (data && data.attributes && data.attributes.email) || '';
	} else if (event === 'signOut' || event === 'oAuthSignOut') {
		email = '';
	}
}

function getSource(error) {
	if (!error) {
		return;
	}
	let requestInfo;
	let responseInfo;
	if (error.request) {
		const { url, method } = error.request;
		requestInfo = {
			url,
			method,
		};
	}
	if (error.response) {
		const { status, statusText } = error.response;
		responseInfo = `${statusText} (${status})`;
	}
	if (error.isCanceled) {
		return {
			message: 'Canceled task not properly disposed of',
			requestInfo,
			responseInfo,
		};
	}
	if (error.message || error.stack) {
		const { message, stack, displayMessage } = error;
		return {
			message,
			stack,
			requestInfo,
			responseInfo,
			displayMessage,
		};
	}
	if (error.ex || error.error || error.reason) {
		const parsed = parseError(error.ex) || parseError(error.error) || parseError(error.reason);
		if (parsed) {
			const { error, message, displayMessage } = parsed;
			requestInfo = parsed.requestInfo || requestInfo;
			responseInfo = parsed.responseInfo || responseInfo;
			return {
				error,
				message,
				requestInfo,
				responseInfo,
				displayMessage,
			};
		}
	}
	return;
}

export function parseError(error) {
	return getSource(error) || {};
}

function getSubject() {
	return `Portal error${email || dba ? ` from ${email ? email : ''}${dba ? ` (${dba})` : ''}` : ''}`;
}

function transactionCustomErrorMessage(stack, additionalInfo) {
	sendError(
		'Something went wrong. Please reload and try again. Please check your transaction history to confirm if the transaction was processed successfully.',
		getSubject(),
		stack,
		additionalInfo
	);
	return (
		<span>
			Something went wrong. Please reload and try again. <br />
			Please check your transaction history to confirm if the transaction was processed successfully.
		</span>
	);
}

export function getMail(
	stack,
	additionalInfo = {},
	displayMessage = 'Something went wrong.\nPlease reload and try again',
	isNewTransaction = false
) {
	if (isNewTransaction && displayMessage === 'Something went wrong. Please reload and try again') {
		return transactionCustomErrorMessage(stack, additionalInfo);
	}
	sendError(displayMessage, getSubject(), stack, additionalInfo);
	return <span>{displayMessage}</span>;
}
