import React from 'react';
import { string, func } from 'prop-types';
import 'react-quill/dist/quill.snow.css';

class ColorPicker extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: props.value || '',
		};
	}

	onChange = e => {
		const { target } = e;
		this.setState({ value: target.value }, () =>
			this.props.onChange({
				target,
			})
		);
	};

	render() {
		const { value } = this.state;
		return (
			<div className="input--color-picker--wrapper">
				<input
					className="input--color-picker"
					type="color"
					value={value}
					name={this.props.name}
					onChange={this.onChange}
				/>
			</div>
		);
	}
}

ColorPicker.propTypes = {
	value: string,
	name: string,
	onChange: func,
};

export default ColorPicker;
