import React, { Component } from 'react';
import { func, arrayOf, object, shape, string } from 'prop-types';
import { find } from 'lodash';

class AddEditCustomReport extends Component {
	constructor(props) {
		super(props);
		let { report } = props;
		if (!report) {
			report = {};
		}
		const { name = '', key = '' } = report;
		this.state = {
			name,
			key,
			dirty: false,
		};
	}

	onChange = ({ target: { value, checked, type, name } }) => {
		const newValue = type === 'checkbox' ? checked : value;
		this.setState({
			[name]: newValue,
			dirty: true,
		});
	};

	onSave = saveAsNew => {
		this.setState({
			dirty: true,
		});
		const { onSave, filters, sortBy } = this.props;
		const { name, key } = this.state;
		if (!name) {
			return;
		}
		onSave(name, filters, sortBy, saveAsNew ? '' : key);
	};

	render = () => {
		const { name, key, dirty } = this.state;
		const { onCancel, filters } = this.props;
		const { onSave, onChange } = this;
		const dateFilter = find(filters, { key: 'date' });
		return (
			<form>
				<div className="type--med type--wgt--medium spc--top--med spc--left--med">Custom Saved Report</div>
				{!name && dirty && (
					<div className="w--max--328 type--color--warning spc--left--med spc--top--sml spc--right--med spc--bottom--med">
						Report name is required.
					</div>
				)}
				{dateFilter.values.key === 'custom' ? (
					<div className="w--max--328 type--color--warning spc--left--med spc--top--sml spc--right--med spc--bottom--med">
						You cannot save a report with a custom date range.
					</div>
				) : (
					<div className="w--436p w--max--100">
						<div className="popup__body">
							<div className="group--alt">
								<div className="pull w--102p spc--top--xsml">
									<label htmlFor="customReportName" className="type--wgt--medium">
										Report Name{' '}
										<span className="required-field label--required" data-tooltip="Required">
											*
										</span>
									</label>
								</div>
								<div className="push w--neg--108p">
									<input
										id="customReportName"
										className="input input--med"
										name="name"
										value={name}
										onChange={onChange}
									/>
								</div>
							</div>
						</div>
						<div className="popup__footer popup__footer--styled">
							<button type="button" className="btn btn--med btn--ghost" onClick={onCancel}>
								Cancel
							</button>
							{key && (
								<button
									type="button"
									className="btn btn--med btn--primary"
									onClick={() => onSave(true)}
								>
									Save As New
								</button>
							)}
							<button type="button" className="btn btn--med btn--primary" onClick={() => onSave(false)}>
								Save
							</button>
						</div>
					</div>
				)}
			</form>
		);
	};
}

AddEditCustomReport.propTypes = {
	onSave: func.isRequired,
	onCancel: func.isRequired,
	filters: arrayOf(object).isRequired,
	sortBy: string.isRequired,
	report: shape({
		name: string.isRequired,
		key: string.isRequired,
	}),
};

export default AddEditCustomReport;
