import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, each, unionBy, startsWith, toLower } from 'lodash';
import { Data } from 'react-data-grid-addons';

import { giftService } from '../../Common/services';
import { MainFilterComponent } from './../../Common/components/filter';
import {
	giftSummaryFilter as Filter,
	compileFilter,
} from '../../Common/components/gift-report/filter/giftSummaryFilter';
import { giftCardSummaryColumns, GridFooterComponent } from '../../Common/components/gift-report';
import { GridComponent } from './../../Common/components/grid';
import { withCancelable } from './../../Common/components/cancelable';
import { withError } from './../../Common/components/error';
import { exportService } from '../../Common/components/export/exportService';
import { ZebraRenderer } from '../../Common/components/row';

const requestKeys = {
	FETCH: 'fetch',
	LOAD_ALL: 'loadAll',
};

class GiftSummary extends Component {
	constructor(props) {
		super(props);

		const filters = unionBy(props.location.filters, Filter, 'key') || Filter;
		const activeFilters = props.activeFilters || cloneDeep(filters);

		this.state = {
			filters: filters,
			activeFilters: activeFilters,
			inlineFilters: {},
			data: null,
			filteredRows: [],
			fetchingData: true,
			fetchingAdditionalData: false,
			columns: cloneDeep(giftCardSummaryColumns),
			lastApiRefNum: null,
		};

		this.gridRef = React.createRef();

		this.components = {
			filter: MainFilterComponent,
			rowRenderer: ZebraRenderer,
			title: props.renderTitle,
			gridFooter: GridFooterComponent,
		};

		this.classes = {
			wrapper: '',
			header: 'header header__btn__holder',
			headerMenu: 'header__menu',
			headerGroup: 'flex flex--primary',
			title: 'header__title',
			print: 'giftprint__table',
		};
	}

	getSnapshotBeforeUpdate = prevProps => prevProps.location.key !== this.props.location.key;

	componentDidUpdate = (_, __, snapshot) => {
		if (snapshot) {
			if (this.gridRef.current) {
				this.gridRef.current.clearFilters();
			}
			this.refetchData();
		}
	};

	fetchData = async (filters, filterDateFormat) => {
		this.setState({
			fetchingData: true,
			data: null,
			filteredRows: [],
			lastApiRefNum: null,
		});
		let lastApiRefNum = null;
		const fields = giftService.getGiftCardSummaryFields();
		const parser = giftService.parseGiftCardSummaryResult;
		try {
			const filter = await this.props.makePendingRequest(compileFilter(filters, filterDateFormat), requestKeys.FETCH);
			const data = await this.props.makePendingRequest(
				giftService.filterGiftRequest(filter, null, fields, parser),
				requestKeys.FETCH
			);
			lastApiRefNum = data.xRefNum;

			this.mapData(data);
			const filteredRows =
				data && data.xReportData
					? Data.Selectors.getRows({
							rows: data.xReportData,
							filters: this.state.inlineFilters,
					  })
					: [];
			if (this.gridRef.current) {
				this.gridRef.current.scrollTo({ top: 0, left: 0 });
			}

			this.setState(
				{
					data,
					filteredRows,
					fetchingData: false,
					columns: this.state.columns,
					lastApiRefNum: lastApiRefNum,
				},
				() => {
					if (this.gridRef.current) {
						this.gridRef.current.handleInitialSort();
					}
				}
			);
		} catch (e) {
			const error = this.props.handleError(e, { delayMessage: true });
			if (error) {
				this.setState({
					fetchingData: false,
				});
				if (!startsWith(toLower(error.message), toLower('License Required'))) {
					error.show();
				}
			}
		}
	};

	fetchAllData = async (maxRecords = 1000) => {
		const fields = giftService.getGiftCardSummaryFields();
		const parser = giftService.parseGiftCardSummaryResult;
		try {
			const filters = await this.props.makePendingRequest(
				compileFilter(this.state.activeFilters, ApplicationSettings.apiDateTimeFormat),
				requestKeys.LOAD_ALL
			);
			const allData = await this.props.makePendingRequest(
				giftService.filterGiftRequest(filters, maxRecords > 1000 ? 1000 : maxRecords, fields, parser),
				requestKeys.LOAD_ALL
			);
			this.mapData(allData);
			return allData.xReportData;
		} catch (e) {
			this.props.handleError(e);
		}
	};

	resolveColumnName = column => {
		let key = column;
		switch (column) {
			case 'CardData':
				key = 'xMaskedCardNumber';
				break;
			case 'AmountData':
				key = 'xAmount';
				break;
			case 'RefNumData':
				key = 'xRefNum';
				break;
			case 'xEnteredDate':
				key = 'xEnteredDateMoment';
				break;
			default:
				break;
		}
		return key;
	};

	mapData = data => {
		let i = 0;
		if (data && data.xReportData && data.xReportData.length > 0) {
			each(data.xReportData, item => {
				// include gridRowNumber as a simple counter for zebra
				item.gridRowNumber = i;
				item.index = i + 1; // for initial sort
				i++;
			});
		}
	};

	handleChange = changes => {
		const newState = {};
		each(changes, ({ key, value }) => {
			if (key === 'data' || key === 'inlineFilters') {
				let filters, data;
				if (key === 'data') {
					filters = this.state.inlineFilters;
					data = value;
				} else {
					filters = value;
					data = this.state.data;
				}
				newState.filteredRows =
					data && data.xReportData
						? Data.Selectors.getRows({
								rows: data.xReportData,
								filters,
						  })
						: [];
			}
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	refetchData = () => {
		this.fetchData(this.state.activeFilters, ApplicationSettings.apiDateTimeFormat);
	};

	render = () => (
		<GridComponent
			emptyMessage="You should change your filter options"
			fetchingData={this.state.fetchingData}
			fetchingAdditionalData={this.state.fetchingAdditionalData}
			filteredRows={this.state.filteredRows}
			columns={this.state.columns}
			data={this.state.data}
			resolveColumnName={this.resolveColumnName}
			inlineFilters={this.state.inlineFilters}
			components={this.components}
			onChange={this.handleChange}
			enableExport={true}
			enablePrint={true}
			printTitle="Gift card summary"
			type="giftCardSummary"
			filters={this.state.filters}
			activeFilters={this.state.activeFilters}
			enableFilters={true}
			fetchData={this.refetchData}
			fetchAllData={this.fetchAllData}
			lastApiRefNum={this.state.lastApiRefNum}
			showResults={true}
			showPrintDropdown={false}
			ref={this.gridRef}
			useInlineFilters={true}
			fetchExportData={{
				current: exportService.mapGiftCardSummaryData,
				all: exportService.getGiftCardSummaryData,
			}}
			classes={this.classes}
		/>
	);
}

GiftSummary.propTypes = {
	makePendingRequest: PropTypes.func,
	activeFilters: PropTypes.any,
	location: PropTypes.object,
	handleError: PropTypes.func,
	renderTitle: PropTypes.func,
};

export default withError(withCancelable(GiftSummary));
