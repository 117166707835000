import React from 'react';
import { string, object, any } from 'prop-types';

import TooltipFormatter from './TooltipFormatter';

class PopupTooltipFormatterComponent extends TooltipFormatter {
	displayPopup = () => {
		this.cancelClose();
		const {
			dependentValues,
			row: { popupRef },
		} = this.props;

		const popup = (
			<div className={this.tooltipClass} onMouseEnter={this.cancelClose} onMouseLeave={this.closePopup}>
				{this.tooltip}
			</div>
		);
		const popupRect = popupRef.current.getBoundingClientRect();
		const rect = this.elementRef.current.getBoundingClientRect();
		dependentValues.onInfoHover(
			{
				width: (rect.left || 0) + (rect.width / 2 || 0) - (popupRect.left || 0),
				height: (rect.top || 0) - 20 - (popupRect.top || 0),
			},
			popup
		);
	};
}

PopupTooltipFormatterComponent.propTypes = {
	value: string.isRequired,
	dependentValues: object,
	row: object.isRequired,
	popupRef: any.isRequired,
};

export default PopupTooltipFormatterComponent;
