import React from 'react';

const GiftCard = () => (
	<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			opacity="0.1"
			d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
			fill="#2DBA67"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.3333 13V15.6655C15.9242 15.3647 15.4249 15.188 14.8858 15.188C14.2199 15.188 13.5813 15.4632 13.1104 15.953C12.1484 16.9537 12.1302 18.5644 13.0558 19.5882H10L10 14.5652C10 13.7008 10.6762 13 11.5068 13H16.3333ZM17.9167 13H27.4932C28.3254 13 29 13.6943 29 14.5652V19.5882H21.4658C22.3914 18.5644 22.3733 16.9537 21.4113 15.953C20.9404 15.4632 20.3017 15.188 19.6358 15.188C18.9707 15.188 18.3659 15.4571 17.9167 15.8963V13ZM29 21.2353V25.4348C29 26.2992 28.3238 27 27.4932 27H17.9167V21.9107C17.9551 22.0007 18.0097 22.085 18.0806 22.1587L20.3198 24.488C20.629 24.8096 21.1302 24.8096 21.4394 24.488C21.7485 24.1664 21.7485 23.645 21.4394 23.3234L19.4321 21.2353H29ZM16.3333 27H11.5068C10.6746 27 10 26.3057 10 25.4348V21.2353H15.3379L13.3306 23.3234C13.0215 23.645 13.0215 24.1664 13.3306 24.488C13.6398 24.8096 14.141 24.8096 14.4502 24.488L16.3333 22.5291V27ZM17.9167 21.2421V21.2353H17.9196C17.9186 21.2376 17.9176 21.2399 17.9167 21.2421ZM19.6358 16.8351C19.8818 16.8351 20.1177 16.9367 20.2917 17.1176C20.6539 17.4944 20.6539 18.1053 20.2917 18.4821C20.1177 18.6631 19.8818 18.7647 19.6358 18.7647H18.7083V17.7999C18.7083 17.267 19.1236 16.8351 19.6358 16.8351ZM14.8858 16.8351C15.3981 16.8351 15.8133 17.267 15.8133 17.7999V18.7647H14.8858C14.6398 18.7647 14.4039 18.6631 14.23 18.4821C13.8678 18.1053 13.8678 17.4944 14.23 17.1176C14.4039 16.9367 14.6398 16.8351 14.8858 16.8351Z"
			fill="#2DBA67"
		/>
	</svg>
);

export default GiftCard;
