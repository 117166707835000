import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FrequencyFilterComponent extends Component {
	constructor(props) {
		super(props);
		this.inputRef = React.createRef();
	}

	filterChange = (key, e) => {
		const value = e.target.value ? e.target.value : null;

		this.onFilterChange(key, value);
	};

	onFilterChange(key, value) {
		const { count, type } = this.props.filter.values;

		this.props.onFilterChanged({
			id: 'frequency',
			values: [
				{
					key: 'count',
					value: key === 'count' ? value : count,
				},
				{
					key: 'type',
					value: key === 'type' ? value : type,
				},
			],
			emptyValue: null,
		});
	}

	componentDidUpdate = () => {
		if (this.inputRef.current) {
			this.inputRef.current.focus();
		}
	};

	render() {
		const { filter, goButtonHandler } = this.props;
		const { count, type } = filter.values;

		return (
			<div className="display--f f--a--c">
				<label className="display--b spc--right--tny">Every</label>
				<input
					type="number"
					step="1"
					placeholder="1"
					value={count === null ? '' : count}
					className="input input--med w--42p spc--right--tny"
					id="count"
					onChange={e => this.filterChange('count', e)}
					ref={this.inputRef}
					autoFocus={true}
				/>
				<select
					value={type === null ? '' : type}
					id="type"
					className="input input--med input--select w--120p spc--right--tny"
					onChange={e => this.filterChange('type', e)}
					placeholder="Test"
				>
					<option value=""></option>
					<option value="day">Days</option>
					<option value="week">Weeks</option>
					<option value="month">Months</option>
					<option value="year">Years</option>
				</select>
				{goButtonHandler && (
					<button className="btn btn--med btn--primary" data-tooltip="Apply" onClick={goButtonHandler}>
						<i className="icon icon--nano icon--arrow--right--positive"></i>
					</button>
				)}
			</div>
		);
	}
}

FrequencyFilterComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
	goButtonHandler: PropTypes.func,
};

export default FrequencyFilterComponent;
