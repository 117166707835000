import React from 'react';
import PropTypes from 'prop-types';

const logo = () => (
	<svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g>
			<path
				d="M9.8 12.8L9.5 12.2C9.2 11.7 9.2 11 9.5 10.5L10.1 9.49998L12.1 12.8H9.8ZM13.6 2.89998L9.3 9.69998C9.2 9.79998 9.1 9.99998 9 10.2C8.2 11.5 7.3 13 5 13C4.3 13 3.7 12.8 3.1 12.5C2.6 12.2 2.1 11.7 1.8 11.1C1.5 10.5 1.4 9.69998 1.4 8.99998C1.4 8.29998 1.5 7.49998 1.8 6.89998C2.1 6.29998 2.5 5.79998 3.1 5.39998C3.7 5.09998 4.4 4.89998 5 4.89998C6.1 4.89998 7.2 5.39998 7.9 6.19998L8 6.29998L6.9 7.99998L6.8 7.79998C6.5 7.19998 5.9 6.69998 5.2 6.59998H5.1C4.1 6.59998 3.6 7.39998 3.6 8.99998C3.6 9.59998 3.7 10.2 4 10.7C4.3 11 4.7 11.2 5.1 11.2C6 11.2 6.5 10.3 6.9 9.59998C7 9.59998 7 9.49998 7.1 9.49998L11.3 2.89998C9.7 2.59998 8.3 1.59998 7.4 0.199984C7.2 -1.62125e-05 6.9 -0.100016 6.6 0.0999838L6.5 0.199984C4.6 2.99998 1.8 3.29998 0.7 2.89998C0.4 2.79998 0.1 2.99998 0 3.19998V3.29998V7.99998C0 14.6 6.8 16.5 6.8 16.5H6.9H7C7.1 16.5 13.8 14.6 13.8 7.99998V3.39998C13.9 3.19998 13.8 2.99998 13.6 2.89998Z"
				fill="#231F20"
			/>
		</g>
		<defs>
			<clipPath id="clip0">
				<rect width="13.9" height="16.7" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

logo.propTypes = {
	width: PropTypes.any,
};

export default logo;
