import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';

import { UserAccountPanel } from 'common/components/user-account-panel';
import { TerminalOnlyUpgradePlanFakeGrid } from './TerminalOnlyUpgradePlanFakeGrid';
import { get } from 'lodash';

class TerminalOnlyUpgradePlan extends Component {
	openPlansPage = () => {
		this.props.history.push({ pathname: '/upgrade-plan' });
	};

	render() {
		const { isCustomerBilling, hideHeader, location } = this.props;

		return (
			<div>
				{!hideHeader && (
					<div className="header">
						<div className="header__menu">
							<UserAccountPanel />
						</div>
					</div>
				)}
				<div className="terminal-only">
					<div className="terminal-only__content">
						<div className="spc--bottom--med">
							<h1 className="type--wgt--light">Your account doesn't support</h1>
							<h1>
								{(location && get(location, 'state.hasCustomerBilling') === true) || isCustomerBilling
									? 'Customers and recurring billing'
									: 'this feature'}
							</h1>
						</div>
						<button
							className="btn btn--med btn--primary"
							onMouseLeave={this.setGrumpyFace}
							onMouseEnter={this.setHappyFace}
							onClick={this.openPlansPage}
						>
							Upgrade plan
						</button>
					</div>
					<div className="terminal-only__person terminal-only__locked"></div>
					<TerminalOnlyUpgradePlanFakeGrid />
				</div>
			</div>
		);
	}
}

TerminalOnlyUpgradePlan.propTypes = {
	isCustomerBilling: PropTypes.bool,
	hideHeader: PropTypes.bool,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

export default withRouter(TerminalOnlyUpgradePlan);
