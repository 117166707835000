import { map, clone, each, get, join, uniq, split } from 'lodash';
import moment from 'moment';

import httpService from './httpService';
import { apiToLocalMoment } from '../utilities';
import principalService from './principalService';
import updateApiEndpoint from 'common/utilities/UpdateApiEndpoint';

const { apiDateTimeFormat, apiResponseDateTimeFormat } = ApplicationSettings;

function generateCustomFields(num) {
	let customFields = [];
	for (let i = 1; i <= num; ++i) {
		customFields.push('xCustom' + i.toString().padStart(2, '0'));
	}
	return customFields.join(',');
}

const giftFields = 'xAmount,xLocation';
const giftReportFields = 'xRefNum,xEnteredDate,xMaskedCardNumber,xCommand,xInvoice,xName';
const giftCardSummaryFields = 'xParentLocation,xTransactionCount';
let giftCardLiabilityFields = 'xMaskedCardNumber,xRemainingBalance,xFirstName,xLastName,xEmail';
const customFields = generateCustomFields(1);

let principal = principalService.get();
principalService.subscribe(data => {
	principal = data;
	const giftReportLiabilityIncludeActivationStatus = get(
		principal,
		'idInfo.xGiftReportLiabilityIncludeActivationStatus'
	);

	if (giftReportLiabilityIncludeActivationStatus) {
		giftCardLiabilityFields = 'xMaskedCardNumber,xRemainingBalance,xFirstName,xLastName,xEmail,xActivationStatus';
	}
});

class GiftService {
	constructor(httpService) {
		this.httpService = httpService;
	}

	getGiftReportFields = () => {
		return [giftFields, giftReportFields, customFields].join(',');
	};

	getGiftCardSummaryFields = () => {
		return [giftFields, giftCardSummaryFields].join(',');
	};

	getGiftCardLiabilityFields = () => {
		return [giftCardLiabilityFields].join(',');
	};

	filterGiftRequest = async (filter, maxRecords = 1000, fields = null, parser = null, skipRouteGateway = false) => {
		filter = clone(filter);

		if (fields !== null && fields.length > 0) {
			filter.xFields = join(uniq(split(fields, ',')), ',');
		} else {
			filter.xFields = this.getGiftReportFields();
		}

		if (!parser) {
			parser = this.parseGiftReportResult;
		}

		if (maxRecords !== null) {
			filter.xMaxRecords = maxRecords;
		}
		filter.xGetNewest = true;
		const result = await this.httpService.post(updateApiEndpoint('report', skipRouteGateway), filter);

		return await this.parseResult(result, parser, filter.xMaxRecords);
	};

	filterGiftCardReportUrlRequest = async (signedReportUrl, parser = null) => {
		if (!parser) {
			parser = this.parseGiftReportResult;
		}

		const result = await this.httpService.get(signedReportUrl);
		return { xReportData: await Promise.all(map(result, parser)) };
	};

	getRemainingBalance = async xCardnum => {
		const filter = {
			xCommand: 'Gift:Balance',
			xCardnum,
		};
		const result = await this.httpService.post(updateApiEndpoint('gateway'), filter);

		return await this.parseResult(result, null);
	};

	parseResult = async (result, parser, xMaxRecords) => {
		if (result) {
			result.xReportingMaxTransactions = parseInt(xMaxRecords);
			result.xRecordsReturned = parseInt(result.xRecordsReturned);
			result.xReportData = await Promise.all(map(result.xReportData, parser));
		}
		return result;
	};

	parseGiftReportResult = async item => {
		item.xEnteredDate = await apiToLocalMoment(item.xEnteredDate, ApplicationSettings.apiResponseDateTimeFormat);
		item.xAmount = parseFloat(item.xAmount);
		item.xMaskedCardNumber = `****${item.xMaskedCardNumber.slice(-7)}`;
		return item;
	};

	parseGiftCardSummaryResult = async item => {
		item.xAmount = parseFloat(item.xAmount);
		item.xTransactionCount = parseInt(item.xTransactionCount);
		return item;
	};

	parseGiftCardLiabilityResult = async item => {
		item.xRemainingBalance = parseFloat(item.xRemainingBalance);
		item.xCardType = 'Gift'; // fix for credit card display component

		if (item.xMaskedCardNumber.indexOf('x') > -1) {
			item.xMaskedCardNumber = `****${item.xMaskedCardNumber.slice(-4)}`;
		}

		return item;
	};

	getMinDateFromData = data => {
		let refNums = [];
		let foundDate = null;
		each(data, ({ xEnteredDate, xRefNum }) => {
			const date = moment(xEnteredDate, apiResponseDateTimeFormat);
			if (!foundDate || date.isAfter(foundDate)) {
				foundDate = date;
				refNums = [xRefNum];
			} else if (date.isSame(foundDate)) {
				refNums.push(xRefNum);
			}
		});

		return { foundDate, refNums };
	};

	getNewStartEndDates = (endDate, data) => {
		const { foundDate, refNums } = this.getMinDateFromData(data);
		let start = foundDate;
		let end = moment(endDate, apiDateTimeFormat);

		return {
			start,
			end,
			refNums,
		};
	};
}

const giftService = new GiftService(httpService);

export default giftService;
