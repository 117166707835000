export default function setDelightedStyles() {
	const collapseExpandDelighted = ({ target }) => {
		target.classList.toggle('is-expanded');
	};

	const delightedMainDiv =
		document.getElementsByClassName('delighted-web-survey')[0] ||
		document.getElementsByClassName('delighted-custom')[0];

	if (delightedMainDiv) {
		delightedMainDiv.style = null;
		document.querySelector('*[id^="delighted-web"]').removeAttribute('id');
		const textArea = document.getElementsByClassName('delighted-web-comment-box')[0];
		textArea.style = null;

		delightedMainDiv.classList.add('delighted-custom');
		delightedMainDiv.classList.remove('delighted-web-survey');
		delightedMainDiv.onmouseenter = e => collapseExpandDelighted(e);
		delightedMainDiv.onmouseleave = e => collapseExpandDelighted(e);
		delightedMainDiv.style['transition'] = 'all 250ms ease-in-out';
	}
}
