import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class FieldManagementHeader extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Fragment>
				<div className="spr__heading--secondary">
					<h4 className="type--sml--alt type--wgt--bold type--uppercase type--color--text--light">
						{this.props.sectionName}
					</h4>
				</div>
				<div>
					<div className="spc--bottom--med hide--to--med--block">
						<div className="row">
							<div className="col col-lrg-3">
								<div className="type--base type--wgt--bold">Label</div>
							</div>
							<div className="col col-lrg-5">
								<div className="type--base type--wgt--bold">Custom Display Label</div>
							</div>
							<div className="col col-lrg-4">
								<div className="row">
									<div className="col col-sml-6">
										<div className="type--base type--wgt--bold type--center">Required</div>
									</div>
									<div className="col col-sml-6">
										<div className="type--base type--wgt--bold type--center">Visible</div>
									</div>
								</div>
							</div>
						</div>
						<hr className="separator separator--grey1" />
					</div>
				</div>
			</Fragment>
		);
	}
}

export default FieldManagementHeader;

FieldManagementHeader.propTypes = {
	sectionName: PropTypes.string.isRequired,
};
