import { principalService } from 'common/services';
import { difference, map, split, toLower } from 'lodash';
import { hasFeaturePackage, featurePackages } from './has-feature-package';

export const getMyPlan = () => {
	const principal = principalService.get();
	const principalFeaturePackages =
		principal && principal.idInfo && map(split(principal.idInfo.xFeaturePackage, ','), item => toLower(item));

	if (hasFeaturePackage(featurePackages.terminalOnly)) {
		return plans.terminalOnly;
	} else if (
		!difference(
			[
				featurePackages.nonTerminal,
				featurePackages.customerBilling,
				featurePackages.intelligentRouting,
				featurePackages.emv,
			],
			principalFeaturePackages
		)
	) {
		return plans.premium;
	} else if (!difference([featurePackages.nonTerminal, featurePackages.customerBilling], principalFeaturePackages)) {
		return plans.standard;
	} else {
		return plans.starter;
	}
};

export const plans = {
	terminalOnly: {
		planName: 'Terminal-Only',
		setupFee: 'Free',
		monthlyFee: 'Free',
		transactionFee: 'Free',
	},
	starter: {
		planName: 'Starter',
		setupFee: '$99.99',
		monthlyFee: '$19.99',
		transactionFee: '$0.1',
	},
	standard: {
		planName: 'Standard',
		setupFee: '$99.99',
		monthlyFee: '$29.99',
		transactionFee: '$0.1',
	},
	premium: {
		planName: 'Premium',
		setupFee: '$99.99',
		monthlyFee: '$49.99',
		transactionFee: '$0.1',
	},
};
