import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { filter, isEmpty, each } from 'lodash';
import { Redirect } from 'react-router-dom';

import { TransactionsSortBy } from '../../../Common/sorters/transactions-sort-options';
import { withError } from '../../../Common/components/error';
import { withCancelable } from '../../../Common/components/cancelable';
import { isComponent } from '../../../Common/utilities';

class QuickReportsFilterComponent extends Component {
	constructor(props) {
		super(props);

		this.reportRefs = {};

		this.state = {
			redirect: false,
			overFlowingReportRefs: {},
		};
	}

	componentDidUpdate = prevProps => {
		const { reports, isLoading } = this.props;
		if (prevProps.reports !== reports || prevProps.isLoading !== isLoading) {
			const overFlowingReportRefs = {};
			each(this.reportRefs, (_, key) => {
				overFlowingReportRefs[key] =
					this.reportRefs[key].current &&
					this.reportRefs[key].current.offsetWidth !== this.reportRefs[key].current.scrollWidth;
			});
			this.setState({ overFlowingReportRefs });
		}
	};

	getOrCreateRef = key => {
		if (!this.reportRefs[key]) {
			this.reportRefs[key] = createRef();
		}
		return this.reportRefs[key];
	};

	setSort = sortBy => {
		const options = TransactionsSortBy;
		const selectedItem = filter(options, { key: sortBy })[0];
		this.props.onSortUpdate(selectedItem);
	};

	setFilters = report => {
		this.setSort(report.sortBy);

		if (report.date === 'custom') {
			const {
				filters: {
					date: { startDate, endDate },
				},
			} = report;

			this.props.setCustomDaySelection({
				from: startDate.toDate(),
				to: endDate.toDate(),
			});
		} else {
			this.props.setDaySelection(report.date);
		}

		return this.props.setFilters(report);
	};

	handleEdit = async ({ fetchReport }) => {
		this.props.resetFilters(true);
		try {
			const report = await this.props.makePendingRequest(fetchReport());
			this.setFilters(report);
			this.props.handleEdit(report);
			if (this.props.topRef && this.props.topRef.current) {
				this.props.topRef.current.scrollIntoView({ behavior: 'smooth' });
			}
		} catch (e) {
			this.props.handleError(e);
		}
	};

	handleView = async ({ fetchReport }) => {
		this.props.resetFilters();
		try {
			const report = await this.props.makePendingRequest(fetchReport());
			await this.setFilters(report);
			this.setState({
				redirect: true,
			});
		} catch (e) {
			this.props.handleError(e);
		}
	};

	handleDownload = async ({ fetchReport }) => {
		try {
			const report = await this.props.makePendingRequest(fetchReport());
			this.props.onQuickReportDownload(report);
		} catch (e) {
			this.props.handleError(e);
		}
	};

	handleDelete = report => {
		this.props.openDeleteModal(report);
	};

	render = () => {
		let output = [];
		let index = 0;
		const { overFlowingReportRefs } = this.state;
		const { reports, isLoading, renderEmptyView, error } = this.props;
		if (isLoading) {
			return (
				<div className="loader__holder">
					<div className="loader__spinner"></div>
				</div>
			);
		}
		if (error) {
			return <div className="spc--bottom--med type--color--warning">{error}</div>;
		}
		if (isEmpty(reports)) {
			return renderEmptyView();
		}
		for (let report of reports) {
			const reportElement = (
				<Fragment>
					{report.key ? (
						<div data-tooltip={overFlowingReportRefs[report.key] ? report.name : null}>
							<p ref={this.getOrCreateRef(report.key)} className="type--wgt--medium type--ellipsis">
								{report.name}
							</p>
						</div>
					) : (
						<div data-tooltip={overFlowingReportRefs[report.name.split(' ').join('')] ? report.name : null}>
							<p
								className="type--wgt--medium type--ellipsis"
								ref={this.getOrCreateRef(report.name.split(' ').join(''))}
							>
								{report.name}
							</p>
						</div>
					)}
					<div className="type--sml type--color--text--light">{report.dateFilter && report.dateFilter.description}</div>
				</Fragment>
			);

			output.push(
				<li key={index} className="reports__list__item">
					{report && report.actions && report.actions.view ? (
						<a
							href="javascript:void(0)"
							onClick={() => this.handleView(report)}
							className="reports__list__link datatooltip--custom-report-name"
						>
							{reportElement}
						</a>
					) : (
						<div className="reports__list__link">{reportElement}</div>
					)}

					<div className="reports__list__action">
						{report.actions.view && (
							<button
								type="button"
								onClick={() => this.handleView(report)}
								className="btn btn--outline reports__list__btn spc--right--xsml"
								data-tooltip="View"
							>
								<i className="icon icon--tiny icon--view align--v--middle"></i>
							</button>
						)}
						{report.actions.download && (
							<button
								type="button"
								onClick={() => this.handleDownload(report)}
								className="btn btn--outline reports__list__btn spc--right--xsml"
								data-tooltip="Export"
							>
								<i className="icon icon--tiny icon--download align--v--middle"></i>
							</button>
						)}
						{report.actions.edit && (
							<button
								type="button"
								onClick={() => this.handleEdit(report)}
								className="btn btn--outline reports__list__btn spc--right--xsml"
								data-tooltip="Edit"
							>
								<i className="icon icon--tiny icon--edit align--v--middle"></i>
							</button>
						)}
						{report.actions.delete && (
							<button
								type="button"
								onClick={() => this.handleDelete(report)}
								className="btn btn--outline reports__list__btn"
								data-tooltip="Delete"
							>
								<i className="icon icon--tiny icon--wastebasket align--v--middle"></i>
							</button>
						)}
					</div>
				</li>
			);
			index++;
		}

		if (this.state.redirect) {
			return (
				<Redirect
					to={{
						pathname: '/transactions',
						filters: this.props.filters,
						initialRecordsLimit: this.props.initialRecordsLimit,
						initialSortBy: this.props.transactionsSortBy,
						ignoreDefaultFilterValues: true,
					}}
				/>
			);
		} else {
			return output;
		}
	};
}

QuickReportsFilterComponent.propTypes = {
	filters: PropTypes.array,
	reports: PropTypes.array,
	initialRecordsLimit: PropTypes.number,
	onFilterChanged: PropTypes.func,
	setDaySelection: PropTypes.func,
	setCustomDaySelection: PropTypes.func,
	resetFilters: PropTypes.func,
	transactionsSortBy: PropTypes.object,
	onSortUpdate: PropTypes.func,
	onQuickReportDownload: PropTypes.func,
	setFilters: PropTypes.func,
	isLoading: PropTypes.bool,
	openDeleteModal: PropTypes.func,
	handleEdit: PropTypes.func,
	topRef: PropTypes.object,
	renderEmptyView: isComponent,
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	error: PropTypes.string,
};

QuickReportsFilterComponent.defaultProps = {
	filters: [],
	onFilterChanged: () => {
		//eslint-disable-next-line
		console.warning('Provide onFilterUpdate method');
	},
	renderEmptyView: () => null,
};

export default withCancelable(withError(QuickReportsFilterComponent));
