export { default as LoginComponent } from './login';
export { default as RegisterComponent } from './register';
export { default as ForgotPasswordComponent } from './forgot-password';
export { default as LogoutComponent } from './logout';
export { default as ConfirmRegistrationComponent } from './confirm-registration';
export { default as ConfirmNewPasswordComponent } from './confirm-new-password';
export { default as ChangePasswordComponent } from './change-password';
export { default as TermsAndConditionsModal } from './terms-and-conditions';
export { default as Security } from './Security';
export { default as ConfirmMFAComponent } from './confirm-mfa';
