import React, { Component, createRef } from 'react';
import { string, func, object } from 'prop-types';
import { cloneDeep, startsWith, toLower, concat, filter, includes, isEmpty, clone, map, each } from 'lodash';
import moment from 'moment';
import { Data } from 'react-data-grid-addons';

import { GridComponent } from 'common/components/grid';
import { exportService } from 'common/components/export/exportService';
import { getFilter } from 'common/utilities';
import { giftActivityFilter as Filter, compileFilter } from 'common/components/gift-report/filter/giftActivityFilter';
import { giftActivityColumns } from 'common/components/gift-report';
import { ZebraRenderer } from 'common/components/row';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { giftService } from 'common/services';

const requestKeys = {
	FETCH: 'fetch',
	LOAD_ALL: 'loadAll',
};

const { apiDateTimeFormat, apiResponseDateTimeFormat, displayDateTimeFormat } = ApplicationSettings;

class GiftActivityBreakdown extends Component {
	constructor(props) {
		super(props);
		const filters = cloneDeep(Filter);
		const cardFilter = getFilter(filters, 'giftCardNumber');
		cardFilter.values.giftCardNumber = props.giftCardNumber;
		const activeFilters = cloneDeep(filters);
		this.state = {
			filters: filters,
			activeFilters: activeFilters,
			inlineFilters: {},
			data: null,
			originalData: null,
			filteredRows: [],
			fetchingData: true,
			fetchingAdditionalData: false,
			columns: cloneDeep(
				filter(giftActivityColumns, ({ key }) => !includes(['xMaskedCardNumber', 'xInvoice', 'xName'], key))
			),
			initialRecordsLimit: 20,
			loadMoreLimit: 50,
			lastApiRefNum: null,
			maskedCardNumber: '',
			xMaskedCardNumber: '',
			remainingBalance: '',
			error: '',
		};

		this.gridRef = createRef();

		this.components = {
			rowRenderer: ZebraRenderer,
			gridHeader: this.renderGridHeader,
			header: this.renderGridHeader,
		};

		this.classes = {
			filter: '',
			gridHeader: '',
			header: '',
		};
	}

	fetchData = async (filters, filterDateFormat, maxRecords = 1000) => {
		this.setState({
			fetchingData: true,
			data: null,
			filteredRows: [],
			lastApiRefNum: null,
		});
		let lastApiRefNum = null;
		const fields = giftService.getGiftReportFields();
		const parser = giftService.parseGiftReportResult;
		try {
			const filter = await this.props.makePendingRequest(compileFilter(filters, filterDateFormat), requestKeys.FETCH);
			const data = await this.props.makePendingRequest(
				giftService.filterGiftRequest(filter, maxRecords > 1000 ? 1000 : maxRecords, fields, parser),
				requestKeys.FETCH
			);
			lastApiRefNum = data.xRefNum;

			this.mapData(data);
			const filteredRows =
				data && data.xReportData
					? Data.Selectors.getRows({
							rows: data.xReportData,
							filters: this.state.inlineFilters,
					  })
					: [];
			if (this.gridRef.current) {
				this.gridRef.current.scrollTo({ top: 0, left: 0 });
			}

			this.setState(
				{
					data,
					filteredRows,
					fetchingData: false,
					columns: this.state.columns,
					lastApiRefNum: lastApiRefNum,
				},
				() => {
					if (this.gridRef.current) {
						this.gridRef.current.handleInitialSort();
					}
				}
			);
		} catch (e) {
			const error = this.props.handleError(e, { delayMessage: true });
			if (error) {
				this.setState({
					fetchingData: false,
				});
				if (!startsWith(toLower(error.message), toLower('License Required'))) {
					error.show();
				}
			}
		}
	};

	fetchAllData = async (maxRecords = 1000) => {
		const fields = giftService.getGiftReportFields();
		const parser = giftService.parseGiftReportResult;
		try {
			const filters = await this.props.makePendingRequest(
				compileFilter(this.state.activeFilters, apiDateTimeFormat),
				requestKeys.LOAD_ALL
			);
			const allData = await this.props.makePendingRequest(
				giftService.filterGiftRequest(filters, maxRecords > 1000 ? 1000 : maxRecords, fields, parser),
				requestKeys.LOAD_ALL
			);
			this.mapData(allData);
			return allData.xReportData;
		} catch (e) {
			this.props.handleError(e);
		}
	};

	combineData = (baseData, additionalData, refNums) => {
		baseData.xReportData = concat(baseData.xReportData, additionalData.xReportData);
		baseData.xRecordsReturned += additionalData.xRecordsReturned - refNums.length;
		baseData.xReportingMaxTransactions += additionalData.xReportingMaxTransactions - refNums.length;
	};

	loadMore = async () => {
		const { loadMoreLimit, data, originalData, activeFilters } = this.state;
		const hasData = originalData && originalData.xReportData && originalData.xReportData.length > 0;

		if (hasData) {
			const filters = cloneDeep(activeFilters);
			const dateFilter = find(filters, { key: 'date' });
			const { start, end, refNums } = giftService.getNewStartEndDates(
				dateFilter.values.startDate,
				originalData.xReportData
			);
			dateFilter.values.startDate = start;
			dateFilter.values.endDate = end;
			let compiledFilter;
			try {
				compiledFilter = await this.props.makePendingRequest(
					compileFilter(filters, apiDateTimeFormat),
					requestKeys.LOAD_MORE
				);
			} catch (e) {
				this.props.handleError(e, { additionalInfo: { dateFilter: dateFilter.values, apiDateTimeFormat } });
				return;
			}
			if (compiledFilter.xBeginDate && compiledFilter.xEndDate) {
				this.setState({
					fetchingAdditionalData: true,
					lastApiRefNum: null,
				});

				try {
					const pendingData = await this.props.makePendingRequest(
						giftService.filterGiftRequest(compiledFilter, Math.min(loadMoreLimit + refNums.length, 1000)),
						requestKeys.LOAD_MORE
					);
					if (
						loadMoreLimit === 1000 &&
						pendingData.xReportData &&
						pendingData.xReportData.length === 1000 &&
						compiledFilter.xBeginDate
					) {
						const {
							start: additionalStart,
							end: additionalEnd,
							refNums: additionalRefNums,
						} = giftService.getNewStartEndDates(dateFilter.values.startDate, pendingData.xReportData);
						dateFilter.values.startDate = moment(additionalStart, apiDateTimeFormat);
						dateFilter.values.endDate = moment(additionalEnd, apiDateTimeFormat);
						const additionalFilter = await this.props.makePendingRequest(
							compileFilter(filters, apiDateTimeFormat),
							requestKeys.LOAD_MORE
						);
						const additionalData = await this.props.makePendingRequest(
							giftService.filterGiftRequest(additionalFilter, refNums.length + additionalRefNums.length),
							requestKeys.LOAD_MORE
						);
						additionalData.xReportData = filter(
							additionalData.xReportData,
							({ xRefNum }) => !includes(additionalRefNums, xRefNum)
						);
						this.combineData(pendingData, additionalData, additionalRefNums);
					}
					pendingData.xReportData = filter(pendingData.xReportData, ({ xRefNum }) => !includes(refNums, xRefNum));
					if (!isEmpty(pendingData.xReportData)) {
						const updatedData = clone(data);
						this.combineData(updatedData, pendingData, refNums);
						updatedData.xReportData = map(updatedData.xReportData, this.mapRow);
						if (!loadMoreLimit) {
							updatedData.xReportingMaxTransactions += 1;
						}
						this.mapData(updatedData);
						const filteredRows =
							updatedData && updatedData.xReportData
								? Data.Selectors.getRows({
										rows: updatedData.xReportData,
										filters: this.state.inlineFilters,
								  })
								: [];
						this.setState(
							{
								originalData: cloneDeep(updatedData),
								data: updatedData,
								filteredRows,
								fetchingAdditionalData: false,
								lastApiRefNum: pendingData.xRefNum,
							},
							() => {
								if (this.gridRef.current) {
									this.gridRef.current.handleInitialSort();
									this.gridRef.current.calculateColumnWidths();
								}
							}
						);
					} else {
						this.setState({
							fetchingAdditionalData: false,
							lastApiRefNum: pendingData.xRefNum,
						});
					}
				} catch (e) {
					if (this.props.handleError(e, { additionalInfo: { compiledFilter } })) {
						this.setState({
							fetchingAdditionalData: false,
						});
					}
				}
			}
		}
	};

	onLoadMoreLimitChange = value => {
		this.setState(
			{
				loadMoreLimit: value,
			},
			() => {
				this.loadMore();
			}
		);
	};

	resolveColumnName = column => {
		let key = column;
		switch (column) {
			case 'CardData':
				key = 'xMaskedCardNumber';
				break;
			case 'AmountData':
				key = 'xAmount';
				break;
			case 'RefNumData':
				key = 'xRefNum';
				break;
			case 'xEnteredDate':
				key = 'xEnteredDateMoment';
				break;
			default:
				break;
		}
		return key;
	};

	mapData = data => {
		let i = 0;
		if (data && data.xReportData && data.xReportData.length > 0) {
			each(data.xReportData, item => {
				const clonedItem = clone(item);

				clonedItem.xEnteredDateMoment = moment(clonedItem.xEnteredDate, apiResponseDateTimeFormat);
				item.xEnteredDate = clonedItem.xEnteredDateMoment.format(displayDateTimeFormat);
				item.xEnteredDateMoment = clonedItem.xEnteredDateMoment;

				if (clonedItem && clonedItem.xMaskedCardNumber && clonedItem.xMaskedCardNumber.includes('xxx')) {
					item.xMaskedCardNumber = clonedItem.xMaskedCardNumber.replace(/x/g, '');
				}
				item.onClick = () => this.showBreakdown(item.xMaskedCardNumber);

				// include gridRowNumber as a simple counter for zebra
				item.gridRowNumber = i;
				item.index = i + 1; // for initial sort
				i++;
			});
		}
	};

	handleChange = changes => {
		const newState = {};
		each(changes, ({ key, value }) => {
			if (key === 'data' || key === 'inlineFilters') {
				let filters, data;
				if (key === 'data') {
					filters = this.state.inlineFilters;
					data = value;
				} else {
					filters = value;
					data = this.state.data;
				}
				newState.filteredRows =
					data && data.xReportData
						? Data.Selectors.getRows({
								rows: data.xReportData,
								filters,
						  })
						: [];
			}
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	refetchData = () => {
		this.setState({ loadMoreLimit: this.state.initialRecordsLimit }, () =>
			this.fetchData(this.state.activeFilters, apiDateTimeFormat, this.state.initialRecordsLimit)
		);
	};

	renderGridHeader = () => {
		return <div className="filter__container__header__item">Gift Card activity breakdown</div>;
	};
	render() {
		return (
			<GridComponent
				emptyMessage="You should change your filter options"
				fetchingData={this.state.fetchingData}
				fetchingAdditionalData={this.state.fetchingAdditionalData}
				filteredRows={this.state.filteredRows}
				columns={this.state.columns}
				data={this.state.data}
				resolveColumnName={this.resolveColumnName}
				inlineFilters={this.state.inlineFilters}
				components={this.components}
				onChange={this.handleChange}
				enableExport={true}
				enablePrint={true}
				printTitle="Gift Card activity breakdown"
				type="giftActivityBreakdown"
				showPanel={false}
				filters={this.state.filters}
				activeFilters={this.state.activeFilters}
				enableFilters={false}
				fetchData={this.refetchData}
				fetchAllData={this.fetchAllData}
				lastApiRefNum={this.state.lastApiRefNum}
				showResults={true}
				showPrintDropdown={false}
				ref={this.gridRef}
				useInlineFilters={false}
				fetchExportData={{
					current: exportService.mapGiftActivityData,
					all: exportService.getGiftActivityData,
				}}
				classes={this.classes}
				printComponents={this.printComponents}
				headerMenuThreshold={0}
			/>
		);
	}
}

GiftActivityBreakdown.propTypes = {
	makePendingRequest: func,
	location: object,
	handleError: func,
	giftCardNumber: string,
};

export default withError(withCancelable(GiftActivityBreakdown));
