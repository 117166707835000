import moment from 'moment';
import { find, trim } from 'lodash';

import { getDate, localToApiMoment, createFilters, getFilterValues } from 'common/utilities';
import { NumberFilter, LastDigitsFilter, StringFilter, SelectFilter } from 'common/components/filters';
import { SingleSelection, SelectSelection } from 'common/components/selections';

const { checkDateFormat } = ApplicationSettings;

export const fraudFilter = createFilters([
	{
		key: 'date',
		getSelectionText: ({ key, startDate, endDate }, predefinedDates) => {
			const predefinedDate = find(predefinedDates, { key });
			if (predefinedDate) {
				return predefinedDate.displayValue;
			}
			try {
				return `${startDate.format(checkDateFormat)} - ${endDate.format(checkDateFormat)}`;
			} catch (e) {
				return 'Custom';
			}
		},
		values: {
			key: 'today',
			startDate: moment().startOf('day'),
			endDate: moment().endOf('day'),
		},
		props: {
			maxDaysRange: 100,
		},
	},
	{
		key: 'amount',
		name: 'Amount',
		values: {
			amount: null,
		},
		props: {
			prefix: '$',
		},
		component: NumberFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'cardNumber',
		name: 'Card number',
		values: {
			cardNumber: null,
		},
		props: {
			numberOfDigits: 4,
		},
		component: LastDigitsFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'cardholderName',
		name: 'Cardholder Name',
		values: {
			cardholderName: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'referenceNumber',
		name: 'Reference Number',
		values: {
			referenceNumber: '',
		},
		component: NumberFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'invoice',
		name: 'Invoice',
		values: {
			invoice: '',
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'fraudStatus',
		name: 'Status',
		values: {
			guaranteed: false,
			notguaranteed: false,
			notguaranteedLowRisk: false,
			pending: false,
			fraud: false,
			error: false,
			review: false,
			chargeback: false,
			settled: false,
			safe: false,
		},
		options: {
			guaranteed: 'Guaranteed',
			fraud: 'Not Guaranteed - Probabble Fraud',
			notguaranteed: 'Not Guaranteed - High Risk',
			notguaranteedLowRisk: 'Not Guaranteed - Low Risk',
			pending: 'Pending',
			review: 'Review',
			chargeback: 'Chargeback',
			settled: 'Settled',
			safe: 'Safe',
			error: 'Error',
		},
		component: SelectFilter,
		selectionComponent: SelectSelection,
	},
]);

export const compileFilter = async (filters, filterDateFormat) => {
	const { date, referenceNumber, amount, cardNumber, cardholderName, invoice, fraudStatus } = getFilterValues(filters);

	const beginDate = await localToApiMoment(date.startDate);
	const endDate = await localToApiMoment(date.endDate);

	const filter = {
		xCommand: 'Report:All',
		xBeginDate: getDate(beginDate, filterDateFormat),
		xEndDate: getDate(endDate, filterDateFormat),
	};

	if (trim(amount.amount)) {
		filter.xAmount = 'ai' + amount.amount.toString();
	}

	if (trim(cardholderName.cardholderName)) {
		filter.xName = 'a=' + cardholderName.cardholderName;
	}

	if (trim(cardNumber.cardNumber) && cardNumber.cardNumber.indexOf('_') === -1) {
		filter.xCardLastFour = 'ai' + cardNumber.cardNumber.toString();
	}

	if (trim(referenceNumber.referenceNumber)) {
		filter.xRefNum = 'a=' + referenceNumber.referenceNumber.toString();
	}

	if (trim(invoice.invoice)) {
		filter.xInvoice = 'a=' + invoice.invoice.toString();
	}
	if (fraudStatus.pending) {
		filter.xStatus = 'ai0';
	} else if (fraudStatus.guaranteed) {
		filter.xStatus = 'ai1';
	} else if (fraudStatus.fraud) {
		filter.xStatus = 'ai2';
	} else if (fraudStatus.notguaranteed) {
		filter.xStatus = 'ai3';
	} else if (fraudStatus.notguaranteedLowRisk) {
		filter.xStatus = 'ai4';
	} else if (fraudStatus.error) {
		filter.xStatus = 'ai8';
	} else if (fraudStatus.safe) {
		filter.xStatus = 'ai11';
	} else if (fraudStatus.review) {
		filter.xStatus = 'ai12';
	} else if (fraudStatus.chargeback) {
		filter.xStatus = 'ai14';
	} else if (fraudStatus.settled) {
		filter.xStatus = 'ai16';
	}

	return filter;
};
