import React from 'react';
import { map } from 'lodash';

import { StringFilter } from '../../columns/filters';
import { ExpandableComponent } from 'common/components/columns/formatters';
import { ColumnHeader } from 'common/components/columns/headers';

const columns = [
	{
		key: 'customerId',
		name: 'C­ustomer I­D', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		formatter: ExpandableComponent,
		getRowMetaData: () => ({ isInputTypeRadio: true }),
		isDefaultSorter: true,
		defaultSortDirection: 'DESC',
		initWidth: 150,
		resizable: true,
		order: 1,
	},
	{
		key: 'billFirstName',
		name: 'F­irst­ N­ame', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		customSettingsKey: 'firstName',
		resizable: true,
	},
	{
		key: 'billLastName',
		name: 'L­ast­ N­ame', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		customSettingsKey: 'lastName',
		resizable: true,
	},
	{
		key: 'billCompany',
		name: 'C­ompany', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		customSettingsKey: 'company',
		resizable: true,
	},
	{
		key: 'email',
		name: 'Em­ail', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 200,
		customSettingsKey: 'email',
		resizable: true,
	},
	{
		key: 'customerNumber',
		name: 'C­ustomer #', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		customSettingsKey: 'customerNumber',
		resizable: true,
	},
];

export const LinkExistingCustomerColumns = map(columns, column => {
	column = {
		headerRenderer: <ColumnHeader />,
		...column,
	};
	return column;
});
