import React from 'react';

const UpdatedSettingsComponent = () => {
	return (
		<div className="">
			<p className="spc--bottom--sml">
				Please note, we have reorganized our settings and split them based on user settings and account settings.
			</p>
		</div>
	);
};

export default UpdatedSettingsComponent;
