export default [
	{
		key: 'today',
		startValue: 0,
		endValue: 0,
		displayValue: 'Today',
		description: '(today)',
	},
	{
		key: 'yesterday',
		startValue: -1,
		endValue: -1,
		displayValue: 'Yesterday',
		description: '(yesterday)',
	},
	{
		key: '2',
		startValue: -2,
		endValue: 0,
		displayValue: 'Last 2 Days',
		description: '(past 2 days)',
	},
	{
		key: '3',
		startValue: -3,
		endValue: 0,
		displayValue: 'Last 3 Days',
		description: '(past 3 days)',
	},
	{
		key: '4',
		startValue: -4,
		endValue: 0,
		displayValue: 'Last 4 Days',
		description: '(past 4 days)',
	},
	{
		key: '5',
		startValue: -5,
		endValue: 0,
		displayValue: 'Last 5 Days',
		description: '(past 5 days)',
	},
	{
		key: '6',
		startValue: -6,
		endValue: 0,
		displayValue: 'Last 6 Days',
		description: '(past 6 days)',
	},
	{
		key: '7',
		startValue: -7,
		endValue: 0,
		displayValue: 'Last 7 Days',
		description: '(past 7 days)',
	},
];
