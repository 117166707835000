import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import {
	map,
	split,
	cloneDeep,
	some,
	each,
	toLower,
	find,
	findIndex,
	padStart,
	times,
	isEmpty,
	filter,
	includes,
	groupBy,
	flatten,
	trim,
	findKey,
	startsWith,
	upperFirst,
	isObject,
} from 'lodash';

import { kvaasService } from '../../../Common/services';
import { Notification } from '../../../Common/components/notifications';
import { withLoader } from '../../../Common/components/loader';
import { withCancelable } from '../../../Common/components/cancelable';
import { withBlock } from '../../../Common/components/block';
import { withError } from '../../../Common/components/error';
import { kvaasResources, CurrencyMap, checkIfCanadian, mapConvenienceToCustom } from '../../../Common/utilities';
import handleInvalidRevision from '../../user-settings/utils/invalidRevision';
import { FieldManagementHeader } from '../components';

const requestKeys = {
	KVAAS: 'kvaas',
	SAVE: 'save',
	FETCH: 'fetch',
};

class AccountManagementNewCustomers extends Component {
	constructor(props) {
		super(props);
		this.refTop = createRef();
		this.notificationRef = createRef();

		this.state = {
			isSaving: false,
			errorMessages: [],
			invalidFields: [],
			oldData: {
				required: null,
				customLabel: null,
				hide: null,
			},
			...this.initialFields,
		};
	}

	get initialFields() {
		return {
			general: {
				customerNumber: {
					required: false,
					label: 'Customer #',
					customLabel: '',
				},
				email: {
					required: false,
					label: 'Email Address',
					customLabel: '',
					hide: true,
				},
				note: {
					required: false,
					label: 'Note',
					customLabel: '',
					inputDisabled: false,
				},
			},
			billingInformation: {
				firstName: {
					required: false,
					label: 'First Name',
					customLabel: '',
					hide: false,
					inputDisabled: true,
				},
				lastName: {
					required: false,
					label: 'Last Name',
					customLabel: '',
					hide: false,
					inputDisabled: true,
				},
				company: {
					required: false,
					label: 'Company',
					customLabel: '',
					inputDisabled: true,
					hide: false,
				},
				address: {
					required: false,
					label: 'Address 1',
					customLabel: '',
					inputDisabled: true,
					hide: false,
				},
				address2: {
					required: false,
					label: 'Address 2',
					customLabel: '',
					hide: false,
				},
				city: {
					required: false,
					label: 'City',
					customLabel: '',
					inputDisabled: true,
					hide: false,
				},
				state: {
					required: false,
					label: 'State',
					customLabel: '',
					inputDisabled: true,
					hide: false,
				},
				zip: {
					required: false,
					label: 'ZIP',
					customLabel: '',
					inputDisabled: true,
					hide: false,
				},
				phoneNumber: {
					required: false,
					label: 'Phone Number',
					customLabel: '',
					inputDisabled: true,
					hide: false,
				},
				mobilePhoneNumber: {
					required: false,
					label: 'Mobile Phone Number',
					customLabel: '',
					hide: false,
				},
			},

			recurringSchedule: {
				scheduleName: {
					required: false,
					label: 'Schedule Name',
					customLabel: '',
					hide: false,
				},
				amount: {
					required: true,
					label: 'Amount',
					customLabel: '',
					disabled: true,
				},
				invoice: {
					required: false,
					label: 'Invoice',
					customLabel: '',
					hide: false,
				},
				...this.createCustomFields(3, 19, 'recurringCustom'),
			},
			custom: this.createCustomFields(3, 19),
		};
	}

	getCustomField = value => find(this.customFieldOptions, { value });

	getValue = (item, isConvenience, isOriginalCustom) => {
		if (isConvenience) {
			return 'Electronic Transfer Fee';
		}
		if (isOriginalCustom) {
			return 'Original Amount';
		}
		return item.customLabel;
	};

	currencyCode = CurrencyMap.resolveCurrency();

	setStateAsync = newState => {
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	setKey = (oneBasedIndex, label = 'custom') => {
		return `${label}${oneBasedIndex}`;
	};

	createCustomFields = (numberOfFields, maxCustomerFields, label) => {
		const custom = {};
		times(maxCustomerFields, i => {
			const oneBasedIndex = i + 1;
			if (oneBasedIndex === 1) return;
			const key = this.setKey(oneBasedIndex, label);
			const field = {
				inputDisabled: true,
				required: false,
				label: `Custom${padStart(i + 1, 2, 0)}`,
				customLabel: '',
				hide: false,
				canDelete: oneBasedIndex > numberOfFields,
			};
			if (label) {
				field.key = key;
			}
			custom[key] = field;
		});
		return custom;
	};

	componentDidMount = async () => {
		this.props.showLoader(true);
		try {
			const getKvaas = kvaasService.get(
				kvaasResources.customerRequiredFields,
				kvaasResources.transactionDisplayLabels,
				kvaasResources.customerHiddenFields,
				kvaasResources.convenienceFees
			);

			const [
				requiredFields,
				customDisplayLabels,
				customerHiddenFields,
				convenience,
			] = await this.props.makePendingRequest(getKvaas, requestKeys.FETCH);
			this.props.showLoader(false);
			const newState = this.handleMapResponseToState(
				requiredFields,
				customDisplayLabels,
				customerHiddenFields,
				convenience
			);
			const isCanadian = checkIfCanadian();

			if (isCanadian) {
				newState.billingInformation.state.label = 'Province';
				newState.billingInformation.zip.label = 'Postal Code';
			}
			this.handleSetNewState(newState);
		} catch (e) {
			this.props.handleError(e);
		}
	};
	handleSetNewState = updateState =>
		this.setState(updateState, () => {
			if (!isEmpty(updateState.errorMessages)) {
				this.scrollToTop();
			}
		});
	handleDisplayErrors = err => {
		return map(err, (displayError, index) => (
			<div key={index} className="validation spc--bottom--med">
				<p className="type--error">{displayError}</p>
			</div>
		));
	};
	getSection = (newState, key, isCustom) => {
		return filter(newState, item => item && (item[key] || (isCustom && item[`recurring${upperFirst(key)}`])));
	};
	mapData = (newState, oldData, type, data) => {
		each(data, (value, key) => {
			const sections = this.getSection(newState, key, startsWith(key, 'custom') && type === 'customLabel');
			if (!sections) return;
			const fieldIndex = findIndex(newState.fields, ({ fieldKey }) => fieldKey === key);
			if (fieldIndex > -1) {
				if (type === 'required' && oldData[key] === undefined) {
					newState.fields[fieldIndex][type] = false;
				}
				newState.fields[fieldIndex][type] = value;
			}

			if (type === 'customLabel') {
				each(sections, section => {
					if (section[key]) {
						section[key][type] = value;
					}
					if (startsWith(key, 'custom') && section[`recurring${upperFirst(key)}`]) {
						section[`recurring${upperFirst(key)}`][type] = value;
					}
				});
			} else {
				each(sections, section => (section[key][type] = value));
			}
		});
	};

	isInvalidRevision = error =>
		toLower(error) === 'invalid: revision' || toLower(error) === 'item exists. revision cannot be 0';

	onBeforeErrorHandler = (refNumber, err) => {
		this.props.handleError({
			isApiError: true,
			ref: refNumber,
			message: err,
			success: false,
		});
	};
	isDataValid = (data, result, error) => data && (toLower(result) === 's' || error === 'Item does not exist');
	mapAvailableFieldsToState = (newState, oldData, type) => {
		const { result, error, refNum, data } = oldData;
		if (this.isDataValid(data, result, error)) {
			if (!error) {
				newState.oldData[type] = {
					...oldData,
				};
			}

			this.mapData(newState, oldData, type, data);
		} else if (this.isInvalidRevision(error)) {
			this.onBeforeErrorHandler(refNum, error);
		} else {
			const refString = ` (Ref# ${refNum})`;
			newState.errorMessages.push(`${error}${refNum ? refString : ''}`);
		}
	};

	handleMapResponseToState = (
		oldFields = this.state.oldData.required,
		oldDisplayLabels = this.state.oldData.customLabel,
		oldHide = this.state.hide,
		oldConvenience = this.state.convenience
	) => {
		const { general, billingInformation, recurringSchedule, custom, oldData } = cloneDeep(this.state);
		const newState = {
			errorMessages: [],
			oldData,
			general,
			billingInformation,
			recurringSchedule,
			custom,
		};
		this.mapAvailableFieldsToState(newState, oldFields, 'required');
		this.mapAvailableFieldsToState(newState, oldHide, 'hide');
		this.mapAvailableFieldsToState(newState, oldDisplayLabels, 'customLabel');
		const { parsedConvenienceKey, parsedOriginalKey } = mapConvenienceToCustom(
			oldConvenience,
			oldHide,
			oldDisplayLabels
		);
		newState.convenience = oldConvenience;
		newState.parsedConvenienceKey = parsedConvenienceKey;
		newState.parsedOriginalKey = parsedOriginalKey;

		this.displayConvenienceFields(newState, parsedOriginalKey, parsedConvenienceKey);
		return newState;
	};
	displayConvenienceFields = (newState, parsedOriginalKey, parsedConvenienceKey) => {
		const originalAmountKey = findKey(newState.custom, (_, key) => key === parsedOriginalKey);
		const convenienceAmountKey = findKey(newState.custom, (_, key) => key === parsedConvenienceKey);
		if (newState.custom[originalAmountKey]) {
			newState.custom[originalAmountKey].hide = false;
		}
		if (newState.custom[convenienceAmountKey]) {
			newState.custom[convenienceAmountKey].hide = false;
		}
	};
	setDefaultHiddenFields = (key, fieldKey, data, defaultData) => {
		if (key === 'hide') {
			data[fieldKey] = defaultData[fieldKey] ? defaultData[fieldKey] : false;
		} else {
			data[fieldKey] = false;
		}
	};

	setToDefault = (requiredFields, fieldKey, defaultData, data, key, newState, sectionKey) => {
		const notRequired = !some(requiredFields, required => required === fieldKey);
		if (notRequired) {
			this.setDefaultHiddenFields(key, fieldKey, data, defaultData);
		}
		if (key === 'customLabel') {
			data[fieldKey] = '';
		}
		if (key === 'customLabel' || notRequired) {
			newState[sectionKey][fieldKey][key] = data[fieldKey];
		}
	};

	mapStateToFields = async (key, { primaryKey, defaultData }, resetToDefault) => {
		try {
			const newState = cloneDeep(this.state);
			const requiredFields = ['amount'];
			const data = {};
			const sections = {
				general: this.state.general,
				billingInformation: this.state.billingInformation,
				recurringSchedule: this.state.recurringSchedule,
				custom: this.state.custom,
			};
			each(sections, (section, sectionKey) => {
				each(section, (field, fieldKey) => {
					if (resetToDefault) {
						this.setToDefault(requiredFields, fieldKey, defaultData, data, key, newState, sectionKey);
					} else {
						if (fieldKey === 'recurringCustom') {
							// loops recurring custom fields and sets correct properties
							each(field, fieldItem => (data[fieldItem.key] = fieldItem[key]));
						} else {
							data[fieldKey] = field[key];
						}
					}
				});
			});
			await this.setStateAsync(newState);
			return {
				newData: {
					revision: 0,
					data,
				},
				oldData: this.state.oldData[key],
				primaryKey,
			};
		} catch (e) {
			this.props.handleError(e, { additionalInfo: { key, primaryKey, resetToDefault } });
		}
	};

	mapStateToRequiredFields = async setToDefaults => [
		await this.mapStateToFields('required', kvaasResources.customerRequiredFields, setToDefaults),
		await this.mapStateToFields('customLabel', kvaasResources.transactionDisplayLabels, setToDefaults),
		await this.mapStateToFields('hide', kvaasResources.customerHiddenFields, setToDefaults),
	];

	scrollToTop = () => {
		if (this.refTop.current) {
			this.refTop.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
		}
	};

	checkIfAllowedValue = ({ value }) => {
		return value <= 100;
	};

	handleChange = event => {
		const { parsedConvenienceKey, parsedOriginalKey } = this.state;
		const { name, checked, value, type } = event.target;
		const [sectionKey, fieldKey, propKey] = split(name, '.');

		const newState = {};
		newState[sectionKey] = cloneDeep(this.state[sectionKey]);
		const newValue = type === 'checkbox' ? checked : value;
		const shippingField = newState['shippingInformation'];
		if (shippingField) {
			shippingField[fieldKey][propKey] = newValue;
		}
		newState[sectionKey][fieldKey][propKey] = newValue;

		if (includes(['required'], propKey)) {
			newState[sectionKey][fieldKey].hide = false;
			if (sectionKey === 'billingInformation') {
				if (shippingField) {
					shippingField[fieldKey].hide = false;
				}
			}
		}
		if (propKey === 'hide') {
			if (fieldKey === parsedConvenienceKey || fieldKey === parsedOriginalKey) return;
			newState[sectionKey][fieldKey].required = false;
			if (shippingField) {
				shippingField.required = false;
			}
		}

		this.setState(newState, this.validateFields);
		this.props.handleBlockChange(true);
	};

	validateFields = () => {
		const {
			billingInformation: { firstName, lastName, company },
		} = this.state;
		const invalidFields = [];
		const duplicates = this.getDuplicates();

		each(duplicates, ({ label }) => {
			invalidFields.push(label);
		});
		if (firstName.hide && lastName.hide && company.hide) {
			invalidFields.push({ value: 'First Name, Last Name or Company needs to be visible' });
		}

		const hasErrors = !isEmpty(invalidFields);

		this.setState({ invalidFields });

		return hasErrors;
	};

	getDuplicates = () => {
		const { general, billingInformation, recurringSchedule, custom } = this.state;
		const customDisplayLabelsList = [];

		this.addCustomLabelsToList(general, customDisplayLabelsList);
		this.addCustomLabelsToList(billingInformation, customDisplayLabelsList);
		this.addCustomLabelsToList(recurringSchedule, customDisplayLabelsList);
		this.addCustomLabelsToList(custom, customDisplayLabelsList);

		return flatten(filter(groupBy(customDisplayLabelsList, 'customLabel'), arr => arr.length > 1));
	};

	addCustomLabelsToList = (section, list) => {
		each(section, ({ customLabel, label, inputDisabled }) => {
			if (customLabel && !inputDisabled) {
				list.push({ customLabel: trim(customLabel), label });
			}
		});
	};
	addCustomerCustomField = () => {
		const custom = cloneDeep(this.state.custom);
		const field = find(custom, item => (parseInt(item.label.substr('Custom'.length)) > 3 ? item.hide : false));
		if (field) {
			field.hide = false;
			this.setState({ custom });
		}
	};

	addRecurringCustomField = () => {
		const recurringSchedule = cloneDeep(this.state.recurringSchedule);
		const field = find(recurringSchedule, item =>
			parseInt(item.label.substr('Custom'.length)) > 3 ? item.hide : false
		);
		if (field) {
			field.hide = false;
			this.setState({ recurringSchedule });
		}
	};

	addCustomField = isRecurringCustom => {
		if (isRecurringCustom) {
			this.addRecurringCustomField();
		} else {
			this.addCustomerCustomField();
		}
		this.props.handleBlockChange(true);
	};

	removeCustomField = key => {
		const isRecurringCustom = startsWith(key, 'recurringCustom');
		let custom = {};
		if (isRecurringCustom) {
			custom = cloneDeep(this.state.recurringSchedule);
		} else {
			custom = cloneDeep(this.state.custom);
		}
		if (custom[key] && custom[key].canDelete) {
			custom[key].hide = true;
			custom[key].required = false;
			custom[key].customLabel = '';
			if (isRecurringCustom) {
				this.setState({ recurringSchedule: custom }, this.validateFields);
			} else {
				this.setState({ custom }, this.validateFields);
			}
		}
		this.props.handleBlockChange(true);
	};

	save = async setToDefaults => {
		let refreshData = false;
		let refNum;
		let error;
		this.props.showLoader(true);
		const mappedState = await this.mapStateToRequiredFields(setToDefaults);
		try {
			const [requiredFields, customDisplayLabels, customerHiddenFields] = await this.props.makePendingRequest(
				kvaasService.save(...mappedState),
				requestKeys.SAVE
			);
			const newState = this.handleMapResponseToState(requiredFields, customDisplayLabels, customerHiddenFields);
			refNum = requiredFields.refNum;
			if (setToDefaults) {
				newState.invalidFields = [];
			}
			this.props.showLoader(false);
			this.handleSetNewState(newState);
			this.props.handleBlockChange(false);
		} catch (e) {
			error = this.props.handleError(e, { delayMessage: true });
			if (error) {
				refreshData = true;
			} else {
				return;
			}
		}
		if (refreshData) {
			try {
				const [requiredFields, customDisplayLabels, customerHiddenFields] = await this.props.makePendingRequest(
					kvaasService.get(
						{ ...kvaasResources.customerRequiredFields, throwError: true },
						{ ...kvaasResources.transactionDisplayLabels, throwError: true },
						{ ...kvaasResources.customerHiddenFields, throwError: true },
						{ ...kvaasResources.convenienceFees, throwError: true }
					),
					requestKeys.KVAAS
				);
				const newState = this.handleMapResponseToState(requiredFields, customDisplayLabels, customerHiddenFields);

				this.setState(newState, () => {
					this.shouldScrollTop(newState.errorMessages);
					this.validateFields();
				});
				this.props.showLoader(false);
				this.props.handleBlockChange(false);
			} catch (e) {
				const handledError = this.props.handleError(e, { delayMessage: true });
				error = handledError;
				if (error) {
					this.props.handleKvaasLoadError();
				}
			}
			this.props.showLoader(false);
			this.shouldScrollTop(this.state.errorMessages, error);
		}
		if (!error) {
			this.notificationRef.current.addNotification({
				message: setToDefaults ? 'New customer settings reset to default' : 'New customer settings updated',
				ref: refNum,
				success: true,
			});
		}
	};

	shouldScrollTop = (errorMessages, error) => {
		if (!isEmpty(errorMessages)) {
			return this.scrollToTop();
		} else if (error) {
			error.show();
		}
	};

	isDisabledSaveButton = () => {
		const { invalidFields, errorMessages } = this.state;
		const { isLoading } = this.props;
		return isLoading || !isEmpty(errorMessages) || !isEmpty(invalidFields);
	};

	renderSaveButton = () => {
		return (
			<Fragment>
				<span className="settings__header__action__text">
					Settings are saved only after clicking on <strong>Save</strong> button
				</span>
				{this.renderSetToDefaultButton()}
				<button
					className="btn btn--primary btn--med spc--bottom--sml"
					disabled={this.isDisabledSaveButton()}
					onClick={() => this.save(false)}
				>
					Save
				</button>
			</Fragment>
		);
	};

	renderSetToDefaultButton = () => (
		<button
			className="btn btn--med btn--ghost spc--right--sml spc--bottom--sml"
			disabled={this.props.isLoading}
			onClick={() => this.save(true)}
		>
			Reset to Default
		</button>
	);

	renderFooterButtons = () => {
		return (
			<Fragment>
				{this.renderSetToDefaultButton()}
				<button
					className="btn btn--med btn--primary spc--bottom--sml"
					disabled={this.isDisabledSaveButton()}
					onClick={() => this.save(false)}
				>
					Save
				</button>
			</Fragment>
		);
	};

	getInputClassName = item => {
		const { invalidFields } = this.state;
		if (some(invalidFields, field => field === item.label)) {
			return 'input input--med is-invalid';
		}
		return 'input input--med';
	};

	renderDisabled = (item, isConvenience = false) => {
		const className = `icon icon--sml icon--${item.hide && !item.required ? 'eye-crossed' : 'view'} `;
		if (item.disabled || isConvenience) {
			return <div data-tooltip="Disabled"><i className="icon icon--sml icon--eye-disabled"></i></div>;
		}
		return (
			<span className="display--b datatooltip--eye datatooltip--no-wrap" data-tooltip={item.hide ? 'Show field' : 'Hide field'}>
				<i className={className}></i>
			</span>
		);
	};

	renderRemoveField = (item, key, isConvenience, hideName) => {
		if (item.canDelete && !isConvenience) {
			return (
				<div className="f-col f-col-sml-6 type--center">
					<div data-tooltip="Remove">
						<i
							onClick={() => this.removeCustomField(key)}
							className="icon icon--sml icon--wastebasket"
						></i>
					</div>
				</div>
			);
		}
		return (
			<div className="f-col f-col-sml-6 type--center">
				<label htmlFor={hideName}>
					{this.renderDisabled(item, isConvenience)}
					<input
						disabled={item.disabled}
						type="checkbox"
						className="input input--check"
						checked={item.hide}
						name={hideName}
						value={hideName}
						id={hideName}
						onChange={this.handleChange}
						onClick={this.handleChange}
					/>
				</label>
			</div>
		);
	};
	renderColumnSections = (sectionName, sectionKey) => (
		<Fragment>
			<FieldManagementHeader sectionName={sectionName} />
			{map(this.state[sectionKey], (item, key) => {
				const name = `${sectionKey}.${key}`;
				const requiredName = `${name}.required`;
				const hideName = `${name}.hide`;
				const labelName = `${name}.customLabel`;
				const isConvenience = key === this.state.parsedConvenienceKey;
				const isOriginalCustom = key === this.state.parsedOriginalKey;
				return this.renderColumns({
					sectionKey,
					item,
					name,
					labelName,
					isConvenience,
					isOriginalCustom,
					requiredName,
					key,
					hideName,
				});
			})}
			{this.renderAddCustomField(sectionKey)}
		</Fragment>
	);

	renderColumns = ({
		sectionKey,
		item,
		name,
		labelName,
		isConvenience,
		isOriginalCustom,
		requiredName,
		key,
		hideName,
	}) => {
		const columnItemData = {
			item,
			name,
			labelName,
			isConvenience,
			isOriginalCustom,
			requiredName,
			key,
			hideName,
		};
		if ((sectionKey === 'custom' || startsWith(key, 'recurringCustom')) && item.canDelete && item.hide) return null;
		return this.renderColumnItem(columnItemData);
	};
	renderColumnItem = ({ item, name, labelName, isConvenience, isOriginalCustom, requiredName, key, hideName }) => {
		const className = `${item.inputDisabled ? '' : 'datatooltip--hidden'}`;
		return (
			<div key={name} className="f-row f-row--center spc--bottom--sml">
				<div className="f-col f-col-sml-12 f-col-lrg-3 datatooltip--no-wrap">
					<label htmlFor={labelName} className="type--base type--wgt--medium">
						{item.label}
						{isConvenience && (
							<i
								data-tooltip="This field was previously referred to as 'Convenience Fee'"
								className="icon icon--nano icon--info spc--left--tny"
							></i>
						)}
					</label>
				</div>
				<div className="f-col f-col-sml-12 f-col-med-6 f-col-lrg-5 spc--bottom--sml">
					<div className={className} data-tooltip="This field name can be edited in the New Transaction settings.">
						<input
							type="text"
							className={this.getInputClassName(item)}
							placeholder={item.label}
							name={labelName}
							value={this.getValue(item, isConvenience, isOriginalCustom)}
							disabled={item.inputDisabled || isConvenience}
							onChange={this.handleChange}
						/>
					</div>
				</div>
				<div className="f-col f-col-sml-12 f-col-med-6 f-col-lrg-4 spc--bottom--sml">
					<div className="f-row f-row--center">
						<div className="f-col f-col-sml-6 type--center">
							<div className="display--ib">
								<input
									type="checkbox"
									className="input--check"
									disabled={item.disabled}
									checked={item.required}
									name={requiredName}
									value={requiredName}
									id={requiredName}
									onChange={this.handleChange}
									onClick={this.handleChange}
								/>
								<label htmlFor={requiredName}>
									<span className="hide--from--med">Required</span>
								</label>
							</div>
						</div>
						{this.renderRemoveField(item, key, isConvenience || isOriginalCustom, hideName)}
					</div>
				</div>
			</div>
		);
	};

	getHasCapacity = isRecurringCustom => {
		if (isRecurringCustom) {
			return this.state.recurringSchedule['recurringCustom19'].hide;
		}
		return some(this.state.custom, field => {
			const fieldLabel = field.label === 'Custom19' ? -2 : -1;
			return Number(field.label.substr(fieldLabel) > 3 ? field.hide : false);
		});
	};
	renderAddCustomField = (sectionKey, isRecurringCustom) => {
		const hasCapacity = this.getHasCapacity(isRecurringCustom);
		if ((sectionKey === 'custom' || isRecurringCustom) && hasCapacity) {
			return (
				<a
					href="javascript:void(0)"
					onClick={() => this.addCustomField(isRecurringCustom)}
					className="anchor anchor--primary type--underline spc--bottom--sml display--ib"
				>
					+ Add Custom Field
				</a>
			);
		}
	};

	renderInvalidFieldMessage = field => {
		const isCustomMessage = isObject(field);
		return (
			<div key={field} className="validation spc--bottom--med">
				<p className="type--error">{isCustomMessage ? field.value : `${field} must be unique.`}</p>
			</div>
		);
	};

	render() {
		const { errorMessages, invalidFields } = this.state;

		const { refTop, notificationRef, renderSaveButton, renderColumnSections, renderFooterButtons } = this;

		return (
			<div className="settings--main settings--main--alt">
				<div className="settings__header">
					<div className="settings__header__title">New Customer Settings</div>
					<div className="settings__header__action">{renderSaveButton()}</div>
				</div>
				<div ref={refTop}></div>
				{this.handleDisplayErrors(errorMessages)}
				{map(invalidFields, field => this.renderInvalidFieldMessage(field))}
				<div className="spr__card clearfix">
					<div className={`spr__heading cursor--pointer is-expanded`}>
						<h4 className="spr__heading__title">Manage Fields</h4>
					</div>
					<div className="spr__content spr__content--transaction-settings clearfix">
						{renderColumnSections('General', 'general')}
						{renderColumnSections('Billing Information', 'billingInformation')}
						{renderColumnSections('Recurring Schedule', 'recurringSchedule')}
						{this.renderAddCustomField('recurringSchedule', true)}
						{renderColumnSections('Custom Fields', 'custom')}
					</div>
				</div>
				<hr className="separator separator--grey1 spc--bottom--med" />
				<footer className="settings__footer spc--bottom--med spc--top--med">
					<span className="settings__footer__text spc--bottom--sml">
						Settings are saved only after clicking on <strong>Save</strong> button
					</span>
					<div className="type--nowrap">{renderFooterButtons()}</div>
				</footer>
				<Notification ref={notificationRef} />
			</div>
		);
	}
}

AccountManagementNewCustomers.propTypes = {
	showLoader: PropTypes.func,
	isLoading: PropTypes.bool,
	handleBlockChange: PropTypes.func,
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func,
	handleKvaasLoadError: PropTypes.func,
};

export default withError(withLoader(withCancelable(withBlock(AccountManagementNewCustomers))), handleInvalidRevision);
