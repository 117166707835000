import React from 'react';

const HeaderBackground = () => (
	<svg width="1190" height="282" viewBox="0 0 1190 282" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M917 264L494 0H1190V282L917 264Z" fill="url(#paint0_linear)" />
		<path d="M280 221L0 46V202L280 221Z" fill="url(#paint1_linear)" />
		<path d="M0 0V46L280 221L609 243L220 0H0Z" fill="url(#paint2_linear)" />
		<path d="M609 243L220 0H494L917 264L609 243Z" fill="url(#paint3_linear)" />
		<defs>
			<linearGradient id="paint0_linear" x1="1080" y1="-71" x2="884.5" y2="245" gradientUnits="userSpaceOnUse">
				<stop stopColor="#F1F1F1" />
				<stop offset="1" stopColor="#DCDCDB" />
			</linearGradient>
			<linearGradient id="paint1_linear" x1="68" y1="88.5" x2="-4.00288e-06" y2="201.5" gradientUnits="userSpaceOnUse">
				<stop stopColor="#EAEAEA" />
				<stop offset="1" stopColor="#D7D7D7" />
			</linearGradient>
			<linearGradient id="paint2_linear" x1="330" y1="68.5" x2="247.5" y2="202.5" gradientUnits="userSpaceOnUse">
				<stop stopColor="#EFEFEF" />
				<stop offset="1" stopColor="#DEDEDE" />
			</linearGradient>
			<linearGradient id="paint3_linear" x1="663.5" y1="106" x2="587.5" y2="228" gradientUnits="userSpaceOnUse">
				<stop stopColor="#E9E9E9" />
				<stop offset="1" stopColor="#D7D7D7" />
			</linearGradient>
		</defs>
	</svg>
);

export default HeaderBackground;
