import React from 'react';
import PropTypes from 'prop-types';

const FakeRefNumComponent = ({ value }) => {
	return (
		<span>
			<i className="icon icon--micro spc--right--tny icon--arrow icon--arrow--right--primary"></i>{' '}
			<span className="anchor anchor--primary">{value}</span>
		</span>
	);
};

FakeRefNumComponent.propTypes = {
	value: PropTypes.string.isRequired,
};

export default FakeRefNumComponent;
