import { cloneDeep } from 'lodash';

const mapCustomKey = (customKey, customLabel, hiddenColumns, customDisplayLabels) => {
	if (customKey) {
		const parsedCustomKey = `custom${parseInt(customKey.slice(-2))}`;
		if (!hiddenColumns.data) {
			hiddenColumns.data = {};
		}
		delete hiddenColumns.data[parsedCustomKey];
		if (!customDisplayLabels.data) {
			customDisplayLabels.data = {};
		}
		customDisplayLabels.data[parsedCustomKey] = customLabel;
		return parsedCustomKey;
	}
};

export const mapConvenienceToCustom = (convenienceFees, unmappedHiddenColumns, unmappedCustomDisplayLabels) => {
	if (!convenienceFees || !convenienceFees.data) {
		return {
			hiddenColumns: unmappedHiddenColumns,
			customDisplayLabels: unmappedCustomDisplayLabels,
		};
	}
	const { convenienceCustomKey, originalCustomKey } = convenienceFees.data;

	let hiddenColumns = cloneDeep(unmappedHiddenColumns) || {};
	let customDisplayLabels = cloneDeep(unmappedCustomDisplayLabels) || {};

	const parsedConvenienceKey = mapCustomKey(
		convenienceCustomKey,
		'Electronic Transfer Fee',
		hiddenColumns,
		customDisplayLabels
	);
	const parsedOriginalKey = mapCustomKey(originalCustomKey, 'Original Amount', hiddenColumns, customDisplayLabels);

	return {
		hiddenColumns,
		customDisplayLabels,
		parsedConvenienceKey,
		parsedOriginalKey,
	};
};
