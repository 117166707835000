import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, size, get, isArray, every } from 'lodash';

import TooltipFormatter from './TooltipFormatter';
import { principalService } from 'common/services';

function checkIfHasAccess(principal, section) {
	if (!section) {
		return true;
	}
	if (!principal || !principal.hasAccess) {
		return false;
	}
	if (isArray(section)) {
		return every(section, item => principal.hasAccess[item]);
	}
	return principal.hasAccess[section];
}

class ActionComponent extends TooltipFormatter {
	constructor(props) {
		super(props, ActionComponent.instances);
	}

	static instances = [];

	get tooltip() {
		return this.props.action.tooltip;
	}

	tooltipClass = 'datatooltip--fake';

	render() {
		const {
			action: { component, key, tooltip, action, icon, className, componentProps = {} },
			dependentValues,
			row,
			isLast,
		} = this.props;
		const Component = component || 'button';
		let iconProps = {
			onMouseEnter: this.displayPopup,
			onMouseLeave: this.closePopup,
		};

		if (!dependentValues.onInfoHover) {
			iconProps = { 'data-tooltip': tooltip };
		}

		function onClick(event) {
			event.stopPropagation();
			action(event);
		}

		return (
			<td>
				<Component key={key} row={row} onClick={onClick} className={className} {...componentProps}>
					<div {...iconProps}>
						<i
							className={`icon icon--xsml icon--${icon} ${isLast ? 'spc--right--sml' : ''}`}
							ref={this.elementRef}
						/>
					</div>
				</Component>
			</td>
		);
	}
}

ActionComponent.propTypes = {
	dependentValues: PropTypes.object,
	row: PropTypes.object.isRequired,
};

class ActionsComponent extends Component {
	state = {
		principal: principalService.get(),
	};

	render() {
		const { principal } = this.state;
		const { dependentValues } = this.props;
		const actions = get(dependentValues, 'actions');
		if (!actions) {
			return null;
		}

		return map(actions, (action, i) => {
			if (!checkIfHasAccess(principal, action.section)) {
				return null;
			}
			return <ActionComponent key={action.key} isLast={i + 1 < size(actions)} action={action} {...this.props} />;
		});
	}
}

ActionsComponent.propTypes = {
	dependentValues: PropTypes.object,
	row: PropTypes.object.isRequired,
};

export default ActionsComponent;
