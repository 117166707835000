import React, { Component, Fragment } from 'react';
import { object, func } from 'prop-types';
import { map } from 'lodash-es';

import PaymentSiteFormSetting from '../PaymentSiteFormSetting';
import PaymentSiteAdditionalSettings from '../components/PaymentSiteAdditionalSettings';
class PaymentSiteInternalSettings extends Component {
	get expanded() {
		return {
			extraHtml: true,
		};
	}

	get settings() {
		return [{ key: 'extraHtml', label: 'Extra HTML', type: 'text', className: 'input input--med' }];
	}
	renderSettings = formSettings => {
		return (
			<Fragment>
				{map(
					formSettings,
					({ key, label, tooltip, fields, expanded, internalSetting, component, indent }, sectionIndex) => {
						if (!internalSetting) return;
						return (
							<PaymentSiteFormSetting
								key={key}
								label={label}
								tooltip={tooltip}
								fields={fields}
								expanded={expanded}
								formComponent={component}
								sectionIndex={sectionIndex}
								section={'internalSettings'}
								formKey={key}
								internalSetting={true}
								hideHeader={true}
								indent={indent}
								{...this.props}
							/>
						);
					}
				)}
			</Fragment>
		);
	};
	render() {
		const {
			state,
			state: { formSettings, data },
			handleAdditionalSettingsDataChange,
			handleAdditionalSettingsClear,
		} = this.props;
		return (
			<div className="paymentsite__card--secondary">
				{this.renderSettings(formSettings, state)}
				<PaymentSiteAdditionalSettings
					settings={this.settings}
					data={data}
					expanded={this.expanded}
					handleExpand={this.handleExpand}
					handleAdditionalSettingsDataChange={handleAdditionalSettingsDataChange}
					handleAdditionalSettingsClear={handleAdditionalSettingsClear}
					internalSetting={true}
					hideHeader={true}
				/>
			</div>
		);
	}
}

PaymentSiteInternalSettings.propTypes = {
	renderComponent: func.isRequired,
	removeFormSetting: func.isRequired,
	toggleRemoveSectionPopup: func.isRequired,
	handleAdditionalSettingsDataChange: func.isRequired,
	handleAdditionalSettingsClear: func.isRequired,
	state: object.isRequired,
};

export default PaymentSiteInternalSettings;
