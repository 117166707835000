import principalService from './principalService';
import HttpServiceError from './httpServiceError';

class PortalHttpService {
	constructor(principalService) {
		this.principalService = principalService;
	}

	httpRequest(url, options) {
		this.initializeHeaders(options);
		const request = new Request(url, options);

		return new Promise((resolve, reject) => {
			fetch(request)
				.then(response => response.json())
				.then(response => resolve(response))
				.catch(ex => {
					reject(new HttpServiceError({ ex, request, response: ex.response }));
				});
		});
	}

	async get(url, options = {}) {
		options.method = 'GET';

		const response = await this.httpRequest(url, options);

		return response;
	}

	async post(url, body, options = {}) {
		options.method = 'POST';

		if (body) {
			options.body = JSON.stringify(body);
		} else {
			options.body = {};
		}

		const response = await this.httpRequest(url, options);

		return response;
	}

	async delete(url, options = {}) {
		options.method = 'DELETE';

		const response = await this.httpRequest(url, options);

		return response;
	}

	initializeHeaders(options) {
		options.headers = options.headers || new Headers();

		options.headers.set('MerchantKey', this.principalService.get().id);
		options.headers.set('Content-Type', 'application/json');
	}
}

const portalHttpService = new PortalHttpService(principalService);

export default portalHttpService;
