import {
	each,
	clone,
	cloneDeep,
	map,
	toLower,
	split,
	filter,
	slice,
	some,
	camelCase,
	transform,
	isArray,
	isPlainObject,
	includes,
	get,
	isEmpty,
	orderBy,
	concat,
	startsWith,
	padStart,
	times,
	startCase,
	replace,
	head,
	size,
	find,
	omit,
} from 'lodash';
import moment from 'moment';

import httpService from './httpService';
import transactionService from './transactionService';
import principalService from './principalService';
import { until, today, calendarTypes } from './../components/customers/popup/constants';
import { apiToLocalMoment, apiToDisplay, combineCustomerData } from './../utilities';
import { getHebrewDateValues } from 'common/utilities/to-hebrew';
const { cardExpiryDateFormat } = ApplicationSettings;

const maxValidYear = 8.64e15;
const {
	recurringApiEndpoint,
	recurringApiVersion,
	apiShortDateTimeFormat,
	displayShortDateTimeFormat,
	apiDateTimeFormat,
	apiDateFormat,
} = ApplicationSettings;

const viewPaymentsFields =
	'xRefNum,xEnteredDate,xResponseResult,xAmount,xCurrency,xCardType,xCommand,xName,xMaskedCardNumber,xDescription,xResponseError';

const endpoint = recurringApiEndpoint;

const paymentMethodType = {
	CC: 'CC',
	CHECK: 'Check',
};

class CustomerService {
	constructor(httpService, transactionService, principalService) {
		this.httpService = httpService;
		this.transactionService = transactionService;
		this.principalService = principalService;
	}

	get headers() {
		let headers = new Headers();
		headers.set('X-Recurring-Api-Version', recurringApiVersion);
		return headers;
	}

	get options() {
		return {
			isJson: true,
			headers: this.headers,
		};
	}

	principal = () => this.principalService.get();

	filterCustomersRequest = async (filter, maxRecords = 0) => {
		const response = await this.httpService.post(endpoint + 'ListCustomers', filter, this.options);

		return await this.parseCustomerResults(this.parseResponse(response), maxRecords);
	};

	filterCustomersAll = async (data, filter) => {
		filter = clone(filter);
		filter.PageSize = 1000;
		let finalResponse = await this.httpService.post(endpoint + 'ListCustomers', filter, this.options);
		const parsedFinalResponse = await this.parseCustomerResults(this.parseResponse(finalResponse), filter.PageSize);
		combineCustomerData(parsedFinalResponse, data);
		filter.NextToken = parsedFinalResponse.nextToken;

		while (filter.NextToken) {
			const response = await this.httpService.post(endpoint + 'ListCustomers', filter, this.options);
			const parsedResponse = await this.parseCustomerResults(this.parseResponse(response), filter.PageSize);
			filter.NextToken = parsedResponse.nextToken;
			combineCustomerData(parsedFinalResponse, parsedResponse);
		}
		delete parsedFinalResponse.nextToken;
		return parsedFinalResponse;
	};

	getCustomer = async CustomerId => {
		const filter = {
			CustomerId,
		};
		const response = await this.httpService.post(endpoint + 'GetCustomer', filter, this.options);
		return await this.parseCustomerResults(this.parseResponse(response, true));
	};

	addCustomer = async data => {
		const response = await this.httpService.post(endpoint + 'CreateCustomer', data, this.options);
		return await this.parseCustomerResults(this.parseResponse(response, true));
	};

	updateCustomer = async userData => {
		let data = cloneDeep(userData);
		delete data.AvsZip;
		delete data.AvsStreet;
		const response = await this.httpService.post(endpoint + 'UpdateCustomer', data, this.options);
		response.customerId = data.CustomerId;
		return await this.parseCustomerResults(this.parseResponse(response, true));
	};

	deleteCustomer = async CustomerId => {
		const response = await this.httpService.post(endpoint + 'DeleteCustomer', { CustomerId }, this.options);
		return await this.parseCustomerResults(this.parseResponse(response, true));
	};

	getCustomerPaymentMethods = async (customerId, defaultPaymentMethodId) => {
		const filter = {
			SortOrder: 'Descending',
			Filters: {
				customerId,
			},
		};
		const response = await this.httpService.post(endpoint + 'ListPaymentMethods', filter, this.options);
		return await this.parsePaymentResults(this.parseResponse(response), defaultPaymentMethodId);
	};

	getPaymentMethods = async () => {
		let nextToken = '';
		const requestPayload = {
			SortOrder: 'Descending',
			PageSize: 1000,
			Filters: {},
		};

		let finalResponse = await this.httpService.post(endpoint + 'ListPaymentMethods', requestPayload, this.options);
		const parsedFinalResponse = await this.parsePaymentResults(this.parseResponse(finalResponse));
		nextToken = finalResponse.nextToken;

		while (nextToken) {
			const response = await this.httpService.post(endpoint + 'ListCustomers', filter, this.options);
			const parsedResponse = await this.parsePaymentResults(this.parseResponse(finalResponse));
			nextToken = response.nextToken;
			parsedFinalResponse.xReportData = concat(parsedFinalResponse.xReportData, parsedResponse.xReportData);
		}
		delete parsedFinalResponse.nextToken;
		return parsedFinalResponse;
	};

	processTransaction = async userData => {
		let data = cloneDeep(userData);
		const response = await this.httpService.post(endpoint + 'ProcessTransaction', data, this.options);
		return await this.parseResponse(response);
	};

	mapCustomerPaymentMethods = (data, paymentMethods, showToken, isReportsExport) => {
		map(data.xReportData, customer => {
			return this.mapCustomerPaymentMethod(customer, paymentMethods, showToken, isReportsExport);
		});
	};

	mapCustomerPaymentMethod = (row, paymentMethods, showToken, isReportsExport) => {
		const customerPaymentMethods = this.filterCustomerPaymentMethods(row, paymentMethods);
		let defaultPaymentMethod = this.getDefaultPaymentMethod(row, customerPaymentMethods);
		if (defaultPaymentMethod) {
			if (isReportsExport) {
				row.isDefaultPaymentMethod = 'true';
			}
			row.avsZip = defaultPaymentMethod.zip;
			row.avsStreet = defaultPaymentMethod.street;
		}
		this.setPaymentMethodDetails(row, defaultPaymentMethod, customerPaymentMethods);
		this.setToken(row, customerPaymentMethods, showToken);
		this.setExpiryDetails(row);
		this.setMaskedCardNumber(row);
		return row;
	};

	filterCustomerPaymentMethods = (row, paymentMethods) => {
		return filter(paymentMethods.xReportData, paymentMethod => paymentMethod.customerId === row.customerId);
	};

	getDefaultPaymentMethod = (row, customerPaymentMethods) => {
		if (row.defaultPaymentMethodId) {
			return find(customerPaymentMethods, pm => pm.paymentMethodId === row.defaultPaymentMethodId);
		}
		return find(customerPaymentMethods, paymentMethod => paymentMethod.isDefaultPaymentMethod);
	};

	setPaymentMethodDetails = (row, defaultPaymentMethod, customerPaymentMethods) => {
		row.paymentMethodDetails = defaultPaymentMethod ? defaultPaymentMethod : customerPaymentMethods[0];
		let method = row.paymentMethodDetails ? row.paymentMethodDetails.tokenType : '';
		row.paymentMethod = isEmpty(method) ? method : method === paymentMethodType.CC ? 'Credit' : 'Check';
	};

	setToken = (row, customerPaymentMethods, showToken) => {
		if (size(customerPaymentMethods) && showToken) {
			row.token = head(customerPaymentMethods).token;
		}
	};

	setExpiryDetails = row => {
		const expiry = !row.paymentMethodDetails ? '' : row.paymentMethodDetails.exp;
		const expiryMoment = !expiry
			? !row.paymentMethod
				? moment(maxValidYear)
				: moment(maxValidYear - 1)
			: moment(expiry, cardExpiryDateFormat, true).isValid() &&
			  moment(expiry, cardExpiryDateFormat, true).endOf('month');
		row.paymentMethodExpiry = this.formattedPaymentMethodExpiry(expiry);
		row.paymentMethodExpiryMoment = expiryMoment;
		row.isPaymentMethodExpired = this.isExpired(expiry)
			? 'Expired'
			: this.isAboutToExpire(expiry)
			? 'About to expire'
			: '';
	};

	setMaskedCardNumber = row => {
		row.maskedCardCardNumber = !row.paymentMethodDetails ? '' : row.paymentMethodDetails.maskedNumber;
	};

	formattedPaymentMethodExpiry = expiry => {
		if (expiry) {
			let result = expiry.split('');
			result.splice(2, 0, '/');
			expiry = result.join('');
		}
		return expiry;
	};

	isExpired = expiry => {
		if (!expiry) return false;
		else {
			const exp = moment(expiry, cardExpiryDateFormat, true);
			const currentDate = moment();
			return exp.isValid() && exp.endOf('month').isBefore(currentDate);
		}
	};

	isAboutToExpire = expiry => {
		if (!expiry) return false;
		else {
			const exp = moment(expiry, cardExpiryDateFormat, true);
			const currentDate = moment();
			const upperlimit = moment(currentDate).add(1, 'M');
			return exp.isValid() && exp.endOf('month').isBetween(currentDate, upperlimit.endOf('month'), undefined, '[]');
		}
	};

	addCustomerPaymentMethod = async (data, saveData = {}) => {
		const paymentData = {
			CustomerId: data.customerId,
			Token: data.token,
			TokenAlias: data.tokenAlias || '',
			TokenType: data.tokenType || '',
			Name: data.name || data.xName,
			Street: data.street || '',
			Zip: data.zip || '',
			SetAsDefault: !!data.setAsDefault || !!data.isDefaultPaymentMethod,
		};

		if (toLower(data.tokenType) === 'check') {
			paymentData.AccountType = data.accountType;
			paymentData.Routing = get(data, '_meta.routingNumber', get(data, 'routingNumber', get(data, 'routing', '')));
		} else if (toLower(data.tokenType) === 'cc') {
			paymentData.Exp = data.exp;
		}

		if (!paymentData.Token || (saveData && saveData.token)) {
			const save = await this.transactionService.createNewTransaction(saveData);
			paymentData.Token = save.token || save.xToken;
		}
		const savePayment = await this.httpService.post(endpoint + 'CreatePaymentMethod', paymentData, this.options);
		return { success: true, message: '', ref: savePayment.refNum, data: savePayment };
	};

	addCustomerCcPaymentMethod = async (cardNumber, exp, data) => {
		// save card data
		const saveData = {
			xCommand: 'cc:save',
			xAmount: 0,
			xExp: exp,
		};
		if (!data.xToken) {
			saveData.xCardNum = cardNumber;
			saveData.xName = data.xName;
		} else if (data.xExp !== data._meta.originalExp) {
			saveData.xToken = data.xToken;
		}

		return await this.addCustomerPaymentMethod(data, saveData);
	};

	addCustomerCheckPaymentMethod = async (accountNumber, routingNumber, data) => {
		// save check data
		const saveData = {
			xCommand: 'check:save',
			xAmount: 0,
			xAccount: accountNumber,
			xRouting: routingNumber,
			xName: data.xName,
			xAccountType: data.accountType,
		};

		return await this.addCustomerPaymentMethod(data, saveData);
	};

	updateCustomerPaymentMethod = async data => {
		const paymentData = {
			TokenAlias: data.tokenAlias || '',
			PaymentMethodId: data.paymentMethodId,
			Revision: data.revision,
			Name: data.name || '',
			Street: data.street || '',
			Zip: data.zip || '',
			SetAsDefault: !!data.setAsDefault || !!data.isDefaultPaymentMethod,
		};

		if (toLower(data.tokenType) === 'cc') {
			paymentData.Exp = data.exp;
		} else {
			paymentData.AccountType = data.accountType;
		}

		const savePayment = await this.httpService.post(endpoint + 'UpdatePaymentMethod', paymentData, this.options);
		return { success: true, message: '', ref: savePayment.refNum };
	};

	deleteCustomerPaymentMethod = async PaymentMethodId => {
		const savePayment = await this.httpService.post(
			endpoint + 'DeletePaymentMethod',
			{ PaymentMethodId },
			this.options
		);
		return { success: true, message: '', ref: savePayment.RefNum };
	};

	getCustomerRecurringSchedules = async CustomerId => {
		const filter = {
			SortOrder: 'Descending',
			Filters: {
				CustomerId,
			},
		};
		const response = await this.httpService.post(endpoint + 'ListSchedules', filter, this.options);
		return await this.parseScheduleResults(this.parseResponse(response));
	};

	getRecurringTransactions = async (
		ScheduleId,
		fields = viewPaymentsFields,
		filters = {},
		PageSize = 20,
		NextToken = ''
	) => {
		const filter = {
			PageSize,
			SortOrder: 'Descending',
			Filters: {
				ScheduleId,
			},
		};

		if (NextToken) {
			filter.NextToken = NextToken;
		}

		const response = await this.httpService.post(endpoint + 'ListTransactions', filter, this.options);

		const nextToken = get(response, 'NextToken', '');
		const transactions = get(response, 'Transactions', []);

		let refNums = [];
		each(transactions, ({ GatewayRefNum }) => {
			if (GatewayRefNum) {
				refNums.push(GatewayRefNum);
			}
		});

		if (isEmpty(refNums)) {
			return this.transactionService.parseResult({
				xResult: 'S',
				xStatus: 'Success',
				xError: '',
				xRefNum: response.RefNum,
				xReportingMaxTransactions: PageSize,
				xRecordsReturned: 0,
				xReportData: [],
				nextToken,
			});
		}

		if (isEmpty(filters)) {
			filters = {
				xRefNum: refNums.join(','),
				xCommand: 'Report:Transactions',
			};
		} else if (!filters.xRefNum && !filters.xBeginDate && !filters.xEndDate) {
			filters.xRefNum = refNums.join(',');
		}

		const result = await this.transactionService.filterTransactionsRequest(filters, 0, fields);
		result.xReportingMaxTransactions = nextToken ? PageSize : PageSize + 1;
		result.nextToken = nextToken;

		return await this.transactionService.parseResult(result);
	};

	getRecurringTransactionsAll = async (ScheduleId, NextToken = null, fields = viewPaymentsFields) => {
		const filter = {
			NextToken,
			PageSize: 1000,
			SortOrder: 'Descending',
			Filters: {
				ScheduleId,
			},
		};

		let finalResponse = await this.httpService.post(endpoint + 'ListTransactions', filter, this.options);

		filter.NextToken = finalResponse.NextToken;

		const transactions = get(finalResponse, 'Transactions', []);
		let refNums = [];

		each(transactions, ({ GatewayRefNum }) => {
			if (GatewayRefNum) {
				refNums.push(GatewayRefNum);
			}
		});

		while (filter.NextToken) {
			const response = await this.httpService.post(endpoint + 'ListTransactions', filter, this.options);
			filter.NextToken = response.NextToken;

			const responseTransactions = get(finalResponse, 'Transactions', []);

			each(responseTransactions, ({ GatewayRefNum }) => {
				if (GatewayRefNum) {
					refNums.push(GatewayRefNum);
				}
			});
		}
		delete finalResponse.nextToken;

		if (isEmpty(refNums)) {
			return this.transactionService.parseResult({
				xResult: 'S',
				xStatus: 'Success',
				xError: '',
				xRefNum: finalResponse.RefNum,
				xReportingMaxTransactions: filter.PageSize,
				xRecordsReturned: 0,
				xReportData: [],
			});
		}

		const result = await this.transactionService.filterTransactionsRequest(
			{
				xRefNum: refNums.join(','),
				xCommand: 'Report:Transactions',
			},
			0,
			fields
		);

		return await this.transactionService.parseResult(result);
	};

	filterRecurringSchedulesRequest = async (filter, maxRecords = 0) => {
		const response = await this.httpService.post(endpoint + 'ListSchedules', filter, this.options);
		return await this.parseScheduleResults(this.parseResponse(response), maxRecords);
	};

	filterRecurringSchedulesAll = async (data, filter) => {
		filter = clone(filter);
		filter.PageSize = 1000;
		let finalResponse = await this.httpService.post(endpoint + 'ListSchedules', filter, this.options);
		const parsedFinalResponse = await this.parseScheduleResults(this.parseResponse(finalResponse), filter.PageSize);
		combineCustomerData(parsedFinalResponse, data);
		filter.NextToken = parsedFinalResponse.nextToken;

		while (filter.NextToken) {
			const response = await this.httpService.post(endpoint + 'ListSchedules', filter, this.options);
			const parsedResponse = await this.parseScheduleResults(this.parseResponse(response), filter.PageSize);
			filter.NextToken = parsedResponse.nextToken;
			combineCustomerData(parsedFinalResponse, parsedResponse);
		}
		delete parsedFinalResponse.nextToken;
		return parsedFinalResponse;
	};

	mapDefaultRetryCount = row => {
		const defaultRetryCount = 5;
		if (!row.failedTransactionRetryTimes) {
			row.failedTransactionRetryTimes = defaultRetryCount;
		} else if (row.failedTransactionRetryTimes < 0) {
			row.failedTransactionRetryTimes = null;
		}
		return row;
	};

	getCustomerTransactions = async (
		customerId,
		beginDate,
		endDate = moment()
			.endOf('day')
			.format(apiDateTimeFormat)
	) => {
		const filter = {
			xCustom01: `a=${customerId}`,
			xCommand: 'report:all',
			xBeginDate: beginDate,
			xEndDate: endDate,
		};
		const result = await this.transactionService.filterTransactionsRequest(
			filter,
			10,
			this.transactionService.getTransactionFields()
		);
		return await this.parseTransactionResults(result);
	};
	updateScheduleRetryAmount = async data => {
		const scheduleData = {
			Revision: data.Revision,
			ScheduleId: data.ScheduleId,
			AfterMaxRetriesAction: data.AfterMaxRetriesAction,
			DaysBetweenRetries: data.DaysBetweenRetries,
			StartDate: data.StartDate,
			CalendarCulture: data.CalendarCulture,
		};

		if (parseInt(data.FailedTransactionRetryTimes) >= 0) {
			scheduleData.FailedTransactionRetryTimes =
				data.FailedTransactionRetryTimes === 0 ? -1 : data.FailedTransactionRetryTimes;
		}
		const response = await this.httpService.post(endpoint + 'UpdateSchedule', scheduleData, this.options);
		const saveSchedule = await this.parseResponse(response);
		return { success: true, message: '', ref: saveSchedule.refNum };
	};

	mapFieldsToScheduleData = (data, scheduleData) => {
		times(19, i => {
			const oneBasedIndex = padStart(i + 1, 2, 0);
			const field = `custom${oneBasedIndex}`;
			const value = data[field];
			if (value) {
				scheduleData[field] = value;
			}
		});
	};

	addCustomerRecurringSchedule = async data => {
		const scheduleData = {
			CustomerId: data.customerId,
			Amount: data.amount,
			CalendarCulture: data.calendarCulture,
			CustReceipt: !!data.custReceipt,
			Description: data.description || '',
			intervalCount: data.intervalCount,
			IntervalType: data.intervalType,
			ScheduleName: data.scheduleName || '',
			SkipSaturdayAndHolidays: !!data.skipSaturdayAndHolidays,
			StartDate: data.startDate === today[data.calendarCulture] ? '' : data.startDate,
			UseDefaultPaymentMethodOnly: !!data.useDefaultPaymentMethodOnly,
			AfterMaxRetriesAction: data.afterMaxRetriesAction,
			AllowInitialTransactionToDecline: data.allowInitialTransactionToDecline,
			DaysBetweenRetries: data.daysBetweenRetries,
			Invoice: data.invoice,
			NewPaymentMethod: data.newPaymentMethod || null,
			NewCustomer: data.newCustomer || null,
			AllowNonAuthenticated: true,
		};
		if (data.paymentMethodId) {
			scheduleData.PaymentMethodId = data.paymentMethodId;
		}
		this.mapCustomFieldsToRequest(data, scheduleData);

		if (parseInt(data.failedTransactionRetryTimes) >= 0) {
			scheduleData.FailedTransactionRetryTimes =
				data.failedTransactionRetryTimes === 0 ? -1 : data.failedTransactionRetryTimes;
		}

		if (data._meta.until === until.ENDDATE) {
			scheduleData.EndDate = data.endDate;
		} else {
			scheduleData.TotalPayments = data._meta.until === until.NEVER ? '' : data.totalPayments || '';
		}

		this.addScheduleRule(scheduleData, data);

		const response = await this.httpService.post(endpoint + 'CreateSchedule', scheduleData, this.options);

		const saveSchedule = await this.parseResponse(response);
		return { success: true, message: '', ref: saveSchedule.refNum, customerId: saveSchedule.customerId };
	};

	mapCustomFieldsToRequest = (data, scheduleData) => {
		each(data, (value, key) => {
			const isCustomFieldKey = !isNaN(parseInt(split(toLower(key), 'custom')[1])) && startsWith(toLower(key), 'custom');

			if (value && isCustomFieldKey) {
				scheduleData[replace(startCase(key), /\s/g, '')] = value;
			}
		});
	};

	updateCustomerRecurringSchedule = async (data, isCustomerUpdated = true) => {
		const scheduleData = {
			Amount: data.amount,
			CalendarCulture: data.calendarCulture,
			CustReceipt: !!data.custReceipt,
			Description: data.description || '',
			ScheduleName: data.scheduleName || data.scheduleId,
			SkipSaturdayAndHolidays: !!data.skipSaturdayAndHolidays,
			StartDate: data.startDate,
			UseDefaultPaymentMethodOnly: !!data.useDefaultPaymentMethodOnly,
			Revision: isCustomerUpdated ? data.revision + 1 : data.revision,
			ScheduleId: data.scheduleId,
			AfterMaxRetriesAction: data.afterMaxRetriesAction,
			DaysBetweenRetries: data.daysBetweenRetries,
			Invoice: data.invoice,
			PaymentMethodId: data.paymentMethodId,
		};

		this.mapCustomFieldsToRequest(data, scheduleData);

		if (parseInt(data.failedTransactionRetryTimes) >= 0) {
			scheduleData.FailedTransactionRetryTimes =
				data.failedTransactionRetryTimes === 0 ? -1 : data.failedTransactionRetryTimes;
		}

		if (data._meta.until === until.ENDDATE) {
			scheduleData.EndDate = data.endDate;
		} else {
			scheduleData.TotalPayments = data._meta.until === until.NEVER ? '' : data.totalPayments || '';
		}

		this.addScheduleRule(scheduleData, data);

		const response = await this.httpService.post(endpoint + 'UpdateSchedule', scheduleData, this.options);
		const saveSchedule = await this.parseResponse(response);
		return { success: true, message: '', ref: saveSchedule.refNum };
	};

	addScheduleRule = (scheduleData, { startDate, startDateRawDate, calendarCulture, _meta: { specificDayOfWeek } }) => {
		if (specificDayOfWeek) {
			scheduleData.ScheduleRule = { RuleType: 'Nth' };

			if (calendarCulture === calendarTypes.HEBREW) {
				const { day, dayOfMonth } = getHebrewDateValues(startDateRawDate);

				scheduleData.ScheduleRule.N = Math.ceil(parseInt(dayOfMonth) / 7);
				scheduleData.ScheduleRule.NthOfType = day;
			} else {
				scheduleData.ScheduleRule.N = Math.ceil(moment(startDate, apiDateFormat).format('DD') / 7);
				scheduleData.ScheduleRule.NthOfType = moment(startDate, apiDateFormat).format('dddd');
			}

			if (scheduleData.ScheduleRule.RuleType === 'Nth' && scheduleData.ScheduleRule.N === 5) {
				scheduleData.ScheduleRule.N = -1;
			}
		}
	};

	deleteCustomerRecurringSchedule = async ScheduleId => {
		const response = await this.httpService.post(endpoint + 'DeleteSchedule', { ScheduleId }, this.options);
		const removeSchedule = await this.parseResponse(response);
		return { success: true, message: '', ref: removeSchedule.refNum };
	};

	activateCustomerRecurringSchedule = async (ScheduleId, isActive) => {
		const response = await this.httpService.post(
			endpoint + `${isActive ? 'Enable' : 'Disable'}Schedule`,
			{ ScheduleId },
			this.options
		);
		const schedule = await this.parseResponse(response);
		return { success: true, message: '', ref: schedule.refNum };
	};

	filterUpcomingPayments = async (scheduleId, paymentsRemaining, amount, currency, calendarCulture) => {
		let upcomingPaymentsCount = paymentsRemaining;
		if (!upcomingPaymentsCount || upcomingPaymentsCount > 13) {
			upcomingPaymentsCount = 13;
		}
		const filter = {
			ScheduleId: scheduleId,
			NumberOfPayments: upcomingPaymentsCount,
			CalendarCulture: calendarCulture,
		};
		const response = await this.httpService.post(endpoint + 'GetUpcomingPaymentDates', filter, this.options);
		return await this.parseUpcomingPaymentsResult(this.parseResponse(response), paymentsRemaining, amount, currency);
	};

	getFullRecurringSchedule = async scheduleId => {
		const filter = {
			ScheduleId: scheduleId,
		};
		const response = await this.httpService.post(endpoint + 'GetSchedule', filter, this.options);
		return await this.parseFullSchedule(this.parseResponse(response));
	};

	describeMerchant = async () => {
		const response = await this.httpService.post(endpoint + 'DescribeMerchant', {}, this.options);
		return await this.parseResponse(response);
	};

	updateMerchant = async data => {
		const merchantInfo = {
			Revision: data.revision || 1,
			ScheduleReportSettings: {
				Enabled: !!data.scheduleReportSettings.enabled,
				ShowDetails: !!data.scheduleReportSettings.showDetails,
				EmailAddresses: data.scheduleReportSettings.emailAddresses,
			},
			MaxRetriesReachedNotification: {
				Enabled: data.maxRetriesReachedNotification.enabled,
				EmailAddresses: data.maxRetriesReachedNotification.emailAddresses,
			},
		};
		const response = await this.httpService.post(endpoint + 'UpdateMerchant', merchantInfo, this.options);
		return await this.parseResponse(response);
	};

	parseCustomerResults = async (result, maxRecords = 0) => {
		if (result) {
			result.xRecordsReturned = result.xReportData.length;
			if (result.xReportData) {
				result.xReportData = result.xReportData.filter(({ customerId }) => customerId);
				result.xReportData = await Promise.all(map(result.xReportData, this.parseCustomerResult));
				result.xReportingMaxCustomers = result.xReportData.length + maxRecords;
			}
		}
		return result;
	};

	parseCustomerResult = async result => {
		if (result) {
			if (result.basicScheduleData) {
				try {
					if (result.basicScheduleData && result.basicScheduleData.length > 0) {
						const activeSchedules = filter(result.basicScheduleData, ({ isActive }) => isActive);
						const firstActiveSchedule = activeSchedules[0];
						result.recurringSchedule =
							activeSchedules.length > 1
								? `${activeSchedules.length} plans`
								: firstActiveSchedule
								? firstActiveSchedule.scheduleName || 'No name'
								: '';
					}
				} catch (e) {
					//intentionally empty catch block
				}
			}
			result.billPhone = result.billPhone || result.billPhoneNumber;
		}
		return result;
	};

	parseScheduleResults = async (result, maxRecords = 0) => {
		if (result) {
			result.xRecordsReturned = result.xReportData.length;
			if (result.xReportData) {
				result.xReportData = result.xReportData.filter(({ scheduleId }) => scheduleId);
				result.xReportData = await Promise.all(map(result.xReportData, this.parseScheduleResult));
				result.xReportingMaxSchedules = result.xReportData.length + maxRecords;
			}
		}
		return result;
	};

	parseFullSchedule = async result => {
		if (result) {
			if (result.xReportData) {
				result = await Promise.all(map(result, this.parseScheduleResult));
			}
		}
		return result;
	};

	parsePaymentResults = async (result, defaultPaymentMethodId) => {
		if (result) {
			result.xRecordsReturned = result.xReportData.length;
			if (result.xReportData) {
				result.xReportData = result.xReportData.filter(({ paymentMethodId }) => paymentMethodId);
				result.xReportData = await Promise.all(
					map(result.xReportData, paymentMethod => this.parsePaymentResult(paymentMethod, defaultPaymentMethodId))
				);
			}
		}
		return result;
	};

	parsePaymentResult = async (result, defaultPaymentMethodId) => {
		result.isDefaultPaymentMethod = result.paymentMethodId === defaultPaymentMethodId;
		return result;
	};

	parseScheduleResult = async result => {
		if (result) {
			const principal = this.principal();
			const currency = (principal && principal.idInfo && principal.idInfo.xMerchantCurrency) || 'USD';

			result.amount = parseFloat(result.amount);
			result.intervalCount = parseInt(result.intervalCount);
			result.frequency = `Every ${result.intervalCount} ${result.intervalType}${result.intervalCount > 1 ? 's' : ''}`;
			result.totalPayments = (result.totalPayments && parseInt(result.totalPayments)) || '';
			result.paymentsProcessed = parseInt(result.paymentsProcessed) || 0;
			result.remainingCharges = result.totalPayments ? result.totalPayments - result.paymentsProcessed : 'Indefinite';
			result.remainingBalance =
				result.remainingCharges === 'Indefinite' ? 'Indefinite' : result.amount * result.remainingCharges;
			result.dateCreated =
				result.createdDate &&
				(await apiToLocalMoment(result.createdDate, apiShortDateTimeFormat)).format(displayShortDateTimeFormat);
			result.nextBillingDate = apiToDisplay(result.nextScheduledRunTime);
			result.until = result.endDate ? until.ENDDATE : result.totalPayments ? until.PAYMENTS : until.NEVER;
			result.displayStartDate = apiToDisplay(result.startDate);
			result.endDate = result.totalPayments ? result.lastProjectedPaymentDate : result.endDate;
			result.displayEndDate = apiToDisplay(result.endDate);
			result.isActive = !!result.isActive;
			result.lastTransactionStatus = result.lastTransactionStatus;
			result.currency = result.currency || currency;
			result.billName = `${result.billFirstName || ''} ${result.billLastName || ''}`;
			result.useDefaultPaymentMethodOnly = result.useDefaultCardOnly;
		}
		return result;
	};

	parseUpcomingPaymentsResult = async (result, paymentsRemaining, amount, currency) => {
		if (result) {
			result.xRecordsReturned = result.xReportData.length;
			result.xReportData = map(result.xReportData, (item, idx) => {
				return {
					dateMoment: moment(item, ApplicationSettings.apiDateFormat),
					date: apiToDisplay(item),
					paymentsRemaining: paymentsRemaining ? paymentsRemaining - (idx + 1) : '-',
					amount,
					currency,
				};
			});
			result.xReportData = orderBy(result.xReportData, ['dateMoment'], ['asc']);
			result.xReportData = slice(result.xReportData, 0, 12);
		}
		return result;
	};

	parseTransactionResults = async result => {
		if (result && result.xReportData) {
			result.xReportData = await Promise.all(map(result.xReportData, this.parseTransactionResult));
		}
		return result;
	};

	parseTransactionResult = async (result, _, results) => {
		if (result) {
			const isApproved = toLower(result.xResponseResult) === 'approved';
			const isVoid = isApproved && result.xVoid === '1';
			const related = filter(
				results,
				item => item.xResponseRefNum === result.xRefNum && item.xRefNum !== result.xRefNum
			);
			let previouslyRefundedAmount = 0;
			let hasRefunds = false;
			each(related, item => {
				if (
					split(toLower(item.xCommand), ':')[1] === 'refund' &&
					toLower(item.xResponseResult) === 'approved' &&
					item.xVoid !== '1'
				) {
					previouslyRefundedAmount += Math.abs(item.xAmount) * 10;
					hasRefunds = true;
				}
			});
			previouslyRefundedAmount = this.toCurrency(previouslyRefundedAmount);
			const [cardType, commandType] = split(toLower(result.xCommand), ':');
			const isRefund = commandType === 'refund';
			const isRefunded = hasRefunds;
			const canRefund =
				isApproved &&
				(commandType === 'sale' || commandType === 'capture' || commandType === 'splitcapture') &&
				!isVoid &&
				some(['cc', 'check'], item => item === cardType) &&
				this.toCurrency(result.xAmount * 10) > previouslyRefundedAmount;
			const canRefundAmount = canRefund ? this.toCurrency((result.xAmount * 10 - previouslyRefundedAmount) / 10) : 0;
			const isAuthOnly = !isVoid && !isRefund && !isRefunded && commandType === 'authonly';
			result.transactionStatus = {
				isVoid,
				isRefund,
				isRefunded,
				isAuthOnly,
				canRefundAmount,
				previouslyRefundedAmount,
			};
		}

		return result;
	};

	parseResponse = (response, needsFormating = false) => {
		const result = transform(response, (acc, value, key) => {
			if (isArray(value) && key !== 'BasicScheduleData') {
				acc.xReportData = map(value, obj => {
					if (isPlainObject(obj)) {
						const transformedObj = transform(obj, (itemAcc, item, itemKey) => {
							if (isArray(item)) {
								itemAcc[camelCase(itemKey)] = [];
								each(item, (arr, arrIndex) => {
									itemAcc[camelCase(itemKey)][arrIndex] = {};
									each(arr, (arrValue, arrKey) => {
										itemAcc[camelCase(itemKey)][arrIndex][camelCase(arrKey)] = arrValue;
									});

									if (
										itemAcc[camelCase(itemKey)][arrIndex].scheduleId &&
										!itemAcc[camelCase(itemKey)][arrIndex].scheduleName
									) {
										itemAcc[camelCase(itemKey)][arrIndex].scheduleName =
											itemAcc[camelCase(itemKey)][arrIndex].scheduleId;
									}
								});
							} else {
								itemAcc[camelCase(itemKey)] = item;
							}
						});

						if (transformedObj.scheduleId && !transformedObj.scheduleName) {
							transformedObj.scheduleName = transformedObj.scheduleId;
						}

						return transformedObj;
					} else {
						return obj;
					}
				});
			} else if (isPlainObject(value)) {
				acc[camelCase(key)] = {};
				each(value, (objValue, objKey) => {
					acc[camelCase(key)][camelCase(objKey)] = objValue;
				});
			} else if (key === 'BasicScheduleData') {
				acc[camelCase(key)] = [];
				each(value, (obj, objIndex) => {
					acc[camelCase(key)][objIndex] = {};
					each(obj, (objValue, objKey) => {
						acc[camelCase(key)][objIndex][camelCase(objKey)] = objValue;
					});

					if (acc[camelCase(key)][objIndex].scheduleId && !acc[camelCase(key)][objIndex].scheduleName) {
						acc[camelCase(key)][objIndex].scheduleName = acc[camelCase(key)][objIndex].scheduleId;
					}
				});
			} else {
				acc[camelCase(key)] = value;
			}
		});
		if (needsFormating) {
			const customer = {};
			each(result, (value, key) => {
				if (!includes(['refNum', 'result', 'error'], key)) {
					customer[key] = value;
					delete result[key];
				}
			});
			result.xReportData = [];
			result.xReportData.push(customer);
		}
		return result;
	};

	toCurrency = value => parseFloat(value.toFixed(2));

	prepareExportData = (data, paymentMethods, showToken) => {
		const newRows = [];
		each(data.xReportData, customer => {
			this.mapPaymentMethodsToCustomer(newRows, customer, paymentMethods, showToken);
		});
		return newRows;
	};

	mapPaymentMethodsToCustomer = (newRows, row, paymentMethods, showToken) => {
		const customerPaymentMethods = filter(
			paymentMethods.xReportData,
			paymentMethod => paymentMethod.customerId === row.customerId
		);

		let defaultPaymentMethod;
		if (row.defaultPaymentMethodId) {
			defaultPaymentMethod = find(customerPaymentMethods, pm => pm.paymentMethodId === row.defaultPaymentMethodId);
		} else {
			defaultPaymentMethod = find(customerPaymentMethods, paymentMethod => paymentMethod.isDefaultPaymentMethod);
		}
		if (defaultPaymentMethod) {
			row.isDefaultPaymentMethod = true;
		}

		// Create an array for non-default payment methods
		let nonDefaultPaymentMethods = [];
		each(customerPaymentMethods, paymentMethod => {
			if (paymentMethod.paymentMethodId !== defaultPaymentMethod.paymentMethodId) {
				nonDefaultPaymentMethods.push(
					this.addRowPaymentMethodData(
						paymentMethod,
						omit({ ...row, isPaymentMethod: true }, ['basicScheduleData', 'recurringSchedule']),
						showToken
					)
				);
			}
		});

		// Add non-default payment methods to the row
		row.nonDefaultPaymentMethods = nonDefaultPaymentMethods;

		newRows.push(
			this.addRowPaymentMethodData(
				defaultPaymentMethod,
				omit({ ...row }, ['basicScheduleData', 'recurringSchedule']),
				showToken
			)
		);
	};

	addRowPaymentMethodData = (paymentMethod, row, showToken) => {
		if (paymentMethod) {
			row.avsZip = paymentMethod.zip;
			row.avsStreet = paymentMethod.street;
		}
		row.paymentMethodDetails = paymentMethod;
		let method = get(paymentMethod, 'tokenType', null);
		row.paymentMethod = isEmpty(method) ? method : method === paymentMethodType.CC ? 'Credit' : 'Check';
		if (paymentMethod && showToken) {
			row.token = paymentMethod.token;
		}
		const expiry = !paymentMethod ? '' : paymentMethod.exp;
		const expiryMoment = !expiry
			? !row.paymentMethod
				? moment(maxValidYear)
				: moment(maxValidYear - 1)
			: moment(expiry, cardExpiryDateFormat, true).isValid() &&
			  moment(expiry, cardExpiryDateFormat, true).endOf('month');
		row.paymentMethodExpiry = this.formattedPaymentMethodExpiry(expiry);
		row.paymentMethodExpiryMoment = expiryMoment;
		row.isPaymentMethodExpired = this.isExpired(expiry)
			? 'Expired'
			: this.isAboutToExpire(expiry)
			? 'About to expire'
			: '';
		row.maskedCardCardNumber = get(paymentMethod, 'maskedNumber', '');
		return row;
	};

	transferCustomerDatabase = async (midFrom, midTo, ticketNumber, merchantEmail, customerIds) => {
		const response = await this.httpService.post(
			endpoint + 'TransferCustomerDatabase',
			{ midFrom, midTo, ticketNumber, merchantEmail, customerIds },
			this.options
		);
		return await this.parseResponse(response);
	};
}

const customerService = new CustomerService(httpService, transactionService, principalService);

export default customerService;
