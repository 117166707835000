import { Auth } from 'aws-amplify';
import { get } from 'lodash';

export default async function currentCognitoToken() {
	try {
		const data = await Auth.currentAuthenticatedUser();
		return get(data, 'signInUserSession.idToken.jwtToken', '');
	} catch (e) {
		return '';
	}
}

