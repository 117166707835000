import React, { Fragment, Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, NavLink } from 'react-router-dom';
import { get, map, find, endsWith } from 'lodash';

import Route from '../../routing/Route';
import GiftActivity from './GiftActivity';
import GiftSummary from './GiftSummary';
import GiftLiability from './GiftLiability';
import { Authorize, OutsideClick } from '../../Common/utilities';
import sectionKeys from '../../routing/sections';
import { principalService } from '../../Common/services';
let principal = principalService.get();
principalService.subscribe(data => {
	principal = data;
});

class EmptyState extends Component {
	render() {
		const { match, location } = this.props;
		const authorizedRoute = find(routes, ({ section }) => principal && principal.hasAccess[section]);
		if (authorizedRoute) {
			return <Redirect to={`${match.url}${authorizedRoute.path}`} />;
		}
		return <NoMatch location={location} />;
	}
}
EmptyState.propTypes = { match: PropTypes.object, location: PropTypes.object };

class NoMatch extends Component {
	render() {
		const { location } = this.props;
		return (
			<div className="l--content">
				<h3>
					Not found <code>{location.pathname}</code>
				</h3>
			</div>
		);
	}
}
NoMatch.propTypes = { location: PropTypes.object };

const routes = [
	{
		path: '',
		exact: true,
		component: EmptyState,
	},
	{
		path: '/activity',
		exact: true,
		component: GiftActivity,
		section: sectionKeys.giftActivity,
		title: 'Gift Card Activity',
		mobileTitle: 'Gift Card Activity',
		linkTitle: 'Activity',
	},
	{
		path: '/summary',
		component: GiftSummary,
		section: sectionKeys.giftSummary,
		title: 'Gift Card Summary',
		mobileTitle: 'Gift Card Summary',
		linkTitle: 'Summary',
	},
	{
		path: '/liability',
		component: GiftLiability,
		section: sectionKeys.giftLiability,
		title: 'Gift Card Liability Report',
		mobileTitle: 'Gift Card Liability',
		linkTitle: 'Liability',
	},
];

class GiftReport extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showLinksDropDown: false,
		};
		this.routeRef = createRef();
	}

	get linksActiveClass() {
		return this.state.showLinksDropDown ? 'is-active' : 'is-closed';
	}

	get activeTitle() {
		const {
			location: { pathname },
		} = this.props;
		return find(routes, ({ path }) => path && endsWith(pathname, path));
	}

	updateGrid = () => {
		const gridRef = get(this.routeRef, 'current.gridRef.current');
		if (gridRef) {
			gridRef.forceUpdate();
		}
	};

	toggleLinksDropDown = () => {
		this.setState(
			{
				showLinksDropDown: !this.state.showLinksDropDown,
			},
			this.updateGrid
		);
	};

	onCloseLinksDropDown = () => {
		if (!this.state.showLinksDropDown) {
			return;
		}
		this.setState(
			{
				showLinksDropDown: false,
			},
			this.updateGrid
		);
	};

	renderTitle = () => {
		const {
			match: { url },
		} = this.props;
		const { showLinksDropDown } = this.state;
		const { title: activeTitle, mobileTitle: activeMobileTitle } = this.activeTitle || {};
		return (
			<OutsideClick action={this.onCloseLinksDropDown} className="header__title__holder">
				<Fragment>
					<div className="pos--rel hide--from--med">
						<div
							onClick={this.toggleLinksDropDown}
							className={`header__title datatooltip--gift datatooltip--h--right type--wgt--medium ${this.linksActiveClass}`}
						>
							<span className="hide--to--med--inline">{activeTitle}</span>
							<span className="hide--from--med--inline">{activeMobileTitle}</span>
							<i
								className="icon icon--tiny icon--arrow--dropdown is-active align--v--neg--2 spc--left--xsml"
								data-tooltip="Additional gift reports"
							></i>
						</div>
						{showLinksDropDown ? (
							<div className={`header__title__menu ${this.linksActiveClass}`}>
								<ul className="header__title__menu__list">
									{map(routes, ({ path, title, section }) =>
										path && title !== activeTitle ? (
											<Authorize key={path} section={section}>
												<li className="header__title__menu__item">
													<NavLink
														onClick={this.onCloseLinksDropDown}
														activeClassName="is-active"
														className="header__title__menu__link"
														to={`${url}${path}`}
													>
														{title}
													</NavLink>
												</li>
											</Authorize>
										) : null
									)}
								</ul>
							</div>
						) : null}
					</div>
					<div className="header__title__tabs">
						{map(routes, ({ path, linkTitle, section }) =>
							path ? (
								<Authorize key={path} section={section}>
									<NavLink activeClassName="is-active" className="header__title__link" to={`${url}${path}`}>
										{linkTitle}
									</NavLink>
								</Authorize>
							) : null
						)}
					</div>
				</Fragment>
			</OutsideClick>
		);
	};
	render() {
		const {
			match: { path },
		} = this.props;

		return (
			<Switch>
				{map(routes, routeData =>
					routeData.hidden || routeData.disabled ? null : (
						<Route
							key={routeData.path}
							routeRef={this.routeRef}
							{...routeData}
							path={`${path}${routeData.path}`}
							componentProps={{ renderTitle: this.renderTitle }}
						/>
					)
				)}
				<Route component={NoMatch} />
			</Switch>
		);
	}
}

GiftReport.propTypes = {
	match: PropTypes.object,
	location: PropTypes.object,
};

export default GiftReport;
