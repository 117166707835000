import moment from 'moment';
import { find, replace, trim } from 'lodash';

import { NumberFilter, LastDigitsFilter } from 'common/components/filters';
import { SingleSelection } from 'common/components/selections';
import { getDate, localToApiMoment, createFilters, getFilterValues } from 'common/utilities';

const { checkDateFormat } = ApplicationSettings;

export const giftActivityFilter = createFilters([
	{
		key: 'date',
		getSelectionText: ({ key, startDate, endDate }, predefinedDates) => {
			const predefinedDate = find(predefinedDates, { key });
			if (predefinedDate) {
				return predefinedDate.displayValue;
			}
			try {
				return `${startDate.format(checkDateFormat)} - ${endDate.format(checkDateFormat)}`;
			} catch (e) {
				return 'Custom';
			}
		},
		values: {
			key: '90',
			startDate: moment()
				.startOf('day')
				.add(-90, 'days'),
			endDate: moment().endOf('day'),
		},
		props: {
			maxDaysRange: 100,
		},
	},
	{
		key: 'amount',
		name: 'Amount',
		values: {
			amount: null,
		},
		props: {
			prefix: '$',
		},
		component: NumberFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'referenceNumber',
		name: 'Reference Number',
		values: {
			referenceNumber: '',
		},
		component: NumberFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'giftCardNumber',
		name: 'Gift card number',
		values: {
			giftCardNumber: '',
		},
		props: {
			numberOfDigits: 19,
			placeholder: 'Gift Card number',
		},
		component: LastDigitsFilter,
		standalone: true,
		clearable: false,
		goButtonText: 'Search',
	},
]);

export const compileFilter = async (filters, filterDateFormat) => {
	const { date, amount, referenceNumber, giftCardNumber } = getFilterValues(filters);
	const beginDate = await localToApiMoment(date.startDate);
	const endDate = await localToApiMoment(date.endDate);

	const filter = {
		xCommand: 'Report:GiftApproved',
		xBeginDate: getDate(beginDate, filterDateFormat),
		xEndDate: getDate(endDate, filterDateFormat),
	};

	if (trim(amount.amount)) {
		filter.xAmount = 'ai' + amount.amount.toString();
	}

	if (trim(referenceNumber.referenceNumber)) {
		filter.xRefNum = 'a=' + referenceNumber.referenceNumber.toString();
	}

	if (trim(giftCardNumber.giftCardNumber)) {
		filter.xCardNum = 'a=' + replace(giftCardNumber.giftCardNumber, /\*/g, '');
	}

	return filter;
};
