import $ from 'jquery';
import './flexcal';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

class FlexcalWrapper extends Component {
	constructor(props) {
		super(props);
		this.flexcal = React.createRef();
	}

	update = () => {
		this.$node = $(this.flexcal.current);
		this.$node.flexcal({
			disabledDaysTooltip: this.props.disabledDaysTooltip,
			calendars: this.props.calendars,
			position: this.props.position,
			class: this.props.className,
			commit: this.props.onChange,
			filter: this.props.filter,
			current: this.props.current,
		});
	};

	componentDidMount = () => {
		this.update();
	};

	componentDidUpdate = () => {
		this.update();
	};

	shouldComponentUpdate(prevProps) {
		return prevProps.value !== this.props.value || !isEqual(prevProps.dependentValues, this.props.dependentValues);
	}

	render() {
		return (
			<span ref={this.flexcal}>
				<this.props.trigger value={this.props.value} />
			</span>
		);
	}

	componentWillUnmount() {
		if (this.$node) {
			this.$node.flexcal('destroy');
		}
	}
}

FlexcalWrapper.propTypes = {
	disabledDaysTooltip: PropTypes.string,
	calendars: PropTypes.arrayOf(PropTypes.string).isRequired,
	className: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	position: PropTypes.string,
	trigger: PropTypes.func.isRequired,
	filter: PropTypes.func,
	dependentValues: PropTypes.array,
	current: PropTypes.object,
};

export default FlexcalWrapper;
