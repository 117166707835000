import React from 'react';
import { withError } from 'common/components/error';
import { GridComponent } from 'common/components/grid';
import DownloadButtonCell from 'common/components/columns/formatters/download-button-cell';
import { MainFilterComponent } from 'common/components/filter';
import { ZebraRenderer } from 'common/components/row';
import { filter, get, includes, isEmpty, map, noop, orderBy, toLower } from 'lodash';
import statementService from 'common/services/statementService';
import { withCancelable } from 'common/components/cancelable';
import { principalService } from 'common/services';
import { func } from 'prop-types';
import { withLoader } from 'common/components/loader';

class StatementsGrid extends React.Component {
	constructor(props) {
		const principal = principalService.get();

		super(props);
		this.classes = {
			filter: '',
			gridHeader: 'flex--primary datatooltip--v--bottom flex--grow--1',
			filterHeader: 'display--n',
		};
		this.gridRef = React.createRef();
		this.components = {
			filter: MainFilterComponent,
			rowRenderer: ZebraRenderer,
			gridHeader: this.renderGridHeader,
			header: this.renderGridHeader,
		};
		this.state = {
			mid: get(principal, 'idInfo.xMerchantID', ''),
			data: null,
			fetchingData: true,
			columns: [
				{ key: 'fileName', name: 'File Name', initWidth: 100, visible: true },
				{
					key: 'year',
					name: 'Year',
					initWidth: 100,
					visible: true,
				},
				{
					key: 'download',
					name: 'Download',
					initWidth: 100,
					visible: true,
					formatter: ({ row }) => <DownloadButtonCell row={row} handleDownload={() => this.downloadStatement(row)} />,
				},
			],
			filters: [],
			activeFilters: [],
		};
	}
	renderGridHeader = () => {
		return (
			<div className="filter__container__header__item fullwidth">
				<input
					type="text"
					name="search"
					placeholder="Search"
					className="input input--med input--search"
					disabled={isEmpty(this.state.data)}
					onChange={({ target: { value } }) =>
						this.setState({
							filteredData: {
								xReportData: filter(this.state.data.xReportData, ({ fileName, year }) => {
									const lowerCaseValue = toLower(value);
									return includes(toLower(fileName), lowerCaseValue) || includes(toLower(year), lowerCaseValue);
								}),
							},
						})
					}
				/>
			</div>
		);
	};

	downloadStatement = async ({ fileName, year }) => {
		this.props.showLoader(true);
		try {
			const response = await this.props.makePendingRequest(
				statementService.loadStatement(this.state.mid, year, fileName),
				{}
			);
			const { fileBytes: base64File, fileName: responseFileName } = response;
			const link = document.createElement('a');
			link.href = `data:application/pdf;base64,${base64File}`;
			link.download = responseFileName;
			link.click();
		} catch (e) {
			this.props.handleError(e);
		}
		this.props.showLoader(false);
	};

	fetchData = async () => {
		try {
			this.setState({ fetchingData: true });
			const response = await this.props.makePendingRequest(statementService.loadStatements(this.state.mid), {});
			const data = {
				xReportData: map(orderBy(response.statements, ['year'], ['desc']), (item, index) => ({
					...item,
					gridRowNumber: index,
				})),
			};
			this.setState({ data, filteredData: data });
		} catch (e) {
			this.props.handleError(e);
		} finally {
			this.setState({ fetchingData: false });
		}
	};

	render() {
		const { data, columns, filters, activeFilters, fetchingData, filteredData } = this.state;

		return (
			<div>
				<GridComponent
					components={this.components}
					classes={this.classes}
					emptyMessage="No items found."
					fetchingData={fetchingData}
					filteredRows={get(filteredData, 'xReportData', [])}
					columns={columns}
					defaultColumns={columns}
					data={data}
					filters={filters}
					activeFilters={activeFilters}
					onChange={noop}
					fetchData={this.fetchData}
					title="Statements"
					type="statementsGrid"
					ref={this.gridRef}
				/>
			</div>
		);
	}
}
StatementsGrid.propTypes = {
	makePendingRequest: func.isRequired,
	showLoader: func.isRequired,
	handleError: func.isRequired,
};
export default withCancelable(withError(withLoader(StatementsGrid)));
