import React from 'react';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';

function AccountUpdater(props) {
	return (
		<div className="">
			<p className="spc--bottom--sml">
				Expired or inaccurate card data shouldn’t cut into your bottom line or your busy schedule. Account Updater keeps
				credit card data current, so you don’t miss out on sales.
			</p>
			<div className="spc--bottom--sml--alt">
				<button className="btn btn--sml btn--primary" onClick={() => props.history.push('features/account-updater')}>
					Activate Now
				</button>
			</div>
		</div>
	);
}
AccountUpdater.propTypes = {
	history: object.isRequired,
};
export default withRouter(AccountUpdater);
