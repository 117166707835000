/**
 * Copyright 2015 Tim Down.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * log4javascript
 *
 * log4javascript is a logging framework for JavaScript based on log4j
 * for Java. This file contains all core log4javascript code and is the only
 * file required to use log4javascript, unless you require support for
 * document.domain, in which case you will also need console.html, which must be
 * stored in the same directory as the main log4javascript.js file.
 *
 * Author: Tim Down <tim@log4javascript.org>
 * Version: 1.4.13
 * Edition: log4javascript
 * Build date: 23 May 2015
 * Website: http://log4javascript.org
 */

function isUndefined(obj) {
	return typeof obj == 'undefined';
}

/* ---------------------------------------------------------------------- */
// Custom event support

function EventSupport() {}

EventSupport.prototype = {
	eventTypes: [],
	eventListeners: {},
	setEventTypes: function(eventTypesParam) {
		if (eventTypesParam instanceof Array) {
			this.eventTypes = eventTypesParam;
			this.eventListeners = {};
			for (let i = 0, len = this.eventTypes.length; i < len; i++) {
				this.eventListeners[this.eventTypes[i]] = [];
			}
		} else {
			handleError('log4javascript.EventSupport [' + this + ']: setEventTypes: eventTypes parameter must be an Array');
		}
	},

	addEventListener: function(eventType, listener) {
		if (typeof listener == 'function') {
			if (!array_contains(this.eventTypes, eventType)) {
				handleError(
					'log4javascript.EventSupport [' + this + "]: addEventListener: no event called '" + eventType + "'"
				);
			}
			this.eventListeners[eventType].push(listener);
		} else {
			handleError('log4javascript.EventSupport [' + this + ']: addEventListener: listener must be a function');
		}
	},

	removeEventListener: function(eventType, listener) {
		if (typeof listener == 'function') {
			if (!array_contains(this.eventTypes, eventType)) {
				handleError(
					'log4javascript.EventSupport [' + this + "]: removeEventListener: no event called '" + eventType + "'"
				);
			}
			array_remove(this.eventListeners[eventType], listener);
		} else {
			handleError('log4javascript.EventSupport [' + this + ']: removeEventListener: listener must be a function');
		}
	},

	dispatchEvent: function(eventType, eventArgs) {
		if (array_contains(this.eventTypes, eventType)) {
			const listeners = this.eventListeners[eventType];
			for (let i = 0, len = listeners.length; i < len; i++) {
				listeners[i](this, eventType, eventArgs);
			}
		} else {
			handleError('log4javascript.EventSupport [' + this + "]: dispatchEvent: no event called '" + eventType + "'");
		}
	},
};

/* -------------------------------------------------------------------------- */

const emptyFunction = function() {};
const newLine = '\r\n';

// Create main log4javascript object; this will be assigned public properties
function Log4JavaScript() {}
Log4JavaScript.prototype = new EventSupport();

const log4javascript = new Log4JavaScript();
log4javascript.version = '1.4.13';
log4javascript.edition = 'log4javascript';

/* -------------------------------------------------------------------------- */
// Utility functions

function toStr(obj) {
	if (obj && obj.toString) {
		return obj.toString();
	} else {
		return String(obj);
	}
}

function getExceptionMessage(ex) {
	if (ex.message) {
		return ex.message;
	} else if (ex.description) {
		return ex.description;
	} else {
		return toStr(ex);
	}
}

// Gets the portion of the URL after the last slash
function getUrlFileName(url) {
	const lastSlashIndex = Math.max(url.lastIndexOf('/'), url.lastIndexOf('\\'));
	return url.substr(lastSlashIndex + 1);
}

// Returns a nicely formatted representation of an error
function getExceptionStringRep(ex) {
	if (ex) {
		let exStr = 'Exception: ' + getExceptionMessage(ex);
		try {
			if (ex.lineNumber) {
				exStr += ' on line number ' + ex.lineNumber;
			}
			if (ex.fileName) {
				exStr += ' in file ' + getUrlFileName(ex.fileName);
			}
		} catch (localEx) {
			logLog.warn('Unable to obtain file and line information for error');
		}
		if (showStackTraces && ex.stack) {
			exStr += newLine + 'Stack trace:' + newLine + ex.stack;
		}
		return exStr;
	}
	return null;
}

function bool(obj) {
	return Boolean(obj);
}

const urlEncode =
	typeof window.encodeURIComponent != 'undefined'
		? function(str) {
				return encodeURIComponent(str);
		  }
		: function(str) {
				return escape(str)
					.replace(/\+/g, '%2B')
					.replace(/"/g, '%22')
					.replace(/'/g, '%27')
					.replace(/\//g, '%2F')
					.replace(/=/g, '%3D');
		  };

function array_remove(arr, val) {
	let index = -1;
	for (let i = 0, len = arr.length; i < len; i++) {
		if (arr[i] === val) {
			index = i;
			break;
		}
	}
	if (index >= 0) {
		arr.splice(index, 1);
		return true;
	} else {
		return false;
	}
}

function array_contains(arr, val) {
	for (let i = 0, len = arr.length; i < len; i++) {
		if (arr[i] == val) {
			return true;
		}
	}
	return false;
}

function extractBooleanFromParam(param, defaultValue) {
	if (isUndefined(param)) {
		return defaultValue;
	} else {
		return bool(param);
	}
}

function extractStringFromParam(param, defaultValue) {
	if (isUndefined(param)) {
		return defaultValue;
	} else {
		return String(param);
	}
}

function extractIntFromParam(param, defaultValue) {
	if (isUndefined(param)) {
		return defaultValue;
	} else {
		try {
			const value = parseInt(param, 10);
			return isNaN(value) ? defaultValue : value;
		} catch (ex) {
			logLog.warn('Invalid int param ' + param, ex);
			return defaultValue;
		}
	}
}

function extractFunctionFromParam(param, defaultValue) {
	if (typeof param == 'function') {
		return param;
	} else {
		return defaultValue;
	}
}

function isError(err) {
	return err instanceof Error;
}

/* ---------------------------------------------------------------------- */
// Simple logging for log4javascript itself

const logLog = {
	quietMode: false,

	debugMessages: [],

	setQuietMode: function(quietMode) {
		this.quietMode = bool(quietMode);
	},

	numberOfErrors: 0,

	alertAllErrors: false,

	setAlertAllErrors: function(alertAllErrors) {
		this.alertAllErrors = alertAllErrors;
	},

	debug: function(message) {
		this.debugMessages.push(message);
	},

	displayDebug: function() {
		alert(this.debugMessages.join(newLine));
	},

	warn: function() {},

	error: function(message, exception) {
		if (++this.numberOfErrors == 1 || this.alertAllErrors) {
			if (!this.quietMode) {
				let alertMessage = 'log4javascript error: ' + message;
				if (exception) {
					alertMessage += newLine + newLine + 'Original error: ' + getExceptionStringRep(exception);
				}
				alert(alertMessage);
			}
		}
	},
};
log4javascript.logLog = logLog;

log4javascript.setEventTypes(['load', 'error']);

function handleError(message, exception) {
	logLog.error(message, exception);
	log4javascript.dispatchEvent('error', { message: message, exception: exception });
}

log4javascript.handleError = handleError;

/* ---------------------------------------------------------------------- */

let enabled = !(
	typeof log4javascript_disabled != 'undefined' &&
	// eslint-disable-next-line
	log4javascript_disabled
);

log4javascript.setEnabled = function(enable) {
	enabled = bool(enable);
};

log4javascript.isEnabled = function() {
	return enabled;
};

let useTimeStampsInMilliseconds = true;

log4javascript.setTimeStampsInMilliseconds = function(timeStampsInMilliseconds) {
	useTimeStampsInMilliseconds = bool(timeStampsInMilliseconds);
};

log4javascript.isTimeStampsInMilliseconds = function() {
	return useTimeStampsInMilliseconds;
};

let showStackTraces = false;

log4javascript.setShowStackTraces = function(show) {
	showStackTraces = bool(show);
};

/* ---------------------------------------------------------------------- */
// Levels

const Level = function(level, name) {
	this.level = level;
	this.name = name;
};

Level.prototype = {
	toString: function() {
		return this.name;
	},
	equals: function(level) {
		return this.level == level.level;
	},
	isGreaterOrEqual: function(level) {
		return this.level >= level.level;
	},
};

Level.ALL = new Level(Number.MIN_VALUE, 'ALL');
Level.TRACE = new Level(10000, 'TRACE');
Level.DEBUG = new Level(20000, 'DEBUG');
Level.INFO = new Level(30000, 'INFO');
Level.WARN = new Level(40000, 'WARN');
Level.ERROR = new Level(50000, 'ERROR');
Level.FATAL = new Level(60000, 'FATAL');
Level.OFF = new Level(Number.MAX_VALUE, 'OFF');

log4javascript.Level = Level;

/* ---------------------------------------------------------------------- */
// Timers

function Timer(name, level) {
	this.name = name;
	this.level = isUndefined(level) ? Level.INFO : level;
	this.start = new Date();
}

Timer.prototype.getElapsedTime = function() {
	return new Date().getTime() - this.start.getTime();
};

/* ---------------------------------------------------------------------- */
// Loggers

const anonymousLoggerName = '[anonymous]';
const defaultLoggerName = '[default]';
const nullLoggerName = '[null]';
const rootLoggerName = 'root';

function Logger(name) {
	this.name = name;
	this.parent = null;
	this.children = [];

	let appenders = [];
	let loggerLevel = null;
	let isRoot = this.name === rootLoggerName;
	let isNull = this.name === nullLoggerName;

	let appenderCache = null;
	let appenderCacheInvalidated = false;

	this.addChild = function(childLogger) {
		this.children.push(childLogger);
		childLogger.parent = this;
		childLogger.invalidateAppenderCache();
	};

	// Additivity
	let additive = true;
	this.getAdditivity = function() {
		return additive;
	};

	this.setAdditivity = function(additivity) {
		const valueChanged = additive != additivity;
		additive = additivity;
		if (valueChanged) {
			this.invalidateAppenderCache();
		}
	};

	// Create methods that use the appenders variable in this scope
	this.addAppender = function(appender) {
		if (isNull) {
			handleError('Logger.addAppender: you may not add an appender to the null logger');
		} else {
			if (appender instanceof log4javascript.Appender) {
				if (!array_contains(appenders, appender)) {
					appenders.push(appender);
					appender.setAddedToLogger(this);
					this.invalidateAppenderCache();
				}
			} else {
				handleError("Logger.addAppender: appender supplied ('" + toStr(appender) + "') is not a subclass of Appender");
			}
		}
	};

	this.removeAppender = function(appender) {
		array_remove(appenders, appender);
		appender.setRemovedFromLogger(this);
		this.invalidateAppenderCache();
	};

	this.removeAllAppenders = function() {
		const appenderCount = appenders.length;
		if (appenderCount > 0) {
			for (let i = 0; i < appenderCount; i++) {
				appenders[i].setRemovedFromLogger(this);
			}
			appenders.length = 0;
			this.invalidateAppenderCache();
		}
	};

	this.getEffectiveAppenders = function() {
		if (appenderCache === null || appenderCacheInvalidated) {
			// Build appender cache
			const parentEffectiveAppenders = isRoot || !this.getAdditivity() ? [] : this.parent.getEffectiveAppenders();
			appenderCache = parentEffectiveAppenders.concat(appenders);
			appenderCacheInvalidated = false;
		}
		return appenderCache;
	};

	this.invalidateAppenderCache = function() {
		appenderCacheInvalidated = true;
		for (let i = 0, len = this.children.length; i < len; i++) {
			this.children[i].invalidateAppenderCache();
		}
	};

	this.log = function(level, params) {
		if (enabled && level.isGreaterOrEqual(this.getEffectiveLevel())) {
			// Check whether last param is an exception
			let exception;
			let finalParamIndex = params.length - 1;
			const lastParam = params[finalParamIndex];
			if (params.length > 1 && isError(lastParam)) {
				exception = lastParam;
				finalParamIndex--;
			}

			// Construct genuine array for the params
			const messages = [];
			for (let i = 0; i <= finalParamIndex; i++) {
				messages[i] = params[i];
			}

			const loggingEvent = new LoggingEvent(this, new Date(), level, messages, exception);

			this.callAppenders(loggingEvent);
		}
	};

	this.callAppenders = function(loggingEvent) {
		const effectiveAppenders = this.getEffectiveAppenders();
		for (let i = 0, len = effectiveAppenders.length; i < len; i++) {
			effectiveAppenders[i].doAppend(loggingEvent);
		}
	};

	this.setLevel = function(level) {
		// Having a level of null on the root logger would be very bad.
		if (isRoot && level === null) {
			handleError('Logger.setLevel: you cannot set the level of the root logger to null');
		} else if (level instanceof Level) {
			loggerLevel = level;
		} else {
			handleError(
				'Logger.setLevel: level supplied to logger ' + this.name + ' is not an instance of log4javascript.Level'
			);
		}
	};

	this.getLevel = function() {
		return loggerLevel;
	};

	this.getEffectiveLevel = function() {
		for (let logger = this; logger !== null; logger = logger.parent) {
			const level = logger.getLevel();
			if (level !== null) {
				return level;
			}
		}
	};

	this.group = function(name, initiallyExpanded) {
		if (enabled) {
			const effectiveAppenders = this.getEffectiveAppenders();
			for (let i = 0, len = effectiveAppenders.length; i < len; i++) {
				effectiveAppenders[i].group(name, initiallyExpanded);
			}
		}
	};

	this.groupEnd = function() {
		if (enabled) {
			const effectiveAppenders = this.getEffectiveAppenders();
			for (let i = 0, len = effectiveAppenders.length; i < len; i++) {
				effectiveAppenders[i].groupEnd();
			}
		}
	};

	const timers = {};

	this.time = function(name, level) {
		if (enabled) {
			if (isUndefined(name)) {
				handleError('Logger.time: a name for the timer must be supplied');
			} else if (level && !(level instanceof Level)) {
				handleError('Logger.time: level supplied to timer ' + name + ' is not an instance of log4javascript.Level');
			} else {
				timers[name] = new Timer(name, level);
			}
		}
	};

	this.timeEnd = function(name) {
		if (enabled) {
			if (isUndefined(name)) {
				handleError('Logger.timeEnd: a name for the timer must be supplied');
			} else if (timers[name]) {
				const timer = timers[name];
				const milliseconds = timer.getElapsedTime();
				this.log(timer.level, ['Timer ' + toStr(name) + ' completed in ' + milliseconds + 'ms']);
				delete timers[name];
			} else {
				logLog.warn('Logger.timeEnd: no timer found with name ' + name);
			}
		}
	};

	this.assert = function(expr) {
		if (enabled && !expr) {
			let args = [];
			for (let i = 1, len = arguments.length; i < len; i++) {
				args.push(arguments[i]);
			}
			args = args.length > 0 ? args : ['Assertion Failure'];
			args.push(newLine);
			args.push(expr);
			this.log(Level.ERROR, args);
		}
	};

	this.toString = function() {
		return 'Logger[' + this.name + ']';
	};
}

Logger.prototype = {
	trace: function() {
		this.log(Level.TRACE, arguments);
	},

	debug: function() {
		this.log(Level.DEBUG, arguments);
	},

	info: function() {
		this.log(Level.INFO, arguments);
	},

	warn: function() {
		this.log(Level.WARN, arguments);
	},

	error: function() {
		this.log(Level.ERROR, arguments);
	},

	fatal: function() {
		this.log(Level.FATAL, arguments);
	},

	isEnabledFor: function(level) {
		return level.isGreaterOrEqual(this.getEffectiveLevel());
	},

	isTraceEnabled: function() {
		return this.isEnabledFor(Level.TRACE);
	},

	isDebugEnabled: function() {
		return this.isEnabledFor(Level.DEBUG);
	},

	isInfoEnabled: function() {
		return this.isEnabledFor(Level.INFO);
	},

	isWarnEnabled: function() {
		return this.isEnabledFor(Level.WARN);
	},

	isErrorEnabled: function() {
		return this.isEnabledFor(Level.ERROR);
	},

	isFatalEnabled: function() {
		return this.isEnabledFor(Level.FATAL);
	},
};

Logger.prototype.trace.isEntryPoint = true;
Logger.prototype.debug.isEntryPoint = true;
Logger.prototype.info.isEntryPoint = true;
Logger.prototype.warn.isEntryPoint = true;
Logger.prototype.error.isEntryPoint = true;
Logger.prototype.fatal.isEntryPoint = true;

/* ---------------------------------------------------------------------- */
// Logger access methods

// Hashtable of loggers keyed by logger name
let loggers = {};
let loggerNames = [];

let ROOT_LOGGER_DEFAULT_LEVEL = Level.DEBUG;
let rootLogger = new Logger(rootLoggerName);
rootLogger.setLevel(ROOT_LOGGER_DEFAULT_LEVEL);

log4javascript.getRootLogger = function() {
	return rootLogger;
};

log4javascript.getLogger = function(loggerName) {
	// Use default logger if loggerName is not specified or invalid
	if (typeof loggerName != 'string') {
		loggerName = anonymousLoggerName;
		logLog.warn(
			'log4javascript.getLogger: non-string logger name ' + toStr(loggerName) + ' supplied, returning anonymous logger'
		);
	}

	// Do not allow retrieval of the root logger by name
	if (loggerName == rootLoggerName) {
		handleError('log4javascript.getLogger: root logger may not be obtained by name');
	}

	// Create the logger for this name if it doesn't already exist
	if (!loggers[loggerName]) {
		const logger = new Logger(loggerName);
		loggers[loggerName] = logger;
		loggerNames.push(loggerName);

		// Set up parent logger, if it doesn't exist
		const lastDotIndex = loggerName.lastIndexOf('.');
		let parentLogger;
		if (lastDotIndex > -1) {
			const parentLoggerName = loggerName.substring(0, lastDotIndex);
			parentLogger = log4javascript.getLogger(parentLoggerName); // Recursively sets up grandparents etc.
		} else {
			parentLogger = rootLogger;
		}
		parentLogger.addChild(logger);
	}
	return loggers[loggerName];
};

let defaultLogger = null;
log4javascript.getDefaultLogger = function() {
	if (!defaultLogger) {
		defaultLogger = createDefaultLogger();
	}
	return defaultLogger;
};

let nullLogger = null;
log4javascript.getNullLogger = function() {
	if (!nullLogger) {
		nullLogger = new Logger(nullLoggerName);
		nullLogger.setLevel(Level.OFF);
	}
	return nullLogger;
};

// Destroys all loggers
log4javascript.resetConfiguration = function() {
	rootLogger.setLevel(ROOT_LOGGER_DEFAULT_LEVEL);
	loggers = {};
};

/* ---------------------------------------------------------------------- */
// Logging events

const LoggingEvent = function(logger, timeStamp, level, messages, exception) {
	this.logger = logger;
	this.timeStamp = timeStamp;
	this.timeStampInMilliseconds = timeStamp.getTime();
	this.timeStampInSeconds = Math.floor(this.timeStampInMilliseconds / 1000);
	this.milliseconds = this.timeStamp.getMilliseconds();
	this.level = level;
	this.messages = messages;
	this.exception = exception;
};

LoggingEvent.prototype = {
	getThrowableStrRep: function() {
		return this.exception ? getExceptionStringRep(this.exception) : '';
	},
	getCombinedMessages: function() {
		return this.messages.length == 1 ? this.messages[0] : this.messages.join(newLine);
	},
	toString: function() {
		return 'LoggingEvent[' + this.level + ']';
	},
};

log4javascript.LoggingEvent = LoggingEvent;

/* ---------------------------------------------------------------------- */
// Layout prototype

const Layout = function() {};

Layout.prototype = {
	defaults: {
		loggerKey: 'logger',
		timeStampKey: 'timestamp',
		millisecondsKey: 'milliseconds',
		levelKey: 'level',
		messageKey: 'message',
		exceptionKey: 'exception',
		urlKey: 'url',
	},
	loggerKey: 'logger',
	timeStampKey: 'timestamp',
	millisecondsKey: 'milliseconds',
	levelKey: 'level',
	messageKey: 'message',
	exceptionKey: 'exception',
	urlKey: 'url',
	batchHeader: '',
	batchFooter: '',
	batchSeparator: '',
	returnsPostData: false,
	overrideTimeStampsSetting: false,
	useTimeStampsInMilliseconds: null,

	format: function() {
		handleError('Layout.format: layout supplied has no format() method');
	},

	ignoresThrowable: function() {
		handleError('Layout.ignoresThrowable: layout supplied has no ignoresThrowable() method');
	},

	getContentType: function() {
		return 'text/plain';
	},

	allowBatching: function() {
		return true;
	},

	setTimeStampsInMilliseconds: function(timeStampsInMilliseconds) {
		this.overrideTimeStampsSetting = true;
		this.useTimeStampsInMilliseconds = bool(timeStampsInMilliseconds);
	},

	isTimeStampsInMilliseconds: function() {
		return this.overrideTimeStampsSetting ? this.useTimeStampsInMilliseconds : useTimeStampsInMilliseconds;
	},

	getTimeStampValue: function(loggingEvent) {
		return this.isTimeStampsInMilliseconds() ? loggingEvent.timeStampInMilliseconds : loggingEvent.timeStampInSeconds;
	},

	getDataValues: function(loggingEvent, combineMessages) {
		const dataValues = [
			[this.loggerKey, loggingEvent.logger.name],
			[this.timeStampKey, this.getTimeStampValue(loggingEvent)],
			[this.levelKey, loggingEvent.level.name],
			[this.urlKey, window.location.href],
			[this.messageKey, combineMessages ? loggingEvent.getCombinedMessages() : loggingEvent.messages],
		];
		if (!this.isTimeStampsInMilliseconds()) {
			dataValues.push([this.millisecondsKey, loggingEvent.milliseconds]);
		}
		if (loggingEvent.exception) {
			dataValues.push([this.exceptionKey, getExceptionStringRep(loggingEvent.exception)]);
		}
		if (this.hasCustomFields()) {
			for (let i = 0, len = this.customFields.length; i < len; i++) {
				let val = this.customFields[i].value;

				// Check if the value is a function. If so, execute it, passing it the
				// current layout and the logging event
				if (typeof val === 'function') {
					val = val(this, loggingEvent);
				}
				dataValues.push([this.customFields[i].name, val]);
			}
		}
		return dataValues;
	},

	setKeys: function(loggerKey, timeStampKey, levelKey, messageKey, exceptionKey, urlKey, millisecondsKey) {
		this.loggerKey = extractStringFromParam(loggerKey, this.defaults.loggerKey);
		this.timeStampKey = extractStringFromParam(timeStampKey, this.defaults.timeStampKey);
		this.levelKey = extractStringFromParam(levelKey, this.defaults.levelKey);
		this.messageKey = extractStringFromParam(messageKey, this.defaults.messageKey);
		this.exceptionKey = extractStringFromParam(exceptionKey, this.defaults.exceptionKey);
		this.urlKey = extractStringFromParam(urlKey, this.defaults.urlKey);
		this.millisecondsKey = extractStringFromParam(millisecondsKey, this.defaults.millisecondsKey);
	},

	setCustomField: function(name, value) {
		let fieldUpdated = false;
		for (let i = 0, len = this.customFields.length; i < len; i++) {
			if (this.customFields[i].name === name) {
				this.customFields[i].value = value;
				fieldUpdated = true;
			}
		}
		if (!fieldUpdated) {
			this.customFields.push({ name: name, value: value });
		}
	},

	hasCustomFields: function() {
		return this.customFields.length > 0;
	},

	formatWithException: function(loggingEvent) {
		let formatted = this.format(loggingEvent);
		if (loggingEvent.exception && this.ignoresThrowable()) {
			formatted += loggingEvent.getThrowableStrRep();
		}
		return formatted;
	},

	toString: function() {
		handleError('Layout.toString: all layouts must override this method');
	},
};

log4javascript.Layout = Layout;

/* ---------------------------------------------------------------------- */
// Appender prototype

const Appender = function() {};

Appender.prototype = new EventSupport();
Appender.prototype.threshold = Level.ALL;
Appender.prototype.loggers = [];

// Performs threshold checks before delegating actual logging to the
// subclass's specific append method.
Appender.prototype.doAppend = function(loggingEvent) {
	if (enabled && loggingEvent.level.level >= this.threshold.level) {
		this.append(loggingEvent);
	}
};

Appender.prototype.append = function() {};

Appender.prototype.setLayout = function(layout) {
	if (layout instanceof Layout) {
		this.layout = layout;
	} else {
		handleError('Appender.setLayout: layout supplied to ' + this.toString() + ' is not a subclass of Layout');
	}
};

Appender.prototype.getLayout = function() {
	return this.layout;
};

Appender.prototype.setThreshold = function(threshold) {
	if (threshold instanceof Level) {
		this.threshold = threshold;
	} else {
		handleError('Appender.setThreshold: threshold supplied to ' + this.toString() + ' is not a subclass of Level');
	}
};

Appender.prototype.getThreshold = function() {
	return this.threshold;
};

Appender.prototype.setAddedToLogger = function(logger) {
	this.loggers.push(logger);
};

Appender.prototype.setRemovedFromLogger = function(logger) {
	array_remove(this.loggers, logger);
};

Appender.prototype.group = emptyFunction;
Appender.prototype.groupEnd = emptyFunction;

Appender.prototype.toString = function() {
	handleError('Appender.toString: all appenders must override this method');
};

log4javascript.Appender = Appender;

/* ---------------------------------------------------------------------- */
// JsonLayout related
function JsonLayout(readable, combineMessages) {
	this.readable = extractBooleanFromParam(readable, false);
	this.combineMessages = extractBooleanFromParam(combineMessages, true);
	this.batchHeader = this.readable ? '[' + newLine : '[';
	this.batchFooter = this.readable ? ']' + newLine : ']';
	this.batchSeparator = this.readable ? ',' + newLine : ',';
	this.setKeys();
	this.colon = this.readable ? ': ' : ':';
	this.tab = this.readable ? '\t' : '';
	this.lineBreak = this.readable ? newLine : '';
	this.customFields = [];
}

/* ---------------------------------------------------------------------- */
// JsonLayout

JsonLayout.prototype = new Layout();

JsonLayout.prototype.isReadable = function() {
	return this.readable;
};

JsonLayout.prototype.isCombinedMessages = function() {
	return this.combineMessages;
};

JsonLayout.prototype.format = function(loggingEvent) {
	const dataValues = this.getDataValues(loggingEvent, this.combineMessages);
	const object = {};
	for (let i = 0, len = dataValues.length - 1; i <= len; i++) {
		object[dataValues[i][0]] = dataValues[i][1];
	}
	return JSON.stringify(object);
};

JsonLayout.prototype.ignoresThrowable = function() {
	return false;
};

JsonLayout.prototype.toString = function() {
	return 'JsonLayout';
};

JsonLayout.prototype.getContentType = function() {
	return 'application/json';
};

log4javascript.JsonLayout = JsonLayout;
/* ---------------------------------------------------------------------- */
// HttpPostDataLayout

function HttpPostDataLayout() {
	this.setKeys();
	this.customFields = [];
	this.returnsPostData = true;
}

HttpPostDataLayout.prototype = new Layout();

// Disable batching
HttpPostDataLayout.prototype.allowBatching = function() {
	return false;
};

HttpPostDataLayout.prototype.format = function(loggingEvent) {
	const dataValues = this.getDataValues(loggingEvent);
	const queryBits = [];
	for (let i = 0, len = dataValues.length; i < len; i++) {
		const val = dataValues[i][1] instanceof Date ? String(dataValues[i][1].getTime()) : dataValues[i][1];
		queryBits.push(urlEncode(dataValues[i][0]) + '=' + urlEncode(val));
	}
	return queryBits.join('&');
};

HttpPostDataLayout.prototype.ignoresThrowable = function() {
	return false;
};

HttpPostDataLayout.prototype.toString = function() {
	return 'HttpPostDataLayout';
};

log4javascript.HttpPostDataLayout = HttpPostDataLayout;
/* ---------------------------------------------------------------------- */
// AjaxAppender related

const xhrFactory = function() {
	return new XMLHttpRequest();
};
const xmlHttpFactories = [xhrFactory];

let withCredentialsSupported = false;
let getXmlHttp = function(errorHandler) {
	// This is only run the first time; the value of getXmlHttp gets
	// replaced with the factory that succeeds on the first run
	let xmlHttp = null;
	let factory;
	for (let i = 0, len = xmlHttpFactories.length; i < len; i++) {
		factory = xmlHttpFactories[i];
		try {
			xmlHttp = factory();
			withCredentialsSupported = factory == xhrFactory && 'withCredentials' in xmlHttp;
			getXmlHttp = factory;
			return xmlHttp;
		} catch (e) {
			// intentinoally empty catch block
		}
	}
	// If we're here, all factories have failed, so throw an error
	if (errorHandler) {
		errorHandler();
	} else {
		handleError('getXmlHttp: unable to obtain XMLHttpRequest object');
	}
};

function isHttpRequestSuccessful(xmlHttp) {
	return (
		isUndefined(xmlHttp.status) ||
		xmlHttp.status === 0 ||
		(xmlHttp.status >= 200 && xmlHttp.status < 300) ||
		xmlHttp.status == 1223 /* Fix for IE */
	);
}

/* ---------------------------------------------------------------------- */
// AjaxAppender

function AjaxAppender(url, withCredentials) {
	const appender = this;
	let isSupported = true;
	if (!url) {
		handleError('AjaxAppender: URL must be specified in constructor');
		isSupported = false;
	}

	let timed = this.defaults.timed;
	let waitForResponse = this.defaults.waitForResponse;
	let batchSize = this.defaults.batchSize;
	let timerInterval = this.defaults.timerInterval;
	let requestSuccessCallback = this.defaults.requestSuccessCallback;
	let failCallback = this.defaults.failCallback;
	let postVarName = this.defaults.postVarName;
	let sendAllOnUnload = this.defaults.sendAllOnUnload;
	let contentType = this.defaults.contentType;
	let sessionId = null;

	const queuedLoggingEvents = [];
	const queuedRequests = [];
	const headers = [];
	let sending = false;
	let initialized = false;

	// Configuration methods. The function scope is used to prevent
	// direct alteration to the appender configuration properties.
	function checkCanConfigure(configOptionName) {
		if (initialized) {
			handleError(
				"AjaxAppender: configuration option '" +
					configOptionName +
					"' may not be set after the appender has been initialized"
			);
			return false;
		}
		return true;
	}

	this.getSessionId = function() {
		return sessionId;
	};
	this.setSessionId = function(sessionIdParam) {
		sessionId = extractStringFromParam(sessionIdParam, null);
		this.layout.setCustomField('sessionid', sessionId);
	};

	this.setLayout = function(layoutParam) {
		if (checkCanConfigure('layout')) {
			this.layout = layoutParam;
			// Set the session id as a custom field on the layout, if not already present
			if (sessionId !== null) {
				this.setSessionId(sessionId);
			}
		}
	};

	this.isTimed = function() {
		return timed;
	};
	this.setTimed = function(timedParam) {
		if (checkCanConfigure('timed')) {
			timed = bool(timedParam);
		}
	};

	this.getTimerInterval = function() {
		return timerInterval;
	};
	this.setTimerInterval = function(timerIntervalParam) {
		if (checkCanConfigure('timerInterval')) {
			timerInterval = extractIntFromParam(timerIntervalParam, timerInterval);
		}
	};

	this.isWaitForResponse = function() {
		return waitForResponse;
	};
	this.setWaitForResponse = function(waitForResponseParam) {
		if (checkCanConfigure('waitForResponse')) {
			waitForResponse = bool(waitForResponseParam);
		}
	};

	this.getBatchSize = function() {
		return batchSize;
	};
	this.setBatchSize = function(batchSizeParam) {
		if (checkCanConfigure('batchSize')) {
			batchSize = extractIntFromParam(batchSizeParam, batchSize);
		}
	};

	this.isSendAllOnUnload = function() {
		return sendAllOnUnload;
	};
	this.setSendAllOnUnload = function(sendAllOnUnloadParam) {
		if (checkCanConfigure('sendAllOnUnload')) {
			sendAllOnUnload = extractBooleanFromParam(sendAllOnUnloadParam, sendAllOnUnload);
		}
	};

	this.setRequestSuccessCallback = function(requestSuccessCallbackParam) {
		requestSuccessCallback = extractFunctionFromParam(requestSuccessCallbackParam, requestSuccessCallback);
	};

	this.setFailCallback = function(failCallbackParam) {
		failCallback = extractFunctionFromParam(failCallbackParam, failCallback);
	};

	this.getPostVarName = function() {
		return postVarName;
	};
	this.setPostVarName = function(postVarNameParam) {
		if (checkCanConfigure('postVarName')) {
			postVarName = extractStringFromParam(postVarNameParam, postVarName);
		}
	};

	this.getHeaders = function() {
		return headers;
	};
	this.addHeader = function(name, value) {
		if (name.toLowerCase() == 'content-type') {
			contentType = value;
		} else {
			headers.push({ name: name, value: value });
		}
	};

	// Internal functions
	function sendAll() {
		if (isSupported && enabled) {
			sending = true;
			let currentRequestBatch;
			if (waitForResponse) {
				// Send the first request then use this function as the callback once
				// the response comes back
				if (queuedRequests.length > 0) {
					currentRequestBatch = queuedRequests.shift();
					sendRequest(preparePostData(currentRequestBatch), sendAll);
				} else {
					sending = false;
					if (timed) {
						scheduleSending();
					}
				}
			} else {
				// Rattle off all the requests without waiting to see the response
				while ((currentRequestBatch = queuedRequests.shift())) {
					sendNonAwaitedRequest(preparePostData(currentRequestBatch));
				}
				sending = false;
				if (timed) {
					scheduleSending();
				}
			}
		}
	}

	this.sendAll = sendAll;

	// Called when the window unloads. At this point we're past caring about
	// waiting for responses or timers or incomplete batches - everything
	// must go, now
	function sendAllRemaining() {
		let sendingAnything = false;
		if (isSupported && enabled) {
			// Create requests for everything left over, batched as normal
			const actualBatchSize = appender.getLayout().allowBatching() ? batchSize : 1;
			let currentLoggingEvent;
			let batchedLoggingEvents = [];
			while ((currentLoggingEvent = queuedLoggingEvents.shift())) {
				batchedLoggingEvents.push(currentLoggingEvent);
				if (queuedLoggingEvents.length >= actualBatchSize) {
					// Queue this batch of log entries
					queuedRequests.push(batchedLoggingEvents);
					batchedLoggingEvents = [];
				}
			}
			// If there's a partially completed batch, add it
			if (batchedLoggingEvents.length > 0) {
				queuedRequests.push(batchedLoggingEvents);
			}
			sendingAnything = queuedRequests.length > 0;
			waitForResponse = false;
			timed = false;
			sendAll();
		}
		return sendingAnything;
	}

	this.sendAllRemaining = sendAllRemaining;

	function preparePostData(batchedLoggingEvents) {
		// Format the logging events
		const formattedMessages = [];
		let currentLoggingEvent;
		while ((currentLoggingEvent = batchedLoggingEvents.shift())) {
			formattedMessages.push(currentLoggingEvent);
		}
		let postData =
			appender.getLayout().batchHeader +
			formattedMessages.join(appender.getLayout().batchSeparator) +
			appender.getLayout().batchFooter;
		if (contentType == appender.defaults.contentType) {
			postData = appender.getLayout().returnsPostData ? postData : urlEncode(postVarName) + '=' + urlEncode(postData);
			// Add the layout name to the post data
			if (postData.length > 0) {
				postData += '&';
			}
			postData += 'layout=' + urlEncode(appender.getLayout().toString());
		}
		return postData;
	}

	function scheduleSending() {
		window.setTimeout(sendAll, timerInterval);
	}

	function xmlHttpErrorHandler() {
		const msg = 'AjaxAppender: could not create XMLHttpRequest object. AjaxAppender disabled';
		handleError(msg);
		isSupported = false;
		if (failCallback) {
			failCallback(msg);
		}
	}

	function sendRequest(postData, successCallback) {
		try {
			let xmlHttp = getXmlHttp(xmlHttpErrorHandler);
			if (isSupported) {
				xmlHttp.onreadystatechange = function() {
					if (xmlHttp.readyState == 4) {
						if (isHttpRequestSuccessful(xmlHttp)) {
							if (requestSuccessCallback) {
								requestSuccessCallback(xmlHttp);
							}
							if (successCallback) {
								successCallback(xmlHttp);
							}
						} else {
							const msg =
								'AjaxAppender.append: XMLHttpRequest request to URL ' + url + ' returned status code ' + xmlHttp.status;
							handleError(msg);
							if (failCallback) {
								failCallback(msg);
							}
						}
						xmlHttp.onreadystatechange = emptyFunction;
						xmlHttp = null;
					}
				};
				xmlHttp.open('POST', url, true);
				// Add withCredentials to facilitate CORS requests with cookies
				if (withCredentials && withCredentialsSupported) {
					xmlHttp.withCredentials = true;
				}
				try {
					// eslint-disable-next-line
					for (let i = 0, header; (header = headers[i++]); ) {
						xmlHttp.setRequestHeader(header.name, header.value);
					}
					xmlHttp.setRequestHeader('Content-Type', contentType);
				} catch (headerEx) {
					const msg =
						"AjaxAppender.append: your browser's XMLHttpRequest implementation" +
						' does not support setRequestHeader, therefore cannot post data. AjaxAppender disabled';
					handleError(msg);
					isSupported = false;
					if (failCallback) {
						failCallback(msg);
					}
					return;
				}
				xmlHttp.send(postData);
			}
		} catch (ex) {
			const errMsg = 'AjaxAppender.append: error sending log message to ' + url;
			handleError(errMsg, ex);
			isSupported = false;
			if (failCallback) {
				failCallback(errMsg + '. Details: ' + getExceptionStringRep(ex));
			}
		}
	}

	function sendNonAwaitedRequest(postData) {
		if (!isSupported || !navigator.sendBeacon) return;
		navigator.sendBeacon(url, postData);
	}

	this.append = function(loggingEvent) {
		if (isSupported) {
			if (!initialized) {
				init();
			}
			queuedLoggingEvents.push(appender.getLayout().formatWithException(loggingEvent));
			const actualBatchSize = this.getLayout().allowBatching() ? batchSize : 1;

			if (queuedLoggingEvents.length >= actualBatchSize) {
				let currentLoggingEvent;
				const batchedLoggingEvents = [];
				while ((currentLoggingEvent = queuedLoggingEvents.shift())) {
					batchedLoggingEvents.push(currentLoggingEvent);
				}
				// Queue this batch of log entries
				queuedRequests.push(batchedLoggingEvents);

				// If using a timer, the queue of requests will be processed by the
				// timer function, so nothing needs to be done here.
				if (!timed && (!waitForResponse || (waitForResponse && !sending))) {
					sendAll();
				}
			}
		}
	};

	function init() {
		initialized = true;
		// Add unload event to send outstanding messages
		if (sendAllOnUnload) {
			window.addEventListener('pagehide', sendAllRemaining);
		}
		// Start timer
		if (timed) {
			scheduleSending();
		}
	}
}

AjaxAppender.prototype = new Appender();

AjaxAppender.prototype.defaults = {
	waitForResponse: false,
	timed: false,
	timerInterval: 1000,
	batchSize: 1,
	sendAllOnUnload: false,
	requestSuccessCallback: null,
	failCallback: null,
	postVarName: 'data',
	contentType: 'application/x-www-form-urlencoded',
};

AjaxAppender.prototype.layout = new HttpPostDataLayout();

AjaxAppender.prototype.toString = function() {
	return 'AjaxAppender';
};

log4javascript.AjaxAppender = AjaxAppender;
/* ---------------------------------------------------------------------- */

function createDefaultLogger() {
	const logger = log4javascript.getLogger(defaultLoggerName);
	const a = new log4javascript.PopUpAppender();
	logger.addAppender(a);
	return logger;
}

/* ---------------------------------------------------------------------- */
// Main load

log4javascript.setDocumentReady = function() {
	log4javascript.dispatchEvent('load', {});
};

if (window.addEventListener) {
	window.addEventListener('load', log4javascript.setDocumentReady, false);
} else if (window.attachEvent) {
	window.attachEvent('onload', log4javascript.setDocumentReady);
} else {
	const oldOnload = window.onload;
	if (typeof window.onload != 'function') {
		window.onload = log4javascript.setDocumentReady;
	} else {
		window.onload = function(evt) {
			if (oldOnload) {
				oldOnload(evt);
			}
			log4javascript.setDocumentReady();
		};
	}
}

export default log4javascript;
