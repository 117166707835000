import React, { Component, createRef, Fragment } from 'react';
import { get, noop } from 'lodash';
import { bool, func, string } from 'prop-types';

import { emailService } from 'common/services';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { withLoader } from 'common/components/loader';
import { emailTemplates } from 'common/utilities';
import { getMyPlan } from 'common/utilities/plans';
import { Modal } from 'common/components/modal';
import { Notification } from 'common/components/notifications';

class EnrollWithPaymentSiteComponent extends Component {
	constructor() {
		super();

		const { monthlyFee, setupFee } = getMyPlan();

		this.state = {
			isOpenSignUpModal: false,
			monthlyFee,
			setupFee,
		};

		this.notificationRef = createRef();
	}

	handleSignUp = async () => {
		const { makePendingRequest, handleError } = this.props;
		const addNotification = get(this.notificationRef, 'current.addNotification', noop);
		const emailProps = {
			TemplateName: emailTemplates.paymentSite,
		};
		try {
			this.props.showLoader(true);
			await makePendingRequest(emailService.sendEmail(emailProps), 'sendEmail');
			addNotification({
				success: true,
				message: (
					<div>
						<p className="spc--bottom--sml">
							We are confirming your request to add a Hosted PaymentSITE to your merchant account.  You will receive an
							email confirmation within the next 48 hours, once your account has been set up.
						</p>
						<p>—The Cardknox Team</p>
					</div>
				),
			});
		} catch (e) {
			handleError(e);
		}
		this.props.showLoader(false);
		this.closeSignUpModal();
	};

	closeSignUpModal = () => this.setState({ isOpenSignUpModal: false });

	openSignUpModal = (isOpenSignUpModal = false) => this.setState({ isOpenSignUpModal });

	renderSignUpModal = () => {
		const { isOpenSignUpModal } = this.state;

		return (
			<Modal
				isOpen={isOpenSignUpModal}
				onClose={this.closeSignUpModal}
				shouldCloseOnOverlayClick={false}
				className="popup__content popup--sml"
			>
				<div className="popup">
					<div className="popup__header">
						<div className="flex flex--tertiary">
							<div className="popup__header__title">Sign Up for PaymentSITE</div>
						</div>
					</div>
					<div className="popup__body">
						<div className="message message--default spc--bottom--sml">
							<p>
								Thank you for your interest in setting up a Hosted PaymentSITE for your business. Please confirm you'd
								like to add this to your merchant account by clicking the Sign Up button below.
							</p>
						</div>
					</div>
					<div className="popup__footer popup__footer--styled">
						<button
							type="button"
							tabIndex="-1"
							className="btn btn--sml btn--ghost spc--right--xsml"
							onClick={this.closeSignUpModal}
						>
							Close
						</button>
						<button
							type="button"
							tabIndex="-1"
							className="btn btn--sml btn--primary"
							onClick={this.handleSignUp}
							disabled={this.props.isLoading}
						>
							Sign Up
						</button>
					</div>
				</div>
			</Modal>
		);
	};

	render() {
		const { monthlyFee, setupFee } = this.state;
		const { disclaimer, displayHeader } = this.props;

		return (
			<Fragment>
				<Notification ref={this.notificationRef} />
				{this.renderSignUpModal()}
				{displayHeader && (
					<div className="settings__header">
						<h3 className="settings__header__title">PaymentSITE Management</h3>
						<div className="settings__header__action">
							<button onClick={this.openSignUpModal} className="btn btn--med btn--primary spc--bottom--sml">
								Sign Up
							</button>
						</div>
					</div>
				)}
				<div className="paymentsite__signup__wrapper">
					<div className="message message--primary type--wgt--bold display--b type--center spc--bottom--med">
						{disclaimer}
					</div>
					<div className="paymentsite__signup__content">
						<div className="paymentsite__signup__aside">
							<img src="/static/media/paymentsite/form.svg"></img>
						</div>
						<div className="paymentsite__signup__main">
							<h4 className="type--base type--color--text--medium type--wgt--bold spc--bottom--med">
								Looking for an easier way to collect outstanding balances?
							</h4>
							<p className="spc--bottom--med">
								PaymentSITE is a secure online form that can be customized and shared with your customers via a unique
								URL.
							</p>
							<p className="spc--bottom--med">
								Once you're set up with a PaymentSITE, you'll be able to configure the display and required fields
								(billing, shipping and transaction info) right here in this tab. You can then send your custom
								PaymentSITE to your client with the click of a button using the Send Payment Request module!
							</p>
							<p>Pricing:</p>
							<p className="type--italic">Setup Fee: {setupFee}</p>
							<p className="type--italic">Monthly Fee: {monthlyFee}</p>
							<button onClick={this.openSignUpModal} className="btn btn--med btn--primary spc--top--med">
								Sign Up
							</button>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

EnrollWithPaymentSiteComponent.defaultProps = {
	displayHeader: false,
};

EnrollWithPaymentSiteComponent.propTypes = {
	handleError: func.isRequired,
	makePendingRequest: func.isRequired,
	isLoading: bool.isRequired,
	showLoader: func.isRequired,
	displayHeader: bool,
	disclaimer: string,
};

export default withError(withCancelable(withLoader(EnrollWithPaymentSiteComponent)));
