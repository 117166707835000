import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toLower, split } from 'lodash';
import NumberFormat from 'react-number-format';

import { CurrencyMap, PaymentTransactionTypes, mapAvsItem } from 'common/utilities';
import { StatusComponent } from 'common/components/columns/formatters';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { withForwardRef } from 'common/components/with-forward-ref';

class TransactionHistoryItemComponent extends Component {
	get isAuthOnly() {
		const { item } = this.props;
		return toLower(item.xCommand).indexOf('authonly') !== -1;
	}

	get currency() {
		return this.props.item && this.props.item.currency;
	}

	get currencyCode() {
		return CurrencyMap.resolveCurrency(this.currency);
	}

	renderStatus = () => {
		const {
			item: {
				transactionStatus: {
					isVoid,
					isAuthOnly,
					isRefund,
					isRefunded,
					canRefundAmount,
					previouslyRefundedAmount,
					isSplitCaptured,
				},
			},
		} = this.props;
		let statusImage = null;
		let tooltip = null;

		if (isVoid) {
			statusImage = <img src="/static/media/tags/void.svg" className="h--18 spc--left--xsml " />;
		} else if (isRefund) {
			statusImage = <img src="/static/media/tags/refund.svg" className="h--18 spc--left--xsml " />;
		} else if (isRefunded) {
			if (canRefundAmount > 0 && previouslyRefundedAmount > 0) {
				tooltip = `Partial refund of ${this.currencyCode}${parseFloat((previouslyRefundedAmount / 10).toFixed(2))}`;
				statusImage = (
					<img src="/static/media/tags/partiallyrefunded.svg" className="h--18 spc--left--xsml " />
				);
			} else {
				statusImage = <img src="/static/media/tags/refunded.svg" className="h--18 spc--left--xsml " />;
			}
		} else if (isAuthOnly) {
			if (isSplitCaptured) {
				statusImage = (
					<Fragment>
						<img src="/static/media/tags/auth.svg" className="h--18 spc--left--tny " />
						<img src="/static/media/tags/split-captured.svg" className="h--18 spc--left--tny " />
					</Fragment>
				);
			} else {
				statusImage = <img src="/static/media/tags/auth.svg" className="h--18 spc--left--tny " />;
			}
		}

		if (statusImage !== null) {
			return (
				<span className="datatooltip--partialrefund">
					<span data-tooltip={tooltip}>{statusImage}</span>
				</span>
			);
		}
		return null;
	};

	isGiftTransaction = (payment, transaction, requestedTransaction) => {
		return payment === 'gift' && transaction === requestedTransaction;
	};

	renderTransactionDetails = () => {
		const {
			item,
			item: { xCommand, xResponseAVSCode, transactionStatus },
		} = this.props;
		let amount = transactionStatus.isVoid ? item.xRequestAmount : item.xAmount;
		let amountClass = transactionStatus.isVoid ? 'type--linethrough type--color--primary' : '';
		const accountNumber =
			item.xMaskedAccountNumber && item.xMaskedAccountNumber.includes('xxx')
				? `**** ${item.xMaskedAccountNumber.slice(-4)}`
				: item.xMaskedAccountNumber;
		let avs = mapAvsItem(xResponseAVSCode);
		if (avs) {
			avs = avs.text;
		}
		const [payment, transaction] = split(toLower(xCommand), ':');
		const issueGift = this.isGiftTransaction(payment, transaction, 'issue');
		const redeemGift = this.isGiftTransaction(payment, transaction, 'redeem');
		if (redeemGift) {
			amount = Math.abs(amount);
		}
		const paymentType = PaymentTransactionTypes.getPaymentType(payment);
		const transactionType = PaymentTransactionTypes.getTransactionType(transaction);

		return (
			<div className="f-row">
				<div className="f-col f-col-sml-12 f-col-med-6 f-col-xlrg-4">
					<label className="label label--sml">Reference Number:</label>
					<div className="flex--primary spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
						<span className="spc--right--tny">{item.xRefNum}</span>
						<StatusComponent value={item.xResponseResult} />
					</div>
				</div>
				<div className="f-col f-col-sml-12 f-col-med-6 f-col-xlrg-4">
					<label className="label label--sml"> {`Amount: ${issueGift ? '-' : ''}`}</label>
					<div
						className={`spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word ${amountClass}`}
					>
						<NumberFormat
							value={amount}
							displayType="text"
							thousandSeparator={true}
							prefix={this.currencyCode}
							decimalScale={2}
							fixedDecimalScale={true}
						/>
						{this.renderStatus()}
					</div>
				</div>
				<div className="f-col f-col-sml-12 f-col-med-6 f-col-xlrg-4">
					<label className="label label--sml">Date:</label>
					<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
						{item.xEnteredDate.format(ApplicationSettings.displayDateTimeFormat)}
					</div>
				</div>
				<div className="f-col f-col-sml-12 f-col-med-6 f-col-xlrg-4">
					<label className="label label--sml">Account number:</label>
					<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
						{accountNumber}
					</div>
				</div>
				<div className="f-col f-col-sml-12 f-col-med-6 f-col-xlrg-4">
					<label className="label label--sml">Payment type:</label>
					<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
						{paymentType}
					</div>
				</div>
				<div className="f-col f-col-sml-12 f-col-med-6 f-col-xlrg-4">
					<label className="label label--sml">Transaction type:</label>
					<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
						{transactionType}
					</div>
				</div>
				{item && item.xInvoice && item.xInvoice.trim() !== '' ? (
					<div className="f-col f-col-sml-12 f-col-med-6 f-col-xlrg-4">
						<label className="label label--sml">Invoice:</label>
						<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
							{item.xInvoice}
						</div>
					</div>
				) : null}
				{item && item.xStreet && item.xStreet.trim() !== '' ? (
					<div className="f-col f-col-sml-12 f-col-med-6 f-col-xlrg-4">
						<label className="label label--sml">AVS Street:</label>
						<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
							{item.xStreet}
						</div>
					</div>
				) : null}
				{item && item.xZip && item.xZip.trim() !== '' ? (
					<div className="f-col f-col-sml-12 f-col-med-6 f-col-xlrg-4">
						<label className="label label--sml">AVS Zip:</label>
						<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
							{item.xZip}
						</div>
					</div>
				) : null}
				{item && item.xDescription && item.xDescription.trim() !== '' ? (
					<div className="f-col f-col-sml-12 f-col-med-12 f-col-xlrg-12">
						<label className="label label--sml">Transaction Description:</label>
						<div
							className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word"
							title={item.xDescription}
						>
							{item.xDescription}
						</div>
					</div>
				) : null}
			</div>
		);
	};

	render() {
		return (
			<div className="grid-sidebar__transaction">
				<div className="">{this.renderTransactionDetails()}</div>
			</div>
		);
	}
}

TransactionHistoryItemComponent.propTypes = {
	item: PropTypes.object,
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	history: PropTypes.object,
};

export default withCancelable(withError(withForwardRef(TransactionHistoryItemComponent, withRouter)));
