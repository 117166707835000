import {
	camelCase,
	toLower,
	toNumber,
	each,
	upperFirst,
	toUpper,
	includes,
	isEmpty,
	find,
	split,
	replace,
	size,
	slice,
	without,
	round,
} from 'lodash-es';
import CurrencyMap from './currencyMap';
const checkStatusValues = statusToParse => {
	if (includes(statusToParse, 'Declined')) {
		statusToParse.push('Error');
	}
	if (size(statusToParse) === 0) {
		statusToParse.push('Approved', 'Error', 'Declined');
	}
};

const getAmountsByCardType = (data, inlineFilterValues = null, totalLabel = null) => {
	const statusToParse =
		totalLabel && without(slice(split(replace(replace(totalLabel, 'Total', ''), /[^a-zA-Z ]/g, ' '), ' ')), '');
	checkStatusValues(statusToParse);
	const totals = {};
	each(data, ({ xVoid, xAmount, xCardType, currency, xCommand, xResponseResult }) => {
		const subtract = toLower(xCommand) === 'gift:issue';
		const isRedeemGift = toLower(xCommand) === 'gift:redeem';

		if (statusToParse && !includes(statusToParse, xResponseResult)) return;
		if (xVoid == 1) return;
		let amount = xAmount;
		if (subtract) {
			amount = -Math.abs(xAmount);
		} else if (isRedeemGift) {
			amount = Math.abs(amount);
		}

		if (!inlineFilterValues || includes(inlineFilterValues, toLower(xResponseResult)) || isEmpty(inlineFilterValues)) {
			const cardType = camelCase(xCardType);
			const mappedCurrency = CurrencyMap.isoCodesMap[currency] || toUpper(currency);

			if (find(totals, (_, key) => key === `${cardType}TotalCount${upperFirst(mappedCurrency)}`)) {
				totals[`${cardType}TotalCount${upperFirst(mappedCurrency)}`]++;
				totals[`${cardType}TotalAmount${upperFirst(mappedCurrency)}`] += round(toNumber(amount), 2);
			} else {
				totals[`${cardType}TotalCount${upperFirst(mappedCurrency)}`] = 1;
				totals[`${cardType}TotalAmount${upperFirst(mappedCurrency)}`] = toNumber(amount);
				totals[`${cardType}Currency${upperFirst(mappedCurrency)}`] = mappedCurrency;
			}
			totals[`${cardType}TotalAmount${upperFirst(mappedCurrency)}`] = toNumber(
				totals[`${cardType}TotalAmount${upperFirst(mappedCurrency)}`].toFixed(2)
			);
		}
	});
	return totals;
};

export default getAmountsByCardType;
