import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withLoader } from '../../Common/components/loader';
import { authenticationService } from '../../Common/services';
import updateApiEndpoint from 'common/utilities/UpdateApiEndpoint';

const {
	apiVersion,
	loginEndpoint,
	paymentSiteApiEndpoint,
	recurringApiEndpoint,
	kvaasEndpoint,
	kvaasMinorVersion,
	keyManagementEndpoint,
	portalManagementApiEndpoint,
} = ApplicationSettings;
const { developer } = TermsAndConditions;
const { name, version: softwareVersion } = SoftwareSettings;

class InfoComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			ssoVersion: 'checking...',
		};
	}

	async componentDidMount() {
		await this.fetchSsoVersion();
	}

	fetchSsoVersion = async () => {
		const user = await authenticationService.getUser(false);
		if (user && user.signInUserSession && user.signInUserSession.idToken && user.signInUserSession.idToken.jwtToken) {
			const token = user.signInUserSession.idToken.jwtToken;
			const res = await authenticationService.getSsoVersion(token);
			if (res && res.xAssemblyVersion) {
				this.setState({
					ssoVersion: res.xAssemblyVersion,
				});
				return;
			}
		}
		this.setState({
			ssoVersion: 'Requires logged in user to check version',
		});
	};

	redirectToHome = () => {
		const { history } = this.props;
		history.push('/');
	};

	render() {
		const { ssoVersion } = this.state;
		const version = `${softwareVersion}-${AppBuildVersion || ''}-${AppBuildEnvironment || ''}`;

		return (
			<div>
				<div className="membership__section membership__section--info">
					<a
						type="button"
						onClick={this.redirectToHome}
						className="btn membership--main__btn type--wgt--medium spc--bottom--med"
					>
						<i className="icon icon--tiny icon--arrow--left--primary spc--right--tny"></i>
						Back to Homepage
					</a>
					<h2 className="membership__title">Info</h2>
					<div>
						<p className="type--break-word datatooltip--w--100">
							<strong data-tooltip="Version in site footer">App Version: </strong> {version}
						</p>
						<hr />
						<p className="type--break-word datatooltip--w--100">
							<strong data-tooltip="Used in requests to the API">Software Name: </strong> {name}
						</p>
						<p className="type--break-word datatooltip--w--100">
							<strong data-tooltip="Used in requests to the API">Software Version: </strong> {softwareVersion}
						</p>
						<p className="type--break-word datatooltip--w--100">
							<strong data-tooltip="Environment for which App was built for">Build environment: </strong>{' '}
							{AppBuildEnvironment || null}
						</p>
						<p className="type--break-word datatooltip--w--100">
							<strong data-tooltip="Code version from git">Git version: </strong> {AppBuildVersion || null}
						</p>
						<p className="type--break-word datatooltip--w--100">
							<strong data-tooltip="Latest git commit hash">Git commit: </strong> {AppBuildCommitHash || null}
						</p>
						<p className="type--break-word datatooltip--w--100">
							<strong data-tooltip="From which branch was code built">Git branch: </strong> {AppBuildBranch || null}
						</p>
						<p className="type--break-word datatooltip--w--100">
							<strong data-tooltip="Terms and Conditions version">T&amp;C Version: </strong> {developer}
						</p>
						<hr />
						<p className="type--break-word datatooltip--w--100">
							<strong data-tooltip="API endpoint URL">API Endpoint: </strong> {updateApiEndpoint(null)}
						</p>
						<p className="type--break-word datatooltip--w--100">
							<strong data-tooltip="API version">API Version: </strong> {apiVersion}
						</p>
						<p className="type--break-word datatooltip--w--100">
							<strong data-tooltip="Recurring API endpoint URL">Recurring API Endpoint: </strong> {recurringApiEndpoint}
						</p>
						<p className="type--break-word datatooltip--w--100">
							<strong data-tooltip="SSO endpoint URL">SSO Endpoint: </strong> {loginEndpoint}
						</p>
						<p className="type--break-word datatooltip--w--100">
							<strong data-tooltip="SSO version - xAssemblyVersion">SSO Version: </strong> {ssoVersion}
						</p>
						<p className="type--break-word datatooltip--w--100">
							<strong data-tooltip="KVAAS endpoint URL">KVAAS Endpoint: </strong> {kvaasEndpoint}
						</p>
						<p className="type--break-word datatooltip--w--100">
							<strong data-tooltip="KVAAS minor version">KVAAS Version: </strong> {kvaasMinorVersion}
						</p>
						<p className="type--break-word datatooltip--w--100">
							<strong data-tooltip="PaymentSITE API endpoint URL">PaymentSITE API Endpoint: </strong>{' '}
							{paymentSiteApiEndpoint}
						</p>
						<p className="type--break-word datatooltip--w--100">
							<strong data-tooltip="Key management API endpoint URL">Key management API Endpoint: </strong>{' '}
							{keyManagementEndpoint}
						</p>
						<p className="type--break-word datatooltip--w--100">
							<strong data-tooltip="Portal management API endpoint URL">Portal management API Endpoint: </strong>{' '}
							{portalManagementApiEndpoint}
						</p>
					</div>
				</div>
			</div>
		);
	}
}

InfoComponent.propTypes = {
	history: PropTypes.object,
};

export default withLoader(InfoComponent);
