import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { every } from 'lodash';

class BatchGrid extends Component {
	render() {
		const { caption, children, handleBreakdownOpen, expanded, toggleExpandAll, exportButtons } = this.props;
		const allExpanded = every(expanded, row => row);

		return (
			<React.Fragment>
				<div className="batches__table__header">
					<div className="batches__table__header__title">
						<h3 className="title title--secondary spc--right--med">{caption}</h3>
						<a
							onClick={() => toggleExpandAll('all', allExpanded)}
							className="anchor anchor--primary batches__expand"
						>{`${allExpanded ? 'Collapse' : 'Expand'} all`}</a>
					</div>
					<div className="flex--primary spc--bottom--sml">
						<div className="display--ib spc--right--med type--right--from--med">
							<a href="javascript:void(0)" className="anchor anchor--primary" onClick={handleBreakdownOpen}>
								Breakdown by Card
							</a>
						</div>
						{exportButtons}
					</div>
				</div>
				<div className="dtable__scrollholder">
					<table className="dtable spc--bottom--med">{children}</table>
				</div>
			</React.Fragment>
		);
	}
}

BatchGrid.propTypes = {
	expanded: PropTypes.object.isRequired,
	caption: PropTypes.string,
	children: PropTypes.any,
	handleBreakdownOpen: PropTypes.func,
	toggleExpandAll: PropTypes.func.isRequired,
	exportButtons: PropTypes.any,
};

export default BatchGrid;
