import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

class PhoneFilterComponent extends Component {
	constructor(props) {
		super(props);
		this.inputRef = createRef();
	}

	componentDidUpdate() {
		const { noFocus } = this.props;
		if (this.inputRef.current && !noFocus) {
			this.inputRef.current.focus();
		}
	}

	handleChange = ({ target: { value } }) => {
		const {
			filter: { key },
			onFilterChanged,
		} = this.props;
		onFilterChanged({
			id: key,
			values: [{ key, value }],
			emptyValue: null,
		});
	};

	render() {
		const {
			filter: { key, values, name, standalone },
			noFocus,
			placeholder,
			goButtonHandler,
		} = this.props;

		return (
			<div className="pos--rel inputgroup">
				<div className="inputgroup--main">
					<input
						type="tel"
						placeholder={placeholder || name}
						className={`input input--med ${
							standalone ? 'input--search' : goButtonHandler ? 'inputgroup--main--bordered--left' : ''
						}`}
						value={values[key] === null ? '' : values[key]}
						id={key}
						onChange={this.handleChange}
						ref={this.inputRef}
						autoFocus={!noFocus}
					/>
				</div>
				{goButtonHandler && (
					<div className="inputgroup--aside">
						<button
							className="btn btn--med btn--primary inputgroup--aside--bordered--right"
							data-tooltip="Apply"
							onClick={goButtonHandler}
						>
							<i className="icon icon--nano icon--arrow--right--positive"></i>
						</button>
					</div>
				)}
			</div>
		);
	}
}

PhoneFilterComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
	noFocus: PropTypes.bool,
	placeholder: PropTypes.string,
	goButtonHandler: PropTypes.func,
};

export default PhoneFilterComponent;
