import httpService from './httpService';
import {
	each,
	clone,
	sum,
	sumBy,
	map,
	isNumber,
	camelCase,
	merge,
	keys,
	toNumber,
	groupBy,
	round,
	split,
} from 'lodash';

import { apiToLocalMoment, fixJsonFormat } from '../utilities';
import updateApiEndpoint from 'common/utilities/UpdateApiEndpoint';

const cardTypes = ['', 'Amex', 'Discover', 'EBT', 'EBTW', 'EWic', 'MC', 'Visa', 'Debit', 'Ach'];
const transactionTypes = ['Credit', 'Sale', 'Void', 'TotalProcessingFee', 'NetTotal'];

class BatchService {
	constructor(httpService) {
		this.httpService = httpService;
	}

	getBatchesSummary = async options => {
		options = clone(options);
		options.xCommand = 'Report:Batch';

		const result = await this.httpService.post(updateApiEndpoint('report'), options, { fixBatchData: true });
		return await this.parseBatchResult(result, false);
	};

	closeBatch = async options => {
		options = clone(options || {});
		options.xCommand = 'cc:settle';

		const result = await this.httpService.post(updateApiEndpoint('gateway'), options);
		if (result.xStatus.toLowerCase() === 'approved') {
			result.success = true;
		} else {
			result.success = false;
		}
		return result;
	};

	parseBatchResult = async (result, isFundedReportOpenBatches = false) => {
		if (result) {
			result.xRecordsReturned = parseInt(result.xRecordsReturned);
			if (result.xAdditionalData !== undefined) {
				const json = fixJsonFormat(result.xAdditionalData);
				const allData = merge({}, ...json);
				const batches = map(keys(allData), toNumber);
				result.xAdditionalData = groupBy(batches, batch => allData[batch]);
			}

			const sumByTypes = transactionTypes.slice(0, 3);
			// Transform to int/float
			result.xReportData = await Promise.all(
				map(result.xReportData, async (item, index) => {
					if (item) {
						const formattedDate = await apiToLocalMoment(
							`${item.xBatchDate} ${item.xBatchTime || '12:00:00 PM'}`,
							ApplicationSettings.displayDateTimeFormat
						);
						if (item.xBatchDate) {
							const dateTime = split(formattedDate.format(ApplicationSettings.displayDateTimeFormat), ' ');
							item.xBatchDate = dateTime[0];
							if (item.xBatchTime) {
								item.xBatchTime = `${dateTime[1]} ${dateTime[2]}`;
							}
							item.index = index;
						}
						each(cardTypes, cardType => {
							const mappedTransactionTypes = !cardType ? ['Total', ...transactionTypes] : transactionTypes;
							each(mappedTransactionTypes, transactionType => {
								const amount = `x${cardType}${transactionType}Amount`;
								const count = `x${cardType}${transactionType}Count`;
								item[amount] = item[amount] ? this.getCurrencyFloat(item[amount], amount) : 0;
								item[count] = item[count] ? parseInt(item[count]) : 0;
							});
							if (cardType) {
								item[`x${cardType}TotalAmount`] = round(
									item[`x${cardType}SaleAmount`] - item[`x${cardType}CreditAmount`],
									2
								);
								if (!item[`x${cardType}TotalCount`]) {
									item[`x${cardType}TotalCount`] = sumBy(sumByTypes, type => item[`x${cardType}${type}Count`]);
								}
								if (item[`x${cardType}NetTotalAmount`] == 0) {
									item[`x${cardType}NetTotalAmount`] = item[`x${cardType}TotalAmount`];
									item[`x${cardType}NetTotalCount`] = item[`x${cardType}TotalCount`];
								}
							}
						});
					}
					return item;
				})
			);

			// Calc totals
			result.totals = {};
			each(cardTypes, cardType => {
				each(['Total', ...transactionTypes], transactionType => {
					this.addTotal(result.totals, result.xReportData, `x${cardType}${transactionType}Amount`);
					this.addTotal(result.totals, result.xReportData, `x${cardType}${transactionType}Count`);
				});
			});

			if (isFundedReportOpenBatches) {
				let openBatches = [];
				let mappedData = {};
				result.xRecordsReturned = 1;
				each(result.xReportData, item => {
					openBatches.push(parseInt(item.xBatch));
					delete item.xBatch;
					each(item, (_, key) => (mappedData[key] = this.sumPropertiesFromArrayObject(result.xReportData, key)));
				});
				result.openBatches = openBatches;
				result.xReportData = [mappedData];
			}
		}
		return result;
	};

	addTotal = (totals, data, key) => {
		totals[camelCase(key.substr(1))] = this.sumPropertiesFromArrayObject(data, key);
	};

	getCurrencyFloat = (str, name) => {
		const treatAsAmount = name => {
			return name.match(/xTotalAmount|NetTotalAmount|TotalProcessingFeeAmount/i);
		};
		if (isNumber(str)) {
			return str;
		}
		let clean = str.replace(/[^\d.-]/g, '');
		if (treatAsAmount(name)) {
			return parseFloat(clean);
		}
		return Math.abs(parseFloat(clean));
	};

	sumPropertiesFromArrayObject = (arrayOfObj, ...propNames) => {
		return round(sumBy(propNames, propName => round(sum(map(arrayOfObj, obj => round(obj[propName], 2))), 2)), 2);
	};
}

const batchService = new BatchService(httpService);

export default batchService;
