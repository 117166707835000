import { replace } from 'lodash';
import httpService from './httpService';

const { logoManagementEndpoint, logoManagementApiVersion } = ApplicationSettings;

class LogoManagementService {
	constructor(httpService) {
		this.httpService = httpService;
	}

	get headers() {
		let headers = new Headers();
		headers.set('X-LogoManagement-Api-Version', logoManagementApiVersion);
		return headers;
	}

	get options() {
		return {
			isJson: true,
			headers: this.headers,
		};
	}

	convertImageToBase64 = imageFile =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => {
				resolve(
					replace(reader.result, /(data:image\/jpg;base64,|data:image\/jpeg;base64,|data:image\/png;base64,)/gi, '')
				);
			};
			reader.onerror = () => reject();
			reader.readAsDataURL(imageFile);
		});

	getLogoUrl = async () => {
		const endpoint = `${logoManagementEndpoint}getlogourl`;
		const result = await this.httpService.post(endpoint, { logotype: 'Default' }, this.options);
		return result;
	};

	fetchImageAndConvertToBase64 = async logoUrl => {
		const result = await this.httpService.get(logoUrl, { isDocument: true });
		const imgBase64 = await this.convertImageToBase64(result);
		return imgBase64;
	};

	uploadLogo = async LogoImageBase64 => {
		const endpoint = `${logoManagementEndpoint}uploadlogo`;
		const result = await this.httpService.post(endpoint, { LogoImageBase64, logotype: 'Default' }, this.options);
		return result;
	};

	removeLogo = async () => {
		const endpoint = `${logoManagementEndpoint}removelogo`;
		const result = await this.httpService.post(endpoint, { logotype: 'Default' }, this.options);
		return result;
	};
}

const logoManagementService = new LogoManagementService(httpService);

export default logoManagementService;
