import React from 'react';
import { map } from 'lodash';

import { MultiselectCardknoxFilter, StringFilter } from 'common/components/columns/filters';
import { ColumnHeader } from 'common/components/columns/headers';

const columns = [
	{
		key: 'creationDate',
		name: 'Date',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 160,
		resizable: true,
		isDefaultSorter: true,
		defaultSortDirection: 'DESC',
	},
	{
		key: 'eventType',
		name: 'Event Type',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 130,
		resizable: true,
	},
	{
		key: 'city',
		name: 'City',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: MultiselectCardknoxFilter,
		generateInlineFilterOptions: true,
		initWidth: 140,
		resizable: true,
	},
	{
		key: 'country',
		name: 'Country',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: MultiselectCardknoxFilter,
		generateInlineFilterOptions: true,
		initWidth: 130,
		resizable: true,
	},
	{
		key: 'deviceName',
		name: 'Device Name',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: MultiselectCardknoxFilter,
		generateInlineFilterOptions: true,
		initWidth: 150,
		resizable: true,
	},
	{
		key: 'ipAddress',
		name: 'IP Address',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 130,
		resizable: true,
	},
];

export const portalManagementUserEventsColumns = map(columns, column => {
	column = {
		headerRenderer: <ColumnHeader />,
		...column,
	};
	return column;
});
