import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { fieldPropType, isValid } from '../../Common/fields';

class TransactionDetailsFields extends Component {
	onChange = e => {
		this.props.onChange({
			key: e.target.name,
			value: e.target.value,
		});
	};

	render() {
		const {
			data,
			invalidClassName,
			requiredFields,
			customDisplayLabels,
			transactionHiddenFields,
			hideOrderId,
		} = this.props;
		const required = (
			<span className="required-field label--required" data-tooltip="Required">
				*
			</span>
		);

		return (
			<div>
				{transactionHiddenFields.invoice ? null : (
					<div className="newtransaction__item newtransaction__item--expandable">
						<label className="newtransaction__item__label newtransaction__item__label--expandable">
							{customDisplayLabels.invoice || 'Invoice'} {requiredFields.invoice ? required : null}
						</label>
						<div className="newtransaction__item__value newtransaction__item__value--expandable">
							<input
								type="text"
								name="invoice"
								inputMode="numeric"
								className={`input input--med ${(!isValid(data.invoice) && invalidClassName) || ''}`}
								placeholder={customDisplayLabels.invoice || 'Invoice'}
								value={data.invoice.value}
								onChange={this.onChange}
							/>
						</div>
					</div>
				)}
				{transactionHiddenFields.poNumber ? null : (
					<div className="newtransaction__item newtransaction__item--expandable">
						<label className="newtransaction__item__label newtransaction__item__label--expandable">
							{customDisplayLabels.poNumber || 'PO Number'} {requiredFields.poNumber ? required : null}
						</label>
						<div className="newtransaction__item__value newtransaction__item__value--expandable">
							<input
								type="text"
								name="poNumber"
								inputMode="numeric"
								className={`input input--med ${(!isValid(data.poNumber) && invalidClassName) || ''}`}
								placeholder={customDisplayLabels.poNumber || 'PO Number'}
								value={data.poNumber.value}
								onChange={this.onChange}
							/>
						</div>
					</div>
				)}
				{transactionHiddenFields.description ? null : (
					<div className="newtransaction__item newtransaction__item--expandable newtransaction__item--expandable--alt">
						<label className="newtransaction__item__label newtransaction__item__label--expandable">
							{customDisplayLabels.description || 'Description'} {requiredFields.description ? required : null}
						</label>
						<div className="newtransaction__item__value newtransaction__item__value--expandable">
							<textarea
								rows="3"
								cols="10"
								name="description"
								className={`input input--textarea ${(!isValid(data.description) && invalidClassName) || ''}`}
								placeholder={customDisplayLabels.description || 'Description'}
								value={data.description.value}
								onChange={this.onChange}
							/>
						</div>
					</div>
				)}
				{transactionHiddenFields.orderId || hideOrderId ? null : (
					<div className="newtransaction__item newtransaction__item--expandable newtransaction__item--expandable--alt">
						<label className="newtransaction__item__label newtransaction__item__label--expandable">
							{customDisplayLabels.orderId || 'Order ID'} {requiredFields.orderId ? required : null}
						</label>
						<div className="newtransaction__item__value newtransaction__item__value--expandable">
							<input
								type="text"
								name="orderId"
								inputMode="numeric"
								className={`input input--med ${(!isValid(data.orderId) && invalidClassName) || ''}`}
								placeholder={customDisplayLabels.orderId || 'Order ID'}
								value={data.orderId.value}
								onChange={this.onChange}
							/>
						</div>
					</div>
				)}
			</div>
		);
	}
}

TransactionDetailsFields.propTypes = {
	data: PropTypes.shape({
		invoice: fieldPropType(PropTypes.string).isRequired,
		poNumber: fieldPropType(PropTypes.string).isRequired,
		description: fieldPropType(PropTypes.string).isRequired,
		orderId: fieldPropType(PropTypes.string).isRequired,
	}).isRequired,
	onChange: PropTypes.func.isRequired,
	invalidClassName: PropTypes.string,
	requiredFields: PropTypes.object,
	customDisplayLabels: PropTypes.object,
	transactionHiddenFields: PropTypes.object,
	hideOrderId: PropTypes.bool,
};

export default TransactionDetailsFields;
