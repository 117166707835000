import httpService from './httpService';
const { statementEndpoint } = ApplicationSettings;

class StatementService {
	constructor(httpService) {
		this.httpService = httpService;
	}
	getHeaders = mid => {
		let headers = new Headers();
		if (mid) {
			headers.set('x-merchant-id', mid);
		}
		return headers;
	};

	getOptions = mid => {
		return {
			isJson: true,
			headers: this.getHeaders(mid),
		};
	};

	loadStatements = async mid => {
		const result = await this.httpService.post(
			`${statementEndpoint}liststatements`,
			{},
			{
				...this.getOptions(mid),
				allowPublic: true,
			}
		);

		return result;
	};
	loadStatement = async (mid, year, filename) => {
		const result = await this.httpService.post(
			`${statementEndpoint}loadstatement`,
			{ Year: year, FileName: filename },
			{
				...this.getOptions(mid),
				allowPublic: true,
			}
		);

		return result;
	};
}

const statementService = new StatementService(httpService);

export default statementService;
