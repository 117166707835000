import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { CurrencyMap, CardTypeImagePath } from '../../Common/utilities';
import NumberFormat from 'react-number-format';
import { Tooltip, PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { toLower } from 'lodash';

const RADIAN = Math.PI / 180;
const chartColors = {
	visa: {
		sale: '#D21745',
		credit: '#6A4AA0',
	},
	masterCard: {
		sale: '#A81743',
		credit: '#533A7C',
	},
	discover: {
		sale: '#821841',
		credit: '#473468',
	},
	amex: {
		sale: '#5C193E',
		credit: '#392856',
	},
	ebt: {
		sale: '#461A3C',
		credit: '#261A3B',
	},
	ebtw: {
		sale: '#361A3C',
		credit: '#1E1926',
	},
	debit: {
		sale: '#000000',
		credit: '#000000',
	},
};

class BatchPieChart extends Component {
	get chartType() {
		switch (this.props.type) {
			case 'sale':
				return 'Sale';
			case 'credit':
				return 'Credit';
			default:
				return 'sale';
		}
	}

	constructor(props) {
		super(props);

		this.state = {
			currency: CurrencyMap.resolveCurrency(),
			chartDataKey: props.type === 'sale' ? 'saleAmount' : 'creditAmount',
		};
	}

	get shouldDisplayChart() {
		const {
			data: { totals },
			type,
		} = this.props;
		return !(totals[`${type}Amount`] === 0 && totals[`${type}Count`] === 0);
	}

	getData = () => {
		const { totals } = this.props.data;
		const { type } = this.props;

		return [
			{
				name: 'Visa',
				icon: 'Visa',
				color: chartColors.visa[type],
				creditAmount: totals.visaCreditAmount,
				creditCount: totals.visaCreditCount,
				saleAmount: totals.visaSaleAmount,
				saleCount: totals.visaSaleCount,
			},
			{
				name: 'Master Card',
				icon: 'MasterCard',
				color: chartColors.masterCard[type],
				creditAmount: totals.mcCreditAmount,
				creditCount: totals.mcCreditCount,
				saleAmount: totals.mcSaleAmount,
				saleCount: totals.mcSaleCount,
			},
			{
				name: 'Discover',
				icon: 'Discover',
				color: chartColors.discover[type],
				creditAmount: totals.discoverCreditAmount,
				creditCount: totals.discoverCreditCount,
				saleAmount: totals.discoverSaleAmount,
				saleCount: totals.discoverSaleCount,
			},
			{
				name: 'AMEX',
				icon: 'Amex',
				color: chartColors.amex[type],
				creditAmount: totals.amexCreditAmount,
				creditCount: totals.amexCreditCount,
				saleAmount: totals.amexSaleAmount,
				saleCount: totals.amexSaleCount,
			},
			{
				name: 'EBT',
				icon: 'EBT',
				color: chartColors.ebt[type],
				creditAmount: totals.ebtCreditAmount,
				creditCount: totals.ebtCreditCount,
				saleAmount: totals.ebtSaleAmount,
				saleCount: totals.ebtSaleCount,
			},
			{
				name: 'EBTW',
				icon: 'EBTW',
				color: chartColors.ebtw[type],
				creditAmount: totals.eWicCreditAmount || totals.ebtwCreditAmount,
				creditCount: totals.eWicCreditCount || totals.ebtwCreditAmount,
				saleAmount: totals.eWicSaleAmount || totals.ebtwSaleAmount,
				saleCount: totals.eWicSaleCount || totals.ebtwSaleAmount,
			},
			{
				name: 'Debit',
				icon: 'Debit',
				color: chartColors.debit[type],
				creditAmount: totals.debitCreditAmount,
				creditCount: totals.debitCreditCount,
				saleAmount: totals.debitSaleAmount,
				saleCount: totals.debitSaleCount,
			},
		];
	};

	getEmptyData = () => {
		return [
			{
				name: 'N/A',
				icon: 'N/A',
				color: '#9AA2B0',
				creditAmount: 1,
				creditCount: 'N/A',
				saleAmount: 1,
				saleCount: 'N/A',
			},
		];
	};

	getCardName = cardName => {
		if (toLower(cardName) === 'ebtw') {
			cardName = 'eWIC';
		}
		return cardName;
	};

	renderCustomTooltip = data => {
		let payload = {};
		if (this.shouldDisplayChart && data && data.payload && data.payload[0] && data.payload[0].payload) {
			payload = data.payload[0].payload;
		} else {
			payload = {
				color: '#9AA2B0',
				stroke: '#9AA2B0',
				fill: '#9AA2B0',
				icon: 'N/A',
				name: 'N/A',
				creditAmount: 0,
				creditCount: 'N/A',
				saleAmount: 0,
				saleCount: 'N/A',
			};
		}

		const value = this.chartType === 'Sale' ? payload.saleAmount : payload.creditAmount;
		const count = this.chartType === 'Sale' ? payload.saleCount : payload.creditCount;

		return (
			<div className="batches__chart__tooltip">
				<div className="spc--bottom--sml">
					{this.shouldDisplayChart ? (
						<React.Fragment>
							<img src={CardTypeImagePath.getPath(payload.icon)} className="grid__creditcard" />
							<span className="type--color--text--regular">{this.getCardName(payload.name)}</span>
						</React.Fragment>
					) : (
						<React.Fragment>
							<div className="type--center">
								<img src="/static/media/warning.jpg" className="spc--bottom--nano" />
								<div className="type--color--warning type--wgt--medium type--lineheight--22">
									There are no values
									<br />
									to show in this view
								</div>
								<hr className="separator separator--grey1" />
							</div>
						</React.Fragment>
					)}
				</div>
				<div className="group type--sml">
					<div className="batches__chart__tooltip__label"></div>
					<div className="batches__chart__tooltip__value">
						<NumberFormat
							prefix={this.state.currency}
							value={value}
							displayType="text"
							thousandSeparator={true}
							decimalScale={2}
							fixedDecimalScale={true}
						/>
						<span> {`(Count: ${count})`}</span>
					</div>
				</div>
			</div>
		);
	};

	renderCustomChartLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		if (this.shouldDisplayChart) {
			if (percent > 0.04) {
				return (
					<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
						{' '}
						{`${(percent * 100).toFixed(0)}%`}
					</text>
				);
			}
		} else {
			return (
				<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
					0%
				</text>
			);
		}
	};

	renderLegend = data => {
		const { chartDataKey } = this.state;
		let total = 0;
		for (let item of data) {
			total += item[chartDataKey];
		}

		return data.map(x => {
			const amount = x[chartDataKey];
			const percent = (amount / total) * 100;
			let displayName = this.getCardName(x.name);

			if (x.isNa === true) {
				return (
					<div key={displayName}>
						<span style={{ color: x.color, fontSize: '36px', verticalAlign: 'text-bottom' }}>&#9679;</span>{' '}
						{displayName} (100%)
					</div>
				);
			} else {
				if (percent > 0) {
					let percentString = percent.toFixed(0);
					if (percent < 1) {
						percentString = '<1';
					}

					return (
						<div key={displayName} className="piechart-legend-item">
							<span style={{ color: x.color, fontSize: '36px', verticalAlign: 'text-bottom' }}>&#9679;</span>{' '}
							{displayName} ({percentString}%)
						</div>
					);
				}
			}
		});
	};

	render() {
		const chartData = this.shouldDisplayChart ? this.getData() : this.getEmptyData();

		return (
			<div>
				<h3 className="title title--secondary">{this.chartType} Summary by Card Type</h3>
				<div className="batches__card">
					<div className="f-row">
						<div className="f-col f-col-sml-12 f-col-med-6">
							<ResponsiveContainer height="100%" aspect={1} debounce={1}>
								<PieChart width={110} height={110}>
									<Tooltip content={this.renderCustomTooltip} />
									<Pie
										data={chartData}
										paddingAngle={0}
										innerRadius="56%"
										outerRadius="100%"
										fill="#82ca9d"
										dataKey={this.state.chartDataKey}
										legendType="circle"
										label={this.renderCustomChartLabel}
										labelLine={false}
									>
										{chartData.map((entry, index) => {
											return <Cell key={`cell-${index}`} stroke={entry.color} fill={entry.color} />;
										})}
									</Pie>
								</PieChart>
							</ResponsiveContainer>
						</div>
						<div className="f-col f-col-sml-12 f-col-med-6 pos--rel">
							<div className="batches__chart__legend">{this.renderLegend(chartData)}</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

BatchPieChart.propTypes = {
	data: PropTypes.any.isRequired,
	type: PropTypes.oneOf(['sale', 'credit']).isRequired,
};

export default BatchPieChart;
