import React from 'react';
import PropTypes from 'prop-types';
import { get, map, noop } from 'lodash';

function FraudSettingsActionGrid({
	onAction,
	onInputButton,
	gridTitle,
	inputPlaceholder,
	addButtonLabel,
	onGridInputChange,
	newGridItem,
	actionLabel,
	isLoading,
	iconOnly,
	columns = [],
	data = [],
}) {
	return (
		<div>
			<div className="message message--med message--default spc--bottom--med fullwidth">
				<div className="input--with-button">
					<input
						value={newGridItem}
						onChange={e => onGridInputChange(e.target.value)}
						type="text"
						className="input input--lrg"
						placeholder={inputPlaceholder}
						disabled={isLoading}
					/>
					<button className="btn btn--sml btn--primary" onClick={onInputButton} disabled={isLoading}>
						{addButtonLabel}
					</button>
				</div>
			</div>

			<div className="card card--med card--white spc--bottom--med--alt">
				<label className="label spc--bottom--sml">{gridTitle}</label>
				<div className="table--secondary__wrapper">
					<table className="table table--secondary table--secondary--alt">
						<thead>
							<tr>
								{map(columns, column =>
									column.label ? (
										<th key={column.label} colSpan={column.colSpan}>
											{column.label}
										</th>
									) : null
								)}
							</tr>
						</thead>
						<tbody>
							{map(data, (item, index) => {
								if (!item) return null;
								return (
									<tr key={`${item}-${index}`}>
										{map(columns, column => {
											const isObject = typeof item === 'object';
											const fieldValue = isObject ? item[column.field] : item;
											return (
												<td
													onClick={() => get(column, 'onClick', noop)(get(item, column.field))}
													className={column.fieldClassName || ''}
													key={column.field}
												>
													<div className="flex--primary flex--nowrap">
														{fieldValue}
														{column.onClick && <i className="icon icon--tiny icon--open-new spc--left--xsml cursor--pointer"></i>}
													</div>
												</td>
											);
										})}
										<td className="type--right">
											<button
												type="button"
												className={`table--secondary__remove ${iconOnly === true ? 'icon-only' : ''}`}
												onClick={() => onAction(item, index)}
												disabled={isLoading}
											>
												{actionLabel}
												<i className="icon icon--tiny icon--close"></i>
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}
FraudSettingsActionGrid.propTypes = {
	data: PropTypes.array.isRequired,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			field: PropTypes.string,
		})
	).isRequired,
	onAction: PropTypes.func.isRequired,
	onInputButton: PropTypes.func.isRequired,
	gridTitle: PropTypes.string,
	inputPlaceholder: PropTypes.string,
	addButtonLabel: PropTypes.string,
	onGridInputChange: PropTypes.func.isRequired,
	newGridItem: PropTypes.string.isRequired,
	actionLabel: PropTypes.string.isRequired,
	isLoading: PropTypes.bool.isRequired,
	iconOnly: PropTypes.bool.any,
};

export default FraudSettingsActionGrid;
