import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

const ModalComponent = ({ children, onClose, hideCloseButton, ...props }) => (
	<ReactModal onRequestClose={onClose} {...props}>
		{!hideCloseButton && <button tabIndex="-1" className="popup__close" onClick={onClose} />}
		{children}
	</ReactModal>
);

ModalComponent.defaultProps = {
	shouldCloseOnOverlayClick: true,
	overlayClassName: 'popup__overlay',
	className: 'popup__content',
};

ModalComponent.propTypes = {
	children: PropTypes.element.isRequired,
	onClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool,
	overlayClassName: PropTypes.string,
	className: PropTypes.string,
	shouldCloseOnOverlayClick: PropTypes.bool,
	hideCloseButton: PropTypes.bool,
};

export default ModalComponent;
