import { uniqueId } from 'common/utilities';
import {
	filter,
	find,
	merge,
	some,
	cloneDeep,
	keys,
	startsWith,
	times,
	padStart,
	each,
	get,
	isArray,
	includes,
	toLower,
	findIndex,
	identity,
	has,
} from 'lodash';
import moment from 'moment';
import { parse, stringify } from 'query-string';

import { calendarTypes, until, sections } from './constants';

function defaultExpand(type, customerId, focusSchedule, focusPayments) {
	if ((type === 'schedules' && !!customerId) || focusSchedule) {
		return sections.SCHEDULES;
	}
	if (focusPayments) {
		return sections.PAYMENTS;
	}
	return sections.GENERAL;
}

function initialData(customer, sendReceipt, { allowCcSave, allowCheckSave }, isPopup) {
	const general = customer ? addGeneralMetaToObject(customer) : newGeneral();
	const payments = [];
	if (allowCcSave && isPopup) {
		payments.push(newPayment('cc', true));
	} else if (allowCheckSave && isPopup) {
		payments.push(newPayment('check', true));
	}
	const schedules = [];
	if (allowCcSave || allowCheckSave) {
		schedules.push(newSchedule(sendReceipt));
	}
	return {
		general,
		payments,
		schedules,
		references: {
			general: null,
			payments: null,
			schedules: null,
			transactions: null,
		},
		errorMessages: {
			general: [],
			payments: [],
			schedules: [],
			transactions: [],
		},
	};
}

function setNewDefaultPayment(selectedTab) {
	return [newPayment(selectedTab, true)];
}

function newGeneral() {
	let obj = {
		customerId: null,
		basicScheduleData: '',
		billCity: '',
		billCompany: '',
		billCountry: '',
		billFirstName: '',
		billLastName: '',
		billMiddleName: '',
		billMobile: '',
		billName: '',
		billPhone: '',
		billState: '',
		billStreet: '',
		billStreet2: '',
		billZip: '',
		shipCity: '',
		shipCompany: '',
		shipFirstName: '',
		shipLastName: '',
		shipPhone: '',
		shipState: '',
		shipStreet: '',
		shipStreet2: '',
		shipZip: '',
		customerNumber: '',
		defaultPaymentMethodId: '',
		email: '',
		shipEmail: '',
		fax: '',
		customerNotes: '',
	};
	times(19, i => {
		const oneBasedIndex = padStart(i + 1, 2, 0);
		return (obj[`customerCustom${oneBasedIndex}`] = '');
	});
	obj = addGeneralMetaToObject(obj);
	obj._meta.created = true;

	return obj;
}

function newPayment(type, isDefault = false, isExisting = false) {
	let obj = {
		paymentMethodId: null,
		customerId: null,
		tokenType: type,
		cardType: '',
		exp: '',
		maskedNumber: '',
		name: '',
		description: '',
		token: '',
		tokenAlias: '',
		zip: '',
		street: '',
		isDefaultPaymentMethod: isDefault,
	};
	obj = addPaymentMetaToObject(obj);
	obj._meta.created = true;
	if (type === 'cc') {
		obj._meta.cc = {
			cardNumberIsEmpty: !isExisting,
			cardNumberIsValid: isExisting,
		};
	} else if (type === 'check') {
		obj._meta.check = {
			achIsEmpty: !isExisting,
			achIsValid: isExisting,
		};
	}

	return obj;
}

function newSchedule(
	sendReceipt,
	scheduleProcessingDefaultValues = {
		allowInitialTransactionToDecline: false,
		afterMaxRetriesAction: 'ContinueNextInterval',
		failedTransactionRetryTimes: 5,
		daysBetweenRetries: 1,
	}
) {
	let obj = {
		scheduleId: '',
		customerId: null,
		intervalType: 'month',
		intervalCount: '1',
		skipSaturdayAndHolidays: false,
		custReceipt: sendReceipt,
		calendarCulture: calendarTypes.GREGORIAN,
		amount: '',
		totalPayments: '',
		startDate: moment().format(ApplicationSettings.apiDateFormat),
		startDateRawDate: new Date(),
		allowDecline: false,
		allowDuplicate: false,
		useDefaultPaymentMethodOnly: false,
		isActive: true,
		description: '',
		scheduleName: '',
		endDate: '',
		invoice: '',
		includeConvenience: true,
	};
	obj = addScheduleDefaultProcessingValues(obj, scheduleProcessingDefaultValues);
	obj = addRecurringMetaToObject(obj);
	obj._meta.created = true;
	obj._meta.until = until.NEVER;
	obj._meta.isNewSchedule = true;

	return obj;
}

function addScheduleDefaultProcessingValues(schedule, scheduleProcessingDefaultValues) {
	each(scheduleProcessingDefaultValues, (value, key) => {
		if (!schedule[key] && value !== undefined) {
			schedule[key] = value;
		}
	});

	return schedule;
}

function getArrayItemByMetaId(collection, key) {
	return find(collection, item => item._meta.id === key);
}

function removeItemFromArrayByMetaId(collection, key) {
	return filter(collection, item => {
		return item._meta.id !== key;
	});
}

function addGeneralMetaToObject(obj) {
	obj._meta = {
		isSelected: get(obj, '_meta.isSelected', false),
		modified: false,
		created: false,
		originalData: cloneDeep(obj),
		sameAsBilling: !some(keys(obj), key => startsWith(key, 'ship') && obj[key]),
		visibleCustomFields: {},
	};

	times(19, i => {
		const key = `customerCustom${i < 9 ? `0${i + 1}` : i + 1}`;
		obj._meta.visibleCustomFields[key] = !!obj[key] || i < 3;
	});

	return obj;
}

function addRecurringMetaToObject(obj) {
	obj._meta = {
		id: uniqueId(),
		errorMessages: [],
		isLoading: false,
		created: false,
		modified: false,
		deleted: false,
		saveAsTemplate: false,
		until: obj.until,
		originalData: cloneDeep(obj),
		scheduleExpanded: obj.scheduleExpanded !== undefined ? obj.scheduleExpanded : true,
		specificDayOfWeek: false,
	};
	return obj;
}

function addRecurringMetaToArray(items) {
	each(items, (item, index) => {
		item.scheduleExpanded = index === 0;
		item = addRecurringMetaToObject(item);
	});
	return items;
}

function addPaymentMetaToObject(obj) {
	obj._meta = {
		id: uniqueId(),
		errorMessages: [],
		isLoading: false,
		created: false,
		modified: false,
		deleted: false,
		routingNumber: '',
		originalData: cloneDeep(obj),
		paymentExpanded: obj.paymentExpanded !== undefined ? obj.paymentExpanded : true,
	};
	return obj;
}

function addPaymentMetaToArray(items) {
	each(items, (item, index) => {
		item.paymentExpanded = index === 0;
		item = addPaymentMetaToObject(item);
	});
	return items;
}

function convertArrayToProperValues(items) {
	for (let item of items) {
		convertObjectToProperValues(item);
	}
	return items;
}

function convertObjectToProperValues(obj) {
	const keys = Object.keys(obj);
	for (let key of keys) {
		try {
			if (obj[key].toLowerCase() === 'true' || obj[key].toLowerCase() === 'false') {
				obj[key] = obj[key].toLowerCase() === 'true' ? true : false;
			}
		} catch (error) {
			// intentionally empty catch block
		}
	}
	return obj;
}

function prepareRecurringArray(items) {
	items = convertArrayToProperValues(items);
	items = addRecurringMetaToArray(items);
	return items;
}

function preparePaymentArray(items) {
	items = convertArrayToProperValues(items);
	items = addPaymentMetaToArray(items);
	return items;
}

function mapTransactionToCustomer(transaction, sendReceipt, { allowCcSave, allowCheckSave }) {
	const payment = {
		tokenType: transaction.xCommand.split(':')[0].toLowerCase(),
		cardType: transaction.xCardType,
		exp: transaction.xExp,
		maskedNumber: transaction.xMaskedAccountNumber,
		name: transaction.xName,
		token: transaction.xToken,
		street: transaction.xStreet,
		zip: transaction.xZip,
		isDefaultPaymentMethod: true,
		setAsDefault: true,
		_meta: {
			modified: true,
			originalExp: transaction.xExp,
		},
	};
	if (payment.tokenType === 'gift') {
		payment.tokenType = 'cc';
	}
	const mappedTransaction = {
		general: {
			billCity: transaction.xBillCity,
			billCompany: transaction.xBillCompany,
			billCountry: transaction.xBillCountry,
			billFirstName: transaction.xBillFirstName,
			billLastName: transaction.xBillLastName,
			billPhone: transaction.xBillPhone,
			billState: transaction.xBillState,
			billStreet: transaction.xBillStreet,
			billZip: transaction.xBillZip,
			email: transaction.xEmail,
		},
		payments:
			((payment.tokenType === 'cc' || payment.tokenType === 'gift') && allowCcSave) ||
			(payment.tokenType === 'check' && allowCheckSave)
				? [merge({}, newPayment(payment.tokenType, true, !!payment.maskedNumber), payment)]
				: [],
	};
	const initData = initialData(false, sendReceipt, {});
	initData.payments = [];
	return merge({}, initData, mappedTransaction);
}

function getModifiedItems(collection) {
	return filter(collection, item => item._meta.modified && !item._meta.deleted);
}

function getSaveAsTemplateItems(collection) {
	return filter(collection, item => item._meta.saveAsTemplate);
}

function getDeletedItems(collection) {
	return filter(collection, item => item._meta.deleted);
}

function getModifiedActivity(collection, errors) {
	return filter(
		collection,
		item =>
			!item._meta.deleted &&
			item.isActive !== item._meta.originalData.isActive &&
			!some(errors, error => error.item.scheduleId === item.scheduleId)
	);
}

const queryFilterValues = (filters, otherProps, updateState, query, initialExpandRow) => {
	const {
		history,
		location: { search: oldSearch },
	} = otherProps;
	let result = {};
	if (query) {
		result = parse(query);
		if (!!result.expandedRow && initialExpandRow) {
			updateState({ expandedRow: result.expandedRow });
		}
	}
	each(filters, ({ values, hasSelection }) => {
		if (hasSelection) {
			each(values, (value, key) => {
				if (value) {
					result[key] = value;
				}
			});
		}
	});
	delete result.refresh;
	const search = stringify(result);
	if (`?${search}` !== oldSearch) {
		history.replace({
			search,
		});
	}
};

function addCustomDataType(newData, customData, mapper, transformer = identity) {
	let anyChanged = false;
	each(customData && customData.data, (value, customSettingsKey) => {
		const item = find(newData, item =>
			isArray(item.customSettingsKey)
				? includes(item.customSettingsKey, customSettingsKey)
				: toLower(item.customSettingsKey) === toLower(customSettingsKey) || has(item, `values.${customSettingsKey}`)
		);
		if (item) {
			if (isArray(item.customSettingsKey)) {
				const labelIndex = findIndex(item.customSettingsKey, key => key === customSettingsKey);
				if (labelIndex > -1) {
					if (mapper(item, value, labelIndex)) {
						anyChanged = true;
					}
				}
			} else {
				if (mapper(item, value)) {
					anyChanged = true;
				}
			}
		}
	});
	if (anyChanged) {
		return transformer(newData);
	}
	return newData;
}

export {
	defaultExpand,
	initialData,
	newGeneral,
	newPayment,
	newSchedule,
	getArrayItemByMetaId,
	removeItemFromArrayByMetaId,
	addGeneralMetaToObject,
	addRecurringMetaToObject,
	addRecurringMetaToArray,
	addPaymentMetaToObject,
	addPaymentMetaToArray,
	convertArrayToProperValues,
	convertObjectToProperValues,
	prepareRecurringArray,
	preparePaymentArray,
	mapTransactionToCustomer,
	getModifiedItems,
	getModifiedActivity,
	getDeletedItems,
	setNewDefaultPayment,
	getSaveAsTemplateItems,
	queryFilterValues,
	addCustomDataType,
};
