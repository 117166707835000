import React, { Component } from 'react';
import { func, string } from 'prop-types';

import { withCancelable } from '../cancelable';
import { messagingService, principalService } from 'common/services';
import { validators } from 'common/fields';
import { endsWith } from 'lodash';

class SendSmsPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isContentValid: true,
			phoneNumber: props.phoneNumber,
		};
	}

	handleChange = ({ target: { name, value } }) => {
		this.setState({
			[name]: value,
		});
	};

	showLoader = (isLoading = false) => this.setState({ isLoading });

	sendSms = async () => {
		const {
			closeModal,
			addNotification,
			handleError,
			makePendingRequest,
			paymentSiteName,
			newPaymentLink,
		} = this.props;
		const { phoneNumber } = this.state;

		this.showLoader(true);

		const paymentSiteLink = `${paymentSiteName}${newPaymentLink}${
			!paymentSiteName && !newPaymentLink ? 'exampleLink' : ''
		}`;
		try {
			await makePendingRequest(
				messagingService.sendPaymentRequest(phoneNumber, principalService.get().companyName, paymentSiteLink)
			);

			await addNotification({
				message: 'SMS sent successfully.',
				success: true,
			});
		} catch (e) {
			const notification = handleError(e, { delayMessage: true });
			if (endsWith(notification.message, 'number')) {
				notification.message = 'The phone number entered is invalid.';
			}

			if (notification) {
				notification.show();
			}
		}
		this.showLoader();
		closeModal();
	};

	render() {
		const { closeModal } = this.props;
		const { phoneNumber, isLoading } = this.state;
		const isPhoneNumberValid = !!phoneNumber && validators.phoneNumber(phoneNumber);

		const disabled = !isPhoneNumberValid || isLoading;

		return (
			<div className="popup">
				<div className="popup__header">
					<div className="popup__header__title">Send Payment Request</div>
				</div>
				<div className="popup__body">
					{isLoading ? (
						<div className="loader--popup__holder">
							<div className="loader__spinner"></div>
						</div>
					) : (
						<div className="spc--bottom--sml">
							<label htmlFor="phoneNumber" className="datatooltip--v--bottom">
								Phone Number
								<span data-tooltip="Required" className="label--required">
									*
								</span>
							</label>
							<input
								name="phoneNumber"
								id="phoneNumber"
								type="tel"
								className={`input input--med${isPhoneNumberValid ? '' : ' is-invalid'}`}
								value={phoneNumber}
								onChange={this.handleChange}
							/>
						</div>
					)}
				</div>
				<div className="popup__footer popup__footer--styled">
					<button type="button" tabIndex="-1" className="btn btn--sml btn--ghost spc--right--xsml" onClick={closeModal}>
						Close
					</button>
					<button
						type="button"
						tabIndex="-1"
						className="btn btn--sml btn--primary"
						onClick={this.sendSms}
						disabled={disabled}
					>
						Send Text
					</button>
				</div>
			</div>
		);
	}
}

SendSmsPopup.propTypes = {
	phoneNumber: string,
	newPaymentLink: string,
	handleError: func.isRequired,
	makePendingRequest: func.isRequired,
	closeModal: func.isRequired,
	addNotification: func.isRequired,
	paymentSiteName: string,
};

export default withCancelable(SendSmsPopup);
