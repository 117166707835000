import React, { Component } from 'react';

import { default as TransactionReceipt } from '../../components/transaction-actions/receipt';
import { Modal } from '../../components/modal';
import { default as Notification } from './notifications';

class ReceiptNotificationComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			receiptVisible: false,
			emailNotificationVisible: false,
		};
	}

	get additional() {
		const { receiptVisible, emailNotificationVisible } = this.state;

		return [
			{
				Message: ({ notification }) =>
					notification && notification.showViewTransaction ? (
						<div className="type--center type--color--text--regular fullwidth">
							<a href="javascript:void(0)" onClick={this.showReceiptModal} className="anchor anchor--primary">
								View transaction
							</a>
						</div>
					) : null,
				Body: ({ notification }) =>
					notification && notification.showViewTransaction ? (
						<Modal isOpen={receiptVisible} onClose={() => this.closeReceiptModal(notification.onClose)}>
							<TransactionReceipt
								notificationRef={this.notificationRef}
								refNum={notification.ref}
								emailNotificationVisible={emailNotificationVisible}
								handleEmailNotificationVisible={this.handleEmailNotificationVisible}
								closeModal={() => this.closeReceiptModal(notification.onClose)}
							/>
						</Modal>
					) : null,
			},
		];
	}

	handleEmailNotificationVisible = () => {
		this.setState({ emailNotificationVisible: !this.state.emailNotificationVisible });
	};

	showReceiptModal = () => {
		this.notificationRef.setState(
			{
				visible: false,
			},
			() => {
				this.setState({
					receiptVisible: true,
				});
			}
		);
	};

	closeReceiptModal = callback => {
		this.setState(
			{
				receiptVisible: false,
				emailNotificationVisible: false,
			},
			callback
		);
	};

	addNotification = notification => this.notificationRef.addNotification(notification);

	render = () => (
		<Notification
			ref={el => {
				this.notificationRef = el;
			}}
			additional={this.additional}
		/>
	);
}

export default ReceiptNotificationComponent;
