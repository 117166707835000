import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import {
	some,
	map,
	each,
	filter,
	find,
	toLower,
	noop,
	isEmpty,
	clone,
	startsWith,
	cloneDeep,
	keys,
	split,
	trim,
	isString,
	isFunction,
	replace,
	startCase,
	camelCase,
	findKey,
	get,
	isPlainObject,
	partition,
	isObject,
	endsWith,
	every,
	compact,
	isNumber,
	first,
	join,
	toString,
	nth,
	concat,
	uniqBy,
	size,
	round,
	times,
	padStart,
	includes,
	upperFirst,
	mapKeys,
	flowRight,
} from 'lodash';
import moment from 'moment';

import * as Util from './utils';
import AddEditCustomerGeneral from './general/main';
import AddEditCustomerPayments from './payments/main';
import AddEditCustomerSchedules from './schedules/main';
import { calculateConvenienceFee } from '../../../../components/new-transaction/helpers';
import { SchedulePreviewGrid } from '../../../../components/schedule-preview-grid';
import ActionsModal from './../../transaction-actions/actions-modal-wrapper';
import { modalNames } from './../../transaction-actions/modal-names';
import { customerService, kvaasService, transactionService, principalService } from './../../../services';
import { until, today, yesterday, sections, threeDS2ProcessTooltip } from './constants';
import { isAfter, kvaasResources, checkIfCanadian, validatePermissions, apiTimezone } from './../../../utilities';
import { UNEXPECTED_ERROR_MSG } from '../../../../components/error';
import { validators } from '../../../fields';
import { withError } from '../../error';
import { Modal } from '../../modal';
import { WrappedViewPaymentsGrid as ViewPaymentsGrid } from 'components/recurring-transactions/ViewPaymentsGrid';
import sectionKeys from '../../../../routing/sections';
import { isOlderThanSevenMonths } from './../../../utilities/isOlderThanSevenMonths';
import { withCancelable } from 'common/components/cancelable';
import { withForwardRef } from 'common/components/with-forward-ref';
import { accountType as accountTypeConstants } from 'components/new-transaction/constants';
import { withLoadMore } from 'common/components/loadmore';
import { SidebarContext } from 'common/contexts';
import { withRouter } from 'react-router-dom';

const requestKeys = {
	FETCH: 'fetch',
	SAVE: 'save',
	REQUIRED: 'required',
	KVAAS: 'kvaas',
	FLAGS: 'flags',
	CANADIAN: 'canadian',
	TOKEN: 'token',
	REMOVE: 'remove',
	DEACTIVATE: 'deactivate',
};
const generalErrorMessages = {
	billCity: 'City is required',
	billCompany: 'Company is required',
	billCountry: 'Country is required',
	billFirstName: 'First name is required',
	billLastName: 'Last name is required',
	billMiddleName: 'Middle name is required',
	billMobile: 'Mobile Phone number is required',
	billName: 'Bill Name is required',
	billPhone: 'Phone number is required',
	billState: 'State is required',
	billStreet: 'Address is required',
	billStreet2: 'Address 2 is required',
	billZip: 'ZIP is required',
	customerNumber: 'Customer number is required',
	defaultPaymentMethodId: 'Default payment method is required',
	email: 'Email is required',
	fax: 'Fax is required',
	customerNotes: 'Note is required',
};

const scheduleErrorMessages = {
	invoice: 'Invoice is required',
	scheduleName: 'Schedule name is required',
	description: 'Description is required',
};
const { apiDateTimeFormat } = ApplicationSettings;

class AddEditCustomerForm extends Component {
	static contextType = SidebarContext;
	constructor(props) {
		super(props);
		this.pageTopRef = createRef();
		this.customerPaymentsRef = createRef();
		this.paymentsRef = createRef();
		this.schedulesRef = createRef();
		this.recurringScheduleTabRef = createRef();
		this.tabsMenuRef = createRef();
		this.popupRef = createRef();
		this.customerSchedulesRefs = createRef();

		const principal = principalService.get();
		const has3DS2 = principal.idInfo.x3DSEnabled && principal.idInfo.x3DSVersion === '2';
		let permissions = {};
		let achEnabled = false;

		if (principal && principal.idInfo) {
			permissions = principal && principal.idInfo && principal.idInfo.permissions;
			achEnabled = principal.idInfo.xACHEnabled;
		}

		const endDate = moment()
			.endOf('day')
			.format(apiDateTimeFormat);
		const beginDate = moment(endDate)
			.startOf('day')
			.subtract(30, 'days')
			.format(apiDateTimeFormat);

		this.state = {
			has3DS2,
			expand: Util.defaultExpand(props.type, props.customerId, props.focusSchedule, props.focusPayments),
			isPreviewOpen: false,
			isViewPaymentsOpen: false,
			isLoading: true,
			fetchingToken: false,
			isSubmitted: false,
			previewedSchedule: null,
			recurringTransactions: null,
			customBlurred: false,
			isViewOnly: get(principal, 'isViewOnly', false),
			modal: {
				name: modalNames.none,
				data: null,
			},
			requiredFields: {},
			customDisplayLabels: {},
			hiddenFields: {},
			customerRequiredFields: {},
			customerHiddenFields: {},
			data: props.existingTransaction
				? Util.mapTransactionToCustomer(props.existingTransaction, false, permissions)
				: Util.initialData(props.customer, false, permissions, this.isPopup),
			sendReceipt: false,
			isCanadian: false,
			selectedTab: 'cc',
			permissions,
			hasIfields: false,
			existsNewModifiedPayment: false,
			isWarningModalOpen: false,
			tabToSwitch: '',
			hideSkipSabbath: false,
			allowInitialTransactionToDecline: false,
			setNewCardAsDefault: false,
			afterMaxRetriesAction: 'ContinueNextInterval',
			failedTransactionRetryTimes: '',
			daysBetweenRetries: '',
			hideCustomerForm: false,
			achEnabled,
			scheduleName: '',
			scheduleId: '',
			principal,
			transactionHistory: {
				disableLoadMore: false,
				endDate,
				beginDate,
			},
			customers: null,
		};
	}

	get zipLabel() {
		return this.state.isCanadian ? 'Postal Code' : 'Zip';
	}

	get isPopup() {
		return !this.props.customerId;
	}

	isAchEnabled = () => {
		const { achEnabled, isCanadian } = this.state;
		return achEnabled && !isCanadian;
	};

	componentDidMount = async () => {
		try {
			const newState = {
				data: { ...this.state.data },
			};

			if (
				(this.props.focusSchedule || this.props.type === 'schedules') &&
				this.tabsMenuRef.current &&
				this.recurringScheduleTabRef.current
			) {
				this.tabsMenuRef.current.scrollLeft = this.recurringScheduleTabRef.current.offsetLeft;
			}
			const [
				portalFlags,
				userSettings,
				recurringSchedules,
				convenienceFees,
				customDisplayLabels,
				requiredFields,
				customerHiddenFields,
				customerRequiredFields,
			] = await this.props.makePendingRequest(
				kvaasService.get(
					kvaasResources.portalFlags,
					kvaasResources.userSettings,
					kvaasResources.recurringSchedules,
					kvaasResources.convenienceFees,
					kvaasResources.transactionDisplayLabels,
					kvaasResources.transactionRequiredFields,
					kvaasResources.customerHiddenFields,
					kvaasResources.customerRequiredFields
				),
				requestKeys.FLAGS
			);
			if (
				some(
					[portalFlags, userSettings, convenienceFees],
					({ result, error }) =>
						(toLower(result) !== 's' && toLower(error) !== 'item does not exist') ||
						toLower(error) === 'failed to fetch'
				)
			) {
				newState.failedToFetchKvaas = true;
			}
			newState.customDisplayLabels = get(customDisplayLabels, 'data', {});
			newState.customerHiddenFields = get(customerHiddenFields, 'data', {});
			newState.customerRequiredFields = get(customerRequiredFields, 'data', {});
			newState.requiredFields = get(requiredFields, 'data', {});
			newState.convenienceFees = get(convenienceFees, 'data', {});
			newState.paymentMethods = await this.getCustomerPaymentMethods();
			const portalFlagsData = get(portalFlags, 'data');
			const userSettingsData = get(userSettings, 'data');
			const recurringSchedulesData = get(recurringSchedules, 'data');

			this.mapRecurringSchedulesDataToState(newState, recurringSchedulesData);
			this.mapScheduleProcessingToNewSchedule(newState, recurringSchedulesData);
			if (recurringSchedulesData || portalFlagsData) {
				newState.sendReceipt = recurringSchedulesData.sendReceiptByDefault || portalFlagsData.sendReceipt || false;
				const schedules = cloneDeep(this.state.data.schedules);
				map(schedules, schedule => {
					if (schedule._meta.created) {
						schedule.custReceipt = recurringSchedulesData.sendReceiptByDefault;
					}
				});
				this.setState({
					data: {
						...this.state.data,
						schedules: schedules,
					},
				});
			}
			if (userSettingsData) {
				newState.hideSkipSabbath = userSettingsData.hideSkipSabbath || false;
			}
			await this.setStateAsync(newState);
			await this.reset({
				general: !this.props.customer,
				schedules: this.state.expand === sections.SCHEDULES,
				payments: this.state.expand === sections.PAYMENTS || !isEmpty(recurringSchedulesData),
			});
			this.updateScheduleScroll();
			const isCanadian = checkIfCanadian();
			this.setState({ isCanadian }, this.setCustomerNumberToCustom);
			await this.mapHiddenCustomFields(newState.customerHiddenFields);
			this.mapExistingTransationCustomFieldsToState();
		} catch (e) {
			this.props.handleError(e);
		}
	};

	componentDidUpdate = prevProps => {
		if (this.props.focusSchedule && this.props.focusScheduleIndex !== prevProps.focusScheduleIndex) {
			this.switchExpanded(sections.SCHEDULES);
		}
	};
	componentWillUnmount = () => {
		this.enableDisableSidebar(false);
	};

	enableDisableSidebar = enable => {
		this.context.enableDisableNewCustomerSidebar(enable);
	};

	mapPayments = payments => {
		if (!isEmpty(payments)) {
			return partition(payments, payment => {
				return payment.tokenType === 'CC';
			});
		}
		return payments;
	};

	mapRecurringSchedulesDataToState = (newState, recurringSchedulesData) => {
		if (recurringSchedulesData) {
			const {
				allowInitialTransactionToDecline,
				afterMaxRetriesAction,
				setNewCardAsDefault,
				failedTransactionRetryTimes,
				daysBetweenRetries,
			} = recurringSchedulesData;
			newState.allowInitialTransactionToDecline = !!allowInitialTransactionToDecline;
			newState.setNewCardAsDefault = setNewCardAsDefault;
			newState.failedTransactionRetryTimes =
				(isOlderThanSevenMonths ? failedTransactionRetryTimes || 6 : failedTransactionRetryTimes || 4) - 1;
			newState.daysBetweenRetries = (isOlderThanSevenMonths ? daysBetweenRetries || 2 : daysBetweenRetries || 4) - 1;
			newState.afterMaxRetriesAction = isOlderThanSevenMonths
				? afterMaxRetriesAction || 'ContinueNextInterval'
				: 'Disable';
		}
	};

	mapScheduleProcessingToNewSchedule = (newState, recurringSchedulesData) => {
		const { existingTransaction } = this.props;
		if (!existingTransaction) {
			const scheduleProcessingDefaultValues = recurringSchedulesData
				? {
						allowInitialTransactionToDecline: newState.allowInitialTransactionToDecline,
						afterMaxRetriesAction: newState.afterMaxRetriesAction,
						failedTransactionRetryTimes: newState.failedTransactionRetryTimes,
						daysBetweenRetries: newState.daysBetweenRetries,
				  }
				: undefined;

			newState.data = { ...this.state.data };

			newState.data.schedules = [Util.newSchedule(false, scheduleProcessingDefaultValues)];
			times(19, i => {
				const oneBasedIndex = i + 1;
				if (oneBasedIndex === 1) return;
				const key = `recurringCustom${oneBasedIndex}`;
				const padStartKey = `custom${padStart(i + 1, 2, 0)}`;
				if (!this.state.customerHiddenFields[key]) {
					newState.data.schedules[0][padStartKey] = '';
				}
			});
		}
	};

	handleTransactionsLoadError = (e, newData) => {
		newData.errorMessages.transactions.push((e && e.message) || UNEXPECTED_ERROR_MSG);
		newData.references.transactions = e && e.ref;
	};

	handleLoadMore = (oldTransactions, newData, beginDate, endDate, customerId, resolve) => {
		const dateFilter = {
			values: {
				startDate: beginDate,
				endDate,
			},
		};

		const { start, end, refNums } = transactionService.getNewStartEndDates(
			dateFilter.values.startDate,
			dateFilter.values.endDate,
			this.state.data.transactions
		);
		const compiledFilter = {
			xCustom01: `a=${customerId}`,
			xBeginDate: start.tz(apiTimezone).format(apiDateTimeFormat),
			xEndDate: end.tz(apiTimezone).format(apiDateTimeFormat),
			xCommand: 'report:all',
			xGetNewest: true,
		};

		dateFilter.values.startDate = start;
		dateFilter.values.endDate = end;
		const newLimit = Math.min(10 + refNums.length, 1000);
		this.props
			.loadMore(
				oldTransactions,
				{},
				dateFilter,
				newLimit,
				refNums,
				compiledFilter,
				null,
				transactionService.getTransactionFields(),
				false,
				customerService.parseTransactionResults,
				true
			)
			.then(({ additionalData, additionalRefNums }) => {
				this.combineData(oldTransactions, additionalData, additionalRefNums);
				newData.transactions = uniqBy(oldTransactions.xReportData, 'xRefNum');

				newData.references.transactions = newData.xRefNum;
			})
			.catch(e => {
				this.handleTransactionsLoadError(e, newData);
			})
			.finally(resolve);
	};

	reset = async fieldsToRetrieve => {
		try {
			const [data] = await this.props.makePendingRequest(
				Promise.all([
					this.fetchCustomer({ customerId: this.props.customerId, ...fieldsToRetrieve }),
					this.getTransactionFields(),
				]),
				requestKeys.FETCH
			);
			const { allowCcSave, allowCheckSave } = this.state.permissions;
			const hasPermissionCheckElseCc = !allowCcSave && allowCheckSave ? 'check' : 'cc';
			const defaultPaymentMethod = find(
				data.payments,
				({ paymentMethodId, isDefaultPaymentMethod }) =>
					paymentMethodId === data.general.defaultPaymentMethodId || isDefaultPaymentMethod
			);

			return new Promise(resolve => {
				this.setState(
					{
						isLoading: false,
						selectedTab: defaultPaymentMethod ? toLower(defaultPaymentMethod.tokenType) : hasPermissionCheckElseCc,
						data,
					},
					() => {
						this.props.resizeGrid();
						resolve();
					}
				);
			});
		} catch (e) {
			if (e && !e.isCanceled) {
				//eslint-disable-next-line
				console.error(e);
			}
		}
	};

	mapExistingTransactionCustomFieldsToNewSchedule = schedule => {
		const {
			data: {
				general: {
					_meta: { visibleCustomFields },
				},
			},
		} = this.state;
		const {
			data: { general },
			convenienceFees: { originalCustomKey, convenienceCustomKey },
		} = this.state;
		each(general, (value, key) => {
			if (
				startsWith(key, 'customerCustom') &&
				value &&
				parseFloat(key.slice(-2)) > 1 &&
				visibleCustomFields[`customerCustom${key.slice(-2)}`]
			) {
				const customField = `custom${key.slice(-2)}`;
				if (customField !== toLower(originalCustomKey) && customField !== toLower(convenienceCustomKey)) {
					schedule[customField] = value;
				}
			}
		});
	};

	mapExistingTransationCustomFieldsToState = () => {
		const {
			data: {
				general: {
					_meta: { visibleCustomFields },
				},
			},
		} = this.state;
		const { existingTransaction } = this.props;
		if (existingTransaction) {
			const newState = cloneDeep(this.state);
			each(existingTransaction, (value, key) => {
				if (
					startsWith(key, 'xCustom') &&
					value &&
					parseFloat(key.slice(-2)) > 1 &&
					visibleCustomFields[`customerCustom${key.slice(-2)}`]
				) {
					const customField = `customerCustom${key.slice(-2)}`;
					newState.data.general[customField] = value;
				}
			});
			this.setState(newState);
		}
	};

	mapHiddenCustomFields = async (hiddenFields = this.state.customerHiddenFields) => {
		try {
			const {
				data,
				data: {
					general: {
						_meta: { visibleCustomFields },
					},
				},
			} = this.state;
			const newState = cloneDeep(this.state);

			each(visibleCustomFields, (_, key) => {
				const number = parseFloat(key.slice(-2));
				if (number > 3 && number < 20) {
					newState.data.general._meta.visibleCustomFields[key] =
						!get(hiddenFields, `data.custom${number}`) || data.general[key];
				}
			});
			await this.setStateAsync(newState);
		} catch (e) {
			if (e && !e.isCanceled) {
				//eslint-disable-next-line
				console.error(e);
			}
		}
	};

	getTransactionFields = async () => {
		const { customerId, advancedView } = this.props;
		const { requiredFields, customDisplayLabels } = this.state;
		const newState = cloneDeep(this.state);

		if (requiredFields && requiredFields.data) {
			newState.requiredFields = requiredFields.data;
			delete newState.requiredFields.custom1;
			if (
				(requiredFields.data[toLower(this.zipLabel)] ||
					requiredFields.data.address ||
					some(keys(requiredFields.data), key => startsWith(key, 'custom'))) &&
				!customerId &&
				!advancedView
			) {
				this.props.toggleAdvancedView();
			}
		}
		if (customDisplayLabels && customDisplayLabels.data) {
			newState.customDisplayLabels = customDisplayLabels.data;
		}
		this.setState(newState);
	};

	handleOpenPreview = id => {
		const schedules = cloneDeep(this.state.data.schedules);
		const previewedSchedule = Util.getArrayItemByMetaId(schedules, id);
		this.setState({ isPreviewOpen: true, previewedSchedule });
	};

	handleClosePreview = () => {
		this.setState({ isPreviewOpen: false });
	};
	handleOpenViewPayments = (scheduleId, scheduleName) => {
		this.setState({ isViewPaymentsOpen: true, scheduleId, scheduleName });
	};

	handleCloseViewPayments = () => {
		this.setState({ isViewPaymentsOpen: false });
	};

	hideCustomerForm = () => {
		this.setState({ hideCustomerForm: true });
	};

	loadmore = async () => {
		const {
			transactionHistory,
			data: { transactions },
			transactionsSize,
		} = this.state;

		let bDate = transactionHistory.beginDate;
		let tSize;
		if (!isEmpty(transactions) && transactionsSize !== size(transactions)) {
			bDate = moment(nth(transactions, -1).xEnteredDate, apiDateTimeFormat);
		}
		tSize = size(transactions);
		const endDate = moment(bDate)
			.endOf('day')
			.tz(apiTimezone)
			.format(apiDateTimeFormat);
		let beginDate = moment(endDate)
			.startOf('day')
			.subtract(30, 'days')
			.tz(apiTimezone)
			.format(apiDateTimeFormat);
		const lastSixMonths = moment()
			.tz(apiTimezone)
			.diff(
				moment()
					.tz(apiTimezone)
					.subtract(6, 'months'),
				'days'
			);
		const dateDifference = moment()
			.tz(apiTimezone)
			.diff(beginDate, 'days');
		const disableLoadMore = dateDifference > lastSixMonths;
		if (disableLoadMore) {
			beginDate = moment(beginDate)
				.tz(apiTimezone)
				.add(dateDifference - lastSixMonths, 'days')
				.format(apiDateTimeFormat);
		}
		await this.setState({
			transactionHistory: { disableLoadMore, endDate, beginDate },
			transactionsSize: tSize,
		});
		this.switchExpanded('transactions', true);
	};
	mapVisibleCustomFields = (data, customerHiddenFields) => {
		each(customerHiddenFields, (value, key) => {
			if (!data[key]) {
				data[key] = value;
			}
		});
	};
	fetchCustomer = async ({
		customerId,
		general = false,
		payments = false,
		schedules = false,
		transactions = false,
		loadMore = false,
	}) => {
		const { schedule, onDataChange, customer } = this.props;
		const {
			data,
			data: {
				general: { defaultPaymentMethodId },
			},
			customerHiddenFields,
		} = this.state;

		if (!customerId) {
			return data;
		}
		const newData = {
			general: {},
			payments: [],
			schedules: [],
			transactions: [],
			references: {
				general: null,
				payments: null,
				schedules: null,
				transactions: null,
			},
			errorMessages: {
				general: [],
				payments: [],
				schedules: [],
				transactions: [],
			},
		};

		if (general) {
			try {
				const userData = await customerService.getCustomer(customerId);
				let customerData = cloneDeep(userData.xReportData[0]);
				if (customer) {
					onDataChange([customerData]);
				}
				newData.general = Util.addGeneralMetaToObject(customerData, customerHiddenFields);
				this.mapVisibleCustomFields(newData.general._meta.visibleCustomFields);

				newData.references.general = userData.refNum;
			} catch (e) {
				this.hideCustomerForm();
				this.props.handleError(e, { onClose: this.props.closeRow });
				return;
			}
		} else {
			newData.general = cloneDeep(data.general);
			newData.references.general = data.references.general;
			newData.errorMessages.general = data.errorMessages.general;
		}

		const paymentsPromise = new Promise(resolve => {
			if (payments) {
				customerService
					.getCustomerPaymentMethods(customerId, newData.general.defaultPaymentMethodId || defaultPaymentMethodId)
					.then(paymentMethods => {
						const mappedCustomer = customerService.mapCustomerPaymentMethod(newData.general, paymentMethods);
						onDataChange([mappedCustomer]);
						newData.paymentMethods = this.mapPaymentMethods(paymentMethods);
						newData.payments = Util.preparePaymentArray(paymentMethods.xReportData);
						newData.references.payments = paymentMethods.refNum;
					})
					.catch(e => {
						newData.errorMessages.payments.push((e && e.message) || UNEXPECTED_ERROR_MSG);
						newData.references.payments = e && e.ref;
					})
					.finally(resolve);
			} else {
				this.getCustomerPaymentMethods().then(paymentMethods => {
					newData.paymentMethods = paymentMethods;
				});
				newData.payments = clone(data.payments);
				newData.references.payments = data.references.payments;
				newData.errorMessages.payments = data.errorMessages.payments;
				resolve();
			}
		});

		const schedulesPromise = new Promise(resolve => {
			if (schedules) {
				customerService
					.getCustomerRecurringSchedules(customerId)
					.then(recurringSchedules => {
						if (schedule) {
							onDataChange(cloneDeep(recurringSchedules.xReportData));
						}
						newData.schedules = Util.prepareRecurringArray(recurringSchedules.xReportData);
						newData.references.schedules = recurringSchedules.refNum;
						this.expandSelectedSchedule(newData.schedules);
					})
					.catch(e => {
						newData.errorMessages.schedules.push((e && e.message) || UNEXPECTED_ERROR_MSG);
						newData.references.schedules = e && e.ref;
					})
					.finally(resolve);
			} else {
				newData.schedules = clone(data.schedules);
				newData.references.schedules = data.references.schedules;
				newData.errorMessages.schedules = data.errorMessages.schedules;
				resolve();
			}
		});

		const transactionsPromise = new Promise(resolve => {
			const oldTransactions = { xReportData: cloneDeep(this.state.data.transactions) };
			if (transactions) {
				const {
					transactionHistory: { beginDate, endDate },
				} = this.state;
				if (loadMore && !isEmpty(this.state.data.transactions)) {
					this.handleLoadMore(oldTransactions, newData, beginDate, endDate, customerId, resolve);
				} else {
					customerService
						.getCustomerTransactions(customerId, beginDate, endDate)
						.then(customerTransactions => {
							newData.transactions = customerTransactions.xReportData;
							newData.references.transactions = customerTransactions.xRefNum;
						})

						.catch(e => {
							this.handleTransactionsLoadError(e, newData);
						})
						.finally(resolve);
				}
			} else {
				newData.transactions = clone(data.transactions);
				newData.references.transactions = data.references.transactions;
				newData.errorMessages.transactions = data.errorMessages.transactions;
				resolve();
			}
		});

		await Promise.all([paymentsPromise, schedulesPromise, transactionsPromise]);

		return newData;
	};

	combineData = (baseData, additionalData, refNums) => {
		baseData.xReportData = concat(baseData.xReportData, additionalData.xReportData);
		baseData.xRecordsReturned += additionalData.xRecordsReturned - refNums.length;
		baseData.xReportingMaxTransactions += additionalData.xReportingMaxTransactions - refNums.length;
		if (additionalData.nextToken) {
			baseData.nextToken = additionalData.nextToken;
		}
	};

	expandSelectedSchedule = schedules => {
		let found = false;
		const { schedule } = this.props;
		if (schedule && schedules && schedules.length > 0) {
			each(schedules, item => {
				if (item.scheduleId === schedule.scheduleId) {
					item._meta.scheduleExpanded = true;
					found = true;
					this.scheduleToFocus = item.scheduleId;
				} else {
					item._meta.scheduleExpanded = false;
				}
			});
			if (!found) schedules[0]._meta.scheduleExpanded = true;
		}
	};

	updateScheduleScroll = () => {
		if (this.customerSchedulesRefs && this.customerSchedulesRefs.current)
			this.customerSchedulesRefs.current.focusSchedule();
	};
	includeExcludeConvenience = (id, newValue) => {
		const { originalCustomKey, convenienceCustomKey } = this.state.convenienceFees;
		const schedules = cloneDeep(this.state.data.schedules);
		const item = Util.getArrayItemByMetaId(schedules, id);
		const amount = parseFloat(item.amount);
		const convenienceFee = this.onBeforeCalculateConvenience(amount, this.state.convenienceFees).toFixed(4);

		item._meta.modified = true;
		item.includeConvenience = newValue;

		if (!newValue) {
			item.amount = item[this.mapCustomKey(originalCustomKey)] || item.amount;
			item[this.mapCustomKey(convenienceCustomKey)] = item.convenienceFee;
			delete item[this.mapCustomKey(originalCustomKey)];
			delete item.totalAmount;
			delete item.convenienceFee;
		} else {
			item.totalAmount = amount + this.onBeforeCalculateConvenience(amount, this.state.convenienceFees);
			item.convenienceFee = convenienceFee;
		}

		this.onDataChange(
			{
				schedules,
			},
			this.validateInputs
		);
	};

	onBeforeCalculateConvenience = (originalAmount, convenienceFees) => {
		const {
			data: { payments },
		} = this.state;

		const defaultPaymentMethod = find(payments, { isDefaultPaymentMethod: true });

		if (defaultPaymentMethod && toLower(defaultPaymentMethod.tokenType) === 'cc') {
			return calculateConvenienceFee(originalAmount, convenienceFees, 'cc');
		} else {
			return calculateConvenienceFee(originalAmount, convenienceFees, 'check');
		}
	};
	setCustomerNumberToCustom = isSave => {
		const {
			data: { general },
		} = this.state;
		if (general.customerNumber && !general.customerCustom02) {
			general.customerCustom02 = general.customerNumber;
			if (isSave) this.setState({ savedGeneral: true });
		}
	};
	disableModalNavigation = disable => {
		if (this.isPopup) {
			this.props.setIsProcessing(disable);
		}
	};
	handleSaveErrors = async (
		existingTransaction,
		paymentErrors,
		scheduleErrors,
		paymentResponse,
		scheduleResponse,
		generalResponse
	) => {
		if (isEmpty(paymentErrors) && isEmpty(scheduleErrors)) {
			if (this.props.customerId) {
				const newData = await this.props.makePendingRequest(
					this.fetchCustomer({
						customerId: this.props.customerId,
						general: true,
						payments: !isEmpty(paymentResponse),
						schedules: !isEmpty(scheduleResponse),
					}),
					requestKeys.FETCH
				);

				if (!isEmpty(newData.schedules) && isEmpty(scheduleResponse)) {
					each(newData.schedules, (_, index) => {
						newData.schedules[index].revision++;
					});
				}

				this.setState(
					{
						data: newData,
						isLoading: false,
					},
					async () => {
						this.mapHiddenCustomFields();
						this.disableModalNavigation(false);
						this.props.resizeGrid();
					}
				);
			}
			if (
				existingTransaction &&
				get(this.state.permissions, 'allowCcAdjust', false) &&
				!get(existingTransaction, 'xCustom01', false)
			) {
				await this.handleSaveExistingTransaction(existingTransaction, generalResponse);
			}
			await this.handleSaveNotification(existingTransaction, generalResponse);
			return true;
		} else {
			const { general, payments, schedules } = await this.props.makePendingRequest(
				this.fetchCustomer({
					customerId: generalResponse.customerId,
					general: true,
					payments: !isEmpty(paymentResponse),
					schedules: !isEmpty(scheduleResponse),
				}),
				requestKeys.FETCH
			);
			this.mergeData(payments, paymentErrors, 'paymentMethodId');
			this.mergeData(schedules, scheduleErrors, 'scheduleId');
			this.onDataChange(
				{
					general,
					payments,
					schedules,
				},
				() => {
					this.displayErrors([], paymentErrors, scheduleErrors);
				}
			);
		}
	};
	handleSaveExistingTransaction = async (existingTransaction, generalResponse) => {
		const {
			xRefNum,
			xCommand,
			xAmount,
			xDescription = '',
			xOrderID = '',
			xCustom02 = '',
			xCustom03 = '',
			xMerchantId,
		} = existingTransaction;
		const tip = existingTransaction.xTip ? existingTransaction.xTip : '';
		const fullAmount = tip ? parseFloat(xAmount) + parseFloat(tip) : xAmount;
		let [paymentMethod, transactionType] = split(toLower(xCommand), ':');
		if (toLower(xCommand) === 'gift:redeem' || toLower(xCommand) === 'gift:issue') {
			paymentMethod = 'CC';
		}
		await this.props.makePendingRequest(
			transactionService.transactionAdjust(
				xRefNum,
				null,
				paymentMethod,
				transactionType,
				fullAmount,
				tip,
				xDescription,
				xOrderID,
				generalResponse.customerId,
				xCustom02,
				xCustom03,
				false,
				xMerchantId
			),
			requestKeys.SAVE
		);
	};
	handleSaveNotification = async (existingTransaction, generalResponse) => {
		await this.props.addNotification({
			message:
				this.state.expand === sections.SCHEDULES && this.state.data.general.customerId
					? 'Recurring schedule updated'
					: this.state.data.general.customerId
					? 'Customer updated'
					: 'Customer created',
			ref: generalResponse.refNum,
			customerId: !this.state.data.general.customerId ? generalResponse.customerId : null,
			showPaymentMethods: !!existingTransaction,
			success: true,
		});
	};
	handleSave = async () => {
		if (this.state.isSubmitted) return;
		this.setCustomerNumberToCustom(true);
		try {
			this.setState(
				{
					isSubmitted: true,
				},
				async () => {
					if (this.state.isLoading) return;
					this.showLoader(true);
					let token = null;
					const { gridHolder, makePendingRequest, existingTransaction } = this.props;
					const isValidInputs = await this.validateInputs(true, false, true);
					if (!isValidInputs) {
						this.setState({ isSubmitted: false, isLoading: false });
						return false;
					}

					this.setState({
						isSubmitted: false,
					});
					if (this.customerPaymentsRef.current && this.customerPaymentsRef.current.paymentsRef.current) {
						this.disableModalNavigation(true);
						this.setState({ fetchingToken: true });
						token = await this.props.makePendingRequest(
							this.customerPaymentsRef.current.paymentsRef.current.getIfieldTokens(),
							requestKeys.TOKEN
						);
					}
					this.setState({ isLoading: true, fetchingToken: false }, async () => {
						this.disableModalNavigation(true);

						try {
							if (gridHolder && this.pageTopRef.current) {
								gridHolder.scrollTop = this.pageTopRef.current.offsetTop;
							}
							const generalResponse = await makePendingRequest(this.saveCustomerGeneral(), requestKeys.SAVE);
							if (!generalResponse.success) {
								this.onDataChange(
									{
										errorMessages: {
											...this.state.data.errorMessages,
											general: [this.generateErrorMessage(generalResponse)],
										},
									},
									() => {
										this.setState({ isLoading: false }, () => {
											this.disableModalNavigation(false);
											this.switchExpanded(sections.GENERAL);
										});
									},
									true
								);
								this.showLoader(false);
								return false;
							}

							const modifiedSchedules = Util.getModifiedItems(this.state.data.schedules);
							const modifiedPayments = Util.getModifiedItems(this.state.data.payments);
							const deletedPayments = Util.getDeletedItems(this.state.data.payments);

							const paymentSaveResponse = await makePendingRequest(
								Promise.all(
									map(modifiedPayments, payment =>
										this.savePayment(
											payment._meta.id,
											this.state.data.general.customerId || generalResponse.customerId,
											!payment.token ? token[payment.tokenType] : null
										)
									)
								),
								requestKeys.SAVE
							);
							const paymentDeleteResponse = await makePendingRequest(
								Promise.all(map(deletedPayments, payment => this.deletePayment(payment._meta.id))),
								requestKeys.SAVE
							);
							const paymentResponse = [...paymentSaveResponse, ...paymentDeleteResponse];
							const paymentErrors = this.getErroredItems(paymentResponse);
							const scheduleResponse = [];
							for (const schedule of modifiedSchedules) {
								scheduleResponse.push(
									await makePendingRequest(
										await this.saveSchedule(schedule._meta.id, generalResponse.customerId),
										requestKeys.save
									)
								);
							}
							const scheduleErrors = this.getErroredItems(scheduleResponse);

							await this.handleSaveErrors(
								existingTransaction,
								paymentErrors,
								scheduleErrors,
								paymentResponse,
								scheduleResponse,
								generalResponse
							);
							this.showLoader(false);
							return false;
						} catch (e) {
							this.enableDisableSidebar(true);
							this.disableModalNavigation(false);
							this.showLoader(false);
							this.props.handleError(e);
						} finally {
							this.disableModalNavigation(false);
							this.enableDisableSidebar(false);
							this.showLoader(false);
						}
					});
				}
			);
		} catch (e) {
			this.enableDisableSidebar(true);

			if (!e || !e.isCanceled) {
				this.setState({
					isLoading: false,
				});
			}
		}
	};
	onPaymentAccountTypeChange = (id, value) => {
		const payments = cloneDeep(this.state.data.payments);
		const item = Util.getArrayItemByMetaId(payments, id);
		item._meta.modified = true;
		item.accountType = value;
		this.onDataChange(
			{
				payments,
			},
			this.validateInputs
		);
	};

	onDataChange = (newData, callback) => {
		this.setState(
			{
				data: {
					...this.state.data,
					...newData,
				},
			},
			callback
		);
	};

	onCustomBlur = ({ target: { name } }) => {
		if (name !== 'customerCustom02') return;
		this.setState({ blurredField: name });
	};
	onGeneralChange = ({ key, value }) => {
		if (key === 'customerCustom02') {
			if (value) {
				this.setState({ blurredField: false, savedGeneral: false });
			} else {
				this.setState({ blurredField: false });
			}
		}
		const general = clone(this.state.data.general);
		if (key.indexOf('.') !== -1) {
			key = key.split('.');
			general[key[0]][key[1]] = value;
		} else {
			general[key] = value;
		}
		general._meta.modified = true;

		this.onDataChange(
			{
				general,
			},
			async () => {
				await this.validateInputs(false, false, true);
				if (key[0] === '_meta') {
					this.props.resizeGrid();
				}
			}
		);
	};

	removeProperties = (data, ...props) => {
		if (!data) {
			return;
		}
		each(props, prop => {
			if (data.hasOwnProperty(prop)) {
				delete data[prop];
			}
		});
		each(data, (value, key) => {
			if (startsWith(key, '_') || isFunction(value)) {
				delete data[key];
			}
		});
	};

	saveCustomerGeneral = async () => {
		const { general } = this.state.data;
		const data = cloneDeep(general);
		let method = customerService.updateCustomer;
		const properties = [
			'basicScheduleData',
			'billName',
			'billPhoneNumber',
			'removed',
			'errorCode',
			'result',
			'status',
			'refNum',
			'createdDate',
			'_meta',
			'gridRowNumber',
			'index',
			'isDetails',
			'expandedRowProps',
			'recurringSchedule',
			'isExpandable',
			'gridRef',
			'history',
			'deleteAction',
			'paymentMethod',
			'paymentMethodExpiryMoment',
			'paymentMethodDetails',
			'paymentMethodExpiry',
			'isPaymentMethodExpired',
			'maskedCardCardNumber',
		];

		if (!data.customerId) {
			method = customerService.addCustomer;
			properties.push('customerId', 'defaultPaymentMethodId');
		}

		let shippingProperties = [];
		if (data._meta.sameAsBilling) {
			shippingProperties = filter(keys(data), key => startsWith(key, 'ship'));
		}
		this.removeProperties(data, ...properties, ...shippingProperties);

		each(data, (value, key) => {
			if (startsWith(key, 'billAddress') || startsWith('shipAddress')) {
				key = replace(key, 'Address', 'Street');
			}
			data[replace(startCase(key), /\s/g, '')] = value;
			delete data[key];
		});

		let rsp;
		try {
			delete data['billAddress2'];
			delete data['billAddress'];
			rsp = await method(data);
			rsp.success = true;
			rsp.customerId = rsp.xReportData && rsp.xReportData[0] && rsp.xReportData[0].customerId;
		} catch (e) {
			rsp = { success: false, message: e && e.message, ref: e && e.ref };
		}
		return rsp;
	};

	getErroredItems = collection => {
		return filter(collection, item => !item.success);
	};

	mergeData = (collection, errors, identifier) => {
		each(errors, error => {
			const existing = find(collection, item => item[identifier] === error.item[identifier]);
			if (existing) {
				existing._meta = error.item._meta;
			} else {
				collection.unshift(error.item);
			}
		});
	};

	isDuplicateCustomer = async newCustomerNumber => {
		const customers = await this.fetchCustomersByCustomerNumber(newCustomerNumber);
		const currentCustomerId = get(this.state, 'data.general.customerId');
		const editingCustomer = this.props.customer;
		let validateCustomerNumber = true;
		let isSameCustomer = editingCustomer && editingCustomer.customerNumber === newCustomerNumber;

		if (isSameCustomer) {
			validateCustomerNumber = false;
		}
		if (validateCustomerNumber) {
			if (this.props.isDuplicate) {
				return this.props.isDuplicate(newCustomerNumber, currentCustomerId);
			} else {
				if (!isEmpty(customers)) {
					const duplicateCustomer = find(
						filter(customers, ({ customerId }) => customerId !== currentCustomerId),
						({ customerNumber }) => customerNumber && customerNumber === newCustomerNumber
					);
					return !!duplicateCustomer;
				}
				return false;
			}
		}
		return false;
	};

	validateSendReceipt = (validateSendReceipt, errors) => {
		const {
			data: { general, schedules },
		} = this.state;

		if (
			!general.email &&
			some(
				schedules,
				({ isActive, custReceipt }) =>
					(validateSendReceipt !== undefined ? validateSendReceipt : isActive) && custReceipt
			)
		) {
			const sendReceiptSchedules = compact(
				map(schedules, ({ custReceipt, scheduleName, scheduleId }) =>
					custReceipt ? scheduleName || scheduleId || 'New Schedule' : null
				)
			);
			errors.push(
				<div>
					{`To send receipts an email is required. Send Receipt is checked on schedule${
						sendReceiptSchedules.length > 1 ? 's' : ''
					}: `}
					{join(map(sendReceiptSchedules, item => item), ', ')}.
				</div>
			);
		}
	};

	validateGeneralInputs = async validateSendReceipt => {
		const { advancedView } = this.props;
		const { customerRequiredFields, customDisplayLabels } = this.state;
		const {
			data: {
				general,
				general: {
					_meta: { originalData },
				},
			},
		} = this.state;
		const errors = [];
		mapKeys(customerRequiredFields, (_value, key) => {
			if (key === 'customerNumber' && !advancedView) return;
			const billKey = `bill${upperFirst(key)}`;
			if (!generalErrorMessages[billKey] && !startsWith(key, 'custom')) return; // prevent validation of non general fields
			if (startsWith(key, 'custom') && key !== 'customerNumber') {
				if (!advancedView) return;
				const customNumber = replace(key, /^\D+/g, '');
				if (customNumber.length < 2) {
					key = replace(key, nth(key, -1), `0${nth(key, -1)}`);
				}
				key = replace(key, 'custom', 'customerCustom');
			}
			const hasKey = general[key] || general[billKey];
			if (!hasKey && key !== 'amount') {
				const customLabel = customDisplayLabels[key] || customDisplayLabels[billKey];
				if (customLabel || startsWith(key, 'custom')) {
					errors.push(`${customLabel || 'Custom field '} is required`);
				} else {
					errors.push(generalErrorMessages[billKey]);
				}
			}
		});

		const isDuplicate = await this.isDuplicateCustomer(general.customerNumber);
		if (isDuplicate) {
			errors.push('A customer with this number already exists. Please use a unique Customer #.');
		}
		if (isEmpty(general.billCompany) && isEmpty(general.billLastName) && isEmpty(general.billFirstName)) {
			errors.push(
				`Please enter ${customDisplayLabels['company'] || 'company'}, ${customDisplayLabels['lastName'] ||
					'last name'} or ${customDisplayLabels['firstName'] || 'first name'}`
			);
		}
		if (general.email && general.email !== originalData.email && !validators.email(general.email)) {
			errors.push('Email is not valid');
		}

		if (
			general.billPhone &&
			general.billPhone !== originalData.billPhone &&
			!validators.phoneNumber(general.billPhone)
		) {
			errors.push('Billing Phone Number is not valid');
		}
		if (
			general.shipPhone &&
			general.shipPhone !== originalData.shipPhone &&
			!validators.phoneNumber(general.shipPhone)
		) {
			errors.push('Shipping Phone Number is not valid');
		}

		this.validateSendReceipt(validateSendReceipt, errors);

		return errors;
	};

	validatePaymentInputs = () => {
		const {
			data: { payments },
			requiredFields,
		} = this.state;
		const errors = [];

		const modifiedPayments = Util.getModifiedItems(payments);
		each(modifiedPayments, payment => {
			if (toLower(payment.tokenType) === 'cc') {
				if (payment.token !== '') {
					if (!validators.expDate(payment.exp)) {
						errors.push({
							id: payment._meta.id,
							message: !payment.exp ? 'Please enter Exp Date' : 'Exp Date is not valid',
						});
					}
				} else {
					if (payment._meta.cc.cardNumberIsEmpty) {
						errors.push({
							id: payment._meta.id,
							message: 'Please enter Card number',
						});
					} else if (!payment._meta.cc.cardNumberIsValid) {
						errors.push({
							id: payment._meta.id,
							message: 'Please enter a valid Card number',
						});
					}

					if (!validators.expDate(payment.exp)) {
						errors.push({
							id: payment._meta.id,
							message: !payment.exp ? 'Please enter Exp Date' : 'Exp Date is not valid',
						});
					}
				}
			} else {
				if (payment.token === '') {
					if (!payment.accountType) {
						errors.push({
							id: payment._meta.id,
							message: 'Please select an Account Type',
						});
					}
					if (payment._meta.routingNumber.length === 0) {
						errors.push({
							id: payment._meta.id,
							message: 'Please enter Routing number',
						});
					}

					if (payment._meta.check.achIsEmpty) {
						errors.push({
							id: payment._meta.id,
							message: 'Please enter Account number',
						});
					} else if (!payment._meta.check.achIsValid) {
						errors.push({
							id: payment._meta.id,
							message: 'Please enter a valid Account number',
						});
					}
				}
				if (isEmpty(payment.name)) {
					errors.push({
						id: payment._meta.id,
						message: 'Please enter Account name',
					});
				}
			}

			if (requiredFields.address && isEmpty(payment.street)) {
				errors.push({
					id: payment._meta.id,
					message: 'Please enter Address',
				});
			}

			if (requiredFields.zip && isEmpty(payment.zip)) {
				errors.push({
					id: payment._meta.id,
					message: `Please enter ${this.zipLabel}`,
				});
			}
		});

		return errors;
	};

	validateScheduleInputs = () => {
		const {
			customerRequiredFields,
			customDisplayLabels,
			data: { schedules },
		} = this.state;
		const errors = [];
		const modifiedSchedules = Util.getModifiedItems(schedules);

		mapKeys(customerRequiredFields, (_value, key) => {
			if (!this.props.advancedView && (key === 'scheduleName' || key === 'description')) return;
			each(schedules, schedule => {
				if (schedule._meta.isNewSchedule && isEmpty(modifiedSchedules)) return;
				if (schedule.isActive && !startsWith(key, 'custom')) {
					let viableKey = key;
					if (includes(key, 'recurringCustom')) {
						const customNumber = replace(key, /^\D+/g, '');
						if (customNumber.length < 2) {
							key = replace(key, nth(key, -1), `0${nth(key, -1)}`);
						}
						viableKey = `${replace(toLower(key), 'recurring', '')}`;
					} else if (!scheduleErrorMessages[viableKey]) return;
					if (!schedule[viableKey]) {
						const customLabel = customDisplayLabels[key];
						if (customLabel || startsWith(viableKey, 'custom')) {
							errors.push({
								id: schedule._meta.id,
								message: `${customLabel || 'Recurring Custom field'} is required`,
							});
						} else {
							let customFieldMessage = `${upperFirst(key)} is required`;
							let customMessage =
								viableKey !== 'customerId' && includes(toLower(viableKey), 'custom')
									? customFieldMessage
									: scheduleErrorMessages[key];
							errors.push({
								id: schedule._meta.id,
								message: customMessage,
							});
						}
					}
				}
			});
		});
		each(modifiedSchedules, schedule => {
			if (!schedule.intervalCount) {
				errors.push({
					id: schedule._meta.id,
					message: 'Please enter Frequency',
				});
			} else if (schedule.intervalCount < 1) {
				errors.push({
					id: schedule._meta.id,
					message: 'Please enter a valid Frequency',
				});
			}

			if (schedule.amount < 0.01) {
				errors.push({
					id: schedule._meta.id,
					message: 'Please enter a valid Amount',
				});
			}

			if (schedule._meta.saveAsTemplate && !schedule.scheduleName) {
				errors.push({
					id: schedule._meta.id,
					message: 'Please enter a Schedule Name in order to save it as a template',
				});
			}

			if (isEmpty(schedule.scheduleId)) {
				if (isEmpty(schedule.startDate)) {
					errors.push({
						id: schedule._meta.id,
						message: 'Please enter Start',
					});
				}
				if (!isAfter(schedule.startDate, yesterday[schedule.calendarCulture]) && !isEmpty(schedule.startDate)) {
					errors.push({
						id: schedule._meta.id,
						message: 'Please enter a valid Start',
					});
				}
			}

			if (schedule._meta.until === until.PAYMENTS && !(isEmpty(schedule.totalPayments) || schedule.totalPayments > 0)) {
				errors.push({
					id: schedule._meta.id,
					message: 'Please enter a valid Number of payments',
				});
			}

			if (schedule._meta.until === until.ENDDATE) {
				if (isEmpty(schedule.endDate)) {
					errors.push({
						id: schedule._meta.id,
						message: 'Please enter Date',
					});
				} else if (!isAfter(schedule.endDate, schedule.startDate)) {
					errors.push({
						id: schedule._meta.id,
						message: 'End Date must be after Start Date',
					});
				}
			}
		});

		return errors;
	};

	validateInputs = async (switchToErroredTab, validateSendReceipt, validateGeneral) => {
		const {
			isSubmitted,
			data: { general, payments, schedules },
		} = this.state;
		const { type } = this.props;
		if (!isSubmitted) {
			return;
		}
		let generalErrors = get(this.state, 'data.errorMessages.general', []);
		if (validateGeneral) {
			generalErrors = await this.validateGeneralInputs(validateSendReceipt);
		}
		const paymentErrors = this.validatePaymentInputs();
		const scheduleErrors = validateSendReceipt ? [] : this.validateScheduleInputs();

		let isValid = true;
		const newData = {
			general,
			payments,
			schedules,
			errorMessages: {
				general: generalErrors,
				payments: paymentErrors,
				schedules: scheduleErrors,
				transactions: this.state.data.errorMessages.transactions,
			},
		};

		if (type === 'schedules' && !general.customerId && !some(schedules, schedule => schedule._meta.modified)) {
			scheduleErrors.push({ message: 'Please enter a recurring schedule' });
		}

		const hasSchedules = some(schedules, schedule => !schedule._meta.created || schedule._meta.modified);
		const hasRetrievedPaymentMethods = some(payments, payment => !payment._meta.created || payment._meta.modified);
		// we use this check in case user is editing a customer and never loaded the payment methods but added a recurring schedule
		const hasDefaultPaymentMethod = !!general.defaultPaymentMethodId;

		if (hasSchedules && !(hasRetrievedPaymentMethods || hasDefaultPaymentMethod)) {
			paymentErrors.push({ message: 'Please enter a payment method' });
		}

		if (hasRetrievedPaymentMethods && !some(payments, payment => payment.isDefaultPaymentMethod)) {
			paymentErrors.push({ message: 'Please set a default payment method' });
		}

		if (generalErrors.length > 0 || paymentErrors.length > 0 || scheduleErrors.length > 0) {
			isValid = false;
		}

		each(payments, payment => {
			const errors = filter(paymentErrors, error => error.id === payment._meta.id);
			payment._meta.errorMessages = map(errors, error => error.message);
		});

		newData.errorMessages.payments = map(filter(paymentErrors, ({ id }) => !id), ({ message }) => message);

		each(schedules, schedule => {
			const errors = filter(scheduleErrors, error => error.id === schedule._meta.id);
			schedule._meta.errorMessages = map(errors, error => error.message);
		});

		newData.errorMessages.schedules = map(filter(scheduleErrors, ({ id }) => !id), ({ message }) => message);

		this.onDataChange(newData, () => {
			if (!isValid && switchToErroredTab) {
				this.displayErrors(generalErrors, paymentErrors, scheduleErrors);
			}
		});
		return isValid;
	};

	errorsByTokenType = type => {
		const {
			data: { payments },
		} = this.state;
		return some(payments, payment => toLower(payment.tokenType) === type && !isEmpty(payment._meta.errorMessages));
	};

	displayErrors = (generalErrors, paymentErrors, scheduleErrors) => {
		this.setState({ isLoading: false }, async () => {
			this.props.resizeGrid();
			if (!isEmpty(generalErrors)) {
				this.switchExpanded(sections.GENERAL);
			} else if (!isEmpty(paymentErrors)) {
				const { selectedTab } = this.state;
				const newSelectedTab = selectedTab === 'cc' ? 'check' : 'cc';
				if (this.errorsByTokenType(newSelectedTab) && !this.errorsByTokenType(selectedTab)) {
					await this.switchTab(newSelectedTab);
					this.switchExpanded(sections.PAYMENTS);
				} else {
					this.switchExpanded(sections.PAYMENTS);
				}
			} else if (!isEmpty(scheduleErrors)) {
				this.switchExpanded(sections.SCHEDULES);
			}
		});
	};

	switchTab = (
		selectedTab,
		existsNewModifiedPayment = !!find(
			this.state.data.payments,
			({ _meta: { created, modified } }) => created && modified
		)
	) => {
		const {
			permissions: { allowCcSave, allowCheckSave },
			data,
			data: {
				general: { customerId },
				payments: [payment, ...rest],
			},
		} = this.state;
		const existsNewUnmodifiedPayment = !!find(
			data.payments,
			({ _meta: { created, modified } }) => created && !modified
		);
		const hasPermission = selectedTab === 'cc' ? allowCcSave : allowCheckSave;
		if (customerId === null && payment && isEmpty(rest) && !existsNewModifiedPayment) {
			if (hasPermission) {
				this.setState({
					data: {
						...data,
						payments: Util.setNewDefaultPayment(selectedTab),
					},
				});
			}
		} else if (customerId && existsNewUnmodifiedPayment && hasPermission) {
			const newPayments = cloneDeep(data.payments);
			const shouldSetNewAsDefault = !some(newPayments, (payment, idx) => idx > 0 && payment.isDefaultPaymentMethod);
			newPayments[0] = Util.newPayment(selectedTab, shouldSetNewAsDefault);
			this.setState({
				data: {
					...data,
					payments: newPayments,
				},
			});
		}

		if (existsNewModifiedPayment) {
			this.setState({ isWarningModalOpen: existsNewModifiedPayment, tabToSwitch: selectedTab });
		} else {
			return new Promise(resolve => {
				this.setState(
					{
						selectedTab,
					},
					() => {
						this.props.resizeGrid();
						resolve();
					}
				);
			});
		}
	};

	generateErrorMessage = e => `${(e && e.message) || UNEXPECTED_ERROR_MSG}${e && e.ref ? ` (Ref# ${e.ref})` : ''}`;

	handlePaymentDelete = id => {
		const payments = cloneDeep(this.state.data.payments);
		const item = Util.getArrayItemByMetaId(payments, id);

		if (item.paymentMethodId && item.paymentMethodId.length > 0) {
			this.onPaymentsChange(id, '_meta.deleted', !item._meta.deleted);
		} else {
			this.removePayment(id);
		}
	};

	removePayment = id => {
		this.onDataChange(
			{
				payments: Util.removeItemFromArrayByMetaId(this.state.data.payments, id),
			},
			() => {
				this.props.resizeGrid();
				this.validateInputs();
			}
		);
		this.enableDisableSidebar(false);
	};

	deletePayment = async id => {
		const payments = cloneDeep(this.state.data.payments);
		const item = Util.getArrayItemByMetaId(payments, id);

		let rsp;
		try {
			rsp = await customerService.deleteCustomerPaymentMethod(item.paymentMethodId);
		} catch (e) {
			rsp = { success: false, message: e && e.message, ref: e && e.ref };
		}
		rsp.item = item;
		if (!rsp.success) {
			item._meta.errorMessages = [this.generateErrorMessage(rsp)];
		}
		return rsp;
	};

	fetchCustomersByCustomerNumber = async customerNumber => {
		try {
			const data = await this.props.makePendingRequest(
				customerService.filterCustomersAll(null, {
					SortOrder: 'Descending',
					Filters: { CustomerNumber: customerNumber },
				}),

				requestKeys.FETCH
			);
			return (data && data.xReportData && data.xReportData) || [];
		} catch (e) {
			this.props.handleError(e);
		}
	};

	addNewPayment = () => {
		this.enableDisableSidebar(true);
		const {
			data: {
				payments,
				general: { billStreet: customerSavedAddress, billStreet2: customerSavedAddress2, billZip: customerSavedZip },
			},
			selectedTab,
			setNewCardAsDefault,
		} = this.state;
		const newPayments = cloneDeep(payments);
		const shouldSetNewAsDefault = setNewCardAsDefault || !some(newPayments, payment => payment.isDefaultPaymentMethod);
		const newItem = Util.newPayment(selectedTab, shouldSetNewAsDefault);
		if (setNewCardAsDefault) {
			const defaultPaymentMethod = find(newPayments, { isDefaultPaymentMethod: true });
			if (defaultPaymentMethod) {
				defaultPaymentMethod.isDefaultPaymentMethod = false;
			}
		}
		newItem.street = customerSavedAddress || customerSavedAddress2 || '';
		if (customerSavedZip) {
			newItem.zip = customerSavedZip;
		}
		newPayments.unshift(newItem);

		this.onDataChange(
			{
				payments: newPayments,
			},
			this.props.resizeGrid
		);
	};

	onPaymentsChange = (id, field, value, setOtherPaymentsToDefault) => {
		return new Promise(resolve => {
			this.setState(
				currentState => {
					const payments = cloneDeep(currentState.data.payments);
					const item = Util.getArrayItemByMetaId(payments, id);

					if (id === 'expandAllPayments') {
						field = split(field, '.');
						each(payments, (_, index) => {
							payments[index][field[0]][field[1]] = value;
						});
						return {
							data: {
								...currentState.data,
								payments,
							},
						};
					}

					if (field.indexOf('.') !== -1) {
						field = field.split('.');
						item[field[0]][field[1]] = value;
					} else {
						item[field] = value;
					}
					item._meta.modified =
						setOtherPaymentsToDefault !== undefined ? !setOtherPaymentsToDefault : this.checkIfPaymentModified(item);
					return {
						data: {
							...currentState.data,
							payments,
						},
					};
				},
				() => {
					this.validateInputs();
					resolve();
				}
			);
		});
	};

	checkIfPaymentModified = item => {
		const originalData = item._meta.originalData;
		const isEmptyKey = find(keys(item._meta[item.tokenType]), key => endsWith(toLower(key), 'isempty'));
		const isIfieldEmpty = get(item, `_meta.${item.tokenType}.${isEmptyKey}`);

		return (
			!every(originalData, (value, key) => value === item[key]) ||
			!isIfieldEmpty ||
			(item.tokenType === 'check' && item._meta.routingNumber)
		);
	};

	addCreditCard = item => customerService.addCustomerCcPaymentMethod(item._meta.cardNumber, item.exp, item);

	addCheck = item =>
		customerService.addCustomerCheckPaymentMethod(item._meta.accountNumber, item._meta.routingNumber, item);

	savePayment = async (id, customerId, token) => {
		const payments = cloneDeep(this.state.data.payments);
		const item = Util.getArrayItemByMetaId(payments, id);
		item.customerId = customerId;

		let method = item.paymentMethodId
			? customerService.updateCustomerPaymentMethod
			: toLower(item.tokenType) === 'cc'
			? this.addCreditCard
			: this.addCheck;

		if (token) {
			item.token = token;
			method = customerService.addCustomerPaymentMethod;
		}

		let rsp;
		try {
			rsp = await method(item);
		} catch (e) {
			this.enableDisableSidebar(true);
			if (token) {
				delete item.token;
			}
			rsp = { success: false, message: e && e.message, ref: e && e.ref };
		}
		rsp.item = item;
		if (!rsp.success) {
			item._meta.errorMessages = [this.generateErrorMessage(rsp)];
		} else {
			try {
				const paymentMethods = await this.getCustomerPaymentMethods();
				this.setState({
					paymentMethods,
				});
			} catch (e) {
				this.props.handleError(e);
			}
		}
		return rsp;
	};

	removeSchedule = id => {
		this.onDataChange(
			{
				schedules: Util.removeItemFromArrayByMetaId(this.state.data.schedules, id),
			},
			() => {
				this.props.resizeGrid();
				this.validateInputs();
			}
		);
	};

	onScheduleChange = (id, field, value, amountWithConvenienceFee, convenienceFee) => {
		const schedules = cloneDeep(this.state.data.schedules);

		if (id === 'expandAllSchedules') {
			field = split(field, '.');
			each(schedules, (_, index) => {
				schedules[index][field[0]][field[1]] = value;
			});
			return this.onDataChange(
				{
					schedules,
				},
				this.validateInputs
			);
		}

		const item = Util.getArrayItemByMetaId(schedules, id);
		const includeConvenience = item.includeConvenience === undefined ? true : item.includeConvenience;

		if (amountWithConvenienceFee && includeConvenience) {
			item.totalAmount = amountWithConvenienceFee;
			item.convenienceFee = convenienceFee;
		} else if (field === 'amount' && item.totalAmount && isNumber(value)) {
			item.totalAmount = value;
		}

		if (field.indexOf('.') !== -1) {
			field = field.split('.');
			item[field[0]][field[1]] = value;
		} else if (endsWith(toLower(field), 'date') && isObject(value)) {
			item[field] = value.value;
			item[`${field}RawDate`] = value.date;
		} else {
			item[field] = value;
		}
		if (field[1] !== 'saveAsTemplate' && field[1] !== 'scheduleExpanded') {
			item._meta.modified = true;
		}
		if (field === 'calendarCulture') {
			item.startDate = today[value];
			item.endDate = '';
		}

		if (field === 'intervalType' && toLower(value) !== 'month') {
			item._meta.specificDayOfWeek = false;
		}

		this.onDataChange(
			{
				schedules,
			},
			this.validateInputs
		);
	};
	mapTemplateValuesToSchedule = (schedule, template) => {
		each(template, (value, key) => {
			if (key === 'amount') {
				schedule[key] = value;
			}
			if (key === 'xSkipSab') {
				schedule['skipSaturdayAndHolidays'] = value;
			} else {
				schedule[camelCase(key.slice(1))] = value;
			}
		});
	};
	applyTemplate = (id, template, convenienceFees) => {
		const schedules = cloneDeep(this.state.data.schedules);
		const schedule = Util.getArrayItemByMetaId(schedules, id);

		if (!schedule.scheduleId) {
			this.mapTemplateValuesToSchedule(schedule, template, convenienceFees);
			schedule._meta.modified = true;
			schedule._meta.until = until.PAYMENTS;

			if (schedule._meta.specificDayOfWeek && schedule.intervalType !== 'month') {
				schedule._meta.specificDayOfWeek = false;
			}

			this.onDataChange({ schedules }, this.validateInputs);
			const cFees = this.state.convenienceFees || convenienceFees;
			if (cFees) {
				const {
					enableConvenienceFee,
					applyToRecurringSchedule,
					ccPercentageAmount,
					ccFlatAmount,
					achPercentageAmount,
					achFlatAmount,
				} = cFees;

				const convenienceFeeEnabled =
					enableConvenienceFee &&
					applyToRecurringSchedule &&
					schedule.includeConvenience &&
					(ccPercentageAmount || ccFlatAmount || achPercentageAmount || achFlatAmount);

				if (convenienceFeeEnabled) {
					const amountWithConvenienceFee =
						parseFloat(schedule.amount) + this.onBeforeCalculateConvenience(parseFloat(schedule.amount), cFees);
					const convenienceFee = this.onBeforeCalculateConvenience(parseFloat(schedule.amount), cFees).toFixed(4);

					this.onScheduleChange(id, 'amount', schedule.amount, amountWithConvenienceFee, convenienceFee);
				}
			}
		}
	};

	mapStateToRequest = (data, oldData) => {
		return {
			newData: {
				revision: 0,
				data,
			},
			oldData,
			...kvaasResources.recurringTemplates,
		};
	};
	mapCustomKey = key => {
		return toLower(key);
	};

	saveTemplate = async item => {
		try {
			const [recurringTemplates] = await this.props.makePendingRequest(
				kvaasService.get(kvaasResources.recurringTemplates),
				requestKeys.KVAAS
			);
			const data = clone(get(recurringTemplates, 'data', {}));

			if (item.scheduleName) {
				const template = {};
				each(item, (value, key) => {
					if (
						some(
							[
								'scheduleName',
								'intervalCount',
								'intervalType',
								'totalPayments',
								'description',
								'skipSaturdayAndHolidays',
							],
							item => item === key
						)
					) {
						template[`x${replace(startCase(key), /\s/g, '')}`] = value;
					}
					if (key == 'amount') {
						template[key] = value;
					}
				});
				each(template, (value, key) => {
					if (isString(value)) {
						template[key] = trim(value);
					}
				});
				data[Date.now()] = JSON.stringify(template);
			}

			const mappedState = this.mapStateToRequest(data, recurringTemplates);
			const response = await this.props.makePendingRequest(kvaasService.save(mappedState), requestKeys.KVAAS);
			return response[0];
		} catch (e) {
			this.props.handleError(e);
		}
	};

	saveSchedule = async (id, customerId) => {
		const { originalCustomKey, convenienceCustomKey } = this.state.convenienceFees;

		const { data } = this.state;
		const schedules = cloneDeep(data.schedules);
		const item = Util.getArrayItemByMetaId(schedules, id);
		const originalAmount = round(item.amount, 2);
		item.customerId = customerId;
		if (originalCustomKey && item.totalAmount) {
			item.amount = round(item.totalAmount, 2);
			item[this.mapCustomKey(originalCustomKey)] = toString(originalAmount);
		}
		if (convenienceCustomKey && item.totalAmount) {
			item[this.mapCustomKey(convenienceCustomKey)] = toString(item.convenienceFee);
		}
		if (item.startDate !== today[item.calendarCulture]) {
			item.allowInitialTransactionToDecline = false;
		}

		const method = item.scheduleId
			? customerService.updateCustomerRecurringSchedule
			: customerService.addCustomerRecurringSchedule;
		let rsp;
		try {
			rsp = await method(item);
		} catch (e) {
			rsp = { success: false, message: e && e.message, ref: e && e.ref };
		}
		rsp.item = item;
		if (!rsp.success) {
			item._meta.errorMessages = [this.generateErrorMessage(rsp)];
		}
		return rsp;
	};

	showLoader = load => {
		this.setState({ isLoading: load });
	};

	setStateAsync = async newState => {
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	runSchedulePopoverAction = async (scheduleId, field) => {
		const {
			data: {
				general: { customerId },
			},
		} = this.state;
		const { addNotification, showHideCustomerModal, makePendingRequest } = this.props;
		this.showLoader(true);
		let schedules = cloneDeep(this.state.data.schedules);
		const index = findKey(schedules, item => item._meta.id === scheduleId);

		if (field.indexOf('.') !== -1) {
			const [meta, fieldName] = split(field, '.');
			schedules[index][meta][fieldName] = !schedules[index][meta][fieldName];
			schedules[index][meta].originalData[fieldName] = !schedules[index][meta].originalData[fieldName];
		} else {
			schedules[index][field] = !schedules[index][field];
			schedules[index]._meta.originalData[field] = !schedules[index]._meta.originalData[field];
		}

		const item = this.mapOriginalDataAsSchedule(schedules[index]);
		let message = 'Recurring schedule updated';
		let refNum = null;
		let hasErrors = false;

		try {
			if (field === '_meta.deleted') {
				if (item.scheduleId) {
					const response = await customerService.deleteCustomerRecurringSchedule(item.scheduleId);
					refNum = response.ref;
					message = 'Recurring schedule removed';
				}
				schedules = filter(schedules, ({ scheduleId }) => scheduleId !== item.scheduleId);
				this.removeSchedule(scheduleId);
			} else if (field === 'isActive') {
				await this.setStateAsync({ isSubmitted: true });
				hasErrors = !this.validateInputs(true, field === 'isActive' && item.isActive);
				if (!hasErrors) {
					if (this.state.data.general._meta.modified) {
						await makePendingRequest(this.saveCustomerGeneral(), requestKeys.SAVE);
					}
					const response = await customerService.activateCustomerRecurringSchedule(item.scheduleId, item.isActive);
					refNum = response.ref;
					message = `Recurring schedule ${item.isActive ? 'activated' : 'deactivated'}`;
					this.props.onDataChange(schedules);
				}
			} else if (field === '_meta.saveAsTemplate') {
				const response = await this.saveTemplate(item);
				if (response.error) {
					throw {
						isApiError: true,
						ref: response.refNum,
						message: response.error,
						success: false,
					};
				}
				refNum = response.refNum;
				message = 'Recurring schedule saved as template';
				if (!customerId && showHideCustomerModal) {
					showHideCustomerModal(true);
				}
				await addNotification(
					{
						message,
						ref: refNum,
						success: true,
						onClose: showHideCustomerModal,
					},
					false
				);
			}

			if (customerId && item.scheduleId) {
				if ((field === 'isActive' && !hasErrors) || field !== 'isActive') {
					this.onDataChange({ schedules });
					await this.reset({ general: true, schedules: field === 'isActive' || field === '_meta.deleted' });
					await addNotification({
						message,
						ref: refNum,
						success: true,
					});
				}
			}
		} catch (e) {
			this.props.handleError(e);
		}
		this.showLoader(false);
	};

	mapOriginalDataAsSchedule = schedule => {
		each(schedule._meta.originalData, (value, key) => {
			if (!schedule.scheduleId && key === 'scheduleName') {
				return;
			}
			schedule[key] = value;
		});
		return schedule;
	};

	addNewSchedule = () => {
		const {
			data: { schedules, general },
			sendReceipt,
			allowInitialTransactionToDecline,
			afterMaxRetriesAction,
			failedTransactionRetryTimes,
			daysBetweenRetries,
		} = this.state;
		const scheduleProcessingDefaultValues = {
			allowInitialTransactionToDecline,
			afterMaxRetriesAction,
			failedTransactionRetryTimes,
			daysBetweenRetries,
		};

		const newItem = Util.newSchedule(sendReceipt, scheduleProcessingDefaultValues);
		newItem.paymentMethodId = general.defaultPaymentMethodId;
		this.mapExistingTransactionCustomFieldsToNewSchedule(newItem);
		schedules.unshift(newItem);

		this.onDataChange(
			{
				schedules,
			},
			this.props.resizeGrid
		);
	};

	openCloseActionsModal = modalObj => {
		this.setState({
			modal: modalObj,
		});
	};

	switchExpanded = (elem, loadMore = false) => {
		const {
			data: { references },
			isLoading,
		} = this.state;
		if (isLoading) return;
		const { customerId, customer } = this.props;

		const retrieveData = (!!customerId && !references[elem] && (elem !== sections.GENERAL || !customer)) || loadMore;

		this.setState(
			{
				expand: elem,
				isLoading: retrieveData || this.state.isLoading,
			},
			async () => {
				this.props.resizeGrid();
				if (retrieveData) {
					try {
						const newData = await this.props.makePendingRequest(
							this.fetchCustomer({
								customerId,
								payments: elem === sections.PAYMENTS || elem === sections.SCHEDULES,
								[sections.GENERAL]: !references[sections.GENERAL] && !customer,
								[elem]: true,
								loadMore,
							}),
							requestKeys.FETCH
						);
						this.setSelectedTab(newData, elem);
					} catch (e) {
						if (e && !e.isCanceled) {
							//eslint-disable-next-line
							console.error(e);
						}
					}
				}
				this.scrollToTop();
			}
		);
	};

	setSelectedTab = (newData, elem) => {
		const newState = {
			isLoading: false,
			data: newData,
		};
		if (elem === sections.PAYMENTS) {
			const defaultPaymentMethod = find(
				newData.payments,
				({ paymentMethodId, isDefaultPaymentMethod }) =>
					paymentMethodId === newData.general.defaultPaymentMethodId || isDefaultPaymentMethod
			);
			newState.selectedTab = defaultPaymentMethod ? toLower(defaultPaymentMethod.tokenType) : 'cc';
		}
		this.setState(newState, this.props.resizeGrid);
	};

	scrollToTop = () => {
		const { advancedView } = this.props;
		const { expand } = this.state;
		if (expand === 'general' || advancedView) {
			if (this.pageTopRef.current) {
				setTimeout(() => this.pageTopRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' }));
			}
		} else {
			if (expand === 'payments' && this.paymentsRef.current) {
				setTimeout(this.paymentsRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' }));
			}
			if (expand === 'schedules' && this.schedulesRef.current) {
				setTimeout(this.schedulesRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' }));
			}
		}
	};

	get components() {
		const {
			isLoading,
			fetchingToken,
			data,
			expand,
			selectedTab,
			requiredFields,
			customDisplayLabels,
			customerRequiredFields,
			customerHiddenFields,
			isCanadian,
			permissions,
			hideSkipSabbath,
			transactionHistory: { disableLoadMore, beginDate },
			convenienceFees,
			blurredField,
			savedGeneral,
			isViewOnly,
			paymentMethods,
		} = this.state;
		const {
			additionalSections,
			advancedView,
			advancedViewByDefault,
			template,
			customerId,
			schedule,
			type,
		} = this.props;

		const dontDisplay = { display: 'none' };
		const range = {
			start: moment(beginDate),
			end: moment(),
		};

		return {
			general: (
				<li
					key={sections.GENERAL}
					style={!isLoading && (expand === sections.GENERAL || !advancedView) ? null : dontDisplay}
				>
					<div>
						<AddEditCustomerGeneral
							customerHiddenFields={customerHiddenFields}
							customerRequiredFields={customerRequiredFields}
							customDisplayLabels={customDisplayLabels}
							data={data.general}
							onChange={this.onGeneralChange}
							onCustomBlur={this.onCustomBlur}
							blurredField={blurredField}
							savedGeneral={savedGeneral}
							refNum={data.references.general}
							errorMessages={data.errorMessages.general}
							advancedView={advancedView}
							isCanadian={isCanadian}
							isExpanded={expand === sections.GENERAL}
							isLoading={isLoading}
							requiredFields={requiredFields}
							renderFailedToFetch={this.renderFailedToFetch}
							isViewOnly={isViewOnly}
						/>
					</div>
				</li>
			),
			payments: (
				<li
					key={sections.PAYMENTS}
					style={!isLoading && (expand === sections.PAYMENTS || !advancedView) ? null : dontDisplay}
					ref={this.paymentsRef}
				>
					<div>
						<AddEditCustomerPayments
							type={type}
							payments={data.payments}
							paymentMethods={this.mapPayments(data.payments)}
							refNum={data.references.payments}
							customerId={customerId}
							onChange={this.onPaymentsChange}
							handleDelete={this.handlePaymentDelete}
							savePayment={this.savePayment}
							selectedTab={selectedTab}
							switchTab={this.switchTab}
							addNewPayment={this.addNewPayment}
							requiredFields={requiredFields}
							errorMessages={data.errorMessages.payments}
							advancedView={advancedView}
							advancedViewByDefault={advancedViewByDefault}
							isCanadian={isCanadian}
							permissions={permissions}
							isExpanded={expand === sections.PAYMENTS}
							ref={this.customerPaymentsRef}
							isAchEnabled={this.isAchEnabled}
							renderAddNewPayment={this.renderAddNewPayment}
							handleOpenCloseModal={this.openCloseActionsModal}
							deactivateActiveSchedulesAndRemovePaymentMethod={this.deactivateActiveSchedulesAndRemovePaymentMethod}
							isViewOnly={isViewOnly}
							accountTypeConstants={accountTypeConstants}
							onPaymentAccountTypeChange={this.onPaymentAccountTypeChange}
						/>
					</div>
				</li>
			),
			schedules: (
				<li
					key={sections.SCHEDULES}
					style={!isLoading && (expand === sections.SCHEDULES || !advancedView) ? null : dontDisplay}
					ref={this.schedulesRef}
				>
					<div>
						<AddEditCustomerSchedules
							schedules={data.schedules}
							general={data.general}
							refNum={data.references.schedules}
							onChange={this.onScheduleChange}
							onGeneralChange={this.onGeneralChange}
							saveSchedule={this.saveSchedule}
							handleOpenPreview={this.handleOpenPreview}
							handleOpenViewPayments={this.handleOpenViewPayments}
							handleOpenCloseModal={this.openCloseActionsModal}
							errorMessages={data.errorMessages.schedules}
							gridHolder={this.props.gridHolder}
							advancedView={advancedView}
							permissions={permissions}
							applyTemplate={this.applyTemplate}
							template={template}
							isCanadian={isCanadian}
							isExpanded={expand === sections.SCHEDULES}
							hideSkipSabbath={hideSkipSabbath}
							runPopoverAction={this.runSchedulePopoverAction}
							customerId={customerId}
							addNewSchedule={this.addNewSchedule}
							renderAddRecurringSchedule={this.renderAddRecurringSchedule}
							schedule={schedule}
							ref={this.customerSchedulesRefs}
							scheduleToFocus={this.scheduleToFocus}
							isLoading={isLoading || fetchingToken}
							convenienceFees={convenienceFees}
							onBeforeCalculateConvenience={this.onBeforeCalculateConvenience}
							includeExcludeConvenience={this.includeExcludeConvenience}
							customerHiddenFields={customerHiddenFields}
							customerRequiredFields={customerRequiredFields}
							customDisplayLabels={customDisplayLabels}
							isViewOnly={isViewOnly}
							paymentMethods={paymentMethods}
						/>
					</div>
				</li>
			),
			additional: map(additionalSections, ({ key, Component }) =>
				!isLoading && expand === key ? (
					<li key={key}>
						<div>
							<Component
								data={data}
								isCanadian={isCanadian}
								permissions={permissions}
								loadmore={this.loadmore}
								loadMoreFlag={!disableLoadMore}
								dataRange={range}
							/>
						</div>
					</li>
				) : null
			),
		};
	}

	handleCloseWarningModal = () => {
		this.setState({ isWarningModalOpen: false });
	};

	prefixKeysWithX = obj => {
		let result = [];
		if (isPlainObject(obj)) {
			result = {};
			each(obj, (value, key) => {
				if (!isObject(value)) {
					result[`x${replace(startCase(key), /\s/g, '')}`] = value;
				}
			});
		} else {
			each(obj, (value, key) => {
				result.push({});
				each(value, (objValue, objKey) => {
					if (!isObject(value)) {
						result[key][`x${replace(startCase(objKey), /\s/g, '')}`] = objValue;
					}
				});
			});
		}
		return result;
	};
	mapPaymentMethods = paymentMethods => {
		return filter(map(paymentMethods.xReportData, this.prefixKeysWithX), payment => !!payment.xPaymentMethodId);
	};

	getCustomerPaymentMethods = async () => {
		this.setState({ isLoading: true });
		const {
			data: {
				general: { defaultPaymentMethodId },
			},
		} = this.state;

		const { customerId } = this.props;

		try {
			const paymentMethods = await customerService.getCustomerPaymentMethods(customerId, defaultPaymentMethodId);
			return this.mapPaymentMethods(paymentMethods);
		} catch (e) {
			this.props.handleError(e);
		} finally {
			this.setState({ isLoading: false });
		}
	};
	openNewTransactionModal = async () => {
		const {
			data: { payments },
		} = this.state;
		const { customer, schedule, handleError } = this.props;
		const data = customer || schedule;
		const defaultPayment = find(payments, { isDefaultPaymentMethod: true });
		try {
			data.showLoader(true);
			const paymentMethods = await this.getCustomerPaymentMethods();
			const mappedRow = this.prefixKeysWithX(customer || schedule);
			mappedRow.xZip = defaultPayment ? defaultPayment.zip : null;
			mappedRow.xStreet = defaultPayment ? defaultPayment.street : null;
			data.openCloseModal({
				name: modalNames.newTransaction,
				data: {
					customer: mappedRow,
					paymentMethods,
					existingTransaction: null,
					refreshGridData: data.refreshGridData,
				},
			});
		} catch (e) {
			handleError(e);
		}
		data.showLoader(false);
	};

	sendPaymentRequest = async () => {
		try {
			const { schedule } = this.props;
			const customer = {};
			each(this.props.customer || schedule, (value, key) => {
				if (!isObject(value)) {
					customer[key] = value;
				}
			});
			this.props.history.push({
				pathname: '/send-payment-request',
				state: { customer },
			});
		} catch (e) {
			this.props.handleError(e);
		}
	};

	deactivateActiveSchedules = async schedules =>
		Promise.all(
			map(schedules, ({ scheduleId, isActive }) => {
				if (isActive && scheduleId) {
					return customerService.activateCustomerRecurringSchedule(scheduleId, false);
				}
			})
		);

	deleteCustomer = async () => {
		const { customerId, customer } = this.props;
		try {
			customer.showLoader(true);
			const schedules = await customerService.getCustomerRecurringSchedules(customerId);
			const existingSchedules = filter(schedules.xReportData, ({ scheduleId }) => scheduleId);
			await this.deactivateActiveSchedules(existingSchedules);
			const response = await customerService.deleteCustomer(customerId);
			customer.showLoader(false);
			return response;
		} catch (e) {
			customer.showLoader(false);
			this.props.handleError(e);
		}
	};

	deactivateActiveSchedulesAndRemovePaymentMethod = async () => {
		this.enableDisableSidebar(false);

		const { makePendingRequest, addNotification } = this.props;
		const { data } = this.state;
		let schedules = data.schedules;
		try {
			if (some(schedules, ({ scheduleId }) => !scheduleId)) {
				const { xReportData } = await customerService.getCustomerRecurringSchedules(data.general.customerId);
				schedules = xReportData;
			}

			await makePendingRequest(this.deactivateActiveSchedules(schedules), requestKeys.DEACTIVATE);
			const rsp = await makePendingRequest(
				this.deletePayment(get(first(data.payments), '_meta.id')),
				requestKeys.REMOVE
			);
			await addNotification({
				message: 'Schedules deactivated and payment method removed successfully',
				onClose: () => history.go(),
				ref: rsp.ref,
				success: true,
			});
		} catch (e) {
			this.enableDisableSidebar(true);

			this.props.handleError(e);
		}
	};

	deleteSchedule = async () => {
		const { schedule } = this.props;
		try {
			schedule.showLoader(true);
			const response = await customerService.deleteCustomerRecurringSchedule(schedule.scheduleId);
			schedule.showLoader(false);
			return response;
		} catch (e) {
			schedule.showLoader(false);
			this.props.handleError(e);
		}
	};

	openDeleteCustomerModal = () => {
		try {
			const { customer } = this.props;
			customer.openCloseModal({
				name: modalNames.confirmAction,
				data: {
					loadingMessage: 'Deleting Customer',
					modalClassName: 'popup__content popup--sml',
					question: (
						<React.Fragment>
							<p className="spc--bottom--sml">
								When a Customer is deleted, all associated recurring schedules (including those that are active) are
								deleted as well.
							</p>
							<p className="type--wgt--bold spc--bottom--sml">This action cannot be undone.</p>
							<p>Are you sure you want to delete the selected Customer?</p>
						</React.Fragment>
					),
					onConfirm: this.deleteCustomer,
					notificationHandler: rsp => ({
						ref: rsp && (rsp.refNum || rsp.ref),
						message:
							rsp && rsp.result === 'S'
								? 'Customer deleted'
								: (rsp && (rsp.message || rsp.error)) || UNEXPECTED_ERROR_MSG,
						success: rsp && rsp.result === 'S',
						onClose: customer.refreshGridData,
					}),
				},
			});
		} catch (e) {
			this.props.handleError(e);
		}
	};

	openDeleteScheduleModal = () => {
		try {
			const { schedule } = this.props;
			schedule.openCloseModal({
				name: modalNames.confirmAction,
				data: {
					loadingMessage: 'Deleting Recurring Schedule',
					question: `Are you sure you want to delete schedule ${schedule.scheduleName || schedule.scheduleId}?`,
					onConfirm: this.deleteSchedule,
					notificationHandler: rsp => ({
						ref: rsp && (rsp.refNum || rsp.ref),
						message:
							rsp && rsp.success
								? 'Recurring schedule deleted'
								: (rsp && (rsp.message || rsp.error)) || UNEXPECTED_ERROR_MSG,
						success: rsp && rsp.success,
						onClose: schedule.refreshGridData,
					}),
				},
			});
		} catch (e) {
			this.props.handleError(e);
		}
	};

	removeNewModifiedPayment = () => {
		const { data, tabToSwitch } = this.state;
		const newPayments = cloneDeep(data.payments);
		const shouldSetNewAsDefault = !some(newPayments, (payment, idx) => idx > 0 && payment.isDefaultPaymentMethod);
		const newItem = Util.newPayment(tabToSwitch, shouldSetNewAsDefault);
		newPayments[0] = newItem;
		this.setState({
			data: {
				...data,
				payments: newPayments,
			},
		});
		this.handleCloseWarningModal();
		this.switchTab(tabToSwitch, false);
	};
	renderFailedToFetch = (divClassName = 'grid-sidebar__message', parClassName = 'message message--warning') => {
		const { failedToFetchKvaas } = this.state;
		return (
			failedToFetchKvaas && (
				<div className={divClassName}>
					<p className={parClassName}>Failed to load your Recurring Settings. Please refresh the page.</p>
				</div>
			)
		);
	};

	renderAddRecurringSchedule = () => {
		const { advancedView } = this.props;
		const {
			expand,
			isLoading,
			fetchingToken,
			permissions: { allowCcSale, allowCheckSale },
			data,
		} = this.state;
		const isSchedulesExpanded = advancedView ? expand === sections.SCHEDULES : false;
		const hasPermissionToCreateSchedule = allowCcSale || allowCheckSale;
		const hasPaymentMethods = data.payments.length > 0;
		const defaultPaymentMethod = find(
			data.payments,
			({ paymentMethodId, isDefaultPaymentMethod }) =>
				paymentMethodId === data.general.defaultPaymentMethodId || isDefaultPaymentMethod
		);
		const isDefaultPaymentMethodExpired = defaultPaymentMethod && customerService.isExpired(defaultPaymentMethod.exp);
		const disableButton = isLoading || fetchingToken || !hasPaymentMethods || isDefaultPaymentMethodExpired;
		const tooltip = !hasPaymentMethods
			? 'Please add a payment method to create a recurring schedule.'
			: isDefaultPaymentMethodExpired
			? 'Please update the expired default payment method.'
			: '';
		const attr = tooltip ? { ['data-tooltip']: tooltip } : {};

		return (
			isSchedulesExpanded &&
			hasPermissionToCreateSchedule && (
				<button disabled={disableButton} onClick={this.addNewSchedule} className="btn btn--sml btn--ghost" {...attr}>
					Add Recurring Schedule
				</button>
			)
		);
	};

	renderAddNewPayment = () => {
		const { advancedView } = this.props;
		const {
			data: { payments },
			selectedTab,
			permissions: { allowCcSave, allowCheckSave },
			expand,
			isLoading,
			fetchingToken,
		} = this.state;
		const existsNewPayment = !!find(payments, ({ _meta: { created } }) => created);
		const isPaymentsExpanded = advancedView ? expand === sections.PAYMENTS : false;
		const disabled = existsNewPayment || isLoading || fetchingToken;

		if (isPaymentsExpanded) {
			return (
				<div className="datatooltip--v--bottom datatooltip--bottom-right datatooltip--w--160 display--ib">
					<div
						data-tooltip={existsNewPayment ? 'Only one payment method can be added at a time.' : null}
						className="display--ib"
					>
						{selectedTab === 'cc' &&
							(allowCcSave ? (
								<button
									onClick={this.addNewPayment}
									disabled={disabled}
									className="btn btn--sml btn--ghost btn--ghost--primary spc--bottom--sml"
								>
									Add Card
								</button>
							) : (
								<span>&nbsp;</span>
							))}
						{selectedTab === 'check' &&
							(allowCheckSave && this.isAchEnabled() ? (
								<button
									onClick={this.addNewPayment}
									disabled={disabled}
									className="btn btn--sml btn--ghost btn--ghost--primary spc--bottom--sml"
								>
									Add Check
								</button>
							) : (
								<span>&nbsp;</span>
							))}
					</div>
				</div>
			);
		}
	};

	renderViewPaymentsPopup = () => {
		const { isViewPaymentsOpen, scheduleId, scheduleName } = this.state;

		return (
			<Modal
				isOpen={this.state.isViewPaymentsOpen}
				onClose={this.handleCloseViewPayments}
				shouldCloseOnOverlayClick={false}
				overlayClassName="popup__overlay"
				className="popup__content popup--xxlrg popup--view-payments"
			>
				<div ref={this.popupRef}>
					{isViewPaymentsOpen && (
						<div className="popup__body">
							<ViewPaymentsGrid popupRef={this.popupRef} scheduleName={scheduleName} scheduleId={scheduleId} />
						</div>
					)}
				</div>
			</Modal>
		);
	};
	renderAdvancedView = ({ expand, hasDefaultPaymentMethod, hasSavePermission, hasSalePermission, hasSchedules }) => {
		return (
			<Fragment>
				<select
					value={expand}
					onChange={e => this.switchExpanded(e.target.value)}
					className="input input--med input--select spc--bottom--med hide--from--med--inline-block"
				>
					<option value={sections.GENERAL}>General</option>
					{!hasDefaultPaymentMethod && !hasSavePermission ? null : (
						<option value={sections.PAYMENTS}>Payment Method</option>
					)}
					{((hasSavePermission && hasSalePermission) || (hasDefaultPaymentMethod && hasSchedules)) && (
						<option value={sections.SCHEDULES}>Recurring Schedule</option>
					)}
				</select>
				<div className="group hide--to--med--block">
					<ul className="customer__tabs">
						<li className="customer__tabs__item customer__tabs__item--primary">
							<a
								className={`customer__tabs__link customer__tabs__link--primary ${
									expand === sections.GENERAL ? 'is-active' : ''
								}`}
								onClick={() => this.switchExpanded(sections.GENERAL)}
							>
								General
							</a>
						</li>
						{!hasDefaultPaymentMethod && !hasSavePermission ? null : (
							<li className="customer__tabs__item customer__tabs__item--primary">
								<a
									className={`customer__tabs__link customer__tabs__link--primary ${
										expand === sections.PAYMENTS ? 'is-active' : ''
									}`}
									onClick={() => this.switchExpanded(sections.PAYMENTS)}
								>
									Payment Method
								</a>
							</li>
						)}
						{((hasSavePermission && hasSalePermission) || (hasDefaultPaymentMethod && hasSchedules)) && (
							<li className="customer__tabs__item customer__tabs__item--primary">
								<a
									className={`customer__tabs__link customer__tabs__link--primary ${
										expand === sections.SCHEDULES ? 'is-active' : ''
									}`}
									onClick={() => this.switchExpanded(sections.SCHEDULES)}
								>
									Recurring Schedule
								</a>
							</li>
						)}
					</ul>
				</div>

				{this.renderFailedToFetch('spc--bottom--sml')}
			</Fragment>
		);
	};
	renderAddEditSidebarInfo = ({
		expand,
		hasDefaultPaymentMethod,
		hasSavePermission,
		hasSalePermission,
		hasSchedules,
		additionalSections,
		closeRow,
	}) => {
		return (
			<Fragment>
				<div className="grid-sidebar__tabs__fade grid-sidebar__tabs__fade--close">
					<div>
						<ul ref={this.tabsMenuRef} className="grid-sidebar__tabs">
							<li className="customer__tabs__item customer__tabs__item--primary">
								<a
									className={`customer__tabs__link customer__tabs__link--primary ${
										expand === sections.GENERAL ? 'is-active' : ''
									}`}
									onClick={() => this.switchExpanded(sections.GENERAL)}
								>
									General
								</a>
							</li>
							{!hasDefaultPaymentMethod && !hasSavePermission ? null : (
								<li className="customer__tabs__item customer__tabs__item--primary">
									<a
										className={`customer__tabs__link customer__tabs__link--primary ${
											expand === sections.PAYMENTS ? 'is-active' : ''
										}`}
										onClick={() => this.switchExpanded(sections.PAYMENTS)}
									>
										Payment Method
									</a>
								</li>
							)}
							{((hasSavePermission && hasSalePermission) || (hasDefaultPaymentMethod && hasSchedules)) && (
								<li ref={this.recurringScheduleTabRef} className="customer__tabs__item customer__tabs__item--primary">
									<a
										className={`customer__tabs__link customer__tabs__link--primary ${
											expand === sections.SCHEDULES ? 'is-active' : ''
										}`}
										onClick={() => this.switchExpanded(sections.SCHEDULES)}
									>
										Recurring Schedule
									</a>
								</li>
							)}

							{map(
								additionalSections,
								({ key, name, permissions }) =>
									validatePermissions(permissions) && (
										<li key={key} className="customer__tabs__item customer__tabs__item--primary">
											<a
												className={`customer__tabs__link customer__tabs__link--primary ${
													expand === key ? 'is-active' : ''
												}`}
												onClick={() => this.switchExpanded(key)}
											>
												{name}
											</a>
										</li>
									)
							)}
						</ul>
					</div>
					<button className="grid-sidebar__close" onClick={closeRow}></button>
				</div>
				{this.renderFailedToFetch()}
			</Fragment>
		);
	};
	render = () => {
		const {
			data,
			expand,
			isPreviewOpen,
			isLoading,
			fetchingToken,
			previewedSchedule,
			modal,
			isWarningModalOpen,
			permissions: { allowCcSale, allowCheckSale, allowCcSave, allowCheckSave },
			hideCustomerForm,
			principal,
			isViewOnly,
			has3DS2,
		} = this.state;
		const { additionalSections, advancedView, closeRow, type } = this.props;
		const hasDefaultPaymentMethod = !!data.general.defaultPaymentMethodId;
		const hasSchedules = !!data.general.recurringSchedule;
		const hasSalePermission = allowCcSale || allowCheckSale;
		const hasSavePermission = allowCcSave || allowCheckSave;
		const { general, payments, schedules, additional } = this.components;
		let components = [general, payments, schedules, additional];
		if (!hasDefaultPaymentMethod && ((!hasSavePermission && !hasSalePermission) || !hasSavePermission)) {
			components = [general, additional];
		} else if (hasSavePermission && !hasSalePermission && !hasDefaultPaymentMethod && !hasSchedules) {
			components = [general, payments, additional];
		}

		return (
			<div className={hideCustomerForm ? 'display--n' : this.isPopup ? '' : 'grid-sidebar__wrapper'}>
				<Modal
					isOpen={isWarningModalOpen}
					onClose={this.handleCloseWarningModal}
					shouldCloseOnOverlayClick={false}
					className="popup__content"
				>
					<div className="popup--customer">
						<div className="popup__header">
							<div className="popup__header__title">Would you like to keep this payment information on file?</div>
						</div>
						<div className="popup__body">
							When saving or creating a new customer only one payment method can be saved at a time. When you switch
							tabs this payment method will be removed.
						</div>
						<div className="popup__footer popup__footer--styled">
							<button
								type="button"
								tabIndex="-1"
								className="btn btn--sml btn--ghost"
								onClick={this.handleCloseWarningModal}
							>
								Yes
							</button>
							<button
								type="button"
								tabIndex="-1"
								className="btn btn--sml btn--primary"
								onClick={this.removeNewModifiedPayment}
							>
								No
							</button>
						</div>
					</div>
				</Modal>
				{this.isPopup && <span ref={this.pageTopRef}></span>}
				<SchedulePreviewGrid
					isOpen={isPreviewOpen}
					onClose={this.handleClosePreview}
					className="popup__content popup--med"
					schedule={previewedSchedule}
				/>
				{this.renderViewPaymentsPopup()}
				<ActionsModal modal={modal} onModalClose={this.openCloseActionsModal} />
				{this.isPopup ? (
					<div>
						{advancedView &&
							this.renderAdvancedView({
								expand,
								hasDefaultPaymentMethod,
								hasSavePermission,
								hasSalePermission,
								hasSchedules,
							})}
					</div>
				) : (
					this.renderAddEditSidebarInfo({
						expand,
						hasDefaultPaymentMethod,
						hasSavePermission,
						hasSalePermission,
						hasSchedules,
						additionalSections,
						closeRow,
					})
				)}

				{isLoading || fetchingToken ? (
					<div className="loader__holder grid-sidebar__loader">
						<div className="loader__spinner"></div>
					</div>
				) : null}
				<div className="grid-sidebar__body" style={isLoading || fetchingToken ? { display: 'none' } : null}>
					<ul className={`${this.isPopup || expand === sections.TRANSACTIONS ? '' : 'spc--bottom--med'}`}>
						{!this.isPopup && <span ref={this.pageTopRef}></span>}
						{components}
					</ul>
				</div>
				{!this.isPopup && (!isLoading || data.general.customerId) ? (
					<div className="grid-sidebar__footer clearfix">
						<Fragment>
							<div className="display--ib datatooltip--w--300">
								<button
									disabled={isLoading || fetchingToken || isViewOnly}
									onClick={this.handleSave}
									className="btn btn--primary btn--sml grid__expansion__btn"
									data-tooltip={has3DS2 ? threeDS2ProcessTooltip : null}
								>
									Save
								</button>
								<button onClick={closeRow} className="btn btn--sml btn--clear btn--anchor--alt spc--right--xsml">
									Close
								</button>
							</div>
						</Fragment>
						<div className="display--ib separator--grey1--left padd--left--xsml">
							{principal.hasAccess[sectionKeys.newTransaction] && (
								<button
									disabled={isLoading || fetchingToken}
									onClick={this.openNewTransactionModal}
									className="btn btn--sml btn--clear spc--right--tny"
									data-tooltip="New transaction"
								>
									<i className="icon icon--xsml icon--middle icon--newtransaction"></i>
								</button>
							)}
							{principal.hasAccess[sectionKeys.newTransaction] && (
								<button
									disabled={isLoading || fetchingToken}
									onClick={this.sendPaymentRequest}
									className="btn btn--sml btn--clear spc--right--tny"
									data-tooltip="Send payment request"
								>
									<i className="icon icon--xsml icon--middle icon--send-email--grey"></i>
								</button>
							)}
							<button
								disabled={isLoading || fetchingToken || isViewOnly}
								onClick={type === 'customers' ? this.openDeleteCustomerModal : this.openDeleteScheduleModal}
								className="btn btn--sml btn--clear"
								data-tooltip={`Delete ${type === 'customers' ? 'customer' : 'schedule'}`}
							>
								<i className="icon icon--xsml icon--middle icon--wastebasket"></i>
							</button>
						</div>
					</div>
				) : null}
			</div>
		);
	};
}
const applyWithForwardRef = hoc => component => withForwardRef(component, hoc);

const enhance = flowRight(
	withError,
	applyWithForwardRef(withRouter),
	applyWithForwardRef(withCancelable),
	applyWithForwardRef(withLoadMore)
);

export default enhance(AddEditCustomerForm);

AddEditCustomerForm.defaultProps = {
	resizeGrid: noop,
};

AddEditCustomerForm.propTypes = {
	customerId: PropTypes.string,
	customer: PropTypes.object,
	schedule: PropTypes.object,
	customerHiddenFields: PropTypes.object,
	customDisplayLabels: PropTypes.object,
	customerRequiredFields: PropTypes.object,
	existingTransaction: PropTypes.object,
	addNotification: PropTypes.func.isRequired,
	gridHolder: PropTypes.object,
	resizeGrid: PropTypes.func,
	refreshGridData: PropTypes.func,
	onDataChange: PropTypes.func,
	type: PropTypes.oneOf(['customers', 'schedules']).isRequired,
	template: PropTypes.object,
	makePendingRequest: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	additionalSections: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			Component: PropTypes.func.isRequired,
		})
	),
	advancedView: PropTypes.bool.isRequired,
	advancedViewByDefault: PropTypes.bool.isRequired,
	focusSchedule: PropTypes.bool,
	focusPayments: PropTypes.bool,
	handleError: PropTypes.func,
	toggleAdvancedView: PropTypes.func,
	closeRow: PropTypes.func,
	customerFooterRef: PropTypes.any,
	showHideCustomerModal: PropTypes.func,
	focusScheduleIndex: PropTypes.number,
	isDuplicate: PropTypes.func,
	setIsProcessing: PropTypes.func,
	loadMore: PropTypes.func,
};
