import { some, isEmpty } from 'lodash';

import principalService from '../services/principalService';

const principal = principalService.get();
let accountPermissions = (principal && principal.idInfo && principal.idInfo.permissions) || {};

principalService.subscribe(principal => {
	accountPermissions = (principal && principal.idInfo && principal.idInfo.permissions) || {};
});

export const validatePermissions = permissions =>
	isEmpty(permissions) || some(permissions, permission => accountPermissions[permission]);
