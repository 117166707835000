import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import moment from 'moment';

import TransactionHistoryItem from './TransactionHistoryItem';

class CustomerTransactionsHistory extends Component {
	get filters() {
		const { customer } = this.props;
		const custom01 = customer.customerId;
		const startDate = moment()
			.startOf('day')
			.subtract(6, 'months')
			.format(ApplicationSettings.parseDateTimeFormat);
		const endDate = moment()
			.endOf('day')
			.format(ApplicationSettings.parseDateTimeFormat);
		const key = 'custom';

		return {
			custom01,
			startDate,
			endDate,
			key,
		};
	}

	render() {
		const { transactions, refNum, errorMessages, customerId, loadmore, loadMoreFlag, dataRange } = this.props;
		const { custom01, startDate, endDate, key } = this.filters;
		const range = moment(dataRange.end).diff(dataRange.start, 'days');
		return (
			<React.Fragment>
				<div>
					{(customerId || refNum) && (
						<div className="clearfix spc--bottom--sml">
							{customerId && (
								<span className="type--wgt--medium type--color--text spc--right--sml pull">
									Customer ID: {customerId}
								</span>
							)}
							{refNum && <span className="type--wgt--medium type--color--text push">(#{refNum})</span>}
						</div>
					)}
					{map(errorMessages, (errorMessage, index) => (
						<div key={index} className="validation spc--bottom--med">
							<p className="type--error">{errorMessage}</p>
						</div>
					))}
				</div>
				{(!transactions || transactions.length < 1) && (
					<div>
						<div className="message message--default spc--bottom--sml">
							{range > 179
								? 'No transactions linked to the customer in the past 6 months.'
								: `Customer doesn't have any transactions in the last ${range} days.`}
						</div>
					</div>
				)}
				{transactions && (
					<React.Fragment>
						{map(transactions, (item, index) => {
							return <TransactionHistoryItem key={index} item={item} />;
						})}
						<div className="flex--primary spc--bottom--sml">
							{loadmore && loadMoreFlag && (
								<div className="datatooltip--w--100">
									<button
										className="btn btn--sml btn--ghost spc--right--med spc--bottom--sml"
										data-tooltip="Load next 30 days transaction history."
										onClick={loadmore}
									>
										Load more
									</button>
								</div>
							)}
							<a
								href={`/transactions?custom01=${custom01}&endDate=${endDate}&key=${key}&startDate=${startDate}`}
								target="_blank"
								className="anchor anchor--primary flex--primary spc--bottom--sml"
							>
								<i className="icon icon--tiny icon--eye-alt--primary spc--right--tny"></i>
								View all customer transactions
							</a>
						</div>
					</React.Fragment>
				)}
			</React.Fragment>
		);
	}
}

CustomerTransactionsHistory.propTypes = {
	customer: PropTypes.object.isRequired,
	transactions: PropTypes.array,
	refNum: PropTypes.string,
	customerId: PropTypes.string,
	errorMessages: PropTypes.arrayOf(PropTypes.string),
	loadmore: PropTypes.func,
	loadMoreFlag: PropTypes.bool,
	dataRange: PropTypes.shape({
		start: PropTypes.instanceOf(moment),
		end: PropTypes.instanceOf(moment),
	}),
};
export default CustomerTransactionsHistory;
