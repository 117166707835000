import { toUpper } from 'lodash';

const avsItems = [
	{
		text: 'Address: Match & 5 Digit ZIP: Match',
		value: 'YYY',
	},
	{
		text: 'Address: No Match & 5 Digit ZIP: Match',
		value: 'NYZ',
	},
	{
		text: 'Address: Match & 5 Digit ZIP: No Match',
		value: 'YNA',
	},
	{
		text: 'Address: No Match & 5 Digit ZIP: No Match',
		value: 'NNN',
	},
	{
		text: 'Address Information not verified for domestic transaction',
		value: 'XXU',
	},
	{
		text: 'Address: Match & 9 Digit ZIP: Match',
		value: 'YYX',
	},
	{
		text: 'Address: No Match & 9 Digit ZIP: Match',
		value: 'NYW',
	},
	{
		text: 'Retry / System Unavailable',
		value: 'XXR',
	},
	{
		text: 'Service Not Supported',
		value: 'XXS',
	},
];

export default function mapAvsItem(item) {
	let itemIndex = avsItems.findIndex(i => i.value === toUpper(item));
	if (itemIndex !== -1) {
		return avsItems[itemIndex];
	}
}
