import React from 'react';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';

function CardknoxSettings(props) {
    return (
        <div>
            <p className="spc--bottom--nano">
                We’ve made some changes to how we display <strong>Portal Settings</strong> and added a new section for{' '}
                <strong>Cardknox Account Settings</strong>.
            </p>
            <p className="spc--bottom--nano">
                This section allows you to easily enable some of our most used account settings.
            </p>
            <p className="spc--bottom--nano">
                Check out the new settings<button
                    className="btn btn-anchor btn--clear"
                    onClick={() =>
                        props.history.replace({
                            pathname: '/cardknox-account-settings/webhook',
                        })
                    }
                >
                    here
                </button>
            </p>
        </div>
    );
}
CardknoxSettings.propTypes = {
    history: object.isRequired,
};
export default withRouter(CardknoxSettings);