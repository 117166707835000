const sectionKeys = {
	dashboard: 'dashboard',
	fraud: 'fraud',
	batches: 'batches',
	users: 'users',
	transactions: 'transactions',
	disputes: 'disputes',
	gift: 'gift',
	giftActivity: 'giftActivity',
	giftSummary: 'giftSummary',
	giftLiability: 'giftLiability',
	balance: 'balance',
	newTransaction: 'newTransaction',
	customers: 'customers',
	settings: 'settings',
	notSaveOnly: 'notSaveOnly',
	portalManagement: 'portalManagement',
	dropIn: 'dropIn',
	paymentEngine: 'paymentEngine',
	virtualTerminal: 'virtualTerminal',
	goPlus: 'goPlus',
	featureAddons: 'featureAddons',
	sendPaymentRequest: 'sendPaymentRequest',
	transfers: 'transfers',
	statements: 'statements',
	cardknoxAccountSettings: 'cardknoxAccountSettings',
};

export default sectionKeys;
