import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { cloneDeep, each, includes, map, filter as filterMethod } from 'lodash';
import Select from 'react-select';

class StatusFilter extends Component {
	constructor(props) {
		super(props);
		this.onFilterChanged = this.onFilterChanged.bind(this);

		this.state = {
			transactionStatusSelectedOptions: null,
			transactionStatusOptions: [{ value: 'approved', label: 'Approved' }, { value: 'declined', label: 'Declined' }],
		};
	}

	componentDidMount = async () => {
		const { transactionStatusOptions } = this.state;
		const { allowReportApproved, allowReportDeclined } = this.props.permissions;
		let options = cloneDeep(transactionStatusOptions);
		if (!allowReportApproved) {
			options = filterMethod(transactionStatusOptions, ({ value }) => value !== 'approved');
		} else if (!allowReportDeclined) {
			options = filterMethod(transactionStatusOptions, ({ value }) => value !== 'declined');
		}
		this.setState({ transactionStatusOptions: options });
	};

	componentDidUpdate = prevProps => {
		if (prevProps.filter !== this.props.filter) {
			const transactionStatusSelectedOptions = this.updateSelectedOption(
				map(this.props.filter.values, (value, key) => ({ value, key }))
			);
			this.setState({ transactionStatusSelectedOptions });
		}
	};

	updateSelectedOption = values => {
		const { transactionStatusOptions } = this.state;
		const selectedValues = filterMethod(values, ({ value }) => !!value);
		const selectedKeys = map(selectedValues, 'key');
		const newOptions = [];

		each(transactionStatusOptions, option => {
			if (includes(selectedKeys, option.value)) {
				newOptions.push(option);
				return false;
			}
		});

		return newOptions;
	};

	onFilterChanged = filter => {
		const transactionStatusSelectedOptions = this.updateSelectedOption(filter.values);
		const filterDefaultValues = cloneDeep(this.props.filter.defaultValues);
		let updateValues = [];
		for (let [key] of Object.entries(filterDefaultValues)) {
			updateValues.push({
				key: key,
				value: key === transactionStatusSelectedOptions[0].value ? true : false,
			});
		}
		filter.values = updateValues.filter(x => x.value === true);
		filter.emptyValue = false;

		this.setState({ transactionStatusSelectedOptions }, () => this.props.onFilterChanged(filter));
	};

	handleTransactionStatusChange = (selected, changed) => {
		let options = selected;

		if (changed.action === 'select-option') {
			options = selected;
		} else if (changed.action === 'remove-value') {
			options = selected;
		} else if (changed.action === 'clear') {
			options = {};
		}

		this.setState(
			{
				transactionStatusSelectedOptions: Object.keys(options).length ? options : null,
			},
			() => this.updateFilterFromState()
		);
	};

	updateFilterFromState = () => {
		const { transactionStatusSelectedOptions } = this.state;
		const selectedValues =
			transactionStatusSelectedOptions && transactionStatusSelectedOptions.value
				? transactionStatusSelectedOptions.value
				: null;
		const values = cloneDeep(this.props.filter.defaultValues);
		let updateValues = [];
		for (let [key] of Object.entries(values)) {
			updateValues.push({
				key: key,
				value: selectedValues && selectedValues.includes(key) ? true : false,
			});
		}

		this.props.onFilterChanged({
			id: 'status',
			values: updateValues,
			emptyValue: false,
		});
	};

	render() {
		const { transactionStatusSelectedOptions, transactionStatusOptions } = this.state;
		const { transactionType } = this.props;

		return (
			<Select
				value={transactionStatusSelectedOptions}
				options={transactionStatusOptions}
				onChange={this.handleTransactionStatusChange}
				isClearable={true}
				className="react-select-container"
				classNamePrefix="react-select"
				isDisabled={transactionType.values.auth}
			/>
		);
	}
}

StatusFilter.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
	permissions: PropTypes.object,
	transactionType: PropTypes.object,
};

export default StatusFilter;
