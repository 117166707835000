import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { mapUserRoles } from '../../users/column-filter/user-roles';

class UserRoleComponent extends Component {
	constructor(props) {
		super(props);
	}

	renderItemText = () => {
		const { value } = this.props;
		if (!value) {
			return 'Default';
		}

		const item = mapUserRoles(value);

		return item ? item.label : 'Default';
	};

	render() {
		return <Fragment>{this.renderItemText()}</Fragment>;
	}
}

UserRoleComponent.propTypes = {
	value: PropTypes.string.isRequired,
	dependentValues: PropTypes.any,
};

export default UserRoleComponent;
