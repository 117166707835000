import React, { Component } from 'react';

class FilterAlert extends Component {
	render() {
		return (
			<p
				className="icon icon--tiny icon--info--note"
				data-tooltip="Alert: Results may not contain all transactions. Click All to retrieve all transactions."
			></p>
		);
	}
}

export default FilterAlert;
