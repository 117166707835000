import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { each, head, keys } from 'lodash';
import { kvaasService, principalService } from 'common/services';
import handleInvalidRevision from '../../user-settings/utils/invalidRevision';
import { withLoader } from 'common/components/loader';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { withBlock } from 'common/components/block';
import BaseSettings from 'components/settings/pages/BaseSettings';
import { Notification } from 'common/components/notifications';
import { kvaasResources } from 'common/utilities';

let principal = principalService.get();

class AccountManagementTransactionHistory extends BaseSettings {
	constructor(props) {
		super(props);
		this.state = this.initialState;
	}

	get initialState() {
		return {
			reportType: 'transactionReport',
			transactionReport: {
				fields: [...this.transactionInitialFields.filter(c => (principal.isAdmin ? true : !c.isAdminColumn))],
				oldData: {
					portalFlags: null,
				},
			},
			sectionsExpanded: {
				columns: false,
			},
		};
	}

	componentDidMount = async () => {
		this.props.showLoader(true);
		await this.fetchData();
		this.props.showLoader(false);
	};
	requiredKvaasResources() {
		return kvaasService.get({ ...kvaasResources.portalFlags, throwError: true });
	}
	getData = async () => {
		const getKvaas = this.requiredKvaasResources();
		const [portalFlags] = await this.props.makePendingRequest(getKvaas, 'kvaas');

		return {
			portalFlags,
		};
	};
	mapPortalFlagsToState = (newState, portalFlagsData) => {
		const portalFlagKeys = keys(portalFlagsData);
		each(portalFlagKeys, key => {
			newState[key] = portalFlagsData[key];
		});
	};
	fetchData = async setToDefault => {
		if (setToDefault) {
			this.setState(this.initialState);
		}
		this.props.showLoader(true);
		try {
			const { portalFlags } = await this.getData();
			const newState = {
				oldData: {},
			};
			this.mapFieldsToState(newState, portalFlags, 'portalFlags');
			this.mapPortalFlagsToState(newState, portalFlags.data);

			this.setState({
				transactionReport: newState,
			});
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.handleKvaasLoadError();
			}
		}
		this.props.showLoader(false);
	};
	setToDefault = async () => {
		return [
			{
				newData: {
					revision: 0,
					data: {
						printerReceiptOption: false,
						displayAmexVerificationColumns: false,
						printInPortrait: false,
						showIssuingBankInfo: false,
						displayOlderTransactionsFirst: false,
						printOnOnePage: false,
					},
				},
				oldData: this.state.transactionReport.oldData.portalFlags,
				...kvaasResources.portalFlags,
			},
		];
	};
	mapStateToReport = () => {
		return [
			{
				newData: {
					revision: 0,
					data: {
						displayOlderTransactionsFirst: this.state.transactionReport.displayOlderTransactionsFirst,
						displayAmexVerificationColumns: this.state.transactionReport.displayAmexVerificationColumns,
						printInPortrait: this.state.transactionReport.printInPortrait,
						printOnOnePage: this.state.transactionReport.printOnOnePage,
						printerReceiptOption: this.state.transactionReport.printerReceiptOption,
						showIssuingBankInfo: this.state.transactionReport.showIssuingBankInfo,
					},
				},
				oldData: this.state.transactionReport.oldData.portalFlags,
				...kvaasResources.portalFlags,
			},
		];
	};

	save = async setToDefaults => {
		this.props.showLoader(true);
		let error;
		let refNum;
		try {
			const mappedState = setToDefaults ? await this.setToDefault() : this.mapStateToReport();
			const response = await this.props.makePendingRequest(kvaasService.save(...mappedState), 'save');
			refNum = head(response).refNum;
			this.props.handleBlockChange(false);
			await this.fetchData(setToDefaults);
		} catch (e) {
			this.props.showLoader(false);

			error = this.props.handleError(e, { delayMessage: true });
			if (error) {
				await this.fetchData();
			}
		}
		this.notifySuccess(error, setToDefaults, 'Transaction History', refNum);
		this.props.showLoader(false);
	};

	render = () => {
		const {
			displayAmexVerificationColumns,
			printerReceiptOption,
			displayOlderTransactionsFirst,
			printInPortrait,
			printOnOnePage,
			showIssuingBankInfo,
		} = this.state.transactionReport;

		return (
			<Fragment>
				<div className="settings--main settings--main--alt">
					<div className="settings__header">
						<div className="settings__header__title">Transaction History Settings</div>
						<div className="settings__header__action">{this.renderSaveButton(false)}</div>
					</div>
					<div ref={top}></div>
					<div className="spr__card clearfix">
						<div className="spr__content">
							<div className="spc--bottom--med">
								<input
									type="checkbox"
									id="transactionReport.displayAmexVerificationColumns"
									name="transactionReport.displayAmexVerificationColumns"
									checked={displayAmexVerificationColumns}
									value={displayAmexVerificationColumns}
									onChange={this.handleCheckboxChange}
									className="input input--check"
								/>
								<label
									htmlFor="transactionReport.displayAmexVerificationColumns"
									className="type--color--text--medium type--wgt--medium"
								>
									Enhanced Amex AVS
								</label>
							</div>
							<div className="spc--bottom--med">
								<input
									type="checkbox"
									id="transactionReport.showIssuingBankInfo"
									name="transactionReport.showIssuingBankInfo"
									checked={showIssuingBankInfo}
									value={showIssuingBankInfo}
									onChange={this.handleCheckboxChange}
									className="input input--check"
								/>
								<label
									htmlFor="transactionReport.showIssuingBankInfo"
									className="type--color--text--medium type--wgt--medium"
								>
									Include Issuing Bank Details in Transaction Details
								</label>
							</div>
							<div className="spc--bottom--med">
								<input
									type="checkbox"
									id="transactionReport.displayOlderTransactionsFirst"
									name="transactionReport.displayOlderTransactionsFirst"
									checked={displayOlderTransactionsFirst}
									value={displayOlderTransactionsFirst}
									onChange={this.handleCheckboxChange}
									className="input input--check"
								/>
								<label
									htmlFor="transactionReport.displayOlderTransactionsFirst"
									className="type--color--text--medium type--wgt--medium"
								>
									Display Older Transactions First
								</label>
							</div>
							<div className="spc--bottom--med">
								<input
									type="checkbox"
									id="transactionReport.printerReceiptOption"
									name="transactionReport.printerReceiptOption"
									checked={printerReceiptOption}
									value={printerReceiptOption}
									onChange={this.handleCheckboxChange}
									className="input input--check"
								/>
								<label
									htmlFor="transactionReport.printerReceiptOption"
									className="type--color--text--medium type--wgt--medium"
								>
									Printer Receipt Option
								</label>
							</div>
							<div className="spc--bottom--med">
								<input
									type="checkbox"
									id="transactionReport.printInPortrait"
									name="transactionReport.printInPortrait"
									checked={printInPortrait}
									value={printInPortrait}
									onChange={this.handleCheckboxChange}
									className="input input--check"
								/>
								<label
									htmlFor="transactionReport.printInPortrait"
									className="type--color--text--medium type--wgt--medium"
								>
									Print Transactions Report In Portrait Mode
								</label>
							</div>
							<div className="flex--primary spc--bottom--med datatooltip--no-wrap">
								<div className="display--b">
									<input
										type="checkbox"
										id="transactionReport.printOnOnePage"
										name="transactionReport.printOnOnePage"
										checked={printOnOnePage}
										value={printOnOnePage}
										onChange={this.handleCheckboxChange}
										className="input input--check"
									/>
									<label
										htmlFor="transactionReport.printOnOnePage"
										className="type--color--text--medium"
									>
										Print Transactions Report On One Page
									</label>
								</div>
								<div data-tooltip={'Up to 15 columns will be displayed on one page.'} className="display--f spc--left--tny">
									<i
										onClick={e => {
											e.preventDefault();
										}}
										className="icon icon--tiny icon--info"
									></i>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Notification ref={this.notification} />
			</Fragment>
		);
	};
}

AccountManagementTransactionHistory.propTypes = {
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	showLoader: PropTypes.func,
	history: PropTypes.object,
	match: PropTypes.object,
	location: PropTypes.object,
	isLoading: PropTypes.bool,
	handleBlockChange: PropTypes.func,
};

export default withError(
	withLoader(withCancelable(withBlock(AccountManagementTransactionHistory))),
	handleInvalidRevision
);
