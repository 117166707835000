import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { first, get, isEmpty, noop } from 'lodash';
import { saveAs } from 'file-saver';

import { merchantRegistrationService } from '../../services';
import { FormErrors } from '../../utilities';
import ProgressStep from './ProgressStep';
class RegisterApplePayOwnCertificate extends Component {
	state = {
		domainAssociationFile: null,
		domain: null,
		invalidDomainName: true,
		isLoading: false,
		uploadedFileName: '',
		paymentCsrGenerated: false,
	};

	validateInputs = () => {
		const { domain } = this.state;
		let hasErrors = false;
		const newState = { errors: {} };
		if (!domain) {
			newState.invalidDomainName = true;
			hasErrors = true;
			newState.errors.domainName = 'Valid Domain Name is required';
		} else {
			newState.invalidDomainName = false;
		}
		this.setState(newState);
		return hasErrors;
	};
	handleDownloadCsr = merchantCsr => {
		const decodedCsr = window.atob(merchantCsr);
		const csrBlob = new Blob([decodedCsr], { type: 'text/plain;charset=utf-8' });
		saveAs(csrBlob, `${this.state.domain}.csr`);
	};

	handleGenerateApplePaymentCsr = async () => {
		this.setState({
			isLoading: true,
			isErroredUploadPaymentCSR: false,
			isGeneratingPaymentCsr: true,
		});
		try {
			const csr = await merchantRegistrationService.generateApplePaymentCsr(this.state.domain, this.state.uploadedCsr);
			const paymentCsrBlob = new Blob([window.atob(get(csr, 'paymentCsr', ''))]);
			saveAs(paymentCsrBlob, `${this.state.domain}Payment.csr`);
			this.setState({ paymentCsrGenerated: true });
		} catch (e) {
			this.setState({ isErroredUploadPaymentCSR: true });
		} finally {
			this.setState({
				isLoading: false,
				isGeneratingPaymentCsr: false,
			});
		}
	};
	handleUploadCertificate = async e => {
		e.persist();
		const file = first(e.target.files);
		if (file) {
			const reader = new FileReader();
			reader.onload = async event => {
				const fileContent = event.target.result;
				const base64Certificate = btoa(fileContent);
				this.setState({ uploadedCsr: base64Certificate, uploadedFileName: file.name });
			};
			reader.readAsBinaryString(file);
		} else {
			this.setState({ uploadedCsr: null });
		}
	};

	generateCsr = async () => {
		const addNotification = this.props.addNotification;
		try {
			this.setState({ isLoading: true });
			const { domain } = this.state;
			const csr = await merchantRegistrationService.generateAppleMerchantCsr(domain);
			const merchantCsr = get(csr, 'merchantCsr', '');
			this.setState({ merchantCsr });
			this.handleDownloadCsr(merchantCsr);
		} catch (e) {
			const notification = this.props.handleError(e, { delayMessage: true });
			addNotification({ ...notification, onClose: noop });
		} finally {
			this.setState({ showUploadCertificate: true });
			this.setState({ isLoading: false });
		}
	};

	setEnteringDomain = e => {
		const newState = { domain: e.target.value };
		this.setState(newState, this.validateInputs);
	};
	renderPopupHeader = () => {
		const { isGeneratingPaymentCsr, showUploadCertificate } = this.state;

		if (isGeneratingPaymentCsr) {
			return <div className="popup__header__title">Generating your Payment CSR</div>;
		} else if (showUploadCertificate) {
			return <div className="popup__header__title">Upload Your Merchant CSR File</div>;
		} else {
			return <div className="popup__header__title">Generate Apple Pay Merchant CSR</div>;
		}
	};
	renderPopupFooter = (isDownloadDisabled, showUploadCertificate) => {
		const onClickHandler = showUploadCertificate ? this.handleGenerateApplePaymentCsr : this.generateCsr;
		const buttonText = showUploadCertificate ? 'Upload Certificate' : 'Download Merchant CSR';
		return (
			<div className="popup__footer popup__footer--styled">
				<button
					type="button"
					className="btn btn--sml btn--primary"
					onClick={this.state.paymentCsrGenerated ? this.props.closeModal : onClickHandler}
					disabled={isDownloadDisabled}
				>
					{this.state.paymentCsrGenerated ? 'Ok' : buttonText}
				</button>
			</div>
		);
	};
	renderEnteringDomain = errors => {
		const required = (
			<span className="required-field label--required" data-tooltip="Required">
				*
			</span>
		);
		return (
			<Fragment>
				<label htmlFor="domain" className="label">
					Entering Domain {required}
				</label>
				<input
					type="text"
					id="domain"
					name="domain"
					className={`input input--med ${!isEmpty(errors) ? 'is-invalid' : ''}`}
					onChange={this.setEnteringDomain}
				/>
				<div>
					{!isEmpty(errors) && (
						<div className="spc--top--tny">
							<FormErrors errors={errors} />
						</div>
					)}
				</div>
			</Fragment>
		);
	};

	renderPopupBody = () => {
		if (this.state.showUploadCertificate) {
			return this.renderUploadMerchantCsr();
		} else {
			return this.renderEnteringDomain(this.state.errors);
		}
	};

	renderUploadMerchantCsr = () => {
		const { isErroredUploadPaymentCSR } = this.state;

		return (
			<div>
				{
					<Fragment>
						<ProgressStep
							number={1}
							message="Please use downloaded Merchant CSR to obtain Merchant Certificate using your Apple Portal Account."
							isCompleted={true}
						/>
						<ProgressStep
							number={2}
							message="Upload Apple Merchant Certificate."
							isCompleted={this.state.paymentCsrGenerated}
						>
							<div className="spc--top--sml">
								{isErroredUploadPaymentCSR && (
									<p className="message message--sml message--warning spc--bottom--sml">
										Failed to upload Apple Pay certificate
									</p>
								)}
								<div>
									<input
										type="file"
										tabIndex="-1"
										accept=".cer"
										name="logo"
										onChange={this.handleUploadCertificate}
										disabled={this.state.uploadedCsr && !this.state.isErroredUploadPaymentCSR}
										filename={this.state.uploadedFileName}
									/>
								</div>
							</div>
						</ProgressStep>

						<ProgressStep
							number={3}
							message="Please use downloaded Payment CSR to generate Payment Certificate in your Apple Portal Account."
						/>
					</Fragment>
				}
			</div>
		);
	};
	render = () => {
		const { invalidDomainName, errors, isLoading, showUploadCertificate, uploadedCsr } = this.state;
		const isDownloadDisabled = invalidDomainName || isLoading || (showUploadCertificate && !uploadedCsr);

		return (
			<div className="popup popup--apple-pay">
				<div className="popup__header">{this.renderPopupHeader()}</div>
				<div className="popup__body">
					{isLoading ? (
						<div className="loader--popup__holder type--center">
							<div className="spc--bottom--sml">Please wait, this may take a few seconds...</div>
							<div className="loader__spinner loader__spinner--apple-pay"></div>
						</div>
					) : (
						this.renderPopupBody(errors, showUploadCertificate)
					)}
				</div>
				{isLoading ? <div></div> : this.renderPopupFooter(isDownloadDisabled, showUploadCertificate)}
			</div>
		);
	};
}

RegisterApplePayOwnCertificate.propTypes = {
	handleError: PropTypes.func,
	addNotification: PropTypes.func,
	closeModal: PropTypes.func,
};

export default RegisterApplePayOwnCertificate;
