import React, { Component, createRef } from 'react';
import DayPicker from 'react-day-picker';
import NumberFormat from 'react-number-format';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import PropTypes from 'prop-types';

import TimePicker from 'common/components/react-time-picker';
import { renderIf } from 'common/utilities';
import { YearMonth } from './components';

const dateFormat = ApplicationSettings.displayDateFormat;
const timeFormat = ApplicationSettings.timeFormat;

class DateFilterComponent extends Component {
	constructor(props) {
		super(props);
		this.dayPickerRef = createRef();
		this.yearMonthRef = createRef();
		this.fromRef = createRef();
		this.toRef = createRef();
		this.state = {
			bottomBarrier: moment()
				.subtract(20, 'years')
				.toDate(),
			upperBarrier: moment().toDate(),
			from: this.props.from,
			to: this.props.to,
			maxDaysRange: this.props.maxDaysRange || 100,
			tempFrom: moment(this.props.from).format(dateFormat),
			tempTo: moment(this.props.to).format(dateFormat),
			fromTime: moment(this.props.from).format(timeFormat),
			toTime: moment(this.props.to).format(timeFormat),
			enteredTo: this.props.to,
			initialClick: true,
			fromFocused: true,
			errors: {
				from: null,
				to: null,
				range: null,
			},
		};
	}

	componentDidMount() {
		this.setState({
			availableYears: this.getYears(),
		});
	}

	componentWillReceiveProps(nextProps) {
		let updateObj = {};

		if (nextProps.to.getTime() !== this.props.to.getTime()) {
			updateObj.to = nextProps.to;
			updateObj.tempTo = moment(nextProps.to).format(dateFormat);
			updateObj.enteredTo = nextProps.to;
			updateObj.toTime = moment(nextProps.to).format(timeFormat);
		}
		if (nextProps.from.getTime() !== this.props.from.getTime()) {
			updateObj.from = nextProps.from;
			updateObj.upperBarrier = moment().toDate();
			updateObj.tempFrom = moment(nextProps.from).format(dateFormat);
			updateObj.fromTime = moment(nextProps.from).format(timeFormat);
		}

		this.setState({
			...updateObj,
		});
	}
	onFocus = (e, isFrom) => {
		if (isFrom) {
			this.setState({ fromFocused: true });
		} else {
			this.setState({ toFocused: true });
		}
	};

	getYears = () => {
		const currentYear = new Date().getFullYear();
		const fromMonth = new Date(currentYear, 0);
		const toMonth = new Date(currentYear + 10, 11);
		const calendarYears = [];

		if (this.state.bottomBarrier) {
			this.mapCalendarYears(calendarYears, this.state.bottomBarrier, this.state.upperBarrier);
		} else {
			this.mapCalendarYears(calendarYears, fromMonth, toMonth);
		}
		return calendarYears;
	};

	mapCalendarYears = (calendarYears, bottomBarrier, upperBarrier) => {
		for (let i = bottomBarrier.getFullYear(); i <= upperBarrier.getFullYear(); i += 1) {
			calendarYears.push(i);
		}
	};

	isSelectingFirstDay = (from, to, day, isFromBeforeTo, disableShorteningRange) => {
		if (!from) {
			return true;
		}
		const dayMoment = this.parseDate(day, null, dateFormat);
		const fromMoment = this.parseDate(from, null, dateFormat);
		const toMoment = this.parseDate(to, null, dateFormat);
		const isBeforeFirstDay = dayMoment.isBefore(fromMoment) || (dayMoment.isSame(fromMoment) && isFromBeforeTo);
		const isRangeSelected = !!to;
		const isAfterLastDay = dayMoment.isAfter(toMoment) || (dayMoment.isSame(toMoment) && !isFromBeforeTo);
		return isBeforeFirstDay || (isRangeSelected && (disableShorteningRange || isAfterLastDay));
	};

	getUpperBarrier = day => {
		const { maxDaysRange } = this.state;
		const maxUpperBarrier = moment().toDate();
		let upperBarrier =
			maxDaysRange === Infinity
				? maxUpperBarrier
				: moment(day)
						.add(maxDaysRange, 'days')
						.toDate();
		if (upperBarrier > maxUpperBarrier) {
			upperBarrier = maxUpperBarrier;
		}
		return upperBarrier;
	};

	getBottomBarrier = day => {
		const { maxDaysRange } = this.state;
		return moment(day)
			.subtract(maxDaysRange, 'days')
			.toDate();
	};

	handleTodayButtonClick = () => {
		const today = new Date();
		this.handleTodayClick(new Date(today.getFullYear(), today.getMonth(), today.getDate()));
		this.yearMonthRef.handleMonthSelect(today.getMonth(), today.getFullYear());
	};
	handleDayClick = day => {
		const { from, to, fromTime, toTime, errors, initialClick } = this.state;
		const { displayTime } = this.props;
		const isFromBeforeTo =
			displayTime && fromTime && toTime && moment(fromTime, timeFormat).isAfter(moment(toTime, timeFormat));

		if (this.isSelectingFirstDay(from, to, day, isFromBeforeTo) || initialClick) {
			errors.from = null;
			errors.range = null;
			const upperBarrier = this.getUpperBarrier(day);
			const bottomBarrier = this.getBottomBarrier(day);
			this.setState(
				{
					from: day,
					tempFrom: moment(day).format(dateFormat),
					to: null,
					enteredTo: null,
					tempTo: null,
					// Restrict date range on first date click
					bottomBarrier,
					upperBarrier,
					initialClick: false,
					errors,
				},
				() => this.toRef.focus()
			);
		} else {
			errors.to = null;
			errors.range = null;
			this.setState(
				{
					to: day,
					tempTo: moment(day).format(dateFormat),
					enteredTo: day,
					bottomBarrier: moment()
						.subtract(20, 'years')
						.toDate(),
					upperBarrier: moment().toDate(),
					errors,
				},
				() => this.fromRef.focus()
			);
		}
	};

	handleDayMouseEnter = day => {
		const { from, to, fromTime, toTime } = this.state;
		const { displayTime } = this.props;
		const isFromBeforeTo =
			displayTime && fromTime && toTime && moment(fromTime, timeFormat).isAfter(moment(toTime, timeFormat));
		if (!this.isSelectingFirstDay(from, to, day, isFromBeforeTo, true)) {
			this.setState({
				enteredTo: day,
			});
		}
	};

	handleOnChange = (from, to, fromTime, toTime) => {
		this.props.onChange({
			from,
			to,
			fromTime,
			toTime,
		});
	};

	applyInputChange = e => {
		e.preventDefault();
		const { from, to, fromTime, toTime } = this.state;
		this.handleOnChange(from, to, fromTime, toTime);
	};

	parseDate = (value, fallback, format) => {
		return moment(value !== null ? value : fallback, format);
	};

	getRangeError = ({ fromInput = null, toInput = null, fromTimeInput = null, toTimeInput = null }) => {
		const { maxDaysRange, from, to, toTime, fromTime } = this.state;

		const fromDate = this.parseDate(fromInput, from, dateFormat);
		const toDate = this.parseDate(toInput, to, dateFormat);
		const fromTimeDate = this.parseDate(fromTimeInput, fromTime, timeFormat);
		const toTimeDate = this.parseDate(toTimeInput, toTime, timeFormat);

		const dateDiff = toDate.diff(fromDate, 'days');
		if (toDate.isAfter(this.getUpperBarrier(), 'day')) {
			return 'To Date cannot be greater than today';
		}
		if (dateDiff < 0 || (dateDiff === 0 && fromTimeDate.isAfter(toTimeDate))) {
			return 'Invalid date range';
		}
		if (dateDiff > maxDaysRange) {
			return `Date range cannot exceed ${maxDaysRange} days`;
		}

		return null;
	};
	get applyDisabled() {
		const { from, to, errors } = this.state;

		return !from || !to || errors.range || errors.from || errors.to;
	}
	handleInputFromChange = values => {
		const { formattedValue } = values;
		const { errors } = this.state;

		if (moment(formattedValue, dateFormat, true).isValid()) {
			errors.from = null;
			errors.range = this.getRangeError({ fromInput: formattedValue });
			const from = moment(formattedValue, dateFormat, true).toDate();
			const upperBarrier = this.getUpperBarrier(from);
			const bottomBarrier = this.getBottomBarrier(from);
			this.setState({
				errors,
				from: moment(formattedValue, dateFormat, true).toDate(),
				upperBarrier,
				bottomBarrier,
			});
		} else {
			errors.from = 'Invalid From date';
			errors.range = null;
		}

		this.setState({
			errors,
			tempFrom: formattedValue,
		});
	};

	handleInputToChange = values => {
		const { formattedValue } = values;
		const { errors } = this.state;

		if (moment(formattedValue, dateFormat, true).isValid()) {
			errors.to = null;
			errors.range = this.getRangeError({ toInput: formattedValue });
			this.setState({
				errors,
				to: moment(formattedValue, dateFormat, true).toDate(),
				enteredTo: moment(formattedValue, dateFormat, true).toDate(),
			});
		} else {
			errors.to = 'Invalid To date';
			errors.range = null;
		}

		this.setState({
			errors,
			tempTo: formattedValue,
		});
	};

	handleFromTimeChange = time => {
		const { errors } = this.state;

		errors.range = this.getRangeError({ fromTimeInput: time });
		this.setState({
			errors,
			fromTime: time,
		});
	};

	handleToTimeChange = time => {
		const { errors } = this.state;

		errors.range = this.getRangeError({ toTimeInput: time });
		this.setState({
			errors,
			toTime: time,
		});
	};

	handleTodayClick = day => {
		this.handleDayMouseEnter(day);
		this.handleDayClick(day);
	};

	render() {
		const {
			bottomBarrier,
			upperBarrier,
			from,
			tempFrom,
			tempTo,
			fromTime,
			toTime,
			enteredTo,
			errors,
			fromFocused,
			toFocused,
		} = this.state;
		const modifiers = { start: from, end: enteredTo };
		const disabledDays = { before: bottomBarrier, after: upperBarrier };
		const selectedDays = [from, { from, to: enteredTo }];
		const fromClassName = `input input--med input--date ${fromFocused ? 'is-focused' : ''}`;
		const toClassName = `input input--med input--date ${toFocused ? 'is-focused' : ''}`;

		return (
			<div className="inputfromto__holder Selectable">
				<div className="inputfromto__heading">
					<div className="row">
						<div className="col col-sml-12 col-lrg-9">
							<div className="row">
								<div className="col col-sml-12 col-lrg-6 spc--bottom--tny inputfromto__start-date">
									<NumberFormat
										autoFocus={true}
										getInputRef={el => (this.fromRef = el)}
										value={tempFrom}
										format="##/##/####"
										className={fromClassName}
										placeholder={dateFormat}
										mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
										onValueChange={this.handleInputFromChange}
										onFocus={e => this.onFocus(e, true)}
										onBlur={() => this.setState({ fromFocused: false })}
									/>
									{renderIf(errors.from)(<p>{errors.from}</p>)}
								</div>
								<div className="col col-sml-12 col-lrg-6 spc--bottom--tny inputfromto__end-date">
									<NumberFormat
										getInputRef={el => (this.toRef = el)}
										value={tempTo}
										format="##/##/####"
										className={toClassName}
										placeholder={dateFormat}
										mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
										onValueChange={this.handleInputToChange}
										onFocus={e => this.onFocus(e)}
										onBlur={() => this.setState({ toFocused: false })}
									/>
									{renderIf(errors.to)(<p>{errors.to}</p>)}
								</div>
								{renderIf(errors.range)(
									<div className="col col-sml-12 col-lrg-6 spc--bottom--tny">
										<p>{errors.range}</p>
									</div>
								)}
							</div>
							{this.props.displayTime ? (
								<div className="row">
									<div className="col col-sml-12 col-lrg-6 spc--bottom--tny">
										<div className="timepicker">
											<div className="timepicker__label">From</div>
											<div className="timepicker__value--from">
												<TimePicker
													value={fromTime}
													className="input input--time"
													clockIcon={null}
													clearIcon={null}
													disableClock={true}
													maxDetail="minute"
													onChange={this.handleFromTimeChange}
													locale="en-US"
												/>
											</div>
										</div>
									</div>

									<div className="col col-sml-12 col-lrg-6 spc--bottom--sml">
										<div className="timepicker">
											<div className="timepicker__label">To</div>
											<div className="timepicker__value">
												<TimePicker
													value={toTime}
													className="input input--time"
													clockIcon={null}
													clearIcon={null}
													disableClock={true}
													maxDetail="minute"
													onChange={this.handleToTimeChange}
													locale="en-US"
												/>
											</div>
										</div>
									</div>
								</div>
							) : null}
						</div>
						<div className="col col-sml-12 col-lrg-3">
							<div>
								<button
									type="button"
									onClick={this.applyInputChange}
									disabled={this.applyDisabled}
									className="btn btn--med btn--primary inputfromto__apply"
								>
									Apply
								</button>
							</div>
							<div>
								<button className="btn btn--med btn--ghost inputfromto__today" onClick={this.handleTodayButtonClick}>
									Today
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="inputfromto__body">
					<style>
						{`
							${
								this.props.maxDaysRange === Infinity
									? `
									.DayPicker-Day--disabled:hover:after { display: none; }
								`
									: `
									.DayPicker-Day--disabled:hover:before {
										content: 'You have selected a date range that exceeds the available range (${this.props.maxDaysRange} days). Please choose a shorter date range.';
									}
								`
							}
						`}
					</style>
					<DayPicker
						className="Range"
						numberOfMonths={2}
						fromMonth={bottomBarrier}
						onTodayButtonClick={this.handleDayClick}
						toMonth={upperBarrier}
						selectedDays={selectedDays}
						disabledDays={disabledDays}
						ref={el => (this.dayPickerRef = el)}
						captionElement={({ date, localeUtils }) => (
							<YearMonth
								ref={el => {
									this.yearMonthRef = el;
								}}
								date={date}
								localeUtils={localeUtils}
								bottomBarrier={bottomBarrier}
								upperBarrier={upperBarrier}
								dayPickerRef={this.dayPickerRef}
								availableYears={this.state.availableYears}
							/>
						)}
						modifiers={modifiers}
						onDayClick={this.handleDayClick}
						onDayMouseEnter={this.handleDayMouseEnter}
					/>
				</div>
			</div>
		);
	}
}

DateFilterComponent.propTypes = {
	from: PropTypes.instanceOf(Date),
	to: PropTypes.instanceOf(Date),
	maxDaysRange: PropTypes.number,
	onChange: PropTypes.func,
	onApplyFilter: PropTypes.func,
	displayTime: PropTypes.bool,
};

export default DateFilterComponent;
