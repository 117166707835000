import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { renderIf } from '../../utilities';

function withLoader(WrappedComponent) {
	class LoaderComponent extends Component {
		constructor(props) {
			super(props);

			this.state = {
				isLoading: false,
			};
		}

		showLoader = value => {
			this.setState({
				isLoading: value,
			});
		};

		render() {
			const { isLoading } = this.state;
			const { forwardedRef, ...rest } = this.props;
			return (
				<React.Fragment>
					{renderIf(isLoading)(<div className="loader--progress" />)}
					<WrappedComponent ref={forwardedRef} isLoading={isLoading} showLoader={this.showLoader} {...rest} />
				</React.Fragment>
			);
		}
	}
	LoaderComponent.propTypes = {
		forwardedRef: PropTypes.any,
	};
	return forwardRef((props, ref) => <LoaderComponent {...props} forwardedRef={ref} />);
}

export default withLoader;
