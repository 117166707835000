import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { get, isNumber } from 'lodash';

const arrowClassNames = {
	NONE: 'sort',
	ASC: 'dropup',
	DESC: 'dropdown',
};

class ColumnHeaderComponent extends Component {
	constructor(props) {
		super(props);

		this.nameRef = createRef();
	}

	get style() {
		const { column } = this.props;
		const ref = get(this.nameRef, 'current', false);
		let style = {};

		if (ref) {
			const sortIconsWidth = isNumber(column.customWidth) ? column.customWidth : 45;
			const width = this.props.column.width - sortIconsWidth;
			const isOverflowing = ref.scrollWidth > width;

			if (isOverflowing) {
				style = {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					width,
				};
			}
		}

		return style;
	}

	render() {
		const {
			column: { name, sortable, alignHeaderRight },
			sortDirection,
		} = this.props;

		return (
			<div className={`type--${alignHeaderRight ? 'right' : 'left'}`}>
				<p ref={this.nameRef} className="display--ib" style={this.style}>
					{name}
				</p>
				{sortable && (
					<i
						className={`icon icon--tiny icon--arrow--${arrowClassNames[sortDirection]}--grey spc--left--tny align--v--top`}
					></i>
				)}
			</div>
		);
	}
}

ColumnHeaderComponent.propTypes = {
	column: PropTypes.object,
	sortDirection: PropTypes.string,
};

export default ColumnHeaderComponent;
