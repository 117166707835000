import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { map, toLower, endsWith, isEmpty, trim } from 'lodash';
import NumberFormat from 'react-number-format';

import { CurrencyMap } from '../../../utilities';
import { withError } from '../../error';
import { withCancelable } from '../../cancelable';

class AddEditTemplateForm extends Component {
	constructor(props) {
		super(props);

		this.amountRef = createRef();
		this.scheduleNameRef = createRef();

		this.state = {
			isLoading: false,
			errorMessages: [],
			formSubmitted: false,
			isScheduleNameValid: true,
			template: {
				xScheduleName: '',
				amount: '',
				xIntervalCount: '',
				xIntervalType: 'month',
				xTotalPayments: '',
				xDescription: '',
				xSkipSab: false,
			},
		};
	}

	get currencyCode() {
		return CurrencyMap.resolveCurrency();
	}

	componentDidMount = () => {
		const { template } = this.props;
		if (!isEmpty(template)) {
			this.setState({ template });
		}
		if (this.scheduleNameRef.current) {
			this.scheduleNameRef.current.focus();
		}
	};

	onChange = ({ target: { name, value, checked, type } }) => {
		this.setState(
			{
				template: {
					...this.state.template,
					[name]: type === 'checkbox' ? checked : value,
				},
			},
			this.validateInputs
		);
	};

	handleNumberOfPaymentsChange = ({ value }) => {
		this.setState({
			template: {
				...this.state.template,
				xTotalPayments: value,
			},
		});
	};

	handleAmountChange = ({ value }) => {
		this.setState(
			{
				template: {
					...this.state.template,
					amount: value,
				},
			},
			this.validateInputs
		);
		if (this.amountRef && !endsWith(value, '.')) {
			this.amountRef.blur();
			this.amountRef.focus();
		}
	};

	handleSave = async () => {
		try {
			const { template, isLoading } = this.state;
			if (isLoading) {
				return;
			}
			if (this.validateInputs(true)) {
				this.setState({ isLoading: true });
				await this.props.makePendingRequest(this.props.saveTemplate(template, template.xKey || Date.now()));
				this.props.handleCloseModal();
			}
		} catch (e) {
			if (this.props.handleError(e)) {
				this.setState({ isLoading: false });
			}
		}
	};

	handleClose = () => {
		this.props.handleCloseModal();
	};

	validateInputs = (isSaving = false) => {
		const {
			template: { xScheduleName },
			formSubmitted,
		} = this.state;
		const errorMessages = [];
		const isScheduleNameValid = trim(xScheduleName);

		if (formSubmitted || isSaving) {
			if (!isScheduleNameValid) {
				errorMessages.push('Schedule name is required');
			}
			const isErrorsEmpty = isEmpty(errorMessages);

			this.setState({
				errorMessages,
				isScheduleNameValid,
				formSubmitted: true,
			});
			return isErrorsEmpty;
		}
	};

	render = () => {
		const { errorMessages, isLoading, template, isScheduleNameValid } = this.state;
		const { type } = this.props;

		return (
			<React.Fragment>
				<div className="popup__header">
					<h4 className="popup__header__title">{`${type === 'add' ? 'New' : 'Edit'} Schedule Template`}</h4>
					<div className="fullwidth">
						<p className="message message--default spc--bottom--sml">
							Changes made here do not affect recurring schedules that currently use this template.
						</p>
					</div>
				</div>
				<div className="popup__body" tabIndex="-1">
					{isLoading ? (
						<div className="loader__holder">
							<div className="loader__spinner"></div>
						</div>
					) : (
						<div className="row">
							{map(errorMessages, (errorMessage, index) => (
								<div className="col col-sml-12 col-med-6" key={index}>
									<div className="message message--warning spc--bottom--sml">
										<p>{errorMessage}</p>
									</div>
								</div>
							))}
							<div className="col col-sml-12 col-med-6">
								<div className="newtransaction__item">
									<label htmlFor="xScheduleName" className="newtransaction__item__label">
										Schedule Name{' '}
										<span className="required-field label--required" data-tooltip="Required">
											*
										</span>
									</label>
									<div className="newtransaction__item__value">
										<input
											type="text"
											id="xScheduleName"
											name="xScheduleName"
											className={`input input--med ${!isScheduleNameValid && 'is-invalid'}`}
											placeholder="Schedule name"
											value={template.xScheduleName || ''}
											onChange={this.onChange}
											ref={this.scheduleNameRef}
											autoFocus={true}
										/>
									</div>
								</div>
							</div>
							<div className="col col-sml-12 col-med-6">
								<div className="newtransaction__item">
									<label htmlFor="amount" className="newtransaction__item__label">
										Amount
									</label>
									<div className="newtransaction__item__value">
										<NumberFormat
											className="input input--med"
											prefix={this.currencyCode}
											value={template.amount || ''}
											inputMode="decimal"
											onValueChange={this.handleAmountChange}
											name="amount"
											id="amount"
											placeholder={`${this.currencyCode}0`}
											thousandSeparator=","
											decimalSeparator="."
											allowNegative={false}
											decimalScale={2}
											getInputRef={el => (this.amountRef = el)}
										/>
									</div>
								</div>
							</div>
							<div className="col col-sml-12 col-med-6">
								<div className="newtransaction__item">
									<label htmlFor="xIntervalCount" className="newtransaction__item__label">
										<span className="spc--right--sml">Frequency</span>
										<span data-tooltip="Interval type">
											<i className="icon icon--tiny icon--info align--v--middle hide--to--lrg--inline-block"></i>
										</span>
									</label>
									<div className="newtransaction__item__value">
										<div className="flex--primary flex--nowrap">
											<div className="spc--right--tny">
												<div className="type--color--text--regular type--wgt--medium">EVERY</div>
											</div>
											<div className="flex--grow--1 spc--right--tny">
												<input
													type="number"
													min="1"
													step="1"
													id="xIntervalCount"
													name="xIntervalCount"
													className="input input--med"
													placeholder="0"
													value={template.xIntervalCount || ''}
													onChange={this.onChange}
												/>
											</div>
											<div className="flex--no-shrink">
												<select
													id="xIntervalType"
													name="xIntervalType"
													className="input input--select input--med"
													value={toLower(template.xIntervalType) || ''}
													onChange={this.onChange}
												>
													<option value="day">Day(s)</option>
													<option value="week">Week(s)</option>
													<option value="month">Month(s)</option>
													<option value="year">Year(s)</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col col-sml-12 col-med-6">
								<div className="newtransaction__item">
									<label htmlFor="xTotalPayments" className="newtransaction__item__label type--none">
										Number of Payments
									</label>
									<div className="newtransaction__item__value">
										<NumberFormat
											id="xTotalPayments"
											value={template.xTotalPayments || ''}
											onValueChange={this.handleNumberOfPaymentsChange}
											format="###"
											placeholder="Indefinite"
											className="input input--med"
											name="xTotalPayments"
											inputMode="numeric"
										/>
									</div>
								</div>
							</div>
							<div className="col col-sml-12">
								<div className="newtransaction__item">
									<label htmlFor="xDescription" className="newtransaction__item__label">
										Description
									</label>
									<div className="newtransaction__item__value">
										<input
											type="text"
											id="xDescription"
											name="xDescription"
											className="input input--med"
											placeholder="Description"
											value={template.xDescription || ''}
											onChange={this.onChange}
										/>
									</div>
								</div>
							</div>
							<div className="col col-sml-12">
								<div className="newtransaction__item">
									<div className="newtransaction__item__value">
										<div className="spc--top--tny">
											<input
												type="checkbox"
												id="xSkipSab"
												name="xSkipSab"
												className="input input--check"
												value={template.xSkipSab || ''}
												checked={!!template.xSkipSab}
												onChange={this.onChange}
											/>
											<label htmlFor="xSkipSab">Skip Sabbath and Holidays</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
				<div className="popup__footer popup__footer--styled">
					<button
						disabled={isLoading}
						type="button"
						tabIndex="-1"
						className="btn btn--sml btn--ghost spc--right--xsml"
						onClick={this.handleClose}
					>
						Cancel
					</button>
					<button
						disabled={isLoading}
						type="button"
						tabIndex="-1"
						className="btn btn--sml btn--primary"
						onClick={this.handleSave}
					>
						Save
					</button>
				</div>
			</React.Fragment>
		);
	};
}

AddEditTemplateForm.propTypes = {
	template: PropTypes.object.isRequired,
	saveTemplate: PropTypes.func.isRequired,
	handleCloseModal: PropTypes.func.isRequired,
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	type: PropTypes.any,
};

export default withCancelable(withError(AddEditTemplateForm));
