import React from 'react';
import { noop } from 'lodash';

import { StringFilter } from 'common/components/columns/filters';
import { ColumnHeader } from 'common/components/columns/headers';

export default function createGridColumn(
	key,
	name,
	initWidth,
	{
		hideable = true,
		visible = false,
		resizable = true,
		showOnMobile = false,
		order = undefined,
		fieldKey = '',
		formatter = null,
		isDefaultSorter = false,
		defaultSortDirection = '',
		customSettingsKey = '',
		exportKey = '',
		hideOnPrint = false,
		filterRenderer = StringFilter,
		headerRenderer = <ColumnHeader />,
		getRowMetaData = noop,
		dependentExportKey = '',
		alignHeaderRight = false,
		sortable = true,
		filterable = true,
		ignoreOnfetch = false,
		hideOnExport = false,
		isAdminColumn = false,
		...rest
	}
) {
	return {
		key,
		name,
		hideable,
		visible,
		resizable,
		showOnMobile,
		order,
		fieldKey,
		initWidth,
		formatter,
		isDefaultSorter,
		defaultSortDirection,
		customSettingsKey,
		exportKey,
		hideOnPrint,
		filterRenderer,
		headerRenderer,
		getRowMetaData,
		dependentExportKey,
		alignHeaderRight,
		sortable,
		filterable,
		ignoreOnfetch,
		hideOnExport,
		isAdminColumn,
		...rest,
	};
}
