import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, filter as filterMethod } from 'lodash';

import { mapAvsItem } from 'common/utilities';

class ExpandFilterContainerComponent extends Component {
	constructor(props) {
		super(props);
		this.onFilterChanged = this.onFilterChanged.bind(this);

		this.state = {
			value: null,
			hasValue: false,
		};
	}

	get cardNames() {
		return {
			visa: 'Visa',
			mastercard: 'Mastercard',
			amex: 'American Express',
			discover: 'Discover',
			jcb: 'JCB',
			diners: 'Diners',
			other: 'Other',
		};
	}

	componentDidUpdate = prevProps => {
		if (prevProps.filter !== this.props.filter) {
			let hasValue = false;
			let value = null;
			if (this.props.filter.hasSelection) {
				hasValue = true;
				value = this.parseDisplayValue({
					id: this.props.filter.key,
					values: map(this.props.filter.values, (value, key) => ({
						key,
						value,
					})),
				});
			} else {
				hasValue = false;
				value = null;
			}
			this.setState({
				hasValue,
				value,
			});
		}
	};

	onFilterChanged = filter => {
		this.props.onFilterChanged(filter);

		let hasValue = false;
		let value = null;
		if (this.props.filter.hasSelection) {
			hasValue = true;
			value = this.parseDisplayValue(filter);
		} else {
			hasValue = false;
			value = null;
		}

		this.setState({
			hasValue: hasValue,
			value: value,
		});
	};

	parseDisplayValue = filter => {
		if (filter.id === 'cvv') {
			if (filter.values[0].key === 'none') {
				return 'None';
			}
			return filter.values[0].key === 'match' ? 'Match' : 'No match';
		} else if (filter.id === 'avs') {
			return mapAvsItem(filter.values[0].key).text;
		} else if (filter.id === 'cardType') {
			return map(filterMethod(filter.values, item => item.value), item => this.cardNames[item.key]).join(', ');
		} else if (filter.id === 'custom') {
			return map(filterMethod(filter.values, item => item.value), item => item.value).join(', ');
		} else {
			return filter.values[0].value;
		}
	};

	onFilterClear = key => {
		const { filter } = this.props;
		this.setState(
			{
				hasValue: false,
				value: filter.defaultValues[key],
			},
			() => this.props.onFilterClear(key)
		);
	};

	showEditable = () => {
		const { onShowEditable, filterKey } = this.props;

		onShowEditable(filterKey);
	};

	render() {
		const { label, component, filter, filterKey, editableFilter } = this.props;
		const { value, hasValue } = this.state;

		const isEditable = editableFilter === filterKey;

		let labelClasses = ['reports__expanded__item type--capitalize'];
		if (hasValue) {
			labelClasses.push('is-filled');
		}
		if (isEditable) {
			labelClasses.push('is-active');
		}

		return (
			<div className="pos--rel spc--bottom--lrg">
				<div className={labelClasses.join(' ')} onClick={this.showEditable}>
					{label}
				</div>
				{isEditable ? (
					React.cloneElement(component, { filter: filter, onFilterChanged: this.onFilterChanged })
				) : hasValue ? (
					<div className="reports__expanded__item__value">{value}</div>
				) : null}
				{!isEditable && hasValue ? (
					<div className="reports__expanded__item__reset">
						<button className="btn btn--reset--background" onClick={() => this.onFilterClear(filterKey)}>
							<i className="icon icon--micro icon--close-big align--v--middle"></i>
						</button>
					</div>
				) : null}
			</div>
		);
	}
}

ExpandFilterContainerComponent.propTypes = {
	label: PropTypes.string.isRequired,
	filterKey: PropTypes.string.isRequired,
	component: PropTypes.object,
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
	onFilterClear: PropTypes.func.isRequired,
	editableFilter: PropTypes.string,
	onShowEditable: PropTypes.func.isRequired,
};

export default ExpandFilterContainerComponent;
