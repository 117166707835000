import React, { Component, Fragment, createRef } from 'react';
import { findDOMNode, createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import {
	merge,
	memoize,
	identity,
	cloneDeep,
	map,
	sum,
	each,
	filter,
	toLower,
	orderBy,
	find,
	findIndex,
	findKey,
	clone,
	constant,
	noop,
	some,
	isEmpty,
	has,
	trim,
	isString,
	debounce,
	isArray,
	split,
	last,
	includes,
	transform,
	concat,
	get,
	upperFirst,
	uniqBy,
	isNumber,
	reverse,
} from 'lodash';

import EmptyGridComponent from './empty-grid';
import TitleComponent from './title';
import ColumnFilterComponent from './column-filter';
import ToolbarComponent from './toolbar';
import ColumnFilterHeader from './column-filter-header';
import ColumnFilterFooter from './column-filter-footer';
import GridFooter from './grid-footer';
import { validInlineFilterValues } from './valid-inline-filter-values';
import { modalNames } from '../transaction-actions/modal-names';
import { UserAccountPanel } from '../user-account-panel';
import { ExportComponent } from '../export';
import { isComponent, ResizeSensor, screenSize } from '../../utilities';
import { PrintGridButton } from '../print-grid-data';
import { ReactDataGrid } from '../react-data-grid';

const defaultComponents = {
	emptyGrid: EmptyGridComponent,
	header: constant(null),
	gridHeader: constant(null),
	modal: constant(null),
	filter: constant(null),
	gridFooter: constant(null),
	title: TitleComponent,
	headerDisclaimer: constant(null),
	tooltip: constant(null),
	columnFilterHeader: ColumnFilterHeader,
	columnFilterFooter: ColumnFilterFooter,
	actionsRenderer: null,
	selectedEntriesActions: constant(null),
	selectAllRowsCheckbox: constant(null),
};

const defaultClasses = {
	content: 'l--content l--content--grid',
	wrapper: '',
	header: 'header header__btn__holder',
	headerMenu: 'header__menu',
	headerMenuAction: 'header__menu__action',
	headerGroup: 'flex--primary',
	title: 'header__title type--wgt--medium',
	print: 'reportprint__table',
	printWrapper: 'filter__print',
	exportWrapper: 'filter__export pos--rel datatooltip--v--bottom',
	gridHolder: 'grid__holder',
	gridWrapper: 'grid__holder--override',
	filter: '',
	filterContainer: 'filter__container',
	selectedEntriesActionsWrapper: '',
	selectAllRowsCheckboxWrapper: '',
	gridHeader: 'flex--primary datatooltip--v--bottom',
};

const getInlineFilterValues = filterId => {
	return get(validInlineFilterValues, filterId);
};

const showFilterThreshold = 990;
const horizontalScrollHeight = 8;
const minColumnWidth = 75;
const filterMargin = 24;

class Grid extends Component {
	memoizedFilterValues = new WeakMap();
	constructor(props) {
		super(props);

		this.state = {
			hideActions: false,
			showFilters: window.innerWidth > showFilterThreshold,
			modal: {
				name: modalNames.none,
				data: null,
			},
			showSelection: true,
			displayHeaderMenu: window.innerWidth < props.headerMenuThreshold,
			isExtraSmallScreen: window.innerWidth < screenSize.sml,
			isExtraLargeScreen: window.innerWidth >= screenSize.xxxlrg,
			isLargeScreen: window.innerWidth >= screenSize.xlrg,
			columnFilterState: {
				columns: cloneDeep(props.columns),
				activeKeys: [],
			},
			isExporting: null,
			screenHeight: window.innerHeight,
		};

		this.contentRef = createRef();
		this.gridHolderRef = createRef();
		this.filtersContainerRef = createRef();
		this.gridFooterRef = createRef();
		this.mainFilterRef = createRef();
		this.gridRef = createRef();
		this.userAccountRef = createRef();
		this.filterSelectionRef = createRef();
		this.printGridButtonRef = createRef();
		this.exportGridButtonRef = createRef();
		this.columnFilterRef = createRef();
		this.columnFilterPortalRef = createRef();

		this.oldVisibleColumns = [];
	}

	get scrollBarWidth() {
		// Creating invisible container
		const outer = document.createElement('div');
		outer.style.visibility = 'hidden';
		outer.style.overflow = 'scroll'; // forcing scrollbar to appear
		outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
		document.body.appendChild(outer);

		// Creating inner element and placing it in the container
		const inner = document.createElement('div');
		outer.appendChild(inner);

		// Calculating difference between container's full width and the child width
		const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

		// Removing temporary elements from the DOM
		outer.parentNode.removeChild(outer);

		return scrollbarWidth;
	}

	get components() {
		return this.mergeComponents(this.props.components);
	}

	get classes() {
		return this.mergeClasses(this.props.classes);
	}

	get scroll() {
		const elem =
			get(this.gridRef, 'current.base.viewport.canvas.canvas') || get(this.gridRef, 'current.base.emptyView');
		if (!elem) {
			return;
		}
		return {
			top: elem.scrollTop,
			left: elem.scrollLeft,
		};
	}

	get headerScroll() {
		const header = get(this.gridRef, 'current.base.header.row');
		if (!header) {
			return;
		}
		//eslint-disable-next-line
		const elem = findDOMNode(header);
		if (!elem) {
			return;
		}
		return {
			top: elem.scrollTop,
			left: elem.scrollLeft,
		};
	}

	componentDidMount = () => {
		const { initialFetch, fetchData } = this.props;
		this.showOrHideFilters();
		this.calculateColumnWidths();
		if (initialFetch) {
			fetchData();
		}
		window.addEventListener('resize', this.setGridSizes);
		this.setGridSizes();
		if (this.contentRef.current) {
			this.oldWidth = window.innerWidth;
			ResizeSensor(this.contentRef.current, async () => {
				this.showOrHideFilters();
				clearTimeout(this.timeout);
				this.timeout = setTimeout(this.calculateColumnWidths, 50);
				this.oldWidth = window.innerWidth;
			});
		}
	};

	componentDidUpdate = prevProps => {
		const { filteredRows: oldRows } = prevProps;
		const { filteredRows: newRows } = this.props;
		if (oldRows !== newRows) {
			const filterRow = get(this.gridRef, 'current.base.header.filterRow');
			if (filterRow) {
				filterRow.forceUpdate();
				//eslint-disable-next-line
				const node = findDOMNode(filterRow);
				if (node) {
					node.removeEventListener('scroll', this.syncScrollsLeft);
					node.addEventListener('scroll', this.syncScrollsLeft);
				}
			}
			const emptyView = get(this.gridRef, 'current.base.emptyView');
			if (emptyView) {
				emptyView.removeEventListener('scroll', this.syncHeaderScrolls);
				emptyView.addEventListener('scroll', this.syncHeaderScrolls);
			}

			const changedEmptiness = (isEmpty(oldRows) && !isEmpty(newRows)) || (isEmpty(newRows) && !isEmpty(oldRows));
			const base = get(this.gridRef, 'current.base');
			if (changedEmptiness && base) {
				base._scrollLeft = 0;
				base._onScroll();
			}
		}
	};

	componentWillUnmount() {
		window.removeEventListener('resize', this.setGridSizes);
	}

	shouldComponentUpdate = (nextProps, nextState) => {
		if (
			nextProps.fetchingData !== this.props.fetchingData ||
			nextProps.fetchingAdditionalData !== this.props.fetchingAdditionalData ||
			nextProps.filteredRows !== this.props.filteredRows ||
			nextProps.columns !== this.props.columns ||
			nextProps.data !== this.props.data ||
			nextProps.inlineFilters !== this.props.inlineFilters ||
			nextProps.expanded !== this.props.expanded ||
			nextProps.lastApiRefNum !== this.props.lastApiRefNum ||
			nextProps.type !== this.props.type ||
			nextProps.filters !== this.props.filters ||
			nextProps.tooltipProps !== this.props.tooltipProps ||
			nextState.showFilters !== this.state.showFilters ||
			nextState.modal !== this.state.modal ||
			nextState.additionalPixel !== this.state.additionalPixel ||
			nextState.showSelection !== this.state.showSelection ||
			nextState.columnFilterState !== this.state.columnFilterState ||
			nextState.isExporting !== this.state.isExporting ||
			nextState.screenHeight !== this.state.screenHeight
		) {
			return true;
		}
		return false;
	};

	setGridSizes = () => {
		const { displayHeaderMenu, isExtraSmallScreen, isExtraLargeScreen, isLargeScreen, screenHeight } = this.state;
		const { headerMenuThreshold, ignoreHeaderMenuTreshold } = this.props;
		let anyChanged = false;
		const newState = {};
		const updatedDisplayHeaderMenu = !ignoreHeaderMenuTreshold && window.innerWidth < headerMenuThreshold;
		if (updatedDisplayHeaderMenu !== displayHeaderMenu && !ignoreHeaderMenuTreshold) {
			newState.displayHeaderMenu = updatedDisplayHeaderMenu;
			anyChanged = true;
		}
		const updatedIsExtraLargeScreen = window.innerWidth >= screenSize.xxxlrg;
		if (updatedIsExtraLargeScreen !== isExtraLargeScreen) {
			newState.isExtraLargeScreen = updatedIsExtraLargeScreen;
			anyChanged = true;
		}
		const updatedIsLargeScreen = window.innerWidth >= screenSize.xlrg;
		if (updatedIsLargeScreen !== isLargeScreen) {
			newState.isLargeScreen = updatedIsLargeScreen;
			anyChanged = true;
		}
		const updatedIsExtraSmallScreen = window.innerWidth < screenSize.sml;
		if (updatedIsExtraSmallScreen !== isExtraSmallScreen) {
			newState.isExtraSmallScreen = updatedIsExtraSmallScreen;
			anyChanged = true;
		}
		if (window.innerHeight !== screenHeight) {
			newState.screenHeight = window.innerHeight;
			anyChanged = true;
		}
		if (anyChanged) {
			this.setState(newState);
		}
	};

	syncScrollsTop = e => {
		if (!this.isSyncingGridScroll) {
			this.isSyncingActionsScroll = true;
			const scrollTop = get(e, 'target.scrollTop', 0);
			const currentScroll = this.scroll;
			if (currentScroll && scrollTop !== currentScroll.top) {
				this.scrollTo({ top: scrollTop });
			}
		}
		this.isSyncingGridScroll = false;
	};

	syncScrollsLeft = debounce(e => {
		const scrollLeft = get(e, 'target.scrollLeft', 0);
		const currentScroll = this.scroll;
		if (currentScroll && scrollLeft !== currentScroll.left) {
			this.scrollTo({ left: scrollLeft });
		}
	}, 25);

	syncHeaderScrolls = debounce(({ target: { scrollLeft } }) => {
		const currentScroll = this.headerScroll;
		if (currentScroll && scrollLeft !== currentScroll.left) {
			this.scrollHeader({ left: scrollLeft });
		}
	}, 25);

	showOrHideFilters = () => {
		const { showFilters } = this.state;
		let shouldChange = false;

		if (
			(window.innerWidth > showFilterThreshold && this.oldWidth <= showFilterThreshold && !showFilters) ||
			(window.innerWidth <= showFilterThreshold && this.oldWidth > showFilterThreshold && showFilters)
		) {
			shouldChange = true;
		}

		return new Promise(resolve => {
			if (shouldChange) {
				this.setState({ showFilters: !showFilters }, resolve);
			} else {
				resolve();
			}
		});
	};

	clearFilters = async () => {
		if (this.props.useInlineFilters) {
			await this.props.onChange([
				{
					key: 'inlineFilters',
					value: {},
				},
			]);
			this.updateToolbar();
		}
		if (this.mainFilterRef.current) {
			this.mainFilterRef.current.resetFilter();
		}
	};

	updateToolbar = callback => {
		// workaround because react-data-grid doesn't have a way of resetting the filters except by toggling the filtering ability altogether
		if (this.gridRef.current && this.props.useInlineFilters) {
			this.gridRef.current.setState({ canFilter: false }, () => {
				this.gridRef.current.setState({ canFilter: true }, callback);
			});
		}
	};

	updateColumnFilterState = newState => {
		this.setState({
			settingsOpen: !isEmpty(newState.activeKeys),
			columnFilterState: {
				...this.state.columnFilterState,
				...newState,
			},
		});
	};

	getVisibleColumnWidth = displayedColumns => {
		return (
			sum(
				map(displayedColumns, ({ resizedWidth, fixWidth, initWidth }) => (!resizedWidth && !fixWidth ? initWidth : 0))
			) || 0
		);
	};
	getResizedColumnWidths = displayedColumns => {
		return (
			sum(
				map(displayedColumns, ({ resizedWidth, fixWidth, initWidth }) => resizedWidth || (fixWidth && initWidth) || 0)
			) || 0
		);
	};

	handleWidthToFill = (widthToFill, visibleColumnWidths, displayedColumns, lastColumn) => {
		if (widthToFill > visibleColumnWidths) {
			each(displayedColumns, column => {
				if (!column.resizedWidth) {
					if (column === lastColumn || column.fixWidth) {
						column.width = column.initWidth;
					} else {
						const factor = column.initWidth / visibleColumnWidths;
						column.width = parseInt(factor * widthToFill) - 2;
					}
				} else {
					column.width = column.resizedWidth;
				}
			});
		} else {
			each(displayedColumns, column => {
				column.width = column.resizedWidth || column.initWidth;
			});
		}
	};
	calculateColumnWidths = async (_, updatedColumns, isRecursedCall = false) => {
		const columns = cloneDeep(updatedColumns || this.props.columns);
		const displayedColumns = this.getDisplayedColumns(columns);
		const lastColumn = last(filter(displayedColumns, ({ fixWidth }) => !fixWidth));
		const visibleColumnWidths = this.getVisibleColumnWidth(displayedColumns);
		const resizedColumnWidths = this.getResizedColumnWidths(displayedColumns);

		if (!this.gridRef.current || !this.gridRef.current.grid) {
			return;
		}
		each(columns, column => {
			delete column.beforeStretchWidth;
		});
		const verticalScrollbarWidth = this.scrollBarWidth + 3;
		const widthToFill = this.gridRef.current.grid.clientWidth - verticalScrollbarWidth - resizedColumnWidths;
		this.handleWidthToFill(widthToFill, visibleColumnWidths, displayedColumns, lastColumn);
		const actualColumnWidth = sum(map(displayedColumns, c => c.width || 0)) || 0;
		const actualWidthToFill = this.gridRef.current.grid.clientWidth - verticalScrollbarWidth;
		if (actualColumnWidth < actualWidthToFill) {
			lastColumn.beforeStretchWidth = lastColumn.width;
			lastColumn.width += actualWidthToFill - actualColumnWidth;
		}
		const changes = [
			{
				key: 'columns',
				value: columns,
			},
		];
		const inlineFilters = clone(this.props.inlineFilters);
		let anyChanged = false;
		each(inlineFilters, (_, key) => {
			const column = find(columns, { key });
			if (!column || !column.visible) {
				delete inlineFilters[key];
				anyChanged = true;
			}
		});
		if (anyChanged) {
			changes.push({
				key: 'inlineFilters',
				value: inlineFilters,
			});
		}
		await this.props.onChange(changes);
		setTimeout(() => {
			if (
				!isRecursedCall &&
				this.gridRef.current &&
				this.gridRef.current.grid &&
				this.gridRef.current.grid.clientWidth !== actualWidthToFill + verticalScrollbarWidth
			) {
				this.calculateColumnWidths(_, updatedColumns, true);
			}
		});
		this.updateViewport();
	};

	calculateGridWidth = ref => {
		const { customWidth } = this.props;
		if (customWidth) {
			return customWidth;
		}
		let lContentWidth = 0;

		if (!ref) {
			return lContentWidth;
		}

		lContentWidth = Math.round(ref.clientWidth) || 0;

		const styles = window.getComputedStyle(ref, null);
		if (!styles) {
			return lContentWidth;
		}

		const paddingLeft = parseInt(styles.getPropertyValue('padding-left'), 10);
		const paddingRight = parseInt(styles.getPropertyValue('padding-right'), 10);

		if (!isNaN(paddingLeft)) {
			lContentWidth -= paddingLeft;
		}

		if (!isNaN(paddingRight)) {
			lContentWidth -= paddingRight;
		}

		return lContentWidth;
	};

	calculateGridHeight = () => {
		if (this.props && this.props.fetchingData) return '';
		try {
			const minHeight = 450;
			let lContentTop = 0;
			if (this.contentRef.current) {
				lContentTop = Math.round(this.contentRef.current.getBoundingClientRect().top) || 0;
				const styles = window.getComputedStyle(this.contentRef.current, null);
				if (styles) {
					const paddingTop = styles.getPropertyValue('padding-top');
					const paddingBottom = styles.getPropertyValue('padding-bottom');
					if (paddingTop) {
						const [value] = split(paddingTop, 'px');
						if (value) {
							lContentTop += parseInt(value);
						}
					}
					if (paddingBottom) {
						const [value] = split(paddingBottom, 'px');
						if (value) {
							lContentTop += parseInt(value);
						}
					}
				}
			}
			const filtersHeight = this.filtersContainerRef.current
				? Math.round(this.filtersContainerRef.current.getBoundingClientRect().height) || 0
				: 0;
			let gridFooterHeight = this.gridFooterRef.current
				? this.gridFooterRef.current.getBoundingClientRect().height + filterMargin
				: 0;
			if (this.gridFooterRef.current) {
				const styles = window.getComputedStyle(this.gridFooterRef.current, null);
				if (styles) {
					const paddingTop = styles.getPropertyValue('padding-top');
					const paddingBottom = styles.getPropertyValue('padding-bottom');
					if (paddingTop) {
						const [value] = split(paddingTop, 'px');
						if (value) {
							gridFooterHeight += parseInt(value);
						}
					}
					if (paddingBottom) {
						const [value] = split(paddingBottom, 'px');
						if (value) {
							gridFooterHeight += parseInt(value);
						}
					}
				}
			}
			const footer = document.querySelector('footer');
			const footerHeight = (footer && footer.getBoundingClientRect().height) || 0;
			const additionalPixel = this.state.additionalPixel ? 1 : 0;
			const total =
				window.innerHeight -
				(lContentTop + filtersHeight + gridFooterHeight + footerHeight + horizontalScrollHeight + additionalPixel - 32);
			return Math.max(minHeight, total);
		} catch (e) {
			//intentionally empty catch block
		}
		return 'auto';
	};

	reset = () => {
		this.syncFilters({ isReset: true });
		this.showOrHideFilters();
		this.calculateColumnWidths();
	};

	mergeComponents = memoize(components => merge({}, defaultComponents, components));

	mergeClasses = memoize(classes => merge({}, defaultClasses, classes));

	getDisplayedColumns = memoize(columns => filter(columns, col => col.visible));

	getDetailsRowIndex = () => {
		const { expanded, filteredRows, expandInSidebar } = this.props;
		if (expandInSidebar) {
			return -1;
		}
		const expandedKey = findKey(expanded, Boolean);
		if (expandedKey == null) {
			return -1;
		}
		return findIndex(filteredRows, ({ index }) => index === parseInt(expandedKey));
	};

	rowGetter = i => this.props.filteredRows[i];
	getIsUndefinedParam = (a, b) => {
		if (a === undefined) {
			return b === undefined ? 0 : -1;
		}
		if (b === undefined) {
			return 1;
		}
	};
	handleSort = (a, b) => {
		const isUndefinedParam = this.getIsUndefinedParam(a, b);
		if (isNumber(isUndefinedParam)) return isUndefinedParam;
		const aNumber = Number(a);
		const bNumber = Number(b);
		if (!isNaN(aNumber) && !isNaN(bNumber)) {
			return aNumber - bNumber;
		}
		if (typeof a === 'string' && typeof b === 'string') {
			const aLower = toLower(a.trim());
			const bLower = toLower(b.trim());
			if (aLower === bLower) {
				return 0;
			}
			return aLower < bLower ? -1 : 1;
		}
		if (typeof a === 'string' || typeof a === 'string') {
			return typeof a === 'string' ? -1 : 1;
		}
		if (a == b) {
			return 0;
		}
		return a < b ? -1 : 1;
	};

	onGridSort = (column, sortDirection) => {
		let data = cloneDeep(this.props.data);
		if (get(data, 'xReportData.length', 0)) {
			if (sortDirection === 'NONE') {
				data.xReportData = orderBy(data.xReportData, [item => item.index], ['asc']);
			} else {
				const sortKey = this.props.resolveColumnName(column);
				const direction = this.props.resolveSortDirection(sortDirection, column);
				data.xReportData = data.xReportData.sort(({ [sortKey]: a }, { [sortKey]: b }) => this.handleSort(a, b));
				if (toLower(direction) === 'desc') {
					reverse(data.xReportData);
				}
			}
			each(data.xReportData, (item, index) => {
				item.gridRowNumber = index;
			});
		}

		const columns = cloneDeep(this.props.columns);
		each(columns, function(item) {
			if (item.key === column) {
				item.sortDirection = sortDirection;
			} else {
				item.sortDirection = null;
			}
		});
		this.props.onChange([
			{
				key: 'columns',
				value: columns,
			},
			{
				key: 'data',
				value: data,
			},
		]);
	};

	onRowClick = (...params) => {
		if (window.getSelection().toString() && params[2]) return;
		if (this.props.onRowClick) {
			this.props.onRowClick(...params);
			return;
		}
		const row = params[1];
		if (!this.props.isExpandable || (row && !row.isExpandable)) {
			return;
		}
		const args = this.props.mapCellArgs(...params);
		if (args) {
			this.onCellExpand(args);
		}
	};

	onCellExpand = args => {
		const rows = this.props.data.xReportData.slice(0);
		const rowKey = args.rowData.index;
		let rowIndex = findIndex(rows, row => row.index === rowKey);

		const expanded = clone(this.props.expanded);

		if (expanded && !expanded[rowKey]) {
			const expandedItem = find(rows, r => r.isDetails);
			if (expandedItem) {
				const expandedItemRowKey = expandedItem.index;
				const expandedItemRowIndex = rows.indexOf(expandedItem);
				expanded[expandedItemRowKey] = false;
				rows[expandedItemRowIndex] = clone(expandedItem);
				rows[expandedItemRowIndex].isDetails = false;
				delete rows[expandedItemRowIndex].expandedRowProps;
			}
			expanded[rowKey] = true;
		} else if (expanded[rowKey] && !args.openOnly) {
			expanded[rowKey] = false;
		}
		rows[rowIndex] = clone(rows[rowIndex]);
		rows[rowIndex].isDetails = expanded[rowKey];
		if (expanded[rowKey]) {
			rows[rowIndex].expandedRowProps = args.expandedRowProps || {};
		} else {
			delete rows[rowIndex].expandedRowProps;
		}

		const newData = clone(this.props.data);
		newData.xReportData = rows;
		this.props.onChange([
			{
				key: 'expanded',
				value: expanded,
			},
			{
				key: 'data',
				value: newData,
			},
		]);
	};

	onColumnResize = async (index, newWidth) => {
		const columns = cloneDeep(this.props.columns);
		const displayedColumns = this.getDisplayedColumns(columns);
		const column = displayedColumns[index];
		if (!column) {
			return;
		}
		const stretchedColumns = filter(columns, c => c.beforeStretchWidth);
		each(stretchedColumns, stretchedColumn => {
			stretchedColumn.width = stretchedColumn.beforeStretchWidth;
			delete stretchedColumn.beforeStretchWidth;
		});
		column.width = Math.max(newWidth, minColumnWidth);
		column.resizedWidth = column.width;
		const visibleColumnWidths = sum(map(displayedColumns, c => c.width || 0)) || 0;
		const widthToFill = this.gridRef.current.grid.clientWidth;
		if (visibleColumnWidths < widthToFill) {
			const lastColumn = last(filter(displayedColumns, ({ fixWidth }) => !fixWidth));
			lastColumn.beforeStretchWidth = lastColumn.width;
			lastColumn.width += widthToFill - visibleColumnWidths - 12;
		}

		await this.props.onChange([
			{
				key: 'columns',
				value: columns,
			},
		]);
		this.updateViewport();
	};

	updateViewport = () => {
		this.setState(
			{
				additionalPixel: true,
			},
			() => {
				this.setState({
					additionalPixel: false,
				});
			}
		);
		const currentScroll = this.scroll;
		if (currentScroll) {
			this.scrollTo({ top: currentScroll.top - 1 });
			this.scrollTo({ top: currentScroll.top + 1 });
			this.scrollTo({ top: currentScroll.top });
		}
	};

	togglePrintExportTooltip = (expanded, buttonRef) => {
		if (this[buttonRef] && this[buttonRef].current) {
			this[buttonRef].current.setState({
				hideTooltip: expanded,
			});
			this.columnFilterRef.current &&
				this.columnFilterRef.current.setState({
					hideTooltip: expanded,
				});
		}
	};

	handleInitialSort = () => {
		let selectedColumn = find(this.props.columns, column => column.sortDirection);
		if (!selectedColumn) {
			selectedColumn = find(this.props.columns, column => column.isDefaultSorter);
		}
		if (this.gridRef && this.gridRef.current && selectedColumn) {
			if (selectedColumn.isDefaultSorter) {
				this.gridRef.current.handleSort(
					selectedColumn.key,
					selectedColumn.sortDirection || selectedColumn.defaultSortDirection
				);
			} else {
				this.gridRef.current.handleSort(selectedColumn.key, selectedColumn.sortDirection || 'ASC');
			}
		}
	};

	handleInlineFilter = async filter => {
		this.setState({ isFiltering: true });
		if (filter) {
			filter.hasMoreData =
				!isEmpty(get(this.props.data, 'xReportData', {})) &&
				this.props.hasPaging &&
				this.props.hasMoreData(this.props.data);
		}
		const newFilters = cloneDeep(this.props.inlineFilters);
		if (filter.filterTerm) {
			newFilters[filter.column.key] = filter;
		} else {
			delete newFilters[filter.column.key];
		}
		await this.props.onChange([
			{
				key: 'inlineFilters',
				value: newFilters,
			},
		]);
		this.scrollTo({ top: 0 });
		this.updateViewport();
		this.setState({ isFiltering: false });
	};

	generateInlineFilterOptions = (filterId, rows) => {
		const options = [];
		each(rows, row => {
			const label = row[filterId];
			const existsOption = find(options, ({ key }) => key === label);
			if (!existsOption) {
				options.push({
					label,
					key: label,
				});
			}
		});
		return options;
	};

	generateUniqueInlineFilterOptions = (rows, cid) => {
		const options = {};
		each(rows, row => {
			if (row[cid] && !options[row[cid]]) {
				options[row[cid]] = row[cid];
			}
		});
		return options;
	};

	getValidInlineFilterValues = (cid, filterId = cid) => {
		const { data, columns } = this.props;
		const memoizedData = this.memoizedFilterValues.get(data);
		if (memoizedData && memoizedData[cid]) return memoizedData[cid];
		const column = find(columns, { key: filterId });
		const generateInlineFilterOptions = get(column, 'generateInlineFilterOptions', false);

		const rows = data ? data.xReportData : [];

		if (generateInlineFilterOptions) return this.generateInlineFilterOptions(filterId, rows);
		const uniqueOptions = this.generateUniqueInlineFilterOptions(rows, cid);
		const values = this.props.getInlineFilterValues(filterId);
		if (values) {
			const inlineFilterValues = uniqBy(
				map(uniqueOptions, value => {
					const predefinedKeyValue = find(
						values,
						option => toLower(option.key) === toLower(value) || find(option.key, k => k == value)
					);
					if (isArray(get(predefinedKeyValue, 'key'))) {
						predefinedKeyValue.key = last(predefinedKeyValue.key);
					}
					return predefinedKeyValue || { key: value, label: `${upperFirst(value)}` };
				}),
				'key'
			);
			let mappedInlineFilterValues = [];

			mappedInlineFilterValues = transform(inlineFilterValues, (acc, value, index) => {
				const item = find(acc, ({ label }) => label === value.label);
				if (item && !includes(item.key, value.key)) {
					if (!isArray(item.key)) {
						item.key = [item.key, value.key];
					} else {
						item.key.push(value.key);
					}
				} else if (!item) {
					acc[index] = value;
				}
			});
			if (!data) return mappedInlineFilterValues;
			if (memoizedData) {
				this.memoizedFilterValues.set(data, { ...memoizedData, [cid]: mappedInlineFilterValues });
			} else {
				this.memoizedFilterValues.set(data, { [cid]: mappedInlineFilterValues });
			}
			return mappedInlineFilterValues;
		} else {
			return ['Options error'];
		}
	};

	onFilterColumns = async (columns, updateDefaultColumns) => {
		this.oldVisibleColumns = concat(
			this.oldVisibleColumns,
			map(this.getDisplayedColumns(this.props.columns), ({ key }) => key)
		);
		const newColumns = map(this.props.columns, column => {
			const newColumn = find(columns, { key: column.key });
			if (newColumn.visible === column.visible) {
				return column;
			}
			return {
				...column,
				visible: newColumn.visible,
			};
		});
		await this.calculateColumnWidths(null, newColumns);
		const changes = [
			{
				key: 'data',
				value: cloneDeep(this.props.data),
			},
		];
		if (updateDefaultColumns) {
			changes.push({
				key: 'defaultColumns',
				value: newColumns,
			});
		}
		await this.props.onChange(changes);
		const newVisibleColumns = this.getDisplayedColumns(this.props.columns);
		this.props.onVisibleColumnsChange(this.oldVisibleColumns, newVisibleColumns);
	};

	mapColumnsForExport = (columns, isPrint) => {
		let filteredColumns = filter(
			columns,
			c => !c.hideOnExport && (!isPrint || !c.hideOnPrint) && (c.hideable || c.visible)
		);

		each(filteredColumns, (column, index) => {
			if (column.dependentExportKey) {
				let dependentColumnKeys = [];
				if (!Array.isArray(column.dependentExportKey)) {
					dependentColumnKeys.push(column.dependentExportKey);
				} else {
					dependentColumnKeys = column.dependentExportKey;
				}
				each(dependentColumnKeys, dependentColumnKey => {
					if (!find(filteredColumns, { key: dependentColumnKey })) {
						let dependentColumn = find(this.props.columns, {
							key: dependentColumnKey,
						});
						filteredColumns.splice(index + 1, 0, dependentColumn);
					}
				});
			}
		});

		filteredColumns = filter(filteredColumns, c => !c.hideOnExport && (!isPrint || !c.hideOnPrint));

		return map(
			filteredColumns,
			({
				key,
				exportKey,
				fieldKey,
				name,
				alignHeaderRight = false,
				alignLeftOnPrint = false,
				ignoreOnfetch = false,
			}) => ({
				key: exportKey || key,
				name,
				fieldKey,
				alignHeaderRight,
				alignLeftOnPrint,
				ignoreOnfetch,
			})
		);
	};

	showLoader = show => {
		this.props.onChange([
			{
				key: 'fetchingAdditionalData',
				value: show,
			},
		]);
	};

	showExportLoader = show => {
		this.setState({ isExporting: show });
		this.showLoader(show);
	};

	openCloseModal = (modalObj, ...rest) => {
		let state = {
			modal: modalObj,
		};
		this.setState(state);
		this.props.onModalToggle(modalObj, ...rest);
	};

	showHideFilters = () => {
		this.setState({ showFilters: !this.state.showFilters }, this.updateViewport);
	};

	showHideInlineFilters = () => {
		if (this.gridRef.current) {
			this.gridRef.current.setState({ canFilter: !this.gridRef.current.state.canFilter }, () => {
				this.props.onChange([
					{
						key: 'inlineFilters',
						value: {},
					},
				]);
				this.updateViewport();
			});
		}
	};

	showHideSelection = () => {
		this.setState({ showSelection: !this.state.showSelection }, this.calculateColumnWidths);
	};

	refreshGridData = () => {
		this.props.fetchData();
	};

	setRowDetailsRef = ref => {
		this.detailsRow = ref;
		this.calculateColumnWidths();
	};

	scrollTo = ({ top, left }) => {
		const elem =
			get(this.gridRef, 'current.base.viewport.canvas.canvas') || get(this.gridRef, 'current.base.emptyView');
		if (!elem) {
			return;
		}
		if (top !== undefined) {
			elem.scrollTop = top;
		}
		if (left !== undefined) {
			elem.scrollLeft = left;
		}
	};

	scrollHeader = ({ top, left }) => {
		each(['current.base.header.row', 'current.base.header.filterRow'], getter => {
			const row = get(this.gridRef, getter);
			if (!row) {
				return;
			}
			//eslint-disable-next-line
			const elem = findDOMNode(row);
			if (!elem) {
				return;
			}
			if (top !== undefined) {
				elem.scrollTop = top;
			}
			if (left !== undefined) {
				elem.scrollLeft = left;
			}
		});
	};

	trimValues = filter => {
		each(filter, ({ values }) => {
			each(values, (value, key) => {
				if (!!value && isString(value)) {
					values[key] = trim(value);
				}
			});
		});
	};

	updateFilters = async ({
		filters,
		activeFilters,
		forceRefresh = false,
		enableDateRange = false,
		standaloneFilter = null,
		isReset = false,
	}) => {
		const { syncQueryFilters, queryFilterValues, updateStandaloneFilter, fetchOnReset } = this.props;
		this.trimValues(activeFilters);
		this.trimValues(filters);

		const newFilters = [...filters];
		const newActiveFilters = [...activeFilters];

		const dateFilterIndex = findIndex(newFilters, i => i.key === 'date');
		const activeDateFilterIndex = findIndex(newActiveFilters, i => i.key === 'date');
		const canDisableDateFilter = dateFilterIndex > -1 && has(newFilters[dateFilterIndex], 'values.disabled');
		const shouldDateFilterEnable =
			canDisableDateFilter &&
			some(filters, item => item.allowsDateDisable && item.hasSelection) &&
			!some(filters, item => item.disallowsDateDisable && item.hasSelection);
		let loadData = false;
		const changes = [];
		if (shouldDateFilterEnable && enableDateRange) {
			newFilters[dateFilterIndex].values = {
				...newFilters[dateFilterIndex].values,
				disabled: false,
			};

			newActiveFilters[activeDateFilterIndex].values = {
				...newActiveFilters[activeDateFilterIndex].values,
				disabled: false,
			};

			changes.push({
				key: 'filters',
				value: newFilters,
			});
			changes.push({
				key: 'activeFilters',
				value: newActiveFilters,
			});
			loadData = true;
		} else {
			if (filters) {
				changes.push({
					key: 'filters',
					value: filters,
				});
			}
			if (activeFilters) {
				changes.push({
					key: 'activeFilters',
					value: activeFilters,
				});
				loadData = true;
			}
		}
		this.showOrHideFilters();
		await this.props.onChange(changes);
		if (syncQueryFilters) {
			queryFilterValues(changes[0].value);
		}
		if (standaloneFilter && updateStandaloneFilter) {
			updateStandaloneFilter(standaloneFilter);
		} else if ((loadData || forceRefresh) && (!isReset || fetchOnReset)) {
			this.syncFilters();
		}
	};

	syncFilters = ({ isReset } = {}) => {
		const { fetchData, fetchOnReset } = this.props;
		if (this.mainFilterRef.current) {
			this.mainFilterRef.current.syncFilters();
		}
		if (isReset && !fetchOnReset) {
			return;
		}
		fetchData();
	};

	resizeGrid = () => {
		this.forceUpdate();
	};

	renderResultsAndReference = () => {
		if (this.props && this.props.fetchingData) return;
		const { lastApiRefNum, showResults, filteredRows } = this.props;
		if (showResults) {
			const numRecords = filteredRows.length;
			const resultsWording = numRecords === 1 ? 'Result' : 'Results';
			const refWording = lastApiRefNum ? `(#${lastApiRefNum})` : '';
			return (
				<Fragment>
					<div className="grid__footer__results">
						{numRecords} {resultsWording} <strong>{refWording}</strong>
					</div>
				</Fragment>
			);
		}
		return null;
	};

	renderPrintButton = () => {
		const {
			data,
			hasMoreData,
			hasPaging,
			showPrintDropdown,
			fetchAllData,
			filteredRows,
			type,
			columns,
			printTitle,
			printComponents,
			printProcessingFee,
			printNetSale,
			printOnOnePage,
			printInPortrait,
			printHideApprovedAmount,
			printHideTotalByCard
		} = this.props;
		const { expandExport, settingsOpen } = this.state;

		const visibleColumns = this.mapColumnsForExport(this.getDisplayedColumns(columns), true);
		const allColumns = this.mapColumnsForExport(columns, true);

		return (
			<div className="filter__container__header__item">
				<div className={this.classes.printWrapper}>
					<PrintGridButton
						getAll={fetchAllData}
						data={filteredRows}
						columns={visibleColumns}
						allColumns={allColumns}
						type={type}
						title={printTitle}
						showLoaderMethod={this.showLoader}
						showDropdown={showPrintDropdown}
						ref={this.printGridButtonRef}
						hasMoreData={hasPaging && hasMoreData(data)}
						className={this.classes.print}
						components={printComponents}
						printProcessingFee={printProcessingFee}
						printNetSale={printNetSale}
						printInPortrait={printInPortrait}
						hideApprovedAmount={printHideApprovedAmount}
						hideTotalByCard={printHideTotalByCard}
						togglePrintExportTooltip={this.togglePrintExportTooltip}
						expandExport={expandExport}
						settingsOpen={settingsOpen}
						printOnOnePage={printOnOnePage}
					/>
				</div>
			</div>
		);
	};

	renderExportButton = () => {
		const {
			columns,
			filteredRows,
			activeFilters,
			exportTypes,
			fetchExportData,
			hasPaging,
			hasMoreData,
			data,
			allTitle,
			type,
			printProcessingFee,
			printNetSale,
		} = this.props;
		const { expandPrint, settingsOpen } = this.state;
		const visibleColumns = this.mapColumnsForExport(this.getDisplayedColumns(columns));

		return (
			<div className="filter__container__header__item">
				<div className={this.classes.exportWrapper}>
					<ExportComponent
						data={filteredRows}
						columns={visibleColumns}
						filters={activeFilters}
						showLoaderMethod={this.showExportLoader}
						ref={this.exportGridButtonRef}
						exportTypes={exportTypes}
						allTitle={allTitle}
						fetchExportData={fetchExportData}
						hasMoreData={hasPaging && hasMoreData(data)}
						type={type}
						exportProcessingFee={printProcessingFee}
						exportNetSale={printNetSale}
						togglePrintExportTooltip={this.togglePrintExportTooltip}
						expandPrint={expandPrint}
						settingsOpen={settingsOpen}
					/>
				</div>
			</div>
		);
	};

	renderEmptyGrid = () => {
		const { columns } = this.props;
		const displayedColumns = this.getDisplayedColumns(columns);
		const columnWidth = sum(map(displayedColumns, c => c.width || 0)) || 0;
		return (
			<div
				style={{
					width: columnWidth,
				}}
			>
				<this.components.emptyGrid emptyMessage={this.props.emptyMessage} fetchingData={this.props.fetchingData} />
			</div>
		);
	};

	renderGridBody = () => {
		const {
			isExpandable,
			columns,
			filteredRows,
			useInlineFilters,
			type,
			expandInSidebar,
			rowDetailsProps,
			tooltipProps,
			onRowClick,
			rowRendererDependentProps,
			showActionsOnError,
		} = this.props;
		const { isLargeScreen, isFiltering } = this.state;
		const gridClassName = `grid__height ${this.classes.gridWrapper} ${
			isExpandable || onRowClick ? 'grid--pointer' : ''
		}`;

		return (
			<div
				style={{
					width: this.calculateGridWidth(this.contentRef.current),
					height: this.calculateGridHeight(),
				}}
				className={`${this.classes.gridHolder} ${
					this.detailsRow && expandInSidebar && !isLargeScreen ? 'display--n' : ''
				}`}
				ref={this.gridHolderRef}
			>
				<div className={`${gridClassName}`}>
					<this.components.tooltip {...tooltipProps} />
					<ReactDataGrid
						minWidth={
							this.calculateGridWidth(this.gridRef.current && this.gridRef.current.grid) +
							(this.state.additionalPixel ? 1 : 0)
						}
						minHeight={this.calculateGridHeight()}
						columns={this.getDisplayedColumns(columns)}
						rowsCount={filteredRows.length}
						rowGetter={this.rowGetter}
						onGridSort={this.onGridSort}
						ref={this.gridRef}
						toolbar={useInlineFilters ? ToolbarComponent : constant(null)}
						onAddFilter={this.handleInlineFilter}
						getValidFilterValues={this.getValidInlineFilterValues}
						onClearFilters={this.handleInlineFilter}
						emptyRowsView={this.renderEmptyGrid}
						onCellExpand={this.onCellExpand}
						onRowClick={this.onRowClick}
						onColumnResize={this.onColumnResize}
						minColumnWidth={minColumnWidth}
						enableRowSelect={null}
						rowScrollTimeout={null}
						rowHeight={48}
						enableCellSelect={false}
						getDetailsRowIndex={this.getDetailsRowIndex}
						rowRenderer={
							this.components.rowRenderer ? (
								<this.components.rowRenderer
									setDetailsRef={this.setRowDetailsRef}
									refreshGridData={this.refreshGridData}
									openModal={this.openCloseModal}
									gridHolder={this.gridHolderRef.current}
									resizeGrid={this.resizeGrid}
									type={type}
									rowDetailsRenderer={expandInSidebar ? null : this.components.rowDetails}
									rowDetailsProps={rowDetailsProps}
									actionsRenderer={this.components.rowActions}
									gridRef={this.gridRef.current}
									showLoader={this.showLoader}
									dependentProps={rowRendererDependentProps}
									showActionsOnError={showActionsOnError}
									hideActions={isFiltering}
								/>
							) : (
								undefined
							)
						}
					/>
				</div>
			</div>
		);
	};

	renderHeader = () => {
		const { enablePrint, enableExport, filterColumns, showPanel, showHeader } = this.props;
		const { displayHeaderMenu } = this.state;
		return (
			showHeader && (
				<Fragment>
					<header className={this.classes.header}>
						{this.renderTitle()}
						<div className={this.classes.headerMenu}>
							{showPanel ? <UserAccountPanel ref={this.userAccountRef} /> : null}
							{displayHeaderMenu && (
								<div className={this.classes.headerMenuAction}>
									<div className={this.classes.headerGroup}>
										<this.components.header
											openCloseModal={this.openCloseModal}
											refreshGridData={this.refreshGridData}
										/>
										{enablePrint && this.renderPrintButton()}
										{enableExport && this.renderExportButton()}
										{filterColumns ? (
											<div className="filter__container__header__item" ref={this.columnFilterPortalRef} />
										) : null}
									</div>
								</div>
							)}
						</div>
					</header>
					{this.renderHeaderDisclaimer()}
				</Fragment>
			)
		);
	};

	renderGridHeader = () => {
		const {
			enableFilters,
			filters,
			activeFilters,
			showGridHeader,
			filterColumns,
			enablePrint,
			enableExport,
			useInlineFilters,
			filterProps,
			displayShowHideSelectedFilters,
			maxApiRangeBreach,
			showRefreshButton,
		} = this.props;
		const { showFilters, showSelection, displayHeaderMenu, isExporting } = this.state;
		const isSomeFilterSelected =
			enableFilters &&
			some(
				activeFilters,
				({ hasSelection, selectionComponent, values: { disabled } }) => hasSelection && selectionComponent && !disabled
			);
		return (
			showGridHeader && (
				<div className="filter" ref={this.filtersContainerRef}>
					<div className={this.classes.filterContainer}>
						<div className="filter__container__header">
							<div className={this.classes.filterHeader || 'flex--primary datatooltip--v--bottom flex--grow--1'}>
								<this.components.selectAllRowsCheckbox />
								<div className={this.classes.filter}>
									{enableFilters ? (
										<this.components.filter
											updateFilters={this.updateFilters}
											filters={filters}
											activeFilters={activeFilters}
											ref={this.mainFilterRef}
											filterSelectionRef={this.filterSelectionRef}
											clearFilters={this.clearFilters}
											isExporting={isExporting}
											showRangeMessage={maxApiRangeBreach}
											{...filterProps}
										/>
									) : (
										<span>&nbsp;</span>
									)}
								</div>
								<div className={this.classes.selectedEntriesActionsWrapper}>
									<this.components.selectedEntriesActions />
								</div>
								{showRefreshButton && (
									<div className="filter__container__header__item spc--bottom--sml" data-tooltip={'Refresh'}>
										<button onClick={this.props.fetchData} className={`btn btn--ghost btn--med btn--med--wicon`}>
											<i className="icon icon--xsml icon--refresh align--v--middle"></i>
										</button>
									</div>
								)}
							</div>
							<div className={this.classes.gridHeader}>
								{enableFilters && showSelection && isSomeFilterSelected ? (
									<div className="pos--rel show--to--xlrg--block spc--right--sml spc--bottom--sml">
										<a
											type="button"
											className="btn btn--med btn--anchor filter__details__toggle"
											onClick={this.showHideFilters}
										>
											<span className="show--to--sml--inlineblock">Filter details</span>
											<i
												className={`icon icon--nano icon--arrow--${
													showFilters ? 'down' : 'right'
												}--primary icon--middle spc--left--tny`}
											/>
										</a>
									</div>
								) : null}
								{!displayHeaderMenu && (
									<React.Fragment>
										<this.components.gridHeader
											openCloseModal={this.openCloseModal}
											refreshGridData={this.refreshGridData}
										/>
										{enableExport && this.renderExportButton()}
										{enablePrint && this.renderPrintButton()}
										{filterColumns && (
											<div className="filter__container__header__item" ref={this.columnFilterPortalRef} />
										)}
										{isSomeFilterSelected && displayShowHideSelectedFilters && (
											<div className="filter__container__header__item datatooltip--v--bottom spc--left--tny">
												<div data-tooltip={showSelection ? 'Hide' : 'Show'}>
													<button
														onClick={this.showHideSelection}
														className={`btn btn--med btn--anchor filter__expand ${showSelection ? 'is-expanded' : ''}`}
													>
														<i className="icon icon--nano icon--arrow--right--primary icon--middle"></i>
													</button>
												</div>
											</div>
										)}
									</React.Fragment>
								)}
								{useInlineFilters && (
									<div className="filter__toggle spc--bottom--sml type--right">
										<button type="button" className="btn btn--med btn--ghost" onClick={this.showHideInlineFilters}>
											<i
												className={`icon icon--sml icon--search${
													this.gridRef.current && this.gridRef.current.state.canFilter ? '' : '--disabled'
												} icon--middle`}
											/>
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
					{showFilters && (
						<div className={showSelection && isSomeFilterSelected ? 'filter__details' : 'display--n'}>
							<div className="separator separator--grey1 spc--bottom--sml hide--to--med--block"></div>
							<div className="hide--from--med type--wgt--bold spc--bottom--tny padd--bottom--tny border--bottom">
								Filter details
							</div>
							<div ref={this.filterSelectionRef} />
						</div>
					)}
				</div>
			)
		);
	};

	renderTitle = () => (
		<this.components.title
			title={this.props.title}
			className={this.classes.title}
			type={this.props.type}
			onTitleClick={this.props.onTitleClick}
		/>
	);

	renderHeaderDisclaimer = () => <this.components.headerDisclaimer />;
	renderBodyDisclaimer = () => {
		const { renderDisclaimerBody } = this.props;
		if (renderDisclaimerBody) {
			return renderDisclaimerBody();
		}
	};

	renderModal = () => (
		<this.components.modal
			modal={this.state.modal}
			onModalClose={this.openCloseModal}
			overlayClassName={this.props.modalOverlayClassName}
		/>
	);

	render = () => {
		const {
			hasPaging,
			hasMoreData,
			data,
			fetchingAdditionalData,
			fetchingData,
			filteredRows,
			type,
			kvaasResourceType,
			onLoadMoreLimitChange,
			loadMoreOptions,
			loadMoreLimit,
			expandInSidebar,
			defaultColumns,
			columnFilterType,
			columns,
			showResults,
			displayErrorRefNum,
		} = this.props;
		const { columnFilterState } = this.state;
		const detailRow = find(data && data.xReportData, r => r.isDetails);
		return (
			<Fragment>
				{this.renderModal()}
				{this.renderHeader()}
				{this.renderBodyDisclaimer()}
				<div
					ref={this.contentRef}
					className={`${this.classes.content} ${
						this.gridRef.current && this.gridRef.current.state.canFilter ? '' : 'grid__holder--override__nofilter'
					} ${this.detailsRow && expandInSidebar ? 'is-expanded' : ''}`}
				>
					<div>
						{this.renderGridHeader()}
						{this.renderGridBody()}
					</div>
					{expandInSidebar && detailRow && (
						<this.components.rowDetails
							key={detailRow.index}
							row={detailRow}
							visibleColumns={this.getDisplayedColumns(columns)}
							openModal={this.openCloseModal}
							refreshGridData={this.refreshGridData}
							gridHolder={this.gridHolderRef.current}
							setDetailsRef={this.setRowDetailsRef}
							resizeGrid={this.resizeGrid}
							type={this.props.type}
							closeRow={() => this.onRowClick(detailRow.gridRowNumber, detailRow)}
							{...(detailRow.expandedRowProps || {})}
							{...this.props.rowDetailsProps}
						/>
					)}
					<GridFooter
						gridFooterRef={this.gridFooterRef}
						isLoadMoreEnabled={hasPaging && hasMoreData(data)}
						fetchingAdditionalData={fetchingAdditionalData}
						fetchingData={fetchingData}
						filteredRows={filteredRows}
						type={type}
						onLoadMoreLimitChange={onLoadMoreLimitChange}
						loadMoreLimit={loadMoreLimit}
						loadMoreOptions={loadMoreOptions}
						openCloseModal={this.openCloseModal}
						CustomComponent={this.components.gridFooter}
						renderResultsAndReference={this.renderResultsAndReference}
						showResults={showResults}
						displayErrorRefNum={displayErrorRefNum}
					/>
					{this.columnFilterPortalRef.current &&
						createPortal(
							<ColumnFilterComponent
								ref={this.columnFilterRef}
								defaultColumns={defaultColumns}
								columns={columns}
								filteredColumns={this.onFilterColumns}
								header={this.components.columnFilterHeader}
								footer={this.components.columnFilterFooter}
								isDisabled={data === null}
								type={columnFilterType}
								kvaasResourceType={kvaasResourceType}
								updateState={this.updateColumnFilterState}
								state={columnFilterState}
								hideSettings={this.props.hideColumnFilterSettings}
								hideSaveSelection={this.props.hideColumnFilterSaveSelection}
							/>,
							this.columnFilterPortalRef.current
						)}{' '}
				</div>
			</Fragment>
		);
	};
}

Grid.defaultProps = {
	components: {},
	fetchingData: false,
	fetchingAdditionalData: false,
	resolveColumnName: identity,
	resolveSortDirection: toLower,
	mapCellArgs: (rowId, row) => {
		if (rowId < 0) {
			return;
		}
		return {
			rowData: row,
			expandArgs: {
				children: [
					{
						isDetails: true,
						row: row,
					},
				],
			},
		};
	},
	isExpandable: false,
	hasPaging: false,
	enableExport: false,
	enablePrint: false,
	onModalToggle: noop,
	enableFilters: false,
	fetchData: noop,
	showResults: false,
	hasMoreData: constant(false),
	showPanel: true,
	showGridHeader: true,
	showHeader: true,
	showPrintDropdown: true,
	initialFetch: true,
	syncQueryFilters: false,
	filterProps: {},
	fetchOnReset: true,
	expandInSidebar: false,
	headerMenuThreshold: screenSize.xlrg,
	getInlineFilterValues: getInlineFilterValues,
	displayShowHideSelectedFilters: true,
	rowRendererDependentProps: {},
	onVisibleColumnsChange: noop,
};

Grid.propTypes = {
	components: PropTypes.shape({
		emptyGrid: isComponent,
		header: isComponent,
		gridHeader: isComponent,
		modal: isComponent,
		filter: isComponent,
		rowRenderer: isComponent,
		rowDetails: isComponent,
		gridFooter: isComponent,
		title: isComponent,
		tooltip: isComponent,
		columnFilterHeader: isComponent,
		columnFilterFooter: isComponent,
		actionsRenderer: isComponent,
	}),
	classes: PropTypes.shape({
		header: PropTypes.string,
		headerMenu: PropTypes.string,
		headerMenuAction: PropTypes.string,
		headerGroup: PropTypes.string,
		content: PropTypes.string,
		wrapper: PropTypes.string,
		title: PropTypes.string,
		print: PropTypes.string,
		gridHeader: PropTypes.string,
		filter: PropTypes.string,
	}),
	emptyMessage: PropTypes.string,
	fetchingData: PropTypes.bool,
	fetchingAdditionalData: PropTypes.bool,
	filteredRows: PropTypes.arrayOf(PropTypes.object),
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			name: PropTypes.string,
			initWidth: PropTypes.number.isRequired,
		})
	),
	defaultColumns: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			name: PropTypes.string,
			initWidth: PropTypes.number.isRequired,
		})
	),
	data: PropTypes.shape({
		xReportData: PropTypes.arrayOf(PropTypes.object),
	}),
	resolveColumnName: PropTypes.func,
	resolveSortDirection: PropTypes.func,
	inlineFilters: PropTypes.object,
	filters: PropTypes.arrayOf(PropTypes.object),
	activeFilters: PropTypes.arrayOf(PropTypes.object),
	onChange: PropTypes.func.isRequired,
	mapCellArgs: PropTypes.func,
	isExpandable: PropTypes.bool,
	hasPaging: PropTypes.bool,
	loadMoreOptions: PropTypes.arrayOf(PropTypes.number),
	hasMoreData: PropTypes.func,
	onLoadMoreLimitChange: PropTypes.func,
	loadMoreLimit: PropTypes.number,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onTitleClick: PropTypes.func,
	enableExport: PropTypes.bool,
	enablePrint: PropTypes.bool,
	type: PropTypes.string,
	kvaasResourceType: PropTypes.string,
	onModalToggle: PropTypes.func,
	enableFilters: PropTypes.bool,
	fetchData: PropTypes.func,
	fetchAllData: PropTypes.func,
	lastApiRefNum: PropTypes.string,
	showResults: PropTypes.bool,
	key: PropTypes.any,
	showPanel: PropTypes.bool,
	showGridHeader: PropTypes.bool,
	showHeader: PropTypes.bool,
	showPrintDropdown: PropTypes.bool,
	initialFetch: PropTypes.bool,
	columnFilterType: PropTypes.string,
	syncQueryFilters: PropTypes.bool,
	queryFilterValues: PropTypes.func,
	allTitle: PropTypes.string,
	exportTypes: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string,
			name: PropTypes.string,
		})
	),
	fetchExportData: PropTypes.shape({
		current: PropTypes.func.isRequired,
		all: PropTypes.func,
	}),
	expanded: PropTypes.any,
	filterColumns: PropTypes.any,
	useInlineFilters: PropTypes.bool,
	filterProps: PropTypes.object,
	printTitle: PropTypes.string,
	printComponents: PropTypes.object,
	fetchOnReset: PropTypes.bool,
	expandInSidebar: PropTypes.bool,
	rowDetailsProps: PropTypes.object,
	tooltipProps: PropTypes.object,
	headerMenuThreshold: PropTypes.number,
	ignoreHeaderMenuTreshold: PropTypes.any,
	selectCustomer: PropTypes.func,
	getInlineFilterValues: PropTypes.func,
	printProcessingFee: PropTypes.bool,
	printNetSale: PropTypes.bool,
	displayShowHideSelectedFilters: PropTypes.bool,
	onRowClick: PropTypes.func,
	rowRendererDependentProps: PropTypes.object,
	onVisibleColumnsChange: PropTypes.func,
	printInPortrait: PropTypes.bool,
	printOnOnePage: PropTypes.bool,
	renderDisclaimerBody: PropTypes.any,
	maxApiRangeBreach: PropTypes.any,
	showRefreshButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
	displayErrorRefNum: PropTypes.any,
	showActionsOnError: PropTypes.bool,
	modalOverlayClassName: PropTypes.string,
	customWidth: PropTypes.any,
	hideColumnFilterSettings: PropTypes.bool,
	hideColumnFilterSaveSelection: PropTypes.bool,
	printHideApprovedAmount: PropTypes.bool,
	printHideTotalByCard: PropTypes.bool,
};

export default Grid;
