import {
	map,
	isArray,
	isEmpty,
	each,
	findIndex,
	replace,
	startCase,
	isObject,
	toLower,
	filter,
	negate,
	endsWith,
	includes,
	join,
	upperFirst,
	some,
	compact,
	toString,
	startsWith,
	first,
} from 'lodash-es';

import httpService from './httpService';
import { invokeIfFunction } from 'common/utilities';

const { paymentSiteApiEndpoint } = ApplicationSettings;

class PaymentSiteService {
	constructor(httpService) {
		this.httpService = httpService;
	}

	get options() {
		const headers = new Headers();
		return {
			headers,
			isJson: true,
		};
	}

	find = async () => {
		const endpoint = `${paymentSiteApiEndpoint}DescribeUserForms`;
		const result = await this.httpService.post(endpoint, {}, this.options);
		return await this.parseResults(result);
	};

	get = async formId => {
		const endpoint = `${paymentSiteApiEndpoint}DescribeFormLayout`;
		const result = await this.httpService.post(endpoint, { formId }, this.options);
		return await this.parseResult(result);
	};

	save = async (form, changedFormSettings) => {
		const endpoint = `${paymentSiteApiEndpoint}UpdateFormLayout`;
		const body = await this.createBody(form, changedFormSettings);
		const result = await this.httpService.post(endpoint, body, this.options);
		return await this.parseResult(result);
	};

	createBody = async (
		{
			id: FormId,
			name: FormTitle,
			path: FormPath,
			logo: FormLogo,
			sections,
			formSettings,
			extraHtml: ExtraHtml,
			theme: FormTheme,
		},
		changedFormSettings
	) => {
		return {
			FormId,
			FormTitle,
			FormPath,
			FormLogo,
			FormTheme,
			ExtraHtml,
			FormLayout: await this.createLayout(sections),
			FormSettings: isEmpty(changedFormSettings)
				? formSettings
				: this.createFormSettings(formSettings, changedFormSettings),
		};
	};

	createFormSettings = (formSettings, changedFormSettings) => {
		const result = [...formSettings];

		each(changedFormSettings, item => {
			if (item.key === 'termsAndConditions' || includes(item.key, 'tab')) {
				let formattedKey = replace(startCase(item.key), /\s/g, '');
				if (includes(item.key, 'tab')) {
					formattedKey = upperFirst(item.key);
				}
				let mappedItem = {};
				const index = findIndex(formSettings, ({ Key }) => toLower(Key) === toLower(item.key));
				mappedItem = this.mapFormSettings(formattedKey, item.fields);
				if (mappedItem) {
					if (index === -1) {
						result.push(mappedItem);
					} else {
						result[index] = mappedItem;
					}
				} else if (index > -1) {
					delete result[index];
				}
			} else if (item.key === 'allowedCommands') {
				const index = findIndex(formSettings, ({ Key }) => toLower(Key) === toLower(item.key));
				const allowedCommands = [];
				each(item.fields, ({ key, value }) => {
					if (key === 'cash:sale') {
						if (index === -1) {
							return;
						}
						const currentValue = formSettings[index].Val;
						value = includes(currentValue, 'cash:sale');
					}
					if (value) {
						allowedCommands.push(key);
					}
				});
				const mappedItem = { Key: 'AllowedCommands', Val: join(allowedCommands, ',') };
				if (mappedItem.Val) {
					if (index === -1) {
						result.push(mappedItem);
					} else {
						result[index] = mappedItem;
					}
				} else if (index > -1) {
					delete result[index];
				}
			} else if (item.key === 'paymentMethods') {
				each(item.fields, field => {
					const index = findIndex(formSettings, ({ Key }) => toLower(Key) === toLower(field.key));
					result[index] = {
						Key: field.key,
						Val: field.key === 'RemoveReenterACHNumbers' ? (field.value ? '0' : '1') : field.value ? '1' : '0',
					};
				});
			} else {
				each(item.fields, field => {
					const mappedItem = {};
					this.mapFormSetting(field, mappedItem, true, item.isPrm);
					const index = findIndex(formSettings, ({ Key }) => toLower(Key) === toLower(field.key));
					if (mappedItem) {
						if (index === -1) {
							result.push(mappedItem);
						} else {
							result[index] = mappedItem;
						}
					} else if (index > -1) {
						delete result[index];
					}
				});
			}
		});

		if (!some(result, { Key: 'AcceptACH' })) {
			result.push({ Key: 'AcceptACH', Val: '0' });
		}

		return filter(result, negate(isEmpty));
	};

	mapFormSettings = (key, fields) => {
		const mappedItem = {};
		each(fields, field => {
			this.mapFormSetting(field, mappedItem, false);
		});
		if (!isEmpty(mappedItem)) {
			return { Key: key, Val: JSON.stringify(mappedItem) };
		}
	};

	mapFormSetting = ({ key, value, enableInput }, mappedItem, usePairs = true, isPrm = false) => {
		if (this.isValidFormSetting(value, enableInput, isPrm)) {
			if (value === true) {
				value = 1;
			} else if (value === false) {
				value = 0;
			}
			if (toLower(key) === 'receiptdefault' && value) {
				value = 'printer';
			}
			if (startsWith(toLower(key), 'service')) {
				value = parseFloat(value);
			} else if (endsWith(toLower(key), 'percent')) {
				value = parseFloat(value) / 100;
			}
			const val = isObject(value) ? value.name : value;
			if (usePairs) {
				mappedItem.Key = key;
				mappedItem.Val = val;
			} else {
				mappedItem[key] = val;
			}
		}
	};

	isValidFormSetting = (value, enableInput, isPrm = false) => {
		return (
			(isObject(value) && !!value.name) ||
			(!isObject(value) && value && (enableInput !== undefined ? enableInput : true)) ||
			isPrm
		);
	};

	createLayout = async sections => {
		return await Promise.all(map(sections, this.createSection));
	};

	createSection = async ({ id, label, fields, params, tempId }) => {
		const sId = tempId ? null : id;
		return {
			SectId: parseInt(sId),
			SectLayout: await this.createFields(fields),
			SectName: label,
			SectParams: {
				...params,
				box_header: label,
			},
		};
	};

	createFields = async fields => {
		return await Promise.all(map(fields, this.createField));
	};

	createField = async ({ key, label, isRequired, inputType, showInPrintReceipt, showInReceipt, value, values }) => {
		const field = {
			FieldName: key,
			Label: label,
			Required: invokeIfFunction(isRequired),
			InputType: inputType,
			ShowInPrintReceipt: showInPrintReceipt,
			ShowInReceipt: showInReceipt,
			Value: value,
		};

		if (values !== undefined) {
			field.Values = compact(
				map(values, ({ key, value }) => {
					if (key || value) {
						return { Key: toString(key), Value: value };
					}
				})
			);
		}

		return field;
	};

	parseResults = async result => {
		const parsedResult = {
			xReportData: result,
		};
		if (parsedResult.xReportData) {
			parsedResult.xReportData = await Promise.all(map(parsedResult.xReportData, this.parseResult));
		}
		return parsedResult;
	};

	parseResult = async result => {
		if (!result) {
			return;
		}
		const { FormId, FormTitle, FormPath, FormLogo, FormLayout, FormSettings, FormTheme, ExtraHtml } = result;
		return {
			id: FormId,
			name: FormTitle,
			path: FormPath,
			logo: FormLogo,
			theme: FormTheme,
			extraHtml: ExtraHtml,
			formSettings: await this.parseFormSettings(FormSettings),
			sections: await this.parseLayout(FormLayout),
		};
	};

	parseFormSettings = async formSettings => {
		return await Promise.all(map(formSettings, this.parseFormSetting));
	};

	parseFormSetting = async formSetting => {
		if (isEmpty(formSetting)) {
			return;
		}
		if (!formSetting.Val && formSetting.val !== '') {
			formSetting.Val = '';
		}
		return formSetting;
	};
	parseLayout = async layout => {
		return await Promise.all(map(layout, this.parseSection));
	};

	parseSection = async section => {
		if (isEmpty(section)) {
			return;
		}
		const { SectId, SectLayout, SectName, SectParams } = section;
		let parsedParams = {};
		let internalSection = false;
		let label = SectName;

		if (SectParams) {
			parsedParams = first(SectParams) || SectParams;
			label = parsedParams.box_header;
		}
		if (toLower(replace(label, ' ', '')) === 'internaloptions') {
			internalSection = true;
		}
		const { box_header, ...params } = parsedParams;
		return {
			id: toString(SectId),
			key: toString(SectId),
			fields: await this.parseFields(SectLayout),
			label,
			params,
			internalSection,
		};
	};

	parseFields = async fields => {
		if (!fields) return [];
		if (!isArray(fields)) return [fields];
		return await Promise.all(map(fields, this.parseField));
	};

	parseField = async field => {
		if (!field) {
			return;
		}
		const { FieldName, Label, Required, InputType, ShowInReceipt, ShowInPrintReceipt, Value, Values } = field;
		const mappedField = {
			hideDropdownOptionPreview: FieldName === 'xBillState',
			key: FieldName,
			label: Label,
			isRequired: Required,
			inputType: InputType,
			showInReceipt: ShowInReceipt,
			showInPrintReceipt: ShowInPrintReceipt,
			value: Value,
			internalSetting: includes(
				[
					'customers_schedules',
					'xRecurring',
					'recurring_simple',
					'recurring_split_payment',
					'recurring_split_payment_8_months',
				],
				toLower(FieldName)
			),
		};
		if (FieldName === 'xRecurring') {
			mappedField.key = 'customers_schedules';
			mappedField.label = 'Cardknox Recurring';
		}
		if (Values !== undefined) {
			mappedField.values =
				compact(
					map(Values, ({ Key, Value }) => {
						if (Key || Value) {
							return { key: Key, value: Value };
						}
					})
				) || [];
		}

		return mappedField;
	};
}

const paymentSiteService = new PaymentSiteService(httpService);

export default paymentSiteService;
