import { map, toPairs } from 'lodash';

import { CurrencyMap, awsUserStatuses } from 'common/utilities';

export const validInlineFilterValues = {
	xResponseResult: [
		{ key: 'Approved', label: 'Approved' },
		{ key: 'Verify', label: 'Verify' },
		{ key: 'Error', label: 'Error' },
		{ key: 'Pending', label: 'Pending' },
		{ key: 'Declined', label: 'Declined' },
		{ key: 'Declined/Retrying', label: 'Declined/Retrying' },
	],
	xEntryMethod: [
		{ key: 'Keyed', label: 'Keyed' },
		{ key: 'EMV', label: 'EMV' },
		{ key: 'Swiped', label: 'Swiped' },
		{ key: 'Unknown', label: 'Unknown' },
	],
	xCommand: [
		{ key: 'AvsOnly', label: 'AVS Only' },
		{ key: 'CC:Sale', label: 'CC:Sale' },
		{ key: 'CC:AuthOnly', label: 'CC:AuthOnly' },
		{ key: 'CC:Capture', label: 'CC:Capture' },
		{ key: 'CC:SplitCapture', label: 'CC:SplitCapture' },
		{ key: 'CC:Save', label: 'CC:Save' },
		{ key: 'CC:PostAuth', label: 'CC:PostAuth' },
		{ key: 'CC:Credit', label: 'CC:Credit' },
		{ key: 'CC:Refund', label: 'CC:Refund' },
		{ key: 'CC:VoidRefund', label: 'CC:VoidRefund' },
		{ key: 'CC:VoidRelease', label: 'CC:VoidRelease' },
		{ key: 'CC:Void', label: 'CC:Void' },
		{ key: 'Check:Sale', label: 'Check:Sale' },
		{ key: 'Check:Credit', label: 'Check:Credit' },
		{ key: 'Check:Save', label: 'Check:Save' },
		{ key: 'Check:Void', label: 'Check:Void' },
		{ key: 'Check:Refund', label: 'Check:Refund' },
		{ key: 'Ebtfs:Sale', label: 'Ebtfs:Sale' },
		{ key: 'Ebtfs:Credit', label: 'Ebtfs:Credit' },
		{ key: 'Ebtfs:Balance', label: 'Ebtfs:Balance' },
		{ key: 'Ebtfs:Voucher', label: 'Ebtfs:Voucher' },
		{ key: 'Ebtfs:Return', label: 'Ebtfs:Return' },
		{ key: 'Ebtcb:Sale', label: 'Ebtcb:Sale' },
		{ key: 'Ebtcb:Cash', label: 'Ebtcb:Cash' },
		{ key: 'Ebtcb:Balance', label: 'Ebtcb:Balance' },
		{ key: 'Ebtw:Sale', label: 'Ebtw:Sale' },
		{ key: 'Ebtw:Balance', label: 'Ebtw:Balance' },
		{ key: 'Ebtw:Void', label: 'Ebtw:Void' },
		{ key: 'Gift:Issue', label: 'Gift:Issue' },
		{ key: 'Gift:Redeem', label: 'Gift:Redeem' },
		{ key: 'Gift:Balance', label: 'Gift:Balance' },
		{ key: 'Fraud:Submit', label: 'Fraud:Submit' },
		{ key: 'Split Pay', label: 'Split Pay' },
	],
	achStatus: [
		{ key: 'Pending', label: 'Pending' },
		{ key: 'Guaranteed', label: 'Guaranteed' },
		{ key: 'Not Guaranteed - Probable Fraud', label: 'Not Guaranteed - Probable Fraud' },
		{ key: 'Not Guaranteed - High Risk', label: 'Not Guaranteed - High Risk' },
		{ key: 'Not Guaranteed - Low Risk', label: 'Not Guaranteed - Low Risk' },
		{ key: 'Error', label: 'Error' },
		{ key: 'Review', label: 'Review' },
		{ key: 'Retrieval', label: 'Retrieval' },
		{ key: 'Chargeback', label: 'Chargeback' },
		{ key: 'Chargeback Reversal', label: 'Chargeback Reversal' },
		{ key: 'Settled', label: 'Settled' },
		{ key: 'Safe', label: 'Safe' },
		{ key: 'N/A', label: 'N/A' },
	],
	isActive: [{ key: 'true', label: 'Active' }, { key: 'false', label: 'Inactive' }],
	lastTransactionStatus: [
		{ key: 'Approved', label: 'Approved' },
		{ key: 'Error', label: 'Error' },
		{ key: 'Declined', label: 'Declined' },
		{ key: 'Declined/Retrying', label: 'Declined/Retrying' },
	],
	xResponseAVSCode: [
		{ key: 'YYY', label: 'Address: Match & 5 Digit ZIP: Match' },
		{ key: 'NYZ', label: 'Address: No Match & 5 Digit ZIP: Match' },
		{ key: 'YNA', label: 'Address: Match & 5 Digit ZIP: No Match' },
		{ key: 'NNN', label: 'Address: No Match & 5 Digit ZIP: No Match' },
		{ key: 'XXU', label: 'Address Information not verified for domestic transaction' },
		{ key: 'YYX', label: 'Address: Match & 9 Digit ZIP: Match' },
		{ key: 'NYW', label: 'Address: No Match & 9 Digit ZIP: Match' },
		{ key: 'XXR', label: 'Retry / System Unavailable' },
		{ key: 'XXS', label: 'Service Not Supported' },
		{ key: 'default', label: 'Unknown', isDefault: true },
	],
	xReviewed: [{ key: ['Y', 'P'], label: 'Approved' }, { key: 'N', label: 'Not Approved' }],
	paymentMethod: [
		{ key: 'Check', label: 'Check' },
		{ key: 'Credit', label: 'Credit' },
		{ key: 'Debit', label: 'Debit' },
		{ key: 'Gift', label: 'Gift' },
	],
	xCardSource: [
		{ key: 'Incoming', label: 'Incoming' },
		{ key: 'Account', label: 'Account' },
		{ key: 'Cardholder', label: 'Cardholder' },
	],
	xCardType: [
		{ key: 'Amex', label: 'Amex' },
		{ key: 'Check', label: 'Check' },
		{ key: 'Diners', label: 'Diners' },
		{ key: 'Discover', label: 'Discover' },
		{ key: 'EBTW', label: 'EBTW' },
		{ key: 'EBT', label: 'EBT' },
		{ key: 'Gift', label: 'Gift' },
		{ key: 'MasterCard', label: 'MasterCard' },
		{ key: 'JCB', label: 'JCB' },
		{ key: 'Visa', label: 'Visa' },
		{ key: 'Unknown', label: 'Unknown' },
	],
	xRole: [
		{
			label: 'Admin',
			key: 'admin',
		},
		{
			label: 'Advanced User',
			key: 'advanced',
		},
		{
			label: 'Standard User',
			key: 'standard',
		},
		{
			label: 'View Only',
			key: 'viewonly',
		},
		{
			label: 'Save Only',
			key: 'saveonly',
		},
		{
			label: 'Sale Only',
			key: 'saleonly',
		},
		{
			label: 'Auth Only',
			key: 'authonly',
		},
		{
			label: 'Custom User',
			key: 'custom',
		},
	],
	currency: (() => {
		const currencyMap = map(CurrencyMap.map, (_, key) => ({ key, label: key }));
		const isoCodesMap = map(CurrencyMap.isoCodesMap, (value, key) => ({ key, label: value }));
		return [...currencyMap, ...isoCodesMap];
	})(),
	isAdmin: [{ key: 'true', label: 'Yes' }, { key: 'false', label: 'No' }],
	status: map(toPairs(awsUserStatuses), ([key, { label }]) => ({ key, label })),
};
