import { toLower } from 'lodash';

import principalService from '../services/principalService';

export const checkIfCanadian = () => {
	let isCanadianCurrency = false;
	const principal = principalService.get();

	if (principal && principal.idInfo && principal.idInfo.xMerchantCurrency) {
		isCanadianCurrency = toLower(principal.idInfo.xMerchantCurrency) === 'cad';
	}
	return isCanadianCurrency;
};
