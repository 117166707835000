import React from 'react';
import PropTypes from 'prop-types';

import { withContainer } from './../drag-and-drop';

const draggableColumn = ({
	item: { isDefault, label, customLabel, canDelete, key, hide },
	onChange,
	removeCustomField,
	isPreview,
	parsedOriginalKey,
	parsedConvenienceKey,
}) => {
	const isDefaultName = `${key}.isDefault`;
	let parsedCustomLabel = customLabel;
	if (key === parsedConvenienceKey) {
		parsedCustomLabel = 'Electronic Transfer Fee';
	} else if (key === parsedOriginalKey) {
		parsedCustomLabel = 'Original Amount';
	}
	return (
		<React.Fragment>
			{!hide ? (
				<div className={isPreview ? 'draggable--report w--max--570' : ''}>
					<div className="clearfix padd--bottom--sml">
						<div className="pull spc--top--sml">
							<input
								type="checkbox"
								name={isDefaultName}
								id={isDefaultName}
								checked={isDefault}
								className="input input--check"
								onChange={onChange}
							/>
							<label htmlFor={isDefaultName} className="type--color--text--medium type--wgt--medium">
								{parsedCustomLabel || label}
							</label>
						</div>
						<div className="push spc--top--sml spc--left--sml">
							<i className={`icon icon--tiny icon--dragdots${isPreview ? '-active' : ''} align--v--middle`}></i>
						</div>
						{canDelete ? (
							<button
								type="button"
								onClick={() => removeCustomField(key)}
								className="push btn btn--med btn--outline spc--top--nano spc--bottom--neg--tny"
								data-tooltip="Remove"
							>
								<i className="icon icon--tiny icon--wastebasket "></i>
							</button>
						) : null}
					</div>
					{isPreview ? null : <hr className="separator separator--grey1 spc--bottom--tny" />}
				</div>
			) : null}
		</React.Fragment>
	);
};

draggableColumn.defaultProps = {
	onChange: () => {},
};

draggableColumn.propTypes = {
	onChange: PropTypes.func,
	item: PropTypes.object,
	removeCustomField: PropTypes.func,
	isPreview: PropTypes.bool,
	parsedConvenienceKey: PropTypes.string,
	parsedOriginalKey: PropTypes.string,
};

const mapItemToId = ({ key }) => key;

export default withContainer(draggableColumn, mapItemToId, {
	dragStyle: {
		height: '42px',
		border: '1px dashed #c7ced5',
		backgroundColor: '#f8fafd',
		borderRadius: '6px',
	},
});
