import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BatchGridBody extends Component {
	render() {
		const { children, ...rest } = this.props;
		return <tbody {...rest}>{children}</tbody>;
	}
}

BatchGridBody.propTypes = {
	children: PropTypes.any,
};

export default BatchGridBody;
