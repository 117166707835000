import React, { Component } from 'react';
import Tour from 'reactour';
import { get } from 'lodash';
import { func, object } from 'prop-types';
import _ from 'lodash';

import { kvaasService } from 'common/services';
import { kvaasResources } from 'common/utilities';
import { withCancelable } from 'common/components/cancelable';

const requestKeys = {
	KVAAS: 'kvaas',
};

class TourComponent extends Component {
	state = {
		isTourOpen: false,
		steps: [],
	};

	async componentDidMount() {
		const { makePendingRequest, tourConfig } = this.props;
		try {
			const [walkthroughSettings] = await makePendingRequest(
				kvaasService.get(kvaasResources.walkthroughSettings),
				requestKeys.KVAAS
			);
			const lastViewedWalkthrough = parseInt(get(walkthroughSettings, `data.${tourConfig.key}`, 0));
			if (lastViewedWalkthrough < tourConfig.version) {
				const data = {
					...get(walkthroughSettings, 'data', {}),
					[tourConfig.key]: tourConfig.version,
				};
				kvaasService.save({
					newData: {
						revision: 0,
						data,
					},
					oldData: walkthroughSettings,
					...kvaasResources.walkthroughSettings,
				});
				const element = document.querySelector(get(tourConfig, 'steps[0].selector'));

				if (element) {
					element.scrollIntoView();
				}

				this.setState({
					isTourOpen: true,
				});
			}
		} catch (e) {
			if (e && e.isCanceled) {
				return;
			}
			console.error(e); //eslint-disable-line
		}
	}

	closeTour = () => {
		this.setState({
			isTourOpen: false,
		});
	};

	handleNavigationRemoval = () => {
		if (this.removedNavigation) {
			return;
		}
		if (this.props.tourConfig.steps.length === 1) {
			const tourNavigation = [...document.querySelectorAll('[data-tour-elem]')];
			_.each(tourNavigation, navigationItem => {
				navigationItem.parentElement.removeChild(navigationItem);
			});
		}
		this.removedNavigation = true;
	};

	render() {
		const { isTourOpen } = this.state;
		const { tourConfig } = this.props;
		return (
			<Tour
				onAfterOpen={this.handleNavigationRemoval}
				steps={tourConfig.steps}
				isOpen={isTourOpen}
				onRequestClose={this.closeTour}
				disableInteraction={true}
			/>
		);
	}
}

TourComponent.propTypes = {
	makePendingRequest: func,
	tourConfig: object,
};

export default withCancelable(TourComponent);
