import React from 'react';
import moment from 'moment';

const Merchant = () => (
	<div>
		<p className="type--wgt--bold spc--bottom--med">Cardknox Terms and Conditions</p>
		<p className="spc--bottom--med">
			These Terms and Conditions (this "Agreement") is a legally binding agreement between you, as the user of the
			Services and as the business or legal entity on behalf of whom you are accepting this Agreement ("you" or "your")
			and Cardknox Development, Inc. ("Cardknox") and governs your use of the Services.
		</p>
		<p className="spc--bottom--med">
			By using or accessing the Services through any means permissible, you acknowledge and agree that: (i) you have
			reviewed and understand this Agreement; (ii) you agree to be legally bound by the terms and conditions of this
			Agreement; and (iii) your use of the Services will be governed by this Agreement. If you do not agree or are not
			willing to be bound by the terms and conditions of this Agreement, you should not use the Services.
		</p>
		<ol className="list--decimal spc--left--sml">
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">DEFINITIONS</p>
				(a) "Card" means any payment card, including a Card Network payment card, gift card, prepaid card, stored-value
				card, or any other generally accepted method of electronic payment.
				<br />
				(b) "Card Networks" means Visa U.S.A., Inc., Visa International, Inc., MasterCard International, Inc., Discover
				Financial Services, LLC, American Express Travel Related Services Company, Inc., each of their affiliates, and
				any other credit or debit card issuing company.
				<br />
				(c) "Data" means any personal, financial, Card, or transaction information.
				<br />
				(d) "Rules" means the rules and regulations promulgated by Card Networks.
				<br />
				(e) "Services" means the merchant user account, merchant portal and the gateway services provided by Cardknox.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">PERIODIC REVISIONS TO THIS AGREEMENT</p>
				From time to time, Cardknox will make revisions to this Agreement and the policies relating to the Services.
				Cardknox will provide notice of such revisions by posting revisions to the merchant portal at Cardknox's website
				at <a href="https://portal.cardknox.com">https://portal.cardknox.com</a>. You agree to visit the merchant portal
				page periodically to review any such revisions. Your continued use of the Services following the posting of the
				changes will mean that you accept and agree to the changes.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">CAPACITY AND RELATED MATTERS</p>
				By accepting the terms and conditions of this Agreement, you represent and warrant that you are of at least 18
				years of age or older. If you are using the Services on behalf of a business entity, you further represent and
				warrant that you have the legal authority to accept the terms and conditions of this Agreement on behalf of such
				entity. Cardknox reserves the right, in its sole discretion, to refuse to provide you with any of the Services.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">DATA COLLECTION, PRIVACY AND SECURITY</p>
				(a) Cardknox reserves the right to verify all users of the Services. Cardknox will have the right to collect and
				process Data collected from you and your customers (including Data associated with the Services), subject to
				applicable law, to use internally for record keeping, internal reporting, analytics, fraud detection and support
				purposes and to compile and disclose Data in the aggregate where your individual or user Data is not
				identifiable, including calculating merchant averages by region or industry. By using the Services, you
				acknowledge that Cardknox, its processors, banks, partners, suppliers and/or their agents and subcontractors may
				transfer Data amongst themselves as necessary for the purpose of the provision and management of the Services.
				Cardknox may further access or disclose Data in order to, among other things: (i) protect or defend the legal
				rights or property of Cardknox, its affiliated companies or their employees, agents and contractors (including
				enforcement of our agreements); (ii) protect the safety and security of users of the Services or members of the
				public including acting in urgent circumstances; (iii) detect, prevent or otherwise address fraud, security or
				technical issues or for risk management purposes; (iv) comply with any applicable law, regulation, legal process
				or governmental request, or (v) enforce this Agreement, including investigation of potential violations hereof.
				<br />
				(b) You will comply with all applicable laws, policies and regulations governing the security, privacy,
				collection, retention and use by you of cardholder Data, including, without limitation, financial information,
				card account numbers, and all other personally identifiable customer information.
				<br />
				(c) You are fully responsible for the security of cardholder Data while in your possession. You will comply with
				all then-current legal obligations and security measures, as applicable, including without limitation those
				issued by Card Networks and the Federal Trade Commission, associated with the collection, security,
				dissemination and destruction of customer and transaction Data, and expressly including the Payment Card
				Industry Data Security Standard (PCI DSS). You warrant that you have taken such precautions as are necessary to
				ensure that your server and electronic systems are secure from breach or intrusion by unauthorized third
				parties. In the event that your system is breached and an unauthorized third party has access to or has accessed
				cardholder Data or Card transaction Data, you will notify Cardknox promptly of such breach and shall take such
				precautions as may be necessary to prevent such breaches from occurring in the future.
				<br />
				(d) You agree to comply with all Cardknox security protocols and security advisories in effect during the term
				of this Agreement. You are solely responsible for verifying the accuracy and completeness of all Data submitted
				and processed by your processor using the Services and verifying that all corresponding funds are accurately
				processed. You acknowledge that Cardknox will not be liable for any improperly processed or unauthorized Data or
				illegal or fraudulent access to the Services, cardholder or Card transaction Data.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">ACCOUNTS AND PASSWORDS</p>
				You will need to create a username and password to access the Services. You will restrict access to such
				username, password, and the Services to your employees and agents as may be reasonably necessary consistent with
				the purposes of this Agreement and will ensure that each such employee and agent accessing and using the
				Services is aware of and otherwise complies with all applicable provisions of this Agreement regarding such use
				and access. You are solely responsible for maintaining adequate security and control of any and all usernames,
				passwords, or any other codes for purposes of giving you access to the Services. Cardknox will be entitled to
				rely on information it receives from you and may assume that all such information was transmitted by or on your
				behalf. You agree to notify Cardknox immediately of any unauthorized use of the Services, username or password,
				or any other breach of security. You may be held liable for losses incurred by Cardknox due to someone else
				using your username, password and personal and other Data as a result of your failing to keep such information
				secure and confidential.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">COMPLIANCE WITH LAWS AND ACCEPTABLE USE OF THE SERVICES</p>
				(a) You are responsible for your conduct related to the Services. Cardknox shall have the right, without notice,
				to terminate this Agreement and cease providing the Services upon any breach of this Agreement by you.
				<br />
				<br />
				(b) You will comply, at your own expense, with all laws, policies, guidelines, regulations, ordinances, and
				rules applicable to you, this Agreement, cardholder Data or Card transaction Data and/or orders of any
				governmental authority or regulatory body having jurisdiction over the subject matter hereof, including, without
				limitation, the Rules, the Federal Trade Commission and Cardknox policies and procedures. You specifically agree
				that you will not use the Services to (i) violate any laws or regulations; (ii) infringe the intellectual
				property or other rights of third parties; (iii) transmit material that contains viruses or other harmful
				computer code or files, and/or (iv) transmit any unauthorized or otherwise illegal Card transactions via the
				Services. In addition, you shall not use the Services in any manner, or in furtherance of any activity that may
				cause Cardknox to be subject to investigation, prosecution or legal action.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">CHANGES TO SERVICES</p>
				Cardknox reserves the right in its discretion and without notice, from time to time, to change, limit,
				terminate, modify, suspend or discontinue providing the Services, in whole or part.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">RESTRICTIONS ON USE</p>
				(a) As a condition of use, you agree not to use the Services for any purpose that is prohibited by this
				Agreement. You are responsible for all of your and your customers' activity in connection with the Services.
				<br />
				(b) You shall not (and shall not permit any third party to) either (i) take any action, or (ii) upload,
				download, post, submit or otherwise distribute or facilitate distribution of any information on or through the
				Services that: (a) infringes any patent, trademark, trade secret, copyright, right of privacy or other right of
				any other person or entity or violates any law or contractual duty; (b) you know is false, misleading,
				untruthful or inaccurate; (c) is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive,
				fraudulent, invasive of another's privacy, tortious, or is otherwise inappropriate as determined by Cardknox in
				its sole discretion; (d) constitutes unauthorized or unsolicited advertising, junk or bulk e-mail ("spamming");
				(e) contains software viruses or any other computer codes, files, or programs that are designed or intended to
				disrupt, damage, limit or interfere with the proper function of any software, hardware, or telecommunications
				equipment or to damage or obtain unauthorized access to any system, Data, password or other information of
				Cardknox or of any third party; (f) impersonates any person or entity, including any Cardknoxemployees or
				representatives; or (g) includes anyone's identification documents or sensitive financial information.
				<br />
				(c) You shall not (directly or indirectly): (i) interfere or attempt to interfere with the proper working of the
				Services; (ii) bypass, circumvent or attempt to bypass or circumvent any measures Cardknox may use to prevent or
				restrict access to the Services (or other accounts, computer systems or networks connected to the Services);
				(iii) decipher, decompile, disassemble, reverse engineer or otherwise attempt to derive any source code or
				underlying ideas or algorithms of any part of the Services (including without limitation any application); (iv)
				modify, translate, or otherwise create derivative works of any part of the Services; (v) copy, rent, lease,
				distribute, or otherwise transfer any of the rights that you receive hereunder. (vi) transfer any rights granted
				to you under this Agreement; or (vii) otherwise take any action in violation of Cardknox's guidelines and
				policies.
				<br />
				(d) You may only use the Services for your own use. You may not pass through, license, resell, rent, lease, or
				otherwise distribute the Services (either for a fee or without charge), or allow third parties to use the
				Services without Cardknox's express written permission. You may not use the Services to act as a payment
				intermediary, aggregator or service bureau. Except as permitted herein, you may not use the Services to handle,
				process, or transmit funds for any third party or process cash advances at any time.
				<br />
				(e) You may not remove any copyright, trademark or other intellectual property or proprietary notice or legend
				contained on the Services and you must retain all copyright, trademark, service mark and other proprietary
				notices contained within the Services on any authorized copy you make of the Services.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">INTELLECTUAL PROPERTY</p>
				The parties agree that Cardknox owns and retains all right, title and interest in and to the Services,
				copyrights and any related technology utilized under or in connection with this Agreement, including but not
				limited to all intellectual property rights associated therewith. Unless otherwise noted, the design and content
				features of the Services, including without limitation, information and other materials, graphics,
				illustrations, layout and design, icons, images, artwork, photography, text, data, and the like, as well as the
				selection, assembly and arrangement thereof, are owned by Cardknox. No title to or ownership of any of the
				foregoing is granted or otherwise transferred to you or any other entity or person under this Agreement. You are
				not authorized to use any Cardknox name or mark as a hypertext link to any website or in any advertising,
				publicity or in any other manner without the prior written consent of Cardknox.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">CARDKNOX OBLIGATIONS</p>
				Cardknox is committed to securing cardholder data. Cardknox has many levels of security to ensure that your
				cardholder data remains secure. Cardknox is responsible for the security of cardholder data it possesses or
				otherwise stores, processes, and transmits on behalf of the customer.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">YOUR REPRESENTATIONS AND WARRANTIES</p>
				You represent and warrant that at all times during the term of this Agreement: (i) all representations and
				statements made by you in this Agreement, or in any other document relating hereto by you or on your behalf, are
				true, accurate and complete in all material respects; (ii) you are engaged in a lawful business and have all
				necessary rights and authorizations to sell and distribute your products and/or services; (iii) you will comply,
				at your own expense, with all laws, policies, guidelines, regulations, ordinances or rules applicable to you,
				this Agreement, cardholder Data or transaction Data, including, without limitation: (a) the Rules; (b) the
				Payment Card Industry Data Security Standard (PCI DSS); and (c) any regulatory body or agency having
				jurisdiction over the subject matter hereof.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">PAYMENT</p>
				(a) You agree to pay Cardknox in full and when due the fees applicable to the Services as provided to you by
				Cardknox, if applicable. You also agree to pay any additional charges or fees applied to your use of the
				Services, including interest and charges due to insufficient credit or insufficient funds.
				<br />
				(b) You are also responsible, and will reimburse Cardknox, for all applicable excise, value added, utility,
				sales or use taxes and any applicable surcharges, assessments or government fees associated with the Services.
				<br />
				(c) Except amounts due and payable upon the effective date of this Agreement, charges for the Services will be
				invoiced monthly. Payment must be made within fifteen (15) days of the date of the invoice. When a billing cycle
				covers less than or more than a full month, Cardknox may make reasonable adjustments and pro-ration. If you
				signed a credit card billing authorization and provided Cardknox with a credit card number, then you expressly
				authorize Cardknox to charge any and all amounts owed to it by you to that credit card number and to demand
				immediate payment from the credit card's issuer. If you are an ACH debit payer, you authorize Cardknox to
				present Automated Clearing House credits/debits to and from the account provided to Cardknox for the purposes
				set forth in this Agreement. To the extent permitted by law, you must pay a fee (presently $25.00, but subject
				to change by Cardknox) for any of your checks returned for any reason. In the event your wireless handheld
				device is lost or stolen, you shall nonetheless be liable for all charges attributable to such device until such
				time as Cardknox is notified of the loss or theft. This Agreement shall not terminate due to any such notice.
				<br />
				(d) If Cardknox does not receive payment for your use of the Services on or before the due date, to the extent
				allowable by law, you will be subject to a late payment fee equal to the lesser of 1.5% per month on all due and
				unpaid amounts to be paid to Cardknox or the highest rate permitted by law. Acceptance of late or partial
				payments and/or late payment fees shall not waive any of Cardknox's rights to collect the full amount due under
				this Agreement. You hereby waive any objection you may have with respect to any invoice, charge or fee if you
				fail to provide Cardknox with written notice of such objection within fifteen (15) days of the date of the
				invoice. You shall pay to Cardknox $25.00 per wireless handheld device to reconnect services that have been
				terminated as provided in this Agreement.
				<br />
				<a href="https://portal.cardknox.com/Account/billingPlan">Billing Plan</a>
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">THIRD PARTY EQUIPMENT AND SERVICES</p>
				Except as may otherwise be specifically agreed to in writing between you and Cardknox , you shall be solely
				responsible for the selection, implementation, and performance of any and all third party equipment, software
				and services used by you in connection with the Services. When you access third party resources, you do so at
				your own risk. You acknowledge and agree that Cardknox shall not be responsible or liable, directly or
				indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or
				reliance on any such content, goods or services available on or through any such third party resource. CARDKNOX
				MAKES NO REPRESENTATION, WARRANTY OR GUARANTEE WHATSOEVER, EXPRESS OR IMPLIED, WITH RESPECT TO SUCH THIRD PARTY
				EQUIPMENT, SOFTWARE, PRODUCTS OR SERVICES. YOUR USE OF THIRD PARTY EQUIPMENT, SOFTWARE, PRODUCTS AND SERVICES IS
				AT YOUR OWN RISK. CARDKNOX ASSUMES NO RESPONSIBILITY AND EXPRESSLY DISCLAIMS ANY LIABILITY FOR CLAIMS OF LOSS
				AND/OR FRAUD INCURRED RESULTING FROM THE USE OF OR CONCLUSIONS DRAWN FROM ANY THIRD PARTY EQUIPMENT, SOFTWARE,
				PRODUCT OR SERVICE, REGARDLESS OF WHETHER OR NOT CARDKNOX IS A RESELLER OF OR REFERRAL AGENT FOR SUCH EQUIPMENT,
				SOFTWARE, PRODUCT OR SERVICE. YOU SHOULD DIRECTLY CONTACT THE THIRD PARTY CONCERNING ALL ISSUES THAT ARISE OUT
				OF THE USE OF SUCH THIRD PARTY EQUIPMENT, SOFTWARE, PRODUCTS AND SERVICES.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">TERM AND TERMINATION</p>
				(a) This Agreement, including any revisions, will remain in effect as long as you continue to use the Services.
				<br />
				(b) You may terminate this Agreement at any time and for any reason upon thirty (30) days prior written notice
				to Cardknox.
				<br />
				(c) In the event that Cardknox is prevented from providing any portion or all of the Services pursuant to this
				Agreement, by any law, regulation, requirement or ruling, or if a notice from a government agency or department
				indicates Cardknox is not permitted to provide any portion or all of the Services, Cardknox may immediately
				cease providing the Services without any liability whatsoever to you. Nothing herein shall be construed to
				require Cardknox, and Cardknox is not required, to seek a waiver of any law, rule, regulation, or restriction,
				or seek judicial review or appeal of any court order.
				<br />
				(d) If Customer commits or permits an Event of Default, then Cardknox may, in addition to all other rights and
				remedies provided by this Agreement or by law or equity, terminate this Agreement immediately and without
				notice. "Event of Default" shall include, but shall not be limited to, (i) failure to perform a material term or
				condition of this Agreement unless such failure is corrected within fifteen (15) days of notice from Cardknox
				advising you of the failure, (ii) your failure to pay any sums due and payable as and when required and such
				failure continuing for a period of ten (10) days after notice from Cardknox advising you of the failure to pay,
				(iii) your insolvency or failure to pay debts as they come due and (iv) you becoming subject to any proceeding
				under the Bankruptcy Act or similar laws.
				<br />
				(e) Cardknox further reserves the right to terminate this Agreement and/or your access to the Services at any
				time and for any reason, with or without notice.
				<br />
				(f) Notwithstanding any termination of this Agreement, you shall be liable and shall immediately pay to Cardknox
				all charges due, or which would have become due under this Agreement.
				<br />
				(g) Upon termination of this Agreement, or if you violate any term or condition of this Agreement, you must
				immediately stop using the Services.
				<br />
				(h) All provisions of this Agreement which by their nature should survive termination shall survive termination,
				including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of
				liability.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">FORCE MAJEURE</p>
				Cardknox shall not be liable for any failure, termination or interruption of the Services or any delay or
				failure in performance hereunder due to fires, strikes, civil disturbances, threatened strikes, stoppage of
				work, embargoes, requirements imposed by governmental or judicial regulations, rules or orders, civil or
				military authorities, acts of God (including, by way of example, weather conditions), pandemics, omissions of
				common carriers, acts of terrorism or other causes which are beyond the reasonable control of Cardknox.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">INDEMNIFICATION</p>
				You shall defend, indemnify, and hold harmless Cardknox and its affiliates, parent companies, and/or
				subsidiaries, and any of their officers, directors, agents and employees (collectively, the "Indemnified
				Parties"), from and against any and all claims, actions, proceedings, and suits and all related liabilities,
				damages, settlements, penalties, fines, costs or expenses (including reasonable attorneys' fees and other
				litigation expenses) incurred by an Indemnified Party arising out of or relating to (a) any breach or alleged
				breach by you of any of your representations, warranties, or obligations set forth in this Agreement; (b) any
				damage or loss caused by negligence, fraud, dishonesty or willful misconduct by you or any of your employees,
				agents or customers; (c) the reliability, accuracy, or legitimacy of payment Data or purchase orders submitted
				by you to Cardknox; (d) payment card transactions submitted by you to Cardknox and rejected by Cardknox or an
				issuing bank; (e) any alleged infringement of a patent, copyright, trademark or other intellectual property
				right resulting from your actions; (f) claims by cardholders, including, without limitation, claims relating to
				the disclosure of cardholder or consumer Data; or (g) any alleged or actual violation by you of any applicable
				laws, regulations, the Rules or any regulatory body or agency having jurisdiction over the subject matter
				hereof. In the event you cause fines and/or penalties to be charged to Cardknox by the Card Networks or any
				other entity, you agree to immediately reimburse Cardknox for said fines or penalties.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">DISCLAIMERS</p>
				THE SERVICES ARE PROVIDED ON AN "AS IS," "AS AVAILABLE" BASIS WITHOUT ANY REPRESENTATIONS OR WARRANTIES.
				CARDKNOX DOES NOT REPRESENT OR WARRANT THAT THE SERVICES WILL BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY,
				SECURE, ACCURATE, COMPLETE, OR ENTIRELY ERROR-FREE. YOU MAY NOT RELY UPON ANY REPRESENTATION OR WARRANTY
				REGARDING THE SERVICES BY ANY THIRD PARTY IN CONTRAVENTION OF THE FOREGOING STATEMENTS. EXCEPT AS EXPRESSLY SET
				FORTH HEREIN, CARDKNOX SPECIFICALLY DISCLAIMS ALL REPRESENTATIONS, WARRANTIES AND CONDITIONS WHETHER EXPRESS OR
				IMPLIED, ARISING BY STATUTE, OPERATION OF LAW, USAGE OF TRADE, COURSE OF DEALING, OR OTHERWISE, INCLUDING BUT
				NOT LIMITED TO, WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-
				INFRINGEMENT, OR TITLE WITH RESPECT TO THE SERVICES, OR OTHER SERVICES OR GOODS PROVIDED UNDER THIS AGREEMENT.
				YOU UNDERSTAND AND AGREE THAT CARDKNOX SHALL BEAR NO RISK WITH RESPECT TO YOUR SALE OF PRODUCTS OR SERVICES,
				INCLUDING, WITHOUT LIMITATION, ANY RISK ASSOCIATED WITH CARD FRAUD OR CHARGEBACKS. EXCEPT AS OTHERWISE PROVIDED
				IN THIS AGREEMENT, YOU EXPRESSLY AGREE THAT CARDKNOX SHALL NOT BE LIABLE FOR ANY LOSS (HOWEVER OCCURRING,
				INCLUDING NEGLIGENCE), ARISING FROM OR RELATED TO: (A) YOUR FAILURE TO PROPERLY ACTIVATE, INTEGRATE OR SECURE
				YOUR ACCOUNT; (B) FRAUDULENT TRANSACTIONS PROCESSED THROUGH YOUR ACCOUNT(S); (C) DISRUPTION OF SERVICES,
				SYSTEMS, SERVER OR WEBSITE BY ANY MEANS, INCLUDING WITHOUT LIMITATION, DDOS ATTACKS, SOFTWARE VIRUSES, TROJAN
				HORSES, WORMS, TIME BOMBS, OR ANY OTHER TECHNOLOGY; (D) ACTIONS OR INACTIONS BY ANY THIRD PARTY, INCLUDING
				WITHOUT LIMITATION, A THIRD PARTY PAYMENT PROCESSOR OR BANK; OR (E) THE LIMITATION OF THE FUNCTIONING OF ANY
				SERVICES OR SOFTWARE, HARDWARE, OR EQUIPMENT ASSOCIATED THEREWITH.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">LIMITATION OF LIABILITY</p>
				(a) CARDKNOX'S TOTAL AGGREGATE LIABILITY UNDER THIS AGREEMENT WILL NOT EXCEED THE AGGREGATE AMOUNT RECEIVED BY
				CARDKNOX FROM YOU FOR PROVIDING THE SERVICES TO YOU UNDER THIS AGREEMENT DURING THE SIX (6) MONTHS PRECEDING THE
				DATE ON WHICH THE CLAIM AROSE. THE FOREGOING LIMITATIONS SHALL APPLY TO THE FULL EXTENT PERMITTED BY LAW, AND
				ARE NOT INTENDED TO ASSERT ANY LIMITATIONS OR DEFENSES WHICH ARE PROHIBITED BY LAW.
				<br />
				(b) EXCEPT WHERE PROHIBITED BY LAW, UNDER NO CIRCUMSTANCE WILL CARDKNOX OR ANY OF ITS PARENTS, AFFILIATES OR
				VENDORS (OR ANY OFFICERS, DIRECTORS, EMPLOYEES OR AGENTS OF THE PARTIES, OR ITS PARENTS, AFFILIATES OR VENDORS)
				BE LIABLE FOR AND YOU HEREBY WAIVE YOUR RIGHT TO CLAIM ANY INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY OR
				CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING LOSS OF PROFITS, BUSINESS OR REVENUES, ANTICIPATED SAVINGS, LOSS OF
				GOODWILL OR REPUTATION, COSTS OF DELAY, LOSS OR DAMAGED DATA, COST OF CAPITAL, COST OF SUBSTITUTE GOODS,
				FACILITIES, SERVICES OR REPLACEMENT SERVICES, DOWNTIME COSTS OR THE INCURRING OF LIABILITY FOR LOSS OR DAMAGE OF
				ANY NATURE WHATSOEVER SUFFERED BY THIRD PARTIES) DIRECTLY OR INDIRECTLY RELATING TO OR ARISING OUT OF THIS
				AGREEMENT OR THE SERVICES, ALL REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING
				NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, AND WHETHER OR NOT SUCH DAMAGES WERE FORESEEN OR UNFORESEEN,
				REGARDLESS OF WHETHER IT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
				<br />
				(c) ALL LIMITATIONS AND DISCLAIMERS STATED IN THIS SECTION ALSO APPLY TO CARDKNOX'S THIRD PARTY LICENSORS,
				PROVIDERS AND SUPPLIERS, AS THIRD PARTY BENEFICIARIES OF THIS AGREEMENT.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">NO THIRD PARTY BENEFICIARIES</p>
				Except as otherwise specifically stated in this Agreement, the provisions of this Agreement are for the benefit
				of the parties hereto and not for any other person.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">NON-EXCLUSIVE</p>
				Each party acknowledges and agrees that the rights granted to the other party in this Agreement are
				non-exclusive, and that, without limiting the generality of the foregoing, nothing in this Agreement shall be
				deemed or construed to prohibit either party from participating in similar business arrangements as those
				described herein.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">INDEPENDENT CONTRACTORS</p>
				The parties are independent contractors and nothing in this Agreement shall make them joint venturers, partners,
				employees, agents or other representatives of the other party. Neither party shall make any representation that
				suggests otherwise.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">WAIVERS OF DEFAULT</p>
				The failure of any party to insist on or enforce strict performance of any provision of this Agreement or to
				exercise any right or remedy under this Agreement or applicable law will not be construed as a waiver or
				relinquishment to any extent of the right to assert or rely upon any such provision, right or remedy in that or
				any other instance; rather, the same will be and remain in full force and effect. Waiver by either party of a
				breach of any provision contained herein must be in writing, and no such waiver will be construed as a waiver of
				any other and/or succeeding breach of such provision or a waiver of the provision itself.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">GOVERNING LAW; VENUE</p>
				YOU AGREE THAT THE SUBSTANTIVE LAWS OF THE STATE OF NEW JERSEY, EXCLUDING ITS CONFLICTS OF LAWS RULES, WILL BE
				APPLIED TO GOVERN, CONSTRUE AND ENFORCE ALL OF THE RIGHTS AND DUTIES OF THE PARTIES ARISING FROM OR RELATING IN
				ANY WAY TO THE SUBJECT MATTER OF THIS AGREEMENT. YOU HEREBY IRREVOCABLY CONSENT TO THE EXCLUSIVE PERSONAL
				JURISDICTION OF AND VENUE IN THE STATE AND FEDERAL COURTS LOCATED IN MONMOUTH COUNTY, NEW JERSEY, AND WAIVE ANY
				OBJECTION TO SUCH JURISDICTION OR VENUE.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">ASSIGNMENT AND DELEGATION</p>
				Cardknox may assign this Agreement and any of its rights and obligations hereunder without notice to, or need to
				obtain consent from you, and you agree to make all subsequent payments as directed. You may not assign or
				otherwise transfer this Agreement (by operation of law or otherwise), or any of your rights or obligations
				hereunder, in whole or in part, to any other person or entity. Any attempt to do so shall be automatically null
				and void. Cardknox may perform all of the Services to be performed under this Agreement directly or may have
				some or all of the Services performed by its subsidiaries, affiliates or subcontractors.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">AMENDMENTS</p>
				No amendment, modification, or change to any provision of this Agreement, nor consent to any departure by either
				party therefrom, will in any event be effective unless the same will be in writing and signed by the other
				party, and then such consent will be effective only in the specific instance and for the specific purpose for
				which given. Notwithstanding the foregoing, Cardknox may amend this Agreement at any time upon written or
				electronic notice to you of not less than ten (10) days prior to the effective date of such amendment; provided
				that the addition or change of service fees, will become effective upon at least thirty (30) days' notice. If
				you do not agree to such amendments, your sole remedy is to cease use of the Services and immediately terminate
				this Agreement upon written notice to Cardknox. Your continued use of the Services following notice of such
				amendment will be deemed as your acceptance and agreement to such changes.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">ENTIRE AGREEMENT</p>
				This Agreement, together with all of Cardknox's policies referenced herein, sets forth the entire agreement
				between the parties, and supersedes any and all prior or contemporaneous oral or written agreements or
				understandings between the parties, as to the subject matter of this Agreement. You acknowledge that this
				Agreement reflects an informed, voluntary allocation between Cardknox and you of all risks (both known and
				unknown) associated with the Services.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">SEVERABILITY</p>
				If any provision of this Agreement is held to be invalid or unenforceable for any reason, the remaining
				provisions will continue in full force without being impaired or invalidated in any way. The parties agree to
				replace any invalid provision with a valid provision, which most closely approximates the intent and economic
				effect of the invalid provision. Headings are used for convenience of reference only and in no way define,
				limit, construe or describe the scope or extent of any section, or in any way affect this Agreement.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">OTHER AGREEMENTS</p>
				You expressly authorize Cardknox to investigate, re-examine, and report business payment and credit history at
				any time and exchange such information with others in connection with the use of the Services by you.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">NOTICES</p>
				(a) When you use the Services or send emails to Cardknox, you are communicating with Cardknox electronically.
				You consent to receive communications from Cardknox electronically. Cardknox may communicate with you by email
				or by posting notices on the merchant portal on Cardknox's website. You agree that all agreements, notices,
				disclosures and other communications that Cardknox provides to you electronically satisfy any legal requirement
				that such communications be in writing.
				<br />
				(b) Notices required under this Agreement by you must be provided to Cardknox at 465 Oak Glen Road, Howell, NJ
				07731, Attention: Customer Support or by email at support@Cardknox.com. Notice by Cardknox to you (including
				notice of changes to this Agreement) shall be deemed given when: (a) transmitted to your primary email address
				on file with Cardknox; or (b) mailed via the U.S. mail or hand-delivered to your address on file with Cardknox;
				or (c) when posted to the merchant portal. Written notices will be deemed given upon personal delivery, upon
				confirmation of receipt if sent by fax, or three (3) days after the date of mailing if sent by certified or
				registered mail, postage prepaid. Email notices shall be deemed given the next business day following the date
				delivered.
				<br />
				(c) If you send an email to Cardknox, you agree that the user ID and/or alias contained in the email is legally
				sufficient to verify you as the sender and the authenticity of the communication.
			</li>
			<li className="spc--bottom--sml">
				<p className="type--wgt--bold">LAST UPDATED</p>
			</li>
		</ol>
		<p className="spc--bottom--sml">This Agreement was last updated: December 18, 2021</p>
		<p className="spc--bottom--med">Today's date: {moment().format('MMM DD, YYYY')}</p>
		<p className="spc--bottom--sml">
			©2022 Cardknox Development Inc. All trademarks, service marks, and trade names referenced in this material are the
			property of their respective owners.
		</p>
	</div>
);

export default Merchant;
