import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ToggleCheckbox from 'components/ToggleCheckbox';
import { chain, isEqual, map, sortBy, upperCase, trim, toLower, includes } from 'lodash';

const AVS_ADVANCED_SETTINGS = [
	{ avsCode: 'nyz', description: 'Address: No Match & 5 Digit Zip: Match' },
	{ avsCode: 'yna', description: 'Address: Match & 5 Digit Zip: No Match' },
	{ avsCode: 'nnn', description: 'Address: No Match & 5 Digit Zip: No Match' },
	{ avsCode: 'nyw', description: 'Address: No Match & 9 Digit Zip: Match' },
	{ avsCode: 'xxw', description: 'Card Number Not On File' },
	{ avsCode: 'xxu', description: 'Address Information not verified for domestic transaction' },
	{ avsCode: 'xxr', description: 'Retry / System Unavailable' },
	{ avsCode: 'xxs', description: 'Service Not Supported' },
	{ avsCode: 'xxe', description: 'Retry / System Unavailable' },
	{ avsCode: 'xxg', description: 'Global Non-AVS participant' },
	{ avsCode: 'yyg', description: 'International Address: Match & Zip: Not Compatible' },
	{ avsCode: 'ygg', description: 'International Address: No Compatible & Zip: Match' },
];
const declineStreetValues = ['nnn', 'nyw', 'nyz'];
const declineZipValues = ['nnn', 'yna'];
const declineStreetAndZipValues = ['nnn', 'nyw', 'nyz', 'yna'];
class AvsSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	isBasicSettingChecked = key => {
		const { AVS } = this.props.cardknoxSettings;
		const AVSArray = chain(AVS)
			.toLower()
			.split(',')
			.sort()
			.value();

		let comparisonArray;
		switch (key) {
			case 'declineStreet':
				comparisonArray = declineStreetValues;
				break;
			case 'declineZip':
				comparisonArray = declineZipValues;
				break;
			case 'declineStreetAndZip':
				comparisonArray = declineStreetAndZipValues;
				break;
			default:
				return false;
		}

		return isEqual(AVSArray, sortBy(comparisonArray));
	};

	handleCheckboxChange = (id, checked) => {
		this.props.handleBlockChange(true);
		const cardknoxSettings = { ...this.props.cardknoxSettings };

		if (id.startsWith('decline')) {
			this.handleDeclineChange(id, checked, cardknoxSettings);
		} else {
			this.handleCategoryChange(id, checked, cardknoxSettings);
		}

		this.props.handleSettingsUpdate('cardknoxSettings', cardknoxSettings);
	};

	handleDeclineChange = (id, checked, cardknoxSettings) => {
		if (!checked) return;

		switch (id) {
			case 'declineStreet':
				cardknoxSettings.AVS = declineStreetValues.join(',');
				break;
			case 'declineZip':
				cardknoxSettings.AVS = declineZipValues.join(',');
				break;
			case 'declineStreetAndZip':
				cardknoxSettings.AVS = declineStreetAndZipValues.join(',');
				break;
			default:
				break;
		}
		// This is to remove the AVS value if it is the same as the previous state meaning same checkbox was clicked
		if (this.props.cardknoxSettings.AVS === cardknoxSettings.AVS) {
			cardknoxSettings.AVS = '';
		}
	};

	handleCategoryChange = (id, checked, cardknoxSettings) => {
		if (id === 'CVV') {
			this.handleCvvChange(id, checked, cardknoxSettings);
		} else {
			this.handleOtherChange(id, checked, cardknoxSettings);
		}
	};

	handleCvvChange = (id, checked, cardknoxSettings) => {
		cardknoxSettings[id] = checked ? 'N' : '';
	};

	handleOtherChange = (id, checked, cardknoxSettings) => {
		const [category, key] = id.split('.');

		if (!cardknoxSettings[category]) {
			cardknoxSettings[category] = '';
		}

		const values = cardknoxSettings[category].split(',').filter(Boolean);

		if (checked) {
			this.updateValues(values, key);
		}

		cardknoxSettings[category] = values.join(',');
	};

	updateValues = (values, key) => {
		if (!values.includes(key)) {
			values.push(key);
		} else {
			const index = values.indexOf(key);
			if (index > -1) {
				values.splice(index, 1);
			}
		}
	};
	isAvsCodeIncluded = avsCode => {
		const avsCodes = map(this.props.cardknoxSettings.AVS.split(','), toLower);
		return includes(avsCodes, toLower(avsCode));
	};

	renderBasicSettings = () => {
		return (
			<div className="spc--bottom--med--alt">
				<p className="type--color--text--light spc--bottom--med">
					Choose from the following options to easily select common options:
				</p>
				<div className="spc--bottom--sml--alt">
					<div className="spc--bottom--sml--alt">
						<input
							type="radio"
							id="declineStreet"
							name="basicAVS"
							className="input input--radio"
							checked={this.isBasicSettingChecked('declineStreet')}
							onClick={e => this.handleCheckboxChange('declineStreet', e.target.checked)}
							disabled={this.props.isLoading}
						/>
						<label htmlFor="declineStreet" className="type--color--text--medium type--wgt--medium">
							Decline transaction if Street does not match
						</label>
					</div>
					<div className="spc--bottom--sml--alt">
						<input
							type="radio"
							id="declineZip"
							name="basicAVS"
							className="input input--radio"
							checked={this.isBasicSettingChecked('declineZip')}
							onClick={e => this.handleCheckboxChange('declineZip', e.target.checked)}
							disabled={this.props.isLoading}
						/>
						<label htmlFor="declineZip" className="type--color--text--medium type--wgt--medium">
							Decline transaction if ZIP does not match
						</label>
					</div>
					<div>
						<input
							type="radio"
							id="declineStreetAndZip"
							name="basicAVS"
							className="input input--radio"
							checked={this.isBasicSettingChecked('declineStreetAndZip')}
							onClick={e => this.handleCheckboxChange('declineStreetAndZip', e.target.checked)}
							disabled={this.props.isLoading}
						/>
						<label htmlFor="declineStreetAndZip" className="type--color--text--medium type--wgt--medium">
							Decline transaction if either Street or ZIP do not match
						</label>
					</div>
				</div>
			</div>
		);
	};
	renderAdvancedSettings = () => {
		return (
			<div className="spc--bottom--med--alt">
				<p className="type--color--text--light spc--bottom--med">
					Select any additional AVS responses you'd like to block:
				</p>
				<table className="table table--secondary table--secondary--alt spc--bottom--med">
					<colgroup>
						<col width="40" />
						<col width="95" />
						<col />
					</colgroup>
					<thead>
						<tr>
							<th>Block</th>
							<th>AVS Code</th>
							<th>Description</th>
						</tr>
					</thead>
					<tbody>
						{map(AVS_ADVANCED_SETTINGS, ({ avsCode, description }) => {
							const settingKey = `AVS.${avsCode}`;
							return (
								<tr key={settingKey}>
									<td>
										<ToggleCheckbox
											key={settingKey}
											id={avsCode}
											checked={this.isAvsCodeIncluded(avsCode)}
											onChange={value => this.handleCheckboxChange(settingKey, value)}
											disabled={this.props.isLoading}
										/>
									</td>
									<td>{upperCase(avsCode)}</td>
									<td className="type--color--text--light">{description}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		);
	};

	handleExcludeAnyCardsChange = e => {
		this.props.handleSettingsUpdate('cardknoxSettings', {
			...this.props.cardknoxSettings,
			AVS_USOnly: e.target.checked,
		});
	};
	render() {
		const { cardknoxSettings, isExpanded, toggleExpand } = this.props;
		return (
			<div>
				<div className="spr__card clearfix">
					<button className={trim(`spr__heading ${isExpanded ? 'is-expanded' : ''}`)} onClick={toggleExpand}>
						<h4 className="spr__heading__title">AVS & CVV Settings</h4>
						<i
							className={`icon icon--tiny icon--arrow--right--grey spr__heading__expand ${
								isExpanded ? 'is-expanded' : ''
							}`}
						></i>
					</button>
					{isExpanded && (
						<div>
							<h4 className="type--lrg type--wgt--semibold spc--bottom--med">CVV Settings</h4>
							<div className="spc--bottom--med--alt">
								<input
									type="checkbox"
									id="cvv"
									name="cvv"
									className="input input--check"
									value={cardknoxSettings.CVV}
									checked={toLower(cardknoxSettings.CVV) === 'n'}
									onChange={e => this.handleCheckboxChange('CVV', e.target.checked)}
									disabled={this.isLoadind}
								/>
								<label htmlFor="cvv" className="type--color--text--medium type--wgt--medium datatooltip--w--200">
									Block mismatch
								</label>
							</div>
							<div className="separator separator--grey1 spc--bottom--med--alt"></div>
							<h4 className="type--lrg type--wgt--semibold spc--bottom--med--alt">AVS Settings</h4>
							{this.renderBasicSettings()}
							{this.renderAdvancedSettings()}
							<div className="spc--bottom--med--alt">
								<input
									type="checkbox"
									id="excludeAnyCards"
									name="excludeAnyCards"
									checked={cardknoxSettings.AVS_USOnly}
									onChange={this.handleExcludeAnyCardsChange}
									className="input--check"
									disabled={this.isLoadind}
								/>
								<label htmlFor="excludeAnyCards" className="type--wgt--medium type--color--black">
									Exclude any cards issued outside of the US from AVS block.
								</label>
							</div>
							<p className="type--sml--plus type--color--black spc--bottom--med--alt spc--top--med">
								Read more about AVS Settings{' '}
								<a
									className="anchor anchor--primary"
									target="_blank"
									rel="noopener noreferrer"
									href="https://docs.cardknox.com/cardknox-products/merchant-portal#fraud-settings"
								>
									here
								</a>.
							</p>
						</div>
					)}
				</div>
			</div>
		);
	}
}
AvsSettings.propTypes = {
	isExpanded: PropTypes.bool.isRequired,
	toggleExpand: PropTypes.func.isRequired,
	cardknoxSettings: PropTypes.object.isRequired,
	handleSettingsUpdate: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	handleBlockChange: PropTypes.func.isRequired,
};
export default AvsSettings;
