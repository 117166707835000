import { Hub, Auth } from 'aws-amplify';
import { get, noop } from 'lodash';

let token = '';
Hub.listen('auth', updateToken);
Auth.currentAuthenticatedUser()
	.then(data => {
		if (data) {
			updateToken({ payload: { event: 'signIn', data } });
		}
	})
	.catch(() => noop);

function updateToken({ payload: { event, data } }) {
	if (event === 'signIn') {
		token = get(data, 'signInUserSession.idToken.jwtToken', '');
	} else if (event === 'signOut' || event === 'oAuthSignOut') {
		token = '';
	}
}
export default function cognitoTokenHandler() {
	return token;
}
