import moment from 'moment';
import { displayToApi, toHebrew } from './../../../utilities';

export const calendarTypes = {
	GREGORIAN: 'Gregorian',
	HEBREW: 'Hebrew',
};

export const until = {
	NEVER: 'Never',
	ENDDATE: 'Date',
	PAYMENTS: 'Number of Payments',
};

const yesterdayMoment = moment().subtract(1, 'day');
export const todayMoment = moment();
const tomorrowMoment = moment().add(1, 'day');

export const yesterday = {
	[calendarTypes.GREGORIAN]: yesterdayMoment.format(ApplicationSettings.apiDateFormat),
	[calendarTypes.HEBREW]: displayToApi(toHebrew(yesterdayMoment.toDate())),
};

export const today = {
	[calendarTypes.GREGORIAN]: todayMoment.format(ApplicationSettings.apiDateFormat),
	[calendarTypes.HEBREW]: displayToApi(toHebrew(todayMoment.toDate())),
};

export const tomorrow = {
	[calendarTypes.GREGORIAN]: tomorrowMoment.format(ApplicationSettings.apiDateFormat),
	[calendarTypes.HEBREW]: displayToApi(toHebrew(tomorrowMoment.toDate())),
};

export const sections = {
	GENERAL: 'general',
	PAYMENTS: 'payments',
	SCHEDULES: 'schedules',
	TRANSACTIONS: 'transactions',
};

export const threeDS2ProcessTooltip =
	'Note that transactions processed via the Merchant Portal will not be authenticated for 3D Secure. Please use the PaymentSITE to run 3D Secure authenticated transactions.';
