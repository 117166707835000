import React from 'react';
import PropTypes from 'prop-types';

const PopupGridTooltip = ({ tooltip, infoDimensions }) => {
	if (!tooltip) {
		return null;
	}
	return (
		<div
			style={{
				left: `${infoDimensions.width || 0}px`,
				top: `${infoDimensions.height || 0}px`,
				position: 'fixed',
				backgroundColor: '#fff',
				zIndex: 99,
				transform: 'translate(calc(-290%), calc(-410%))',
			}}
		>
			{tooltip}
		</div>
	);
};

PopupGridTooltip.propTypes = {
	tooltip: PropTypes.any,
	infoDimensions: PropTypes.object,
};

export default PopupGridTooltip;
