import React from 'react';

const withForwardRef = (WrappedComponent, wrappingComponent) => {
	const WrappingComponent = wrappingComponent(({ forwardRef, ...otherProps }) => (
		<WrappedComponent ref={forwardRef} {...otherProps} />
	));
	const WithForwardRef = React.forwardRef((props, ref) => <WrappingComponent {...props} forwardRef={ref} />);
	return WithForwardRef;
};

export default withForwardRef;
