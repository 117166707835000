export const afterMaxRetriesActionOptions = [
	{
		name: 'ContinueNextInterval',
		label: 'Maintain recurring schedule',
		tooltip: "The customer's recurring payment will resume on the next scheduled date.",
	},
	{
		name: 'Disable',
		label: 'Disable recurring schedule',
		tooltip: "You can reactivate a customer's recurring schedule on the customer's Recurring Schedule screen.",
	},
];
export const newAccountAfterMaxRetriesTooltip =
	'New accounts cannot configure what happens if all retry attempts fail for the first seven months of processing with Cardknox.';
export const warningMessage =
	'Cardknox does not recommend setting the number of retries to be higher than 5 because it can potentially expose your account to risk-related issues.';
export const retrySettingKeysList = ['failedTransactionRetryTimes', 'afterMaxRetriesAction', 'daysBetweenRetries'];
export const maxRetriesReachedNotificationTooltip =
	'Cardknox will send this report when the maximum number of retries (specified above) has been reached.';
