import React, { Fragment, createRef } from 'react';
import BaseSettings from './BaseSettings';
import PropTypes from 'prop-types';
import { map, toLower, includes, split, cloneDeep, isEmpty, each, every, sortBy } from 'lodash';

import { withBlock } from 'common/components/block';
import { withCancelable } from 'common/components/cancelable';
import { withLoader } from 'common/components/loader';
import { withError } from 'common/components/error';
import { Notification } from 'common/components/notifications';
import { kvaasService } from 'common/services';
import { kvaasResources } from 'common/utilities';
import { DraggableColumn } from 'common/components/settings';
const handleInvalidRevision = error => {
	if (
		error &&
		(toLower(error.message) === 'invalid: revision' || toLower(error.message) === 'item exists. revision cannot be 0')
	) {
		return {
			...error,
			message: 'The data you were trying to update is not up to date. Fetching latest data...',
		};
	}
	return error;
};

const requestKeys = {
	FETCH: 'fetch',
	SAVE: 'save',
	KVAAS: 'kvaas',
	REFRESH: 'refresh',
};

class RecurringSettingsComponent extends BaseSettings {
	constructor(props) {
		super(props);

		this.top = createRef();
		this.notification = createRef();

		this.state = {
			reportType: 'recurringReport',
			sectionsExpanded: {
				columns: false,
			},
			isSaving: false,
			expanded: false,
			errorMessages: [],
			recurringReport: {
				fields: [...this.recurringInitialFields],
				defaultValues: {
					activeDefault: false,
				},
				oldData: {
					defaultValues: {
						data: {
							isDefault: null,
						},
					},
					hide: null,
					order: null,
				},
			},

			oldData: {
				userSettings: null,
				recurringReport: null,
			},

			userSettings: {
				hideSkipSabbath: false,
			},
		};
	}

	mapStateToRequest = (data, oldData) => {
		return {
			newData: {
				revision: 0,
				data,
			},
			oldData,
		};
	};

	componentDidMount = async () => {
		await this.fetchData();
	};

	fetchData = async refreshData => {
		const {
			props: { showLoader, handleError },
			fetchKvaas,
		} = this;
		try {
			showLoader(true);
			const [
				{
					userSettings,
					recurringReportDefaultColumns,
					recurringReportHiddenFields,
					recurringReportDefaultValues,
					recurringReportOrder,
				},
			] = await Promise.all([fetchKvaas(refreshData)]);
			const newState = this.mapResponseToState(
				recurringReportDefaultColumns,
				recurringReportDefaultValues,
				recurringReportHiddenFields,
				recurringReportOrder,
				userSettings
			);
			this.setState(newState, () => {
				if (!isEmpty(newState.errorMessages)) {
					this.scrollToTop();
				}
			});
			showLoader(false);
		} catch (e) {
			showLoader(false);
			if (handleError(e)) {
				this.props.handleKvaasLoadError();
			}
		}
	};

	fetchKvaas = async refreshData => {
		const [
			userSettings,
			recurringReportDefaultColumns,
			recurringReportHiddenFields,
			recurringReportDefaultValues,
			recurringReportOrder,
		] = await this.props.makePendingRequest(
			kvaasService.get(
				{ ...kvaasResources.userSettings, throwError: true },
				{ ...kvaasResources.recurringReportDefaultColumns, throwError: true },
				{ ...kvaasResources.recurringReportHiddenFields, throwError: true },
				{ ...kvaasResources.recurringReportDefaultValues, throwError: true },
				{ ...kvaasResources.recurringReportOrder, throwError: true }
			),
			refreshData ? requestKeys.REFRESH : requestKeys.KVAAS
		);
		return {
			userSettings,
			recurringReportDefaultColumns,
			recurringReportHiddenFields,
			recurringReportDefaultValues,
			recurringReportOrder,
		};
	};

	setStateAsync = newState => {
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	scrollToTop = () => {
		if (this.top.current) {
			this.top.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
		}
	};

	toggleExpandCollapseSection = section => () => {
		this.setState({
			sectionsExpanded: { ...this.state.sectionsExpanded, [section]: !this.state.sectionsExpanded[section] },
		});
	};

	save = async setToDefaults => {
		let refreshData = false;
		let refNum;
		let error;
		this.props.showLoader(true);
		const mappedState = await this.mapStateToRequiredFields(setToDefaults);
		try {
			const [[userSettings, order, isDefault, defaultValues]] = await this.props.makePendingRequest(
				Promise.all([kvaasService.save(...mappedState)]),
				requestKeys.SAVE
			);
			const newState = this.mapResponseToState(isDefault, defaultValues, order, userSettings);
			refNum = userSettings.refNum;
			if (setToDefaults) {
				newState.recurringReport.defaultValues = { activeDefault: false };
				newState.userSettings = {
					hideSkipSabbath: false,
				};
			}
			this.props.showLoader(false);
			this.setState(newState, () => {
				if (!isEmpty(newState.errorMessages)) {
					this.scrollToTop();
				}
			});
			this.props.handleBlockChange(false);
		} catch (e) {
			error = this.props.handleError(e, { delayMessage: true });
			if (error) {
				refreshData = true;
			} else {
				return;
			}
		}
		if (refreshData) {
			try {
				await this.fetchData(true);
			} catch (e) {
				error = this.props.handleError(e, { delayMessage: true });
				if (error) {
					refreshData = true;
				} else {
					return;
				}
			}
			this.props.showLoader(false);
			if (!isEmpty(this.state.errorMessages)) {
				this.scrollToTop();
			} else {
				error.show();
			}
		}
		if (!error) {
			this.notification.current.addNotification({
				message: setToDefaults ? (
					'Recurring settings reset to default'
				) : (
					<React.Fragment>
						<p className="spc--bottom--sml">Recurring Settings Updated</p>
					</React.Fragment>
				),
				ref: refNum,
				success: true,
			});
		}
	};

	getUserSettingsSaveData = setToDefaults => {
		if (setToDefaults) {
			return {
				hideSkipSabbath: false,
			};
		}

		return this.state.userSettings;
	};
	mapStateToRequiredFields = async setToDefaults => [
		this.mapStateToFields('recurringReport', 'order', kvaasResources.recurringReportOrder, setToDefaults),
		this.mapStateToFields('recurringReport', 'hide', kvaasResources.recurringReportHiddenFields, setToDefaults),
		this.mapStateToFields('recurringReport', 'isDefault', kvaasResources.recurringReportDefaultColumns, setToDefaults),
		{
			newData: {
				revision: 0,
				data: setToDefaults ? { activeDefault: false } : this.state.recurringReport.defaultValues,
			},
			oldData: this.state.recurringReport.oldData.defaultValues,
			...kvaasResources.recurringReportDefaultValues,
		},
		{
			newData: {
				revision: 0,
				data: this.getUserSettingsSaveData(setToDefaults),
			},
			oldData: this.state.oldData.userSettings,
			...kvaasResources.userSettings,
		},
	];

	mapResponseToState = (
		oldDefault,
		oldValues,
		oldHide,
		oldOrder,
		oldUserSettings = this.state.oldData.userSettings
	) => {
		const { recurringSchedules, userSettings, oldData, recurringReport } = cloneDeep(this.state);
		const newState = {
			displayRetrySettingsDisclaimer: false,
			errorMessages: [],
			oldData,
			recurringSchedules,
			userSettings,
			recurringReport,
		};
		this.mapFieldsToState(newState.recurringReport, oldDefault, 'isDefault');
		this.mapDefaultValueToState(newState.recurringReport, oldValues, 'defaultValues');
		oldHide && this.mapFieldsToState(newState.recurringReport, oldHide, 'hide');
		this.mapFieldsToState(newState.recurringReport, oldOrder, 'order');
		this.mapSettingsToState(newState, oldUserSettings, 'userSettings');
		newState.recurringReport.fields = sortBy(newState.recurringReport.fields, ({ order }) => order);
		return newState;
	};
	mapRetries = (data, newState, type) => {
		each(data, (value, key) => {
			newState[type][key] = value;
		});
	};

	mapSettingsToState = (newState, oldData, type) => {
		const { data, result, error, refNum } = oldData;
		if (data && (toLower(result) === 's' || error === 'Item does not exist')) {
			if (!error) {
				newState.oldData[type] = {
					...oldData,
				};
			}
			this.mapRetries(data, newState, type);
		} else if (toLower(error) === 'invalid: revision' || toLower(error) === 'item exists. revision cannot be 0') {
			throw {
				isApiError: true,
				ref: refNum,
				message: error,
				success: false,
			};
		} else {
			newState.errorMessages.push(`${error}${refNum ? ` (Ref# ${refNum})` : ''}`);
		}
	};

	handleCheckboxChange = ({ target: { name, checked } }) => {
		const newState = cloneDeep(this.state);

		if (includes(name, '.')) {
			const [section, key] = split(name, '.');
			newState[section][key] = checked;
		} else {
			newState[name] = checked;
		}

		this.setState(newState);
		this.props.handleBlockChange(true);
	};

	handleSelectChange = ({ name: value }, { name }) => {
		const newState = {};
		if (includes(name, '.')) {
			const [section, key] = split(name, '.');

			newState[section] = {
				...this.state[section],
				[key]: value,
			};
		} else {
			newState[name] = value;
		}
		this.setState(newState);
		this.props.handleBlockChange(true);
	};

	handleNumberChange = ({ value }, { target: { name } }) => {
		const newState = {};
		if (includes(name, '.')) {
			const [section, key] = split(name, '.');
			newState[section] = {
				...this.state[section],
				[key]: value,
			};
		} else {
			newState[name] = value;
		}
		this.setState(newState);
		this.props.handleBlockChange(true);
	};

	renderConfig = () => {
		const { fields } = this.state.recurringReport;
		const selectValue = every(fields, i => i.hide || i.isDefault);
		const selectLabel = selectValue ? 'Unselect all' : 'Select all';
		return (
			<div>
				<div className="settings--report__header spc--bottom--sml--alt">
					<div className="settings__transaction__heading">Show in Report</div>

					<div className="type--sml settings__transaction__manual">
						<i className="icon icon--xsml icon--drag icon--middle spc--right--tny"></i>
						Drag to edit order
					</div>
				</div>

				<hr className="separator separator--grey1 spc--bottom--sml" />

				<div className="w--max--570">
					<div className="spc--bottom--sml">
						<input
							type="checkbox"
							name="selectAll"
							id="selectAll"
							checked={selectValue}
							className="input--check"
							onChange={this.handleChange}
						/>
						<label htmlFor="selectAll" className="type--color--text--medium type--wgt--medium type--base">
							{selectLabel}
						</label>
					</div>
					<hr className="separator separator--grey1" />
					<DraggableColumn
						items={fields}
						disable={false}
						onChange={this.handleChange}
						onHover={this.moveColumn}
						removeCustomField={this.removeCustomField}
					/>
				</div>
				{this.canAddCustomFields ? (
					<div className="spc--bottom--med spc--top--med">
						<a onClick={this.addCustomField} className="anchor anchor--primary anchor--underline">
							Add Custom Field
						</a>
					</div>
				) : null}
			</div>
		);
	};

	renderHeaderButtons = () => (
		<Fragment>
			<span className="settings__header__action__text">
				Settings are saved only after clicking on <strong>Save</strong> button
			</span>
			{this.renderSetToDefaultButton()}
			{this.renderSaveButton()}
		</Fragment>
	);

	renderSaveButton = () => {
		return (
			<button
				className="btn btn--primary btn--med spc--bottom--sml"
				disabled={this.props.isLoading}
				onClick={() => this.save(false)}
			>
				Save
			</button>
		);
	};

	renderSetToDefaultButton = () => (
		<button
			className="btn btn--ghost btn--med spc--right--sml spc--bottom--sml"
			disabled={this.props.isLoading}
			onClick={() => this.save(true)}
		>
			Reset to Default
		</button>
	);

	render = () => {
		const {
			errorMessages,
			userSettings: { hideSkipSabbath },
			recurringReport: { defaultValues },
			sectionsExpanded,
		} = this.state;
		return (
			<div className="settings--main settings--main--alt">
				<div className="settings__header">
					<div className="settings__header__title">Recurring Settings</div>
					<div className="settings__header__action">{this.renderHeaderButtons()}</div>
				</div>
				<div ref={this.top}></div>
				{this.state.errorMessages.length > 0
					? map(errorMessages, (error, index) => (
							<div key={index} className="validation spc--bottom--med">
								<p className="type--error">{error}</p>
							</div>
					  ))
					: null}

				<div className="spr__card clearfix">
					<div
						className={`spr__heading cursor--pointer ${sectionsExpanded.columns ? 'is-expanded' : ''}`}
						onClick={this.toggleExpandCollapseSection('columns')}
					>
						<h4 className="spr__heading__title">Manage Columns</h4>
						<i
							className={`icon icon--tiny icon--arrow--right--grey spr__heading__expand ${
								sectionsExpanded.columns ? 'is-expanded' : ''
							}`}
						></i>
					</div>
					{sectionsExpanded.columns && this.renderConfig()}
				</div>
				<div className="spr__card clearfix">
					<div className="spr__content">
						<div>
							<div className="display--ib spc--bottom--sml">
								<input
									type="checkbox"
									id="activeDefault"
									name="activeDefault"
									checked={defaultValues.activeDefault}
									value={defaultValues.activeDefault}
									onChange={this.handleActiveDefaultChange}
									className="input input--check"
								/>
								<label htmlFor="activeDefault" className="type--color--text--medium type--wgt--medium">
									View active schedules by default
								</label>
							</div>
						</div>
						<div className="display--ib spc--bottom--sml">
							<input
								type="checkbox"
								id="userSettings.hideSkipSabbath"
								name="userSettings.hideSkipSabbath"
								className="input input--check"
								checked={hideSkipSabbath}
								onChange={this.handleCheckboxChange}
							/>
							<label htmlFor="userSettings.hideSkipSabbath" className="type--color--text--medium type--wgt--medium">
								Hide 'Skip Sabbath And Holidays' Checkbox in Recurring Schedules Dialog Box
							</label>
						</div>
					</div>
				</div>
				<Notification ref={this.notification} />
			</div>
		);
	};
}

RecurringSettingsComponent.propTypes = {
	isLoading: PropTypes.bool,
	handleError: PropTypes.func.isRequired,
	makePendingRequest: PropTypes.func.isRequired,
	showLoader: PropTypes.func.isRequired,
	handleBlockChange: PropTypes.func.isRequired,
};

export default withError(withLoader(withCancelable(withBlock(RecurringSettingsComponent))), handleInvalidRevision);
