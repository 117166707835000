import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { cloneDeep, each, map, includes, filter as filterMethod } from 'lodash';
import Select from 'react-select';

const transactionTypeOptions = [
	{ value: 'all', label: 'All' },
	{ value: 'sale', label: 'Sale' },
	{ value: 'auth', label: 'Auth' },
	{ value: 'capture', label: 'Capture' },
	{ value: 'refund', label: 'Refund' },
	{ value: 'credit', label: 'Credit' },
];

class TransactionTypeReportsFilter extends Component {
	constructor(props) {
		super(props);
		this.onFilterChanged = this.onFilterChanged.bind(this);

		this.state = {
			transactionTypeSelectedOptions: [],
			transactionTypeOptions: filterMethod(
				transactionTypeOptions,
				({ value }) => value !== 'auth' || !props.status.hasSelection
			),
			statusHasSelection: props.status.hasSelection,
		};
	}

	componentDidUpdate = prevProps => {
		if (prevProps.filter !== this.props.filter) {
			const transactionTypeSelectedOptions = this.updateSelectedOption(
				map(this.props.filter.values, (value, key) => ({ value, key }))
			);
			this.setState({ transactionTypeSelectedOptions });
		}
		if (this.state.statusHasSelection !== this.props.status.hasSelection) {
			const newOptions = filterMethod(
				transactionTypeOptions,
				({ value }) => value !== 'auth' || !this.props.status.hasSelection
			);
			this.setState({ transactionTypeOptions: newOptions, statusHasSelection: this.props.status.hasSelection });
		}
	};

	updateSelectedOption = values => {
		const { transactionTypeOptions } = this.state;
		const selectedValues = filterMethod(values, ({ value }) => !!value);
		const selectedKeys = map(selectedValues, 'key');
		const newOptions = [];

		each(transactionTypeOptions, option => {
			if (includes(selectedKeys, option.value)) {
				newOptions.push(option);
			}
		});

		return newOptions;
	};

	onFilterChanged = filter => {
		const transactionTypeSelectedOptions = this.updateSelectedOption(filter.values);
		filter.emptyValue = false;
		this.setState({ transactionTypeSelectedOptions }, () => this.props.onFilterChanged(filter));
	};

	handleTransactionTypeChange = (selected, changed) => {
		let options = selected;
		if (changed.action === 'select-option') {
			if (changed.option.value === 'all') {
				const { transactionTypeOptions } = this.state;
				options = cloneDeep(transactionTypeOptions);
				options.shift();
			}
		} else if (changed.action === 'remove-value') {
			options = selected;
		} else if (changed.action === 'clear') {
			options = [];
		}

		this.setState(
			{
				transactionTypeSelectedOptions: options,
			},
			() => this.updateFilterFromState()
		);
	};

	updateFilterFromState = () => {
		const selectedValues = map(this.state.transactionTypeSelectedOptions, 'value');
		const values = cloneDeep(this.props.filter.defaultValues);
		let updateValues = [];
		for (let [key] of Object.entries(values)) {
			updateValues.push({
				key: key,
				value: selectedValues.includes(key) ? true : false,
			});
		}
		this.props.onFilterChanged({
			id: 'transactionType',
			values: updateValues,
			emptyValue: false,
		});
	};

	render() {
		const { transactionTypeSelectedOptions, transactionTypeOptions } = this.state;

		return (
			<Select
				value={transactionTypeSelectedOptions}
				isMulti={true}
				options={transactionTypeOptions}
				onChange={this.handleTransactionTypeChange}
				className="react-select-container"
				classNamePrefix="react-select"
				closeMenuOnSelect={false}
			/>
		);
	}
}

TransactionTypeReportsFilter.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
	status: PropTypes.object,
};

export default TransactionTypeReportsFilter;
