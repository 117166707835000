import moment from 'moment';
import { find } from 'lodash';

import { getDate, localToApiMoment, createFilters, getFilterValues } from 'common/utilities';

const { checkDateFormat } = ApplicationSettings;

export const giftSummaryFilter = createFilters([
	{
		key: 'date',
		getSelectionText: ({ key, startDate, endDate }, predefinedDates) => {
			const predefinedDate = find(predefinedDates, { key });
			if (predefinedDate) {
				return predefinedDate.displayValue;
			}
			try {
				return `${startDate.format(checkDateFormat)} - ${endDate.format(checkDateFormat)}`;
			} catch (e) {
				return 'Custom';
			}
		},
		values: {
			key: '90',
			startDate: moment()
				.startOf('day')
				.add(-90, 'days'),
			endDate: moment().endOf('day'),
		},
		defaultValues: {
			key: '90',
			startDate: moment()
				.startOf('day')
				.add(-90, 'days'),
			endDate: moment().endOf('day'),
		},
		props: {
			maxDaysRange: 100,
		},
	},
]);

export const compileFilter = async (filters, filterDateFormat) => {
	const { date } = getFilterValues(filters);
	const beginDate = await localToApiMoment(date.startDate);
	const endDate = await localToApiMoment(date.endDate);

	const filter = {
		xCommand: 'Report:GiftSummary',
		xBeginDate: getDate(beginDate, filterDateFormat),
		xEndDate: getDate(endDate, filterDateFormat),
	};

	return filter;
};
