import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { forOwn, cloneDeep, filter, find, trim, get } from 'lodash';
import moment from 'moment';
import Select from 'react-select';

import QuickReportsFilterComponent from './quick-reports-filter';
import ExpandFilterContainerComponent from './expand-filter-container';
import StatusReportsFilter from './StatusFilter';
import TransactionTypeReportsFilter from './TransactionTypeFilter';
import { predefinedDates, DatePickerPredefined } from 'common/components/date-picker';
import { TransactionsSortBy } from 'common/sorters/transactions-sort-options';
import { UserAccountPanel } from 'common/components/user-account-panel';
import { OutsideClick } from 'common/utilities';
import { QuickReports } from './quick-reports';
import { principalService } from 'common/services';
import {
	NumberFilter,
	StringFilter,
	SelectFilter,
	LastDigitsFilter,
	CardTypeFilter,
	CustomFieldsFilter,
} from 'common/components/filters';
import { OtherReports } from './other-reports';
import sectionKeys from 'routing/sections';

class MainFilterComponent extends Component {
	constructor(props) {
		super(props);

		this.references = {};

		const sortOptions = this.getSortOptions();

		this.state = {
			activeKeys: [],
			isExpanded: false,
			transactionsSortBy: sortOptions[0],
			transactionStatusSelectedOptions: null,
			dates: cloneDeep(predefinedDates),
			editableFilter: '',
			permissions: {},
		};
	}

	componentDidMount() {
		const principal = principalService.get();
		this.references = {
			date: React.createRef(),
			status: React.createRef(),
			transactionType: React.createRef(),
			amount: React.createRef(),
			cardNumber: React.createRef(),
			cardholderName: React.createRef(),
			referenceNumber: React.createRef(),
			invoice: React.createRef(),
			cvv: React.createRef(),
			avs: React.createRef(),
			cardType: React.createRef(),
			batch: React.createRef(),
			description: React.createRef(),
			authCode: React.createRef(),
			terminal: React.createRef(),
			poNumber: React.createRef(),
			orderId: React.createRef(),
			custom: React.createRef(),
		};
		this.csRep = principal.hasAccess[sectionKeys.portalManagement];

		const permissions = (principal && principal.idInfo && principal.idInfo.permissions) || {};
		this.setState({ permissions });
	}

	componentDidUpdate = prevProps => {
		if (prevProps.transactionsSortBy !== this.props.transactionsSortBy) {
			const options = this.getSortOptions();
			const transactionsSortBy = find(options, ({ value }) => value === this.props.transactionsSortBy.key);
			if (transactionsSortBy) {
				this.setState({ transactionsSortBy });
			}
		}
	};

	parseFilters = (filters, filterItem) => {
		let hasSelection = false;
		forOwn(filters.values, function(value, key) {
			const valueItem = filter(filterItem.values, { key: key });
			if (valueItem.length > 0) {
				filters.values[key] = valueItem[0].value;
				if (moment.isMoment(filters.values[key])) {
					if (!filters.values[key].isSame(filterItem.emptyValue, 'day')) {
						hasSelection = true;
					}
				} else if (
					filters.type !== 'object' &&
					filters.values[key] !== undefined &&
					filters.values[key] !== null &&
					trim(filters.values[key]) &&
					filters.values[key] !== filterItem.emptyValue
				) {
					hasSelection = true;
				} else if (
					filters.type === 'object' &&
					filters.values[key] !== undefined &&
					filters.values[key] !== null &&
					trim(filters.values[key]) &&
					filters.values[key] !== filterItem.emptyValue[key]
				) {
					hasSelection = true;
				}
			} else {
				if (filters.type === 'object') {
					filters.values[key] = filterItem.emptyValue[key];
				} else {
					filters.values[key] = filterItem.emptyValue;
				}
			}
		});
		filters.hasSelection = hasSelection;
	};

	renderCustomSubMenuItemTitle = (_, key) => {
		const item = this.getItem(key);
		return (
			<span>
				<span className="selected rc-menu-input-text">
					{item.getSelectionText && item.getSelectionText(item.values, this.state.dates, true)}
				</span>
			</span>
		);
	};

	onOpenChange = activeKeys => {
		this.setState({ activeKeys: activeKeys });
	};

	getItem = key => {
		const item = filter(this.props.filters, { key: key })[0];
		return item;
	};

	getActiveItem = key => {
		const item = filter(this.props.activeFilters, { key: key })[0];
		return item;
	};

	onFilterChanged = item => {
		const filter = this.getItem(item.id);
		this.parseFilters(filter, item);
		this.setState({ activeKeys: [] });
		this.props.onFiltersUpdate(this.props.filters);
	};

	onActiveFilterChanged = item => {
		const filter = this.getActiveItem(item.id);
		this.parseFilters(filter, item);
		this.setState({ activeKeys: [] });
		this.props.onFiltersUpdate(null, this.props.activeFilters);
	};

	onFilterClear = key => {
		const filter = this.getItem(key);
		const item = {
			id: key,
			emptyValue: filter.type === 'object' ? filter.defaultValues : filter.defaultValues[key],
			values: [filter.defaultValues],
		};
		this.onFilterChanged(item);
	};

	resetFilters = cacheOldFilters => {
		this.props.resetFilters(cacheOldFilters);
		const { filters } = this.props;
		for (let filter of filters) {
			if (filter.key !== 'date') {
				this.onFilterClear(filter.key);
			}
		}
	};

	applyFilter = () => {
		this.props.onFiltersUpdate(cloneDeep(this.props.filters), this.props.filters);
	};

	toggleExpanded = () => {
		this.setState({
			isExpanded: !this.state.isExpanded,
		});
	};

	setDaySelection = key => {
		this.references['date'].current.setDaySelection(key);
	};

	setCustomDaySelection = range => {
		this.references['date'].current.setCustomDaySelection(range);
	};

	getSortOptions = () => {
		const options = TransactionsSortBy;
		let visibleOptions = [];
		options.map(val => {
			if (val.visible === true) {
				visibleOptions.push({
					value: val.key,
					label: val.label,
				});
			}
		});
		return visibleOptions;
	};

	handleSortByChange = (selected, changed) => {
		let options = selected;
		if (changed.action === 'clear') {
			options = {};
		}
		this.setState(
			{
				transactionsSortBy: options,
			},
			() => this.updateSortFromState()
		);
	};

	updateSortFromState = () => {
		const options = TransactionsSortBy;
		const { transactionsSortBy } = this.state;
		const selectedItem = filter(options, { key: transactionsSortBy.value })[0];
		this.props.onSortUpdate(selectedItem);
	};

	onShowEditable = (filterKey = '') => {
		if (filterKey.target) return;
		const newEditableFilter = this.state.editableFilter === filterKey ? '' : filterKey;
		if (newEditableFilter === this.state.editableFilter) return;
		this.setState({
			editableFilter: newEditableFilter,
		});
	};

	filterReportByPermissons = reports => {
		const {
			permissions: { allowReportDeclined, allowReportApproved },
		} = this.state;

		if (!allowReportApproved) {
			reports = filter(reports, ({ filters }) => !filters.status || (filters.status && !filters.status.approved));
		} else if (!allowReportDeclined) {
			reports = filter(reports, ({ filters }) => !filters.status || (filters.status && !filters.status.declined));
		}
		if (!this.csRep) {
			reports = filter(reports, report => !report.csRepOnly);
		}
		return reports;
	};

	renderCustomReportsMessage = () => (
		<div className="message message--default spc--bottom--sml">
			Create custom reports using the form
			<span className="hide--to--lrg--inline"> to the left</span>
			<span className="hide--from--lrg"> at the top</span>. Reports are saved for future access.
		</div>
	);

	render() {
		const {
			isExpanded,
			dates,
			editableFilter,
			permissions,
			permissions: { allowReportDeclined, allowReportApproved },
		} = this.state;
		const { canShowMoreCustomReports, reportToEdit, renderToolbar, hideSourceKey } = this.props;
		const isAuth = get(this.getItem('transactionType'), 'values.auth', false);

		return (
			<Fragment>
				<header className="header">
					<div className="header__title type--wgt--medium">Reports</div>
					<div className="header__menu">
						<UserAccountPanel />
					</div>
				</header>

				<div className="l--content l--content--reports">
					<div className="row">
						<div className="col col-sml-12 col-xlrg-7 col-xxxlrg-8">
							{reportToEdit ? (
								<div className="flex--primary flex--nowrap">
									<h3 className="title title--secondary">Edit - {reportToEdit.name} </h3>
									<button
										type="button"
										className="btn--reset anchor anchor--primary type--sml--plus spc--left--xsml spc--bottom--sml"
										onClick={this.props.handleCancel}
									>
										Cancel
									</button>
								</div>
							) : (
								<h3 className="title title--secondary">Create new report</h3>
							)}
							<div className="reports__card">
								<div className="reports__card__main">
									<div className="row">
										<div className="col col-sml-12 col-med-6 field">
											<label className="field__label--form">Select Date:</label>
											<div className="filter__date filter__date--fullwidth">
												<DatePickerPredefined
													maxDaysRange={Infinity}
													subMenuTitle={this.renderCustomSubMenuItemTitle('Custom', 'date')}
													onOpenChange={this.onOpenChange}
													activeKeys={this.state.activeKeys}
													filter={this.getItem('date')}
													onApplyFilter={this.applyFilter}
													onFilterChanged={this.onFilterChanged}
													onActiveFilterChanged={this.onFilterChanged}
													ref={this.references['date']}
													predefinedDates={dates}
												/>
											</div>
										</div>
										<div className="col col-sml-12 col-med-6 field">
											<label className="field__label--form">View / Sort by:</label>
											<Select
												value={this.state.transactionsSortBy}
												onChange={this.handleSortByChange}
												options={this.getSortOptions()}
												isClearable={false}
												isSearchable={false}
												className="react-select-container"
												classNamePrefix="react-select"
											/>
										</div>
									</div>
									<div className="row">
										<div className="col col-sml-12 col-med-6 field">
											<label className="field__label--form">Transaction Type</label>
											<TransactionTypeReportsFilter
												filter={this.getItem('transactionType')}
												onFilterChanged={this.onFilterChanged}
												ref={this.references['transactionType']}
												status={this.getItem('status')}
											/>
										</div>
										{!allowReportApproved && !allowReportDeclined ? null : (
											<div className="col col-sml-12 col-med-6 field">
												<div className="flex--primary datatooltip--w--170">
													<label className="field__label--form">Transaction Status</label>
													{isAuth && (
														<i
															className="icon icon--tiny icon--info spc--bottom--tny spc--left--tny"
															data-tooltip="Transaction status is disabled when Transaction type Auth is selected"
														></i>
													)}
												</div>
												<StatusReportsFilter
													filter={this.getItem('status')}
													onFilterChanged={this.onFilterChanged}
													ref={this.references['status']}
													permissions={permissions}
													transactionType={this.getItem('transactionType')}
												/>
											</div>
										)}
									</div>
								</div>
								<div className="reports__card__expandable">
									<a
										href="javascript:void(0)"
										className="anchor anchor--primary reports__card__expandable__link"
										onClick={this.toggleExpanded}
									>
										<span className="pull">{isExpanded ? 'Hide filters' : 'Add filters'}</span>
										<i
											className={`icon icon--nano icon--arrow icon--arrow--right--primary ${
												isExpanded ? 'is-expanded' : ''
											} spc--top--nano push`}
										></i>
									</a>
									<div
										className="reports__card__expandable__content"
										style={{ display: isExpanded ? 'block' : 'none' }}
									>
										<OutsideClick action={this.onShowEditable}>
											<div className="row">
												<div className="col col-sml-12 col-med-6">
													<div>
														<ExpandFilterContainerComponent
															label="Amount"
															filterKey="amount"
															component={
																<NumberFilter
																	filter={this.getItem('amount')}
																	onFilterChanged={this.onFilterChanged}
																	inputMode="decimal"
																	thousandSeparator=","
																	decimalSeparator="."
																/>
															}
															filter={this.getItem('amount')}
															editableFilter={editableFilter}
															onFilterChanged={this.onFilterChanged}
															onFilterClear={this.onFilterClear}
															onShowEditable={this.onShowEditable}
															ref={this.references['amount']}
														/>
													</div>
													<div>
														<ExpandFilterContainerComponent
															label="Cardholder name"
															filterKey="cardholderName"
															component={
																<StringFilter
																	filter={this.getItem('cardholderName')}
																	onFilterChanged={this.onFilterChanged}
																/>
															}
															filter={this.getItem('cardholderName')}
															editableFilter={editableFilter}
															onFilterChanged={this.onFilterChanged}
															onFilterClear={this.onFilterClear}
															onShowEditable={this.onShowEditable}
															ref={this.references['cardholderName']}
														/>
													</div>
													<div>
														<ExpandFilterContainerComponent
															label="Invoice"
															filterKey="invoice"
															component={
																<StringFilter filter={this.getItem('invoice')} onFilterChanged={this.onFilterChanged} />
															}
															filter={this.getItem('invoice')}
															editableFilter={editableFilter}
															onFilterChanged={this.onFilterChanged}
															onFilterClear={this.onFilterClear}
															onShowEditable={this.onShowEditable}
															ref={this.references['invoice']}
														/>
													</div>
													<div>
														<ExpandFilterContainerComponent
															label="AVS"
															filterKey="avs"
															component={
																<SelectFilter filter={this.getItem('avs')} onFilterChanged={this.onFilterChanged} />
															}
															filter={this.getItem('avs')}
															editableFilter={editableFilter}
															onFilterChanged={this.onFilterChanged}
															onFilterClear={this.onFilterClear}
															onShowEditable={this.onShowEditable}
															ref={this.references['avs']}
														/>
													</div>
													<div>
														<ExpandFilterContainerComponent
															label="Batch"
															filterKey="batch"
															component={
																<NumberFilter filter={this.getItem('batch')} onFilterChanged={this.onFilterChanged} />
															}
															filter={this.getItem('batch')}
															editableFilter={editableFilter}
															onFilterChanged={this.onFilterChanged}
															onFilterClear={this.onFilterClear}
															onShowEditable={this.onShowEditable}
															ref={this.references['batch']}
														/>
													</div>
													<div>
														<ExpandFilterContainerComponent
															label="Description"
															filterKey="description"
															component={
																<StringFilter
																	filter={this.getItem('description')}
																	onFilterChanged={this.onFilterChanged}
																/>
															}
															filter={this.getItem('description')}
															editableFilter={editableFilter}
															onFilterChanged={this.onFilterChanged}
															onFilterClear={this.onFilterClear}
															onShowEditable={this.onShowEditable}
															ref={this.references['description']}
														/>
													</div>
													<div>
														<ExpandFilterContainerComponent
															label="Auth code"
															filterKey="authCode"
															component={
																<StringFilter
																	filter={this.getItem('authCode')}
																	onFilterChanged={this.onFilterChanged}
																/>
															}
															filter={this.getItem('authCode')}
															editableFilter={editableFilter}
															onFilterChanged={this.onFilterChanged}
															onFilterClear={this.onFilterClear}
															onShowEditable={this.onShowEditable}
															ref={this.references['authCode']}
														/>
													</div>
													<div>
														<ExpandFilterContainerComponent
															label="Terminal number"
															filterKey="terminal"
															component={
																<NumberFilter
																	filter={this.getItem('terminal')}
																	onFilterChanged={this.onFilterChanged}
																/>
															}
															filter={this.getItem('terminal')}
															editableFilter={editableFilter}
															onFilterChanged={this.onFilterChanged}
															onFilterClear={this.onFilterClear}
															onShowEditable={this.onShowEditable}
															ref={this.references['terminal']}
														/>
													</div>
												</div>
												<div className="col col-sml-12 col-med-6">
													<div>
														<ExpandFilterContainerComponent
															label="Card Number"
															filterKey="cardNumber"
															component={
																<LastDigitsFilter
																	numberOfDigits={4}
																	filter={this.getItem('cardNumber')}
																	onFilterChanged={this.onFilterChanged}
																/>
															}
															filter={this.getItem('cardNumber')}
															editableFilter={editableFilter}
															onFilterChanged={this.onFilterChanged}
															onFilterClear={this.onFilterClear}
															onShowEditable={this.onShowEditable}
															ref={this.references['cardNumber']}
														/>
													</div>
													<div>
														<ExpandFilterContainerComponent
															label="Reference Number"
															filterKey="referenceNumber"
															component={
																<NumberFilter
																	filter={this.getItem('referenceNumber')}
																	onFilterChanged={this.onFilterChanged}
																/>
															}
															filter={this.getItem('referenceNumber')}
															editableFilter={editableFilter}
															onFilterChanged={this.onFilterChanged}
															onFilterClear={this.onFilterClear}
															onShowEditable={this.onShowEditable}
															ref={this.references['referenceNumber']}
														/>
													</div>
													<div>
														<ExpandFilterContainerComponent
															label="CVV Result"
															filterKey="cvv"
															component={
																<SelectFilter filter={this.getItem('cvv')} onFilterChanged={this.onFilterChanged} />
															}
															filter={this.getItem('cvv')}
															editableFilter={editableFilter}
															onFilterChanged={this.onFilterChanged}
															onFilterClear={this.onFilterClear}
															onShowEditable={this.onShowEditable}
															ref={this.references['cvv']}
														/>
													</div>
													<div>
														<ExpandFilterContainerComponent
															label="Card type"
															filterKey="cardType"
															component={
																<CardTypeFilter
																	filter={this.getItem('cardType')}
																	onFilterChanged={this.onFilterChanged}
																/>
															}
															filter={this.getItem('cardType')}
															editableFilter={editableFilter}
															onFilterChanged={this.onFilterChanged}
															onFilterClear={this.onFilterClear}
															onShowEditable={this.onShowEditable}
															ref={this.references['cardType']}
														/>
													</div>
													<div>
														<ExpandFilterContainerComponent
															label="PO number"
															filterKey="poNumber"
															component={
																<StringFilter
																	filter={this.getItem('poNumber')}
																	onFilterChanged={this.onFilterChanged}
																/>
															}
															filter={this.getItem('poNumber')}
															editableFilter={editableFilter}
															onFilterChanged={this.onFilterChanged}
															onFilterClear={this.onFilterClear}
															onShowEditable={this.onShowEditable}
															ref={this.references['poNumber']}
														/>
													</div>
													<div>
														<ExpandFilterContainerComponent
															label="Order ID"
															filterKey="orderId"
															component={
																<StringFilter filter={this.getItem('orderId')} onFilterChanged={this.onFilterChanged} />
															}
															filter={this.getItem('orderId')}
															editableFilter={editableFilter}
															onFilterChanged={this.onFilterChanged}
															onFilterClear={this.onFilterClear}
															onShowEditable={this.onShowEditable}
															ref={this.references['orderId']}
														/>
													</div>
													<div>
														<ExpandFilterContainerComponent
															label="Custom fields"
															filterKey="custom"
															component={
																<CustomFieldsFilter
																	filter={this.getItem('custom')}
																	numberOfCustomFields={3}
																	onFilterChanged={this.onFilterChanged}
																/>
															}
															filter={this.getItem('custom')}
															editableFilter={editableFilter}
															onFilterChanged={this.onFilterChanged}
															onFilterClear={this.onFilterClear}
															onShowEditable={this.onShowEditable}
															ref={this.references['custom']}
														/>
													</div>
													{!hideSourceKey && (
														<div>
															<ExpandFilterContainerComponent
																label="Source key"
																filterKey="sourceKey"
																component={
																	<StringFilter
																		filter={this.getItem('sourceKey')}
																		onFilterChanged={this.onFilterChanged}
																	/>
																}
																filter={this.getItem('sourceKey')}
																editableFilter={editableFilter}
																onFilterChanged={this.onFilterChanged}
																onFilterClear={this.onFilterClear}
																onShowEditable={this.onShowEditable}
																ref={this.references['sourceKey']}
															/>
														</div>
													)}
												</div>
											</div>
										</OutsideClick>
									</div>
								</div>
							</div>
							{renderToolbar()}
						</div>
						<div className="col col-sml-12 col-xlrg-5 col-xxxlrg-4">
							<div className="reports__list__card">
								<div className="reports__list__card__header">
									<h3 className="title title--secondary spc--bottom--no">Custom Saved Reports</h3>
								</div>
								<div className="reports__list__card__body">
									<ul className="reports__list">
										<QuickReportsFilterComponent
											filters={this.props.filters}
											reports={this.props.customReports}
											initialRecordsLimit={this.props.initialRecordsLimit}
											transactionsSortBy={this.props.transactionsSortBy}
											onFilterChanged={this.onFilterChanged}
											setDaySelection={this.setDaySelection}
											resetFilters={this.resetFilters}
											onSortUpdate={this.props.onSortUpdate}
											onQuickReportDownload={this.props.onQuickReportDownload}
											setFilters={this.props.setFilters}
											isLoading={this.props.isLoading}
											openDeleteModal={this.props.openDeleteModal}
											handleEdit={this.props.handleEdit}
											topRef={this.props.topRef}
											renderEmptyView={this.renderCustomReportsMessage}
											error={this.props.error}
										/>
									</ul>
									{canShowMoreCustomReports && !this.props.isLoading && (
										<div className="spc--bottom--sml">
											<a
												href="javascript:void(0)"
												onClick={this.props.showMoreCustomReports}
												className="anchor anchor--primary anchor--underline"
											>
												Show more reports
											</a>
										</div>
									)}
								</div>
							</div>
							<div className="reports__list__card">
								<div className="reports__list__card__header">
									<h3 className="title title--secondary spc--bottom--no">
										Quick Reports
										<span className="type--sml type--color--text--light spc--left--sml">(past 30 days)</span>
									</h3>
								</div>
								<div className="reports__list__card__body">
									<ul className="reports__list">
										<QuickReportsFilterComponent
											filters={this.props.filters}
											reports={this.filterReportByPermissons(cloneDeep(QuickReports))}
											initialRecordsLimit={this.props.initialRecordsLimit}
											transactionsSortBy={this.props.transactionsSortBy}
											onFilterChanged={this.onFilterChanged}
											setDaySelection={this.setDaySelection}
											resetFilters={this.resetFilters}
											onSortUpdate={this.props.onSortUpdate}
											onQuickReportDownload={this.props.onQuickReportDownload}
											setFilters={this.props.setFilters}
											isLoading={false}
										/>
									</ul>
								</div>
							</div>
							<div className="reports__list__card">
								<div className="reports__list__card__header">
									<h3 className="title title--secondary spc--bottom--no">Other Reports</h3>
								</div>
								<div className="reports__list__card__body">
									<ul className="reports__list">
										<QuickReportsFilterComponent
											filters={this.props.filters}
											reports={this.filterReportByPermissons(cloneDeep(OtherReports()))}
											initialRecordsLimit={this.props.initialRecordsLimit}
											transactionsSortBy={this.props.transactionsSortBy}
											onFilterChanged={this.onFilterChanged}
											setDaySelection={this.setDaySelection}
											setCustomDaySelection={this.setCustomDaySelection}
											resetFilters={this.resetFilters}
											onSortUpdate={this.props.onSortUpdate}
											onQuickReportDownload={this.props.onQuickReportDownload}
											setFilters={this.props.setFilters}
											isLoading={false}
										/>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

MainFilterComponent.propTypes = {
	filters: PropTypes.array,
	customReports: PropTypes.array,
	activeFilters: PropTypes.array,
	initialRecordsLimit: PropTypes.number,
	onFiltersUpdate: PropTypes.func,
	onSortUpdate: PropTypes.func,
	onQuickReportDownload: PropTypes.func,
	setFilters: PropTypes.func,
	isLoading: PropTypes.bool,
	showMoreCustomReports: PropTypes.func,
	canShowMoreCustomReports: PropTypes.bool,
	openDeleteModal: PropTypes.func,
	handleEdit: PropTypes.func,
	handleCancel: PropTypes.func,
	reportToEdit: PropTypes.object,
	renderToolbar: PropTypes.func,
	topRef: PropTypes.object,
	transactionsSortBy: PropTypes.object,
	resetFilters: PropTypes.func,
	error: PropTypes.string,
	hideSourceKey: PropTypes.any,
};

MainFilterComponent.defaultProps = {
	filters: [],
	activeFilters: [],
	onFiltersUpdate: () => {
		//eslint-disable-next-line
		console.warning('Provide onFilterUpdate method');
	},
};

export default MainFilterComponent;
