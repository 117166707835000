export const OVERSCAN_ROWS = 2;

const { min, max, ceil, round } = Math;

export const MAX_DETAILS_ROW_BUFFER = Infinity;

function isFrozen(column) {
	return column.frozen === true;
}

const SCROLL_DIRECTION = {
	UP: 'upwards',
	DOWN: 'downwards',
	LEFT: 'left',
	RIGHT: 'right',
	NONE: 'none',
};

export function findLastFrozenColumnIndex(columns) {
	return columns.findIndex(c => isFrozen(c));
}

function getTotalFrozenColumnWidth(columns) {
	const lastFrozenColumnIndex = findLastFrozenColumnIndex(columns);
	if (lastFrozenColumnIndex === -1) {
		return 0;
	}
	const lastFrozenColumn = columns[lastFrozenColumnIndex];
	return lastFrozenColumn.left + lastFrozenColumn.width;
}

function getColumnCountForWidth(columns, initialWidth, colVisibleStartIdx) {
	let width = initialWidth;
	let count = 0;

	columns.forEach((column, idx) => {
		if (idx != null && idx >= colVisibleStartIdx) {
			width -= column.width;
			if (width >= 0) {
				count++;
			}
		}
	});

	return count;
}

export function getNonFrozenVisibleColStartIdx(columns, scrollLeft) {
	let remainingScroll = scrollLeft;
	const lastFrozenColumnIndex = findLastFrozenColumnIndex(columns);
	const nonFrozenColumns = columns.slice(lastFrozenColumnIndex + 1);
	let columnIndex = lastFrozenColumnIndex;
	while (remainingScroll >= 0 && columnIndex < nonFrozenColumns.length) {
		columnIndex++;
		const column = columns[columnIndex];
		remainingScroll -= column ? column.width : 0;
	}
	return max(columnIndex, 0);
}

export function getNonFrozenRenderedColumnCount(columnMetrics, viewportDomWidth, scrollLeft) {
	const { columns, totalColumnWidth } = columnMetrics;
	if (columns.length === 0) {
		return 0;
	}
	const colVisibleStartIdx = getNonFrozenVisibleColStartIdx(columns, scrollLeft);
	const totalFrozenColumnWidth = getTotalFrozenColumnWidth(columns);
	const viewportWidth = viewportDomWidth > 0 ? viewportDomWidth : totalColumnWidth;
	const firstColumn = columns[colVisibleStartIdx];
	// calculate the portion width of first column hidden behind frozen columns
	const scrolledFrozenWidth = totalFrozenColumnWidth + scrollLeft;
	const firstColumnHiddenWidth = scrolledFrozenWidth > firstColumn.left ? scrolledFrozenWidth - firstColumn.left : 0;
	const initialWidth = viewportWidth - totalFrozenColumnWidth + firstColumnHiddenWidth;
	return getColumnCountForWidth(columns, initialWidth, colVisibleStartIdx);
}

export function getVisibleBoundaries(gridHeight, rowHeight, scrollTop, rowsCount) {
	const renderedRowsCount = ceil(gridHeight / rowHeight);
	const rowVisibleStartIdx = max(0, round(scrollTop / rowHeight));
	const rowVisibleEndIdx = min(rowVisibleStartIdx + renderedRowsCount, rowsCount);
	return { rowVisibleStartIdx, rowVisibleEndIdx };
}

export function getScrollDirection(lastScroll, scrollTop, scrollLeft) {
	if (scrollTop !== lastScroll.scrollTop && lastScroll.scrollTop !== undefined) {
		return scrollTop - lastScroll.scrollTop >= 0 ? SCROLL_DIRECTION.DOWN : SCROLL_DIRECTION.UP;
	}
	if (scrollLeft !== lastScroll.scrollLeft && lastScroll.scrollLeft !== undefined) {
		return scrollLeft - lastScroll.scrollLeft >= 0 ? SCROLL_DIRECTION.RIGHT : SCROLL_DIRECTION.LEFT;
	}
	return SCROLL_DIRECTION.NONE;
}

export function getRowOverscanStartIdx(scrollDirection, rowVisibleStartIdx, detailsRowIndex) {
	let rowOverscanStartIdx = max(0, rowVisibleStartIdx);
	if (scrollDirection === SCROLL_DIRECTION.UP) {
		rowOverscanStartIdx = max(0, rowVisibleStartIdx - OVERSCAN_ROWS);
	}
	if (detailsRowIndex > -1) {
		const diff = rowOverscanStartIdx - detailsRowIndex;
		if (diff >= 0 && diff < MAX_DETAILS_ROW_BUFFER) {
			rowOverscanStartIdx = max(0, detailsRowIndex - OVERSCAN_ROWS);
		}
	}
	return rowOverscanStartIdx;
}

export function getRowOverscanEndIdx(scrollDirection, rowVisibleEndIdx, rowsCount, detailsRowIndex) {
	let rowOverscanEndIdx = rowVisibleEndIdx;
	if (scrollDirection === SCROLL_DIRECTION.DOWN) {
		const overscanBoundaryIdx = rowVisibleEndIdx + OVERSCAN_ROWS;
		rowOverscanEndIdx = min(overscanBoundaryIdx, rowsCount);
	}
	if (detailsRowIndex > -1) {
		const diff = detailsRowIndex - rowOverscanEndIdx;
		if (diff >= 0 && diff < MAX_DETAILS_ROW_BUFFER) {
			rowOverscanEndIdx = min(detailsRowIndex + OVERSCAN_ROWS, rowsCount);
		}
	}
	return rowOverscanEndIdx;
}

export function getColOverscanStartIdx(scrollDirection, colVisibleStartIdx, lastFrozenColumnIdx) {
	if (scrollDirection === SCROLL_DIRECTION.LEFT || scrollDirection === SCROLL_DIRECTION.RIGHT) {
		return lastFrozenColumnIdx > -1 ? lastFrozenColumnIdx + 1 : 0;
	}
	return colVisibleStartIdx;
}

export function getColOverscanEndIdx(scrollDirection, colVisibleEndIdx, totalNumberColumns) {
	if (scrollDirection === SCROLL_DIRECTION.DOWN || scrollDirection === SCROLL_DIRECTION.UP) {
		return colVisibleEndIdx;
	}
	return totalNumberColumns;
}
