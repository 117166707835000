import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { toLower, each, isEmpty, find } from 'lodash';

import { SchedulePreviewGrid } from 'components/schedule-preview-grid';
import TooltipFormatter from './TooltipFormatter';

class RecurringScheduleComponent extends TooltipFormatter {
	static instances = [];
	constructor(props) {
		super(props, RecurringScheduleComponent.instances);
		this.state = {
			isPreviewOpen: false,
		};
		this.tooltipClass = 'recurring__tooltip';
	}

	get tooltip() {
		const { dependentValues } = this.props;

		const scheduleDetails = this.mapScheduleDetails(dependentValues.basicScheduleData);
		return (
			<div>
				<div className="type--wgt--bold type--break-word">{scheduleDetails.scheduleName}</div>
				Every {this.displayValue(scheduleDetails.intervalCount)} {this.displayValue(scheduleDetails.intervalType)}
				{scheduleDetails.intervalCount > 1 ? this.displayValue('s') : null}
				{this.getRepeatedTime(scheduleDetails)}, in the amount of{' '}
				{this.displayValue(
					<NumberFormat
						value={scheduleDetails.amount}
						displayType="text"
						thousandSeparator={true}
						prefix="$"
						decimalScale={2}
						fixedDecimalScale={true}
					/>
				)}
				, for {this.getPeriod(scheduleDetails)} and due next on{' '}
				{this.displayValue(scheduleDetails.nextRunTime.format(ApplicationSettings.apiDateFormat))}
				{scheduleDetails.hasAdditionalInfo ? (
					<div onClick={this.expandRow} className="anchor anchor--primary anchor--underline type--right spc--top--xsml">
						+ Additional plans
					</div>
				) : null}
			</div>
		);
	}

	mapScheduleDetails = scheduleData => {
		if (!isEmpty(scheduleData)) {
			try {
				const schedule = {};
				const firstActiveSchedule = find(scheduleData, ({ isActive }) => isActive);
				each(firstActiveSchedule, (value, key) => {
					schedule[key] = value;
				});
				schedule.nextRunTime = moment(schedule.nextRunTime, ApplicationSettings.displayDateFormat);
				schedule.totalPayments = parseInt(schedule.totalPayments || 0);
				schedule.paymentsProcessed = parseInt(schedule.paymentsProcessed || 0);
				schedule.amount = parseFloat(schedule.amount);
				schedule.hasAdditionalInfo = scheduleData.length > 1;
				return schedule;
			} catch (e) {
				//intentionally empty catch block
			}
		}
	};

	//eslint-disable-next-line
	getRepeatedTime = ({ intervalType, nextRunTime }) => {
		let repeatedTime = null;
		let prefix = null;
		switch (toLower(intervalType)) {
			case 'week': {
				repeatedTime = nextRunTime.format('dddd');
				prefix = ' on';
				break;
			}
			case 'month': {
				repeatedTime = 'same weekday that schedule was made';
				prefix = ' on the';
				break;
			}
			case 'year': {
				repeatedTime = nextRunTime.format('MMM Do');
				prefix = ' on';
				break;
			}
			default: {
				break;
			}
		}
		return repeatedTime ? (
			<Fragment>
				{prefix} {this.displayValue(repeatedTime)}
			</Fragment>
		) : null;
	};

	//eslint-disable-next-line
	getPeriod = ({ totalPayments, paymentsProcessed }) => {
		if (!totalPayments) {
			return <Fragment>an {this.displayValue('indefinite')} period</Fragment>;
		}
		const remainingCharges = totalPayments - paymentsProcessed;
		return <Fragment>{this.displayValue(remainingCharges)} more charges</Fragment>;
	};

	displayValue = val => {
		return <span className="type--wgt--bold">{val}</span>;
	};

	expandRow = () => {
		const { dependentValues } = this.props;

		this.closePopup();
		dependentValues.onRowClick(dependentValues.gridRowNumber, dependentValues, null, true, true);
	};

	handleOpenPreview = e => {
		e.stopPropagation();
		this.setState({ isPreviewOpen: true }, this.closePopup);
	};

	handleClosePreview = () => {
		this.setState({ isPreviewOpen: false });
	};

	render() {
		const { value, dependentValues } = this.props;
		const { isPreviewOpen } = this.state;

		return value ? (
			<div ref={this.elementRef}>
				<a
					className="anchor anchor--primary anchor--underline recurring__tooltip__trigger"
					onClick={this.handleOpenPreview}
					onMouseEnter={this.displayPopup}
					onMouseLeave={this.closePopupLater}
				>
					{value}
				</a>
				<div onClick={e => e.stopPropagation()}>
					<SchedulePreviewGrid
						isOpen={isPreviewOpen}
						onClose={this.handleClosePreview}
						className="popup__content popup--med"
						schedule={this.mapScheduleDetails(dependentValues.basicScheduleData)}
					/>
				</div>
			</div>
		) : (
			<span>-</span>
		);
	}
}

RecurringScheduleComponent.propTypes = {
	value: PropTypes.string.isRequired,
	dependentValues: PropTypes.object,
};

export default RecurringScheduleComponent;
