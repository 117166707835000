import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { clone, noop } from 'lodash';

import principalService from '../../Common/services/principalService';
import authenticationService from '../../Common/services/authenticationService';
import { withError } from '../../Common/components/error';
import { withLoader } from 'common/components/loader';
import { quickAssistService } from 'common/services';

class ApiKeysComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentWillMount() {
		const {
			location: { search },
		} = this.props;
		const data = clone(principalService.get());
		let callback = noop;
		if (search) {
			const parsed = parse(search);
			if (parsed && parsed.hash) {
				data.hash = parsed.hash;
				data.navigateOnSave = true;
				callback = this.fetchKey;
			}
		}
		this.setState(data, callback);
	}

	fetchKey = async () => {
		this.props.showLoader(true);
		try {
			const { xKey: key } = await quickAssistService.getFullKey(this.state.hash);
			this.setState(
				{
					key,
				},
				this.saveKeys
			);
		} catch (e) {
			this.props.handleError(e);
		}
	};

	saveKeys = async () => {
		const { key, navigateOnSave } = this.state;
		if (!key) {
			this.setErrorMessage('Key is required');
			return;
		}
		
		this.props.showLoader(true);
		localStorage.removeItem('hasPaymentSites');
		localStorage.removeItem('isProPay');

		try {
			const data = await authenticationService.validateKey(key);
			const { list } = this.state;
			const newState = {
				id: data.xMerchantID,				
				companyName: data.xMerchantDBA,
				idInfo: data,
				list: [
					...list,
					{
						dba: data.xMerchantDBA,
						mid: data.xMerchantID,
						revision: '1',
						role: 'Admin',
						sortorder: list.length.toString(),
					},
				],
			};

			principalService.set(newState);
			this.setState(newState, () => {
				if (navigateOnSave) {
					window.location = '/';
				} else {
					window.location.reload();
				}
			});
		} catch (e) {
			this.props.handleError(e, { additionalInfo: { key } });
		}
		this.props.showLoader(false);
	};

	handleKeyChange = e => {
		this.setState({ key: e.target.value });
	};

	handleIfieldsChange = e => {
		this.setState({ ifields: e.target.value });
	};

	setErrorMessage = errorMessage => {
		this.setState({ errorMessage });
	}

	render() {
		const { isLoading } = this.props;
		const { errorMessage } = this.state;
		return (
			<div>
				<header className="header">
					<div className="fullwidth display--f f--j--sb f--a--c">
						<div className="display--f f--a--bl">
							<div className="type--xlrg type--wgt--medium">Api Keys</div>
						</div>
					</div>
				</header>

				<div className="l--content">
					<div className="row">
						<div className="col col-sml-12">
							<div className="form-group">
								<label htmlFor="id">Key: </label>
								<input
									id="id"
									type="text"
									className="form-control"
									placeholder="Key"
									value={this.state.key}
									disabled={isLoading}
									onChange={this.handleKeyChange}
								/>
							</div>
							<div className="form-group">
								<label htmlFor="ifields">Ifields key: </label>
								<input
									id="ifields"
									type="text"
									className="form-control"
									placeholder="Ifields key"
									value={this.state.ifields}
									disabled={isLoading}
									onChange={this.handleIfieldsChange}
								/>
							</div>
							<div className="form-group">
								<label htmlFor="ifields">Company: </label>
								{this.state.companyName}
							</div>
							<div className="form-group">
								<hr />
								<button disabled={isLoading} onClick={this.saveKeys}>
									Save
								</button>
								{errorMessage ? (
								<div className="spc--top--sml membership__spacer type--color--warning">{errorMessage}</div>
							) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

ApiKeysComponent.propTypes = {
	handleError: PropTypes.func,
	location: PropTypes.object,
	isLoading: PropTypes.bool,
	showLoader: PropTypes.func,
};

export default withLoader(withError(ApiKeysComponent));
