import ReactDataGrid from 'react-data-grid';

import {
	getColOverscanEndIdx,
	getVisibleBoundaries,
	getScrollDirection,
	getRowOverscanStartIdx,
	getRowOverscanEndIdx,
	getColOverscanStartIdx,
	getNonFrozenVisibleColStartIdx,
	getNonFrozenRenderedColumnCount,
	findLastFrozenColumnIndex,
} from './viewportUtils';

const defaultGetDetailsRowIndex = () => -1;

const mapViewportMethods = (viewport, getDetailsRowIndex = defaultGetDetailsRowIndex) => {
	viewport.getNextScrollState = ({ scrollTop, scrollLeft, height, rowHeight, rowsCount }) => {
		const isScrolling = true;
		const { columns } = viewport.props.columnMetrics;
		const scrollDirection = getScrollDirection(viewport.state, scrollTop, scrollLeft);
		const { rowVisibleStartIdx, rowVisibleEndIdx } = getVisibleBoundaries(height, rowHeight, scrollTop, rowsCount);
		const detailsRowIndex = getDetailsRowIndex();
		const rowOverscanStartIdx = getRowOverscanStartIdx(scrollDirection, rowVisibleStartIdx, detailsRowIndex);
		const rowOverscanEndIdx = getRowOverscanEndIdx(scrollDirection, rowVisibleEndIdx, rowsCount, detailsRowIndex);
		const totalNumberColumns = columns.length;
		const lastFrozenColumnIndex = findLastFrozenColumnIndex(columns);
		const nonFrozenColVisibleStartIdx = getNonFrozenVisibleColStartIdx(columns, scrollLeft);
		const nonFrozenRenderedColumnCount = getNonFrozenRenderedColumnCount(
			viewport.props.columnMetrics,
			viewport.getDOMNodeOffsetWidth(),
			scrollLeft
		);
		const colVisibleEndIdx = Math.min(nonFrozenColVisibleStartIdx + nonFrozenRenderedColumnCount, totalNumberColumns);
		const colOverscanStartIdx = getColOverscanStartIdx(
			scrollDirection,
			nonFrozenColVisibleStartIdx,
			lastFrozenColumnIndex
		);
		const colOverscanEndIdx = getColOverscanEndIdx(scrollDirection, colVisibleEndIdx, totalNumberColumns);
		return {
			height,
			scrollTop,
			scrollLeft,
			rowVisibleStartIdx,
			rowVisibleEndIdx,
			rowOverscanStartIdx,
			rowOverscanEndIdx,
			colVisibleStartIdx: nonFrozenColVisibleStartIdx,
			colVisibleEndIdx,
			colOverscanStartIdx,
			colOverscanEndIdx,
			scrollDirection,
			lastFrozenColumnIndex,
			isScrolling,
		};
	};
};

class ReactDataGridOverride extends ReactDataGrid {
	render() {
		// force viewport columns to always start from zero
		// to prevent row re-rendering when scrolling horizontally
		if (this.base && this.base.viewport) {
			mapViewportMethods(this.base.viewport, this.props.getDetailsRowIndex);
			this.base.viewport.getVisibleColStart = () => 0;
		}
		return super.render();
	}
}

export default ReactDataGridOverride;
