import React from 'react';
import { map } from 'lodash';

import { ColumnHeader } from 'common/components/columns/headers';
import { ActionsComponent } from '../../columns/formatters';
import { StringFilter } from '../../columns/filters';
const columns = [
	{
		key: 'keyDescription',
		name: 'Key Description',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		resizable: true,
		isDefaultSorter: true,
		defaultSortDirection: 'ASC',
	},
	{
		key: 'keyType',
		name: 'Key Type',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		resizable: true,
	},
	{
		key: 'actions',
		name: '',
		sortable: false,
		hideable: false,
		visible: true,
		filterable: false,
		hideOnExport: true,
		formatter: ActionsComponent,
		getRowMetaData: row => row,
		initWidth: 75,
	},
];

export const keyManagementColumns = map(columns, column => {
	column = {
		headerRenderer: <ColumnHeader />,
		...column,
	};
	return column;
});
