import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FlexcalWrapper from 'common/components/flexcal/flexcal-wrapper';
import { displayToApi, apiToDisplay, toHebrew } from 'common/utilities';

class HebrewDatePickerComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			gregorian: undefined,
		};
	}

	onChange = (e, d) => {
		const value = displayToApi(toHebrew(d));
		this.props.onChange({ value, date: d });
		this.setState({
			gregorian: d,
		});
	};

	render = () =>
		this.props.disabled ? (
			<div className="input input--med input--date fakeinput">{apiToDisplay(this.props.date)}</div>
		) : (
			<FlexcalWrapper
				calendars={['he-jewish']}
				position="bl"
				className="ui-calendar"
				onChange={this.onChange}
				value={this.props.date || '-'}
				trigger={props => <div className="input input--med input--date">{apiToDisplay(props.value)}</div>}
				filter={this.props.filter}
				dependentValues={this.props.dependentValues}
				disabledDaysTooltip={this.props.disabledDaysTooltip}
				current={this.state.gregorian}
			/>
		);
}

HebrewDatePickerComponent.propTypes = {
	date: PropTypes.string,
	onChange: PropTypes.func,
	filter: PropTypes.func,
	disabled: PropTypes.bool,
	dependentValues: PropTypes.array,
	disabledDaysTooltip: PropTypes.string,
};

export default HebrewDatePickerComponent;
