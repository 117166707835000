import { times, padStart } from 'lodash';

import { NumericFilter, MultiselectCardknoxFilter } from '../../columns/filters';
import {
	DisplayDateComponent,
	CreditCardComponent,
	CurrencyComponent,
	StatusComponent,
	AvsComponent,
	LinkComponent,
	PaymentMethodComponent,
	BooleanComponent,
	CardTypeComponent,
	SecureResultComponent,
	CurrencyCodesComponent,
	StatusFraudComponent,
	TooltipFormatter,
} from '../../columns/formatters';
import createGridColumn from 'common/utilities/createGridColumn';

export const TransactionColumns = [
	createGridColumn('xRefNum', 'Ref #', 150, {
		hideable: false,
		visible: true,
		showOnMobile: true,
		order: 1,
		fieldKey: 'xRefNum,xEnteredDate,xResponseResult,xAmount,xCurrency,xCardType,xCommand,xCustID,xCustomerID',
	}),
	createGridColumn('xEnteredDate', 'Date', 200, {
		showOnMobile: true,
		formatter: DisplayDateComponent,
		isDefaultSorter: true,
		defaultSortDirection: 'DESC',
		customSettingsKey: 'date',
	}),
	createGridColumn('currency', 'Currency', 130, {
		showOnMobile: true,
		formatter: CurrencyCodesComponent,
		customSettingsKey: 'currency',
		exportKey: 'xCurrency',
		filterRenderer: MultiselectCardknoxFilter,
	}),
	createGridColumn('amountToUse', 'Amount', 180, {
		showOnMobile: true,
		formatter: CurrencyComponent,
		customSettingsKey: 'amount',
		exportKey: 'xAmount',
		filterRenderer: NumericFilter,

		getRowMetaData: row => {
			return { ...row, displayBadge: true };
		},
		dependentExportKey: ['xVoid'],
		alignHeaderRight: true,
	}),
	createGridColumn('xClearedAmount', 'Cleared Amount', 150, {
		showOnMobile: true,
		formatter: CurrencyComponent,
		customSettingsKey: 'clearedAmount',
		filterRenderer: NumericFilter,
		getRowMetaData: row => row,
		alignHeaderRight: true,
	}),
	createGridColumn('xClearedCount', 'Cleared Count', 150, {
		showOnMobile: true,
		customSettingsKey: 'clearedCount',
		filterRenderer: NumericFilter,
	}),
	createGridColumn('xVoid', 'Void Status', 200, {
		hideable: false,
		hideOnPrint: true,
		sortable: false,
		filterable: false,
		ignoreOnfetch: true,
	}),
	createGridColumn('xTax', 'Tax', 133, {
		showOnMobile: true,
		formatter: CurrencyComponent,
		customSettingsKey: 'tax',
		filterRenderer: NumericFilter,
		getRowMetaData: row => row,
		alignHeaderRight: true,
	}),
	createGridColumn('xServiceFee', 'Service Fee', 150, {
		showOnMobile: true,
		formatter: CurrencyComponent,
		customSettingsKey: 'serviceFee',
		filterRenderer: NumericFilter,
		getRowMetaData: row => row,
		alignHeaderRight: true,
	}),
	createGridColumn('xProcessingFee', 'Processing Fee', 150, {
		showOnMobile: true,
		formatter: CurrencyComponent,
		customSettingsKey: 'processingFee',
		hideOnPrint: true,
		filterRenderer: NumericFilter,
		getRowMetaData: row => row,
		alignHeaderRight: true,
	}),
	createGridColumn('netSale', 'Net Sale', 100, {
		showOnMobile: true,
		fieldKey: 'xAmount,xProcessingFee',
		formatter: CurrencyComponent,
		customSettingsKey: 'netSale',
		hideOnPrint: true,
		filterRenderer: NumericFilter,
		getRowMetaData: row => row,
		alignHeaderRight: true,
	}),
	createGridColumn('xDigitalWalletType', 'Digital Wallet', 100, { customSettingsKey: 'digitalWalletType' }),
	createGridColumn('xBillFirstName', 'Bill First N­ame', 150, { customSettingsKey: 'firstName' }),
	createGridColumn('xBillStreet2', 'Bill Address 2', 150, { customSettingsKey: 'billAddress2' }),
	createGridColumn('xBillMobile', 'Bill Mobile', 150, { customSettingsKey: 'billMobile' }),
	createGridColumn('xBillLastName', 'Bill Last N­ame', 150, { customSettingsKey: 'lastName' }),
	createGridColumn('xName', 'C­ar­dholder N­ame', 150, { customSettingsKey: 'cardholderName' }),
	createGridColumn('xSoftwareName', 'Software Name', 220, { customSettingsKey: 'softwareName' }),
	createGridColumn('xCardType', 'Card Type', 150, {
		formatter: CardTypeComponent,
		customSettingsKey: 'cardType',
		filterRenderer: MultiselectCardknoxFilter,
	}),
	createGridColumn('xMaskedCardNumber', 'Account #', 115, {
		showOnMobile: true,
		formatter: CreditCardComponent,
		customSettingsKey: 'accountNumber',
		getRowMetaData: row => row,
		dependentExportKey: 'xCardType',
	}),
	createGridColumn('xResponseResult', 'Result', 120, {
		showOnMobile: true,
		formatter: StatusComponent,
		customSettingsKey: 'result',
		filterRenderer: MultiselectCardknoxFilter,
		getRowMetaData: row => row,
	}),
	createGridColumn('xResponseError', 'Error Details', 120, {
		showOnMobile: true,
		order: '15',
		formatter: TooltipFormatter,
		customSettingsKey: 'responseError',
		getRowMetaData: row => row,
	}),
	createGridColumn('xCommand', 'Command', 170, {
		customSettingsKey: 'command',
		filterRenderer: MultiselectCardknoxFilter,
	}),
	createGridColumn('achStatus', 'ACH Status', 130, {
		formatter: StatusFraudComponent,
		customSettingsKey: 'achStatus',
		exportKey: 'xStatus',
		filterRenderer: MultiselectCardknoxFilter,
	}),
	createGridColumn('xAchReturnFee', 'ACH Return Fee', 150, {
		showOnMobile: true,
		formatter: CurrencyComponent,
		customSettingsKey: 'achReturnFee',
		filterRenderer: NumericFilter,
		getRowMetaData: row => row,
		alignHeaderRight: true,
	}),
	createGridColumn('xBillCompany', 'Bill Company', 220, { customSettingsKey: 'company' }),
	createGridColumn('xBillStreet', 'Bill Address', 220, { customSettingsKey: 'address' }),
	createGridColumn('xBillCity', 'Bill City', 220, { customSettingsKey: 'city' }),
	createGridColumn('xBillState', 'Bill State', 220, { customSettingsKey: 'state' }),
	createGridColumn('xBillZip', 'Bill Zip', 220, { customSettingsKey: 'zip' }),
	createGridColumn('xBillCountry', 'Bill Country', 150, { customSettingsKey: 'country' }),
	createGridColumn('xBillPhone', 'Bill Phone Number', 220, {
		customSettingsKey: 'billPhone',
		alternateCustomKey: 'phoneNumber',
	}),
	createGridColumn('xShipFirstName', 'Ship First N­ame', 150, { customSettingsKey: 'shipFirstName' }),
	createGridColumn('xShipLastName', 'Ship Last N­ame', 150, { customSettingsKey: 'shipLastName' }),
	createGridColumn('xShipCompany', 'Ship Company', 220, { customSettingsKey: 'shipCompany' }),
	createGridColumn('xShipStreet2', 'Ship Street 2', 150, { customSettingsKey: 'shipAddress2' }),
	createGridColumn('xShipStreet', 'Ship Street', 220, { customSettingsKey: 'shipAddress' }),
	createGridColumn('xShipCity', 'Ship City', 220, { customSettingsKey: 'shipCity' }),
	createGridColumn('xShipState', 'Ship State', 220, { customSettingsKey: 'shipState' }),
	createGridColumn('xShipZip', 'Ship Zip', 220, { customSettingsKey: 'shipZip' }),
	createGridColumn('xShipCountry', 'Ship Country', 150, { customSettingsKey: 'shipCountry' }),
	createGridColumn('xShipMobile', 'Ship Mobile', 150, { customSettingsKey: 'shipMobile' }),
	createGridColumn('xShipPhone', 'Ship Phone', 220, { customSettingsKey: 'shipPhoneNumber' }),
	createGridColumn('xMerchantName', 'Merchant Name', 580, {
		hideable: false,
	}),
	createGridColumn('xDescription', 'Description', 300, { customSettingsKey: 'description' }),
	createGridColumn('xEmail', 'Email', 220, { customSettingsKey: 'email' }),
	createGridColumn('paymentMethod', 'Payment Method', 150, {
		fieldKey: 'xIsDebit',
		formatter: PaymentMethodComponent,
		customSettingsKey: 'paymentMethod',
		filterRenderer: MultiselectCardknoxFilter,
	}),
	createGridColumn('xResponseBatch', 'Batch', 90, { customSettingsKey: 'batch' }),
	createGridColumn('xInvoice', 'Invoice', 150, { customSettingsKey: 'invoice' }),
	createGridColumn('CVV', 'CVV Result', 120, {
		customSettingsKey: 'cvvResult',
		exportKey: 'xResponseCVVCode',
	}),

	createGridColumn('x3dsResponseCode', '3DS Response Code', 110, {
		customSettingsKey: '3DsResponseCode',
	}),
	createGridColumn('x3dsConsumerInteraction', '3DS Consumer Interaction', 110, {
		customSettingsKey: 'x3dsConsumerInteraction',
	}),
	createGridColumn('xResponseAuthCode', 'Auth Code', 110, { customSettingsKey: 'authCode' }),
	createGridColumn('xOrderID', 'OrderID', 150, { customSettingsKey: 'orderId' }),
	createGridColumn('xPONum', 'PO Number', 110, { customSettingsKey: 'poNumber' }),
	createGridColumn('xResponseAVSCode', 'AVS', 350, {
		formatter: AvsComponent,
		customSettingsKey: 'avs',
		filterRenderer: MultiselectCardknoxFilter,
	}),
	createGridColumn('xStreet', 'AVS Street', 220, { customSettingsKey: 'avsStreet' }),
	createGridColumn('xZip', 'AVS Zip', 220, { customSettingsKey: 'avsZip' }),
	createGridColumn('xResponseNameVerificationCode', 'AMEX Name Verification', 220, {
		customSettingsKey: 'nameVerification',
	}),
	createGridColumn('xResponseEmailVerificationCode', 'AMEX Email Verification', 220, {
		customSettingsKey: 'emailVerification',
	}),
	createGridColumn('xResponsePhoneVerificationCode', 'AMEX Phone Verification', 220, {
		customSettingsKey: 'phoneVerification',
	}),
	createGridColumn('xSourceKey', 'Source Key', 150, { customSettingsKey: 'sourceKey' }),
	createGridColumn('xTerminalNum', 'Terminal Number', 150, { customSettingsKey: 'terminalNumber' }),
	createGridColumn('xUserName', 'User Name', 150, { customSettingsKey: 'userName' }),
	createGridColumn('xComputerName', 'Computer Name', 150, {
		customSettingsKey: 'computername',
		isAdminColumn: true,
	}),
	createGridColumn('xToken', 'Token', 150, {
		customSettingsKey: 'token',
	}),
	createGridColumn('xIsEMV', 'Is EMV', 150, {
		formatter: BooleanComponent,
		customSettingsKey: 'isemv',
		isAdminColumn: true,
	}),
	createGridColumn('xIsSwiped', 'Is Swiped', 150, {
		formatter: BooleanComponent,
		customSettingsKey: 'isswiped',
		isAdminColumn: true,
	}),
	createGridColumn('xEngine', 'Engine', 150, {
		customSettingsKey: 'engine',
		isAdminColumn: true,
	}),
	createGridColumn('xProcessor', 'Processor', 150, {
		customSettingsKey: 'processor',
		isAdminColumn: true,
	}),
	createGridColumn('xRouting', 'Routing', 150, {
		customSettingsKey: 'routing',
		isAdminColumn: true,
	}),
	createGridColumn('xClientIP', 'IP Address', 200, {
		customSettingsKey: 'ipAddress',
		sortable: false,
	}),
	createGridColumn('xEntryMethod', 'Entry Method', 120, {
		customSettingsKey: 'entryMethod',
		filterRenderer: MultiselectCardknoxFilter,
	}),
	createGridColumn('xCardSource', 'Card Source', 120, {
		customSettingsKey: 'cardSource',
		filterRenderer: MultiselectCardknoxFilter,
	}),
	createGridColumn('xReviewed', '3D Secure Result', 150, {
		formatter: SecureResultComponent,
		customSettingsKey: 'reviewed',
		filterRenderer: MultiselectCardknoxFilter,
		hasNoField: true,
	}),
	createGridColumn('xSerialNumber', 'Serial Number', 300, { customSettingsKey: 'serialNumber' }),
	...times(19, i => {
		const oneBasedIndex = padStart(i + 1, 2, 0);
		return createGridColumn(`xCustom${oneBasedIndex}`, `Custom${oneBasedIndex}`, 150, {
			formatter: LinkComponent,
			customSettingsKey: `custom${i + 1}`,
		});
	}),
	createGridColumn('xAdditionalRefnum', 'Additional Refnum', 200, { customSettingsKey: 'additionalRefnum' }),
	createGridColumn('xAdditionalAuthAmount', 'Additional Auth Amount', 200, {
		customSettingsKey: 'additionalAuthAmount',
	}),
	createGridColumn('xIsInternational', 'International', 100, {
		customSettingsKey: 'international',
	}),
];	
