import { map, split, zip, find, slice } from 'lodash';

const toParts = (value, separator) => map(split(value, separator), n => parseInt(n));

const partsCompare = (separator, maxDepth = Infinity) => (a, b) => {
	if (!a) {
		return -1;
	}
	const parts = slice(zip(toParts(a, separator), toParts(b, separator)), 0, maxDepth);
	const differentValuesPart = find(parts, part => part[0] !== part[1]);
	if (!differentValuesPart) {
		return 0;
	}
	return differentValuesPart[0] > differentValuesPart[1] ? 1 : -1;
};

export default partsCompare;
