import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-timezone';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';
import { map, toLower, endsWith, each, findKey, clone, isEmpty, find, get, toNumber, times, padStart } from 'lodash';
import NumberFormat from 'react-number-format';
import Select, { components } from 'react-select';
import { FullScheduleInfo } from '../../../full-schedule-info';
import { customerService } from 'common/services';

import {
	CurrencyMap,
	displayToApi,
	isAfter,
	toHebrew,
	kvaasResources,
	apiTimezone,
	OutsideClick,
	afterMaxRetriesActionOptions,
	newAccountAfterMaxRetriesTooltip,
	warningMessage,
	CardTypeImagePath,
} from './../../../../utilities';
import { calendarTypes, until, today } from './../constants';
import { HebrewDatePicker, SingleDatePicker } from 'common/components/date-picker';
import kvaasService from '../../../../services/kvaasService';
import { withCancelable } from './../../../cancelable';
import { withError } from './../../../error';
import { modalNames } from 'common/components/transaction-actions/modal-names';
import { Tour } from 'common/components/tour';
import { isOlderThanSevenMonths } from 'common/utilities/isOlderThanSevenMonths';

const { displayDateFormat, apiDateFormat } = ApplicationSettings;

const intervalOptions = [
	{ name: 'day', label: 'Day(s)' },
	{ name: 'week', label: 'Week(s)' },
	{ name: 'month', label: 'Month(s)' },
	{ name: 'year', label: 'Year(s)' },
];

const mapToOption = option => ({ label: option, name: option });
const calendarTypeOptions = map(calendarTypes, mapToOption);
const untilTypeOptions = map(until, mapToOption);

const tourConfig = {
	version: 1, // increase this every time you make changes to the tourConfig,
	key: 'retriesSettings',
	steps: [
		{
			selector: '#retriesSettings',
			content: 'You can now set and apply the Retries setting at the schedule level for recurring schedules.',
		},
	],
};

const disabledDaysTooltip =
	'The end date you selected is earlier than the next run date. Please select a date that is later than the next scheduled run date or disable the schedule.';

class AddEditCustomerSchedulesAddEdit extends Component {
	constructor(props) {
		super(props);
		this.hebrewDateCloserRef = createRef();
		this.endDateRef = createRef();
		this.startDateRef = createRef();
		this.selectRef = createRef();

		const {
			schedule: { startDate, endDate },
		} = props;
		this.state = {
			isPopoverOpen: false,
			templateOptions: [],
			temporaryValue: '',
			startDate: moment(clone(startDate), apiDateFormat).format(displayDateFormat),
			endDate: moment(clone(endDate), apiDateFormat).format(displayDateFormat),
			isFullScheduleInfoOpen: false,
			fullScheduleData: null,
			includeConvenience: false,
			customKeys: [],
			customerRequiredFields: {},
			customerHiddenFields: {},
			customDisplayLabels: {},
			convenienceFees: {},
			recurringCustomFields: {},
		};
	}

	get today() {
		return today[this.props.schedule.calendarCulture];
	}

	get fieldClassName() {
		return `f-col f-col-sml-12 f-col-med-12 spc--bottom--sml--alt ${this.props.advancedView ? ' f-col-xxlrg-6' : ''}`;
	}

	componentDidMount = async () => {
		const { convenienceFees } = this.props;
		try {
			if (this.props.gridHolder) {
				this.props.gridHolder.addEventListener('scroll', this.closeDatePickers);
			}
			const [
				recurringTemplates,
				requiredFields,
				customDisplayLabels,
				customerHiddenFields,
			] = await this.props.makePendingRequest(
				kvaasService.get(
					kvaasResources.recurringTemplates,
					kvaasResources.customerRequiredFields,
					kvaasResources.transactionDisplayLabels,
					kvaasResources.customerHiddenFields
				)
			);
			const newState = {
				customerRequiredFields: get(requiredFields, 'data', {}),
				customDisplayLabels: get(customDisplayLabels, 'data', {}),
				customerHiddenFields: get(customerHiddenFields, 'data', {}),
				customKeys: [],
				templateOptions: [],
				templates: {},
				convenienceFees,
			};
			times(19, i => {
				const oneBasedIndex = i + 1;
				const key = `recurringCustom${oneBasedIndex}`;
				const padStartKey = `custom${padStart(i + 1, 2, 0)}`;
				if (!newState.customerHiddenFields[key]) {
					newState.customKeys.push(padStartKey);
				}
			});
			newState.selectedCustomerPaymentMethod = this.getSelectedCustomerPaymentMethod();
			this.mapResponseToState(recurringTemplates, newState);
			this.setState(newState);
		} catch (e) {
			this.props.handleError(e);
		}
	};

	componentDidUpdate = (prevProps, prevState) => {
		const {
			template,
			id,
			schedule: {
				_meta: { modified },
			},
			schedule,
		} = this.props;
		this.shouldAutoFocusAmount(prevProps);
		if (prevProps.schedule !== schedule && !!template && !modified) {
			const { convenienceFees } = this.state;
			this.props.applyTemplate(id, template, convenienceFees);
		}
		const selectedCustomerPaymentMethod = this.getSelectedCustomerPaymentMethod();

		if (
			prevProps.paymentMethods !== this.props.paymentMethods &&
			selectedCustomerPaymentMethod !== prevState.selectedCustomerPaymentMethod
		) {
			this.setState({
				selectedCustomerPaymentMethod,
			});
		}
	};

	componentWillUnmount = () => {
		if (this.props.gridHolder) {
			this.props.gridHolder.removeEventListener('scroll', this.closeDatePickers);
		}
	};

	shouldAutoFocusAmount = prevProps => {
		const { isExpanded, advancedView } = this.props;
		if (!prevProps.isExpanded && isExpanded && this.amountRef && advancedView) {
			this.amountRef.focus();
		}
	};
	getSelectedCustomerPaymentMethod = () => {
		const paymentMethodId =
			get(this.props, 'schedule.paymentMethodId') || get(this.props, 'general.defaultPaymentMethodId');
		return find(this.props.paymentMethods, paymentMethod => paymentMethod.xPaymentMethodId === paymentMethodId);
	};
	mapResponseToState = ({ data }, newState) => {
		each(data, (template, key) => {
			const parsed = JSON.parse(template);
			const label = parsed.xScheduleName;

			newState.templateOptions.push({ name: key, label });
			newState.templates[key] = parsed;
		});
		this.setState({ ...newState });
	};

	mapStateToCustomFields = () => {
		const { schedule, isViewOnly, convenienceFees } = this.props;
		const { customerRequiredFields, customDisplayLabels, customerHiddenFields } = this.state;
		const isActive = schedule._meta.originalData.isActive === true;
		return map(this.state.customKeys, key => {
			const number = key.slice(-2);
			if (number === '01') return;
			const parsedNumber = parseFloat(number);
			let value = schedule[key] || '';
			if (toLower(convenienceFees.originalCustomKey) === `custom${number}`) return;
			if (toLower(convenienceFees.convenienceCustomKey) === `custom${number}`) return;
			if (customerHiddenFields[`recurringCustom${parsedNumber}`]) return null;
			return (
				<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--sml--alt" key={key}>
					<label className="grid__recurring__main__label">
						{customDisplayLabels[`custom${parsedNumber}`] || `Custom${number}`}{' '}
						{this.renderRequired(`recurringCustom${parsedNumber}`, customerRequiredFields)}
					</label>
					<div className="inputgroup">
						<div className="inputgroup--main">
							<input
								type="text"
								name={key}
								className="input input--med"
								placeholder={customDisplayLabels[`custom${parsedNumber}`] || `Custom${number}`}
								value={value}
								onChange={this.onChange}
								disabled={!isActive || isViewOnly}
							/>
						</div>
					</div>
				</div>
			);
		});
	};

	closeDatePickers = () => {
		if (this.hebrewDateCloserRef.current) {
			this.hebrewDateCloserRef.current.click();
		}
		const singleDatePickerRef = document.querySelector(
			'.filter__date.filter__date--fullwidth.input--dateselect__holder .rc-menu-submenu-open .rc-menu-submenu-title'
		);
		if (singleDatePickerRef) {
			singleDatePickerRef.click();
		}
	};

	getIntervalType = name => {
		return find(intervalOptions, { name });
	};

	get currencyCode() {
		const {
			schedule: { currency },
		} = this.props;
		return CurrencyMap.resolveCurrency(currency);
	}

	toggleExpandCollapseSchedule = () => {
		const {
			schedule: {
				_meta: { id, scheduleExpanded },
			},
		} = this.props;
		this.props.onChange(id, '_meta.scheduleExpanded', !scheduleExpanded);
	};

	onChange = e => {
		const { id } = this.props;
		let { name, value, checked, type } = e.target;

		let allowChange = true;

		if (name === 'email' && this.props.onGeneralChange) {
			this.props.onGeneralChange({
				key: name,
				value: type === 'checkbox' ? checked : value,
			});
			return;
		}

		if (name === 'intervalCount' || name === 'totalPayments') {
			if (value === '0') return;
			try {
				const newVal = parseInt(value);
				if (newVal <= 0 || (newVal && isNaN(newVal))) {
					allowChange = false;
				}
			} catch (error) {
				allowChange = false;
			}
		}

		if (allowChange) {
			this.props.onChange(id, name, type === 'checkbox' ? checked : value);
		}
	};

	handleScheduleNameChange = schedule => {
		const { name, label } = schedule;
		const { id } = this.props;
		const { templates } = this.state;
		const key = findKey(templates, (_, key) => key == name);
		this.props.onChange(id, 'scheduleName', label);
		if (key) {
			const { convenienceFees } = this.state;

			this.props.applyTemplate(id, templates[key], convenienceFees);
			this.setState({ temporaryValue: label }, () => {
				const selectRef = get(this.selectRef, 'current');
				if (!selectRef) {
					return;
				}
				selectRef.blur();
				selectRef.focus();
				selectRef.blur();
			});
		}
	};

	handleSelectChange = ({ name: value }, { name }) => {
		const { id, onChange } = this.props;
		onChange(id, name, value);
	};

	handleCalendarTypeChange = ({ name: value }, { name }) => {
		const { id, onChange } = this.props;
		const today = moment().format(displayDateFormat);
		this.setState({ startDate: today });
		onChange(id, name, value);
	};

	handleNumberFormatChange = ({ value }, { target: { name } }) => {
		const { id } = this.props;
		if (value !== '') {
			value = toNumber(value);
		}

		this.props.onChange(id, name, value);
	};

	handleDateChange = (stateName, fieldName, datePickerRef, { formattedValue, value }) => {
		const { id } = this.props;
		this.setState({ [stateName]: formattedValue });
		if (value.length === 8 && moment(formattedValue, displayDateFormat, true).isValid()) {
			this.props.onChange(id, fieldName, moment(formattedValue, displayDateFormat, true).format(apiDateFormat));
			if (datePickerRef.current && datePickerRef.current.dateRef.current) {
				datePickerRef.current.dateRef.current.showMonth(moment.utc(formattedValue, displayDateFormat, true).toDate());
			}
		} else {
			this.props.onChange(id, fieldName, '');
		}
	};

	onSkipSaturdayAndHolidaysChange = () => {
		const { id, schedule } = this.props;
		this.props.onChange(id, 'skipSaturdayAndHolidays', !schedule.skipSaturdayAndHolidays);
	};

	onCustReceiptChange = async () => {
		const { id, schedule } = this.props;
		if (schedule.custReceipt && this.props.onGeneralChange) {
			await this.props.onGeneralChange({
				key: 'email',
				value: '',
			});
		}
		this.props.onChange(id, 'custReceipt', !schedule.custReceipt);
	};

	onuseDefaultPaymentMethodOnlyChange = () => {
		const { id, schedule } = this.props;
		this.props.onChange(id, 'useDefaultPaymentMethodOnly', !schedule.useDefaultPaymentMethodOnly);
	};

	checkIfAmountAllowed = ({ value }) => {
		return value.length < 16;
	};

	handleAmountChange = ({ floatValue = 0, value }) => {
		const { id } = this.props;
		const { convenienceFees, schedule } = this.props;
		const includeConvenience = schedule.includeConvenience === undefined ? true : schedule.includeConvenience;
		const { applyToRecurringSchedule, enableConvenienceFee } = convenienceFees;
		let amountWithConvenienceFee;
		let convenienceFee;
		if (
			applyToRecurringSchedule &&
			enableConvenienceFee &&
			includeConvenience &&
			(convenienceFees.ccPercentageAmount || convenienceFees.ccFlatAmount)
		) {
			amountWithConvenienceFee = floatValue + this.props.onBeforeCalculateConvenience(floatValue, convenienceFees);
			convenienceFee = this.props.onBeforeCalculateConvenience(floatValue, convenienceFees).toFixed(4);
		}

		this.props.onChange(id, 'amount', value, amountWithConvenienceFee, convenienceFee);
		if (this.amountRef && !endsWith(value, '.')) {
			this.amountRef.blur();
			this.amountRef.focus();
		}
	};

	handleOpenPreview = () => {
		const { id } = this.props;
		this.props.handleOpenPreview(id);
	};

	handleOpenViewPayments = () => {
		const {
			schedule: { scheduleId, scheduleName },
		} = this.props;
		this.props.handleOpenViewPayments(scheduleId, scheduleName);
	};
	handleOpenFullScheduleInfo = () => {
		if (!this.state.fullScheduleData) {
			this.getFullScheduleData(true);
		} else {
			this.setState({ isFullScheduleInfoOpen: true });
		}
	};
	handleCloseFullScheduleInfo = () => {
		this.setState({ isFullScheduleInfoOpen: false });
	};

	getFullScheduleData = async showPopup => {
		const {
			schedule: { scheduleId },
		} = this.props;
		let schedule = {};
		if (scheduleId) {
			try {
				schedule = await this.props.makePendingRequest(customerService.getFullRecurringSchedule(scheduleId));
				this.setState({
					fullScheduleData: schedule,
					isFullScheduleInfoOpen: showPopup,
				});
			} catch (e) {
				if (this.props.handleError(e)) {
					this.handleCloseFullScheduleInfo();
				}
			}
		}
		return schedule;
	};

	hasCustomValues = data => {
		const { convenienceCustomKey, originalCustomKey } = this.props.convenienceFees;
		const hasValue = data[toLower(convenienceCustomKey)] || data[toLower(originalCustomKey)];
		return hasValue !== undefined;
	};
	renderOption = props => <components.Option {...props}>{this.renderPaymentMethod(props)}</components.Option>;

	renderSelected = props => (
		<components.SingleValue {...props}>{this.renderPaymentMethod(props)}</components.SingleValue>
	);
	renderPaymentMethod = ({ data }) => {
		return (
			<Fragment>
				<img src={CardTypeImagePath.getPath(data.xCardType)} className="grid__creditcard" />
				<span>
					{data.xTokenAlias || data.xName} {data.xMaskedNumber.slice(-4)}
					{data.xIsDefaultPaymentMethod ? ' (default)' : null}
				</span>
			</Fragment>
		);
	};
	handleCustomerPaymentMethodChange = selectedCustomerPaymentMethod => {
		const newState = { selectedCustomerPaymentMethod };
		this.setState(newState, () => {
			this.props.onChange(this.props.id, 'paymentMethodId', selectedCustomerPaymentMethod.xPaymentMethodId);
		});
	};

	renderPaymentMethodSelect = (
		customerId,
		selectedCustomerPaymentMethod,
		paymentMethodOptions = this.props.paymentMethods
	) => {
		return (
			customerId && (
				<div className="f-col f-col-sml-12 f-col-med-12 f-col-xxlrg-6">
					<label className="grid-sidebar__main__label">Payment Method</label>
					<Select
						value={selectedCustomerPaymentMethod}
						options={paymentMethodOptions}
						components={{
							Option: this.renderOption,
							SingleValue: this.renderSelected,
						}}
						onChange={this.handleCustomerPaymentMethodChange}
						isClearable={false}
						isMulti={false}
						className="react-select-container"
						classNamePrefix="react-select"
					/>
				</div>
			)
		);
	};

	renderRequired = (key, customerRequiredFields) => {
		if (customerRequiredFields[key]) {
			return (
				<span data-tooltip="Required" className="label--required">
					*
				</span>
			);
		}
		return null;
	};

	renderCostFields = (id, schedule, required, isActive) => {
		const { amount, convenienceFee, totalAmount } = schedule;
		const { convenienceFees, customDisplayLabels, isViewOnly } = this.props;
		if (!convenienceFees) return [];
		const {
			enableConvenienceFee,
			applyToRecurringSchedule,
			convenienceCustomKey,
			originalCustomKey,
			allowExclude,
		} = convenienceFees;
		const convenienceFeeEnabled = enableConvenienceFee && applyToRecurringSchedule;
		const includeConvenience =
			(schedule.includeConvenience && convenienceFeeEnabled) ||
			(schedule[toLower(originalCustomKey)] && convenienceFeeEnabled);

		const scheduleCustomValue = schedule[toLower(originalCustomKey)];
		const scheduleConvenienceValue = schedule[toLower(convenienceCustomKey)];
		const displayTotalAndConvenienceFee = (!allowExclude && convenienceFeeEnabled) || includeConvenience;

		let totalToDisplay;
		if (scheduleCustomValue && !totalAmount) {
			totalToDisplay = scheduleCustomValue;
		} else if (totalAmount && amount > totalAmount) {
			totalToDisplay = amount;
		} else if (totalAmount) {
			totalToDisplay = totalAmount;
		} else {
			totalToDisplay = 0;
		}
		{
			return (
				<Fragment>
					<div className={this.fieldClassName} id="amountField">
						<label htmlFor={`${id}-amount`} className="grid-sidebar__main__label">
							{customDisplayLabels['amount'] || 'Amount'} {required}
						</label>
						<NumberFormat
							className="input input--med"
							prefix={this.currencyCode}
							value={
								convenienceFeeEnabled &&
								!schedule.totalAmount &&
								originalCustomKey &&
								!totalAmount &&
								!totalToDisplay &&
								schedule[toLower(originalCustomKey)]
									? schedule[toLower(originalCustomKey)]
									: schedule.amount || '' || schedule.amount
							}
							inputMode="decimal"
							onValueChange={this.handleAmountChange}
							isAllowed={this.checkIfAmountAllowed}
							name="amount"
							id={`${id}-amount`}
							placeholder={`${this.currencyCode}0`}
							thousandSeparator=","
							decimalSeparator="."
							allowNegative={false}
							decimalScale={2}
							getInputRef={el => (this.amountRef = el)}
							disabled={!isActive || isViewOnly}
							min="1"
							step="1"
						/>
					</div>
					{displayTotalAndConvenienceFee && (
						<div className={this.fieldClassName} id="totalAmountField">
							<label htmlFor={`${id}-totalAmount`} className="grid-sidebar__main__label">
								Total Amount
							</label>
							<NumberFormat
								className="input input--med"
								prefix={this.currencyCode}
								value={
									(schedule.includeConvenience === undefined && !totalAmount) || schedule.includeConvenience === false
										? amount
										: schedule.includeConvenience === false
										? totalAmount
										: amount > totalToDisplay
										? amount
										: totalToDisplay
								}
								inputMode="decimal"
								name="Total Amount"
								id={`${id}-totalAmount`}
								placeholder={`${this.currencyCode}0`}
								thousandSeparator=","
								decimalSeparator="."
								allowNegative={false}
								decimalScale={2}
								getInputRef={el => (this.amountRef = el)}
								disabled={true}
							/>
						</div>
					)}
					{displayTotalAndConvenienceFee && (
						<div className={`${this.fieldClassName} datatooltip--no-wrap`} id="convenienceFeeField">
							<label htmlFor={`${id}-convenienceFee`} className="grid-sidebar__main__label">Electronic Transfer Fee</label>
							<div data-tooltip="This field was previously referred to as 'Convenience Fee'" className="display--f spc--left--tny">
								<i className="icon icon--nano icon--info"></i>
							</div>
							<NumberFormat
								className="input input--med"
								prefix={this.currencyCode}
								value={convenienceFee || scheduleConvenienceValue || ''}
								inputMode="decimal"
								name="Electronic Transfer Fee"
								id={`${id}-convenienceFee`}
								placeholder={`${this.currencyCode}0`}
								thousandSeparator=","
								decimalSeparator="."
								allowNegative={false}
								decimalScale={2}
								getInputRef={el => (this.amountRef = el)}
								disabled={true}
							/>
						</div>
					)}
					{convenienceFeeEnabled &&
						applyToRecurringSchedule &&
						convenienceFees.allowExclude &&
						(convenienceFees.ccPercentageAmount || convenienceFees.ccFlatAmount) && (
							<div className="f-col f-col-sml-12 spc--bottom--sml--alt">
								<input
									tabIndex="-1"
									type="checkbox"
									name="includeConvenience"
									id={`${id}includeConvenience`}
									className="input--check datatooltip--convenience-fee"
									value={includeConvenience}
									checked={includeConvenience}
									onChange={() => this.props.includeExcludeConvenience(id, !includeConvenience)}
									disabled={!isActive || isViewOnly}
								/>
								<label htmlFor={`${id}includeConvenience`} className="datatooltip--no-wrap">
									<div
										className="display--ib spc--right--xsml"
										data-tooltip={`${schedule.includeConvenience ? 'Exclude' : 'Include'} Electronic Transfer Fee`}
									>
										<span>Include Electronic Transfer Fee</span>
									</div>{' '}
								</label>
							</div>
						)}
				</Fragment>
			);
		}
	};

	renderFullScheduleInfo = () => {
		const { fullScheduleData, isFullScheduleInfoOpen } = this.state;
		if (!fullScheduleData) return null;
		return (
			<FullScheduleInfo
				isOpen={isFullScheduleInfoOpen}
				onClose={this.handleCloseFullScheduleInfo}
				className="popup__content popup--sml"
				schedule={fullScheduleData}
			/>
		);
	};

	setCustomDaySelection = (date, key) => {
		const { id } = this.props;
		const value = moment(date.date).format(apiDateFormat);
		this.props.onChange(id, key, value);
	};

	setCustomDaySelectionHebrew = (date, key) => {
		const { id } = this.props;
		this.props.onChange(id, key, date);
	};

	closePopover = () => {
		if (!this.state.isPopoverOpen) return;
		this.setState({ isPopoverOpen: false });
	};

	toggleOpenClosePopover = () => {
		this.setState({ isPopoverOpen: !this.state.isPopoverOpen });
	};

	runPopoverAction = async (e, id, field) => {
		const { schedule } = this.props;
		if (field !== '_meta.saveAsTemplate' || (field === '_meta.saveAsTemplate' && schedule.scheduleName)) {
			await this.props.runPopoverAction(id, field);
		} else {
			e.preventDefault();
			e.stopPropagation();
		}
	};

	handleOpenActivateScheduleModal = async (e, id, isActive) => {
		const { handleOpenCloseModal } = this.props;
		let fullScheduleData = {};
		if (!isActive) {
			fullScheduleData = await this.getFullScheduleData();
		}
		if (isActive || (!isActive && !fullScheduleData.paymentsMissed)) {
			return this.runPopoverAction(e, id, 'isActive');
		}
		handleOpenCloseModal({
			name: modalNames.confirmAction,
			data: {
				loadingMessage: 'Activate Recurring Schedule',
				question: (
					<p>
						Note that when reactivating this schedule, if a scheduled payment was missed,
						<br /> we will process a payment immediately. The schedule will then resume as normal.
						<br /> <span className="display--ib spc--top--sml">Are you sure you want to proceed? </span>
					</p>
				),
				onConfirm: () => this.runPopoverAction(e, id, 'isActive'),
			},
		});
	};

	handleOpenDeleteModal = e => {
		const {
			schedule,
			handleOpenCloseModal,
			permissions: { allowCcSale, allowCheckSale },
		} = this.props;
		const hasPermissionToRemoveSchedule = allowCcSale || allowCheckSale;
		if (!hasPermissionToRemoveSchedule) {
			e.preventDefault();
			e.stopPropagation();
			return;
		}
		handleOpenCloseModal({
			name: modalNames.confirmAction,
			data: {
				loadingMessage: 'Deleting Recurring Schedule',
				question: `Deleting a recurring schedule cannot be undone. Are you sure you want to delete the schedule?`,
				onConfirm: () => this.runPopoverAction(e, schedule._meta.id, '_meta.deleted'),
			},
		});
	};

	handleInputChange = (...event) => {
		const { id } = this.props;
		const { temporaryValue } = this.state;
		const [value, { action }] = event;

		if (action === 'input-change') {
			this.props.onChange(id, 'scheduleName', value);
			this.setState({ temporaryValue: value });
		}
		if (action === 'menu-close' && !!temporaryValue) {
			this.props.onChange(id, 'scheduleName', temporaryValue);
		}
	};

	disableAutoComplete = e => {
		if (!e.target.autocomplete) {
			//this disables the chrome autofill feature
			e.target.autocomplete = 'autocomplete';
		}
	};

	syncInputWithDate = (name, value) => {
		this.setState({ [name]: value });
	};

	checkIfAllowedValue = ({ value }) => value > 0 && value < (isOlderThanSevenMonths ? 11 : 4);

	checkIfAllowedDaysBetweenRetries = ({ value }) => {
		return (value ? value > 0 : true) && value < (isOlderThanSevenMonths ? 100 : 4);
	};
	checkIfZeroValue = ({ value }) => {
		return value ? value > 0 : true;
	};

	renderScheduleProcessingOptions = () => {
		const {
			schedule,
			schedule: { failedTransactionRetryTimes, daysBetweenRetries, afterMaxRetriesAction },
			isExpanded,
			index,
			scheduleToFocus,
			advancedView,
			isViewOnly,
		} = this.props;
		const id = schedule._meta.id;
		const hasScheduleId = !!schedule.scheduleId;
		const isActive = schedule._meta.originalData.isActive === true;
		const afterMaxRetriesActionValue = find(afterMaxRetriesActionOptions, { name: afterMaxRetriesAction });
		const createScheduleFailChecked = get(schedule, 'allowInitialTransactionToDecline', false);
		return (
			<div className="f-col f-col-sml-12">
				{!hasScheduleId && (
					<div className="spc--bottom--sml">
						<input
							type="checkbox"
							id={`${id}-allowInitialTransactionToDecline`}
							name="allowInitialTransactionToDecline"
							className="input input--check"
							value={createScheduleFailChecked}
							checked={createScheduleFailChecked}
							onChange={this.onChange}
							disabled={!isActive || isViewOnly}
						/>
						<label htmlFor={`${id}-allowInitialTransactionToDecline`}>
							Create the schedule even if the initial payment fails
						</label>
					</div>
				)}

				<div className="spc--bottom--sml">
					<div className="message message--primary">
						<p>Note:</p>
						<p>
							If you set up a schedule that recurs sooner than the retry settings, its transactions won't be retried.
						</p>
						<p>Total tries includes the original transaction.</p>
					</div>
				</div>

				{failedTransactionRetryTimes > 5 && (
					<div className="validation spc--bottom--med">
						<p className="type--error">{warningMessage}</p>
					</div>
				)}
				<div id="retriesSettings" className="flex--primary flex--gap--12 datatooltip--w--200 datatooltip--left spc--bottom--sml">
					<div className="flex--primary flex--gap--12">
						<label>After</label>
						<NumberFormat
							className="input input--med w--54p"
							id={`${id}-failedTransactionRetryTimes`}
							name="failedTransactionRetryTimes"
							value={failedTransactionRetryTimes}
							format="##"
							allowNegative={false}
							isAllowed={this.checkIfAllowedValue}
							placeholder={isOlderThanSevenMonths ? '1-10' : '1-3'}
							inputMode="numeric"
							disabled={!isActive || isViewOnly}
							onValueChange={this.handleNumberFormatChange}
						/>
						<div className="flex--primary datatooltip--olderThanSevenMonths">
							<label>Tries</label>
							{isOlderThanSevenMonths && (
								<div className="display--f spc--left--tny" data-tooltip="Determine the number of times recurring will retry after a failed attempt, set the number of days between retries and whether the schedule should resume or deactivate if all retries fail.">
									<i className="icon icon--tiny icon--info"></i>
								</div>
							)}
						</div>
						<div className="flex--primary">
							<label>With</label>
							<div className="pos--rel display--ib">
								<NumberFormat
									id={`${id}-daysBetweenRetries`}
									name="daysBetweenRetries"
									className="input input--med w--54p spc--left--sml"
									onValueChange={this.handleNumberFormatChange}
									value={daysBetweenRetries}
									format="##"
									allowNegative={false}
									isAllowed={this.checkIfAllowedDaysBetweenRetries}
									placeholder={isOlderThanSevenMonths ? '1' : '3'}
									inputMode="numeric"
									disabled={!isActive || isViewOnly}
								/>
							</div>
						</div>
					</div>

					<div className="flex--primary flex--gap--12 datatooltip--w--200">
						<label>Day(s) between Retries:</label>
						<div data-tooltip={!isOlderThanSevenMonths ? newAccountAfterMaxRetriesTooltip : null}>
							<Select
								className="react-select-container w--210p"
								classNamePrefix="react-select"
								menuPlacement="auto"
								name="afterMaxRetriesAction"
								id={`${id}-afterMaxRetriesAction`}
								value={afterMaxRetriesActionValue}
								options={afterMaxRetriesActionOptions}
								onChange={this.handleSelectChange}
								onFocus={this.disableAutoComplete}
								isDisabled={!isOlderThanSevenMonths || !isActive || isViewOnly}
							/>
						</div>
						<div className="datatooltip--newtransaction datatooltip--right">
							<div data-tooltip={afterMaxRetriesActionValue && afterMaxRetriesActionValue.tooltip}>
								<i className="icon icon--tiny icon--info"></i>
							</div>
						</div>
					</div>
				</div>
				{(scheduleToFocus ? scheduleToFocus === schedule.scheduleId : index === 0) &&
					(advancedView ? isExpanded : true) &&
					schedule._meta.scheduleExpanded && <Tour tourConfig={tourConfig} />}
			</div>
		);
	};

	renderAdvancedViewOutsideClick = ({
		scheduleToFocus,
		schedule,
		index,
		isPopoverOpen,
		hasScheduleId,
		isViewOnly,
		id,
		isActive,
		hasPermissionToRemoveSchedule,
	}) => {
		return (
			<OutsideClick action={this.closePopover}>
				<div className="btn btn--sml btn--ghost" onClick={this.toggleOpenClosePopover}>
					<i
						className={`icon icon--tiny icon--more-dots--grey icon--middle pos--rel cursor--pointer ${
							scheduleToFocus
								? scheduleToFocus === schedule.scheduleId
									? 'viewPaymentsWidget'
									: ''
								: index === 0
								? 'viewPaymentsWidget'
								: ''
						}`}
					>
						{isPopoverOpen && (
							<div className="grid-sidebar__popover__wrapper">
								<div className="grid-sidebar__popover">
									<ul>
										{hasScheduleId && !isViewOnly && (
											<li onClick={e => this.handleOpenActivateScheduleModal(e, id, isActive)}>
												<a>
													<i
														className={`icon icon--tiny spc--right--xsml icon--check-circle--${
															isActive ? 'grey' : 'success'
														} align--v--middle`}
													></i>
													<span className="display--ib align--v--middle">
														{`${isActive ? 'Deactivate' : 'Activate'} Schedule`}
													</span>
												</a>
											</li>
										)}
										{!isViewOnly && (
											<li
												className="datatooltip--w--200"
												onClick={e => this.runPopoverAction(e, id, '_meta.saveAsTemplate')}
											>
												<a
													data-tooltip={
														schedule.scheduleName
															? null
															: 'Schedule Name field is required in order to save as a template'
													}
												>
													<i className="icon icon--tiny spc--right--xsml icon--save--grey align--v--middle"></i>
													<span className="display--ib align--v--middle">Save As Template</span>
												</a>
											</li>
										)}
										{hasScheduleId && (
											<Fragment>
												<li onClick={this.handleOpenPreview}>
													<a>
														<i className="icon icon--tiny spc--right--xsml icon--preview--grey align--v--middle"></i>
														<span className="display--ib align--v--middle">Preview Schedule</span>
													</a>
												</li>
												<li onClick={this.handleOpenViewPayments}>
													<a>
														<i className="icon icon--tiny spc--right--xsml icon--card-alt align--v--middle"></i>
														<span className="display--ib align--v--middle">View Payments</span>
													</a>
												</li>
												<li onClick={this.handleOpenFullScheduleInfo}>
													<a>
														<i className="icon icon--tiny icon--description--grey icon--middle spc--right--xsml"></i>
														<span className="display--ib align--v--middle">Full Schedule Information</span>
													</a>
												</li>
											</Fragment>
										)}
										{!isViewOnly && (
											<li
												onClick={e => this.handleOpenDeleteModal(e, id, '_meta.deleted')}
												data-tooltip={!hasPermissionToRemoveSchedule ? 'Permission required' : null}
											>
												<a className="type--color--warning">
													<i className="icon icon--tiny spc--right--xsml icon--delete-alt--warning align--v--middle"></i>
													<span className="display--ib align--v--middle">Remove Schedule</span>
												</a>
											</li>
										)}
									</ul>
								</div>
							</div>
						)}
					</i>
				</div>
			</OutsideClick>
		);
	};
	renderAdvancedView = ({
		id,
		advancedView,
		schedule,
		hasScheduleId,
		isActive,
		scheduleTitle,
		scheduleToFocus,
		index,
		isPopoverOpen,
		isViewOnly,
		hasPermissionToRemoveSchedule,
	}) => {
		{
			return (
				(advancedView && (
					<Fragment>
						<div className="grid-sidebar__body__header">
							<div className="cursor--pointer display--f f--a--c" onClick={this.toggleExpandCollapseSchedule}>
								<div>
									<i
										className={`icon icon--nano icon--arrow--${
											schedule._meta.scheduleExpanded ? 'down' : 'right'
										}--primary icon--middle spc--right--tny`}
									></i>
								</div>
								{hasScheduleId && (
									<div>
										<i
											className={`icon icon--tiny icon--check-circle--${
												isActive ? 'success' : 'grey'
											} icon--middle spc--right--tny flex--no-shrink`}
											data-tooltip={isActive ? 'Active' : 'Inactive'}
										></i>
									</div>
								)}
								<label className="grid-sidebar__body__title cursor--pointer">
									{scheduleTitle}{' '}
									{hasScheduleId && schedule.scheduleName !== schedule.scheduleId && (
										<div onClick={e => e.stopPropagation()} className="status status--grey">
											{schedule.scheduleId}
										</div>
									)}
								</label>
							</div>
							<div>
								{this.renderAdvancedViewOutsideClick({
									scheduleToFocus,
									schedule,
									index,
									isPopoverOpen,
									hasScheduleId,
									isViewOnly,
									id,
									isActive,
									hasPermissionToRemoveSchedule,
								})}
								{this.renderFullScheduleInfo()}
							</div>
						</div>
					</Fragment>
				)) ||
				null
			);
		}
	};

	renderScheduleEndDate = ({
		id,
		required,
		schedule,
		advancedView,
		isViewOnly,
		isEndDateDisabled,
		endDate,
		endMoment,
		earliestExpiryDate,
	}) => {
		return (
			<div className={this.fieldClassName}>
				<div className="datatooltip--recurringschedule">
					<label htmlFor={`${id}-endDate`} className="grid-sidebar__main__label">
						<span>Date {required}</span>
					</label>
				</div>
				<div className="customer__item__value">
					<div className="filter__date filter__date--fullwidth">
						{schedule.calendarCulture === calendarTypes.GREGORIAN || !advancedView ? (
							<Menu mode={'horizontal'} openAnimation={'slide-up'} triggerSubMenuAction={'click'} disabled={isViewOnly}>
								<SubMenu
									popupClassName="rc-menu-datepicker-tooltip"
									className="customer__datepicker"
									disabled={isEndDateDisabled}
									title={
										<NumberFormat
											value={endDate || '-'}
											format="##/##/####"
											className="input"
											placeholder={displayDateFormat}
											mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
											onValueChange={e => this.handleDateChange('endDate', 'endDate', this.endDateRef, e)}
											disabled={isEndDateDisabled}
										/>
									}
									mode={'vertical-right'}
									key={'custom'}
								>
									<MenuItem disabled>
										<SingleDatePicker
											isFilter={false}
											date={endMoment}
											onChange={date => this.setCustomDaySelection(date, 'endDate')}
											disabledDays={[earliestExpiryDate, { before: earliestExpiryDate }]}
											type="endDate"
											syncInputWithDate={this.syncInputWithDate}
											ref={this.endDateRef}
											customerError={
												endDate !== 'Invalid date' && !moment(endDate, displayDateFormat).isValid()
													? 'Invalid date'
													: ''
											}
										/>
									</MenuItem>
								</SubMenu>
							</Menu>
						) : (
							<HebrewDatePicker
								disabledDaysTooltip={disabledDaysTooltip}
								disabled={isEndDateDisabled || isViewOnly}
								date={schedule.endDate}
								dependentValues={[schedule.startDate]}
								onChange={date => this.setCustomDaySelectionHebrew(date, 'endDate')}
								filter={date =>
									isAfter(
										displayToApi(toHebrew(date)),
										schedule.nextScheduledRunTime ? displayToApi(toHebrew(earliestExpiryDate)) : schedule.startDate
									) && moment(date).isAfter(today[calendarTypes.GREGORIAN], 'day')
								}
							/>
						)}
					</div>
				</div>
			</div>
		);
	};
	render = () => {
		const {
			schedule,
			advancedView,
			permissions: { allowCcSale, allowCheckSale },
			hideSkipSabbath,
			index,
			scheduleToFocus,
			general,
			isLoading,
			customDisplayLabels,
			isViewOnly,
		} = this.props;
		const {
			templateOptions,
			startDate,
			endDate,
			isPopoverOpen,
			temporaryValue,
			customerRequiredFields,
			customerHiddenFields,
			selectedCustomerPaymentMethod,
			paymentMethodOptions,
		} = this.state;
		const hasPermissionToRemoveSchedule = allowCcSale || allowCheckSale;
		const id = schedule._meta.id;

		if (schedule._meta.isLoading || isLoading) {
			return (
				<div className="loader__holder">
					<div className="loader__spinner"></div>
				</div>
			);
		} else {
			const startMoment = schedule.startDate
				? moment(schedule.startDate, apiDateFormat)
				: moment(
						moment()
							.tz(apiTimezone)
							.startOf('day')
							.format(apiDateFormat),
						apiDateFormat
				  );
			const endMoment = schedule.endDate ? moment(schedule.endDate, apiDateFormat) : startMoment.clone();
			const nextScheduledRunTimeMoment = schedule.nextScheduledRunTime
				? moment(schedule.nextScheduledRunTime)
				: startMoment;
			const earliestExpiryDate = moment
				.max(
					nextScheduledRunTimeMoment,
					moment(
						moment()
							.tz(apiTimezone)
							.startOf('day')
							.format(apiDateFormat),
						apiDateFormat
					)
				)
				.toDate();
			const isActive = schedule._meta.originalData.isActive === true;
			const hasScheduleId = !!schedule.scheduleId;
			const isStartDateDisabled =
				schedule.startDate === schedule._meta.originalData.startDate &&
				hasScheduleId &&
				!isAfter(schedule.startDate, this.today);
			const isEndDateDisabled =
				!hasPermissionToRemoveSchedule ||
				schedule._meta.originalData.isActive === false ||
				(!!schedule.endDate &&
					schedule.endDate === schedule._meta.originalData.endDate &&
					hasScheduleId &&
					!isAfter(schedule.endDate, this.today));
			const scheduleTitle = schedule.scheduleName || schedule.scheduleId || 'New schedule';
			const required = advancedView ? (
				<span data-tooltip="Required" className="label--required">
					*
				</span>
			) : null;
			const showSpecificDayOfWeekCheckbox = toLower(schedule.intervalType) === 'month' && !hasScheduleId;
			const displayDailyScheduleWarningMessage =
				schedule.intervalType === 'day' && !schedule.scheduleId && !isOlderThanSevenMonths;
			const scheduleNameLabel = customDisplayLabels['scheduleName'] || 'Schedule Name';
			const descriptionLabel = customDisplayLabels['description'] || 'Description';
			const invoiceLabel = customDisplayLabels['invoice'] || 'Invoice';
			return (
				<div ref={this.hebrewDateCloserRef}>
					<div>
						<div>
							{!advancedView && (
								<div className="message message--primary fullwidth type--center spc--bottom--med">
									NOTE: By entering an amount you are creating a new recurring schedule.
								</div>
							)}
							{this.renderAdvancedView({
								id,
								advancedView,
								schedule,
								hasScheduleId,
								isActive,
								scheduleTitle,
								scheduleToFocus,
								index,
								isPopoverOpen,
								isViewOnly,
								hasPermissionToRemoveSchedule,
							})}
							{map(schedule._meta.errorMessages, (errorMessage, index) => (
								<div key={index}>
									<div className="validation spc--bottom--med">
										<p className="type--error">{errorMessage}</p>
									</div>
								</div>
							))}
							{displayDailyScheduleWarningMessage && (
								<div className="validation spc--bottom--med">
									<p className="type--error">
										Daily recurring scheduling is not recommended for new accounts as it may result in risk issues. By
										selecting the daily recurring option, you are acknowledging and consenting to higher risk for your
										account.
									</p>
								</div>
							)}
						</div>

						{schedule._meta.scheduleExpanded && (
							<Fragment>
								<div className="f-row">
									{advancedView && !customerHiddenFields['scheduleName'] && (
										<div className={this.fieldClassName}>
											<label htmlFor={`${id}-scheduleName`} className="grid-sidebar__main__label">
												{scheduleNameLabel}
												{this.renderRequired('scheduleName', customerRequiredFields)}
											</label>
											<div className="customer__item__value">
												{hasScheduleId || isEmpty(templateOptions) ? (
													<input
														type="text"
														id={`${id}-scheduleName`}
														name="scheduleName"
														className="input input--med"
														placeholder={scheduleNameLabel}
														value={schedule.scheduleName || ''}
														onChange={this.onChange}
														disabled={(!isActive && hasScheduleId) || isViewOnly}
													/>
												) : (
													<Select
														isDisabled={isViewOnly}
														ref={this.selectRef}
														className="react-select-container"
														classNamePrefix="react-select"
														placeholder={scheduleNameLabel}
														name="scheduleName"
														id={`${id}-scheduleName`}
														options={templateOptions}
														value={mapToOption(schedule.scheduleName)}
														inputValue={temporaryValue}
														onChange={this.handleScheduleNameChange}
														onInputChange={this.handleInputChange}
														onFocus={this.disableAutoComplete}
													/>
												)}
											</div>
										</div>
									)}

									{advancedView && !customerHiddenFields['description'] && (
										<div className={this.fieldClassName}>
											<label htmlFor={`${id}-description`} className="grid-sidebar__main__label">
												{descriptionLabel}
												{this.renderRequired('description', customerRequiredFields)}
											</label>
											<div className="customer__item__value">
												<input
													type="text"
													id={`${id}-description`}
													name="description"
													className="input input--med"
													placeholder={descriptionLabel}
													value={schedule.description || ''}
													onChange={this.onChange}
													disabled={(!isActive && hasScheduleId) || isViewOnly}
												/>
											</div>
										</div>
									)}
									{this.renderCostFields(id, schedule, required, isActive)}
									{this.renderPaymentMethodSelect(
										general.customerId,
										selectedCustomerPaymentMethod,
										paymentMethodOptions
									)}
									{!customerHiddenFields['invoice'] && (
										<div className={this.fieldClassName}>
											<label htmlFor={`${id}-amount`} className="grid__recurring__main__label">
												{invoiceLabel}
												{this.renderRequired('invoice', customerRequiredFields)}
											</label>
											<input
												type="text"
												id={`${id}-invoice`}
												name="invoice"
												className="input input--med"
												placeholder={invoiceLabel}
												value={schedule.invoice || ''}
												onChange={this.onChange}
												disabled={(!isActive && hasScheduleId) || isViewOnly}
											/>
										</div>
									)}

									<div className={this.fieldClassName}>
										<div className="flex--primary datatooltip--frequency">
											<label htmlFor={`${id}-intervalCount`} className="grid-sidebar__main__label spc--bottom--tny">
												<span className="display--ib align--v--middle spc--right--tny">Frequency</span>
											</label>
											<div className="display--f" data-tooltip="Interval type">
												<i className="icon icon--nano icon--info"></i>
											</div>
										</div>
										<div className="display--f">
											<div className="spc--top--xsml spc--right--sml type--color--text--regular type--wgt--medium">
												EVERY
											</div>
											<div className="customer__frequency__input spc--right--sml">
												<input
													disabled={hasScheduleId || isViewOnly}
													type="number"
													min="1"
													step="1"
													id={`${id}-intervalCount`}
													name="intervalCount"
													className="input input--med"
													placeholder="1"
													value={schedule.intervalCount || ''}
													onChange={this.onChange}
													inputMode="numeric"
												/>
											</div>
											<div className="customer__frequency__select">
												<Select
													isDisabled={hasScheduleId || isViewOnly}
													id={`${id}-intervalType`}
													name="intervalType"
													className="react-select-container"
													classNamePrefix="react-select"
													value={this.getIntervalType(toLower(schedule.intervalType)) || {}}
													options={intervalOptions}
													onChange={this.handleSelectChange}
													onFocus={this.disableAutoComplete}
												/>
											</div>
										</div>
										{showSpecificDayOfWeekCheckbox && (
											<div className="flex--primary flex--nowrap spc--top--tny datatooltip--recurringschedule--future">
												<input
													type="checkbox"
													id={`${id}-_meta.specificDayOfWeek`}
													name="_meta.specificDayOfWeek"
													className="input input--check input--check--sml"
													value={schedule._meta.specificDayOfWeek || false}
													checked={schedule._meta.specificDayOfWeek || false}
													onChange={this.onChange}
													disabled={isViewOnly}
												/>
												<label htmlFor={`${id}-_meta.specificDayOfWeek`}>
													Run recurring at a specific day of the week
												</label>
												<div data-tooltip="Run future transactions on the same day as the first transaction, e.g. first Sunday of the month.">
													<i className="icon icon--nano icon--info align--v--middle spc--left--xsml"></i>
												</div>
											</div>
										)}
									</div>

									<div className={`f-col f-col-sml-12 spc--bottom--sml${advancedView ? ' f-col-xxlrg-6' : ''}`}>
										<div>
											<label htmlFor={`${id}-startDate`} className="grid-sidebar__main__label">
												<span className="spc--right--tny">Start {required}</span>
											</label>
											<div className="display--ib datatooltip--recurringschedule">
												<div data-tooltip="When selecting today’s date, a transaction will be processed immediately.">
													<i className="icon icon--nano icon--info align--v--middle"></i>
												</div>
											</div>
										</div>
										<div className="filter__date filter__date--fullwidth spc--bottom--nano">
											{schedule.calendarCulture === calendarTypes.GREGORIAN ? (
												<Menu
													mode={'horizontal'}
													openAnimation={'slide-up'}
													triggerSubMenuAction={'click'}
													disabled={isViewOnly}
												>
													<SubMenu
														className="customer__datepicker"
														disabled={isStartDateDisabled}
														mode={'vertical-right'}
														key={'custom'}
														title={
															<NumberFormat
																value={startDate || '-'}
																format="##/##/####"
																className="input"
																placeholder={displayDateFormat}
																mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
																onValueChange={e =>
																	this.handleDateChange('startDate', 'startDate', this.startDateRef, e)
																}
																disabled={isStartDateDisabled}
															/>
														}
													>
														<MenuItem disabled>
															<SingleDatePicker
																isFilter={false}
																date={startMoment}
																onChange={date => this.setCustomDaySelection(date, 'startDate')}
																disabledDays={{ before: new Date() }}
																type="startDate"
																syncInputWithDate={this.syncInputWithDate}
																ref={this.startDateRef}
																customerError={
																	startDate !== '' && !moment(startDate, displayDateFormat).isValid()
																		? 'Invalid date'
																		: ''
																}
															/>
														</MenuItem>
													</SubMenu>
												</Menu>
											) : (
												<HebrewDatePicker
													date={schedule.startDate}
													onChange={date => this.setCustomDaySelectionHebrew(date, 'startDate')}
													filter={date => moment(date).isSameOrAfter(today[calendarTypes.GREGORIAN], 'day')}
													disabled={isStartDateDisabled || isViewOnly}
												/>
											)}
										</div>
										{!hideSkipSabbath && (
											<div className="spc--top--tny">
												<input
													type="checkbox"
													id={`${id}-skipSaturdayAndHolidays`}
													name="skipSaturdayAndHolidays"
													className="input input--check input--check--sml"
													value={schedule.skipSaturdayAndHolidays || false}
													checked={schedule.skipSaturdayAndHolidays || false}
													onChange={this.onSkipSaturdayAndHolidaysChange}
													disabled={(!isActive && hasScheduleId) || isViewOnly}
												/>
												<label htmlFor={`${id}-skipSaturdayAndHolidays`}>Skip Sabbath and Holidays</label>
											</div>
										)}
									</div>

									{advancedView && (
										<div className={this.fieldClassName}>
											<label htmlFor={`${id}-calendarCulture`} className="grid-sidebar__main__label">
												Calendar type
											</label>
											<div className="customer__item__value">
												<Select
													isDisabled={hasScheduleId || isViewOnly}
													id={`${id}-calendarCulture`}
													name="calendarCulture"
													className="react-select-container"
													classNamePrefix="react-select"
													value={mapToOption(schedule.calendarCulture)}
													options={calendarTypeOptions}
													onChange={this.handleCalendarTypeChange}
													onFocus={this.disableAutoComplete}
												/>
											</div>
										</div>
									)}

									<div className={this.fieldClassName}>
										<label htmlFor={`${id}-_meta.until`} className="grid-sidebar__main__label">
											End
										</label>
										<div className="customer__item__value">
											<Select
												isSearchable={false}
												isDisabled={isEndDateDisabled || isViewOnly}
												id={`${id}-_meta.until`}
												name="_meta.until"
												className="react-select-container"
												classNamePrefix="react-select"
												value={mapToOption(schedule._meta.until)}
												options={untilTypeOptions}
												onChange={this.handleSelectChange}
												onFocus={this.disableAutoComplete}
											/>
										</div>
									</div>

									{schedule._meta.until === until.ENDDATE &&
										this.renderScheduleEndDate({
											id,
											required,
											schedule,
											advancedView,
											isViewOnly,
											isEndDateDisabled,
											endDate,
											endMoment,
											earliestExpiryDate,
										})}

									{schedule._meta.until === until.PAYMENTS && (
										<div id="numberOfPayments" className={this.fieldClassName}>
											<label htmlFor={`${id}-totalPayments`} className="grid-sidebar__main__label">
												Number of Payments
											</label>
											<div className="customer__item__value">
												<NumberFormat
													id={`${id}-totalPayments`}
													value={schedule.totalPayments}
													onValueChange={this.handleNumberFormatChange}
													format="###"
													placeholder="Indefinite"
													className="input input--med"
													name="totalPayments"
													inputMode="numeric"
													disabled={isEndDateDisabled || isViewOnly}
													isAllowed={this.checkIfZeroValue}
												/>
											</div>
										</div>
									)}

									<div className="f-col f-col-sml-12">
										<div className="spc--bottom--sml--alt">
											<input
												type="checkbox"
												id={`${id}-custReceipt`}
												name="custReceipt"
												className="input input--check"
												value={schedule.custReceipt || false}
												checked={schedule.custReceipt || false}
												onChange={this.onCustReceiptChange}
												disabled={(!isActive && hasScheduleId) || isViewOnly}
											/>
											<label htmlFor={`${id}-custReceipt`}>Send receipt</label>
										</div>
										{schedule.custReceipt && general && (
											<div className="spc--bottom--sml--alt">
												<label htmlFor={`${general.customerId}-email`} className="grid__recurring__main__label">
													Email address
												</label>
												<div className="customer__item__value">
													<input
														type="text"
														id={`${general.customerId}-email`}
														name="email"
														className="input input--med"
														placeholder="Email address"
														value={general.email || ''}
														onChange={this.onChange}
														inputMode="email"
														disabled={isViewOnly}
													/>
												</div>
											</div>
										)}
									</div>
									<div className="f-col f-col-sml-12 spc--bottom--sml--alt">
										<input
											type="checkbox"
											id={`${id}-useDefaultPaymentMethodOnly`}
											name="useDefaultPaymentMethodOnly"
											className="input input--check"
											value={schedule.useDefaultPaymentMethodOnly || false}
											checked={schedule.useDefaultPaymentMethodOnly || false}
											onChange={this.onuseDefaultPaymentMethodOnlyChange}
											disabled={(!isActive && hasScheduleId) || isViewOnly}
										/>
										<label htmlFor={`${id}-useDefaultPaymentMethodOnly`}>
											Retry declined recurring with default card only
										</label>
									</div>
									{this.renderScheduleProcessingOptions()}
									<div className="f-col f-col-sml-12">
										<hr className="separator separator--grey1 separator--negative--24 spc--bottom--med" />
									</div>
									<div className="f-col f-col-sml-12 spc--bottom--sml--alt">
										<label className="type--color--primary type--wgt--medium spc--bottom--sml">Custom Fields</label>
									</div>
									<div className="f-col f-col-sml-12">
										<div className="f-row f-row--bottom">{this.mapStateToCustomFields()}</div>
									</div>
								</div>
							</Fragment>
						)}
					</div>
				</div>
			);
		}
	};
}

AddEditCustomerSchedulesAddEdit.propTypes = {
	id: PropTypes.string.isRequired,
	schedule: PropTypes.object.isRequired,
	general: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	paymentMethods: PropTypes.array.isRequired,
	onGeneralChange: PropTypes.func,
	gridHolder: PropTypes.object,
	advancedView: PropTypes.bool.isRequired,
	applyTemplate: PropTypes.func.isRequired,
	template: PropTypes.object,
	handleOpenPreview: PropTypes.func,
	handleOpenViewPayments: PropTypes.func,
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func,
	isExpanded: PropTypes.bool,
	isViewOnly: PropTypes.bool,
	permissions: PropTypes.object.isRequired,
	hideSkipSabbath: PropTypes.bool,
	runPopoverAction: PropTypes.func.isRequired,
	handleOpenCloseModal: PropTypes.func.isRequired,
	index: PropTypes.number,
	scheduleToFocus: PropTypes.string,
	isLoading: PropTypes.bool.isRequired,
	convenienceFees: PropTypes.object,
	onBeforeCalculateConvenience: PropTypes.func.isRequired,
	includeExcludeConvenience: PropTypes.func.isRequired,
	customDisplayLabels: PropTypes.any,
	data: PropTypes.any,
};

export default withError(withCancelable(AddEditCustomerSchedulesAddEdit));
