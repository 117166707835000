import { has, isFunction } from 'lodash';

class InMemoryCacheService {
	constructor(cacheKey) {
		this.cacheKey = cacheKey;
		this._cache = {};
	}

	clear(table, key) {
		if (!table) {
			this._cache = {};
			return;
		}
		if (!key) {
			this._cache[table] = {};
			return;
		}
		if (has(this._cache[table], key)) {
			this._cache[table][key] = null;
		}
	}

	async loadOrFetch(table, key, fetchMethod) {
		const fromCache = this.load(table, key);
		if (fromCache) {
			return fromCache;
		}
		if (isFunction(fetchMethod)) {
			const value = await fetchMethod();
			if (value) {
				this.save(table, key, value);
			}
			return value;
		}
	}

	load(table, key) {
		if (has(this._cache[table], key)) {
			return this._cache[table][key];
		}
	}

	save(table, key, value) {
		if (!this._cache[table]) {
			this._cache[table] = {};
		}
		this._cache[table][key] = value;
	}
}

export default InMemoryCacheService;
