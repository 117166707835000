import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { YearMonth } from 'common/components/date-picker/components';

const dateFormat = 'MM/DD/YYYY';

class SingleDatePickerComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			date: this.props.date.toDate() || moment.utc().toDate(),
			tempDate: this.props.date.format(dateFormat),
			displaySelector: false,
			errors: {
				date: null,
			},
		};

		this.dateRef = React.createRef();
	}
	componentDidMount() {
		this.setState({
			availableYears: this.getYears(),
		});
	}
	componentWillReceiveProps = nextProps => {
		let updateObj = {};

		if (!nextProps.date.isSame(this.props.date)) {
			updateObj.date = nextProps.date.toDate();
			updateObj.tempDate = nextProps.date.format(dateFormat);
		}

		this.setState({
			...updateObj,
		});
	};

	getYears = () => {
		const currentYear = new Date().getFullYear();
		const fromMonth = new Date(currentYear, 0);
		const toMonth = new Date(currentYear + 10, 11);
		const calendarYears = [];

		this.mapCalendarYears(calendarYears, fromMonth, toMonth);
		return calendarYears;
	};

	mapCalendarYears = (calendarYears, bottomBarrier, upperBarrier) => {
		for (let i = bottomBarrier.getFullYear(); i <= upperBarrier.getFullYear(); i += 1) {
			calendarYears.push(i);
		}
	};

	handleDayClick = (day, { selected }) => {
		if (!selected) {
			this.setState(
				{
					date: day,
					tempDate: moment.utc(day).format(dateFormat),
				},
				() => {
					const stringDate = moment(day.toISOString()).format(ApplicationSettings.displayDateFormat);
					this.handleOnChange(day);
					if (!this.props.isFilter) {
						this.props.syncInputWithDate(this.props.type, stringDate);
					}
				}
			);
		}
	};

	handleOnChange = date => {
		this.props.onChange({ date: moment.utc(date) });
	};

	handleInputChange = values => {
		const { formattedValue } = values;
		const { errors } = this.state;

		const date = moment.utc(formattedValue, dateFormat, true);

		if (date.isValid()) {
			errors.date = null;
			this.setState(
				{
					date: date.toDate(),
				},
				() => {
					if (this.dateRef.current) {
						this.dateRef.current.showMonth(this.state.date);
					}
					this.handleOnChange(this.state.date);
				}
			);
		} else {
			errors.date = 'Invalid date';
		}

		this.setState({
			errors: errors,
			tempDate: formattedValue,
		});
	};

	render() {
		const { isFilter, customerError, type, goButtonHandler } = this.props;
		const { date, errors, tempDate } = this.state;

		return (
			<div className="inputfromto__holder inputfromto__holder--single">
				{(isFilter || errors.date || customerError) && (
					<div className="inputfromto__heading">
						{isFilter && (
							<div className="inputgroup">
								<div className="inputgroup--main">
									<NumberFormat
										value={tempDate}
										format="##/##/####"
										className="input input--med input--date inputgroup--main--bordered--left"
										placeholder={dateFormat}
										mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
										onValueChange={this.handleInputChange}
										getInputRef={this.props.inputRef}
										autoFocus={true}
									/>
								</div>
								{goButtonHandler && (
									<div className="inputgroup--aside">
										<button
											className="btn btn--med btn--primary inputgroup--aside--bordered--right"
											data-tooltip="Apply"
											onClick={goButtonHandler}
										>
											<i className="icon icon--nano icon--arrow--right--positive"></i>
										</button>
									</div>
								)}
							</div>
						)}
						{errors.date && <p>{errors.date}</p>}
						{customerError && <p>{customerError}</p>}
					</div>
				)}

				<div className={`inputfromto__body inputfromto__body--noseparator ${type}`}>
					<style id="styleBlock">
						{`
							${
								type !== 'endDate'
									? `
									.startDate .DayPicker-Day--disabled:hover:after { display: none; }
									.startDate .DayPicker-Day--disabled:hover:before { content: null; }
								`
									: `
									.${type} .DayPicker-Day--disabled:hover:before {
										content: 'The end date you selected is earlier than the next run date. Please select a date that is later than the next scheduled run date or disable the schedule.';
									}
								`
							}
						`}
					</style>
					<DayPicker
						showOutsideDays
						selectedDays={date}
						onDayClick={this.handleDayClick}
						disabledDays={this.props.disabledDays}
						ref={this.dateRef}
						className="single"
						captionElement={({ date, localeUtils }) => (
							<YearMonth
								singleDate={true}
								date={date}
								localeUtils={localeUtils}
								dayPickerRef={this.dateRef && this.dateRef.current}
								availableYears={this.state.availableYears}
							/>
						)}
					/>
				</div>
			</div>
		);
	}
}

SingleDatePickerComponent.propTypes = {
	date: PropTypes.any,
	onChange: PropTypes.func,
	disabledDays: PropTypes.any,
	isFilter: PropTypes.bool,
	syncInputWithDate: PropTypes.func,
	customerError: PropTypes.string,
	inputRef: PropTypes.any,
	type: PropTypes.string,
	goButtonHandler: PropTypes.func,
};

export default SingleDatePickerComponent;
