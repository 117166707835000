import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { times, padStart, get } from 'lodash';

import { isValid } from '../../Common/fields';

class CustomFields extends Component {
	onChange = e => {
		const { name, value } = e.target;

		this.props.onChange({
			key: name,
			value: value,
		});
	};

	render() {
		const {
			numberOfCustomFields,
			data,
			invalidClassName,
			customerId,
			requiredFields,
			customDisplayLabels,
			transactionHiddenFields,
			saveAsCustomer,
			convenienceFees,
			originalAmount,
			convenienceFee,
			includeConvenience,
			includeSalesTax,
			salesTax,
			bulkCharge,
		} = this.props;
		const hasSalesTax = includeSalesTax && get(salesTax, 'enableSalesTax');
		const required = (
			<span className="required-field label--required" data-tooltip="Required">
				*
			</span>
		);
		let originalIndex = -1;
		let convenienceIndex = -1;

		if (convenienceFees.originalCustomKey && (includeConvenience || hasSalesTax)) {
			originalIndex = parseInt(convenienceFees.originalCustomKey.slice(-2));
		}
		if (convenienceFees.convenienceCustomKey && includeConvenience) {
			convenienceIndex = parseInt(convenienceFees.convenienceCustomKey.slice(-2));
		}

		return (
			<div className="clearfix">
				{times(numberOfCustomFields, index => {
					index = index + 1;
					const leadingZeroIndex = padStart(index, 2, 0);
					let value = data[`custom${index}`] && data[`custom${index}`].value;
					const isConvenience = index === originalIndex || index === convenienceIndex;
					if (isConvenience) {
						value = index === originalIndex ? originalAmount : convenienceFee;
					}

					if (hasSalesTax) {
						value = index === originalIndex ? originalAmount : value;
					}

					if (bulkCharge && index === 1) {
						return;
					}

					return transactionHiddenFields['custom' + index] ? null : (
						<div key={index} className="newtransaction__item newtransaction__item--expandable">
							<div className="newtransaction__item__label newtransaction__item__label--expandable">
								{customDisplayLabels['custom' + index] || `Custom${leadingZeroIndex}`}{' '}
								{requiredFields['custom' + index] && !isConvenience ? required : null}
							</div>
							<div className="newtransaction__item__value newtransaction__item__value--expandable">
								<input
									type="text"
									className={`input input--med ${(!isValid(data['custom' + index]) && invalidClassName) || ''}`}
									name={'custom' + index}
									placeholder={customDisplayLabels['custom' + index] || `Custom${leadingZeroIndex}`}
									onChange={this.onChange}
									value={value}
									disabled={(index === 1 && (customerId || saveAsCustomer || bulkCharge)) || isConvenience}
								/>
							</div>
						</div>
					);
				})}
			</div>
		);
	}
}

CustomFields.propTypes = {
	data: PropTypes.object.isRequired,
	numberOfCustomFields: (props, propName) => {
		if (props[propName] < 1 || props[propName] > 20) {
			return new Error('There can be between 1 and 20 custom fields');
		}
	},
	onChange: PropTypes.func.isRequired,
	invalidClassName: PropTypes.string,
	requiredFields: PropTypes.object,
	customDisplayLabels: PropTypes.object,
	transactionHiddenFields: PropTypes.object,
	customerId: PropTypes.string,
	saveAsCustomer: PropTypes.bool,
	convenienceFees: PropTypes.object,
	originalAmount: PropTypes.number,
	convenienceFee: PropTypes.number,
	salesTax: PropTypes.object,
	includeSalesTax: PropTypes.bool,
	includeConvenience: PropTypes.bool,
	bulkCharge: PropTypes.bool,
};

export default CustomFields;
