import React, { Component, Fragment } from 'react';
import { object, func, bool, string, array } from 'prop-types';
import {
	identity,
	some,
	map,
	toLower,
	filter,
	startsWith,
	first,
	noop,
	head,
	constant,
	uniqueId,
	includes,
} from 'lodash';
import Select from 'react-select';

import { withContainer, itemTypes } from 'common/components/drag-and-drop';
import { invokeIfFunction, paymentSiteFields } from 'common/utilities';

const dragStyleBase = {
	height: '76px',
	marginBottom: '12px',
};

const dragStyle = {
	...dragStyleBase,
	border: '1px dashed #c7ced5',
	backgroundColor: '#f8fafd',
	borderRadius: '6px',
	overflow: 'hidden',
	maxHeight: '1000px',
	width: 'calc(100% - 36px)',
	marginLeft: '18px',
};

const dragStyleEmpty = {
	maxHeight: '0',
};

const wrapperPreviewDragStyle = { backgroundColor: '#f3f6f9', border: '1px solid #d4d4d4' };
const zipKeys = ['xBillState'];
const inputTypeOptions = [
	{ name: 'text', icon: 'singleline', label: 'Single-line text box', selector: constant(true) },
	{
		name: 'textarea',
		icon: 'multiline',
		label: 'Multi-line text box',
		disabled: key => toLower(key) === 'xamount',
	},
	{ name: 'hidden', icon: 'eye-crossed-fill', label: 'Hidden field' },
	{
		name: 'dropdown',
		icon: 'dropdown--grey',
		label: 'Dropdown',
		disabled: key => toLower(key) !== 'xamount' && !startsWith(toLower(key), 'xcustom') && !includes(zipKeys, key),
	},
	{
		name: 'radio',
		icon: 'radio-button-checked--grey',
		label: 'Radio Button',
		disabled: key => toLower(key) !== 'xamount' && !startsWith(toLower(key), 'xcustom'),
	},
	{
		name: 'checkbox',
		icon: 'checkbox-checked--grey',
		label: 'Checkbox',
		disabled: key => toLower(key) !== 'xamount' && !startsWith(toLower(key), 'xcustom'),
	},
];

class DraggableSectionInput extends Component {
	constructor(props) {
		super(props);
	}

	isDisabledAmountDropdown = (fieldKey, disabled, name) => {
		return toLower(fieldKey) === 'xamount' && disabled && toLower(name) === 'dropdown';
	};

	getLabel = (fieldKey, disabled, originalLabel, name) => {
		let label = originalLabel;
		if (this.isDisabledAmountDropdown(fieldKey, disabled, toLower(name))) {
			label = 'Custom Amount dropdown is not supported when Service Fee is enabled';
		}
		return label;
	};

	mapToOption = item => {
		if (item) {
			const { key: value, value: label } = item;
			return { value, label };
		}
	};

	renderExpandButton = () => {
		const { expandOptions, item, hideActions, isOpenOptionsModal } = this.props;
		const { key } = item;
		return (
			<label
				className={`paymentsite__card__actions__expand${!hideActions ? '' : ' no-actions'}`}
				key={uniqueId('option')}
			>
				<button
					className={`btn btn--icon--xsml ${isOpenOptionsModal === key ? 'is-active' : ''}`}
					onClick={expandOptions(key, null, true)}
					data-tooltip={isOpenOptionsModal === key ? 'Collapse' : 'Expand'}
				>
					<i
						className={`align--v--middle icon icon--tiny icon--arrow--${
							isOpenOptionsModal === key ? 'up' : 'down'
						}--regular`}
					/>
				</button>
			</label>
		);
	};
	renderSideButtons = ({
		isFieldPreview,
		setEditField,
		fieldKey,
		disableAmountDropdown,
		inputType,
		onChange,
		key,
		hideActions,
		hideDropdownOption,
		invalidValue,
		isNewField,
		isOpenOptionsModal,
	}) => {
		if (isFieldPreview) {
			return (
				<Fragment>
					{fieldKey !== 'xBillState' && (inputType === 'dropdown' || inputType === 'radio' || inputType === 'checkbox')
						? this.renderExpandButton()
						: null}
					{!hideActions &&
						map(inputTypeOptions, ({ name, icon, label, disabled }) => {
							const isCheckbox = name === 'checkbox';

							if (
								invokeIfFunction(disabled, fieldKey) ||
								(isCheckbox && hideDropdownOption && startsWith(toLower(fieldKey), 'xcustom'))
							) {
								return null;
							}

							return (
								<label
									key={uniqueId('option')}
									data-tooltip={this.getLabel(fieldKey, disableAmountDropdown, label, name)}
								>
									{isCheckbox ? (
										<button
											className={`btn btn--icon--xsml ${name === inputType ? 'is-active' : ''}`}
											disabled={
												this.isDisabledAmountDropdown(fieldKey, disableAmountDropdown, toLower(name)) ||
												isOpenOptionsModal === fieldKey
											}
											onClick={() =>
												onChange(
													{ target: { name: `${key}.inputType`, value: name, type: 'text' } },
													true,
													this.hideTypes
												)
											}
										>
											<i className={`icon icon--nano icon--middle icon--checkbox--grey`} />
										</button>
									) : (
										<button
											className={`btn btn--icon--xsml ${name === inputType ? 'is-active' : ''}`}
											disabled={
												this.isDisabledAmountDropdown(fieldKey, disableAmountDropdown, toLower(name)) ||
												isOpenOptionsModal === fieldKey
											}
											type="button"
											onClick={() =>
												onChange(
													{ target: { name: `${key}.inputType`, value: name, type: 'text' } },
													true,
													this.hideTypes
												)
											}
										>
											<i className={`icon icon--tiny icon--middle icon--${icon}`} />
										</button>
									)}
								</label>
							);
						})}
				</Fragment>
			);
		}
		if (!isFieldPreview) {
			return (
				<Fragment>
					<button
						className="btn btn--icon--xsml btn--primary"
						type="button"
						onClick={setEditField}
						name="confirm"
						data-tooltip={'Confirm'}
						disabled={invalidValue}
					>
						<i className="icon icon--sml icon--middle icon--check--white" data-name="confirm"></i>
					</button>
					<button
						className="btn btn--icon--xsml"
						type="button"
						onClick={setEditField}
						data-tooltip={'Cancel'}
						name="cancel"
						disabled={invalidValue || isNewField}
					>
						<i className="icon icon--nano icon--middle icon--close-big"></i>
					</button>
				</Fragment>
			);
		}
		return null;
	};

	renderPreviewEdit = ({
		isFieldPreview,
		fieldLabel,
		key,
		isDisabled,
		fieldKey,
		onChange,
		setEditField,
		hideEdit,
		hideFieldLabel,
	}) => {
		return isFieldPreview ? (
			<div className="flex--primary flex--nowrap">
				<div>
					{!hideFieldLabel && (
						<p className="type--sml--plus type--color--text">{paymentSiteFields[toLower(fieldKey)]}</p>
					)}
					<div className="flex--primary">
						{!hideFieldLabel && <p className="type--base"> {fieldLabel}</p>}
						{!hideEdit && (
							<button
								className="btn btn--icon--xsml btn--clear spc--right--xsml"
								type="button"
								onClick={setEditField}
								data-tooltip={'Edit'}
								disabled={
									isDisabled ||
									toLower(fieldKey) === 'customers_schedules' ||
									toLower(fieldKey) === 'xrecurring' ||
									toLower(fieldKey) === 'same_as_billing'
								}
							>
								<i className="icon icon--nano icon--middle icon--edit-alt--grey"></i>
							</button>
						)}
					</div>
				</div>
			</div>
		) : (
			<input
				className="input input--sml input--text spc--right--sml fullwidth w--max--350"
				id={`${key}.label`}
				name={`${key}.label`}
				type="text"
				disabled={
					isDisabled ||
					toLower(fieldKey) === 'customers_schedules' ||
					toLower(fieldKey) === 'xrecurring' ||
					toLower(fieldKey) === 'same_as_billing'
				}
				value={fieldLabel}
				onChange={onChange}
			/>
		);
	};
	handleRenderPreviewEdit = () => {
		const { item, sectionKey, onChange, isDisabled, hideEdit, hideFieldLabel } = this.props;
		const { key: fieldKey, isFieldPreview, setEditField, label: fieldLabel } = item;
		const key = `${sectionKey}.${fieldKey}`;

		return this.renderPreviewEdit({
			isFieldPreview,
			fieldLabel,
			key,
			isDisabled,
			fieldKey,
			onChange,
			setEditField,
			hideEdit,
			hideFieldLabel,
		});
	};
	handleRenderSideButtons = () => {
		const {
			item,
			sectionKey,
			onChange,
			isDisabled,
			disableAmountDropdown,
			hideActions,
			hideDropdownOption,
			isOpenOptionsModal,
		} = this.props;

		const { key: fieldKey, isFieldPreview, inputType, setEditField, invalidValue, isNewField } = item;
		const key = `${sectionKey}.${fieldKey}`;
		return this.renderSideButtons({
			isDisabled,
			isFieldPreview,
			setEditField,
			fieldKey,
			disableAmountDropdown,
			inputType,
			onChange,
			key,
			hideActions,
			hideDropdownOption,
			invalidValue,
			isNewField,
			isOpenOptionsModal,
		});
	};

	renderPaymentSiteCardActions = disableFieldRemove => {
		const { item, isDisabled, hideEdit } = this.props;
		const { isFieldPreview, removeField, isConvFeeCustomField } = item;
		return (
			<div
				className={`paymentsite__card__actions${
					isConvFeeCustomField ? ' datatooltip--right datatooltip--w--160' : ''
				} ${!isFieldPreview ? ' is-edit' : ''}`}
			>
				{this.handleRenderSideButtons()}
				{isFieldPreview && !hideEdit && (
					<button
						disabled={isConvFeeCustomField || isDisabled || disableFieldRemove}
						className="btn btn--icon--xsml"
						type="button"
						onClick={removeField}
						data-tooltip={
							isConvFeeCustomField
								? 'This field is already being used for your Electronic Transfer Fee settings'
								: 'Delete'
						}
					>
						<i className="icon icon--tiny icon--middle icon--delete"></i>
					</button>
				)}
			</div>
		);
	};

	render() {
		const {
			item,
			sectionKey,
			onChange,
			connectDragSource,
			items,
			canDrop,
			draggedItem,
			isPreview,
			isDisabled,
			isAdmin,
			hideEdit,
			hideDropdownOption,
			renderOptions,
			isOpenOptionsModal,
			hideFieldLabel,
			hideOptionsPreview,
			isGoPlus,
		} = this.props;
		const {
			key: fieldKey,
			isFieldPreview,
			isRequired,
			label: fieldLabel,
			inputType,
			setEditField,
			isEmpty,
			setRef,
			customLabel,
			isConvFeeCustomField,
			disableRemove,
			values,
			value,
			internalSetting,
			hideRequired,
			hideDropdownOptionPreview,
		} = item;
		const hideField =
			(fieldKey === 'customers_schedules' || fieldKey === 'xRecurring') && isGoPlus
				? false
				: internalSetting && !isAdmin;
		const isDefaultDropdownValue =
			toLower(inputType) === 'dropdown' &&
			head(values) &&
			head(values).key === 'Select' &&
			toLower(fieldKey).includes('custom');
		let displayValues = values;
		if (isDefaultDropdownValue) {
			displayValues = filter(values, item => item.value !== 'Select');
		}
		if (fieldKey === 'xCVV' || hideField || isConvFeeCustomField) {
			return null;
		}
		if (hideDropdownOption && fieldKey === 'customers_schedules' && !isAdmin) return null;
		const disableFieldRemove = disableRemove ? disableRemove(item) : false;
		const key = `${sectionKey}.${fieldKey}`;
		if (isEmpty) {
			const isDraggingNew = !some(items, item => item.key === (draggedItem && draggedItem.id));
			return (
				<div
					className="cursor--default"
					style={{ ...(isDraggingNew && canDrop ? dragStyle : dragStyleEmpty), transition: 'all 0.01s' }}
				>
					&nbsp;
				</div>
			);
		}
		return connectDragSource(
			<div
				ref={!isPreview ? setRef : null}
				className={'paymentsite__card__body__item'}
				style={isPreview && wrapperPreviewDragStyle}
			>
				<div className="paymentsite__card__body__header">
					{isFieldPreview && hideOptionsPreview ? null : (
						<div className={`paymentsite__card__body__main ${!isFieldPreview ? '' : 'is-preview'}`}>
							<i className="icon icon--nano icon--dragdots cursor--move"></i>
							{!hideRequired && (
								<div data-tooltip="Required">
									<input
										className="paymentsite__check-star"
										id={`${key}.isRequired`}
										name={`${key}.isRequired`}
										type="checkbox"
										disabled={
											isDisabled ||
											(toLower(fieldKey) === 'xamount' && disableFieldRemove) ||
											toLower(fieldKey) === 'same_as_billing'
										}
										onChange={onChange}
										checked={isRequired}
									/>
									<label htmlFor={`${key}.isRequired`}></label>
								</div>
							)}

							{customLabel && <div data-tooltip={customLabel} className="display--f"><i className="icon icon--tiny icon--info"></i></div>}
							{inputType === 'dropdown' ? (
								<Fragment>
									<div className="spc--right--xsml">
										{this.renderPreviewEdit({
											isFieldPreview,
											fieldLabel,
											key,
											isDisabled,
											fieldKey,
											onChange,
											setEditField,
											hideEdit,
											hideFieldLabel,
										})}
									</div>

									{this.renderPaymentSiteCardActions(disableFieldRemove)}
									<div className="paymentsite__card__break"></div>

									{hideOptionsPreview || hideDropdownOptionPreview ? null : hideDropdownOption ? (
										<div className="paymentsite__card__options__wrap">
											<div className="paymentsite__card__options">
												{map(values, ({ value: label }) => (
													<label key={uniqueId('label')}>{label}</label>
												))}
											</div>
										</div>
									) : (
										<Select
											value={this.mapToOption(first(displayValues))}
											options={map(displayValues, item => this.mapToOption(item))}
											onChange={noop}
											isClearable={false}
											isMulti={false}
											className="react-select-container react-select-container--sml w--300p"
											classNamePrefix="react-select"
										/>
									)}
								</Fragment>
							) : inputType === 'radio' ? (
								<Fragment>
									{this.handleRenderPreviewEdit()}
									{this.renderPaymentSiteCardActions(disableFieldRemove)}
									{!hideOptionsPreview && (
										<Fragment>
											<div className="paymentsite__card__break"></div>

											<div className="paymentsite__card__options__wrap">
												<div className="paymentsite__card__options">
													{map(values, ({ value: label }) => (
														<label key={uniqueId('value')}>{label}</label>
													))}
												</div>
											</div>
										</Fragment>
									)}
								</Fragment>
							) : (
								<Fragment>
									{this.handleRenderPreviewEdit()}
									{this.renderPaymentSiteCardActions(disableFieldRemove)}
									<div className="paymentsite__card__break"></div>
								</Fragment>
							)}
							{inputType === 'hidden' && (
								<div className="flex--primary__item spc--right--sml w--max--200">
									<input
										className="input input--sml input--text"
										id={`${key}.value`}
										name={`${key}.value`}
										type="text"
										disabled={isDisabled}
										value={value}
										onChange={onChange}
										placeholder="Value"
									/>
								</div>
							)}
						</div>
					)}
				</div>
				{isOpenOptionsModal === fieldKey ? renderOptions() : null}
			</div>
		);
	}
}

const mapItemToId = ({ key }) => key;

DraggableSectionInput.defaultProps = {
	connectDragSource: identity,
};

DraggableSectionInput.propTypes = {
	item: object.isRequired,
	sectionKey: string,
	onChange: func,
	connectDragSource: func,
	items: array,
	canDrop: bool,
	draggedItemType: string,
	draggedItem: object,
	isPreview: bool,
	hideFieldLabel: bool,
	hideOptionsPreview: bool,
	isDisabled: bool,
	openOptionsModal: func,
	isCanadian: bool,
	isAdmin: bool.isRequired,
	isGoPlus: bool.isRequired,
	disableAmountDropdown: bool.isRequired,
	hideEdit: bool,
	hideActions: bool,
	hideDropdownOption: bool,
	renderOptions: func,
	isOpenOptionsModal: bool,
	expandOptions: func,
};

export default withContainer(DraggableSectionInput, mapItemToId, {
	dragStyle,
	dragType: itemTypes.INPUT_ITEM,
	dropType: [itemTypes.INPUT_ITEM, itemTypes.CREATABLE_INPUT_ITEM],
	emptyItem: (_, { sectionKey }) => ({
		isEmpty: true,
		key: `${sectionKey}.empty`,
	}),
	useDefaultDrag: false,
});
