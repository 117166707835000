import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { cloneDeep, concat, find, isNumber, uniqBy } from 'lodash';

class PortalFieldsDropdown extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		const {
			sections,
			availableFields,
			activeSectionIndex,
			renderAvailableFields,
			sectionActionsRef,
			isGoPlus,
		} = this.props;
		if (!sectionActionsRef || !isNumber(activeSectionIndex)) return null;
		let currentFields = find(cloneDeep(availableFields), field => field.label === sections[activeSectionIndex].label);
		if (!currentFields) {
			currentFields = cloneDeep(availableFields);
		} else {
			const customField = find(availableFields, { key: 'custom' });
			const customFields = customField ? customField.fields : [];
			const uniqueCustomFields = uniqBy(customFields, 'key');
			currentFields.fields = uniqBy(concat(currentFields.fields, uniqueCustomFields), 'key');

			if (isGoPlus && sections[activeSectionIndex].label === 'Transaction Details') {
				let internalSection = find(availableFields, field => field.internalSection);
				let customersSchedulesField = find(internalSection.fields, field => field.key === 'customers_schedules');
				if (customersSchedulesField) {
					currentFields.fields.push(customersSchedulesField);
				}
			}
		}
		return ReactDOM.createPortal(renderAvailableFields(currentFields), sectionActionsRef);
	}
}
PortalFieldsDropdown.propTypes = {
	sections: PropTypes.array.isRequired,
	availableFields: PropTypes.array.isRequired,
	activeSectionIndex: PropTypes.number,
	renderAvailableFields: PropTypes.func,
	sectionActionsRef: PropTypes.any,
	isGoPlus: PropTypes.bool,
};

export default PortalFieldsDropdown;
