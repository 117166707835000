import { has } from 'lodash';

import principalService from '../services/principalService';

class CurrencyMap {
	static map = {
		USD: '$',
		AED: 'د.إ',
		AFN: '؋',
		ALL: 'L',
		AMD: '֏',
		ANG: 'ƒ',
		AOA: 'Kz',
		ARS: '$',
		AUD: '$',
		AWG: 'ƒ',
		AZN: '₼',
		BAM: 'KM',
		BBD: '$',
		BDT: '৳',
		BGN: 'лв',
		BHD: '.د.ب',
		BIF: 'FBu',
		BMD: '$',
		BND: '$',
		BOB: '$b',
		BRL: 'R$',
		BSD: '$',
		BTC: '฿',
		BTN: 'Nu.',
		BWP: 'P',
		BYR: 'Br',
		BYN: 'Br',
		BZD: 'BZ$',
		CAD: '$',
		CDF: 'FC',
		CHF: 'CHF',
		CLP: '$',
		CNY: '¥',
		COP: '$',
		CRC: '₡',
		CUC: '$',
		CUP: '₱',
		CVE: '$',
		CZK: 'Kč',
		DJF: 'Fdj',
		DKK: 'kr',
		DOP: 'RD$',
		DZD: 'دج',
		EEK: 'kr',
		EGP: '£',
		ERN: 'Nfk',
		ETB: 'Br',
		ETH: 'Ξ',
		EUR: '€',
		FJD: '$',
		FKP: '£',
		GBP: '£',
		GEL: '₾',
		GGP: '£',
		GHC: '₵',
		GHS: 'GH₵',
		GIP: '£',
		GMD: 'D',
		GNF: 'FG',
		GTQ: 'Q',
		GYD: '$',
		HKD: '$',
		HNL: 'L',
		HRK: 'kn',
		HTG: 'G',
		HUF: 'Ft',
		IDR: 'Rp',
		ILS: '₪',
		IMP: '£',
		INR: '₹',
		IQD: 'ع.د',
		IRR: '﷼',
		ISK: 'kr',
		JEP: '£',
		JMD: 'J$',
		JOD: 'JD',
		JPY: '¥',
		KES: 'KSh',
		KGS: 'лв',
		KHR: '៛',
		KMF: 'CF',
		KPW: '₩',
		KRW: '₩',
		KWD: 'KD',
		KYD: '$',
		KZT: 'лв',
		LAK: '₭',
		LBP: '£',
		LKR: '₨',
		LRD: '$',
		LSL: 'M',
		LTC: 'Ł',
		LTL: 'Lt',
		LVL: 'Ls',
		LYD: 'LD',
		MAD: 'MAD',
		MDL: 'lei',
		MGA: 'Ar',
		MKD: 'ден',
		MMK: 'K',
		MNT: '₮',
		MOP: 'MOP$',
		MRO: 'UM',
		MRU: 'UM',
		MUR: '₨',
		MVR: 'Rf',
		MWK: 'MK',
		MXN: '$',
		MYR: 'RM',
		MZN: 'MT',
		NAD: '$',
		NGN: '₦',
		NIO: 'C$',
		NOK: 'kr',
		NPR: '₨',
		NZD: '$',
		OMR: '﷼',
		PAB: 'B/.',
		PEN: 'S/.',
		PGK: 'K',
		PHP: '₱',
		PKR: '₨',
		PLN: 'zł',
		PYG: 'Gs',
		QAR: '﷼',
		RMB: '￥',
		RON: 'lei',
		RSD: 'Дин.',
		RUB: '₽',
		RWF: 'R₣',
		SAR: '﷼',
		SBD: '$',
		SCR: '₨',
		SDG: 'ج.س.',
		SEK: 'kr',
		SGD: '$',
		SHP: '£',
		SLL: 'Le',
		SOS: 'S',
		SRD: '$',
		SSP: '£',
		STD: 'Db',
		STN: 'Db',
		SVC: '$',
		SYP: '£',
		SZL: 'E',
		THB: '฿',
		TJS: 'SM',
		TMT: 'T',
		TND: 'د.ت',
		TOP: 'T$',
		TRL: '₤',
		TRY: '₺',
		TTD: 'TT$',
		TVD: '$',
		TWD: 'NT$',
		TZS: 'TSh',
		UAH: '₴',
		UGX: 'USh',
		UYU: '$U',
		UZS: 'лв',
		VEF: 'Bs',
		VND: '₫',
		VUV: 'VT',
		WST: 'WS$',
		XAF: 'FCFA',
		XBT: 'Ƀ',
		XCD: '$',
		XOF: 'CFA',
		XPF: '₣',
		YER: '﷼',
		ZAR: 'R',
		BOV: 'Bs',
		CLF: '$',
		COU: '$',
		MXV: 'Mex$',
		USN: '$',
		UYI: '$U',
		UYW: '$U',
		VES: 'Bs.F.',
		ZMW: 'K',
		ZWL: '$',
		ZWD: 'Z$',
	};

	static isoCodesMap = {
		840: 'USD',
		784: 'AED',
		971: 'AFN',
		8: 'ALL',
		51: 'AMD',
		532: 'ANG',
		973: 'AOA',
		32: 'ARS',
		36: 'AUD',
		533: 'AWG',
		944: 'AZN',
		977: 'BAM',
		52: 'BBD',
		50: 'BDT',
		975: 'BGN',
		48: 'BHD',
		108: 'BIF',
		60: 'BMD',
		96: 'BND',
		68: 'BOB',
		986: 'BRL',
		44: 'BSD',
		64: 'BTN',
		72: 'BWP',
		933: 'BYR',
		84: 'BZD',
		124: 'CAD',
		976: 'CDF',
		756: 'CHF',
		152: 'CLP',
		156: 'CNY',
		170: 'COP',
		188: 'CRC',
		931: 'CUC',
		192: 'CUP',
		132: 'CVE',
		203: 'CZK',
		262: 'DJF',
		208: 'DKK',
		214: 'DOP',
		12: 'DZD',
		818: 'EGP',
		232: 'ERN',
		230: 'ETB',
		978: 'EUR',
		242: 'FJD',
		238: 'FKP',
		826: 'GBP',
		981: 'GEL',
		936: 'GHS',
		292: 'GIP',
		270: 'GMD',
		324: 'GNF',
		320: 'GTQ',
		328: 'GYD',
		344: 'HKD',
		340: 'HNL',
		191: 'HRK',
		332: 'HTG',
		348: 'HUF',
		360: 'IDR',
		376: 'ILS',
		356: 'INR',
		368: 'IQD',
		364: 'IRR',
		352: 'ISK',
		388: 'JMD',
		400: 'JOD',
		392: 'JPY',
		404: 'KES',
		417: 'KGS',
		116: 'KHR',
		174: 'KMF',
		408: 'KPW',
		410: 'KRW',
		414: 'KWD',
		136: 'KYD',
		398: 'KZT',
		418: 'LAK',
		422: 'LBP',
		144: 'LKR',
		430: 'LRD',
		426: 'LSL',
		434: 'LYD',
		504: 'MAD',
		498: 'MDL',
		969: 'MGA',
		807: 'MKD',
		104: 'MMK',
		496: 'MNT',
		446: 'MOP',
		929: 'MRO',
		480: 'MUR',
		462: 'MVR',
		454: 'MWK',
		484: 'MXN',
		458: 'MYR',
		943: 'MZN',
		516: 'NAD',
		566: 'NGN',
		558: 'NIO',
		578: 'NOK',
		524: 'NPR',
		554: 'NZD',
		512: 'OMR',
		590: 'PAB',
		604: 'PEN',
		598: 'PGK',
		608: 'PHP',
		586: 'PKR',
		985: 'PLN',
		600: 'PYG',
		634: 'QAR',
		946: 'RON',
		941: 'RSD',
		643: 'RUB',
		646: 'RWF',
		682: 'SAR',
		90: 'SBD',
		690: 'SCR',
		938: 'SDG',
		752: 'SEK',
		702: 'SGD',
		654: 'SHP',
		694: 'SLL',
		706: 'SOS',
		968: 'SRD',
		728: 'SSP',
		930: 'STN',
		222: 'SVC',
		760: 'SYP',
		748: 'SZL',
		764: 'THB',
		972: 'TJS',
		934: 'TMT',
		788: 'TND',
		776: 'TOP',
		949: 'TRY',
		780: 'TTD',
		901: 'TWD',
		834: 'TZS',
		980: 'UAH',
		800: 'UGX',
		858: 'UYU',
		860: 'UZS',
		704: 'VND',
		548: 'VUV',
		882: 'WST',
		950: 'XAF',
		951: 'XCD',
		952: 'XOF',
		953: 'XPF',
		886: 'YER',
		710: 'ZAR',
		984: 'BOV',
		990: 'CLF',
		970: 'COU',
		979: 'MXV',
		997: 'USN',
		940: 'UYI',
		927: 'UYW',
		928: 'VES',
		999: 'No currency',
		967: 'ZMW',
		932: 'ZWL',
	};

	static resolveCurrency(key) {
		if (!key) {
			const principal = principalService.get();
			if (principal && principal.idInfo && principal.idInfo.xMerchantCurrency) {
				key = principal.idInfo.xMerchantCurrency;
			}
		}
		if (has(this.map, key)) {
			return this.map[key];
		}
		return '$';
	}
}

export default CurrencyMap;
