import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { CurrencyMap } from '../../Common/utilities';
import { round } from 'lodash';

class DashboardTransactionStatus extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hasRecords: props.hasRecords,
			sale: props.sale,
			credit: props.credit,
			total: props.total,
			avg: props.avg,
		};
	}

	currencyCode = CurrencyMap.resolveCurrency();

	getValue = (value, isTotal) => {
		const { hasRecords } = this.state;
		if (hasRecords && !isNaN(value)) {
			if (isTotal) {
				value = round(value, 2);
			} else {
				value = Math.abs(value);
			}
		} else {
			value = 0.0;
		}
		return (
			<NumberFormat
				prefix={this.currencyCode}
				value={value}
				displayType="text"
				thousandSeparator={true}
				decimalScale={2}
				fixedDecimalScale={true}
			/>
		);
	};

	render() {
		const { sale, credit, total, avg } = this.state;
		return (
			<React.Fragment>
				<div className="row">
					<div className="col col-sml-12">
						<h3 className="title title--secondary">Transaction Activity</h3>
					</div>
				</div>
				<div className="row">
					<div className="col col-sml-12 col-med-6">
						<div className="dashboard__status">
							<div className="dashboard__status__label dashboard__status__label--status dashboard__status__label--primary">
								Sales
							</div>
							<div className="dashboard__status__value">{this.getValue(sale)}</div>
						</div>
					</div>
					<div className="col col-sml-12 col-med-6">
						<div className="dashboard__status">
							<div className="dashboard__status__label dashboard__status__label--status dashboard__status__label--secondary">
								Credit
							</div>
							<div className="dashboard__status__value">{this.getValue(credit)}</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col col-sml-12 col-med-6">
						<div className="dashboard__status">
							<div className="dashboard__status__label dashboard__status__label--status dashboard__status__label--tertiary">
								Total
							</div>
							<div className="dashboard__status__value">{this.getValue(total, true)}</div>
						</div>
					</div>
					<div className="col col-sml-12 col-med-6">
						<div className="dashboard__status">
							<div className="dashboard__status__label dashboard__status__label--status dashboard__status__label--quaternary">
								Avg. Transaction
							</div>
							<div className="dashboard__status__value">{this.getValue(avg)}</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

DashboardTransactionStatus.propTypes = {
	hasRecords: PropTypes.bool.isRequired,
	sale: PropTypes.number,
	credit: PropTypes.number,
	total: PropTypes.number,
	avg: PropTypes.number,
};

export default DashboardTransactionStatus;
