import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { differenceWith, filter, isEqual, map, startsWith, union, trim, head } from 'lodash';
import { countryList } from 'components/new-transaction/constants';
import CountryList from './components/CountryList';

class TransactionScreening extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedCountry: '',
			AFF_BLOCK_COUNTRIES: [],
			denySearch: '',
			allowSearch: '',
			enableCountryWhitelist: null,
		};
	}
	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.affSettings.AFF_Allow_Country, this.props.affSettings.AFF_Allow_Country)) {
			// Update block countries state when allow countries prop changes
			const newState = {
				AFF_BLOCK_COUNTRIES: differenceWith(this.getAllCountries(), this.props.affSettings.AFF_Allow_Country, isEqual),
			};
			if (this.state.enableCountryWhitelist === null) {
				newState.enableCountryWhitelist = head(this.props.affSettings.AFF_Allow_Country);
			}
			this.setState(newState);
		}
	}
	handleDenySearch = event => {
		this.setState({ denySearch: event.target.value });
	};

	handleAllowSearch = event => {
		this.setState({ allowSearch: event.target.value });
	};

	getAllCountries = () => {
		return map(
			filter(countryList, country => !country.hideAff && country.name && !startsWith(country.name, '-')),
			country => country.name
		);
	};
	isChecked = (settings, key) => {
		return this.props[settings][key];
	};
	handleEnableCountryWhiteList = e => {
		if (!e.target.checked) {
			const updatedAffSettings = {
				...this.props.affSettings,
				AFF_Allow_Country: [''],
			};
			this.props.handleSettingsUpdate('affSettings', updatedAffSettings);
		}
		this.setState({ enableCountryWhitelist: e.target.checked, selectedCountry: '' });
	};

	handleCheckboxChange = (key, isChecked) => {
		const updatedAffSettings = {
			...this.props.affSettings,
		};
		updatedAffSettings[key] = isChecked;
		this.props.handleSettingsUpdate('affSettings', updatedAffSettings);
	};

	handleProcessingOptionChange = (key, isChecked) => {
		const { AFF_Allow_Country } = this.props.affSettings;
		const { AFF_BLOCK_COUNTRIES, denySearch, allowSearch } = this.state;

		// Filter the countries based on the search terms
		const filteredBlockCountries = AFF_BLOCK_COUNTRIES.filter(country =>
			country.toLowerCase().includes(denySearch.toLowerCase())
		);
		const filteredAllowCountries = AFF_Allow_Country.filter(country =>
			country.toLowerCase().includes(allowSearch.toLowerCase())
		);

		if (key === 'blockProcessing' && isChecked) {
			// If "Block Processing" is selected, move the filtered blocked countries to the "Allow" list
			const remainingBlockCountries = differenceWith(AFF_BLOCK_COUNTRIES, filteredBlockCountries, isEqual);
			const updatedAllowCountries = union(AFF_Allow_Country, filteredBlockCountries);
			this.props.handleSettingsUpdate('affSettings', {
				...this.props.affSettings,
				AFF_Allow_Country: updatedAllowCountries,
			});
			this.setState({ AFF_BLOCK_COUNTRIES: remainingBlockCountries, selectedCountry: '' });
		} else if (key === 'allowProcessing' && isChecked) {
			// If "Allow Processing" is selected, move the filtered allowed countries to the "Block" list
			const remainingAllowCountries = differenceWith(AFF_Allow_Country, filteredAllowCountries, isEqual);
			const updatedBlockCountries = union(AFF_BLOCK_COUNTRIES, filteredAllowCountries);
			this.props.handleSettingsUpdate('affSettings', {
				...this.props.affSettings,
				AFF_Allow_Country: remainingAllowCountries,
			});
			this.setState({ AFF_BLOCK_COUNTRIES: updatedBlockCountries, selectedCountry: '' });
		}
	};
	handleDenyToAllow = () => {
		const { selectedCountry, AFF_BLOCK_COUNTRIES } = this.state;
		if (!selectedCountry) return;
		const { AFF_Allow_Country } = this.props.affSettings;
		const updatedAffSettings = {
			...this.props.affSettings,
			AFF_Allow_Country: [...AFF_Allow_Country, selectedCountry],
		};
		this.setState({
			selectedCountry: '',
			AFF_BLOCK_COUNTRIES: AFF_BLOCK_COUNTRIES.filter(country => country !== selectedCountry),
		});
		this.props.handleSettingsUpdate('affSettings', updatedAffSettings);
	};

	handleAllowToDeny = () => {
		const { selectedCountry } = this.state;
		if (!selectedCountry) return;
		const { AFF_Allow_Country } = this.props.affSettings;
		const updatedAffSettings = {
			...this.props.affSettings,
			AFF_Allow_Country: AFF_Allow_Country.filter(country => country !== selectedCountry),
		};
		this.setState({
			selectedCountry: '',
			AFF_BLOCK_COUNTRIES: [...this.state.AFF_BLOCK_COUNTRIES, selectedCountry],
		});
		this.props.handleSettingsUpdate('affSettings', updatedAffSettings);
	};

	handleCountrySelect = (selectedCountry, selectedList) => {
		if (this.props.isLoading || !this.state.enableCountryWhitelist) return;
		this.props.handleBlockChange(true);
		this.setState({ selectedCountry, selectedList });
	};

	render() {
		const { affSettings, isLoading, isExpanded, toggleExpand } = this.props;
		const { selectedCountry, AFF_BLOCK_COUNTRIES, denySearch, allowSearch, enableCountryWhitelist } = this.state;

		return (
			<div className="spr__card clearfix">
				<button className={trim(`spr__heading ${isExpanded ? 'is-expanded' : ''}`)} onClick={toggleExpand}>
					<h4 className="spr__heading__title">Transaction Screening</h4>
					<i
						className={`icon icon--tiny icon--arrow--right--grey spr__heading__expand ${
							isExpanded ? 'is-expanded' : ''
						}`}
					></i>
				</button>
				{isExpanded && (
					<Fragment>
						<p className="label type--base type--wgt--semibold type--color--black spc--bottom--med">
							Allowed Countries
						</p>
						<div className="spc--bottom--med--alt">
							<input
								type="checkbox"
								id="enableCountryWhitelist"
								checked={enableCountryWhitelist}
								onChange={this.handleEnableCountryWhiteList}
								className="input--check"
							></input>
							<label htmlFor="enableCountryWhitelist">Enable Country Whitelist</label>
						</div>
						<div className="transfer-list__wrapper spc--bottom--med">
							<div className="transfer-list__main">
								<div className="flex--tertiary spc--bottom--xsml">
									<p className="type--color--text--regular type--xsml">List of Countries</p>
									<button
										className="btn btn--anchor type--xsml type--wgt--medium"
										onClick={() => this.handleProcessingOptionChange('blockProcessing', true)}
										disabled={isLoading || !enableCountryWhitelist || !AFF_BLOCK_COUNTRIES.length}
									>
										Allow all
									</button>
								</div>
								<div className="transfer-list__box">
									<div className="transfer-list__search">
										<input
											className="input input--sml input--search"
											type="text"
											value={denySearch}
											onChange={this.handleDenySearch}
											placeholder="Search country"
											disabled={!enableCountryWhitelist}
										/>
									</div>
									<CountryList
										countries={AFF_BLOCK_COUNTRIES}
										selectedCountry={selectedCountry}
										search={denySearch}
										handleCountrySelect={country => this.handleCountrySelect(country, 'deny')}
										disabled={!enableCountryWhitelist}
									/>
								</div>
							</div>
							<div className="transfer-list__actions">
								<button
									className="btn btn--med btn--primary fullwidth"
									onClick={this.handleDenyToAllow}
									disabled={isLoading || !selectedCountry || this.state.selectedList !== 'deny'}
								>
									Add to Allow <i className="icon icon--nano icon--arrow--down--white hide--from--med"></i>
									<i className="icon icon--nano icon--arrow--right--positive hide--to--med"></i>
								</button>
								<button
									className="btn btn--med btn--ghost btn--ghost--no-border fullwidth"
									onClick={this.handleAllowToDeny}
									disabled={isLoading || !selectedCountry || this.state.selectedList !== 'allow'}
								>
									<i className="icon icon--nano icon--arrow--up--regular hide--from--med"></i>
									<i className="icon icon--nano icon--arrow--left--regular hide--to--med"></i> Remove
								</button>
							</div>
							<div className="transfer-list__main">
								<div className="flex--tertiary spc--bottom--xsml">
									<p className="type--color--text--regular type--xsml">Allow</p>
									<button
										className="btn btn--anchor type--xsml type--wgt--medium"
										onClick={() => this.handleProcessingOptionChange('allowProcessing', true)}
										disabled={isLoading || !enableCountryWhitelist || !affSettings.AFF_Allow_Country.length}
									>
										Remove all
									</button>
								</div>
								<div className="transfer-list__box">
									<div className="transfer-list__search">
										<input
											className="input input--sml input--search"
											type="text"
											value={allowSearch}
											onChange={this.handleAllowSearch}
											placeholder="Search country"
											disabled={!enableCountryWhitelist}
										/>
									</div>
									<CountryList
										countries={affSettings.AFF_Allow_Country}
										selectedCountry={selectedCountry}
										search={allowSearch}
										handleCountrySelect={country => this.handleCountrySelect(country, 'allow')}
									/>
								</div>
							</div>
						</div>
						<div className="spc--bottom--med--alt">
							<input
								type="checkbox"
								id="AFF_Allow_Country_BlockOnFailure"
								name="AFF_Allow_Country_BlockOnFailure"
								checked={!this.isChecked('affSettings', 'AFF_Allow_Country_BlockOnFailure')}
								onChange={e => this.handleCheckboxChange('AFF_Allow_Country_BlockOnFailure', !e.target.checked)}
								className="input--check"
								disabled={isLoading}
							/>
							<label htmlFor="AFF_Allow_Country_BlockOnFailure" className="type--wgt--medium type--color--black">
								Allow transactions when the IP lookup fails or the IP address is unknown.
							</label>
						</div>
					</Fragment>
				)}
			</div>
		);
	}
}

TransactionScreening.propTypes = {
	isExpanded: PropTypes.bool.isRequired,
	toggleExpand: PropTypes.func.isRequired,
	affSettings: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	handleSettingsUpdate: PropTypes.func.isRequired,
	handleBlockChange: PropTypes.func.isRequired,
};

export default TransactionScreening;
