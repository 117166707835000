import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { HttpServiceError } from './services';
import { logger } from './utilities';

// add errors which can be safely ignored
const errorsToIgnore = [
	// Fixes IE issue with closing reactour
	"Unable to get property 'contains' of undefined or null reference",
	'SyntaxError: dynamic module import is not implemented',
	'The quota has been exceeded',
	'DOMException: The quota has been exceeded',
	'Error: ❌ localStorage cannot be used.',
	'ResizeObserver loop limit exceeded',
	'ResizeObserver loop completed with undelivered notifications.',
	'Script error.',
	`Uncaught TypeError: Cannot read properties of null (reading 'getBoundingClientRect')`, // Ignores the Tour.js error
	"Failed to execute 'removeChild' on 'Node'",
	'NotSupportedError: ReadableStream uploading is not supported', // Ignores the FullStory.js error
];

class GlobalErrorHandler extends React.Component {
	static instance = null;
	constructor(props) {
		super(props);

		GlobalErrorHandler.instance = this;
		this.state = { errors: null };

		this.addError = this.addError.bind(this);
		this.clearErrors = this.clearErrors.bind(this);
		this.handleUncaughtError = this.handleUncaughtError.bind(this);
		this.handleUnhandledRejection = this.handleUnhandledRejection.bind(this);
	}

	componentDidMount() {
		const { history } = this.props;
		this.unlistenHistory = history.listen(this.clearErrors);

		window.addEventListener('beforeunload', () => {
			this.disableErrors = true;
		});
		window.addEventListener('error', this.handleUncaughtError);
		window.addEventListener('unhandledrejection', this.handleUnhandledRejection);
	}

	handleUncaughtError(error) {
		setTimeout(this.addError(error), 50);
	}

	handleUnhandledRejection(error) {
		if (error && error.reason && (error.reason.isApiError || error.reason instanceof HttpServiceError)) {
			return;
		}
		setTimeout(this.addError(error), 50);
	}

	getChildContext() {
		return { errors: this.state.errors };
	}

	addError(error) {
		if (this.disableErrors || _.includes(errorsToIgnore, _.get(error, 'message'))) {
			return;
		}
		this.setState(state => ({ errors: (state.errors || []).concat(error) }));
	}

	clearErrors() {
		logger.logPageView();
		this.setState({ errors: null });
	}

	componentWillUnmount() {
		this.unlistenHistory();

		window.removeEventListener('error', this.handleUncaughtError);
		window.removeEventListener('unhandledrejection', this.handleUnhandledRejection);
	}

	render() {
		return <React.Fragment>{this.props.children}</React.Fragment>;
	}
}

GlobalErrorHandler.childContextTypes = {
	errors: PropTypes.array,
};

GlobalErrorHandler.propTypes = {
	history: PropTypes.object,
	children: PropTypes.any,
};

export default GlobalErrorHandler;
