import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import {
	isEmpty,
	merge,
	isObject,
	isNumber,
	cloneDeep,
	clone,
	padStart,
	upperFirst,
	each,
	some,
	find,
	endsWith,
	every,
	keys,
	sortBy,
	concat,
	filter,
	times,
	includes,
	toLower,
	replace,
	startCase,
	startsWith,
	get,
	split,
	transform,
	join,
	round,
	debounce,
	mapKeys,
} from 'lodash';
import NumberFormat from 'react-number-format';
import Select, { components } from 'react-select';
import { Link } from 'react-router-dom';

import transactionService from '../../Common/services/transactionService';
import {
	FormErrors,
	CardTypeImagePath,
	kvaasResources,
	CurrencyMap,
	mapConvenienceToCustom,
	checkIfCanadian,
	hasFeaturePackage,
	screenSize,
} from '../../Common/utilities';
import {
	transactionType,
	paymentMethod,
	accountType,
	maxAllowedSalesTaxTooltip,
	threeDS2ProcessTooltip,
} from './constants';
import { default as CreditCardFields } from './credit-card-fields';
import { default as CreditCardDisplay } from './credit-card-display';
import { default as CheckFields } from './check-fields';
import { default as CheckDisplay } from './check-display';
import { default as CustomerInfoFields } from './customer-info-fields';
import { default as TransactionDetailsFields } from './transaction-details-fields';
import { default as CustomFields } from './custom-fields';
import { withLoader } from '../../Common/components/loader';
import { withCancelable } from '../../Common/components/cancelable';
import { withError } from '../../Common/components/error';
import { createField, isValid, validators } from '../../Common/fields';
import {
	mapToCardknoxFields,
	mapToTransactionFields,
	areAllPropsTrue,
	parseCustomerInfo,
	calculateConvenienceFee,
	checkHasConvenienceFee,
	toCurrency,
	calculateSalesTax,
} from './helpers';
import {
	customerService,
	kvaasService,
	authenticationService,
	principalService,
	messagingService,
} from '../../Common/services';
import { featurePackages, featurePackageTooltips } from 'common/utilities/has-feature-package';
import sectionKeys from '../../routing/sections';
import { Modal } from 'common/components/modal';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';
import moment from 'moment';
import { SingleDatePicker } from 'common/components/date-picker';
import { Tour } from 'common/components/tour';

const { displayDateFormat } = ApplicationSettings;
const todayDate = moment()
	.add(1, 'days')
	.format(displayDateFormat);

const handleInvalidAccountSettings = error => {
	if (error && toLower(error.message) === 'invalid account settings (g3)') {
		return {
			...error,
			isApiError: false,
			displayMessage: "This account can't process gift cards",
		};
	}
	return error;
};

const tourConfig = {
	version: 2, // increase this every time you make changes to the tourConfig,
	key: 'processLaterAch',
	steps: [
		{
			selector: '#processLaterAch',
			content:
				'If you have ACH transactions enabled for your account, you can schedule ACH transactions to be processed at a later time or date.',
		},
	],
};

const requestKeys = {
	KVAAS: 'kvaas',
	CC: 'ccFields',
	ACH: 'achFields',
	TRANS: 'transaction',
	MAP: 'map',
	FEESANDTAXES: 'taxes',
	SAVE: 'save',
};

const customerInfoFieldLabels = [
	'firstName',
	'lastName',
	'company',
	'address',
	'city',
	'state',
	'zip',
	'country',
	'phoneNumber',
];
const transactionDetailsFieldLabels = ['invoice', 'description', 'poNumber', 'orderId'];
const customFieldLabels = times(19, i => `custom${i + 1}`);

export class NewTransaction extends Component {
	initialState = (props, permissions = {}, achEnabled = false, has3DS = false, has3DS2 = false) => {
		const {
			allowCcSale,
			allowCcAuthOnly,
			allowCcCredit,
			allowCcPostAuth,
			allowCcSave,
			allowGiftIssue,
			allowGiftRedeem,
			allowEbtfsVoucher,
		} = permissions;

		let cCTransactionType = '';

		if (allowCcSale) {
			cCTransactionType = transactionType.SALE;
		} else if (allowCcAuthOnly) {
			cCTransactionType = transactionType.AUTH_ONLY;
		} else if (allowCcCredit) {
			cCTransactionType = transactionType.CREDIT;
		} else if (allowCcPostAuth) {
			cCTransactionType = transactionType.POST_AUTH;
		} else if (allowCcSave) {
			cCTransactionType = transactionType.SAVE;
		} else if (allowGiftIssue) {
			cCTransactionType = transactionType.GIFT_ISSUE;
		} else if (allowGiftRedeem) {
			cCTransactionType = transactionType.GIFT_REDEEM;
		} else if (allowEbtfsVoucher) {
			cCTransactionType = transactionType.EBTFS_VOUCHER;
		}
		const amount = createField(
			cCTransactionType === transactionType.SAVE ? 0 : '',
			validators.if(
				validators.currency,
				() =>
					(this.state.paymentMethod === paymentMethod.CC &&
						(this.state.creditCard && this.state.creditCard.transactionType) !== transactionType.SAVE) ||
					this.state.paymentMethod === paymentMethod.CHECK
			)
		);
		const expiry = createField(
			'',
			validators.if(
				validators.expDate,
				field =>
					validators.required(field) &&
					!this.state.creditCard.isSwipe.value &&
					this.validateCc &&
					(this.state.creditCard && !startsWith(this.state.creditCard.transactionType, 'gift'))
			),
			validators.if(
				validators.required,
				() =>
					!this.state.creditCard.isSwipe.value &&
					this.validateCc &&
					(this.state.creditCard &&
						!startsWith(this.state.creditCard.transactionType, 'gift') &&
						!startsWith(this.state.creditCard.transactionType, 'ebt'))
			)
		);
		const cvv = createField(
			'',
			validators.if(validators.required, () => {
				const paymentMethod = get(this.state, 'paymentMethod');
				const customerId = get(this.state, 'customerId');
				const selectedCustomerPaymentMethod = get(this.state, 'selectedCustomerPaymentMethod.newMethod');
				const differentPayment = get(this.state, 'differentPayment');
				const creditCardToken = get(this.state, 'creditCard.token');
				const existingTransaction = get(this.props, 'existingTransaction');
				const cvvAllTransactionsRequired = get(this.state, 'requiredFields.cvvAllTransactionsRequired');
				const cvvNewTransactionsOnlyRequired = get(this.state, 'requiredFields.cvvNewTransactionsOnlyRequired');
				const creditCard = get(this.state, 'creditCard');
				const transactionType = get(this.state, 'creditCard.transactionType');

				return (
					((paymentMethod === paymentMethod.CC && customerId && !selectedCustomerPaymentMethod) ||
						(!differentPayment && creditCardToken)) &&
					(existingTransaction || this.state.selectedCustomerPaymentMethod
						? cvvAllTransactionsRequired
						: cvvNewTransactionsOnlyRequired) &&
					(creditCard && !startsWith(transactionType, 'gift'))
				);
			})
		);
		const cardholderName = createField('');
		const isSwipe = createField(false);
		const ccValid = createField(
			false,
			validators.if(
				validators.assert(true),
				() =>
					!this.state.creditCard.isSwipe.value &&
					this.state.differentPayment &&
					this.validateCc &&
					(this.state.creditCard.cardNumberIsEmpty || !startsWith(this.state.creditCard.transactionType, 'gift'))
			)
		);
		const cvvValid = createField(
			this.state &&
				this.state.requiredFields &&
				(this.props.existingTransaction || this.validateCc
					? !this.state.requiredFields.cvvAllTransactionsRequired
					: !this.state.requiredFields.cvvAllTransactionsRequired &&
					  !this.state.requiredFields.cvvNewTransactionsOnlyRequired),
			validators.if(
				validators.assert(true),
				() =>
					!this.state.creditCard.isSwipe.value &&
					this.state.differentPayment &&
					this.validateCc &&
					(this.state.creditCard && !startsWith(this.state.creditCard.transactionType, 'gift'))
			)
		);
		const authCode = createField(
			'',
			validators.if(
				validators.required,
				() =>
					this.validateCc &&
					this.state.creditCard &&
					(this.state.creditCard.transactionType === transactionType.POST_AUTH ||
						this.state.creditCard.transactionType === transactionType.EBTFS_VOUCHER)
			)
		);
		const voucherSerial = createField(
			'',
			validators.if(
				validators.required,
				() =>
					this.validateCc &&
					this.state.creditCard &&
					this.state.creditCard.transactionType === transactionType.EBTFS_VOUCHER
			)
		);
		const creditCard = {
			expiry,
			cvv,
			cardholderName,
			isSwipe,
			ccValid,
			cvvValid,
			authCode,
			voucherSerial,
			transactionType: cCTransactionType,
			ccToken: null,
			cvvToken: null,
			token: null,
			cardNumberIsEmpty: true,
			cvvIsEmpty: true,
		};
		const accountName = createField('', validators.if(validators.required, () => this.validateCheck));
		const routingNumber = createField('', validators.if(validators.required, () => this.validateCheck));
		const achValid = createField('', validators.if(validators.assert(true), () => this.validateCheck));
		const check = {
			accountName,
			routingNumber,
			achValid,
			accountType: accountType.CHECKING,
			achToken: null,
			token: null,
			achIsEmpty: true,
		};
		const email = createField(
			props.customer && !!props.customer.xEmail && validators.email(props.customer.xEmail) ? props.customer.xEmail : '',
			validators.if(
				validators.email,
				field => validators.required(field) && (this.state.sendReceipt || !this.state.transactionHiddenFields.email)
			),
			validators.if(validators.required, () => this.state.requiredFields.email || this.state.sendReceipt)
		);

		const billingInfo = this.setInitialBillingInfo();
		let shippingInfo = this.setInitialShippingInfo();
		const originalShippingInfo = shippingInfo;
		const avsInfo = this.setInitialAvsInfo();
		const sameAsBilling = !some(shippingInfo, ({ value }) => value);
		if (sameAsBilling) {
			shippingInfo = billingInfo;
		}

		const invoice = createField('', validators.if(validators.required, () => this.state.requiredFields.invoice));
		const poNumber = createField('', validators.if(validators.required, () => this.state.requiredFields.poNumber));
		const description = createField(
			'',
			validators.if(validators.required, () => this.state.requiredFields.description)
		);
		const orderId = createField('', validators.if(validators.required, () => this.state.requiredFields.orderId));

		const transactionDetails = {
			invoice,
			poNumber,
			description,
			orderId,
		};

		const customFields = this.createCustomFields(props.customer);

		const defaultPaymentMethod = find(this.props.paymentMethods, x => x.xIsDefaultPaymentMethod);
		const type =
			toLower(defaultPaymentMethod && defaultPaymentMethod.xTokenType) ||
			(allowCcAuthOnly ||
			allowCcSale ||
			allowCcSave ||
			allowCcCredit ||
			allowCcPostAuth ||
			allowGiftIssue ||
			allowGiftRedeem ||
			allowEbtfsVoucher
				? paymentMethod.CC
				: paymentMethod.CHECK);
		const paymentMethodOptions = this.getPaymentMethodOptions(type);
		const selectedCustomerPaymentMethod = paymentMethodOptions[0];
		const customerId = props.customer && props.customer.xCustomerId;

		return {
			amount,
			oneTimePayment: true,
			paymentMethod: type,
			isLoading: true,
			creditCard,
			check,
			expandAll: false,
			expand: {
				avs: false,
				billing: false,
				shipping: !sameAsBilling,
				transaction: false,
				custom: false,
			},
			differentPayment: true,
			sameAsBilling,
			sendReceipt: false,
			sendSmsReceipt: false,
			sendCopy: false,
			setAsDefault: false,
			saveToFile: false,
			saveAsCustomer: false,
			customerId,
			email,
			billingInfo,
			shippingInfo,
			originalShippingInfo,
			avsInfo,
			transactionDetails,
			customFields,
			selectedCustomerPaymentMethod,
			paymentMethodOptions,
			errors: {},
			isProcessing: false,
			expandView: true,
			customDisplayLabels: {},
			requiredFields: {},
			transactionHiddenFields: {},
			convenienceFees: {},
			salesTax: {},
			createdPaymentMethods: [],
			permissions,
			unapprovedTransaction: false,
			total: '',
			convenienceFee: 0,
			salesTaxAmount: 0,
			isSalesTaxAmountOverwritten: false,
			includeConvenience: false,
			includeSalesTax: false,
			showPopupDetails: false,
			allowDuplicate: false,
			showAllowDuplicate: false,
			showEbtfsVoucherOption: false,
			disableVerification: false,
			showDisableVerification: false,
			splitCaptureEnabled: false,
			achEnabled,
			has3DS,
			has3DS2,
			isCanadian: false,
			isExpandableView: window.innerWidth >= screenSize.lrg,
			displayConvenienceAndSalesFailedToLoad: false,
			scheduleNameDirty: false,
			processLater: this.initialProcessLater,
			ignoreAvs: false,
			isBlurred: false,
			amountFocused: true,
		};
	};

	constructor(props) {
		super(props);
		this.debouncedSubmit = debounce(this.onSubmit, 500); // Adjust the debounce delay as needed
		this.pageTopRef = createRef();
		this.cvvRef = createRef();
		this.hidCvv = createRef();
		this.startDateRef = createRef();

		const principal = principalService.get();
		const isCanadianCurrency = principal && principal.idInfo && toLower(principal.idInfo.xMerchantCurrency) === 'cad';
		const permissions = (principal && principal.idInfo && principal.idInfo.permissions) || {};
		const achEnabled = principal && principal.idInfo && principal.idInfo.xACHEnabled;
		const has3DS = principal && principal.idInfo.x3DSEnabled && principal.idInfo.x3DSVersion !== '2';
		const has3DS2 = principal.idInfo.x3DSEnabled && principal.idInfo.x3DSVersion === '2';

		if (props != null && !isEmpty(props) && props.existingTransaction != null) {
			let diffObj = {
				oneTimePayment: true,
				isLoading: true,
				expand: {
					avs: false,
					billing: false,
					shipping: false,
					transaction: false,
					custom: false,
				},
				sendReceipt: false,
				sendCopy: false,
				differentPayment: false,
				selectedCustomerPaymentMethod: null,
				paymentMethodOptions: [],
				setAsDefault: false,
				saveToFile: false,
				saveAsCustomer: false,
				customerId: null,
				expandView: true,
				errors: {},
				isProcessing: false,
				customDisplayLabels: {},
				requiredFields: {},
				transactionHiddenFields: {},
				convenienceFees: {},
				salesTax: {},
				createdPaymentMethods: [],
				permissions,
				unapprovedTransaction: false,
				includeConvenience: false,
				includeSalesTax: false,
				showPopupDetails: false,
				allowDuplicate: false,
				showAllowDuplicate: false,
				showEbtfsVoucherOption: false,
				disableVerification: false,
				showDisableVerification: false,
				splitCaptureEnabled: false,
				achEnabled,
				has3DS,
				has3DS2,
				isCanadian: false,
				isExpandableView: window.innerWidth >= screenSize.lrg,
				displayConvenienceAndSalesFailedToLoad: false,
				scheduleNameDirty: false,
				processLater: this.initialProcessLater,
				ignoreAvs: false,
			};
			this.state = merge({}, diffObj, mapToTransactionFields(this, props.existingTransaction, permissions));
		} else {
			this.state = this.initialState(props, permissions, achEnabled, has3DS, has3DS2);
		}
		this.state.isCanadianCurrency = isCanadianCurrency;
		this.state.principal = principal;
	}

	get initialProcessLater() {
		return {
			isModalOpen: false,
			startDate: todayDate,
			scheduleName: `Scheduled Payment ${todayDate}`,
		};
	}

	get notificationRef() {
		return this.props.notificationRef;
	}

	get existingTransactionPaymentType() {
		const { existingTransaction } = this.props;
		if (get(existingTransaction, 'xCommand')) {
			return existingTransaction.xCommand.toLowerCase().indexOf('check') === -1
				? paymentMethod.CC
				: paymentMethod.CHECK;
		}
		return null;
	}

	get isNotExistingGrantTransaction() {
		const { existingTransaction } = this.props;
		if (get(existingTransaction, 'xCommand')) {
			return existingTransaction.xCommand.toLowerCase().indexOf('grant') === -1;
		}
		return true;
	}

	get missingToken() {
		const { existingTransaction } = this.props;

		return existingTransaction && !existingTransaction.xToken;
	}

	get validateCc() {
		const data = this.state;
		if (!data.selectedCustomerPaymentMethod || data.selectedCustomerPaymentMethod.newMethod) {
			if (data.paymentMethod === paymentMethod.CC) {
				return true;
			}
		}
	}

	get validateCheck() {
		const data = this.state;
		if (!data.selectedCustomerPaymentMethod || data.selectedCustomerPaymentMethod.newMethod) {
			if (data.paymentMethod === paymentMethod.CHECK) {
				return data.differentPayment;
			}
		}
	}

	get hiddenFields() {
		const { saveAsCustomer, customerId, transactionHiddenFields } = this.state;
		const hideShippingInfoFields = this.areAllFieldsHidden(customerInfoFieldLabels) || this.isAchDisabled;
		const hideAvsFields = transactionHiddenFields.avsStreet && transactionHiddenFields.avsZip;
		const hideBillingInfoFields = (!saveAsCustomer && hideShippingInfoFields) || this.isAchDisabled;
		const hideTransactionDetails =
			this.areAllFieldsHidden(
				customerId ? filter(transactionDetailsFieldLabels, item => item !== 'orderId') : transactionDetailsFieldLabels
			) || this.isAchDisabled;
		const hideCustomFields = this.areAllFieldsHidden(customFieldLabels) || this.isAchDisabled;
		return {
			hideShippingInfoFields,
			hideBillingInfoFields,
			hideTransactionDetails,
			hideCustomFields,
			hideAvsFields,
			hideAll: hideBillingInfoFields && hideTransactionDetails && hideCustomFields,
		};
	}

	get isExpanded() {
		const { expandView, isExpandableView } = this.state;
		const { hideAll } = this.hiddenFields;
		return isExpandableView && expandView && !hideAll;
	}

	get isAchEnabled() {
		const { achEnabled, isCanadian } = this.state;
		return this.state.paymentMethod === paymentMethod.CHECK && achEnabled && !isCanadian;
	}

	get isAchDisabled() {
		const { achEnabled, isCanadian } = this.state;
		return this.state.paymentMethod === paymentMethod.CHECK && !achEnabled && !isCanadian;
	}

	get showSendSms() {
		const { allowSmsReceipt } = this.state;
		return allowSmsReceipt;
	}

	get visibleExpansions() {
		const {
			expand: { billing, shipping, transaction, custom, avs },
		} = this.state;
		const {
			hideBillingInfoFields,
			hideShippingInfoFields,
			hideTransactionDetails,
			hideCustomFields,
			hideAvsFields,
		} = this.hiddenFields;
		const expand = {};
		if (!hideAvsFields) {
			expand.avs = avs;
		}
		if (!hideBillingInfoFields) {
			expand.billing = billing;
		}
		if (!hideShippingInfoFields) {
			expand.shipping = shipping;
		}
		if (!hideTransactionDetails) {
			expand.transaction = transaction;
		}
		if (!hideCustomFields) {
			expand.custom = custom;
		}
		return expand;
	}

	getAvsInformation = () => {
		const {
			avsInfo: { address, zip },
		} = this.state;
		return {
			zip: zip ? zip.value : null,
			street: address ? address.value : null,
		};
	};
	setFieldsToBillInfo = (bInfo, fieldName) => {
		const { customer } = this.props;

		const isCustomerValid = validators.any(
			() => this.state.billingInfo.firstName,
			() => this.state.billingInfo.lastName,
			() => this.state.billingInfo.company
		)(validators.if(validators.required, () => this.state.saveAsCustomer));
		if (fieldName === 'phoneNumber') {
			bInfo.phoneNumber = createField(
				customer && !!customer.xBillPhone && validators.phoneNumber(customer.xBillPhone) ? customer.xBillPhone : '',
				validators.if(validators.phoneNumber, validators.required),
				validators.if(
					validators.required,
					() =>
						(!this.state.customerId && this.state.requiredFields.phoneNumber) ||
						(this.state.sendSmsReceipt && this.showSendSms)
				)
			);
		} else if (fieldName === 'address') {
			bInfo[fieldName] = createField(
				customer ? customer.xBillStreet : '',
				validators.if(validators.required, () => !this.state.customerId && this.state.requiredFields.address)
			);
		} else {
			bInfo[fieldName] = createField(
				customer ? customer[`xBill${upperFirst(fieldName)}`] : '',
				validators.if(validators.required, () => !this.state.customerId && this.state.requiredFields[fieldName]),
				isCustomerValid
			);
		}
	};

	setInitialBillingInfo = () => {
		const billFields = [
			'firstName',
			'lastName',
			'company',
			'address',
			'city',
			'state',
			'zip',
			'country',
			'phoneNumber',
		];
		const bInfo = {};

		each(billFields, field => this.setFieldsToBillInfo(bInfo, field));

		return bInfo;
	};

	setInitialShippingInfo = () => {
		const { customer } = this.props;
		return {
			firstName: createField(customer ? customer.xShipFirstName : ''),
			lastName: createField(customer ? customer.xShipLastName : ''),
			company: createField(customer ? customer.xShipCompany : ''),
			address: createField(customer ? customer.xShipStreet : ''),
			city: createField(customer ? customer.xShipCity : ''),
			state: createField(customer ? customer.xShipState : ''),
			zip: createField(customer ? customer.xShipZip : ''),
			country: createField(customer ? customer.xShipCountry : ''),
			phoneNumber: createField(
				customer && !!customer.xShipPhone && validators.phoneNumber(customer.xShipPhone) ? customer.xShipPhone : '',
				validators.if(validators.phoneNumber, validators.required)
			),
		};
	};
	setInitialAvsInfo = () => {
		const { customer } = this.props;
		return {
			address: createField(
				customer && !!customer.xStreet && validators.any(customer.xStreet) ? customer.xStreet : '',
				validators.if(validators.required, () => this.state.requiredFields.avsStreet)
			),
			zip: createField(
				customer && !!customer.xZip && validators.phoneNumber(customer.xZip) ? customer.xZip : '',
				validators.if(validators.required, () => this.state.requiredFields.avsZip)
			),
		};
	};

	getCustomerInfoData = (sameAsBilling, type) => {
		const { billingInfo, shippingInfo, avsInfo } = this.state;
		if (sameAsBilling) {
			return billingInfo;
		}
		if (type === 'billing') {
			return billingInfo;
		}

		if (type === 'shipping') {
			return shippingInfo;
		}

		if (type == 'avs') {
			return avsInfo;
		}
	};
	getAmountPrefix = () => {
		const { creditCard, amount } = this.state;
		let merchantCurrency = this.merchantCurrencyCode;
		if (
			creditCard &&
			(creditCard.transactionType === 'credit' || creditCard.transactionType === 'gift:issue') &&
			this.state.paymentMethod !== paymentMethod.CHECK
		) {
			this.includeErrorClassName = true;
			if (amount.value) {
				merchantCurrency = `- ${merchantCurrency}`;
			}
		} else {
			this.includeErrorClassName = false;
		}

		return merchantCurrency;
	};

	get formRenderingParams() {
		const { convenienceFees, salesTax, permissions, isCanadian } = this.state;
		const check = this.state.check || this.initialState(this.props, permissions)['check'];
		const creditCard = this.state.creditCard || this.initialState(this.props, permissions)['creditCard'];
		const invalidClassName = 'is-invalid';
		const required = (
			<span className="required-field label--required" data-tooltip="Required">
				*
			</span>
		);

		const {
			hideShippingInfoFields,
			hideBillingInfoFields,
			hideTransactionDetails,
			hideAvsFields,
			hideCustomFields,
			hideAll,
		} = this.hiddenFields;
		const hasConvenienceFee =
			!!get(convenienceFees, 'enableConvenienceFee') &&
			checkHasConvenienceFee(convenienceFees, this.state.paymentMethod);
		const hasSalesTax = !!get(salesTax, 'enableSalesTax') && !!get(salesTax, 'salesTaxPercentage');

		if (this.state.paymentMethod === paymentMethod.CHECK && isCanadian) {
			this.switchPaymentMethodType(paymentMethod.CC);
		}

		const isEbtfs = creditCard && creditCard.transactionType === transactionType.EBTFS_VOUCHER;
		const isGift =
			creditCard && includes([transactionType.GIFT_ISSUE, transactionType.GIFT_REDEEM], creditCard.transactionType);

		return {
			isEbtfs,
			isGift,
			hasSalesTax,
			hasConvenienceFee,
			required,
			invalidClassName,
			creditCard,
			check,
			hideShippingInfoFields,
			hideBillingInfoFields,
			hideAvsFields,
			hideTransactionDetails,
			hideCustomFields,
			hideAll,
		};
	}

	async componentDidMount() {
		window.addEventListener('resize', this.updateExpandableView);
		try {
			const { customer, existingTransaction, makePendingRequest } = this.props;
			const [
				[requiredFields, unmappedCustomDisplayLabels, unmappedHiddenFields, portalFlags, userSettings],

				[convenienceFees, salesTaxResponse],
			] = await makePendingRequest(
				Promise.all([
					kvaasService.get(
						kvaasResources.transactionRequiredFields,
						kvaasResources.transactionDisplayLabels,
						kvaasResources.transactionHiddenFields,
						kvaasResources.portalFlags,
						kvaasResources.userSettings
					),
					this.loadConvenienceAndSales(),
				]),
				requestKeys.KVAAS
			);
			const isCanadian = checkIfCanadian();
			const salesTax = get(salesTaxResponse, 'data');
			const newState = {
				expand: clone(this.state.expand),
				isCanadian,
				customFields: cloneDeep(this.state.customFields),
			};
			const { hiddenColumns, customDisplayLabels } = mapConvenienceToCustom(
				convenienceFees,
				unmappedHiddenFields,
				unmappedCustomDisplayLabels
			);
			if (requiredFields && requiredFields.data) {
				const {
					data,
					data: { cvvAllTransactionsRequired, cvvNewTransactionsOnlyRequired },
				} = requiredFields;
				data.cvvNewTransactionsOnlyRequired = !!cvvNewTransactionsOnlyRequired;
				data.cvvAllTransactionsRequired = !!cvvAllTransactionsRequired;
				newState.requiredFields = data;

				const transactionType = get(userSettings, 'data.defaultTxnTypeOnNewTxn', '');
				const giftCard = existingTransaction && includes(toLower(existingTransaction.xCommand), 'gift');
				if (this.state.paymentMethod === paymentMethod.CC) {
					newState.creditCard = {
						...this.state.creditCard,
						cvvValid: {
							...this.state.creditCard.cvvValid,
							value: existingTransaction
								? !cvvAllTransactionsRequired
								: !cvvAllTransactionsRequired && !cvvNewTransactionsOnlyRequired,
						},
						cardNumberIsEmpty: true,
						cvvIsEmpty: true,
					};
					if (giftCard) {
						newState.creditCard.transactionType = 'gift:redeem';
					}
					if (transactionType && !giftCard) newState.creditCard.transactionType = transactionType;
				}
				this.expandSectionsWithRequiredFields(newState);
			}

			if (customDisplayLabels && customDisplayLabels.data) {
				newState.customDisplayLabels = customDisplayLabels.data;
			}
			if (hiddenColumns && hiddenColumns.data) {
				newState.transactionHiddenFields = hiddenColumns.data;
			}
			if (portalFlags && portalFlags.data) {
				newState.expandView = !!portalFlags.data.expandNewTransactionPopup;
				newState.allowSmsReceipt = !!portalFlags.data.allowSmsReceipt;
				newState.sendReceipt = !!portalFlags.data.sendReceipt;
				newState.sendSmsReceipt =
					newState.allowSmsReceipt && !!portalFlags.data.sendSmsReceipt && this.state.permissions.allowSendSms;
				newState.sendCopy = !!portalFlags.data.sendCopy;
				newState.showAllowDuplicate = !!portalFlags.data.allowDuplicate;
				newState.showEbtfsVoucherOption = !!portalFlags.data.showEbtfsVoucherOption;
				newState.showDisableVerification = this.state.has3DS && !!portalFlags.data.allowVerifyDisable;
				newState.splitCaptureEnabled = get(portalFlags, 'data.multipleCapture', false);
				newState.showIgnoreAvs = !!portalFlags.data.ignoreAvsOption;
			}
			if (convenienceFees && convenienceFees.data) {
				newState.convenienceFees = convenienceFees.data;
				newState.includeConvenience = !!newState.convenienceFees.enableConvenienceFee;
				newState.convenienceBeforeSales = !!newState.convenienceFees.convenienceBeforeSales;
				if (convenienceFees.data.allowExclude && !convenienceFees.data.enabledByDefault) {
					newState.includeConvenience = false;
				}
			}
			if (salesTax) {
				newState.salesTax = salesTax;
				newState.includeSalesTax = !!salesTax.enableSalesTax;
			}
			this.mapCustomerToCustomFields(newState, customer);
			this.setNewState(newState, userSettings);
		} catch (e) {
			if (e && !e.isCanceled) {
				//eslint-disable-next-line
				console.error(e);
			}
		}
	}

	async componentWillUnmount() {
		window.removeEventListener('resize', this.updateExpandableView);
	}

	setNewState = (newState, userSettings) => {
		const { permissions, achEnabled, isCanadian } = this.state;
		newState.defaultPaymentType = get(userSettings, 'data.defaultPaymentType', '');
		if (
			achEnabled &&
			!isCanadian &&
			permissions.allowCheckSale &&
			newState.defaultPaymentType === 'check' &&
			this.state.paymentMethod !== 'check'
		) {
			this.setState(newState, () => this.switchPaymentMethodType(paymentMethod.CHECK));
		} else {
			newState.isLoading = false;
			this.setState(newState);
		}
	};

	loadConvenienceAndSales = async () => {
		const results = await this.props.makePendingRequest(
			kvaasService.get(kvaasResources.convenienceFees, kvaasResources.salesTax),
			requestKeys.FEESANDTAXES
		);

		if (
			some(
				results,
				({ result, error }) =>
					(toLower(result) !== 's' && toLower(error) !== 'item does not exist') || toLower(error) === 'failed to fetch'
			)
		) {
			this.setState({ displayConvenienceAndSalesFailedToLoad: true, isLoading: false });
			return;
		}

		return results;
	};

	currencyCode = CurrencyMap.resolveCurrency();

	get merchantCurrencyCode() {
		const { isCanadianCurrency } = this.state;
		return isCanadianCurrency ? 'C$' : this.currencyCode;
	}

	getSmsTooltip = () => {
		const { customDisplayLabels } = this.state;
		let smsTooltip = '$0.02 SMS fee will apply for every text receipt sent.';
		if (this.props.customer && !this.props.customer.xBillPhone) {
			smsTooltip = `Billing ${customDisplayLabels.phoneNumber || 'Phone Number'} is required`;
		}

		return smsTooltip;
	};
	areAllFieldsHidden = fields => {
		const { transactionHiddenFields } = this.state;
		const hiddenFields = filter(keys(transactionHiddenFields), Boolean);
		return every(fields, field => includes(hiddenFields, field));
	};

	expandSectionsWithRequiredFields = newState => {
		const { requiredFields } = newState;
		newState.expand.billing = true;
		newState.expand.transaction = some(transactionDetailsFieldLabels, item => !!requiredFields[item]);
		newState.expand.custom = some(customFieldLabels, item => !!requiredFields[item]);
	};

	getPaymentMethodOptions = type => {
		const createdPaymentMethods = (this.state && this.state.createdPaymentMethods) || [];
		return concat(
			sortBy(
				filter([...(this.props.paymentMethods || []), ...createdPaymentMethods], x => toLower(x.xTokenType) === type),
				x => !x.xIsDefaultPaymentMethod
			),
			[{ xTokenType: type, newMethod: true }]
		);
	};

	createCustomFields = customer => {
		const createCustomField = i =>
			createField(
				'',
				validators.if(
					validators.required,
					() =>
						this.state.requiredFields[`custom${i + 1}`] &&
						this.state.convenienceFees.originalCustomKey !== `Custom${padStart(i + 1, 2, 0)}` &&
						this.state.convenienceFees.convenienceCustomKey !== `Custom${padStart(i + 1, 2, 0)}`
				)
			);
		const customFields = {};
		times(19, i => {
			customFields[`custom${i + 1}`] = createCustomField(i);
		});

		customFields.custom1 = createField(
			customer && customer.xCustomerId,
			validators.if(validators.required, () => this.state.requiredFields.custom1 && !this.state.saveAsCustomer)
		);
		customFields.custom2.value = (customer && customer.xCustomerNumber) || '';

		return customFields;
	};

	setStateAsync = newState => {
		return new Promise(resolve => {
			this.setState(newState, () => {
				resolve();
			});
		});
	};

	shouldDecimalScale = (value, isBlurred) => {
		if (!value) return false;
		return isBlurred;
	};

	handleChange = e => {
		const target = e.target;
		const name = target.name;

		let value = target.type === 'checkbox' ? target.checked : target.value;
		if (name === 'sameAsBilling') {
			let { billingInfo, shippingInfo, originalShippingInfo } = this.state;
			if (value) {
				this.setState({
					shippingInfo: billingInfo,
					originalShippingInfo: shippingInfo,
				});
			} else {
				this.setState({ shippingInfo: originalShippingInfo });
			}
		}
		if (name === 'saveAsCustomer' && value && this.state.customFields.custom1.value) {
			const newErrors = {
				custom1:
					'The information on Custom01 will be lost, it is advised to either copy the information to some other field or to clear the field if not needed.',
			};
			this.setState({ errors: { ...this.state.errors, ...newErrors } });
			return;
		}

		if (isObject(this.state[name])) {
			value = {
				...this.state[name],
				value,
				dirty: true,
			};
		}
		const newState = {
			[name]: value,
		};
		if (name === 'saveToFile') {
			newState.setAsDefault = false;
		}
		if (name === 'saveAsCustomer') {
			newState.customFields = {
				...this.state.customFields,
				custom1: {
					...this.state.customFields.custom1,
					value: '',
				},
			};
		}

		if (some(['includeConvenience', 'includeSalesTax'], item => item === name)) {
			this.calculateTotalAmount(newState);
		}

		this.setState(newState, this.checkFormValidity);

		if (name === 'sameAsBilling' && value === this.state.expand.shipping) {
			this.onExpandShrink('shipping');
		}
	};

	checkIfAmountAllowed = ({ value }) => {
		return value.length < 16;
	};

	checkIfSalesTaxAmountAllowed = ({ value }) => {
		const maxAllowedAmount = round(get(this.state, 'amount.value', 0) * 0.2, 2);
		return value <= maxAllowedAmount;
	};

	getAmountValue = value => (value === '.0' ? value : this.getAbsoluteAmountValue(value));

	getAbsoluteAmountValue = value => {
		return isNumber(parseFloat(value)) && parseFloat(Math.abs(value));
	};

	calculateTotalAmount = newState => {
		const {
			amount: { value },
			convenienceFees,
			salesTax,
			salesTaxAmount,
			isSalesTaxAmountOverwritten,
			paymentMethod,
			convenienceBeforeSales,
		} = this.state;
		const {
			includeConvenience = this.state.includeConvenience,
			includeSalesTax = this.state.includeSalesTax,
		} = newState;
		const floatValue = parseFloat(value || 0);

		if (includeSalesTax) {
			if (isSalesTaxAmountOverwritten) {
				newState.salesTaxAmount = salesTaxAmount;
			} else {
				const amountWithConvenienceFee = parseFloat(
					floatValue + calculateConvenienceFee(floatValue, convenienceFees, paymentMethod)
				).toFixed(4);

				newState.salesTaxAmount = calculateSalesTax(
					convenienceBeforeSales && includeConvenience ? amountWithConvenienceFee : floatValue,
					get(salesTax, 'salesTaxPercentage', 0)
				);
			}
		} else {
			newState.salesTaxAmount = this.state.salesTaxAmount;
		}

		newState.convenienceFee = includeConvenience
			? calculateConvenienceFee(
					convenienceBeforeSales || !includeSalesTax
						? floatValue
						: parseFloat(floatValue + newState.salesTaxAmount).toFixed(4),
					convenienceFees,
					paymentMethod
			  )
			: 0;

		newState.total = toCurrency(floatValue + newState.convenienceFee + (includeSalesTax ? newState.salesTaxAmount : 0));
	};

	handleSalesTaxAmountChange = ({ floatValue, value }) => {
		const {
			convenienceFees,
			paymentMethod,
			amount,
			includeConvenience,
			includeSalesTax,
			convenienceBeforeSales,
		} = this.state;
		const amountFloatValue = toCurrency(amount.value);
		let convenienceFee = includeConvenience
			? calculateConvenienceFee(
					convenienceBeforeSales ? amountFloatValue : parseFloat(amountFloatValue + floatValue).toFixed(4),
					convenienceFees,
					paymentMethod
			  )
			: 0;
		const newState = {
			salesTaxAmount: floatValue,
			isSalesTaxAmountOverwritten: true,
			convenienceFee,
		};

		if (includeSalesTax) {
			newState.total = toCurrency(amountFloatValue + convenienceFee + (floatValue || 0));
		}

		this.setState(newState, () => {
			if (this.salesTaxInputRef && !endsWith(value, '.')) {
				this.salesTaxInputRef.blur();
				this.salesTaxInputRef.focus();
			}
		});
	};
	handleAmountFocus = () => {
		if (this.state.amountFocused) return;
		this.setState({
			isBlurred: false,
		});
	};
	handleAmountBlur = () => {
		if (this.state.amountFocused) return;

		this.setState({
			isBlurred: true,
		});
	};

	handleAmountChange = ({ floatValue = 0, value = '0' }) => {
		const {
			convenienceFees,
			paymentMethod,
			creditCard,
			includeConvenience,
			convenienceBeforeSales,
			includeSalesTax,
		} = this.state;
		const floatAmount = Math.abs(floatValue);
		const salesTaxPercentage = get(this.state.salesTax, 'salesTaxPercentage', 0);
		const amountWithConvenienceFee = parseFloat(
			calculateConvenienceFee(floatAmount, convenienceFees, paymentMethod) + floatAmount
		);
		const amountWithConv = convenienceBeforeSales && includeConvenience ? amountWithConvenienceFee : null;
		const saleTaxAmount = amountWithConv || floatAmount;
		let salesTaxAmount = includeSalesTax ? calculateSalesTax(saleTaxAmount, salesTaxPercentage) : 0;
		const convenienceFeeAmount =
			convenienceBeforeSales || !includeSalesTax ? floatAmount : parseFloat((salesTaxAmount + floatAmount).toFixed(4));
		let convenienceFee = includeConvenience
			? calculateConvenienceFee(convenienceFeeAmount, convenienceFees, paymentMethod)
			: 0;

		if (creditCard && creditCard.transactionType === transactionType.SAVE) {
			salesTaxAmount = 0;
			convenienceFee = 0;
		}
		const amountValue = !value ? '' : this.getAmountValue(value);
		this.setState(
			{
				amountFocused: true,
				salesTaxAmount,
				isSalesTaxAmountOverwritten: false,
				convenienceFee,
				total: toCurrency(convenienceFee + floatAmount + salesTaxAmount),
				amount: {
					...this.state.amount,
					value: amountValue,
				},
			},
			() => {
				this.checkFormValidity();
				if (this.amountRef && !endsWith(value, '.')) {
					this.amountRef.blur();
					this.amountRef.focus();
					this.setState({ amountFocused: false });
				}
			}
		);
	};

	handleCustomerPaymentMethodChange = selectedCustomerPaymentMethod => {
		const { creditCard } = this.state;
		const newState = { selectedCustomerPaymentMethod };

		if (
			selectedCustomerPaymentMethod.xToken &&
			creditCard &&
			creditCard.transactionType === transactionType.EBTFS_VOUCHER
		) {
			newState.creditCard = { ...this.state.creditCard, transactionType: transactionType.SALE };
		}

		this.setState(newState);
	};

	updateExpandableView = () => {
		const { isExpandableView } = this.state;
		const newIsExpandableView = window.innerWidth >= screenSize.lrg;
		if (newIsExpandableView !== isExpandableView) {
			this.setState({
				isExpandableView: newIsExpandableView,
			});
		}
	};

	switchPaymentMethodType = type => {
		if (this.state.paymentMethod !== type) {
			let updateDifferentPayment = true;
			if (type === this.existingTransactionPaymentType && !this.missingToken) {
				updateDifferentPayment = false;
			}

			const paymentMethodOptions = this.getPaymentMethodOptions(type);
			const selectedCustomerPaymentMethod = paymentMethodOptions[0];
			let newState;

			if (type === paymentMethod.CC) {
				const { differentPayment, requiredFields, creditCard, permissions } = this.state;
				const { existingTransaction } = this.props;
				const cc = creditCard || this.initialState(this.props, permissions).creditCard;
				let cvvValid = requiredFields.cvvAllTransactionsRequired
					? !requiredFields.cvvAllTransactionsRequired
					: !requiredFields.cvvNewTransactionsOnlyRequired;

				if (existingTransaction) {
					cvvValid = differentPayment
						? !requiredFields.cvvAllTransactionsRequired
						: !requiredFields.cvvAllTransactionsRequired && !requiredFields.cvvNewTransactionsOnlyRequired;
				}

				newState = {
					creditCard: {
						...cc,
						ccValid: {
							...cc.ccValid,
							value: false,
						},
						cvvValid: {
							...cc.cvvValid,
							value: cvvValid,
						},
						isSwipe: {
							...cc.isSwipe,
							value: false,
						},
						expiry: {
							...cc.expiry,
							value: '',
						},
						cardNumberIsEmpty: true,
						cvvIsEmpty: true,
					},
					paymentMethod: type,
					differentPayment: updateDifferentPayment,
					paymentMethodOptions,
					selectedCustomerPaymentMethod,
				};
			} else {
				const check = this.state.check || this.initialState(this.props, this.state.permissions).check;
				newState = {
					isLoading: false,
					check,
					paymentMethod: type,
					differentPayment: updateDifferentPayment,
					paymentMethodOptions,
					selectedCustomerPaymentMethod,
				};
			}
			const {
				amount: { value },
				convenienceFees,
				salesTax,
				salesTaxAmount,
				includeConvenience,
				includeSalesTax,
				isSalesTaxAmountOverwritten,
				convenienceBeforeSales,
			} = this.state;
			const floatValue = parseFloat(value || 0);

			if (includeSalesTax) {
				if (isSalesTaxAmountOverwritten) {
					newState.salesTaxAmount = salesTaxAmount;
				} else {
					const amountWithConvenienceFee = parseFloat(
						floatValue + calculateConvenienceFee(floatValue, convenienceFees, type)
					);

					newState.salesTaxAmount = calculateSalesTax(
						convenienceBeforeSales && includeConvenience ? amountWithConvenienceFee : floatValue,
						get(salesTax, 'salesTaxPercentage', 0)
					);
				}
			}

			newState.convenienceFee = includeConvenience
				? calculateConvenienceFee(
						convenienceBeforeSales || !includeSalesTax
							? floatValue
							: (newState.salesTaxAmount && parseFloat(floatValue + newState.salesTaxAmount).toFixed(4)) || floatValue,
						convenienceFees,
						type
				  )
				: 0;

			newState.total = toCurrency(
				floatValue + newState.convenienceFee + (includeSalesTax ? newState.salesTaxAmount : 0)
			);
			this.setState(newState, this.checkFormValidity);
		}
	};

	refreshGridData = () => {
		this.props.refreshGridData();
	};
	setOnClose = (customerId, useMethodOnFile, saveToFile, createdPaymentMethods) =>
		!customerId || (!useMethodOnFile && saveToFile) || !isEmpty(createdPaymentMethods)
			? this.closeAndRefresh
			: this.closeModal;

	closeModal = () => {
		if (this.props.closeModal) {
			this.props.closeModal();
		}
	};

	closeAndRefresh = () => {
		this.closeModal();
		this.refreshGridData();
	};

	closeProcessLaterPopup = () => this.setState({ processLater: this.initialProcessLater, scheduleNameDirty: false });

	scrollToTop = () => {
		const node = this.pageTopRef;
		node && node.current && node.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
	};

	showLoader = () => {
		//this.props.showLoader(value);
	};
	shouldRefreshSutToken = (customerId, differentPayment, useMethodOnFile) => {
		return (!customerId && differentPayment) || (customerId && !useMethodOnFile);
	};

	refreshSutToken = async () => {
		if (this.ccFields) {
			const {
				requiredFields,
				creditCard: { isSwipe, transactionType },
			} = this.state;
			const fetchCvvToken = !startsWith(transactionType, 'gift');
			const request = this.props.makePendingRequest(this.ccFields.getIfieldTokens(fetchCvvToken), requestKeys.CC);
			try {
				const response = await request;
				if (response && response.cc) {
					const newCc = cloneDeep(this.state.creditCard);
					newCc.ccToken = response.cc;
					if (
						fetchCvvToken &&
						(!requiredFields.cvvAllTransactionsRequired ||
							!requiredFields.cvvNewTransactionsOnlyRequired ||
							response.cvv ||
							isSwipe.value)
					) {
						newCc.cvvToken = response.cvv;
					}

					await this.setStateAsync({
						creditCard: newCc,
					});
					return true;
				}
			} catch (e) {
				if (e && !e.isCanceled) {
					//eslint-disable-next-line
					console.error(e);
				}
			}
		}
		if (this.achFields) {
			const request = this.props.makePendingRequest(this.achFields.getIfieldTokens(), requestKeys.ACH);
			try {
				const response = await request;
				if (response && response.ach) {
					const newAch = cloneDeep(this.state.check);
					newAch.achToken = response.ach;

					await this.setStateAsync({
						check: newAch,
					});

					return true;
				}
			} catch (e) {
				if (e && !e.isCanceled) {
					//eslint-disable-next-line
					console.error(e);
				}
			}
		}
		return false;
	};

	handleError = e => {
		const handledError = this.props.handleError(e, { isNewTransaction: true });
		if (handledError) {
			this.showLoader(false);
			this.setState({
				isProcessing: false,
				unapprovedTransaction: true,
			});
			return handledError;
		}
	};

	mapCustomerToCustomFields = (newState, customer) => {
		each(customer, (value, key) => {
			const number = parseFloat(key.slice(-2));
			if (
				number > 1 &&
				number <= 19 &&
				(!newState.transactionHiddenFields[`custom${number}`] || (number > 3 && customer[key])) &&
				startsWith(key, 'xCustomerCustom') &&
				value
			) {
				if (newState.transactionHiddenFields[`custom${number}`]) {
					newState.transactionHiddenFields[`custom${number}`] = false;
				}
				newState.customFields[`custom${number}`].value = customer[key];
			}
		});
	};

	performDirtyCheck = async () => {
		const newState = cloneDeep(this.state);
		each(newState, item => {
			if (isObject(item)) {
				if (item.dirty !== undefined) {
					item.dirty = true;
				} else {
					each(item, field => {
						if (isObject(field)) {
							if (field.dirty !== undefined) {
								field.dirty = true;
							}
						}
					});
				}
			}
		});
		await this.setStateAsync(newState);
	};

	onProcessLaterInputsChange = (name, value) => {
		const { processLater, scheduleNameDirty } = this.state;
		const newState = {};

		if (name === 'scheduleName') {
			newState.scheduleNameDirty = true;
			newState.processLater = {
				...processLater,
				[name]: value,
			};
		} else {
			newState.processLater = {
				...processLater,
				[name]: moment(value.date).format(displayDateFormat),
			};

			if (!scheduleNameDirty) {
				newState.processLater.scheduleName = `Scheduled Payment ${moment(value.date).format(displayDateFormat)}`;
			}
		}

		this.setState(newState);
	};

	syncInputWithDate = (name, value) => {
		const { scheduleNameDirty } = this.state;
		const newState = { processLater: { ...this.state.processLater, [name]: value } };

		if (!scheduleNameDirty) {
			newState.processLater.scheduleName = `Scheduled Payment ${value}`;
		}

		this.setState(newState);
	};

	handleSendCopy = async (sendCopy, rsp, xMerchantId) => {
		if (sendCopy) {
			const user = await authenticationService.getUser();
			const username = user && user.attributes && user.attributes.email;
			transactionService.sendReceipt(username, rsp.xRefNum || rsp.xRecurringRefNum || rsp.xGatewayRefNum, xMerchantId);
		}
	};

	handleSaveAsCustomer = async (saveAsCustomer, customer, creditCard, check, rsp) => {
		if (saveAsCustomer) {
			try {
				const paymentData = {
					customerId: !isEmpty(customer.xReportData) && customer.xReportData[0].customerId,
					tokenType: this.state.paymentMethod,
					name:
						this.state.paymentMethod === paymentMethod.CC ? creditCard.cardholderName.value : check.accountName.value,
					tokenAlias: '',
					token: rsp.xToken,
					setAsDefault: true,
					exp: this.state.paymentMethod === paymentMethod.CC ? creditCard.expiry.value : '',
					routingNumber: this.state.paymentMethod === paymentMethod.CHECK ? check.routingNumber.value : '',
					...this.getAvsInformation(),
				};
				await this.props.makePendingRequest(customerService.addCustomerPaymentMethod(paymentData), requestKeys.TRANS);
			} catch (e) {
				this.handleError(e);
				return;
			}
		}
	};

	handleDateChange = (fieldName, datePickerRef, { formattedValue, value }) => {
		const { processLater, scheduleNameDirty } = this.state;
		const newState = { processLater: { ...processLater, [fieldName]: formattedValue } };

		if (!scheduleNameDirty) {
			newState.processLater.scheduleName = `Scheduled Payment ${formattedValue}`;
		}

		this.setState(newState);

		if (value.length === 8 && moment(formattedValue, displayDateFormat, true).isValid()) {
			if (datePickerRef.current && datePickerRef.current.dateRef.current) {
				datePickerRef.current.dateRef.current.showMonth(moment.utc(formattedValue, displayDateFormat, true).toDate());
			}
		}
	};

	processLater = async () => {
		const { billingInfo, customDisplayLabels, errors } = this.state;
		await this.performDirtyCheck();
		if (!this.checkFormValidity()) {
			this.scrollToTop();
			return;
		}

		if (!billingInfo.firstName.value && !billingInfo.lastName.value && !billingInfo.company.value) {
			errors.billingInfo = errors.billingInfo || {};
			errors.billingInfo.lastName = `Transactions scheduled to be processed later require a ${customDisplayLabels.company ||
				'Company'}, ${customDisplayLabels.firstName || 'First Name'} or ${customDisplayLabels.lastName || 'Last Name'}`;
			this.setState({ errors });
			this.scrollToTop();
			return;
		}

		const newState = cloneDeep(this.state.processLater);
		newState.isModalOpen = true;
		this.setState({
			processLater: newState,
		});
	};

	onSubmit = async e => {
		const {
			allowDuplicate,
			disableVerification,
			saveAsCustomer,
			isProcessing,
			customerId,
			differentPayment,
			selectedCustomerPaymentMethod,
			billingInfo,
			saveToFile,
			createdPaymentMethods,
			creditCard,
			check,
			sendCopy,
			principal,
			splitCaptureEnabled,
			ignoreAvs,
			sendSmsReceipt,
		} = this.state;
		const { xMerchantId } = this.props;
		e && e.preventDefault();

		if (isProcessing) {
			return;
		}

		await this.performDirtyCheck();

		if (!this.checkFormValidity()) {
			this.scrollToTop();
			return;
		}
		this.showLoader(true);
		await this.setStateAsync({
			isProcessing: true,
		});

		const useMethodOnFile = selectedCustomerPaymentMethod && !selectedCustomerPaymentMethod.newMethod;
		if (this.shouldRefreshSutToken(customerId, differentPayment, useMethodOnFile)) {
			const receivedTokens = await this.refreshSutToken();
			if (!receivedTokens) {
				this.onError();
				return;
			}
		}
		try {
			if (this.state.paymentMethod === paymentMethod.CC) {
				await this.props.makePendingRequest(this.updateExpDate(), requestKeys.MAP);
			}
			const mapped = await mapToCardknoxFields(this.state);

			const transactionType = split(toLower(mapped.xCommand), ':')[1];
			let createdPaymentMethod;

			if (this.state.paymentMethod === paymentMethod.CC && creditCard.transactionType === 'save') {
				mapped.xExp = get(creditCard, 'expiry.value', '');
			}
			if (customerId && !useMethodOnFile && saveToFile) {
				const { success, newPaymentMethod } = await this.createToken(mapped);
				if (!success) {
					this.onError();
					return;
				}
				createdPaymentMethod = newPaymentMethod;
			}
			const method = transactionService.createNewTransaction;
			let customer;
			try {
				if (saveAsCustomer) {
					billingInfo.email = mapped.xEmail;
					customer = await this.props.makePendingRequest(
						customerService.addCustomer(this.prepareCustomerData()),
						requestKeys.TRANS
					);
					mapped.xCustom01 = !isEmpty(customer.xReportData) && customer.xReportData[0].customerId;
				}
				mapped.xIgnoreAvs = !!ignoreAvs;
				mapped.xAllowDuplicate = !!allowDuplicate;
				mapped.xDisableVerify = !!disableVerification;
				mapped.xAllowNonAuthenticated = true;

				if (transactionType === 'authonly') {
					mapped.xRequireSplitCapturable = splitCaptureEnabled;
				}

				const rsp = await this.props.makePendingRequest(method(xMerchantId, mapped), requestKeys.TRANS);
				const notification = {
					ref: rsp.xGatewayRefNum || rsp.xRefNum || rsp.xRecurringRefNum,
					message: 'Transaction processed!',
					success: true,
					showViewTransaction: !principal.hasAccess[sectionKeys.virtualTerminal] && transactionType !== 'save',
					onClose: this.setOnClose(customerId, useMethodOnFile, saveToFile, createdPaymentMethods),
				};
				await this.handleSendCopy(sendCopy, rsp, xMerchantId);
				if (sendSmsReceipt) {
					const paymentMethod = includes(mapped.xCommand, 'authonly') ? 'authorization' : 'payment';
					const isReturn = includes(mapped.xCommand, 'refund') || includes(mapped.xCommand, 'credit');
					const isCredit = startsWith(mapped.xCommand, 'check:');
					const number = isCredit ? rsp.xMaskedAccountNumber : rsp.xMaskedCardNumber;
					messagingService.sendReceipt(
						mapped.xBillPhone,
						principal.companyName,
						paymentMethod,
						isReturn ? 'returned' : 'received',
						isReturn ? 'to' : 'from',
						isCredit ? 'account' : 'card',
						number.substring(number.length - 4),
						rsp.xRefNum,
						mapped.xAmount
					);
				}
				await this.handleSaveAsCustomer(saveAsCustomer, customer, creditCard, check, rsp);

				this.setState({ unapprovedTransaction: false }, () => {
					this.closeModal();
					this.notificationRef.addNotification(notification);
					this.showLoader(false);
				});
			} catch (e) {
				if (this.handleError(e)) {
					if (customer && mapped.xCustom01) {
						customerService.deleteCustomer(mapped.xCustom01);
					}
					if (createdPaymentMethod) {
						this.addPaymentMethod(createdPaymentMethod);
					}
				}
			}
		} catch (e) {
			this.handleError(e);
		}
	};

	parseCustomFields = () => {
		const { customFields } = this.state;

		return transform(customFields, (acc, { value }, key) => {
			if (value && key !== 'custom1') {
				let customFieldNumber = replace(key, 'custom', '');

				if (customFieldNumber.length === 1) {
					customFieldNumber = customFieldNumber.padStart(2, '0');
				}

				acc[`CustomerCustom${customFieldNumber}`] = value;
			}
		});
	};

	prepareCustomerData = () => {
		const {
			billingInfo,
			shippingInfo,
			email: { value: Email },
			sameAsBilling,
		} = this.state;
		let data = {
			...parseCustomerInfo(billingInfo, null, true),
			...this.parseCustomFields(),
			Email,
		};

		if (!sameAsBilling) {
			data = {
				...data,
				...parseCustomerInfo(shippingInfo, 'shipping', true),
			};
		}

		data = transform(data, (acc, value, key) => {
			if (value) {
				acc[key] = value;
			}
		});

		return data;
	};

	processPaymentLater = async e => {
		const {
			allowDuplicate,
			disableVerification,
			isProcessing,
			customerId,
			differentPayment,
			amount,
			processLater,
			transactionDetails: {
				invoice: { value: invoice },
				description: { value: description },
			},
			creditCard,
			check,
		} = this.state;
		e && e.preventDefault();

		if (isProcessing) {
			return;
		}

		await this.performDirtyCheck();

		if (!this.checkFormValidity()) {
			this.scrollToTop();
			return;
		}

		this.showLoader(true);
		this.setState({
			isProcessing: true,
		});

		const schedule = {
			_meta: {},
			customerId: '',
			intervalType: 'month',
			intervalCount: '1',
			totalPayments: 1,
			amount: amount.value,
			calendarCulture: 'Gregorian',
			scheduleName: processLater.scheduleName,
			afterMaxRetriesAction: 'ContinueNextInterval',
			startDate: processLater.startDate,
			failedTransactionRetryTimes: 5,
			invoice,
			description,
		};

		let customer;
		let createdPaymentMethod;

		const useMethodOnFile =
			(this.state.paymentMethod === paymentMethod.CC ? !!creditCard.token : !!check.token) && !differentPayment;

		if ((!customerId && differentPayment) || !useMethodOnFile) {
			const receivedTokens = await this.refreshSutToken();
			if (!receivedTokens) {
				this.onError();
				return;
			}
		}

		try {
			if (this.state.paymentMethod === paymentMethod.CC) {
				await this.props.makePendingRequest(this.updateExpDate(), requestKeys.MAP);
			}
			const mapped = await mapToCardknoxFields(this.state);

			try {
				customer = await this.props.makePendingRequest(
					customerService.addCustomer(this.prepareCustomerData()),
					requestKeys.TRANS
				);
				const customerId = (!isEmpty(customer.xReportData) && customer.xReportData[0].customerId) || customerId;
				mapped.xCustom01 = customerId;
				schedule.customerId = customerId;

				if (allowDuplicate) {
					mapped.xAllowDuplicate = true;
				}
				if (disableVerification) {
					mapped.xDisableVerify = true;
				}
				await this.setState({ customerId: schedule.customerId, saveToFile: true });

				if (!useMethodOnFile) {
					const { success, newPaymentMethod } = await this.createToken(mapped);
					if (!success) {
						this.onError();
						return;
					}
					createdPaymentMethod = newPaymentMethod;
				} else {
					try {
						const paymentData = {
							customerId,
							tokenType: this.state.paymentMethod,
							name:
								this.state.paymentMethod === paymentMethod.CC
									? creditCard.cardholderName.value
									: check.accountName.value,
							tokenAlias: '',
							token: this.state.paymentMethod === paymentMethod.CC ? creditCard.token : check.token,
							setAsDefault: true,
							exp: this.state.paymentMethod === paymentMethod.CC ? creditCard.expiry.value : '',
							routingNumber: this.state.paymentMethod === paymentMethod.CHECK ? check.routingNumber.value : '',
						};
						await this.props.makePendingRequest(
							customerService.addCustomerPaymentMethod(paymentData),
							requestKeys.TRANS
						);
					} catch (e) {
						this.closeProcessLaterPopup();
						this.handleError(e);
						return;
					}
				}

				await this.setState({ saveToFile: false });

				const rsp = await this.props.makePendingRequest(
					customerService.addCustomerRecurringSchedule(schedule),
					requestKeys.MAP
				);

				const notification = {
					ref: rsp.ref,
					message: (
						<div>
							<p>
								This transaction is scheduled to be processed at {moment(schedule.startDate).format(displayDateFormat)}.
							</p>
							<br />
							<p>
								Scheduling a transaction to be processed later will create a recurring schedule with a single payment.
							</p>
						</div>
					),
					success: true,
					showViewTransaction: false,
					onClose: this.closeModal,
				};
				this.setState({ unapprovedTransaction: false, processLater: this.initialProcessLater }, () => {
					this.closeModal();
					this.notificationRef.addNotification(notification);
					this.showLoader(false);
				});
			} catch (e) {
				this.closeProcessLaterPopup();
				if (this.handleError(e)) {
					if (customer && mapped.xCustom01) {
						customerService.deleteCustomer(mapped.xCustom01);
					}
					if (createdPaymentMethod) {
						this.addPaymentMethod(createdPaymentMethod);
					}
				}
			}
		} catch (e) {
			this.closeProcessLaterPopup();
			this.handleError(e);
		}
	};

	prefixKeysWithX = obj => {
		return mapKeys(obj, (value, key) => {
			return 'x' + upperFirst(key);
		});
	};

	addPaymentMethod = async createdPaymentMethod => {
		this.setState(
			{
				createdPaymentMethods: [...this.state.createdPaymentMethods, createdPaymentMethod],
			},
			async () => {
				const paymentMethods = await customerService.getCustomerPaymentMethods(this.state.customerId);
				const selectedCustomerPaymentMethod = this.prefixKeysWithX(
					find(paymentMethods.xReportData, {
						paymentMethodId: createdPaymentMethod.PaymentMethodId,
					})
				);
				this.setState({
					paymentMethodOptions: [...this.state.paymentMethodOptions, selectedCustomerPaymentMethod],
					selectedCustomerPaymentMethod,
					saveToFile: false,
					setAsDefault: false,
				});
			}
		);
	};

	createToken = async mapped => {
		const name =
			this.state.paymentMethod === paymentMethod.CC
				? this.state.creditCard.cardholderName.value
				: this.state.check.accountName.value;
		const saveData = {
			xCommand: `${this.state.paymentMethod}:save`,
			xAmount: 0,
			xName: name,
		};
		if (this.state.paymentMethod === paymentMethod.CC) {
			saveData.xCardNum = this.state.creditCard.ccToken;
			saveData.xExp = this.state.creditCard.expiry.value;
			if (this.state.creditCard.cvvToken) {
				saveData.xCvv = this.state.creditCard.cvvToken;
			}
		} else {
			saveData.xRouting = this.state.check.routingNumber.value;
			saveData.xAccount = this.state.check.achToken;
		}
		try {
			const save = await this.props.makePendingRequest(
				transactionService.createNewTransaction('', saveData),
				requestKeys.TRANS
			);
			mapped.xToken = save.xToken;
			let newPaymentMethod;
			if (this.state.saveToFile) {
				const paymentData = {
					customerId: this.state.customerId,
					tokenType: this.state.paymentMethod,
					name: name,
					tokenAlias: '',
					token: save.xToken,
					setAsDefault: this.state.setAsDefault,
					exp: this.state.paymentMethod === paymentMethod.CC ? this.state.creditCard.expiry.value : '',
					routingNumber: this.state.paymentMethod === paymentMethod.CHECK ? this.state.check.routingNumber.value : '',
				};
				const { data } = await this.props.makePendingRequest(
					customerService.addCustomerPaymentMethod(paymentData),
					requestKeys.TRANS
				);
				newPaymentMethod = data;
			}
			return {
				success: true,
				newPaymentMethod,
			};
		} catch (e) {
			this.handleError(e);
			return {
				success: false,
			};
		}
	};

	updateExpDate = async () => {
		const { existingTransaction, xMerchantId } = this.props;
		const {
			creditCard: {
				expiry: { value },
				token,
			},
			updatedExp,
			differentPayment,
			creditCard,
		} = this.state;
		if (differentPayment || !token) {
			return;
		}
		let oldExpiration = updatedExp;
		if (!oldExpiration) {
			oldExpiration = existingTransaction && existingTransaction.xExp;
		}
		if (oldExpiration && oldExpiration !== value && creditCard.transactionType !== 'save') {
			try {
				const result = await transactionService.createNewTransaction(xMerchantId, {
					xCommand: 'cc:save',
					xExp: value,
					xToken: token,
				});

				if (result && result.xToken) {
					return this.setStateAsync({
						updatedExp: value,
						creditCard: {
							...this.state.creditCard,
							token: result.xToken,
						},
					});
				}
			} catch (e) {
				this.handleError(e);
			}
		}
	};

	onError = e => {
		this.setState(
			{
				errors: {
					tokenfetch: 'Unable to create tokens',
				},
				isProcessing: false,
			},
			() => {
				this.scrollToTop();
			}
		);
		//eslint-disable-next-line
		console.log('load error: ', e);
	};

	onCreditCardChange = (...changedItems) => {
		if (changedItems[0].value === transactionType.SAVE) {
			this.handleAmountChange({ floatValue: 0, value: 0 });
		}

		this.setState(currentState => {
			const newState = { creditCard: {} };
			const newCc = cloneDeep(currentState.creditCard);
			each(changedItems, changedItem => this.updateField(newCc, changedItem.key, changedItem.value));
			if (
				some(changedItems, changedItem => {
					changedItem.key === 'transactionType';

					if (changedItem.value === transactionType.EBTFS_VOUCHER) {
						newState.saveAsCustomer = false;
						newState.saveToFile = false;
						newState.setAsDefault = false;
					}
				})
			) {
				newCc.authCode.value = '';
				newCc.voucherSerial.value = '';
			}

			newState.creditCard = newCc;
			return newState;
		}, this.checkFormValidity);
	};

	/**
	 * Updates check state with changed item from the check form
	 * @param changedItem object with shape
	 * {
	 *   key: <name of element that changed>,
	 *   value: <value of the element that changed>
	 * }
	 */
	onCheckChange = (...changedItems) => {
		const newCheck = cloneDeep(this.state.check);
		each(changedItems, changedItem => this.updateField(newCheck, changedItem.key, changedItem.value));

		this.setState({ check: newCheck }, this.checkFormValidity);
	};

	onBillingChange = changedItem => {
		let { sameAsBilling, shippingInfo } = this.state;
		const newBilling = cloneDeep(this.state.billingInfo);

		if (sameAsBilling) {
			shippingInfo = newBilling;
		}
		this.updateField(newBilling, changedItem.key, changedItem.value);

		this.setState({ billingInfo: newBilling, shippingInfo }, this.checkFormValidity);
	};

	onShippingChange = changedItem => {
		const newShipping = cloneDeep(this.state.shippingInfo);
		this.updateField(newShipping, changedItem.key, changedItem.value);

		this.setState({ shippingInfo: newShipping }, this.checkFormValidity);
	};
	onAvsInfoChange = ({ target: { name, value } }) => {
		const changedItem = {
			key: name,
			value: value,
		};
		const newAvsInfo = cloneDeep(this.state.avsInfo);
		this.updateField(newAvsInfo, changedItem.key, changedItem.value);

		this.setState({ avsInfo: newAvsInfo }, this.checkFormValidity);
	};

	onTransactionDetailsChange = changedItem => {
		const newTransactionDetails = cloneDeep(this.state.transactionDetails);
		this.updateField(newTransactionDetails, changedItem.key, changedItem.value);

		this.setState({ transactionDetails: newTransactionDetails }, this.checkFormValidity);
	};

	onCustomFieldsChange = changedItem => {
		const newCustom = cloneDeep(this.state.customFields);
		this.updateField(newCustom, changedItem.key, changedItem.value);

		this.setState({ customFields: newCustom }, this.checkFormValidity);
	};

	onExpandShrink = elem => {
		const { expand } = this.state;

		let newExpand = { ...expand };

		newExpand[elem] = !expand[elem];

		this.setState({
			expand: newExpand,
		});
	};

	onExpandShrinkAll = (event, value) => {
		const { expand } = this.state;

		let newExpand = { ...expand };

		Object.keys(newExpand).forEach(k => {
			newExpand[k] = value;
		});

		this.setState({
			expand: newExpand,
		});
	};

	updateField = (obj, key, value) => {
		if (isObject(obj[key])) {
			obj[key].value = value;
			obj[key].dirty = true;
		} else {
			obj[key] = value;
		}
	};

	onCreditCardFieldsChange = e => {
		this.onCreditCardChange({
			key: e.target.name,
			value: e.target.value,
		});
	};

	toggleShowDetails = () => {
		const { showPopupDetails } = this.state;
		this.setState({ showPopupDetails: !showPopupDetails });
	};

	useDifferentPaymentMethod = e => {
		e.preventDefault();
		const {
			existingTransaction: { xExp },
			existingTransaction,
		} = this.props;
		const { differentPayment, requiredFields } = this.state;
		const newState = {
			differentPayment: !differentPayment,
		};
		let cvvValid = false;
		if (existingTransaction) {
			cvvValid = differentPayment
				? !requiredFields.cvvAllTransactionsRequired
				: !requiredFields.cvvAllTransactionsRequired && !requiredFields.cvvNewTransactionsOnlyRequired;
		} else {
			cvvValid = !requiredFields.cvvAllTransactionsRequired || !requiredFields.cvvNewTransactionsOnlyRequired;
		}
		if (this.state.paymentMethod === paymentMethod.CC) {
			const cc = this.state.creditCard;
			newState.creditCard = {
				...cc,
				ccValid: {
					...cc.ccValid,
					value: false,
				},
				cvvValid: {
					...cc.cvvValid,
					value: cvvValid,
				},
				isSwipe: {
					...cc.isSwipe,
					value: false,
				},
				cardNumberIsEmpty: true,
				cvvIsEmpty: true,
				expiry: {
					...cc.expiry,
					value: differentPayment ? xExp : '',
				},
				transactionType: transactionType.SALE,
			};
		}

		this.setState(newState, this.checkFormValidity);
	};

	expandView = () => {
		this.setState({
			expandView: !this.state.expandView,
		});
	};
	invalidCardNum = creditCardValid => {
		this.setState({
			creditCardValid,
		});
		if (!creditCardValid) {
			this.checkFormValidity();
		}
	};

	getAmountClassName = (amount, invalidClassName) => {
		return `input newtransaction__amount__input ${this.includeErrorClassName && 'type--color--warning'} ${(!isValid(
			amount
		) &&
			invalidClassName) ||
			''} ${amount.value.length > 6 ? 'is-reduced' : ''}`;
	};

	validateAvsInfo = (avsInfo, errors, customDisplayLabels) => {
		each(avsInfo, (item, key) => {
			const formattedKey = key === 'address' ? 'avsStreet' : 'avsZip';
			if (!isValid(item)) {
				errors.avsInfo = errors.avsInfo || {};
				errors.avsInfo[formattedKey] = `AVS ${customDisplayLabels[formattedKey] ||
					replace(startCase(key), /\s/g, '')} is required`;
			}
		});
	};

	setTransactionTypeErrors = (errors, param) => {
		errors[param] = errors[param] || {};
	};
	handleCreditCardValidation = (data, errors, customDisplayLabels) => {
		const { cvvIsEmpty, cvv, cardNumberIsEmpty, ccValid, cvvValid, expiry, authCode, voucherSerial } = data.creditCard;
		if (!isValid(cvv)) {
			this.setTransactionTypeErrors(errors, 'creditCard');
			errors.creditCard.cvv = `${customDisplayLabels.cvv || 'CVV'} is required`;
		}
		if (!isValid(ccValid) && !this.state.creditCardValid) {
			this.setTransactionTypeErrors(errors, 'creditCard');
			const label = customDisplayLabels.cardNumber || 'Card Number';
			errors.creditCard.ccValid = cardNumberIsEmpty ? `${label} is required` : `A valid ${label} is required`;
		}
		if (!isValid(cvvValid)) {
			this.setTransactionTypeErrors(errors, 'creditCard');
			errors.creditCard.cvvValid = `${customDisplayLabels.cvv || 'CVV'} is ${cvvIsEmpty ? 'required' : 'not valid'}`;
		}
		if (!isValid(expiry)) {
			this.setTransactionTypeErrors(errors, 'creditCard');
			errors.creditCard.expiry = `${customDisplayLabels.validUntil || 'Exp Date'} is ${
				expiry.value ? 'not valid' : 'required'
			}`;
		}
		if (!isValid(authCode)) {
			this.setTransactionTypeErrors(errors, 'creditCard');
			errors.creditCard.authCode = 'Auth Code is required';
		}
		if (!isValid(voucherSerial)) {
			this.setTransactionTypeErrors(errors, 'creditCard');
			errors.creditCard.voucherSerial = 'Voucher Serial Number is required';
		}
	};
	handleCheckValidation = (data, errors) => {
		const { achIsEmpty, accountName, achValid, routingNumber } = data.check;
		if (!isValid(accountName)) {
			this.setTransactionTypeErrors(errors, 'check');
			errors.check.accountName = 'Account name is required';
		}
		if (!isValid(achValid)) {
			this.setTransactionTypeErrors(errors, 'check');
			errors.check.achValid = achIsEmpty ? 'Account number is required' : 'A valid Account number is required';
		}
		if (!isValid(routingNumber)) {
			this.setTransactionTypeErrors(errors, 'check');

			errors.check.routingNumber = 'Routing number is required';
		}
	};

	validateBillingInfo = (data, errors, customDisplayLabels) => {
		each(data.billingInfo, (item, key) => {
			if (!some(['lastName', 'firstName', 'company', 'phoneNumber'], item => item === key)) {
				if (!isValid(item)) {
					this.setTransactionTypeErrors(errors, 'billingInfo');
					errors.billingInfo[key] = `Billing ${customDisplayLabels[key] ||
						replace(startCase(key), /\s/g, '')} is required`;
				}
			} else {
				if (!isValid(data.billingInfo.phoneNumber)) {
					this.setTransactionTypeErrors(errors, 'billingInfo');
					errors.billingInfo.phoneNumber = `Billing ${customDisplayLabels.phoneNumber || 'Phone Number'} is ${
						data.billingInfo.phoneNumber.value ? 'not valid' : 'required'
					}`;
				}
				if (!isValid(data.shippingInfo.phoneNumber)) {
					errors.shippingInfo = errors.shippingInfo || {};
					errors.shippingInfo.phoneNumber = `Shipping ${customDisplayLabels.phoneNumber || 'Phone Number'} is ${
						data.shippingInfo.phoneNumber.value ? 'not valid' : 'required'
					}`;
				}
			}
		});
	};

	validateTransactionDetails = (data, errors, customDisplayLabels) => {
		each(data.transactionDetails, (item, key) => {
			if (!isValid(item)) {
				errors.transactionDetails = errors.transactionDetails || {};
				let label = replace(startCase(key), /\s/g, '');
				if (key === 'orderId') {
					label = 'Order ID';
				} else if (key === 'poNumber') {
					label = 'PO Number';
				}
				errors.transactionDetails[key] = `${customDisplayLabels[key] || label} is required`;
			}
		});
	};
	validateCustomFields = (data, errors, customDisplayLabels) => {
		each(data.customFields, (item, key) => {
			if (!isValid(item)) {
				errors.customFields = errors.customFields || {};
				const fieldCount = key.match(/(\d+)/);

				const label = !fieldCount
					? key
					: fieldCount[0] > 9
					? key[0].toUpperCase() + key.slice(1)
					: replace(startCase(key), /\s/g, '0');

				errors.customFields[key] = `${customDisplayLabels[key] || label} is required`;
			}
		});
	};
	handleRequiredFieldsValidation = (data, requiredFields, saveAsCustomer, errors, customDisplayLabels) => {
		if (saveAsCustomer) {
			if (!isValid(data.billingInfo.lastName)) {
				this.setTransactionTypeErrors(errors, 'billingInfo');
				errors.billingInfo.lastName = `${customDisplayLabels.company || 'Company'}, ${customDisplayLabels.lastName ||
					'last name'} or ${customDisplayLabels.firstName || 'first name'} is required`;
			}
		} else {
			if (!isValid(data.billingInfo.lastName)) {
				this.setTransactionTypeErrors(errors, 'billingInfo');
				errors.billingInfo.lastName = `Billing ${customDisplayLabels.lastName || 'Last Name'} is required`;
			}
			if (!isValid(data.billingInfo.firstName)) {
				this.setTransactionTypeErrors(errors, 'billingInfo');
				errors.billingInfo.firstName = `Billing ${customDisplayLabels.firstName || 'First Name'} is required`;
			}
			if (!isValid(data.billingInfo.company)) {
				this.setTransactionTypeErrors(errors, 'billingInfo');
				errors.billingInfo.company = `Billing ${customDisplayLabels.company || 'Company'} is required`;
			}
		}

		this.validateAvsInfo(data.avsInfo, errors, customDisplayLabels, requiredFields);
		this.validateBillingInfo(data, errors, customDisplayLabels);
		this.validateTransactionDetails(data, errors, customDisplayLabels);
		this.validateCustomFields(data, errors, customDisplayLabels);
	};
	checkFormValidity = () => {
		const data = this.state;
		const { requiredFields, saveAsCustomer, customDisplayLabels } = data;

		let errors = {};

		if (!isValid(data.amount)) {
			errors.amount = `A valid ${customDisplayLabels.amount || 'amount'} is required`;
		}
		if (!isValid(data.email)) {
			errors.email = `${customDisplayLabels.email || 'Email'} is ${data.email.value ? 'not valid' : 'required'}`;
		}
		if (data.creditCard) {
			this.handleCreditCardValidation(data, errors, customDisplayLabels);
		}
		if (data.check) {
			this.handleCheckValidation(data, errors);
		}

		if (requiredFields) {
			this.handleRequiredFieldsValidation(data, requiredFields, saveAsCustomer, errors, customDisplayLabels);
		}

		const state = {
			errors: errors,
		};
		state.expand = {
			...this.state.expand,
		};
		if (!isEmpty(errors.billingInfo)) {
			state.expand.billing = true;
		}
		if (!isEmpty(errors.transactionDetails)) {
			state.expand.transaction = true;
		}
		if (!isEmpty(errors.customFields)) {
			state.expand.custom = true;
		}
		if (!isEmpty(errors.avsInfo)) {
			state.expand.avs = true;
		}
		this.setState(state);

		return isEmpty(errors);
	};

	getRecurringNotSupportedFields = () => {
		const {
			customDisplayLabels,
			includeConvenience,
			includeSalesTax,
			transactionDetails: {
				poNumber: { value: poNumber },
				orderId: { value: orderId },
			},
		} = this.state;
		const fields = [];

		if (includeConvenience) {
			fields.push('Electronic Transfer Fee');
		}
		if (includeSalesTax) {
			fields.push('Sales Tax');
		}
		if (poNumber) {
			fields.push(customDisplayLabels.poNumber || 'PO Number');
		}
		if (orderId) {
			fields.push(customDisplayLabels.orderId || 'Order ID');
		}

		return join(fields);
	};

	renderRecurringNotSupportedFields = () => {
		const fields = this.getRecurringNotSupportedFields();

		return (
			fields && (
				<div className="message message--default spc--bottom--sml fullwidth type--wgt--medium">
					<p>These fields are not supported for a Scheduled Payment:</p>
					<p>{fields}.</p>
				</div>
			)
		);
	};

	renderProcessLaterPopup = () => {
		const {
			processLater: { startDate, scheduleName },
			isProcessing,
		} = this.state;
		const disabled =
			isProcessing ||
			!startDate ||
			(startDate !== '' &&
				(!moment(startDate, displayDateFormat, true).isValid() ||
					!moment(startDate, displayDateFormat, true).isAfter(moment())));

		return (
			<Modal
				onClose={this.closeProcessLaterPopup}
				isOpen={this.state.processLater.isModalOpen}
				shouldCloseOnOverlayClick={false}
			>
				{isProcessing ? (
					this.renderLoader()
				) : (
					<div className="popup__body">
						{this.renderRecurringNotSupportedFields()}
						<input
							type="text"
							id="scheduleName"
							name="scheduleName"
							className="input input--med spc--bottom--sml"
							placeholder="Schedule name"
							value={scheduleName || ''}
							onChange={e => this.onProcessLaterInputsChange(e.target.name, e.target.value)}
							disabled={isProcessing}
						/>
						<div className="filter__date filter__date--fullwidth spc--bottom--sml">
							{
								<Menu mode={'horizontal'} openAnimation={'slide-up'} triggerSubMenuAction={'click'}>
									<SubMenu
										className="customer__datepicker"
										disabled={isProcessing}
										mode={'vertical-right'}
										key={'custom'}
										title={
											<NumberFormat
												value={startDate || '-'}
												format="##/##/####"
												className="input"
												placeholder={displayDateFormat}
												mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
												onValueChange={e => this.handleDateChange('startDate', this.startDateRef, e)}
												disabled={false}
											/>
										}
									>
										<MenuItem disabled>
											<SingleDatePicker
												isFilter={false}
												date={moment(startDate, displayDateFormat)}
												onChange={value => this.onProcessLaterInputsChange('startDate', value)}
												disabledDays={{
													before: moment()
														.add(1, 'days')
														.toDate(),
												}}
												type="startDate"
												syncInputWithDate={this.syncInputWithDate}
												ref={this.startDateRef}
												customerError={
													startDate !== '' && !moment(startDate, displayDateFormat, true).isValid()
														? 'Invalid date'
														: ''
												}
											/>
										</MenuItem>
									</SubMenu>
								</Menu>
							}
						</div>
						<button
							type="button"
							className="btn btn--primary btn--med popup__footer__process"
							onClick={this.processPaymentLater}
							disabled={disabled}
						>
							<div className="display--ib type--ellipsis">Schedule Payment</div>
						</button>
					</div>
				)}
			</Modal>
		);
	};
	renderCustomFields = () => {
		const {
			customerId,
			amount,
			customFields,
			expand,
			requiredFields,
			customDisplayLabels,
			transactionHiddenFields,
			saveAsCustomer,
			convenienceFees,
			convenienceFee,
			salesTax,
			includeConvenience,
			includeSalesTax,
		} = this.state;
		const { invalidClassName, hideCustomFields } = this.formRenderingParams;
		return hideCustomFields ? null : (
			<li className="newtransaction__accordion__item">
				<a
					className="anchor anchor--primary newtransaction__accordion__link"
					onClick={() => this.onExpandShrink('custom')}
				>
					{expand.custom ? '-' : '+'} Custom fields
				</a>
				{expand.custom && (
					<div className="newtransaction__accordion__content">
						<CustomFields
							numberOfCustomFields={19}
							data={customFields}
							onChange={this.onCustomFieldsChange}
							invalidClassName={invalidClassName}
							customerId={customerId}
							requiredFields={requiredFields}
							customDisplayLabels={customDisplayLabels}
							transactionHiddenFields={transactionHiddenFields}
							saveAsCustomer={saveAsCustomer}
							convenienceFees={convenienceFees}
							originalAmount={amount.value}
							convenienceFee={convenienceFee}
							salesTax={salesTax}
							includeSalesTax={includeSalesTax}
							includeConvenience={includeConvenience}
						/>
					</div>
				)}
			</li>
		);
	};

	renderBillingInfo = (disabled, disableOnChange) => {
		const { expand, sameAsBilling } = this.state;
		const { hideBillingInfoFields, hideAvsFields } = this.formRenderingParams;
		return (
			<Fragment>
				{!hideBillingInfoFields && (
					<Fragment>
						<li className="newtransaction__accordion__item">
							<a
								className="anchor anchor--primary newtransaction__accordion__link"
								onClick={() => this.onExpandShrink('billing')}
							>
								{expand.billing ? '-' : '+'} Billing information
							</a>
							{expand.billing && (
								<div className="newtransaction__accordion__content">
									{this.renderCustomerInfoFields('billing', disabled, disableOnChange)}
								</div>
							)}
						</li>
						{this.renderShippingInfoFields(sameAsBilling, expand)}
					</Fragment>
				)}
				{!hideAvsFields && this.renderAvsInfoFields(sameAsBilling, expand)}
			</Fragment>
		);
	};
	renderTransactionDetails = () => {
		const {
			customerId,
			customDisplayLabels,
			transactionDetails,
			expand,
			requiredFields,
			transactionHiddenFields,
		} = this.state;

		const { invalidClassName, hideTransactionDetails } = this.formRenderingParams;

		return hideTransactionDetails ? null : (
			<li className="newtransaction__accordion__item">
				<a
					className="anchor anchor--primary newtransaction__accordion__link"
					onClick={() => this.onExpandShrink('transaction')}
				>
					{expand.transaction ? '-' : '+'} Transaction details
				</a>
				{expand.transaction && (
					<div className="newtransaction__accordion__content">
						<TransactionDetailsFields
							data={transactionDetails}
							onChange={this.onTransactionDetailsChange}
							invalidClassName={invalidClassName}
							requiredFields={requiredFields}
							customDisplayLabels={customDisplayLabels}
							transactionHiddenFields={transactionHiddenFields}
							hideOrderId={!!customerId}
						/>
					</div>
				)}
			</li>
		);
	};
	renderShippingInfoFields = (sameAsBilling, expand) => {
		const { hideShippingInfoFields } = this.formRenderingParams;
		return (
			!hideShippingInfoFields && (
				<li className="newtransaction__accordion__item">
					<div className="spc--top--sml">
						<input
							type="checkbox"
							name="sameAsBilling"
							id="sameAsBilling"
							className="input input--check"
							value={sameAsBilling}
							checked={sameAsBilling}
							onChange={this.handleChange}
						/>
						<label htmlFor="sameAsBilling">Same as billing?</label>
					</div>
					<a
						className="anchor anchor--primary newtransaction__accordion__link"
						onClick={() => this.onExpandShrink('shipping')}
					>
						{expand.shipping ? '-' : '+'} Shipping info
					</a>
					{expand.shipping && (
						<div className="newtransaction__accordion__content">
							{this.renderCustomerInfoFields('shipping', sameAsBilling, null, sameAsBilling)}
						</div>
					)}
				</li>
			)
		);
	};

	renderAvsInfoFields = () => {
		const { expand } = this.state;
		const hideAvsFields = this.formRenderingParams.hideAvsFields;

		if (hideAvsFields) return;
		return (
			<li className="newtransaction__accordion__item">
				<a
					className="anchor anchor--primary newtransaction__accordion__link"
					onClick={() => this.onExpandShrink('avs')}
				>
					{expand.avs ? '-' : '+'} AVS Information
				</a>
				{expand.avs && <div className="newtransaction__accordion__content">{this.renderAvsFields()}</div>}
			</li>
		);
	};
	renderOption = props => <components.Option {...props}>{this.renderPaymentMethod(props)}</components.Option>;

	renderSelected = props => (
		<components.SingleValue {...props}>{this.renderPaymentMethod(props)}</components.SingleValue>
	);
	renderPaymentMethodSelect = (customerId, selectedCustomerPaymentMethod, paymentMethodOptions) => {
		return (
			customerId && (
				<Fragment>
					{this.isAchDisabled ? null : (
						<div className="newtransaction--expanded--inner">
							<div className="newtransaction__item newtransaction__item--expandable newtransaction__item--expandable--alt">
								<label className="newtransaction__item__label newtransaction__item__label--expandable">
									{this.state.paymentMethod === paymentMethod.CC ? 'Credit Card' : 'Check'}
								</label>
								<div className="newtransaction__item__value newtransaction__item__value--expandable">
									<Select
										value={selectedCustomerPaymentMethod}
										options={paymentMethodOptions}
										components={{
											Option: this.renderOption,
											SingleValue: this.renderSelected,
										}}
										onChange={this.handleCustomerPaymentMethodChange}
										isClearable={false}
										isMulti={false}
										className="react-select-container"
										classNamePrefix="react-select"
									/>
								</div>
							</div>
						</div>
					)}
				</Fragment>
			)
		);
	};

	renderPaymentMethod = ({ data }) => {
		if (toLower(data.xTokenType) === this.state.paymentMethod) {
			return data.newMethod ? (
				<span>New {toLower(data.xTokenType) === paymentMethod.CC ? 'Card' : 'Check'}</span>
			) : (
				<Fragment>
					<img src={CardTypeImagePath.getPath(data.xCardType)} className="grid__creditcard" />
					<span>
						{data.xTokenAlias || data.xName} {data.xMaskedNumber.slice(-4)}
						{data.xIsDefaultPaymentMethod ? ' (default)' : null}
					</span>
				</Fragment>
			);
		}
		return null;
	};

	renderLoader = () => (
		<div className="loader__holder">
			<div className="loader__spinner"></div>
		</div>
	);

	renderBillingAndShippingInfo = (className = 'newtransaction__group') => {
		const { sameAsBilling, customerId } = this.state;
		const { hideShippingInfoFields, hideBillingInfoFields, hideAvsFields } = this.formRenderingParams;

		return (
			<Fragment>
				{!hideBillingInfoFields && (
					<li>
						<div className="newtransaction__item__title">Billing Information</div>
						<div className={className}>{this.renderCustomerInfoFields('billing', !!customerId)}</div>
						{!hideShippingInfoFields && (
							<Fragment>
								<div className="newtransaction__item__title">Shipping Information</div>
								<div className={className}>
									<div className="newtransaction__item display--ib">
										<input
											type="checkbox"
											name="sameAsBilling"
											id="sameAsBilling"
											className="input input--check"
											value={!sameAsBilling}
											checked={sameAsBilling}
											onChange={this.handleChange}
										/>
										<label htmlFor="sameAsBilling">Same as billing?</label>
									</div>
									{!sameAsBilling && this.renderCustomerInfoFields('shipping')}
								</div>
							</Fragment>
						)}
					</li>
				)}
				{!hideAvsFields && <li> {this.renderAvsFields(true)}</li>}
			</Fragment>
		);
	};

	renderTransactionTypes = (outerClassName, className, creditCard, required) => {
		const {
			permissions: {
				allowCcSale,
				allowCcSave,
				allowGiftIssue,
				allowGiftRedeem,
				allowCcAuthOnly,
				allowCcCredit,
				allowCcPostAuth,
				allowEbtfsVoucher,
			},
			showEbtfsVoucherOption,
		} = this.state;
		const { isEbtfs, isGift } = this.formRenderingParams;
		return (
			<div className={outerClassName}>
				<div className={className}>
					<label className="newtransaction__item__label">Transaction type {required}</label>
					<div className="newtransaction__item__value newtransaction__item__value--expandable">
						<select
							name="transactionType"
							className="input input--med input--select"
							value={creditCard.transactionType}
							onChange={this.onCreditCardFieldsChange}
							tabIndex="-1"
						>
							{allowCcSale ? <option value={transactionType.SALE}>Charge</option> : null}
							{allowCcSave ? <option value={transactionType.SAVE}>Save</option> : null}
							{allowCcAuthOnly ? <option value={transactionType.AUTH_ONLY}>Auth Only</option> : null}
							{allowCcCredit ? <option value={transactionType.CREDIT}>Refund</option> : null}
							{allowCcPostAuth ? <option value={transactionType.POST_AUTH}>Post Auth</option> : null}
							{allowEbtfsVoucher && showEbtfsVoucherOption && isEbtfs ? (
								<option value={transactionType.EBTFS_VOUCHER}>EBTFS Voucher</option>
							) : null}
							{isGift && (
								<Fragment>
									{allowGiftIssue ? <option value={transactionType.GIFT_ISSUE}>Gift: Issue</option> : null}
									{allowGiftRedeem ? <option value={transactionType.GIFT_REDEEM}>Gift: Redeem</option> : null}
								</Fragment>
							)}
						</select>
					</div>
				</div>
			</div>
		);
	};
	renderSmsReceipt = (sendSmsReceipt, allowSendSms) => {
		return (
			allowSendSms &&
			this.showSendSms && (
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="sendSmsReceipt"
						id="sendSmsReceipt"
						className="input input--check input--check--sml"
						value={sendSmsReceipt}
						onChange={this.handleChange}
						checked={sendSmsReceipt}
					/>
					<label htmlFor="sendSmsReceipt" className="datatooltip--w--200 datatooltip--top-left">
						Send SMS receipt{' '}
						<i
							data-tooltip={this.getSmsTooltip()}
							className="icon icon--tiny icon--info align--v--middle spc--left--tny datatooltip--pre-wrap"
						></i>
					</label>
				</div>
			)
		);
	};
	renderCreditCardInputs = () => {
		const {
			sendReceipt,
			sendSmsReceipt,
			sendCopy,
			permissions: { allowSendSms },
		} = this.state;
		return (
			((this.isAchEnabled || this.state.paymentMethod === paymentMethod.CC) && (
				<Fragment>
					{this.renderTransactionEmailField()}
					<div className="flex flex--primary newtransaction__item no-bottom">
						<div className="spc--right--med spc--bottom--sml">
							<input
								type="checkbox"
								name="sendReceipt"
								id="sendReceipt"
								className="input input--check input--check--sml"
								value={sendReceipt}
								onChange={this.handleChange}
								checked={sendReceipt}
							/>
							<label htmlFor="sendReceipt">Send Email Receipt</label>
						</div>
						<div className="spc--right--med spc--bottom--sml">
							<input
								type="checkbox"
								name="sendCopy"
								id="sendCopy"
								className="input input--check input--check--sml"
								value={sendCopy}
								onChange={this.handleChange}
								checked={sendCopy}
							/>
							<label htmlFor="sendCopy" className="type--none">
								Send me a copy
							</label>
						</div>
						{this.renderSmsReceipt(sendSmsReceipt, allowSendSms)}
					</div>
				</Fragment>
			)) ||
			null
		);
	};

	renderVoucherIputs = (creditCard, invalidClassName, optionWrapperClassName, required) => {
		return (
			(creditCard && creditCard.transactionType === transactionType.EBTFS_VOUCHER && (
				<div className={optionWrapperClassName}>
					<div className="newtransaction__item newtransaction__item--expandable spc--top--med no-bottom">
						<label className="newtransaction__item__label newtransaction__item__label--expandable">
							Authorization Code {required}
						</label>
						<div className="newtransaction__item__value newtransaction__item__value--expandable">
							<input
								type="text"
								name="authCode"
								className={`input input--med ${(!isValid(creditCard.authCode) && invalidClassName) || ''}`}
								placeholder="Authorization Code"
								value={creditCard.authCode.value}
								onChange={this.onChange}
							></input>
						</div>
					</div>
					<div className="newtransaction__item newtransaction__item--expandable no-bottom">
						<label className="newtransaction__item__label newtransaction__item__label--expandable">
							Voucher Serial Number {required}
						</label>
						<div className="newtransaction__item__value newtransaction__item__value--expandable">
							<NumberFormat
								name="voucherSerial"
								value={creditCard.voucherSerial.value}
								placeholder="Voucher Serial Number"
								className={`input input--med ${(!isValid(creditCard.voucherSerial) && invalidClassName) || ''}`}
								onValueChange={({ value }) => this.onCreditCardChange({ key: 'voucherSerial', value })}
							/>
						</div>
					</div>
				</div>
			)) ||
			null
		);
	};

	renderCreditCardFields = ({
		wrapperClassName,
		outerClassName,
		className,
		optionWrapperClassName,
		billingShippingClassName,
	}) => {
		const { requiredFields, customDisplayLabels } = this.state;
		const { required, invalidClassName, creditCard, hideAll } = this.formRenderingParams;
		return (
			<Fragment>
				<div className="newtransaction--expanded--inner">
					{this.state.paymentMethod === paymentMethod.CC ? (
						<div className={wrapperClassName}>
							{this.renderTransactionTypes(outerClassName, className, creditCard, required)}
							{creditCard && creditCard.transactionType === transactionType.POST_AUTH && (
								<React.Fragment>
									<div className={optionWrapperClassName}>
										<div className="newtransaction__item">
											<label className="newtransaction__item__label newtransaction__item__label--expandable">
												Auth Code {required}
											</label>
											<div className="newtransaction__item__value newtransaction__item__value--expandable">
												<input
													type="text"
													name="authCode"
													className={`input input--med ${(!isValid(creditCard.authCode) && invalidClassName) || ''}`}
													placeholder="Auth Code"
													value={creditCard.authCode.value}
													onChange={this.onCreditCardFieldsChange}
												/>
											</div>
										</div>
									</div>
									<div className="f-col f-col-sml-12 newtransaction__item">
										<div className="message message--primary display--b">
											Please only use the auth code that was provided by your processing bank.
										</div>
									</div>
								</React.Fragment>
							)}
							{this.renderVoucherIputs(creditCard, invalidClassName, optionWrapperClassName, required)}
							{creditCard && !startsWith(creditCard.transactionType, 'gift') && (
								<div className="f-col f-col-sml-12 f-col-lrg-6 newtransaction__item">
									<label className="newtransaction__item__label newtransaction__item__label--expandable newtransaction__item__label--alt newtransaction__item__label--expandable--alt">
										{customDisplayLabels.cvv || 'CVV'} {requiredFields.cvvAllTransactionsRequired && required}
									</label>
									<NumberFormat
										value={creditCard.cvv.value}
										format="####"
										className={`input input--med ${(!isValid(creditCard.cvv) && invalidClassName) || ''}`}
										placeholder="XXX"
										onValueChange={({ value }) => this.onCreditCardChange({ key: 'cvv', value })}
										inputMode="numeric"
									/>
								</div>
							)}
						</div>
					) : null}
					{this.renderCreditCardInputs()}
				</div>
				{!this.isExpanded && !hideAll && (
					<ul className={billingShippingClassName}>
						{this.renderBillingInfo(true, true)}
						{this.renderTransactionDetails()}
						{this.renderCustomFields()}
					</ul>
				)}
			</Fragment>
		);
	};
	renderTransactionEmailField = () => {
		const { email, sendReceipt, requiredFields, customDisplayLabels, transactionHiddenFields } = this.state;
		const { required, invalidClassName } = this.formRenderingParams;

		return transactionHiddenFields.email && !sendReceipt ? null : (
			<div className="newtransaction__item newtransaction__item--expandable newtransaction__item--expandable--alt">
				<label className="newtransaction__item__label newtransaction__item__label--expandable">
					{customDisplayLabels.email || 'Email'} {requiredFields.email || sendReceipt ? required : null}
				</label>
				<div className="newtransaction__item__value newtransaction__item__value--expandable">
					<input
						type="text"
						name="email"
						className={`input input--med ${(!isValid(email) && invalidClassName) || ''}`}
						placeholder={customDisplayLabels.email || 'user@email.com'}
						value={email.value}
						onChange={this.handleChange}
						inputMode="email"
					/>
				</div>
			</div>
		);
	};
	renderReceiptFields = () => {
		const {
			sendReceipt,
			sendSmsReceipt,
			sendCopy,
			permissions: { allowSendSms },
		} = this.state;
		return (
			((this.isAchEnabled || this.state.paymentMethod === paymentMethod.CC) && (
				<Fragment>
					{this.renderTransactionEmailField()}
					<div className="flex flex--primary newtransaction__item no-bottom">
						<div className="spc--right--med spc--bottom--sml">
							<input
								type="checkbox"
								name="sendCopy"
								id="sendCopy"
								className="input input--check input--check--sml"
								value={sendCopy}
								onChange={this.handleChange}
								checked={sendCopy}
							/>
							<label htmlFor="sendCopy" className="type--none">
								Send me a copy
							</label>
						</div>
						<div className="spc--right--med spc--bottom--sml">
							<input
								type="checkbox"
								name="sendReceipt"
								id="sendReceipt"
								className="input input--check input--check--sml"
								value={sendReceipt}
								onChange={this.handleChange}
								checked={sendReceipt}
							/>
							<label htmlFor="sendReceipt">Send Email Receipt</label>
						</div>
						{this.renderSmsReceipt(sendSmsReceipt, allowSendSms)}
					</div>
				</Fragment>
			)) ||
			null
		);
	};

	renderCardholderInfo = () => {
		const { setAsDefault, saveToFile } = this.state;
		const { invalidClassName, creditCard, hideAll } = this.formRenderingParams;
		return (
			<div className="newtransaction--expanded--inner">
				{this.renderReceiptFields()}
				{this.isAchDisabled ? null : (
					<div className="newtransaction__chboptions">
						{creditCard && startsWith(toLower(creditCard.transactionType), transactionType.EBTFS_VOUCHER) ? null : (
							<div className="newtransaction__chboptions__item newtransaction__chboptions__item--primary spc--bottom--sml">
								<div className="">
									<input
										type="checkbox"
										name="saveToFile"
										id="saveToFile"
										className="input input--check"
										value={saveToFile}
										onChange={this.handleChange}
										checked={saveToFile}
									/>
									<label htmlFor="saveToFile" className="type--none">
										Save payment method
									</label>
								</div>
							</div>
						)}
						{saveToFile && (
							<div className="newtransaction__chboptions__item newtransaction__chboptions__item--secondary">
								<div className="">
									<input
										type="checkbox"
										name="setAsDefault"
										id="setAsDefault"
										className="input input--check"
										value={setAsDefault}
										onChange={this.handleChange}
										checked={setAsDefault}
									/>
									<label htmlFor="setAsDefault" className="type--none">
										Set as default payment method
									</label>
								</div>
							</div>
						)}
					</div>
				)}
				{this.state.paymentMethod === paymentMethod.CC ? (
					<div className="newtransaction__item newtransaction__item--expandable newtransaction__item--expandable--alt">
						<label className="newtransaction__item__label newtransaction__item__label--expandable">
							Cardholder Name
						</label>
						<div className="newtransaction__item__value newtransaction__item__value--expandable">
							<input
								type="text"
								name="cardholderName"
								className={`input input--med ${(!isValid(creditCard.cardholderName) && invalidClassName) || ''}`}
								placeholder="John Doe"
								value={creditCard.cardholderName.value}
								onChange={this.onCreditCardFieldsChange}
							/>
						</div>
					</div>
				) : null}
				{!this.isExpanded && !hideAll && (
					<ul className="newtransaction__accordion">
						{this.renderBillingInfo(true)}
						{this.renderTransactionDetailsAndCustomFields()}
					</ul>
				)}
			</div>
		);
	};

	renderSelectedCustomerPaymentMethod = (
		wrapperClassName = 'f-row',
		outerClassName = 'f-col f-col-sml-12 f-col-med-6',
		className = 'newtransaction__item',
		optionWrapperClassName = 'f-col f-col-sml-12 f-col-med-6',
		billingShippingClassName = 'newtransaction__accordion'
	) => {
		const {
			customerId,
			selectedCustomerPaymentMethod,
			paymentMethodOptions,
			sendReceipt,
			sendSmsReceipt,
			sendCopy,
			requiredFields,
			customDisplayLabels,
			transactionHiddenFields,
			permissions,
			isCanadian,
			showEbtfsVoucherOption,
			permissions: { allowSendSms },
		} = this.state;
		const { isEbtfs, isGift, invalidClassName, creditCard, check, hideAll } = this.formRenderingParams;
		return (
			<Fragment>
				{this.renderPaymentMethodSelect(customerId, selectedCustomerPaymentMethod, paymentMethodOptions)}
				{selectedCustomerPaymentMethod && !selectedCustomerPaymentMethod.newMethod ? (
					this.renderCreditCardFields({
						wrapperClassName,
						outerClassName,
						className,
						optionWrapperClassName,
						billingShippingClassName,
					})
				) : (
					<Fragment>
						{this.props.existingTransaction != null &&
						this.isNotExistingGrantTransaction &&
						this.existingTransactionPaymentType === this.state.paymentMethod &&
						!this.missingToken ? (
							<Fragment>
								{this.isAchDisabled || isCanadian ? null : (
									<div className="type--center">
										<button
											type="button"
											className={`btn--reset anchor anchor--primary type--wgt--medium${
												this.state.differentPayment ? ' spc--bottom--med' : ''
											}`}
											onClick={this.useDifferentPaymentMethod}
										>
											Use {this.state.differentPayment ? 'the same' : 'different'} payment method
										</button>
									</div>
								)}
							</Fragment>
						) : null}
						{this.state.paymentMethod === paymentMethod.CC ? (
							<Fragment>
								{this.state.differentPayment ? (
									<CreditCardFields
										ref={r => {
											this.ccFields = r;
										}}
										customerId={customerId}
										ccData={creditCard}
										onChange={this.onCreditCardChange}
										invalidClassName={invalidClassName}
										requiredFields={requiredFields}
										customDisplayLabels={customDisplayLabels}
										transactionHiddenFields={transactionHiddenFields}
										permissions={permissions}
										showEbtfsVoucherOption={showEbtfsVoucherOption}
										invalidCardNum={this.invalidCardNum}
									/>
								) : (
									<CreditCardDisplay
										type={this.props.existingTransaction.xCardType}
										exp={creditCard.expiry}
										mask={this.props.existingTransaction.xMaskedCardNumber}
										cvv={creditCard.cvv}
										onChange={this.onCreditCardChange}
										ccData={creditCard}
										invalidClassName={invalidClassName}
										requiredFields={requiredFields}
										customDisplayLabels={customDisplayLabels}
										transactionHiddenFields={transactionHiddenFields}
										permissions={permissions}
										showEbtfsVoucherOption={showEbtfsVoucherOption}
										displayOnlyGiftTypes={startsWith(toLower(this.props.existingTransaction.xCommand), 'gift')}
										isGift={isGift}
										isEbtfs={isEbtfs}
									/>
								)}
							</Fragment>
						) : null}
						{this.isAchEnabled && (
							<Fragment>
								{this.state.differentPayment ? (
									<CheckFields
										ref={r => {
											this.achFields = r;
										}}
										check={check}
										onChange={this.onCheckChange}
										customerId={customerId}
										invalidClassName={invalidClassName}
									/>
								) : (
									<CheckDisplay
										mask={this.props.existingTransaction.xMaskedCardNumber}
										accountName={this.props.existingTransaction.xName}
									/>
								)}
							</Fragment>
						)}
						{customerId ? (
							this.renderCardholderInfo()
						) : (
							<Fragment>
								<div className="newtransaction--expanded--inner">
									{(this.isAchEnabled || this.state.paymentMethod === paymentMethod.CC) && (
										<Fragment>
											{this.renderTransactionEmailField()}
											<div className="flex flex--primary newtransaction__item no-bottom">
												<div className="spc--right--med spc--bottom--sml">
													<input
														type="checkbox"
														name="sendReceipt"
														id="sendReceipt"
														className="input input--check input--check--sml"
														value={sendReceipt}
														onChange={this.handleChange}
														checked={sendReceipt}
													/>
													<label htmlFor="sendReceipt">Send Email Receipt</label>
												</div>
												<div className="spc--right--med spc--bottom--sml">
													<input
														type="checkbox"
														name="sendCopy"
														id="sendCopy"
														className="input input--check input--check--sml"
														value={sendCopy}
														onChange={this.handleChange}
														checked={sendCopy}
													/>
													<label htmlFor="sendCopy" className="type--none">
														Send me a copy
													</label>
												</div>
												{allowSendSms && this.showSendSms && (
													<div className="spc--bottom--sml">
														<input
															type="checkbox"
															name="sendSmsReceipt"
															id="sendSmsReceipt"
															className="input input--check input--check--sml"
															value={sendSmsReceipt}
															onChange={this.handleChange}
															checked={sendSmsReceipt}
														/>
														<label htmlFor="sendSmsReceipt" className="datatooltip--w--200 datatooltip--top-left">
															Send SMS receipt{' '}
															<i
																data-tooltip={this.getSmsTooltip()}
																className="icon icon--tiny icon--info align--v--middle spc--left--tny datatooltip--pre-wrap"
															></i>
														</label>
													</div>
												)}
											</div>
										</Fragment>
									)}
								</div>
								{!this.isExpanded && !hideAll && (
									<Fragment>
										<div className="group">
											<h6 className="spc--bottom--tny type--base type--color--text--regular type--wgt--regular pull">
												General Information
											</h6>
											{!this.isExpanded && (
												<Fragment>
													{this.isAchDisabled ? null : (
														<div className="spc--top--tny push">
															{!hideAll && (
																<Fragment>
																	{areAllPropsTrue(this.visibleExpansions) ? (
																		<button
																			type="button"
																			className="btn--reset anchor anchor--primary type--xsml"
																			onClick={e => this.onExpandShrinkAll(e, false)}
																		>
																			- Collapse All
																		</button>
																	) : (
																		<button
																			type="button"
																			className="btn--reset anchor anchor--primary type--xsml"
																			onClick={e => this.onExpandShrinkAll(e, true)}
																		>
																			+ Expand All
																		</button>
																	)}
																</Fragment>
															)}
														</div>
													)}
												</Fragment>
											)}
										</div>
										<ul className="newtransaction__accordion">
											{this.renderBillingInfo()}
											{this.renderTransactionDetailsAndCustomFields()}
										</ul>
									</Fragment>
								)}
							</Fragment>
						)}
					</Fragment>
				)}
			</Fragment>
		);
	};
	renderTransactionDetailsAndCustomFields() {
		return (
			<Fragment>
				{this.renderTransactionDetails()}
				{this.renderCustomFields()}
			</Fragment>
		);
	}
	enableSaveAsCustomer = () => {
		const newState = { isOpenSaveAsCustomer: false };
		newState.saveAsCustomer = !this.state.saveAsCustomer;
		newState.customFields = {
			...this.state.customFields,
			custom1: {
				...this.state.customFields.custom1,
				value: '',
			},
		};
		this.setState(newState);
	};
	onSaveAsCustomerChange = () => {
		const {
			customFields: { custom1 },
		} = this.state;
		if (custom1 && custom1.value) {
			this.setState({ isOpenSaveAsCustomer: true });
		} else {
			this.enableSaveAsCustomer();
		}
	};

	closeSaveAsCustomerPopup = () => {
		this.setState({ isOpenSaveAsCustomer: false });
	};
	renderSaveAsCustomerPopup = () => {
		const { isOpenSaveAsCustomer } = this.state;
		return (
			<div>
				<Modal
					isOpen={isOpenSaveAsCustomer}
					onClose={this.closeSaveAsCustomerPopup}
					shouldCloseOnOverlayClick={false}
					className="popup__content"
				>
					<div className="popup--customer">
						<div className="popup__body">
							<p className="type--base">
								Custom 01 will be cleared when saving as a Customer, are you sure you want to proceed?
							</p>
						</div>

						<div className="popup__footer">
							<button
								type="button"
								tabIndex="-1"
								className="btn btn--sml btn--ghost spc--right--xsml"
								onClick={this.enableSaveAsCustomer}
							>
								Yes
							</button>
							<button
								type="button"
								tabIndex="-1"
								className="btn btn--sml btn--primary"
								onClick={this.closeSaveAsCustomerPopup}
							>
								No
							</button>
						</div>
					</div>
				</Modal>
			</div>
		);
	};
	renderCustomerInfoFields = (type, disabled = false, disableOnChange, sameAsBilling) => {
		const { invalidClassName } = this.formRenderingParams;
		const {
			requiredFields,
			customDisplayLabels,
			transactionHiddenFields,
			saveAsCustomer,
			isCanadianCurrency,
			sendSmsReceipt,
		} = this.state;
		return (
			<CustomerInfoFields
				data={this.getCustomerInfoData(sameAsBilling, type)}
				onChange={disableOnChange ? undefined : type === 'billing' ? this.onBillingChange : this.onShippingChange}
				dataType={type}
				invalidClassName={invalidClassName}
				requiredFields={requiredFields}
				customDisplayLabels={customDisplayLabels}
				transactionHiddenFields={transactionHiddenFields}
				disabled={disabled}
				saveAsCustomer={type === 'billing' ? saveAsCustomer : undefined}
				isCanadianCurrency={isCanadianCurrency}
				sendSmsReceipt={sendSmsReceipt && this.showSendSms}
			/>
		);
	};
	renderAvsFields = isLrg => {
		const { avsInfo, isCanadian, customDisplayLabels, transactionHiddenFields, requiredFields } = this.state;
		const { invalidClassName } = this.formRenderingParams;
		const zLabel = isCanadian ? 'AVS Postal Code' : 'AVS ZIP';
		const streetLabel = customDisplayLabels.avsStreet || 'AVS Street';
		const required = (
			<span className="required-field label--required" data-tooltip="Required">
				*
			</span>
		);
		return (
			<Fragment>
				{isLrg && <div className="newtransaction__item__title">AVS Information</div>}
				<div className="clearfix">
					{!transactionHiddenFields.avsStreet && (
						<div className="newtransaction__item newtransaction__item--expandable">
							<label className="newtransaction__item__label newtransaction__item__label--expandable">
								{streetLabel}
								{requiredFields.avsStreet ? required : null}
							</label>
							<div className="newtransaction__item__value newtransaction__item__value--expandable">
								<input
									type="text"
									name="address"
									className={`input input--med ${(!isValid(avsInfo.address) && invalidClassName) || ''}`}
									placeholder={streetLabel}
									value={avsInfo.address.value}
									onChange={this.onAvsInfoChange}
								/>
							</div>
						</div>
					)}
					{!transactionHiddenFields.avsZip && (
						<div className="newtransaction__item newtransaction__item--expandable">
							<label className="newtransaction__item__label newtransaction__item__label--expandable">
								{customDisplayLabels.avsZip || zLabel}
								{requiredFields.avsZip ? required : null}
							</label>

							<div className="newtransaction__item__value newtransaction__item__value--expandable">
								<input
									type="text"
									name="zip"
									className={`input input--med ${(!isValid(avsInfo.zip) && invalidClassName) || ''}`}
									placeholder={customDisplayLabels.avsZip || zLabel}
									value={avsInfo.zip.value}
									onChange={this.onAvsInfoChange}
									inputMode="numeric"
								/>
							</div>
						</div>
					)}
				</div>
			</Fragment>
		);
	};

	renderTransactionTabs = () => {
		const {
			isCanadian,
			permissions: {
				allowCcSale,
				allowCcSave,
				allowCcAuthOnly,
				allowCcCredit,
				allowCcPostAuth,
				allowCheckSale,
				allowGiftIssue,
				allowGiftRedeem,
				allowEbtfsVoucher,
			},
		} = this.state;

		return (
			<ul className="newtransaction__tabs">
				{(allowCcAuthOnly ||
					allowCcSale ||
					allowCcSave ||
					allowCcCredit ||
					allowCcPostAuth ||
					allowGiftIssue ||
					allowGiftRedeem ||
					allowEbtfsVoucher) && (
					<li className="newtransaction__tabs__item">
						<button
							type="button"
							className={
								'newtransaction__tabs__link ' + (this.state.paymentMethod === paymentMethod.CC ? 'is-active' : '')
							}
							onClick={() => this.switchPaymentMethodType(paymentMethod.CC)}
							tabIndex="-1"
						>
							Credit Card
						</button>
					</li>
				)}
				{allowCheckSale && !isCanadian && (
					<li className="newtransaction__tabs__item">
						<button
							type="button"
							className={
								'newtransaction__tabs__link ' + (this.state.paymentMethod === paymentMethod.CHECK ? 'is-active' : '')
							}
							onClick={() => this.switchPaymentMethodType(paymentMethod.CHECK)}
							tabIndex="-1"
						>
							Check
						</button>
					</li>
				)}
			</ul>
		);
	};

	renderTaxAndConvenience = () => {
		const {
			amount,
			convenienceFees,
			convenienceFee,
			salesTax,
			salesTaxAmount,
			includeConvenience,
			includeSalesTax,
		} = this.state;
		const { hasSalesTax, hasConvenienceFee, creditCard } = this.formRenderingParams;

		return (
			<Fragment>
				{(hasConvenienceFee || hasSalesTax) && creditCard && creditCard.transactionType !== transactionType.SAVE && (
					<div className="type--center type--wgt--bold">
						{hasConvenienceFee && (
							<div className="spc--right--sml spc--bottom--sml">
								{convenienceFees.allowExclude && (
									<input
										tabIndex="-1"
										type="checkbox"
										name="includeConvenience"
										id="includeConvenience"
										className="input newtransaction--convenience-check"
										value={includeConvenience}
										checked={includeConvenience}
										onChange={this.handleChange}
									/>
								)}
								<label htmlFor="includeConvenience" className="datatooltip--no-wrap">
									<div
										className="display--ib spc--right--xsml"
										data-tooltip={`${includeConvenience ? 'Exclude' : 'Include'} Electronic Transfer Fee`}
									>
										<span></span>
									</div>
									<span className="spc--right--tny">Electronic Transfer Fee:</span>
									<span className="type--wgt--medium">
										<NumberFormat
											thousandSeparator=","
											decimalSeparator="."
											disabled={true}
											decimalScale={2}
											prefix={this.merchantCurrencyCode}
											value={convenienceFee || 0}
											displayType="text"
										/>
									</span>
								</label>
							</div>
						)}
						{hasSalesTax && (
							<div className="display--f f--a--c f--j--c spc--bottom--sml">
								{salesTax.allowExclude ? (
									<div>
										<input
											tabIndex="-1"
											type="checkbox"
											name="includeSalesTax"
											id="includeSalesTax"
											className="input newtransaction--convenience-check"
											value={includeSalesTax}
											checked={includeSalesTax}
											onChange={this.handleChange}
										/>
										<label htmlFor="includeSalesTax" className="datatooltip--w--200">
											<div
												className="display--ib"
												data-tooltip={`${includeSalesTax ? 'Exclude' : 'Include'} Sales Tax`}
											>
												<span></span>
											</div>
											<span className="spc--right--tny">Sales Tax:</span>
											<i
												data-tooltip={maxAllowedSalesTaxTooltip}
												className="icon icon--nano icon--info align--v--middle spc--right--tny"
												onClick={e => {
													e.preventDefault();
													e.stopPropagation();
												}}
											></i>
											{!salesTax.allowOverride && (
												<span className="type--wgt--medium">
													<NumberFormat
														thousandSeparator=","
														decimalSeparator="."
														disabled={true}
														decimalScale={2}
														prefix={this.merchantCurrencyCode}
														value={salesTaxAmount || 0}
														displayType="text"
													/>
												</span>
											)}
										</label>
									</div>
								) : (
									<span>
										<label htmlFor="includeSalesTax" className="flex--primary datatooltip--w--200">
											<span className="spc--right--tny">Sales Tax:</span>
											<i
												data-tooltip={maxAllowedSalesTaxTooltip}
												className="icon icon--nano icon--info spc--right--tny"
											></i>
											{!salesTax.allowOverride && (
												<span className="type--wgt--medium">
													<NumberFormat
														thousandSeparator=","
														decimalSeparator="."
														disabled={true}
														decimalScale={2}
														prefix={this.merchantCurrencyCode}
														value={salesTaxAmount || 0}
														displayType="text"
													/>
												</span>
											)}
										</label>
									</span>
								)}
								{salesTax.allowOverride && (
									<NumberFormat
										getInputRef={el => {
											this.salesTaxInputRef = el;
										}}
										className="input input--sml w--102p"
										thousandSeparator=","
										decimalSeparator="."
										decimalScale={2}
										prefix={this.merchantCurrencyCode}
										value={salesTaxAmount}
										inputMode="decimal"
										isAllowed={this.checkIfSalesTaxAmountAllowed}
										allowNegative={false}
										onValueChange={this.handleSalesTaxAmountChange}
										disabled={!includeSalesTax || !amount.value}
									/>
								)}
							</div>
						)}
					</div>
				)}
			</Fragment>
		);
	};

	renderForm = () => {
		const {
			amount,
			transactionDetails,
			customFields,
			errors,
			customerId,
			requiredFields,
			isLoading,
			customDisplayLabels,
			transactionHiddenFields,
			saveAsCustomer,
			convenienceFees,
			convenienceFee,
			salesTax,
			includeConvenience,
			includeSalesTax,
			isBlurred,
			displayConvenienceAndSalesFailedToLoad,
		} = this.state;
		const { invalidClassName, creditCard, hideTransactionDetails, hideCustomFields } = this.formRenderingParams;
		const amountPrefix = this.getAmountPrefix();
		return (
			<Fragment>
				{isLoading ? (
					this.renderLoader()
				) : (
					<div className={`newtransaction__container ${this.isExpanded ? 'is-expanded' : ''}`}>
						<span ref={this.pageTopRef}></span>

						<form id="transaction-form" onSubmit={this.debouncedSubmit}>
							<KeyboardEventHandler handleKeys={['enter']} onKeyEvent={() => this.debouncedSubmit()}>
								{!this.isExpanded && !isEmpty(errors) && (
									<div className="spc--bottom--med">
										<FormErrors errors={errors} />
									</div>
								)}
								<div className="newtransaction--expanded">
									{this.isExpanded && (
										<div className="newtransaction--expanded--primary">
											<ul className="newtransaction__group">
												{this.renderBillingAndShippingInfo()}
												{!hideTransactionDetails && (
													<Fragment>
														<li>
															<div className="newtransaction__item__title">Transaction Details</div>
														</li>
														<li>
															<div className="newtransaction__group">
																<TransactionDetailsFields
																	data={transactionDetails}
																	onChange={this.onTransactionDetailsChange}
																	invalidClassName={invalidClassName}
																	requiredFields={requiredFields}
																	customDisplayLabels={customDisplayLabels}
																	transactionHiddenFields={transactionHiddenFields}
																	hideOrderId={!!customerId}
																/>
															</div>
														</li>
													</Fragment>
												)}
												{!hideCustomFields && (
													<Fragment>
														<li>
															<div className="newtransaction__item__title">Custom details</div>
														</li>
														<li>
															<div className="newtransaction__group">
																<CustomFields
																	numberOfCustomFields={19}
																	data={customFields}
																	onChange={this.onCustomFieldsChange}
																	customerId={customerId}
																	invalidClassName={invalidClassName}
																	requiredFields={requiredFields}
																	customDisplayLabels={customDisplayLabels}
																	transactionHiddenFields={transactionHiddenFields}
																	saveAsCustomer={saveAsCustomer}
																	convenienceFees={convenienceFees}
																	originalAmount={amount.value}
																	convenienceFee={convenienceFee}
																	salesTax={salesTax}
																	includeSalesTax={includeSalesTax}
																	includeConvenience={includeConvenience}
																/>
															</div>
														</li>
													</Fragment>
												)}
											</ul>
										</div>
									)}
									<div className="newtransaction--expanded--secondary__wrapper">
										<div className="newtransaction--expanded--secondary">
											<div className="newtransaction__amount">
												<p className="spc--bottom--sml--alt type--base type--color--text--regular datatooltip--h--right">
													{customDisplayLabels.amount || 'Amount'}{' '}
													<span className="required-field label--required" data-tooltip="Required">
														*
													</span>
												</p>
												<NumberFormat
													getInputRef={el => {
														this.amountRef = el;
													}}
													placeholder={`${this.merchantCurrencyCode}0.00`}
													onBlur={this.handleAmountBlur}
													onFocus={this.handleAmountFocus}
													thousandSeparator=","
													decimalSeparator="."
													fixedDecimalScale={this.shouldDecimalScale(amount.value, isBlurred)}
													allowNegative={false}
													decimalScale={2}
													prefix={amountPrefix}
													onValueChange={this.handleAmountChange}
													value={amount.value}
													isAllowed={this.checkIfAmountAllowed}
													disabled={
														creditCard &&
														creditCard.transactionType === transactionType.SAVE &&
														this.state.paymentMethod === paymentMethod.CC
													}
													autoFocus={true}
													inputMode="decimal"
													className={this.getAmountClassName(amount, invalidClassName)}
												/>
												{displayConvenienceAndSalesFailedToLoad && (
													<div className="validation spc--bottom--sml">
														<p className="type--error">
															Failed to load Electronic Transfer Fee and/or tax settings. If you would like to add your
															Electronic Transfer Fee and/or tax settings to your transaction, please refresh this page.
														</p>
													</div>
												)}
												{this.renderTaxAndConvenience()}
											</div>
											{this.renderTransactionTabs()}
											{this.isAchDisabled && (
												<div className="spc--bottom--lrg type--center">
													<i className="icon icon--xlrg icon--ach-disabled" />
													<div className="type--wgt--light type--base">
														Allow your customers to pay directly to your bank account without a credit card.
													</div>
												</div>
											)}
											{this.renderSelectedCustomerPaymentMethod()}
										</div>
										{this.isExpanded && !isEmpty(errors) && (
											<div className="spc--top--sml">
												<FormErrors errors={errors} />
											</div>
										)}
									</div>
								</div>
							</KeyboardEventHandler>
						</form>
					</div>
				)}
			</Fragment>
		);
	};

	renderPopupHeader = () => {
		const { expandView } = this.state;
		const { hideAll } = this.hiddenFields;
		return (
			<div className="popup__header popup__header--new-transaction">
				<label className="popup__header__title popup__header__title--new-transaction popup__header__title--nospacing">
					Add new transaction
				</label>
				<div className="datatooltip--h--left">
					<Link
						to="/user-settings/new-transaction"
						className="btn btn--med btn--ghost popup__header__btn hide--to--lrg--inline-block spc--right--xsml"
						data-tooltip="Settings"
					>
						<i className="icon icon--sml icon--settings icon--middle"></i>
					</Link>
					{!hideAll && (
						<button
							type="button"
							className="btn btn--med btn--ghost popup__header__btn hide--to--lrg--inline-block"
							data-tooltip={expandView ? 'Minimize' : 'Expand view'}
							onClick={this.expandView}
						>
							<i className={`icon icon--xsml icon--${expandView ? 'minimize' : 'expand'} align--v--middle`} />
						</button>
					)}
				</div>
			</div>
		);
	};

	renderPopupFooter = () => {
		const {
			isProcessing,
			total,
			amount,
			saveAsCustomer,
			showPopupDetails,
			showAllowDuplicate,
			allowDuplicate,
			disableVerification,
			showDisableVerification,
			has3DS2,
			includeConvenience,
			includeSalesTax,
			creditCard,
			permissions: { allowCcSave, allowCheckSave },
			isLoading,
			customerId,
			showIgnoreAvs,
			ignoreAvs,
		} = this.state;
		const { customer } = this.props;

		const saveAsCustomerDisabled = !hasFeaturePackage(featurePackages.customerBilling);
		const saveAsCustomerTooltip = saveAsCustomerDisabled ? featurePackageTooltips.noCustomerBilling : null;
		const displayProcessLaterButton =
			!customerId &&
			((this.state.paymentMethod === paymentMethod.CC &&
				toLower(creditCard.transactionType) === transactionType.SALE) ||
				this.state.paymentMethod === paymentMethod.CHECK);

		return (
			<Fragment>
				{this.renderProcessLaterPopup()}
				<div
					className={`popup__footer popup__footer--styled popup__footer--new-transaction separated ${
						this.isExpanded ? 'is-expanded' : ''
					}`}
				>
					<div className={`popup__footer__details clearfix ${showPopupDetails ? 'is-expanded' : ''}`}>
						<div className="hide--from--lrg spc--bottom--sml">
							<a className="anchor anchor--primary" onClick={this.toggleShowDetails}>
								{showPopupDetails ? '- Hide' : '+ Show'} details
							</a>
						</div>
						{this.isAchDisabled ? null : (
							<div className={`popup__footer--convenience ${showPopupDetails ? 'is-visible' : ''}`}>
								{(!customer || showAllowDuplicate || showDisableVerification || showIgnoreAvs) && (
									<div>
										{!customer &&
											(creditCard &&
												!startsWith(toLower(creditCard.transactionType), transactionType.EBTFS_VOUCHER) &&
												((this.state.paymentMethod === paymentMethod.CC && allowCcSave) ||
													(this.state.paymentMethod === paymentMethod.CHECK && allowCheckSave))) && (
												<div
													className="display--ib spc--right--sml--alt spc--bottom--sml"
													data-tooltip={saveAsCustomerTooltip}
												>
													<input
														type="checkbox"
														name="saveAsCustomer"
														id="saveAsCustomer"
														className="input input--check input--check--sml"
														value={saveAsCustomer}
														checked={saveAsCustomer}
														onChange={this.onSaveAsCustomerChange}
														disabled={saveAsCustomerDisabled}
													/>
													<label htmlFor="saveAsCustomer">Save as customer</label>
												</div>
											)}
										{showAllowDuplicate && (
											<div className="display--ib spc--right--sml--alt spc--bottom--sml">
												<input
													type="checkbox"
													name="allowDuplicate"
													id="allowDuplicate"
													className="input input--check input--check--sml"
													value={allowDuplicate}
													checked={allowDuplicate}
													onChange={this.handleChange}
												/>
												<label htmlFor="allowDuplicate">Allow duplicates</label>
											</div>
										)}
										{showIgnoreAvs && (
											<div className="display--ib spc--right--sml--alt spc--bottom--sml">
												<input
													type="checkbox"
													name="ignoreAvs"
													id="ignoreAvs"
													className="input input--check input--check--sml"
													value={ignoreAvs}
													checked={ignoreAvs}
													onChange={this.handleChange}
												/>
												<label htmlFor="ignoreAvs">
													<span>
														Ignore AVS{' '}
														<i
															data-tooltip="Allow transaction to process if an AVS mismatch occurs."
															className="icon icon--tiny icon--info align--v--middle spc--left--tny datatooltip--ignore-avs"
														></i>
													</span>
												</label>
											</div>
										)}
										{showDisableVerification && (
											<div className="display--ib spc--right--sml--alt spc--bottom--sml">
												<input
													type="checkbox"
													name="disableVerification"
													id="disableVerification"
													className="input input--check input--check--sml"
													value={disableVerification}
													checked={disableVerification}
													onChange={this.handleChange}
												/>
												<label htmlFor="disableVerification">Disable 3DS verification</label>
											</div>
										)}
									</div>
								)}
							</div>
						)}
					</div>
					{this.isAchDisabled ? (
						<a
							className="btn btn--primary btn--med fullwidth"
							rel="noopener noreferrer"
							target="_blank"
							href="https://www.cardknox.com/go"
						>
							Click here to apply for ACH
						</a>
					) : (
						<div className="popup__footer__process">
							<div className="flex--grow--1 datatooltip--w--300">
								<button
									type="button"
									className="btn btn--primary btn--med popup__footer__process--main"
									disabled={isProcessing}
									data-tooltip={has3DS2 ? threeDS2ProcessTooltip : null}
									onClick={this.onSubmit}
								>
									<div className="display--ib type--ellipsis">
										Process (Total{' '}
										<NumberFormat
											thousandSeparator=","
											decimalSeparator="."
											disabled={true}
											decimalScale={2}
											fixedDecimalScale
											prefix={this.merchantCurrencyCode}
											value={
												((includeConvenience || includeSalesTax) &&
												creditCard &&
												creditCard.transactionType !== transactionType.SAVE
													? total
													: amount.value) || 0
											}
											displayType="text"
										/>
										)
									</div>
								</button>
							</div>
							{displayProcessLaterButton && (
								<div className="datatooltip--right">
									<button
										type="button"
										className="btn btn--ghost btn--med spc--left--tny"
										disabled={isProcessing}
										onClick={this.processLater}
										data-tooltip="Schedule for later"
										id="processLaterAch"
									>
										<i className="icon icon--tiny icon--clock"></i>
									</button>
									{!isLoading && this.state.paymentMethod === paymentMethod.CHECK && <Tour tourConfig={tourConfig} />}
								</div>
							)}
						</div>
					)}
				</div>
			</Fragment>
		);
	};

	render() {
		const { showPopupDetails } = this.state;
		return (
			<div ref={this.popupRef}>
				{this.renderSaveAsCustomerPopup()}
				{this.renderPopupHeader()}
				<div
					className={
						this.popupRef && this.popupRef.current
							? 'popup__body'
							: `popup__body popup__body--convenience newtransaction__popup__body ${
									this.isExpanded ? 'is-expanded' : ''
							  } ${showPopupDetails ? 'is-visible' : ''}`
					}
					tabIndex="-1"
				>
					{this.renderForm()}
				</div>
				{this.renderPopupFooter()}
			</div>
		);
	}
}

NewTransaction.propTypes = {
	existingTransaction: PropTypes.object,
	buttonStyle: PropTypes.string,
	refreshGridData: PropTypes.func,
	closeModal: PropTypes.func,
	notificationRef: PropTypes.any.isRequired,
	customer: PropTypes.object,
	paymentMethods: PropTypes.array,
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	xMerchantId: PropTypes.any,
};

export const WrappedNewTransaction = withLoader(
	withError(withCancelable(NewTransaction), handleInvalidAccountSettings)
);
