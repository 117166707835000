import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import { get, every, each } from 'lodash';

import ActionsModal from 'common/components/transaction-actions/actions-modal-wrapper';
import { modalNames } from 'common/components/transaction-actions/modal-names';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { withBlock } from 'common/components/block';
import { Notification } from 'common/components/notifications';

const { c2payEnabled } = ApplicationSettings;

class PaymentMethodsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			modal: {
				name: modalNames.none,
				data: null,
			},
			sectionsExpanded: {
				applePay: false,
				clickToPay: false,
			},
		};

		this.notificationRef = createRef();
	}

	openCloseActionsModal = modal => this.setState({ modal });

	toggleExpandCollapseSection = section => () => {
		this.setState({
			sectionsExpanded: { ...this.state.sectionsExpanded, [section]: !this.state.sectionsExpanded[section] },
		});
	};

	toggleExpandCollapseAll = expand => () => {
		const newState = { sectionsExpanded: this.state.sectionsExpanded };

		each(newState.sectionsExpanded, (_, section) => {
			newState.sectionsExpanded[section] = !expand;
		});

		this.setState(newState);
	};

	handleMessage = () => {
		const { expanded } = this.state;
		this.setState({ expanded: !expanded });
	};

	render = () => {
		const {
			expanded,
			modal,
			sectionsExpanded,
			sectionsExpanded: { applePay, clickToPay },
		} = this.state;
		const isExpandedAll = every(sectionsExpanded, item => item);

		return (
			<div className="settings--main settings--main--alt">
				<ActionsModal modal={modal} onModalClose={this.openCloseActionsModal} />
				<Notification ref={this.notificationRef} />
				<div className="settings__header">
					<div className="settings__header__title">
						Payment Methods
						<i onClick={this.handleMessage} className="icon icon--tiny icon--info spc--left--tny cursor--pointer"></i>
					</div>
					{/* <div className="settings__header__action">{this.renderHeaderButtons()}</div> */}
				</div>
				<div className={expanded ? 'spc--bottom--med' : 'display--n'}>
					<div className="message message--default message--close" id="logoManagementCloseMessage">
						<p>In this section, you can configure your Payment Methods</p>
						<div onClick={this.handleMessage} className="message--close__button" id="logoManagementCloseButton">
							<i className="icon icon--xnano icon--close-big"></i>
						</div>
					</div>
				</div>
				<div className="spc--bottom--med clearfix">
					<button className="btn btn--sml btn--primary push" onClick={this.toggleExpandCollapseAll(isExpandedAll)}>
						{`${isExpandedAll ? '- Collapse' : '+ Expand'} All`}
					</button>
				</div>
				<div className="spr__card clearfix">
					<div
						className={`spr__heading cursor--pointer ${applePay ? 'is-expanded' : ''}`}
						onClick={this.toggleExpandCollapseSection('applePay')}
					>
						<div className="flex--primary datatooltip--w--140">
							<h4 className="spr__heading__title">Configure Apple Pay</h4>
							<div data-tooltip="Configure Apple Pay for your website" className="display--f spc--left--tny">
								<i className="icon icon--tiny icon--info cursor--pointer"></i>
							</div>
						</div>
						<i
							className={`icon icon--tiny icon--arrow--right--grey spr__heading__expand ${
								applePay ? 'is-expanded' : ''
							}`}
						></i>
					</div>
					{applePay && (
						<Fragment>
							<div className="spr__content">
								<div className="flex--tertiary">
									<p className="type--base spc--bottom--sml">Register for Apple Pay with Cardknox Certificate</p>
									<button
										className="btn btn--med btn--primary spc--bottom--sml"
										onClick={() =>
											this.openCloseActionsModal({
												name: modalNames.registerApplePay,
												data: {
													handleError: this.props.handleError,
													addNotification: get(this.notificationRef, 'current.addNotification'),
												},
											})
										}
									>
										Register
									</button>
								</div>
							</div>
							<div className="spr__content">
								<div className="flex--tertiary">
									<p className="type--base spc--bottom--sml"> Register for Apple Pay using your own Certificate</p>
									<button
										className="btn btn--med btn--primary spc--bottom--sml"
										onClick={() =>
											this.openCloseActionsModal({
												name: modalNames.generateApplePay,
												data: {
													handleError: this.props.handleError,
													addNotification: get(this.notificationRef, 'current.addNotification'),
												},
											})
										}
									>
										Register
									</button>
								</div>
							</div>
						</Fragment>
					)}
				</div>
				{c2payEnabled && (
					<div className="spr__card clearfix">
						<div
							className={`spr__heading cursor--pointer ${clickToPay ? 'is-expanded' : ''}`}
							onClick={this.toggleExpandCollapseSection('clickToPay')}
						>
							<div className="flex flex--primary datatooltip--w--140">
								<h4 className="spr__heading__title">Configure Click To Pay</h4>
								<div data-tooltip="Configure Click To Pay for your website" className="display--f spc--left--tny" >
									<i className="icon icon--tiny icon--info cursor--pointer"></i>
								</div>
							</div>
							<i
								className={`icon icon--tiny icon--arrow--right--grey spr__heading__expand ${
									clickToPay ? 'is-expanded' : ''
								}`}
							></i>
						</div>
						{clickToPay && (
							<div className="spr__content">
								<div className="flex--tertiary">
									<p className="type--base spc--bottom--sml">
										Click the button below to start your Click To Pay Domain registration
									</p>
									<button
										className="btn btn--med btn--primary spc--bottom--sml"
										onClick={() =>
											this.openCloseActionsModal({
												name: modalNames.configureClickToPay,
												data: {
													addNotification: get(this.notificationRef, 'current.addNotification'),
												},
											})
										}
									>
										Register your merchant
									</button>
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		);
	};
}

PaymentMethodsComponent.propTypes = {
	handleError: PropTypes.func.isRequired,
	handleBlockChange: PropTypes.func.isRequired,
};

export default withError(withCancelable(withBlock(PaymentMethodsComponent)));
