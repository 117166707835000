import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Logo from '../img/cardholder-footer.png';
import Glyph from '../img/cardholder-footer-glyph.png';

class PublicLayoutComponent extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<React.Fragment>
				<div className="cardholder">
					<div className="cardholder__main">{this.props.children}</div>
					<div className="cardholder__footer">
						<img src={Logo}></img>
						<p className="cardholder__footer__message type--color--text--light">
							<img className="spc--right--sml" src={Glyph}></img>
							Secured by <span className="type--wgt--bold">Cardknox</span>
						</p>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

PublicLayoutComponent.propTypes = {
	children: PropTypes.any,
};

export default PublicLayoutComponent;
