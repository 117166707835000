import React from 'react';
import moment from 'moment';
import { find, some, trim } from 'lodash';

import { SelectFilter, NumberFilter } from 'common/components/filters';
import { SelectSelection, SingleSelection, DatePickerSelection } from 'common/components/selections';
import { getDate, localToApiMoment, createFilters, getFilterValues, maxApiDaysRange } from 'common/utilities';

const checkTodayEnd = endDate => endDate.isSame(moment(), 'd');

const checkToday = (startDate, endDate) => endDate.isSame(moment(), 'd') && startDate.isSame(moment(), 'd');

const checkYesterday = (startDate, endDate) => {
	const yesterday = moment()
		.subtract(1, 'days')
		.startOf('day');
	return endDate.isSame(yesterday, 'd') && startDate.isSame(yesterday, 'd');
};
const checkLastMonth = endDate =>
	endDate.isSame(
		moment()
			.subtract(1, 'months')
			.endOf('month'),
		'd'
	);

const isValidKey = (key, startDate, endDate) => {
	let isInvalid = false;
	switch (key) {
		case 'today':
			isInvalid = checkToday(startDate, endDate);
			break;
		case '7':
		case '30':
		case '60':
		case '90':
		case 'month':
			isInvalid = checkTodayEnd(endDate);
			break;
		case 'yesterday':
			isInvalid = checkYesterday(startDate, endDate);
			break;
		case 'lastmonth':
			isInvalid = checkLastMonth(endDate);
			break;
	}
	return isInvalid;
};
export const transfersFilter = createFilters([
	{
		key: 'secondaryKeys',
		name: 'Secondary Keys',
		values: {
			secondaryKeys: null,
		},
		selectionComponent: () => (
			<div className="display--ib type--xsml bg--chablis padd--sml type--color--primary spc--bottom--tny">
				Expect slower response time when viewing transfers for all accounts.
			</div>
		),
	},
	{
		key: 'date',
		customSettingsKey: 'date',
		hasSelection: true,
		defaultHasSelection: true,
		getSelectionText: ({ key, startDate, endDate }, predefinedDates) => {
			if (!isValidKey(key, startDate, endDate)) {
				key = 'custom';
			}
			const predefinedDate = find(predefinedDates, { key });
			if (predefinedDate) {
				return predefinedDate.displayValue;
			}
			const diff = endDate.diff(startDate, 'days');
			return diff ? `Custom (${diff + 1} days)` : diff === 0 ? 'Custom (1 day)' : 'Custom';
		},
		values: {
			key: '7',
			startDate: moment()
				.startOf('day')
				.add(-7, 'day'),
			endDate: moment().endOf('day'),
			disabled: false,
		},
		props: {
			displayTime: true,
			maxDaysRange: Infinity,
			maxApiDaysRange: maxApiDaysRange,
			showTour: true,
			milliseconds: {
				startDate: '000',
				endDate: '999',
			},
		},
		selectionComponent: DatePickerSelection,
	},
	{
		key: 'amount',
		name: 'Amount',
		customSettingsKey: 'amount',
		values: {
			amount: null,
		},
		props: {
			prefix: '$',
		},
		component: NumberFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'referenceNumber',
		name: 'Reference Number',
		values: {
			referenceNumber: '',
		},
		component: NumberFilter,
		selectionComponent: SingleSelection,
		onTop: true,
		allowsDateDisable: true,
	},
	{
		key: 'transferType',
		name: 'Transfer Type',
		values: {
			transferOut: false,
			transferIn: false,
		},
		options: {
			transferOut: 'Transfer Out',
			transferIn: 'Transfer In',
		},
		component: SelectFilter,
		selectionComponent: SelectSelection,
	},
]);

export const compileFilter = async (filterList, filterDateFormat) => {
	const { date, amount, transferType, referenceNumber, custom } = getFilterValues(filterList);

	const filters = {
		xCommand: 'Report:Net',
	};

	if (!date.disabled) {
		const beginDate = await localToApiMoment(date.startDate);
		const endDate = await localToApiMoment(date.endDate);
		filters.xBeginDate = getDate(beginDate, filterDateFormat);
		filters.xEndDate = getDate(endDate, filterDateFormat);

		if (trim(amount.amount)) {
			filters.xAmount = 'ai' + amount.amount.toString();
		}

		if (transferType.transferOut) {
			filters.xFilterCommand = 'xfer_out';
		} else if (transferType.transferIn) {
			filters.xFilterCommand = 'xfer_in';
		}

		if (some(custom, p => p)) {
			for (let prop in custom) {
				if (custom.hasOwnProperty(prop)) {
					if (trim(custom[prop])) {
						filters['x' + prop] = 'a=' + custom[prop];
					}
				}
			}
		}
	}

	if (trim(referenceNumber.referenceNumber)) {
		filters.xRefNum = (date.disabled ? '' : 'a=') + referenceNumber.referenceNumber.toString();
	}

	return filters;
};
