import { toLower } from 'lodash-es';

const invalidRevision = error => {
	if (
		error &&
		(toLower(error.message) === 'invalid: revision' || toLower(error.message) === 'item exists. revision cannot be 0')
	) {
		return {
			...error,
			message: 'The data you were trying to update is not up to date. Fetching latest data...',
		};
	}
	return error;
};

export default invalidRevision;
