import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, noop } from 'lodash';

import { merchantRegistrationService } from '../../services';
import { FormErrors } from '../../utilities';

import { Buffer } from 'buffer';
import { saveAs } from 'file-saver';

class RegisterApplePayCardknoxCertificate extends Component {
	constructor() {
		super();

		this.state = {
			domainAssociationFile: null,
			domainName: null,
			invalidDomainName: true,
			isLoading: false,
		};
	}

	validateInputs = () => {
		const { domainName } = this.state;
		let hasErrors = false;
		const newState = { errors: {} };
		if (!domainName) {
			newState.errors.domainName = 'Valid Domain Name is required';
			newState.invalidDomainName = true;
			hasErrors = true;
		} else {
			newState.invalidDomainName = false;
		}
		this.setState(newState);
		return hasErrors;
	};

	downloadDomainAssociationFile = async () => {
		if (this.state.isLoading) return;
		const addNotification = this.props.addNotification;
		let domainAssociationFile = this.state.domainAssociationFile;
		try {
			this.setState({ isLoading: true });
			if (!this.state.domainAssociationFile) {
				const domainData = await merchantRegistrationService.downloadAppleDomainAssociationFile();
				domainAssociationFile = get(domainData, 'domainAssociationFile', '');
				this.setState({ domainAssociationFile: domainAssociationFile });
			}
			const result = new Blob([Buffer.from(domainAssociationFile, 'base64')], {
				type: 'application/octet-stream',
			});
			saveAs(result, 'apple-developer-merchantid-domain-association');
		} catch (e) {
			const notification = this.props.handleError(e, { delayMessage: true });
			addNotification({ ...notification, onClose: noop });
			this.props.closeModal();
		} finally {
			this.setState({ isLoading: false });
		}
	};

	registerDomain = async () => {
		const addNotification = this.props.addNotification;
		try {
			this.setState({ isLoading: true });
			const { domainName } = this.state;
			await merchantRegistrationService.registerAppleDomain(domainName);
			addNotification({
				message: `Domain '${domainName}' has been generated successfully!`,
				success: true,
			});
		} catch (e) {
			const notification = this.props.handleError(e, { delayMessage: true });
			addNotification({ ...notification, onClose: noop });
		} finally {
			this.props.closeModal();
		}
	};

	setDomainName = e => {
		const newState = { domainName: e.target.value };
		this.setState(newState, this.validateInputs);
	};

	render = () => {
		const required = (
			<span className="label--required" data-tooltip="Required">
				*
			</span>
		);

		const { invalidDomainName, errors, isLoading } = this.state;
		const isRegisterDisabled = invalidDomainName || isLoading;

		return (
			<div className="popup popup--apple-pay">
				<div className="popup__header">
					<div className="popup__header__title">Apple Pay Domain Registration</div>
				</div>
				<div className="popup__body">
					{isLoading ? (
						<div className="loader--popup__holder type--center">
							<div className="spc--bottom--sml">Please wait, this may take a few seconds...</div>
							<div className="loader__spinner loader__spinner--apple-pay"></div>
						</div>
					) : (
						<Fragment>
							<div className="progress__step">
								<div className="progress__step__aside">
									<span>1</span>
								</div>
								<div className="progress__step__main">
									<p>
										Download{' '}
										<span className="anchor anchor--primary" onClick={this.downloadDomainAssociationFile}>
											apple-developer-merchantid-domain-association
										</span>{' '}
										file
									</p>
								</div>
							</div>

							<div className="progress__step">
								<div className="progress__step__aside">
									<span>2</span>
								</div>
								<div className="progress__step__main">
									<p className="spc--bottom--sml">
										Upload this file under <strong>https://yourDomain/.well-known/</strong>
										<br /> Assuming your domain is <strong>www.mycompany.com</strong> the full URL will be
										<br />{' '}
										<strong>
											https://www.mycompany.com/.well-known/apple-developer-merchantid-domain-association
										</strong>{' '}
									</p>
									<div className="message message--default">
										<p>Please note: This url must be publicly available. (No login required)</p>
										<p>
											Once done, complete Step 3 by providing your Domain name in the text box
											<br />
											and clicking <strong>Register Domain</strong> button below in Step 3
										</p>
									</div>
								</div>
							</div>

							<div className="progress__step">
								<div className="progress__step__aside">
									<span>3</span>
								</div>
								<div className="progress__step__main">
									<label htmlFor="domainName" className="grid__recurring__main__label">
										Domain Name {required}
									</label>
									<input
										type="text"
										id="domainName"
										name="domainName"
										className="input input--med"
										onChange={this.setDomainName}
									/>
									<div>
										{!isEmpty(errors) && (
											<div className="spc--top--tny">
												<FormErrors errors={errors} />
											</div>
										)}
									</div>
								</div>
							</div>
						</Fragment>
					)}
				</div>
				{isLoading ? (
					<div></div>
				) : (
					<div className="popup__footer popup__footer--styled">
						<button
							type="button"
							className="btn btn--sml btn--primary"
							onClick={this.registerDomain}
							disabled={isRegisterDisabled}
						>
							Register Domain
						</button>
					</div>
				)}
			</div>
		);
	};
}

RegisterApplePayCardknoxCertificate.propTypes = {
	handleError: PropTypes.func,
	addNotification: PropTypes.func,
	closeModal: PropTypes.func,
};

export default RegisterApplePayCardknoxCertificate;
