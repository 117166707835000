import React, { Component } from 'react';
import { bool, func } from 'prop-types';
import { filter, map, cloneDeep, find, every, isEmpty, each, toLower, includes, get, some, endsWith } from 'lodash';
import { NavLink } from 'react-router-dom';
import { createPortal } from 'react-dom';

import { OutsideClick, parseError, getMail, kvaasResources, compareSemver } from './../../utilities';
import { Notification } from './../../components/notifications';
import authenticationService from './../../services/authenticationService';
import principalService from './../../services/principalService';
import kvaasService from './../../services/kvaasService';
import { withCancelable } from '../cancelable';
import { withError } from '../error';
import {
	newVersion,
	phonePayFree,
	newPortal,
	oldPortal,
	updatedSettings,
	accountUpdater,
	vp3300,
	mobileAppLink,
	digitalWallet,
	giftCard,
	cardknoxSettings,
	tapToPhone,
} from './notification-types';
import ModalComponent from '../modal/modal';
import { optInForNewPortal, toggleShareFeedback } from 'common/utilities/commonEnterLeaveBetaPortalMethods';
import sectionKeys from 'routing/sections';
import { hasPaymentSites, isProPay } from 'common/services/helper-service';
let displayedCardknoxSettings = false;
let displayedNewVersionComponent = false;
let displayedPhonePayFree = false;
let displayedUpdatedSettings = false;
let displayedNewPortal = false;
let displayedOldPortal = false;
let displayedAccountUpdater = false;
let displayedVp3300 = false;
let displayedMobileApp = false;
let displayedDigitalWallet = false;
let displayedGiftCard = false;
let displayedTapToPhone = false;
const version = SoftwareSettings.version;

const newPortalEndpoint = ApplicationSettings.newPortalEndpoint;
const isNewPortal = newPortalEndpoint && endsWith(window.location.host, newPortalEndpoint);
const newPortalVersion = newPortalEndpoint && !isNewPortal ? version : null;
const oldPortalVersion = isNewPortal ? version : null;

const requestKeys = {
	FETCH: 'fetch',
	USERSETTINGS: 'usersettings',
	SAVE: 'save',
};

class UserAccountPanel extends Component {
	static notifications = [];

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			username: null,
			search: '',
			selectedCompany: null,
			availableCompanies: [],
			showCompanyDropDown: false,
			showCompanyDropDownDekstop: false,
			showCompanyDropDownMobile: false,
			showUserDropDown: false,
			showUserDropDownDesktop: false,
			showUserDropDownMobile: false,
			showNotificationDropDown: false,
			showNotificationDropDownDekstop: false,
			showNotificationDropDownMobile: false,
			isValidatingKey: false,
			notifications: UserAccountPanel.notifications,
			isLoadingNotifications: false,
			userSettings: null,
			principal: principalService.get(),
			isShareFeedbackOpen: false,
			switchingCompany: false,
		};

		this.toggleShareFeedback = toggleShareFeedback.bind(this);
		this.optInForNewPortal = optInForNewPortal.bind(this);
	}

	get mobileHeaderNotificationPortal() {
		return document.querySelector('#mobileHeaderNotificationPortal');
	}

	get mobileHeaderActionsPortal() {
		return document.querySelector('#mobileHeaderActionsPortal');
	}
	setNotifications = async principal => {
		if (!principal) return;
		const proPay = await isProPay();
		const hasPS = await hasPaymentSites(principal);
		this.setState({ hasPS, proPay });
	};
	async componentDidMount() {
		const user = await authenticationService.getUser();
		let state = {};
		const principal = principalService.get();
		this.setNotifications(principal);
		if (user && user.attributes && user.attributes.email) {
			state.username = user.attributes.email;
			if (principal && principal.id && principal.list) {
				const selectedItem = find(principal.list, { mid: principal.id });
				state.selectedCompany = selectedItem;
				state.availableCompanies = principal.list;
			}
			this.subscription = principalService.subscribe(this.refreshKeys, true);

			state.isLoading = false;
			this.setState(state);
		}
		await Promise.all([this.loadUserSettings(), this.initializeNotifications(principal)]);
	}
	async initializeNotifications(principal) {
		if (
			displayedNewVersionComponent &&
			displayedPhonePayFree &&
			displayedNewPortal &&
			displayedOldPortal &&
			displayedUpdatedSettings &&
			displayedAccountUpdater &&
			displayedVp3300 &&
			displayedMobileApp &&
			displayedDigitalWallet &&
			displayedGiftCard &&
			displayedCardknoxSettings &&
			displayedTapToPhone
		) {
			return;
		}
		try {
			const [notifications] = await this.props.makePendingRequest(
				kvaasService.get(kvaasResources.notifications),
				requestKeys.FETCH
			);

			if (get(notifications, 'data', null)) {
				if (compareSemver(notifications.data.newVersion, version) > -1) {
					displayedNewVersionComponent = true;
				}
				if (notifications.data.tapToPhone) {
					displayedTapToPhone = true;
				}
				if (notifications.data.cardknoxSettings) {
					displayedCardknoxSettings = true;
				}
				if (notifications.data.updatedSettings) {
					displayedUpdatedSettings = true;
				}
				if (notifications.data.mobileApp) {
					displayedMobileApp = true;
				}
				if (notifications.data.accountUpdater) {
					displayedAccountUpdater = true;
				}
				if (notifications.data.vp3300) {
					displayedVp3300 = true;
				}
				if (notifications.data.phonePayFree) {
					displayedPhonePayFree = true;
				}
				if (notifications.data.digital) {
					displayedDigitalWallet = true;
				}
				if (notifications.data.giftCard) {
					displayedGiftCard = true;
				}
				if (!newPortalVersion || compareSemver(notifications.data.newPortal, newPortalVersion) > -1) {
					displayedNewPortal = true;
				}
				if (!oldPortalVersion || compareSemver(notifications.data.oldPortal, oldPortalVersion) > -1) {
					displayedOldPortal = true;
				}
			}
		} catch (e) {
			const error = this.props.handleError(e, true);
			if (error) {
				//eslint-disable-next-line
				console.error(error);
			}
		}
		if (!displayedTapToPhone) {
			this.addNotification(tapToPhone);
			displayedTapToPhone = true;
		}
		if (!displayedPhonePayFree) {
			this.addNotification(phonePayFree);
			displayedPhonePayFree = true;
		}
		if (!displayedNewVersionComponent) {
			this.addNotification(newVersion);
			displayedNewVersionComponent = true;
		}

		if (!displayedNewPortal) {
			this.addNotification({
				...newPortal,
				componentProps: {
					optInForNewPortal: this.optInForNewPortal,
				},
			});
			displayedNewPortal = true;
		}
		if (!displayedOldPortal) {
			this.addNotification({
				...oldPortal,
				componentProps: {
					optInForNewPortal: this.optInForNewPortal,
				},
			});
			displayedOldPortal = true;
		}
		if (!displayedUpdatedSettings) {
			this.addNotification(updatedSettings);
			displayedUpdatedSettings = true;
		}
		if (!displayedMobileApp) {
			this.addNotification(mobileAppLink);
			displayedMobileApp = true;
		}

		if (principal && principal.hasAccess && principal.hasAccess[sectionKeys.featureAddons]) {
			if (!displayedAccountUpdater) {
				this.addNotification(accountUpdater);
				displayedAccountUpdater = true;
			}

			if (!displayedVp3300) {
				this.addNotification(vp3300);
				displayedVp3300 = true;
			}
			if (!displayedDigitalWallet && get(principal, 'idInfo.xSupportsDigitalWallet', false)) {
				this.addNotification(digitalWallet);
				displayedDigitalWallet = true;
			}
			if (!displayedGiftCard) {
				this.addNotification(giftCard);
				displayedGiftCard = true;
			}
			if (!displayedCardknoxSettings) {
				this.addNotification(cardknoxSettings);
				displayedCardknoxSettings = true;
			}
		}
	}
	async loadUserSettings() {
		try {
			const [userSettings] = await this.props.makePendingRequest(
				kvaasService.get(kvaasResources.userSettings),
				requestKeys.USERSETTINGS
			);
			if (userSettings && userSettings.data) {
				this.setState({ userSettings });
			}
		} catch (e) {
			const error = this.props.handleError(e, true);
			if (error) {
				//eslint-disable-next-line
				console.error(error);
			}
		}
	}

	componentWillUnmount = () => {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	};

	refreshKeys = principal => {
		const newState = {};
		if (principal && principal.id && principal.list) {
			const selectedItem = filter(principal.list, { mid: principal.id })[0];
			newState.selectedCompany = selectedItem;
			newState.availableCompanies = principal.list;
		}
		this.setState(newState);
	};

	toggleCompanyDropDown = () => {
		const { showCompanyDropDown } = this.state;
		this.setState({
			showCompanyDropDown: !showCompanyDropDown,
			showCompanyDropDownDekstop: !showCompanyDropDown,
			showCompanyDropDownMobile: !showCompanyDropDown,
		});
	};

	toggleUserDropDown = () => {
		const { showUserDropDown } = this.state;
		this.setState({
			showUserDropDown: !showUserDropDown,
			showUserDropDownDekstop: !showUserDropDown,
			showUserDropDownMobile: !showUserDropDown,
		});
	};

	toggleNotificationDropDown = (event, ignoreMap = false) => {
		const { showNotificationDropDown, notifications } = this.state;
		if (!showNotificationDropDown && notifications.length === 0) {
			return;
		}
		this.setState(
			{
				showNotificationDropDown: !showNotificationDropDown,
				showNotificationDropDownDekstop: !showNotificationDropDown,
				showNotificationDropDownMobile: !showNotificationDropDown,
				notifications: ignoreMap
					? notifications
					: map(notifications, notification => ({
							...notification,
							isOpen: showNotificationDropDown ? false : notifications.length === 1 && !notification.read,
							read: (!showNotificationDropDown && notifications.length === 1) || notification.read,
							selected: false,
					  })),
			},
			this.syncNotifications
		);
	};

	onCloseCompanyDropDown = () => {
		if (!this.state.showCompanyDropDown) {
			return;
		}
		this.setState({
			showCompanyDropDown: false,
		});
	};

	onCloseCompanyDropDownDesktop = () => {
		if (!this.state.showCompanyDropDownDekstop) {
			return;
		}
		this.setState(
			{
				showCompanyDropDownDekstop: false,
			},
			() => {
				if (!this.state.showCompanyDropDownMobile) {
					this.onCloseCompanyDropDown();
				}
			}
		);
	};

	onCloseCompanyDropDownMobile = () => {
		if (!this.state.showCompanyDropDownMobile) {
			return;
		}
		this.setState(
			{
				showCompanyDropDownMobile: false,
			},
			() => {
				if (!this.state.showCompanyDropDownDekstop) {
					this.onCloseCompanyDropDown();
				}
			}
		);
	};

	onCloseUserDropDown = () => {
		if (!this.state.showUserDropDown) {
			return;
		}
		this.setState({
			showUserDropDown: false,
		});
	};

	onCloseUserDropDownDesktop = () => {
		if (!this.state.showUserDropDownDekstop) {
			return;
		}
		this.setState(
			{
				showUserDropDownDekstop: false,
			},
			() => {
				if (!this.state.showUserDropDownMobile) {
					this.onCloseUserDropDown();
				}
			}
		);
	};

	onCloseUserDropDownMobile = () => {
		if (!this.state.showUserDropDownMobile) {
			return;
		}
		this.setState(
			{
				showUserDropDownMobile: false,
			},
			() => {
				if (!this.state.showUserDropDownDekstop) {
					this.onCloseUserDropDown();
				}
			}
		);
	};

	onCloseNotificationDropDown = () => {
		if (!this.state.showNotificationDropDown) {
			return;
		}
		this.setState({
			showNotificationDropDown: false,
		});
	};

	onCloseNotificationDropDownDesktop = () => {
		if (!this.state.showNotificationDropDownDekstop) {
			return;
		}
		this.setState(
			{
				showNotificationDropDownDekstop: false,
			},
			() => {
				if (!this.state.showNotificationDropDownMobile) {
					this.onCloseNotificationDropDown();
				}
			}
		);
	};

	onCloseNotificationDropDownMobile = () => {
		if (!this.state.showNotificationDropDownMobile) {
			return;
		}
		this.setState(
			{
				showNotificationDropDownMobile: false,
			},
			() => {
				if (!this.state.showNotificationDropDownDekstop) {
					this.onCloseNotificationDropDown();
				}
			}
		);
	};
	addNotification = notification => {
		this.setState(prevState => {
			const newNotification = {
				isOpen: false,
				read: false,
				selected: false,
				...notification,
			};
			return { notifications: [newNotification, ...prevState.notifications] };
		}, this.syncNotifications);
	};

	syncNotifications = () => {
		UserAccountPanel.notifications = this.state.notifications;
	};

	renderCompanySubmenu = () => {
		const { selectedCompany, availableCompanies, search } = this.state;
		let filteredCompanies = cloneDeep(availableCompanies);

		if (!isEmpty(search)) {
			const lowerCasedSearch = toLower(search);
			filteredCompanies = filter(filteredCompanies, item => {
				// Concatenate item.dba, item.dba_alias, and item.mid into a single string
				const combinedString = toLower(`${item.dba_alias || item.dba} - ${item.mid}`);
				return includes(combinedString, lowerCasedSearch);
			});
		}

		if (filteredCompanies.length) {
			return map(filteredCompanies, company => {
				const selectedCompanyMid = get(selectedCompany, 'mid', '');
				const companyMid = get(company, 'mid', '');
				const companyDba = get(company, 'dba', '');
				const companyDbaAlias = get(company, 'dba_alias', '');
				const isActiveClass = selectedCompanyMid === companyMid ? ' is-active' : '';
				return (
					<li
						key={company.key}
						className={'header__submenu__list__item' + isActiveClass}
						onClick={() => this.switchCompany(company)}
					>
						{companyDbaAlias || companyDba}
						{companyMid ? ` - ${companyMid}` : ''}
					</li>
				);
			});
		} else {
			return (
				<li key="--empty--" className="header__submenu__list__item">
					No results
				</li>
			);
		}
	};

	showInactiveUserNotification = e =>
		this.notification.addNotification({
			message: 'The account is inactive for user',
			success: false,
			ref: e && e.ref,
		});

	switchCompany = company => {
		localStorage.removeItem('hasPaymentSites');
		localStorage.removeItem('isProPay');

		this.setState({ switchingCompany: true }, async () => {
			const { availableCompanies, isValidatingKey } = this.state;

			if (isValidatingKey) {
				return;
			}

			try {
				this.setState({ isValidatingKey: true });
				const idInfo = await authenticationService.validateKey(company.key, company.mid);
				this.setState(
					{
						selectedCompany: company,
						search: '',
						switchingCompany: true,
					},
					() => {
						(() => {
							principalService.set({
								id: company.mid,
								companyName: company.dba_alias || company.dba,
								list: availableCompanies,
								idInfo: idInfo,
							});
						})(),
							(() => {
								window.location.reload();
							})();
					}
				);
			} catch (e) {
				if (!e || !e.isCanceled) {
					const { message, stack } = parseError(e);
					if (includes(toLower(message), ['inactive'])) {
						this.showInactiveUserNotification(e);
					} else {
						this.setState({ isValidatingKey: false });
						this.notification.addNotification({
							message:
								!e.isApiError && stack
									? getMail(stack)
									: `An error has occurred. Please try again (${
											includes(
												[
													'networkerror when attempting to fetch resource.',
													'network request failed',
													'failed to fetch',
												],
												toLower(message)
											)
												? 'FFE'
												: message
									  })`,
							ref: e && e.ref,
							success: false,
						});
					}
				}
			} finally {
				this.setState({ switchingCompany: false, isValidatingKey: false });
			}
		});
	};

	searchCompany = e => {
		this.setState({
			search: e.target.value,
		});
	};

	handleNotificationSelect = index => {
		const notifications = [...this.state.notifications];
		notifications[index] = {
			...notifications[index],
			selected: !notifications[index].selected,
		};
		this.setState({ notifications }, this.syncNotifications);
	};

	handleAllSelect = selected => {
		const notifications = map(this.state.notifications, notification => ({
			...notification,
			selected,
		}));
		this.setState({ notifications }, this.syncNotifications);
	};

	toggleNotificationOpen = index => {
		const notifications = [...this.state.notifications];
		notifications[index] = {
			...notifications[index],
			isOpen: !notifications[index].isOpen,
			read: true,
		};
		this.setState({ notifications }, this.syncNotifications);
	};

	handleNotificationDelete = async () => {
		const { notifications, isLoadingNotifications } = this.state;
		const { makePendingRequest, handleError } = this.props;

		if (isLoadingNotifications) {
			return;
		}

		const toDelete =
			notifications.length === 1 && notifications[0].isOpen ? [notifications[0]] : filter(notifications, 'selected');

		if (isEmpty(toDelete)) {
			return;
		}

		try {
			this.setState({ isLoadingNotifications: true });

			const [oldData] = await makePendingRequest(kvaasService.get(kvaasResources.notifications), requestKeys.SAVE);
			const data = oldData && oldData.data ? { ...oldData.data } : {};

			each(toDelete, ({ key, value }) => {
				data[key] = value;
			});

			const request = {
				newData: {
					revision: 0,
					data,
				},
				oldData,
				...kvaasResources.notifications,
			};

			await makePendingRequest(kvaasService.save(request), requestKeys.SAVE);

			const updatedNotifications = notifications.filter(notification => !some(toDelete, { key: notification.key }));

			const newState = { notifications: updatedNotifications, isLoadingNotifications: false };

			if (isEmpty(updatedNotifications)) {
				newState.showNotificationDropDown = false;
			}

			this.setState(newState, this.syncNotifications);
		} catch (e) {
			if (handleError(e)) {
				this.setState({ isLoadingNotifications: false });
			}
		}
	};
	getNotifications = () => {
		const { principal, hasPS, proPay } = this.state;
		let availableNotifications = cloneDeep(this.state.notifications);
		if (!principal.hasAccess[sectionKeys.sendPaymentRequest] || displayedDigitalWallet || !hasPS || !proPay) {
			availableNotifications = filter(availableNotifications, notification => notification.key !== 'digitalWallet');
		}
		return availableNotifications;
	};

	renderNotificationMenu(isMobile) {
		const { showNotificationDropDown, isLoadingNotifications } = this.state;
		const notificationButtonClass = showNotificationDropDown ? 'open' : 'closed';
		const notificationDropDownActiveClass = showNotificationDropDown ? 'open' : 'closed';
		const onClose = isMobile ? this.onCloseNotificationDropDownMobile : this.onCloseNotificationDropDownDesktop;
		const notifications = this.getNotifications();
		let unreadNotifications = filter(notifications, ({ read }) => !read).length;
		const allSelected = every(notifications, ({ selected }) => selected);

		return (
			<OutsideClick action={onClose}>
				<div className="pos--rel">
					<div className="datatooltip--notification">
						<div
							data-tooltip={
								isEmpty(notifications) && !this.state.showNotificationDropDown ? 'No new notifications.' : null
							}
							onClick={this.toggleNotificationDropDown}
							className={`cursor--pointer ${notificationButtonClass}`}
						>
							<div className="pos--rel">
								<i className="icon icon--xsml icon--bell align--v--neg--4 header__notification__icon"></i>
								{unreadNotifications > 0 && (
									<span className="header__notification__counter">{unreadNotifications}</span>
								)}
							</div>
						</div>
					</div>
					{notifications.length > 0 && this.state.showNotificationDropDown && (
						<div className={`header__notification ${notificationDropDownActiveClass}`}>
							<div className="header__notification__header">
								<p className="header__notification__header__title">Notifications</p>

								{!isLoadingNotifications && (
									<div className="flex--primary">
										{notifications.length > 1 && (
											<a
												onClick={() => this.handleAllSelect(!allSelected)}
												href="javascript:void(0)"
												className="header__notification__header__select"
											>
												{allSelected ? 'Unselect' : 'Select'} All
											</a>
										)}
										<button
											data-tooltip="Clear"
											className="btn btn--sml btn--clear"
											type="button"
											onClick={this.handleNotificationDelete}
										>
											<i className="icon icon--tiny icon--delete"></i>
										</button>
									</div>
								)}
							</div>
							{isLoadingNotifications && <div className="header__notification__content loader__spinner" />}
							<div className="header__notification__content__wrapper">
								{!isLoadingNotifications &&
									map(
										notifications,
										({ Component, key, read, selected, isOpen, type, title, summary, componentProps }, index) => (
											<div
												key={key}
												className={`header__notification__content ${
													!read ? 'header__notification__content--read' : ''
												}`}
											>
												{isOpen ? (
													<div>
														<div className="type--right">
															<i
																className="icon icon--micro icon--close-big cursor--pointer"
																onClick={() => this.toggleNotificationOpen(index)}
															></i>
														</div>
														<Component isMobile={isMobile} {...componentProps} />
													</div>
												) : (
													<div className="header__notification__item">
														<div className="header__notification__checkbox spc--right--sml">
															<input
																className="input input--check input--check--rounded"
																checked={selected}
																type="checkbox"
																name="select"
																id={`select-${key}`}
																onChange={() => this.handleNotificationSelect(index)}
															/>
															<label htmlFor={`select-${key}`}></label>
														</div>
														<div className="spc--right--sml">
															<i className={`icon icon--med icon--middle icon--${type}`}></i>
														</div>
														<div className="fullwidth">
															<p className="header__notification__item__title">{title}</p>
															<p className="type--xsml">{summary}</p>
														</div>
														<button
															className="btn btn--med btn--clear"
															onClick={() => this.toggleNotificationOpen(index)}
														>
															<i className="icon icon--nano icon--arrow--right--primary icon--middle"></i>
														</button>
													</div>
												)}
											</div>
										)
									)}
							</div>
						</div>
					)}
				</div>
			</OutsideClick>
		);
	}

	renderAccountMenu(isMobile) {
		const {
			search,
			showCompanyDropDown,
			selectedCompany,
			availableCompanies,
			principal,
			switchingCompany,
		} = this.state;
		const companyButtonClass = showCompanyDropDown ? 'open' : 'closed';
		const companyDropDownActiveClass = showCompanyDropDown ? 'open' : 'closed';
		const doingBusinessAs = (selectedCompany && (selectedCompany.dba_alias || selectedCompany.dba)) || '';
		const numberOfCompanies = availableCompanies && availableCompanies.length ? availableCompanies.length : 0;
		const onClose = isMobile ? this.onCloseCompanyDropDownMobile : this.onCloseCompanyDropDownDesktop;
		const mid = get(principal, 'idInfo.xMerchantID');

		return (
			<OutsideClick action={onClose}>
				<React.Fragment>
					<div
						onClick={this.toggleCompanyDropDown}
						className={`flex--primary flex--nowrap cursor--pointer ${companyButtonClass}`}
					>
						<i className={`icon icon--xsml icon--company align--v--neg--4 spc--right--xsml`}></i>

						<span className="header__menu__item__label header--mobile__business">
							{doingBusinessAs}
							{!switchingCompany && ` - ${mid}`}
						</span>
						{numberOfCompanies > 1 ? (
							<i
								className={
									isMobile
										? 'icon icon--tiny icon--arrow--dropdown align--v--neg--2'
										: 'icon icon--tiny icon--arrow--dropdown align--v--neg--4 spc--left--xsml'
								}
							></i>
						) : null}
					</div>
					{numberOfCompanies > 1 && showCompanyDropDown ? (
						<div className={`header__submenu ${companyDropDownActiveClass}`}>
							<div className="header__submenu__search">
								<input
									onChange={this.searchCompany}
									value={search}
									type="text"
									className="input input--sml input--search"
									placeholder="Search by name"
								/>
							</div>
							<ul className="header__submenu__list">{this.renderCompanySubmenu()}</ul>
						</div>
					) : null}
				</React.Fragment>
			</OutsideClick>
		);
	}

	renderUserMenu(isMobile) {
		const { username, showUserDropDown } = this.state;
		const { displayEnterBetaPortal } = this.props;
		const userButtonClass = showUserDropDown ? 'open' : 'closed';
		const userDropDownActiveClass = showUserDropDown ? 'open' : 'closed';
		const onClose = isMobile ? this.onCloseUserDropDownMobile : this.onCloseUserDropDownDesktop;

		return (
			<OutsideClick action={onClose}>
				<React.Fragment>
					<div
						onClick={this.toggleUserDropDown}
						className={`flex--primary flex--nowrap cursor--pointer ${userButtonClass}`}
					>
						<i className={`icon icon--xsml icon--user-alt ${isMobile ? '' : 'spc--right--tny'}`}></i>
						<i
							className={
								isMobile
									? 'icon icon--tiny icon--arrow--dropdown align--v--neg--2'
									: 'icon icon--tiny icon--arrow--dropdown align--v--neg--4'
							}
						></i>
					</div>
					{showUserDropDown ? (
						<div className={`header__submenu header__submenu--alt ${userDropDownActiveClass}`}>
							<ul className="header__submenu__list">
								<li className="header__submenu__list--enh">{username}</li>
								{newPortalEndpoint && (
									<li className={`header__submenu__list__item${displayEnterBetaPortal ? ' show--to--sml--block' : ''}`}>
										<div onClick={() => this.toggleShareFeedback(isNewPortal)} className="header__submenu__list__link">
											<i className={`icon icon--xsml icon--middle icon--${isNewPortal ? 'leave' : 'enter'}`}></i>
											{isNewPortal ? 'Leave Beta Portal' : 'Enter Beta Portal'}
										</div>
									</li>
								)}
								<li className="header__submenu__list__item">
									<NavLink to="/user-settings/account-dropdown-order" className="header__submenu__list__link">
										Display Settings
									</NavLink>
								</li>
								<li className="header__submenu__list__item">
									<NavLink to="/logout" className="header__submenu__list__link">
										<i className="icon icon--sml icon--shutdown align--v--neg--6 spc--right--xsml"></i>Logout
									</NavLink>
								</li>
							</ul>
						</div>
					) : null}
				</React.Fragment>
			</OutsideClick>
		);
	}

	render() {
		const { isLoading, principal, isShareFeedbackOpen, switchingCompany } = this.state;
		const { displayEnterBetaPortal } = this.props;
		const mid = get(principal, 'idInfo.xMerchantID');
		const email = get(principal, 'email');

		return (
			<React.Fragment>
				{isLoading ? null : (
					<div className="header__menu__holder">
						{switchingCompany && <div className="loader--progress"></div>}
						{createPortal(
							<React.Fragment>
								{this.renderAccountMenu(true)}
								{this.renderUserMenu(true)}
							</React.Fragment>,
							this.mobileHeaderActionsPortal
						)}
						{createPortal(
							<React.Fragment>{this.renderNotificationMenu(true)}</React.Fragment>,
							this.mobileHeaderNotificationPortal
						)}
						<div className="hide--to--xlrg--inline-block">{this.renderNotificationMenu(false)}</div>
						<div className="header__menu__divider hide--to--xlrg--inline-block"></div>
						<div className="header__menu__item hide--to--xlrg--inline-block">{this.renderAccountMenu(false)}</div>
						<div className="header__menu__divider hide--to--xlrg--inline-block"></div>
						<div className="header__menu__item header__menu__item--alt hide--to--xlrg--inline-block">
							{this.renderUserMenu(false)}
						</div>
						{newPortalEndpoint && displayEnterBetaPortal && (
							<div className="header__menu__item hide--to--xlrg--inline-block">
								<button
									onClick={() => this.toggleShareFeedback(isNewPortal)}
									className="btn btn--sml btn--ghost btn--ghost--primary"
								>
									<i className={`icon icon--xsml icon--middle icon--${isNewPortal ? 'leave' : 'enter'}`}></i>
									{isNewPortal ? 'Leave Beta Portal' : 'Enter Beta Portal'}
								</button>
							</div>
						)}
					</div>
				)}
				<ModalComponent isOpen={isShareFeedbackOpen} onClose={this.optInForNewPortal}>
					{mid && email && (
						<div className="popup__body popup__body--tall popup__body--leaving-beta">
							<iframe
								height="1034"
								title="Embedded Wufoo Form"
								frameBorder="0"
								scrolling="no"
								style={{ width: '100%', border: 'none' }}
								src={`https://cardknox.wufoo.com/embed/m12th9db08hmkis/?Field118=${encodeURIComponent(
									mid
								)}&Field119=${encodeURIComponent(email)}`}
							/>
						</div>
					)}
				</ModalComponent>
				<Notification ref={el => (this.notification = el)} />
			</React.Fragment>
		);
	}
}

UserAccountPanel.propTypes = {
	handleError: func,
	makePendingRequest: func,
	displayEnterBetaPortal: bool,
};

export default withError(withCancelable(UserAccountPanel));
