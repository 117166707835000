import React from 'react';
import PropTypes from 'prop-types';

const title = ({ title, className }) => <div className={className}>{title}</div>;

title.propTypes = {
	title: PropTypes.string,
	className: PropTypes.string,
};

export default title;
