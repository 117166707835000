/* eslint-disable */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { polyfill } from 'react-lifecycles-compat';
import makeEventProps from 'make-event-props';

import TimeInput from './TimeInput';

import { isTime } from './shared/propTypes';
import { callIfDefined } from './shared/utils';

const allViews = ['hour', 'minute', 'second'];
const baseClassName = 'react-time-picker';

export default class TimePicker extends PureComponent {
	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.isOpen !== prevState.isOpenProps) {
			return {
				isOpen: nextProps.isOpen,
				isOpenProps: nextProps.isOpen,
			};
		}

		return null;
	}

	state = {};

	get eventProps() {
		return makeEventProps(this.props);
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.onOutsideAction);
		document.addEventListener('focusin', this.onOutsideAction);
	}

	componentDidUpdate(prevProps, prevState) {
		const { isOpen } = this.state;
		const { onClockClose, onClockOpen } = this.props;

		if (isOpen !== prevState.isOpen) {
			callIfDefined(isOpen ? onClockOpen : onClockClose);
		}
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.onOutsideAction);
		document.removeEventListener('focusin', this.onOutsideAction);
	}

	onOutsideAction = event => {
		if (this.wrapper && !this.wrapper.contains(event.target)) {
			this.closeClock();
		}
	};

	openClock = () => {
		this.setState({ isOpen: true });
	};

	closeClock = () => {
		this.setState(prevState => {
			if (!prevState.isOpen) {
				return null;
			}

			return { isOpen: false };
		});
	};

	toggleClock = () => {
		this.setState(prevState => ({ isOpen: !prevState.isOpen }));
	};

	onChange = (value, closeClock = true) => {
		this.setState({
			isOpen: !closeClock,
		});

		const { onChange } = this.props;
		if (onChange) {
			onChange(value);
		}
	};

	onFocus = event => {
		const { disabled, onFocus } = this.props;

		if (onFocus) {
			onFocus(event);
		}

		// Internet Explorer still fires onFocus on disabled elements
		if (disabled) {
			return;
		}

		this.openClock();
	};

	stopPropagation = event => event.stopPropagation();

	clear = () => this.onChange(null);

	renderInputs() {
		const {
			clearIcon,
			clockIcon,
			disableClock,
			disabled,
			format,
			isOpen,
			locale,
			maxDetail,
			maxTime,
			minTime,
			name,
			required,
			value,
		} = this.props;

		return (
			<div className={`${baseClassName}__wrapper`}>
				<TimeInput
					className={`${baseClassName}__inputGroup`}
					disabled={disabled}
					format={format}
					locale={locale}
					isClockOpen={isOpen}
					maxDetail={maxDetail}
					maxTime={maxTime}
					minTime={minTime}
					name={name}
					onChange={this.onChange}
					placeholder={this.placeholder}
					required={required}
					value={value}
				/>
				{clearIcon !== null && (
					<button
						className={`${baseClassName}__clear-button ${baseClassName}__button`}
						disabled={disabled}
						onClick={this.clear}
						onFocus={this.stopPropagation}
						type="button"
					>
						{clearIcon}
					</button>
				)}
				{clockIcon !== null && !disableClock && (
					<button
						className={`${baseClassName}__clock-button ${baseClassName}__button`}
						disabled={disabled}
						onClick={this.toggleClock}
						onFocus={this.stopPropagation}
						onBlur={this.resetValue}
						type="button"
					>
						{clockIcon}
					</button>
				)}
			</div>
		);
	}

	render() {
		const { className, disabled } = this.props;
		const { isOpen } = this.state;

		return (
			<div
				className={`${baseClassName} ${baseClassName}--${isOpen ? 'open' : 'closed'} ${baseClassName}--${
					disabled ? 'disabled' : 'enabled'
				} ${className ? className : ''}`}
				{...this.eventProps}
				onFocus={this.onFocus}
				ref={ref => {
					if (!ref) {
						return;
					}

					this.wrapper = ref;
				}}
			>
				{this.renderInputs()}
			</div>
		);
	}
}

const ClockIcon = (
	<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
		<g stroke="black" strokeWidth="2" fill="none">
			<circle cx="9.5" cy="9.5" r="7.5" />
			<path d="M9.5 4.5 v5 h4" />
		</g>
	</svg>
);

const ClearIcon = (
	<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
		<g stroke="black" strokeWidth="2">
			<line x1="4" y1="4" x2="15" y2="15" />
			<line x1="15" y1="4" x2="4" y2="15" />
		</g>
	</svg>
);

TimePicker.defaultProps = {
	clearIcon: ClearIcon,
	clockIcon: ClockIcon,
	isOpen: null,
	maxDetail: 'minute',
};

TimePicker.propTypes = {
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	clearIcon: PropTypes.node,
	clockClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	clockIcon: PropTypes.node,
	disableClock: PropTypes.bool,
	disabled: PropTypes.bool,
	isOpen: PropTypes.bool,
	locale: PropTypes.string,
	maxDetail: PropTypes.oneOf(allViews),
	maxTime: isTime,
	minTime: isTime,
	name: PropTypes.string,
	onChange: PropTypes.func,
	onClockClose: PropTypes.func,
	onClockOpen: PropTypes.func,
	required: PropTypes.bool,
	value: PropTypes.oneOfType([isTime, PropTypes.instanceOf(Date)]),
};

polyfill(TimePicker);
