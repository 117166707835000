import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { countryList } from './constants';
import { fieldPropType, isValid } from '../../Common/fields';
import { withCancelable } from '../../Common/components/cancelable';

class CustomerInfoFields extends Component {
	constructor(props) {
		super(props);
	}

	get zipLabel() {
		return this.props.isCanadianCurrency ? 'Postal Code' : 'ZIP';
	}

	get stateLabel() {
		return this.props.isCanadianCurrency ? 'Province' : 'State';
	}

	onChange = e => {
		const { name, value } = e.target;
		if (name === 'phoneNumber') {
			if (!/[^\d()+\-\s]/g.test(value) || value === '') {
				this.props.onChange({
					key: name,
					value: value,
				});
			}
		} else {
			this.props.onChange({
				key: name,
				value: value,
			});
		}
	};

	renderField = (key, defaultLabel, ignoreDataType, isCountry, inputProps = {}, requiredOverride = false) => {
		const {
			data,
			dataType,
			disabled,
			invalidClassName,
			requiredFields,
			customDisplayLabels,
			saveAsCustomer,
			transactionHiddenFields,
		} = this.props;
		const required =
			dataType == 'billing' && !disabled ? (
				<span className="required-field label--required" data-tooltip="Required">
					*
				</span>
			) : null;
		const hideField = transactionHiddenFields[key] && (ignoreDataType || (dataType !== 'billing' || !saveAsCustomer));
		return !hideField ? (
			<div className="newtransaction__item newtransaction__item--expandable">
				<label className="newtransaction__item__label newtransaction__item__label--expandable">
					{customDisplayLabels[key] || defaultLabel} {requiredFields[key] || requiredOverride ? required : null}
				</label>
				<div className="newtransaction__item__value newtransaction__item__value--expandable">
					{isCountry ? (
						<select
							name="country"
							className={`input input--med input--select ${(!isValid(data.country) && invalidClassName) || ''}`}
							value={data.country.value}
							onChange={this.onChange}
							disabled={disabled}
						>
							{countryList.map(country => (
								<option key={country.code} value={country.code} disabled={country.disabled}>
									{country.name}
								</option>
							))}
						</select>
					) : (
						<input
							type="text"
							name={key}
							className={`input input--med ${(!isValid(data[key]) && invalidClassName) || ''}`}
							placeholder={customDisplayLabels[key] || defaultLabel}
							value={data[key].value}
							onChange={this.onChange}
							disabled={disabled}
							{...inputProps}
						/>
					)}
				</div>
			</div>
		) : null;
	};

	render() {
		return (
			<div>
				<div>
					{this.renderField('firstName', 'First Name')}
					{this.renderField('lastName', 'Last Name')}
					{this.renderField('company', 'Company')}
					{this.renderField('address', 'Address', true)}
					{this.renderField('city', 'City', true)}
					{this.renderField('state', this.stateLabel, true)}
					{this.renderField('zip', this.zipLabel, true, false, { inputMode: 'numeric' })}
					{this.renderField('country', 'Country', true, true)}
					{this.renderField(
						'phoneNumber',
						'Phone Number',
						true,
						false,
						{ type: 'tel' },
						this.props.dataType === 'billing' && this.props.sendSmsReceipt
					)}
				</div>
			</div>
		);
	}
}

CustomerInfoFields.propTypes = {
	data: PropTypes.shape({
		firstName: fieldPropType(PropTypes.string).isRequired,
		lastName: fieldPropType(PropTypes.string).isRequired,
		company: fieldPropType(PropTypes.string).isRequired,
		address: fieldPropType(PropTypes.string).isRequired,
		city: fieldPropType(PropTypes.string).isRequired,
		state: fieldPropType(PropTypes.string).isRequired,
		zip: fieldPropType(PropTypes.string).isRequired,
		country: fieldPropType(PropTypes.string).isRequired,
		phoneNumber: fieldPropType(PropTypes.string).isRequired,
	}).isRequired,
	onChange: PropTypes.func,
	dataType: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	invalidClassName: PropTypes.string,
	requiredFields: PropTypes.object,
	customDisplayLabels: PropTypes.object,
	transactionHiddenFields: PropTypes.object,
	saveAsCustomer: PropTypes.bool,
	makePendingRequest: PropTypes.func,
	isCanadianCurrency: PropTypes.bool,
	sendSmsReceipt: PropTypes.bool,
};

export default withCancelable(CustomerInfoFields);
