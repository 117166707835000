import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import { find, toLower, map, every, size, each } from 'lodash';

import AddEditCustomerPaymentsAddEdit from './add-edit';
import { paymentMethod } from '../../../../../components/new-transaction/constants';

class AddEditCustomerPayments extends Component {
	constructor(props) {
		super(props);

		this.paymentsRef = createRef();
	}

	componentDidUpdate = () => {
		const { isCanadian, selectedTab } = this.props;
		if (selectedTab === paymentMethod.CHECK && isCanadian) {
			this.handleSwitchPaymentMethod({ target: { name: 'cc' } });
		}
	};

	toggleExpandCollapseAll = allExpanded => {
		this.props.onChange('expandAllPayments', '_meta.paymentExpanded', !allExpanded);
	};

	handleSwitchPaymentMethod = ({ target: { name } }) => {
		const { switchTab, selectedTab } = this.props;
		if (name !== selectedTab) {
			return switchTab(name);
		}
	};

	handleDelete = id => {
		this.props.handleDelete(id);
	};

	onChange = async (id, field, value) => {
		if (field === 'isDefaultPaymentMethod') {
			const { payments } = this.props;
			for (let i in payments) {
				const payment = payments[i];
				if (payment._meta.id !== id) {
					if (payment[field]) {
						await this.props.onChange(payment._meta.id, field, false, true);
					}
					if (payment._meta.tentativeDelete) {
						await this.props.onChange(payment._meta.id, '_meta.tentativeDelete', false);
					}
				} else if (!payment[field]) {
					await this.props.onChange(id, '_meta.deleted', false);
					await this.props.onChange(id, '_meta.tentativeDelete', false);
				}
			}
		}

		await this.props.onChange(id, field, value);
	};

	getShowExpandAll = () => {
		const { paymentMethods, selectedTab } = this.props;
		const ccTypes = [];
		const checkTypes = [];

		if (size(paymentMethods)) {
			each(paymentMethods, paymentMethod =>
				each(paymentMethod, method => {
					if (toLower(method.tokenType) === 'cc') {
						ccTypes.push(method);
					} else {
						checkTypes.push(method);
					}
				})
			);
		}
		if (toLower(selectedTab) === 'check') {
			return size(checkTypes) > 1;
		}

		return size(ccTypes) > 1;
	};

	savePayment = id => {
		this.props.savePayment(id);
	};

	render = () => {
		const {
			type,
			payments,
			selectedTab,
			refNum,
			customerId,
			requiredFields,
			errorMessages,
			advancedView,
			advancedViewByDefault,
			isCanadian,
			isExpanded,
			isViewOnly,
			renderAddNewPayment,
			handleOpenCloseModal,
			deactivateActiveSchedulesAndRemovePaymentMethod,
			accountTypeConstants,
		} = this.props;
		const showExpandAll = this.getShowExpandAll();
		const defaultPaymentMethod = find(payments, ({ paymentMethodId }) => !!paymentMethodId);
		const allExpanded = every(payments, ({ _meta: { paymentExpanded } }) => paymentExpanded);

		return (
			<Fragment>
				<div className="customer__tabs__holder">
					{(customerId || refNum) && (
						<div className="clearfix">
							{customerId && (
								<span className="type--wgt--medium type--color--text spc--right--sml pull">
									Customer ID: {customerId}
								</span>
							)}
							{refNum && <span className="type--wgt--medium type--color--text push">(#{refNum})</span>}
						</div>
					)}
					<ul className="customer__tabs customer__tabs--secondary type--xsml">
						<li className="customer__tabs__item customer__tabs__item--primary datatooltip--newtransaction">
							<button
								name="cc"
								onClick={this.handleSwitchPaymentMethod}
								type="button"
								className={`customer__tabs__link customer__tabs__link--primary ${
									selectedTab === 'cc' ? 'is-active' : ''
								}`}
								tabIndex="-1"
							>
								Credit Card
							</button>
						</li>
						{!isCanadian && (
							<li className="customer__tabs__item customer__tabs__item--primary">
								<button
									name="check"
									onClick={this.handleSwitchPaymentMethod}
									type="button"
									className={`customer__tabs__link customer__tabs__link--primary ${
										selectedTab === 'check' ? 'is-active' : ''
									}`}
									tabIndex="-1"
								>
									Check
								</button>
							</li>
						)}
					</ul>
				</div>
				<div className="spc--bottom--sml">
					{showExpandAll && (
						<div
							className="spc--right--sml spc--bottom--sml display--ib"
							onClick={() => this.toggleExpandCollapseAll(allExpanded)}
						>
							<a className="anchor anchor--primary type--sml type--underline">
								<span className="display--ib spc--right--nano align--v--middle">{`${
									allExpanded ? 'Collapse' : 'Expand'
								} all`}</span>
								<i className="icon icon--tiny icon--expand-vertical--primary icon--middle"></i>
							</a>
						</div>
					)}
					{renderAddNewPayment()}
					{map(errorMessages, (errorMessage, index) => (
						<div key={index} className="validation spc--bottom--lrg">
							<p className="type--error">{errorMessage}</p>
						</div>
					))}
				</div>
				{selectedTab === 'check' && !this.props.isAchEnabled() ? (
					<div className="spc--bottom--lrg type--center">
						<i className="icon icon--xlrg icon--ach-disabled" />
						<div className="type--wgt--light type--base">
							Allow your customers to pay directly to their bank account without credit card
						</div>
						<a
							className={`btn btn--primary btn--med spc--top--sml${advancedView ? '' : ' fullwidth'}`}
							rel="noopener noreferrer"
							target="_blank"
							href="https://www.cardknox.com/go"
						>
							Click here to apply for ACH
						</a>
					</div>
				) : (
					<Fragment>
						{payments && payments.length > 0 ? (
							map(payments, (item, index) => {
								const focusNew = index === 0 && !item.customerId;
								item._meta.index = index;

								return (
									<div
										style={toLower(item.tokenType) === selectedTab ? null : { display: 'none' }}
										key={item._meta.id}
										className="paymentPopupItem spc--bottom--med spc--bottom--lrg--last"
									>
										<AddEditCustomerPaymentsAddEdit
											type={type}
											focusOnMount={focusNew}
											key={item._meta.id}
											id={item._meta.id}
											hasMultiplePayments={payments.length > 1}
											payment={item}
											handleDelete={this.handleDelete}
											onChange={this.onChange}
											savePayment={this.savePayment}
											requiredFields={requiredFields}
											defaultPaymentMethodExists={!!defaultPaymentMethod}
											advancedView={advancedView}
											advancedViewByDefault={advancedViewByDefault}
											isCanadian={isCanadian}
											isExpanded={isExpanded}
											ref={item._meta.created ? this.paymentsRef : null}
											handleOpenCloseModal={handleOpenCloseModal}
											deactivateActiveSchedulesAndRemovePaymentMethod={deactivateActiveSchedulesAndRemovePaymentMethod}
											isViewOnly={isViewOnly}
											accountTypeConstants={accountTypeConstants}
											onPaymentAccountTypeChange={this.props.onPaymentAccountTypeChange}
										/>
									</div>
								);
							})
						) : (
							<div className="paymentsPopupNoItems spc--top--sml spc--bottom--sml">
								No {selectedTab === 'cc' ? 'credit card' : 'check'} payment methods linked to this customer
							</div>
						)}
					</Fragment>
				)}
			</Fragment>
		);
	};
}

AddEditCustomerPayments.propTypes = {
	type: PropTypes.string,
	payments: PropTypes.array,
	refNum: PropTypes.string,
	customerId: PropTypes.string,
	onChange: PropTypes.func,
	handleDelete: PropTypes.func.isRequired,
	savePayment: PropTypes.func.isRequired,
	selectedTab: PropTypes.string.isRequired,
	switchTab: PropTypes.func.isRequired,
	addNewPayment: PropTypes.func.isRequired,
	requiredFields: PropTypes.object,
	errorMessages: PropTypes.arrayOf(PropTypes.string),
	advancedView: PropTypes.bool.isRequired,
	advancedViewByDefault: PropTypes.bool.isRequired,
	isCanadian: PropTypes.bool,
	permissions: PropTypes.object,
	isExpanded: PropTypes.bool,
	isViewOnly: PropTypes.bool,
	isAchEnabled: PropTypes.func.isRequired,
	renderAddNewPayment: PropTypes.func.isRequired,
	handleOpenCloseModal: PropTypes.func.isRequired,
	deactivateActiveSchedulesAndRemovePaymentMethod: PropTypes.func.isRequired,
	paymentMethods: PropTypes.array,
	accountTypeConstants: PropTypes.object,
	onPaymentAccountTypeChange: PropTypes.func.isRequired,
};

export default AddEditCustomerPayments;
