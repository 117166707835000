import React from 'react';
import { map } from 'lodash';

import { MultiselectCardknoxFilter, StringFilter } from 'common/components/columns/filters';
import { ActiveComponent, UserStatus } from 'common/components/columns/formatters';
import { ColumnHeader } from 'common/components/columns/headers';
import PortalManagementActions from 'common/components/columns/formatters/PortalManagementActions';

const columns = [
	{
		key: 'username',
		name: 'Use­rname', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		resizable: true,
	},
	{
		key: 'lastLoginDate',
		name: 'Last Login Date',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		resizable: true,
	},
	{
		key: 'lastLoginIp',
		name: 'Last Login IP',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		resizable: true,
	},
	{
		key: 'status',
		name: 'Status',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: MultiselectCardknoxFilter,
		formatter: UserStatus,
		initWidth: 150,
		resizable: true,
	},
	{
		key: 'mfa',
		name: 'MFA',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		resizable: true,
	},
	{
		key: 'isAdmin',
		name: 'Is Admin',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: MultiselectCardknoxFilter,
		formatter: ActiveComponent,
		initWidth: 150,
		resizable: true,
	},
	{
		key: 'actions',
		name: '',
		sortable: false,
		hideable: false,
		visible: true,
		filterable: false,
		formatter: PortalManagementActions,
		getRowMetaData: row => row,
		initWidth: 50,
		fixWidth: true,
		hideOnExport: true,
	},
];

export const portalManagementColumns = map(columns, column => {
	column = {
		headerRenderer: <ColumnHeader />,
		...column,
	};
	return column;
});
