import React from 'react';

const AccountNumberTooltipComponent = props => {
	return (
		<i {...props} className="icon icon--tiny icon--info newtransaction__tooltip__trigger">
			<span className="newtransaction__tooltip newtransaction__tooltip--routing">
				<div className="check">
					<div className="row">
						<div className="col col-sml-7">
							<div className="check__line check__line--primary"></div>
						</div>
						<div className="col col-sml-5">
							<div className="group">
								<div className="check__label">&#36;</div>
								<div className="check__square"></div>
							</div>
						</div>
					</div>
					<div className="check__line check__line--secondary"></div>
					<div className="row">
						<div className="col col-sml-7">
							<div className="check__line check__line--tertiary"></div>
						</div>
						<div className="col col-sml-5">
							<div className="check__line check__line--tertiary"></div>
						</div>
					</div>
					<div className="row--alt">
						<div className="col-sml-6">
							<div className="check__number">0123451234</div>
						</div>
						<div className="col-sml-6">
							<div className="check__number is-focused">52184720082</div>
						</div>
					</div>
				</div>
				<p>
					Your checking account number, also called the demand deposit account (DDA) number, is located to the right of
					the ABA number on the bottom center of your bank checks.
				</p>
			</span>
		</i>
	);
};

export default AccountNumberTooltipComponent;
