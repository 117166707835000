import { startsWith } from 'lodash-es';
import partsCompare from './parts-compare';

const compareSemverBase = partsCompare('.', 2);

function isNewVersioningSchema(a, b) {
	return a && startsWith(a, '6') && b && startsWith(b, '2');
}

function compareSemver(a, b) {
	if (isNewVersioningSchema(a, b)) return -1;
	if (isNewVersioningSchema(b, a)) return 1;
	return compareSemverBase(a, b);
}

export default compareSemver;
