import React from 'react';
import { map, times, padStart } from 'lodash';

import { ColumnHeader, TooltipComponent } from 'common/components/columns/headers';
import { InputFormatter } from 'common/components/columns/formatters';
import { maxAllowedSalesTaxTooltip, bulkChargeCustomerNumberTooltip } from 'components/new-transaction/constants';

export const columns = [
	{
		key: 'action',
		name: 'Actions',
		sortable: false,
		hideable: false,
		visible: true,
		filterable: false,
		initWidth: 60,
		customWidth: 0,
	},
	{
		key: 'customerId',
		name: 'C­ustomer I­D', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		sortable: true,
		hideable: false,
		visible: true,
		filterable: false,
		isDefaultSorter: true,
		defaultSortDirection: 'DESC',
		initWidth: 130,
		resizable: true,
		order: 1,
	},
	{
		key: 'customerNumber',
		name: 'C­ustomer #', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		sortable: true,
		hideable: true,
		visible: false,
		filterable: false,
		formatter: InputFormatter,
		headerRenderer: <TooltipComponent tooltip={bulkChargeCustomerNumberTooltip} />,
		getRowMetaData: ({ customerNumber: value, customerId: dependentValue }) => ({
			value,
			dependentValue,
			placeholder: 'Customer #',
			name: 'customerNumber',
			disabled: true,
		}),
		initWidth: 150,
		customSettingsKey: 'customerNumber',
		resizable: true,
	},
	{
		key: 'amount',
		name: 'Amount',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: false,
		formatter: InputFormatter,
		getRowMetaData: ({ amount: value, customerId: dependentValue, currency, isMultiAmount, index }) => ({
			dependentValue,
			value,
			placeholder: `${currency}0`,
			name: 'amount',
			disabled: !isMultiAmount && index > 1,
			isNumberFormat: true,
		}),
		initWidth: 110,
		showOnMobile: true,
		customSettingsKey: 'amount',
		resizable: true,
	},
	{
		key: 'salesTax',
		name: 'Tax',
		sortable: true,
		hideable: false,
		visible: false,
		filterable: false,
		formatter: InputFormatter,
		headerRenderer: <TooltipComponent tooltip={maxAllowedSalesTaxTooltip} />,
		getRowMetaData: ({ salesTax: value, customerId: dependentValue, currency, onInfoHover, isMultiAmount, index }) => ({
			onInfoHover,
			dependentValue,
			value,
			placeholder: `${currency}0`,
			columnName: 'Tax',
			name: 'salesTax',
			isNumberFormat: true,
			disabled: !isMultiAmount && index > 1,
			isTax: true,
		}),
		initWidth: 240,
		showOnMobile: true,
		customSettingsKey: 'tax',
		resizable: true,
		displaySelectAllCheckbox: true,
		selectAllKey: 'includeSalesTax.feeAndTaxPermissions',
	},
	{
		key: 'convenienceFee',
		name: 'Electronic Transfer Fee',
		sortable: true,
		hideable: false,
		visible: false,
		filterable: false,
		formatter: InputFormatter,
		headerRenderer: <TooltipComponent tooltip={"This field was previously referred to as 'Convenience Fee'"} />,
		getRowMetaData: ({
			convenienceFee: value,
			customerId: dependentValue,
			currency,
			onInfoHover,
			isMultiAmount,
			index,
		}) => ({
			onInfoHover,
			dependentValue,
			value,
			placeholder: `${currency}0`,
			columnName: 'Electronic Transfer Fee',
			name: 'convenienceFee',
			isNumberFormat: true,
			isFee: true,
			disabled: !isMultiAmount && index > 1,
		}),
		initWidth: 190,
		showOnMobile: true,
		resizable: true,
		displaySelectAllCheckbox: true,
		selectAllKey: 'includeConvenience.feeAndTaxPermissions',
	},

	{
		key: 'billPhone',
		name: 'Pho­ne Number', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		sortable: true,
		hideable: true,
		visible: false,
		filterable: false,
		formatter: InputFormatter,
		getRowMetaData: ({ billPhone: value, customerId: dependentValue }) => ({
			value,
			dependentValue,
			placeholder: 'Phone Number',
			name: 'billPhone',
		}),
		initWidth: 150,
		customSettingsKey: 'billPhone',
		resizable: true,
	},
	{
		key: 'billMobile',
		name: 'Mo­bile Pho­ne Number', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		sortable: true,
		hideable: true,
		visible: false,
		filterable: false,
		formatter: InputFormatter,
		getRowMetaData: ({ billMobile: value, customerId: dependentValue }) => ({
			value,
			dependentValue,
			placeholder: 'Mobile Phone Number',
			name: 'billMobile',
		}),
		initWidth: 150,
		customSettingsKey: 'billMobile',
		resizable: true,
	},
	{
		key: 'billCompany',
		name: 'Company',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: false,
		formatter: InputFormatter,
		getRowMetaData: ({ billCompany: value, customerId: dependentValue }) => ({
			value,
			dependentValue,
			placeholder: 'Company',
			name: 'billCompany',
		}),
		initWidth: 150,
		customSettingsKey: 'company',
		resizable: true,
	},
	{
		key: 'billFirstName',
		name: 'F­irst­ N­ame', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		sortable: true,
		hideable: true,
		visible: false,
		filterable: false,
		formatter: InputFormatter,
		getRowMetaData: ({ billFirstName: value, customerId: dependentValue }) => ({
			value,
			dependentValue,
			placeholder: 'First Name',
			name: 'billFirstName',
		}),
		initWidth: 150,
		customSettingsKey: 'firstName',
		resizable: true,
	},
	{
		key: 'billLastName',
		name: 'L­ast­ N­ame', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		sortable: true,
		hideable: true,
		visible: false,
		filterable: false,
		formatter: InputFormatter,
		getRowMetaData: ({ billLastName: value, customerId: dependentValue }) => ({
			value,
			dependentValue,
			placeholder: 'Last Name',
			name: 'billLastName',
		}),

		initWidth: 150,
		customSettingsKey: 'lastName',
		resizable: true,
	},
	{
		key: 'PONumber',
		name: 'PO Number',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: false,
		formatter: InputFormatter,
		getRowMetaData: ({ poNumber: value, customerId: dependentValue }) => ({
			value,
			dependentValue,
			placeholder: 'PO Number',
			name: 'PONumber',
		}),
		initWidth: 150,
		customSettingsKey: 'PONumber',

		resizable: true,
	},
	{
		key: 'invoice',
		name: 'Invoice',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: false,
		formatter: InputFormatter,
		getRowMetaData: ({ invoice: value, customerId: dependentValue }) => ({
			value,
			dependentValue,
			placeholder: 'Invoice',
			name: 'invoice',
		}),
		initWidth: 150,
		customSettingsKey: 'invoice',
		resizable: true,
	},
	{
		key: 'description',
		name: 'Description',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: false,
		formatter: InputFormatter,
		getRowMetaData: ({ description: value, customerId: dependentValue }) => ({
			value,
			dependentValue,
			placeholder: 'Description',
			name: 'description',
		}),
		initWidth: 200,
		customSettingsKey: 'description',
		resizable: true,
	},

	{
		key: 'customerCustom02',
		name: 'Custom 02',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: false,
		formatter: InputFormatter,
		getRowMetaData: ({ custom02: value, customerId: dependentValue }) => ({
			value,
			dependentValue,
			placeholder: 'Custom 02',
			name: 'customerCustom02',
		}),
		initWidth: 150,
		customSettingsKey: 'custom2',
		resizable: true,
	},
	{
		key: 'customerCustom03',
		name: 'Custom 03',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: false,
		formatter: InputFormatter,
		getRowMetaData: ({ custom03: value, customerId: dependentValue }) => ({
			value,
			dependentValue,
			placeholder: 'Custom 03',
			name: 'customerCustom03',
		}),
		initWidth: 150,
		customSettingsKey: 'custom3',
		resizable: true,
	},
	...times(16, i => {
		const oneBasedIndex = padStart(i + 4, 2, 0);
		const customKey = `customerCustom${oneBasedIndex}`;
		const customName = `Custom ${oneBasedIndex}`;
		return {
			key: customKey,
			name: customName,
			sortable: true,
			hideable: true,
			visible: false,
			filterable: false,
			formatter: InputFormatter,
			getRowMetaData: ({ [customKey]: value, customerId: dependentValue }) => ({
				value,
				dependentValue,
				placeholder: customName,
				name: customKey,
			}),
			initWidth: 150,
			customSettingsKey: `custom${i + 1}`,
			resizable: true,
		};
	}),
	{
		key: 'sendReceipt',
		name: 'Email Notification',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: false,
		formatter: InputFormatter,
		headerRenderer: <TooltipComponent />,
		getRowMetaData: ({ sendReceipt: value, customerId: dependentValue }) => ({
			value,
			checked: value,
			dependentValue,
			label: 'Send',
			inputType: 'checkbox',
			name: 'sendReceipt',
		}),
		initWidth: 195,
		resizable: true,
		displaySelectAllCheckbox: true,
	},
	{
		key: 'email',
		name: 'Em­ail', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		sortable: true,
		hideable: false,
		visible: true,
		filterable: false,
		formatter: InputFormatter,
		getRowMetaData: ({ email: value, customerId: dependentValue }) => ({
			value,
			dependentValue,
			name: 'email',
			placeholder: 'Email',
		}),
		initWidth: 200,
		resizable: true,
	},
	{
		key: 'sendCopy',
		name: 'Send Receipt',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: false,
		formatter: InputFormatter,
		headerRenderer: <TooltipComponent />,
		getRowMetaData: ({ sendCopy: value, customerId: dependentValue }) => ({
			value,
			checked: value,
			dependentValue,
			label: 'Send me a copy',
			inputType: 'checkbox',
			name: 'sendCopy',
		}),
		initWidth: 160,
		resizable: true,
		displaySelectAllCheckbox: true,
	},
];

export const BulkChargeColumns = map(columns, column => {
	column = {
		headerRenderer: <ColumnHeader />,
		...column,
	};
	return column;
});
