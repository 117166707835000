import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { Modal } from 'common/components/modal/index';
import { withError } from 'common/components/error';

class ConfirmDeleteModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
		};
	}

	handleOpenModal = async () => {
		if (this.props.disabled) {
			return;
		}
		const newState = { visible: true };
		this.setState(newState);
	};

	handleCloseModal = async () => {
		const { onCancel } = this.props;
		const newState = {};
		newState.visible = false;
		await onCancel();
		this.setState(newState);
	};

	confirmationHandler = async () => {
		const { onConfirm, data } = this.props;
		const newState = {};
		newState.visible = false;
		await onConfirm(data);
		this.setState(newState);
	};

	render() {
		const { className, children, isLoading, shouldHideModal } = this.props;
		const { visible } = this.state;
		return (
			<React.Fragment>
				<button onClick={this.handleOpenModal} type="button" className={className}>
					{children}
				</button>
				<Modal
					isOpen={visible}
					onClose={this.handleCloseModal}
					overlayClassName={shouldHideModal() ? 'popup__hide' : 'popup__overlay'}
				>
					<div className="user__popup">
						<div className="popup__body">
							<p className="type--med">
								<span className="type--wgt--bold type--color--warning">Warning! </span>
								If you delete this key, any software that uses it will no longer work. Do you still want to delete?
							</p>
						</div>
						<div className="popup__footer">
							<button
								type="button"
								tabIndex="-1"
								className="btn btn--sml btn--ghost spc--right--xsml"
								onClick={this.handleCloseModal}
								disabled={isLoading}
							>
								No
							</button>
							<button
								type="button"
								tabIndex="-1"
								className="btn btn--sml btn--primary w--102p"
								onClick={this.confirmationHandler}
								disabled={isLoading}
							>
								Yes
							</button>
						</div>
					</div>
				</Modal>
			</React.Fragment>
		);
	}
}

ConfirmDeleteModal.defaultProps = {
	onCancel: noop,
};

ConfirmDeleteModal.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	onConfirm: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	children: PropTypes.any,
	className: PropTypes.string,
	data: PropTypes.object,
	shouldHideModal: PropTypes.func,
	disabled: PropTypes.bool,
};

export default withError(ConfirmDeleteModal);
