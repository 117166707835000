import { toLower, each } from 'lodash-es';
const checkIfError = (newState, oldData, type, callback) => {
	const { data, result, error, refNum } = oldData;
	if (data && (toLower(result) === 's' || error === 'Item does not exist')) {
		if (!error) {
			newState.oldData[type] = {
				...oldData,
			};
		}
		each(data, callback);
	} else if (toLower(error) === 'invalid: revision' || toLower(error) === 'item exists. revision cannot be 0') {
		const errorMessage = {
			isApiError: true,
			ref: refNum,
			message: error,
			success: false,
		};
		throw errorMessage;
	} else {
		const refString = ` (Ref# ${refNum})`;
		newState.errorMessages.push(`${error}${refNum ? refString : ''}`);
	}
};

export default checkIfError;
