import React from 'react';
import { string } from 'prop-types';

const LogoPreviewComponent = ({ logoUrl }) => (
	<div className="popup">
		<div className="popup__header">
			<div className="popup__header__title">Logo Preview</div>
		</div>
		<div className="popup__body popup__body--logo-preview">
			<img src={logoUrl} alt="Logo Image" />
		</div>
	</div>
);

LogoPreviewComponent.propTypes = {
	logoUrl: string.isRequried,
};

export default LogoPreviewComponent;
