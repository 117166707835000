import React, { Component } from 'react';
import { arrayOf, object, bool, string, array, func } from 'prop-types';
import { map } from 'lodash';

import { invokeIfFunction } from '../../utilities';

class SectionActions extends Component {
	render() {
		const { actions, disableAll, addFieldExpanded, hideDelete, hideAdd, createActionRef } = this.props;
		return (
			<div className="paymentsite__card__header__actions" ref={ref => createActionRef(ref, addFieldExpanded)}>
				{map(actions, ({ icon, action, tooltip, disabled, className, activeClass, isAdd }) => {
					if ((icon === 'delete' && hideDelete) || (isAdd && hideAdd)) return;
					return (
						<span
							key={invokeIfFunction(icon)}
							className={
								invokeIfFunction(disabled)
									? 'pos--rel datatooltip--paymentsite--action spc--left--xsml'
									: 'pos--rel spc--left--xsml'
							}
						>
							<button
								id={isAdd ? 'addButton' : 'actionsButton'}
								className={className || `btn btn--icon--tny btn--default ${addFieldExpanded ? activeClass || '' : ''}`}
								disabled={disableAll || invokeIfFunction(disabled)}
								data-tooltip={invokeIfFunction(tooltip)}
								type="button"
								onClick={action}
							>
								<i className={`icon icon--nano icon--middle icon--${invokeIfFunction(icon)}`}></i>
							</button>
						</span>
					);
				})}
			</div>
		);
	}
}

SectionActions.propTypes = {
	actions: arrayOf(object).isRequired,
	disableAll: bool,
	disableRemove: bool,
	addFieldExpanded: bool,
	hideDelete: bool,
	hideAdd: bool,
	label: string,
	availableFields: array,
	renderAvailableFields: func,
	createActionRef: func,
};
export default SectionActions;
