import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'common/components/modal/index';
class KeyProvider extends Component {
	constructor() {
		super();
		this.state = {
			visible: false,
			data: {},
			isCopied: false,
		};
	}

	show = data => {
		this.setState({
			visible: true,
			data: data,
		});
	};

	handleCloseModal = () => {
		this.setState({ visible: false, isCopied: false });
	};

	copy = e => {
		this.input.select();
		document.execCommand('copy');
		e.target.focus();
		this.setState({ isCopied: true });
	};

	render() {
		const { title } = this.props;
		const { data, visible, isCopied } = this.state;
		return (
			<div>
				<Modal
					isOpen={visible}
					onClose={this.handleCloseModal}
					shouldCloseOnOverlayClick={false}
					shouldCloseOnEsc={false}
				>
					<div className="user__popup">
						<div className="popup__header">
							<div className="popup__header__title">{title}</div>
						</div>
						<div className="popup__body">
							<div className="inputgroup spc--bottom--sml">
								<div className="inputgroup--aside padd--right--sml">Key</div>
								<div className="inputgroup--main">
									<input
										className="input input--med"
										type="textbox"
										value={data.key}
										ref={input => (this.input = input)}
										readOnly="true"
									></input>
								</div>
								{document.queryCommandSupported('copy') && (
									<div className="inputgroup--aside datatooltip--v--bottom datatooltip--right">
										<button
											className="btn btn--med btn--clear"
											data-tooltip={isCopied ? 'Copied!' : 'Copy the key'}
											onClick={this.copy}
										>
											<i className="icon icon--nano icon--copy--primary icon--middle"></i>
										</button>
									</div>
								)}
							</div>
							<div className="type--color--primary type--sml bg--chablis padd--sml border-radial">
								<div className="spc--bottom--sml">Please save this key securely to prevent unauthorized access.</div>
								<div>For security purposes, you will not be able to recover this again.</div>
								<div>However, you can create new keys at any time.</div>
							</div>
						</div>
						<div className="popup__footer popup__footer--styled">
							<button
								type="button"
								tabIndex="-1"
								className="btn btn--med btn--primary"
								onClick={this.handleCloseModal}
							>
								Done
							</button>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}
KeyProvider.propTypes = {
	title: PropTypes.string.isRequired,
};

export default KeyProvider;
