import React, { Component, createRef, Fragment } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';

import { Notification } from '../notifications';
import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';
import { withError } from '../error';
import { merchantRegistrationService } from '../../services';
import { FormErrors } from '../../utilities';
import { emailValidator } from '../../components/email-validator';
import { stateList } from '../../../components/new-transaction';
import principalService from '../../services/principalService';

class ClickToPayRegistrationPopup extends Component {
	constructor() {
		super();

		this.pageTopRef = createRef();
		this.focusInputRef = createRef();
		this.notificationRef = createRef();

		const principal = principalService.get();

		this.requiredFields = {
			environment: 'Environment',
			companyPrimaryLegalName: 'Legal Name',
			primaryWebsiteURL: 'Website',
			businessIdentificationType: 'Business Identification Type',
			businessIdentificationValue: 'Business Identification Value',
			companyCity: 'Company City',
			companyCountryCode: 'Company Country',
			primaryContactFirstName: 'Primary Contact First Name',
			primaryContactLastName: 'Primary Contact Last Name',
			primaryContactEmail: 'Primary Contact Email',
		};

		this.validationDetails = {
			companyPrimaryTradeName: {
				regex: /^[a-zA-Z0-9 '_.-~]*$/,
				'invalid regex': "DBA Name can only contain letters, numbers, and the following characters .- '~",
				length: {
					min: 0,
					max: 75,
				},
				'invalid length': 'DBA name cannot exceed 75 characters',
			},
			companyPrimaryLegalName: {
				regex: /^[a-zA-Z0-9 '_.-~]*$/,
				'invalid regex': "Legal Name can only contain letters, numbers, and the following characters .- '~",
				length: {
					min: 1,
					max: 75,
				},
				'invalid length': 'Legal Name cannot exceed 75 characters',
			},
			primaryWebsiteURL: {
				regex: /^(https?:\/\/|www\.)([\w-]+\.)+\w{2,4}(:\d+)?(\/[~\w#%./-]+)?(\?[\w% /&[\];=-]+)?\b$/,
				'invalid regex': 'Website must be a valid URL',
				length: {
					min: 1,
					max: 100,
				},
				'invalid length': 'Website cannot exceed 100 characters',
			},
			companyAddress1: {
				regex: /^[a-zA-Z0-9 '#,_/-]*$/,
				'invalid regex':
					"Company Address Line 1 can only contain letters, numbers, and the following characters '#,_:/-",
				length: {
					min: 0,
					max: 140,
				},
				'invalid length': 'Company Address Line 1 cannot exceed 140 characters',
			},
			companyAddress2: {
				regex: /^[a-zA-Z0-9 '#,_/-]*$/,
				'invalid regex':
					"Company Address Line 2 can only contain letters, numbers, and the following characters '#, _:/-",
				length: {
					min: 0,
					max: 140,
				},
				'invalid length': 'Company Address Line 2 cannot exceed 140 characters',
			},
			companyCity: {
				regex: /^[a-zA-Z0-9 '.-]*$/,
				'invalid regex': "Company City can only contain letters, numbers, and the following characters ' .-",
				length: {
					min: 1,
					max: 100,
				},
				'invalid length': 'Company City cannot exceed 100 characters',
			},
			companyPostalCode: {
				regex: /^[a-zA-Z0-9]*$/,
				'invalid regex': 'Company Postal Code can only contain letters and numbers',
				length: {
					min: 0,
					max: 7,
				},
				'invalid length': 'Company Postal Code cannot exceed 7 characters',
			},
			companyPhone: {
				regex: /^[0-9().+-]*$/,
				'invalid regex': 'Company Phone can only contain numbers and the following characters -+().',
				length: {
					min: 0,
					max: 16,
				},
				'invalid length': 'Company Phone cannot exceed 16 characters',
			},
			primaryContactFirstName: {
				regex: /^[a-zA-Z '`~".-]*$/,
				'invalid regex': 'Primary Contact First Name can only contain letters and the following characters \'`~".-',
				length: {
					min: 1,
					max: 256,
				},
				'invalid length': 'Primary Contact First Name cannot exceed 256 characters',
			},
			primaryContactLastName: {
				regex: /^[a-zA-Z '`~".-]*$/,
				'invalid regex': 'Primary Contact Last Name can only contain letters and the following characters \'`~".-',
				length: {
					min: 1,
					max: 256,
				},
				'invalid length': 'Primary Contact Last Name cannot exceed 256 characters',
			},
			primaryContactEmail: {
				regex: emailValidator,
				'invalid regex': 'Primary Contact Email must be a valid email address',
				length: {
					min: 1,
					max: 256,
				},
				'invalid length': 'Primary Contact Email cannot exceed a 256 characters',
			},
			primaryContactPhone: {
				regex: /^[0-9().+-]*$/,
				'invalid regex': 'Primary Contact Phone can only contain numbers and the following characters -+().',
				length: {
					min: 0,
					max: 16,
				},
				'invalid length': 'Primary Contact Phone cannot exceed 16 characters',
			},
			secondaryContactFirstName: {
				regex: /^[a-zA-Z '`~".-]*$/,
				'invalid regex': 'Secondary Contact First Name can only contain letters and the following characters \'`~".-',
				length: {
					min: 0,
					max: 256,
				},
				'invalid length': 'Secondary Contact First Name cannot exceed 256 characters',
			},
			secondaryContactLastName: {
				regex: /^[a-zA-Z '`~".-]*$/,
				'invalid regex': 'Secondary Contact Last Name can only contain letters and the following characters \'`~".-',
				length: {
					min: 0,
					max: 24,
				},
				'invalid length': 'Secondary Contact Last Name cannot exceed 24 characters',
			},
			secondaryContactEmail: {
				regex: emailValidator,
				'invalid regex': 'Secondary Contact Email must be a valid email address',
				length: {
					min: 0,
					max: 256,
				},
				'invalid length': 'Secondary Contact Email cannot exceed 256 characters',
			},
			secondaryContactPhone: {
				regex: /^[0-9().+-]*$/,
				'invalid regex': 'Secondary Contact Phone can only contain numbers and the following characters -+().',
				length: {
					min: 0,
					max: 16,
				},
				'invalid length': 'Secondary Contact Phone cannot exceed 16 characters',
			},
		};
		this.state = {
			errors: {},
			isError: false,
			isProcessing: false,
			fields: {
				environment: null,
				cardknoxMid: principal.idInfo.xMerchantID,
				companyPrimaryTradeName: principal.idInfo.xMerchantDBA,
				companyPrimaryLegalName: principal.idInfo.xMerchantDBA,
				primaryWebsiteURL: null,
				businessIdentificationType: null,
				businessIdentificationValue: null,
				estimatedTransactionVolume: null,
				companyAddress1: principal.idInfo.xMerchantAddress,
				companyAddress2: principal.idInfo.xMerchantAddress2,
				companyCity: principal.idInfo.xMerchantCity,
				companyStateProvinceCode: principal.idInfo.xMerchantState,
				companyPostalCode: principal.idInfo.xMerchantZip,
				companyCountryCode: null,
				companyPhone: principal.idInfo.xMerchantPhone,
				primaryContactFirstName: null,
				primaryContactLastName: null,
				primaryContactEmail: principal.idInfo.xMerchantEmail,
				primaryContactPhone: null,
				secondaryContactFirstName: null,
				secondaryContactLastName: null,
				secondaryContactEmail: null,
				secondaryContactPhone: null,
				profiles: [],
				relationships: [
					{
						externalClientId: null,
					},
				],
				threeDSConfigs: [
					{
						program: null,
						acquirerId: null,
						acquirerMerchantId: null,
						mcc: null,
						activate3DS: false,
						currency: null,
					},
				],
			},
		};
	}

	componentDidMount() {
		this.focusInputRef.current.focus();
	}

	get fieldClassName() {
		return 'spc--bottom--sml--alt';
	}

	handleChange = ({ target: { name, value } }) => {
		const { fields } = this.state;
		const newState = { fields: { ...fields } };

		if (name.indexOf('.') > -1) {
			this.handleSectionChange({ newState, name, value });
		} else {
			newState.fields[name] = value;
		}

		this.setState(newState);
	};

	handleSectionChange = params => {
		const { newState, name, value } = params;

		let nameSplit = name.split('.');
		newState.fields[nameSplit[0]][0][nameSplit[1]] = value;
	};

	handleBlur = ({ target: { name } }) => {
		this.validateInput(name);
	};

	validateInput = name => {
		let { fields, errors } = this.state;
		if (name) {
			if (name in this.requiredFields) {
				if (fields[name]) {
					delete errors[name];
				} else {
					errors[name] = `${this.requiredFields[name]} is required`;
				}
			}
		} else {
			errors = {};
			Object.keys(this.requiredFields).forEach(fldName => {
				if (!fields[fldName]) {
					errors[fldName] = `${this.requiredFields[fldName]} is required`;
				}
			});
		}
		this.validation(name, errors);
		this.validateBusinessIdentificationValue(name, errors);
		this.validateStateProvinceCode(name, errors);
		this.setState({ errors });
		return isEmpty(errors);
	};

	validateLength = (testString, length) => {
		let validateLength = false;
		if (testString.length >= length.min && testString.length <= length.max) {
			validateLength = true;
		}
		return validateLength;
	};

	validation = (name, errors) => {
		if (name) {
			this.validateFields(name, errors);
		} else {
			Object.keys(this.validationDetails).forEach(fieldName => {
				this.validateFields(fieldName, errors);
			});
		}
	};

	validateFields = (fieldName, errors) => {
		const { fields } = this.state;
		let fieldValidationDetails = this.validationDetails[fieldName];
		if (fieldValidationDetails != null) {
			const { regex, length } = fieldValidationDetails;
			if (fields[fieldName]) {
				if (!regex.test(fields[fieldName])) {
					errors[fieldName] = fieldValidationDetails['invalid regex'];
				} else if (!this.validateLength(fields[fieldName], length)) {
					errors[fieldName] = fieldValidationDetails['invalid length'];
				} else {
					delete errors[fieldName];
				}
			}
		}
	};

	validateBusinessIdentificationValue = (name, errors) => {
		const { fields } = this.state;
		let businessIdentificationFields = ['businessIdentificationType', 'businessIdentificationValue'];
		let valueField = 'businessIdentificationValue';
		let typeField = 'businessIdentificationType';
		if (!name || businessIdentificationFields.includes(name)) {
			if (fields[typeField]) {
				delete errors[typeField];
				const regex = this.getBusinessIdentificationValueRegex(fields[typeField], fields['companyCountryCode']);
				if (regex.test(fields[valueField])) {
					delete errors[typeField];
				} else {
					errors[valueField] = 'Business Identification Value is invalid';
				}
			} else {
				errors[typeField] = 'Business Identification Type is required';
			}
		}
	};

	getBusinessIdentificationValueRegex = (businessIdentificationType, countryCode) => {
		switch (businessIdentificationType) {
			case 'BID':
				return /^\d{8}$/;
			case 'EIN':
			case 'SSN':
			case 'SIN':
				return /^\d{9}$/;
			case 'BN':
				return /^\d{9}[a-zA-Z0-9]{5}$/;
			case 'RFC':
				return /^[a-zA-Z0-9]{12,13}$/;
			case 'VAT':
				return this.getVatRegex(countryCode);
		}
	};

	getVatRegex = countryCode => {
		let pattern = '\\d{10}';
		let regex = '';
		switch (countryCode) {
			case 'IE':
				regex = `^(${pattern}|${countryCode}[a-zA-Z0-9]{8})$`;
				break;
			case 'ES':
			case 'GB':
				regex = `^(${pattern}|${countryCode}[a-zA-Z0-9]{9})$`;
				break;
			case 'PL':
				regex = `^(${pattern}|${countryCode}[a-zA-Z0-9]{10})$`;
				break;
			case 'FR':
				regex = `^(${pattern}|${countryCode}[a-zA-Z0-9]{11})$`;
				break;
			case 'AE':
			case 'SA':
				regex = `^(${pattern}|${countryCode}\\d{15})$`;
				break;
			case 'UA':
				regex = `^(${pattern}|${countryCode}(\\d{9}|\\d{10}|\\d{12}))$`;
				break;
			default:
				regex = `^${pattern}$`;
		}
		return new RegExp(regex);
	};

	validateStateProvinceCode = (name, errors) => {
		const { fields } = this.state;
		let stateProvinceField = 'companyStateProvinceCode';
		let countryCodeField = 'companyCountryCode';
		let stateCountryFields = ['companyStateProvinceCode', 'companyCountryCode'];
		if (!name || stateCountryFields.includes(name)) {
			if (fields[countryCodeField]) {
				delete errors[countryCodeField];
				let province = false;
				stateList.forEach(stateDetails => {
					if (stateDetails.code == fields[stateProvinceField] && stateDetails.province) {
						province = true;
					}
				});
				if ((fields[countryCodeField] == 'CA' && province) || (fields[countryCodeField] == 'US' && !province)) {
					delete errors[stateProvinceField];
				} else {
					errors[stateProvinceField] = 'Company State is invalid';
				}
			} else {
				errors[countryCodeField] = 'Company Country is required';
			}
		}
	};

	scrollToTop = () => {
		const node = this.pageTopRef;
		node && node.current && node.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
	};

	submit = async e => {
		e && e.preventDefault();

		if (this.state.isProcessing) {
			return;
		}

		if (!this.validateInput()) {
			this.scrollToTop();
			return;
		}

		const addNotification = get(this.notificationRef, 'current.addNotification');
		try {
			this.setState({ isProcessing: true });
			const { companyPrimaryLegalName } = this.state.fields;
			await merchantRegistrationService.registerClickToPay(this.state.fields);
			this.props.addNotification({
				message: `Merchant '${companyPrimaryLegalName}' has been registered successfully!`,
				success: true,
			});
			this.props.closeModal();
		} catch (e) {
			this.setState({ isError: true });
			const notification = this.props.handleError(e, { delayMessage: true });
			addNotification({
				...notification,
				onClose: async () => {
					this.setState({ isError: false, isProcessing: false });
				},
			});
		}
	};

	renderLoader = () => {
		return (
			<div className="loader__holder">
				<Fragment>
					<div className="spc--bottom--sml type--center">Please wait, this may take a few seconds...</div>
					<div className="loader__spinner loader__spinner--click-to-pay"></div>
				</Fragment>
			</div>
		);
	};

	render = () => {
		const { fields } = this.state;
		const { errors, isProcessing, isError } = this.state;
		const mainClassName = `popup  ${isError ? 'popup--click-to-pay--error' : 'popup--click-to-pay'}`;
		const required = (
			<span className="required-field label--required" data-tooltip="Required">
				*
			</span>
		);
		const getInvalidClassname = name => {
			return errors[name] ? ' is-invalid' : '';
		};

		return (
			<Fragment>
				<div className="popup popup--click-to-pay--notification">
					<Notification ref={this.notificationRef} />
				</div>
				<div className={mainClassName}>
					<div className="popup__header">
						<div className="popup__header__title">Click-To-Pay Merchant Registration</div>
					</div>
					<div className="popup__body">
						{isProcessing ? (
							this.renderLoader()
						) : (
							<Fragment>
								<span ref={this.pageTopRef}></span>
								<form id="clcik-to-pay-form" onSubmit={this.submit}>
									<KeyboardEventHandler handleKeys={['enter']} onKeyEvent={() => this.submit()}>
										{!isEmpty(errors) && (
											<div className="spc--bottom--med">
												<FormErrors errors={errors} />
											</div>
										)}
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="environment">
												Environment {required}
											</label>
											<select
												ref={this.focusInputRef}
												className={`input input--select input--sml${getInvalidClassname('environment')}`}
												name="environment"
												id="environment"
												value={fields.environment}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											>
												<option value="">Select...</option>
												<option value="Production">Production</option>
												<option value="Sandbox">Sandbox</option>
											</select>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="companyPrimaryTradeName">
												DBA Name
											</label>
											<input
												className={`input input--sml${getInvalidClassname('companyPrimaryTradeName')}`}
												type="text"
												name="companyPrimaryTradeName"
												value={fields.companyPrimaryTradeName}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="companyPrimaryLegalName">
												Legal Name {required}
											</label>
											<input
												className={`input input--sml${getInvalidClassname('companyPrimaryLegalName')}`}
												type="text"
												name="companyPrimaryLegalName"
												value={fields.companyPrimaryLegalName}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="primaryWebsiteURL">
												Website {required}
											</label>
											<input
												className={`input input--sml${getInvalidClassname('primaryWebsiteURL')}`}
												type="text"
												id="primaryWebsiteURL"
												name="primaryWebsiteURL"
												value={fields.primaryWebsiteURL}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="businessIdentificationType">
												Business Identification Type {required}
											</label>
											<select
												className={`input input--select input--sml${getInvalidClassname('businessIdentificationType')}`}
												name="businessIdentificationType"
												id="businessIdentificationType"
												value={fields.businessIdentificationType}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											>
												<option value="">Select...</option>
												<option value="BID">Business Identification Number</option>
												<option value="EIN">US Federal Tax ID</option>
												<option value="BN">Canadian Business Number</option>
												<option value="SSN">US Social Security Number</option>
												<option value="SIN">Canadian Social Insurance Number</option>
											</select>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="businessIdentificationValue">
												Business Identification Value {required}
											</label>
											<input
												className={`input input--sml${getInvalidClassname('businessIdentificationValue')}`}
												type="text"
												id="businessIdentificationValue"
												name="businessIdentificationValue"
												value={fields.businessIdentificationValue}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="estimatedTransactionVolume">
												Estimated Transaction Volume
											</label>
											<select
												className={`input input--select input--sml${getInvalidClassname('estimatedTransactionVolume')}`}
												name="estimatedTransactionVolume"
												id="estimatedTransactionVolume"
												value={fields.estimatedTransactionVolume}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											>
												<option value="">Select...</option>
												<option value="PCI_Level_1">&lt;20,000</option>
												<option value="PCI_Level_2">20,000-999,999</option>
												<option value="PCI_Level_3">1 million-6 million</option>
												<option value="PCI_Level_4">&gt;6 million</option>
											</select>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="companyAddress1">
												Company Address Line 1
											</label>
											<input
												className={`input input--sml${getInvalidClassname('companyAddress1')}`}
												type="text"
												id="companyAddress1"
												name="companyAddress1"
												value={fields.companyAddress1}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="companyAddress2">
												Company Address Line 2
											</label>
											<input
												className={`input input--sml${getInvalidClassname('companyAddress2')}`}
												type="text"
												id="companyAddress2"
												name="companyAddress2"
												value={fields.companyAddress2}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="companyCity">
												Company City {required}
											</label>
											<input
												className={`input input--sml${getInvalidClassname('companyCity')}`}
												type="text"
												id="companyCity"
												name="companyCity"
												value={fields.companyCity}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="companyStateProvinceCode">
												Company State
											</label>
											<select
												className={`input input--select input--sml${getInvalidClassname('companyStateProvinceCode')}`}
												name="companyStateProvinceCode"
												id="companyStateProvinceCode"
												value={fields.companyStateProvinceCode}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											>
												{stateList.map(stateDetails => (
													<option value={stateDetails.code} key={stateDetails.code}>
														{stateDetails.name}
													</option>
												))}
											</select>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="comapnyPostalCode">
												Company Postal Code
											</label>
											<input
												className={`input input--sml${getInvalidClassname('companyPostalCode')}`}
												type="text"
												id="companyPostalCode"
												name="companyPostalCode"
												value={fields.companyPostalCode}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="companyCountryCode">
												Company Country {required}
											</label>
											<select
												className={`input input--select input--sml${getInvalidClassname('companyCountryCode')}`}
												name="companyCountryCode"
												id="companyCountryCode"
												value={fields.companyCountryCode}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											>
												<option value="">Select...</option>
												<option value="US">United States</option>
												<option value="CA">Canada</option>
											</select>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="companyPhone">
												Company Phone
											</label>
											<input
												className={`input input--sml${getInvalidClassname('companyPhone')}`}
												type="text"
												id="companyPhone"
												name="companyPhone"
												value={fields.companyPhone}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="primaryContactFirstName">
												Primary Contact First Name {required}
											</label>
											<input
												className={`input input--sml${getInvalidClassname('primaryContactFirstName')}`}
												type="text"
												id="primaryContactFirstName"
												name="primaryContactFirstName"
												value={fields.primaryContactFirstName}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="primaryContactLastName">
												Primary Contact Last Name {required}
											</label>
											<input
												className={`input input--sml${getInvalidClassname('primaryContactLastName')}`}
												type="text"
												id="primaryContactLastName"
												name="primaryContactLastName"
												value={fields.primaryContactLastName}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="primaryContactEmail">
												Primary Contact Email {required}
											</label>
											<input
												className={`input input--sml${getInvalidClassname('primaryContactEmail')}`}
												type="text"
												id="primaryContactEmail"
												name="primaryContactEmail"
												value={fields.primaryContactEmail}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="primaryContactPhone">
												Primary Contact Phone
											</label>
											<input
												className={`input input--sml${getInvalidClassname('primaryContactPhone')}`}
												type="text"
												id="primaryContactPhone"
												name="primaryContactPhone"
												value={fields.primaryContactPhone}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="secondaryContactFirstName">
												Secondary Contact First Name
											</label>
											<input
												className={`input input--sml${getInvalidClassname('secondaryContactFirstName')}`}
												type="text"
												id="secondaryContactFirstName"
												name="secondaryContactFirstName"
												value={fields.secondaryContactFirstName}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="secondaryContactLastName">
												Secondary Contact Last Name
											</label>
											<input
												className={`input input--sml${getInvalidClassname('secondaryContactLastName')}`}
												type="text"
												id="secondaryContactLastName"
												name="secondaryContactLastName"
												value={fields.secondaryContactLastName}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="secondaryContactEmail">
												Secondary Contact Email
											</label>
											<input
												className={`input input--sml${getInvalidClassname('secondaryContactEmail')}`}
												type="text"
												id="secondaryContactEmail"
												name="secondaryContactEmail"
												value={fields.secondaryContactEmail}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="secondaryContactPhone">
												Secondary Contact Phone
											</label>
											<input
												className={`input input--sml${getInvalidClassname('secondaryContactPhone')}`}
												type="text"
												id="secondaryContactPhone"
												name="secondaryContactPhone"
												value={fields.secondaryContactPhone}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="externalClientId">
												External Client Id
											</label>
											<input
												className={`input input--sml${getInvalidClassname('externalClientId')}`}
												type="text"
												id="externalClientId"
												name="relationships.externalClientId"
												value={fields.relationships[0].externalClientId}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="program">
												3D Secure Program
											</label>
											<input
												className={`input input--sml${getInvalidClassname('program')}`}
												type="text"
												id="program"
												name="threeDSConfigs.program"
												value={fields.threeDSConfigs[0].program}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="acquirerId">
												3D Secure Acquirer Id
											</label>
											<input
												className={`input input--sml${getInvalidClassname('acquirerId')}`}
												type="text"
												id="acquirerId"
												name="threeDSConfigs.acquirerId"
												value={fields.threeDSConfigs[0].acquirerId}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="acquirerMerchantId">
												3D Secure Acquirer Merchant Id
											</label>
											<input
												className={`input input--sml${getInvalidClassname('acquirerMerchantId')}`}
												type="text"
												id="acquirerMerchantId"
												name="threeDSConfigs.acquirerMerchantId"
												value={fields.threeDSConfigs[0].acquirerMerchantId}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="mcc">
												3D Secure MCC
											</label>
											<input
												className={`input input--sml${getInvalidClassname('mcc')}`}
												type="text"
												id="mcc"
												name="threeDSConfigs.mcc"
												value={fields.threeDSConfigs[0].mcc}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="activate3DS">
												Activate 3DS
											</label>
											<input
												className={`input input--sml${getInvalidClassname('activate3DS')}`}
												type="text"
												id="activate3DS"
												name="threeDSConfigs.activate3DS"
												value={fields.threeDSConfigs[0].activate3DS}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
										<div className={this.fieldClassName}>
											<label className="grid-sidebar__main__label" htmlFor="currency">
												Currency
											</label>
											<input
												className={`input input--sml${getInvalidClassname('currency')}`}
												type="text"
												id="currency"
												name="threeDSConfigs.currency"
												value={fields.threeDSConfigs[0].currency}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
											/>
										</div>
									</KeyboardEventHandler>
								</form>
							</Fragment>
						)}
					</div>
					<div className="popup__footer popup__footer--styled">
						<button
							type="submit"
							value="Submit"
							className="btn btn--primary btn--med popup__footer__process--main"
							onClick={this.submit}
							disabled={isProcessing}
						>
							Submit
						</button>
					</div>
				</div>
			</Fragment>
		);
	};
}

ClickToPayRegistrationPopup.propTypes = {
	handleError: PropTypes.func,
	addNotification: PropTypes.func,
	closeModal: PropTypes.func,
};

export default withError(ClickToPayRegistrationPopup);
