import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withLoader } from './../../../Common/components/loader';
import { withError } from './../../../Common/components/error';
import { withCancelable } from './../../../Common/components/cancelable';
import { kvaasService, paymentEngineSettingsService, principalService } from 'common/services';
import {
	keys,
	map,
	replace,
	split,
	assign,
	head,
	nth,
	includes,
	filter,
	get,
	find,
	isEmpty,
	join,
	uniq,
	orderBy,
	toLower,
	toString,
	upperFirst,
	trim,
	compact,
	slice,
	each,
} from 'lodash-es';
import { kvaasResources } from 'common/utilities';

const arrowClassNames = {
	0: 'sort--grey',
	1: 'sort--desc',
	2: 'sort--asc',
};

const orderConstants = {
	0: '',
	1: 'asc',
	2: 'desc',
};
class PaymentEngineComponent extends Component {
	constructor(props) {
		super(props);
		this.state = this.initialState;
	}

	async componentDidMount() {
		try {
			this.showLoader(true);
			const rows = await this.fetchPaymentEngines();
			await this.fetchKvaas();
			this.setState({
				rows,
			});
		} catch (e) {
			this.handleError(e);
		}
		this.showLoader(false);
	}

	fetchPaymentEngines = async () => {
		return this.props.makePendingRequest(paymentEngineSettingsService.list());
	};

	get initialState() {
		return {
			principal: principalService.get(),
			user: null,
			rows: [],
			editMode: false,
			isDirty: false,
			collapsedSettingsMessages: { data: {} },
			activeFilters: {
				keyFilter: '',
				valueFilter: '',
				orderDirection: 1,
				orderName: 'key',
			},
		};
	}

	showLoader = this.props.showLoader;
	handleError = this.props.handleError;

	fetchKvaas = async () => {
		try {
			const [collapsedSettingsMessages] = await this.props.makePendingRequest(
				kvaasService.get(kvaasResources.collapsedSettingsMessages),
				'kvaas'
			);
			const collapsed = get(collapsedSettingsMessages, 'data.paymentEngineMessageCollapsed', false);
			this.setState({ collapsedSettingsMessages, expanded: !collapsed });
		} catch (e) {
			this.props.handleError(e);
		}
	};
	selectSoftware = ({ target: { value } }) => {
		this.setState(
			{
				software: value,
			},
			this.onBeforeSelectUser
		);
	};

	selectComputer = ({ target: { value } }) => {
		this.setState(
			{
				computer: value,
				newComputer: false,
				newSettings: [],
			},
			this.onBeforeSelectUser
		);
	};

	onBeforeSelectUser = async () => {
		let { software, computer, rows } = this.state;
		if (isEmpty(find(rows, row => includes(row, join([software, computer], '_'))))) {
			computer = nth(split(head(filter(rows, row => includes(row, software))), /[_/]/g), -1);
		}
		if (software && computer) {
			this.selectUser([software, computer]);
		}
	};

	selectUser = async (info = this.state.currentKey) => {
		const { newComputer } = this.state;
		if (newComputer) return;
		this.showLoader(true);
		try {
			const selectedUserData = await this.props.makePendingRequest(paymentEngineSettingsService.load(...info));
			this.setCurrentKey([...info]);
			await this.setSettings(selectedUserData);
			this.orderKeys();
		} catch (e) {
			this.handleError(e);
		}
		this.showLoader(false);
	};

	setEditMode = editMode => {
		this.setState({
			editMode,
		});
	};

	setCurrentKey = currentKey => {
		this.setState({ currentKey });
	};
	setStateAsync = async newState => {
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};
	setSettings = async (xSettings, mappedSetting) => {
		const mappedSettings = mappedSetting || this.mapKeys(xSettings);
		await this.setStateAsync({ xSettings, mappedSettings, isDirty: true });
	};

	getChangeSettings = ({ target: { value, checked } }, key, isCheckbox) => {
		const newSetting = {};
		const { xSettings, mappedSettings } = this.state;
		const newValue = isCheckbox ? upperFirst(toString(checked)) : value;

		newSetting[key] = newValue;
		this.setSettings(assign({}, xSettings, newSetting), mappedSettings);
	};
	reloadData = () => {
		let { software, computer } = this.state;
		if (software && computer) {
			this.selectUser([software, computer]);
		}
	};

	mapNewComputer = computer => {
		const newXSettings = {
			ddb_revision: 0,
		};
		each(computer, setting => (newXSettings[setting.keyValue] = setting.value));
		return newXSettings;
	};

	onSave = async () => {
		this.showLoader(true);

		const { currentKey, xSettings, newComputer, newSettings, xUserName, software, computer } = this.state;
		const settings = newComputer ? this.mapNewComputer(newSettings) : xSettings;		
		const key = newComputer ? [software, xUserName] : currentKey;
		const selectComputer = newComputer ? xUserName : computer;

		try {
			const result = await this.props.makePendingRequest(paymentEngineSettingsService.save(settings, ...key));
			const rows = await this.fetchPaymentEngines();
			this.setSettings(assign({}, xSettings, result));
			this.setEditMode(false);

			this.setState(
				{ isDirty: false, newComputer: false, newSettings: [], xUserName: '', rows, computer: selectComputer },
				() => this.selectUser(key)
			);
		} catch (e) {
			if (e.message === 'Invalid: DDB_Revision not current. Reload settings and try again.') {
				e.message = 'Settings out of date, \nreload and try again';
			}
			this.handleError(e);
		}
		this.showLoader(false);
	};

	onError = e => this.props.handleError(e);

	mapState = (data, oldData) => {
		return {
			oldData,
			...kvaasResources.collapsedSettingsMessages,
			newData: {
				revision: 0,
				data,
			},
		};
	};

	handleUserNameChange = ({ target: { value } }) => {
		this.setState({
			xUserName: value,
		});
	};

	handleSort = name => {
		const { activeFilters } = this.state;
		const { orderDirection, orderName } = activeFilters;

		if (orderDirection === 0) {
			activeFilters.orderDirection = 1;
		} else if (orderDirection === 1) {
			activeFilters.orderDirection = 2;
		} else if (orderDirection === 2) {
			activeFilters.orderDirection = 0;
		}

		// switch order direction when a different column is sorted
		if (orderName && name !== orderName) {
			activeFilters.orderDirection = 1;
		}
		activeFilters.orderName = name;

		this.setState(
			{
				activeFilters,
			},
			() => {
				this.orderKeys();
			}
		);
	};
	handleFilterChange = ({ target: { name, value } }) => {
		const { activeFilters } = this.state;
		activeFilters[name] = trim(toLower(value));
		this.setState({
			activeFilters,
		});
	};

	handleNotification = () => {
		const { collapsedSettingsMessages, expanded, saving } = this.state;
		if (saving) {
			return;
		}
		const mappedState = this.mapState(
			{ ...collapsedSettingsMessages.data, paymentEngineMessageCollapsed: expanded },
			collapsedSettingsMessages
		);
		this.setState({ expanded: !expanded, saving: true }, async () => {
			try {
				const [collapsedSettingsMessages] = await this.props.makePendingRequest(kvaasService.save(mappedState));
				this.setState({ saving: false, collapsedSettingsMessages });
			} catch (e) {
				this.props.handleError(e);
			}
		});
	};
	formatXkey = xKey => {
		return `${join(slice(xKey, 0, 3), '')}*********${join(slice(xKey, xKey.length - 4), '')}`;
	};

	addNewComputer = () => {
		this.setState({
			newComputer: true,
			newSettings: [{ keyValue: '', value: '' }],
			isDirty: true,
			computer: null,
		});
	};
	addNewSetting = () => {
		const { newSettings } = this.state;
		newSettings.push({ keyValue: '', value: '' });
		this.setState({
			newSettings,
		});
	};

	deleteSetting = index => {
		const { newSettings } = this.state;
		newSettings.splice(index, 1);
		this.setState({
			newSettings,
		});
	};

	renderCell = (editMode, key) => {
		const { xSettings } = this.state;
		const value = xSettings[key];
		const xKey = key === 'xKey';
		const displayValue = xKey ? this.formatXkey(value) : value;
		return editMode && !xKey ? this.renderEditCell(key, value) : this.renderPreviewCell(displayValue);
	};

	renderPreviewCell = value => {
		const isCheckbox = includes(['true', 'false'], toLower(value));
		return isCheckbox ? (
			<td className="dtable__cell">
				<input
					type="checkbox"
					className="input--check input--check--no-label"
					checked={toLower(value) === 'true'}
					readOnly
				/>
				<label></label>
			</td>
		) : (
			<td className="dtable__cell">{value}</td>
		);
	};

	renderEditCell = (key, value, isNewComputer, isKey, index) => {
		const onChange = isNewComputer ? this.changeNewKeyValue : this.getChangeSettings;
		const deleteIcon = (
			<button
				data-tooltip="Delete row"
				onClick={() => this.deleteSetting(index)}
				className="btn btn--med btn--clear spc--left--sml"
			>
				<i className="icon icon--tiny icon--middle icon--delete"></i>
			</button>
		);
		const displayDeleteIcon = isNewComputer && !isKey && deleteIcon;
		if (includes(['true', 'false'], toLower(value))) {
			return (
				<td className="dtable__cell">
					<div className="flex--primary">
						<input
							type="checkbox"
							className="input--check input--check--no-label"
							checked={toLower(value) === 'true'}
							onChange={e => onChange(e, key, true, isKey, index)}
							id={key}
						/>
						<label htmlFor={key}></label>
						{displayDeleteIcon}
					</div>
				</td>
			);
		}

		return (
			<td className="dtable__cell">
				<div className="flex--primary">
					<div className="flex--grow--1">
						<input className="input input--sml" value={value} onChange={e => onChange(e, key, false, isKey, index)} />
					</div>
					{displayDeleteIcon}
				</div>
			</td>
		);
	};

	mapKeys = xSettings => {
		return map(keys(xSettings));
	};

	orderKeys = () => {
		const { xSettings, mappedSettings, activeFilters } = this.state;
		const orderedSettings = orderBy(
			mappedSettings,
			orderKey => (activeFilters.orderName === 'key' ? orderKey : xSettings[orderKey]),
			orderConstants[activeFilters.orderDirection]
		);
		this.setState({ mappedSettings: orderedSettings });
	};

	renderEmpty = () => {
		const { activeFilters } = this.state;
		return activeFilters['keyFilter'] || activeFilters['valueFilter'] ? (
			<div className="message message--default spc--bottom--sml spc--left--med">No Data</div>
		) : null;
	};
	renderNewSettings = () => {
		const { newSettings, newComputer } = this.state;
		if (!newComputer) return;
		return map(newSettings, ({ keyValue, value, id }, i) => {
			return (
				<tr key={id} className="dtable__row">
					{this.renderEditCell(keyValue, keyValue, true, true, i)}
					{this.renderEditCell(keyValue, value, true, false, i)}
				</tr>
			);
		});
	};

	changeNewKeyValue = ({ target: { value } }, keyValue, isCheckbox, isKey, index) => {
		const { newSettings } = this.state;
		const updatedSetting = newSettings[index];
		if (isKey) {
			updatedSetting.keyValue = value;
		} else {
			updatedSetting.value = value;
		}
		newSettings[index] = updatedSetting;
		this.setState({
			newSettings,
		});
	};
	renderSettingsList = () => {
		const {
			xSettings,
			editMode,
			newComputer,
			mappedSettings,
			activeFilters: { keyFilter, valueFilter },
		} = this.state;
		if (newComputer) return [];
		const settings = compact(
			map(mappedSettings, key => {
				const formattedKey = replace(key, /_/g, ' ');
				if (
					(keyFilter && !includes(toLower(formattedKey), keyFilter)) ||
					(valueFilter && !includes(toLower(xSettings[key]), valueFilter))
				)
					return;
				return (
					<tr key={key} className="dtable__row">
						<td className="dtable__cell type--color--text--light">{formattedKey}</td>
						{this.renderCell(editMode, key)}
					</tr>
				);
			})
		);
		return !isEmpty(settings) ? settings : this.renderEmpty();
	};

	renderUserName = () => {
		const { xUserName, newComputer } = this.state;

		return (
			newComputer && (
				<tr className="dtable__row">
					{this.renderPreviewCell('xUserName')}
					<td className="dtable__cell">
						<input className="input input--sml" value={xUserName} onChange={this.handleUserNameChange} />
					</td>
				</tr>
			)
		);
	};

	renderMessage = () => (
		<div className="message message--default message--close message--header spc--bottom--sml" id="settingsCloseMessage">
			<p>Changes Require a restart and can take up to 24 hrs to propagate unless cache is deleted</p>
		</div>
	);
	renderUniqueSoftwareOptions = rows => {
		const uniqueOptions = uniq(orderBy(map(uniq(rows), x => head(split(x, /[_/]/g)))));
		return map(uniqueOptions, (option, i) => <option key={i}>{option}</option>);
	};
	renderAddButtons = () => {
		const { newComputer, software } = this.state;
		return (
			software && (
				<Fragment>
					{!newComputer && (
						<button
							className="btn btn--ghost btn--ghost--primary btn--med spc--bottom--xsml spc--left--tny"
							onClick={this.addNewComputer}
							disabled={this.props.isLoading}
						>
							Add New Computer
						</button>
					)}
					{newComputer && (
						<button
							className="btn btn--ghost btn--ghost--primary btn--med spc--bottom--xsml spc--left--tny"
							onClick={this.addNewSetting}
							disabled={!this.state.newComputer || this.props.isLoading}
						>
							Add Setting
						</button>
					)}
				</Fragment>
			)
		);
	};

	renderDropdowns = () => {
		const { rows, newComputer } = this.state;
		return (
			<div className="flex--grow--1 w--max--610 spc--right--med">
				<div className="row">
					<div className="col col-sml-12 col-med-6 spc--bottom--xsml">
						<div className="flex flex--primary flex--nowrap">
							<label className="label label--primary spc--right--sml"> Software</label>
							<div className="flex--grow--1">
								<select className="input input--select input--med" onChange={this.selectSoftware}>
									<option hidden>Select...</option>
									{this.renderUniqueSoftwareOptions(rows)}
								</select>
							</div>
						</div>
					</div>
					<div className="col col-sml-12 col-med-6 spc--bottom--xsml">
						{this.state.software && !newComputer && (
							<Fragment>
								<div className="flex flex--primary flex--nowrap">
									<label className="label label--primary spc--right--sml">Computer:</label>
									<div className="flex--grow--1">
										<select className="input input--select input--med" onChange={this.selectComputer}>
											<option hidden>Select...</option>
											{orderBy(
												map(filter(uniq(rows), row => includes(row, this.state.software)), (x, i) => (
													<option key={i}>{nth(split(x, /[_/]/g), -1)}</option>
												))
											)}
										</select>
									</div>
								</div>
							</Fragment>
						)}
					</div>
				</div>
			</div>
		);
	};

	renderEditButtons = () => {
		const { editMode, currentKey, newComputer } = this.state;

		return (
			<React.Fragment>
				{(editMode || newComputer) && this.renderEditSave()}
				{!newComputer && currentKey && !editMode && (
					<button
						className="btn btn--primary btn--med spc--bottom--xsml spc--left--tny"
						onClick={() => this.setEditMode(true)}
						disabled={this.state.editMode}
					>
						Edit
					</button>
				)}
			</React.Fragment>
		);
	};

	renderEditSave = () => (
		<div className="flex--primary">
			<div className="separator--grey1--left separator--height--med spc--left--sml spc--bottom--xsml spc--right--sml"></div>

			<label className="display--b spc--bottom--xsml spc--right--sml">Do you want to save changes?</label>
			<button
				onClick={() => {
					this.selectUser();
					this.setEditMode(false);
					this.setState({ newComputer: false });
				}}
				disabled={this.props.isLoading}
				className="btn btn--med btn--ghost spc--right--xsml spc--bottom--xsml"
			>
				Cancel
			</button>
			<button
				className="btn btn--med btn--primary spc--bottom--xsml"
				onClick={this.onSave}
				disabled={this.props.isLoading || !this.state.isDirty}
			>
				Save
			</button>

			<div className="separator--grey1--right separator--height--med spc--left--sml spc--bottom--xsml spc--right--tny"></div>
		</div>
	);

	render = () => {
		const { orderDirection, orderName } = this.state.activeFilters;
		const keyArrowClassName = `icon icon--tiny icon--arrow--${
			orderName === 'key' ? arrowClassNames[orderDirection] : arrowClassNames[0]
		} cursor--pointer`;
		const valueArrowClassName = `icon icon--tiny icon--arrow--${
			orderName === 'valueFilter' ? arrowClassNames[orderDirection] : arrowClassNames[0]
		} cursor--pointer`;

		return (
			<div className="settings--main settings--main--payment-engine">
				<header className="settings__header">
					<h4 className="settings__header__title">Payment Engine Settings</h4>
					{this.renderMessage()}
				</header>

				<div className="filter__container--grey filter__container--grey--payment-engine spc--bottom--med">
					{this.renderDropdowns()}
					<div className="flex flex--primary flex--grow--1 flex--right">
						{this.renderAddButtons()}
						{this.renderEditButtons()}
						<div className="spc--bottom--xsml spc--left--tny" data-tooltip="Refresh">
							<button onClick={this.reloadData} className="btn btn--ghost btn--med btn--med--wicon">
								<i className="icon icon--xsml icon--refresh align--v--middle"></i>
							</button>
						</div>
					</div>
				</div>

				<div className="table--primary__wrapper table--primary__wrapper--payment-engine">
					<table className="table table--primary table--payment-engine">
						<thead className="table--primary__thead">
							<tr>
								<th className="dtable__header__cell dtable__header__cell--sticky">
									<div className="flex flex--primary spc--bottom--tny">
										<span>Setting</span>
										<i className={keyArrowClassName} onClick={() => this.handleSort('key')}></i>
									</div>
									<div>
										<input name="keyFilter" className="input input--sml" onChange={this.handleFilterChange} />
									</div>
								</th>
								<th className="dtable__header__cell dtable__header__cell--sticky">
									<div className="flex flex--primary spc--bottom--tny">
										<span>Value</span>
										<i className={valueArrowClassName} onClick={() => this.handleSort('valueFilter')}></i>
									</div>
									<div>
										<input name="valueFilter" className="input input--sml" onChange={this.handleFilterChange} />
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{this.renderSettingsList()}
							{this.renderUserName()}
							{this.renderNewSettings()}
						</tbody>
					</table>
				</div>
			</div>
		);
	};
}
PaymentEngineComponent.propTypes = {
	handleError: PropTypes.func,
	showLoader: PropTypes.func,
	makePendingRequest: PropTypes.func,
	isLoading: PropTypes.bool.isRequired,
};

export default withError(withLoader(withCancelable(PaymentEngineComponent)));
