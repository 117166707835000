import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { replace, toLower, cloneDeep, find, split, get } from 'lodash';
import moment from 'moment';

import { Modal } from 'common/components/modal';
import { kvaasService, principalService } from 'common/services';
import { withLoader } from 'common/components/loader';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { kvaasResources, initialPageOptions, logger } from 'common/utilities';
import Develop from '../../assets/terms_and_conditions_developer';
import Merchant from '../../assets/terms_and_conditions_merchant';

const requestKeys = {
	KVAAS: 'kvaas',
};

class TermsAndConditionsModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			declinedTerms: false,
			principal: {},
			userSettings: {},
			oldData: null,
			isLoading: true,
		};
	}

	componentDidMount = async () => {
		try {
			this.subscription = principalService.subscribe(principal => this.setState({ principal }));
			const principal = principalService.get();
			const userSettings = await this.props.makePendingRequest(
				kvaasService.get(kvaasResources.userSettings),
				requestKeys.KVAAS
			);
			const newState = {
				...this.state,
				principal,
				...this.mapResponseToState(userSettings),
			};
			this.setState(newState);
		} catch (e) {
			this.props.handleError(e);
		} finally {
			this.setState({ isLoading: false });
		}
	};

	componentWillUnmount = () => {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	};

	mapResponseToState = (oldUserSettings = this.state.oldData) => {
		const { oldData, userSettings } = cloneDeep(this.state);
		const newState = {
			oldData,
			userSettings,
		};
		this.checkIfError(newState, oldUserSettings);

		return newState;
	};

	checkIfError = (newState, oldData) => {
		const { data, result, error, refNum } = oldData[0];
		if (data && (toLower(result) === 's' || error === 'Item does not exist')) {
			if (!error) {
				newState.oldData = {
					...oldData[0],
				};
			}
		} else if (toLower(error) === 'invalid: revision' || toLower(error) === 'item exists. revision cannot be 0') {
			throw {
				isApiError: true,
				ref: refNum,
				message: error,
				success: false,
			};
		}
	};

	async redirect() {
		const { history, location } = this.props;

		let redirectUrl = '/';
		let search = '';
		let additionalState;

		try {
			const [userSettings] = await kvaasService.get(kvaasResources.userSettings);
			const initialPage = get(userSettings, 'data.initialPage', false);
			if (initialPage) {
				const route = find(initialPageOptions, { key: initialPage });
				if (route) {
					redirectUrl = route.path;
					additionalState = route.state;
				}
			}
		} catch (e) {
			logger.logError({
				message: 'Initial page redirect error.',
				errorDetails: e,
			});
		} finally {
			this.setState({ isLoading: false });
		}

		if (location.state && location.state.returnUrl) {
			[redirectUrl, search] = split(location.state.returnUrl, '?');
		}

		history.push({
			pathname: redirectUrl,
			search,
			...(additionalState || {}),
		});
	}

	closeModal = () => {
		this.props.history.push('/logout');
	};

	toggleDeclinedModal = () => {
		this.setState({ declinedTerms: !this.state.declinedTerms });
	};

	acceptTerms = async () => {
		const {
			oldData,
			principal,
			principal: {
				idInfo: { userType },
			},
		} = this.state;
		this.setState({ isLoading: true });
		const { primaryKey, userSetting } = kvaasResources.userSettings;
		try {
			const termsAndConditionsVersion = replace(TermsAndConditions[userType], /\./g, '_dot_');
			const newData = {
				newData: {
					revision: 0,
					data: {
						...(oldData && oldData.data),
						[`${termsAndConditionsVersion}_dot_${userType}`]: moment().format(ApplicationSettings.apiDateTimeFormat),
					},
				},
				oldData,
				primaryKey,
				userSetting,
			};
			await this.props.makePendingRequest(kvaasService.save(newData), requestKeys.KVAAS);
			if (principal && principal.idInfo) {
				principal.idInfo.acceptedTermsAndConditions = true;
				principalService.set(principal);
				this.redirect();
			}
		} catch (e) {
			this.setState({ isLoading: false });
			this.props.handleError(e);
		}
	};

	render = () => {
		const { declinedTerms, principal, isLoading } = this.state;

		return (
			<Modal isOpen={true} onClose={declinedTerms ? this.closeModal : this.toggleDeclinedModal} isLoading={isLoading}>
				{isLoading ? (
					<div className="popup__body type--med">
						<div className="loader__holder grid-sidebar__loader">
							<div className="loader__spinner"></div>
						</div>
						<div className="type--wgt--medium type--center">Please wait</div>
					</div>
				) : (
					<div className="w--508p w--max--100">
						<div className="popup__body spc--top--med">
							{declinedTerms ? (
								<div>
									You must accept the Cardknox Terms and Conditions to continue using this app. Click "Continue" to
									proceed to the Terms And Conditions.
								</div>
							) : principal && principal.idInfo && principal.idInfo.userType === 'merchant' ? (
								<Merchant />
							) : (
								<Develop />
							)}
						</div>
						<div className="popup__footer popup__footer--styled">
							<div className="row">
								<div className="col col-sml-6">
									<button
										onClick={declinedTerms ? this.closeModal : this.toggleDeclinedModal}
										className="btn btn--outline btn--outline--negative btn--med fullwidth"
									>
										Decline
									</button>
								</div>
								<div className="col col-sml-6">
									<button
										className="btn btn--primary btn--med fullwidth"
										onClick={declinedTerms ? this.toggleDeclinedModal : this.acceptTerms}
									>
										{declinedTerms ? 'Continue' : 'Accept'}
									</button>
								</div>
							</div>
						</div>
					</div>
				)}
			</Modal>
		);
	};
}

TermsAndConditionsModal.propTypes = {
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func,
	history: PropTypes.object,
};

export default withError(withLoader(withCancelable(TermsAndConditionsModal)));
