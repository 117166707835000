import React from 'react';
import Template from './error.html';
import { HttpServiceError } from '../../Common/services';
import { logger } from 'common/utilities';
import sendError from 'common/components/error/sendError';

export const UNEXPECTED_ERROR_MSG = 'Something went wrong. Please reload and try again.';
export const SERVER_COMM_ERROR_MSG = 'A server communication error has occurred, please contact support.';

class DisplayError extends React.Component {
	render = Template;
	constructor(props) {
		super(props);
		this.state = { errorObjects: null };
	}

	async componentWillMount() {
		const { errors } = this.props;
		const errorObjects = await this.generateErrorMessages(errors);
		this.setState({ errorObjects });
	}

	async handleHttpError(e, errorObjects) {
		const { ex, request, response } = e.reason;
		const { message: errorMessage, stack: stackTrace } = ex;

		let requestInfo, responseInfo;

		if (request) {
			const { url, method } = request;

			requestInfo = {
				url,
				method,
			};
		}

		if (response) {
			const { status: statusCode, statusText } = response;
			const status = `${statusText} (${statusCode})`;

			let responseException, exceptionType, exceptionMessage, stackTrace;

			const contentType = response.headers.get('Content-Type');

			if (contentType) {
				if (contentType.indexOf('application/json') !== -1) {
					const jsonBody = await response.json();

					({ exceptionType, exceptionMessage, stackTrace } = jsonBody);

					if (!!exceptionType || !!exceptionMessage || !!stackTrace) {
						responseException = `${exceptionType}\n${exceptionMessage}\n${stackTrace}`;
					}
				} else {
					responseException = await response.text();
				}
			}

			responseInfo = {
				status,
				exception: responseException,
			};
		}

		errorObjects.push({
			message: SERVER_COMM_ERROR_MSG,
			errorMessage,
			stackTrace,
			requestInfo,
			responseInfo,
		});
		sendError(errorMessage, null, stackTrace, {
			requestInfo,
			responseInfo,
		});
	}
	async generateErrorMessages(errors) {
		let errorSend = false;
		const errorObjects = [];
		for (let e of errors) {
			let errorObj = null;
			if (typeof PromiseRejectionEvent !== 'undefined' && e instanceof PromiseRejectionEvent) {
				//eslint-disable-next-line
				console.log('log PromiseRejectionEvent', e);
				if (e.reason instanceof HttpServiceError) {
					await this.handleHttpError(e, errorObjects);
					errorSend = true;
				} else {
					const { message: errorMessage, stack: stackTrace } = e.reason;

					errorObj = {
						message: UNEXPECTED_ERROR_MSG,
						errorMessage,
						stackTrace,
					};
				}
			} else if (e instanceof ErrorEvent) {
				//eslint-disable-next-line
				console.log('log ErrorEvent', e);
				let errorMessage, stackTrace;
				if (e && e.error && e.error !== null) {
					errorMessage = e.error.message;
					stackTrace = e.error.stack;
				} else {
					errorMessage = e.message;
				}

				errorObj = {
					message: UNEXPECTED_ERROR_MSG,
					errorMessage,
					stackTrace,
				};
			} else {
				const { message: errorMessage } = e;
				errorObj = {
					message: UNEXPECTED_ERROR_MSG,
					errorMessage,
				};
			}

			const { message, ...errorDetails } = errorObj;
			logger.logError({
				message,
				errorDetails,
			});
			if (!errorSend) {
				sendError(errorObj.errorMessage, null, errorObj.stackTrace, {});
			}
			errorObjects.push(errorObj);
		}
		return errorObjects;
	}
}

export default DisplayError;
