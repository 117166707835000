import React, { Component, Fragment, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
	trim,
	clone,
	toLower,
	split,
	includes,
	get,
	each,
	every,
	transform,
	startsWith,
	noop,
	isArray,
	isEmpty,
	map,
	compact,
	toNumber,
	head,
	cloneDeep,
	toString,
} from 'lodash';
import NumberFormat from 'react-number-format';
import { ExportToCsv } from 'export-to-csv';

import { ReactToPrint } from '../../../Common/components/react-to-print';
import {
	transactionService,
	principalService,
	kvaasService,
	customerService,
	logoManagementService,
} from '../../../Common/services';
import {
	PrintTransactionReceiptComponent,
	PrinterReceipt,
	modalNames,
} from '../../../Common/components/transaction-actions';
import {
	CurrencyMap,
	renderIf,
	OutsideClick,
	PaymentTransactionTypes,
	checkIfCanadian,
	kvaasResources,
} from '../../../Common/utilities';
import { Notification } from '../../../Common/components/notifications';
import { StatusComponent, StatusFraudComponent } from './../../../Common/components/columns/formatters';
import { AddEditCustomer } from '../../../Common/components/customers/popup';
import { exportOptions } from './../../../Common/components/export/export-options';
import { generateFileName } from './../../../Common/components/export/helpers';
import { authenticationService } from './../../../Common/services';
import { withError } from './../../../Common/components/error';
import { withCancelable } from './../../../Common/components/cancelable';
import { validators } from '../../../Common/fields';
import { mapAvsItem } from 'common/utilities';
import { withForwardRef } from '../../../Common/components/with-forward-ref';
import { Modal } from '../../../Common/components/modal';
import TransactionReceipt from '../../../Common/components/transaction-actions/receipt';
import { hasFeaturePackage, featurePackages, featurePackageTooltips } from 'common/utilities/has-feature-package';
import LinkExistingCustomerForm from 'common/components/customers/popup/LinkExistingCustomerForm';
import { withLoader } from 'common/components/loader';
import sectionKeys from 'routing/sections';
import renderCustomFields from 'common/components/transaction-actions/printUtils/printUtils';
import { exportService } from 'common/components/export/exportService';
import LinkTransactionComponent from 'common/components/transaction-actions/LinkTransaction';
import RenderLinkPaymentMethodOrCustomer from '../components/RenderLinkPaymentCustomer';

const requestKeys = {
	LABELS: 'labels',
	DATA: 'data',
	LOGO: 'logo',
	FETCH: 'fetch',
};

const hardcodedKeys = [
	'xZip',
	'xToken',
	'xCustom',
	'xCustId',
	'xCustomerId',
	'xResponseResult',
	'xSerialNumber',
	'xStreet',
	'xServiceFee',
	'xTax',
	'xInvoice',
	'xOrderID',
	'xEnteredDate',
	'xBillCompany',
	'xShipCompany',
	'xBillPhone',
	'xShipPhone',
	'xBillStreet',
	'xShipStreet',
	'xEmail',
	'xShipEmail',
	'xBillsState',
	'xBillFirstName',
	'xBillLastName',
	'xShipFirstName',
	'xShipLastName',
	'xBillCity',
	'xBillZip',
	'xShipCity',
	'xShipState',
	'xShipZip',
	'xProcessingFee',
	'xRequestAmount',
	'xCommand',
	'xSplitAmount',
	'xIssuingBank',
	'xIssuingBankPhone',
	'xIssuingBankWebsite',
	'xResponseAVSCode',
	'xIsSplitCapturable',
	'xClearedAmount',
	'xClearedCount',
	'xDescription',
	'xMaskedCardNumber',
	'xMaskedAccountNumber',
	'xRefNum',
	'xSignature',
	'xAmount',
	'xCryptoStatus',
	'xCryptoProcessingFee',
	'xCryptoTransactionFee',
	'xCryptoNetworkFee',
	'xAchReturnFee',
];

class RowDetailsComponent extends Component {
	constructor(props) {
		super(props);
		this.principal = principalService.get();
		this.state = clone(this.initialState);
		this.emailRef = createRef();
		this.notificationRef = createRef();
		this.popupRef = createRef();
		this.setState = this.setState.bind(this);
	}

	async componentDidMount() {
		await this.loadData();
		this.loadLabels();
	}

	componentDidUpdate() {
		if (this.emailRef.current) {
			const isOverflowing = this.emailRef.current.offsetWidth - 1 !== this.emailRef.current.scrollWidth;
			if (isOverflowing !== this.state.isOverflowing) {
				this.setState({ isOverflowing });
			}
		}
	}

	get zipLabel() {
		return this.state.isCanadian ? 'Postal Code' : 'Zip';
	}

	get stateLabel() {
		return this.state.isCanadian ? 'Province' : 'State';
	}

	get isAuthOnly() {
		const { data } = this.state;
		return toLower(data.xCommand).indexOf('authonly') !== -1;
	}

	get initialState() {
		return {
			data: null,
			logoUrlBase64: null,
			isLoading: true,
			showShareEmail: false,
			shareEmail: '',
			shareEmailValid: false,
			shareEmailSubmitted: false,
			sendCopy: false,
			emailCustomer: false,
			username: null,
			isCanadian: false,
			permissions: {},
			isOverflowing: false,
			isOpenLinkTransaction: false,
			ishiddenLinkTransaction: false,
			isOpenLinkExistingCustomer: false,
			selectedCustomer: null,
			emailNotificationVisible: false,
			setNewCardAsDefault: false,
			printerReceiptOption: false,
			expanded: {
				general: true,
				bill: true,
				ship: true,
				customFields: true,
				authorization: true,
				additionalInformation: false,
				processingDetails: false,
				issuingBankInfo: false,
			},
		};
	}

	get hasTerminalOnly() {
		return hasFeaturePackage(featurePackages.terminalOnly);
	}

	get allExpanded() {
		return every(this.state.expanded, isExpanded => isExpanded);
	}

	getShouldDisplayAuthoriazion = (userSettings, data) => {
		return (
			get(userSettings, 'data.displayAuthorization', false) &&
			get(data, 'xRequestAmount', false) &&
			split(toLower(data.xCommand), ':')[1] === 'capture'
		);
	};

	closeSplitCapturedAuthOnly = async () => {
		const { row, makePendingRequest, handleError } = this.props;
		try {
			const addNotification = get(this.notificationRef, 'current.addNotification', noop);
			const { xRefNum: ref, xStatus } = await makePendingRequest(
				transactionService.closeSplitCapturedAuthOnly(row.xRefNum, row.xMerchantId),
				requestKeys.DATA
			);
			addNotification({
				message: 'Closed Split Capture successfully',
				success: toLower(xStatus) === 'approved',
				onClose: this.refreshGridData,
				ref,
			});
		} catch (e) {
			handleError(e);
		}
	};

	toggleExpand = name => {
		const { expanded } = this.state;
		const newState = { expanded: { ...expanded } };

		if (name === 'all') {
			each(expanded, (_, key) => {
				newState.expanded[key] = !this.allExpanded;
			});
		} else {
			newState.expanded[name] = !expanded[name];
		}

		this.setState(newState);
	};

	isProPay = (data = this.state.data) => data.xProcessingFee > 0;

	// This will need to be refactor at a later date as it's not used within this component but within RenderLinkPaymentCustomer
	openLinkTransactionPopup = async linkPaymentMethod => {
		const {
			refreshGridData,
			openModal,
			showLoader,
			makePendingRequest,
			handleError,
			row: { xMerchantId },
		} = this.props;
		const { xRefNum } = this.state.data;

		this.props.showLoader(true);
		const { data: row } = await transactionService.getTransactionExtended(xRefNum, xMerchantId);

		this.openModal(
			{
				component: LinkTransactionComponent,
				props: {
					shouldCloseOnOverlayClick: false,
				},
			},
			{
				row,
				refreshGridData,
				openModal,
				showLoader,
				makePendingRequest,
				handleError,
				xMerchantId,
				linkPaymentMethod: linkPaymentMethod,
				newCustomer: !linkPaymentMethod, // for better readability
			}
		);
		this.props.showLoader(false);
	};

	setQueryParamAndRefresh = (param, value) => {
		const history = this.props.history;
		let searchParams = new URLSearchParams(window.location.search);

		searchParams.set(param, value);
		history.push({ search: toString(searchParams) });
		window.location.reload();
	};
	openLinkExistingCustomer = () => {
		this.setState({ isOpenLinkExistingCustomer: true });
	};

	closeLinkTransactionPopup = () => {
		this.setState({ isOpenLinkTransaction: false, selectedCustomer: null });
	};

	hideLinkTransactionPopup = () => {
		this.setState({ ishiddenLinkTransaction: true });
	};

	loadLabels = () => {
		const isCanadian = checkIfCanadian();

		this.setState({
			isCanadian,
		});
	};

	loadData = async () => {
		try {
			const principal = principalService.get();
			this.setState({
				permissions: get(principal, 'idInfo.permissions', {}),
				isViewOnly: get(principal, 'isViewOnly'),
			});
			const { row } = this.props;
			const refnum = row.xCommand == 'CC:Capture' && row.xRefnumCurrent ? row.xRefnumCurrent : row.xRefNum;
			const [
				{ data, related, ...status },
				user,
				[customDisplayLabels, convenienceFees, recurringSchedules, logoManagement, portalFlags, userSettings],
			] = await this.props.makePendingRequest(
				Promise.all([
					transactionService.getTransactionExtended(refnum, row.xMerchantId),
					authenticationService.getUser(),
					kvaasService.get(
						kvaasResources.transactionDisplayLabels,
						kvaasResources.convenienceFees,
						kvaasResources.recurringSchedules,
						kvaasResources.logoManagement,
						kvaasResources.portalFlags,
						kvaasResources.userSettings
					),
				]),
				requestKeys.DATA
			);
			const setNewCardAsDefault = get(recurringSchedules, 'data.setNewCardAsDefault', false);
			const convenienceCustomKey = get(convenienceFees, 'data.convenienceCustomKey', '');
			const originalCustomKey = get(convenienceFees, 'data.originalCustomKey', '');
			const showIssuingBankInfo = get(portalFlags, 'data.showIssuingBankInfo', false);
			const splitCaptureEnabled = get(portalFlags, 'data.multipleCapture', false);
			const printerReceiptOption = get(portalFlags, 'data.printerReceiptOption', false);
			const customLabels = get(customDisplayLabels, 'data', false);
			const displayToken = get(userSettings, 'data.displayTokenColumn', false);
			const displayAuthorization = this.getShouldDisplayAuthoriazion(userSettings, data);

			const expanded = { ...this.state.expanded };

			const newState = {
				transactionStatus: status,
				isLoading: false,
				shareEmail: data.xEmail || '',
				shareEmailValid: validators.email(data.xEmail),
				username: user && user.attributes && user.attributes.email,
				setNewCardAsDefault,
				showIssuingBankInfo,
				splitCaptureEnabled,
				printerReceiptOption,
				customDisplayLabels: customLabels,
				isCashSale: toLower(data.xCommand) === 'cash:sale',
				isCheck: includes(toLower(data.xCommand), 'check'),
				token: displayToken ? data.xToken : null,
				displayAuthorization,
			};

			if (get(logoManagement, 'data.includeCoBrandLogoOnReceipts', false)) {
				try {
					newState.logoUrlBase64 = await this.fetchLogo();
				} catch (e) {
					// eslint-disable-next-line no-console
					console.error(e);
				}
			}

			if (this.isProPay(data)) {
				expanded.processingDetails = true;
			}

			if (data.xIssuingBank || data.xIssuingBankPhone || data.xIssuingBankWebsite) {
				expanded.issuingBankInfo = true;
			}

			data.xIsDebit = row.xIsDebit;
			data.xRefnumCurrent = row.xRefnumCurrent;
			data.originalCustomKey = originalCustomKey ? `x${originalCustomKey}` : originalCustomKey;
			data.convenienceCustomKey = convenienceCustomKey ? `x${convenienceCustomKey}` : convenienceCustomKey;

			newState.data = data;
			newState.expanded = expanded;

			this.setState(newState);
		} catch (e) {
			this.props.handleError(e);
			this.setState({
				isLoading: false,
			});
		}
	};

	fetchLogo = async () => {
		const { LogoUrl: logoUrl } = await this.props.makePendingRequest(
			logoManagementService.getLogoUrl(),
			requestKeys.LOGO
		);
		let logoUrlBase64 = '';

		if (logoUrl) {
			logoUrlBase64 = await this.props.makePendingRequest(
				logoManagementService.fetchImageAndConvertToBase64(`${logoUrl}?${Date.now()}`),
				requestKeys.FETCH
			);
		}

		return logoUrlBase64;
	};

	get currency() {
		return this.state.data && this.state.data.currency;
	}

	get currencyCode() {
		return CurrencyMap.resolveCurrency(this.currency);
	}

	formatCityStateZip = (city, state, zip) => {
		let cityString = city ? `${city}, ` : '';
		let stateString = state ? `${state} ` : '';
		let zipString = zip ? zip : '';
		let str = cityString + stateString + zipString;

		if (str === '') {
			return 'N/A';
		}
		return str;
	};

	redirectToUpgradePlan = () => {
		this.props.history.push({ pathname: '/terminal-only' });
	};

	renderCustomFields = () => {
		const {
			data,
			customDisplayLabels,
			expanded: { customFields },
		} = this.state;

		const customInformation = compact(renderCustomFields(data, customDisplayLabels, this.renderReceiptItem, true));

		if (isEmpty(customInformation)) {
			return;
		}
		return (
			<Fragment>
				<hr className="separator separator--grey1 separator--ghost btn--ghost--primary--15 spc--bottom--sml--alt" />
				<div>
					<div className="grid-sidebar__body__header">
						<div className="display--f cursor--pointer" onClick={() => this.toggleExpand('customFields')}>
							<i
								className={`icon icon--nano icon--arrow--${
									customFields ? 'down' : 'right'
								}--primary icon--middle spc--right--tny`}
							></i>
							<h6 className="type--xsml type--uppercase type--color--text--regular">Custom Information</h6>
						</div>
					</div>
					{customFields && (
						<Fragment>
							<div className="f-row">{customInformation}</div>
						</Fragment>
					)}
				</div>
			</Fragment>
		);
	};

	/**
	 * Renders Billing/Shipping information
	 * @param type Bill or Ship. Default is Bill
	 * @returns {*}
	 */
	renderBillingDetails = (type = 'Bill') => {
		const { data, isOverflowing, expanded } = this.state;
		const title = type === 'Bill' ? 'Billing Information' : 'Shipping Information';

		const name =
			trim(
				type === 'Bill'
					? `${data.xBillFirstName || ''} ${data.xBillLastName || ''}`
					: `${data.xShipFirstName || ''} ${data.xShipLastName || ''}`
			) || null;
		const company = trim(type === 'Bill' ? data.xBillCompany : data.xShipCompany) || null;
		const phoneNumber = trim(type === 'Bill' ? data.xBillPhone : data.xShipPhone) || null;
		const street = trim(type === 'Bill' ? data.xBillStreet : data.xShipStreet) || null;
		const email = trim(type === 'Bill' ? data.xEmail : data.xShipEmail) || null;

		let cityStateZipArray = [];
		if (type === 'Bill') {
			data.xBillCity ? cityStateZipArray.push(data.xBillCity) : null;
			data.xBillState ? cityStateZipArray.push(data.xBillState) : null;
			data.xBillZip ? cityStateZipArray.push(data.xBillZip) : null;
		} else {
			data.xShipCity ? cityStateZipArray.push(data.xShipCity) : null;
			data.xShipState ? cityStateZipArray.push(data.xShipState) : null;
			data.xShipZip ? cityStateZipArray.push(data.xShipZip) : null;
		}
		const cityStateZipString = cityStateZipArray.join(', ');

		if (!name && !company && !phoneNumber && !street && cityStateZipArray.length == 0 && !email) {
			if (expanded[toLower(type)]) {
				const newExpanded = transform(expanded, (acc, value, key) => {
					if (toLower(type) !== key) {
						acc[key] = value;
					}
				});
				this.setState({ expanded: newExpanded });
			}

			return <Fragment />;
		}

		return (
			<Fragment>
				<hr className="separator separator--grey1 separator--ghost btn--ghost--primary--15 spc--bottom--sml--alt" />
				<div>
					<div className="grid-sidebar__body__header" onClick={() => this.toggleExpand(toLower(type))}>
						<div className="display--f cursor--pointer">
							<i
								className={`icon icon--nano icon--arrow--${
									expanded[toLower(type)] ? 'down' : 'right'
								}--primary icon--middle spc--right--tny`}
							></i>
							<h6 className="type--xsml type--uppercase type--color--text--regular">{title}</h6>
						</div>
					</div>
					{expanded[toLower(type)] && (
						<Fragment>
							<div className="f-row">
								{name ? (
									<div className="f-col col-sml-12 col-med-6 col-xlrg-3">
										<label className="label label--sml">Name:</label>
										<div className="grid__expansion__value">{name}</div>
									</div>
								) : null}
								{company ? (
									<div className="f-col col-sml-12 col-med-6 col-xlrg-3">
										<label className="label label--sml">Company:</label>
										<div className="grid__expansion__value datatooltip--no-wrap datatooltip--left">
											<a
												className="anchor anchor--primary"
												onClick={() => this.setQueryParamAndRefresh('company', company)}
												href="javascript:void(0)"
												data-tooltip="Filter by company"
											>
												{company}
											</a>
										</div>
									</div>
								) : null}
								{phoneNumber ? (
									<div className="f-col col-sml-12 col-med-6 col-xlrg-3">
										<label className="label label--sml">Phone:</label>
										<div className="grid__expansion__value">
											<span>{phoneNumber}</span>
										</div>
									</div>
								) : null}
								{street ? (
									<div className="f-col col-sml-12 col-med-6 col-xlrg-3">
										<label className="label label--sml">Street:</label>
										<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
											{street}
										</div>
									</div>
								) : null}
								{cityStateZipArray.length > 0 ? (
									<div className="f-col col-sml-12 col-med-6 col-xlrg-3">
										<label className="label label--sml">
											City, {this.stateLabel}, {this.zipLabel}:
										</label>
										<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
											{cityStateZipString}
										</div>
									</div>
								) : null}
								{email ? (
									<div
										className={`f-col col-sml-12 col-med-6 col-xlrg-3${
											isOverflowing ? ' datatooltip--transaction-email' : ''
										}`}
									>
										<label className="label label--sml">Email:</label>
										<div
											className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt"
											data-tooltip={`Filter by ${isOverflowing ? email : 'email'}`}
										>
											<a
												className="anchor anchor--primary display--b type--ellipsis"
												onClick={() => this.setQueryParamAndRefresh('email', email)}
												href="javascript:void(0)"
												ref={this.emailRef}
											>
												{email}
											</a>
										</div>
									</div>
								) : null}
							</div>
						</Fragment>
					)}
				</div>
			</Fragment>
		);
	};

	renderProcessingDetails = () => {
		const {
			expanded: { processingDetails },
			data: { xProcessingFee, xRequestAmount, xCommand, xSplitAmount, xAchReturnFee },
		} = this.state;

		const transactionType = split(toLower(xCommand), ':')[1];
		const netSale = xRequestAmount - xProcessingFee;
		return (
			this.isProPay() && (
				<Fragment>
					<hr className="separator separator--grey1 separator--ghost btn--ghost--primary--15 spc--bottom--med" />
					<div>
						<div className="grid-sidebar__body__header">
							<div className="display--f cursor--pointer" onClick={() => this.toggleExpand('processingDetails')}>
								<i
									className={`icon icon--nano icon--arrow--${
										processingDetails ? 'down' : 'right'
									}--primary icon--middle spc--right--tny`}
								></i>
								<h6 className="type--xsml type--uppercase type--color--text--regular">Processing Details</h6>
							</div>
						</div>
						{processingDetails && (
							<div className="f-row">
								{transactionType === 'refund' ? (
									<div className="f-col col-sml-12 col-med-6 col-xlrg-3">
										<label className="label label--sml">Refund fee:</label>
										<NumberFormat
											className="grid__expansion__value"
											value={xRequestAmount}
											displayType="text"
											thousandSeparator={true}
											prefix={this.currencyCode}
											decimalScale={2}
											fixedDecimalScale={true}
										/>
									</div>
								) : (
									<Fragment>
										<div className="f-col col-sml-12 col-med-6 col-xlrg-3">
											<label className="label label--sml">Gross amount:</label>
											<NumberFormat
												className="grid__expansion__value"
												value={xRequestAmount}
												displayType="text"
												thousandSeparator={true}
												prefix={this.currencyCode}
												decimalScale={2}
												fixedDecimalScale={true}
											/>
										</div>
										<div className="f-col col-sml-12 col-med-6 col-xlrg-3">
											<label className="label label--sml">Processing fee: </label>
											<NumberFormat
												className="grid__expansion__value"
												value={xProcessingFee}
												displayType="text"
												thousandSeparator={true}
												prefix={this.currencyCode}
												decimalScale={2}
												fixedDecimalScale={true}
											/>
										</div>
										{parseFloat(xSplitAmount) > 0 && (
											<div className="f-col col-sml-12 col-med-6 col-xlrg-3">
												<label className="label label--sml">Split fee: </label>
												<NumberFormat
													className="grid__expansion__value"
													value={xSplitAmount}
													displayType="text"
													thousandSeparator={true}
													prefix={this.currencyCode}
													decimalScale={2}
													fixedDecimalScale={true}
												/>
											</div>
										)}
										<div className="f-col col-sml-12 col-med-6 col-xlrg-3">
											<label className="label label--sml">Net sale: </label>
											<NumberFormat
												className="grid__expansion__value"
												value={netSale}
												displayType="text"
												thousandSeparator={true}
												prefix={this.currencyCode}
												decimalScale={2}
												fixedDecimalScale={true}
											/>
										</div>
										{parseFloat(xAchReturnFee) > 0 && (
											<div className="f-col col-sml-12 col-med-6 col-xlrg-3">
												<label className="label label--sml">ACH Return Fee: </label>
												<NumberFormat
													className="grid__expansion__value"
													value={xAchReturnFee}
													displayType="text"
													thousandSeparator={true}
													prefix={this.currencyCode}
													decimalScale={2}
													fixedDecimalScale={true}
												/>
											</div>
										)}
									</Fragment>
								)}
							</div>
						)}
					</div>
				</Fragment>
			)
		);
	};

	renderIssuingBankInfo = () => {
		const {
			data: { xIssuingBank, xIssuingBankPhone, xIssuingBankWebsite },
			expanded: { issuingBankInfo },
			showIssuingBankInfo,
		} = this.state;
		const websiteUrl = startsWith(xIssuingBankWebsite, 'http') ? xIssuingBankWebsite : `https://${xIssuingBankWebsite}`;

		return (
			showIssuingBankInfo &&
			(xIssuingBank || xIssuingBankPhone || xIssuingBankWebsite) && (
				<Fragment>
					<hr className="separator separator--grey1 separator--ghost btn--ghost--primary--15 spc--bottom--sml--alt" />
					<div>
						<div className="grid-sidebar__body__header" onClick={() => this.toggleExpand('issuingBankInfo')}>
							<div className="display--f cursor--pointer">
								<i
									className={`icon icon--nano icon--arrow--${
										issuingBankInfo ? 'down' : 'right'
									}--primary icon--middle spc--right--tny`}
								></i>
								<h6 className="type--xsml type--uppercase type--color--text--regular">Issuing Bank information</h6>
							</div>
						</div>
						{issuingBankInfo && (
							<div className="f-row">
								{xIssuingBank && xIssuingBank.trim() !== '' ? (
									<div className="f-col col-sml-12">
										<label className="label label--sml">Bank Name:</label>
										<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
											{xIssuingBank}
										</div>
									</div>
								) : null}
								{xIssuingBankPhone && xIssuingBankPhone.trim() !== '' ? (
									<div className="f-col col-sml-12">
										<label className="label label--sml">Bank Phone Number:</label>
										<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
											{xIssuingBankPhone}
										</div>
									</div>
								) : null}
								{xIssuingBankWebsite && xIssuingBankWebsite.trim() !== '' ? (
									<div className="f-col col-sml-12">
										<label className="label label--sml">Bank Website:</label>
										<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
											<a className="anchor anchor--primary" rel="noopener noreferrer" target="_blank" href={websiteUrl}>
												{websiteUrl}
											</a>
										</div>
									</div>
								) : null}
							</div>
						)}
					</div>
				</Fragment>
			)
		);
	};

	getAdditionalInfoDisplayValue = (value, type, name) => {
		const booleanValue = toNumber(value) ? 'True' : 'False';
		if (includes(toLower(name), 'custom')) {
			return;
		}
		if (type === 'object') {
			return value.format(ApplicationSettings.displayDateTimeFormat);
		} else if (type === 'boolean') {
			return booleanValue;
		} else if ((startsWith(toLower(name), 'is') && value === '0') || value === '1') {
			return booleanValue;
		}
		return value;
	};

	renderVisibleColumnsAdditionalInformation = data => {
		const { visibleColumns } = this.props;

		return compact(
			map(visibleColumns, c => {
				if (!includes(hardcodedKeys, c.key)) {
					const value = data[c.key];
					const name = c.name;
					const type = typeof value;
					const displayValue = this.getAdditionalInfoDisplayValue(value, type, name);

					if (!displayValue) return;
					return (
						<div className="spc--bottom--sml f-col col-sml-12 col-med-3 col-xxlrg-4 col-xxxlrg-4 col-huge-3">
							<label className="label label--sml">{name}:</label>
							<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
								{displayValue}
							</div>
						</div>
					);
				}
			})
		);
	};

	renderAdditionalInformation = () => {
		const {
			data,
			isCheck,
			expanded: { additionalInformation },
		} = this.state;
		const visibleColumnsData = this.renderVisibleColumnsAdditionalInformation(data);
		const hasData =
			!isEmpty(visibleColumnsData) ||
			get(data, 'xDescription', false) ||
			get(data, 'xSignature', false) ||
			(get(data, 'xStatus', false) && !isCheck) ||
			get(data, 'xStatusReason', false);

		return (
			hasData && (
				<Fragment>
					<hr className="separator separator--grey1 separator--ghost btn--ghost--primary--15 spc--bottom--sml--alt" />
					<div>
						<div className="grid-sidebar__body__header" onClick={() => this.toggleExpand('additionalInformation')}>
							<div className="display--f cursor--pointer">
								<i
									className={`icon icon--nano icon--arrow--${
										additionalInformation ? 'down' : 'right'
									}--primary icon--middle spc--right--tny`}
								></i>
								<h6 className="type--xsml type--uppercase type--color--text--regular">Additional information</h6>
							</div>
						</div>
						{additionalInformation && (
							<div className="f-row">
								{data.xDescription && data.xDescription.trim() !== '' ? (
									<div className="f-col col-sml-12">
										<label className="label label--sml">Transaction Description:</label>
										<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
											{data.xDescription}
										</div>
									</div>
								) : null}
								{data.xSignature && data.xSignature.trim() !== '' ? (
									<div className="f-col col-sml-12">
										<label className="label label--sml">Signature:</label>
										<div>
											<img src={'data:image/png;base64,' + data.xSignature} className="grid__expansion__signature" />
										</div>
									</div>
								) : null}
								{!isCheck && data.xStatus ? (
									<div className="f-col col-sml-12">
										<label className="label label--sml">Status:</label>
										<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
											{data.xStatus}
										</div>
									</div>
								) : null}
								{data.xStatusReason && data.xStatusReason.trim() !== '' ? (
									<div className="f-col col-sml-12">
										<label className="label label--sml">Status Description:</label>
										<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
											{data.xStatusReason}
										</div>
									</div>
								) : null}
								{!isEmpty(visibleColumnsData) && visibleColumnsData}
							</div>
						)}
					</div>
				</Fragment>
			)
		);
	};

	refreshGridData = () => {
		this.props.refreshGridData();
	};

	openModal = (modalName, data = {}) => {
		this.props.openModal({
			name: modalName,
			data: data,
		});
	};

	renderStatus = () => {
		const {
			transactionStatus: {
				isVoid,
				isAuthOnly,
				isRefund,
				isRefunded,
				canRefundAmount,
				previouslyRefundedAmount,
				isSplitCaptured,
			},
		} = this.state;
		let statusImage = null;
		let tooltip = null;

		if (isVoid) {
			statusImage = <img src="/static/media/tags/void.svg" className="h--18 spc--left--xsml " />;
		} else if (isRefund) {
			statusImage = <img src="/static/media/tags/refund.svg" className="h--18 spc--left--xsml " />;
		} else if (isRefunded) {
			if (canRefundAmount > 0 && previouslyRefundedAmount > 0) {
				tooltip = `Partial refund of ${this.currencyCode}${parseFloat((previouslyRefundedAmount / 10).toFixed(2))}`;
				statusImage = (
					<img
						alt="Partially Refunded"
						src="/static/media/tags/partiallyrefunded.svg"
						className="h--18 spc--left--xsml "
					/>
				);
			} else {
				statusImage = <img alt="Refunded" src="/static/media/tags/refunded.svg" className="h--18 spc--left--xsml " />;
			}
		} else if (isAuthOnly) {
			if (isSplitCaptured) {
				statusImage = (
					<Fragment>
						<img src="/static/media/tags/auth.svg" className="h--18 spc--left--tny " />
						<img src="/static/media/tags/split-captured.svg" className="h--18 spc--left--tny " />
					</Fragment>
				);
			} else {
				statusImage = <img src="/static/media/tags/auth.svg" className="h--18 spc--left--tny " />;
			}
		}

		if (statusImage !== null) {
			return (
				<span className="display--ib datatooltip--v--bottom datatooltip--w--100">
					<span data-tooltip={tooltip}>{statusImage}</span>
				</span>
			);
		}
		return null;
	};
	getGeneralInformationCustomLabel = (label, defaultLabel) => {
		const { customDisplayLabels } = this.state;
		return customDisplayLabels[label] || defaultLabel;
	};

	renderTransactionDetails = displayAuthorization => {
		const {
			data,
			transactionStatus,
			data: { xCommand, xResponseAVSCode, xIsSplitCapturable, xClearedAmount, xClearedCount, xRequestAmount },
			expanded: { general },
			token,
		} = this.state;
		let amount = transactionStatus.isVoid ? data.xRequestAmount : data.xAmount;
		let amountClass = transactionStatus.isVoid ? 'type--linethrough type--color--primary' : '';
		const accountNumber =
			data.xMaskedAccountNumber && data.xMaskedAccountNumber.includes('xxx')
				? `**** ${data.xMaskedAccountNumber.slice(-4)}`
				: data.xMaskedAccountNumber;
		let avs = mapAvsItem(xResponseAVSCode);
		if (avs) {
			avs = avs.text;
		}
		const [payment, transaction] = split(toLower(xCommand), ':');
		const tType = toLower(payment) === 'avsonly' ? payment : transaction;
		const issueGift = payment === 'gift' && transaction === 'issue';
		const redeemGift = payment === 'gift' && transaction === 'redeem';
		if (redeemGift) {
			amount = Math.abs(amount);
		}
		const paymentType = PaymentTransactionTypes.getPaymentType(payment);
		const transactionType = PaymentTransactionTypes.getTransactionType(tType);
		return (
			<Fragment>
				<div>
					<div className="grid-sidebar__body__header">
						<div className="display--f cursor--pointer" onClick={() => this.toggleExpand('general')}>
							<i
								className={`icon icon--nano icon--arrow--${
									general ? 'down' : 'right'
								}--primary icon--middle spc--right--tny`}
							></i>
							<h6 className="type--xsml type--uppercase type--color--text--regular">General information</h6>
						</div>
					</div>
					{general && (
						<Fragment>
							<div className="f-row">
								<div className="f-col col-sml-12 col-med-6 col-xlrg-4 spc--bottom--sml">
									<label className="type--sml--alt type--wgt--medium type--color--text--medium spc--right--tny">
										{`Amount: ${issueGift ? '-' : ''}`}
									</label>
									<div className={`type--sml--plus type--color--text--medium datatooltip--v--bottom ${amountClass}`}>
										<NumberFormat
											value={amount}
											displayType="text"
											thousandSeparator={true}
											prefix={this.currencyCode}
											decimalScale={2}
											fixedDecimalScale={true}
										/>
										{xClearedCount && (
											<i
												className="icon icon--tiny icon--info align--v--middle spc--left--tny align--v--top"
												data-tooltip="This is the amount that remains after split captures."
											></i>
										)}
										{this.renderStatus()}
									</div>
								</div>
								{displayAuthorization && (
									<div className="f-col col-sml-12 col-med-6 col-xlrg-8 spc--bottom--sml">
										<label className="type--sml--alt type--wgt--medium type--color--text--medium spc--right--tny">
											Authorization Amount:
										</label>
										<div
											className={`type--sml--plus type--color--text--medium datatooltip--w--250 datatooltip--v--bottom ${amountClass}`}
										>
											<NumberFormat
												value={xRequestAmount}
												displayType="text"
												thousandSeparator={true}
												prefix={this.currencyCode}
												decimalScale={2}
												fixedDecimalScale={true}
											/>
										</div>
									</div>
								)}
							</div>
							{xIsSplitCapturable && xClearedCount && (
								<div className="f-row">
									<div className="f-col col-sml-12 col-med-6 col-xlrg-3 spc--bottom--sml">
										<label className="label label--sml">Cleared Amount:</label>
										<div className="grid__expansion__value">
											<NumberFormat
												value={xClearedAmount}
												displayType="text"
												thousandSeparator={true}
												prefix={this.currencyCode}
												decimalScale={2}
												fixedDecimalScale={true}
											/>{' '}
											({xClearedCount})
										</div>
									</div>
								</div>
							)}
							<div className="f-row">
								<div className="f-col col-sml-12 col-med-6 col-xlrg-3 spc--bottom--sml">
									<label className="label label--sml">Transaction type:</label>
									<div className="grid__expansion__value">{transactionType}</div>
								</div>
								<div className="f-col col-sml-12 col-med-6 col-xlrg-3 spc--bottom--sml">
									<label className="label label--sml">Payment type:</label>
									<div className="grid__expansion__value">{paymentType}</div>
								</div>
								<div className="f-col col-sml-12 col-med-6 col-xlrg-6 spc--bottom--sml">
									<label className="label label--sml">Entered date:</label>
									<div className="grid__expansion__value">
										{data.xEnteredDate.format(ApplicationSettings.displayDateTimeFormat)}
									</div>
								</div>
								{data && data.xOrderID && data.xOrderID.trim() !== '' ? (
									<div className="f-col col-sml-12 col-med-3 col-xlrg-3 spc--bottom--sml">
										<label className="label label--sml">
											{this.getGeneralInformationCustomLabel('orderId', 'Order Id')}:
										</label>
										<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
											{data.xOrderID}
										</div>
									</div>
								) : null}
								{data && data.xInvoice && data.xInvoice.trim() !== '' ? (
									<div className="f-col col-sml-12 col-med-3 col-xlrg-3 spc--bottom--sml">
										<label className="label label--sml">
											{this.getGeneralInformationCustomLabel('invoice', 'Invoice')}:
										</label>
										<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
											{data.xInvoice}
										</div>
									</div>
								) : null}
								{token && (
									<div className="f-col col-sml-12 spc--bottom--sml">
										<label className="label label--sml">Token:</label>
										<div className="grid__expansion__value type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word datatooltip--left">
											{token}
										</div>
									</div>
								)}
								<div className="f-col col-sml-12 col-med-3 col-xlrg-3 spc--bottom--sml">
									<label className="label label--sml">Account number:</label>
									<div className="grid__expansion__value type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word datatooltip--left">
										{payment !== 'check' && accountNumber ? (
											<a
												className="anchor anchor--primary"
												href={`/transactions?cardNumber=${accountNumber.slice(-4)}&disabled=true`}
												target="_blank"
												data-tooltip="Filter by last 4 digits"
											>
												{accountNumber}
											</a>
										) : (
											accountNumber
										)}
									</div>
								</div>
								{data && data.xCryptoStatus ? (
									<div className="f-col col-sml-12 col-med-3 col-xlrg-3 spc--bottom--sml">
										<label className="label label--sml">Status:</label>
										<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
											{data.xCryptoStatus}
										</div>
									</div>
								) : null}
								{data && parseFloat(data.xCryptoProcessingFee) ? (
									<div className="f-col col-sml-12 col-med-3 col-xlrg-3 spc--bottom--sml">
										<label className="label label--sml">Processing Fee:</label>
										<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
											<NumberFormat
												value={data.xCryptoProcessingFee}
												displayType="text"
												thousandSeparator={true}
												prefix={this.currencyCode}
												decimalScale={2}
												fixedDecimalScale={true}
											/>
										</div>
									</div>
								) : null}
								{data && parseFloat(data.xCryptoTransactionFee) ? (
									<div className="f-col col-sml-12 col-med-3 col-xlrg-3 spc--bottom--sml">
										<label className="label label--sml">Transaction Fee:</label>
										<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
											<NumberFormat
												value={data.xCryptoTransactionFee}
												displayType="text"
												thousandSeparator={true}
												prefix={this.currencyCode}
												decimalScale={2}
												fixedDecimalScale={true}
											/>
										</div>
									</div>
								) : null}
								{data && parseFloat(data.xCryptoNetworkFee) ? (
									<div className="f-col col-sml-12 col-med-3 col-xlrg-3 spc--bottom--sml">
										<label className="label label--sml">Network Fee:</label>
										<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
											<NumberFormat
												value={data.xCryptoNetworkFee}
												displayType="text"
												thousandSeparator={true}
												prefix={this.currencyCode}
												decimalScale={2}
												fixedDecimalScale={true}
											/>
										</div>
									</div>
								) : null}
								{data && parseFloat(data.xTax) ? (
									<div className="f-col col-sml-12 col-med-3 spc--bottom--sml">
										<label className="label label--sml">Tax:</label>
										<div className="grid__expansion__value">
											<NumberFormat
												value={data.xTax}
												displayType="text"
												thousandSeparator={true}
												prefix={this.currencyCode}
												decimalScale={2}
												fixedDecimalScale={true}
											/>
										</div>
									</div>
								) : null}
								{data && parseFloat(data.xServiceFee) ? (
									<div className="f-col col-sml-12 col-med-3 spc--bottom--sml">
										<label className="label label--sml">Service Fee:</label>
										<div className="grid__expansion__value">
											<NumberFormat
												value={data.xServiceFee}
												displayType="text"
												thousandSeparator={true}
												prefix={this.currencyCode}
												decimalScale={2}
												fixedDecimalScale={true}
											/>
										</div>
									</div>
								) : null}
								{avs ? (
									<div className="f-col col-sml-12 spc--bottom--sml">
										<label className="label label--sml">AVS:</label>
										<div className="grid__expansion__value">{avs}</div>
									</div>
								) : null}
								{this.renderReceiptItem(
									`${this.getGeneralInformationCustomLabel('avsStreet', 'AVS Street')}:`,
									data.xStreet
								)}
								{this.renderReceiptItem(
									`${this.getGeneralInformationCustomLabel('avsZip', `AVS ${this.zipLabel}`)}:`,
									data.xZip
								)}
								{data && data.xSerialNumber && data.xSerialNumber.trim() !== '' ? (
									<div className="f-col col-sml-12 col-med-3 col-xlrg-3 spc--bottom--sml">
										<label className="label label--sml">Serial Number</label>
										<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
											{data.xSerialNumber}
										</div>
									</div>
								) : null}
							</div>
						</Fragment>
					)}
				</div>
			</Fragment>
		);
	};
	renderMultiValues = (name, values) => {
		if (isArray(values)) {
			return values.map((e, index) => (
				<div key={index} className="grid__expansion__value">
					{e}
				</div>
			));
		} else {
			return (
				<div className="grid__expansion__value">
					{includes(['electronic transfer fee', 'original amount', 'service fee', 'tax'], toLower(name))
						? this.renderNumFormat(values)
						: values}
				</div>
			);
		}
	};
	renderNumFormat = value => {
		return (
			<NumberFormat
				prefix={this.currencyCode}
				value={value}
				displayType="text"
				thousandSeparator={true}
				decimalScale={2}
				fixedDecimalScale={true}
			/>
		);
	};

	renderReceiptItem = (name, value, renderEmpty = false) => {
		const { data } = this.state;
		const customerId = data.xCustomerID || data.xCustom01;
		const isCustomerId = value === customerId && /^[a-z]\d+/.test(value);
		const displayTooltip = startsWith(toLower(name), 'electronic transfer');
		if ((name && value) || renderEmpty) {
			return (
				<div className="f-col col-sml-12 col-med-6 col-xlrg-4 spc--bottom--sml">
					<label className="label label--sml datatooltip--w--140">
						{name}
						{displayTooltip && (
							<i
								data-tooltip="This field was previously referred to as 'Convenience Fee'"
								className="icon icon--nano icon--info align--v--middle spc--left--tny"
							></i>
						)}
					</label>
					<div className="spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word">
						{isCustomerId ? (
							<a
								className="anchor anchor--primary"
								href={`/customers?customerId=${this.state.data.xCustom01}&expandedRow=${this.state.data.xCustom01}`}
								target="_blank"
							>
								{value}
							</a>
						) : (
							<p className="type--wgt--medium">{this.renderMultiValues(name, value)}</p>
						)}
					</div>
				</div>
			);
		}
	};

	getCustomFieldLabel = label => {
		const { data } = this.state;
		const { convenienceCustomKey, originalCustomKey } = data;
		let title;
		if (label === convenienceCustomKey) title = 'Electronic Transfer Fee';
		if (label === originalCustomKey) title = 'Original Amount';

		return title;
	};
	renderVoidButton = () => {
		const {
			transactionStatus: { canVoid, isVoid, isApproved },
			permissions: { allowCcVoid, allowCheckVoid },
			data: { xResponseResult, xRefNum, xCommand, xCCType },
		} = this.state;
		const isOJC = toLower(xCCType) === 'ojc';
		const { row } = this.props;
		const paymentType = split(toLower(xCommand), ':')[0];
		const allowVoid = paymentType === 'cc' || paymentType === 'grant' ? allowCcVoid : allowCheckVoid;

		const isVoidable = paymentType === 'crypto' || isOJC ? false : allowVoid;
		const isSplitCapture = toLower(xCommand) === 'cc:splitcapture';

		if (isApproved || toLower(xResponseResult) === 'declined') {
			if (isVoid) {
				return (
					<button
						type="button"
						className="btn btn--clear btn--sml grid__expansion__btn"
						disabled={true}
						data-tooltip="The transaction is voided"
					>
						<i className="icon icon--sml icon--void icon--middle"></i>
					</button>
				);
			}

			if (canVoid && !isSplitCapture) {
				return (
					<button
						type="button"
						className="btn btn--clear btn--sml grid__expansion__btn"
						disabled={!isVoidable || this.hasTerminalOnly}
						data-tooltip={
							this.hasTerminalOnly
								? featurePackageTooltips.hasTerminalOnly
								: !isVoidable
								? 'Permission required'
								: 'Void'
						}
						onClick={() =>
							this.openModal(modalNames.void, {
								refNum: xRefNum,
								type: xCommand,
								refreshGridData: this.refreshGridData,
								xMerchantId: row.xMerchantId,
							})
						}
					>
						<i className="icon icon--sml icon--void icon--middle"></i>
					</button>
				);
			} else {
				return (
					<button
						type="button"
						className="btn btn--clear btn--sml grid__expansion__btn"
						disabled={true}
						data-tooltip="The transaction cannot be voided"
					>
						<i className="icon icon--sml icon--void icon--middle"></i>
					</button>
				);
			}
		}
	};

	renderRefundButton = () => {
		const {
			transactionStatus: { isApproved, isRefunded, isVoid, canRefund, canRefundAmount, isRefund },
			data,
			data: { xResponseResult, xRefNum: refNum, xAmount: amount, xCommand: type, xCurrency: currency },
			permissions: { allowCcRefund, allowCheckRefund },
		} = this.state;
		const { row } = this.props;
		const email = data && data.xEmail;
		const paymentType = split(toLower(type), ':')[0];
		const allowRefund = paymentType === 'cc' ? allowCcRefund : allowCheckRefund;
		const isRefundable = paymentType === 'crypto' ? false : allowRefund;

		if (isApproved || toLower(xResponseResult) === 'declined') {
			if (isRefunded && !isVoid) {
				if (canRefund && canRefundAmount > 0) {
					return (
						<Fragment>
							{!isRefundable ? (
								<button
									className="btn btn--clear btn--sml grid__expansion__btn"
									disabled={true}
									data-tooltip={this.hasTerminalOnly ? featurePackageTooltips.hasTerminalOnly : 'Permission required'}
								>
									<i className="icon icon--sml icon--refund icon--middle"></i>
								</button>
							) : (
								<button
									type="button"
									className="btn btn--clear btn--sml grid__expansion__btn"
									disabled={this.hasTerminalOnly}
									data-tooltip={
										this.hasTerminalOnly
											? featurePackageTooltips.hasTerminalOnly
											: 'The transaction is partially refunded'
									}
									onClick={() =>
										this.openModal(modalNames.refund, {
											refNum,
											amount: canRefundAmount,
											type,
											currency,
											refreshGridData: this.refreshGridData,
											email,
											xMerchantId: row.xMerchantId,
										})
									}
								>
									<i className="icon icon--sml icon--refund icon--middle"></i>
								</button>
							)}
						</Fragment>
					);
				}
			}

			if (isRefund && !isVoid) {
				return (
					<button
						type="button"
						className="btn btn--clear btn--sml grid__expansion__btn"
						disabled={true}
						data-tooltip="The transaction is refunded"
					>
						<i className="icon icon--sml icon--refund icon--middle"></i>
					</button>
				);
			}

			if (canRefund) {
				return (
					<Fragment>
						{allowRefund ? (
							<button
								className="btn btn--clear btn--sml grid__expansion__btn"
								data-tooltip={this.hasTerminalOnly ? featurePackageTooltips.hasTerminalOnly : 'Refund'}
								disabled={this.hasTerminalOnly}
								onClick={() => {
									this.openModal(modalNames.refund, {
										refNum,
										amount,
										type,
										currency,
										refreshGridData: this.refreshGridData,
										email,
										xMerchantId: row.xMerchantId,
									});
								}}
							>
								<i className="icon icon--sml icon--refund icon--middle"></i>
							</button>
						) : (
							<button
								type="button"
								className="btn btn--clear btn--sml grid__expansion__btn"
								disabled={true}
								data-tooltip={this.hasTerminalOnly ? featurePackageTooltips.hasTerminalOnly : 'Permission required'}
							>
								<i className="icon icon--sml icon--refund icon--middle"></i>
							</button>
						)}
					</Fragment>
				);
			} else {
				return (
					<button
						type="button"
						className="btn btn--clear btn--sml grid__expansion__btn"
						disabled={true}
						data-tooltip="The transaction cannot be refunded"
					>
						<i className="icon icon--sml icon--refund icon--middle"></i>
					</button>
				);
			}
		}
	};

	renderCaptureButton = () => {
		const {
			transactionStatus: { isVoid, isApproved, isSplitCaptured, canSplitCapture },
			permissions: { allowCcCapture },
			data,
			transactionStatus,
		} = this.state;
		const { autoPartialAuthReversal, splitCaptureEnabled, row } = this.props;
		const splitCapturedAndSettingDisabled = canSplitCapture && isSplitCaptured && !splitCaptureEnabled;
		const disabled = isVoid || !allowCcCapture || this.hasTerminalOnly || splitCapturedAndSettingDisabled;

		let component = null;
		let tooltip = 'Capture';
		if (this.hasTerminalOnly) {
			tooltip = featurePackageTooltips.hasTerminalOnly;
		} else if (!allowCcCapture) {
			tooltip = 'Permission required';
		} else if (isVoid) {
			tooltip = 'The transaction cannot be captured';
		} else if (splitCapturedAndSettingDisabled) {
			tooltip = 'Transaction was split captured and can no longer be captured. Enable split capture to continue';
		}

		if (this.isAuthOnly && !this.voided && isApproved) {
			component = (
				<button
					type="button"
					className="btn btn--clear btn--sml grid__expansion__btn"
					data-tooltip={tooltip}
					disabled={disabled}
					onClick={() => {
						this.openModal(modalNames.capture, {
							autoPartialAuthReversal,
							row: data,
							transactionStatus,
							refreshGridData: this.refreshGridData,
							splitCaptureEnabled,
							xMerchantId: row.xMerchantId,
						});
					}}
				>
					<i className="icon icon--sml icon--capture icon--middle"></i>
				</button>
			);
		}

		return <span className={splitCapturedAndSettingDisabled ? 'datatooltip--w--200' : ''}>{component}</span>;
	};

	renderCloseSplitCapturedAuthOnlyButton = () => {
		const {
			transactionStatus: { isSplitCaptured },
			data: row,
		} = this.state;

		return (
			isSplitCaptured && (
				<button
					type="button"
					className="btn btn--clear btn--sml grid__expansion__btn"
					data-tooltip="Close Split Capture"
					onClick={() =>
						this.openModal(modalNames.confirmAction, {
							loadingMessage: 'Closing Split Capture',
							question: 'This action cannot be undone. Are you sure you want to close Split Capture?',
							onConfirm: () => this.closeSplitCapturedAuthOnly(row),
						})
					}
				>
					<i className="icon icon--sml icon--void icon--middle"></i>
				</button>
			)
		);
	};

	renderAdjustButton = () => {
		const {
			row: { currency, xMerchantId },
			handleError,
		} = this.props;
		const {
			data: {
				xRefNum: refNum,
				xCommand: type,
				xAmount: amount,
				xTip: tip,
				xDescription: description,
				xOrderID: orderId,
				xCustom01: custom1,
				xCustom02: custom2,
				xCustom03: custom3,
				xResponseResult,
				xRefnumCurrent,
			},
			transactionStatus: { canAdjust, isApproved },
			permissions: { allowCcAdjust, allowCheckAdjust },
			customDisplayLabels,
		} = this.state;
		let component = null;
		const paymentType = split(toLower(type), ':')[0];
		const allowAdjust = paymentType === 'cc' ? allowCcAdjust : allowCheckAdjust;

		if (isApproved || toLower(xResponseResult) === 'declined') {
			if (canAdjust) {
				component = (
					<button
						type="button"
						className="btn btn--clear btn--sml grid__expansion__btn"
						disabled={this.hasTerminalOnly || !allowAdjust}
						data-tooltip={
							this.hasTerminalOnly
								? featurePackageTooltips.hasTerminalOnly
								: !allowAdjust
								? 'Permission required'
								: 'Adjust'
						}
						onClick={() => {
							this.openModal(modalNames.adjust, {
								refNum,
								type,
								amount,
								tip,
								description,
								orderId,
								custom1,
								custom2,
								custom3,
								currency,
								xRefnumCurrent,
								refreshGridData: this.refreshGridData,
								handleError,
								xMerchantId,
								customDisplayLabels,
							});
						}}
					>
						<i className="icon icon--xsml icon--edit icon--middle"></i>
					</button>
				);
			} else {
				component = (
					<button
						type="button"
						className="btn btn--clear btn--sml grid__expansion__btn"
						disabled={true}
						data-tooltip="The transaction cannot be adjusted"
					>
						<i className="icon icon--xsml icon--edit icon--middle"></i>
					</button>
				);
			}
		}

		return <Fragment>{component}</Fragment>;
	};

	renderNewTransactionButton = () => {
		const {
			transactionStatus: { isRefund },
			data,
			data: { xCommand, xToken, xResponseResult },
		} = this.state;

		if (toLower(xResponseResult) === 'error' && !xToken) return;

		const principal = principalService.get();
		const canProcessNewTransaction = principal.hasAccess[sectionKeys.newTransaction];
		const isSplitCapture = toLower(xCommand) === 'cc:splitcapture';
		const isEbt = includes(toLower(xCommand), 'ebt');
		const isCrypto = includes(toLower(xCommand), 'crypto');
		const tooltip = 'Add new transaction';

		const disabled =
			isRefund || !canProcessNewTransaction || isEbt || isSplitCapture || isCrypto || this.state.isCashSale;
		if (disabled) return;

		return (
			<button
				type="button"
				className="btn btn--clear btn--sml grid__expansion__btn"
				data-tooltip={tooltip}
				disabled={disabled}
				onClick={() => {
					if (this.hasTerminalOnly) {
						this.redirectToUpgradePlan();
					} else {
						this.openModal(modalNames.newTransaction, {
							existingTransaction: data,
							refreshGridData: this.refreshGridData,
							xMerchantId: this.props.row.xMerchantId,
						});
					}
				}}
			>
				<i className="icon icon--xsml icon--middle icon--newtransaction"></i>
			</button>
		);
	};

	openEmailSender = () => {
		this.setState({ showShareEmail: !this.state.showShareEmail });
	};

	handleEmailChange = ({ target: { value } }) => {
		this.setState({ shareEmail: value, shareEmailValid: validators.email(value) });
	};

	handleSendCopyChange = e => {
		const val = e.target.checked;
		this.setState({ sendCopy: val });
	};

	handlePrintError = (method, error) => {
		const { handleError } = this.props;
		if (handleError(error, { additionalInfo: { method } })) {
			this.setState({ isPrinting: false });
		}
	};

	handleAfterPrint = () => {
		this.setState({ isPrinting: false });
	};

	handleOnBeforeGetContent = () => {
		return new Promise(resolve => {
			this.setState({ isPrinting: true }, resolve);
		});
	};

	handleEmailNotificationVisible = () => {
		this.setState({ emailNotificationVisible: !this.state.emailNotificationVisible });
	};

	handleEmailSend = async () => {
		const { shareEmail, shareEmailValid, username, sendCopy, data } = this.state;

		if (shareEmailValid) {
			let emails = shareEmail;
			if (sendCopy && username) {
				emails += `,${username}`;
			}
			transactionService
				.sendReceipt(emails, data.xRefNum, this.props.row.xMerchantId)
				.then(rsp => {
					this.openEmailSender();
					let success = rsp.xStatus === 'Success';
					this.notificationRef.current.addNotification({
						message: success ? 'Transaction receipt was emailed successfully' : rsp.xError,
						ref: rsp.xRefNum,
						success: success,
					});
				})
				.catch(this.props.handleError);
		}
		this.setState({
			shareEmailSubmitted: true,
		});
	};

	renderSendEmailButton = () => {
		const {
			data: { xCommand },
		} = this.state;
		const transactionType = split(toLower(xCommand), ':')[1];

		return (
			!includes(['splitcapture', 'save'], transactionType) && (
				<button
					className="btn btn--sml btn--ghost btn--ghost--primary spc--left--sml spc--bottom--sml"
					onClick={() => this.openEmailSender()}
					data-tooltip="Email transaction receipt"
				>
					<i className="icon icon--xsml icon--envelope align--v--middle" />
				</button>
			)
		);
	};

	renderPrintButton = () => {
		const {
			isPrinting,
			data,
			transactionStatus,
			isLoading,
			logoUrlBase64,
			printerReceiptOption,
			customDisplayLabels,
		} = this.state;
		return (
			<Fragment>
				<ReactToPrint
					trigger={() => (
						<button
							disabled={isPrinting}
							className="btn btn--sml btn--ghost btn--ghost--primary"
							data-tooltip="Print transaction receipt"
						>
							<i className="icon icon--xsml icon--print align--v--middle" />
						</button>
					)}
					content={() => this.print}
					onPrintError={this.handlePrintError}
					onBeforeGetContent={this.handleOnBeforeGetContent}
					onAfterPrint={this.handleAfterPrint}
				/>
				<span style={{ display: 'none' }}>
					{renderIf(data && !isLoading)(
						<PrintTransactionReceiptComponent
							ref={el => (this.print = el)}
							data={{ ...data, ...transactionStatus }}
							logoUrlBase64={logoUrlBase64}
						/>
					)}
				</span>
				{printerReceiptOption && (
					<Fragment>
						<ReactToPrint
							trigger={() => (
								<button
									disabled={isPrinting}
									className="btn btn--sml btn--ghost btn--ghost--primary spc--left--sml"
									data-tooltip="Printer Receipt"
								>
									<i className="icon icon--xsml icon--receipt-print align--v--middle" />
								</button>
							)}
							content={() => this.printerReceipt}
							onPrintError={this.handlePrintError}
							onBeforeGetContent={this.handleOnBeforeGetContent}
							onAfterPrint={this.handleAfterPrint}
							customPageStyle="@page { width: 300px; margin: 10%; } }"
						/>
						<span style={{ display: 'none' }}>
							{renderIf(data && !isLoading)(
								<PrinterReceipt
									ref={el => (this.printerReceipt = el)}
									data={{ ...data, ...transactionStatus, customDisplayLabels }}
									logoUrlBase64={logoUrlBase64}
								/>
							)}
						</span>
					</Fragment>
				)}
			</Fragment>
		);
	};

	renderReceiptViewButton = () => {
		const {
			receiptVisible,
			data: { xRefNum },
			emailNotificationVisible,
			printerReceiptOption,
			isCashSale,
		} = this.state;

		return (
			<Fragment>
				<div className="display--ib datatooltip--view-transaction-receipt">
					<button
						className="btn btn--sml btn--ghost btn--ghost--primary spc--left--sml"
						data-tooltip="View transaction receipt"
						onClick={this.openReceiptViewModal}
					>
						<i className="icon icon--xsml icon--view align--v--middle" />
					</button>
				</div>
				<Modal
					isOpen={receiptVisible}
					hideCloseButton={emailNotificationVisible}
					onClose={this.closeReceiptViewModal}
					className="popup__content popup--med"
				>
					<TransactionReceipt
						emailNotificationVisible={emailNotificationVisible}
						handleEmailNotificationVisible={this.handleEmailNotificationVisible}
						closeModal={this.closeReceiptViewModal}
						refNum={xRefNum}
						xMerchantId={this.props.row.xMerchantId}
						printerReceiptOption={printerReceiptOption}
						isCashSale={isCashSale}
					/>
				</Modal>
			</Fragment>
		);
	};

	download = () => {
		const exporter = new ExportToCsv({ filename: generateFileName(false, this.props.row.xRefNum), ...exportOptions });
		const data = this.replaceColumns(cloneDeep(this.props.row), this.props.visibleColumns);
		if (data && exportService.shouldSubtract(head(data).Command)) {
			head(data).Amount = -Math.abs(head(data).Amount);
		}
		exporter.generateCsv(data);
	};

	replaceColumns = (data, columnsSource) => {
		const columns = this.getColumns(columnsSource);
		if (data) {
			let newObj = {};
			const columnKeys = Object.keys(columns);
			for (let colKey of columnKeys) {
				const newPropName = columns[colKey];
				if (data[colKey] !== undefined) {
					newObj[newPropName] = data[colKey];
				}
			}
			return [newObj];
		}
		return [];
	};

	getColumns = columns => {
		let returnObj = [];
		for (let col of columns) {
			returnObj[col.key] = col.name.replace(/\u00AD/g, '');
		}
		return returnObj;
	};

	openReceiptViewModal = () => {
		this.setState({ receiptVisible: true, emailNotificationVisible: false });
	};

	closeReceiptViewModal = () => {
		this.setState({ receiptVisible: false, emailNotificationVisible: false });
	};

	selectCustomer = selectedCustomer => {
		this.setState({ selectedCustomer });
	};

	linkTransactionToExistingCustomer = async () => {
		try {
			const {
				selectedCustomer,
				setNewCardAsDefault,
				data: {
					xCommand,
					xRefNum,
					xToken,
					xExp,
					xRouting,
					xAmount,
					xTip,
					xDescription,
					xOrderID,
					xCustom02,
					xCustom03,
					xZip,
					xStreet,
				},
			} = this.state;
			this.props.showLoader(true);
			let [paymentType, transactionType] = split(toLower(xCommand), ':');
			if (toLower(xCommand) === 'gift:redeem' || toLower(xCommand) === 'gift:issue') {
				paymentType = 'CC';
			}
			const [{ xRefNum: ref }] = await this.props.makePendingRequest(
				Promise.all([
					transactionService.transactionAdjust(
						xRefNum,
						null,
						paymentType,
						transactionType,
						xAmount,
						xTip,
						xDescription,
						xOrderID,
						selectedCustomer.customerId,
						xCustom02,
						xCustom03,
						false,
						this.props.row.xMerchantId
					),
					customerService.addCustomerPaymentMethod({
						customerId: selectedCustomer.customerId,
						tokenType: paymentType,
						token: xToken,
						setAsDefault: setNewCardAsDefault || !selectedCustomer.defaultPaymentMethodId,
						exp: xExp,
						routingNumber: xRouting,
						zip: xZip,
						street: xStreet,
					}),
				]),
				requestKeys.SAVE
			);
			await this.notificationRef.current.addNotification({
				success: true,
				message: 'Transaction and its payment method linked to customer',
				ref,
				customerId: selectedCustomer.customerId,
				showPaymentMethods: true,
			});
		} catch (e) {
			this.props.handleError(e);
		}
		this.closeLinkTransactionPopup();
		this.props.showLoader(false);
	};

	renderExportTransaction = () => {
		return (
			<button
				className="btn btn--sml btn--ghost btn--ghost--primary spc--left--sml"
				data-tooltip="Download transaction"
				onClick={() => this.download()}
			>
				<i className="icon icon--xsml icon--download align--v--middle" />
			</button>
		);
	};

	closeRenderLinkTransactionPopup = () => {
		this.setState({ isOpenLinkTransaction: false });
	};

	renderLinkTransactionPopup = () => {
		const {
			isOpenLinkTransaction,
			isOpenLinkExistingCustomer,
			ishiddenLinkTransaction,
			data,
			selectedCustomer,
		} = this.state;

		return (
			<Modal
				isOpen={isOpenLinkTransaction}
				onClose={this.closeLinkTransactionPopup}
				shouldCloseOnOverlayClick={false}
				overlayClassName={ishiddenLinkTransaction ? '' : 'popup__overlay'}
				className={`popup__content${ishiddenLinkTransaction ? ' display--n' : ''} ${
					isOpenLinkExistingCustomer ? 'popup--lrg' : ''
				}`}
			>
				<div ref={this.popupRef} className="">
					<div className={`popup__header ${isOpenLinkExistingCustomer ? 'popup__header--existing-customer' : ''}`}>
						<div className="popup__header__title">
							{`Link transaction to${isOpenLinkExistingCustomer ? ' an existing customer' : ':'}`}
						</div>
						{!isOpenLinkExistingCustomer ? (
							<Fragment>
								<div>
									<AddEditCustomer
										trigger={props => (
											<button
												className="btn btn--primary btn--sml spc--bottom--sml"
												{...props}
												onClick={() => {
													props.onClick();
												}}
											>
												New Customer
											</button>
										)}
										closeLinkTransactionPopup={this.closeLinkTransactionPopup}
										hideLinkTransactionPopup={this.hideLinkTransactionPopup}
										refreshGrid={this.refreshGridData}
										existingTransaction={data}
										advancedView={true}
										notificationRef={this.notificationRef}
										shouldRefreshGrid={false}
										xMerchantId={this.props.row.xMerchantId}
									/>
									<button
										className="btn btn--primary btn--sml spc--left--tny spc--bottom--sml"
										onClick={this.openLinkExistingCustomer}
									>
										Existing Customer
									</button>
								</div>
							</Fragment>
						) : (
							<p className="message message--primary">
								Please select the customer you'd like to link this transaction and its payment method to.
							</p>
						)}
					</div>
					{isOpenLinkExistingCustomer && (
						<div className="popup__body">
							<div className="grid__holder--link-trans">
								<LinkExistingCustomerForm
									popupRef={this.popupRef}
									selectCustomer={this.selectCustomer}
									existingTransaction={data}
								/>
							</div>
						</div>
					)}
					{isOpenLinkExistingCustomer && (
						<div className="popup__footer popup__footer--styled">
							<button type="button" className="btn btn--sml btn--ghost" onClick={this.closeRenderLinkTransactionPopup}>
								Cancel
							</button>
							<button
								type="button"
								tabIndex="-1"
								className="btn btn--sml btn--primary"
								onClick={this.linkTransactionToExistingCustomer}
								disabled={!selectedCustomer}
							>
								Save
							</button>
						</div>
					)}
				</div>
			</Modal>
		);
	};

	renderFooterButtons = () => [
		this.renderVoidButton(),
		this.renderRefundButton(),
		this.renderAdjustButton(),
		this.renderCaptureButton(),
		this.renderCloseSplitCapturedAuthOnlyButton(),
	];
	renderGridFooter = data => {
		if (this.props.hideFooter) return null;
		return (
			<div className="grid-sidebar__footer datatooltip--top-right">
				{this.renderNewTransactionButton()}
				{
					<RenderLinkPaymentMethodOrCustomer
						row={data}
						customClassNames={'btn btn--clear btn--sml grid__expansion__btn'}
						principal={this.principal}
						openLinkTransactionPopup={this.openLinkTransactionPopup}
					/>
				}
				{
					<RenderLinkPaymentMethodOrCustomer
						row={data}
						customClassNames={'btn btn--clear btn--sml grid__expansion__btn'}
						principal={this.principal}
						isLinkPaymentMethod={true}
						openLinkTransactionPopup={this.openLinkTransactionPopup}
					/>
				}
				{this.renderLinkTransactionPopup()}
				{toLower(data.xResponseResult) !== 'error' && this.renderFooterButtons()}
			</div>
		);
	};
	renderHeaderButtons = () => {
		const { isCashSale, showShareEmail, shareEmail, shareEmailValid, shareEmailSubmitted, sendCopy } = this.state;
		if (this.props.hideHeaderButtons) return null;
		return (
			<div className="clearfix">
				{!isCashSale && <div className="pull spc--bottom--sml">{this.renderPrintButton()}</div>}
				{!isCashSale && (
					<div className="pos--rel pull">
						{renderIf(!showShareEmail)(this.renderSendEmailButton())}
						{renderIf(showShareEmail)(
							<OutsideClick action={this.openEmailSender}>
								{this.renderSendEmailButton()}
								<div className="grid-sidebar__transaction-email">
									<div className="inputgroup">
										<div className="inputgroup--main padd--right--sml">
											<input
												type="text"
												name="email"
												className="input input--med"
												placeholder="E-mail"
												onChange={this.handleEmailChange}
												autoComplete="new-password"
												value={shareEmail}
											/>
										</div>
										<div className="inputgroup--aside">
											<button onClick={this.handleEmailSend} className="btn btn--med btn--primary">
												Send
											</button>
										</div>
									</div>
									{!shareEmailValid && shareEmailSubmitted ? (
										<div className="spc--top--sml type--color--warning">Please enter a valid email address</div>
									) : null}
									<div className="spc--top--sml">
										<input
											type="checkbox"
											name="sendCopy"
											className="input input--check"
											checked={sendCopy}
											onChange={this.handleSendCopyChange}
											id="sendCopy"
										/>
										<label htmlFor="sendCopy" className="type--none">
											Send me an email of this receipt
										</label>
									</div>
								</div>
							</OutsideClick>
						)}
					</div>
				)}
				<div className="pull spc--bottom--sml">{this.renderExportTransaction()}</div>
				<div className="pull spc--bottom--sml">{this.renderReceiptViewButton()}</div>
			</div>
		);
	};
	render() {
		const { data, isLoading, isCheck, displayAuthorization } = this.state;

		return (
			<Fragment>
				<Notification ref={this.notificationRef} />
				<div ref={this.props.setDetailsRef} className="grid-sidebar__wrapper grid-sidebar__wrapper--transactions">
					{isLoading ? (
						<div className="loader__holder fullheight">
							<div className="loader__spinner"></div>
						</div>
					) : null}
					{data ? (
						<Fragment>
							<div className="grid-sidebar__header">
								<h4 className="grid-sidebar__header__title">Transaction Information</h4>
								{this.props.closeRow && (
									<button className="grid-sidebar__close transaction" onClick={this.props.closeRow}></button>
								)}
							</div>
							<div className="grid-sidebar__toolbar">
								<div className="flex--tertiary spc--bottom--sml">
									<StatusComponent value={data.xResponseResult} error={data.xResponseError} />
									{isCheck && (
										<span className="spc--left--sml grid__expansion__value">
											ACH Status: <StatusFraudComponent value={data.xStatus} showBadge="true" />
										</span>
									)}
									<span className="spc--left--sml grid__expansion__value">Ref #: {data.xRefNum}</span>
								</div>
								<div className="flex--tertiary">
									<a
										className="anchor anchor--primary type--sml type--underline spc--bottom--sml"
										onClick={() => this.toggleExpand('all')}
									>
										<span className="display--ib spc--right--nano align--v--middle">
											{`${this.allExpanded ? 'Collapse' : 'Expand'} all`}
										</span>
										<i className="icon icon--tiny icon--expand-vertical--primary icon--middle"></i>
									</a>
									{this.renderHeaderButtons()}
								</div>
							</div>
							<div className="separator--grey1"></div>
							<div className="grid-sidebar__body">
								{this.renderTransactionDetails(displayAuthorization)}
								{this.renderBillingDetails('Bill')}
								{this.renderBillingDetails('Ship')}
								{this.renderProcessingDetails()}
								{this.renderIssuingBankInfo()}
								{this.renderCustomFields()}
								{this.renderAdditionalInformation()}
							</div>

							{this.renderGridFooter(data)}
						</Fragment>
					) : null}
				</div>
			</Fragment>
		);
	}
}

RowDetailsComponent.propTypes = {
	row: PropTypes.object,
	visibleColumns: PropTypes.array,
	refreshGridData: PropTypes.func,
	openModal: PropTypes.func,
	setDetailsRef: PropTypes.func,
	makePendingRequest: PropTypes.func,
	showLoader: PropTypes.func.isRequired,
	handleError: PropTypes.func,
	history: PropTypes.object,
	closeRow: PropTypes.func.isRequired,
	autoPartialAuthReversal: PropTypes.bool,
	splitCaptureEnabled: PropTypes.bool,
	hideFooter: PropTypes.bool,
	hideHeaderButtons: PropTypes.bool,
};

export default withCancelable(withError(withLoader(withForwardRef(RowDetailsComponent, withRouter))));
