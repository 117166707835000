import React from 'react';
import { map, get } from 'lodash';

import { NumericFilter, StringFilter } from '../columns/filters';

import { CurrencyComponent, CreditCardComponent } from '../columns/formatters';
import { ColumnHeader } from '../columns/headers';
import { principalService } from 'common/services';

const columns = [
	{
		key: 'xMaskedCardNumber',
		name: 'Gift Card Number',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		formatter: CreditCardComponent,
		getRowMetaData: row => row,
		initWidth: 275,
		resizable: true,
	},
	{
		key: 'xRemainingBalance',
		name: 'Remaining Balance',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: NumericFilter,
		formatter: CurrencyComponent,
		getRowMetaData: row => {
			return { ...row, displayBadge: true };
		},
		initWidth: 275,
		resizable: true,
		alignHeaderRight: true,
	},
	{
		key: 'xFirstName',
		name: 'First Name',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 275,
		resizable: true,
	},
	{
		key: 'xLastName',
		name: 'Last Name',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 275,
		resizable: true,
	},
	{
		key: 'xEmail',
		name: 'Email',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 275,
		resizable: true,
	},
];

const giftCardLiabilityColumns = () => {
	const mappedColumns = map(columns, column => {
		column = {
			headerRenderer: <ColumnHeader />,
			...column,
		};
		return column;
	});
	let principal = principalService.get();
	let giftReportLiabilityIncludeActivationStatus = get(principal, 'idInfo.xGiftReportLiabilityIncludeActivationStatus');
	principalService.subscribe(data => {
		principal = data;
		giftReportLiabilityIncludeActivationStatus = get(principal, 'idInfo.xGiftReportLiabilityIncludeActivationStatus');
	});
	if (giftReportLiabilityIncludeActivationStatus) {
		mappedColumns.push({
			headerRenderer: <ColumnHeader />,
			key: 'xActivationStatus',
			name: 'Activation Status',
			sortable: true,
			hideable: true,
			visible: true,
			filterable: true,
			filterRenderer: StringFilter,
			initWidth: 275,
			resizable: true,
		});
	}
	return mappedColumns;
};

export default giftCardLiabilityColumns;
