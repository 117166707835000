import React from 'react';

const Dollar = () => (
	<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			opacity="0.1"
			d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z"
			fill="#2E86EB"
		/>
		<path
			d="M30.45 21C30.45 15.7809 26.2191 11.55 21 11.55C15.7809 11.55 11.55 15.7809 11.55 21C11.55 26.2191 15.7809 30.45 21 30.45C26.2191 30.45 30.45 26.2191 30.45 21Z"
			fill="#77AFF1"
		/>
		<path
			d="M20.1357 27.5422V26.0189C19.0438 25.9707 17.9843 25.6823 17.365 25.3294L17.854 23.4534C18.5385 23.8221 19.5001 24.1587 20.5596 24.1587C21.4887 24.1587 22.1242 23.8062 22.1242 23.1645C22.1242 22.5552 21.6027 22.1703 20.3966 21.7696C18.6527 21.1923 17.4629 20.3906 17.4629 18.8351C17.4629 17.4241 18.4733 16.3176 20.2174 15.9808V14.4575H21.8146V15.8686C22.9066 15.9167 23.6401 16.1412 24.1778 16.3977L23.7052 18.2097C23.2815 18.0334 22.5318 17.6645 21.3582 17.6645C20.2988 17.6645 19.9566 18.1135 19.9566 18.5625C19.9566 19.0916 20.5271 19.4283 21.9124 19.9416C23.8519 20.615 24.6343 21.4969 24.6343 22.9401C24.6343 24.3672 23.6075 25.5858 21.7331 25.9064V27.5418L20.1357 27.5422Z"
			fill="#DFE7F1"
		/>
	</svg>
);

export default Dollar;
