import { filter, forOwn, isEqual, trim } from 'lodash';
import moment from 'moment';

export const parseFilters = (filters, filterItem) => {
	let hasSelection = false;
	forOwn(filters.values, (_, key) => {
		const valueItem = filter(filterItem.values, { key: key });
		if (valueItem.length > 0) {
			filters.values[key] = valueItem[0].value;
			if (moment.isMoment(filters.values[key])) {
				if (!filterItem.emptyValue || !filters.values[key].isSame(filterItem.emptyValue, 'day')) {
					hasSelection = true;
				}
			} else if (
				filters.values[key] !== undefined &&
				filters.values[key] !== null &&
				trim(filters.values[key]) &&
				filters.values[key] !== filterItem.emptyValue &&
				!isEqual(filters.values, filters.defaultValues)
			) {
				hasSelection = true;
			}
		} else {
			filters.values[key] = filterItem.emptyValue;
		}
	});
	filters.hasSelection = hasSelection;
};
