import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { padStart } from 'lodash';

class LastDigitsFilterComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			cnError: null,
		};
	}

	componentDidUpdate() {
		const { noFocus } = this.props;
		if (this.inputRef && !noFocus) {
			this.inputRef.focus();
		}
	}

	setInputRef = el => {
		this.inputRef = el;
	};

	handleChange = ({ value }) => {
		const {
			numberOfDigits,
			filter: { key },
			onFilterChanged,
		} = this.props;

		let regex = new RegExp(`\\d{${numberOfDigits}}`);

		if (key === 'giftCardNumber') {
			regex = new RegExp(`\\d{7,${numberOfDigits}}`);
		}

		if (value != null && (value.match(regex) != null || value === '')) {
			if (value === '') {
				value = null;
			}
			onFilterChanged({
				id: key,
				values: [{ key, value }],
				emptyValue: null,
			});
		}
	};

	render() {
		const {
			numberOfDigits,
			noFocus,
			placeholder,
			filter: { key, values, standalone },
			goButtonHandler,
			goButtonText,
		} = this.props;
		const { cnError } = this.state;
		return (
			<div className="pos--rel inputgroup">
				<div className="inputgroup--main">
					<NumberFormat
						inputMode="numeric"
						className={`input input--sml ${
							standalone
								? 'input--search input--med inputgroup--main--bordered--left'
								: goButtonHandler
								? 'inputgroup--main--bordered--left'
								: ''
						}`}
						placeholder={placeholder || `Last ${numberOfDigits} Digits`}
						onValueChange={this.handleChange}
						value={values[key] == null ? '' : values[key]}
						format={padStart('', numberOfDigits, '#')}
						mask={key === 'giftCardNumber' ? '' : '_'}
						getInputRef={this.setInputRef}
						autoFocus={!noFocus}
					/>
				</div>
				{cnError && <span>{cnError}</span>}
				{goButtonHandler && (
					<div className="inputgroup--aside">
						<button
							className={`btn btn--${standalone ? 'med' : 'sml'} btn--primary inputgroup--aside--bordered--right`}
							data-tooltip={goButtonText ? null : 'Apply'}
							onClick={goButtonHandler}
						>
							{goButtonText || <i className="icon icon--nano icon--arrow--right--positive"></i>}
						</button>
					</div>
				)}
			</div>
		);
	}
}

LastDigitsFilterComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
	numberOfDigits: PropTypes.number,
	noFocus: PropTypes.bool,
	placeholder: PropTypes.string,
	goButtonHandler: PropTypes.func,
	goButtonText: PropTypes.string,
};

export default LastDigitsFilterComponent;
