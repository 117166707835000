import React from 'react';
import PropTypes from 'prop-types';
import { every, isEmpty, trim } from 'lodash';
import { Address4, Address6 } from 'ip-address';

const isValidIp = ip => {
	return new Address4(trim(ip)).isValid() || new Address6(trim(ip)).isValid();
};

const IpInput = props => {
	const handleChange = event => {
		props.onChange({ eventArgs: event });
	};

	const handleBlur = event => {
		const {
			target: { value },
		} = event;
		const allIPs = value.split('\n');
		const allIPsAreValid = isEmpty(value) ? true : every(allIPs, ip => isValidIp(ip));

		props.onBlur({ areAllIPsValid: allIPsAreValid, eventArgs: event });
	};

	return (
		<textarea
			value={props.value}
			id={props.id}
			name={props.name}
			onChange={handleChange}
			onBlur={handleBlur}
			placeholder="000.000.000.000"
			className="input input--textarea input--textarea--vertical"
		></textarea>
	);
};

export default IpInput;
IpInput.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
};
