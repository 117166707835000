import React from 'react';
import { map } from 'lodash';

import { ActionsComponent, UserRoleComponent } from '../../columns/formatters';
import { ColumnHeader } from 'common/components/columns/headers';
import { MultiselectCardknoxFilter, StringFilter } from 'common/components/columns/filters';

const columns = [
	{
		key: 'xEmail',
		name: 'User',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 200,
		isDefaultSorter: true,
		defaultSortDirection: 'ASC',
	},
	{
		key: 'xRole',
		name: 'Role',
		formatter: UserRoleComponent,
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: MultiselectCardknoxFilter,
		initWidth: 150,
	},
	{
		key: 'actions',
		name: '',
		sortable: false,
		hideable: false,
		visible: true,
		filterable: false,
		hideOnExport: true,
		formatter: ActionsComponent,
		getRowMetaData: row => row,
		initWidth: 75,
	},
];

export const UserColumns = map(columns, column => {
	column = {
		headerRenderer: <ColumnHeader />,
		...column,
	};
	return column;
});
