import {
	extend,
	every,
	isEqual,
	map,
	replace,
	toLower,
	padStart,
	startsWith,
	get,
	clone,
	round,
	some,
	includes,
} from 'lodash';
import { paymentMethod, transactionType, convenienceFeeTypes } from './constants';

import { createField, validators } from '../../Common/fields';
import { principalService } from 'common/services';

export function toCurrency(value) {
	return parseFloat(parseFloat(value).toFixed(2));
}

function mapCcFields(data, rsp) {
	const cc = data.creditCard;

	if (!data.differentPayment) {
		rsp.xToken = cc.token;
		rsp.xCvv = cc.cvv.value;
	} else {
		rsp.xCardNum = cc.ccToken;
		if (!cc.isSwipe.value) {
			rsp.xCvv = cc.cvvToken;
			rsp.xExp = cc.expiry.value;
		}
	}
	if (cc.transactionType === transactionType.POST_AUTH) {
		rsp.xAuthCode = cc.authCode.value;
	}
	if (cc.transactionType === transactionType.EBTFS_VOUCHER) {
		rsp.xAuthCode = cc.authCode.value;
		rsp.xVoucherSerial = cc.voucherSerial.value;
	}
	if (cc.cvvToken) {
		rsp.xCvv = cc.cvvToken;
	}
	if (startsWith(data.creditCard.transactionType, 'gift')) {
		rsp.xCommand = data.creditCard.transactionType;
	} else if (startsWith(data.creditCard.transactionType, 'ebtfs')) {
		rsp.xCommand = data.creditCard.transactionType;
	} else {
		if (startsWith(data.creditCard.transactionType, 'cc')) {
			rsp.xCommand = data.creditCard.transactionType;
		} else {
			removexAmount(cc.transactionType, rsp);
			rsp.xCommand = 'cc:' + cc.transactionType; // this will produce cc:sale, cc:authOnly, etc.
		}
	}
}
function mapCheckFields(data, rsp) {
	const check = data.check;

	if (!data.differentPayment) {
		rsp.xToken = check.token;
	} else {
		rsp.xName = check.accountName.value;
		rsp.xAccountType = check.accountType;
		rsp.xRouting = check.routingNumber.value;
		rsp.xAccount = check.achToken;
	}
	rsp.xCommand = data.paymentMethod + ':sale';
}
function mapStandaloneFields(data, rsp) {
	if (data.paymentMethod === 'cc') {
		mapCcFields(data, rsp);
	} else if (data.paymentMethod === 'check') {
		mapCheckFields(data, rsp);
	} else {
		throw new Error('Unknown payment method'); // this should never occur. But just in case someone is keen to play around
	}

	// billing info
	let billingInfo = parseCustomerInfo(data.billingInfo);

	let avsInfo = null;
	let shippingInfo = parseCustomerInfo(data.shippingInfo, 'shipping');
	if (data.avsInfo) {
		const { zip, address } = data.avsInfo;
		avsInfo = {
			xZip: zip.value,
			xStreet: address.value,
		};
	}

	let transactionDetails = parseTransactionDetails(data.transactionDetails);
	let customFields = parseCustomFields(data.customFields, data.saveAsCustomer ? 2 : 1);

	extend(rsp, billingInfo, shippingInfo, avsInfo, transactionDetails, customFields);
}

function mapCustomerCcFields(data, rsp, useMethodOnFile) {
	const procesingTransactionType = get(data, 'creditCard.transactionType');
	if (startsWith(procesingTransactionType, 'gift')) {
		rsp.xCommand = procesingTransactionType;
	} else if (startsWith(procesingTransactionType, 'ebtfs')) {
		rsp.xCommand = procesingTransactionType;
	} else {
		if (startsWith(procesingTransactionType, 'cc')) {
			rsp.xCommand = procesingTransactionType;
		} else {
			removexAmount(procesingTransactionType, rsp);
			rsp.xCommand = 'cc:' + procesingTransactionType; // this will produce cc:sale, cc:authOnly, etc.
		}
	}
	if (data.creditCard.transactionType === transactionType.POST_AUTH) {
		rsp.xAuthCode = data.creditCard.authCode.value;
	}
	if (data.creditCard.transactionType === transactionType.EBTFS_VOUCHER) {
		rsp.xAuthCode = data.creditCard.authCode.value;
		rsp.xVoucherSerial = data.creditCard.voucherSerial.value;
	}
	if (!useMethodOnFile && !data.saveToFile) {
		rsp.xCardNum = data.creditCard.ccToken;
		rsp.xExp = data.creditCard.expiry.value;
		if (data.creditCard.cvvToken) {
			rsp.xCvv = data.creditCard.cvvToken;
		}
	}
}

/**
 * Maps data from Form(in new-transaction.js) to object for Cardknox API
 * @param data Form data from NewTransaction form
 */
export async function mapToCardknoxFields(data) {
	const useMethodOnFile = data.selectedCustomerPaymentMethod && !data.selectedCustomerPaymentMethod.newMethod;
	let rsp = {};

	rsp.xAmount = parseFloat(data.amount.value);
	rsp.xCustReceipt = data.sendReceipt;

	if (data.sendReceipt || !data.transactionHiddenFields.email) {
		rsp.xEmail = replace(data.email.value, /\s/g, '');
	}

	if (data.customerId) {
		rsp.xCustomerId = data.customerId;

		let customFields = parseCustomFields(data.customFields, 2);
		let transactionDetails = parseTransactionDetails(data.transactionDetails);
		let billingInfo = parseCustomerInfo(data.billingInfo);
		let shippingInfo = parseCustomerInfo(data.shippingInfo, 'shipping');
		let avsInfo = null;

		if (data.paymentMethod === 'cc') {
			mapCustomerCcFields(data, rsp, useMethodOnFile);
		} else if (data.paymentMethod === 'check') {
			rsp.xCommand = data.paymentMethod + ':sale';
			if (!useMethodOnFile && !data.saveToFile) {
				rsp.xName = data.check.accountName.value;
				rsp.xAccountType = data.check.accountType;
				rsp.xRouting = data.check.routingNumber.value;
				rsp.xAccount = data.check.achToken;
			}
		} else {
			throw new Error('Unknown payment method'); // this should never occur. But just in case someone is keen to play around
		}

		if (data.avsInfo) {
			const { zip, address } = data.avsInfo;
			avsInfo = {
				xZip: zip.value,
				xStreet: address.value,
			};
		}

		rsp = extend(rsp, billingInfo, shippingInfo, avsInfo, customFields, transactionDetails);

		const hasCvv = data.paymentMethod === paymentMethod.CC && data.creditCard.cvv.value;
		if (hasCvv) {
			rsp.xCvv = data.creditCard.cvv.value;
		}

		const principal = principalService.get();
		await principalService.emailPromise;
		if (principal && principal.username) {
			rsp.xUserName = principal.username;
		}

		if (useMethodOnFile) {
			rsp.xToken = data.selectedCustomerPaymentMethod.xToken;
		}

		rsp.xPaymentMethodID = data.selectedCustomerPaymentMethod.xPaymentMethodID;
		rsp.xCustID = data.customerId;
		rsp.xCustom01 = data.customerId;
		rsp.xCustom02 = data.customFields.custom2.value;
	} else {
		mapStandaloneFields(data, rsp);
	}

	if (!(data.paymentMethod === 'cc' && data.creditCard && data.creditCard.transactionType === transactionType.SAVE)) {
		const convenienceBeforeSales = data.convenienceBeforeSales;
		const amount = clone(rsp.xAmount);

		if (data.includeSalesTax && get(data, 'salesTax.enableSalesTax')) {
			const salesTaxAmount = data.isSalesTaxAmountOverwritten
				? data.salesTaxAmount
				: calculateSalesTax(
						convenienceBeforeSales && data.includeConvenience
							? amount + calculateConvenienceFee(amount, data.convenienceFees, data.paymentMethod)
							: amount,
						get(data.salesTax, 'salesTaxPercentage', 0)
				  );
			if (salesTaxAmount) {
				addSalesTax(rsp, salesTaxAmount);
				rsp.xTax = salesTaxAmount;
			}
		}

		addConvenienceFee(convenienceBeforeSales || !data.includeSalesTax ? amount : amount + (rsp.xTax || 0), rsp, data);
	}

	if (data.custId) {
		rsp.xCustomerID = data.custId;
	}

	return cleanObject(rsp);
}

export function addConvenienceFee(amount, rsp, data) {
	const { convenienceFees, salesTax, paymentMethod: type, includeSalesTax, includeConvenience } = data;
	const convenienceFee = includeConvenience ? calculateConvenienceFee(amount, convenienceFees, type) : 0;

	if (
		convenienceFees.originalCustomKey &&
		((includeConvenience && convenienceFee && convenienceFees.enableConvenienceFee) ||
			(includeSalesTax && salesTax.enableSalesTax))
	) {
		rsp[`x${convenienceFees.originalCustomKey}`] = parseFloat(data.amount.value);
	}

	if (
		convenienceFee &&
		convenienceFees.convenienceCustomKey &&
		(includeConvenience && convenienceFees.enableConvenienceFee)
	) {
		rsp[`x${convenienceFees.convenienceCustomKey}`] = convenienceFee;
	}

	rsp.xAmount = toCurrency(rsp.xAmount + convenienceFee);
}

export function addSalesTax(rsp, salesTaxAmount) {
	rsp.xAmount = toCurrency(rsp.xAmount + salesTaxAmount);
}

export function checkHasConvenienceFee(convenienceFees, type) {
	let prefix;
	switch (toLower(type)) {
		case paymentMethod.CC:
			prefix = 'cc';
			break;
		case paymentMethod.CHECK:
			prefix = 'ach';
			break;
		default:
			return false;
	}
	if (convenienceFees.enableConvenienceFee) {
		switch (convenienceFees[`${prefix}Type`]) {
			case convenienceFeeTypes.percentage:
				return !!convenienceFees[`${prefix}PercentageAmount`];
			case convenienceFeeTypes.flat:
				return !!convenienceFees[`${prefix}FlatAmount`];
			default:
				return false;
		}
	}
	return false;
}

export function calculateSalesTax(originalAmount, salesTaxPercentage) {
	return toCurrency(round((originalAmount * salesTaxPercentage) / 100, 2));
}

export function calculateConvenienceFee(originalAmount, convenienceFees, type) {
	if (convenienceFees.enableConvenienceFee) {
		if (toLower(type) === paymentMethod.CC) {
			switch (convenienceFees.ccType) {
				case convenienceFeeTypes.percentage: {
					if (convenienceFees.ccPercentageAmount) {
						return toCurrency(round((originalAmount * convenienceFees.ccPercentageAmount) / 100, 2));
					}
					break;
				}
				case convenienceFeeTypes.flat: {
					if (convenienceFees.ccFlatAmount) {
						return toCurrency(convenienceFees.ccFlatAmount);
					}
					break;
				}
				default: {
					break;
				}
			}
		} else if (toLower(type) === paymentMethod.CHECK) {
			switch (convenienceFees.achType) {
				case convenienceFeeTypes.percentage: {
					if (convenienceFees.achPercentageAmount) {
						return toCurrency(round((originalAmount * convenienceFees.achPercentageAmount) / 100, 2));
					}
					break;
				}
				case convenienceFeeTypes.flat: {
					if (convenienceFees.achFlatAmount) {
						return toCurrency(convenienceFees.achFlatAmount);
					}
					break;
				}
				default: {
					break;
				}
			}
		}
	}
	return 0;
}

const setTransactionType = (ccObject, ref, permissions) => {
	ccObject.transactionType = '';
	if (ref.props.existingTransaction && startsWith(toLower(ref.props.existingTransaction.xCommand), 'gift')) {
		ccObject.transactionType = transactionType.GIFT_ISSUE;
	} else if (permissions.allowCcSale) {
		ccObject.transactionType = transactionType.SALE;
	} else if (permissions.allowCcAuthOnly) {
		ccObject.transactionType = transactionType.AUTH_ONLY;
	} else if (permissions.allowCcCredit) {
		ccObject.transactionType = transactionType.CREDIT;
	} else if (permissions.allowCcPostAuth) {
		ccObject.transactionType = transactionType.POST_AUTH;
	} else if (permissions.allowEbtfsVoucher) {
		ccObject.transactionType = transactionType.EBTFS_VOUCHER;
	}
};

const cvvField = (ccObject, ref) => {
	ccObject.cvv = createField(
		'',
		validators.if(
			validators.required,
			() =>
				((ref.state.paymentMethod === paymentMethod.CC &&
					ref.state.customerId &&
					ref.state.selectedCustomerPaymentMethod &&
					!ref.state.selectedCustomerPaymentMethod.newMethod) ||
					(!ref.state.differentPayment && ref.state.creditCard.token)) &&
				(ref.props.existingTransaction || ref.state.selectedCustomerPaymentMethod
					? ref.state.requiredFields.cvvAllTransactionsRequired
					: ref.state.requiredFields.cvvNewTransactionsOnlyRequired) &&
				ref.state.creditCard &&
				!startsWith(ref.state.creditCard.transactionType, 'gift')
		)
	);
};

const expiryField = (ccObject, data, ref) => {
	ccObject.expiry = createField(
		data.xExp,
		validators.if(
			validators.expDate,
			field =>
				validators.required(field) &&
				!ref.state.creditCard.isSwipe.value &&
				ref.validateCc &&
				ref.state.creditCard &&
				!startsWith(ref.state.creditCard.transactionType, 'gift')
		),
		validators.if(
			validators.required,
			() =>
				!ref.state.creditCard.isSwipe.value &&
				ref.validateCc &&
				(ref.state.creditCard &&
					!startsWith(ref.state.creditCard.transactionType, 'gift') &&
					!startsWith(ref.state.creditCard.transactionType, 'ebt'))
		)
	);
};
const ccValidField = (ccObject, ref) => {
	ccObject.ccValid = createField(
		false,
		validators.if(
			validators.assert(true),
			() =>
				!ref.state.creditCard.isSwipe.value &&
				ref.state.differentPayment &&
				ref.validateCc &&
				!ref.state.customerId &&
				(ref.state.creditCard.cardNumberIsEmpty || !startsWith(ref.state.creditCard.transactionType, 'gift'))
		)
	);
};

const cvvValidField = (ccObject, ref) => {
	ccObject.cvvValid = createField(
		ref.state &&
			ref.state.requiredFields &&
			(ref.props.existingTransaction || ref.validateCc
				? !ref.state.requiredFields.cvvAllTransactionsRequired
				: !ref.state.requiredFields.cvvAllTransactionsRequired &&
				  !ref.state.requiredFields.cvvNewTransactionsOnlyRequired),
		validators.if(
			validators.assert(true),
			() =>
				!ref.state.creditCard.isSwipe.value &&
				ref.state.differentPayment &&
				ref.validateCc &&
				ref.state.creditCard &&
				!startsWith(ref.state.creditCard.transactionType, 'gift')
		)
	);
};
const ccAuthCode = (ccObject, ref) => {
	ccObject.authCode = createField(
		'',
		validators.if(
			validators.required,
			() =>
				ref.validateCc &&
				(ref.state.creditCard &&
					(ref.state.creditCard.transactionType === transactionType.POST_AUTH ||
						ref.state.creditCard.transactionType === transactionType.EBTFS_VOUCHER))
		)
	);
};

const voucherField = (ccObject, ref) => {
	ccObject.voucherSerial = createField(
		'',
		validators.if(
			validators.required,
			() =>
				ref.validateCc && ref.state.creditCard && ref.state.creditCard.transactionType === transactionType.EBTFS_VOUCHER
		)
	);
};
export function mapToTransactionFields(ref, data, permissions = {}) {
	let obj = {};

	obj.amount = createField(
		get(ref, 'state.creditCard.transactionType') === transactionType.SAVE ? 0 : '',
		validators.if(
			validators.currency,
			() =>
				(ref.state.paymentMethod === paymentMethod.CC &&
					(ref.state.creditCard && ref.state.creditCard.transactionType) !== transactionType.SAVE) ||
				ref.state.paymentMethod === paymentMethod.CHECK
		)
	);
	obj.formattedAmount = '$' + obj.amount.value;
	const [method, hasPermission] = getTransactionType(data.xCommand, permissions);
	obj.paymentMethod = method;

	if (obj.paymentMethod === paymentMethod.CC) {
		let ccObject = {};
		setTransactionType(ccObject, ref, permissions);
		expiryField(ccObject, data, ref);
		cvvField(ccObject, ref);
		ccObject.cardholderName = createField('');
		ccObject.isSwipe = createField(false);
		ccValidField(ccObject, ref);
		cvvValidField(ccObject, ref);
		ccAuthCode(ccObject, ref);
		voucherField(ccObject, ref);
		ccObject.token = hasPermission ? data.xToken : '';
		obj.differentPayment = !ccObject.token;

		obj.creditCard = ccObject;
	}
	if (obj.paymentMethod === paymentMethod.CHECK) {
		let checkObject = {};

		checkObject.token = hasPermission ? data.xToken : '';
		obj.differentPayment = !checkObject.token;

		checkObject.accountName = createField('', validators.if(validators.required, () => ref.validateCheck));
		checkObject.routingNumber = createField('', validators.if(validators.required, () => ref.validateCheck));
		checkObject.achValid = createField('', validators.if(validators.assert(true), () => ref.validateCheck));
		obj.check = checkObject;
	}

	obj.email = createField(
		data.xEmail,
		validators.if(
			validators.email,
			field => validators.required(field) && (ref.state.sendReceipt || !ref.state.transactionHiddenFields.email)
		),
		validators.if(validators.required, () => ref.state.requiredFields.email || ref.state.sendReceipt)
	);

	let billingObj = parseCardknoxCustomerInfo(ref, data, 'billing');
	let shippingObj = parseCardknoxCustomerInfo(ref, data, 'shipping');
	let avsObj = parseCardknoxCustomerInfo(ref, data, 'avs');
	let transactionObj = parseCardknoxTransactionDetails(ref, data);
	let customFieldsObj = parseCardknoxCustomFields(ref, data);

	obj.billingInfo = billingObj;
	obj.shippingInfo = shippingObj;
	obj.originalShippingInfo = shippingObj;
	obj.avsInfo = avsObj;
	obj.sameAsBilling = isEqual(
		map(billingObj, ({ value }, key) => ({ [key]: value })),
		map(shippingObj, ({ value }, key) => ({ [key]: value }))
	);

	obj.transactionDetails = transactionObj;
	obj.customFields = customFieldsObj;

	if (data.xCustomerID || data.xCustID) {
		obj.custId = data.xCustomerID || data.xCustID;
	}

	return obj;
}

function removexAmount(tType, rsp) {
	if (toLower(tType) === 'save') {
		delete rsp.xAmount;
	}
}

function getTransactionType(command, { allowCcSale, allowCcAuthOnly, allowCcCredit, allowCcPostAuth, allowCheckSale }) {
	const type = toLower(command);
	const canCc = some([allowCcSale, allowCcAuthOnly, allowCcCredit, allowCcPostAuth]);
	const canCheck = allowCheckSale;
	const isCCType = some(['cc', 'gift', 'avsonly', 'grant'], sub => includes(type, sub));
	const isCheckType = includes(type, 'check');

	if (isCCType) {
		if (canCc) return [paymentMethod.CC, true];
		if (canCheck) return [paymentMethod.CHECK, false];
	} else if (isCheckType) {
		if (canCheck) return [paymentMethod.CHECK, true];
		if (canCc) return [paymentMethod.CC, false];
	}

	throw new Error('Unknown payment type');
}

const parseAvsInfo = (obj, data) => {
	obj.address = createField(data.xStreet ? data.xStreet : '');
	obj.zip = createField(data.xZip ? data.xZip : '');
	return obj;
};

function parseCardknoxCustomerInfo(ref, data, infoType = 'billing') {
	let obj = {};
	let placeholder = 'Bill';

	if (infoType === 'shipping') {
		placeholder = 'Ship';
	}

	if (infoType === 'avs') {
		return parseAvsInfo(obj, data);
	}

	const isCustomerValid = validators.any(
		() => ref.state.billingInfo.firstName,
		() => ref.state.billingInfo.lastName,
		() => ref.state.billingInfo.company
	)(validators.if(validators.required, () => infoType === 'billing' && ref.state.saveAsCustomer));
	obj.firstName = createField(
		data[createPropName('FirstName', placeholder)],
		validators.if(
			validators.required,
			() => infoType === 'billing' && !ref.state.customerId && ref.state.requiredFields.firstName
		),
		isCustomerValid
	);
	obj.lastName = createField(
		data[createPropName('LastName', placeholder)] || (infoType === 'billing' ? data.xName : ''),
		validators.if(
			validators.required,
			() => infoType === 'billing' && !ref.state.customerId && ref.state.requiredFields.lastName
		),
		isCustomerValid
	);
	obj.company = createField(
		data[createPropName('Company', placeholder)],
		validators.if(
			validators.required,
			() => infoType === 'billing' && !ref.state.customerId && ref.state.requiredFields.company
		),
		isCustomerValid
	);
	obj.address = createField(
		data[createPropName('Street', placeholder)],
		validators.if(
			validators.required,
			() => infoType === 'billing' && !ref.state.customerId && ref.state.requiredFields.address
		)
	);
	obj.city = createField(
		data[createPropName('City', placeholder)],
		validators.if(
			validators.required,
			() => infoType === 'billing' && !ref.state.customerId && ref.state.requiredFields.city
		)
	);
	obj.state = createField(
		data[createPropName('State', placeholder)],
		validators.if(
			validators.required,
			() => infoType === 'billing' && !ref.state.customerId && ref.state.requiredFields.state
		)
	);
	obj.zip = createField(
		data[createPropName('Zip', placeholder)],
		validators.if(
			validators.required,
			() => infoType === 'billing' && !ref.state.customerId && ref.state.requiredFields.zip
		)
	);
	obj.country = createField(
		data[createPropName('Country', placeholder)],
		validators.if(
			validators.required,
			() => infoType === 'billing' && !ref.state.customerId && ref.state.requiredFields.country
		)
	);
	obj.phoneNumber = createField(
		data[createPropName('Phone', placeholder)],
		validators.if(validators.phoneNumber, validators.required),
		validators.if(
			validators.required,
			() =>
				infoType === 'billing' &&
				((!ref.state.customerId && ref.state.requiredFields.phoneNumber) ||
					(ref.state.sendSmsReceipt && ref.showSendsms))
		)
	);

	return obj;
}

/**
 * Parses custom fields
 * @param data customFields object.
 */
function parseCustomFields(data, startIndex = 1) {
	let obj = {};

	for (let i = startIndex; i <= 19; ++i) {
		let idx = i.toString().padStart(2, '0');

		obj[createPropName(idx, 'Custom')] = data['custom' + i] && data['custom' + i].value;
	}

	return obj;
}

function parseCardknoxCustomFields(ref, data, count = 19) {
	let obj = {};

	for (let i = 1; i <= count; i++) {
		let idx = i.toString().padStart(2, '0');

		obj['custom' + i] = createField(
			data[createPropName(idx, 'Custom')],
			validators.if(
				validators.required,
				() =>
					ref.state.requiredFields['custom' + i] &&
					ref.state.convenienceFees.originalCustomKey !== `Custom${padStart(i, 2, 0)}` &&
					ref.state.convenienceFees.convenienceCustomKey !== `Custom${padStart(i, 2, 0)}`
			)
		);
	}

	return obj;
}

/**
 * Parses transaction details
 * @param data transactionDetails object
 */
function parseTransactionDetails(data) {
	let obj = {};

	obj[createPropName('Description')] = data.description.value;
	obj[createPropName('Invoice')] = data.invoice.value;
	obj[createPropName('PONum')] = data.poNumber.value;
	obj[createPropName('OrderId')] = data.orderId.value;

	return obj;
}

function parseCardknoxTransactionDetails(ref) {
	let obj = {};

	obj.description = createField('', validators.if(validators.required, () => ref.state.requiredFields.description));
	obj.invoice = createField('', validators.if(validators.required, () => ref.state.requiredFields.invoice));
	obj.poNumber = createField('', validators.if(validators.required, () => ref.state.requiredFields.poNumber));
	obj.orderId = createField('', validators.if(validators.required, () => ref.state.requiredFields.orderId));

	return obj;
}

/**
 * Parses customer info
 * @param data
 * @param infoType can be either "billing" or "shipping". Default is "billing"
 */
export function parseCustomerInfo(data, infoType = 'billing', createCustomer) {
	let obj = {};
	let placeholder = 'Bill';

	if (infoType === 'shipping') {
		placeholder = 'Ship';
	}

	//obj.xName = data.name;
	obj[createPropName('FirstName', placeholder, createCustomer)] = data.firstName.value;
	obj[createPropName('LastName', placeholder, createCustomer)] = data.lastName.value;
	obj[createPropName('Company', placeholder, createCustomer)] = data.company.value;
	obj[createPropName('Street', placeholder, createCustomer)] = data.address.value;
	obj[createPropName('City', placeholder, createCustomer)] = data.city.value;
	obj[createPropName('State', placeholder, createCustomer)] = data.state.value;
	obj[createPropName('Zip', placeholder, createCustomer)] = data.zip.value;
	obj[createPropName('Country', placeholder, createCustomer)] = data.country.value;
	obj[createPropName('Phone', placeholder, createCustomer)] = data.phoneNumber.value;
	if (createCustomer) {
		obj['email'] = data.email;
	}

	return obj;
}

function createPropName(field, placeholder = null, createCustomer) {
	if (placeholder) {
		return (createCustomer ? '' : 'x') + placeholder + field;
	}
	return 'x' + field;
}

/**
 * Will remove all properties without a value
 * @param obj
 */
export function cleanObject(obj) {
	for (let prop in obj) {
		if (obj.hasOwnProperty(prop)) {
			if (obj[prop] == null || obj[prop] == '') {
				delete obj[prop];
			}
		}
	}

	return obj;
}

// Other

export function areAllPropsTrue(obj) {
	return every(obj, e => e === true);
}
