import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class SingleDateSelectionComponent extends Component {
	get startDayValue() {
		return moment(this.props.filter.values.startDay, ApplicationSettings.apiDateFormat).format(
			ApplicationSettings.displayDateFormat
		);
	}

	filterRemoved = () => {
		this.props.onFilterRemoved({
			id: 'startDay',
			emptyValue: null,
		});
	};

	render() {
		return (
			<div className="filter__tag">
				<label className="filter__tag__label">Start Date: </label>
				{this.startDayValue}
				<button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
			</div>
		);
	}
}

SingleDateSelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired,
};

export default SingleDateSelectionComponent;
