import { padStart } from 'lodash';

export const apiToDisplay = date => {
	return (
		date &&
		date.replace(/(....)-(..?)-(..?).*/, (_, year, month, day) => {
			return [padStart(month, 2, '0'), padStart(day, 2, '0'), year].join('/');
		})
	);
};
