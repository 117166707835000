import React, { Component, createRef, Fragment } from 'react';
import { cloneDeep, each, every, first, get, includes, isEmpty, map, noop, split } from 'lodash';
import { bool, func } from 'prop-types';

import { withCancelable } from 'common/components/cancelable';
import { withError } from 'common/components/error';
import { withLoader } from 'common/components/loader';
import { withBlock } from 'common/components/block';
import { Notification } from 'common/components/notifications';
import { kvaasService, logoManagementService } from 'common/services';
import { invokeIfFunction, kvaasResources } from 'common/utilities';
import { ActionsModal, modalNames } from 'common/components/transaction-actions';
import { SidebarContext } from 'common/contexts';

const requestKeys = {
	DATA: 'data',
	KVAAS: 'kvaas',
	SAVE: 'save',
	REMOVE: 'remove',
	FETCH: 'fetch',
};

class LogoManagementComponent extends Component {
	constructor() {
		super();

		this.top = createRef();
		this.notificationRef = createRef();

		this.state = {
			sectionsExpanded: {
				uploadLogo: false,
				settings: false,
			},
			errorMessages: [],
			collapsedSettingsMessages: { data: {} },
			logoUrl: null,
			imageFileBase64: null,
			expanded: false,
			isSaving: false,
			modal: {
				name: modalNames.none,
				data: null,
			},
			oldData: {
				logoManagement: null,
			},
			logoManagement: {
				coBrandPortal: false,
				includeCoBrandLogoOnReceipts: false,
				includeCoBrandLogoOnPymntReqEmails: false,
			},
		};
	}
	static contextType = SidebarContext;
	componentDidMount() {
		this.fetchData();
	}

	fetchData = async () => {
		const {
			props: { showLoader, handleError },
			fetchLogo,
			fetchKvaas,
		} = this;
		try {
			showLoader(true);
			const [logoUrl, [collapsedSettingsMessages, logoManagement]] = await Promise.all([fetchLogo(), fetchKvaas()]);

			const newState = this.mapResponseToState(logoManagement);

			newState.collapsedSettingsMessages = collapsedSettingsMessages;
			newState.expanded = !get(collapsedSettingsMessages, 'data.logoManagementSettingsMessageCollapsed', false);

			if (logoUrl) {
				newState.logoUrl = logoUrl;
			}

			this.setState(newState, () => {
				if (!isEmpty(newState.errorMessages)) {
					this.scrollToTop();
				}
			});
		} catch (e) {
			handleError(e);
		}
		showLoader(false);
	};

	fetchLogo = async () => {
		const { LogoUrl: logoUrl } = await this.props.makePendingRequest(
			logoManagementService.getLogoUrl(),
			requestKeys.DATA
		);
		return logoUrl && `${logoUrl}?${Date.now()}`;
	};

	fetchKvaas = async () => {
		const [collapsedSettingsMessages, logoManagement] = await this.props.makePendingRequest(
			kvaasService.get(kvaasResources.collapsedSettingsMessages, kvaasResources.logoManagement),
			requestKeys.KVAAS
		);
		return [collapsedSettingsMessages, logoManagement];
	};

	setStateAsync = newState => {
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	mapResponseToState = (oldLogoManagement = this.state.oldData.logoManagement) => {
		const { logoManagement, oldData } = cloneDeep(this.state);
		const newState = {
			errorMessages: [],
			oldData,
			logoManagement,
		};
		kvaasService.mapFieldsToState(
			newState,
			oldLogoManagement,
			'logoManagement',
			(value, key) => (newState['logoManagement'][key] = value)
		);

		return newState;
	};

	mapStateToRequiredFields = async setToDefaults => [
		await this.mapStateToFields('logoManagement', kvaasResources.logoManagement, setToDefaults),
	];

	mapStateToFields = async (key, { primaryKey, userSetting, defaultData }, resetToDefault) => {
		try {
			const newState = cloneDeep(this.state);
			const data = {};
			const sections = {
				logoManagement: this.state.logoManagement,
			};
			each(sections, section => {
				each(section, (field, fieldKey) => {
					if (resetToDefault) {
						data[fieldKey] = defaultData[fieldKey] ? defaultData[fieldKey] : false;
					} else {
						data[fieldKey] = field;
					}
				});
			});
			await this.setStateAsync(newState);
			return {
				newData: {
					revision: 0,
					data,
				},
				oldData: this.state.oldData[key],
				primaryKey,
				userSetting,
			};
		} catch (e) {
			this.props.handleError(e, { additionalInfo: { key, primaryKey, resetToDefault } });
		}
	};

	toggleExpandCollapseSection = section => () => {
		this.setState({
			sectionsExpanded: { ...this.state.sectionsExpanded, [section]: !this.state.sectionsExpanded[section] },
		});
	};

	toggleExpandCollapseAll = expand => () => {
		const newState = { sectionsExpanded: this.state.sectionsExpanded };

		each(newState.sectionsExpanded, (_, section) => {
			newState.sectionsExpanded[section] = !expand;
		});

		this.setState(newState);
	};

	scrollToTop = () => {
		if (this.top.current) {
			this.top.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
		}
	};

	save = async setToDefaults => {
		const { showLoader, handleBlockChange, makePendingRequest, handleError } = this.props;
		let refreshData = false;
		let refNum;
		let error;
		const addNotification = get(this.notificationRef, 'current.addNotification', noop);

		showLoader(true);
		const mappedState = await this.mapStateToRequiredFields(setToDefaults);

		try {
			const { imageFileBase64 } = this.state;
			const promises = [kvaasService.save(...mappedState)];

			if (imageFileBase64) {
				promises.push(new Promise(resolve => resolve(logoManagementService.uploadLogo(imageFileBase64))));
			}

			const [logoManagement, { LogoUrl: logoUrl } = {}] = await makePendingRequest(
				Promise.all(promises),
				requestKeys.SAVE
			);

			const newState = this.mapResponseToState(logoManagement[0]);
			newState.imageFileBase64 = null;

			if (logoUrl) {
				newState.logoUrl = `${logoUrl}?${Date.now()}`;
				invokeIfFunction(this.context.updateSidebarLogo, {
					logoUrl: newState.logoUrl,
					coBrandPortal: newState.logoManagement.coBrandPortal,
				});
			}
			refNum = logoManagement[0].refNum;

			if (setToDefaults) {
				newState.logoManagement = {
					coBrandPortal: true,
					includeCoBrandLogoOnReceipts: false,
					includeCoBrandLogoOnPymntReqEmails: false,
				};
			}
			showLoader(false);
			this.setState(newState, () => {
				if (!isEmpty(newState.errorMessages)) {
					this.scrollToTop();
				}
			});
			handleBlockChange(false);
		} catch (e) {
			error = handleError(e, { delayMessage: true });
			if (error) {
				refreshData = true;
			} else {
				return;
			}
		}
		if (refreshData) {
			try {
				const logoManagement = await makePendingRequest(
					kvaasService.get(kvaasResources.logoManagement),
					requestKeys.REFRESH
				);
				const newState = this.mapResponseToState(logoManagement[0]);
				this.setState(newState, () => {
					if (!isEmpty(newState.errorMessages)) {
						this.scrollToTop();
					}
				});
				showLoader(false);
				handleBlockChange(false);
			} catch (e) {
				error = handleError(e, { delayMessage: true });
				if (error) {
					refreshData = true;
				} else {
					return;
				}
			}
			showLoader(false);
			if (!isEmpty(this.state.errorMessages)) {
				this.scrollToTop();
			} else {
				error.show();
			}
		}
		if (!error) {
			addNotification({
				message: setToDefaults ? (
					'Logo Management settings reset to default'
				) : (
					<div>
						<p className="spc--bottom--sml">Logo Management settings updated</p>
					</div>
				),
				ref: refNum,
				success: true,
			});
		}
	};

	removeLogo = async () => {
		const { showLoader, makePendingRequest, handleError } = this.props;
		const {
			logoUrl,
			imageFileBase64,
			logoManagement: { coBrandPortal },
		} = this.state;
		try {
			showLoader(true);
			const addNotification = get(this.notificationRef, 'current.addNotification', noop);
			invokeIfFunction(this.context.updateSidebarLogo, { logoUrl: null, coBrandPortal });

			const newState = { imageFileBase64: null, modal: null };
			if (!logoUrl && imageFileBase64) {
				this.setState(newState, () => {
					addNotification({
						message: 'Logo Removed Successfully',
						success: true,
					});
				});
			} else {
				const { refNum: ref } = await makePendingRequest(logoManagementService.removeLogo(), requestKeys.REMOVE);
				newState.logoUrl = null;
				this.setState(newState, () => {
					addNotification({
						message: 'Logo Removed Successfully',
						ref,
						success: true,
					});
				});
			}
		} catch (e) {
			handleError(e);
		}
		showLoader();
	};

	handleCheckboxChange = ({ target: { name, checked, value, type } }) => {
		const newState = cloneDeep(this.state);
		const newValue = type === 'checkbox' ? checked : value;
		if (includes(name, '.')) {
			const [section, key] = split(name, '.');
			newState[section][key] = newValue;
		} else {
			newState[name] = newValue;
		}
		this.setState(newState);
		this.props.handleBlockChange(true);
	};

	openCloseActionsModal = modal => this.setState({ modal });

	handleMessage = () => {
		const { collapsedSettingsMessages, expanded, isSaving } = this.state;
		if (isSaving) {
			return;
		}
		const mappedState = this.mapStateToRequest(
			{ ...collapsedSettingsMessages.data, logoManagementSettingsMessageCollapsed: expanded },
			collapsedSettingsMessages
		);
		this.setState({ expanded: !expanded, isSaving: true }, async () => {
			try {
				const [collapsedSettingsMessages] = await this.props.makePendingRequest(kvaasService.save(mappedState));
				this.setState({ isSaving: false, collapsedSettingsMessages });
			} catch (e) {
				this.props.handleError(e);
			}
		});
	};

	checkImageWidth = file =>
		new Promise(resolve => {
			const img = new Image();
			img.onload = function() {
				URL.revokeObjectURL(this.src);
				resolve(this.width);
			};
			img.src = URL.createObjectURL(file);
		});

	handleUploadLogo = async e => {
		e.persist();
		const file = first(e.target.files);
		if (file) {
			const imageWidth = await this.checkImageWidth(file);
			if (imageWidth > 842) {
				const addNotification = get(this.notificationRef, 'current.addNotification', noop);
				addNotification({
					message: 'Please use a logo up to 842px.',
					success: false,
				});
				e.target.value = null;
				if (this.state.imageFileBase64) {
					this.setState({ imageFileBase64: null });
				}
				return;
			}
			const imageFileBase64 = await logoManagementService.convertImageToBase64(file);
			this.setState({ imageFileBase64 });
			this.props.handleBlockChange(true);
		} else {
			this.setState({ imageFileBase64: null });
		}
	};

	mapStateToRequest = (data, oldData) => {
		return {
			newData: {
				revision: 0,
				data,
			},
			oldData,
			...kvaasResources.collapsedSettingsMessages,
		};
	};

	renderHeaderButtons = () => (
		<Fragment>
			<span className="settings__header__action__text">
				Settings are saved only after clicking on <strong>Save</strong> button
			</span>
			{this.renderSetToDefaultButton()}
			{this.renderSaveButton()}
		</Fragment>
	);

	renderSaveButton = () => (
		<button
			className="btn btn--med btn--primary spc--bottom--sml"
			disabled={this.props.isLoading}
			onClick={() => this.save(false)}
		>
			Save
		</button>
	);

	renderSetToDefaultButton = () => (
		<button
			className="btn btn--med btn--ghost spc--right--sml spc--bottom--sml"
			disabled={this.props.isLoading}
			onClick={() => this.save(true)}
		>
			Reset to Default
		</button>
	);

	renderFooterButtons = () => (
		<footer className="settings__footer spc--bottom--med spc--top--med">
			<span className="settings__footer__text">
				Settings are saved only after clicking on <strong>Save</strong> button
			</span>
			<div className="type--nowrap">
				{this.renderSetToDefaultButton()}
				{this.renderSaveButton()}
			</div>
		</footer>
	);

	render() {
		const {
			expanded,
			logoUrl,
			modal,
			errorMessages,
			logoManagement: { coBrandPortal, includeCoBrandLogoOnReceipts, includeCoBrandLogoOnPymntReqEmails },
			imageFileBase64,
			sectionsExpanded,
			sectionsExpanded: { uploadLogo, settings },
		} = this.state;
		const isExpandedAll = every(sectionsExpanded, item => item);

		return (
			<div className="settings--main settings--main--alt">
				<ActionsModal modal={modal} onModalClose={this.openCloseActionsModal} />
				<Notification ref={this.notificationRef} />
				<div className="settings__header">
					<div className="settings__header__title">
						Logo Management
						<i onClick={this.handleMessage} className="icon icon--tiny icon--info spc--left--tny cursor--pointer"></i>
					</div>
					<div className="settings__header__action">{this.renderHeaderButtons()}</div>
				</div>
				<div ref={this.top}></div>
				<div className={expanded ? 'spc--bottom--med' : 'display--n'}>
					<div className="message message--default message--close" id="logoManagementCloseMessage">
						<p className="spc--bottom--sml">
							In this section, you can upload your company logo, which will be displayed on emailed receipts. You can
							also choose to display your logo on receipts printed from the Portal, Send Payment Request emails, and on
							your Portal account.
						</p>

						<p>
							Please note, uploading a logo here will not be added to your PaymentSITE. To add a logo to your
							PaymentSITE, please go to PaymentSITE Management under Portal Account Settings.
						</p>

						<div onClick={this.handleMessage} className="message--close__button" id="logoManagementCloseButton">
							<i className="icon icon--xnano icon--close-big"></i>
						</div>
					</div>
				</div>
				{map(errorMessages, (error, index) => (
					<div key={index} className="spc--top--sml spc--bottom--med type--color--warning">
						{error}
					</div>
				))}
				<div className="spc--bottom--med clearfix">
					<button className="btn btn--sml btn--primary push" onClick={this.toggleExpandCollapseAll(isExpandedAll)}>
						{`${isExpandedAll ? '- Collapse' : '+ Expand'} All`}
					</button>
				</div>
				<div className="spr__card clearfix">
					<div
						className={`spr__heading cursor--pointer ${uploadLogo ? 'is-expanded' : ''}`}
						onClick={this.toggleExpandCollapseSection('uploadLogo')}
					>
						<div className="flex flex--primary datatooltip--w--140">
							<h4 className="spr__heading__title">Upload Logo</h4>
							<i
								className="icon icon--tiny icon--info spc--left--tny cursor--pointer"
								data-tooltip="Applicable for Portal and Email Receipts"
							></i>
						</div>
						<i
							className={`icon icon--tiny icon--arrow--right--grey spr__heading__expand ${
								uploadLogo ? 'is-expanded' : ''
							}`}
						></i>
					</div>
					{uploadLogo && (
						<div className="spr__content">
							<div className="logo-management">
								<div>
									<div className="spc--bottom--sml">
										<input
											type="file"
											tabIndex="-1"
											accept="image/png, image/jpg, image/jpeg"
											name="logo"
											onChange={this.handleUploadLogo}
										/>
									</div>
									<div className="message message--default spc--bottom--sml">
										<p>Recommended image width is 842px.</p>
										<p>Allowed formats are .jpg, .jpeg.</p>
										<p>Please allow up to 5 minutes for the logo change to take effect.</p>
									</div>
								</div>
								{(imageFileBase64 || logoUrl) && (
									<div className="logo-management__aside">
										<div
											className="logo-management__preview__wrapper"
											data-tooltip="Click to preview logo"
											onClick={() =>
												this.openCloseActionsModal({
													name: modalNames.previewLogo,
													data: { logoUrl: imageFileBase64 ? `data:image/jpeg;base64,${imageFileBase64}` : logoUrl },
												})
											}
										>
											<div className="logo-management__preview">
												<img
													src={imageFileBase64 ? `data:image/jpeg;base64,${imageFileBase64}` : logoUrl}
													alt="Logo Image"
												/>
											</div>
										</div>
										<button
											type="button"
											tabIndex="-1"
											className="btn btn--sml btn--ghost spc--top--sml spc--bottom--sml"
											disabled={this.props.isLoading}
											onClick={() =>
												this.openCloseActionsModal({
													name: modalNames.confirmAction,
													data: {
														question: 'Are you sure you want to remove your logo?',
														onConfirm: this.removeLogo,
													},
												})
											}
										>
											<i className="icon icon--tiny icon--wastebasket spc--right--sml"></i>
											Remove logo
										</button>
									</div>
								)}
							</div>
						</div>
					)}
				</div>
				<div className="spr__card clearfix">
					<div
						className={`spr__heading cursor--pointer ${settings ? 'is-expanded' : ''}`}
						onClick={this.toggleExpandCollapseSection('settings')}
					>
						<h4 className="spr__heading__title">Settings</h4>
						<i
							className={`icon icon--tiny icon--arrow--right--grey spr__heading__expand ${
								settings ? 'is-expanded' : ''
							}`}
						></i>
					</div>
					{settings && (
						<div className="spr__content">
							<div className="f-row f--a--c">
								<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--med">
									<input
										type="checkbox"
										id="logoManagement.coBrandPortal"
										name="logoManagement.coBrandPortal"
										checked={coBrandPortal}
										value={coBrandPortal}
										onChange={this.handleCheckboxChange}
										className="input input--check"
									/>
									<label htmlFor="logoManagement.coBrandPortal" className="label">
										Include my logo on my merchant portal account
									</label>
								</div>
							</div>
							<div className="f-row f--a--c">
								<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--med">
									<input
										type="checkbox"
										id="logoManagement.includeCoBrandLogoOnReceipts"
										name="logoManagement.includeCoBrandLogoOnReceipts"
										checked={includeCoBrandLogoOnReceipts}
										value={includeCoBrandLogoOnReceipts}
										onChange={this.handleCheckboxChange}
										className="input input--check"
									/>
									<label htmlFor="logoManagement.includeCoBrandLogoOnReceipts" className="label">
										Include my logo on portal print receipts
									</label>
								</div>
							</div>
							<div className="f-row f--a--c">
								<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--sml">
									<input
										type="checkbox"
										id="logoManagement.includeCoBrandLogoOnPymntReqEmails"
										name="logoManagement.includeCoBrandLogoOnPymntReqEmails"
										checked={includeCoBrandLogoOnPymntReqEmails}
										value={includeCoBrandLogoOnPymntReqEmails}
										onChange={this.handleCheckboxChange}
										className="input input--check"
									/>
									<label htmlFor="logoManagement.includeCoBrandLogoOnPymntReqEmails" className="label">
										Include my logo on Send Payment Request emails
									</label>
								</div>
							</div>
						</div>
					)}
				</div>
				{this.renderFooterButtons()}
			</div>
		);
	}
}

LogoManagementComponent.propTypes = {
	showLoader: func.isRequired,
	handleError: func.isRequired,
	makePendingRequest: func.isRequired,
	isLoading: bool.isRequired,
	handleBlockChange: func.isRequired,
};

export default withError(withLoader(withCancelable(withBlock(LogoManagementComponent))));
