export const titles = {
	giftReport: 'Gift Card Activity',
	giftCardSummary: 'Gift Card Summary',
	giftCardLiability: 'Gift Card Liability Report',
};

export const mobileTitles = {
	giftReport: 'Gift Card Activity',
	giftCardSummary: 'Gift Card Summary',
	giftCardLiability: 'Gift Card Liability',
};

export const linkTitles = {
	giftReport: 'Activity',
	giftCardSummary: 'Summary',
	giftCardLiability: 'Liability',
};
