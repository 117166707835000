import { OutsideClick } from 'common/utilities';
import { func } from 'prop-types';
import React from 'react';

const Requirements = ({ handleClose }) => {
	return (
		<div className="popup__overlay">
			<OutsideClick action={handleClose}>
				<div className="popup__content popup--requirements">
					<button tabIndex="-1" className="popup__close" onClick={handleClose} />
					<div className="popup__body">
						<h2 className="type--lrg spc--bottom--med">Instructions</h2>
						<div className="separator--grey1 spc--bottom--med"></div>
						<div className="popup__section">
							<h3 className="type--base spc--bottom--sml--alt">General Info</h3>
							<ul className="list--disc list--disc--primary spc--bottom--med">
								<li>Supported file format: CSV.</li>
								<li>For each customer either Billing First Name, Billing Last Name or Company Name is required.</li>
							</ul>
							<div className="separator--grey1 spc--bottom--med"></div>
						</div>
						<div className="popup__section">
							<h3 className="type--base spc--bottom--sml--alt">Payment Info</h3>
							<ul className="list--disc list--disc--primary spc--bottom--med">
								<li>
									Adding a customer without a payment method will generate a customer record without a stored payment
									method.
								</li>
								<li>When uploading Cardknox tokens, expiration is not required.</li>
								<li>When uploading card numbers expiration is required. The supported format is MMYY.</li>
								<li>
									When uploading Check/ACH an Account Name, 9 digit Routing Number and 4 - 17 digit Account number is
									required.
								</li>
								<li>Payment Type is required and supports either "CC" or "Check".</li>
							</ul>
							<div className="separator--grey1 spc--bottom--med"></div>
						</div>
						<div className="popup__section">
							<h3 className="type--base spc--bottom--sml--alt">Schedule Info</h3>
							<div className="spc--bottom--tny">The following fields are required to generate a schedule:</div>
							<ul className="list--disc list--disc--primary">
								<li>
									A payment method. When uploading multiple payment methods the first payment method will be the
									default.
								</li>
								<li>Every supports an interval of “day,” “week,” “month,” or “year”.</li>
								<li>
									Frequency supports a number indicating the frequency at which the schedule will run. For example: to
									run a schedule every month, input "month" in Every and "1" in Frequency.
								</li>
								<li>Start Date formatted as MM/DD/YYYY.</li>
								<li>
									Until supports a number of payments, an end date formatted as MM/DD/YYYY or blank to run indefinitely.
								</li>
							</ul>
						</div>
					</div>
				</div>
			</OutsideClick>
		</div>
	);
};

Requirements.propTypes = {
	handleClose: func.isRequired,
};

export default Requirements;
