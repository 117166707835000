import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { cloneDeep, find, each, filter, unionBy, includes, concat, isEmpty, sortBy, findIndex } from 'lodash';
import { Data } from 'react-data-grid-addons';

import { kvaasService, transactionService } from '../../Common/services';
import { LoadMoreOptions, getFilter, kvaasResources } from '../../Common/utilities';
import { MainFilterComponent } from './../../Common/components/filter';
import { fraudFilter as Filter, compileFilter } from './../../Common/components/transactions/filter/fraudFilter';
import { FraudColumns as Columns } from './../../Common/components/transactions/column-filter/fraudColumns';
import { GridComponent } from './../../Common/components/grid';
import { withError } from './../../Common/components/error';
import { exportService } from '../../Common/components/export/exportService';
import { ZebraRenderer } from '../../Common/components/row';
import { mapDefaultOrder } from 'common/utilities/map-default-order';
import { withLoadMore } from 'common/components/loadmore';
import { addCustomDataType } from 'common/components/customers/popup/utils';

const { apiDateTimeFormat, apiResponseDateTimeFormat, displayDateTimeFormat } = ApplicationSettings;

const requestKeys = {
	FETCH: 'fetch',
	LOAD_MORE: 'loadMore',
	LOAD_ALL: 'loadAll',
};
const loadMoreOptionsWithAll = concat(LoadMoreOptions, [0]);
class Fraudwatch extends Component {
	constructor(props) {
		super(props);

		const filters = unionBy(props.location.filters, Filter, 'key') || Filter;
		const activeFilters = props.activeFilters || cloneDeep(filters);

		this.state = {
			filters: filters,
			activeFilters: activeFilters,
			inlineFilters: {},
			data: null,
			filteredRows: [],
			originalData: null,
			fetchingData: true,
			fetchingAdditionalData: false,
			columns: cloneDeep(Columns),
			defaultColumns: cloneDeep(Columns),
			loadMoreLimit: 50,
			initialRecordsLimit: 20,
			lastApiRefNum: null,
		};

		this.gridRef = React.createRef();

		this.components = {
			filter: MainFilterComponent,
			rowRenderer: ZebraRenderer,
		};

		this.classes = {
			content: 'l--content grid__holder--override__header-one-row',
			wrapper: '',
			header: 'header',
			headerMenu: 'header__menu',
			headerGroup: 'flex--primary',
			title: 'header__title',
		};
	}

	getSnapshotBeforeUpdate = prevProps => prevProps.location.key !== this.props.location.key;

	componentDidUpdate = async (_, __, snapshot) => {
		if (snapshot) {
			if (this.gridRef.current) {
				this.gridRef.current.clearFilters();
			}
			this.refetchData();
		}
	};

	fetchData = async (filters, filterDateFormat, maxRecords = 1000) => {
		this.setState({
			fetchingData: true,
			data: null,
			filteredRows: [],
			lastApiRefNum: null,
		});

		const fields = transactionService.getFraudFields();
		try {
			const filter = await this.props.makePendingRequest(compileFilter(filters, filterDateFormat), requestKeys.FETCH);
			const [data, [customDefaultColumns, fraudWatchColumnsOrder]] = await this.props.makePendingRequest(
				Promise.all([
					transactionService.filterTransactionsRequest(filter, maxRecords > 1000 ? 1000 : maxRecords, fields),
					kvaasService.get(kvaasResources.fraudwatchReportDefaultColumns, kvaasResources.fraudwatchReportDefaulOrder),
				]),
				requestKeys.FETCH
			);
			const lastApiRefNum = data.xRefNum;

			const order = mapDefaultOrder(kvaasResources.fraudwatchReportDefaulOrder.defaultData, fraudWatchColumnsOrder);
			const columns = this.addCustomData(this.state.columns, {
				customDefaultColumns,
				order,
			});
			const formattedColumns = this.formatColumns(columns, cloneDeep(filter));

			const defaultColumns = this.addCustomData(this.state.defaultColumns, {
				customDefaultColumns,
				order,
			});

			this.mapData(data);
			const filteredRows =
				data && data.xReportData
					? Data.Selectors.getRows({
							rows: data.xReportData,
							filters: this.state.inlineFilters,
					  })
					: [];
			if (this.gridRef.current) {
				this.gridRef.current.scrollTo({ top: 0, left: 0 });
			}

			this.setState(
				{
					data,
					filteredRows,
					originalData: cloneDeep(data),
					fetchingData: false,
					columns: formattedColumns,
					defaultColumns,
					lastApiRefNum: lastApiRefNum,
				},
				() => {
					if (this.gridRef.current) {
						this.gridRef.current.handleInitialSort();
						this.gridRef.current.calculateColumnWidths();
					}
				}
			);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.setState({
					fetchingData: false,
				});
			}
		}
	};

	addCustomData = (data, { customDefaultColumns, order }) => {
		let newData = cloneDeep(data);
		let anyChanged = false;
		newData = addCustomDataType(newData, customDefaultColumns, (item, _, index) => {
			if (index === undefined) {
				item.visible = true;
				anyChanged = true;
				return true;
			}
		});
		newData = addCustomDataType(
			newData,
			order,
			(item, value, index) => {
				if (index === undefined) {
					item.order = value;
					anyChanged = true;
					return true;
				}
			},
			updatedData => sortBy(updatedData, 'order')
		);
		return anyChanged ? newData : data;
	};

	fetchAllData = async () => {
		try {
			const fields = transactionService.getFraudFields();
			const filters = await this.props.makePendingRequest(
				compileFilter(this.state.activeFilters, apiDateTimeFormat),
				requestKeys.LOAD_ALL
			);
			const allData = await this.props.makePendingRequest(
				transactionService.filterTransactionsAll(filters, fields),
				requestKeys.LOAD_ALL
			);
			this.mapData(allData);
			return allData.xReportData;
		} catch (e) {
			this.props.handleError(e);
		}
	};

	resolveColumnName = column => {
		let key = column;
		switch (column) {
			case 'CardData':
				key = 'xMaskedCardNumber';
				break;
			case 'AmountData':
				key = 'xAmount';
				break;
			case 'RefNumData':
				key = 'xRefNum';
				break;
			case 'xEnteredDate':
				key = 'xEnteredDateMoment';
				break;
			default:
				break;
		}
		return key;
	};

	updateRow = row => {
		const { data, filteredRows } = this.state;
		this.mapRow(row);
		const newData = cloneDeep(data);
		const newFilteredRows = cloneDeep(filteredRows);
		const dirtiedIndex = findIndex(newData.xReportData, item => item.xRefNum === row.xRefNum);
		if (dirtiedIndex > -1) {
			const oldRow = newData.xReportData[dirtiedIndex];
			row.gridRowNumber = oldRow.gridRowNumber;
			row.index = oldRow.index;
			newData.xReportData[dirtiedIndex] = row;
		}
		const dirtiedFilteredIndex = findIndex(newFilteredRows, item => item.xRefNum === row.xRefNum);
		if (dirtiedFilteredIndex > -1) {
			newFilteredRows[dirtiedFilteredIndex] = row;
		}
		this.setState({
			data: newData,
			filteredRows: newFilteredRows,
		});
	};

	mapData = data => {
		let i = 0;
		if (data && data.xReportData && data.xReportData.length > 0) {
			each(data.xReportData, item => {
				this.mapRow(item);
				// include gridRowNumber as a simple counter for zebra
				item.gridRowNumber = i;
				item.index = i + 1; // for initial sort
				i++;
			});
		}
	};

	mapRow = item => {
		if (moment.isMoment(item.xEnteredDate)) {
			item.xEnteredDateMoment = moment(item.xEnteredDate, apiResponseDateTimeFormat);
			item.xEnteredDate = item.xEnteredDateMoment.format(displayDateTimeFormat);
		}

		if (item.xMaskedCardNumber && item.xMaskedCardNumber.includes('xxx')) {
			item.xMaskedCardNumber = `**** ${item.xMaskedCardNumber.slice(-4)}`;
		}
		item.updateRow = this.updateRow;
	};

	combineData = (baseData, additionalData, refNums) => {
		baseData.xReportData = concat(baseData.xReportData, additionalData.xReportData);
		baseData.xRecordsReturned += additionalData.xRecordsReturned - refNums.length;
		baseData.xReportingMaxTransactions += additionalData.xReportingMaxTransactions - refNums.length;
	};

	loadMore = async () => {
		const { loadMoreLimit, data, originalData, activeFilters } = this.state;
		const hasData = originalData && originalData.xReportData && originalData.xReportData.length > 0;
		const fields = transactionService.getFraudFields();

		if (hasData) {
			const filters = cloneDeep(activeFilters);
			const dateFilter = getFilter(filters, 'date');
			const { start, end, refNums } = transactionService.getNewStartEndDates(
				dateFilter.values.startDate,
				dateFilter.values.endDate,
				originalData.xReportData
			);
			dateFilter.values.startDate = start;
			dateFilter.values.endDate = end;
			let compiledFilter;
			try {
				compiledFilter = await this.props.makePendingRequest(
					compileFilter(filters, apiDateTimeFormat),
					requestKeys.LOAD_MORE
				);
			} catch (e) {
				this.props.handleError(e);
				return;
			}
			if (compiledFilter.xBeginDate && compiledFilter.xEndDate) {
				this.setState({
					fetchingAdditionalData: true,
					lastApiRefNum: null,
				});

				try {
					const pendingData = await this.props.makePendingRequest(
						!loadMoreLimit
							? transactionService.filterTransactionsAll(compiledFilter, fields)
							: transactionService.filterTransactionsRequest(
									compiledFilter,
									Math.min(loadMoreLimit + refNums.length, 1000),
									fields
							  ),
						requestKeys.LOAD_MORE
					);
					const resp = await this.props.makePendingRequest(
						this.props.loadMore(
							pendingData,
							filters,
							dateFilter,
							loadMoreLimit,
							refNums,
							compiledFilter,
							compileFilter
						),
						requestKeys.LOAD_MORE
					);
					this.combineData(pendingData, resp.additionalData, resp.additionalRefNums);
					pendingData.xReportData = filter(pendingData.xReportData, ({ xRefNum }) => !includes(refNums, xRefNum));
					if (!isEmpty(pendingData.xReportData)) {
						const updatedData = cloneDeep(data);
						this.combineData(updatedData, pendingData, refNums);
						if (!loadMoreLimit) {
							updatedData.xReportingMaxTransactions += 1;
						}
						const formattedColumns = this.formatColumns(this.state.columns);
						this.mapData(updatedData);
						const filteredRows =
							updatedData && updatedData.xReportData
								? Data.Selectors.getRows({
										rows: updatedData.xReportData,
										filters: this.state.inlineFilters,
								  })
								: [];
						this.setState(
							{
								originalData: cloneDeep(updatedData),
								data: updatedData,
								filteredRows,
								fetchingAdditionalData: false,
								columns: formattedColumns,
								lastApiRefNum: pendingData.xRefNum,
							},
							() => {
								if (this.gridRef.current) {
									this.gridRef.current.handleInitialSort();
									this.gridRef.current.calculateColumnWidths();
								}
							}
						);
					} else {
						this.setState({
							fetchingAdditionalData: false,
							lastApiRefNum: pendingData.xRefNum,
						});
					}
				} catch (e) {
					if (this.props.handleError(e)) {
						this.setState({
							fetchingAdditionalData: false,
						});
					}
				}
			}
		}
	};

	formatColumns = (columns, appliedFilter = null) => {
		if (appliedFilter) {
			delete appliedFilter['xBeginDate'];
			delete appliedFilter['xCommand'];
			delete appliedFilter['xEndDate'];

			for (let prop in appliedFilter) {
				if (appliedFilter.hasOwnProperty(prop)) {
					// find hidden column by key
					let column = find(columns, i => {
						if (prop === 'xAmount') {
							prop = 'AmountData';
						}

						return i.key.toLowerCase() === prop.toLowerCase() && !i.visible;
					});

					if (column) {
						column.visible = true;
					}
				}
			}
		}
		return columns;
	};

	onLoadMoreLimitChange = value => {
		this.setState(
			{
				loadMoreLimit: value,
			},
			() => {
				this.loadMore();
			}
		);
	};

	hasMoreData = data => {
		return (
			data && data.xReportData && data.xReportData.length > 0 && data.xRecordsReturned >= data.xReportingMaxTransactions
		);
	};

	handleChange = changes => {
		const newState = {};
		each(changes, ({ key, value }) => {
			if (key === 'data' || key === 'inlineFilters') {
				let filters, data;
				if (key === 'data') {
					filters = this.state.inlineFilters;
					data = value;
				} else {
					filters = value;
					data = this.state.data;
				}
				newState.filteredRows =
					data && data.xReportData
						? Data.Selectors.getRows({
								rows: data.xReportData,
								filters,
						  })
						: [];
			}
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	refetchData = () => {
		this.setState({ loadMoreLimit: this.state.initialRecordsLimit }, () =>
			this.fetchData(this.state.activeFilters, apiDateTimeFormat, this.state.initialRecordsLimit)
		);
	};

	render = () => (
		<GridComponent
			emptyMessage="You should change your filter options"
			fetchingData={this.state.fetchingData}
			fetchingAdditionalData={this.state.fetchingAdditionalData}
			filteredRows={this.state.filteredRows}
			columns={this.state.columns}
			defaultColumns={this.state.defaultColumns}
			filterColumns={true}
			columnFilterType="/user-settings/fraudwatch"
			kvaasResourceType="fraudwatch"
			data={this.state.data}
			resolveColumnName={this.resolveColumnName}
			inlineFilters={this.state.inlineFilters}
			components={this.components}
			onChange={this.handleChange}
			title="FraudWatch report"
			type="fraud"
			printTitle="FraudWatch report"
			enablePrint={true}
			enableExport={true}
			hasPaging={true}
			hasMoreData={this.hasMoreData}
			loadMoreOptions={loadMoreOptionsWithAll}
			onLoadMoreLimitChange={this.onLoadMoreLimitChange}
			enableFilters={true}
			filters={this.state.filters}
			activeFilters={this.state.activeFilters}
			fetchData={this.refetchData}
			fetchAllData={this.fetchAllData}
			showResults={true}
			lastApiRefNum={this.state.lastApiRefNum}
			gridClassName="grid"
			ref={this.gridRef}
			useInlineFilters={true}
			allTitle="All transactions"
			fetchExportData={{ current: exportService.mapFraudData, all: exportService.getFraudData }}
			loadMoreLimit={this.state.loadMoreLimit}
			classes={this.classes}
		/>
	);
}

Fraudwatch.propTypes = {
	makePendingRequest: PropTypes.func,
	activeFilters: PropTypes.any,
	location: PropTypes.object,
	handleError: PropTypes.func,
	loadMore: PropTypes.func,
};

export default withError(withLoadMore(Fraudwatch));
