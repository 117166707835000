import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { each, endsWith, every, head, transform } from 'lodash';
import { DraggableColumn } from 'common/components/settings';
import handleInvalidRevision from '../utils/invalidRevision';
import { withLoader } from 'common/components/loader';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { withBlock } from 'common/components/block';
import BaseSettings from 'components/settings/pages/BaseSettings';
import { Notification } from 'common/components/notifications';
import { kvaasResources } from 'common/utilities';
import { kvaasService } from 'common/services';

class UserSettingsCustomers extends BaseSettings {
	constructor(props) {
		super(props);
		this.state = {
			reportType: 'customerReport',
			customerReport: {
				oldData: {
					isDefault: null,
					order: null,
					hide: null,
				},
				fields: [...this.customerInitialFields],
			},
			userSettings: {
				expandNewCustomerPopup: false,
			},

			sectionsExpanded: {
				columns: false,
			},
		};
	}
	componentWillUnmount = () => {
		const { requestedFrame } = this.props;
		if (requestedFrame) {
			cancelAnimationFrame(requestedFrame);
		}
	};
	mapStateToReport = setToDefaults => {
		if (setToDefaults) return this.setToDefault();
		return [
			this.mapStateToFields('customerReport', 'order', kvaasResources.customerReportOrder, setToDefaults),
			this.mapStateToFields('customerReport', 'hide', kvaasResources.customerReportHiddenFields, setToDefaults),
			this.mapStateToFields('customerReport', 'isDefault', kvaasResources.customerReportDefaultColumns, setToDefaults),
			{
				newData: {
					revision: 0,
					data: this.state.userSettings,
				},
				oldData: this.state.oldData.userSettings,
				...kvaasResources.userSettings,
			},
		];
	};
	save = async setToDefaults => {
		this.props.showLoader(true);
		let error;
		let refNum;
		try {
			const mappedState = this.mapStateToReport(setToDefaults);
			const response = await this.props.makePendingRequest(kvaasService.save(...mappedState), 'save');
			refNum = head(response).refNum;

			await this.fetchData();
		} catch (e) {
			this.props.showLoader(false);

			error = this.props.handleError(e, { delayMessage: true });
			await this.fetchData();
		}

		this.notifySuccess(error, setToDefaults, 'Customers', refNum);
		this.props.handleBlockChange(false);
		this.props.showLoader(false);
	};

	setToDefault = () => {
		this.setState({
			userSettings: kvaasResources.userSettings,
		});
		return [
			this.mapStateToFields('customerReport', 'order', kvaasResources.customerReportOrder, true),
			this.mapStateToFields('customerReport', 'hide', kvaasResources.customerReportHiddenFields, true),
			this.mapStateToFields('customerReport', 'isDefault', kvaasResources.customerReportDefaultColumns, true),
			{
				newData: {
					revision: 0,
					data: {
						expandNewCustomerPopup: false,
					},
				},
				oldData: this.state.oldData.userSettings,
				...kvaasResources.userSettings,
			},
		];
	};

	fetchData = async () => {
		this.props.showLoader(true);
		try {
			const {
				customerReportDefaultColumns,
				customerReportOrder,
				customerReportHiddenFields,
				customLabel,
				userSettings,
			} = await this.getData();

			const newState = this.mapResponseToState(
				customerReportDefaultColumns,
				customerReportOrder,
				customerReportHiddenFields,
				customLabel,
				null,
				userSettings,
				null
			);

			this.setState({
				customerReport: newState,
				userSettings: userSettings.data,
				oldData: {
					userSettings,
				},
			});
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.handleKvaasLoadError();
			}
		}
		this.props.showLoader(false);
	};
	getData = async () => {
		const getKvaas = this.requiredKvaasResources();
		const [
			customerReportDefaultColumns,
			customerReportOrder,
			customerReportHiddenFields,
			transactionDisplayLabels,
			userSettings,
		] = await this.props.makePendingRequest(getKvaas, 'kvaas');
		return {
			customerReportDefaultColumns,
			customerReportOrder,
			customerReportHiddenFields,
			transactionDisplayLabels,
			userSettings,
		};
	};

	requiredKvaasResources() {
		return kvaasService.get(
			...[
				{ ...kvaasResources.customerReportDefaultColumns, throwError: true },
				{ ...kvaasResources.customerReportOrder, throwError: true },
				{ ...kvaasResources.customerReportHiddenFields, throwError: true },
				{ ...kvaasResources.transactionDisplayLabels, throwError: true },
				{ ...kvaasResources.userSettings, throwError: true },
			]
		);
	}

	componentDidMount = async () => {
		this.props.showLoader(true);
		await this.fetchData();
		this.props.showLoader(false);
	};

	toggleExpandCollapseSection = section => () => {
		this.setState({
			sectionsExpanded: { ...this.state.sectionsExpanded, [section]: !this.state.sectionsExpanded[section] },
		});
	};

	toggleExpandCollapseAll = expand => () => {
		const sections = transform(this.state, (acc, value, key) => {
			if (endsWith(key, 'Expanded')) {
				acc[key] = value;
			}
		});
		const newState = {};

		each(sections, (_, section) => {
			newState[section] = !expand;
		});
		this.setState(newState);
	};

	render = () => {
		const { fields } = this.state.customerReport;
		const {
			sectionsExpanded: { columns },
		} = this.state;
		const userSettings = this.state.userSettings;
		const selectAllValue = every(fields, i => i.hide || i.isDefault);
		const selectAllLabel = selectAllValue ? 'Unselect all' : 'Select all';

		return (
			<Fragment>
				<div className="settings--main settings--main--alt">
					<div className="settings__header">
						<div className="settings__header__title">Customers Settings</div>
						<div className="settings__header__action">{this.renderSaveButton(false)}</div>
					</div>
					<div ref={top}></div>
					<div className="spr__card clearfix">
						<div
							className={`spr__heading cursor--pointer ${columns ? 'is-expanded' : ''}`}
							onClick={this.toggleExpandCollapseSection('columns')}
						>
							<h4 className="spr__heading__title">Manage Columns</h4>
							<i
								className={`icon icon--tiny icon--arrow--right--grey spr__heading__expand ${
									columns ? 'is-expanded' : ''
								}`}
							></i>
						</div>
						{columns && (
							<div className="spr__content">
								<div className="message message--default spc--bottom--sml--alt">
									The column name reflects the name set on the New Transaction Settings page.
								</div>
								<div className="settings--report__header spc--bottom--sml--alt">
									<div className="settings__transaction__heading">Show in Report</div>

									<div className="type--sml settings__transaction__manual">
										<i className="icon icon--xsml icon--drag icon--middle spc--right--tny"></i>
										Drag to edit order
									</div>
								</div>

								<hr className="separator separator--grey1 spc--bottom--sml--alt" />

								<div className="w--max--570">
									<div className="display--ib spc--bottom--sml">
										<input
											type="checkbox"
											name="selectAll"
											id="selectAll"
											checked={selectAllValue}
											className="input--check"
											onChange={this.handleChange}
										/>
										<label htmlFor="selectAll" className="type--color--text--medium type--wgt--medium type--base">
											{selectAllLabel}
										</label>
									</div>
									<hr className="separator separator--grey1" />
									<DraggableColumn
										items={fields}
										disable={false}
										onChange={this.handleChange}
										removeCustomField={this.removeCustomField}
										onHover={this.moveColumn}
									/>
								</div>
								{this.canAddCustomFields ? (
									<div className="spc--bottom--med spc--top--med">
										<a onClick={this.addCustomField} className="anchor anchor--primary anchor--underline">
											Add Custom Field
										</a>
									</div>
								) : null}
							</div>
						)}
					</div>
					<div className="spr__card">
						<div className="spr__content">
							<div className="spc--bottom--sml">
								<input
									type="checkbox"
									id="expandNewCustomerPopup"
									name="expandNewCustomerPopup"
									className="input input--check"
									checked={userSettings.expandNewCustomerPopup}
									onChange={this.handleUserSettingsChange}
								/>
								<label htmlFor="expandNewCustomerPopup" className="type--color--text--medium type--wgt--medium">
									Expand New Customer Popup By Default
								</label>
							</div>
						</div>
					</div>
				</div>
				<Notification ref={this.notification} />
			</Fragment>
		);
	};
}

UserSettingsCustomers.propTypes = {
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	showLoader: PropTypes.func,
	history: PropTypes.object,
	match: PropTypes.object,
	location: PropTypes.object,
	isLoading: PropTypes.bool,
	handleBlockChange: PropTypes.func,
};

export default withError(withLoader(withCancelable(withBlock(UserSettingsCustomers))), handleInvalidRevision);
