import React, { Component } from 'react';
import { get, endsWith } from 'lodash';
import { func, string } from 'prop-types';

import { kvaasService, principalService } from 'common/services';
import { withError } from '../error';
import { withCancelable } from '../cancelable';
import ModalComponent from '../modal/modal';
import { optInForNewPortal, toggleShareFeedback } from 'common/utilities/commonEnterLeaveBetaPortalMethods';
import { kvaasResources } from 'common/utilities';

const newPortalEndpoint = ApplicationSettings.newPortalEndpoint;
const isNewPortal = newPortalEndpoint && endsWith(window.location.host, newPortalEndpoint);

class EnterLeaveBetaPortalMobile extends Component {
	constructor() {
		super();

		this.state = {
			isShareFeedbackOpen: false,
			principal: principalService.get(),
		};

		this.toggleShareFeedback = toggleShareFeedback.bind(this);
		this.optInForNewPortal = optInForNewPortal.bind(this);
	}

	async componentDidMount() {
		try {
			const [userSettings] = await this.props.makePendingRequest(kvaasService.get(kvaasResources.userSettings));
			if (userSettings && userSettings.data) {
				this.setState({ userSettings });
			}
		} catch (error) {
			this.props.handleError(error);
		}
	}

	render() {
		const { isShareFeedbackOpen, principal } = this.state;
		const { className } = this.props;
		const mid = get(principal, 'idInfo.xMerchantID');
		const email = get(principal, 'email');

		return (
			newPortalEndpoint && (
				<div className={`spc--left--tny hide--to--sml hide--from--xlrg push ${className}`}>
					<button
						type="button"
						className="btn btn--med btn--ghost btn--ghost--primary"
						onClick={() => this.toggleShareFeedback(isNewPortal)}
					>
						<i className={`icon icon--xsml icon--middle icon--${isNewPortal ? 'leave' : 'enter'}`}></i>
						{isNewPortal ? 'Leave Beta Portal' : 'Enter Beta Portal'}
					</button>
					<ModalComponent isOpen={isShareFeedbackOpen} onClose={this.optInForNewPortal}>
						{mid && email && (
							<div className="popup__body popup__body--tall popup__body--leaving-beta">
								<iframe
									height="990"
									title="Embedded Wufoo Form"
									frameBorder="0"
									scrolling="no"
									style={{ width: '100%', border: 'none' }}
									src={`https://cardknox.wufoo.com/embed/m12th9db08hmkis/?Field118=${encodeURIComponent(
										mid
									)}&Field119=${encodeURIComponent(email)}`}
								/>
							</div>
						)}
					</ModalComponent>
				</div>
			)
		);
	}
}

EnterLeaveBetaPortalMobile.defaultProps = {
	className: '',
};

EnterLeaveBetaPortalMobile.propTypes = {
	handleError: func.isRequired,
	makePendingRequest: func.isRequired,
	className: string,
};

export default withError(withCancelable(EnterLeaveBetaPortalMobile));
