import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { map, every } from 'lodash';

import AddEditCustomerSchedulesAddEdit from './add-edit';

class AddEditCustomerSchedules extends Component {
	constructor(props) {
		super(props);
		this.scheduleToFocusRef = {};
		this.isFocusScheduleRefSet = false;
	}

	toggleExpandCollapseAll = allExpanded => {
		this.props.onChange('expandAllSchedules', '_meta.scheduleExpanded', !allExpanded);
	};

	setScheduleRef = (element, scheduleId) => {
		const { schedule } = this.props;
		if (!this.isFocusScheduleRefSet && schedule) {
			if (schedule.scheduleId === scheduleId) {
				this.scheduleToFocusRef = element;
				this.isFocusScheduleRefSet = true;
			}
		}
	};

	focusSchedule = () => {
		if (this.scheduleToFocusRef && this.isFocusScheduleRefSet) {
			if (window.innerWidth < 1200) {
				const scrollableArea = document.querySelector('.grid-sidebar__body');
				const scheduleTop = this.scheduleToFocusRef.offsetTop;
				const offsetHeader = 82;
				scrollableArea.scrollTop = Math.max(scheduleTop, offsetHeader) - offsetHeader;
			} else {
				this.scheduleToFocusRef.scrollIntoView();
			}
		}
	};

	render() {
		const {
			schedules,
			refNum,
			errorMessages,
			applyTemplate,
			advancedView,
			template,
			isExpanded,
			permissions,
			hideSkipSabbath,
			saveSchedule,
			handleOpenPreview,
			handleOpenViewPayments,
			onChange,
			runPopoverAction,
			customerId,
			handleOpenCloseModal,
			renderAddRecurringSchedule,
			scheduleToFocus,
			general,
			onGeneralChange,
			isLoading,
			convenienceFees,
			onBeforeCalculateConvenience,
			includeExcludeConvenience,
			customDisplayLabels,
			isViewOnly,
			paymentMethods,
		} = this.props;

		const allExpanded = every(schedules, ({ _meta: { scheduleExpanded } }) => scheduleExpanded);

		return (
			<Fragment>
				<div>
					{map(errorMessages, (errorMessage, index) => (
						<div key={index} className="validation spc--bottom--lrg">
							<p className="type--error">{errorMessage}</p>
						</div>
					))}
				</div>
				{(customerId || refNum) && (
					<div className="clearfix spc--bottom--sml">
						{customerId && (
							<span className="type--wgt--medium type--color--text spc--right--med pull">
								Customer ID: {customerId}
							</span>
						)}
						{refNum && <span className="type--wgt--medium type--color--text push">(#{refNum})</span>}
					</div>
				)}
				<div className="spc--bottom--sml datatooltip--v--bottom datatooltip--w--200">
					{schedules.length > 1 && (
						<div className="spc--right--sml display--ib">
							<a
								className="anchor anchor--primary type--sml type--underline"
								onClick={() => this.toggleExpandCollapseAll(allExpanded)}
							>
								<span className="display--ib spc--right--nano align--v--middle">{`${
									allExpanded ? 'Collapse' : 'Expand'
								} all`}</span>
								<i className="icon icon--tiny icon--expand-vertical--primary icon--middle"></i>
							</a>
						</div>
					)}
					{renderAddRecurringSchedule()}
				</div>
				{schedules && schedules.length > 0 ? (
					schedules.map((item, index) => {
						return (
							<div key={item._meta.id} ref={elem => this.setScheduleRef(elem, item.scheduleId)}>
								<AddEditCustomerSchedulesAddEdit
									index={index}
									applyTemplate={applyTemplate}
									advancedView={advancedView}
									gridHolder={this.props.gridHolder}
									handleOpenPreview={handleOpenPreview}
									handleOpenViewPayments={handleOpenViewPayments}
									key={item._meta.id}
									id={item._meta.id}
									schedule={item}
									general={general}
									onChange={onChange}
									onGeneralChange={onGeneralChange}
									saveSchedule={saveSchedule}
									template={template}
									isExpanded={isExpanded}
									permissions={permissions}
									hideSkipSabbath={hideSkipSabbath}
									runPopoverAction={runPopoverAction}
									handleOpenCloseModal={handleOpenCloseModal}
									scheduleToFocus={scheduleToFocus}
									isLoading={isLoading}
									convenienceFees={convenienceFees}
									onBeforeCalculateConvenience={onBeforeCalculateConvenience}
									includeExcludeConvenience={includeExcludeConvenience}
									customDisplayLabels={customDisplayLabels}
									isViewOnly={isViewOnly}
									paymentMethods={paymentMethods}
								/>
							</div>
						);
					})
				) : (
					<div className="recurringSchedulePopupNoItems spc--top--sml spc--bottom--sml">
						No recurring schedules linked to this customer
					</div>
				)}
			</Fragment>
		);
	}
}

AddEditCustomerSchedules.propTypes = {
	schedules: PropTypes.array.isRequired,
	general: PropTypes.object,
	refNum: PropTypes.string,
	runPopoverAction: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onGeneralChange: PropTypes.func,
	handleOpenPreview: PropTypes.func.isRequired,
	handleOpenViewPayments: PropTypes.func.isRequired,
	paymentMethods: PropTypes.array.isRequired,
	handleOpenCloseModal: PropTypes.func.isRequired,
	errorMessages: PropTypes.arrayOf(PropTypes.string),
	gridHolder: PropTypes.object,
	advancedView: PropTypes.bool.isRequired,
	permissions: PropTypes.object,
	applyTemplate: PropTypes.func.isRequired,
	template: PropTypes.object,
	saveSchedule: PropTypes.func,
	isExpanded: PropTypes.bool,
	isViewOnly: PropTypes.bool,
	hideSkipSabbath: PropTypes.bool,
	customerId: PropTypes.string,
	renderAddRecurringSchedule: PropTypes.func.isRequired,
	schedule: PropTypes.object,
	scheduleToFocus: PropTypes.string,
	isLoading: PropTypes.bool.isRequired,
	convenienceFees: PropTypes.object,
	onBeforeCalculateConvenience: PropTypes.func.isRequired,
	includeExcludeConvenience: PropTypes.func.isRequired,
	customDisplayLabels: PropTypes.any,
};

export default AddEditCustomerSchedules;
