import { map, get, concat, orderBy, toLower, find } from 'lodash';

import httpService from './httpService';
import { toMoment, localTimezone, cognitoTokenHandler } from 'common/utilities';

const { portalManagementApiEndpoint, isoDateFormat, displayDateTimeFormat } = ApplicationSettings;

class PortalManagementService {
	constructor(httpService) {
		this.httpService = httpService;
	}

	get options() {
		const headers = new Headers();
		headers.set('Authorization', `Bearer ${cognitoTokenHandler()}`);
		return {
			headers,
			isJson: true,
		};
	}

	listAuthEvents = async params => {
		const endpoint = `${portalManagementApiEndpoint}listuserauthevents`;
		const result = await this.httpService.post(endpoint, params, this.options);
		return await this.parseAuthEvents(result);
	};

	listAuthEventsAll = async (username, nextToken = null) => {
		const endpoint = `${portalManagementApiEndpoint}listuserauthevents`;

		let finalResponse = await this.httpService.post(endpoint, { username, nextToken }, this.options);

		nextToken = finalResponse.nextToken;

		while (nextToken) {
			const response = await this.httpService.post(endpoint, { username, nextToken }, this.options);

			nextToken = response.nextToken;
			finalResponse.events = concat(finalResponse.events, response.events);
		}

		finalResponse = await this.parseAuthEvents(finalResponse);
		finalResponse.events = orderBy(finalResponse.events, ['creationDateMoment'], ['desc']);
		finalResponse.nextToken = null;

		return finalResponse;
	};

	listUserGroups = async params => {
		const endpoint = `${portalManagementApiEndpoint}listusergroups`;
		const result = await this.httpService.post(endpoint, params, this.options);
		return await this.parseUserGroups(result);
	};

	get = async params => {
		const endpoint = `${portalManagementApiEndpoint}getuser`;
		const result = await this.httpService.post(endpoint, params, this.options);
		return await this.parseUser(result, params);
	};

	listAllUsers = async () => {
		const endpoint = `${portalManagementApiEndpoint}listusers`;
		const result = await this.httpService.post(endpoint, {}, this.options);

		const filter = { NextToken: result.nextToken };

		while (filter.NextToken) {
			const newResponse = await this.httpService.post(endpoint, filter, this.options);
			filter.NextToken = newResponse.nextToken;
			result.users = concat(result.users, newResponse.users);
			result.refNum = newResponse.refNum;
		}

		delete result.nextToken;
		return result;
	};

	confirmUser = async params => {
		const endpoint = `${portalManagementApiEndpoint}verifyuser`;
		const result = await this.httpService.post(endpoint, params, this.options);
		return result;
	};

	deleteUser = async params => {
		const endpoint = `${portalManagementApiEndpoint}deleteuser`;
		const result = await this.httpService.post(endpoint, params, this.options);
		return result;
	};

	resetMfa = async params => {
		const endpoint = `${portalManagementApiEndpoint}resetmfa`;
		const result = await this.httpService.post(endpoint, params, this.options);
		return result;
	};

	resendConfirmationCode = async params => {
		const endpoint = `${portalManagementApiEndpoint}resendconfirmation`;
		const result = await this.httpService.post(endpoint, params, this.options);
		return result;
	};

	listLogs = async filter => {
		const endpoint = `${portalManagementApiEndpoint}listlogs`;
		const result = await this.httpService.post(endpoint, filter, this.options);
		return result;
	};

	parseAuthEvents = async result => {
		if (!result) {
			return;
		}
		const { events, ...rest } = result;
		const mappedResults = {
			...rest,
			events: await Promise.all(map(events, this.parseAuthEvent)),
		};
		return mappedResults;
	};

	parseAuthEvent = async result => {
		if (!result) {
			return;
		}
		if (result.creationDate) {
			result.creationDateMoment = await toMoment(result.creationDate, 'UTC', localTimezone, isoDateFormat);
			result.creationDate = result.creationDateMoment.format(displayDateTimeFormat);
		}
		return result;
	};

	parseUser = async (result, { username }) => {
		if (!result) {
			return;
		}

		if (result.userCreateDate) {
			result.userCreateDateMoment = await toMoment(result.userCreateDate, 'UTC', localTimezone, isoDateFormat);
			result.userCreateDate = result.userCreateDateMoment.format(displayDateTimeFormat);
		}

		result.mfa = '';
		if (result.preferredMfaSetting === 'SMS_MFA') {
			result.mfa = get(find(result.attributes, ({ name }) => toLower(name) === 'phone_number'), 'value', '');
		}
		if (result.preferredMfaSetting === 'SOFTWARE_TOKEN_MFA') {
			result.mfa = 'Software MFA';
		}

		result.username = username;
		return result;
	};

	parseUserGroups = async result => {
		if (!result) {
			return;
		}
		return result;
	};
}

const portalManagementService = new PortalManagementService(httpService);

export default portalManagementService;
