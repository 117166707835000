import { get } from 'lodash';
import principalService from '../services/principalService';

function updateApiEndpoint(endpoint, skipRouteGateway) {
	const principal = principalService.get();
	const routeGatewayLambda = get(principal, 'idInfo.xPortalUseGatewayLambda');
	// Check if the specific parameter exists in the Cognito user
	if (!skipRouteGateway && routeGatewayLambda) {
		// Update the apiEndpoint based on the environment
		switch (AppBuildEnvironment) {
			case 'local':
			case 'dev':
				return `https://us-west-2.devgateway.cardknox.com/${endpoint || ''}`;
			case 'stg':
			case 'prd':
				return `https://us-west-2.gateway.cardknox.com/${endpoint || ''}`;
		}
	}

	return endpoint ? `${ApplicationSettings.apiEndpoint}${endpoint}` : ApplicationSettings.apiEndpoint;
}

export default updateApiEndpoint;
