import { each, clone } from 'lodash';

export const mapDefaultOrder = (defaultOrder, order = { data: {} }) => {
	const mappedOrder = clone(order);
	each(defaultOrder, (item, key) => {
		if (!mappedOrder.data[key]) {
			mappedOrder.data[key] = item;
		}
	});
	return mappedOrder;
};
