import httpService from './httpService';
const { paymentEngineSettingsApiEndpoint } = ApplicationSettings;
const endpoint = paymentEngineSettingsApiEndpoint;

const { name, version: softwareVersion } = SoftwareSettings;

class PaymentEngineSettingsService {
	constructor() {
		this.httpService = httpService;
	}
	get options() {
		let headers = new Headers();
		return {
			headers,
			isJson: true,
		};
	}

	async list() {
		const response = await this.httpService.post(
			`${endpoint}/list`,
			{
				xSoftwareName: name,
				xSoftwareVersion: softwareVersion,
			},
			this.options
		);
		return response.xResultData;
	}

	async save(xSettings, xSoftwareName, xUserName) {
		const body = {
			xSettings,
			xSoftwareName,
			xUserName,
			xSoftwareVersion: softwareVersion,
		};

		const response = await this.httpService.post(`${endpoint}/save`, body, this.options);
		return response.xResultData;
	}

	async load(xSoftwareName, xUserName, xClientName) {
		const body = {
			xUserName,
			xClientName,
			xSoftwareName,
			xSoftwareVersion: softwareVersion,
		};

		const response = await this.httpService.post(`${endpoint}/load`, body, this.options);
		return response.xResultData;
	}
}

const paymentEngineSettingsService = new PaymentEngineSettingsService();

export default paymentEngineSettingsService;
