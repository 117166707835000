import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { clone, every, filter, get, head, includes, map, toLower } from 'lodash';
import { DraggableColumn } from 'common/components/settings';
import { kvaasService, principalService } from 'common/services';
import handleInvalidRevision from '../utils/invalidRevision';
import { withLoader } from 'common/components/loader';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { withBlock } from 'common/components/block';
import BaseSettings from 'components/settings/pages/BaseSettings';
import { Notification } from 'common/components/notifications';
import { kvaasResources, mapConvenienceToCustom } from 'common/utilities';
import { Select } from 'common/components/select';
import { predefinedDates } from 'common/components/date-picker';

let principal = principalService.get();
const has3DS2 = principal.idInfo && principal.idInfo.x3DSEnabled && principal.idInfo.x3DSVersion === '2';
const predefinedDateOptions = map(predefinedDates, ({ key: name, displayValue: label }) => ({ name, label }));

class UserSettingsTransactionHistory extends BaseSettings {
	constructor(props) {
		super(props);
		this.state = this.initialState;
	}

	get initialState() {
		return {
			reportType: 'transactionReport',
			transactionReport: {
				fields: this.getTransactionFields(),
				defaultValues: {
					date: '7',
					limit: '20',
					approved: false,
					includeVoidStatus: false,
					multiKeyReporting: false,
				},
				oldData: {
					isDefault: null,
					order: null,
					hide: null,
					customLabel: null,
					portalFlags: null,
					userSettings: null,
					defaultValues: null,
				},
			},
			sectionsExpanded: {
				columns: false,
			},
		};
	}

	componentDidMount = async () => {
		this.props.showLoader(true);
		await this.fetchData();
		this.props.showLoader(false);
	};
	requiredKvaasResources() {
		return kvaasService.get(
			...[
				{ ...kvaasResources.transactionReportDefaultColumns, throwError: true },
				{ ...kvaasResources.transactionReportOrder, throwError: true },
				{ ...kvaasResources.transactionReportHiddenFields, throwError: true },
				{ ...kvaasResources.transactionReportDefaultValues, throwError: true },
				{ ...kvaasResources.transactionDisplayLabels, throwError: true },
				{ ...kvaasResources.portalFlags, throwError: true },
				{ ...kvaasResources.userSettings, throwError: true },
				{ ...kvaasResources.convenienceFees, throwError: true },
			]
		);
	}

	getTransactionFields = () => {
		const initialFields = filter(this.transactionInitialFields, c => {
			let showColumn = true;
			showColumn = principal.isAdmin ? true : !c.isAdminColumn;
			if (!has3DS2 && (toLower(c.key) === 'x3dsresponsecode' || toLower(c.key) === 'x3dsconsumerinteraction')) {
				showColumn = false;
			}
			return showColumn;
		});

		return [...initialFields];
	};
	getData = async () => {
		const getKvaas = this.requiredKvaasResources();
		const [
			transactionReportDefaultColumns,
			transactionReportOrder,
			transactionReportHiddenFields,
			transactionReportDefaultValues,
			transactionDisplayLabels,
			portalFlags,
			userSettings,
			convenienceFees,
		] = await this.props.makePendingRequest(getKvaas, 'kvaas');

		return {
			transactionReportDefaultColumns,
			transactionReportOrder,
			transactionReportHiddenFields,
			transactionReportDefaultValues,
			transactionDisplayLabels,
			portalFlags,
			userSettings,
			convenienceFees,
		};
	};

	fetchData = async setToDefault => {
		if (setToDefault) {
			this.setState(this.initialState);
		}
		this.props.showLoader(true);
		try {
			const {
				transactionReportDefaultColumns,
				transactionReportOrder,
				transactionReportHiddenFields,
				transactionReportDefaultValues,
				transactionDisplayLabels,
				portalFlags,
				userSettings,
				convenienceFees,
			} = await this.getData();

			const newState = this.mapResponseToState(
				transactionReportDefaultColumns,
				transactionReportOrder,
				transactionReportHiddenFields,
				transactionDisplayLabels,
				portalFlags,
				userSettings,
				transactionReportDefaultValues
			);
			const portalFlagsData = this.getPortalFlagsData(portalFlags);

			newState.displayTokenColumn = get(userSettings, 'data.displayTokenColumn', false);
			newState.displayAuthorization = get(userSettings, 'data.displayAuthorization', false);

			const { parsedConvenienceKey, parsedOriginalKey } = mapConvenienceToCustom(
				convenienceFees,
				null,
				transactionDisplayLabels
			);
			if (!this.hasDataUserSettings('displayTokenColumn', get(userSettings, 'data', []))) {
				const fields = filter(newState.fields, ({ key }) => key !== 'token');
				newState.fields = fields;
			}
			newState.parsedConvenienceKey = parsedConvenienceKey;
			newState.parsedOriginalKey = parsedOriginalKey;
			if (!this.hasDataPortalFlags('displaySplitPayColumns', portalFlagsData)) {
				const fields = filter(newState.fields, ({ key }) => !includes(['netSale', 'processingFee'], key));
				newState.fields = fields;
			}

			if (!this.hasDataPortalFlags('displayAmexVerificationColumns', portalFlagsData)) {
				this.toggleAmexVerificationColumns(false, newState);
			}

			if (!this.hasDataPortalFlags('multipleCapture', portalFlagsData)) {
				const fields = filter(newState.fields, ({ key }) => !includes(['clearedAmount', 'clearedCount'], key));
				newState.fields = fields;
			}
			if (!this.hasDataPortalFlags('displayAmexVerificationColumns', portalFlagsData)) {
				this.toggleAmexVerificationColumns(false, newState);
			}

			this.setState({
				transactionReport: newState,
			});
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.handleKvaasLoadError();
			}
		}
		this.props.showLoader(false);
	};
	setToDefault = async () => {
		return [
			this.mapStateToFields('transactionReport', 'order', kvaasResources.transactionReportOrder, true),
			this.mapStateToFields('transactionReport', 'hide', kvaasResources.transactionReportHiddenFields, true),
			this.mapStateToFields('transactionReport', 'isDefault', kvaasResources.transactionReportDefaultColumns, true),
			{
				newData: {
					revision: 0,
					data: { date: '7', limit: '20', approved: false, includeVoidStatus: false, multiKeyReporting: false },
				},
				oldData: this.state.transactionReport.oldData.defaultValues,
				...kvaasResources.transactionReportDefaultValues,
			},
			{
				newData: {
					revision: 0,
					data: {
						displayAuthorization: false,
						displayTokenColumn: false,
					},
				},
				oldData: this.state.transactionReport.oldData.userSettings,
				...kvaasResources.userSettings,
			},
		];
	};

	hasDataUserSettings = (key, userSettingsData) => {
		return userSettingsData[key];
	};

	mapStateToReport = () => {
		return [
			this.mapStateToFields('transactionReport', 'order', kvaasResources.transactionReportOrder),
			this.mapStateToFields('transactionReport', 'hide', kvaasResources.transactionReportHiddenFields),
			this.mapStateToFields('transactionReport', 'isDefault', kvaasResources.transactionReportDefaultColumns),
			{
				newData: { revision: 0, data: this.state.transactionReport.defaultValues },
				oldData: this.state.transactionReport.oldData.defaultValues,
				...kvaasResources.transactionReportDefaultValues,
			},
			{
				newData: {
					revision: 0,
					data: {
						displayTokenColumn: this.state.transactionReport.displayTokenColumn,
						displayAuthorization: this.state.transactionReport.displayAuthorization,
					},
				},
				oldData: this.state.transactionReport.oldData.userSettings,
				...kvaasResources.userSettings,
			},
		];
	};

	save = async setToDefaults => {
		this.props.showLoader(true);
		let error;
		let refNum;
		try {
			const mappedState = setToDefaults ? await this.setToDefault() : this.mapStateToReport();
			const response = await this.props.makePendingRequest(kvaasService.save(...mappedState), 'save');
			refNum = head(response).refNum;

			this.props.handleBlockChange(false);
			await this.fetchData(setToDefaults);
		} catch (e) {
			this.props.showLoader(false);

			error = this.props.handleError(e, { delayMessage: true });
			if (error) {
				await this.fetchData();
			}
		}
		this.notifySuccess(error, setToDefaults, 'Transaction History', refNum);
		this.props.showLoader(false);
	};

	toggleAmexVerificationColumns = (display, newState) => {
		if (this.state.reportType !== 'transactionReport') return;
		const fields = filter(newState.fields, ({ key }) => {
			if (!display) {
				return !includes(['nameVerification', 'emailVerification', 'phoneVerification'], key);
			}
			return true;
		});

		newState.fields = fields;
	};
	handleDefaultLoadLimitChange = ({ label: limit }) => {
		const defaultValues = clone(this.state.transactionReport.defaultValues);
		defaultValues.limit = limit;
		this.setState({
			transactionReport: {
				...this.state.transactionReport,
				defaultValues: defaultValues,
			},
		});
		this.props.handleBlockChange(true);
	};
	toggleExpandCollapseSection = section => () => {
		this.setState({
			sectionsExpanded: { ...this.state.sectionsExpanded, [section]: !this.state.sectionsExpanded[section] },
		});
	};

	render = () => {
		const {
			fields,
			parsedOriginalKey,
			parsedConvenienceKey,
			defaultValues,
			displayTokenColumn,
			displayAuthorization,
		} = this.state.transactionReport;
		const {
			sectionsExpanded: { columns },
		} = this.state;
		const selectAllValue = every(fields, i => i.hide || i.isDefault);
		const selectAllLabel = selectAllValue ? 'Unselect all' : 'Select all';

		return (
			<Fragment>
				<div className="settings--main settings--main--alt">
					<div className="settings__header">
						<div className="settings__header__title">Transaction History Settings</div>
						<div className="settings__header__action">{this.renderSaveButton(false)}</div>
					</div>
					<div ref={top}></div>
					<div className="spr__card clearfix">
						<div
							className={`spr__heading cursor--pointer ${columns ? 'is-expanded' : ''}`}
							onClick={this.toggleExpandCollapseSection('columns')}
						>
							<h4 className="spr__heading__title">Manage Columns</h4>
							<i
								className={`icon icon--tiny icon--arrow--right--grey spr__heading__expand ${
									columns ? 'is-expanded' : ''
								}`}
							></i>
						</div>
						{columns && (
							<div className="spr__content">
								<div className="message message--default spc--bottom--sml--alt">
									The column name reflects the name set in the Portal Account Settings {'>'} New Transaction settings.
								</div>
								<div className="settings--report__header spc--bottom--sml--alt">
									<div className="settings__transaction__heading">Show in Report</div>
									<div className="type--sml settings__transaction__manual">
										<i className="icon icon--xsml icon--drag icon--middle spc--right--tny"></i>
										Drag to edit order
									</div>
								</div>

								<hr className="separator separator--grey1 spc--bottom--sml--alt" />

								<div className="w--max--570">
									<div className="display--ib spc--bottom--sml">
										<input
											type="checkbox"
											name="selectAll"
											id="selectAll"
											checked={selectAllValue}
											className="input--check"
											onChange={this.handleChange}
										/>
										<label htmlFor="selectAll" className="type--color--text--medium type--wgt--medium type--base">
											{selectAllLabel}
										</label>
									</div>
									<hr className="separator separator--grey1" />
									<DraggableColumn
										items={fields}
										parsedConvenienceKey={parsedConvenienceKey}
										parsedOriginalKey={parsedOriginalKey}
										disable={false}
										onChange={this.handleChange}
										removeCustomField={this.removeCustomField}
										onHover={this.moveColumn}
									/>
								</div>
								{this.canAddCustomFields ? (
									<div className="spc--bottom--med spc--top--med">
										<a onClick={this.addCustomField} className="anchor anchor--primary anchor--underline">
											Add Custom Field
										</a>
									</div>
								) : null}
							</div>
						)}
					</div>
					<div className="spr__card clearfix">
						<div className="spr__content">
							<div className="f-row f-row--center">
								<div className="f-col f-col-sml-12 f-col-lrg-4 type--color--text--medium type--wgt--medium spc--bottom--sml--alt">
									Default Date Range
								</div>
								<div className="f-col f-col-sml-12 f-col-lrg-5 spc--bottom--sml--alt">
									<Select
										name="defaultDateRange"
										id="defaultDateRange"
										reportType="transactionReport"
										value={this.getDate(defaultValues.date)}
										options={predefinedDateOptions}
										onChange={e => this.handleDefaultDateRangeChange(e, 'transactionReport')}
									/>
								</div>
							</div>
							<div className="f-row f-row--center">
								<div className="f-col f-col-sml-12 f-col-lrg-4 type--color--text--medium type--wgt--medium spc--bottom--sml--alt">
									Default Load Limit
								</div>
								<div className="f-col f-col-sml-12 f-col-lrg-5 spc--bottom--sml--alt">
									<Select
										name="defaultLoadLimit"
										id="defaultLoadLimit"
										reportType="transactionReport"
										value={this.getLimit(defaultValues.limit)}
										options={this.loadMoreOptionsWithAll}
										onChange={e => this.handleDefaultLoadLimitChange(e, 'transactionReport')}
									/>
								</div>
							</div>
							<div className="spc--bottom--med">
								<input
									type="checkbox"
									id="multiKeyReporting"
									name="multiKeyReporting"
									checked={defaultValues.multiKeyReporting}
									value={defaultValues.multiKeyReporting}
									onChange={this.handleActiveDefaultChange}
									className="input input--check"
								/>
								<label htmlFor="multiKeyReporting" className="type--color--text--medium type--wgt--medium">
									Enable Reporting Across Multiple Accounts
								</label>
							</div>
							<div className="spc--bottom--med">
								<input
									type="checkbox"
									id="transactionReport.displayAuthorization"
									name="transactionReport.displayAuthorization"
									checked={displayAuthorization}
									value={displayAuthorization}
									onChange={this.handleCheckboxChange}
									className="input input--check"
								/>
								<label
									htmlFor="transactionReport.displayAuthorization"
									className="type--color--text--medium type--wgt--medium"
								>
									View Authorization information on captures
								</label>
							</div>
							<div className="spc--bottom--med">
								<input
									type="checkbox"
									id="transactionReport.displayTokenColumn"
									name="transactionReport.displayTokenColumn"
									checked={displayTokenColumn}
									value={displayTokenColumn}
									onChange={this.handleCheckboxChange}
									className="input input--check"
								/>
								<label
									htmlFor="transactionReport.displayTokenColumn"
									className="type--color--text--medium type--wgt--medium"
								>
									View Tokens
								</label>
							</div>
							<div className="spc--bottom--med">
								<input
									type="checkbox"
									id="approved"
									name="approved"
									checked={defaultValues.approved}
									value={defaultValues.approved}
									onChange={this.handleActiveDefaultChange}
									className="input input--check"
								/>
								<label htmlFor="approved" className="type--color--text--medium type--wgt--medium">
									Show Approved-Only Transactions By Default
								</label>
							</div>
							<div className="spc--bottom--med">
								<input
									type="checkbox"
									id="includeVoidStatus"
									name="includeVoidStatus"
									checked={defaultValues.includeVoidStatus}
									value={defaultValues.includeVoidStatus}
									onChange={this.handleActiveDefaultChange}
									className="input input--check"
								/>
								<label htmlFor="includeVoidStatus" className="type--color--text--medium type--wgt--medium">
									Show Void Status When Exported
								</label>
							</div>
						</div>
					</div>
				</div>
				<Notification ref={this.notification} />
			</Fragment>
		);
	};
}

UserSettingsTransactionHistory.propTypes = {
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	showLoader: PropTypes.func,
	history: PropTypes.object,
	match: PropTypes.object,
	location: PropTypes.object,
	isLoading: PropTypes.bool,
	handleBlockChange: PropTypes.func,
};

export default withError(withLoader(withCancelable(withBlock(UserSettingsTransactionHistory))), handleInvalidRevision);
