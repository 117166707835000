import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SingleSelectionComponent extends Component {
	filterRemoved = () => {
		const {
			onFilterRemoved,
			filter: { key },
		} = this.props;
		onFilterRemoved({
			id: key,
			emptyValue: null,
		});
	};

	render() {
		const {
			filter: { key, values, selectionName, name },
			prefix,
		} = this.props;
		return (
			<div className="filter__tag">
				<label className="filter__tag__label">
					{selectionName || name}: {prefix}
				</label>
				<span className="display--ib align--v--middle">{values[key]}</span>
				<button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
			</div>
		);
	}
}

SingleSelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired,
	prefix: PropTypes.string,
};

export default SingleSelectionComponent;
