import React from 'react';
import { map } from 'lodash';

import { NumericFilter, StringFilter } from '../columns/filters';

import { CurrencyComponent } from '../columns/formatters';
import { ColumnHeader } from '../columns/headers';

const columns = [
	{
		key: 'xLocation',
		name: 'Location',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 350,
		resizable: true,
	},
	{
		key: 'xTransactionCount',
		name: 'Transaction Count',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: NumericFilter,
		initWidth: 350,
		resizable: true,
	},
	{
		key: 'xAmount',
		name: 'Total Amount',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: NumericFilter,
		formatter: CurrencyComponent,
		getRowMetaData: row => {
			return { ...row, displayBadge: true };
		},
		initWidth: 126,
		resizable: true,
		alignHeaderRight: true,
	},
	{
		key: 'xParentLocation',
		name: 'Parent Location',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 350,
		resizable: true,
	},
];

const giftCardSummaryColumns = map(columns, column => {
	column = {
		headerRenderer: <ColumnHeader />,
		...column,
	};
	return column;
});

export default giftCardSummaryColumns;
