import moment from 'moment';

export default [
	{
		key: 'today',
		startValue: 0,
		endValue: 0,
		displayValue: 'Today',
		description: '(today)',
	},
	{
		key: 'yesterday',
		startValue: -1,
		endValue: -1,
		displayValue: 'Yesterday',
		description: '(yesterday)',
	},
	{
		key: 'month',
		startValue: () => -parseInt(moment().format('DD')) + 1,
		endValue: 0,
		displayValue: 'This Month',
		description: '(this month)',
	},
	{
		key: 'lastmonth',
		startValue: () =>
			-parseInt(
				moment().diff(
					moment()
						.subtract(1, 'months')
						.date(1),
					'days'
				)
			),
		endValue: () => -parseInt(moment().diff(moment().date(1), 'days')) - 1,
		displayValue: 'Last Month',
		description: '(last month)',
	},
	{
		key: '7',
		startValue: -7,
		endValue: 0,
		displayValue: 'Last 7 Days',
		description: '(past 7 days)',
	},
	{
		key: '30',
		startValue: -30,
		endValue: 0,
		displayValue: 'Last 30 Days',
		description: '(past 30 days)',
	},
	{
		key: '60',
		startValue: -60,
		endValue: 0,
		displayValue: 'Last 60 Days',
		description: '(past 60 days)',
	},
	{
		key: '90',
		startValue: -90,
		endValue: 0,
		displayValue: 'Last 90 Days',
		description: '(past 90 days)',
	},
];
