import { endsWith } from 'lodash';

import NewVersionComponent from './new-version';
import AccountUpdaterComponent from './account-updater';
import VP3300 from './vp3300';
import TapToPhone from './tap-to-phone';

import PhonePayFree from './phone-pay-free';
import NewPortalComponent from './new-portal';
import OldPortalComponent from './old-portal';
import UpdatedSettingsComponent from './updated-settings';
import MobileAppNotificationComponent from './mobile-app-notification';
import DigitalWallet from './digital-wallet';
import giftCardNotification from './gift-card-notification';
import cardknoxSettingsNotification from './cardknox-settings';

const currentVersion = SoftwareSettings.version;
const newPortalEndpoint = ApplicationSettings.newPortalEndpoint;
const isNewPortal = endsWith(window.location.host, newPortalEndpoint);
const newPortalVersion = newPortalEndpoint && !isNewPortal ? currentVersion : '1.0.0';
const oldPortalVersion = newPortalEndpoint && isNewPortal ? currentVersion : '1.0.0';

const types = {
	general: 'notifications--general',
	info: 'notifications--info',
	warning: 'notifications--warning',
};
export const cardknoxSettings = {
	Component: cardknoxSettingsNotification,
	summary: 'We’ve made some changes to how we display Portal Settings.',
	title: 'Portal Setting Changes',
	type: types.info,
	key: 'cardknoxSettings',
	value: true,
};

export const newVersion = {
	Component: NewVersionComponent,
	summary: 'A new version has been released',
	title: 'New version release',
	type: types.info,
	key: 'newVersion',
	value: currentVersion,
};
export const digitalWallet = {
	Component: DigitalWallet,
	summary: 'Enable Google Pay and Apple Pay on PaymentSITE hosted checkout pages to make checkout easier than ever.',
	title: 'Streamline Checkout with Digital Wallets',
	type: types.info,
	key: 'digital',
	value: true,
};
export const giftCard = {
	Component: giftCardNotification,
	summary: 'Get started with our Cardknox Gift Card Program to issue, track, and redeem gift cards.',
	title: 'Get Started With Our Cardknox Gift Card Program',
	type: types.info,
	key: 'giftCard',
	value: true,
};

export const vp3300 = {
	Component: VP3300,
	summary: 'Boost your revenue by accepting payments on the go! Order our compact card reader, the ID TECH VP3300.',
	title: 'ID TECH VP3300 Card Reader',
	type: types.info,
	key: 'vp3300',
	value: true,
};

export const accountUpdater = {
	Component: AccountUpdaterComponent,
	summary: 'Reduce card declines on recurring payments with Account Updater! ',
	title: 'Account Updater',
	type: types.info,
	key: 'accountUpdater',
	value: true,
};
export const mobileAppLink = {
	Component: MobileAppNotificationComponent,
	summary: 'Accept and manage payments on the go with our free mobile app!',
	title: 'Cardknox Mobile App',
	type: types.info,
	key: 'mobileApp',
	value: true,
};

export const phonePayFree = {
	Component: PhonePayFree,
	summary: 'Accept phone payments from your customers quickly and securely, day or night',
	title: 'Phone Pay Free',
	type: types.info,
	key: 'phonePayFree',
	value: true,
};
export const tapToPhone = {
	Component: TapToPhone,
	summary: 'Accept phone payments from your customers quickly and securely, day or night',
	title: 'Tap To Phone',
	type: types.info,
	key: 'tapToPhone',
	value: true,
};
export const updatedSettings = {
	Component: UpdatedSettingsComponent,
	summary: "We've updated the Portal...",
	title: 'We have reorganized our settings!',
	type: types.info,
	key: 'updatedSettings',
	value: true,
	isOpen: true,
};

export const newPortal = {
	Component: NewPortalComponent,
	summary: "We've updated the Portal...",
	title: 'Early Access To New Features!',
	type: types.info,
	key: 'newPortal',
	value: newPortalVersion,
	isOpen: true,
};

export const oldPortal = {
	Component: OldPortalComponent,
	summary: 'We hope that you’re enjoying...',
	title: 'Thanks for joining our pilot program!',
	type: types.info,
	key: 'oldPortal',
	value: oldPortalVersion,
	isOpen: true,
};
