import { transform, times, padStart, merge, toLower } from 'lodash';

const paymentSiteFields = transform(
	{
		xBillFirstName: 'Bill First Name',
		xBillMiddleName: 'Bill Middle Name',
		xBillLastName: 'Bill Last Name',
		xBillCompany: 'Bill Company',
		xBillPhone: 'Bill Phone Number',
		xBillMobile: 'Bill Cell Phone',
		xEmail: 'Bill Email',
		xBillStreet: 'Bill Address',
		xBillStreet2: 'Bill Address 2',
		'bill city, state, zip': 'Bill City, State, Zip',
		'bill city, state, zip zip required': 'Bill City, State, Zip required',
		xBillCity: 'Bill City',
		xBillState: 'Bill State',
		xBillCountry: 'Bill Country',
		xBillZip: 'Bill ZIP',
		xShipFirstName: 'Ship First Name',
		xShipLastName: 'Ship Last Name',
		xShipMiddleName: 'Ship Middle Name',
		xShipCompany: 'Ship Company',
		xShipEmail: 'Ship Email',
		xShipPhone: 'Ship Phone Number',
		xShipMobile: 'Ship Cell Phone',
		xShipStreet: 'Ship Address',
		xShipStreet2: 'Ship Address 2',
		'ship city, state, zip': 'Ship City, State, Zip',
		'ship city, state, zip zip required': 'Ship City, State, Zip required',
		xShipCity: 'Ship City',
		xShipState: 'Ship State',
		xShipCountry: 'Ship Country',
		xShipZip: 'Ship ZIP',
		xShipMethod: 'Ship Method',
		xZip: 'AVS Zip',
		xStreet: 'AVS Street',
		xAmount: 'Amount',
		xInvoice: 'Invoice',
		xPONum: 'PO Number',
		xTax: 'Tax',
		xTip: 'Tip',
		xDescription: 'Description',
		xCardNum: 'Card Number',
		xExpDate: 'Exp Date',
		xCVV: 'CVV',
		xAccountType: 'Account Type',
		xAccountName: 'Account Name',
		xRoutingNumber: 'Routing Number',
		xAccountNumber: 'Account Number',
		xRecurring: 'Cardknox Recurring',
		customers_schedules: 'Cardknox Recurring',
		recurring_simple: 'Fidelipay Recurring',
		recurring_split_payment: 'Fidelipay Split Payments',
		recurring_split_payment_8_months: 'Fidelipay Monthly Split Payments',
		same_as_billing: 'Display Same as Billing Checkbox ',
		termsAndConditions: 'Terms And Conditions',
		convenienceFee: 'Set up Electronic Transfer Fee',
		ReenterRoutingNumber: 'Re-enter Routing number',
		ReenterAccountNumber: 'Re-enter Account number',
		AllowedCommands: 'Allowed Transaction Types:',

		...transform(
			times(20, i => ({ [`xCustom${padStart(i + 1, 2, 0)}`]: `Custom${padStart(i + 1, 2, 0)}` })),
			merge,
			{}
		),
	},
	(acc, curr, key) => {
		acc[toLower(key)] = curr;
		return acc;
	},
	{}
);

export default paymentSiteFields;
