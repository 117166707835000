import moment from 'moment';
import 'moment-timezone';
import { get, isFunction } from 'lodash';

import kvaasService from '../services/kvaasService';
import { kvaasResources } from './kvaas-resources';

export const apiTimezone = 'America/New_York';
export const localTimezone = async () => {
	try {
		const [userSettings] = await kvaasService.get(kvaasResources.userSettings);
		return get(userSettings, 'data.timezone') || apiTimezone;
	} catch (e) {
		return apiTimezone;
	}
};

export const apiToLocalString = (date, format) => toTimeString(date, apiTimezone, localTimezone, format);

export const localToApiString = (date, format) => toTimeString(date, localTimezone, apiTimezone, format);

export const apiToLocalMoment = (date, format) => toMoment(date, apiTimezone, localTimezone, format);

export const localToApiMoment = (date, format) => toMoment(date, localTimezone, apiTimezone, format);

export const toTimeString = async (date, sourceTimezoneGetter, targetTimezoneGetter, format) => {
	if (!date) {
		return;
	}
	const parsed = await toMoment(date, sourceTimezoneGetter, targetTimezoneGetter, format);
	try {
		return parsed.format(format);
	} catch (e) {
		//eslint-disable-next-line
		console.error(e);
		return date;
	}
};

export const toMoment = async (date, sourceTimezoneGetter, targetTimezoneGetter, format) => {
	if (!date) {
		return;
	}
	const parsed = moment(date, format);
	try {
		const sourceTimezone = isFunction(sourceTimezoneGetter) ? await sourceTimezoneGetter() : sourceTimezoneGetter;
		const targetTimezone = isFunction(targetTimezoneGetter) ? await targetTimezoneGetter() : targetTimezoneGetter;
		return parsed.tz(sourceTimezone, true).tz(targetTimezone);
	} catch (e) {
		//eslint-disable-next-line
		console.error(e);
		return parsed;
	}
};
