import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { mapAvsItem } from 'common/utilities';

class AvsComponent extends Component {
	constructor(props) {
		super(props);
	}

	renderItemText = () => {
		const { value } = this.props;
		if (!value) {
			return '';
		}

		const item = mapAvsItem(value);

		return item ? item.text : 'Unknown';
	};

	render() {
		return <div>{this.renderItemText()}</div>;
	}
}

AvsComponent.propTypes = {
	value: PropTypes.string.isRequired,
	dependentValues: PropTypes.any,
};

export default AvsComponent;
