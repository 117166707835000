import React from 'react';
import { map } from 'lodash';

import { NumericFilter, StringFilter, MultiselectCardknoxFilter } from '../columns/filters';

import { DisplayDateComponent, CurrencyComponent, LinkComponent, GiftCard, Command } from '../columns/formatters';
import { ColumnHeader } from '../columns/headers';

const columns = [
	{
		key: 'xRefNum',
		name: 'Reference Number',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		getRowMetaData: row => row,
		initWidth: 155,
		resizable: true,
	},
	{
		key: 'xMaskedCardNumber', //just changed name for now...
		name: 'Gift Card Number',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		formatter: GiftCard,
		getRowMetaData: row => row,
		initWidth: 150,
		resizable: true,
	},

	{
		key: 'xAmount',
		name: 'Amount',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: NumericFilter,
		formatter: CurrencyComponent,
		getRowMetaData: row => {
			return { ...row, displayBadge: true };
		},
		initWidth: 126,
		resizable: true,
		alignHeaderRight: true,
	},
	{
		key: 'xName',
		name: 'C­ar­dholder N­ame', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		customSettingsKey: 'cardholderName',
		resizable: true,
	},
	{
		key: 'xEnteredDate',
		name: 'Date and Time',
		sortable: true,
		hideable: true,
		filterable: true,
		visible: true,
		filterRenderer: StringFilter,
		formatter: DisplayDateComponent,
		isDefaultSorter: true,
		defaultSortDirection: 'DESC',
		initWidth: 220,
		resizable: true,
	},
	{
		key: 'xLocation',
		name: 'Location',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		resizable: true,
	},
	{
		key: 'xCommand',
		name: 'Command',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: MultiselectCardknoxFilter,
		formatter: Command,
		initWidth: 150,
		resizable: true,
	},
	{
		key: 'xInvoice',
		name: 'Invoice',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		resizable: true,
	},
	{
		key: 'xCustom01',
		name: 'Custom01',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		formatter: LinkComponent,
		initWidth: 150,
		resizable: true,
	},
];

const giftActivityColumns = map(columns, column => {
	column = {
		headerRenderer: <ColumnHeader />,
		...column,
	};
	return column;
});

export default giftActivityColumns;
