import * as React from 'react';
import * as _ from 'lodash';
export default function () {
    function repeatErrorObj1(errorObj, ix) {
        return React.createElement('div', { 'key': ix }, React.createElement('h2', {}, '\n\t\t\t\t', errorObj.message, '\n\t\t\t'), errorObj.errorMessage ? React.createElement('div', { 'key': '131' }, '\n\t\t\t\t', errorObj.errorMessage, '\n\t\t\t') : null, errorObj.stackTrace ? React.createElement('div', { 'key': '208' }, React.createElement('code', {}, errorObj.stackTrace)) : null, errorObj.requestInfo ? React.createElement('div', { 'key': '294' }, React.createElement('h3', {}, 'Request'), errorObj.requestInfo.method ? React.createElement('div', { 'key': '354' }, React.createElement('code', {}, 'Method: ', errorObj.requestInfo.method)) : null, errorObj.requestInfo.url ? React.createElement('div', { 'key': '467' }, React.createElement('code', {}, 'URL: ', errorObj.requestInfo.url)) : null) : null, errorObj.responseInfo ? React.createElement('div', { 'key': '580' }, React.createElement('h3', {}, 'Response'), errorObj.responseInfo.status ? React.createElement('div', { 'key': '642' }, React.createElement('code', {}, 'Status: ', errorObj.responseInfo.status)) : null, errorObj.responseInfo.exception ? React.createElement('div', { 'key': '758' }, React.createElement('code', {}, errorObj.responseInfo.exception)) : null) : null);
    }
    return React.createElement('div', {}, React.createElement.apply(this, [
        'section',
        {},
        _.map(this.state.errorObjects, repeatErrorObj1.bind(this))
    ]));
}