import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { startsWith, toLower, debounce } from 'lodash';

import { renderIf, formatCardExpiry } from '../../Common/utilities';
import { transactionType } from './constants';
import principalService from '../../Common/services/principalService';
import { fieldPropType, isValid } from '../../Common/fields';

const { ifieldsSource } = ApplicationSettings;

const commonStyle = {
	width: 'calc(100% - 28px)',
	height: '38px',
	outline: 'none',
	'border-radius': '4px',
};

const inputStyle = {
	...commonStyle,
	padding: '0 12px',
	border: '1px solid #dfe3e8',
	'box-shadow': '',
};

const invalidStyle = {
	...commonStyle,
	padding: '0 11px',
	border: '1px solid #f03e1b',
	'box-shadow': '0 0 0 2px #f9d3d0',
};

import NumberFormat from 'react-number-format';

/* global setAccount, setIfieldStyle, enableAutoFormatting, ifieldEventCallbacks, addIfieldCallback,addIfieldKeyPressCallback, getTokens, enableAutoSubmit */

class CreditCardFields extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		if (this.cardNumRef && this.cvvRef && this.hidCardNum && this.hidCvv) {
			const {
				ccData: { ccValid, cvvValid },
				invalidCardNum,
			} = this.props;
			window.ifieldDataCache = {
				cardNumberIsValid: false,
				cardNumberLength: 0,
				cardNumberFormattedLength: 0,
				cardNumberIsEmpty: true,
				issuer: 'unknown',
				cvvIsValid: false,
				cvvLength: 0,
				cvvIsEmpty: true,
				achLength: 0,
				achIsEmpty: true,
				achIsValid: false,
				lastIfieldChanged: '',
			};
			const ifields = principalService.get().ifields;

			/*
			 * [Optional]
			 * You can enable allowing the user to submit the form by pressing the 'enter' key on their keyboard when the ifield is focused by calling
			 * enableAutoSubmit(formElementId)
			 *
			 * The formElementId is the id of your form that gets submit when the user presses 'enter' on their keyboard.
			 *
			 * Note: If this feature is enabled, the getTokens must be handled in a submit event listener for the form as that event is what gets triggered.
			 */
			enableAutoSubmit('transaction-form');

			setAccount(ifields, SoftwareSettings.name, SoftwareSettings.version);

			setIfieldStyle('card-number', isValid(ccValid) ? inputStyle : invalidStyle);

			setIfieldStyle('cvv', isValid(cvvValid) ? inputStyle : invalidStyle);

			/**
			 * Format the input card number but does not validate it
			 * Accepts delimiter as a parameter
			 *
			 * Resulting card number in an iframe will look like: 4111-1111-1111-1111 with the "-" as delimiter
			 */
			enableAutoFormatting(' ');

			/**
			 * Value is an object like
			 * {
			 *   cardNumberFormattedLength: 2
			 *   cardNumberIsEmpty: false
			 *   cardNumberIsValid: false
			 *   cardNumberLength: undefined
			 *   cvvIsEmpty: true
			 *   cvvIsValid: false
			 *   cvvLength: 0
			 *   issuer: "unknown"
			 *   lastIfieldChanged: "card-number"
			 * }
			 */
			if (ifieldEventCallbacks) {
				//eslint-disable-next-line
				window.ifieldEventCallbacks = {};
			}
			addIfieldCallback(
				'blur',
				function(data) {
					if (data && toLower(data.triggeredByIfield) === 'card-number') {
						this.focused = false;
						invalidCardNum(false);
					}
				}.bind(this)
			);

			addIfieldCallback(
				'focus',
				function(data) {
					if (data && toLower(data.triggeredByIfield) === 'card-number') {
						this.focused = true;
						invalidCardNum(true);
					}
				}.bind(this)
			);

			addIfieldKeyPressCallback(
				debounce(
					function(value) {
						const {
							cardNumberIsValid,
							cardNumberIsEmpty,
							issuer,
							cvvIsEmpty,
							cvvLength,
							cardNumberFormattedLength,
							lastIfieldChanged,
						} = value;
						const {
							requiredFields: { cvvAllTransactionsRequired, cvvNewTransactionsOnlyRequired },
						} = this.props;
						const isSwipe = cardNumberFormattedLength > 25;

						const cvvIsValid =
							(!cvvAllTransactionsRequired && !cvvNewTransactionsOnlyRequired && cvvLength === 0) ||
							(issuer === 'unknown' ? null : issuer === 'amex' ? cvvLength === 4 : cvvLength === 3);
						const changes = [
							{
								key: 'cvvValid',
								value: cvvIsValid,
							},
							{
								key: 'cvvIsEmpty',
								value: cvvIsEmpty,
							},
						];

						if (lastIfieldChanged === 'card-number') {
							changes.push(
								{
									key: 'ccValid',
									value: cardNumberIsValid,
								},
								{
									key: 'isSwipe',
									value: isSwipe,
								},
								{
									key: 'cardNumberIsEmpty',
									value: cardNumberIsEmpty,
								}
							);
						}

						this.props.onChange(...changes);
					}.bind(this),
					2
				)
			);
		}
	}

	getIfieldTokens = () => {
		return new Promise(resolve => {
			if (this.hidCardNum) {
				getTokens(
					() => {
						const tokens = { cc: this.hidCardNum.value };

						if (this.hidCvv) {
							tokens.cvv = this.hidCvv.value;
						}
						resolve(tokens);
					},
					resolve,
					30000
				);
			} else {
				resolve();
			}
		});
	};

	setCardNumberRef = el => {
		this.cardNumRef = el;
	};

	setCvvRef = el => {
		this.cvvRef = el;
	};

	onExpiryChange = ({ value }) => {
		this.props.onChange({
			key: 'expiry',
			value: value,
		});
	};

	onNumberChange = ({ value }, { target: { name } }) => {
		this.props.onChange({
			key: name,
			value,
		});
	};

	onChange = e => {
		this.props.onChange({
			key: e.target.name,
			value: e.target.value,
		});
	};

	render() {
		const {
			ccData,
			ccData: { isSwipe, ccValid, cvvValid, authCode, voucherSerial, expiry },
			customerId,
			invalidClassName,
			requiredFields,
			customDisplayLabels,
			transactionHiddenFields,
			showEbtfsVoucherOption,
			permissions: {
				allowCcSale,
				allowCcSave,
				allowCcAuthOnly,
				allowCcCredit,
				allowCcPostAuth,
				allowGiftIssue,
				allowGiftRedeem,
				allowEbtfsVoucher,
			},
		} = this.props;
		const required = (
			<span className="required-field label--required" data-tooltip="Required">
				*
			</span>
		);
		const ccClassName = `f-col f-col-sml-12 f-col-xlrg-6 spc--bottom--sml ${
			startsWith(ccData.transactionType, 'gift') ? 'display--n' : ''
		}`;

		setIfieldStyle('card-number', isValid(ccValid) || this.focused ? inputStyle : invalidStyle);
		setIfieldStyle('cvv', isValid(cvvValid) ? inputStyle : invalidStyle);
		return (
			<div className="newtransaction--expanded--inner">
				<div className="f-row">
					<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--med">
						<label className="label--transaction">Transaction type {required}</label>

						<div className="">
							<select
								name="transactionType"
								className="input input--med input--select"
								value={ccData.transactionType}
								onChange={this.onChange}
								tabIndex="-1"
							>
								{allowCcSale ? <option value={transactionType.SALE}>Charge</option> : null}
								{allowCcSave ? <option value={transactionType.SAVE}>Save</option> : null}
								{allowCcAuthOnly ? <option value={transactionType.AUTH_ONLY}>Auth Only</option> : null}
								{allowCcCredit ? <option value={transactionType.CREDIT}>Refund</option> : null}
								{allowCcPostAuth ? <option value={transactionType.POST_AUTH}>Post Auth</option> : null}
								{allowEbtfsVoucher && showEbtfsVoucherOption ? (
									<option value={transactionType.EBTFS_VOUCHER}>EBTFS Voucher</option>
								) : null}
								{allowGiftIssue ? <option value={transactionType.GIFT_ISSUE}>Gift: Issue</option> : null}
								{allowGiftRedeem ? <option value={transactionType.GIFT_REDEEM}>Gift: Redeem</option> : null}
							</select>
							<label data-ifields-id="card-data-error" />
						</div>
					</div>
					{renderIf(ccData.transactionType === transactionType.POST_AUTH)(
						<Fragment>
							<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--med">
								<div className="">
									<label className="label--transaction">Auth Code {required}</label>

									<input
										type="text"
										name="authCode"
										className={`input input--med ${(!isValid(authCode) && invalidClassName) || ''}`}
										placeholder="Auth Code"
										value={authCode.value}
										onChange={this.onChange}
									></input>
								</div>
							</div>
							<div className="f-col f-col-sml-12 f-col-med-12 spc--bottom--med">
								<div className="warning">
									<div className="bg--chablis padd--sml type--color--primary type--center type--xsml">
										Please only use the auth code that was provided by your processing bank.
									</div>
								</div>
							</div>
						</Fragment>
					)}
					{ccData.transactionType === transactionType.EBTFS_VOUCHER && (
						<Fragment>
							<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--med">
								<label className="label--transaction">Authorization Code {required}</label>

								<input
									type="text"
									name="authCode"
									className={`input input--med ${(!isValid(authCode) && invalidClassName) || ''}`}
									placeholder="Authorization Code"
									value={authCode.value}
									onChange={this.onChange}
								></input>
							</div>
							<div className="f-col f-col-sml-12 f-col-med-6 spc--bottom--med">
								<label className="label--transaction">Voucher Serial Number {required}</label>

								<NumberFormat
									name="voucherSerial"
									value={voucherSerial.value}
									placeholder="Voucher Serial Number"
									className={`input input--med ${(!isValid(voucherSerial) && invalidClassName) || ''}`}
									onValueChange={this.onNumberChange}
								/>
							</div>
						</Fragment>
					)}
				</div>
				<div className="f-row spc--bottom--med f-row--bottom">
					<div className="f-col f-col-sml-12 f-col-xlrg-6 spc--bottom--sml">
						<div>
							<label className="label--transaction">
								{customDisplayLabels.cardNumber || 'Card Number'} {required}
							</label>
							<div className="">
								<div className="newtransaction__iframe__fakeinput" tabIndex="-1">
									<iframe
										ref={this.setCardNumberRef}
										data-ifields-id="card-number"
										className="newtransaction__iframe"
										data-ifields-placeholder="XXXX XXXX XXXX XXXX"
										src={ifieldsSource}
									/>
									<input
										type="hidden"
										data-ifields-id="card-number-token"
										name="xCardNum"
										ref={r => {
											this.hidCardNum = r;
										}}
										required
									/>
								</div>
							</div>
						</div>
					</div>

					<div className={ccClassName}>
						<div className="f-row f-row--bottom">
							<div className="f-col f-col-sml-6">
								<div className="">
									<label className="label--transaction">
										{customDisplayLabels.validUntil || 'Exp Date'}{' '}
										{!isSwipe.value && ccData.transactionType !== transactionType.EBTFS_VOUCHER ? required : null}
									</label>
									<div className="transaction__input--fix">
										<NumberFormat
											disabled={isSwipe.value}
											format={formatCardExpiry}
											name="expiry"
											value={isSwipe.value ? '' : expiry.value}
											placeholder="MM/YY"
											className={`input input--med ${(!isValid(expiry) && invalidClassName) || ''}`}
											onValueChange={this.onExpiryChange}
											inputMode="decimal"
										/>
									</div>
								</div>
							</div>
							<div className={`${transactionHiddenFields.cvv ? 'display--n' : null} f-col f-col-sml-6`}>
								<div className="">
									<label className="label--transaction">
										{customDisplayLabels.cvv || 'CVV'}{' '}
										{(requiredFields.cvvAllTransactionsRequired || requiredFields.cvvNewTransactionsOnlyRequired) &&
										!isSwipe.value
											? required
											: null}
									</label>
									<div className="">
										<div className="newtransaction__iframe__fakeinput" tabIndex="-1">
											{!startsWith(ccData.transactionType, 'gift') && !customerId && isSwipe.value ? (
												<NumberFormat className="input input--med" placeholder="XXX" disabled={true} type="number" />
											) : (
												<iframe
													ref={this.setCvvRef}
													data-ifields-id="cvv"
													className="newtransaction__iframe"
													data-ifields-placeholder="XXX"
													src={ifieldsSource}
												/>
											)}
											<input
												type="hidden"
												data-ifields-id="cvv-token"
												name="xCVV"
												ref={r => {
													this.hidCvv = r;
												}}
												required
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

CreditCardFields.propTypes = {
	ccData: PropTypes.shape({
		expiry: fieldPropType(PropTypes.string).isRequired,
		transactionType: PropTypes.string.isRequired,
		authCode: fieldPropType(PropTypes.string).isRequired,
		voucherSerial: fieldPropType(PropTypes.string).isRequired,
	}).isRequired,
	onChange: PropTypes.func.isRequired,
	invalidCardNum: PropTypes.func.isRequired,

	customerId: PropTypes.string,
	invalidClassName: PropTypes.string,
	requiredFields: PropTypes.object,
	customDisplayLabels: PropTypes.object,
	transactionHiddenFields: PropTypes.object,
	permissions: PropTypes.object,
	showEbtfsVoucherOption: PropTypes.bool,
};

export default CreditCardFields;
