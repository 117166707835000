import BaseTitle from 'common/components/baseTitle/BaseTitle';
import React from 'react';

const titles = {
	'/recurring-schedules': { title: 'Recurring Schedules' },
	'/recurring-templates': { title: 'Templates' },
};

class Title extends React.Component {
	render() {
		return <BaseTitle titles={titles} withBadge={false} />;
	}
}

export default Title;
