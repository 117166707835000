import React from 'react';
import PropTypes from 'prop-types';

import { CardTypeImagePath } from './../../../utilities';
import { startsWith, toLower } from 'lodash';

class CreditCardComponent extends React.Component {
	get cardType() {
		const { xCommand, xCardType } = this.props.dependentValues;

		if (xCommand && startsWith(toLower(xCommand), 'gift') && toLower(xCardType) !== 'ojc') {
			return 'Gift';
		}
		return xCardType;
	}

	render() {
		return (
			<div>
				<img src={CardTypeImagePath.getPath(this.cardType)} className="grid__creditcard" />
				<span>{this.props.value || 'Unknown'}</span>
			</div>
		);
	}
}

CreditCardComponent.propTypes = {
	value: PropTypes.string.isRequired,
	xCommand: PropTypes.string.isRequired,
	xCardType: PropTypes.string.isRequired,
	dependentValues: PropTypes.object.isRequired,
};

export default CreditCardComponent;
