import { has, each, startsWith, toLower, get } from 'lodash';

import principalService from './principalService';
import HttpServiceError from './httpServiceError';
import { fixJsonFormat } from '../utilities/fix-json-format';
import logger from '../utilities/logger';
import currentCognitoToken from '../utilities/currentCognitoToken';
import updateApiEndpoint from 'common/utilities/UpdateApiEndpoint';

const applicationSettings = ApplicationSettings;

class HttpService {
	constructor(principalService) {
		this.principalService = principalService;
	}

	tryParse = (text, ...parsers) => {
		let data;
		each(parsers, parser => {
			try {
				data = parser(text);
			} catch (e) {
				//intentionally empty catch block
			}
			if (data) {
				return false;
			}
		});
		return data;
	};

	checkIfApiError = (response, url) => {
		const { data, ok } = response;
		if (
			data &&
			(!!data.xError ||
				!!data.xMessage ||
				!!data.errormessage ||
				!!data.Error ||
				(!!data.error && !startsWith(url, applicationSettings.kvaasEndpoint)))
		) {
			throw {
				isApiError: true,
				ref: data.xGatewayRefNum || data.xRefNum || data.xRecurringRefNum || data.refnum || data.RefNum,
				message: data.xError || data.xMessage || data.errormessage || data.Error || data.error,
				success: false,
				data,
			};
		}
		if (!ok && (data && !data.size)) {
			const errorMessage = { response: data };
			throw errorMessage;
		}

		return response;
	};
	handleResponse = async (response, options) => {
		const text = await response.text();
		const data = this.tryParse(text, JSON.parse, content => this.deserialize(content, options.fixBatchData));
		return { data, ok: response.ok, status: response.status };
	};

	httpRequest(url, options, deserializedBody) {
		const request = new Request(url, options);

		return new Promise((resolve, reject) => {
			fetch(request)
				.then(response =>
					options.isDocument
						? { data: response.blob(), ok: response.ok, status: response.status }
						: this.handleResponse(response, options)
				)
				.then(response => this.checkIfApiError(response, url))
				.then(response => {
					if (!options.skipLogging) {
						logger.logApiCall({
							url,
							request: deserializedBody,
							response,
						});
					}
					return resolve(response.data);
				})
				.catch(ex => {
					if (options.skipLogging) {
						resolve();
					} else {
						logger.logApiCall({
							url,
							request: deserializedBody,
							response: ex && ex.response,
						});
						if (ex && ex.isApiError) {
							reject(ex);
						} else {
							reject(
								new HttpServiceError({
									ex,
									request,
									response: ex.response,
								})
							);
						}
					}
				});
		});
	}

	async post(url, body, options = {}) {
		await this.initializeHeaders(url, options, options.isJson);
		options.method = 'POST';
		let deserializedBody = {};
		if (options.noInit) {
			deserializedBody = body;
		} else if (body) {
			deserializedBody = await this.initializeBody(url, body, options.allowPublic);
		}

		options.body = options.isJson ? JSON.stringify(deserializedBody) : this.serialize(deserializedBody);

		return await this.httpRequest(url, options, deserializedBody);
	}

	async get(url, options = {}, isJson = false) {
		if (!options.isDocument) {
			await this.initializeHeaders(url, options, isJson);
		}
		options.method = 'GET';

		return await this.httpRequest(url, options);
	}

	async initializeHeaders(url, options, isJson = false) {
		const hasContentType = options.headers && !!options.headers.get('Content-Type');
		const hasAuthorization = options.headers && !!options.headers.get('Authorization');

		options.headers = options.headers || new Headers();

		if (isJson && !hasContentType) {
			options.headers.set('Content-Type', 'application/json');
		} else if (!hasContentType) {
			options.headers.set('Content-Type', 'application/x-www-form-urlencoded');
		}

		if (applicationSettings.apiOrigin) {
			options.headers.set('Origin', applicationSettings.apiOrigin);
		}
		if (applicationSettings.apiRequestedWith) {
			options.headers.set('X-Requested-With', applicationSettings.apiRequestedWith);
		}
		if (options.noAuthHeader || hasAuthorization) return;

		if (
			startsWith(url, applicationSettings.recurringApiEndpoint) ||
			startsWith(url, applicationSettings.keyManagementEndpoint) ||
			startsWith(url, applicationSettings.logoManagementEndpoint) ||
			startsWith(url, applicationSettings.paymentEngineSettingsApiEndpoint) ||
			startsWith(url, applicationSettings.merchantRegistrationEndpoint) ||
			startsWith(url, applicationSettings.messagingApiEndpoint) ||
			startsWith(url, applicationSettings.statementEndpoint) ||
			startsWith(url, applicationSettings.paymentSiteApiEndpoint) ||
			startsWith(url, applicationSettings.kvaasEndpoint) ||
			startsWith(url, applicationSettings.settingsEndpoint) ||
			startsWith(url, applicationSettings.apiEndpoint) ||
			startsWith(url, applicationSettings.loginEndpoint) ||
			startsWith(url, updateApiEndpoint(null))
		) {
			options.headers.set('Authorization', await currentCognitoToken());
			const hasMerchantId = options.headers && !!options.headers.get('x-merchant-id');
			if (!hasMerchantId) {
				const principal = principalService.get();
				if (!options.allowPublic && (!principal || !principal.id)) {
					principalService.clear();
					window.location.reload();
				}
				const mid = get(principal, 'idInfo.xMerchantID');
				options.headers.set('x-merchant-id', mid);
			}
		}
	}

	async initializeBody(url, content, allowPublic = false) {
		if (startsWith(toLower(content.xCommand), 'report')) {
			content.xSupports64bitrefnum = true;
		}
		if (startsWith(url, updateApiEndpoint(null)) || startsWith(url, applicationSettings.apiEndpoint)) {
			if (!content.xUserName && !allowPublic && principalService.username) {
				content.xUserName = principalService.username;
			}
			content.xVersion = applicationSettings.apiVersion;
			content.xSoftwareName = SoftwareSettings.name;
			content.xSoftwareVersion = SoftwareSettings.version;
		} else if (
			startsWith(url, applicationSettings.recurringApiEndpoint) ||
			startsWith(url, applicationSettings.kvaasEndpoint) ||
			startsWith(url, applicationSettings.logoManagementEndpoint)
		) {
			content.softwareName = SoftwareSettings.name;
			content.softwareVersion = SoftwareSettings.version;
		} else if (startsWith(url, applicationSettings.keyManagementEndpoint)) {
			content.settingsversion = applicationSettings.keyManagementApiVersion;
		}
		return content;
	}

	serialize(content) {
		if (!content) {
			return;
		}

		const data = [];

		for (let key in content) {
			if (has(content, key)) {
				if (content[key] === undefined) {
					continue;
				}
				data.push(encodeURIComponent(key) + '=' + encodeURIComponent(content[key]));
			}
		}

		return data.join('&');
	}

	deserialize(content, fixBatchData) {
		let data = {};
		content.replace(/\+/g, ' ').replace(/([^=&]+)=([^&]*)/g, function(m, key, value) {
			data[decodeURIComponent(key)] = decodeURIComponent(value);
		});

		if (has(data, 'xReportData')) {
			data.xReportData = fixBatchData ? fixJsonFormat(data.xReportData) : JSON.parse(data.xReportData);
		}
		return data;
	}
}

const httpService = new HttpService(principalService);

export default httpService;
