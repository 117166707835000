import { toLower } from 'lodash';

export const userRoles = [
	{
		label: 'Admin',
		value: 'admin',
	},
	{
		label: 'Advanced User',
		value: 'advanced',
	},
	{
		label: 'Standard User',
		value: 'standard',
	},
	{
		label: 'View Only',
		value: 'viewonly',
	},
	{
		label: 'Save Only',
		value: 'saveonly',
	},
	{
		label: 'Sale Only',
		value: 'saleonly',
	},
	{
		label: 'Auth Only',
		value: 'authonly',
	},
	{
		label: 'Custom User',
		value: 'custom',
	},
];

export function mapUserRoles(item) {
	let itemIndex = userRoles.findIndex(i => i.value === toLower(item));
	if (itemIndex !== -1) {
		return userRoles[itemIndex];
	}
}
