import { identity, toLower, each } from 'lodash';

const mapToBoolLax = value => {
	const lowered = toLower(value);
	if (lowered === 'true') {
		return true;
	}
	if (lowered === 'false') {
		return false;
	}
	return value;
};

const mapToBool = value => mapToBoolLax(value) === true;

const transactionFields = [
	'date',
	'amount',
	'clearedAmount',
	'clearedCount',
	'tax',
	'serviceFee',
	'processingFee',
	'netSale',
	'digitalWalletType',
	'currency',
	'firstName',
	'lastName',
	'cardholderName',
	'softwareName',
	'cardType',
	'accountNumber',
	'result',
	'command',
	'achStatus',
	'company',
	'address',
	'city',
	'state',
	'zip',
	'country',
	'billPhone',
	'shipFirstName',
	'shipLastName',
	'shipCompany',
	'shipAddress',
	'shipCity',
	'shipState',
	'shipZip',
	'shipCountry',
	'shipPhoneNumber',
	'description',
	'email',
	'paymentMethod',
	'batch',
	'invoice',
	'cvvResult',
	'authCode',
	'orderId',
	'poNumber',
	'avs',
	'avsStreet',
	'avsZip',
	'nameVerification',
	'emailVerification',
	'phoneVerification',
	'sourceKey',
	'terminalNumber',
	'userName',
	'ipAddress',
	'entryMethod',
	'cardSource',
	'serialNumber',
	'reviewed',
	'computername',
	'routing',
	'engine',
	'processor',
	'isemv',
	'isswiped',
	'token',
	'custom1',
	'custom2',
	'custom3',
	'custom4',
	'custom5',
	'custom6',
	'custom7',
	'custom8',
	'custom9',
	'custom10',
	'additionalRefnum',
	'additionalAuthAmount',
];
const disputeFields = [
	'transactionDate',
	'disputeTransactionAmount',
	'currency',
	'cardholderName',
	'cardType',
	'accountNumber',
];
const customerFields = [
	'customerNumber',
	'company',
	'firstName',
	'lastName',
	'recurringSchedule',
	'street',
	'city',
	'state',
	'zip',
	'phoneNumber',
	'mobilePhoneNumber',
	'email',
	'customerNotes',
	'custom1',
	'custom2',
	'custom3',
	'custom4',
	'custom5',
	'custom6',
	'custom7',
	'custom8',
	'custom9',
	'custom10',
	'custom11',
	'custom12',
	'custom13',
	'custom14',
	'custom15',
	'custom16',
	'custom17',
	'custom18',
	'custom19',
	'custom20',
];
const recurringFields = [
	'scheduleName',
	'customer',
	'company',
	'customerNumber',
	'email',
	'dateCreated',
	'startDate',
	'nextBillingDate',
	'endDate',
	'frequency',
	'remainingCharges',
	'amount',
	'remainingBalance',
	'totalCharges',
	'description',
	'status',
	'active',
];
const templatesFields = ['description', 'amount', 'frequency'];

export const initialPageOptions = [
	{
		key: 'dashboard',
		path: '/',
		title: 'Dashboard',
	},
	{
		key: 'newTransactions',
		path: '/transactions',
		title: 'New Transaction',
		state: {
			openNewTransaction: true,
		},
	},
	{
		key: 'transactions',
		path: '/transactions',
		title: 'Transactions',
	},
	{
		key: 'transfers',
		path: '/transfers',
		title: 'Transfers',
	},
	{
		key: 'quickReports',
		path: '/reports',
		title: 'Quick Reports',
	},
	{
		key: 'giftReport',
		path: '/giftreport',
		title: 'Gift Report',
	},
	{
		key: 'fraudwatch',
		path: '/fraudwatch',
		title: 'FraudWatch Report',
	},
	{
		key: 'batches',
		path: '/batches',
		title: 'Batches',
	},
	{
		key: 'newCustomer',
		path: '/customers',
		title: 'New Customer',
		state: {
			openNewCustomer: true,
		},
	},
	{
		key: 'customers',
		path: '/customers',
		title: 'Customers',
	},
	{
		key: 'recurringSchedules',
		path: '/recurring-schedules',
		title: 'Recurring Schedules',
	},
	{
		key: 'sendPaymentRequest',
		path: '/send-payment-request',
		title: 'Send Payment Request',
	},
	{
		key: 'userSettings',
		path: '/user-settings',
		title: 'User Settings',
	},
	{
		key: 'accountManagement',
		path: '/account-settings',
		title: 'Portal Account Settings',
	},
];

export const kvaasResources = {
	customerRequiredFields: {
		primaryKey: 'customerRequiredFields',
		mapper: mapToBool,
		defaultData: {},
	},
	customerDisplayLabels: {
		primaryKey: 'customerDisplayLabels',
		mapper: identity,
		defaultData: {},
	},
	customerHiddenFields: {
		primaryKey: 'customerHiddenFields',
		mapper: mapToBool,
		defaultData: {
			custom4: true,
			custom5: true,
			custom6: true,
			custom7: true,
			custom8: true,
			custom9: true,
			custom10: true,
			custom11: true,
			custom12: true,
			custom13: true,
			custom14: true,
			custom15: true,
			custom16: true,
			custom17: true,
			custom18: true,
			custom19: true,
			recurringCustom4: true,
			recurringCustom5: true,
			recurringCustom6: true,
			recurringCustom7: true,
			recurringCustom8: true,
			recurringCustom9: true,
			recurringCustom10: true,
			recurringCustom11: true,
			recurringCustom12: true,
			recurringCustom13: true,
			recurringCustom14: true,
			recurringCustom15: true,
			recurringCustom16: true,
			recurringCustom17: true,
			recurringCustom18: true,
			recurringCustom19: true,
		},
	},
	transactionRequiredFields: {
		primaryKey: 'transactionRequiredFields',
		mapper: mapToBool,
		defaultData: {},
	},
	transactionDisplayLabels: {
		primaryKey: 'transactionDisplayLabels',
		mapper: identity,
		defaultData: {},
	},
	transactionHiddenFields: {
		primaryKey: 'transactionHiddenFields',
		mapper: mapToBool,
		defaultData: {
			custom4: true,
			custom5: true,
			custom6: true,
			custom7: true,
			custom8: true,
			custom9: true,
			custom10: true,
			custom11: true,
			custom12: true,
			custom13: true,
			custom14: true,
			custom15: true,
			custom16: true,
			custom17: true,
			custom18: true,
			custom19: true,
		},
	},
	transactionReportDefaultColumns: {
		primaryKey: 'transactionReportDefaultColumns',
		mapper: mapToBool,
		defaultData: {
			date: true,
			amount: true,
			cardholderName: true,
			accountNumber: true,
			result: true,
			command: true,
			description: true,
		},
		userSetting: true,
	},
	transactionReportHiddenFields: {
		primaryKey: 'transactionReportHiddenFields',
		mapper: mapToBool,
		defaultData: {
			custom5: true,
			custom6: true,
			custom7: true,
			custom8: true,
			custom9: true,
			custom10: true,
			custom11: true,
			custom12: true,
			custom13: true,
			custom14: true,
			custom15: true,
			custom16: true,
			custom17: true,
			custom18: true,
			custom19: true,
		},
		userSetting: true,
	},
	transactionReportOrder: {
		primaryKey: 'transactionReportOrder',
		mapper: parseInt,
		defaultData: {},
		userSetting: true,
	},
	transactionReportDefaultValues: {
		primaryKey: 'transactionReportDefaultValues',
		mapper: mapToBoolLax,
		defaultData: {
			date: '7',
			limit: '20',
		},
		userSetting: true,
	},
	transfersReportOrder: {
		primaryKey: 'transfersReportOrder',
		mapper: parseInt,
		defaultData: {},
		userSetting: true,
	},
	transfersReportDefaultValues: {
		primaryKey: 'transfersReportDefaultValues',
		mapper: mapToBoolLax,
		defaultData: {
			date: '7',
			limit: '20',
		},
		userSetting: true,
	},
	disputeReportDefaultColumns: {
		primaryKey: 'disputeReportDefaultColumns',
		mapper: mapToBool,
		defaultData: {
			transactionDate: true,
			disputeTransactionAmount: true,
			cardholderName: true,
			accountNumber: true,
		},
		userSetting: true,
	},
	disputeReportOrder: {
		primaryKey: 'disputeReportOrder',
		mapper: parseInt,
		defaultData: {},
		userSetting: true,
	},
	disputeReportDefaultValues: {
		primaryKey: 'disputeReportDefaultValues',
		mapper: identity,
		defaultData: {
			date: '7',
			limit: '20',
		},
		userSetting: true,
	},
	recurringReportDefaultColumns: {
		primaryKey: 'recurringReportDefaultColumns',
		mapper: mapToBool,
		defaultData: {
			scheduleName: true,
			customer: true,
			dateCreated: true,
			nextBillingDate: true,
			frequency: true,
			amount: true,
			status: true,
			active: true,
		},
		userSetting: true,
	},
	recurringReportHiddenFields: {
		primaryKey: 'recurringReportHiddenFields',
		mapper: mapToBool,
		defaultData: {
			custom4: true,
			custom5: true,
			custom6: true,
			custom7: true,
			custom8: true,
			custom9: true,
			custom10: true,
			custom11: true,
			custom12: true,
			custom13: true,
			custom14: true,
			custom15: true,
			custom16: true,
			custom17: true,
			custom18: true,
			custom19: true,
			custom20: true,
		},
		userSetting: true,
	},
	recurringReportDefaultValues: {
		primaryKey: 'recurringReportDefaultValues',
		mapper: mapToBoolLax,
		defaultData: {},
		userSetting: true,
	},
	recurringTemplatesReportDefaultColumns: {
		primaryKey: 'recurringTemplatesReportDefaultColumns',
		mapper: mapToBool,
		defaultData: {
			description: true,
			amount: true,
			frequency: true,
		},
		userSetting: true,
	},
	recurringTemplates: {
		primaryKey: 'recurringTemplates',
		mapper: identity,
		defaultData: {},
	},
	recurringReportOrder: {
		primaryKey: 'recurringReportOrder',
		mapper: parseInt,
		defaultData: {},
		userSetting: true,
	},
	recurringTemplatesReportOrder: {
		primaryKey: 'recurringTemplatesReportOrder',
		mapper: parseInt,
		defaultData: {},
		userSetting: true,
	},
	recurringSchedules: {
		primaryKey: 'recurringSchedules',
		mapper: identity,
		defaultData: {
			afterMaxRetriesAction: 'ContinueNextInterval',
		},
	},
	customerReportDefaultColumns: {
		primaryKey: 'customerReportDefaultColumns',
		mapper: mapToBool,
		defaultData: {
			company: true,
			firstName: true,
			lastName: true,
			recurringSchedule: true,
			phoneNumber: true,
			email: true,
		},
		userSetting: true,
	},
	bulkChargeReportDefaultColumns: {
		primaryKey: 'bulkChargeReportDefaultColumns',
		mapper: mapToBool,
		defaultData: {
			poNumber: true,
			invoice: true,
			description: true,
			email: true,
		},
		userSetting: true,
	},
	fraudwatchReportDefaultColumns: {
		primaryKey: 'fraudwatchReportDefaultColumns',
		mapper: mapToBool,
		defaultData: {
			xRefNum: true,
			xEnteredDate: true,
			xCurrency: true,
			xAmount: true,
			xCardType: true,
			xMaskedCardNumber: true,
			xName: true,
			xInvoice: true,
			xStatus: true,
			xStatusReason: true,
		},
		userSetting: true,
	},
	fraudwatchReportDefaulOrder: {
		primaryKey: 'fraudwatchReportDefaulOrder',
		mapper: parseInt,
		defaultData: {},
		userSetting: true,
	},
	customerReportHiddenFields: {
		primaryKey: 'customerReportHiddenFields',
		mapper: mapToBool,
		defaultData: {
			custom4: true,
			custom5: true,
			custom6: true,
			custom7: true,
			custom8: true,
			custom9: true,
			custom10: true,
			custom11: true,
			custom12: true,
			custom13: true,
			custom14: true,
			custom15: true,
			custom16: true,
			custom17: true,
			custom18: true,
			custom19: true,
			custom20: true,
		},
		userSetting: true,
	},
	customerReportOrder: {
		primaryKey: 'customerReportOrder',
		mapper: parseInt,
		defaultData: {},
		userSetting: true,
	},
	portalFlags: {
		primaryKey: 'portalFlags',
		mapper: mapToBool,
		defaultData: {
			expandNewTransactionPopup: true,
		},
	},
	paymentSiteSettings: {
		primaryKey: 'paymentSiteSettings',
		mapper: identity,
		defaultData: {},
	},

	userSettings: {
		primaryKey: 'userSettings',
		mapper: mapToBoolLax,
		defaultData: {
			timezone: 'America/New_York',
			initialPage: 'dashboard',
			expandAdvanceFilterByDefault: false,
			defaultTxnTypeOnNewTxn: '',
			expandNewCustomerPopup: false,
		},
		userSetting: true,
	},
	dashboardSettings: {
		primaryKey: 'dashboardSettings',
		mapper: identity,
		defaultData: {
			date: 'yesterday',
		},
		userSetting: true,
	},
	customReports: {
		primaryKey: `customQuickReports`,
		mapper: identity,
		defaultData: {},
	},
	reportFilters: primaryKey => ({
		primaryKey,
		tableName: `${AppBuildEnvironment}customQuickReportFilters`,
		mapper: identity,
		defaultData: [],
	}),
	convenienceFees: {
		primaryKey: 'convenienceFees',
		mapper: mapToBoolLax,
		defaultData: {
			enabledByDefault: true,
		},
	},
	salesTax: {
		primaryKey: 'salesTax',
		mapper: mapToBoolLax,
		defaultData: {},
	},
	notifications: {
		primaryKey: 'notifications',
		mapper: mapToBoolLax,
		defaultData: {},
		userSetting: true,
	},
	walkthroughSettings: {
		primaryKey: 'walkthroughSettings',
		mapper: identity,
		defaultData: {},
		userSetting: true,
	},
	collapsedSettingsMessages: {
		primaryKey: 'collapsedSettingsMessages',
		mapper: mapToBool,
		defaultData: {},
	},
	collapsedMainSettingsMessage: {
		primaryKey: 'collapsedMainSettingsMessage',
		mapper: mapToBool,
		defaultData: {},
	},
	logoManagement: {
		primaryKey: 'logoManagement',
		mapper: mapToBool,
		defaultData: {
			coBrandPortal: true,
			includeCoBrandLogoOnReceipts: false,
			includeCoBrandLogoOnPymntReqEmails: false,
		},
	},
	sendPaymentRequestManagement: {
		primaryKey: 'sendPaymentRequestManagement',
		mapper: identity,
		defaultData: {
			subject: 'A new [invoice] from [company]',
			content: `Dear [name],\n\nThis is a reminder that your [company] payment is due. Click [link] to make your payment. Thank you for your business!\n\nSincerely,\n[company] Billing Department`,
			fromEmail: '',
			fromCompany: '',
		},
	},
	mfaSettings: {
		primaryKey: 'mfaSettings',
		mapper: mapToBool,
		defaultData: {},
		userSetting: true,
	},
};

each(transactionFields, (field, idx) => (kvaasResources.transactionReportOrder.defaultData[field] = idx + 1));
each(disputeFields, (field, idx) => (kvaasResources.disputeReportOrder.defaultData[field] = idx + 1));
each(customerFields, (field, idx) => (kvaasResources.customerReportOrder.defaultData[field] = idx + 1));
each(recurringFields, (field, idx) => (kvaasResources.recurringReportOrder.defaultData[field] = idx + 1));
each(templatesFields, (field, idx) => (kvaasResources.recurringTemplatesReportOrder.defaultData[field] = idx + 1));
