import React from 'react';
import PropTypes from 'prop-types';

const expandedClassName = (isDetails, isExpandable) => {
	return `icon icon--micro spc--right--tny icon--arrow icon--arrow--right--${isExpandable ? 'primary' : 'grey'} ${
		isDetails ? 'is-expanded' : ''
	}`;
};

const ExpandableComponent = ({ value, row: { isExpandable, isDetails, customerId }, dependentValues }) => {
	const isInputTypeRadio = dependentValues && dependentValues.isInputTypeRadio;
	return isInputTypeRadio ? (
		<span className="spc--top--nano">
			<input type="radio" className="input--radio" id={customerId} value={!!isDetails} checked={!!isDetails} />
			<label>{value}</label>
		</span>
	) : (
		<span>
			<i className={expandedClassName(isDetails, isExpandable)}></i> {value}
		</span>
	);
};

ExpandableComponent.propTypes = {
	value: PropTypes.string.isRequired,
	row: PropTypes.object,
	dependentValues: PropTypes.object,
};

export default ExpandableComponent;
