import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Data } from 'react-data-grid-addons';
import { cloneDeep, map, each, find } from 'lodash';

import { LinkExistingCustomerColumns as Columns } from 'common/components/customers/column-filter/linkExistingCustomerColumns';
import { customersFilter as Filter, compileFilter } from 'common/components/customers/filter/customersFilter';
import { ZebraRenderer } from 'common/components/row';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { customerService } from 'common/services';
import PopupGrid from 'common/components/grid/PopupGrid';

const requestKeys = {
	FETCH: 'fetch',
	SAVE: 'save',
};

class LinkExistingCustomerForm extends Component {
	constructor() {
		super();

		this.gridRef = createRef();

		this.state = {
			...this.addFiltersToState(cloneDeep(this.initialState)),
			columns: Columns,
			defaultColumns: cloneDeep(Columns),
			loadMoreLimit: 50,
			fields: this.fields,
		};
		this.components = {
			rowRenderer: ZebraRenderer,
		};
	}

	get initialState() {
		return {
			data: null,
			filteredRows: [],
			expanded: {},
			fetchingData: true,
			fetchingAdditionalData: false,
			lastApiRefNum: null,
			initiallyOpenNewCustomer: false,
		};
	}

	fetchData = async (filters, _, maxRecords = 0) => {
		this.setState({
			fetchingData: true,
			data: null,
			filteredRows: [],
			expanded: {},
			lastApiRefNum: null,
		});
		let lastApiRefNum = null;

		try {
			const filter = await this.props.makePendingRequest(compileFilter(filters), requestKeys.FETCH);
			const data = await this.props.makePendingRequest(
				customerService.filterCustomersAll(false, filter),
				requestKeys.FETCH
			);
			lastApiRefNum = data.refNum;
			if (data && data.xReportData) {
				data.xReportData = map(data.xReportData, this.mapRow);
			}

			if (maxRecords === 0) {
				data.xRecordsReturned = data.xReportData.length;
				data.xReportingMaxCustomers = data.xReportData.length + 1; // +1 = quick fix
			}

			const formattedColumns = this.formatColumns(this.state.columns, cloneDeep(filter));
			this.mapData(data);
			const filteredRows =
				data && data.xReportData
					? Data.Selectors.getRows({
							rows: data.xReportData,
							filters: this.state.inlineFilters,
					  })
					: [];
			if (this.gridRef.current) {
				this.gridRef.current.scrollTo({ top: 0, left: 0 });
			}

			this.setState(
				{
					data,
					originalData: cloneDeep(data),
					filteredRows,
					fetchingData: false,
					columns: formattedColumns,
					lastApiRefNum: lastApiRefNum,
				},
				() => {
					if (this.gridRef.current) {
						this.gridRef.current.handleInitialSort();
					}
				}
			);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.setState({
					fetchingData: false,
				});
			}
		}
	};

	refetchData = () => {
		this.fetchData(this.state.activeFilters);
	};

	handleChange = changes => {
		const newState = {};
		each(changes, ({ key, value }) => {
			if (key === 'data' || key === 'inlineFilters') {
				let filters, data;
				if (key === 'data') {
					filters = this.state.inlineFilters;
					data = value;
				} else {
					filters = value;
					data = this.state.data;
				}
				newState.filteredRows =
					data && data.xReportData
						? Data.Selectors.getRows({
								rows: data.xReportData,
								filters,
						  })
						: [];
			}
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	mapRow = row => ({
		...row,
		isExpandable: true,
		gridRef: this.gridRef.current ? this.gridRef.current : null,
		handleError: this.props.handleError,
	});

	mapData = data => {
		let i = 0;
		if (data && data.xReportData && data.xReportData.length > 0) {
			each(data.xReportData, item => {
				item.gridRowNumber = i;
				item.index = i + 1;
				i++;
			});
		}
	};

	formatColumns = (columns, appliedFilter = null) => {
		if (appliedFilter) {
			for (let prop in appliedFilter) {
				if (appliedFilter.hasOwnProperty(prop)) {
					let column = find(columns, i => {
						return i.key.toLowerCase() === prop.toLowerCase() && !i.visible;
					});

					if (column) {
						column.visible = true;
					}
				}
			}
		}
		return columns;
	};

	mapCellArgs = (rowId, row, _, openOnly = false, focusSchedule = false) => {
		if (rowId < 0) {
			return; // header row
		}
		const args = {
			rowData: row,
			expandArgs: {
				children: [
					{
						isDetails: true,
						row: row,
					},
				],
			},
			openOnly: openOnly,
			expandedRowProps: {
				focusSchedule,
			},
		};
		return args;
	};

	addFiltersToState = state => {
		const filters = Filter;
		return {
			...state,
			filters: filters,
			activeFilters: cloneDeep(filters),
			inlineFilters: {},
		};
	};

	render() {
		const {
			fetchingData,
			fetchingAdditionalData,
			filteredRows,
			columns,
			data,
			inlineFilters,
			expanded,
			filters,
			activeFilters,
			lastApiRefNum,
			defaultColumns,
		} = this.state;
		const { selectCustomer, popupRef } = this.props;

		return (
			<PopupGrid
				emptyMessage="You should change your filter options"
				fetchingData={fetchingData}
				fetchingAdditionalData={fetchingAdditionalData}
				filteredRows={filteredRows}
				columns={columns}
				data={data}
				inlineFilters={inlineFilters}
				components={this.components}
				onChange={this.handleChange}
				expanded={expanded}
				type="customers"
				filters={filters}
				activeFilters={activeFilters}
				enableFilters={true}
				fetchData={this.refetchData}
				lastApiRefNum={lastApiRefNum}
				mapCellArgs={this.mapCellArgs}
				showPrintDropdown={false}
				filterColumns={true}
				defaultColumns={defaultColumns}
				ref={this.gridRef}
				initialFetch={true}
				columnFilterType="/user-settings/customers"
				kvaasResourceType="customer"
				useInlineFilters={true}
				showGridHeader={false}
				showHeader={false}
				showPanel={false}
				isExpandable={true}
				selectCustomer={selectCustomer}
				popupRef={popupRef}
			/>
		);
	}
}

LinkExistingCustomerForm.propTypes = {
	makePendingRequest: PropTypes.func.isRequired,
	handleError: PropTypes.func.isRequired,
	selectCustomer: PropTypes.func.isRequired,
	popupRef: PropTypes.any,
};

export default withError(withCancelable(LinkExistingCustomerForm));
