import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { principalService } from 'common/services';
import PrivacyPolicyFooter from 'common/components/footer/PrivacyPolicyFooter';
import ConfirmPasswordBody from './components/ConfirmPasswordBody';
import { get } from 'lodash';

class ConfirmNewPasswordComponent extends Component {
	constructor(props) {
		super(props);

		const username = get(props, 'location.state.username', '');

		this.state = {
			username: username,
			password: '',
			password2: '',
			code: '',
			errorMessage: null,
		};
	}

	componentWillMount = () => {
		if (principalService.get()) {
			this.redirect();
		} else if (!this.state.username) this.props.history.push('/login');
	};

	redirect = () => {
		const { history, location } = this.props;
		let redirectUrl = '/';

		if (location.state && location.state.returnUrl) {
			redirectUrl = location.state.returnUrl;
		}

		history.push(redirectUrl);
	};

	setComponentState = newState => {
		this.setState(newState);
	};

	render = () => {
		const { username, password, password2, code, errorMessage } = this.state;

		return (
			<div>
				<ConfirmPasswordBody
					username={username}
					password={password}
					password2={password2}
					code={code}
					errorMessage={errorMessage}
					setComponentState={this.setComponentState}
					{...this.props}
				/>
				<PrivacyPolicyFooter />
			</div>
		);
	};
}

ConfirmNewPasswordComponent.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
};

export default ConfirmNewPasswordComponent;
