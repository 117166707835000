import { padStart, times, cloneDeep } from 'lodash';

import { principalService } from '../../Common/services';

export const sortColumns = [
	createColumn('Default', 'default', 'xEnteredDate'),
	createColumn('Date', 'date', 'xEnteredDate'),
	createColumn('Reference Number', 'referenceNumber', 'xRefNum'),
	createColumn('Cardholder Name', 'cardholderName', 'xName'),
	createColumn('Invoice', 'invoice', 'xInvoice'),
	createColumn('Amount', 'amount', 'amountToUse'),
	createColumn('Cleared Amount', 'clearedAmount', 'xClearedAmount'),
	createColumn('Cleared Count', 'clearedCount', 'xClearedCount'),
	createColumn('Source Key', 'sourceKey', 'xSourceKey'),
	...times(19, i => {
		const oneBasedIndex = padStart(i + 1, 2, 0);
		return createColumn(`Custom${oneBasedIndex}`, `custom${i + 1}`, `xCustom${oneBasedIndex}`);
	}),
	createColumn('Batch Number', 'batchNumber', 'xResponseBatch'),
	createColumn('Currency', 'currency', 'currency'),
	createColumn('Void Status', 'voidStatus', 'xVoid', false, false),
	createColumn('Tax', 'tax', 'xTax'),
	createColumn('Service fee', 'serviceFee', 'xServiceFee'),
	createColumn('Processing fee', 'processingFee', 'xProcessingFee'),
	createColumn('Net sale', 'netSale', 'netSale'),
	createColumn('Digital Wallet', 'digitalWalletType', 'xDigitalWalletType'),
	createColumn('First N­ame', 'firstName', 'xBillFirstName'),
	createColumn('Last N­ame', 'lastName', 'xBillLastName'),
	createColumn('Software Name', 'softwareName', 'xSoftwareName'),
	createColumn('Card type', 'cardType', 'xCardType'),
	createColumn('Account Number', 'accountNumber', 'xMaskedCardNumber'),
	createColumn('Result', 'result', 'xResponseResult'),
	createColumn('Error Details', 'errorDetails', 'xResponseError'),
	createColumn('Command', 'command', 'xCommand'),
	createColumn('ACH Status', 'achStatus', 'achStatus'),
	createColumn('ACH Return Fee', 'achReturnFee', 'xAchReturnFee'),
	createColumn('Company', 'company', 'xBillCompany'),
	createColumn('Address', 'address', 'xBillStreet'),
	createColumn('City', 'city', 'xBillCity'),
	createColumn('State', 'state', 'xBillState'),
	createColumn('Zip', 'zip', 'xBillZip'),
	createColumn('Bill Phone', 'billPhone', 'xBillPhone'),
	createColumn('Description', 'description', 'xDescription'),
	createColumn('Email', 'email', 'xEmail'),
	createColumn('Payment method', 'paymentMethod', 'paymentMethod'),
	createColumn('CVV Result', 'cvvResult', 'CVV'),
	createColumn('Auth code', 'authCode', 'xResponseAuthCode'),
	createColumn('OrderID', 'orderID', 'xOrderID'),
	createColumn('PO Number', 'poNumber', 'xPONum'),
	createColumn('AVS', 'avs', 'xResponseAVSCode'),
	createColumn('AVS Street', 'avsStreet', 'xStreet'),
	createColumn('AVS Zip', 'avsZip', 'xZip'),
	createColumn('AMEX Name Verification', 'nameVerification', 'xResponseNameVerificationCode'),
	createColumn('AMEX Email Verification', 'emailVerification', 'xResponseEmailVerificationCode'),
	createColumn('AMEX Phone Verification', 'phoneVerification', 'xResponsePhoneVerificationCode'),
	createColumn('Terminal number', 'terminalNumber', 'xTerminalNum'),
	createColumn('User Name', 'userName', 'xUserName'),
	createColumn('Computer Name', 'computername', 'xComputerName', true),
	createColumn('Token', 'token', 'xToken'),
	createColumn('Is EMV', 'isemv', 'xIsEMV', true),
	createColumn('Is Swiped', 'isswiped', 'xIsSwiped', true),
	createColumn('Engine', 'engine', 'xEngine', true),
	createColumn('Processor', 'processor', 'xProcessor', true),
	createColumn('Routing', 'routing', 'xRouting', true),
	createColumn('IP address', 'ipAddress', 'xClientIP', false, false),
	createColumn('Entry Method', 'entryMethod', 'xEntryMethod'),
	createColumn('Card Source', 'cardSource', 'xCardSource'),
	createColumn('Serial Number', 'serialNumber', 'xSerialNumber'),
	createColumn('3D Secure Result', 'reviewed', 'xReviewed'),
	createColumn('Additional Refnum', 'additionalRefnum', 'xAdditionalRefnum'),
	createColumn('Additional Auth Amount', 'additionalAuthAmount', 'xAdditionalAuthAmount'),
];

function createColumn(label, key, xColumn, isAdminColumn = false, visible = true) {
	return { label, key, xColumn, isAdminColumn, visible };
}

const principal = principalService.get();
export const TransactionsSortBy = cloneDeep(sortColumns).filter(c => (principal.isAdmin ? true : !c.isAdminColumn));

export const TransactionsSortOrder = [
	{
		label: 'Ascending',
		direction: 'asc',
	},
	{
		label: 'Descending',
		direction: 'desc',
	},
];
