import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty, find, trim, toLower, get, split } from 'lodash';

import { Modal } from 'common/components/modal/index';
import { withError } from 'common/components/error';
import { FormErrors } from 'common/utilities';
import IpInput from 'common/components/ip-input/ip-input';
import { ReactMultiEmail, isEmail } from 'common/components/react-multi-email';

const permissionkeys = {
	admin: 'admin',
	advanced: 'advanced',
	standard: 'standard',
	viewOnly: 'viewOnly',
	saveOnly: 'saveOnly',
	authonly: 'authonly',
	saleOnly: 'saleonly',
};
const keyTypes = {
	paymentsite: 'paymentsite',
	api: 'standard',
	ifields: 'ifields',
};

const descriptionKeys = {
	software: 'software',
	store: 'store',
	website: 'website',
	other: 'other',
};

const unhide = {
	display: 'block',
};
const hide = {
	display: 'none',
};

class AddEditKey extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.defaults,
			emails: [],
			showEmailInput: false,
		};
		this.errorsRef = null;
	}

	get permissions() {
		return [
			{
				label: 'Admin Role',
				key: permissionkeys.admin,
				description: 'Has permission to run all commands and pull reports.',
			},
			{
				label: 'Standard Role',
				key: permissionkeys.standard,
				description: (
					<Fragment>
						<p className="spc--bottom--tny">
							<span className="type--italic">Has permission to: </span>
							create and save new transactions and gift transactions; run reports for transactions, batches, and gifts;
							create new customers and recurring schedules.
						</p>
						<p>
							<span className="type--italic">Doesn’t have permission to: </span>issue refunds/credit, void transactions,
							or manage users.
						</p>
					</Fragment>
				),
			},
			{
				label: 'View Only Role',
				key: permissionkeys.viewOnly,
				description: (
					<Fragment>
						<p className="spc--bottom--tny">Only has permission to pull reports.</p>

						<p>
							For more details on the API calls associated with these permissions, see the{' '}
							<a href="https://kb.cardknox.com/api/" className="anchor anchor--primary">
								Cardknox API documentation
							</a>
							.
						</p>
					</Fragment>
				),
			},
			{
				label: 'Save Only Role',
				key: permissionkeys.saveOnly,
				description: 'Can only use the save command.',
			},
			{
				label: 'Auth only Role',
				key: permissionkeys.authonly,
				description: 'Can only run auth commands.',
			},
			{
				label: 'Sale only Role',
				key: permissionkeys.saleOnly,
				description: 'Can only run sales.',
			},
		];
	}
	get keyTypes() {
		const types = [
			{ key: keyTypes.api, label: 'API (Transaction Key)' },
			{ key: keyTypes.ifields, label: 'iFields (Token Key)' },
		];
		if (this.props.action === 'edit') {
			types.push({ key: keyTypes.paymentsite, label: 'Payment Site' });
		}
		return types;
	}
	get descriptions() {
		return [
			{ key: '', label: 'Please select' },
			{ key: descriptionKeys.software, label: 'Software' },
			{ key: descriptionKeys.store, label: 'Store' },
			{ key: descriptionKeys.website, label: 'Website' },
			{ key: descriptionKeys.other, label: 'Other' },
		];
	}

	get defaultSelectedDescription() {
		return '';
	}

	get defaultSelectedKeyType() {
		return this.keyTypes[0].key;
	}

	get defaultPermission() {
		return this.permissions[0].key;
	}
	get isPaymentSiteKey() {
		return get(this.props, 'data.keyType', '') === 'paymentsite';
	}
	get defaults() {
		return {
			selectedKeyType: this.defaultSelectedKeyType,
			selectedDescription: this.defaultSelectedDescription,
			description: this.defaultSelectedDescription,
			selectedPermission: this.defaultPermission,
			errors: {},
			keyInfo: {},
			whiteListedIPs: {
				allIPs: '',
				areAllIPsValid: true,
			},
			isModalOpen: false,
			isAdvanceSectionOpen: false,
		};
	}
	get isInvalidEmails() {
		const { emails, showEmailInput } = this.state;
		return showEmailInput && isEmpty(emails);
	}
	handleCloseModal = () => {
		const newState = this.defaults;
		newState.isModalOpen = false;
		this.setState(newState);
	};

	handleOpenModal = async () => {
		const { data, action } = this.props;
		const { whiteListedIPs } = this.state;
		const newState = {};
		if ((action === 'edit' || action === 'duplicate') && data && data.key) {
			newState.keyInfo = await this.props.fetchKey(data.key);
			if (newState.keyInfo) {
				const keyType = find(this.keyTypes, keyType => keyType.key === newState.keyInfo.keyType);
				const description = find(this.descriptions, description => description.key === newState.keyInfo.keyDescription);
				const role = find(this.permissions, permission => toLower(permission.key) === toLower(newState.keyInfo.role));
				const emailReceipts = get(newState.keyInfo.data, 'Email_Receipts', 'false');
				const emails = get(newState.keyInfo.data, 'Email_ReceiptsOverrideTo', '');
				if (emails) {
					newState.emails = split(emails, ',');
				}
				newState.showEmailInput = toLower(emailReceipts) === 'true';
				newState.selectedKeyType = keyType ? keyType.key : this.defaultSelectedKeyType;
				newState.whiteListedIPs = {
					...whiteListedIPs,
					allIPs: newState.keyInfo.whiteListServerIPs.replace(/,/g, '\n'),
				};

				if (newState.selectedKeyType === keyTypes.ifields) {
					newState.selectedDescription = this.defaultSelectedDescription;
					newState.description = newState.keyInfo.keyDescription;
					newState.selectedPermission = role ? role.key : permissionkeys.saveOnly;
				} else {
					newState.selectedDescription = description ? description.key : descriptionKeys.other;
					newState.description = description ? description.key : newState.keyInfo.keyDescription;
					newState.selectedPermission = role ? role.key : this.defaultPermission;
				}
				newState.isModalOpen = true;
			}
		} else {
			newState.isModalOpen = true;
		}

		this.setState(newState);
	};

	handleChange = ({ target: { name, value } }) => {
		const newState = { [name]: value };
		this.setState(newState);
	};

	onIPChange = event => {
		const { whiteListedIPs } = this.state;
		const {
			eventArgs: {
				target: { value },
			},
		} = event;
		whiteListedIPs.allIPs = value.replace(/ +?/g, '');
		this.setState({ whiteListedIPs });
	};

	onIPBlur = event => {
		const { areAllIPsValid } = event;
		const { whiteListedIPs } = this.state;
		whiteListedIPs.areAllIPsValid = areAllIPsValid;
		this.setState({ whiteListedIPs });
	};

	handleDescriptionChange = ({ target: { name, value } }) => {
		const newState = { [name]: value, description: value };
		if (value === 'other') newState.description = '';
		this.setState(newState);
	};
	getLabel = (email, index, removeEmail) => {
		return (
			<div data-tag key={index}>
				<span>{email}</span>
				<span data-tag-handle onClick={() => removeEmail(index)} onKeyDown={() => null}></span>
			</div>
		);
	};

	setEmail = ({ emails }) => {
		if (emails) {
			this.setState({ emails });
		}
	};
	handleShowEmailChange = () => {
		this.setState(prevState => ({
			showEmailInput: !prevState.showEmailInput,
		}));
	};

	handleKeyTypeChange = ({ target: { name, value } }) => {
		const newState = { [name]: value };
		if (value === keyTypes.ifields) {
			newState.description = '';
			newState.selectedPermission = permissionkeys.saveOnly;
		} else {
			newState.description = this.defaultSelectedDescription;
			newState.selectedDescription = this.defaultSelectedDescription;
			newState.selectedPermission = this.defaultPermission;
		}
		this.setState(newState);
	};

	createNewKey = () => {
		if (!this.checkFormValidity()) {
			return;
		}
		const {
			selectedKeyType,
			description,
			selectedPermission,
			whiteListedIPs: { allIPs },
		} = this.state;
		const data = {
			role: selectedPermission,
			keytype: selectedKeyType,
			keyDescription: trim(description),
			whiteListedIPs: allIPs.replace(/\n/g, ','),
		};

		this.props.createNewKey(data, this.handleCloseModal, this.errorHandler);
	};

	saveChanges = () => {
		if (!this.checkFormValidity()) {
			return;
		}
		const {
			keyInfo: { ddbRevision, index1 },
			whiteListedIPs: { allIPs },
		} = this.state;

		const { selectedKeyType, description, selectedPermission } = this.state;
		const data = {
			key2: this.props.row.key,
			role: selectedPermission,
			ddbrevision: ddbRevision,
			index1,
			keytype: selectedKeyType,
			keyDescription: trim(description),
			whiteListedIPs: allIPs.replace(/\n/g, ','),
		};
		if (this.isPaymentSiteKey) {
			data['Email_ReceiptsOverrideTo'] = this.state.emails.join(',');
			data['Email_Receipts'] = this.state.showEmailInput;
		}

		this.props.saveChanges(data, this.handleCloseModal, this.errorHandler);
	};

	checkFormValidity = () => {
		const errors = {};
		const {
			description,
			selectedDescription,
			whiteListedIPs: { areAllIPsValid },
			selectedKeyType,
		} = this.state;
		if (isEmpty(trim(description))) {
			errors.description =
				selectedKeyType === keyTypes.api && selectedDescription === descriptionKeys.other
					? 'Custom Description is required'
					: 'Description is required.';
		}
		if (!areAllIPsValid) {
			errors.whiteListedIPs = 'All IPs must be a valid IPv4 or IPv6 address.';
		}
		this.setState({ errors });
		const anyErrors = !isEmpty(errors);
		if (anyErrors) this.focusErrors();
		return !anyErrors;
	};

	errorHandler = error => {
		const errors = {};
		errors[error.key] = error.message;
		this.setState({ errors });
		this.focusErrors();
	};

	focusErrors = () => {
		if (this.errorsRef) this.errorsRef.scrollIntoView({ block: 'end', behavior: 'smooth' });
	};

	setErrorsRef = element => {
		this.errorsRef = element;
	};

	toggleAdvancedRoles = () => {
		this.setState({
			isAdvanceSectionOpened: !this.state.isAdvanceSectionOpened,
		});
	};
	renderSettings = () => {
		const { emails, showEmailInput } = this.state;

		return (
			<Fragment>
				<div>
					<div className="spc--bottom--sml--alt">
						<input
							className="input--check"
							type="checkbox"
							checked={showEmailInput}
							onChange={this.handleShowEmailChange}
							name="emailReceipt"
							id="emailReceipt"
						/>

						<label htmlFor="emailReceipt" className="type--color--text--medium type--wgt--medium">
							Enable Email Receipts
						</label>
					</div>
					{showEmailInput && (
						<div>
							<p className="type--xsml type--color--text--light spc--bottom--xsml">
								Multiple emails should be separated by commas
							</p>
							<ReactMultiEmail
								placeholder="Email"
								emails={emails}
								onChange={this.setEmail}
								validateEmail={isEmail}
								getLabel={this.getLabel}
							/>
						</div>
					)}
				</div>
				<div className="separator separator--grey1 spc--bottom--med"></div>
			</Fragment>
		);
	};

	renderPermissions = () => {
		const { selectedPermission, selectedKeyType, isAdvanceSectionOpened } = this.state;
		const advanceSectionFrom = 3;

		const permissions = this.permissions.splice(0, advanceSectionFrom - 1);
		const advancedSection = this.permissions.splice(advanceSectionFrom - 1, this.permissions.length);

		return (
			<div className="key-management__create-key__list">
				{map(permissions, permission => {
					return (
						<div className="item" key={permission.key}>
							<div className="spc--bottom--nano">
								<input
									type="radio"
									className="input input--radio"
									id={permission.key}
									name="selectedPermission"
									value={permission.key}
									checked={permission.key === selectedPermission}
									onChange={this.handleChange}
									disabled={selectedKeyType === keyTypes.ifields && permission.key !== permissionkeys.saveOnly}
								/>
								<label htmlFor={permission.key}>{permission.label}</label>
							</div>
							<p className="label" htmlFor={permission.key}>
								{permission.description}
							</p>
						</div>
					);
				})}
				<div className="spc--bottom--med">
					<a className="display--b anchor anchor--primary spc--bottom--sml--alt" onClick={this.toggleAdvancedRoles}>
						{isAdvanceSectionOpened ? '-' : '+'} Additional roles
					</a>
					<div style={isAdvanceSectionOpened ? unhide : hide}>
						{map(advancedSection, permission => {
							return (
								<div className="item" key={permission.key}>
									<div className="spc--bottom--nano">
										<input
											type="radio"
											className="input input--radio"
											id={permission.key}
											name="selectedPermission"
											value={permission.key}
											checked={permission.key === selectedPermission}
											onChange={this.handleChange}
											disabled={selectedKeyType === keyTypes.ifields && permission.key !== permissionkeys.saveOnly}
										/>
										<label htmlFor={permission.key}>{permission.label}</label>
									</div>
									<p className="label" htmlFor={permission.key}>
										{permission.description}
									</p>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	};

	renderAllowedIPs = () => {
		return (
			<div className="spc--bottom--sml">
				<div className="flex--primary spc--bottom--tny datatooltip--w--200">
					<h5 className="type--base type--wgt--medium">Whitelist IPs</h5>
					<div className="display--f spc--left--tny" data-tooltip="Add the IP addresses allowed to use this API key. If you leave this field blank, all IP addresses can use the key. For multiple IP addresses, type each IP address on a separate line.">
						<i className="icon icon--tiny icon--info cursor--pointer"></i>
					</div>
				</div>
				<IpInput
					id="whiteListedIPs"
					name="whiteListedIPs"
					value={this.state.whiteListedIPs.allIPs}
					onChange={this.onIPChange}
					onBlur={this.onIPBlur}
				></IpInput>
			</div>
		);
	};

	renderKeyType = () => {
		const { selectedKeyType } = this.state;
		const keyType = find(this.keyTypes, keyType => keyType.key === selectedKeyType);
		return (
			<div className="fakeinput fakeinput--med spc--bottom--sml">
				<span>{keyType.label}</span>
			</div>
		);
	};

	render() {
		const { children, className, shouldHideModal, isLoading, action } = this.props;
		const { isModalOpen, selectedKeyType, selectedDescription, description, errors } = this.state;
		const title = action === 'edit' ? 'Edit a Key' : 'Create a Key';

		const descriptionInput = (
			<div className="f-col f-col-sml-12 f-col-lrg-8">
				<input
					className="input input--med spc--bottom--sml"
					type="text"
					name="description"
					value={description}
					onChange={this.handleChange}
					placeholder="Write Your Description"
				/>
			</div>
		);
		return (
			<React.Fragment>
				<button onClick={this.handleOpenModal} type="button" className={className}>
					{children}
				</button>
				<Modal
					isOpen={isModalOpen}
					onClose={this.handleCloseModal}
					overlayClassName={shouldHideModal() ? 'popup__hide' : 'popup__overlay'}
				>
					<div className="user__popup">
						<div className="popup__header">
							<div className="popup__header__title">{title}</div>
						</div>
						<form className="popup__body">
							<div className="spc--bottom--med" ref={this.setErrorsRef}>
								{!isEmpty(errors) && <FormErrors errors={errors} />}
							</div>

							<div className="sepparator">
								<div className="f-row f--a--c spc--bottom--sml">
									<div className="f-col f-col-sml-12 f-col-lrg-4">
										<div className="flex--primary">
											<div className="label type--color--text--medium">
												Type
												<span className="label--required" data-tooltip="Required">*</span>
											</div>
											{selectedKeyType === keyTypes.ifields && (
												<div data-tooltip="iField key allows save only permission." className="display--f spc--left--tny datatooltip--key-ifield">
													<i className="icon icon--tiny icon--info cursor--pointer"></i>
												</div>
											)}
										</div>
									</div>
									<div className="f-col f-col-sml-12 f-col-lrg-8">
										{action === 'edit' ? (
											this.renderKeyType()
										) : (
											<select
												className="input input--med input--select"
												name="selectedKeyType"
												onChange={this.handleKeyTypeChange}
												value={selectedKeyType}
											>
												{map(this.keyTypes, keyType => (
													<option key={keyType.key} value={keyType.key}>
														{keyType.label}
													</option>
												))}
											</select>
										)}
									</div>
								</div>
								<div className="f-row f--a--c spc--bottom--sml">
									<div className="f-col f-col-sml-12 f-col-lrg-4">
										<label className="type--color--text--medium type--wgt--medium">
											Description
											<span className="label--required" data-tooltip="Required">
												*
											</span>
										</label>
									</div>

									{selectedKeyType === keyTypes.ifields ? (
										descriptionInput
									) : (
										<Fragment>
											<div className="f-col f-col-sml-12 f-col-lrg-8">
												<select
													className="input input--med input--select spc--bottom--sml"
													name="selectedDescription"
													onChange={this.handleDescriptionChange}
													value={selectedDescription}
												>
													{map(this.descriptions, description => (
														<option key={description.key} value={description.key}>
															{description.label}
														</option>
													))}
												</select>
											</div>
											{selectedDescription === 'other' && (
												<Fragment>
													<div className="f-col f-col-sml-12 f-col-lrg-4">
														<label className="type--color--text--medium type--wgt--medium">
															Custom Description
															<span className="label--required" data-tooltip="Required">
																*
															</span>
														</label>
													</div>
													{descriptionInput}
												</Fragment>
											)}
										</Fragment>
									)}
								</div>
							</div>
							<hr className="separator separator--grey1 spc--bottom--sml--alt"></hr>
							<h5 className="type--base type--wgt--medium spc--bottom--sml--alt">Key Permissions</h5>
							{this.renderPermissions()}
							{action === 'edit' && this.isPaymentSiteKey && (
								<Fragment>
									<div className="separator separator--grey1 spc--bottom--med"></div>
									<h5 className="type--base type--wgt--medium spc--bottom--sml--alt">Key Settings</h5>
									{this.renderSettings()}
								</Fragment>
							)}
							{this.renderAllowedIPs()}
						</form>
						<div className="popup__footer popup__footer--styled">
							<button
								type="button"
								tabIndex="-1"
								className="btn btn--med btn--ghost"
								onClick={this.handleCloseModal}
								disabled={isLoading}
							>
								Cancel
							</button>
							<button
								type="button"
								tabIndex="-1"
								className="btn btn--med btn--primary"
								disabled={isLoading || this.isInvalidEmails}
								onClick={action === 'edit' ? this.saveChanges : this.createNewKey}
							>
								{action === 'edit' ? 'Save Changes' : 'Create and View'}
							</button>
						</div>
					</div>
				</Modal>
			</React.Fragment>
		);
	}
}

AddEditKey.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	action: PropTypes.oneOf(['add', 'edit', 'duplicate']).isRequired,
	shouldHideModal: PropTypes.func,
	createNewKey: PropTypes.func,
	isLoading: PropTypes.bool.isRequired,
	data: PropTypes.object,
	fetchKey: PropTypes.func,
	saveChanges: PropTypes.func,
	row: PropTypes.object,
	key: PropTypes.string,
};

export default withError(AddEditKey);
