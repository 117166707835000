import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';

import { Modal } from './../../modal';
import { Notification } from './../../../components/notifications';
import { default as AddEditCustomerForm } from './add-edit-customer-form';
import { withError } from '../../error';
import { withCancelable } from '../../cancelable';
import { kvaasService, principalService } from '../../../services';
import { kvaasResources, invokeIfFunction } from '../../../utilities';
import { get } from 'lodash';
import { threeDS2ProcessTooltip } from './constants';

class AddEditCustomer extends Component {
	constructor(props) {
		super(props);
		this.formRef = createRef();
		this.notificationRef = createRef();
		this.customerFooterRef = createRef();
		this.principal = principalService.get();
		const has3DS2 = this.principal.idInfo.x3DSEnabled && this.principal.idInfo.x3DSVersion === '2';

		this.state = {
			has3DS2,
			isModalOpen: props.isModalOpen || false,
			advancedView: this.props.advancedView,
			advancedViewByDefault: props.advancedViewByDefault || false,
			hideCustomerModal: false,
			isProcessing: false,
		};
	}

	get hasIfields() {
		if (this.formRef.current && this.formRef.current.isPopup) {
			return false;
		}
		return !!document.querySelector('.newtransaction__iframe');
	}
	get permissions() {
		return get(this.principal, 'idInfo.permissions', {});
	}

	get allowCcAdjust() {
		const { existingTransaction } = this.props;
		return !get(existingTransaction, 'xCustom01') && get(this.permissions, 'allowCcAdjust', false);
	}
	componentDidMount = async () => {
		try {
			const [[userSettings]] = await this.props.makePendingRequest(
				Promise.all([kvaasService.get(kvaasResources.userSettings)]),
				'kvaas'
			);

			const newState = {
				isModalOpen: this.props.isModalOpen || this.state.isModalOpen,
				advancedViewByDefault:
					this.props.advancedViewByDefault || get(userSettings, 'data.expandNewCustomerPopup', false),
			};
			this.setState(newState);
		} catch (e) {
			this.props.handleError(e);
		}
	};

	setIsProcessing = isProcessing => {
		this.setState({ isProcessing });
	};

	addNotification = (notification, closeModal = true) => {
		const { shouldRefreshGrid, closeLinkTransactionPopup, refreshGrid } = this.props;
		const notificationRef = this.props.notificationRef || this.notificationRef;
		if (notificationRef.current) {
			invokeIfFunction(closeLinkTransactionPopup);
			notificationRef.current.addNotification({
				...notification,
				onClose: async () => {
					if (shouldRefreshGrid) {
						refreshGrid();
					}
				},
			});
		}
		if (closeModal) {
			this.handleCloseModal();
		}
	};

	handleOpenModal = async (advancedView = false) => {
		invokeIfFunction(this.props.hideLinkTransactionPopup);
		if (this.formRef.current) {
			await this.formRef.current.reset();
		}
		this.setState({
			isModalOpen: true,
			advancedView: advancedView || this.state.advancedViewByDefault || this.props.advancedView,
		});
	};

	handleCloseModal = () => {
		invokeIfFunction(this.props.closeModal);
		this.setState({
			isModalOpen: false,
			advancedView: false,
		});
	};

	handleSave = () => {
		if (this.formRef.current) {
			this.formRef.current.handleSave();
		}
	};

	toggleAdvancedView = () => {
		if (this.state.isProcessing) return;
		this.setState({ advancedView: !this.state.advancedView }, () => {
			if (this.formRef.current) {
				this.formRef.current.scrollToTop();
			}
		});
	};

	showHideCustomerModal = value => {
		this.setState({ hideCustomerModal: value });
	};

	render() {
		const {
			type,
			trigger,
			existingTransaction,
			template,
			isOpen,
			closeModal,
			isDuplicate,
			closeLinkTransactionPopup,
			hideLinkTransactionPopup,
		} = this.props;
		const { isModalOpen, advancedView, advancedViewByDefault, hideCustomerModal, isProcessing, has3DS2 } = this.state;
		const title = 'Add a new customer';

		return (
			<Fragment>
				<Notification ref={this.notificationRef} />
				{trigger ? trigger({ onClick: () => this.handleOpenModal() }) : null}
				<Modal
					isOpen={trigger ? isModalOpen : isOpen}
					onClose={
						trigger || closeLinkTransactionPopup
							? () => {
									this.handleCloseModal();
									invokeIfFunction(this.props.closeLinkTransactionPopup);
							  }
							: closeModal
					}
					shouldCloseOnOverlayClick={false}
					overlayClassName={hideCustomerModal ? '' : 'popup__overlay'}
					className={`popup__content${hideCustomerModal ? ' display--n' : ''}`}
				>
					<div className={`popup--new-customer${advancedView ? '-advanced' : ''}`}>
						<div className="popup__header">
							<div className="popup__header__title">{title}</div>
							{!this.allowCcAdjust && hideLinkTransactionPopup && (
								<p className="message message--primary">
									The transaction will not be linked to the new Customer
								</p>
							)}
						</div>
						<div className="popup__body" tabIndex="-1">
							<AddEditCustomerForm
								setIsProcessing={this.setIsProcessing}
								existingTransaction={existingTransaction}
								addNotification={this.addNotification}
								type={type}
								ref={this.formRef}
								template={template}
								advancedView={advancedView}
								advancedViewByDefault={advancedViewByDefault}
								toggleAdvancedView={this.toggleAdvancedView}
								customerFooterRef={this.customerFooterRef}
								showHideCustomerModal={this.showHideCustomerModal}
								isDuplicate={isDuplicate}
							/>
						</div>
						<div className={`popup__footer popup__footer--styled ${advancedView ? '' : 'separated'}`}>
							{!advancedView && (
								<div className="spc--top--tny">
									<a
										className="type--color--primary type--wgt--medium spc--top--sml--xalt"
										href="javascript:void(0)"
										onClick={this.toggleAdvancedView}
									>
										<i className="icon icon--tiny icon--middle icon--open-new spc--right--xsml"></i>
										Advanced View
									</a>
								</div>
							)}
							<div className="datatooltip--w--300">
								<span ref={this.customerFooterRef} className="pull" />
								<button
									type="button"
									tabIndex="-1"
									className="btn btn--sml btn--ghost spc--right--xsml"
									onClick={
										trigger
											? () => {
													this.handleCloseModal();
													invokeIfFunction(this.props.closeLinkTransactionPopup);
											  }
											: closeModal
									}
									disabled={isProcessing}
								>
									Cancel
								</button>
								<button
									type="button"
									tabIndex="-1"
									className="btn btn--sml btn--primary"
									data-tooltip={has3DS2 ? threeDS2ProcessTooltip : null}
									onClick={this.handleSave}
									disabled={isProcessing}
								>
									Save
								</button>
							</div>
						</div>
					</div>
				</Modal>
			</Fragment>
		);
	}
}

AddEditCustomer.defaultProps = {
	type: 'customers',
	shouldRefreshGrid: true,
};

AddEditCustomer.propTypes = {
	refreshGrid: PropTypes.func.isRequired,
	existingTransaction: PropTypes.object,
	type: PropTypes.oneOf(['customers', 'schedules']).isRequired,
	trigger: PropTypes.func,
	advancedView: PropTypes.bool.isRequired,
	closeModal: PropTypes.func,
	template: PropTypes.object,
	isOpen: PropTypes.bool,
	notificationRef: PropTypes.any,
	makePendingRequest: PropTypes.func.isRequired,
	handleError: PropTypes.func.isRequired,
	advancedViewByDefault: PropTypes.bool,
	closeLinkTransactionPopup: PropTypes.func,
	hideLinkTransactionPopup: PropTypes.func,
	shouldRefreshGrid: PropTypes.bool,
	isDuplicate: PropTypes.func,
	isModalOpen: PropTypes.bool,
};

export default withError(withCancelable(AddEditCustomer));
