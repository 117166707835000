import React, { Component } from 'react';
import { string } from 'prop-types';
import { get, toUpper } from 'lodash';

import { awsUserStatuses } from 'common/utilities';

class UserStatusComponent extends Component {
	defaultValue = {
		label: '',
		color: '',
	};
	render() {
		const { value } = this.props;
		const { label, color } = get(awsUserStatuses, toUpper(value), this.defaultValue);
		return <div className={`status status--${color} align--v--middle`}>{label}</div>;
	}
}

UserStatusComponent.propTypes = {
	value: string,
};

export default UserStatusComponent;
