import React, { Component, createRef, Fragment } from 'react';
import { cloneDeep, each, get, includes, isEmpty, map, noop, split } from 'lodash';
import { bool, func } from 'prop-types';

import { withCancelable } from 'common/components/cancelable';
import { withError } from 'common/components/error';
import { withLoader } from 'common/components/loader';
import { withBlock } from 'common/components/block';
import { Notification } from 'common/components/notifications';
import { kvaasService, principalService } from 'common/services';
import { checkForUrls, kvaasResources } from 'common/utilities';
import { ActionsModal, modalNames } from 'common/components/transaction-actions';
import { Tour } from 'common/components/tour';

const requestKeys = {
	DATA: 'data',
	KVAAS: 'kvaas',
	SAVE: 'save',
	FETCH: 'fetch',
};

const fromCompanyTooltip =
	"To use a different from company, type in your changes. To use the text currently displayed, don't make any changes. Available placeholders like [invoice], [company], [link], and [name] are replaced with the appropriate text when Cardknox sends the email. When left blank the field will be prefilled with your company name";

const subjectTooltip =
	"To use a different subject line, type in your changes. To use the text currently displayed, don't make any changes. Available placeholders like [invoice], [company], [link], and [name] are replaced with the appropriate text when Cardknox sends the email.";
const contentTooltip =
	"To use different text for the email message, type in your changes. To use the text currently displayed, don't make any changes. Available placeholders like [invoice], [company], [link], and [name] are replaced with the appropriate text when Cardknox sends the email.";

const tourConfig = {
	version: 1, // increase this every time you make changes to the tourConfig,
	key: 'sendTestEmail',
	steps: [
		{
			selector: '#sendTestEmail',
			content: 'Send a test email to yourself or others.',
		},
	],
};

class SendPaymentRequestManagementComponent extends Component {
	constructor() {
		super();
		const { companyName: fromCompany = '', hasAccess } = principalService.get();

		this.top = createRef();
		this.notificationRef = createRef();

		this.state = {
			isAdmin: hasAccess.users,
			isContentValid: true,
			sectionsExpanded: {
				emailTemplate: false,
				settings: false,
			},
			principalData: {
				fromCompany,
			},
			errorMessages: [],
			expanded: false,
			isSaving: false,
			modal: {
				name: modalNames.none,
				data: null,
			},
			oldData: {
				sendPaymentRequestManagement: null,
				portalFlags: null,
			},
			sendPaymentRequestManagement: {
				subject: '',
				content: '',
				fromCompany: '',
			},
			portalFlags: {
				allowAdjustAmount: false,
				enableSmsRequests: false,
			},
		};
	}

	get isLoadingOrInvalid() {
		return this.props.isLoading || !this.state.isContentValid;
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData = async () => {
		const {
			props: { showLoader, handleError },
			fetchKvaas,
		} = this;
		try {
			showLoader(true);
			const [sendPaymentRequestManagement, portalFlags] = await fetchKvaas();
			const newState = this.mapResponseToState(sendPaymentRequestManagement, portalFlags);
			newState.isContentValid = this.validateContent(get(sendPaymentRequestManagement, 'data.content', ''));
			this.setState(newState, () => {
				if (!isEmpty(newState.errorMessages)) {
					this.scrollToTop();
				}
			});
		} catch (e) {
			this.props.handleKvaasLoadError();
			handleError(e);
		}
		showLoader(false);
	};

	fetchKvaas = async () => {
		const [sendPaymentRequestManagement, portalFlags] = await this.props.makePendingRequest(
			kvaasService.getIgnoreCache(
				{ ...kvaasResources.sendPaymentRequestManagement, throwError: true },
				{ ...kvaasResources.portalFlags, throwError: true }
			),
			requestKeys.KVAAS
		);
		return [sendPaymentRequestManagement, portalFlags];
	};

	setStateAsync = newState => {
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	validateContent = (content = this.state.sendPaymentRequestManagement.content) => {
		return includes(content, '[link]') && !checkForUrls(content);
	};

	mapResponseToState = (
		oldSendPaymentRequestSettings = this.state.oldData.sendPaymentRequestManagement,
		oldPortalFlags = this.state.oldData.portalFlags
	) => {
		const { sendPaymentRequestManagement, portalFlags, oldData } = cloneDeep(this.state);
		const newState = {
			errorMessages: [],
			oldData,
			sendPaymentRequestManagement,
			portalFlags,
		};
		const callback = type => (value, key) => (newState[type][key] = value);
		kvaasService.mapFieldsToState(
			newState,
			oldSendPaymentRequestSettings,
			'sendPaymentRequestManagement',
			callback('sendPaymentRequestManagement')
		);
		kvaasService.mapFieldsToState(newState, oldPortalFlags, 'portalFlags', callback('portalFlags'));

		return newState;
	};

	mapDefaultPaymentRequestValues = (field, key, defaultData) => {
		const { principalData } = this.state;
		if (includes(['fromCompany'], key) && (!field || defaultData)) {
			return principalData[key];
		}
		if (defaultData) {
			return defaultData[key] || false;
		}
		return field;
	};
	mapStateToRequiredFields = async setToDefaults => [
		await this.mapStateToFields(
			'sendPaymentRequestManagement',
			kvaasResources.sendPaymentRequestManagement,
			setToDefaults
		),
		await this.mapStateToFields('portalFlags', kvaasResources.portalFlags),
	];

	mapStateToFields = async (key, { primaryKey, userSetting, defaultData }, resetToDefault) => {
		try {
			const newState = cloneDeep(this.state);
			const data = {};
			const sections = {
				[key]: this.state[key],
			};
			each(sections, section => {
				each(section, (field, fieldKey) => {
					if (resetToDefault) {
						data[fieldKey] = this.mapDefaultPaymentRequestValues(field, fieldKey, defaultData);
					} else {
						data[fieldKey] = this.mapDefaultPaymentRequestValues(field, fieldKey);
					}
				});
			});
			await this.setStateAsync(newState);
			return {
				newData: {
					revision: 0,
					data,
				},
				oldData: this.state.oldData[key],
				primaryKey,
				userSetting,
			};
		} catch (e) {
			this.props.handleError(e, { additionalInfo: { key, primaryKey, resetToDefault } });
		}
	};

	scrollToTop = () => {
		if (this.top.current) {
			this.top.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
		}
	};

	save = async setToDefaults => {
		const { showLoader, handleBlockChange, makePendingRequest, handleError } = this.props;
		let refreshData = false;
		let refNum;
		let error;
		const addNotification = get(this.notificationRef, 'current.addNotification', noop);
		showLoader(true);
		const mappedState = await this.mapStateToRequiredFields(setToDefaults);

		try {
			const [sendPaymentRequestManagement, portalFlags] = await makePendingRequest(
				kvaasService.save(...mappedState),
				requestKeys.SAVE
			);

			const newState = this.mapResponseToState(sendPaymentRequestManagement, portalFlags);

			refNum = sendPaymentRequestManagement.refNum;

			if (setToDefaults) {
				newState.isContentValid = true;
				newState.sendPaymentRequestManagement = { ...sendPaymentRequestManagement.data };
			}
			showLoader(false);
			this.setState(newState, () => {
				if (!isEmpty(newState.errorMessages)) {
					this.scrollToTop();
				}
			});
			handleBlockChange(false);
		} catch (e) {
			error = handleError(e, { delayMessage: true });
			if (error) {
				refreshData = true;
			} else {
				return;
			}
		}
		if (refreshData) {
			try {
				const response = await makePendingRequest(
					kvaasService.getIgnoreCache(kvaasResources.sendPaymentRequestManagement, kvaasResources.portalFlags),
					requestKeys.REFRESH
				);
				const newState = this.mapResponseToState(...response);
				this.setState(newState, () => {
					if (!isEmpty(newState.errorMessages)) {
						this.scrollToTop();
					}
				});
				showLoader(false);
				handleBlockChange(false);
			} catch (e) {
				error = handleError(e, { delayMessage: true });
				if (error) {
					refreshData = true;
				} else {
					return;
				}
			}
			showLoader(false);
			if (!isEmpty(this.state.errorMessages)) {
				this.scrollToTop();
			} else {
				error.show();
			}
		}
		if (!error) {
			addNotification({
				message: `Send Payment Request settings${setToDefaults ? ' reset to default' : ' updated'}`,
				ref: refNum,
				success: true,
			});
		}
	};

	openSendTestEmail = () =>
		this.setState({
			modal: {
				name: modalNames.sendEmail,
				data: {
					handleError: this.props.handleError,
					addNotification: get(this.notificationRef, 'current.addNotification', noop),
					sendPaymentRequestManagement: cloneDeep(this.state.sendPaymentRequestManagement),
				},
			},
		});

	handleChange = ({ target: { name, checked, value, type } }) => {
		const newState = cloneDeep(this.state);
		const newValue = type === 'checkbox' ? checked : value;
		if (includes(name, 'content')) {
			newState.isContentValid = this.validateContent(value);
		}
		if (includes(name, '.')) {
			const [section, key] = split(name, '.');
			newState[section][key] = newValue;
		} else {
			newState[name] = newValue;
		}
		this.setState(newState);
		this.props.handleBlockChange(true);
	};

	toggleExpandCollapseSection = section => () => {
		this.setState({
			sectionsExpanded: { ...this.state.sectionsExpanded, [section]: !this.state.sectionsExpanded[section] },
		});
	};

	toggleExpandCollapseAll = expand => () => {
		const newState = { sectionsExpanded: this.state.sectionsExpanded };

		each(newState.sectionsExpanded, (_, section) => {
			newState.sectionsExpanded[section] = !expand;
		});

		this.setState(newState);
	};

	openCloseActionsModal = modal => this.setState({ modal });

	mapStateToRequest = (data, oldData) => {
		return {
			newData: {
				revision: 0,
				data,
			},
			oldData,
			...kvaasResources.collapsedSettingsMessages,
		};
	};

	renderHeaderButtons = () => (
		<Fragment>
			<span className="settings__header__action__text">
				Settings are saved only after clicking on <strong>Save</strong> button
			</span>
			{this.renderSaveButton()}
		</Fragment>
	);

	renderSaveButton = () => (
		<button
			className="btn btn--med btn--primary spc--bottom--sml"
			disabled={this.isLoadingOrInvalid}
			onClick={() => this.save(false)}
		>
			Save
		</button>
	);

	render() {
		const {
			modal,
			errorMessages,
			portalFlags: { enableSmsRequests, allowAdjustAmount },
			sendPaymentRequestManagement: { subject, content, fromCompany },
			sectionsExpanded: { emailTemplate },
			isContentValid,
			isAdmin,
		} = this.state;

		return (
			<div className="settings--main settings--main--alt">
				<ActionsModal modal={modal} onModalClose={this.openCloseActionsModal} />
				<Notification ref={this.notificationRef} />
				{!this.props.isLoading && emailTemplate && <Tour tourConfig={tourConfig} />}
				<div className="settings__header">
					<div className="settings__header__title">Send Payment Request Management</div>
					<div className="settings__header__action">{this.renderHeaderButtons()}</div>
				</div>
				<div ref={this.top}></div>
				{map(errorMessages, (error, index) => (
					<div key={index} className="spc--top--sml spc--bottom--med type--color--warning">
						{error}
					</div>
				))}

				<div className="spr__card clearfix">
					<div className="spr__heading clearfix" onClick={this.toggleExpandCollapseSection('emailTemplate')}>
						<div className="group--clear cursor--pointer">
							<h4 className="type--base type--wgt--bold pull">Send Payment Request Email</h4>
						</div>
					</div>
					<div className="spr__content">
						<div className="spc--bottom--sml">
							<label htmlFor="fromEmail" className="type--none datatooltip--sendpayment--email">
								From Email
							</label>
							<input
								type="text"
								id="sendPaymentRequestManagement.fromEmail"
								name="sendPaymentRequestManagement.fromEmail"
								className="input input--med"
								placeholder="From Email"
								disabled={true}
								value="noreply@cardknox.com"
							/>
						</div>

						<div className="spc--bottom--sml">
							<label htmlFor="fromCompany" className="type--none datatooltip--left datatooltip--w--200">
								From Company
								<i
									className="icon icon--tiny icon--info align--v--middle spc--left--tny align--v--top"
									data-tooltip={fromCompanyTooltip}
								></i>
							</label>
							<input
								type="text"
								id="sendPaymentRequestManagement.fromCompany"
								name="sendPaymentRequestManagement.fromCompany"
								className="input input--med"
								placeholder="From Company"
								value={fromCompany}
								onChange={this.handleChange}
							/>
						</div>
						<div className="spc--bottom--sml">
							<label
								htmlFor="sendPaymentRequestManagement.subject"
								className="type--none datatooltip--left datatooltip--w--200"
							>
								Subject
								<i
									className="icon icon--tiny icon--info align--v--middle spc--left--tny align--v--top"
									data-tooltip={subjectTooltip}
								></i>
							</label>
							<input
								type="text"
								id="sendPaymentRequestManagement.subject"
								name="sendPaymentRequestManagement.subject"
								className="input input--med"
								placeholder="Subject"
								value={subject}
								onChange={this.handleChange}
							/>
						</div>
						<div className="spc--bottom--sml">
							<label
								htmlFor="sendPaymentRequestManagement.content"
								className="type--none datatooltip--left datatooltip--w--200"
							>
								Content
								{!isContentValid && (
									<span data-tooltip="[link] required and no other links allowed" className="type--color--primary">
										{' '}
										*
									</span>
								)}
								<i
									className="icon icon--tiny icon--info align--v--middle spc--left--tny align--v--top"
									data-tooltip={contentTooltip}
								></i>
							</label>
							<textarea
								rows="7"
								cols="10"
								name="sendPaymentRequestManagement.content"
								className={`input input--textarea input--textarea--vertical${isContentValid ? '' : ' is-invalid'}`}
								placeholder="Content"
								value={content}
								onChange={this.handleChange}
							/>
						</div>
						<div className="spc--bottom--sml datatooltip--top-right">
							<button
								className="btn btn--ghost btn--med"
								data-tooltip="Restore email to the original template."
								disabled={this.props.isLoading}
								onClick={() => this.save(true)}
							>
								Reset
							</button>

							<button
								id="sendTestEmail"
								className="btn btn--primary btn--med spc--left--sml"
								disabled={this.isLoadingOrInvalid}
								onClick={this.openSendTestEmail}
							>
								Send Test Email
							</button>
						</div>
					</div>
				</div>
				<div className="spr__content">
					<div className="f-row f--a--c">
						<div className="f-col f-col-sml-12 spc--bottom--sml">
							<input
								type="checkbox"
								id="portalFlags.allowAdjustAmount"
								name="portalFlags.allowAdjustAmount"
								checked={allowAdjustAmount}
								value={allowAdjustAmount}
								onChange={this.handleChange}
								className="input input--check"
							/>
							<label htmlFor="portalFlags.allowAdjustAmount" className="label">
								"Allow User To Adjust Amount" Checkbox Unchecked By Default
							</label>
						</div>
					</div>
				</div>
				<div className="spr__content">
					{isAdmin && (
						<div className="f-row f--a--c">
							<div className="f-col f-col-sml-12 spc--bottom--med">
								<input
									type="checkbox"
									id="portalFlags.enableSmsRequests"
									name="portalFlags.enableSmsRequests"
									checked={enableSmsRequests}
									value={enableSmsRequests}
									onChange={this.handleChange}
									className="input input--check"
								/>
								<label
									htmlFor="portalFlags.enableSmsRequests"
									className="type--none datatooltip--sendpayment--email label"
								>
									Enable Payment Requests via SMS
									<i
										className="icon icon--tiny icon--info align--v--middle spc--left--tny align--v--top"
										data-tooltip={
											'A $0.02 SMS fee will apply for every SMS payment request sent.  Changes made to this setting will be applied to the Mobile App as well.'
										}
									></i>
								</label>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

SendPaymentRequestManagementComponent.propTypes = {
	showLoader: func.isRequired,
	handleError: func.isRequired,
	makePendingRequest: func.isRequired,
	isLoading: bool.isRequired,
	handleBlockChange: func.isRequired,
	handleKvaasLoadError: func,
};

export default withError(withLoader(withCancelable(withBlock(SendPaymentRequestManagementComponent))));
