import React, { Component, Fragment, createRef } from 'react';
import { map, keys, filter, some, get, replace, camelCase, each, includes, size, isEmpty } from 'lodash';
import PropTypes, { object } from 'prop-types';

class AddEditCustomerGeneralAddEdit extends Component {
	constructor(props) {
		super(props);
		this.customerNumberInput = createRef();
		this.firstNameRef = createRef();
		this.state = {
			billFirstLastCompanyRequired: false,
		};
	}

	getFields = isBill => {
		const fields = [
			{
				key: 'shipFirstName',
				label: 'First Name',
			},
			{
				key: 'shipLastName',
				label: 'Last Name',
			},
			{
				key: 'shipCompany',
				label: 'Company',
			},
			{
				key: 'shipStreet',
				label: 'Address 1',
			},
			{
				key: 'shipStreet2',
				label: 'Address 2',
			},
			{
				key: 'shipCity',
				label: 'City',
			},
			{
				key: 'shipState',
				label: this.stateLabel,
			},
			{
				key: 'shipZip',
				label: this.zipLabel,
			},
			{
				key: 'shipPhone',
				label: 'Phone Number',
			},
			{
				key: 'shipMobile',
				label: 'Mobile Phone Number',
			},
		];
		if (isBill) {
			each(fields, field => {
				field.key = replace(field.key, 'ship', 'bill');
			});
		}
		return fields;
	};

	get zipLabel() {
		return this.props.isCanadian ? 'Postal Code' : 'ZIP';
	}

	get stateLabel() {
		return this.props.isCanadian ? 'Province' : 'State';
	}

	get shipFields() {
		return this.getFields();
	}

	get billFields() {
		return this.getFields(true);
	}

	componentDidMount() {
		const requiredFields = ['billFirstName', 'billLastName', 'billCompany'];
		const elementWithValue = filter(requiredFields, field => this.props.data[field]);
		if (!isEmpty(elementWithValue)) {
			this.setState({ billFirstLastCompanyRequired: false });
		} else {
			this.setState({ billFirstLastCompanyRequired: true });
		}
	}
	componentDidUpdate = prevProps => {
		const { advancedView, isLoading, isExpanded } = this.props;
		if (
			prevProps.advancedView !== advancedView ||
			prevProps.isLoading !== isLoading ||
			prevProps.isExpanded !== isExpanded
		) {
			this.shouldAutoFocusCustomerNumber();
			this.shouldAutoFocusFirstName(prevProps);
		}
	};

	shouldAutoFocusCustomerNumber = () => {
		const {
			isExpanded,
			data: { customerId },
			advancedView,
		} = this.props;
		if (isExpanded && !customerId && advancedView && this.customerNumberInput.current) {
			this.customerNumberInput.current.focus();
		}
	};

	shouldAutoFocusFirstName = prevProps => {
		const {
			data: { customerId },
			isLoading,
		} = this.props;
		if (prevProps.isLoading && !isLoading && !customerId && this.firstNameRef.current) {
			this.firstNameRef.current.focus();
		}
	};

	onChange = e => {
		const { name, value, checked, type } = e.target;
		const requiredFields = ['billFirstName', 'billLastName', 'billCompany'];
		if (some(requiredFields, field => field === name)) {
			if (value) {
				this.setState({ billFirstLastCompanyRequired: false });
			} else if (!value) {
				const elementWithValue = filter(requiredFields, field => this.props.data[field]);
				if (size(elementWithValue) > 1) {
					this.setState({ billFirstLastCompanyRequired: false });
				} else {
					this.setState({ billFirstLastCompanyRequired: true });
				}
			}
		}
		if (some(['billPhone', 'billMobile', 'shipPhone', 'shipMobile'], item => item === name)) {
			if (!/[^\d()+\-\s]/g.test(value) || value === '') {
				this.props.onChange({
					key: name,
					value: type === 'checkbox' ? checked : value,
				});
			}
		} else {
			this.props.onChange({
				key: name,
				value: type === 'checkbox' ? checked : value,
			});
		}
	};

	showTooltip = (value, isCustom2, customerNumber, customerCustom02, modified, blurredField, savedGeneral, key) => {
		if (
			(!value && isCustom2 && customerNumber && modified && !customerCustom02 && blurredField === key) ||
			(savedGeneral && isCustom2)
		) {
			return true;
		}
		return false;
	};

	mapStateToCustomFields = () => {
		const {
			data,
			data: {
				_meta: { visibleCustomFields, modified },
			},
			customDisplayLabels,
			onCustomBlur,
			blurredField,
			savedGeneral,
			customerRequiredFields,
			customerHiddenFields,
			isViewOnly,
		} = this.props;
		const customFields = filter(keys(visibleCustomFields), key => visibleCustomFields[key]);
		return map(customFields, key => {
			const number = key.slice(-2);
			const parsedNumber = parseFloat(number);
			if (customerHiddenFields[`custom${parsedNumber}`] || parsedNumber === 1) return;
			let value = data[key] || '';
			const isCustom2 = key === 'customerCustom02';
			if (key === 'customerCustom01' && data.customerId) {
				value = data.customerId;
			} else if (!value && !modified && isCustom2 && data.customerNumber) {
				value = data.customerNumber;
			}

			const showTooltip = this.showTooltip(
				value,
				isCustom2,
				data.customerNumber,
				data.customerCustom02,
				modified,
				blurredField,
				savedGeneral,
				key
			);

			return (
				<div
					key={key}
					className="f-col f-col-sml-12 f-col-med-6 f-col-lrg-4 f-col-xxlrg-6 f-col-huge-4 spc--bottom--sml--alt"
				>
					<div className="flex--primary datatooltip--w--160">
						<label htmlFor={`${data.customerId}-${key}`} className="grid__recurring__main__label">
							{customDisplayLabels[`custom${parsedNumber}`] || `Custom${number}`}{' '}
							{this.renderRequired(`custom${parsedNumber}`, customerRequiredFields)}
						</label>
						{showTooltip && (
							<div data-tooltip={'Custom02 will be prefilled with your Customer Number'} className="display--f spc--left--tny">
								<i className="icon icon--tiny icon--info"></i>
							</div>
						)}
					</div>
					<div className="inputgroup">
						{showTooltip && <label htmlFor={`${data.customerId}-${key}`} className="datatooltip--no-wrap"></label>}
						<div className="inputgroup--main">
							<input
								type="text"
								id={`${data.customerId}-${key}`}
								name={key}
								className="input input--med"
								placeholder={customDisplayLabels[`custom${parsedNumber}`] || `Custom${number}`}
								value={value}
								onChange={this.onChange}
								disabled={key === 'customerCustom01' || isViewOnly}
								onBlur={onCustomBlur}
							/>
						</div>
					</div>
				</div>
			);
		});
	};

	getProperty = (key, defaultValue, data) => {
		if (key === 'billStreet' || key === 'shipStreet') {
			key = 'address';
		}
		if (key === 'billPhone') {
			key = 'phoneNumber';
		}
		if (key === 'billMobile') {
			key = 'mobilePhoneNumber';
		}
		if (key === 'shipStreet2' || key === 'billStreet2') {
			key = 'address2';
		}

		return get(data, camelCase(replace(key, 'bill', '')), defaultValue);
	};

	renderBillAndShipInformation = (data, fields, isShip) => {
		return (
			<div className="f-row">
				{map(fields, field => {
					const id = `${data.customerId}-${field.name || field.key}`;
					return this.renderField(id, field.key, field.label, id, data[field.name] || data[field.key], isShip);
				})}
			</div>
		);
	};
	renderRequired = (key, customerRequiredFields) => {
		if (
			this.getProperty(key, false, customerRequiredFields) ||
			(includes(['billFirstName', 'billLastName', 'billCompany'], key) && this.state.billFirstLastCompanyRequired)
		) {
			return (
				<span data-tooltip="Required" className="label--required">
					*
				</span>
			);
		}
		return null;
	};

	renderField = (htmlFor, key, fieldLabel, id, value, isShip) => {
		const { customDisplayLabels, customerRequiredFields, customerHiddenFields } = this.props;
		if (this.getProperty(key, false, customerHiddenFields)) return;
		if (
			customerHiddenFields[key] ||
			customerHiddenFields[camelCase(replace(key, 'bill', ''))] ||
			customerHiddenFields[camelCase(replace(key, 'ship', ''))]
		)
			return null;
		const label = this.getProperty(key, fieldLabel, customDisplayLabels);
		return (
			<div className="f-col f-col-sml-12 f-col-med-6 f-col-huge-4 spc--bottom--sml--alt" key={key}>
				<label htmlFor={htmlFor} className="grid__recurring__main__label">
					{label} {!isShip && this.renderRequired(key, customerRequiredFields)}
				</label>
				<div className="customer__item__value">
					<input
						type="text"
						id={id}
						name={key}
						className="input input--med"
						placeholder={label}
						value={value || ''}
						onChange={this.onChange}
						disabled={this.props.isViewOnly}
					/>
				</div>
			</div>
		);
	};
	renderGeneralInformation = data => {
		const customerNumberLabel = this.getProperty('customerNumber', 'Customer #', this.props.customDisplayLabels);
		const emailLabel = this.getProperty('email', 'Email Address', this.props.customDisplayLabels);
		const noteLabel = this.getProperty('note', 'Note', this.props.customDisplayLabels);
		return (
			<div className="f-row">
				{!this.props.customerHiddenFields['customerNumber'] && (
					<div className="f-col f-col-sml-12 f-col-med-6 f-col-huge-4 spc--bottom--sml--alt">
						<label htmlFor={`${data.customerId}-customerNumber`} className="grid__recurring__main__label">
							{customerNumberLabel}
							{this.renderRequired('customerNumber', this.props.customerRequiredFields)}
						</label>
						<div className="customer__item__value">
							<input
								ref={this.customerNumberInput}
								type="text"
								id={`${data.customerId}-customerNumber`}
								name="customerNumber"
								className="input input--med"
								placeholder={customerNumberLabel}
								value={data.customerNumber || ''}
								onChange={this.onChange}
								disabled={this.props.isViewOnly}
							/>
						</div>
					</div>
				)}
				{this.renderField(
					`${data.customerId}-email`,
					'email',
					emailLabel,
					`${data.customerId}-email`,
					data.email || ''
				)}
				{!this.props.customerHiddenFields['note'] && (
					<div className="f-col f-col-sml-12 spc--bottom--sml--alt">
						<label htmlFor={`${data.customerId}-customerNotes`} className="grid__recurring__main__label">
							{noteLabel} {this.renderRequired('customerNotes', this.props.customerRequiredFields)}
						</label>
						<div className="customer__item__value">
							<textarea
								name="customerNotes"
								id={`${data.customerId}-customerNotes`}
								className="input input--textarea input--textarea--one-row input--textarea--vertical"
								placeholder={noteLabel}
								value={data.customerNotes || ''}
								onChange={this.onChange}
								disabled={this.props.isViewOnly}
							/>
						</div>
					</div>
				)}
			</div>
		);
	};

	render = () => {
		const { data, advancedView, customerHiddenFields, isViewOnly } = this.props;
		const firstNameLabel = this.getProperty('billFirstName', 'First Name', this.props.customDisplayLabels);
		const lastNameLabel = this.getProperty('billLastName', 'Last Name', this.props.customDisplayLabels);
		const companyLabel = this.getProperty('billCompany', 'Company', this.props.customDisplayLabels);
		return (
			<Fragment>
				{advancedView ? (
					<Fragment>
						{this.renderGeneralInformation(data)}
						<hr className="separator separator--grey1 separator--negative--24 spc--bottom--med"></hr>
						<div className="type--color--primary type--wgt--medium spc--bottom--med">Billing Information</div>

						{!some(['billFirstName', 'billLastName', 'billCompany'], field => data[field]) && (
							<div className="message message--med message--default spc--bottom--sml">
								{`Please enter at least one of the following: ${firstNameLabel}, ${lastNameLabel}, ${companyLabel}`}
							</div>
						)}
						{this.renderBillAndShipInformation(data, this.billFields)}
						<hr className="separator separator--grey1 separator--negative--24 spc--bottom--med" />
						<div className="type--color--primary type--wgt--medium spc--bottom--med">Shipping Information</div>
						<div className="spc--bottom--med">
							<input
								type="checkbox"
								name="_meta.sameAsBilling"
								value={data._meta.sameAsBilling || false}
								id={`${data.customerId}-_meta.sameAsBilling`}
								className="input input--check"
								checked={data._meta.sameAsBilling || false}
								onChange={this.onChange}
								disabled={isViewOnly}
							/>
							<label htmlFor={`${data.customerId}-_meta.sameAsBilling`} className="type--wgt--medium type--none">
								Same as billing
							</label>
						</div>
						{!data._meta.sameAsBilling && (
							<Fragment>{this.renderBillAndShipInformation(data, this.shipFields, true)}</Fragment>
						)}
						<hr className="separator separator--grey1 separator--negative--24 spc--bottom--med" />
						<div className="type--color--primary type--wgt--medium spc--bottom--sml">Custom Fields</div>
						<div className="f-row f-row--bottom">{this.mapStateToCustomFields()}</div>
					</Fragment>
				) : (
					<div className="f-row">
						<div className="f-col f-col-sml-12">
							{!customerHiddenFields['firstName'] && (
								<div className="spc--bottom--sml--alt">
									<label htmlFor={`${data.customerId}-billFirstName`} className="grid__recurring__main__label">
										{firstNameLabel}
										{this.renderRequired('billFirstName', this.props.customerRequiredFields)}
									</label>
									<div className="customer__item__value">
										<input
											type="text"
											id={`${data.customerId}-billFirstName`}
											name="billFirstName"
											className="input input--med"
											placeholder={firstNameLabel}
											value={data.billFirstName || ''}
											onChange={this.onChange}
											ref={this.firstNameRef}
											disabled={isViewOnly}
										/>
									</div>
								</div>
							)}
						</div>
						<div className="f-col f-col-sml-12">
							{!customerHiddenFields['lastName'] && (
								<div className="spc--bottom--sml--alt">
									<label htmlFor={`${data.customerId}-billLastName`} className="grid__recurring__main__label">
										{lastNameLabel}
										{this.renderRequired('billLastName', this.props.customerRequiredFields)}
									</label>
									<div className="customer__item__value">
										<input
											type="text"
											id={`${data.customerId}-billLastName`}
											name="billLastName"
											className="input input--med"
											placeholder={lastNameLabel}
											value={data.billLastName || ''}
											disabled={isViewOnly}
											onChange={this.onChange}
										/>
									</div>
								</div>
							)}
						</div>
						<div className="f-col f-col-sml-12">
							{!customerHiddenFields['company'] && (
								<div className="spc--bottom--sml--alt">
									<label htmlFor={`${data.customerId}-billCompany`} className="grid__recurring__main__label">
										{companyLabel}
										{this.renderRequired('billCompany', this.props.customerRequiredFields)}
									</label>
									<div className="customer__item__value">
										<input
											type="text"
											id={`${data.customerId}-billCompany`}
											name="billCompany"
											className="input input--med"
											placeholder={companyLabel}
											value={data.billCompany || ''}
											disabled={isViewOnly}
											onChange={this.onChange}
										/>
									</div>
								</div>
							)}
						</div>
					</div>
				)}
			</Fragment>
		);
	};
}

AddEditCustomerGeneralAddEdit.propTypes = {
	data: PropTypes.object.isRequired,
	customDisplayLabels: object.isRequired,
	onChange: PropTypes.func.isRequired,
	onCustomBlur: PropTypes.func.isRequired,
	advancedView: PropTypes.bool.isRequired,
	isCanadian: PropTypes.bool,
	isExpanded: PropTypes.bool,
	isViewOnly: PropTypes.bool,
	isLoading: PropTypes.bool.isRequired,
	requiredFields: PropTypes.object,
	customerRequiredFields: PropTypes.object,
	customerHiddenFields: PropTypes.object,
	savedGeneral: PropTypes.any,
	blurredField: PropTypes.any,
};

export default AddEditCustomerGeneralAddEdit;
