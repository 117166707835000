import React, { forwardRef } from 'react';
import moment from 'moment';
import { filter, includes, isArray } from 'lodash';

import { transactionService } from 'common/services';
import { withCancelable } from '../cancelable';

const { apiDateTimeFormat } = ApplicationSettings;

function withLoadMore(Component) {
	async function loadMore(
		pendingData,
		filters,
		dateFilter,
		loadMoreLimit,
		refNums,
		compiledFilter,
		compileFilter,
		fields,
		displayOlderTransactionsFirst,
		parseTransactionResults,
		isHistory
	) {
		if (
			(loadMoreLimit === 1000 &&
				pendingData.xReportData &&
				pendingData.xReportData.length === 1000 &&
				compiledFilter.xBeginDate) ||
			isHistory
		) {
			const {
				start: additionalStart,
				end: additionalEnd,
				refNums: additionalRefNums,
			} = transactionService.getNewStartEndDates(
				dateFilter.values.startDate,
				dateFilter.values.endDate,
				pendingData.xReportData,
				displayOlderTransactionsFirst
			);
			dateFilter.values.startDate = moment(additionalStart, apiDateTimeFormat);
			dateFilter.values.endDate = moment(additionalEnd, apiDateTimeFormat);
			const additionalFilter = isArray(filters) ? await compileFilter(filters, apiDateTimeFormat) : compiledFilter;
			const maxTransactions = isHistory ? loadMoreLimit : refNums.length + additionalRefNums.length;
			const additionalData = await transactionService.filterTransactionsRequest(
				additionalFilter,
				maxTransactions,
				fields,
				displayOlderTransactionsFirst
			);
			if (isHistory) {
				await parseTransactionResults(additionalData);
			}
			additionalData.xReportData = filter(
				additionalData.xReportData,
				({ xRefNum }) => !includes(additionalRefNums, xRefNum)
			);
			return {
				additionalData,
				additionalRefNums,
			};
		}
		return {
			additionalData: {
				xReportData: [],
				xRecordsReturned: 0,
				xReportingMaxTransactions: 0,
			},
			additionalRefNums: [],
		};
	}
	return withCancelable(forwardRef((props, ref) => <Component {...props} loadMore={loadMore} forwardedRef={ref} />));
}

export default withLoadMore;
