import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, constant, split, find, memoize, merge, concat, map, cloneDeep, isEmpty, each } from 'lodash';

import Grid from './grid';
import { ReactDataGrid } from '../react-data-grid';
import ToolbarComponent from './toolbar';
import GridFooter from './grid-footer';
import { createPortal } from 'react-dom';
import ColumnFilterComponent from './column-filter';

const minColumnWidth = 75;

const defaultClasses = {
	content: 'l--content',
	wrapper: '',
	header: 'flex--tertiary',
	headerMenu: 'header__menu',
	headerMenuAction: 'header__menu__action',
	headerGroup: 'group--alt group--alt--auto align--v--middle header__btn__holder',
	title: 'header__title',
	print: 'reportprint__table',
	printWrapper: 'pos--rel',
	exportWrapper: 'pos--rel',
	gridHolder: 'grid__holder',
	gridWrapper: 'grid__holder--override',
	filter: 'align--h--left spc--right--sml',
	filterContainer: 'filter__container',
	filterWrapper: 'filter',
};

class PopupGrid extends Grid {
	mergeClasses = memoize(classes => merge({}, defaultClasses, classes));

	onRowClick = (...params) => {
		const row = params[1];
		if (
			!this.props.isExpandable ||
			(row && !row.isExpandable) ||
			(!this.props.displayRowDetails && row && row.isDetails) ||
			params[0] < 0
		) {
			return;
		}
		const args = this.props.mapCellArgs(...params);
		if (args) {
			this.onCellExpand(args);
		}
		if (this.props.selectCustomer) {
			this.props.selectCustomer(row);
		}
	};
	onFilterColumns = async (col, updateDefColumns) => {
		this.oldVisibleColumns = concat(
			this.oldVisibleColumns,
			map(this.getDisplayedColumns(this.props.columns), ({ key }) => key)
		);
		const columns = map(this.props.columns, column => {
			const newColumn = find(col, { key: column.key });
			if (newColumn.visible === column.visible) {
				return column;
			}
			return {
				...column,
				visible: newColumn.visible,
			};
		});
		await this.calculateColumnWidths(null, columns);
		const changes = [
			{
				value: this.props.mapExistingValuesToRows(cloneDeep(this.props.data), columns),
				key: 'data',
			},
		];
		if (updateDefColumns) {
			changes.push({
				value: columns,
				key: 'defaultColumns',
			});
		}
		await this.props.onChange(changes);
	};
	calculateGridHeight = () => {
		try {
			const minHeight = 200;
			const { showGridFooter, showGridHeader } = this.props;
			const ref = get(this.props.popupRef, 'current', false);
			if (ref) {
				let total = 0;
				let styles;
				const popupBody = find(ref.children, { className: 'popup__body' });
				styles = window.getComputedStyle(popupBody, null);
				if (styles) {
					const maxHeight = styles.getPropertyValue('max-height');
					const paddingTop = styles.getPropertyValue('padding-top');
					const paddingBottom = styles.getPropertyValue('padding-bottom');
					if (maxHeight) {
						const [value] = split(maxHeight, 'px');
						if (value) {
							total += parseInt(value);
						}
					}
					if (paddingTop) {
						const [value] = split(paddingTop, 'px');
						if (value) {
							total -= parseInt(value);
						}
					}
					if (paddingBottom) {
						const [value] = split(paddingBottom, 'px');
						if (value) {
							total -= parseInt(value);
						}
					}
				}
				if (showGridHeader) {
					const gridHeader = document.getElementById('filterContainer');
					styles = window.getComputedStyle(gridHeader, null);
					total = this.getElementHeight(styles, total);
				}
				if (showGridFooter) {
					const gridFooter = popupBody.getElementsByClassName('grid__footer');
					styles = window.getComputedStyle(gridFooter[0], null);
					total = this.getElementHeight(styles, total);
				}
				const elementsToIgnore = document.getElementsByClassName('ignoreHeight');
				if (!isEmpty(elementsToIgnore)) {
					each(elementsToIgnore, element => {
						styles = window.getComputedStyle(element, null);
						total = this.getElementHeight(styles, total);
					});
				}
				const additionalPixel = this.state.additionalPixel ? 1 : 0;
				return Math.max(Math.abs(total), minHeight) - additionalPixel;
			}
		} catch (e) {
			//intentionally empty catch block
		}
		return 'auto';
	};
	getElementHeight = (styles, total, addToTotal) => {
		if (styles) {
			const propertyValues = [];
			propertyValues.push(styles.getPropertyValue('height'));
			propertyValues.push(styles.getPropertyValue('padding-top'));
			propertyValues.push(styles.getPropertyValue('padding-bottom'));
			each(propertyValues, property => {
				const [value] = split(property, 'px');
				if (value && !addToTotal) {
					total -= parseInt(value);
				} else if (value && addToTotal) {
					total += parseInt(value);
				}
			});
		}
		return total;
	};
	getCellActions = (column, row) => {
		const cellActions = [
			{
				icon: <span className="icon icon--tiny icon--middle icon--delete-alt--warning cursor--pointer" />,
				callback: () => this.props.removeRow(row),
			},
		];
		return column.key === 'action' ? cellActions : null;
	};

	renderGridBody = () => {
		const {
			isExpandable,
			columns,
			filteredRows,
			useInlineFilters,
			type,
			expandInSidebar,
			rowDetailsProps,
			tooltipProps,
			defaultColumns,
		} = this.props;
		const { isLargeScreen } = this.state;
		const gridClassName = `grid__height ${this.classes.gridWrapper} ${isExpandable ? 'grid--pointer' : ''}`;
		return (
			<div
				className={`${this.classes.gridHolder} ${
					this.detailsRow && expandInSidebar && !isLargeScreen ? 'display--n' : ''
				}`}
				ref={this.gridHolderRef}
			>
				<div
					style={{
						width: this.calculateGridWidth(this.contentRef.current),
						height: this.calculateGridHeight(),
					}}
					className={`${gridClassName} grid__holder--fixed--main`}
				>
					<this.components.tooltip {...tooltipProps} />
					<ReactDataGrid
						minWidth={this.calculateGridWidth(this.contentRef.current) + (this.state.additionalPixel ? 1 : 0)}
						minHeight={this.calculateGridHeight()}
						columns={this.getDisplayedColumns(columns)}
						defaultColumns={defaultColumns}
						rowsCount={filteredRows.length}
						rowGetter={this.rowGetter}
						onGridSort={this.onGridSort}
						ref={this.gridRef}
						toolbar={useInlineFilters ? ToolbarComponent : constant(null)}
						onAddFilter={this.handleInlineFilter}
						getValidFilterValues={this.getValidInlineFilterValues}
						onClearFilters={this.handleInlineFilter}
						emptyRowsView={this.renderEmptyGrid}
						onCellExpand={this.onCellExpand}
						onRowClick={this.onRowClick}
						onColumnResize={this.onColumnResize}
						minColumnWidth={minColumnWidth}
						enableRowSelect={null}
						rowScrollTimeout={null}
						rowHeight={48}
						enableCellSelect={false}
						getDetailsRowIndex={this.getDetailsRowIndex}
						rowRenderer={
							this.components.rowRenderer ? (
								<this.components.rowRenderer
									setDetailsRef={this.setRowDetailsRef}
									refreshGridData={this.refreshGridData}
									openModal={this.openCloseModal}
									gridHolder={this.gridHolderRef.current}
									resizeGrid={this.resizeGrid}
									type={type}
									rowDetailsRenderer={expandInSidebar ? null : this.components.rowDetails}
									rowDetailsProps={rowDetailsProps}
									showLoader={this.showLoader}
								/>
							) : (
								undefined
							)
						}
						getCellActions={this.getCellActions}
					/>
				</div>
			</div>
		);
	};

	renderGridHeader = () => {
		const {
			showGridHeader,
			filterColumns,
			enablePrint,
			enableExport,
			enableViewInTransactionReport,
			useInlineFilters,
			renderGridHeaderViewInTransactionReport,
		} = this.props;

		return (
			showGridHeader && (
				<div className={this.classes.filterWrapper} ref={this.filtersContainerRef}>
					<div id="filterContainer" className={this.classes.filterContainer}>
						<div className={this.classes.header}>
							<div className={this.classes.filter}>{this.renderTitle()}</div>
							<div className={this.classes.gridHeader}>
								<div className={this.classes.headerGroup}>
									{filterColumns && <div className="spc--left--tny push" ref={this.columnFilterPortalRef} />}
									{enableViewInTransactionReport && renderGridHeaderViewInTransactionReport()}
									{enablePrint && this.renderPrintButton()}
									{enableExport && this.renderExportButton()}
									<this.components.gridHeader
										openCloseModal={this.openCloseModal}
										refreshGridData={this.refreshGridData}
									/>
								</div>
								{useInlineFilters && (
									<div className="filter__toggle">
										<button type="button" className="btn btn--med btn--ghost" onClick={this.showHideInlineFilters}>
											<i
												className={`icon icon--sml icon--search${
													this.gridRef.current && this.gridRef.current.state.canFilter ? '' : '--disabled'
												} icon--middle`}
											/>
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)
		);
	};

	renderResultsAndReference = () => {
		const {
			lastApiRefNum,
			showResults,
			filteredRows,
			showGridFooterLoadAllResults,
			renderGridFooterLoadAllResults,
		} = this.props;
		if (showResults) {
			const numRecords = filteredRows.length;
			const resultsWording = numRecords === 1 ? 'Result' : 'Results';
			const refWording = lastApiRefNum ? `(#${lastApiRefNum})` : '';
			return (
				<Fragment>
					<div className="grid__footer__results">
						{numRecords} {resultsWording} <strong>{refWording}</strong>
					</div>
					{showGridFooterLoadAllResults && renderGridFooterLoadAllResults()}
				</Fragment>
			);
		}
		return null;
	};

	render = () => {
		const {
			data,
			expandInSidebar,
			hasPaging,
			fetchingAdditionalData,
			fetchingData,
			filteredRows,
			type,
			hasMoreData,
			onLoadMoreLimitChange,
			loadMoreOptions,
			loadMoreLimit,
			showGridFooter,
			showColumnFilter,
			kvaasResourceType,
		} = this.props;

		const detailRow = find(data && data.xReportData, r => r.isDetails);
		const popupRef = get(this.props.popupRef, 'current', false);

		return (
			<div className="grid__holder--bulk-charge">
				<div className={this.classes.wrapper}>
					<div
						ref={this.contentRef}
						className={
							popupRef
								? ''
								: `${this.classes.content} ${
										this.gridRef.current && this.gridRef.current.state.canFilter
											? ''
											: 'grid__holder--override__header-one-row'
								  } ${this.detailsRow && expandInSidebar ? 'is-expanded' : ''}`
						}
					>
						<div>
							{this.renderGridHeader()}
							{this.renderGridBody()}
						</div>
						{expandInSidebar && detailRow && (
							<this.components.rowDetails
								key={detailRow.index}
								row={detailRow}
								visibleColumns={this.props.columns}
								openModal={this.openCloseModal}
								refreshGridData={this.refreshGridData}
								gridHolder={this.gridHolderRef.current}
								setDetailsRef={this.setRowDetailsRef}
								resizeGrid={this.resizeGrid}
								type={this.props.type}
								closeRow={() => this.onRowClick(detailRow.gridRowNumber, detailRow)}
								{...(detailRow.expandedRowProps || {})}
								{...this.props.rowDetailsProps}
							/>
						)}
						{showGridFooter && (
							<GridFooter
								gridFooterRef={this.gridFooterRef}
								isLoadMoreEnabled={hasPaging && hasMoreData(data)}
								fetchingAdditionalData={fetchingAdditionalData}
								fetchingData={fetchingData}
								filteredRows={filteredRows}
								type={type}
								kvaasResourceType={kvaasResourceType}
								onLoadMoreLimitChange={onLoadMoreLimitChange}
								loadMoreLimit={loadMoreLimit}
								loadMoreOptions={loadMoreOptions}
								openCloseModal={this.openCloseModal}
								CustomComponent={this.components.gridFooter}
								renderResultsAndReference={this.renderResultsAndReference}
							/>
						)}
						{this.columnFilterPortalRef.current &&
							showColumnFilter &&
							createPortal(
								<ColumnFilterComponent
									hideSettings={true}
									defaultColumns={this.props.defaultColumns}
									columns={this.props.columns}
									filteredColumns={this.onFilterColumns}
									header={this.components.columnFilterHeader}
									footer={this.components.columnFilterFooter}
									isDisabled={data === null}
									type={type}
									kvaasResourceType={kvaasResourceType}
									updateState={this.updateColumnFilterState}
									state={this.state.columnFilterState}
								/>,
								this.columnFilterPortalRef.current
							)}{' '}
					</div>
				</div>
			</div>
		);
	};
}

PopupGrid.propTypes = {
	renderGridFooterLoadAllResults: PropTypes.func,
	renderGridHeaderViewInTransactionReport: PropTypes.func,
	enableViewInTransactionReport: PropTypes.bool,
	showGridFooterLoadAllResults: PropTypes.bool,
};

export default PopupGrid;
