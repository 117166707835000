import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { includes, isEmpty, map } from 'lodash-es';

export default class UploadInvoice extends Component {
	constructor(props) {
		super(props);
	}
	renderDropzoneBody = () => {
		const files = this.props.acceptedFiles;
		if (!isEmpty(files)) {
			return files;
		}
		return <span className="spc--bottom--sml">No file(s) selected</span>;
	};

	displayAttachments = () => {
		if (isEmpty(this.props.uploadedFile)) return;
		return map(this.props.files, fileData => {
			return (
				includes(fileData.type, 'image') && (
					<div className="spr__invoice__preview">
						<img src={fileData.file} className="flex--grow--1" />
					</div>
				)
			);
		});
	};
	renderFileSizeError = () => {
		if (this.props.fileSizeError) {
			return (
				<div className="spc--bottom--sml">
					<div className="validation display--ib">
						<p className="type--error">File size limit is 15MB for emails sent through the portal</p>
					</div>
				</div>
			);
		}
		return null;
	};

	render = () => {
		return (
			<div className="spr__card">
				{this.renderFileSizeError()}
				<div className="spr__heading is-expanded">
					<label className="spr__heading__title datatooltip--w--200">
						Attach An Invoice
					</label>
					<div className="display--f align--h--left spc--left--tny" data-tooltip="Max 5 Invoices can be uploaded.">
						<i className="icon icon--tiny icon--info"></i>
					</div>
				</div>
				<div className="spr__content">
					<div className="logo-management">
						<Dropzone onDrop={this.props.onDrop} multiple={true} maxFiles={5}>
							{({ getRootProps, getInputProps }) => (
								<section className="flex--grow--1 spc--bottom--sml">
									<div {...getRootProps()}>
										<input {...getInputProps()} />
										<div className="flex flex--primary">
											<button className="btn btn--sml btn--ghost spc--right--tny spc--bottom--sml">Browse</button>

											{this.renderDropzoneBody()}
										</div>
									</div>
								</section>
							)}
						</Dropzone>
						<div className="spr__invoice__preview__wrapper">{this.displayAttachments()}</div>
					</div>
				</div>
			</div>
		);
	};
}

UploadInvoice.propTypes = {
	acceptedFiles: PropTypes.array,
	onDrop: PropTypes.func,
	files: PropTypes.any,
	uploadedFile: PropTypes.any,
	fileSizeError: PropTypes.bool.isRequired,
};
