import React from 'react';
import PropTypes from 'prop-types';
import ModalComponent from 'common/components/modal/modal';
import { isEmpty } from 'lodash';

const BulkProgressModal = ({
	isOpen,
	onClose,
	progress,
	mappedErrors,
	errors,
	someErrorMessage,
	dataType,
	successMessage,
	failureMessage,
	allSuccessMessage,
	action,
	className,
}) => {
	const uploadHasErrors = !isEmpty(mappedErrors);
	const allCustomersFailed = progress.completed === 0 && progress.errored === progress.total;
	const someCustomersFailed = progress.errored > 0 && !allCustomersFailed;
	const processCompleted = progress.completed + progress.errored === progress.total;
	const renderProgressStatus = () => {
		if (uploadHasErrors) {
			return (
				<table className="table table--secondary table--secondary--upload-error">
					<tbody>
						<tr>
							<td className="counter" colSpan={3}>
								Errors ({errors.length})
							</td>
						</tr>
						{mappedErrors}
					</tbody>
				</table>
			);
		}

		if (someCustomersFailed) {
			return (
				<p className="type--xlrg">
					<span>{progress.errored}</span> {someErrorMessage}
				</p>
			);
		}

		return null;
	};
	const message =
		uploadHasErrors && !allCustomersFailed
			? successMessage
			: allCustomersFailed
			? failureMessage
			: processCompleted
			? allSuccessMessage
			: '';
	return (
		<ModalComponent isOpen={isOpen} onClose={onClose}>
			<div className="popup__body popup--process__wrapper">
				<div
					className={`popup--process ${className} ${!processCompleted ? 'is-loading' : ''} ${
						processCompleted && !allCustomersFailed ? 'is-success' : ''
					} ${uploadHasErrors ? 'has-error' : ''}`}
				>
					<div className="icon"></div>
				</div>
				<h2 className="type--wgt--regular type--base type--center type--color--text--regular w--334p">{message}</h2>

				<p className="type--xlrg">
					{action}
					<span className="type--wgt--bold spc--right--tny spc--left--tny">
						{progress.completed + progress.errored} of {progress.total}
					</span>
					{dataType}
				</p>

				{renderProgressStatus()}
				{processCompleted && (
					<button onClick={onClose} className="btn btn--med btn--primary fullwidth spc--top--sml">
						Ok
					</button>
				)}
			</div>
		</ModalComponent>
	);
};
BulkProgressModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	progress: PropTypes.shape({
		completed: PropTypes.number.isRequired,
		total: PropTypes.number.isRequired,
		errored: PropTypes.number.isRequired,
	}).isRequired,
	mappedErrors: PropTypes.node,
	errors: PropTypes.array,
	someErrorMessage: PropTypes.string,
	dataType: PropTypes.string,
	successMessage: PropTypes.string,
	failureMessage: PropTypes.string,
	allSuccessMessage: PropTypes.string,
	action: PropTypes.string.isRequired,
	className: PropTypes.string,
};

export default BulkProgressModal;
