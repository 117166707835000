import { has } from 'lodash';

class PaymentTransactionTypes {
	static paymentTypes = {
		cc: 'Credit Card',
		check: 'Check',
		ebtfs: 'EBT Food',
		ebtcb: 'EBT Cash',
		ebtonline: 'EBT Online',
		gift: 'Gift Card',
		fraud: 'Fraud',
		avsonly: 'Credit Card',
		crypto: 'Crypto',
		grant: 'Grant',
	};

	static transactionTypes = {
		sale: 'Sale',
		fssale: 'Fs Sale',
		cbsale: 'Cb Sale',
		authonly: 'AuthOnly',
		capture: 'Capture',
		splitcapture: 'Split Capture',
		save: 'Save',
		postauth: 'PostAuth',
		credit: 'Credit',
		refund: 'Refund',
		voidrefund: 'Void Refund',
		voidrelease: 'Void Release',
		void: 'Void',
		balance: 'Balance',
		voucher: 'Voucher',
		cash: 'Cash',
		issue: 'Issue',
		redeem: 'Redeem',
		submit: 'Submit',
		avsonly: 'Avs Only',
		initiate: 'Initiate',
		recommendation: 'Recommendation',
	};

	static getPaymentType(key) {
		if (has(this.paymentTypes, key)) {
			return this.paymentTypes[key];
		}
		return '';
	}

	static getTransactionType(key) {
		if (has(this.transactionTypes, key)) {
			return this.transactionTypes[key];
		}
		return '';
	}
}

export default PaymentTransactionTypes;
