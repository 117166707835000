/* eslint-disable */

import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import css from '../../../styles/css/print.print.css';
import { uniqueId } from 'common/utilities';

export default class ReactToPrint extends Component {
	wasPrintTriggered = false; // IE triggers print several times which causes crashes

	startPrint = (target, onAfterPrint) => {
		try {
			if (!target.contentWindow) {
				return;
			}
			target.contentWindow.focus();
			target.contentWindow.print();
			target.contentWindow.addEventListener('afterprint', () => {
				// This code will be executed after the print dialog is closed
				if (onAfterPrint) {
					onAfterPrint();
				}

				const printWindow = document.getElementById(this.printWindowId);
				if (printWindow) {
					document.body.removeChild(printWindow);
				}
			});
		} catch (e) {
			const { onPrintError } = this.props;
			if (onPrintError) {
				onPrintError('startPrint', e);
			}
			return;
		}
	};

	handleTableRefPrint = (tableRef, domDoc) => {
		const pageWidth = this.props.isPortrait ? 800 : 1024;
		const printWrap = domDoc.createElement('div');
		const positions = [];
		const headers = domDoc.querySelectorAll('th');
		let lastOuterWidth = 0;
		let totalDiff = 0;
		for (let i = 0; i < headers.length; i++) {
			const header = headers[i];
			const position = header.getBoundingClientRect().left;
			// fractional pixels handling for non-Edge browsers
			const roundedPosition = Math.ceil(position + totalDiff);
			totalDiff = roundedPosition - position;
			positions.push(roundedPosition);
			lastOuterWidth = header.clientWidth;
		}
		const pageWidths = [];
		const endColumns = [];
		let lastPosition = positions[0];
		for (let i = 0; i < positions.length; i++) {
			if (positions[i] - lastPosition > pageWidth) {
				pageWidths.push(positions[i - 1] - lastPosition);
				lastPosition = positions[i - 1];
				endColumns.push(i - 1);
			}
			if (i === positions.length - 1) {
				const fullWidth = positions[i] + lastOuterWidth - lastPosition;
				if (fullWidth > pageWidth) {
					pageWidths.push(positions[i] - lastPosition);
					endColumns.push(i - 1);
				}
				pageWidths.push(pageWidth);
				endColumns.push(i);
				lastPosition = positions[i];
			}
		}
		const pageCount = pageWidths.length;
		let lastEndColumn = 0;
		for (let i = 0; i < pageCount; i++) {
			const thisPageWidth = pageWidths[i];
			const styleString = `overflow: hidden; width: ${thisPageWidth}px; page-break-before: ${
				i === 0 ? 'auto' : 'always'
			};`;
			const printPage = domDoc.createElement('div');
			printPage.setAttribute('style', styleString);
			const clone = tableRef.cloneNode(true);
			clone.style.position = 'relative';
			clone.style.left = -1 * lastEndColumn;
			const headerRef = tableRef.querySelector('.header-wrapper');
			if (headerRef) {
				headerRef.style.maxWidth = pageWidths[0] + 'px';
			}
			printPage.appendChild(clone);
			lastEndColumn += thisPageWidth;
			printWrap.appendChild(printPage);
		}
		tableRef.parentNode.appendChild(printWrap);
		tableRef.style.display = 'none';
	};

	handleSplitColumns = target => {
		const domDoc = target.contentDocument || (target.contentWindow && target.contentWindow.document);
		if (!domDoc) {
			return;
		}
		const tableRef = domDoc.querySelector('.print-wrapper');
		if (tableRef) {
			this.handleTableRefPrint(tableRef, domDoc);
		}
	};

	handleOnBeforePrint = (target, onBeforePrint, onAfterPrint, onPrintError) => {
		const onBeforePrintOutput = onBeforePrint();
		if (onBeforePrintOutput && typeof onBeforePrintOutput.then === 'function') {
			onBeforePrintOutput
				.then(() => {
					this.startPrint(target, onAfterPrint);
				})
				.catch(error => {
					if (onPrintError) {
						onPrintError('onBeforePrint', error);
					}
				});
		} else {
			this.startPrint(target, onAfterPrint);
		}
	};
	triggerPrint = target => {
		if (this.wasPrintTriggered) {
			return;
		}
		this.wasPrintTriggered = true;
		const { onAfterPrint, onBeforePrint, onPrintError, splitColumns = false } = this.props;

		if (splitColumns) {
			this.handleSplitColumns(target);
		}

		if (onBeforePrint) {
			this.handleOnBeforePrint(target, onBeforePrint, onAfterPrint, onPrintError);
		} else {
			this.startPrint(target, onAfterPrint);
		}
	};

	handleClick = () => {
		const { onBeforeGetContent, onPrintError } = this.props;

		if (onBeforeGetContent) {
			const onBeforeGetContentOutput = onBeforeGetContent();
			if (onBeforeGetContentOutput && typeof onBeforeGetContentOutput.then === 'function') {
				onBeforeGetContentOutput.then(this.handlePrint).catch(error => {
					if (onPrintError) {
						onPrintError('onBeforeGetContent', error);
					}
				});
			} else {
				this.handlePrint();
			}
		} else {
			this.handlePrint();
		}
	};

	handlePrint = () => {
		const { bodyClass = '', content, copyStyles = true, isPortrait = true, customPageStyle } = this.props;
		this.wasPrintTriggered = false;

		const contentEl = content();

		if (contentEl === undefined) {
			console.error(
				`Refs are not available for stateless components. For "react-to-print" to work only Class based components can be printed`
			);
			return;
		}

		const printWindow = document.createElement('iframe');
		this.printWindowId = 'printWindow' + uniqueId();
		printWindow.style.position = 'absolute';
		printWindow.style.top = '-1000px';
		printWindow.style.left = '-1000px';
		printWindow.id = this.printWindowId;

		const contentNodes = findDOMNode(contentEl);

		printWindow.onload = () => {
			/* IE11 support */
			printWindow.onload = null;

			const domDoc = printWindow.contentDocument || (printWindow.contentWindow && printWindow.contentWindow.document);
			if (!domDoc || !contentNodes) {
				return;
			}
			const srcCanvasEls = contentNodes.querySelectorAll('canvas');

			domDoc.open();
			domDoc.write(contentNodes.outerHTML);
			domDoc.close();

			/* remove date/time from top */
			const defaultPageStyle = `@page { size: auto;  margin: 0mm; } @media print { @page { size: ${
				isPortrait ? 'portrait' : 'landscape'
			}; } body { -webkit-print-color-adjust: exact; } }`;

			const styleEl = domDoc.createElement('style');
			styleEl.appendChild(domDoc.createTextNode(customPageStyle || defaultPageStyle));
			domDoc.head.appendChild(styleEl);

			if (bodyClass.length) {
				domDoc.body.classList.add(bodyClass);
			}

			const canvasEls = domDoc.querySelectorAll('canvas');
			for (let i = 0, canvasElsLen = canvasEls.length; i < canvasElsLen; ++i) {
				const node = canvasEls[i];
				node.getContext('2d').drawImage(srcCanvasEls[i], 0, 0);
			}

			const styleTag = domDoc.createElement('style');
			styleTag.appendChild(domDoc.createTextNode(css));
			domDoc.head.appendChild(styleTag);

			this.triggerPrint(printWindow);
		};

		if (document.getElementById(this.printWindowId)) {
			document.body.removeChild(document.getElementById(this.printWindowId));
		}
		document.body.appendChild(printWindow);
	};

	setRef = ref => {
		this.triggerRef = ref;
	};

	render() {
		const { trigger } = this.props;

		return React.cloneElement(trigger(), {
			onClick: e => {
				const { onClick } = this.props;
				if (onClick) {
					onClick(e);
				}
				this.handleClick();
			},
			ref: this.setRef,
		});
	}
}
