import React, { Fragment } from 'react';
import PropTypes, { string } from 'prop-types';
import moment from 'moment';
import { trim } from 'lodash';

import { mapAvsItem } from '../../../Common/utilities';
import Logo from '../print-grid-data/logo-negative';
import { withCancelable } from '../../../Common/components/cancelable';
import { PrintTransactionReceipt } from './printReceipt';
import { principalService } from 'common/services';
import { checkIfCanadian } from '../../../Common/utilities/check-if-canadian';
import renderCustomFields from './printUtils/printUtils';
import { setToCanadianVerbiage } from 'common/utilities/setToCanadianVerbiage';
import ImageWithFallback from '../ImageFallback';

class PrinterReceiptComponent extends PrintTransactionReceipt {
	isCanadian = checkIfCanadian();
	renderPrintHeader() {
		const principal = principalService.get();
		const { data } = this.props;
		if (!principal || !principal.idInfo || !principal.companyName || !data) return null;
		const { xMerchantCity, xMerchantAddress, xMerchantState, xMerchantZip, xMerchantPhone } = principal.idInfo;
		return (
			<div>
				<p className="type--sml--plus type--wgt--bold spc--bottom--sml">{principal.companyName}</p>
				<p className="type--xsml">{xMerchantAddress}</p>
				<p className="display--ib type--xsml address">{xMerchantCity}</p>
				<p className="display--ib type--xsml address">{xMerchantState}</p>
				<p className="display--ib type--xsml">{xMerchantZip}</p>
				<p className="type--xsml">{xMerchantPhone}</p>
				<p className="type--xsml">
					{moment(data.xEnteredDate, ApplicationSettings.apiResponseDateTimeFormat).format(
						ApplicationSettings.displayDateTimeFormat
					)}
				</p>
			</div>
		);
	}

	renderReceiptItem = (name, values, renderEmpty = false) => {
		if (name && ((values && this.hasAnyInfoValues(values)) || renderEmpty)) {
			return (
				<Fragment>
					<label className="spc--right--tny spc--bottom--nano type--wgt--bold">{setToCanadianVerbiage(name)}:</label>
					{this.renderReceiptMultipleValues(name, values)}
				</Fragment>
			);
		}
	};

	render() {
		const { data } = this.props;

		let avs = mapAvsItem(data && data.xResponseAVSCode);
		if (avs) {
			avs = avs.text;
		}

		return (
			<table className="receipt">
				<thead>
					<tr>
						<th />
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<div className="receipt__header">{this.renderPrintHeader()}</div>
						</td>
					</tr>
					<tr>
						<td className="receipt__info">
							<div className="spc--bottom--sml type--xsml">
								<div className="receipt__info__item">
									{this.renderReceiptItem('First Name', data.xBillFirstName, true)}
								</div>
								<div className="receipt__info__item">
									{this.renderReceiptItem('Last Name', data.xBillLastName, true)}
								</div>
							</div>
							<div className="spc--bottom--sml type--xsml">
								<div className="receipt__info__item">
									{this.renderReceiptItem('Status', data.xResponseResult, true)}
								</div>
								<div className="receipt__info__item">
									{this.renderReceiptItem('Auth Code', data.xResponseAuthCode, true)}
								</div>
								<div className="receipt__info__item">{this.renderReceiptItem('Reference#', data.xRefNum, true)}</div>
								<div className="receipt__info__item">{this.renderReceiptItem('AVS Results', avs, true)}</div>
								<div className="receipt__info__item">{this.renderReceiptItem('AVS Street', data.xStreet)}</div>
								<div className="receipt__info__item">{this.renderReceiptItem('AVS ZIP', data.xZip)}</div>
								<div className="receipt__info__item">
									{this.renderReceiptItem('Description', data.xDescription, true)}
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td className="receipt__custom">
							<div className="newtransaction__print__title">Custom information</div>
							<div className="receipt__custom__item type--xsml">
								{renderCustomFields(data, this.state.customDisplayLabels, this.renderReceiptItem, true)}
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="flex flex--primary receipt__amount">
								<label className="type--sml--base type--wgt--bold spc--right--tny">Sale Amount:</label>
								<div>{this.renderAmount()}</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="receipt__signature">
								{trim(data.xSignature) ? (
									<div className="f-col f-col-sml-4 spc--bottom--med">
										<div className="field__label spc--bottom--tny">
											{data.xBillFirstName} {data.xBillLastName}
										</div>
										<img
											className="newtransaction__print__signature__img"
											src={`data:image/png;base64,${data.xSignature}`}
										/>
									</div>
								) : (
									<p className="type--nano padd--bottom--xsml">X</p>
								)}
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<p className="type--nano type--uppercase spc--bottom--med">
								I Agree to pay above total amount according to card issuer agreement period.
							</p>
						</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<td>
							<div className="receipt__footer">
								<div className="spc--right--tny">
									<ImageWithFallback imgAttributes={{ width: '120px' }}>
										{props => <Logo {...props} />}
									</ImageWithFallback>
								</div>
								<p className="type--nano">Powered by Cardknox</p>
							</div>
						</td>
					</tr>
				</tfoot>
			</table>
		);
	}
}

PrinterReceiptComponent.propTypes = {
	data: PropTypes.any,
	makePendingRequest: PropTypes.func,
	logoUrlBase64: string,
};

export default withCancelable(PrinterReceiptComponent);
