import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { isSafari } from 'react-device-detect';

class StringFilterComponent extends Component {
	constructor(props) {
		super(props);
		this.inputRef = createRef();
	}

	componentDidUpdate() {
		const { noFocus } = this.props;
		if (this.inputRef.current && !noFocus) {
			this.inputRef.current.focus();
		}
	}

	handleChange = ({ target: { value } }) => {
		const {
			filter: { key },
			onFilterChanged,
		} = this.props;
		onFilterChanged({
			id: key,
			values: [{ key, value }],
			emptyValue: null,
		});
	};

	render() {
		const {
			filter: { key, name, values, standalone, disabled },
			noFocus,
			placeholder,
			injectedFilters,
			goButtonHandler,
			goButtonText,
		} = this.props;

		return (
			<div className="inputgroup">
				<div className="inputgroup--main">
					<input
						type="text"
						autoComplete="off"
						value={values[key] === null ? '' : values[key]}
						placeholder={placeholder || name}
						id={key}
						onChange={this.handleChange}
						autoFocus={!noFocus}
						ref={this.inputRef}
						className={`input input--sml ${isSafari ? 'safari-class' : 'not-safari-class'} ${
							standalone
								? 'input--med input--search inputgroup--main--bordered--left'
								: goButtonHandler
								? 'inputgroup--main--bordered--left'
								: ''
						}`}
						disabled={disabled && disabled(injectedFilters)}
					/>
				</div>
				{goButtonHandler && (
					<div className="inputgroup--aside">
						<button
							className={`btn btn--${standalone ? 'med' : 'sml'} btn--primary inputgroup--aside--bordered--right`}
							data-tooltip={goButtonText ? null : 'Apply'}
							onClick={goButtonHandler}
						>
							{goButtonText || <i className="icon icon--nano icon--arrow--right--positive"></i>}
						</button>
					</div>
				)}
			</div>
		);
	}
}

StringFilterComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
	noFocus: PropTypes.bool,
	placeholder: PropTypes.string,
	injectedFilters: PropTypes.object,
	goButtonHandler: PropTypes.func,
	goButtonText: PropTypes.string,
};

export default StringFilterComponent;
