import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
	merge,
	each,
	sumBy,
	isEmpty,
	find,
	map,
	replace,
	memoize,
	constant,
	camelCase,
	startCase,
	toUpper,
	sortBy,
	keys,
	toLower,
	includes,
	round,
} from 'lodash';
import NumberFormat from 'react-number-format';

import { apiToDisplay, CurrencyMap } from '../../utilities';
import principalService from '../../services/principalService';
import {
	DisplayDateComponent,
	CreditCardComponent,
	CurrencyComponent,
	StatusFraudComponent,
	CardAmountCountComponent,
	Command,
} from '../columns/formatters';
import Logo from './logo';
import UserStatusComponent from '../columns/formatters/UserStatus';
import { exportService } from '../export/exportService';
import ImageWithFallback from '../ImageFallback';

const defaultComponents = {
	preBody: constant(null),
};

class PrintGridData extends Component {
	get titleSizeClassName() {
		const {
			columns: { length },
		} = this.props;
		if (length < 6) {
			return 'type--xlrg';
		} else if (length < 10) {
			return 'type--lrg';
		} else {
			return 'type--med';
		}
	}

	get baseFontSizeClassName() {
		const {
			columns: { length },
		} = this.props;

		if (length < 6) {
			return 'type--lrg';
		} else if (length < 10) {
			return 'type--sml--plus';
		} else {
			return 'type--max';
		}
	}

	get fontSizeClassName() {
		const { isPortrait } = this.props;

		return `${this.baseFontSizeClassName}${isPortrait ? '--portrait' : ''}`;
	}

	get components() {
		return this.mergeComponents(this.props.components);
	}

	shouldComponentUpdate(nextProps) {
		return nextProps.data !== this.props.data || nextProps.columns !== this.props.columns;
	}

	mergeComponents = memoize(components => merge({}, defaultComponents, components));

	calculateSums = data => {
		const { printProcessingFee, printNetSale } = this.props;
		const currencies = [];
		if (!isEmpty(data)) {
			each(
				data,
				({ xRefNum, currency, xAmount, xProcessingFee: processingFee, netSale, xCommand, xResponseResult }) => {
					const subtract = toLower(xCommand) === 'gift:issue';
					const isGiftRedeem = toLower(xCommand) === 'gift:redeem';
					const isApproved = toLower(xResponseResult) === 'approved';
					let amount = isGiftRedeem ? Math.abs(xAmount) : xAmount;
					if (subtract) {
						amount = -Math.abs(xAmount);
					}
					if (xRefNum) {
						const item = find(currencies, { currency: toUpper(currency) });
						if (item) {
							if (printProcessingFee) {
								item.processingFee += processingFee;
							}
							if (printNetSale) {
								item.netSale += netSale;
							}
							item.amount += amount;
							if (isApproved) {
								if (item.approvedAmount) {
									item.approvedAmount += amount;
								} else {
									item.approvedAmount = amount;
								}
							}
						} else {
							const currencyOption = {
								currency,
								symbol: CurrencyMap.resolveCurrency(currency),
								amount,
							};
							if (isApproved) {
								currencyOption.approvedAmount = amount;
							}
							if (printProcessingFee) {
								currencyOption.processingFee = processingFee;
							}
							if (printNetSale) {
								currencyOption.netSale = netSale;
							}
							currencies.push(currencyOption);
						}
					}
				}
			);
		}
		return currencies;
	};

	getAmountsByCardType = () => {
		const { data } = this.props;
		const totals = {};
		const result = {};

		each(data, ({ xAmount, xCardType, currency, xCommand }) => {
			const subtract = toLower(xCommand) === 'gift:issue';
			let amount = xAmount;
			if (subtract) {
				amount = -Math.abs(xAmount);
			}
			const cardType = camelCase(xCardType);
			const mappedCurrency = CurrencyMap.isoCodesMap[currency] || toUpper(currency);
			exportService.setTotalsByCardType(totals, amount, cardType, mappedCurrency);
		});
		each(sortBy(keys(totals)), item => (result[item] = totals[item]));

		return result;
	};

	renderPrintSummary = () => {
		const { type, data, printProcessingFee, printNetSale, hideApprovedAmount, hideTotalByCard, columns } = this.props;
		const currencies = this.calculateSums(data);
		const cardBreakdown = this.getAmountsByCardType();
		const includeCurrency = find(columns, column => column.key === 'xCurrency' || toLower(column.key) === 'currency');
		return (
			type === 'transactions' && (
				<div className="spc--bottom--xsml type--tny">
					<hr className="separator separator--grey1 separator--negative--24 spc--bottom--sml" />
					<div className="type--wgt--bold spc--bottom--xsml">Transaction summary</div>
					<div className="transaction__summary">
						<div className="transaction__summary__item">
							<label className={`display--b spc--bottom--tny ${this.fontSizeClassName}`}>All transactions:</label>
							<div className="type--wgt--bold">
								<NumberFormat
									className={this.fontSizeClassName}
									value={isEmpty(data) ? 0 : data.length}
									displayType="text"
									thousandSeparator={true}
								/>
							</div>
						</div>
						<div className="transaction__summary__item">
							<label className={`display--b spc--bottom--tny ${this.fontSizeClassName}`}>Amount:</label>
							{map(currencies, ({ currency, symbol, amount }) => (
								<div key={currency} className="reportprint__currencies type--wgt--bold ">
									<NumberFormat
										className={`${this.fontSizeClassName}`}
										value={amount}
										displayType="text"
										thousandSeparator={true}
										prefix={symbol}
										suffix={includeCurrency ? ` ${toUpper(currency)}` : ''}
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							))}
						</div>
						<div className="transaction__summary__item">
							{!hideApprovedAmount && (
								<Fragment>
									<label className={`display--b spc--bottom--tny ${this.fontSizeClassName}`}>Total Approved:</label>
									{map(currencies, ({ currency, symbol, approvedAmount }) =>
										approvedAmount ? (
											<div key={currency} className="reportprint__currencies type--wgt--bold ">
												<NumberFormat
													className={`${this.fontSizeClassName}`}
													value={approvedAmount}
													displayType="text"
													thousandSeparator={true}
													prefix={symbol}
													suffix={includeCurrency ? ` ${toUpper(currency)}` : ''}
													decimalScale={2}
													fixedDecimalScale={true}
												/>
											</div>
										) : (
											'-'
										)
									)}
								</Fragment>
							)}
						</div>
						{printProcessingFee && (
							<div className="transaction__summary__item">
								<label className={`display--b spc--bottom--tny ${this.fontSizeClassName}`}>Total Processing Fee:</label>
								{map(currencies, ({ currency, symbol, processingFee }) => (
									<div key={currency} className="reportprint__currencies type--wgt--bold">
										<NumberFormat
											className={`${this.fontSizeClassName}`}
											value={processingFee}
											displayType="text"
											thousandSeparator={true}
											prefix={symbol}
											suffix={includeCurrency ? ` ${toUpper(currency)}` : ''}
											decimalScale={2}
											fixedDecimalScale={true}
										/>
									</div>
								))}
							</div>
						)}
						{printNetSale && (
							<div className="transaction__summary__item">
								<label className={`display--b spc--bottom--tny ${this.fontSizeClassName}`}>Total Net Sale:</label>
								{map(currencies, ({ currency, symbol, netSale }) => (
									<div key={currency} className="reportprint__currencies type--wgt--bold">
										<NumberFormat
											className={`${this.fontSizeClassName}`}
											value={netSale}
											displayType="text"
											thousandSeparator={true}
											prefix={symbol}
											suffix={includeCurrency ? ` ${toUpper(currency)}` : ''}
											decimalScale={2}
											fixedDecimalScale={true}
										/>
									</div>
								))}
							</div>
						)}
						{!hideTotalByCard && (
							<div className="transaction__summary__item">
								<label className={`display--b spc--bottom--tny ${this.fontSizeClassName}`}>Total by card:</label>
								{map(cardBreakdown, (currency, key) => (
									<div key={key}>
										<div className={`${this.fontSizeClassName}`} key={key}>
											{`${startCase(key)}: `}
											{map(currency, ({ amount, currency, count }) => (
												<Fragment key={amount + currency}>
													<NumberFormat
														className="type--wgt--bold spc--left--nano"
														value={amount}
														displayType="text"
														thousandSeparator={true}
														prefix={`${CurrencyMap.resolveCurrency(currency)}`}
														suffix={includeCurrency ? ` ${toUpper(currency)}` : ''}
														decimalScale={2}
														fixedDecimalScale={true}
													/>
													{` (count: ${count})`}
												</Fragment>
											))}
										</div>
									</div>
								))}
							</div>
						)}
						<div className="transaction__summary__item logo">
							<ImageWithFallback imgAttributes={{ width: '130px' }}>{props => <Logo {...props} />}</ImageWithFallback>
						</div>
					</div>
				</div>
			)
		);
	};

	renderTable = () => {
		const { type, data, columns, className, printOnOnePage } = this.props;
		const tableClassName = `${className}${printOnOnePage ? ' reportprint--max' : ''}`;
		const giftValue = isEmpty(data) ? 0 : round(sumBy(data, item => round(item.xAmount, 2)), 2);
		return (
			<table className={tableClassName}>
				<thead>
					<tr>{this.renderTableHeader()}</tr>
				</thead>
				<tbody>
					{this.renderBody()}
					{type === 'giftCardSummary' ? (
						<tr>
							<td colSpan={columns.length}>
								<div className="type--lrg type--right">
									<p className="display--ib spc--right--sml">Amount:</p>
									<NumberFormat
										value={giftValue}
										displayType="text"
										thousandSeparator={true}
										prefix="$"
										decimalScale={2}
										fixedDecimalScale={true}
									/>
								</div>
							</td>
						</tr>
					) : null}
				</tbody>
			</table>
		);
	};

	renderTableHeader = () => {
		const { columns } = this.props;
		return map(columns, ({ key, name, alignHeaderRight }) => {
			return (
				<th key={key}>
					<p className={`${this.fontSizeClassName} ${alignHeaderRight ? 'type--right display--b' : ''}`}>
						{replace(name, /\u00AD/g, '')}
					</p>
				</th>
			);
		});
	};

	renderBody = () => {
		const { data } = this.props;
		return (
			<Fragment>
				{map(data, (row, rowIndex) => (
					<tr key={rowIndex}>{this.renderRow(row, rowIndex)}</tr>
				))}
			</Fragment>
		);
	};

	renderRow = (row, rowIndex) => {
		const { columns, renderAdditionalDataPerCell } = this.props;
		return map(columns, (column, columnIndex) => {
			return (
				<td className={`${this.fontSizeClassName}`} key={column.key}>
					<p>{this.renderCell(row, column)}</p>
					{renderAdditionalDataPerCell && renderAdditionalDataPerCell(row, rowIndex, column, columnIndex)}
				</td>
			);
		});
	};

	renderCell = (row, col) => {
		const { type } = this.props;

		if (col.key === 'currency') {
			return null;
		}

		if (col.key === 'xEnteredDate' || col.key === 'date') {
			let dateFormats;
			if (type === 'schedulePreview') {
				dateFormats = {
					inputFormat: ApplicationSettings.displayDateFormat,
					outputFormat: ApplicationSettings.displayDateFormat,
				};
			}
			if (type === 'schedules') {
				dateFormats = {
					inputFormat: ApplicationSettings.apiShortDateTimeFormat,
					outputFormat: ApplicationSettings.displayShortDateTimeFormat,
				};
			}
			const dependentValues = dateFormats ? merge(dateFormats, row) : row;
			return <DisplayDateComponent value={row[col.key]} dependentValues={dependentValues} isPrint={true} />;
		}

		if (col.key === 'xStartDate') {
			return apiToDisplay(row[col.key]);
		}

		if (
			col.key === 'xAmount' ||
			col.key === 'xRemainingBalance' ||
			col.key === 'totalAmount' ||
			col.key === 'amount' ||
			col.key === 'saleAmount' ||
			col.key === 'xTax' ||
			col.key === 'xServiceFee' ||
			col.key === 'remainingBalance' ||
			col.key === 'xClearedAmount'
		) {
			if (col.key === 'totalAmount') {
				row.seperateCountColumn = true;
			}
			return (
				<CurrencyComponent
					value={row[col.key]}
					dependentValues={{ ...col, ...row }}
					row={{ ...row, displayBadge: true }}
				/>
			);
		}

		if (col.key === 'xMaskedCardNumber' && toLower(row.xCommand) !== 'split pay') {
			return <CreditCardComponent value={row[col.key]} dependentValues={row} />;
		}

		if (col.key === 'xStatus') {
			if (type === 'fraud' || (type === 'transactions' && includes(toLower(row.xCommand), 'check'))) {
				return <StatusFraudComponent value={row[col.key]} showBadge={false} />;
			} else {
				return '';
			}
		}

		if (col.key === 'status' && type === 'portalManagement') {
			return <UserStatusComponent value={row[col.key]} />;
		}

		if (
			col.key === 'xSaleAmount' ||
			col.key === 'xCreditAmount' ||
			col.key === 'xTotalAmount' ||
			col.key === 'xVoidAmount'
		) {
			const dependentValue = col.key.replace('Amount', 'Count');
			return (
				<CardAmountCountComponent
					value={row[col.key]}
					dependentValues={row[dependentValue]}
					boldify={toLower(col.key) === 'xtotalamount'}
				/>
			);
		}

		if (col.key === 'xBatchDate') {
			if (!row[col.key] && !row.xBatchTime) {
				if (row.xCardType) {
					return <CreditCardComponent value={row.xCardType} dependentValues={row} />;
				}
				return '----';
			} else {
				return `${row[col.key] || ''} ${row.xBatchTime || ''}`;
			}
		}

		if (col.key === 'xCommand') {
			return <Command value={row[col.key]} />;
		}

		return row[col.key];
	};

	render() {
		const { data, title } = this.props;

		// Company from principal
		const principal = principalService.get();

		return (
			<div className="reportprint print-wrapper">
				<div className="reportprint__header header-wrapper">
					<div className="row">
						<div className="col col-sml-3">
							<p className={`type--wgt--medium ${this.titleSizeClassName}`}>{title}</p>
						</div>
						<div className="col col-sml-9 type--xsml type--right">
							<p className="type--nano">Company name:</p> {principal.companyName}
						</div>
					</div>
					{this.renderPrintSummary()}
				</div>
				<this.components.preBody data={data} />
				<div>{data.length > 0 ? this.renderTable() : <div>No data to show</div>}</div>
			</div>
		);
	}
}

PrintGridData.defaultProps = {
	className: 'reportprint__table',
	components: {},
};

PrintGridData.propTypes = {
	data: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	type: PropTypes.string,
	title: PropTypes.string,
	className: PropTypes.string,
	components: PropTypes.object,
	printProcessingFee: PropTypes.bool,
	printNetSale: PropTypes.bool,
	renderAdditionalDataPerCell: PropTypes.func,
	isPortrait: PropTypes.bool,
	printOnOnePage: PropTypes.bool,
	hideApprovedAmount: PropTypes.bool,
	hideTotalByCard: PropTypes.bool,
};

export default PrintGridData;
