import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import { find } from 'lodash';

import Route from './Route';
import { publicLayout } from '../layouts';
import { invokeIfFunction } from '../Common/utilities';

const NoMatch = ({ location }) => (
	<div className="membership__section">
		<h3>
			Not found <code>{location.pathname}</code>
		</h3>
		<div>
			<a href="/">&laquo; Back to homepage</a>
		</div>
	</div>
);

NoMatch.propTypes = {
	location: PropTypes.object,
};

const renderRoutes = routes => {
	if (!routes) {
		return null;
	}

	const findDefault = () => {
		const defaultRoute = find(routes, ({ isDefault }) => invokeIfFunction(isDefault));
		if (defaultRoute) {
			return defaultRoute.path;
		}
		return null;
	};

	return (
		<Switch>
			{routes.map((routeData, i) => (
				<Route key={i} {...routeData} findDefaultRoute={findDefault} />
			))}
			<Route component={NoMatch} layout={publicLayout} findDefaultRoute={findDefault} />
		</Switch>
	);
};

export default renderRoutes;
