import React from 'react';
import PropTypes from 'prop-types';
import { filter, toLower, isEmpty, map, includes } from 'lodash';

const CountryList = ({ countries, selectedCountry, search, handleCountrySelect }) => {
	const filteredCountries = filter(
		countries,
		country => !isEmpty(country) && includes(toLower(country), toLower(search))
	);

	return (
		<ul className="transfer-list">
			{map(filteredCountries, country => (
				<li className="transfer-list__item" key={country}>
					<button
						className={`transfer-list__item__button${selectedCountry === country ? ' is-active' : ''}`}
						onClick={() => handleCountrySelect(country)}
					>
						{country}
					</button>
				</li>
			))}
		</ul>
	);
};

CountryList.propTypes = {
	countries: PropTypes.array.isRequired,
	selectedCountry: PropTypes.string,
	search: PropTypes.string,
	handleCountrySelect: PropTypes.func.isRequired,
};

export default CountryList;
