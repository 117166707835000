import React, { Component, Fragment } from 'react';
import { object, func, bool } from 'prop-types';
import { filter, find, findIndex, includes, map, toLower } from 'lodash-es';

import PaymentSiteFormSetting from '../PaymentSiteFormSetting';
import PaymentSiteGroupedSettings from '../components/PaymentSiteGroupedSettings';

class PaymentSiteGeneralSettingsTab extends Component {
	get expanded() {
		return {
			theme: true,
		};
	}

	renderTabs = () => {
		const {
			state: { formSettings, entireTabA, entireTabB, entireTabC, availableFormSettings, isKioskTheme },
		} = this.props;
		if (isKioskTheme) return null;
		let allDisplayed = entireTabA && entireTabB && entireTabC;
		const anyDisplayed = entireTabA || entireTabB || entireTabC;
		const accountTabs = find(availableFormSettings, setting => toLower(setting.key) === 'additionalaccounttabs');
		const tabs = filter(formSettings, ({ key }) => includes(toLower(key), 'tab'));
		return (
			<div className="paymentsite__card--secondary spc--bottom--sml--alt">
				<div className="paymentsite__card--secondary__header cursor--default">
					<div className="flex--primary datatooltip--w--160">
						<div className="spc--right--sml">
							<input
								onChange={accountTabs.onChange}
								type="checkbox"
								className="input--check input--check--no-label input--check--rounded"
								id={accountTabs.key}
								name={accountTabs.key}
								value={anyDisplayed}
								checked={anyDisplayed}
							/>
							<label htmlFor={accountTabs.key}></label>
						</div>
						<label className="paymentsite__card__title">Account Tabs</label>
					</div>
				</div>
				{anyDisplayed &&
					map(
						tabs,
						({ key, label, tooltip, fields, expanded, component, noCheckbox, cardTypesAccepted, acceptAch }) => {
							return (
								<PaymentSiteFormSetting
									key={key}
									label={label}
									tooltip={tooltip}
									fields={fields}
									expanded={expanded}
									formComponent={component}
									sectionIndex={findIndex(formSettings, setting => setting.key === key)}
									displayDelete={true}
									displayAddTab={true}
									allDisplayed={allDisplayed}
									section={'generalSettings'}
									formKey={key}
									noCheckbox={noCheckbox}
									cardTypesAccepted={cardTypesAccepted}
									acceptAch={acceptAch}
									formSettings={formSettings}
									isTab={true}
									{...this.props}
								/>
							);
						}
					)}
			</div>
		);
	};

	render() {
		const {
			state: {
				formSettings,
				entireTabA,
				entireTabB,
				entireTabC,
				entireAcceptACH,
				entireCardTypesAccepted,
				isKioskTheme,
			},
		} = this.props;
		let allDisplayed = entireTabA && entireTabB && entireTabC;
		return (
			<Fragment>
				<PaymentSiteGroupedSettings {...this.props} />
				{map(
					formSettings,
					(
						{
							key,
							label,
							tooltip,
							fields,
							expanded,
							component,
							internalSetting,
							groupedSetting,
							noCheckbox,
							cardTypesAccepted,
							acceptAch,
						},
						sectionIndex
					) => {
						if ((key === 'redirectOptions' || key === 'enableDigitalWallet') && isKioskTheme) return null;
						let displayAddTab = false;
						if (internalSetting || groupedSetting || key === 'paymentSiteLogo') return;
						if (includes(toLower(key), 'tab')) {
							displayAddTab = true;
						}
						let displayDelete = key === 'cardTypesAccepted' ? entireAcceptACH : true;
						if (key === 'acceptACH' && !entireCardTypesAccepted) {
							displayDelete = false;
						}
						if (displayAddTab) return null;
						return (
							<PaymentSiteFormSetting
								key={key}
								label={label}
								tooltip={tooltip}
								fields={fields}
								expanded={expanded}
								formComponent={component}
								sectionIndex={sectionIndex}
								displayDelete={displayDelete}
								displayAddTab={displayAddTab}
								allDisplayed={allDisplayed}
								section={'generalSettings'}
								formKey={key}
								noCheckbox={noCheckbox}
								cardTypesAccepted={cardTypesAccepted}
								acceptAch={acceptAch}
								formSettings={formSettings}
								{...this.props}
							/>
						);
					}
				)}
				{this.renderTabs()}
			</Fragment>
		);
	}
}

PaymentSiteGeneralSettingsTab.propTypes = {
	handleAdditionalSettingsDataChange: func.isRequired,
	handleAdditionalSettingsClear: func.isRequired,
	toggleRemoveSectionPopup: func.isRequired,
	removeFormSetting: func.isRequired,
	renderComponent: func.isRequired,
	toggleExpanded: func.isRequired,
	hasThemeAccess: bool.isRequired,
	addTab: func.isRequired,
	state: object.isRequired,
};

export default PaymentSiteGeneralSettingsTab;
