import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { toLower, each, uniqueId } from 'lodash';

import { kvaasResources } from 'common/utilities';
import { kvaasService } from 'common/services';
import { withCancelable } from 'common/components/cancelable';
import { withError } from 'common/components/error';

class ColumnFilterFooter extends Component {
	idPrefix = `saveColumnSettings${uniqueId()}`;
	constructor(props) {
		super(props);
		this.state = {
			saveColumnSettings: false,
			oldData: {
				defaultColumns: null,
			},
			disabled: true,
			error: false,
		};
	}

	componentDidMount = async () => {
		const { kvaasResourceType } = this.props;
		try {
			const [defaultColumns] = await this.props.makePendingRequest(
				kvaasService.get(kvaasResources[`${kvaasResourceType}ReportDefaultColumns`])
			);
			const newState = this.mapResponseToState(defaultColumns);
			this.setState(newState);
		} catch (e) {
			this.handleError(e);
		}
	};

	handleError = e => {
		//eslint-disable-next-line
		console.error(e);
		this.setState({ error: true });
	};

	mapResponseToState = (oldDefaultColumns = this.state.oldData.defaultColumns) => {
		const newState = {
			oldData: { ...this.state.oldData },
		};
		if (oldDefaultColumns) {
			const { data, result, error, refNum } = oldDefaultColumns;
			if (data) {
				if (toLower(result) === 's' || error === 'Item does not exist') {
					if (!error) {
						newState.oldData.defaultColumns = {
							...oldDefaultColumns,
						};
					}
					newState.disabled = false;
				} else {
					this.handleError({
						isApiError: true,
						isCanceled: false,
						success: false,
						message: error,
						ref: refNum,
					});
					newState.disabled = true;
				}
			}
		}
		return newState;
	};

	onReset = () => this.props.onReset();

	onApply = async () => {
		const { columns, onApply, kvaasResourceType } = this.props;
		const {
			saveColumnSettings,
			oldData: { defaultColumns },
		} = this.state;
		if (saveColumnSettings) {
			const data = {};
			each(columns, ({ visible, hideable, customSettingsKey }) => {
				if (customSettingsKey && hideable) {
					data[customSettingsKey] = visible;
				}
			});
			try {
				const [newDefaultColumns] = await kvaasService.save({
					newData: {
						revision: 0,
						data,
					},
					oldData: defaultColumns,
					...kvaasResources[`${kvaasResourceType}ReportDefaultColumns`],
				});
				const newState = this.mapResponseToState(newDefaultColumns);
				this.setState(newState, () => {
					if (!this.props.errorMessageVisible) {
						onApply(saveColumnSettings);
					}
				});
			} catch (e) {
				this.props.handleError(e);
			}
		} else {
			onApply(saveColumnSettings);
		}
	};

	handleChange = ({ target: { checked } }) => {
		this.setState({
			saveColumnSettings: checked,
		});
	};

	render() {
		const { hideSaveSelection, hasNoColumns } = this.props;

		const { saveColumnSettings, disabled, error } = this.state;
		return (
			<Fragment>
				{!hideSaveSelection && (
					<div
						className="spc--bottom--sml type--wgt--bold"
						data-tooltip={error ? 'Failed fetch from KVAAS' : disabled ? 'Fetching from KVAAS...' : null}
					>
						<input
							type="checkbox"
							id={this.idPrefix}
							name={this.idPrefix}
							value={this.idPrefix}
							className="input input--check"
							disabled={disabled || hasNoColumns}
							checked={saveColumnSettings}
							onChange={this.handleChange}
						/>
						<label htmlFor={this.idPrefix}>Save selection</label>
					</div>
				)}
				<div className="group">
					<button type="button" onClick={this.onReset} className="btn btn--reset pull">
						Reset
					</button>
					<button
						type="button"
						onClick={this.onApply}
						className="btn btn--reset anchor anchor--primary push type--wgt--bold type--uppercase"
					>
						Done
					</button>
				</div>
			</Fragment>
		);
	}
}

ColumnFilterFooter.propTypes = {
	onApply: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	columns: PropTypes.arrayOf(PropTypes.object).isRequired,
	makePendingRequest: PropTypes.func.isRequired,
	handleError: PropTypes.func.isRequired,
	errorMessageVisible: PropTypes.bool,
	hideSaveSelection: PropTypes.any,
	hasNoColumns: PropTypes.any,
};

export default withError(withCancelable(ColumnFilterFooter));
