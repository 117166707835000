import { trim } from 'lodash';

import { StringFilter } from 'common/components/filters';
import { createFilters, getFilterValues } from 'common/utilities';

export const portalManagementFilter = createFilters([
	{
		key: 'username',
		name: 'Username',
		values: {
			username: '',
		},
		component: StringFilter,
		props: {
			placeholder: 'Search by Username',
		},
		standalone: true,
	},
]);

export const compileFilter = async filters => {
	const { username } = getFilterValues(filters);

	const filter = {};

	if (trim(username.username)) {
		filter.username = username.username;
	}

	return filter;
};
