import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { startsWith } from 'lodash';
import { FilterAlert } from '../components';

const ExcelColumn = {
	name: PropTypes.node.isRequired,
	key: PropTypes.string.isRequired,
	width: PropTypes.number.isRequired,
	filterable: PropTypes.bool,
};

const RuleType = {
	Number: 1,
	Range: 2,
	GreaterThen: 3,
	LessThen: 4,
};

class NumericFilterComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: '',
			displayAlert: false,
		};
	}

	filterValues = (row, columnFilter, columnKey) => {
		if (columnFilter.filterTerm == null) {
			return true;
		}
		this.setState({ hasData: columnFilter && columnFilter.hasMoreData });
		let result = false;
		// implement default filter logic
		let value = parseFloat(row[columnKey]);
		for (let ruleKey in columnFilter.filterTerm) {
			if (!columnFilter.filterTerm.hasOwnProperty(ruleKey)) {
				continue;
			}

			let rule = columnFilter.filterTerm[ruleKey];

			switch (rule.type) {
				case RuleType.Number:
					if (startsWith(value.toFixed(2), rule.value)) {
						result = true;
					}
					break;
				case RuleType.GreaterThen:
					if (rule.value < value) {
						result = true;
					}
					break;
				case RuleType.LessThen:
					if (rule.value > value) {
						result = true;
					}
					break;
				case RuleType.Range:
					if (rule.begin <= value && rule.end >= value) {
						result = true;
					}
					break;
				default:
					// do nothing
					break;
			}
		}
		return result;
	};

	getRules = value => {
		let rules = [];
		if (value === '') {
			return rules;
		}
		// check comma
		let list = value.split(',');
		if (list.length > 0) {
			// handle each value with comma
			for (let key in list) {
				if (!list.hasOwnProperty(key)) {
					continue;
				}

				let obj = list[key];
				if (obj.indexOf('>') > -1) {
					// handle greater then
					let begin = parseFloat(obj.split('>')[1]);
					rules.push({ type: RuleType.GreaterThen, value: begin });
				} else if (obj.indexOf('<') > -1) {
					// handle less then
					let end = parseFloat(obj.split('<')[1]);
					rules.push({ type: RuleType.LessThen, value: end });
				} else if (obj.lastIndexOf('-') > 0) {
					// handle dash
					const match = obj.match(/(\d[^-]*)(-)/);
					if (match && match.length > 0) {
						const hyphenIndex = match.index + match[1].length;
						let begin = parseFloat(obj.substr(0, hyphenIndex));
						let end = parseFloat(obj.substr(hyphenIndex + 1));
						rules.push({ type: RuleType.Range, begin: begin, end: end });
					}
				} else {
					// handle normal values
					const indexOfDot = obj.indexOf('.');
					let numericValue =
						indexOfDot > -1 && indexOfDot !== obj.length - 1
							? parseFloat(obj).toFixed(indexOfDot === obj.length - 2 ? 1 : 2)
							: parseFloat(obj);
					rules.push({ type: RuleType.Number, value: numericValue });
				}
			}
		}
		return rules;
	};

	handleKeyPress = e => {
		let regex = '>|<|-|.|,|([0-9])';
		let result = RegExp(regex).test(e.key);
		if (result === false) {
			e.preventDefault();
		}
	};

	handleChange = e => {
		let value = e.target.value;
		let filters = this.getRules(value);
		this.props.onChange({
			filterTerm: filters.length > 0 ? filters : null,
			column: this.props.column,
			rawValue: value,
			filterValues: this.filterValues,
		});
		this.setState({ value, displayAlert: value });
	};

	clearFilters = () => {
		this.props.onChange({
			filterTerm: null,
			column: this.props.column,
			rawValue: '',
			filterValues: this.filterValues,
		});
		this.setState({ value: '', displayAlert: false });
	};
	renderDisplayAlert = () => {
		const { displayAlert, value, isFocused, hasData } = this.state;
		const display = displayAlert && value && isFocused && hasData;
		if (!display) return null;
		return (
			display && (
				<div className="datatooltip--inline-filter datatooltip--bottom-left datatooltip--w--200">
					<FilterAlert alertClassName="icon icon--tiny icon--info--note" />
				</div>
			)
		);
	};

	renderInput = () => {
		let inputKey = 'header-filter-' + this.props.column.key;
		return (
			<div>
				{this.renderDisplayAlert()}
				<input
					key={inputKey}
					type="text"
					placeholder="3, 10-15, >20"
					className="form-control input input--sml"
					onChange={this.handleChange}
					onKeyPress={this.handleKeyPress}
					value={this.state.value}
					onFocus={() => this.setState({ isFocused: true })}
					onBlur={() => this.setState({ displayAlert: false, hasData: false, isFocused: false })}
				/>
			</div>
		);
	};

	render = () => <div>{this.renderInput()}</div>;
}

NumericFilterComponent.propTypes = {
	onChange: PropTypes.func.isRequired,
	column: PropTypes.shape(ExcelColumn),
};

export default NumericFilterComponent;
