function createColumn(key, name) {
	return { key, name };
}

export const BatchExportColumns = [
	createColumn('xBatch', 'Batch'),
	createColumn('xBatchDate', 'Batch Date'),
	createColumn('xBatchTime', 'Batch Time'),
	createColumn('xTotalAmount', 'Total Amount'),
	createColumn('xTotalCount', 'Total Count'),
	createColumn('xSaleAmount', 'Sale Amount'),
	createColumn('xSaleCount', 'Sale Count'),
	createColumn('xTotalProcessingFeeAmount', 'Processing Fee'),
	createColumn('xNetTotalAmount', 'Net Total'),
	createColumn('xCreditAmount', 'Credit Amount'),
	createColumn('xCreditCount', 'Credit Count'),
	createColumn('xAmexCreditAmount', 'Amex Credit Amount'),
	createColumn('xAmexCreditCount', 'Amex Credit Count'),
	createColumn('xAmexSaleAmount', 'Amex Sale Amount'),
	createColumn('xAmexSaleCount', 'Amex Sale Count'),
	createColumn('xDiscoverCreditAmount', 'Discover Credit Amount'),
	createColumn('xDiscoverCreditCount', 'Discover Credit Count'),
	createColumn('xDiscoverSaleAmount', 'Discover Sale Amount'),
	createColumn('xDiscoverSaleCount', 'Discover Sale Count'),
	createColumn('xEBTCreditAmount', 'EBT Credit Amount'),
	createColumn('xEBTCreditCount', 'EBT Credit Count'),
	createColumn('xEBTSaleAmount', 'EBT Sale Amount'),
	createColumn('xEBTSaleCount', 'EBT Sale Count'),
	createColumn('xMCCreditAmount', 'MC Credit Amount'),
	createColumn('xMCCreditCount', 'MC Credit Count'),
	createColumn('xMCSaleAmount', 'MC Sale Amount'),
	createColumn('xMCSaleCount', 'MC Sale Count'),
	createColumn('xVisaCreditAmount', 'Visa Credit Amount'),
	createColumn('xVisaCreditCount', 'Visa Credit Count'),
	createColumn('xVisaSaleAmount', 'Visa Sale Amount'),
	createColumn('xVisaSaleCount', 'Visa Sale Count'),
	createColumn('xEWicCreditAmount', 'eWIC Credit Amount'),
	createColumn('xEWicCreditCount', 'eWIC Credit Count'),
	createColumn('xEWicSaleAmount', 'eWIC Sale Amount'),
	createColumn('xEWicSaleCount', 'eWIC Sale Count'),
];

export const batchDebitExportColumns = [
	createColumn('xDebitCreditAmount', 'Debit Credit Amount'),
	createColumn('xDebitCreditCount', 'Debit Credit Count'),
	createColumn('xDebitSaleAmount', 'Debit Sale Amount'),
	createColumn('xDebitSaleCount', 'Debit Sale Count'),
];
