import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { SingleDatePicker } from 'common/components/date-picker';

class SingleDateFilterComponent extends Component {
	constructor(props) {
		super(props);
	}

	componentDidUpdate() {
		if (this.inputRef) {
			this.inputRef.focus();
		}
	}

	filterChange = date => {
		const value = date
			? moment(date.date, ApplicationSettings.apiDateFormat).format(ApplicationSettings.apiDateFormat)
			: null;

		this.onFilterChange(value);
	};

	onFilterChange(value) {
		this.props.onFilterChanged({
			id: 'startDay',
			values: [{ key: 'startDay', value: value }],
			emptyValue: null,
		});
	}

	render() {
		const { filter } = this.props;
		const startDay = filter.values.startDay;
		return (
			<div className="pos--rel">
				<SingleDatePicker
					date={moment(startDay, ApplicationSettings.apiDateFormat)}
					onChange={this.filterChange}
					inputRef={el => {
						this.inputRef = el;
					}}
					isFilter={true}
					{...this.props}
				/>
			</div>
		);
	}
}

SingleDateFilterComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
	goButtonHandler: PropTypes.func,
};

export default SingleDateFilterComponent;
