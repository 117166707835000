import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { includes, map } from 'lodash';

class NotificationComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			visible: false,
			notification: {},
		};
	}

	showNotification = () => {
		this.setState({
			visible: true,
		});
	};
	pluralize = ref => {
		const isPlural = includes(ref, ',');

		return `Reference Number${(isPlural && 's') || ''}: ${ref}`;
	};
	closeNotification = () => {
		this.setState({
			visible: false,
		});
	};

	hideNotification = () => {
		let cb = () => {};
		const {
			notification: { success, forceCloseHandler, onClose },
		} = this.state;
		if ((success || forceCloseHandler) && typeof onClose === 'function') {
			cb = onClose;
		}

		this.setState(
			{
				visible: false,
			},
			() => {
				cb();
			}
		);
	};

	addNotification = notification => {
		this.setState({
			visible: true,
			notification: notification,
		});
	};

	render() {
		const { visible, notification } = this.state;
		const { additional, style } = this.props;

		let title = null;
		let message = null;
		let ref = null;

		const notifications = notification.children ? [notification, ...notification.children] : [notification];

		if (notification.title) {
			title = (
				<div className="popup__header">
					<div className="popup__header__title">{notification.title}</div>
					{map(additional, ({ Title }, index) => Title && <Title key={index} notification={notification} />)}
				</div>
			);
		}

		if (notification.message) {
			message = (
				<div className="popup__body">
					<div className={notification.contentClassName || 'fullwidth w--max--357 type--break-word spc--bottom--sml'}>
						{notifications.map((item, index) => {
							return (
								<div
									key={item.ref || index}
									className={
										(item.success ? 'type--check' : item.infoMessage ? 'type--info' : 'type--cross') +
										` type--lrg type--color--black display--t ${notification.alignLeft ? '' : 'align--h--center'}`
									}
								>
									{item.message}
								</div>
							);
						})}
						{map(additional, ({ Message }, index) => Message && <Message key={index} notification={notification} />)}
					</div>
				</div>
			);
		}

		if (notification.ref) {
			ref = (
				<React.Fragment>
					<div className="popup__footer">
						<div className="type--center type--color--text--regular fullwidth">{this.pluralize(notification.ref)}</div>
						{notification.customerId ? (
							<div className="type--center type--color--text--regular fullwidth">
								<Link
									onClick={this.hideNotification}
									className="anchor anchor--primary"
									href="javascript:void(0)"
									to={{
										pathname: '/customers',
										customerId: notification.customerId,
										showPaymentMethods: notification.showPaymentMethods,
									}}
								>
									View customer
								</Link>
							</div>
						) : null}
					</div>
					{map(additional, ({ Ref }, index) => Ref && <Ref key={index} notification={notification} />)}
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				{visible ? (
					<div className="popup__overlay__wrapper">
						<div className="popup__overlay" onClick={this.hideNotification}></div>
						<div className="popup__content popup__content--notification" style={style}>
							<button className="popup__close" onClick={this.hideNotification} />
							{title}
							{message}
							{ref}
						</div>
					</div>
				) : null}
				{map(additional, ({ Body }, index) => Body && <Body key={index} notification={notification} />)}
			</React.Fragment>
		);
	}
}

NotificationComponent.propTypes = {
	additional: PropTypes.arrayOf(
		PropTypes.shape({
			Ref: PropTypes.func,
			Title: PropTypes.func,
			Message: PropTypes.func,
			Body: PropTypes.func,
		})
	),
	style: PropTypes.object,
};

export default NotificationComponent;
