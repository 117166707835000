import React, { Component, createRef } from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { toLower, isEmpty } from 'lodash';
import { parse } from 'query-string';

import { withError } from '../../Common/components/error';
import { withCancelable } from '../../Common/components/cancelable';
import { withLoader } from '../../Common/components/loader';
import { principalService, cardholderUpdaterService } from '../../Common/services';
import { Notification } from '../../Common/components/notifications';
import { FormErrors, CurrencyMap } from '../../Common/utilities';
import validators from '../../Common/fields/validators';
import { defaultIfields } from 'common/fields';

const { ifieldsSource } = ApplicationSettings;

const commonStyle = {
	width: 'calc(100% - 28px)',
	height: '38px',
	outline: 'none',
	'border-radius': '4px',
};

const inputStyle = {
	...commonStyle,
	padding: '0 12px',
	border: '1px solid #dfe3e8',
	'box-shadow': '',
};

const invalidStyle = {
	...commonStyle,
	padding: '0 11px',
	border: '1px solid #f03e1b',
	'box-shadow': '0 0 0 2px #f9d3d0',
};

/* global setAccount, setIfieldStyle, enableAutoFormatting, ifieldEventCallbacks, addIfieldKeyPressCallback, getTokens */

class CardholderUpdater extends Component {
	constructor() {
		super();

		this.cardNumRef = createRef();
		this.hidCardNumRef = createRef();
		this.notificationRef = createRef();

		this.state = {
			amount: '',
			merchantName: '',
			lastFour: '',
			originalMerchantOnly: true,
			useForThisAccount: true,
			formSubmitted: false,
			errors: {},
			refNum: '',
			ccToken: '',
			token: '',
			expDate: '',
			useForAllAccounts: false,
			cardNumberIsEmpty: true,
			cardNumberIsValid: true,
		};
	}

	componentDidMount = () => {
		const {
			location: { search },
		} = this.props;
		if (this.cardNumRef.current) {
			window.ifieldDataCache = {
				cardNumberIsValid: false,
				cardNumberLength: 0,
				cardNumberFormattedLength: 0,
				cardNumberIsEmpty: true,
				issuer: 'unknown',
				cvvIsValid: false,
				cvvLength: 0,
				cvvIsEmpty: true,
				achLength: 0,
				achIsEmpty: true,
				achIsValid: false,
				lastIfieldChanged: '',
			};
			const ifields = principalService.get().ifields || defaultIfields;
			setAccount(ifields, SoftwareSettings.name, SoftwareSettings.version);
			setIfieldStyle('card-number', inputStyle);
			enableAutoFormatting(' ');
			if (ifieldEventCallbacks) {
				//eslint-disable-next-line
				window.ifieldEventCallbacks = {};
			}
			addIfieldKeyPressCallback(
				function(value) {
					const { cardNumberIsValid, cardNumberIsEmpty } = value;
					this.setState({ cardNumberIsValid, cardNumberIsEmpty }, this.validateInputs);
				}.bind(this)
			);
		}
		this.parseUrltoState(search);
	};

	redirect = () => {
		this.props.history.push('/');
	};

	parseUrltoState = search => {
		try {
			const { Amount, MerchantName, LastFour, Token, Refnum } = parse(atob(search.substr(1)));
			if (!Token) {
				this.redirect();
			}
			const newState = {
				amount: Amount,
				merchantName: MerchantName,
				lastFour: LastFour,
				token: Token,
				refNum: Refnum,
			};
			this.setState(newState);
		} catch (e) {
			this.redirect();
		}
	};

	getIfieldTokens = () => {
		return new Promise(resolve => {
			if (this.hidCardNumRef.current) {
				getTokens(
					() => {
						resolve({
							ccToken: this.hidCardNumRef.current.value,
						});
					},
					resolve,
					30000
				);
			} else {
				resolve();
			}
		});
	};

	validateInputs = () => {
		const { cardNumberIsEmpty, cardNumberIsValid, expDate, formSubmitted } = this.state;
		let hasErrors = false;
		const newState = { errors: {} };
		if (cardNumberIsEmpty || !cardNumberIsValid) {
			newState.errors.cardNumber = cardNumberIsEmpty ? 'Card Number is required' : 'Card Number is not valid';
			hasErrors = true;
		}
		if (!expDate || !validators.expDate(expDate)) {
			newState.errors.expDate = !expDate ? 'Expiration Date is required' : 'Expiration Date is not valid';
			hasErrors = true;
		}
		if (formSubmitted) {
			this.setState(newState);
		}
		return hasErrors;
	};

	updateCard = async () => {
		this.setState({ formSubmitted: true }, async () => {
			try {
				if (!this.validateInputs()) {
					this.props.showLoader(true);
					const { refNum: Refnum, expDate: Exp, originalMerchantOnly: OriginalMerchantOnly, token: Token } = this.state;
					const { ccToken: SUTData } = await this.props.makePendingRequest(this.getIfieldTokens());
					const data = {
						SUTData,
						Refnum,
						Exp,
						OriginalMerchantOnly,
						Token,
					};
					const { xRefnum, xResultData, xResult } = await this.props.makePendingRequest(
						cardholderUpdaterService.updateCard(data)
					);
					if (toLower(xResult) === 's') {
						this.notificationRef.current.addNotification({
							message: xResultData,
							ref: xRefnum,
							success: true,
						});
					}
					this.props.showLoader(false);
				}
			} catch (e) {
				this.props.showLoader(false);
				this.props.handleError(e);
			}
		});
	};

	handleExpDateChange = ({ value }) => {
		this.setState({ expDate: value }, this.validateInputs);
	};

	handleRadioChange = ({ target: { name, checked } }) => {
		const newState = {
			[name]: checked,
			originalMerchantOnly: name === 'useForThisAccount',
		};

		if (name === 'useForThisAccount') {
			newState.useForAllAccounts = false;
		} else {
			newState.useForThisAccount = false;
		}

		this.setState(newState);
	};

	render = () => {
		const {
			amount,
			lastFour,
			merchantName,
			expDate,
			useForAllAccounts,
			useForThisAccount,
			cardNumberIsValid,
			errors,
		} = this.state;
		const required = (
			<span className="required-field label--required" data-tooltip="Required">
				*
			</span>
		);

		setIfieldStyle('card-number', cardNumberIsValid ? inputStyle : invalidStyle);

		return (
			<div>
				<div>
					<div className="cardholder__logo" />
				</div>
				<Notification ref={this.notificationRef} />
				<div className="cardholder__card">
					<p className="type--base">
						Your card ending in <span className="type--wgt--bold">{lastFour}</span> used at merchant {merchantName} for
						the amount of {CurrencyMap.resolveCurrency()}
						{amount} has been <span className="type--color--primary">declined</span>.
					</p>
					<p className="type--sml--plus type--color--text--light spc--top--lrg">
						Please enter a new card for future transactions.
					</p>
					{!isEmpty(errors) && (
						<div className="spc--top--tny">
							<FormErrors errors={errors} />
						</div>
					)}
					<label data-ifields-id="card-data-error" />
					<div className="spr__inputgroup">
						<div className="row spc--top--tny">
							<div className="col col-sml-12 col-med-6 spc--bottom--sml">
								<label className="type--color--primary" htmlFor="cardNumber">
									Card Number {required}
								</label>
								<iframe
									ref={this.cardNumRef}
									data-ifields-id="card-number"
									className="newtransaction__iframe"
									data-ifields-placeholder="XXXX XXXX XXXX XXXX"
									src={ifieldsSource}
								/>
								<input
									type="hidden"
									data-ifields-id="card-number-token"
									name="xCardNum"
									ref={this.hidCardNumRef}
									required
								/>
							</div>
							<div className="col col-sml-12 col-med-6 spc--bottom--sml">
								<label className="type--color--primary" htmlFor="expDate">
									Expiration Date {required}
								</label>
								<NumberFormat
									name="expDate"
									id="expDate"
									type="text"
									className="input input--med"
									format="##/##"
									placeholder="MM/YY"
									value={expDate}
									onValueChange={this.handleExpDateChange}
								/>
							</div>
						</div>
						<div className="row spc--bottom--med">
							<input
								type="radio"
								name="useForThisAccount"
								id="useForThisAccount"
								checked={useForThisAccount}
								className="input input--radio input--sml"
								onChange={this.handleRadioChange}
							/>
							<label
								htmlFor="useForThisAccount"
								className="type--color--text--light type--sml spc--bottom--sml col col-sml-12"
							>
								Use updated card for {merchantName} only
							</label>
							<input
								type="radio"
								name="useForAllAccounts"
								id="useForAllAccounts"
								checked={useForAllAccounts}
								className="input input--radio input--sml"
								onChange={this.handleRadioChange}
							/>
							<label htmlFor="useForAllAccounts" className="type--color--text--light type--sml col col-sml-12">
								Use for all Cardknox accounts
							</label>
						</div>
						<div className="type--status--error padd--top--xsml padd--bottom--xsml spc--bottom--med">
							Please note that in some cases your original card may be retried before your new card is attempted.
						</div>
						<button className="btn btn--tertiary btn--med fullwidth" onClick={this.updateCard}>
							Update Card Information
						</button>
					</div>
				</div>
			</div>
		);
	};
}

CardholderUpdater.propTypes = {
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func,
	showLoader: PropTypes.func,
	isLoading: PropTypes.bool,
	location: PropTypes.object,
	history: PropTypes.object,
};

export default withError(withLoader(withCancelable(CardholderUpdater)));
