import React, { Fragment } from 'react';
function saveButton() {
	return (
		<Fragment>
			<span className="settings__header__action__text">
				Settings are saved only after clicking on <strong>Save</strong> button
			</span>{' '}
			<button
				className="btn btn--primary btn--med spc--bottom--sml"
				disabled={this.props.isLoading}
				onClick={this.save}
			>
				Save
			</button>
		</Fragment>
	);
}

export default saveButton;
