import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { times, map, every } from 'lodash';

class CustomFieldsFilterComponent extends Component {
	constructor(props) {
		super(props);

		this.inputRefs = times(props.numberOfCustomFields, React.createRef);
	}

	componentDidUpdate() {
		if (every(this.inputRefs, ref => ref.current !== document.activeElement)) {
			if (this.inputRefs[0].current) {
				this.inputRefs[0].current.focus();
			}
		}
	}

	onFilterChange = e => {
		const { name, value } = e.target;
		const { values } = this.props.filter;

		let valuesArr = map(values, (v, k) => {
			return { key: k, value: v };
		});

		const objIndex = valuesArr.findIndex(o => o.key === name);

		valuesArr[objIndex].value = value;

		this.props.onFilterChanged({
			id: 'custom',
			values: valuesArr,
			emptyValue: null,
		});
	};

	renderInputField = index => {
		const { hiddenFields, goButtonHandler, standalone } = this.props;
		const idx = (index + 1).toString().padStart(2, '0');
		return hiddenFields[index] ? null : (
			<div className="spc--top--tny">
				<div key={idx} className="spc--bottom--sml">
					<div>
						<label htmlFor={idx}>{this.props.labels[index] || 'Custom' + idx}</label>
					</div>
					<div className="inputgroup">
						<div className="inputgroup--main">
							<input
								type="text"
								className={`input input--med input--search ${
									standalone ? '' : goButtonHandler ? 'inputgroup--main--bordered--left' : ''
								}`}
								id={idx}
								name={'custom' + idx}
								placeholder={this.props.labels[index] || 'Custom' + idx}
								value={this.props.filter.values['custom' + idx] == null ? '' : this.props.filter.values['custom' + idx]}
								onChange={this.onFilterChange}
								ref={this.inputRefs[index]}
								autoFocus={index === 0}
							/>
						</div>
						{goButtonHandler && (
							<div className="inputgroup--aside">
								<button
									className="btn btn--med btn--primary inputgroup--aside--bordered--right"
									data-tooltip="Apply"
									onClick={goButtonHandler}
								>
									<i className="icon icon--nano icon--arrow--right--positive"></i>
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	};

	render() {
		const { numberOfCustomFields } = this.props;

		return <div>{times(numberOfCustomFields, this.renderInputField)}</div>;
	}
}

CustomFieldsFilterComponent.defaultProps = {
	labels: [],
	hiddenFields: [],
};

CustomFieldsFilterComponent.propTypes = {
	numberOfCustomFields: (props, propName) => {
		if (props[propName] < 1 || props[propName] > 21) {
			return new Error('There can be between 1 and 20 custom fields');
		}
	},
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
	labels: PropTypes.arrayOf(PropTypes.string),
	hiddenFields: PropTypes.arrayOf(PropTypes.bool),
	goButtonHandler: PropTypes.func,
	standalone: PropTypes.bool,
};

export default CustomFieldsFilterComponent;
