import React from 'react';
import { Router, withRouter } from 'react-router-dom';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

// NOTICE: this is mandatory!!! Do not delete
import 'aws-exports';

import { routes, renderRoutes } from './routing';
import GlobalErrorHandler from './Common/GlobalErrorHandler';
import { history, logger } from './Common/utilities';
import CustomDragLayer from './Common/components/drag-and-drop/drag-layer';
import { withSidebar } from 'common/components/withSidebar/withSidebar';

const RouterAwareGlobalErrorHandler = withRouter(withSidebar(GlobalErrorHandler));

logger.logPageView();

const App = () => (
	<DragDropContextProvider backend={HTML5Backend}>
		<CustomDragLayer />
		<Router history={history}>
			<RouterAwareGlobalErrorHandler>{renderRoutes(routes)}</RouterAwareGlobalErrorHandler>
		</Router>
	</DragDropContextProvider>
);

export default App;
