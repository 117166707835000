import httpService from './httpService';

const { quickAssistApiEndpoint } = ApplicationSettings;

class QuickAssistService {
	constructor(_httpService) {
		this.httpService = _httpService;
	}

	get headers() {
		return new Headers();
	}

	get options() {
		return {
			isJson: true,
			headers: this.headers,
		};
	}

	getFullKey = hash => {
		return this.httpService.post(quickAssistApiEndpoint, { hash }, this.options);
	};
}

const quickAssistService = new QuickAssistService(httpService);

export default quickAssistService;
