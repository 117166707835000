import httpService from './httpService';
import principalService from './principalService';

const { merchantRegistrationEndpoint } = ApplicationSettings;

class MerchantRegistrationService {
	constructor(_httpService, _principalService) {
		this.httpService = _httpService;
		this.principalService = _principalService;
	}

	get headers() {
		let headers = new Headers();
		return headers;
	}

	getOptions = isJson => {
		return {
			isJson,
			headers: this.headers,
		};
	};

	downloadAppleDomainAssociationFile = async () => {
		return await this.httpService.post(
			`${merchantRegistrationEndpoint}GetAppleDomainAssociationFile`,
			null,
			this.getOptions(false)
		);
	};
	generateApplePaymentCsr = async (domainName, merchantCertificate) => {
		const principal = this.principalService.get();
		const request = {
			domain: domainName,
			dba: principal && principal.idInfo.xMerchantDBA,
			uidId: principal && principal.idInfo.xMerchantID,
			merchantCertificate,
		};
		return await this.httpService.post(
			`${merchantRegistrationEndpoint}GenerateApplePaymentCsr`,
			request,
			this.getOptions(true)
		);
	};
	generateAppleMerchantCsr = async domainName => {
		const request = {
			domain: domainName,
		};
		return await this.httpService.post(
			`${merchantRegistrationEndpoint}GenerateAppleMerchantCsr`,
			request,
			this.getOptions(true)
		);
	};
	registerAppleDomain = async domainName => {
		const principal = this.principalService.get();
		const request = {
			domain: domainName,
			dba: principal && principal.idInfo.xMerchantDBA,
			uidId: principal && principal.idInfo.xMerchantID,
		};
		return await this.httpService.post(
			`${merchantRegistrationEndpoint}RegisterAppleMerchant`,
			request,
			this.getOptions(true)
		);
	};
	registerClickToPay = async request => {
		return await this.httpService.post(
			`${merchantRegistrationEndpoint}RegisterClickToPayMerchant`,
			request,
			this.getOptions(true)
		);
	};
}

const merchantRegistrationService = new MerchantRegistrationService(httpService, principalService);

export default merchantRegistrationService;
