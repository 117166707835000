import React, { Component } from 'react';
import { object, func } from 'prop-types';

import { withError } from '../error';
import { withCancelable } from '../cancelable';
import { transactionService } from 'common/services';
import { withRouter } from 'react-router-dom';

const requestKeys = {
	RESUBMIT: 'resubmit',
	REFETCH: 'refetch',
};

class FraudRowActions extends Component {
	state = {
		disabled: false,
	};
	addFraudProtection = async () => {
		const {
			row: { xRefNum, xEngine, updateRow },
			handleError,
			makePendingRequest,
		} = this.props;
		this.setState({ disabled: true });
		try {
			await makePendingRequest(transactionService.fraudResubmit(xRefNum, xEngine), requestKeys.RESUBMIT);
			const newRow = await makePendingRequest(transactionService.getTransaction(xRefNum), requestKeys.REFETCH);
			updateRow(newRow);
		} catch (e) {
			handleError(e);
		}
		this.setState({ disabled: false });
	};

	render() {
		const { row } = this.props;
		if (!row.canResubmit) return null;
		return (
			<button
				type="button"
				className="btn btn--tny btn--primary"
				disabled={this.state.disabled}
				onClick={this.addFraudProtection}
			>
				Add Fraud Protection
			</button>
		);
	}
}

FraudRowActions.propTypes = {
	row: object.isRequired,
	showLoader: func.isRequired,
	makePendingRequest: func.isRequired,
	handleError: func.isRequired,
};

export default withRouter(withError(withCancelable(FraudRowActions)));
